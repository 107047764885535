import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { BaseCrud } from "../../siht/base";
import { FormButtons, FormItem, Row, Panel, PanelBody, PanelFooter, Container, ModalDialog, Col, BtnRadioGroup } from "../../siht/components";
import { InputText, Div, Form, InputValorReais, InputDate, TextArea, InputCheckSwitch } from "../../siht/elements";
import Config from "./Config";
import Util from "../../utils/Util";
import { FormItemBancoConta } from "../../components/FormItem";
import BtnRadioGroupTipoBaixa from "../../components/BtnRadioGroupDB/BtnRadioGroupTipoBaixa";
import BtnRadioGroupTipoPagamento from "../../components/BtnRadioGroupDB/BtnRadioGroupTipoPagamento";

class FormFaturaCobrancaBaixaManual extends BaseCrud {

	constructor(props) {
		super(props);
		this.init = this.init.bind(this);
		this.handleSuccess = this.handleSuccess.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleChangeModel = this.handleChangeModel.bind(this);
		this.handleSubmitForm = this.handleSubmitForm.bind(this);
		this.handleChangeDtBaixa = this.handleChangeDtBaixa.bind(this);
		this.handleChangeContaUnidade = this.handleChangeContaUnidade.bind(this);

        var tzoffset = (new Date()).getTimezoneOffset() * 60000;
        var dtBaixa = new Date();
		

		this.state.model = {
			flContaUnidade : true,
			flPagamentoParcial : false,
			flJuros : true,
			flMulta : true,

			faturaCobranca : this.props?.faturaCobranca,
            tipoBaixa : null,
            tipoPagamento : null,
            dtBaixa : new Date(dtBaixa - tzoffset).toISOString().slice(0, 10),
            vlBaixa : "",
            vlMultaHoje : this.props?.faturaCobranca?.vwFaturaCobranca?.vlMultaHoje,
            vlJurosHoje : this.props?.faturaCobranca?.vwFaturaCobranca?.vlJurosHoje,
			vlCobrado : this.props?.faturaCobranca.vlAberto + this.props?.faturaCobranca?.vwFaturaCobranca?.vlMultaHoje + this.props?.faturaCobranca?.vwFaturaCobranca?.vlJurosHoje,
			vlEngargo : this.props?.faturaCobranca?.vwFaturaCobranca?.vlMultaHoje + this.props?.faturaCobranca?.vwFaturaCobranca?.vlJurosHoje,
            vlTaxa : 0,
            conta : null,
            txBaixa : ""
		};
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		super.init(Config);
	}

	handleSuccess(e) {
		Config.getService().baixarManual(this.state.model, (response) =>{
            if (this.props.handleSuccess !== undefined) {
                this.props.handleSuccess(e, response);
            }
        });
	}

	handleClose(e) {
		super.handleClose(e, Config);
	}

	handleChangeDtBaixa() {
        if (!isNaN(Date.parse(this.state.model.dtBaixa))) {
            Config.getService().getEncargosByDtBaixa(this.state.model, (response) => {
                this.setState(state => {
                    state.model.vlJurosHoje = state.model.flJuros ? parseFloat(response.vlJurosHoje) : 0;
                    state.model.vlMultaHoje = state.model.flMulta ? parseFloat(response.vlMultaHoje) : 0;
                    state.model.vlEngargo = parseFloat(state.model.vlJurosHoje) + parseFloat(state.model.vlMultaHoje);
                    state.model.vlCobrado = parseFloat(state.model.faturaCobranca.vlAberto) + state.model.vlJurosHoje + state.model.vlMultaHoje;
                    return state;
                });
            });
        } else {
            this.setState(state => {
                state.model.vlJurosHoje = 0;
                state.model.vlMultaHoje = 0;
				state.model.vlEngargo = 0;
                state.model.vlCobrado = state.model.faturaCobranca.vlAberto;
                return state;
            });
        }
    }

	handleChangeContaUnidade(){
		this.setModel({conta : null});
	}

	render() {
		return super.render(
				<Div key={this.state.key}>
					<Form onSubmit={this.handleSuccess} refParam={f => (this.form = f)}>
						<Panel title="Baixa Manual de Cobrança" onClose={this.handleClose} isModal={this.isModal()} container={{sizing: Container.sizing.xxl, className : ""}} modal={{sizing: this.props.sizing ? this.props.sizing : ModalDialog.sizing.xl}}>
							<PanelBody>
								<Row className="g-3 pb-3 px-2">
									<FormItem md={6} label="Tipo de Baixa">
										<BtnRadioGroupTipoBaixa filter={{flBaixaManual : true}} sizing={BtnRadioGroup.sizing.sm} name="tipoBaixa" value={this.state.model.tipoBaixa} required={true} onClick={this.handleChangeModel} placeholder="Selecione o Tipo de Baixa" buttons={{search : false, new : false, edit: false}}/>
									</FormItem>
								</Row>
								<Row className="g-3 pb-3 px-2">									
									<Col sizing={12} className="ps-0" show={this.state.model.tipoBaixa?.flPagamento}>
										<InputCheckSwitch label="É um pagamento parcial?" name="flPagamentoParcial" checked={this.state.model.flPagamentoParcial} onChange={this.handleChangeModel} />
										<InputCheckSwitch label="Cobrar Juros?" name="flJuros" checked={this.state.model.flJuros} onChange={(e,v,p) => this.handleChangeModel(e,v, p, this.handleChangeDtBaixa)} />
										<InputCheckSwitch label="Cobrar Multa?" name="flMulta" checked={this.state.model.flMulta} onChange={(e,v,p) => this.handleChangeModel(e,v, p, this.handleChangeDtBaixa)} />
									</Col>
                                    <FormItem md={2} label="Valor da Cobrança">
                                        <InputValorReais readOnly={true} className="text-end text-primary fw-bold" required value={this.state.model.faturaCobranca?.vlCobranca} />
									</FormItem>
                                    <FormItem md={2} label="Valor Pago">
                                        <InputValorReais readOnly={true} className="text-end text-success fw-bold" required value={this.state.model.faturaCobranca?.vlBaixa} />
									</FormItem>
									<FormItem md={2} label="Valor Aberto">
                                        <InputValorReais readOnly={true} className="text-end text-danger fw-bold" required value={this.state.model.faturaCobranca?.vlCobranca - this.state.model.faturaCobranca?.vlBaixa} />
									</FormItem>
									<FormItem md={2} label="Encargos">
                                        <InputValorReais readOnly={true} className="text-end text-danger fw-bold" required value={this.state.model.vlEngargo} />
									</FormItem>
                                    <FormItem md={2} label="Valor Cobrado">
                                        <InputValorReais readOnly={true} className="text-end text-danger fw-bold" required value={this.state.model.vlCobrado} />
									</FormItem>                                    
									<FormItem md={2} label="Vencimento">
										<InputText readOnly={true} value={Util.date2Br(this.state.model.faturaCobranca?.dtVencimento)} className="text-center"/>
									</FormItem>
								</Row>
                                <Row className="g-3 pb-3 px-2">
                                    <FormItem md={12} label="Tipo de Pagamento" show={this.state.model.tipoBaixa?.flPagamento}>
										<BtnRadioGroupTipoPagamento sizing={BtnRadioGroup.sizing.sm}  name="tipoPagamento" value={this.state.model.tipoPagamento} required={true} onClick={this.handleChangeModel} placeholder="Selecione o Tipo de Pagamento" buttons={{search : false, new : false, edit: false}}/>
									</FormItem>
                                </Row>
								<Row className="g-0 py-0 pb-2 px-2">
									<InputCheckSwitch label="Somente as contas da Unidade da Fatura?" name="flContaUnidade" checked={this.state.model.flContaUnidade} onChange={(e,v,p) => this.handleChangeModel(e, v, p, this.handleChangeContaUnidade)} />
								</Row>
								<Row className="g-3 pb-3 px-2">
									{this.state.model.flContaUnidade ?
										<FormItemBancoConta filter={{unidade : this.state.model.faturaCobranca?.fatura?.unidade}} show={this.state.model.tipoBaixa?.flPagamento} md={[6,6]} value={this.state.model.conta} name="conta" required={true} onChange={this.handleChangeModel} buttons={{search : false, new : false, edit: false}}/>
										: <FormItemBancoConta show={this.state.model.tipoBaixa?.flPagamento} md={[6,6]} value={this.state.model.conta} name="conta" required={true} onChange={this.handleChangeModel} buttons={{search : false, new : false, edit: false}}/>
									}			
                                </Row>
                                <Row className="g-3 pb-3 px-2">
                                    <FormItem md={2} label="Data de Baixa">
                                        <InputDate className="text-center" required value={this.state.model.dtBaixa} name="dtBaixa" onChange={(e,v,p) => this.handleChangeModel(e,v, p, this.handleChangeDtBaixa)} />
                                    </FormItem>
                                    <FormItem md={2} label={`Valor ${this.state.model.tipoBaixa?.flPagamento ? "do Pagamento" : "do Desconto"}`}>
                                        <InputValorReais autoFocus className="text-end" required value={this.state.model.vlBaixa} name="vlBaixa" onChange={this.handleChangeModel} />
                                    </FormItem>
                                    <FormItem md={2} label="Multa" show={this.state.model.tipoBaixa?.flPagamento}>
                                        <InputValorReais readOnly={true} className="text-end text-danger fw-bold" required value={this.state.model.vlMultaHoje} />
									</FormItem>
                                    <FormItem md={2} label="Juros" show={this.state.model.tipoBaixa?.flPagamento}>
                                        <InputValorReais readOnly={true} className="text-end text-danger fw-bold" required value={this.state.model.vlJurosHoje} />
									</FormItem>
                                    <FormItem md={2} label="Taxas" show={this.state.model.tipoBaixa?.flPagamento}>
                                        <InputValorReais className="text-end" required value={this.state.model.vlTaxa} name="vlTaxa" onChange={this.handleChangeModel} />
									</FormItem>
                                </Row>
                                <Row className="g-3 pb-3 px-2">
                                    <FormItem md={12} label="Observação">
                                        <TextArea style={{resize : "none"}} name="txBaixa" value={this.state.model.txBaixa} required={false} onChange={this.handleChangeModel}/>
                                    </FormItem>
                                </Row>
							</PanelBody>
							<PanelFooter>
								<FormButtons onClose={this.handleClose} label={["Baixar"]}/>
							</PanelFooter>
						</Panel>
					</Form>
				</Div>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <FormFaturaCobrancaBaixaManual {...props} navigate={navigate} params={params} location={location}/>
}
export default With