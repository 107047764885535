import Http from "../controllers/Http";

export default class VwContaPagarService {

	static urlBase = "/vwContaPagar/";

	static getForCombo(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(VwContaPagarService.urlBase + 'forCombo', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static list(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(VwContaPagarService.urlBase + 'list', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneById(id, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.get(VwContaPagarService.urlBase + id)
			.then(fnSucess)
			.catch(fnError);
	}


	static getAllByContaPagar(contaPagar, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(VwContaPagarService.urlBase + 'contaPagar', contaPagar)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByContaPagar(contaPagar, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(VwContaPagarService.urlBase + 'contaPagar/' + contaPagar.idContaPagar)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByStatusConta(statusConta, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(VwContaPagarService.urlBase + 'statusConta', statusConta)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByStatusConta(statusConta, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(VwContaPagarService.urlBase + 'statusConta/' + statusConta.idStatusConta)
			.then(fnSucess)
			.catch(fnError);
	}
}