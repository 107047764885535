import ManyToManyCheckUnidade from "../../components/ManyToManyCheck/ManyToManyCheckUnidade";
import EquipeUnidadeService from "../../services/EquipeUnidadeService";

export default class ManyToManyCheckEquipeUnidade extends ManyToManyCheckUnidade {

    constructor(props) {
        super(props);
        
        this.onChangeAddItem = this.onChangeAddItem.bind(this);
		this.onChangeDelItem = this.onChangeDelItem.bind(this);
		this.onChangeAddAll = this.onChangeAddAll.bind(this);
		this.onChangeDelAll = this.onChangeDelAll.bind(this);
        this.getSelecteds = this.getSelecteds.bind(this);

        this.state.selectedsOriginal = [];
    }

    componentDidMount() {
        super.componentDidMount();
        this.getSelecteds();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    componentDidUpdate(nextProps) {
        if (nextProps.parent !== undefined && this.props.parent !== undefined) {
            if (!this.objEqual(nextProps.parent, this.props.parent)) {
                this.getSelecteds();
            }
        }
    }
	

	getColumns(){
		return 1;
	}

    getSelecteds(){
        if(this.props?.parent?.idEquipe  || (Array.isArray(this.props?.parent) && this.props?.parent.length > 0)){
            EquipeUnidadeService.getAllByEquipe(this.props.parent, (response) => {
                let selecteds = response.flatMap(i => i.unidade);
				let noSelecteds = [];

				if(Array.isArray(this.props?.parent)){
					selecteds = selecteds.filter((value, index, self) => self.findIndex((v) => parseInt(v.idUnidade) === parseInt(value.idUnidade)) === index);
					selecteds.forEach((selected, index) => {
						let equipes = response.filter(i=> parseInt(i.unidade.idUnidade) === (selected.idUnidade)).flatMap(i => i.equipe);
						if((equipes.length !== this.props.parent.length)){
							noSelecteds.push(selected);
						}
					});

					selecteds = selecteds.filter(selected => !noSelecteds.find(ns => selected.idUnidade === ns.idUnidade));
				}

                this.setState({selecteds : selecteds, selectedsOriginal : response, noSelecteds : noSelecteds});
            });
        }
	}

    onChangeAddItem(e, item){     
		var parents = Array.isArray(this.props.parent) ? this.props.parent : [this.props.parent];

		parents.forEach(parent => {
			var selected = this.state.selectedsOriginal.find(i => parseInt(i.equipe.idEquipe) === parseInt(parent.idEquipe) && parseInt(i.unidade.idUnidade) === parseInt(item.idUnidade));
			if(!selected){
				EquipeUnidadeService.save({
					unidade : item,
					equipe : parent
				}, response =>{
					this.getSelecteds();
					super.onChangeAddItem(e, item);
				});
			}
		});
	};

	onChangeDelItem(e, item){
		var parents = Array.isArray(this.props.parent) ? this.props.parent : [this.props.parent];

		parents.forEach(parent => {			
			var selected = this.state.selectedsOriginal.find(i => parseInt(i.equipe.idEquipe) === parseInt(parent.idEquipe) && parseInt(i.unidade.idUnidade) === parseInt(item.idUnidade));
			if(selected){
				EquipeUnidadeService.delete(selected.idEquipeUnidade, response =>{
					this.getSelecteds();
					super.onChangeDelItem(e, item);
				});
			};
		});
	};

	onChangeAddAll(e, itens){
		var parents = Array.isArray(this.props.parent) ? this.props.parent : [this.props.parent];

		parents.forEach(parent => {
			var obj = itens.filter(item => 
				!this.state.selectedsOriginal.find(i => parseInt(i.equipe.idEquipe) === parseInt(parent.idEquipe) && parseInt(i.unidade.idUnidade) === parseInt(item.idUnidade))
			).map(item => {
				return {
					unidade : item,
					equipe : parent
				}
			});

			if(obj.length > 0){
				EquipeUnidadeService.save(obj, response =>{
					this.getSelecteds();
					super.onChangeAddAll(e, itens);
				});
			}
		});
	};

	onChangeDelAll(e, itens){
		var parents = Array.isArray(this.props.parent) ? this.props.parent : [this.props.parent];

		parents.forEach(parent => {
			var selecteds = this.state.selectedsOriginal.filter(i => parseInt(i.equipe.idEquipe) === parseInt(parent.idEquipe)).filter(i => itens.find(is => parseInt(i.unidade.idUnidade) === parseInt(is.idUnidade)));
			EquipeUnidadeService.delete(selecteds , response =>{
				this.getSelecteds();
				super.onChangeDelAll(e, itens);
			});
		});
	};

    render() {
        return super.render();
    }
}