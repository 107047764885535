//import TipoRecorrenciaService from "../services/TipoRecorrenciaService";
import Config from "../../view/TipoRecorrencia/Config";
import {SelectSearch} from "../../siht/elements";
//import Permissao from "../controllers/Permissao";

export default class SelectTipoRecorrencia extends SelectSearch {

    constructor(props) {
        super(props);
        this.getOptionsWhen = this.getOptionsWhen.bind(this);
    }

    getForComboService(){
        return Config.getService().getForCombo;
    }

    getDefault() {
        return this.state?.options.find(item => item?.flPadrao);
    }

    getFormCrud(){
        return Config.getComponentForm();
    }

    getPermissionFormCrud(){
        return Config.getPermissionForm();
    }

    getConfigSearch(){
        return [
            {
                key : Config.getKeyId(),
                label : "Código",
                className: "text-center"
            },
            {
                key : Config.getKeyDescription(),
                label : "Nome",
                //fnTransform : (value) => value
            },
        ];
    }

    getOptionsWhen(filter){
        return super.getOptionsWhen(filter);
    }

    getOptionValue(item) {
        if(item && item[Config.getKeyId()]){
            return item[Config.getKeyId()];
        }
    }

    getOptionLabel(item) {
        if(item && item[Config.getKeyDescription()]){
            return item[Config.getKeyDescription()];
        }
    }

    render() {
        return super.render();
    }
}