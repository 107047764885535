//#NO-CHANGE-FILE#

import Config from "./Config";
import React from "react";
import { useNavigate, useLocation, useParams } from "react-router";
import { BaseList } from "../../siht/base";
import { DataGrid, DataGridColumn, DataGridFooter } from "../../siht/components";
import { BtnLink,  Div, Span } from "../../siht/elements";
import { StatusCustom } from "../../components/Custom";
import { Util } from "../../utils";
import { Modal } from "../../siht/controller";
import FormComissao from "../Comissao/FormComissao";

class ListComissaoPessoaOrdemByOrdem extends BaseList {

	constructor(props) {
		super(props);
		this.getItens = this.getItens.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleNew = this.handleNew.bind(this);
		this.handleEdit = this.handleEdit.bind(this);
		this.handleCopy = this.handleCopy.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.handleMore = this.handleMore.bind(this);
		this.onSubmitFilter = this.onSubmitFilter.bind(this);
		this.handleClear = this.handleClear.bind(this);
		this.handleFormSuccess = this.handleFormSuccess.bind(this);
		this.handleFormClose = this.handleFormClose.bind(this);
		this.getTotalValor = this.getTotalValor.bind(this);
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		super.init(Config);
	}

	getItens(more){
		if(this.props?.parent){
			super.getItens(Config.getService().getAllByOrdem, this.props.parent, more);
		}
	}

	handleNew(e) {
		super.handleNew(e, Config);
	}

	handleEdit(e, item) {
		super.handleEdit(e, Config, item);
	}

	handleDelete(e, item) {
		super.handleDelete(e, Config, item);
	}

	handleCopy(e, item) {
		super.handleCopy(e, Config, item);
	}

	getFormEmbedded(){
		return super.getFormEmbedded(Config);
	}

	getTotalValor(){
		let total = 0;
		if(this.state.selecteds.length === 0){
			total = this.state.data.reduce((soma, item) => soma + item.vlValor, 0);
		}else{
			total = this.state.selecteds.reduce((soma, item) => soma + item.vlValor, 0);
		}

		return total;
	}

	handleViewComissao (e, comissao) {
		Modal.openWindow(FormComissao, {sizing : Modal.sizing.xl}, { id : comissao.idComissao });
	}

	render() {

		return super.render(
			<Div className="p-2 mt-2">

				<DataGrid responsive={true} data={this.state.data} search={false} isDisabled={i => false} sizing={DataGrid.sizing.default} select={false} selecteds={this.state.selecteds} onSelect={(selecteds)=>{this.setState({selecteds : selecteds})}} getItemValue={i=>i.idComissaoPessoaOrdem} onMore={this.handleMore}>

					<DataGridColumn col={3} label="Pessoa" field={i => <React.Fragment>
						<Div className="fw-bold">{i.pessoa?.nmPessoa}</Div>
						<Div>{i.cargo?.nmCargo} </Div>
					</React.Fragment>} className="text-start"/>

					<DataGridColumn col={1} label="Valor" field={i => Util.floatToReais(i.vlValor)} className="text-end fw-bold text-success"/>
					<DataGridColumn col={1} label="Comissão" field={i => <BtnLink onClick={(e) => this.handleViewComissao(e, i.comissao)}> {i.comissao?.idComissao} </BtnLink>} className="text-center"/>
					<DataGridColumn col={1} label="Tipo Comissão" field={i => <StatusCustom color={i.comissao.tipoComissao}> {i.comissao.tipoComissao?.nmTipoComissao} </StatusCustom>} className="text-center"/>
					<DataGridColumn col={1} label="Status" field={i => <StatusCustom color={i.comissao.statusComissao}> {i.comissao.statusComissao?.nmStatusComissao} </StatusCustom>} className="text-center"/>
					<DataGridColumn col={1} label="Data" field={i => Util.date2Br(i.comissao.dhInsert, true)} className="text-center"/>
					<DataGridColumn col={1} label="Pagamento" field={i => Util.date2Br(i.comissao.dtPagamento)} className="text-center"/>
					<DataGridColumn col={3} label="Usuário" field={i => i.comissao?.usuario?.nmUsuario} className="text-start"/>

					<DataGridFooter>
						<DataGridColumn colSpan={1} className="text-center"/>
						<DataGridColumn label="Valor"  className="text-end">
							<Span className="fw-bold text-success"> {Util.floatToReais(this.getTotalValor())} </Span>
						</DataGridColumn>
						<DataGridColumn colSpan={3} className="text-center"/>
					</DataGridFooter>
				</DataGrid>
			</Div>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <ListComissaoPessoaOrdemByOrdem {...props} navigate={navigate} params={params} location={location}/>
}
export default With