//#NO-CHANGE-FILE#

import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import SelectTipoNotificacaoDestino from "../../components/SelectDB/SelectTipoNotificacaoDestino";
import BtnRadioGroupSimNao from "../../components/BtnRadioGroupSimNao";
import { BaseCrud } from "../../siht/base";
import { FormButtons, FormItem, Row, Panel, PanelBody, PanelFooter, Container, ModalDialog } from "../../siht/components";
import { InputText, TextArea, InputColor, Form } from "../../siht/elements";
import Config from "./Config";

class FormTipoNotificacao extends BaseCrud {

	constructor(props) {
		super(props);
		this.init = this.init.bind(this);
		this.handleSuccess = this.handleSuccess.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleChangeModel = this.handleChangeModel.bind(this);
		this.handleSubmitForm = this.handleSubmitForm.bind(this);

		this.state.model = {
			idTipoNotificacao : null,
			tipoNotificacaoDestino : null,
			nmTipoNotificacao : "",
			dsTitulo : "",
			txMensagem : "",
			dsUrl : "",
			cdTipoNotificacao : "",
			cdCorFundo : "#000000",
			cdCorTexto : "#FFFFFF",
			cdCorBorda : "#FFFFFF",
			flPadrao : false,
			flSistema : true,
			flEmail : true,
			flWhatsapp : true
		};
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		super.init(Config);
	}

	handleSuccess(e) {
		super.handleSuccess(e, Config);
	}

	handleClose(e) {
		super.handleClose(e, Config);
	}

	render() {
		return super.render(

					<Form onSubmit={this.handleSuccess} refParam={f => (this.form = f)} key={this.state.key}>
						<Panel key={this.state.key} title="Cadastro de Tipo de Notificação" onClose={this.handleClose} isModal={this.isModal()} container={{sizing: Container.sizing.xxl, className : ""}} modal={{sizing: this.props.sizing ? this.props.sizing : ModalDialog.sizing.xl}}>
							<PanelBody>
								<Row className="g-3 pb-2 px-2">
									<FormItem md={3} label="Código">
										<InputText name="idTipoNotificacao" value={this.state.model.idTipoNotificacao} readOnly={true} className="readonly-field" required={false} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={9} label="Nome da Tipo de Notificação">
										<InputText name="nmTipoNotificacao" value={this.state.model.nmTipoNotificacao} autoFocus={true} maxLength={100} required={true} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={12} label="Destino">
										<SelectTipoNotificacaoDestino name="tipoNotificacaoDestino" value={this.state.model.tipoNotificacaoDestino} required={true} onChange={this.handleChangeModel} placeholder="Selecione Nome do TipoNotificacaoDestino"/>
									</FormItem>
									<FormItem md={12} label="Título">
										<InputText name="dsTitulo" value={this.state.model.dsTitulo} maxLength={250} required={true} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={12} label="Mensagem">
										<TextArea rows={4} name="txMensagem" value={this.state.model.txMensagem} required={true} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={6} label="Url">
										<InputText name="dsUrl" value={this.state.model.dsUrl} maxLength={100} required={true} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={6} label="Identificador">
										<InputText name="cdTipoNotificacao" value={this.state.model.cdTipoNotificacao} maxLength={100} required={true} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={2} label="Cor Fundo">
										<InputColor name="cdCorFundo" value={this.state.model.cdCorFundo} maxLength={7} required={true} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={2} label="Cor Texto">
										<InputColor name="cdCorTexto" value={this.state.model.cdCorTexto} maxLength={7} required={true} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={2} label="Cor Borda">
										<InputColor name="cdCorBorda" value={this.state.model.cdCorBorda} maxLength={7} required={true} onChange={this.handleChangeModel}/>
									</FormItem>
								</Row>
								<Row className="g-3 pb-2 px-2">
									<FormItem md={2} label="Padrão?">
										<BtnRadioGroupSimNao name="flPadrao" value={this.state.model.flPadrao} required={true} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={2} label="FlSistema">
										<BtnRadioGroupSimNao name="flSistema" value={this.state.model.flSistema} required={true} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={2} label="FlEmail">
										<BtnRadioGroupSimNao name="flEmail" value={this.state.model.flEmail} required={true} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={2} label="FlWhatsapp">
										<BtnRadioGroupSimNao name="flWhatsapp" value={this.state.model.flWhatsapp} required={true} onChange={this.handleChangeModel}/>
									</FormItem>
								</Row>
							</PanelBody>
							<PanelFooter>
								<FormButtons onClose={this.handleClose} />
							</PanelFooter>
						</Panel>
					</Form>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <FormTipoNotificacao {...props} navigate={navigate} params={params} location={location}/>
}
export default With