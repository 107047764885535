
import FormProduto from "./FormProduto.js";
import ListProduto from "./ListProduto.js";
import { Permissao } from "../../controllers";
import { Util } from "../../utils";
import { DadoValorService, ProdutoService } from "../../services";

export default class Config {

	static getUrlForm() {
		return "/CadProduto";
	}

	static getUrlList() {
		return "/LstProduto";
	}

	static getComponentForm() {
		return FormProduto;
	}

	static getPermissionForm() {
		return Permissao.getInstance().getPermissoes([49]);
	}

	static getPermissionList() {
		return Permissao.getInstance().getPermissoes([49]);
	}

	static getComponentList() {
		return ListProduto;
	}

	static getService() {
		return ProdutoService;
	}

	static getKeyId() {
		return "idProduto";
	}

	static getKeyDescription() {
		return "nmProduto";
	}

	static setDefaultValues(state , fnCallBack = () => {}, className = undefined) {
		className = className === undefined ? "FormProduto" : className;
		let keyNameScreen = Util.toToUpperTextCamelCase(className, "_") + ".";
		var prefix = "CONFIGURACAO.";
		DadoValorService.getValues(`${prefix}${keyNameScreen}`, (response)=> {
			Util.setValueObjectCamelCase(state.model, response, keyNameScreen, prefix);
			fnCallBack();
		}, fnCallBack);
	}
}