import Http from "../controllers/Http";

export default class VwFaturaCobrancaService {

	static urlBase = "/vwFaturaCobranca/";

	static getForCombo(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(VwFaturaCobrancaService.urlBase + 'forCombo', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static list(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(VwFaturaCobrancaService.urlBase + 'list', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneById(id, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.get(VwFaturaCobrancaService.urlBase + id)
			.then(fnSucess)
			.catch(fnError);
	}


	static getAllByFaturaCobranca(faturaCobranca, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(VwFaturaCobrancaService.urlBase + 'faturaCobranca', faturaCobranca)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByFaturaCobranca(faturaCobranca, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(VwFaturaCobrancaService.urlBase + 'faturaCobranca/' + faturaCobranca.idFaturaCobranca)
			.then(fnSucess)
			.catch(fnError);
	}
}