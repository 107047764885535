import { Component } from "react";
import { BtnButton, Div } from "../elements";
import ModalTitle from "./ModalTitle";

export default class ModalHeader extends Component {

    render() {
        if(this.props.show !== undefined && !Boolean(this.props.show)){
            return (<></>);
        }

        const props = Object.assign({}, this.props);
        props.className = `modal-header ${props.className !== undefined ? props.className : ""}`;

        delete props.show;

        return (
            <Div {...props}> 
                {this.props.children ? this.props.children : <ModalTitle> {this.props.title} </ModalTitle>}
                {this.props.onClose ? <BtnButton className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={this.props.onClose}></BtnButton> : <></>}
            </Div>
        );
    }
}