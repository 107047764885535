import Config from "./Config";
import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import ManyToManyCheckGrupoUnidadeByUnidade from "./ManyToManyCheckGrupoUnidadeByUnidade";
import ManyToManyCheckGrupoModuloByModulo from "./ManyToManyCheckGrupoModuloByModulo";
import ManyToManyCheckGrupoTipoOrdemByTipoOrdem from "./ManyToManyCheckGrupoTipoOrdemByTipoOrdem";
import ManyToManyCheckGrupoTipoPrecoByTipoPreco from "./ManyToManyCheckGrupoTipoPrecoByTipoPreco";
import ManyToManyCheckGrupoPainelByPainel from "./ManyToManyCheckGrupoPainelByPainel";
import Permissao from "../../controllers/Permissao";
import { BaseCrud } from "../../siht/base";
import { FormButtons, FormItem, Row, TabNavigator, Tab, Panel, PanelBody, PanelFooter, Container } from "../../siht/components";
import { InputText, Form, BtnSubmit } from "../../siht/elements";

class FormGrupo extends BaseCrud {

	constructor(props) {
		super(props);
		this.init = this.init.bind(this);
		this.initModel = this.initModel.bind(this);
		this.handleSuccess = this.handleSuccess.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleChangeModel = this.handleChangeModel.bind(this);
		this.handleSubmitForm = this.handleSubmitForm.bind(this);
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		super.init(Config);
	}

	initModel(){
		return {
			idGrupo : null,
			nmGrupo : "",
			grupoUnidade : [],
			grupoModulo : [],
			grupoTipoOrdem : [],
			grupoTipoPreco : [],
			grupoPainel : []
		}
	}

	handleSuccess(e) {
		super.handleSuccess(e, Config);
	}

	handleClose(e) {
		super.handleClose(e, Config);
	}

	render() {
		return super.render(

					<Panel key={this.state.key} title="Cadastro de Grupo" onClose={this.handleClose} isModal={this.isModal()} container={{sizing: Container.sizing.xxl, className : ""}}>
						<PanelBody className="p-0 m-0">
							<TabNavigator key={this.state.key} onChangeTab={this.handleChangeTab} className="border-light">
								<Tab title="Grupo" className="m-2">
									<Form onSubmit={this.handleSuccess} refParam={f => (this.form = f)}>
										<Row className="g-3 pb-2 px-2">
											<FormItem md={6} label="Código">
												<InputText name="idGrupo" value={this.state.model.idGrupo} readOnly={true} className="readonly-field" required={false} onChange={this.handleChangeModel}/>
											</FormItem>
											<FormItem md={12} label="Nome da Grupo">
												<InputText name="nmGrupo" value={this.state.model.nmGrupo} autoFocus={true} maxLength={100} required={true} onChange={this.handleChangeModel}/>
											</FormItem>
											<BtnSubmit hidden > Enviar </BtnSubmit>
										</Row>
									</Form>
								</Tab>
								<Tab show={Permissao.getInstance().getPermissoes([11])} title="Unidades" disabled={!this.state.model.idGrupo} opened={false} className="m-2">
									<ManyToManyCheckGrupoUnidadeByUnidade typeCheck={ManyToManyCheckGrupoUnidadeByUnidade.typeCheck.box} parent={this.state.model} search={true}/>
								</Tab>
								<Tab show={Permissao.getInstance().getPermissoes([12])} title="Módulos" disabled={!this.state.model.idGrupo} opened={false} className="m-2">
									<ManyToManyCheckGrupoModuloByModulo typeCheck={ManyToManyCheckGrupoModuloByModulo.typeCheck.box} parent={this.state.model} search={true}/>
								</Tab>
								<Tab show={Permissao.getInstance().getPermissoes([13])} title="Tipos de Ordem" disabled={!this.state.model.idGrupo} opened={false} className="m-2">
									<ManyToManyCheckGrupoTipoOrdemByTipoOrdem typeCheck={ManyToManyCheckGrupoTipoOrdemByTipoOrdem.typeCheck.box} parent={this.state.model} search={true}/>
								</Tab>
								<Tab show={Permissao.getInstance().getPermissoes([14])} title="Tipos de Preço" disabled={!this.state.model.idGrupo} opened={false} className="m-2">
									<ManyToManyCheckGrupoTipoPrecoByTipoPreco typeCheck={ManyToManyCheckGrupoTipoPrecoByTipoPreco.typeCheck.box} parent={this.state.model} search={true}/>
								</Tab>
								<Tab show={Permissao.getInstance().getPermissoes([101])} title="Paineis" disabled={!this.state.model.idGrupo} opened={false} className="m-2">
									<ManyToManyCheckGrupoPainelByPainel typeCheck={ManyToManyCheckGrupoPainelByPainel.typeCheck.box} parent={this.state.model} search={true}/>
								</Tab>
							</TabNavigator>
						</PanelBody>
						<PanelFooter>
							<FormButtons show={this.state.tabIndex === 0} onClose={this.handleClose} onSubmit={this.handleSubmitForm}/>
						</PanelFooter>
					</Panel>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <FormGrupo {...props} navigate={navigate} params={params} location={location}/>
}
export default With