import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import SelectModulo from "../../components/SelectDB/SelectModulo";
import SelectUsuario from "../../components/SelectDB/SelectUsuario";
import { BaseCrud } from "../../siht/base";
import { FormButtons, FormItem, Row, Panel, PanelBody, PanelFooter, Container } from "../../siht/components";
import { InputText, Div, Form } from "../../siht/elements";
import Config from "./Config";

class FormUsuarioModulo extends BaseCrud {

	constructor(props) {
		super(props);
		this.init = this.init.bind(this);
		this.handleSuccess = this.handleSuccess.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleChangeModel = this.handleChangeModel.bind(this);
		this.handleSubmitForm = this.handleSubmitForm.bind(this);

		this.state.model = {
			idUsuarioModulo : null,
			modulo : null,
			usuario : null
		};
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		super.init(Config);
	}

	handleSuccess(e) {
		super.handleSuccess(e, Config);
	}

	handleClose(e) {
		super.handleClose(e, Config);
	}

	render() {
		return super.render(
				<Div key={this.state.key}>
					<Form onSubmit={this.handleSuccess} refParam={f => (this.form = f)}>
						<Panel title="Cadastro de UsuarioModulo" onClose={this.handleClose} isModal={this.isModal()} container={{sizing: Container.sizing.xxl, className : ""}}>
							<PanelBody>
								<Row className="g-3 pb-2 px-2">
									<FormItem md={6} label="Código">
										<InputText name="idUsuarioModulo" value={this.state.model.idUsuarioModulo} readOnly={true} className="readonly-field" required={false} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={12} label="Nome do Modulo">
										<SelectModulo name="modulo" value={this.state.model.modulo} required={true} onChange={this.handleChangeModel} placeholder="Selecione Nome do Modulo"/>
									</FormItem>
									<FormItem md={12} label="Nome do Usuario">
										<SelectUsuario name="usuario" value={this.state.model.usuario} required={true} onChange={this.handleChangeModel} placeholder="Selecione Nome do Usuario"/>
									</FormItem>
								</Row>
							</PanelBody>
							<PanelFooter>
								<FormButtons onClose={this.handleClose} />
							</PanelFooter>
						</Panel>
					</Form>
				</Div>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <FormUsuarioModulo {...props} navigate={navigate} params={params} location={location}/>
}
export default With