import Select from "./Select";
import BtnButton from "../elements/BtnButton";
import Modal from "../controller/Modal";
import PanelSearch from "../components/PanelSearch";
import Button from "./Button";
import { DropDown, DropDownItem, DropDownLabel } from "../components";
import Span from "./Span";
import InputText from "./InputText";
import { IconEdit, IconNew } from "../../icons";

export default class SelectSearch extends Select {

    constructor(props) {
        super(props);

        this.handleSearch = this.handleSearch.bind(this);
        this.handleClear = this.handleClear.bind(this);
        this.handleAdd = this.handleAdd.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.resultForm = this.resultForm.bind(this);
        this.getPermissionFormCrud = this.getPermissionFormCrud.bind(this);
    }

    getPermissionFormCrud(){
        return false;
    }

    componentDidUpdate(nextProps) {
        super.componentDidUpdate(nextProps);
    }
    
    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    getFormCrud(){
        return null;
    }

    handleClear(){
        var e = {target : this.ref.current};
        super.handleChange(e, null, this.props.name); //TODO: CORRIGIR CLEAR QUANDO É SUBCOMBO, PAIS, ESTADO E CIDADE
    }

    handleSearch(){
        Modal.openWindow(PanelSearch, {}, { 
            options : this.state.options, 
            value : this.props.value,
            getConfigSearch : this.getConfigSearch,
            getOptionValue : this.getOptionValue,
            getOptionLabel : this.getOptionLabel,
            handleChange : super.handleChange
        }, (selected) =>{
            super.handleChange({}, selected, this.props.name);
        });
    }

    resultForm(e, response){
        this.getOptions(() => super.handleChange(e, response.data, this.props.name));
    }

    handleAdd() {
        if(this.getFormCrud()){
            Modal.openWindow(this.getFormCrud(), {sizing : Modal.sizing.xl}, {}, this.resultForm);
        }
    }

    handleEdit() {
        if(this.getFormCrud()){
            Modal.openWindow(this.getFormCrud(), {sizing : Modal.sizing.xl}, { id : this.getOptionValue(this.props?.value) }, this.resultForm);
        }
    }

    render() {
        if(this.getConfigSearch === undefined){
            return super.render();
        }

        var buttons = {
            search: this.props?.buttons?.search !== undefined ? this.props.buttons.search : true,
            new : this.props?.buttons?.new !== undefined ? this.props.buttons.new : true,
            edit : this.props?.buttons?.edit !== undefined ? this.props.buttons.edit : true,
            clear : this.props?.buttons?.clear !== undefined ? this.props.buttons.clear : true,
        };

        if(this.props.buttons === false){
            buttons = {
                search: false,
                new : false,
                edit : false,
                clear : false,
            };
        }

        if(buttons.new){
            buttons.new = this.getPermissionFormCrud();
        }
        
        if(buttons.edit){
            buttons.edit = this.getPermissionFormCrud();
        }

        if(this.props.readOnly !== undefined && this.props.readOnly){
            return(
                <InputText value={this.getOptionLabel(this.props.value)} readOnly={true} required={false}/>
            )
        }else{

            return (
                <div className="input-group">
                    {super.render(false)}

                    <BtnButton tabIndex="-1" className="ps-2 pe-2" show={buttons.clear} color={Button.color.outlineDanger} sizing={this.props.sizing} onClick={this.handleClear} disabled={this.props.disabled || !this.getOptionValue(this.props.value)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                        </svg>
                    </BtnButton>

                    <BtnButton tabIndex="-1" className="ps-2 pe-2" show={buttons.search} color={Button.color.outlineDark} sizing={this.props.sizing} onClick={this.handleSearch} disabled={this.props.disabled || this.state.options.length === 0}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                        </svg>
                    </BtnButton>
                    
                    {buttons.new || buttons.edit ?
                    <>
                        <DropDownLabel className="dropdown-toggle-split" tabIndex="-1" > <Span className="visually-hidden">Toggle Dropdown</Span> </DropDownLabel>
                        <DropDown tabIndex="-1"  alignment={DropDown.alignment.end}>                    
                            <DropDownItem show={buttons.new} title="Novo Registro" type="button" disabled={this.props.disabled} onClick={e => this.handleAdd()}> <IconNew/> Novo </DropDownItem>
                            <DropDownItem show={buttons.edit} title="Excluir Registro(s) Selecionado(s)" type="button" disabled={this.props.disabled || this.getOptionValue(this.props?.value) === undefined} onClick={e => this.handleEdit()}> <IconEdit/> Editar </DropDownItem>
                        </DropDown>
                    </> : <></>
                    }
                    {super.getFeedback()}
                </div>
            );
        }
    }
}