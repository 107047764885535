
import FormPessoaEndereco from "./FormPessoaEndereco.js";
import ListPessoaEndereco from "./ListPessoaEndereco.js";
import { Permissao } from "../../controllers";
import { Util } from "../../utils";
import { DadoValorService, PessoaEnderecoService } from "../../services";

export default class Config {

	static getUrlForm() {
		return "/CadPessoaEndereco";
	}

	static getUrlList() {
		return "/LstPessoaEndereco";
	}

	static getComponentForm() {
		return FormPessoaEndereco;
	}

	static getPermissionForm() {
		return Permissao.getInstance().getPermissoes([38]);
	}

	static getPermissionList() {
		return Permissao.getInstance().getPermissoes([38]);
	}

	static getComponentList() {
		return ListPessoaEndereco;
	}

	static getService() {
		return PessoaEnderecoService;
	}

	static getKeyId() {
		return "idPessoaEndereco";
	}

	static getKeyDescription() {
		return "nmPessoaEndereco";
	}

	static setDefaultValues(state , fnCallBack = () => {}, className = undefined) {
		className = className === undefined ? "FormPessoaEndereco" : className;
		let keyNameScreen = Util.toToUpperTextCamelCase(className, "_") + ".";
		var prefix = "CONFIGURACAO.";
		DadoValorService.getValues(`${prefix}${keyNameScreen}`, (response)=> {
			Util.setValueObjectCamelCase(state.model, response, keyNameScreen, prefix);
			fnCallBack();
		}, fnCallBack);
	}
}