import Config from "./Config";
import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import SelectUnidadeMedida from "../../components/SelectDB/SelectUnidadeMedida";
import { BaseCrud } from "../../siht/base";
import { FormButtons, FormItem, Row, Panel, PanelBody, PanelFooter, Container, ModalDialog } from "../../siht/components";
import { InputText, InputNumber, InputNumberFormat, Form } from "../../siht/elements";

class FormFaturaNotaFiscalDeducaoItem extends BaseCrud {

	constructor(props) {
		super(props);
		this.init = this.init.bind(this);
		this.initModel = this.initModel.bind(this);
		this.handleSuccess = this.handleSuccess.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleChangeModel = this.handleChangeModel.bind(this);
		this.handleSubmitForm = this.handleSubmitForm.bind(this);
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		super.init(Config);
	}

	initModel(){
		return {
			idFaturaNotaFiscalDeducaoItem : null,
			faturaNotaFiscalDeducao : this.props.parent !== undefined ? this.props.parent : {},
			unidadeMedida : null,
			cdItem : "",
			dsItem : "",
			nrQuantidade : null,
			vlValor : 0
		}
	}

	handleSuccess(e) {
		super.handleSuccess(e, Config);
	}

	handleClose(e) {
		super.handleClose(e, Config);
	}

	render() {
		return super.render(

					<Form onSubmit={this.handleSuccess} refParam={f => (this.form = f)} key={this.state.key}>
						<Panel key={this.state.key} title="Cadastro de FaturaNotaFiscalDeducaoItem" localOpen={this.props.localOpen}  onClose={this.handleClose} isModal={this.isModal()} container={{sizing: Container.sizing.xxl, className : ""}} modal={{sizing: this.props.sizing ? this.props.sizing : ModalDialog.sizing.xl}}>
							<PanelBody>
								<Row className="g-3 pb-2 px-2">
									<FormItem md={6} label="Código">
										<InputText name="idFaturaNotaFiscalDeducaoItem" value={this.state.model.idFaturaNotaFiscalDeducaoItem} readOnly={true} className="readonly-field" required={false} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={12} label="Nome do UnidadeMedida">
										<SelectUnidadeMedida name="unidadeMedida" value={this.state.model.unidadeMedida} required={true} onChange={this.handleChangeModel} placeholder="Selecione Nome do UnidadeMedida"/>
									</FormItem>
									<FormItem md={6} label="CdItem">
										<InputText name="cdItem" value={this.state.model.cdItem} maxLength={100} required={true} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={6} label="DsItem">
										<InputText name="dsItem" value={this.state.model.dsItem} maxLength={100} required={true} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={6} label="NrQuantidade">
										<InputNumber name="nrQuantidade" value={this.state.model.nrQuantidade} required={false} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={4} label="VlValor">
										<InputNumberFormat name="vlValor" value={this.state.model.vlValor} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} prefix="R$ " required={true} onChange={this.handleChangeModel}/>
									</FormItem>
								</Row>
							</PanelBody>
							<PanelFooter>
								<FormButtons onClose={this.handleClose} />
							</PanelFooter>
						</Panel>
					</Form>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <FormFaturaNotaFiscalDeducaoItem {...props} navigate={navigate} params={params} location={location}/>
}
export default With