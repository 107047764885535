import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BaseComponent } from "../../siht/base";
import SelectDadoLocal from "../../components/SelectDB/SelectDadoLocal";
import SelectDadoLocalGrupo from "../../components/SelectDB/SelectDadoLocalGrupo";
import { FormItem } from "../../siht/components";

class FormItemDadoLocalDadoLocalGrupo extends BaseComponent {

	constructor(props) {
		super(props);
		this.init = this.init.bind(this);        
        this.handleChangeDadoLocal = this.handleChangeDadoLocal.bind(this);
        this.handleChangeDadoLocalGrupo = this.handleChangeDadoLocalGrupo.bind(this);
	}

	componentDidMount() {
		super.componentDidMount();
        this.init();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		
	}

	handleChangeDadoLocal(e, v, p){
        var value =  { dadoLocal : v ? v : {} };
        this.props.onChange(e, value, this.props.name);
	}

	handleChangeDadoLocalGrupo(e, v, p){		
        var value =  v ? v : { dadoLocal : this.props.value?.dadoLocal };
		this.props.onChange(e, value, this.props.name);
	}

	render() {

		var propsFormItem = ["col","xs","sm","md","lg","xl","xxl"];
		var propsItem = ["required" , "buttons" , "label", "placeholder", "disabled", "opcional"];

		return super.render(
            <React.Fragment>
                <FormItem label="Local do Dado" {...(this.getProps(propsFormItem,0))}>
                    <SelectDadoLocal {...(this.getProps(propsItem,0))} name="dadoLocal" value={this.props.value?.dadoLocal} onChange={this.handleChangeDadoLocal} placeholder="Selecione o DadoLocal"/>
                </FormItem>
                <FormItem label="Grupo do Dado" {...(this.getProps(propsFormItem,1))}>
                    <SelectDadoLocalGrupo  {...(this.getProps(propsItem,1))} getOptionsWhen={f => f?.dadoLocal?.idDadoLocal !== undefined} filter={{dadoLocal : this.props.value?.dadoLocal}} name="dadoLocalGrupo" value={this.props.value} onChange={this.handleChangeDadoLocalGrupo} placeholder="Selecione Nome do DadoLocalGrupo"/>
                </FormItem>
            </React.Fragment>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	return <FormItemDadoLocalDadoLocalGrupo {...props} navigate={navigate} params={params} />
}
export default With