import React, { Component } from "react";
import AccordionItem from "./AccordionItem";
import { Div } from "../elements";

export default class Accordion extends Component {

    render() {
        if(this.props.show !== undefined && !Boolean(this.props.show)){
            return (<></>);
        }

        const props = Object.assign({}, this.props);
        props.className = `accordion ${props.className !== undefined ? props.className : ""} ${props.flush !== undefined && props.flush ? "accordion-flush" : ""}`;
        props.id = `accordion-${Math.random()}`;

        var alwaysOpen = (props.alwaysOpen !== undefined && props.alwaysOpen);
        
        delete props.show;
        delete props.flush;
        delete props.alwaysOpen;

        var itens = React.Children.toArray(this.props.children).filter(child => child.type === AccordionItem);
        

        return (            
            <div {...props}> 
                {itens.map((item, index) => {

                    let id = `accordion-item-${index}-${Math.random()}`;
                    let open = (index === 0 || (item.props.open !== undefined && item.props.open)) && !(item.props.open !== undefined && !item.props.open);
                    let className = item.props.className !== undefined && item.props.className ? item.props.className : "";

                    return (
                        <Div key={index} className="accordion-item" id={props.id}>
                            <h2 className="accordion-header">
                                <button className={`accordion-button ${!open ? "collapsed" : ""}`} type="button" data-bs-toggle="collapse" data-bs-target={`#${id}`} aria-expanded={open ? "true" : "false"} aria-controls={id}>
                                    {item.props.label}
                                </button>
                            </h2>

                            <div id={id} className={`accordion-collapse collapse ${open ? "show" : ""}`} data-bs-parent={!alwaysOpen ? `#${props.id}` : "null"}>
                                <div className={`accordion-body ${className}`}>
                                    {item.props.children}
                                </div>
                            </div>
                        </Div>
                    )

                })} 
            </div>
        );
    }
}