import { Component } from "react";

export default class Col extends Component {

    static layout = {
        xs : "xs",
        sm : "sm",
        md : "md",
        lg : "lg",
        xl : "xl",
        xxl : "xxl",
        default : ""
    }

    render() {
        if(this.props.show !== undefined && !Boolean(this.props.show)){
            return (<></>);
        }

        const props = Object.assign({}, this.props);
        props.className = `col${props.layout !== undefined && props.layout ? `-${props.layout}` : `${Col.layout.default ? `-${Col.layout.default}` : ""}`}${props.sizing !== undefined ? `-${props.sizing}` : ""} ${props.className !== undefined ? props.className : ""}`;

        delete props.sizing;
        delete props.layout;
        delete props.show;

        return (
            <div {...props}> {this.props.children} </div>
        );
    }
}