
import FormRecursoOrdem from "./FormRecursoOrdem.js";
import ListRecursoOrdem from "./ListRecursoOrdem.js";
import { Permissao } from "../../controllers";
import { Util } from "../../utils";
import { DadoValorService, RecursoOrdemService } from "../../services";

export default class Config {

	static getUrlForm() {
		return "/CadRecursoOrdem";
	}

	static getUrlList() {
		return "/LstRecursoOrdem";
	}

	static getComponentForm() {
		return FormRecursoOrdem;
	}

	static getPermissionForm() {
		return Permissao.getInstance().getPermissoes([81]);
	}

	static getPermissionList() {
		return Permissao.getInstance().getPermissoes([81]);
	}

	static getComponentList() {
		return ListRecursoOrdem;
	}

	static getService() {
		return RecursoOrdemService;
	}

	static getKeyId() {
		return "idRecursoOrdem";
	}

	static getKeyDescription() {
		return "nmRecursoOrdem";
	}

	static setDefaultValues(state , fnCallBack = () => {}, className = undefined) {
		className = className === undefined ? "FormRecursoOrdem" : className;
		let keyNameScreen = Util.toToUpperTextCamelCase(className, "_") + ".";
		var prefix = "CONFIGURACAO.";
		DadoValorService.getValues(`${prefix}${keyNameScreen}`, (response)=> {
			Util.setValueObjectCamelCase(state.model, response, keyNameScreen, prefix);
			fnCallBack();
		}, fnCallBack);
	}
}