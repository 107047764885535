import React from 'react';
import {
  BaseEdge,
  EdgeLabelRenderer,  
  useReactFlow,
  getBezierPath,
  getSmoothStepPath,
  getStraightPath,
  
} from '@xyflow/react';
 
export default function CustomEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  markerEnd,
  sourceHandleId,
  ...props
}) {
  const reactFlow = useReactFlow();

  let [edgePath, labelX, labelY] = [null, null, null];
  switch(props.type){
    case "step":
        [edgePath, labelX, labelY] = getSmoothStepPath({sourceX, sourceY, sourcePosition, targetX, targetY, targetPosition, borderRadius : 0});    
      break;

    case "smoothstep":
        [edgePath, labelX, labelY] = getSmoothStepPath({sourceX, sourceY, sourcePosition, targetX, targetY, targetPosition});    
      break;
    
    case "straight":
        [edgePath, labelX, labelY] = getStraightPath({sourceX, sourceY, sourcePosition, targetX, targetY, targetPosition});    
      break;
    
    default:
      [edgePath, labelX, labelY] = getBezierPath({sourceX, sourceY, sourcePosition, targetX, targetY, targetPosition});
      //getSimpleBezierPath
  }
 
  const onEdgeClick = () => {
    reactFlow.deleteElements({edges : reactFlow.getEdges().filter((edge) => edge.id === id)});
  };

  return (
    <>
      <BaseEdge path={edgePath}/>
      <EdgeLabelRenderer>
        <div
          className="button-edge__label nodrag nopan"
          style={{
            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
          }}
        >
          <button className="button-edge__button" onClick={onEdgeClick}>
            ×
          </button>
          
            {sourceHandleId !== undefined && sourceHandleId === "source-sim" ? <span className="edge-label-sim">Sim</span> : "" }
            {sourceHandleId !== undefined && sourceHandleId === "source-nao" ? <span className="edge-label-nao">Não</span> : "" }
        </div>
      </EdgeLabelRenderer>
    </>
  );
}