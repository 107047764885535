import Http from "../controllers/Http";

export default class FaturaNotaFiscalItemIssService {

	static urlBase = "/faturaNotaFiscalItemIss/";

	static getForCombo(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(FaturaNotaFiscalItemIssService.urlBase + 'forCombo', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static list(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(FaturaNotaFiscalItemIssService.urlBase + 'list', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneById(id, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.get(FaturaNotaFiscalItemIssService.urlBase + id)
			.then(fnSucess)
			.catch(fnError);
	}

	static create(data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(FaturaNotaFiscalItemIssService.urlBase, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static update(id, data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.put(FaturaNotaFiscalItemIssService.urlBase + id, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static delete(id, fnSucess = () => { }, fnError = () => { }) {
		if(Number.isInteger(id)){
			Http.getInstanceAppJson()
				.delete(FaturaNotaFiscalItemIssService.urlBase + id)
				.then(fnSucess)
				.catch(fnError);
		}else{
			Http.getInstanceAppJson()
			.post(FaturaNotaFiscalItemIssService.urlBase + 'delete', id)
			.then(fnSucess)
			.catch(fnError);
		}
	}

	static save(data, fnSucess = () => { }, fnError = () => { }) {
		if (data.idFaturaNotaFiscalItemIss) {
			this.update(data.idFaturaNotaFiscalItemIss, data, fnSucess, fnError);
		} else {
			this.create(data, fnSucess, fnError);
		}
	}

	static getAllByFaturaNotaFiscalItem(faturaNotaFiscalItem, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(FaturaNotaFiscalItemIssService.urlBase + 'faturaNotaFiscalItem', faturaNotaFiscalItem)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByFaturaNotaFiscalItem(faturaNotaFiscalItem, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(FaturaNotaFiscalItemIssService.urlBase + 'faturaNotaFiscalItem/' + faturaNotaFiscalItem.idFaturaNotaFiscalItem)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByTipoTributacaoServico(tipoTributacaoServico, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(FaturaNotaFiscalItemIssService.urlBase + 'tipoTributacaoServico', tipoTributacaoServico)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByTipoTributacaoServico(tipoTributacaoServico, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(FaturaNotaFiscalItemIssService.urlBase + 'tipoTributacaoServico/' + tipoTributacaoServico.idTipoTributacaoServico)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByTipoExigibilidadeServico(tipoExigibilidadeServico, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(FaturaNotaFiscalItemIssService.urlBase + 'tipoExigibilidadeServico', tipoExigibilidadeServico)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByTipoExigibilidadeServico(tipoExigibilidadeServico, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(FaturaNotaFiscalItemIssService.urlBase + 'tipoExigibilidadeServico/' + tipoExigibilidadeServico.idTipoExigibilidadeServico)
			.then(fnSucess)
			.catch(fnError);
	}
}