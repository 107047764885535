import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BaseComponent } from "../../siht/base";
import SelectBanco from "../../components/SelectDB/SelectBanco";
import SelectConta from "../../components/SelectDB/SelectConta";
import { FormItem } from "../../siht/components";

class FormItemBancoConta extends BaseComponent {

	constructor(props) {
		super(props);
		this.init = this.init.bind(this);        
        this.handleChangeBanco = this.handleChangeBanco.bind(this);
        this.handleChangeConta = this.handleChangeConta.bind(this);
	}

	componentDidMount() {
		super.componentDidMount();
        this.init();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		
	}

	handleChangeBanco(e, v, p){
        var value =  { banco : v ? v : {} };
        this.props.onChange(e, value, this.props.name);
	}

	handleChangeConta(e, v, p){
        var value =  v ? v : { banco : this.props.value?.banco };
		this.props.onChange(e, value, this.props.name);
	}

	render() {

		var propsFormItem = ["col","xs","sm","md","lg","xl","xxl"];
		var propsItem = ["required" , "buttons" , "label", "placeholder", "disabled"];

		let unidade = this.props.filter?.unidade !== undefined ? this.props.filter?.unidade : null;

		return super.render(
            <React.Fragment>
                <FormItem label="Banco" {...(this.getProps(propsFormItem,0))}>
                    <SelectBanco filter={{flTemConta : true, unidade : unidade }} {...(this.getProps(propsItem,0))} name="banco" value={this.props.value?.banco} onChange={this.handleChangeBanco} placeholder="Selecione o Banco"/>
                </FormItem>
                <FormItem label="Conta" {...(this.getProps(propsFormItem,1))}>
                    <SelectConta {...(this.getProps(propsItem,1))} getOptionsWhen={f => f?.banco?.idBanco !== undefined} filter={{banco : this.props.value?.banco, unidade : unidade}} name="conta" value={this.props.value} onChange={this.handleChangeConta} placeholder="Selecione Nome do Conta"/>
                </FormItem>
            </React.Fragment>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	return <FormItemBancoConta {...props} navigate={navigate} params={params} />
}
export default With