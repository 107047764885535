import React, { Component } from "react";
import InputGroupText from "./InputGroupText";
import InputGroup from "./InputGroup";
import InputGroupButton from "./InputGroupButton";
import { Tooltip } from 'react-tooltip'
import Util from "../../utils/Util";
import { InfoCircle as IconInfoCircle} from "react-bootstrap-icons";

export default class FormItem extends Component {

    static layout = {
        floating: "floating",
        default: ""
    }

    static sizing = {
        sm: "sm",
        lg: "lg",
        default: "",
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }


    getColSize() {
        var colValue = false;
        var colSize = false;

        if (this.props.col) {
            colValue = "";
            colSize = this.props.col;
        } else if (this.props.xs) {
            colValue = this.props.xs;
            colSize = "xs";
        } else if (this.props.sm) {
            colValue = this.props.sm;
            colSize = "sm";
        } else if (this.props.md) {
            colValue = this.props.md;
            colSize = "md";
        } else if (this.props.lg) {
            colValue = this.props.lg;
            colSize = "lg";
        } else if (this.props.xl) {
            colValue = this.props.xl;
            colSize = "xl";
        } else if (this.props.xxl) {
            colValue = this.props.xxl;
            colSize = "xxl";
        }

        if (colSize) {
            return "col-" + colSize + (colValue > 0 ? "-" + colValue : "");
        }

        return "";
    }

    recursiveChild(childs, pps){
        if(Array.isArray(childs)){
            childs.forEach((child, index) => {
                if( child.type === InputGroup ){
                    return this.recursiveChild(child.props.children, pps);
                } else {
                    if( child.type !== InputGroupText && child.type !== InputGroupButton ){

                        let element = React.cloneElement(child, {
                            id: child.props.id !== undefined ? child.props.id : "frmItem" + (child.props.name ? child.props.name : Math.random()),
                            placeholder: child.props.placeholder ? child.props.placeholder : this.props.label,
                            sizing: child.props.sizing === undefined && this.props.sizing ? this.props.sizing : ""
                        });

                        let label = this.props.label;
            
                        if (label && !element.props.required && !element.props.readOnly && !element.props.opcional) {
                            label = <>{this.props.label} <small className="badge bg-light text-dark">opcional</small></>;
                        }
            
                        child = element

                        pps.label = label;
                        pps.id = element.props.id;
                    }
                }
            });
        }
    }

    render() {
        if (this.props.show !== undefined && !Boolean(this.props.show)) {
            return (<></>);
        }

        const props = Object.assign({}, this.props);
        props.className = `${this.getColSize()} ${props.className !== undefined ? props.className : ""}`;
        delete props.layout;
        delete props.sizing;
        delete props.opcional;
        delete props.show;

        var childs = React.Children.toArray(this.props.children);
        var pps = { label : "", id : ""};

        this.recursiveChild(childs, pps);

        var render = <></>;

        var renderHelp = <></>;

        if(this.props.help){
            let idHelp = Math.random();
            let message = "";
            let maxWidth = 300;

            if (Util.isObject(this.props.help) && this.props?.help?.message !== undefined){
                message = this.props.help.message;
                if(this.props.help.maxWidth !== undefined){
                    maxWidth = this.props.help.maxWidth;
                }
            }else{
                message = this.props.help;
            }

            renderHelp = (
                <>
                    <span data-tooltip-id={`my-tooltip-styles-${idHelp}`}><IconInfoCircle/></span>
                    <Tooltip className="text-bg-warning" id={`my-tooltip-styles-${idHelp}`} style={{zIndex : 99999999999999}}>
                        <div style={{maxWidth : `${maxWidth}px`, textAlign : "justify"}}>
                            {message}
                        </div>
                    </Tooltip>
                </>
            );
        }

        if (this.props.layout === FormItem.layout.floating) {
            props.className = `form-floating ${props.className !== undefined ? props.className : ""}`;

            render = (
                <div {...props}>
                    {childs}
                    <label htmlFor={pps.id}>{pps.label}</label>
                </div>
            );

        } else {
            render = (
                <div {...props}>
                    <label htmlFor={pps.id} className={`form-label ${this.props.sizing ? "form-label-app col-form-label-" + this.props.sizing : ""}`}>
                        {pps.label} {renderHelp}
                    </label>
                    {childs}
                </div>
            );
        }

        return render;
    }
}