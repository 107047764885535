//#NO-CHANGE-FILE#

import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import SelectTipoEndereco from "../../components/SelectDB/SelectTipoEndereco";
import { BaseCrud } from "../../siht/base";
import { FormButtons, FormItem, Row, Panel, PanelBody, PanelFooter, Container, ModalDialog } from "../../siht/components";
import { Form } from "../../siht/elements";
import Config from "./Config";
import Util from "../../utils/Util";
import EnderecoService from "../../services/EnderecoService";
import OneToOneFormEndereco from "../Endereco/OneToOneFormEndereco";

class FormFaturaNotaFiscalEndereco extends BaseCrud {

	constructor(props) {
		super(props);
		this.init = this.init.bind(this);
		this.handleSuccess = this.handleSuccess.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleChangeModel = this.handleChangeModel.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmitForm = this.handleSubmitForm.bind(this);
		this.handleBuscarCep = this.handleBuscarCep.bind(this);

		this.state.model = {
			idFaturaNotaFiscalEndereco : null,
			faturaNotaFiscal : this.props.parent !== undefined ? this.props.parent : {},
			tipoEndereco : null,
			endereco : null
		};
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		super.init(Config);
	}

	handleSuccess(e) {
		super.handleSuccess(e, Config);
	}

	handleClose(e) {
		super.handleClose(e, Config);
	}

	handleBuscarCep(){

		if(!this.state.model?.endereco?.nrCep){
			return;
		}

		if(Util.stringToNumber(this.state.model?.endereco?.nrCep).length !== 8){
			return;
		}

		EnderecoService.getOneByCep(this.state.model.endereco?.nrCep, (response) => {
			this.setState(state =>{
				state.model.endereco.cidade = response.cidade;
				state.model.endereco.dsLogradouro = response.logradouro;
				state.model.endereco.dsBairro = response.bairro;

				if(response.tipoLogradouro !== undefined){
					state.model.endereco.tipoLogradouro = response.tipoLogradouro;
				}
				if(response.tipoBairro !== undefined){
					state.model.endereco.tipoBairro = response.tipoBairro;
				}

				return state;
			});
		});
	}

	render() {
		return super.render(
			<Form onSubmit={this.handleSuccess} refParam={f => (this.form = f)} key={this.state.key}>
				<Panel key={this.state.key} title="Cadastro de Ordem Endereço" onClose={this.handleClose} isModal={this.isModal()} container={{sizing: Container.sizing.xxl, className : ""}} modal={{sizing: this.props.sizing ? this.props.sizing : ModalDialog.sizing.xl}}>
					<PanelBody>
						<Row className="g-3 pb-2 px-2">
							<FormItem md={12} label="Tipo de Endereço">
								<SelectTipoEndereco name="tipoEndereco" value={this.state.model.tipoEndereco} required={true} onChange={this.handleChangeModel} placeholder="Selecione o Tipo de Endereço" buttons={{search : false, new : false, edit: false}}/>
							</FormItem>

							<FormItem md={12} label="Endereço" help="Endereço do Cliente na Fatura">
								<OneToOneFormEndereco flSomenteCidade={true} name="endereco" value={this.state.model.endereco} required={true} onChange={this.handleChangeModel}  placeholder="Informe o Endereço"/>
							</FormItem>
						</Row>
					</PanelBody>
					<PanelFooter>
						<FormButtons onClose={this.handleClose} />
					</PanelFooter>
				</Panel>
			</Form>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <FormFaturaNotaFiscalEndereco {...props} navigate={navigate} params={params} location={location}/>
}
export default With