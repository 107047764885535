import InputMask from "./InputMask";

export default class InputCardValidate extends InputMask {

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    isValid(value) {
        try {
            var hoje, data;
            var mes = parseInt(value.substring(0, 2)) - 1;
            var ano = parseInt(value.substring(3, 7));

            if (mes < 0 || mes > 11) {
                return false;
            }

            hoje = new Date();
            hoje = new Date(hoje.getFullYear(), hoje.getMonth(), 1);
            data = new Date(ano, mes, 1);

            if (data >= hoje) {
                return true;
            }
        } catch (error) {
            return false;
        }
        return false;

    }

    handleChange(event) {
        if (event.target.value && !this.isValid(event.target.value)) {
            event.target.setCustomValidity("Data Inválida!");
        } else {
            event.target.setCustomValidity("");
        }

        if (this.props.onChange) {
            this.props.onChange(event)
        }
    }


/*    handleChange(event) {
        super.handleChange(event);
    }*/

    render() {
        const props = {};
        props.mask = "99/9999";
        props.maskChar = null;
        //props.pattern = /\d{2}\/\d{4}/;
        props.placeholder = "MM/YYYY";

        return super.render(props);
    }
}