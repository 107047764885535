//#NO-CHANGE-FILE#

import AnsPerfilService from "../../services/AnsPerfilService";
import FormAnsPerfil from "./FormAnsPerfil";
import ListAnsPerfil from "./ListAnsPerfil";
import Permissao from "../../controllers/Permissao";
import Util from "../../utils/Util";
import DadoValorService from "../../services/DadoValorService";

export default class Config {

	static getUrlForm() {
		return "/CadAnsPerfil";
	}

	static getUrlList() {
		return "/LstAnsPerfil";
	}

	static getComponentForm() {
		return FormAnsPerfil;
	}

	static getPermissionForm() {
		return Permissao.getInstance().getPermissoes([61]);
	}

	static getPermissionList() {
		return Permissao.getInstance().getPermissoes([61]);
	}

	static getComponentList() {
		return ListAnsPerfil;
	}

	static getService() {
		return AnsPerfilService;
	}

	static getKeyId() {
		return "idAnsPerfil";
	}

	static getKeyDescription() {
		return "nmAnsPerfil";
	}

	static setDefaultValues(state , fnCallBack = () => {}, className = undefined) {
		className = className === undefined ? "FormAnsPerfil" : className;
		let keyNameScreen = Util.toToUpperTextCamelCase(className, "_") + ".";

		var prefix = "CONFIGURACAO.";
		DadoValorService.getValues(`${prefix}${keyNameScreen}`, (response)=> {
			Util.setValueObjectCamelCase(state.model, response, keyNameScreen, prefix);
			fnCallBack();
		}, fnCallBack);
	}

	static getPermissaoAnsPerfil(ansPerfil){

		if(ansPerfil === undefined){
			ansPerfil = { idAnsPerfil : true };
		}

		return {			
			flPodeEditar : !ansPerfil?.idAnsPerfil || ansPerfil?.ansStatus?.flPodeEditar,
			flPodeAlterarStatus : ansPerfil?.idAnsPerfil && Permissao.getInstance().getPermissoes([123]),
		};
	}
}