import { MarkerType } from "@xyflow/react";


export default class Config {

	static getNodeType(value) {
		switch(value){
			case "TAREFA": return "task";
			case "CONDICIONAL": return "condition";
			case "INICIO": return "start";
			case "FIM": return "end";
			case "GRUPO": return "group";			
			case "ANOTACAO": return "annotation";
			default: return "task";
		}
	}	

	static getTipoFluxoItem(value) {
		switch(value){
			case "task": return 1;
			case "condition": return 2;
			case "start": return 3;
			case "end": return 4;			
			case "group": return 5;
			case "annotation": return 6;
			default: return 1;
		}
	}

	static fluxoItemToNode(fluxoItem){

		let node = {...Config.nodeInit};

		node = {
			...node,
			...{
				id : fluxoItem.idFluxoItem.toString(),
				data: {
					label: fluxoItem?.nmFluxoItem?.toString(),					
				},
				position: {
					x: fluxoItem.nrX,
					y: fluxoItem.nrY,
				}			
			}
		};

		if(fluxoItem?.fluxoItemPai && fluxoItem?.fluxoItemPai?.idFluxoItem){
			node.parentId = fluxoItem?.fluxoItemPai?.idFluxoItem.toString();
			//node.extent = 'parent'; //bloqueia saida do grupo
		}
		

		node.type = Config.getNodeType(fluxoItem.tipoFluxoItem.cdTipoFluxoItem);

		if(fluxoItem.nrTamanho !== undefined && fluxoItem.nrTamanho !== null){
			node.width = fluxoItem.nrTamanho;
		}

		if(fluxoItem.nrAltura !== undefined && fluxoItem.nrAltura !== null){
			node.height = fluxoItem.nrAltura;
		}

		if(fluxoItem.txPropriedade !== undefined){
			let txPropriedade = JSON.parse(fluxoItem.txPropriedade);
			node = {...node, ...txPropriedade};
		}

		return node;
	}

	static fluxoItemDestinoToEdge(fluxoItemDestino){

		let edge = {...Config.edgeInit};

		edge = {
			...edge,
			...{
				source: fluxoItemDestino.fluxoItemPai.idFluxoItem.toString(),
				target: fluxoItemDestino.fluxoItem.idFluxoItem.toString(),
				id: fluxoItemDestino.idFluxoItemDestino.toString(),
				label: fluxoItemDestino?.nmFluxoItemDestino?.toString(),
			}
		};

		if(fluxoItemDestino.txPropriedade !== undefined){
			let txPropriedade = JSON.parse(fluxoItemDestino.txPropriedade);
			edge = {...edge, ...txPropriedade};
		}

		return edge;
	}

	static nodeToFluxoItem(node){

		let tipoFluxoItem = {
			idTipoFluxoItem : Config.getTipoFluxoItem(node.type)
		};

		let fluxoItem =  {
			idFluxoItem : node.id,
			nmFluxoItem : node?.data?.label !== undefined && node?.data?.label ? node?.data?.label : null,
			nrX : node.position.x,
			nrY : node.position.y,
			nrTamanho : node?.width ? node?.width : null,
			nrAltura : node?.height ? node?.height : null,
			txPropriedade : {
				sourcePosition : node.sourcePosition,
				targetPosition : node.targetPosition,
				style : node.style,
				data : node.data
			},
			tipoFluxoItem : tipoFluxoItem
		};

		if(node.parentId !== undefined && node.parentId){
			fluxoItem.fluxoItemPai = {
				idFluxoItem : node.parentId
			};
		}

		return fluxoItem;
	}

	static edgeToFluxoItemDestino(edge){
		return {
			idFluxoItemDestino : edge.id,
			nmFluxoItemDestino : edge?.label !== undefined && edge?.label ? edge?.label : null,
			txPropriedade : {
				type : edge?.type !== undefined && edge?.type ? edge?.type : null,
				sourceHandle : edge.sourceHandle
			},
			fluxoItem : {
				idFluxoItem : edge.target
			},
			fluxoItemPai : {
				idFluxoItem : edge.source
			}
		};
	}

	static nodeInit = {
		sourcePosition: 'bottom',
		targetPosition: 'top',
		type: Config.getNodeType("TAREFA"),
		style: {
			//backgroundColor: "#000000"
		},
	};
	
	static edgeInit = {
		type: 'default', //straight, step, smoothstep, bezier = default
		markerEnd: {
			type: MarkerType.ArrowClosed,
			width: 20,
			height: 20,
			color: '#FF0072',
		},	
		style: {
			//strokeWidth: 2,
			//stroke: '#FF0072',
		},	
	};
}