//#NO-CHANGE-FILE#

import React from "react";
import { useNavigate, useLocation, useParams } from "react-router";
import { BadgeSimNao, BtnCopy, BtnDelete, BtnEdit, BtnNew, StatusCustom } from "../../components/Custom";
import Config from "./Config";
import {ThreeDotsVertical as IconThreeDotsVertical} from "react-bootstrap-icons"
import { IconEdit, IconDelete, IconCopy, IconSearch, IconClear } from "../../icons";
import { BaseList } from "../../siht/base";
import { DataGrid, DataGridButtons, DataGridColumn, Panel, Container, Row, InputGroup, InputGroupText, InputGroupButton, DropDown, DropDownLabel, DropDownItem, TabNavigator, Tab, PanelBody } from "../../siht/components";
import { Button, Div, Form, InputSearch, BtnSubmit, BtnButton } from "../../siht/elements";
import DadoLocalService from "../../services/DadoLocalService";
import DadoLocalGrupoService from "../../services/DadoLocalGrupoService";

class ListDado extends BaseList {

	constructor(props) {
		super(props);
		this.getItens = this.getItens.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleNew = this.handleNew.bind(this);
		this.handleEdit = this.handleEdit.bind(this);
		this.handleCopy = this.handleCopy.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.handleMore = this.handleMore.bind(this);
		this.onSubmitFilter = this.onSubmitFilter.bind(this);
		this.handleClear = this.handleClear.bind(this);
		this.handleFormSuccess = this.handleFormSuccess.bind(this);
		this.handleFormClose = this.handleFormClose.bind(this);
		this.getDadoLocal = this.getDadoLocal.bind(this);

		this.state.dadoLocal = [];
		this.state.dadoLocalGrupo = [];
		this.state.localOpen = BaseList.localOpen.modal;
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		super.init(Config);
		this.getDadoLocal();
		this.getDadoLocalGrupo();
	}

	initStateFilter(){
        return {
            search: "",
            page : 1,
            pageSize : 100,
        };
    }

	getItens(more){
		super.getItens(Config.getService().list, false, more);
	}

	getDadoLocal(){
		DadoLocalService.getForCombo({}, response =>{
			this.setState({dadoLocal : response});
		});
	}

	getDadoLocalGrupo(){
		DadoLocalGrupoService.getForCombo({}, response =>{
			this.setState({dadoLocalGrupo : response});
		});
	}

	handleNew(e, dadoLocalGrupo) {
		super.handleNew(e, Config, {dadoLocalGrupo : dadoLocalGrupo});
	}

	handleEdit(e, item) {
		super.handleEdit(e, Config, item);
	}

	handleDelete(e, item) {
		super.handleDelete(e, Config, item);
	}

	handleCopy(e, item) {
		super.handleCopy(e, Config, item);
	}

	getFormEmbedded(){
		return super.getFormEmbedded(Config);
	}

	render() {

		return super.render (
			<Panel title="Lista de Dado(s)" isModal={this.isModal()} container={{sizing: Container.sizing.fluid, className : ""}}>
				<PanelBody className="p-0 m-0">
					<TabNavigator className="border-light p-0 m-0">
						{this.state.dadoLocal.map((dadoLocal, index) => {

							let dataLocal = this.state.data.filter(i => parseInt(i?.dadoLocalGrupo?.dadoLocal?.idDadoLocal) === parseInt(dadoLocal.idDadoLocal));
							
							return (
								<Tab key={index} title={`${dadoLocal.nmDadoLocal} (${dataLocal.length})`} className="m-0 p-0">
									
									<TabNavigator className="border-light">
										{this.state.dadoLocalGrupo.filter(dlg => parseInt(dadoLocal.idDadoLocal) === parseInt(dlg.dadoLocal.idDadoLocal)).map((gl, ixgl)=> {

											let dataLocalGrupo = dataLocal.filter(item => parseInt(item.dadoLocalGrupo.idDadoLocalGrupo) === parseInt(gl.idDadoLocalGrupo));

											return (
												<Tab key={ixgl} title={`${gl.nmDadoLocalGrupo} (${dataLocalGrupo.length})`} className="m-0 p-2">
													<Form onSubmit={this.onSubmitFilter}>
														<Row className="mt-1 mb-2 g-3">
															<InputGroup className="flex-nowrap" sizing={InputGroup.sizing.default}>
																<InputGroupText><IconSearch/></InputGroupText>
																<InputSearch autoComplete="off" autoFocus placeholder="Digite sua busca" name="search" value={this.state.filter.search} required={false} opcional={true} onChange={(e,v,n) => this.handleChange(e,v,n, this.state.filter)}/>
																<InputGroupButton>
																	<BtnSubmit title="Filtrar"> Buscar </BtnSubmit>
																	<BtnButton title="Limpar Pesquisa" color={BtnButton.color.outlineDanger} onClick={this.handleClear}> <IconClear/> </BtnButton>
																</InputGroupButton>
															</InputGroup>
														</Row>
													</Form>

													<Div className="d-flex justify-content-end flex-nowrap gap-2 pt-1">
														<BtnNew onClick={e => this.handleNew(e, gl)}/>
														<BtnDelete disabled={this.state.selecteds.length === 0} sizing={Button.sizing.default} onClick={e => this.handleDelete(e, this.state.selecteds)}> Excluir </BtnDelete>
													</Div>

													<DataGrid onDoubleClickRow={this.handleEdit} responsive={true} data={dataLocalGrupo} search={false} isDisabled={i => false} sizing={DataGrid.sizing.sm} select={true} selecteds={this.state.selecteds} onSelect={(selecteds)=>{this.setState({selecteds : selecteds})}} getItemValue={i=>i.idDado} onMore={this.handleMore}>
														<DataGridColumn label="Código" field={i => i.idDado} className="text-center"/>
														<DataGridColumn col={3} label="Nome do Dado" field={i => i.nmDado} className="text-start"/>
														
														<DataGridColumn mobile={false} md={2} label="Tipo do Dado" field={i => 
															<React.Fragment> 
																<StatusCustom className="w-100" color={i.tipoDado}> {i.tipoDado.nmTipoDado} </StatusCustom>
															</React.Fragment>
														} className="text-center"/>

														<DataGridColumn col={2} label="Identificador" field={i => i?.cdDado} className="text-start" style={{ fontFamily: "Courier New" }}/>
														<DataGridColumn col={2} label="Local" field={i => i.dadoLocalGrupo?.dadoLocal?.nmDadoLocal} className="text-start"/>
														<DataGridColumn col={2} label="Grupo" field={i => i.dadoLocalGrupo?.nmDadoLocalGrupo} className="text-start"/>
														<DataGridColumn col={1} label="Min." field={i => i.nrMinimoItem} className="text-center"/>
														<DataGridColumn col={1} label="Max." field={i => i.nrMaximoItem} className="text-center"/>
														<DataGridColumn col={1} label="Ativo?" field={i => <BadgeSimNao value={i.flAtivo}/>} className="text-center"/>
														<DataGridButtons>
															<BtnEdit sizing={Button.sizing.default} onClick={this.handleEdit}/>
															<BtnDelete sizing={Button.sizing.default} onClick={this.handleDelete}/>
															<BtnCopy sizing={Button.sizing.default} onClick={this.handleCopy}/>
															<DropDown alignment={DropDown.alignment.end}>
																<DropDownLabel sizing={Button.sizing.default} toggle={false}> <IconThreeDotsVertical/> </DropDownLabel>
																<DropDownItem title="Editar Registro" type="button" onClick={this.handleEdit}> <IconEdit/> Editar </DropDownItem>
																<DropDownItem title="Excluir Registro" type="button" onClick={this.handleDelete}> <IconDelete/> Excluir </DropDownItem>
																<DropDownItem title="Copiar Registro" type="button" onClick={this.handleCopy}> <IconCopy/> Copiar </DropDownItem>
															</DropDown>
														</DataGridButtons>
													</DataGrid>
												</Tab>
												);
									})}
									</TabNavigator>
									
									
								</Tab>
							);
						})}
						
					</TabNavigator>
				</PanelBody>
			</Panel>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <ListDado {...props} navigate={navigate} params={params} location={location}/>
}
export default With