import Http from "../controllers/Http";

export default class PluginFuncionalidadePluginService {

	static urlBase = "/pluginFuncionalidadePlugin/";

	static getForCombo(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(PluginFuncionalidadePluginService.urlBase + 'forCombo', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static list(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(PluginFuncionalidadePluginService.urlBase + 'list', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneById(id, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.get(PluginFuncionalidadePluginService.urlBase + id)
			.then(fnSucess)
			.catch(fnError);
	}

	static create(data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(PluginFuncionalidadePluginService.urlBase, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static update(id, data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.put(PluginFuncionalidadePluginService.urlBase + id, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static delete(id, fnSucess = () => { }, fnError = () => { }) {
		if(Number.isInteger(id)){
			Http.getInstanceAppJson()
				.delete(PluginFuncionalidadePluginService.urlBase + id)
				.then(fnSucess)
				.catch(fnError);
		}else{
			Http.getInstanceAppJson()
			.post(PluginFuncionalidadePluginService.urlBase + 'delete', id)
			.then(fnSucess)
			.catch(fnError);
		}
	}

	static save(data, fnSucess = () => { }, fnError = () => { }) {
		if (data.idPluginFuncionalidadePlugin) {
			this.update(data.idPluginFuncionalidadePlugin, data, fnSucess, fnError);
		} else {
			this.create(data, fnSucess, fnError);
		}
	}

	static getAllByPlugin(plugin, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(PluginFuncionalidadePluginService.urlBase + 'plugin', plugin)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByPlugin(plugin, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(PluginFuncionalidadePluginService.urlBase + 'plugin/' + plugin.idPlugin)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByFuncionalidadePlugin(funcionalidadePlugin, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(PluginFuncionalidadePluginService.urlBase + 'funcionalidadePlugin', funcionalidadePlugin)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByFuncionalidadePlugin(funcionalidadePlugin, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(PluginFuncionalidadePluginService.urlBase + 'funcionalidadePlugin/' + funcionalidadePlugin.idFuncionalidadePlugin)
			.then(fnSucess)
			.catch(fnError);
	}
}