export default class Formatter {
    static cep(cep) {
        if (cep) {
            cep = cep.toString();
            cep = cep.replace(/[^\d]/g, "");
            return cep.replace(/(\d{5})(\d{3})/, "$1-$2");
        }
        return cep;

    }

    static cpf(cpf) {
        if (cpf) {
            cpf = cpf.toString();
            cpf = cpf.replace(/[^\d]/g, "");
            return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
        }
        return cpf;

    }

    static cnpj(cnpj) {
        if (cnpj) {
            cnpj = cnpj.toString();
            cnpj = cnpj.replace(/[^\d]/g, "");
            return cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
        }
        return cnpj;
    }

    static floatToReais(value) {
        if (value) {
            value = parseFloat(value);
        } else {
            value = parseFloat(0);
        }

        return value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
    }

    static floatToPeso(value) {
        if (value) {
            value = parseFloat(value);
        } else {
            value = parseFloat(0);
        }

        return value.toLocaleString('pt-br', { minimumFractionDigits: 3 });
    }

    static floatToValor(value, nrCasasDecimais = 2) {
        if (value) {
            value = parseFloat(value);
        } else {
            value = parseFloat(0);
        }

        return value.toLocaleString('pt-br', { minimumFractionDigits: nrCasasDecimais });
    }

    static stringToNumber(value, er = /[^0-9]/g) {
        if (value) {
            value = value.toString().replace(er, '');
        }
        return value;
    }

    static dateFormatYYYYMM(date){
        var yyyy = date.getFullYear();
        var mm = date.getMonth() + 1;
        mm = mm < 10 ? '0' + mm.toString() : mm;
        return yyyy + "-" + mm;
    }

    static dateFormatYYYYMMDD(date){
        return date.toISOString().slice(0, 10);
    }

    static formatBytes(bytes, decimals = 2) {
        if (!+bytes) return '0 Bytes'
    
        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    
        const i = Math.floor(Math.log(bytes) / Math.log(k))
    
        return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
    }

    static nl2br (str, is_xhtml = true) {
        if (typeof str === 'undefined' || str === null) {
            return '';
        }
        var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
        return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
    }
}