import { Component } from "react";

export default class Badge extends Component {

    static color = {
        primary : "primary",
        secondary : "secondary",
        success : "success",
        danger : "danger",
        warning : "warning",
        info : "info",
        light : "light",
        dark : "dark",
        default : "primary"
    };

    render() {
        if(this.props.show !== undefined && !Boolean(this.props.show)){
            return (<></>);
        }

        const props = Object.assign({}, this.props);
        props.color = props.color !== undefined ? props.color : Badge.color.default;
        props.noTextBgColor = props.noTextBgColor !== undefined ? props.noTextBgColor : false;
        props.className = `badge ${props.className !== undefined ? props.className : ""}`;

        if(!props.noTextBgColor){
            props.className += ` text-bg-${props.color} ${props.className !== undefined ? props.className : ""}`;
        }

        delete props.color;
        delete props.show;
        delete props.noTextBgColor;
        delete props.opcional;

        return (
            <span {...props}> {this.props.children} </span>
        );
    }
}