//#NO-CHANGE-FILE#

import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import SelectTipoBloco from "../../components/SelectDB/SelectTipoBloco";
import SelectModeloBloco from "../../components/SelectDB/SelectModeloBloco";
import SelectTipoComando from "../../components/SelectDB/SelectTipoComando";
import BtnRadioGroupSimNao from "../../components/BtnRadioGroupSimNao";
import { BaseCrud } from "../../siht/base";
import { FormButtons, FormItem, Row, Panel, PanelBody, PanelFooter, Container, ModalDialog, TabNavigator, Tab } from "../../siht/components";
import { InputText, TextArea, Form, InputNumber } from "../../siht/elements";
import Config from "./Config";
import Util from "../../utils/Util";
import Bloco from "../Painel/Bloco";
import SelectTipoFiltro from "../../components/SelectDB/SelectTipoFiltro";

class FormBloco extends BaseCrud {

	constructor(props) {
		super(props);
		this.init = this.init.bind(this);
		this.handleSuccess = this.handleSuccess.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleChangeModel = this.handleChangeModel.bind(this);
		this.handleSubmitForm = this.handleSubmitForm.bind(this);

		this.state.model = {
			idBloco : null,
			nmBloco : "",
			txDetalhe : "",
			painel : this.props.parent !== undefined ? this.props.parent : {},
			nrTempoAtualizar : 3600,
			nrTamanhoColuna : 3,
			tipoBloco : null,
			modeloBloco : null,
			txConfiguracao : "{}",
			tipoComando : null,
			txAgrupador : "",
			txComando : "",
			flAtivo : true,
			nrOrdem : "",
			tipoFiltro : null,
			nrIntervaloFiltro : 0
		};
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		super.init(Config);
	}

	handleSuccess(e) {
		super.handleSuccess(e, Config);
	}

	handleClose(e) {
		super.handleClose(e, Config);
	}

	render() {
		return super.render(
			<Form onSubmit={this.handleSuccess} refParam={f => (this.form = f)} key={this.state.key}>
				<Panel key={this.state.key} title="Cadastro de Bloco" onClose={this.handleClose} isModal={this.isModal()} container={{sizing: Container.sizing.xxl, className : ""}} modal={{sizing: this.props.sizing ? this.props.sizing : ModalDialog.sizing.xl}}>
					<PanelBody className="p-0 m-0">
						<TabNavigator onChangeTab={this.handleChangeTab} className="border-light">
							<Tab title="Bloco" className="m-2">
								<Row className="g-3 py-2 px-2">
									<FormItem md={2} label="Código">
										<InputText name="idBloco" value={this.state.model.idBloco} readOnly={true} className="readonly-field" required={false} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={4} label="Nome da Bloco">
										<InputText name="nmBloco" value={this.state.model.nmBloco} autoFocus={true} maxLength={100} required={true} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={2} label="Atualização">
										<InputNumber min={10} step={1} name="nrTempoAtualizar" value={this.state.model.nrTempoAtualizar}  required={true} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={2} label="Tamanho">
										<InputNumber min={1} max={12} step={1}  name="nrTamanhoColuna" value={this.state.model.nrTamanhoColuna}  required={true} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={2} label="Ativo?">
										<BtnRadioGroupSimNao name="flAtivo" value={this.state.model.flAtivo} required={true} onChange={this.handleChangeModel}/>
									</FormItem>

									<FormItem md={12} label="Detalhe">
										<TextArea name="txDetalhe" value={this.state.model.txDetalhe} required={false} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={6} label="Tipo do Bloco">
										<SelectTipoBloco name="tipoBloco" value={this.state.model.tipoBloco} required={true} onChange={this.handleChangeModel} placeholder="Selecione Nome do TipoBloco"/>
									</FormItem>
									<FormItem md={6} label="Modelo do Bloco">
										<SelectModeloBloco getOptionsWhen={f => f?.tipoBloco?.idTipoBloco !== undefined}  filter={{tipoBloco : this.state.model.tipoBloco}} name="modeloBloco" value={this.state.model.modeloBloco} required={false} onChange={this.handleChangeModel} placeholder="Selecione o Modelo do Bloco"/>
									</FormItem>
								</Row>
							</Tab>
							<Tab title="Configuração" className="m-2">
								<Row className="g-3 py-2 px-2">
									<FormItem md={12} label="Configuração">
										<TextArea name="txConfiguracao" value={this.state.model.txConfiguracao} required={true} onChange={this.handleChangeModel} className="text-bg-dark" style={{ height: "400px", fontFamily: "Courier New" }}/>
									</FormItem>
								</Row>
								{!Util.isJsonString(this.state.model.txConfiguracao) ? <div className="alert alert-danger pt-1 pb-1" role="alert"> JSON Inválido </div> : <></>}
							</Tab>
							<Tab title="Comando" className="m-2">
								<Row className="g-3 py-2 px-2">
									<FormItem md={4} label="Tipo de Comando">
										<SelectTipoComando name="tipoComando" value={this.state.model.tipoComando} required={true} onChange={this.handleChangeModel} placeholder="Selecione o Tipo" buttons={{search : false, new : false, edit: false}}/>
									</FormItem>
									<FormItem md={6} label="Tipo de Filtro">
										<SelectTipoFiltro name="tipoFiltro" value={this.state.model.tipoFiltro} required={true} onChange={this.handleChangeModel} placeholder="Selecione Tipo de Filtro" buttons={{search : false, new : false, edit: false}}/>
									</FormItem>
									<FormItem md={2} label="Intervalo" show={this.state.model.tipoFiltro}>
										<InputNumber name="nrIntervaloFiltro" value={this.state.model.nrIntervaloFiltro} required={true} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={12} label="Agrupador">
										<TextArea name="txAgrupador" value={this.state.model.txAgrupador} required={false} onChange={this.handleChangeModel} className="text-bg-dark" style={{ height: "100px", fontFamily: "Courier New" }}/>
									</FormItem>
									<FormItem md={12} label="Comando">
										<TextArea name="txComando" value={this.state.model.txComando} required={true} onChange={this.handleChangeModel} className="text-bg-dark" style={{ height: "300px", fontFamily: "Courier New" }}/>
									</FormItem>
								</Row>
							</Tab>
							<Tab title="Resultado" className="m-2" show={this.state.model.idBloco}>
								<Bloco idBloco={this.state.model.idBloco} play={true} forceShow={true} buttons={false}/>
							</Tab>				
						</TabNavigator>
					</PanelBody>
					<PanelFooter>
						<FormButtons onClose={this.handleClose} />
					</PanelFooter>
				</Panel>
			</Form>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <FormBloco {...props} navigate={navigate} params={params} location={location}/>
}
export default With