
import FormOrdemEndereco from "./FormOrdemEndereco.js";
import ListOrdemEndereco from "./ListOrdemEndereco.js";
import { Permissao } from "../../controllers";
import { Util } from "../../utils";
import { DadoValorService, OrdemEnderecoService } from "../../services";

export default class Config {

	static getUrlForm() {
		return "/CadOrdemEndereco";
	}

	static getUrlList() {
		return "/LstOrdemEndereco";
	}

	static getComponentForm() {
		return FormOrdemEndereco;
	}

	static getPermissionForm() {
		return Permissao.getInstance().getPermissoes([136]);
	}

	static getPermissionList() {
		return Permissao.getInstance().getPermissoes([136]);
	}

	static getComponentList() {
		return ListOrdemEndereco;
	}

	static getService() {
		return OrdemEnderecoService;
	}

	static getKeyId() {
		return "idOrdemEndereco";
	}

	static getKeyDescription() {
		return "nmOrdemEndereco";
	}

	static setDefaultValues(state , fnCallBack = () => {}, className = undefined) {
		className = className === undefined ? "FormOrdemEndereco" : className;
		let keyNameScreen = Util.toToUpperTextCamelCase(className, "_") + ".";
		var prefix = "CONFIGURACAO.";
		DadoValorService.getValues(`${prefix}${keyNameScreen}`, (response)=> {
			Util.setValueObjectCamelCase(state.model, response, keyNameScreen, prefix);
			fnCallBack();
		}, fnCallBack);
	}
}