//#NO-CHANGE-FILE#

import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import SelectTipoTelefone from "../../components/SelectDB/SelectTipoTelefone";
import SelectPais from "../../components/SelectDB/SelectPais";
import ManyToManyCheckPessoaTelefoneTipoContatoByTipoContato from "./ManyToManyCheckPessoaTelefoneTipoContatoByTipoContato";
import Permissao from "../../controllers/Permissao";
import { BaseCrud } from "../../siht/base";
import { FormButtons, FormItem, Row, TabNavigator, Tab, Panel, PanelBody, PanelFooter, Container } from "../../siht/components";
import { InputText, InputTelefone, Form, BtnSubmit } from "../../siht/elements";
import Config from "./Config";

class FormPessoaTelefone extends BaseCrud {

	constructor(props) {
		super(props);
		this.init = this.init.bind(this);
		this.handleSuccess = this.handleSuccess.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleChangeModel = this.handleChangeModel.bind(this);
		this.handleSubmitForm = this.handleSubmitForm.bind(this);

		this.state.model = {
			idPessoaTelefone : null,
			pessoa : this.props.parent !== undefined ? this.props.parent : {},
			tipoTelefone : null,
			pais : null,
			nrTelefone : "",
			pessoaTelefoneTipoContato : []
		};
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		super.init(Config);
	}

	handleSuccess(e) {
		super.handleSuccess(e, Config);
	}

	handleClose(e) {
		super.handleClose(e, Config);
	}

	render() {
		return super.render(

					<Panel key={this.state.key} title="Cadastro de PessoaTelefone" onClose={this.handleClose} isModal={this.isModal()} container={{sizing: Container.sizing.xxl, className : ""}}>
						<PanelBody className="p-0 m-0">
							<TabNavigator key={this.state.key} onChangeTab={this.handleChangeTab} className="border-light">
								<Tab title="Pessoa Telefone" className="m-2">
									<Form onSubmit={this.handleSuccess} refParam={f => (this.form = f)}>
										<Row className="g-3 pb-2 px-2">
											<FormItem md={4} label="Código">
												<InputText name="idPessoaTelefone" value={this.state.model.idPessoaTelefone} readOnly={true} className="readonly-field" required={false} onChange={this.handleChangeModel}/>
											</FormItem>
											<FormItem md={8} label="Tipo de Telefone">
												<SelectTipoTelefone name="tipoTelefone" value={this.state.model.tipoTelefone} required={true} onChange={this.handleChangeModel} placeholder="Selecione o Tipo de Telefone" buttons={{search : false, new : false, edit: false}}/>
											</FormItem>
											<FormItem md={6} label="Nome do Pais">
												<SelectPais showDDI={true} name="pais" value={this.state.model.pais} required={true} onChange={this.handleChangeModel} placeholder="Selecione Nome do Pais" />
											</FormItem>
											<FormItem md={6} label="Telefone">
												<InputTelefone name="nrTelefone" value={this.state.model.nrTelefone} maxLength={100} required={true} onChange={this.handleChangeModel}/>
											</FormItem>
											<BtnSubmit hidden > Enviar </BtnSubmit>
										</Row>
									</Form>
								</Tab>
								<Tab show={Permissao.getInstance().getPermissoes([37])} title="Tipo de Contato" disabled={!this.state.model.idPessoaTelefone} opened={false} className="m-2">
									<ManyToManyCheckPessoaTelefoneTipoContatoByTipoContato typeCheck={ManyToManyCheckPessoaTelefoneTipoContatoByTipoContato.typeCheck.box} parent={this.state.model} search={true}/>
								</Tab>
							</TabNavigator>
						</PanelBody>
						<PanelFooter>
							<FormButtons show={this.state.tabIndex === 0} onClose={this.handleClose} onSubmit={this.handleSubmitForm}/>
						</PanelFooter>
					</Panel>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <FormPessoaTelefone {...props} navigate={navigate} params={params} location={location}/>
}
export default With