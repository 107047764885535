//#NO-CHANGE-FILE#

import Config from "./Config";
import React from "react";
import { useNavigate, useLocation, useParams } from "react-router";
import { BaseList } from "../../siht/base";
import { DataGrid, DataGridColumn, DataGridFooter } from "../../siht/components";
import { BtnLink, Div, Span } from "../../siht/elements";
import { Util } from "../../utils";
import { Modal } from "../../siht/controller";
import FormContaPagar from "../ContaPagar/FormContaPagar";
import { StatusCustom } from "../../components/Custom";

class ListComissaoPessoa extends BaseList {

	constructor(props) {
		super(props);
		this.init = this.init.bind(this);
		this.getItens = this.getItens.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleNew = this.handleNew.bind(this);
		this.handleEdit = this.handleEdit.bind(this);
		this.handleCopy = this.handleCopy.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.handleMore = this.handleMore.bind(this);
		this.onSubmitFilter = this.onSubmitFilter.bind(this);
		this.handleClear = this.handleClear.bind(this);
		this.handleFormSuccess = this.handleFormSuccess.bind(this);
		this.handleFormClose = this.handleFormClose.bind(this);
		this.getTotalValor = this.getTotalValor.bind(this);
		
		this.handleViewContaPagar = this.handleViewContaPagar.bind(this);
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		super.init(Config);
	}

	getItens(more){
		if(this.props?.parent){
			super.getItens(Config.getService().getAllByComissao, this.props.parent, more);
		}
	}

	handleNew(e) {
		super.handleNew(e, Config);
	}

	handleEdit(e, item) {
		super.handleEdit(e, Config, item);
	}

	handleDelete(e, item) {
		super.handleDelete(e, Config, item);
	}

	handleCopy(e, item) {
		super.handleCopy(e, Config, item);
	}

	getFormEmbedded(){
		return super.getFormEmbedded(Config);
	}

	getTotalValor(){
		let total = 0;
		if(this.state.selecteds.length === 0){
			total = this.state.data.reduce((soma, item) => soma + item.vlValor, 0);
		}else{
			total = this.state.selecteds.reduce((soma, item) => soma + item.vlValor, 0);
		}

		return total;
	}

	handleViewContaPagar (e, contaPagar) {
		Modal.openWindow(FormContaPagar, {sizing : Modal.sizing.xl}, { id : contaPagar.idContaPagar }, this.init, this.init);
	}

	render() {
		let editable = this.props.editable !== undefined ? this.props.editable : true;
		
		return super.render(
			<Div className="p-2 mt-2">
				<DataGrid onDoubleClickRow={editable ? this.handleEdit : ()=>{}} responsive={true} data={this.state.data} search={true} isDisabled={i => false} sizing={DataGrid.sizing.default} select={false} selecteds={this.state.selecteds} onSelect={(selecteds)=>{this.setState({selecteds : selecteds})}} getItemValue={i=>i.idComissaoPessoa} onMore={this.handleMore}>
					<DataGridColumn col={6} label="Pessoa" field={i => <React.Fragment>
						<Div className="fw-bold">{i.pessoa?.nmPessoa}</Div>
						<StatusCustom color={i.pessoa?.tipoPessoa}> PESSOA {i.pessoa?.tipoPessoa?.nmTipoPessoa} </StatusCustom>
						<Div className="fw-bold fs-7" show={i.pessoa?.tipoPessoa?.cdTipoPessoa === "F"}>{Util.formataCPF(i.pessoa?.nrCpf)}</Div>
						<Div className="fw-bold fs-7" show={i.pessoa?.tipoPessoa?.cdTipoPessoa === "J"}>{Util.formataCnpj(i.pessoa?.nrCnpj)}</Div>
					</React.Fragment>} className="text-start"/>

					<DataGridColumn col={4} label="Empresa / Unidade" field={i => <React.Fragment>
						<Div className="fw-bold">{i.unidade?.empresa?.nmEmpresa}</Div>
						<Div>{i.unidade?.nmUnidade} </Div>
					</React.Fragment>} className="text-start"/>
					
					<DataGridColumn col={1} label="Conta" field={i => <>
						<BtnLink onClick={(e) => this.handleViewContaPagar(e, i.contaPagarComissaoPessoa?.contaPagar)}> {i.contaPagarComissaoPessoa?.contaPagar?.idContaPagar} </BtnLink>
					</>} className="text-start"/>

					<DataGridColumn label="Status" field={i =>
						<StatusCustom className="w-100" color={i?.contaPagarComissaoPessoa?.contaPagar?.vwContaPagar?.statusConta}> {i?.contaPagarComissaoPessoa?.contaPagar?.vwContaPagar?.statusConta?.nmStatusConta} </StatusCustom> 
					} className="text-center"/>

					<DataGridColumn col={1} label="Comissão" field={i => Util.floatToReais(i.vlValor)} className="text-end fw-bold text-success"/>

					<DataGridFooter>
						<DataGridColumn colSpan={4} className="text-center"/>
						<DataGridColumn label="Valor"  className="text-end">
							<Span className="fw-bold text-success"> {Util.floatToReais(this.getTotalValor())} </Span>
						</DataGridColumn>
					</DataGridFooter>
				</DataGrid>
			</Div>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <ListComissaoPessoa {...props} navigate={navigate} params={params} location={location}/>
}
export default With