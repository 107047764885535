import { Functions } from "../util";
import InputMask from "./InputMask";

export default class InputTelefone extends InputMask {

    constructor(props) {
        super(props);
        this.modify = this.modify.bind(this);
        this.modify(props);
    }

    modify({ value }) {

        let replacement = { _: /\d/ };

        if (Functions.stringToNumber(value).length >= 10) {
            this.options = {
                mask: "(__)_____-____",
                replacement: replacement,
            };      
        } else {
            this.options = {
                mask: "(__)____-____",
                replacement: replacement,
            };      
        }
        return this.options;
    }

    render() {
        const props = {};
        props.placeholder = this.options.mask;
        props.modify = this.modify;
        return super.render(props);
    }
}