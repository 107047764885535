import { Component } from "react";
import { BtnRadio } from "../siht/elements";
import { BtnRadioGroup } from "../siht/components";

export default class BtnRadioGroupSimNao extends Component {


    render() {
        if(this.props.show !== undefined && !Boolean(this.props.show)){
            return (<></>);
        }

        const props = Object.assign({}, this.props);

        let label = this.props.label !== undefined ? this.props.label : ["Sim", "Não"];
        let color = this.props.color !== undefined ? this.props.color : [ BtnRadio.color.success, BtnRadio.color.danger ];
        
        return (
            <BtnRadioGroup {...props}>
                <BtnRadio value={true} label={label[0]} color={color[0]} />
                <BtnRadio value={false} label={label[1]} color={color[1]}/>
            </BtnRadioGroup>
        );
    }
}