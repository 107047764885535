//#NO-CHANGE-FILE#

import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import SelectBanco from "../../components/SelectDB/SelectBanco";
import ListContaTipoPagamento from "../ContaTipoPagamento/ListContaTipoPagamento";
import Permissao from "../../controllers/Permissao";
import { BaseList, BaseCrud } from "../../siht/base";
import { FormButtons, FormItem, Row, TabNavigator, Tab, Panel, PanelBody, PanelFooter, Container } from "../../siht/components";
import { InputText, Form, BtnSubmit } from "../../siht/elements";
import Config from "./Config";
import OneToOneFormEndereco from "../Endereco/OneToOneFormEndereco";
import ListDadoValorByParent from "../DadoValor/ListDadoValorByParent";
import { FormItemEmpresaUnidade } from "../../components/FormItem";
import ListDadoValorByParentForm from "../DadoValor/ListDadoValorByParentForm";
import { PatchPlus as IconPatchPlus} from "react-bootstrap-icons";

class FormConta extends BaseCrud {

	constructor(props) {
		super(props);
		this.init = this.init.bind(this);
		this.handleSuccess = this.handleSuccess.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleChangeModel = this.handleChangeModel.bind(this);
		this.handleSubmitForm = this.handleSubmitForm.bind(this);

		this.state.model = {
			idConta : null,
			nmConta : "",
			unidade : null,
			banco : null,
			endereco : null,
			nrAgencia : "",
			nrDigitoAgencia : null,
			nrConta : "",
			nrDigitoConta : null,
			contaTipoPagamento : [],
			contaDadoValor : []
		};
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		super.init(Config);
	}

	handleSuccess(e) {
		super.handleSuccess(e, Config);
	}

	handleClose(e) {
		super.handleClose(e, Config);
	}

	render() {
		return super.render(

					<Panel key={this.state.key} title="Cadastro de Conta" onClose={this.handleClose} isModal={this.isModal()} container={{sizing: Container.sizing.xxl, className : ""}}>
						<PanelBody className="p-0 m-0">
							<TabNavigator key={this.state.key} onChangeTab={this.handleChangeTab} className="border-light">
								<Tab title="Conta" className="m-2">
									<Form onSubmit={this.handleSuccess} refParam={f => (this.form = f)}>
										<Row className="g-3 pb-2 px-2">
											<FormItem md={3} label="Código">
												<InputText name="idConta" value={this.state.model.idConta} readOnly={true} className="readonly-field" required={false} onChange={this.handleChangeModel}/>
											</FormItem>
											<FormItem md={9} label="Nome do Banco">
												<SelectBanco name="banco" value={this.state.model.banco} required={true} onChange={this.handleChangeModel} placeholder="Selecione o Banco"/>
											</FormItem>

											<FormItemEmpresaUnidade  md={[6,6]} name="unidade" value={this.state.model.unidade} required={true} onChange={this.handleChangeModel}/>

											<FormItem md={6} label="Nome da Conta">
												<InputText name="nmConta" value={this.state.model.nmConta} autoFocus={true} maxLength={100} required={true} onChange={this.handleChangeModel}/>
											</FormItem>
											<FormItem md={2} label="Agência">
												<InputText name="nrAgencia" value={this.state.model.nrAgencia} maxLength={100} required={true} onChange={this.handleChangeModel}/>
											</FormItem>
											<FormItem md={1} label="Dígito">
												<InputText name="nrDigitoAgencia" value={this.state.model.nrDigitoAgencia} maxLength={100} required={false} onChange={this.handleChangeModel} opcional={true}/>
											</FormItem>
											<FormItem md={2} label="Conta">
												<InputText name="nrConta" value={this.state.model.nrConta} maxLength={100} required={true} onChange={this.handleChangeModel}/>
											</FormItem>
											<FormItem md={1} label="Dígito">
												<InputText name="nrDigitoConta" value={this.state.model.nrDigitoConta} maxLength={100} required={false} onChange={this.handleChangeModel} opcional={true}/>
											</FormItem>
											<FormItem md={12} label="Endereço">
												<OneToOneFormEndereco flSomenteCidade={true} name="endereco" value={this.state.model.endereco} required={true} onChange={this.handleChangeModel} placeholder="Informe o Endereço"/>
											</FormItem>

											<ListDadoValorByParentForm cdDadoLocal="CONTA" parent={this.state.model} onChange={this.handleChangeModel} name="contaDadoValor"/>

											<BtnSubmit hidden > Enviar </BtnSubmit>
										</Row>
									</Form>
								</Tab>
								<Tab show={Permissao.getInstance().getPermissoes([90])} title="Tipo Pagamento" disabled={!this.state.model.idConta} opened={false} className="m-2">
									<ListContaTipoPagamento parent={this.state.model} localOpen={BaseList.localOpen.modal}/>
								</Tab>
								<Tab show={Permissao.getInstance().getPermissoes([145])} title={<><IconPatchPlus/> Complementar</>} disabled={!this.state.model.idConta} opened={false} className="p-0 m-0">
									<ListDadoValorByParent cdDadoLocal="CONTA" parent={this.state.model} localOpen={BaseList.localOpen.modal}/>
								</Tab>
							</TabNavigator>
						</PanelBody>
						<PanelFooter>
							<FormButtons show={this.state.tabIndex === 0} onClose={this.handleClose} onSubmit={this.handleSubmitForm}/>
						</PanelFooter>
					</Panel>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <FormConta {...props} navigate={navigate} params={params} location={location}/>
}
export default With