import { BaseComponent } from "../siht/base";

export default class IconSearch extends BaseComponent {

	render() {

		if(this.props.show !== undefined && !Boolean(this.props.show)){
			return (<></>);
		}

		const props = Object.assign({}, this.props);
		props.xmlns = "http://www.w3.org/2000/svg";
		props.width = props.width === undefined ? 16 : props.width;
		props.height = props.height === undefined ? 16 : props.height;
		props.fill = "currentColor";
		props.viewBox=`0 0 16 16`

		return (
			<svg {...props}>
			  <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0"/>
			</svg>
		);
	}
}