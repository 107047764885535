//#NO-CHANGE-FILE#

import Http from "../controllers/Http";

export default class VeiculoService {

	static urlBase = "/veiculo/";

	static listMapa(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(VeiculoService.urlBase + 'listMapa', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static getForCombo(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(VeiculoService.urlBase + 'forCombo', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static list(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(VeiculoService.urlBase + 'list', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneById(id, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.get(VeiculoService.urlBase + id)
			.then(fnSucess)
			.catch(fnError);
	}

	static create(data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(VeiculoService.urlBase, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static update(id, data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.put(VeiculoService.urlBase + id, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static delete(id, fnSucess = () => { }, fnError = () => { }) {
		if(Number.isInteger(id)){
			Http.getInstanceAppJson()
				.delete(VeiculoService.urlBase + id)
				.then(fnSucess)
				.catch(fnError);
		}else{
			Http.getInstanceAppJson()
			.post(VeiculoService.urlBase + 'delete', id)
			.then(fnSucess)
			.catch(fnError);
		}
	}

	static save(data, fnSucess = () => { }, fnError = () => { }) {
		if (data.idVeiculo) {
			this.update(data.idVeiculo, data, fnSucess, fnError);
		} else {
			this.create(data, fnSucess, fnError);
		}
	}

	static getAllByLocalizacao(localizacao, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(VeiculoService.urlBase + 'localizacao', localizacao)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByLocalizacao(localizacao, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(VeiculoService.urlBase + 'localizacao/' + localizacao.idLocalizacao)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByTipoVeiculo(tipoVeiculo, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(VeiculoService.urlBase + 'tipoVeiculo', tipoVeiculo)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByTipoVeiculo(tipoVeiculo, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(VeiculoService.urlBase + 'tipoVeiculo/' + tipoVeiculo.idTipoVeiculo)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByModelo(modelo, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(VeiculoService.urlBase + 'modelo', modelo)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByModelo(modelo, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(VeiculoService.urlBase + 'modelo/' + modelo.idModelo)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByTransmissao(transmissao, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(VeiculoService.urlBase + 'transmissao', transmissao)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByTransmissao(transmissao, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(VeiculoService.urlBase + 'transmissao/' + transmissao.idTransmissao)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByVeiculoPlaca(veiculoPlaca, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(VeiculoService.urlBase + 'veiculoPlaca', veiculoPlaca)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByVeiculoPlaca(veiculoPlaca, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(VeiculoService.urlBase + 'veiculoPlaca/' + veiculoPlaca.idVeiculoPlaca)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByVeiculoOdometro(veiculoOdometro, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(VeiculoService.urlBase + 'veiculoOdometro', veiculoOdometro)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByVeiculoOdometro(veiculoOdometro, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(VeiculoService.urlBase + 'veiculoOdometro/' + veiculoOdometro.idVeiculoOdometro)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByVeiculoLocalizacao(veiculoLocalizacao, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(VeiculoService.urlBase + 'veiculoLocalizacao', veiculoLocalizacao)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByVeiculoLocalizacao(veiculoLocalizacao, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(VeiculoService.urlBase + 'veiculoLocalizacao/' + veiculoLocalizacao.idVeiculoLocalizacao)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByVeiculoCombustivel(veiculoCombustivel, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(VeiculoService.urlBase + 'veiculoCombustivel', veiculoCombustivel)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByVeiculoCombustivel(veiculoCombustivel, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(VeiculoService.urlBase + 'veiculoCombustivel/' + veiculoCombustivel.idVeiculoCombustivel)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByVeiculoArquivo(veiculoArquivo, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(VeiculoService.urlBase + 'veiculoArquivo', veiculoArquivo)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByVeiculoArquivo(veiculoArquivo, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(VeiculoService.urlBase + 'veiculoArquivo/' + veiculoArquivo.idVeiculoArquivo)
			.then(fnSucess)
			.catch(fnError);
	}
}