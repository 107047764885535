import Http from "../controllers/Http";

export default class VwFaturaService {

	static urlBase = "/vwFatura/";

	static getForCombo(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(VwFaturaService.urlBase + 'forCombo', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static list(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(VwFaturaService.urlBase + 'list', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneById(id, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.get(VwFaturaService.urlBase + id)
			.then(fnSucess)
			.catch(fnError);
	}


	static getAllByFatura(fatura, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(VwFaturaService.urlBase + 'fatura', fatura)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByFatura(fatura, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(VwFaturaService.urlBase + 'fatura/' + fatura.idFatura)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByUnidade(unidade, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(VwFaturaService.urlBase + 'unidade', unidade)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByUnidade(unidade, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(VwFaturaService.urlBase + 'unidade/' + unidade.idUnidade)
			.then(fnSucess)
			.catch(fnError);
	}
}