import { Component } from "react";
import {BtnButton, Button} from "../siht/elements";
import { IconDownload } from "../icons";
import ConfigApp from "../ConfigApp";

export default class BtnDownload extends Component {

    render() {
        if(this.props.show !== undefined && !Boolean(this.props.show)){
            return (<></>);
        }

        const props = Object.assign({}, this.props);
        props.className = `${props.className !== undefined ? props.className : ""} text-nowrap`;
        props.title="Download";
        props.color = Button.color.outlineDark;
        let icon = this.props.icon ? this.props.icon : <IconDownload/>;

        delete props.show;
        delete props.icon;
        
        let arquivo = null;
        if(this.props.arquivo !== undefined){
            arquivo = this.props.arquivo;
        }else if(this.props.item !== undefined && this.props.item.arquivo !== undefined){
            arquivo = this.props.item.arquivo;
        }

        if(arquivo){
            let url = ConfigApp.getUrlArquivo() + "public/download/" + arquivo.cdUid + "/" + arquivo.arquivoBase?.cdHash;
            return (
                <a {...props} rel="opener" className={`btn btn-${props.color}`} href={url}>
                    {icon}
                </a>
            );
        } else {
            props.disabled = true;
            return (
                <BtnButton {...props}>
                    {icon} {this.props.children}                
                </BtnButton>
            );
        }
    }
}