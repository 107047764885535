//#NO-CHANGE-FILE#

import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { BaseCrud } from "../../siht/base";
import { FormItem, Row, InputGroup, InputGroupButton } from "../../siht/components";
import { TextArea, Div, Form, BtnSubmit, Button, BtnButton } from "../../siht/elements";
import Config from "./Config";
import { Modal } from "../../siht/controller";
import FormUpload from "../FormUpload";
import { SendFill as IconSendFill, Paperclip as IconPaperclip} from "react-bootstrap-icons";

class FormOrdemComentario extends BaseCrud {

	constructor(props) {
		super(props);
		this.init = this.init.bind(this);
		this.handleSuccess = this.handleSuccess.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleChangeModel = this.handleChangeModel.bind(this);
		this.handleSubmitForm = this.handleSubmitForm.bind(this);
		this.handleArquivo = this.handleArquivo.bind(this);

		this.state.model = {
			idOrdemComentario : null,
			ordem : this.props.parent !== undefined ? this.props.parent : {},
			usuario : null,
			txComentario : "",
			flPrivado : false
		};
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		super.init(Config);
	}

	handleSuccess(e) {
		Config.getService().save(this.state.model, (response) => {
			this.setModel({txComentario : ""}, () => {
                if (this.props.handleSuccess !== undefined) {
                    this.props.handleSuccess(e, response);
                }
                this.setState({key : Math.random()})
			});
		});
	}

	handleClose(e) {
		super.handleClose(e, Config);
	}

	handleArquivo() {
		Modal.openWindow(FormUpload, {sizing : Modal.sizing.default}, {  }, (e, response) => { 
			var comentarios =[];
			response.forEach(arquivo =>{
				comentarios.push({
					ordem : this.props.parent, 
					txComentario : 
					arquivo.nmArquivo , 
					arquivo : arquivo
				});
			});

			Config.getService().save(comentarios, () =>{
				if (this.props.handleSuccess !== undefined) {
					this.props.handleSuccess(e, response);
				}
			});
		});
	}

	render() {
		return super.render(
				<Div key={this.state.key}>
					<Form onSubmit={this.handleSuccess} refParam={f => (this.form = f)}>
						<Row className="g-3 pb-2 px-2">
							<FormItem md={12}>
								<InputGroup>
									<TextArea autoFocus style={{resize : "none"}} placeholder="Digite seu comentário aqui" name="txComentario" value={this.state.model.txComentario} required={true} onChange={this.handleChangeModel}/>
									<InputGroupButton>
										<BtnSubmit sizing={Button.sizing.sm} > <IconSendFill/> </BtnSubmit>
										<BtnButton sizing={Button.sizing.sm} onClick={this.handleArquivo} color={BtnButton.color.outlinePrimary}> <IconPaperclip/> </BtnButton>
									</InputGroupButton>
								</InputGroup>
							</FormItem>
						</Row>
						
					</Form>
				</Div>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <FormOrdemComentario {...props} navigate={navigate} params={params} location={location}/>
}
export default With