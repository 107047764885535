import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { BaseCrud } from "../../siht/base";
import { FormButtons, FormItem, Row, Panel, PanelBody, PanelFooter, Container, ModalDialog, BtnRadioGroup } from "../../siht/components";
import { Form, InputCheckSwitch, BtnRadio } from "../../siht/elements";
import Permissao from "../../controllers/Permissao";
import Util from "../../utils/Util";
import ConfigApp from "../../ConfigApp";

class FormRelatorioOrdem002 extends BaseCrud {

    constructor(props) {
        super(props);

        this.init = this.init.bind(this);
		this.handleSuccess = this.handleSuccess.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleChangeModel = this.handleChangeModel.bind(this);
		this.handleSubmitForm = this.handleSubmitForm.bind(this);

        this.urlRelatorio = "relatorio/ordem-002";

        this.state.model = {
            idOrdens: props.idOrdens,
            orientation: "L",
            destination: "I",
            flMostrarValor: Permissao.getInstance().getPermissao(22),
            flMarcaDagua: true,
            flMostrarComentario: false,
            flMostrarComentarioPrivado : false,
            flOrdens : false
        };
    }
   
	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
	}

	handleSuccess(e) {
        Util.openNewWindow(ConfigApp.getUrlApi() + this.urlRelatorio, this.state.model);
	}

	handleClose(e) {
		super.handleClose(e, {});
	}

    render() {
        return (
            <Form onSubmit={this.handleSuccess} refParam={f => (this.form = f)} key={this.state.key}>
				<Panel key={this.state.key} title="Impressão da Ordem" onClose={this.handleClose} isModal={this.isModal()} container={{sizing: Container.sizing.xxl, className : ""}} modal={{sizing: this.props.sizing ? this.props.sizing : ModalDialog.sizing.xl}}>
                    <PanelBody>
                        <Row className="row mb-3">
                            <FormItem md={6} label="Disposição da Página">                                
                                <BtnRadioGroup name="orientation" color={BtnRadioGroup.color.primary} opcional={true} value={this.state.model.orientation} onClick={this.handleChangeModel}>
                                    <BtnRadio value="P" label="Vertical" />
                                    <BtnRadio value="L" label="Horizontal" />
                                </BtnRadioGroup>
                            </FormItem>
                            <FormItem md={6} label="Destino do Relatório">
                                <BtnRadioGroup name="destination" color={BtnRadioGroup.color.primary} opcional={true} value={this.state.model.destination} onClick={this.handleChangeModel}>
                                    <BtnRadio value="I" label="Tela" />
                                    <BtnRadio value="D" label="Download" />
                                </BtnRadioGroup>
                            </FormItem>
                        </Row>
                        <Row className="row px-3 mb-3">
                            {Permissao.getInstance().getPermissao(76) ?
                                <InputCheckSwitch label="Mostrar valor dos itens?" name="flMostrarValor" checked={Boolean(this.state.model.flMostrarValor)} onChange={this.handleChangeModel} />
                                : <></>}
                            <InputCheckSwitch label="Mostrar marca d'água" name="flMarcaDagua" checked={Boolean(this.state.model.flMarcaDagua)} onChange={this.handleChangeModel} />
                            <InputCheckSwitch label="Mostrar comentários" name="flMostrarComentario" checked={Boolean(this.state.model.flMostrarComentario)} onChange={this.handleChangeModel} />

                            {Permissao.getInstance().getPermissao(108) && Boolean(this.state.model.flMostrarComentario) ?
                                <InputCheckSwitch label="Mostrar comentários privados?" name="flMostrarComentarioPrivado" checked={Boolean(this.state.model.flMostrarComentarioPrivado)} onChange={this.handleChangeModel} />
                                : <></>}

                            <InputCheckSwitch label="Incluir Ordens?" name="flOrdens" checked={Boolean(this.state.model.flOrdens)} onChange={this.handleChangeModel} />
                        </Row>
                    </PanelBody>
                    <PanelFooter>
                        <FormButtons onClose={this.handleClose} label={['Imprimir','Fechar']}/>
                    </PanelFooter>
                </Panel>
            </Form>
        );
    }
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <FormRelatorioOrdem002 {...props} navigate={navigate} params={params} location={location}/>
}
export default With