//#NO-CHANGE-FILE#

import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { BaseList } from "../../siht/base";
import { Panel, Container, GoogleMaps, GoogleMapsMarker, PanelBody, Row, FormItem, InputGroupText, InputGroupButton, InputGroup, GoogleMapsPolyLine, Tab, TabNavigator, BtnGroup, BtnRadioGroup, Col, ListGroup, ListGroupItem, PanelHeader, GoogleMapsRoute } from "../../siht/components";
import ConfigApp from "../../ConfigApp";
import { Modal } from "../../siht/controller";
import { BtnButton, BtnCheck, BtnRadio, BtnSubmit, Div, Form, InputDate, InputDateTimeLocal, InputNumber, InputSearch, InputText, Span } from "../../siht/elements";
import SelectAnsStatus from "../../components/SelectDB/SelectAnsStatus";
import { FormItemEmpresaUnidade, FormItemPaisEstadoCidade, FormItemTipoOrdemStatusOrdem, FormItemTipoOrdemStatusOrdemMultiple, FormItemTipoPessoaPessoa } from "../../components/FormItem";
import { ArrowBarLeft as IconArrowBarLeft, ArrowBarRight as IconArrowBarRight, CardChecklist as IconCardChecklist, CarFront as IconCarFront, Crosshair2 as IconCrosshair2, Funnel as IconFunnel, FunnelFill as IconFunnelFill, Geo as IconGeo, GeoFill as IconGeoFill, MapFill as IconMapFill, SignMergeRight as IconSignMergeRight } from "react-bootstrap-icons";
import FormVeiculo from "../Veiculo/FormVeiculo";
import ListVeiculoResumo from "../VeiculoResumo/ListVeiculoResumo";
import FormAnsPerfil from "../AnsPerfil/FormAnsPerfil";
import FormOrdem from "../Ordem/FormOrdem";
import AnsPerfilService from "../../services/AnsPerfilService";
import VeiculoService from "../../services/VeiculoService";
import OrdemService from "../../services/OrdemService";
import Util from "../../utils/Util";
import { IconClear, IconSearch, IconEdit } from "../../icons";
import { StatusCustom } from "../../components/Custom";
import './custom.css';
import { ObraService } from "../../services";

class ListMapaLocalizacao extends BaseList {

	constructor(props) {
		super(props);
		this.getItens = this.getItens.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.onSubmitFilter = this.onSubmitFilter.bind(this);
		this.handleClear = this.handleClear.bind(this);
		this.handleFormSuccess = this.handleFormSuccess.bind(this);
		this.handleFormClose = this.handleFormClose.bind(this);		
		this.handleChangeFilter = this.handleChangeFilter.bind(this);

		this.handleChangeZoom = this.handleChangeZoom.bind(this);
		this.handleClickZoomIn = this.handleClickZoomIn.bind(this);
		this.handleClickZoomOut = this.handleClickZoomOut.bind(this);
		
		this.getVeiculos = this.getVeiculos.bind(this);
		this.getOrdens = this.getOrdens.bind(this);
		this.getAnsPerfis = this.getAnsPerfis.bind(this);
		this.getObras = this.getObras.bind(this);

		this.setPosicao = this.setPosicao.bind(this);

		this.uptRoutePoint = this.uptRoutePoint.bind(this);
		this.addRoutePoint = this.addRoutePoint.bind(this);
		this.delRoutePoint = this.delRoutePoint.bind(this);
		this.clearRoute = this.clearRoute.bind(this);

		this.handleChangeCidade = this.handleChangeCidade.bind(this);
		
		this.colors = [			
			"#191970",
			"#800000",
			"#6B8E23",
			"#800080",
			"#778899",
			"#008B8B",			
			"#00CED1",
			"#00FA9A",
			"#00FF00",
			"#4B0082",
			"#000080",
			"#00FFFF",
			"#0000CD",
			"#1E90FF",
			"#20B2AA",
			"#228B22",
			"#2E8B57",
			"#00FF7F",
			"#2F4F4F",
			"#32CD32",
			"#3CB371",
			"#0000FF",
			"#556B2F",
			"#4169E1",
			"#4682B4",
			"#483D8B",
			"#48D1CC",
			"#008000",
			"#00BFFF",
			"#5F9EA0",
			"#006400",
			"#6495ED",
			"#663399",
			"#7CFC00",
			"#66CDAA",
			"#696969",
			"#696969",
			"#6A5ACD",			
			"#708090",
			"#708090",
			"#008080",
			"#40E0D0",
			"#778899",			
			"#7B68EE",
			"#7FFF00",
			"#7FFFD4",			
			"#808000",
		];
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		this.getItens();
	}

	initState(){
		return {
			showBar : true,
			showFilter : true,
			ordens : [],
			ansPerfis : [],
			pessoas : [],
			equipes : [],
			veiculos : [],
			obras : [],
			latlng : {
				lat : -23.442955362297,
				lng : -51.916717513525,
				zoom : 5
			},
			route : {
				origin : null,
				destination : null,
				waypoints : [],
				points : []
			}
		}
	}

	initStateFilter(){
		var tzoffset = (new Date()).getTimezoneOffset() * 60000;

		var dtHoje = new Date();
        dtHoje.setDate(dtHoje.getDate());
        var dhLocalizacaoStart = new Date(dtHoje - tzoffset).toISOString().slice(0, 10)+"T00:00";
		var dhLocalizacaoEnd = new Date(dtHoje - tzoffset).toISOString().slice(0, 10)+"T23:59";
		
        return {
			flMostrarVeiculos : true,
			flMostrarOrdens : true,
			flMostrarEquipe : false,
			flMostrarPessoas : false,
			flMostrarAnsPerfis : false,
			flMostrarObras : false,

			mapa : {
				cidade : null
			},

			veiculo : {
				tipo : "TRAJETO",
				dhLocalizacaoStart : dhLocalizacaoStart,
				dhLocalizacaoEnd : dhLocalizacaoEnd,
			},
			ordem : {
				idOrdem : "",				
				cdIdentificadorExterno : "",
				statusOrdems : null,
				statusOrdem : null,
				dtOrdemStart : "",
				dtOrdemEnd : "",
				unidade : null,
				pessoa : null,
				search: "",
			},	
			ansPerfil : {
				idOrdem : "",				
				cdIdentificadorExterno : "",
				statusOrdem : null,
				ansStatus : null,
				dtOrdemStart : "",
				dtOrdemEnd : "",
				unidade : null,
				pessoa : null,
				search: "",
			},			
        };
    }

	handleClear(){
        this.setState(state =>{
			state = this.initState();
            state.filter = this.initStateFilter();
            return state;
        }, this.getItens);
    }

	getItens(){		
		this.getVeiculos();
		this.getAnsPerfis();
		this.getOrdens();
		this.getObras();
	}

	handleClickOrdem(item){
		Modal.openWindow(FormOrdem, {sizing : Modal.sizing.fullscreen}, { id : item.idOrdem });
	}

	handleClickAnsPerfil(item){
		Modal.openWindow(FormAnsPerfil, {sizing : Modal.sizing.fullscreen}, { id : item.idAnsPerfil });
	}

	handleClickVeiculo(item){
		Modal.openWindow(FormVeiculo, {sizing : Modal.sizing.xl}, { id : item.idVeiculo });
	}

	handleClickResumoVeiculo(item){
		Modal.open(ListVeiculoResumo, {sizing : Modal.sizing.xl}, { parent : item , title : "Resumo do Veículo por Data"});
	}
	
	getVeiculos(){
		VeiculoService.listMapa(this.state.filter.veiculo, response => {
			this.setState({veiculos : response});
		});
	}

	getOrdens(){
		OrdemService.listMapa(this.state.filter.ordem, response => {
			this.setState({ordens : response});
		});
	}

	getAnsPerfis(){
		AnsPerfilService.listMapa(this.state.filter.ansPerfil, response => {
			this.setState({ansPerfis : response});
		});
	}

	getObras(){
		ObraService.listMapa({}, response => {
			this.setState({obras : response});
		});
	}

	setPosicao(lat, lng, zoom){
		this.setState({latlng : {
			lat : lat,
			lng : lng,
			zoom : zoom
		}});
	}

	clearRoute(){
		this.setState(state => {
			state.route = {
				origin : null,
				destination : null,
				waypoints : [],
				points : []
			};
			return state;			
		});
	}

	uptRoutePoint(){
		this.setState(state => {
			if(state.route.points.length > 1){
				state.route.origin = {lat : state.route.points[0].vlLatitude, lng : state.route.points[0].vlLongitude };
				state.route.destination = {lat : state.route.points[state.route.points.length - 1].vlLatitude, lng : state.route.points[state.route.points.length - 1].vlLongitude };
				state.route.waypoints = state.route.points.slice(1, state.route.points.length - 1).map(l => {return {lat : l.vlLatitude, lng : l.vlLongitude }});
			}else{
				state.route.origin = null;
				state.route.destination = null;
				state.route.waypoints = null;
			}
			return state;			
		});
	}

	addRoutePoint(localizacao){
		this.setState(state => {			
			state.route.points.push(localizacao);
			return state;			
		}, this.uptRoutePoint);
	}

	delRoutePoint(localizacao){
		this.setState(state => {			
			let index = state.route.points.findIndex(l => parseInt(localizacao.idLocalizacao) === parseInt(l.idLocalizacao));
			if(index !== -1){
				state.route.points.splice(index, 1);
			}
			return state;			
		}, this.uptRoutePoint);
	}


	handleChangeCidade(){
		if(this.state.filter.mapa.cidade && this.state.filter.mapa.cidade.localizacao){
			this.setPosicao(this.state.filter.mapa.cidade.localizacao.vlLatitude, this.state.filter.mapa.cidade.localizacao.vlLongitude, 10);			
		}else if(this.state.filter.mapa.cidade.estado && this.state.filter.mapa.cidade.estado.localizacao){
			this.setPosicao(this.state.filter.mapa.cidade.estado.localizacao.vlLatitude, this.state.filter.mapa.cidade.estado.localizacao.vlLongitude, 10);			
		}
	}

	handleChangeMap(e, map, marker, position) {
		this.setState(state => {
			state.model.lng = position.lng
			state.model.lat = position.lat
			return state;
		});
	}

	handleChangeZoom(e, map, zoom) {
		this.setState(state => {
			state.latlng.zoom = zoom
			return state;
		});
	}

	handleClickZoomIn() {

		if(!(this.state.latlng.zoom < 22)){
			return;
		}

		this.setState(state => {
			state.latlng.zoom = state.latlng.zoom + 1
			return state;
		});
	}

	handleClickZoomOut() {
		if(!(this.state.latlng.zoom > 0)){
			return;
		}

		this.setState(state => {
			state.latlng.zoom = state.latlng.zoom - 1
			return state;
		});
	}

	render() {

		return super.render (
			<Panel title="Mapa" isModal={this.isModal()} container={{sizing: Container.sizing.fluid, className : ""}}>
				<PanelHeader className="d-flex flex-nowrap">
					Mapa
					<Div className="d-flex ms-auto me-0">
						<BtnButton color={BtnButton.color.link} sizing={BtnButton.sizing.sm} onClick={e => this.setState({showFilter : !this.state.showFilter})}>
							{this.state.showFilter ? <IconFunnelFill/> : <IconFunnel/>}
						</BtnButton>
						<BtnButton color={BtnButton.color.link} sizing={BtnButton.sizing.sm} onClick={e => this.setState({showBar : !this.state.showBar})}>
							{this.state.showBar ? <IconArrowBarRight/> : <IconArrowBarLeft/>}
						</BtnButton>
					</Div>
				</PanelHeader>
				<PanelBody className="p-0 m-0">					
					<TabNavigator className="border-light" show={this.state.showFilter}>
						<Tab title={<><IconMapFill/> Mapa</>} className="m-2">
							<Form onSubmit={this.onSubmitFilter}>
								<Row className="g-3">
									<FormItemPaisEstadoCidade  md={[2,4,6]} name="cidade" value={this.state?.filter?.mapa?.cidade} required={true} onChange={(e,v,p) => this.handleChange(e,v,p,"filter.mapa", this.handleChangeCidade)} 
										buttons={[{search: false,new : false,edit : false,clear : false }, {search: false,new : false,edit : false,clear : false }, {search: true,new : false,edit : false,clear : true }]}
									/>

									<FormItem md={12} label="Mostrar">
										<BtnGroup opcional={true} sizing={BtnGroup.sizing.default} border={BtnGroup.border.primary}>
											<BtnCheck name="flMostrarVeiculos" value={this.state.filter.flMostrarVeiculos} label="Veículos" onClick={this.handleChangeFilter}/>
											<BtnCheck name="flMostrarOrdens" value={this.state.filter.flMostrarOrdens} label="Ordens" onClick={this.handleChangeFilter}/>
											<BtnCheck name="flMostrarEquipe" value={this.state.filter.flMostrarEquipe} label="Equipes" onClick={this.handleChangeFilter}/>
											<BtnCheck name="flMostrarPessoas" value={this.state.filter.flMostrarPessoas} label="Pessoas" onClick={this.handleChangeFilter}/>
											<BtnCheck name="flMostrarAnsPerfis" value={this.state.filter.flMostrarAnsPerfis} label="Ans Perfis" onClick={this.handleChangeFilter}/>
											<BtnCheck name="flMostrarObras" value={this.state.filter.flMostrarObras} label="Obras" onClick={this.handleChangeFilter}/>
										</BtnGroup>
									</FormItem>									
									
								</Row>
							</Form>
						</Tab>
						<Tab title={<><IconCarFront/> Veículos</>} className="m-2" show={this.state.filter.flMostrarVeiculos}>

							<Div className="p-3 mt-2">
								<Form onSubmit={this.onSubmitFilter}>
									<Row className="g-3">
										<FormItem md={3} label="Visualização">
											<BtnRadioGroup name="tipo" color={BtnRadioGroup.color.primary} opcional={true} value={this.state?.filter?.veiculo.tipo} onChange={(e,v,p) => this.handleChange(e,v,p,"filter.veiculo")}>
												<BtnRadio value="POSICAO" label="Última Posição" />
												<BtnRadio value="TRAJETO" label="Trajeto" />
											</BtnRadioGroup>
										</FormItem>

										<FormItem md={3} label="Data Inicial">
											<InputDateTimeLocal disabled={this.state.filter.veiculo.tipo !== "TRAJETO"} name="dhLocalizacaoStart" value={this.state?.filter?.veiculo?.dhLocalizacaoStart} required={true} opcional={true} onChange={(e,v,p) => this.handleChange(e,v,p,"filter.veiculo")}/>
										</FormItem>

										<FormItem md={3} label="Data Final">
											<InputDateTimeLocal disabled={this.state.filter.veiculo.tipo !== "TRAJETO"} name="dhLocalizacaoEnd" min={this.state?.filter?.veiculo?.dhLocalizacaoStart} value={this.state?.filter?.veiculo?.dhLocalizacaoEnd} required={true} opcional={true} onChange={(e,v,p) => this.handleChange(e,v,p,"filter.veiculo")}/>
										</FormItem>

										<Col md={3} className="d-flex justify-content-end align-items-end">
											<Div>
												<InputGroup className="flex-nowrap" sizing={InputGroup.sizing.default}>												
													<InputGroupButton>
														<BtnSubmit title="Filtrar"> Buscar </BtnSubmit>
														<BtnButton title="Limpar Pesquisa" color={BtnButton.color.outlineDanger} onClick={this.handleClear}> <IconClear/> </BtnButton>
													</InputGroupButton>
												</InputGroup>
											</Div>
										</Col>
									</Row>
								</Form>
							</Div>
						</Tab>
						<Tab title={<><IconCarFront/> Ordens</>} className="m-2" show={this.state.filter.flMostrarOrdens}>

							<Div className="p-3 mt-2">
								<Form onSubmit={this.onSubmitFilter}>
									<Row className="g-3">
										<FormItem md={1} label="Ordem">
											<InputNumber name="idOrdem" value={this.state.filter.ordem?.idOrdem} opcional={true} required={false} onChange={(e,v,p) => this.handleChange(e,v,p,"filter.ordem")}/>
										</FormItem>

										<FormItem md={1} label="Idend. Ext">
											<InputText name="cdIdentificadorExterno" value={this.state.filter.ordem?.cdIdentificadorExterno} opcional={true} required={false} onChange={(e,v,p) => this.handleChange(e,v,p,"filter.ordem")}/>
										</FormItem>

										<FormItemTipoOrdemStatusOrdemMultiple md={[3,3]} name="statusOrdems" opcional={true} value={this.state.filter.ordem?.statusOrdems} required={false} onChange={(e,v,p) => this.handleChange(e,v,p,"filter.ordem")} buttons={{search : false, new : false, edit: false}}/>

										<FormItem md={2} label="Data Inicial">
											<InputDate name="dtOrdemStart" value={this.state.filter.ordem?.dtOrdemStart} required={false} opcional={true} onChange={(e,v,p) => this.handleChange(e,v,p,"filter.ordem")}/>
										</FormItem>
										
										<FormItem md={2} label="Data Final">
											<InputDate name="dtOrdemEnd" min={this.state.filter.ordem?.dtOrdemStart} value={this.state.filter.ordem?.dtOrdemEnd} required={false} opcional={true} onChange={(e,v,p) => this.handleChange(e,v,p,"filter.ordem")}/>
										</FormItem>

										<FormItemEmpresaUnidade md={[3,3]} name="unidade" opcional={true} value={this.state.filter.ordem?.unidade} required={false} onChange={(e,v,p) => this.handleChange(e,v,p,"filter.ordem")} buttons={{search : false, new : false, edit: false}}/>
										<FormItemTipoPessoaPessoa label={["Tipo Cliente", "Cliente"]} md={[2,4]} name="pessoa" value={this.state.filter.ordem?.pessoa} required={false} onChange={(e,v,p) => this.handleChange(e,v,p,"filter.ordem")} opcional={true}/>

										<Col md={3} className="d-flex justify-content-end align-items-end">
											<Div>
												<InputGroup className="flex-nowrap" sizing={InputGroup.sizing.default}>												
													<InputGroupButton>
														<BtnSubmit title="Filtrar"> Buscar </BtnSubmit>
														<BtnButton title="Limpar Pesquisa" color={BtnButton.color.outlineDanger} onClick={this.handleClear}> <IconClear/> </BtnButton>
													</InputGroupButton>
												</InputGroup>
											</Div>
										</Col>
									</Row>
								</Form>
							</Div>

						</Tab>
						<Tab title={<><IconCarFront/> Equipes</>} className="m-2" show={this.state.filter.flMostrarEquipe}>
						
						</Tab>
						<Tab title={<><IconCarFront/> Pessoas</>} className="m-2" show={this.state.filter.flMostrarPessoas}>
						
						</Tab>
						<Tab title={<><IconCrosshair2/> Perfis</>} className="m-2" show={this.state.filter.flMostrarAnsPerfis}>
							<Form onSubmit={this.onSubmitFilter}>
								<Row className="g-3">
									<FormItem md={1} label="Ordem">
										<InputNumber name="idOrdem" value={this.state.filter.ansPerfil.idOrdem} opcional={true} required={false} onChange={(e,v,p) => this.handleChange(e,v,p,"filter.ansPerfil")}/>
									</FormItem>

									<FormItem md={1} label="Idend. Ext">
										<InputText name="cdIdentificadorExterno" value={this.state.filter.ansPerfil.cdIdentificadorExterno} opcional={true} required={false} onChange={(e,v,p) => this.handleChange(e,v,p,"filter.ansPerfil")}/>
									</FormItem>

									<FormItemTipoOrdemStatusOrdem md={[3,3]} name="statusOrdem" opcional={true} value={this.state.filter.ansPerfil.statusOrdem} required={false} onChange={(e,v,p) => this.handleChange(e,v,p,"filter.ansPerfil")} buttons={{search : false, new : false, edit: false}}/>

									<FormItem md={4} label="Status do Perfil">
										<SelectAnsStatus name="ansStatus" value={this.state?.filter.ansPerfil?.ansStatus} required={false} opcional={true} onChange={(e,v,p) => this.handleChange(e,v,p,"filter.ansPerfil")} placeholder="Selecione o Status" buttons={{search : false, new : false, edit: false}}/>
									</FormItem>

									<FormItem md={2} label="Data Inicial">
										<InputDate name="dtOrdemStart" value={this.state.filter.ansPerfil.dtOrdemStart} required={false} opcional={true} onChange={(e,v,p) => this.handleChange(e,v,p,"filter.ansPerfil")}/>
									</FormItem>
									
									<FormItem md={2} label="Data Final">
										<InputDate name="dtOrdemEnd" min={this.state.filter.ansPerfil.dtOrdemStart} value={this.state.filter.ansPerfil.dtOrdemEnd} required={false} opcional={true} onChange={(e,v,p) => this.handleChange(e,v,p,"filter.ansPerfil")}/>
									</FormItem>

									<FormItemEmpresaUnidade md={[3,3]} name="unidade" opcional={true} value={this.state.filter.ansPerfil.unidade} required={false} onChange={(e,v,p) => this.handleChange(e,v,p,"filter.ansPerfil")} buttons={{search : false, new : false, edit: false}}/>
									<FormItemTipoPessoaPessoa label={["Tipo Cliente", "Cliente"]} md={[2,4]} name="pessoa" value={this.state.filter.ansPerfil.pessoa} required={false} onChange={(e,v,p) => this.handleChange(e,v,p,"filter.ansPerfil")} opcional={true}/>

									<FormItem md={8} label="Busca">
										<InputGroup className="flex-nowrap" sizing={InputGroup.sizing.default}>
											<InputGroupText><IconSearch/></InputGroupText>
											<InputSearch autoComplete="off" autoFocus placeholder="Digite sua busca" name="search" value={this.state?.filter?.search} required={false} opcional={true} onChange={(e,v,n) => this.handleChange(e,v,n, "filter.ansPerfil")}/>
											<InputGroupButton>
												<BtnSubmit title="Filtrar"> Buscar </BtnSubmit>
												<BtnButton title="Limpar Pesquisa" color={BtnButton.color.outlineDanger} onClick={this.handleClear}> <IconClear/> </BtnButton>
											</InputGroupButton>
										</InputGroup>
									</FormItem>
								</Row>
							</Form>
						</Tab>
					</TabNavigator>

					<Row>
						<Col>
							{ConfigApp.getInstance().clientIdGoogleMaps ?
								<GoogleMaps key={this.state.key} apiKey={ConfigApp.getInstance().clientIdGoogleMaps} lat={this.state.latlng.lat} lng={this.state.latlng.lng} >
									
									{this.state.filter.flMostrarAnsPerfis ? 
										this.state.ansPerfis.map((item, index) => (
											<GoogleMapsMarker icon="./assets/image/icon_sondagem.png" zIndex={0} key={index} lat={item.localizacao?.vlLatitude} lng={item.localizacao?.vlLongitude} title={item.idAnsPerfil} onClick={e => this.handleClickAnsPerfil(item)}/>
										)) : <></>
									}

									{this.state.equipes.map((item, index) => (
										<GoogleMapsMarker icon="./assets/image/icon_equipe.png" zIndex={1} key={index} lat={item.localizacao?.vlLatitude} lng={item.localizacao?.vlLongitude} title={item.idEquipe} onClick={e => this.handleClickEquipe(item)}/>
									))}

									{this.state.pessoas.map((item, index) => (
										<GoogleMapsMarker icon="./assets/image/icon_pessoa.png" zIndex={1} key={index} lat={item.localizacao?.vlLatitude} lng={item.localizacao?.vlLongitude} title={item.idEquipe} onClick={e => this.handleClickEquipe(item)}/>
									))}

									{this.state.filter.flMostrarOrdens ? this.state.ordens.map((item, index) => {
										let icon = "./assets/image/icon_ordem.png";
										
										if(item.tipoOrdem.cdTipoOrdem === "SONDAGEM_SOLO"){
											icon = "./assets/image/icone_sondagem.png";
										}else if(item.tipoOrdem.cdTipoOrdem === "LABORATORIO_SOLO"){
											icon = "./assets/image/icon_ordem.png";
										}

										return(
											<GoogleMapsMarker className="marker-mapa" css={{background: item.statusOrdem.cdCorFundo, borderColor: item.statusOrdem.cdCorBorda, glyphColor: "transparent" }} icon={icon} zIndex={1} key={index} lat={item.localizacao?.vlLatitude} lng={item.localizacao?.vlLongitude} title={item.idEquipe} onClick={e => this.handleClickOrdem(item)}/>
										)
									}) : <></>
									}

									{this.state.filter.flMostrarObras ? this.state.obras.map((item, index) => (
											<GoogleMapsMarker 
											className="marker-mapa" css={{background: "red", borderColor: "blue", glyphColor: "transparent" }} 
											icon="./assets/image/icone_obra.png"
											zIndex={1} key={index} lat={item.endereco?.localizacao?.vlLatitude} lng={item.endereco?.localizacao?.vlLongitude} title={item.idEquipe} onClick={e => this.handleClickObra(item)}/>
										)) : <></>
									}

									{this.state.filter.flMostrarVeiculos && this.state.filter.veiculo.tipo === "POSICAO" ?
										this.state.veiculos.map((item, index) => (
											<GoogleMapsMarker className="marker-mapa" css={{background: "green", borderColor: "green", glyphColor: "transparent" }} icon="./assets/image/icone_veiculo.png" zIndex={2} key={index} lat={item.localizacao?.vlLatitude} lng={item.localizacao?.vlLongitude} title={item.idEquipe} onClick={e => this.handleClickVeiculo(item)}/>
										)) : <></>
									}

									{this.state.filter.flMostrarVeiculos && this.state.filter.veiculo.tipo === "TRAJETO" ?
										this.state.veiculos.filter(v => v.veiculoTrajetos !== undefined && v.veiculoTrajetos?.length > 0).map((item, index) => (
											<GoogleMapsPolyLine key={index}
												stroke={{color : this.colors[index], opacity : 1.0, weight : 5}}
												markers={[
													<GoogleMapsMarker icon={{path: GoogleMapsMarker.SymbolPath.CIRCLE,scale: 5.5, fillColor: this.colors[index], fillOpacity: 1, strokeWeight: 1.0 }} lat={item.veiculoTrajetos[0].localizacao?.vlLatitude} lng={item.veiculoTrajetos[0].localizacao?.vlLongitude} title={"Teste"}/>,
													<GoogleMapsMarker className="marker-mapa" css={{background: "green", borderColor: "green", glyphColor: "transparent" }} icon="./assets/image/icone_veiculo.png" lat={item.veiculoTrajetos[item.veiculoTrajetos.length - 1].localizacao?.vlLatitude} lng={item.veiculoTrajetos[item.veiculoTrajetos.length - 1].localizacao?.vlLongitude} title={"Teste"} onClick={e => this.handleClickVeiculo(item)}/>,
												]}
												coordinates={item.veiculoTrajetos.map(ivl => {return {lat: ivl.localizacao?.vlLatitude, lng: ivl.localizacao?.vlLongitude}})}/>
										)) : <></>
									}

									{this.state.route?.origin && this.state.route?.destination ? 
										<GoogleMapsRoute 
											origin={this.state.route.origin} 
											destination={this.state.route.destination} 
											waypoints={[...this.state.route.waypoints]}/>
									: <></>}
								</GoogleMaps>
							:<></>}
						</Col>
						<Col sizing={3} show={this.state.showBar} className="overflow-auto h100-calc">
							
							<Div className="d-flex justify-content-end">
								<BtnButton color={BtnButton.color.outlinePrimary} sizing={BtnButton.sizing.sm} onClick={this.clearRoute} show={this.state.route.points.length > 0}>
									Limpar Rota
								</BtnButton>
							</Div>

							{this.state.filter.flMostrarVeiculos ?
								<Div className="mb-2">
									<Div className="fs-5 fw-bold"> Veículos </Div>
									<ListGroup className="list-group-numbered me-2">
										{this.state.veiculos.map((item, index) => (
											<ListGroupItem key={index} className="d-flex justify-content-between align-items-start">
												<Div className="ms-2 me-auto">
													<Div className="fw-bold">{item.modelo?.marca?.nmMarca} - {item?.modelo?.nmModelo}</Div>
													<Div>{item.transmissao?.nmTransmissao}</Div>
													<Div>{Util.floatToValor(item.vlQuilometragem, 3)} KM </Div>
													
													{item.veiculoPlaca ?
														<Div>
															<Span className="badge text-bg-primary">
																{item.veiculoPlaca?.dsPlaca} - {item.veiculoPlaca?.pais?.nmPais}
															</Span>
														</Div>: 
													<></>}

													<Div className="pt-1 d-grid gap-2 d-md-flex">
														<BtnButton className="px-2 py-1" color={BtnButton.color.outlinePrimary} disabled={!item.localizacao} sizing={BtnButton.sizing.sm} onClick={e => this.setPosicao(item.localizacao.vlLatitude, item.localizacao.vlLongitude, 15)}> 
															<IconSearch/>
														</BtnButton>
														<BtnButton className="px-2 py-1" color={BtnButton.color.outlinePrimary} sizing={BtnButton.sizing.sm} disabled={!item.localizacao} onClick={e => this.addRoutePoint(item.localizacao)} show={this.state.route.points.findIndex(l => parseInt(item.localizacao?.idLocalizacao) === parseInt(l.idLocalizacao)) === -1}> 
															<IconGeoFill/>
														</BtnButton>
														<BtnButton className="px-2 py-1" color={BtnButton.color.outlineDanger} sizing={BtnButton.sizing.sm} onClick={e => this.delRoutePoint(item.localizacao)} show={this.state.route.points.findIndex(l => parseInt(item.localizacao?.idLocalizacao) === parseInt(l.idLocalizacao)) !== -1}> 
															<IconGeo/>
														</BtnButton>
														<BtnButton className="px-2 py-1" color={BtnButton.color.outlinePrimary} sizing={BtnButton.sizing.sm} onClick={e => this.handleClickVeiculo(item)}> 
															<IconEdit/>
														</BtnButton>
														<BtnButton className="px-2 py-1" color={BtnButton.color.outlinePrimary} sizing={BtnButton.sizing.sm} onClick={e => this.handleClickResumoVeiculo(item)}> 
															<IconSignMergeRight/>
														</BtnButton>

														
													</Div>
												</Div>
												<Span className="badge text-bg-primary">{item?.tipoVeiculo?.nmTipoVeiculo}</Span>
												
											</ListGroupItem>
										))}
									</ListGroup>
								</Div>
								: <></>
							}

							{this.state.filter.flMostrarAnsPerfis ?
								<Div className="mb-2">
									<Div className="fs-5 fw-bold"> Perfis </Div>
									<ListGroup className="list-group-numbered me-2">
										{this.state.ansPerfis.map((item, index) => (
											<ListGroupItem key={index} className="d-flex justify-content-between align-items-start">
												<Div className="ms-2 me-auto">
													<Div className="fw-bold fs-7">{item.ordem.pessoa.nmPessoa}</Div>
													<Div><StatusCustom color={item.ordem.pessoa?.tipoPessoa}> PESSOA {item.ordem.pessoa?.tipoPessoa?.nmTipoPessoa} </StatusCustom></Div>
													<Div><StatusCustom color={item.ordem.statusOrdem}> {item.ordem.statusOrdem?.nmStatusOrdem} </StatusCustom></Div>

													<Div className="pt-1 d-grid gap-2 d-md-flex">
														<BtnButton className="px-2 py-1" color={BtnButton.color.outlinePrimary} disabled={!item.localizacao} sizing={BtnButton.sizing.sm} onClick={e => this.setPosicao(item.localizacao.vlLatitude, item.localizacao.vlLongitude, 15)}> 
															<IconSearch/>
														</BtnButton>														
													
														<BtnButton className="px-2 py-1" color={BtnButton.color.outlinePrimary} sizing={BtnButton.sizing.sm} disabled={!item.localizacao} onClick={e => this.addRoutePoint(item.localizacao)} show={this.state.route.points.findIndex(l => parseInt(item.localizacao?.idLocalizacao) === parseInt(l.idLocalizacao)) === -1}> 
															<IconGeoFill/>
														</BtnButton>
														<BtnButton className="px-2 py-1" color={BtnButton.color.outlineDanger} sizing={BtnButton.sizing.sm} onClick={e => this.delRoutePoint(item.localizacao)} show={this.state.route.points.findIndex(l => parseInt(item.localizacao?.idLocalizacao) === parseInt(l.idLocalizacao)) !== -1}> 
															<IconGeo/>
														</BtnButton>

														<BtnButton className="px-2 py-1" color={BtnButton.color.outlinePrimary} sizing={BtnButton.sizing.sm} onClick={e => this.handleClickAnsPerfil(item)}> 
															<IconEdit/>
														</BtnButton>
														<BtnButton className="px-2 py-1" color={BtnButton.color.outlinePrimary} sizing={BtnButton.sizing.sm} onClick={e => this.handleClickOrdem(item.ordem)}> 
															<IconCardChecklist/>
														</BtnButton>
													</Div>
												</Div>
												<Span className="badge text-bg-primary">{item?.ordem.idOrdem}</Span>
												
											</ListGroupItem>
										))}
									</ListGroup>
								</Div>
								: <></>
							}

							{this.state.filter.flMostrarOrdens ?
								<Div className="mb-2">
									<Div className="fs-5 fw-bold"> Ordens </Div>
									<ListGroup className="list-group-numbered me-2">
										{this.state.ordens.map((item, index) => (
											<ListGroupItem key={index} className="d-flex justify-content-between align-items-start">
												<Div className="ms-2 me-auto">
													<Div className="fw-bold fs-7">{item.pessoa.nmPessoa}</Div>
													<Div><StatusCustom color={item.pessoa?.tipoPessoa}> PESSOA {item.pessoa?.tipoPessoa?.nmTipoPessoa} </StatusCustom></Div>
													<Div><StatusCustom color={item.statusOrdem}> {item.statusOrdem?.nmStatusOrdem} </StatusCustom></Div>

													<Div className="pt-1 d-grid gap-2 d-md-flex">
														<BtnButton className="px-2 py-1" color={BtnButton.color.outlinePrimary} disabled={!item.localizacao} sizing={BtnButton.sizing.sm} onClick={e => this.setPosicao(item.localizacao.vlLatitude, item.localizacao.vlLongitude, 15)}> 
															<IconSearch/>
														</BtnButton>														
													
														<BtnButton className="px-2 py-1" color={BtnButton.color.outlinePrimary} sizing={BtnButton.sizing.sm} disabled={!item.localizacao} onClick={e => this.addRoutePoint(item.localizacao)} show={this.state.route.points.findIndex(l => parseInt(item.localizacao?.idLocalizacao) === parseInt(l.idLocalizacao)) === -1}> 
															<IconGeoFill/>
														</BtnButton>
														<BtnButton className="px-2 py-1" color={BtnButton.color.outlineDanger} sizing={BtnButton.sizing.sm} onClick={e => this.delRoutePoint(item.localizacao)} show={this.state.route.points.findIndex(l => parseInt(item.localizacao?.idLocalizacao) === parseInt(l.idLocalizacao)) !== -1}> 
															<IconGeo/>
														</BtnButton>
														
														<BtnButton className="px-2 py-1" color={BtnButton.color.outlinePrimary} sizing={BtnButton.sizing.sm} onClick={e => this.handleClickOrdem(item)}> 
															<IconEdit/>
														</BtnButton>
													</Div>
												</Div>
												<Span className="badge text-bg-primary">{item?.idOrdem}</Span>
												
											</ListGroupItem>
										))}
									</ListGroup>
								</Div>
								: <></>
							}
						</Col>
					</Row>
				
					

				</PanelBody>
				
			</Panel>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <ListMapaLocalizacao {...props} navigate={navigate} params={params} location={location}/>
}
export default With