//#NO-CHANGE-FILE#

import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import BtnRadioGroupSimNao from "../../components/BtnRadioGroupSimNao";
//import ManyToManyCheckFaturaCobrancaTipoPagamentoByTipoPagamento from "./ManyToManyCheckFaturaCobrancaTipoPagamentoByTipoPagamento";
import Permissao from "../../controllers/Permissao";
import { BaseCrud } from "../../siht/base";
import { FormItem, Row, TabNavigator, Tab, Panel, PanelBody, PanelFooter, Container, ModalDialog, Col } from "../../siht/components";
import { InputText, InputNumberFormat, InputDate, Form, BtnSubmit, BtnButton, Button } from "../../siht/elements";
import Config from "./Config";
import Util from "../../utils/Util";
import ListFaturaCobrancaBaixa from "./ListFaturaCobrancaBaixa";
import ListFaturaCobrancaTransacao from "./ListFaturaCobrancaTransacao";
import { StatusCustom } from "../../components/Custom";

class FormFaturaCobranca extends BaseCrud {

	constructor(props) {
		super(props);
		this.init = this.init.bind(this);
		this.handleSuccess = this.handleSuccess.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleChangeModel = this.handleChangeModel.bind(this);
		this.handleSubmitForm = this.handleSubmitForm.bind(this);

		this.state.model = {
			idFaturaCobranca : null,
			statusFaturaCobranca : null,
			fatura : this.props.parent !== undefined ? this.props.parent : {},
			nrParcela : "",
			vlCobranca : 0,
			vlPago : 0,
			vlDesconto : 0,
			vlBaixa : 0,
			vlAberto : 0,
			vlBaixaEncargo : 0,
			vlBaixaTaxa : 0,
			vlBaixaFinal : 0,
			flJuros : true,
			flMulta : true,
			vlJuros : "",
			vlMulta : "",
			dtVencimento : "",
			flEstorno : false,
			cdUid : "",
			faturaCobrancaTipoPagamento : []
		};
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		super.init(Config);
	}

	handleSuccess(e) {
		super.handleSuccess(e, Config);
	}

	handleClose(e) {
		super.handleClose(e, Config);
	}

	render() {
		return super.render(

					<Panel key={this.state.key} title="Cobrança" onClose={this.handleClose} isModal={this.isModal()} container={{sizing: Container.sizing.xxl, className : ""}} modal={{sizing: this.props.sizing ? this.props.sizing : ModalDialog.sizing.xl}}>
						<PanelBody className="p-0 m-0">
							<TabNavigator key={this.state.key} onChangeTab={this.handleChangeTab} className="border-light">
								<Tab title="Cobrança" className="m-2">
									<Form onSubmit={this.handleSuccess} refParam={f => (this.form = f)}>
										<Row className="g-3 pb-2 px-2">
											<FormItem md={2} label="Cobrança">
												<InputText name="idFaturaCobranca" value={this.state.model.idFaturaCobranca} readOnly={true} className="text-center" required={false} />
											</FormItem>
											<FormItem md={3} label="Status">
												<StatusCustom className="text-center" opcional={true} type="InputText" color={this.state.model.statusFaturaCobranca}>{this.state.model.statusFaturaCobranca?.nmStatusFaturaCobranca}</StatusCustom>
											</FormItem>
											<FormItem md={3} label="Situação" show={this.state.model.vwFaturaCobranca?.flVencido}>
												<StatusCustom className="text-center fw-bold" opcional={true} type="InputText" color={{cdCorFundo : "#FF0000", cdCorTexto : "#FFFFFF", cdCorBorda : "#8c0000"}}>VENCIDO</StatusCustom>
											</FormItem>
											<FormItem md={3} label="Situação" show={!this.state.model.vwFaturaCobranca?.flVencido}>
												<StatusCustom className="text-center fw-bold" opcional={true} type="InputText" color={{cdCorFundo : "#009154", cdCorTexto : "#FFFFFF", cdCorBorda : "#005b34"}}>EM DIA</StatusCustom>
											</FormItem>
											<FormItem md={2} label="Parcela">
												<InputText name="nrParcela" value={`${this.state.model.nrParcela} / ${this.state.model.fatura.nrParcela}`} maxLength={100} readOnly={true} opcional={true}  className="text-center"/>
											</FormItem>
											<FormItem md={2} label="Vencimento">
												<InputDate name="dtVencimento" value={this.state.model.dtVencimento} readOnly={true} opcional={true}  className="text-center"/>
											</FormItem>
											<FormItem md={3} label="Cobrança">
												<InputNumberFormat className="text-end text-primary fw-bold" name="vlCobranca" value={this.state.model.vlCobranca} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} prefix="R$ " readOnly={true} opcional={true} />
											</FormItem>
											<FormItem md={3} label="Pago">
												<InputNumberFormat className="text-end text-success fw-bold" name="vlPago" value={this.state.model.vlPago} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} prefix="R$ " readOnly={true} opcional={true} />
											</FormItem>
											<FormItem md={3} label="Desconto">
												<InputNumberFormat className="text-end text-danger fw-bold" name="vlDesconto" value={this.state.model.vlDesconto} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} prefix="R$ " readOnly={true} opcional={true} />
											</FormItem>											
											<FormItem md={3} label="Aberto">
												<InputNumberFormat className="text-end text-danger fw-bold" name="vlAberto" value={this.state.model.vlAberto} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} prefix="R$ " readOnly={true} opcional={true} />
											</FormItem>
											<FormItem md={3} label="Baixa">
												<InputNumberFormat className="text-end" name="vlBaixa" value={this.state.model.vlBaixa} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} prefix="R$ " readOnly={true} opcional={true} />
											</FormItem>
											<FormItem md={3} label="Encargos Pago">
												<InputNumberFormat className="text-end" name="vlBaixaEncargo" value={this.state.model.vlBaixaEncargo} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} prefix="R$ " readOnly={true} opcional={true} />
											</FormItem>
											<FormItem md={3} label="Taxas">
												<InputNumberFormat className="text-end" name="vlBaixaTaxa" value={this.state.model.vlBaixaTaxa} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} prefix="R$ " readOnly={true} opcional={true} />
											</FormItem>
											<FormItem md={3} label="Total Baixado">
												<InputNumberFormat className="text-end" name="vlBaixaFinal" value={this.state.model.vlBaixaFinal} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} prefix="R$ " readOnly={true} opcional={true} />
											</FormItem>
											
											<FormItem md={2} label="Juros?">
												<BtnRadioGroupSimNao name="flJuros" value={this.state.model.flJuros} disabled={true} opcional={true} />
											</FormItem>
											<FormItem md={2} label="Multa?">
												<BtnRadioGroupSimNao name="flMulta" value={this.state.model.flMulta} disabled={true} opcional={true} />
											</FormItem>
											<FormItem md={2} label="Juros">
												<InputText className="text-center" name="vlJuros" value={`${Util.floatToValor(this.state.model.vlJuros, 2)} %`} maxLength={100} readOnly={true} opcional={true}/>
											</FormItem>
											<FormItem md={2} label="Multa">
												<InputText className="text-center" name="vlJuros" value={`${Util.floatToValor(this.state.model.vlMulta, 2)} %`} maxLength={100} readOnly={true} opcional={true}/>
											</FormItem>
											<FormItem md={2} label="Juros">
												<InputNumberFormat className="text-end text-danger fw-bold" name="vlJurosHoje" value={this.state.model.vwFaturaCobranca?.vlJurosHoje} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} prefix="R$ " readOnly={true} opcional={true} />
											</FormItem>
											<FormItem md={2} label="Multa">
												<InputNumberFormat className="text-end text-danger fw-bold" name="vlMultaHoje" value={this.state.model.vwFaturaCobranca?.vlMultaHoje} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} prefix="R$ " readOnly={true} opcional={true} />
											</FormItem>
											<FormItem md={3} label="Vencido Há" show={this.state.model.vwFaturaCobranca?.flVencido}>
												<InputText className="text-center text-danger fw-bold" name="vlJuros" value={`${this.state.model.vwFaturaCobranca?.nrDiasVencido} dia(s)`} maxLength={100} readOnly={true} opcional={true}/>
											</FormItem>
											<FormItem md={3} label="Vence em" show={!this.state.model.vwFaturaCobranca?.flVencido}>
												<InputText className="text-center text-success fw-bold" name="vlJuros" value={`${this.state.model.vwFaturaCobranca?.nrDiasVencer} dia(s)`} maxLength={100} readOnly={true} opcional={true}/>
											</FormItem>

											<Col sizing={6}/>
											<FormItem md={3} label="Valor Cobrado">
												<InputNumberFormat className="text-end text-danger fw-bold" name="vlCobrado" value={this.state.model.vlCobrado} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} prefix="R$ " readOnly={true} opcional={true} />
											</FormItem>

											<BtnSubmit hidden > Enviar </BtnSubmit>
										</Row>
									</Form>
								</Tab>
								{/*
								<Tab show={Permissao.getInstance().getPermissoes([69])} title="Tipo Pagamento" disabled={!this.state.model.idFaturaCobranca} opened={false} className="m-2">
									<ManyToManyCheckFaturaCobrancaTipoPagamentoByTipoPagamento typeCheck={ManyToManyCheckFaturaCobrancaTipoPagamentoByTipoPagamento.typeCheck.box} parent={this.state.model} search={true}/>
								</Tab>*/}
								<Tab show={Permissao.getInstance().getPermissoes([88])} title="Transações" disabled={!this.state.model.idFaturaCobranca} opened={false} className="m-2">
									<ListFaturaCobrancaTransacao localOpen={BaseCrud.localOpen.embedded} faturaCobranca={this.state.model}/>
								</Tab>
								<Tab show={Permissao.getInstance().getPermissoes([89])} title="Baixas" disabled={!this.state.model.idFaturaCobranca} opened={false} className="m-2">
									<ListFaturaCobrancaBaixa localOpen={BaseCrud.localOpen.embedded} faturaCobranca={this.state.model}/>
								</Tab>
							</TabNavigator>
						</PanelBody>
						<PanelFooter>							
							<BtnButton sizing={Button.sizing.default} color={Button.color.outlineSecondary} onClick={this.handleClose} > Fechar </BtnButton>
						</PanelFooter>
					</Panel>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <FormFaturaCobranca {...props} navigate={navigate} params={params} location={location}/>
}
export default With