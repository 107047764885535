import Auth from "../controllers/Auth";
import Http from "../controllers/Http";

export default class LoginService {

    static login(data, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .post("/public/login/", data)
            .then(fnSucess)
            .catch(fnError);
    }

    static validateToken(fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .get("/login/validate/")
            .then((response) => {
                Auth.login();
                this.refreshToken(fnSucess);
            })
            .catch(fnError);
    }

    static validateLogged(fnSucess = () => { }, fnError = () => { }) {
        if (Auth.isAuthenticated()) {
            Http.getInstanceAppJson({ showLoadding: false })
                .get("/login/validate/")
                .then(fnSucess)
                .catch(fnError);
        }
    }

    static refreshToken(fnSucess = () => { }, fnError = () => { }) {
        if (Auth.isAuthenticated()) {
            var data = { refreshToken: Auth.getRefreshToken() };
            Http.getInstanceAppJson({ showLoadding: false })
                .post("/login/refresh/", data)
                .then((response) => {
                    Auth.login(response.token, response.refreshToken);
                    return response;
                })
                .then(fnSucess)
                .catch(fnError);
        }
    }

    static getToken(id, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .get('/login/token/' + id)
            .then(fnSucess)
            .catch(fnError);
    }

}
