import Config from "./Config";
import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import SelectTipoTransacao from "../../components/SelectDB/SelectTipoTransacao";
import SelectTipoPagamento from "../../components/SelectDB/SelectTipoPagamento";
import SelectConta from "../../components/SelectDB/SelectConta";
import SelectUsuario from "../../components/SelectDB/SelectUsuario";
import SelectFaturaCobrancaTipoPagamentoParcela from "../../components/SelectDB/SelectFaturaCobrancaTipoPagamentoParcela";
import { BaseCrud } from "../../siht/base";
import { FormButtons, FormItem, Row, Panel, PanelBody, PanelFooter, Container, ModalDialog } from "../../siht/components";
import { InputText, InputNumberFormat, InputDate, Form } from "../../siht/elements";
import { BtnRadioGroupSimNao } from "../../components/Custom";

class FormFaturaCobrancaTransacao extends BaseCrud {

	constructor(props) {
		super(props);
		this.init = this.init.bind(this);
		this.initModel = this.initModel.bind(this);
		this.handleSuccess = this.handleSuccess.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleChangeModel = this.handleChangeModel.bind(this);
		this.handleSubmitForm = this.handleSubmitForm.bind(this);
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		super.init(Config);
	}

	initModel(){
		return {
			idFaturaCobrancaTransacao : null,
			faturaCobranca : this.props.parent !== undefined ? this.props.parent : {},
			tipoTransacao : null,
			tipoPagamento : null,
			conta : null,
			usuario : null,
			faturaCobrancaTipoPagamentoParcela : null,
			vlCobranca : 0,
			vlEncargo : 0,
			vlTransacao : 0,
			vlTaxa : 0,
			vlJuros : 0,
			vlMulta : 0,
			dtVencimento : "",
			cdUid : "",
			flEstorno : false,
			flEfetivada : true
		}
	}

	handleSuccess(e) {
		super.handleSuccess(e, Config);
	}

	handleClose(e) {
		super.handleClose(e, Config);
	}

	render() {
		return super.render(

					<Form onSubmit={this.handleSuccess} refParam={f => (this.form = f)} key={this.state.key}>
						<Panel key={this.state.key} title="Cadastro de FaturaCobrancaTransacao" localOpen={this.props.localOpen}  onClose={this.handleClose} isModal={this.isModal()} container={{sizing: Container.sizing.xxl, className : ""}} modal={{sizing: this.props.sizing ? this.props.sizing : ModalDialog.sizing.xl}}>
							<PanelBody>
								<Row className="g-3 pb-2 px-2">
									<FormItem md={6} label="Código">
										<InputText name="idFaturaCobrancaTransacao" value={this.state.model.idFaturaCobrancaTransacao} readOnly={true} className="readonly-field" required={false} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={12} label="Nome do TipoTransacao">
										<SelectTipoTransacao name="tipoTransacao" value={this.state.model.tipoTransacao} required={true} onChange={this.handleChangeModel} placeholder="Selecione Nome do TipoTransacao"/>
									</FormItem>
									<FormItem md={12} label="Nome do TipoPagamento">
										<SelectTipoPagamento name="tipoPagamento" value={this.state.model.tipoPagamento} required={true} onChange={this.handleChangeModel} placeholder="Selecione Nome do TipoPagamento"/>
									</FormItem>
									<FormItem md={12} label="Nome do Conta">
										<SelectConta name="conta" value={this.state.model.conta} required={true} onChange={this.handleChangeModel} placeholder="Selecione Nome do Conta"/>
									</FormItem>
									<FormItem md={12} label="Nome do Usuario">
										<SelectUsuario name="usuario" value={this.state.model.usuario} required={true} onChange={this.handleChangeModel} placeholder="Selecione Nome do Usuario"/>
									</FormItem>
									<FormItem md={12} label="Nome do FaturaCobrancaTipoPagamentoParcela">
										<SelectFaturaCobrancaTipoPagamentoParcela name="faturaCobrancaTipoPagamentoParcela" value={this.state.model.faturaCobrancaTipoPagamentoParcela} required={true} onChange={this.handleChangeModel} placeholder="Selecione Nome do FaturaCobrancaTipoPagamentoParcela"/>
									</FormItem>
									<FormItem md={4} label="VlCobranca">
										<InputNumberFormat name="vlCobranca" value={this.state.model.vlCobranca} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} prefix="R$ " required={true} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={4} label="VlEncargo">
										<InputNumberFormat name="vlEncargo" value={this.state.model.vlEncargo} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} prefix="R$ " required={true} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={4} label="VlTransacao">
										<InputNumberFormat name="vlTransacao" value={this.state.model.vlTransacao} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} prefix="R$ " required={true} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={4} label="VlTaxa">
										<InputNumberFormat name="vlTaxa" value={this.state.model.vlTaxa} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} prefix="R$ " required={true} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={4} label="VlJuros">
										<InputNumberFormat name="vlJuros" value={this.state.model.vlJuros} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} prefix="R$ " required={true} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={4} label="VlMulta">
										<InputNumberFormat name="vlMulta" value={this.state.model.vlMulta} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} prefix="R$ " required={true} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={4} label="DtVencimento">
										<InputDate name="dtVencimento" value={this.state.model.dtVencimento} required={true} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={6} label="CdUid">
										<InputText name="cdUid" value={this.state.model.cdUid} maxLength={100} required={true} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={2} label="Estorno?">
										<BtnRadioGroupSimNao name="flEstorno" value={this.state.model.flEstorno} required={true} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={2} label="FlEfetivada">
										<BtnRadioGroupSimNao name="flEfetivada" value={this.state.model.flEfetivada} required={true} onChange={this.handleChangeModel}/>
									</FormItem>
								</Row>
							</PanelBody>
							<PanelFooter>
								<FormButtons onClose={this.handleClose} />
							</PanelFooter>
						</Panel>
					</Form>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <FormFaturaCobrancaTransacao {...props} navigate={navigate} params={params} location={location}/>
}
export default With