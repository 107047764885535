import UsuarioTipoPrecoService from "../../services/UsuarioTipoPrecoService";
import FormUsuarioTipoPreco from "./FormUsuarioTipoPreco";
import ListUsuarioTipoPreco from "./ListUsuarioTipoPreco";
import Permissao from "../../controllers/Permissao";

export default class Config {

	static getUrlForm() {
		return "/CadUsuarioTipoPreco";
	}

	static getUrlList() {
		return "/LstUsuarioTipoPreco";
	}

	static getComponentForm() {
		return FormUsuarioTipoPreco;
	}

	static getPermissionForm() {
		return Permissao.getInstance().getPermissoes([15]);
	}

	static getPermissionList() {
		return Permissao.getInstance().getPermissoes([15]);
	}

	static getComponentList() {
		return ListUsuarioTipoPreco;
	}

	static getService() {
		return UsuarioTipoPrecoService;
	}

	static getKeyId() {
		return "idUsuarioTipoPreco";
	}

	static getKeyDescription() {
		return "nmUsuarioTipoPreco";
	}
}