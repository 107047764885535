import Config from "./Config";
import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import SelectConta from "../../components/SelectDB/SelectConta";
import { BaseCrud } from "../../siht/base";
import { FormButtons, FormItem, Row, Panel, PanelBody, PanelFooter, Container, ModalDialog } from "../../siht/components";
import { InputText, TextArea, InputNumberFormat, InputDate, InputNumber, Form } from "../../siht/elements";
import { BtnRadioGroupSimNao } from "../../components/Custom";

class FormFaturaCobrancaPix extends BaseCrud {

	constructor(props) {
		super(props);
		this.init = this.init.bind(this);
		this.initModel = this.initModel.bind(this);
		this.handleSuccess = this.handleSuccess.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleChangeModel = this.handleChangeModel.bind(this);
		this.handleSubmitForm = this.handleSubmitForm.bind(this);
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		super.init(Config);
	}

	initModel(){
		return {
			idFaturaCobrancaPix : null,
			faturaCobrancaTransacao : this.props.parent !== undefined ? this.props.parent : {},
			cdPixCode : "",
			cdTxId : "",
			cdChavePix : "",
			vlPix : 0,
			dtVencimento : "",
			nrDiaAgenda : null,
			flJuros : true,
			flMulta : true,
			vlJuros : 0,
			vlMulta : 0,
			cdTipoJuros : "",
			cdTipoMulta : "",
			cdLocationUrl : "",
			cdLocationId : "",
			flRegistrar : true,
			flRegistrado : true,
			conta : null
		}
	}

	handleSuccess(e) {
		super.handleSuccess(e, Config);
	}

	handleClose(e) {
		super.handleClose(e, Config);
	}

	render() {
		return super.render(

					<Form onSubmit={this.handleSuccess} refParam={f => (this.form = f)} key={this.state.key}>
						<Panel key={this.state.key} title="Cadastro de FaturaCobrancaPix" localOpen={this.props.localOpen}  onClose={this.handleClose} isModal={this.isModal()} container={{sizing: Container.sizing.xxl, className : ""}} modal={{sizing: this.props.sizing ? this.props.sizing : ModalDialog.sizing.xl}}>
							<PanelBody>
								<Row className="g-3 pb-2 px-2">
									<FormItem md={6} label="Código">
										<InputText name="idFaturaCobrancaPix" value={this.state.model.idFaturaCobrancaPix} readOnly={true} className="readonly-field" required={false} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={6} label="CdPixCode">
										<InputText name="cdPixCode" value={this.state.model.cdPixCode} maxLength={100} required={true} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={12} label="CdTxId">
										<TextArea name="cdTxId" value={this.state.model.cdTxId} required={true} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={6} label="CdChavePix">
										<InputText name="cdChavePix" value={this.state.model.cdChavePix} maxLength={100} required={true} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={4} label="VlPix">
										<InputNumberFormat name="vlPix" value={this.state.model.vlPix} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} prefix="R$ " required={true} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={4} label="DtVencimento">
										<InputDate name="dtVencimento" value={this.state.model.dtVencimento} required={true} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={6} label="NrDiaAgenda">
										<InputNumber name="nrDiaAgenda" value={this.state.model.nrDiaAgenda} required={false} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={2} label="FlJuros">
										<BtnRadioGroupSimNao name="flJuros" value={this.state.model.flJuros} required={true} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={2} label="FlMulta">
										<BtnRadioGroupSimNao name="flMulta" value={this.state.model.flMulta} required={true} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={4} label="VlJuros">
										<InputNumberFormat name="vlJuros" value={this.state.model.vlJuros} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} prefix="R$ " required={true} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={4} label="VlMulta">
										<InputNumberFormat name="vlMulta" value={this.state.model.vlMulta} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} prefix="R$ " required={true} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={6} label="CdTipoJuros">
										<InputText name="cdTipoJuros" value={this.state.model.cdTipoJuros} maxLength={100} required={true} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={6} label="CdTipoMulta">
										<InputText name="cdTipoMulta" value={this.state.model.cdTipoMulta} maxLength={100} required={true} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={6} label="CdLocationUrl">
										<InputText name="cdLocationUrl" value={this.state.model.cdLocationUrl} maxLength={100} required={true} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={6} label="CdLocationId">
										<InputText name="cdLocationId" value={this.state.model.cdLocationId} maxLength={100} required={true} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={2} label="FlRegistrar">
										<BtnRadioGroupSimNao name="flRegistrar" value={this.state.model.flRegistrar} required={true} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={2} label="FlRegistrado">
										<BtnRadioGroupSimNao name="flRegistrado" value={this.state.model.flRegistrado} required={true} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={12} label="Nome do Conta">
										<SelectConta name="conta" value={this.state.model.conta} required={true} onChange={this.handleChangeModel} placeholder="Selecione Nome do Conta"/>
									</FormItem>
								</Row>
							</PanelBody>
							<PanelFooter>
								<FormButtons onClose={this.handleClose} />
							</PanelFooter>
						</Panel>
					</Form>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <FormFaturaCobrancaPix {...props} navigate={navigate} params={params} location={location}/>
}
export default With