import Http from "../controllers/Http";

export default class FaturaNotaFiscalEnderecoService {

	static urlBase = "/faturaNotaFiscalEndereco/";

	static getForCombo(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(FaturaNotaFiscalEnderecoService.urlBase + 'forCombo', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static list(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(FaturaNotaFiscalEnderecoService.urlBase + 'list', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneById(id, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.get(FaturaNotaFiscalEnderecoService.urlBase + id)
			.then(fnSucess)
			.catch(fnError);
	}

	static create(data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(FaturaNotaFiscalEnderecoService.urlBase, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static update(id, data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.put(FaturaNotaFiscalEnderecoService.urlBase + id, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static delete(id, fnSucess = () => { }, fnError = () => { }) {
		if(Number.isInteger(id)){
			Http.getInstanceAppJson()
				.delete(FaturaNotaFiscalEnderecoService.urlBase + id)
				.then(fnSucess)
				.catch(fnError);
		}else{
			Http.getInstanceAppJson()
			.post(FaturaNotaFiscalEnderecoService.urlBase + 'delete', id)
			.then(fnSucess)
			.catch(fnError);
		}
	}

	static save(data, fnSucess = () => { }, fnError = () => { }) {
		if (data.idFaturaNotaFiscalEndereco) {
			this.update(data.idFaturaNotaFiscalEndereco, data, fnSucess, fnError);
		} else {
			this.create(data, fnSucess, fnError);
		}
	}

	static getAllByFaturaNotaFiscal(faturaNotaFiscal, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(FaturaNotaFiscalEnderecoService.urlBase + 'faturaNotaFiscal', faturaNotaFiscal)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByFaturaNotaFiscal(faturaNotaFiscal, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(FaturaNotaFiscalEnderecoService.urlBase + 'faturaNotaFiscal/' + faturaNotaFiscal.idFaturaNotaFiscal)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByTipoEndereco(tipoEndereco, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(FaturaNotaFiscalEnderecoService.urlBase + 'tipoEndereco', tipoEndereco)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByTipoEndereco(tipoEndereco, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(FaturaNotaFiscalEnderecoService.urlBase + 'tipoEndereco/' + tipoEndereco.idTipoEndereco)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByEndereco(endereco, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(FaturaNotaFiscalEnderecoService.urlBase + 'endereco', endereco)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByEndereco(endereco, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(FaturaNotaFiscalEnderecoService.urlBase + 'endereco/' + endereco.idEndereco)
			.then(fnSucess)
			.catch(fnError);
	}
}