//#NO-CHANGE-FILE#

//import CidadeService from "../services/CidadeService";
import Config from "../../view/Cidade/Config";
import {InputSearchDB} from "../../siht/elements";
//import Permissao from "../controllers/Permissao";

export default class InputCidade extends InputSearchDB {

    constructor(props) {
        super(props);
        this.getOptionsWhen = this.getOptionsWhen.bind(this);
    }

    getForComboService(){
        return Config.getService().getForCombo;
    }

    getDefault() {
        return this.state?.options.find(item => item?.flPadrao);
    }

    getFormCrud(){
        return Config.getComponentForm();
    }

    getPermissionFormCrud(){
        return Config.getPermissionForm();
    }

    getConfigSearch(){
        return [
            {
                key : Config.getKeyId(),
                label : "Código",
                className: "text-center"
            },
            {
                key : Config.getKeyDescription(),
                label : "Nome",
            },
            {
                key : "estado",
                label : "Estado",
                fnTransform : (estado) => estado.nmEstado + " - " + estado.cdSigla
            },
            {
                key : "estado",
                label : "País",
                fnTransform : (estado) => estado.pais.nmPais + " - " + estado.pais.cdIso3
            },
        ];
    }

    getOptionsWhen(filter){
        return super.getOptionsWhen(filter);
    }

    getOptionValue(item) {
        if(item && item[Config.getKeyId()]){
            return item[Config.getKeyId()];
        }
    }

    getOptionLabel(item) {
        if(item){
            return item[Config.getKeyDescription()] + " - " + item?.estado?.cdSigla + " - " + item?.estado?.pais?.cdIso3;
        }
    }

    render() {
        return super.render();
    }
}