import FuncionalidadePluginService from "../../services/FuncionalidadePluginService";
import { ManyToManyCheck } from "../../siht/components";

export default class ManyToManyCheckFuncionalidadePlugin extends ManyToManyCheck {

    constructor(props) {
        super(props);
        this.getOptionsWhen = this.getOptionsWhen.bind(this);
        this.getForComboService = FuncionalidadePluginService.getForCombo;
    }

    getOptionsWhen(item){
        return true;
    }

    getOptionValue(item) {
        return item?.idFuncionalidadePlugin;
    }

    getOptionLabel(item) {
        return item?.nmFuncionalidadePlugin;
    }

    /*getOptionGroup(item){
        return false;
    }*/

    /*getColumns(){
        return 2;
    }*/

    render() {
        return super.render();
    }
}