//#NO-CHANGE-FILE#

import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import ListContaReceberParcela from "../ContaReceberParcela/ListContaReceberParcela";
import ListContaReceberArquivo from "../ContaReceberArquivo/ListContaReceberArquivo";
import Permissao from "../../controllers/Permissao";
import { BaseList, BaseCrud } from "../../siht/base";
import { FormButtons, FormItem, Row, TabNavigator, Tab, Panel, PanelBody, PanelFooter, Container, ModalDialog, ProgressBar } from "../../siht/components";
import { InputText, TextArea, Form, BtnSubmit, H6, InputDate } from "../../siht/elements";
import Config from "./Config";
import FormRecorrencia from "../Recorrencia/FormRecorrencia";
import FormItemTipoPessoaPessoa from "../../components/FormItem/FormItemTipoPessoaPessoa";
import FormItemEmpresaUnidade from "../../components/FormItem/FormItemEmpresaUnidade";
import SelectTipoContaReceber from "../../components/SelectDB/SelectTipoContaReceber";
import { StatusCustom } from "../../components/Custom";

class FormContaReceber extends BaseCrud {

	constructor(props) {
		super(props);
		this.init = this.init.bind(this);
		this.initModel = this.initModel.bind(this);
		this.handleSuccess = this.handleSuccess.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleChangeModel = this.handleChangeModel.bind(this);
		this.handleSubmitForm = this.handleSubmitForm.bind(this);
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		super.init(Config);
	}

	initModel(){
		var tzoffset = (new Date()).getTimezoneOffset() * 60000;
        var dtContaReceber = new Date();

		return {
			idContaReceber : null,
			tipoContaReceber : null,
			pessoa : null,
			fatura : null,
			unidade : null,
			usuario : null,
			recorrencia : null,
			nmContaReceber : "",
			dtContaReceber : new Date(dtContaReceber - tzoffset).toISOString().slice(0, 10),
			txObservacao : "",
			flPodeEditar : true,
			flPodeExcluir : true,
			flPodeBaixar : true,
			contaReceberParcela : []
		}
	}

	handleSuccess(e) {
		super.handleSuccess(e, Config);
	}

	handleClose(e) {
		super.handleClose(e, Config);
	}

	render() {

		let disabled = !this.state.model.flPodeEditar;

		return super.render(
			<Panel key={this.state.key} title="Cadastro de Conta a Receber" onClose={this.handleClose} isModal={this.isModal()} localOpen={this.props.localOpen} container={{sizing: Container.sizing.xxl, className : ""}} modal={{sizing: this.props.sizing ? this.props.sizing : ModalDialog.sizing.xl}}>
				<PanelBody className="p-0 m-0">
					<TabNavigator key={this.state.key} onChangeTab={this.handleChangeTab} className="border-light">
						<Tab title="Conta a Receber" className="m-2">
							<Form onSubmit={this.handleSuccess} refParam={f => (this.form = f)}>
								
								<Row className="g-3 py-2 px-2" show={this.state.model.idContaReceber}>
									<FormItem md={12} label="Progresso do Pagamento">
										<ProgressBar height="30px" opcional={true} color={ProgressBar.color.success} progress={((this.state.model?.nrParcelaPaga/this.state.model?.recorrencia?.nrParcela)*100)} label={`${parseInt((this.state.model?.nrParcelaPaga/this.state.model?.recorrencia?.nrParcela)*100)}%`}/>
									</FormItem>
								</Row>

								<Row className="g-3 pb-2 px-2">
									<FormItem md={2} label="Código">
										<InputText name="idContaReceber" value={this.state.model.idContaReceber} readOnly={true} className="readonly-field" required={false} onChange={this.handleChangeModel}/>
									</FormItem>

									<FormItem md={8} label="Título da Conta a Receber">
										<InputText name="nmContaReceber" value={this.state.model.nmContaReceber} autoFocus={true} maxLength={100} required={true} onChange={this.handleChangeModel} disabled={disabled}/>
									</FormItem>

									<FormItem md={2} label="Data da Conta">
										<InputDate name="dtContaReceber" value={this.state.model.dtContaReceber} required={true} opcional={true}  className="text-center"  onChange={this.handleChangeModel} disabled={disabled}/>
									</FormItem>

									<FormItem md={8} label="Tipo da Conta">
										<SelectTipoContaReceber name="tipoContaReceber" value={this.state.model.tipoContaReceber} required={false} onChange={this.handleChangeModel} placeholder="Selecione o Tipo da Conta" disabled={disabled}/>
									</FormItem>

									<FormItem md={4} label="Status" >	
										<StatusCustom show={this.state.model.idContaReceber} opcional={true} type="InputText" color={this.state.model?.vwContaReceber?.statusConta}>{this.state.model?.vwContaReceber?.statusConta?.nmStatusConta}</StatusCustom>
										<StatusCustom show={!this.state.model.idContaReceber} opcional={true} type="InputText" color={{cdCorFundo : "#FFFFFF", cdCorTexto : "#079600", cdCorBorda : "#079600"}}> NOVA CONTA </StatusCustom>
									</FormItem>

									<FormItem md={12} label="Observação">
										<TextArea name="txObservacao" value={this.state.model.txObservacao} required={false} onChange={this.handleChangeModel} disabled={disabled}/>
									</FormItem>

									<FormItemEmpresaUnidade disabled={this.state.model.idContaReceber || disabled} md={[6,6]} name="unidade" value={this.state.model.unidade} required={true} onChange={this.handleChangeModel}/>

									<FormItemTipoPessoaPessoa disabled={disabled} type={["BtnRadioGroup"]} label={["Tipo Fornecedor", "Fornecedor"]} md={[4,8]} name="pessoa" value={this.state.model.pessoa} required={false} onChange={this.handleChangeModel}/>
								</Row>

								<Row className="g-3 py-2 px-2">
									<H6 className="border-dark border-bottom fw-bold"> Recorrência </H6>
								</Row>

								<FormRecorrencia name="recorrencia" onChange={this.handleChangeModel} value={this.state.model.recorrencia} disabled={disabled}/>
								

								<BtnSubmit hidden > Enviar </BtnSubmit>
							</Form>
							
						</Tab>
						<Tab show={Permissao.getInstance().getPermissoes([189])} title="Parcelas" disabled={!this.state.model.idContaReceber} opened={false} className="m-2">
							<ListContaReceberParcela parent={this.state.model} localOpen={BaseList.localOpen.modal} disabled={disabled}/>
						</Tab>
						<Tab show={Permissao.getInstance().getPermissoes([189])} title="Arquivos" disabled={!this.state.model.idContaReceber} opened={false} className="m-2">
							<ListContaReceberArquivo parent={this.state.model} localOpen={BaseList.localOpen.modal}/>
						</Tab>
					</TabNavigator>
				</PanelBody>
				<PanelFooter>
					<FormButtons show={this.state.tabIndex === 0} onClose={this.handleClose} onSubmit={this.handleSubmitForm} disabled={disabled}/>
				</PanelFooter>
			</Panel>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <FormContaReceber {...props} navigate={navigate} params={params} location={location}/>
}
export default With