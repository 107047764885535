//#NO-CHANGE-FILE#
import Config from "../../view/FaturaCobrancaTipoPagamentoParcela/Config";
import {Select } from "../../siht/elements";
import Util from "../../utils/Util";

export default class SelectFaturaCobrancaTipoPagamentoParcela extends Select {

    constructor(props) {
        super(props);
        this.getOptionsWhen = this.getOptionsWhen.bind(this);
    }

    getForComboService(){
        return Config.getService().getPublicForCombo;
    }

    getDefault() {
        return this.state?.options.find(item => item?.flPadrao);
    }

    getOptionsWhen(filter){
        return filter.faturaCobrancaTipoPagamento?.idFaturaCobrancaTipoPagamento;
    }

    getOptionValue(item) {
        if(item && item[Config.getKeyId()]){
            return item[Config.getKeyId()];
        }
    }

    getOptionLabel(item) {
        if(item){

            let labelJuros = "sem juros";

            if(parseFloat(item.pcTaxa) > 0){
                labelJuros = `com juros de ${Util.floatToValor(item.pcTaxa, 2)}%`;
            }else if(parseFloat(item.vlTaxa) > 0){
                labelJuros = `com juros de ${Util.floatToReais(item.vlTaxa, 2)}`;
            }

            return `${item.nrParcela}x ${labelJuros}`;
        }
    }

    render() {
        return super.render();
    }
}