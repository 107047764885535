import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import Form from "../elements/Form";
import InputText from "../elements/InputText";
import Panel from "../components/Panel";
import PanelFooter from "../components/PanelFooter";
import PanelBody from "../components/PanelBody";
import { Functions } from "../util";
import InputRadio from "../elements/InputRadio";
import BtnSubmit from "../elements/BtnSubmit";
import BtnButton from "../elements/BtnButton";
import { BaseCrud } from "../base";
import { Button, Caption, Div, TBody, THead, Table, Td, Th, Tr } from "../elements";
import PanelSubHeader from "./PanelSubHeader";
import ModalDialog from "./ModalDialog";

class PanelSearch extends BaseCrud {

    constructor(props) {
        super(props);

        this.init = this.init.bind(this);        
        this.handleClose = this.handleClose.bind(this);
        this.handleChangeModel = this.handleChangeModel.bind(this);
        this.getResultFilter = this.getResultFilter.bind(this);
        this.handleClickItem = this.handleClickItem.bind(this);
        this.handleChangeItem = this.handleChangeItem.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleKeyUp = this.handleKeyUp.bind(this);
        this.handleSearchDB = this.handleSearchDB.bind(this);
        this.handleMore = this.handleMore.bind(this);

        this.configSearch = this.props.getConfigSearch();
        this.state._search = "";
        this.state.value = this.props.value;
        this.refSelected = React.createRef();
        this.refBusca = React.createRef();
        
        this.state.hasMore = false;
        this.state.filter = this.initStateFilter();

        this.state.options = this.props.options ? this.props.options : [];

        this.searchDB = this.props.searchDB !== undefined && this.props.searchDB;
    }

    componentDidMount() {
        super.componentDidMount();
        this.init();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    init() {
        if(this.refSelected?.current){
            this.refSelected.current.focus();
        }

        if(this.refBusca?.current){
            this.refBusca.current.focus();
        }

        if(this.searchDB){
            this.handleSearchDB();
        }
    }

    initStateFilter(){
        return {
            search: "",
            page : 1,
            pageSize : 15,
        };
    }

    getResultFilter() {
        if(this.searchDB){
            return this.state.options;
        }else{
            return this.state.options.filter(f =>
                Functions.searchTextInObject(f, this.configSearch.map(element => element.key), this.state.filter.search)
            );
        }
    }

    handleClickItem(value) {
        var selected = this.state.options.find(item => this.props.getOptionValue(item)?.toString() === value.toString());
        selected = selected === undefined ? "" : selected;
        this.setState({value : selected}, () => {
            if (this.refSelected?.current) {
                this.refSelected.current.focus();
            }
            this.props.handleSuccess(selected);
        });
    }

    handleChangeItem(e, value) {
        var selected = this.state.options.find(item => this.props.getOptionValue(item)?.toString() === value.toString());
        selected = selected === undefined ? "" : selected;
        this.setState({value : selected}, ()=>{
            //this.refSelected.current.focus();
        });
    }

    handleMore(){
        this.handleSearchDB(true);
	}

    handleSearchDB(more = false){

        const filter = Object.assign({}, this.props.filter);
        filter.search = this.state.filter.search;
        filter.page = this.state.filter.page;
        filter.pageSize = this.state.filter.pageSize;

        if(!more){
            filter.page=1;
        }else{
            filter.page+=1;
        }

        this.props.getForComboService(filter, response => {

            var callback =  () => {
                /*if(!this.props.getOptionValue(this.props.value)){
                    let item = this.props.getDefault();
                    if(item){
                        this.handleChange({}, item);
                    }
                }*/
            };

            if(more){
                this.setState(state => {
                    state.options = state.options.concat(response);
                    state.hasMore = (response.length === this.state.filter.pageSize) && response.length > 0;
                    state._search = this.state.filter.search;
                    state.filter.page+=1;
                    return state;
                }, callback);

            }else{
                this.setState(state =>{
                    state.options = response;
                    state.hasMore = (response.length === this.state.filter.pageSize);
                    state._search = this.state.filter.search;
                    state.filter.page = 1;
                    state.selecteds = [];
                    return state;
                }, callback);
            }

            //this.setState({options : response, _search : this.state.filter.search})
        });
    }

    handleSubmit() {
        if(this.searchDB){

            if(this.state.filter.search === this.state._search){                
                this.props.handleSuccess(this.state.value);
            }else{
                this.handleSearchDB();
            }
        }else{
            this.props.handleSuccess(this.state.value);
        }
    }

    getIndexByKey(keyValue) {
        if (keyValue) {
            return this.getResultFilter().findIndex(item => this.props.getOptionValue(item).toString() === keyValue.toString());
        }
        return -1;
    }

    handleKeyUp(event) {

        var result = this.getResultFilter();
        var index = -1;
        var indexSet = -1;

        if (event.keyCode === 40) {
            index = this.getIndexByKey(this.props.getOptionValue(this.state.value));

            indexSet = -1;
            for (let i = 0; i < result.length; i++) {
                if (!result[i].disabled && index < i) {
                    indexSet = i;
                    break;
                }
            }

            if(this.searchDB && this.state.hasMore && indexSet === -1){
                this.handleSearchDB(true);
                return;
            }

            if (indexSet !== -1) {
                this.handleChangeItem(event, this.props.getOptionValue(result[indexSet]), true);
            } else if (this.refSelected.current) {
                this.refSelected.current.focus();
            }

        } else if (event.keyCode === 38) {
            index = this.getIndexByKey(this.props.getOptionValue(this.state.value));
            index = index === -1 ? result.length : index;

            indexSet = -1;
            for (let i = (result.length - 1); i > 0; i--) {
                if (!result[i].disabled && index > i) {
                    indexSet = i;
                    break;
                }
            }

            if (indexSet !== -1) {
                this.handleChangeItem(event, this.props.getOptionValue(result[indexSet]), true);
            } else if (this.refSelected.current) {
                this.refSelected.current.focus();
            }
        } else {
            if (result.length > 0) {
                this.handleChangeItem(event, this.props.getOptionValue(result[0]));
            }
        }
    }

    render() {
        return (
            <Form onSubmit={this.handleSubmit} style={{display : "inline"}}>
                <Panel title="Busca Avançada" onClose={this.handleClose} isModal={this.props.localOpen === BaseCrud.localOpen.modal} modal={{sizing: this.props.sizing ? this.props.sizing : ModalDialog.sizing.xl}}>
                    <PanelSubHeader className="p-3 pb-0">
                        <InputText placeholder="Digite sua busca" refParam={this.refBusca} value={this.state.filter.search} autoComplete="off" autoFocus name="search" onChange={(e,v,p) => this.handleChange(e,v,p, this.state.filter)} onKeyUp={this.handleKeyUp}/>
                    </PanelSubHeader>                    
                    <PanelBody>
                        
                        <Table hover={true} sizing={Table.sizing.sm} caption={Table.caption.top}>
                            <Caption className="text-end me-3" show={this.state.value}>
                                {this.props.getOptionValue(this.state.value)?.toString()} - {this.props.getOptionLabel(this.state.value)?.toString()}
                            </Caption>
                            <THead>
                                <Tr>
                                    {this.configSearch.map((header, index) => (
                                        <Th className={header.className ? header.className : ''} key={index}> {header.label} </Th>
                                    ))}
                                    <Th className="text-center"> # </Th>
                                </Tr>
                            </THead>
                            <TBody>
                                {this.getResultFilter().map((option, indexData) => (
                                    <Tr key={indexData}
                                        className={`${this.props.getOptionValue(this.state.value)?.toString() === this.props.getOptionValue(option)?.toString() ? 'table-primary' : ''} ${option.className ? option.className : ""}`}>
                                        {this.configSearch.map((header, indexHeader) => (
                                            <Td onClick={() => { if (!option.disabled) { this.handleClickItem(this.props.getOptionValue(option), true) } }} className={header.className ? header.className : ''} key={indexHeader}>
                                                {header.fnTransform ? header.fnTransform(option[header.key], option) : option[header.key]}
                                            </Td>
                                        ))}
                                        <Td className="text-center">
                                            {option.disabled ? <></> :
                                                <InputRadio
                                                    refParam={this.props.getOptionValue(this.state.value)?.toString() === this.props.getOptionValue(option)?.toString() ? this.refSelected : null}
                                                    className="form-check-input" type="radio" name="value"
                                                    checked={this.props.getOptionValue(this.state.value)?.toString() === this.props.getOptionValue(option)?.toString()}
                                                    value={this.props.getOptionValue(option)?.toString()}
                                                    onChange={this.handleChangeItem}
                                                    disabled={option.disabled}
                                                />
                                        }
                                        </Td>
                                    </Tr>
                                ))}
                            </TBody>
                        </Table>

                        <Div className="text-center py-1" show={this.searchDB && this.state.options && this.state.options?.length > 0}>
                            <BtnButton className onClick={this.handleMore} disabled={!this.state.hasMore}> Buscar Mais Itens </BtnButton>
                        </Div>

                    </PanelBody>
                    <PanelFooter>
                        <Div className="d-grid gap-2 d-md-flex">
                            <BtnSubmit color={Button.color.primary} disabled={!this.state.value}> Selecionar </BtnSubmit>
                            <BtnButton color={Button.color.outlineSecondary} onClick={this.handleClose}> Cancelar </BtnButton>
                        </Div>
                    </PanelFooter>
                </Panel>
            </Form>
        );
    }
}

function With(props) {
    let navigate = useNavigate();
    let params = useParams();
    return <PanelSearch {...props} navigate={navigate} params={params} />
}

export default With