import Http from "../controllers/Http";

export default class FluxoItemService {

	static urlBase = "/fluxoItem/";

	static getForCombo(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(FluxoItemService.urlBase + 'forCombo', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static list(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(FluxoItemService.urlBase + 'list', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneById(id, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.get(FluxoItemService.urlBase + id)
			.then(fnSucess)
			.catch(fnError);
	}

	static create(data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(FluxoItemService.urlBase, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static update(id, data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.put(FluxoItemService.urlBase + id, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static delete(id, fnSucess = () => { }, fnError = () => { }) {
		if(Number.isInteger(id)){
			Http.getInstanceAppJson()
				.delete(FluxoItemService.urlBase + id)
				.then(fnSucess)
				.catch(fnError);
		}else{
			Http.getInstanceAppJson()
			.post(FluxoItemService.urlBase + 'delete', id)
			.then(fnSucess)
			.catch(fnError);
		}
	}

	static save(data, fnSucess = () => { }, fnError = () => { }) {
		if (data.idFluxoItem) {
			this.update(data.idFluxoItem, data, fnSucess, fnError);
		} else {
			this.create(data, fnSucess, fnError);
		}
	}

	static getAllByTipoFluxoItem(tipoFluxoItem, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(FluxoItemService.urlBase + 'tipoFluxoItem', tipoFluxoItem)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByTipoFluxoItem(tipoFluxoItem, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(FluxoItemService.urlBase + 'tipoFluxoItem/' + tipoFluxoItem.idTipoFluxoItem)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByFluxoVersao(fluxoVersao, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(FluxoItemService.urlBase + 'fluxoVersao', fluxoVersao)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByFluxoVersao(fluxoVersao, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(FluxoItemService.urlBase + 'fluxoVersao/' + fluxoVersao.idFluxoVersao)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByFluxoItemTarefa(fluxoItemTarefa, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(FluxoItemService.urlBase + 'fluxoItemTarefa', fluxoItemTarefa)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByFluxoItemTarefa(fluxoItemTarefa, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(FluxoItemService.urlBase + 'fluxoItemTarefa/' + fluxoItemTarefa.idFluxoItemTarefa)
			.then(fnSucess)
			.catch(fnError);
	}
}