//#NO-CHANGE-FILE#

import React from "react";
import { useNavigate, useLocation, useParams } from "react-router";
import BtnEdit from "../../components/BtnEdit";
import Config from "./Config";
import { BaseList } from "../../siht/base";
import { DataGrid, DataGridButtons, DataGridColumn, ModalDialog, Panel, Container, PanelFooter, Row, FormItem, InputGroup, InputGroupText, InputGroupButton } from "../../siht/components";
import { BtnButton, BtnSubmit, Button, Div, Form, InputDate, InputNumber, InputSearch, Span } from "../../siht/elements";
import FormRelatorioAns001 from "../Relatorio/FormRelatorioAns001";
import FormRelatorioAns002 from "../Relatorio/FormRelatorioAns002";
import FormRelatorioAns004 from "../Relatorio/FormRelatorioAns004";
import { Modal } from "../../siht/controller";
import { IconClear, IconConstructorHatHelmetProtectionWorker, IconSearch } from "../../icons";
import { PrinterFill as IconPrinterFill} from "react-bootstrap-icons";
import Util from "../../utils/Util";
import Permissao from "../../controllers/Permissao";
import SelectStatusOrdem from "../../components/SelectDB/SelectStatusOrdem";
import SelectTipoOrdem from "../../components/SelectDB/SelectTipoOrdem";
import SelectAnsStatus from "../../components/SelectDB/SelectAnsStatus";
import { FormItemEmpresaUnidade, FormItemTipoPessoaPessoa } from "../../components/FormItem";
import { BtnCopy, StatusCustom } from "../../components/Custom";
import { toast } from "react-toastify";
import { Tooltip } from "react-tooltip";

class ListAnsPerfil extends BaseList {

	constructor(props) {
		super(props);
		this.getItens = this.getItens.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleNew = this.handleNew.bind(this);
		this.handleEdit = this.handleEdit.bind(this);
		this.handleCopy = this.handleCopy.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.handleMore = this.handleMore.bind(this);
		this.onSubmitFilter = this.onSubmitFilter.bind(this);
		this.handleClear = this.handleClear.bind(this);
		this.handleFormSuccess = this.handleFormSuccess.bind(this);
		this.handleFormClose = this.handleFormClose.bind(this);
		this.handleImprimirAns = this.handleImprimirAns.bind(this);
		this.handleRelatorioAns = this.handleRelatorioAns.bind(this);
		this.handleChangeFilter = this.handleChangeFilter.bind(this);

		this.cache = {
            active: true,
            id: "cache-ListAnsPerfil",
            state: {
                filter : true
            },
            clear: true
        };
	}

	initStateFilter(){
        return {
			idAnsPerfil  :"",
			idOrdem  : "",
			statusOrdem : null,
			ansStatus : null,
			tipoOrdem : null,
			dtOrdemStart : "",
			dtOrdemEnd : "",
			dtAnsPerfilStart : "",
			dtAnsPerfilEnd : "",
			unidade : null,
            pessoa : null,
            search: "",
            page : 1,
            pageSize : 50,
        };
    }

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		super.init(Config);
	}

	getItens(more){
		super.getItens(Config.getService().list, false, more);
	}

	handleNew(e) {
		super.handleNew(e, Config, {sizing : ModalDialog.sizing.fullscreen });
	}

	handleEdit(e, item) {
		this.props.navigate(Config.getUrlForm() + "/" + item.idAnsPerfil, {state :{origem : "PERFIL"}});
	}

	handleDelete(e, item) {
		super.handleDelete(e, Config, item);
	}

	handleCopy(e, item) {
		Modal.confirm("Confirmação", "Deseja realmente COPIAR esse Perfil?" , null, Modal.style.warning, () =>{
			Config.getService().duplicar(item, (response)=> {
				toast.success("Perfil foi duplicada, novo perfil " + response.idAnsPerfil);
				this.props.navigate(Config.getUrlForm() + '/' + response.idAnsPerfil);
			});
		});
	}

	handleImprimirAns(e, ansPerfil = null) {
		if(ansPerfil){
			Modal.openWindow(FormRelatorioAns001, {sizing : Modal.sizing.default}, { idAnsPerfis : [ansPerfil.idAnsPerfil] });
		}else{
			let idAnsPerfis = this.state.selecteds.flatMap((o) => (o.idAnsPerfil));
			Modal.openWindow(FormRelatorioAns001, {sizing : Modal.sizing.default}, { idAnsPerfis : idAnsPerfis });
		}
	}

	handleRelatorioAns(e) {
		Modal.openWindow(FormRelatorioAns002, {sizing : Modal.sizing.default}, { idOrdens : [this.props.parent.idOrdem] });
	}

	handleImprimirMapa(e, ansPerfil = null) {
		Modal.openWindow(FormRelatorioAns004, {sizing : Modal.sizing.default}, { idOrdens : [ansPerfil.ordem.idOrdem] });
	}

	render() {

		return super.render(
			<Panel title="Lista de Perfil(is)" isModal={this.isModal()} container={{sizing: Container.sizing.fluid, className : ""}}>
				<Div className="p-2 mt-2">
					<Div className="d-flex justify-content-end flex-nowrap gap-2">					
						<BtnButton disabled={this.state.selecteds.length === 0} color={BtnButton.color.outlinePrimary} sizing={Button.sizing.default} onClick={this.handleImprimirAns} show={Permissao.getInstance().getPermissoes([115])}> <IconPrinterFill/> </BtnButton>
						<BtnButton disabled={this.state.data.length === 0} color={BtnButton.color.outlineDanger} sizing={Button.sizing.default} onClick={this.handleRelatorioAns} show={Permissao.getInstance().getPermissoes([116])}> <IconPrinterFill/> </BtnButton>
					</Div>

					<Form onSubmit={this.onSubmitFilter}>
						<Row className="g-3">
							<FormItem md={1} label="Perfil">
								<InputNumber name="idAnsPerfil" value={this.state?.filter?.idAnsPerfil} opcional={true} required={false} onChange={this.handleChangeFilter}/>
							</FormItem>

							<FormItem md={1} label="Ordem">
								<InputNumber name="idOrdem" value={this.state?.filter?.idOrdem} opcional={true} required={false} onChange={this.handleChangeFilter}/>
							</FormItem>

							<FormItem md={3} label="Tipo da Ordem">
								<SelectTipoOrdem name="tipoOrdem" value={this.state?.filter?.tipoOrdem} required={false} opcional={true} onChange={this.handleChangeFilter} placeholder="Selecione o Tipo da Ordem" buttons={{search : false, new : false, edit: false}}/>
							</FormItem>
							
							<FormItem md={3} label="Status da Ordem">
								<SelectStatusOrdem name="statusOrdem" value={this.state?.filter?.statusOrdem} required={false} opcional={true} onChange={this.handleChangeFilter} placeholder="Selecione o Status" buttons={{search : false, new : false, edit: false}}/>
							</FormItem>

							<FormItem md={4} label="Status do Perfil">
								<SelectAnsStatus name="ansStatus" value={this.state?.filter?.ansStatus} required={false} opcional={true} onChange={this.handleChangeFilter} placeholder="Selecione o Status" buttons={{search : false, new : false, edit: false}}/>
							</FormItem>
							
							<FormItem md={2} label="Data Inicial">
								<InputDate name="dtOrdemStart" value={this.state?.filter?.dtOrdemStart} required={false} opcional={true} onChange={this.handleChangeFilter}/>
							</FormItem>
							<FormItem md={2} label="Data Final">
								<InputDate name="dtOrdemEnd" min={this.state?.filter?.dtOrdemStart} value={this.state?.filter?.dtOrdemEnd} required={false} opcional={true} onChange={this.handleChangeFilter}/>
							</FormItem>

							<FormItemEmpresaUnidade md={[4,4]} name="unidade" opcional={true} value={this.state.filter.unidade} required={false} onChange={this.handleChangeFilter} buttons={{search : false, new : false, edit: false}}/>
                        	<FormItemTipoPessoaPessoa label={["Tipo Cliente", "Cliente"]} md={[2,4]} name="pessoa" value={this.state.filter.pessoa} required={false} onChange={this.handleChangeFilter} opcional={true}/>
						</Row>
						
						<Row className="mt-1 mb-2 g-3">
							<InputGroup className="flex-nowrap" sizing={InputGroup.sizing.default}>
								<InputGroupText><IconSearch/></InputGroupText>
								<InputSearch autoComplete="off" autoFocus placeholder="Digite sua busca" name="search" value={this.state?.filter?.search} required={false} opcional={true} onChange={(e,v,n) => this.handleChange(e,v,n, this.state.filter)}/>
								<InputGroupButton>
									<BtnSubmit title="Filtrar"> Buscar </BtnSubmit>
									<BtnButton title="Limpar Pesquisa" color={BtnButton.color.outlineDanger} onClick={this.handleClear}> <IconClear/> </BtnButton>
								</InputGroupButton>
							</InputGroup>
						</Row>
					</Form>

					<DataGrid onDoubleClickRow={this.handleEdit} responsive={true} data={this.state.data} search={false} isDisabled={i => false} sizing={DataGrid.sizing.sm} select={true} selecteds={this.state.selecteds} onSelect={(selecteds)=>{this.setState({selecteds : selecteds})}} getItemValue={i=>i.idAnsPerfil} onMore={this.handleMore}>
						<DataGridColumn label="Perfil" field={i => i.idAnsPerfil } className="text-center"/>
						<DataGridColumn label="Ordem" field={i => i.ordem.idOrdem } className="text-center"/>

						<DataGridColumn md={3} label="Cliente" field={i => <React.Fragment> 							
							<Span className="fw-bold">{i.ordem.pessoa?.nmPessoa}</Span> <br/>
							<StatusCustom color={i.ordem.pessoa?.tipoPessoa}> PESSOA {i.ordem.pessoa?.tipoPessoa?.nmTipoPessoa} </StatusCustom>
							<Div className="fw-bold fs-7" show={i.ordem.pessoa?.tipoPessoa?.cdTipoPessoa === "F"}>{Util.formataCPF(i.ordem.pessoa?.nrCpf)}</Div>
							<Div className="fw-bold fs-7" show={i.ordem.pessoa?.tipoPessoa?.cdTipoPessoa === "J"}>{Util.formataCnpj(i.ordem.pessoa?.nrCnpj)}</Div>
						</React.Fragment>}
						className="text-start"/>
						
						<DataGridColumn mobile={false} md={1} label="Tipo Ordem" field={i => 
							<React.Fragment> 
								<StatusCustom color={i.ordem.tipoOrdem}> {i.ordem.tipoOrdem.nmTipoOrdem} </StatusCustom>

								<Div className="mt-1">
									{i.ansPerfilPessoas !== undefined && i.ansPerfilPessoas.map((ansPerfilPessoa, index) => {
										let idHelp = Math.random();
										
										return (
											<React.Fragment key={index}>
												<span data-tooltip-id={`my-tooltip-styles-${idHelp}`}>
													<IconConstructorHatHelmetProtectionWorker width={24} height={24}/>
												</span>
												<Tooltip className="text-bg-primary" id={`my-tooltip-styles-${idHelp}`} style={{zIndex : 99999999999999}}>
													<div className="fw-bold" style={{maxWidth : `300px`, textAlign : "center"}}>
														{ansPerfilPessoa.pessoa.nmPessoa}
													</div>
													<div style={{maxWidth : `300px`, textAlign : "center"}}>
														{ansPerfilPessoa?.cargo?.nmCargo}
													</div>
												</Tooltip>
											</React.Fragment>
										);
									})}
								</Div>

							</React.Fragment>
						} className="text-center"/>

						<DataGridColumn col={1} label="Tipo Perfil" field={i => <>
							{i.tipoAnsPerfil?.cdTipoAnsPerfil}-{i.nrTipoAnsPerfil}
						</>} className="text-start"/>
						
						<DataGridColumn col={2} label="Status" field={i => <StatusCustom className="w-100" color={i.ansStatus}> {i.ansStatus?.nmAnsStatus} </StatusCustom>} className="text-center"/>

						<DataGridColumn mobile={false} col={3} label="Endereço" field={i => <>
							<Div>{i.endereco?.enderecoCompleto}</Div>
						</>} className="text-start"/>
						
						<DataGridColumn mobile={false} col={1} label="Inicio" field={i => Util.date2Br(i.dtInicio)} className="text-center"/>
						<DataGridColumn mobile={false} col={1} label="Fim" field={i => Util.date2Br(i.dtFim)} className="text-center"/>
						<DataGridColumn mobile={false} col={1} label="Prof." field={i => <>
							<Div>{Util.floatToValor(i.vlProfundidadeLimite, 2)}</Div>
							<Div>{Util.floatToValor(i.vlProfundidadeFuro, 2)}</Div>
						</>} className="text-center"/>
						<DataGridColumn mobile={false} col={1} label="NívelAgua" field={i => Util.floatToValor(i.vlProfundidadeNivelAgua, 2)} className="text-center"/>
						<DataGridColumn mobile={false} col={1} label="Cota" field={i => <>{Util.floatToValor(i.vlCota,2)} %</> } className="text-center"/>
						<DataGridColumn mobile={false} col={1} label="Site" field={i => i.dsSite} className="text-start"/>
						<DataGridButtons>
							<BtnCopy sizing={Button.sizing.default} onClick={this.handleCopy}/>
							<BtnEdit sizing={Button.sizing.default} onClick={this.handleEdit}/>
							<BtnButton color={BtnButton.color.outlinePrimary} sizing={Button.sizing.default} onClick={this.handleImprimirAns} show={Permissao.getInstance().getPermissoes([115])}> <IconPrinterFill/> </BtnButton>
							<BtnButton color={BtnButton.color.outlinePrimary} sizing={Button.sizing.default} onClick={this.handleImprimirMapa} show={Permissao.getInstance().getPermissoes([219])}> <IconPrinterFill/> </BtnButton>
						</DataGridButtons>
					</DataGrid>
				</Div>
				<PanelFooter className="text-center py-2" show={this.state.data && this.state.data?.length > 0}>
					<BtnButton onClick={this.handleMore} disabled={!this.state.hasMore}> Buscar Mais Itens </BtnButton>
				</PanelFooter>
			</Panel>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <ListAnsPerfil {...props} navigate={navigate} params={params} location={location}/>
}
export default With