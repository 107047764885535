import InputMask from "./InputMask";

export default class InputPlaca extends InputMask {
    
    constructor(props){
        super(props);
        this.modify = this.modify.bind(this);
        
        this.options = {
            mask: '___-#$##',
            replacement: { '#' : /\d/, _ : /[a-zA-Z]/, '$' : /[a-zA-Z0-9]/, '*' : /./},
        };

        this.withoutMask = false;
    }

    modify({ value }) {
    
            let replacement = { '#' : /\d/, _ : /[a-zA-Z]/, '$' : /[a-zA-Z0-9]/, '*' : /./};

            if(this.props?.pais?.cdEstrangeiro === "1058"){
                this.options = {
                    mask: '___-#$##',
                    replacement: replacement,
                };
            }else{
                this.options = {
                    mask: '***********************',
                    replacement: replacement,
                };
            }
    
            return this.options;
        }

    render() {
        const props = {};
        props.modify = this.modify;

        if(this.props?.pais?.cdEstrangeiro === "1058"){
            props.placeholder = "AAA-9A99";
        }else{
            props.placeholder = "Sem formato de Máscara";
        }
        
        return super.render(props);
    }
}