//#NO-CHANGE-FILE#

import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import BtnRadioGroupSimNao from "../../components/BtnRadioGroupSimNao";
import ListFaturaAcordoFaturaCobranca from "../FaturaAcordoFaturaCobranca/ListFaturaAcordoFaturaCobranca";
import Permissao from "../../controllers/Permissao";
import { BaseList, BaseCrud } from "../../siht/base";
import { FormItem, Row, TabNavigator, Tab, Panel, PanelBody, PanelFooter, Container, ModalDialog, Alert } from "../../siht/components";
import { InputText, InputNumberFormat, InputDate, TextArea, Form, BtnButton, Button, Div } from "../../siht/elements";
import Config from "./Config";
import Util from "../../utils/Util";
import { ExclamationTriangle as IconExclamationTriangle, HandThumbsUp as IconHandThumbsUp } from "react-bootstrap-icons";
import { StatusCustom } from "../../components/Custom";

class FormFaturaAcordo extends BaseCrud {

	constructor(props) {
		super(props);
		this.init = this.init.bind(this);
		this.initModel = this.initModel.bind(this);
		this.handleSuccess = this.handleSuccess.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleChangeModel = this.handleChangeModel.bind(this);
		this.handleSubmitForm = this.handleSubmitForm.bind(this);
		this.handleClickEfetivar = this.handleClickEfetivar.bind(this);
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		super.init(Config);
	}

	initModel(){
		return {
			idFaturaAcordo : null,
			statusFaturaAcordo : null,
			usuario : null,
			unidade : null,
			pessoa : null,
			vlAcordo : 0,
			vlDesconto : 0,
			vlAcrescimo : 0,
			flEncargos : true,
			flExpirado : true,
			flEfetivado : true,
			dtValidade : "",
			txObservacao : "",
			faturaAcordoFaturaCobranca : []
		}
	}

	handleSuccess(e) {
		super.handleSuccess(e, Config);
	}

	handleClose(e) {
		super.handleClose(e, Config);
	}

	handleClickEfetivar(e) {
		let faturaAcordo = this.state.model;
		this.props.navigate('/CadFaturar', { state : { ordens : [], pessoa : faturaAcordo.pessoa, unidade : faturaAcordo.unidade, faturaAcordo : faturaAcordo }});
	}

	render() {
		return super.render(
					<Panel key={this.state.key} title="Cadastro de Acordo" localOpen={this.props.localOpen} onClose={this.handleClose} isModal={this.isModal()} container={{sizing: Container.sizing.fluid, className : ""}} modal={{sizing: this.props.sizing ? this.props.sizing : ModalDialog.sizing.xl}}>
						<PanelBody className="p-0 m-0">
							<TabNavigator key={this.state.key} onChangeTab={this.handleChangeTab} className="border-light">
								<Tab title="Acordo" className="m-2">
									<Form onSubmit={this.handleSuccess} refParam={f => (this.form = f)}>
										<Row className="g-3 pb-2 px-2">
											
											<Alert color={Alert.color.danger} className="mt-4 py-2 mb-2" show={this.state.model.flExpirado}>
												<strong> <IconExclamationTriangle/> Atenção </strong>: Esse acordo expirou em <strong>{Util.date2Br(this.state.model.dtValidade)}</strong>
											</Alert>

											<FormItem md={2} label="Código">
												<InputText name="idFaturaAcordo" value={this.state.model.idFaturaAcordo} readOnly={true} className="readonly-field" required={false} onChange={this.handleChangeModel}/>
											</FormItem>
											<FormItem md={5} label="Usuário">
												<InputText value={this.state.model.usuario?.nmUsuario} readOnly={true} required={false}/>
											</FormItem>
											<FormItem md={5} label="Status">
												<StatusCustom className="text-center" type="InputText" opcional={true} color={this.state.model.statusFaturaAcordo}>{this.state.model.statusFaturaAcordo?.nmStatusFaturaAcordo}</StatusCustom>
											</FormItem>
											<FormItem md={6} label="Empresa">
												<InputText value={this.state.model.unidade?.empresa?.nmEmpresa} readOnly={true} required={false}/>
											</FormItem>
											<FormItem md={6} label="Unidade">
												<InputText value={this.state.model.unidade?.nmUnidade} readOnly={true} required={false}/>
											</FormItem>
											<FormItem md={3} label="Tipo Pessoa">
												<StatusCustom className="text-center" type="InputText" opcional={true} color={this.state.model.pessoa?.tipoPessoa}>{this.state.model.pessoa?.tipoPessoa?.nmTipoPessoa}</StatusCustom>
											</FormItem>
											<FormItem md={9} label="Pessoa">
												<InputText value={this.state.model.pessoa?.nmPessoa} readOnly={true} required={false}/>
											</FormItem>
											<FormItem md={4} label="Valor">
												<InputNumberFormat name="vlAcordo" className="fw-bold text-primary" readOnly={true} value={this.state.model.vlAcordo} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} prefix="R$ " required={true} onChange={this.handleChangeModel}/>
											</FormItem>
											<FormItem md={4} label="Desconto">
												<InputNumberFormat name="vlDesconto" className="fw-bold text-danger" readOnly={true} value={this.state.model.vlDesconto} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} prefix="R$ " required={true} onChange={this.handleChangeModel}/>
											</FormItem>
											<FormItem md={4} label="Acréscimo">
												<InputNumberFormat name="vlAcrescimo" className="fw-bold text-success" readOnly={true} value={this.state.model.vlAcrescimo} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} prefix="R$ " required={true} onChange={this.handleChangeModel}/>
											</FormItem>
											<FormItem md={2} label="Encargos">
												<BtnRadioGroupSimNao name="flEncargos" disabled={true} value={this.state.model.flEncargos} required={true} onChange={this.handleChangeModel}/>
											</FormItem>
											<FormItem md={2} label="Efetivado">
												<BtnRadioGroupSimNao name="flEfetivado" disabled={true} value={this.state.model.flEfetivado} required={true} onChange={this.handleChangeModel}/>
											</FormItem>
											<FormItem md={2} label="Expirado">
												<BtnRadioGroupSimNao name="flExpirado" disabled={true} value={this.state.model.flExpirado} required={true} onChange={this.handleChangeModel}/>
											</FormItem>
											<FormItem md={3} label="Validade">
												<InputDate name="dtValidade" readOnly={true} value={this.state.model.dtValidade} required={true} onChange={this.handleChangeModel} className={`text-center fw-bold ${this.state.model.flExpirado ? 'text-danger' : 'text-bg-primary'}`}/>
											</FormItem>
											<FormItem md={12} label="Observação">
												<TextArea name="txObservacao" readOnly={true} value={this.state.model.txObservacao} required={true} onChange={this.handleChangeModel}/>
											</FormItem>											
										</Row>
									</Form>
								</Tab>
								<Tab title="Cobranças" disabled={!this.state.model.idFaturaAcordo} opened={false} className="m-2">
									<ListFaturaAcordoFaturaCobranca parent={this.state.model} localOpen={BaseList.localOpen.modal}/>
								</Tab>
							</TabNavigator>
						</PanelBody>
						<PanelFooter>
							<Div className="d-grid gap-2 d-md-flex">
								<BtnButton show={Permissao.getInstance().getPermissoes([167])}  disabled={!this.state.model.statusFaturaAcordo?.flPodeEfetivar || this.state.model.flExpirado} color={BtnButton.color.outlineSuccess} sizing={Button.sizing.default} onClick={this.handleClickEfetivar}>
									<IconHandThumbsUp/> Efetivar
								</BtnButton>
								<BtnButton show={this.state.tabIndex === 0 && this.props.localOpen !== BaseCrud.localOpen.embedded} color={BtnButton.color.outlineSecondary} onClick={this.handleClose}> Cancelar </BtnButton>
							</Div>
						</PanelFooter>
					</Panel>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <FormFaturaAcordo {...props} navigate={navigate} params={params} location={location}/>
}
export default With