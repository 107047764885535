import Config from "./Config";
import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import SelectTipoDesconto from "../../components/SelectDB/SelectTipoDesconto";
import SelectTipoBaixa from "../../components/SelectDB/SelectTipoBaixa";
import { BaseCrud } from "../../siht/base";
import { FormButtons, FormItem, Row, Panel, PanelBody, PanelFooter, Container, ModalDialog } from "../../siht/components";
import { InputText, TextArea, InputNumberFormat, Form } from "../../siht/elements";

class FormFaturaDesconto extends BaseCrud {

	constructor(props) {
		super(props);
		this.init = this.init.bind(this);
		this.initModel = this.initModel.bind(this);
		this.handleSuccess = this.handleSuccess.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleChangeModel = this.handleChangeModel.bind(this);
		this.handleSubmitForm = this.handleSubmitForm.bind(this);
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		super.init(Config);
	}

	initModel(){
		return {
			idFaturaDesconto : null,
			fatura : this.props.parent !== undefined ? this.props.parent : {},
			tipoDesconto : null,
			tipoBaixa : null,
			txDesconto : "",
			vlDesconto : 0
		}
	}

	handleSuccess(e) {
		super.handleSuccess(e, Config);
	}

	handleClose(e) {
		super.handleClose(e, Config);
	}

	render() {
		return super.render(

					<Form onSubmit={this.handleSuccess} refParam={f => (this.form = f)} key={this.state.key}>
						<Panel key={this.state.key} title="Cadastro de Fatura Desconto" localOpen={this.props.localOpen}  onClose={this.handleClose} isModal={this.isModal()} container={{sizing: Container.sizing.xxl, className : ""}} modal={{sizing: this.props.sizing ? this.props.sizing : ModalDialog.sizing.xl}}>
							<PanelBody>
								<Row className="g-3 pb-2 px-2">
									<FormItem md={6} label="Código">
										<InputText name="idFaturaDesconto" value={this.state.model.idFaturaDesconto} readOnly={true} className="readonly-field" required={false} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={12} label="Nome do TipoDesconto">
										<SelectTipoDesconto name="tipoDesconto" value={this.state.model.tipoDesconto} required={true} onChange={this.handleChangeModel} placeholder="Selecione Nome do TipoDesconto"/>
									</FormItem>
									<FormItem md={12} label="Nome do TipoBaixa">
										<SelectTipoBaixa name="tipoBaixa" value={this.state.model.tipoBaixa} required={true} onChange={this.handleChangeModel} placeholder="Selecione Nome do TipoBaixa"/>
									</FormItem>
									<FormItem md={12} label="TxDesconto">
										<TextArea name="txDesconto" value={this.state.model.txDesconto} required={true} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={4} label="VlDesconto">
										<InputNumberFormat name="vlDesconto" value={this.state.model.vlDesconto} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} prefix="R$ " required={true} onChange={this.handleChangeModel}/>
									</FormItem>
								</Row>
							</PanelBody>
							<PanelFooter>
								<FormButtons onClose={this.handleClose} />
							</PanelFooter>
						</Panel>
					</Form>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <FormFaturaDesconto {...props} navigate={navigate} params={params} location={location}/>
}
export default With