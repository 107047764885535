import { useNavigate, useLocation, useParams } from "react-router-dom";
import { Outlet, Link } from "react-router-dom";
import ConfigApp from "../ConfigApp";
import { BaseComponent } from "../siht/base";
import { Modal } from "../siht/controller";
import Menu from "./Menu";
import Util from "../utils/Util";
import Auth from "../controllers/Auth";
import React from "react";
import Tree from "./Tree";
import FormMeusDados from "./Usuario/FormMeusDados";
import FormSobre from "./Sobre/FormSobre";
import FormAlterarSenha from "./Usuario/FormAlterarSenha";
import { BtnButton, Div } from "../siht/elements";
import UsuarioService from "../services/UsuarioService";
import { toast } from "react-toastify";
//import { Col, Row } from "../siht/components";
import { PersonFill as IconPersonFill, Search as IconSearch, BellFill as IconBellFill, House as IconHouse } from "react-bootstrap-icons";

class Layout extends BaseComponent {

    constructor(props) {
        super(props);

        this.handleOpenMenu = this.handleOpenMenu.bind(this);
        this.handleClickSair = this.handleClickSair.bind(this);
        this.handleClickMeusDados = this.handleClickMeusDados.bind(this);
        this.getNotificacoes = this.getNotificacoes.bind(this);
        this.handleClickMarcarTodasLida = this.handleClickMarcarTodasLida.bind(this);
        this.toggleMenu = this.toggleMenu.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.usuario = Auth.getDataToken();
        this.usuario.nmPrimeiro = this.usuario.nmUsuario ? this.usuario.nmUsuario.split(' ')[0] : "";
        this.state.notificacoes = [];

        this.state.menuCollapsed = false;
        this.state.search = "";
    }

    componentDidMount() {
        super.componentDidMount();

        this.getNotificacoes(false);
        setInterval(this.getNotificacoes, (ConfigApp.timeGetNotification * 1000));
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    handleOpenMenu(e, item) {
        Modal.open(Menu, { sizing: Modal.sizing.lg }, { title: "Menu" });
    }

    handleClickSair(e, item) {
        Modal.confirm("Confirmação", "Deseja realmente sair do Sistema", "", Modal.style.warning, () => {
            Auth.logout();
            this.props.navigate('/Login');
        });
    }

    handleClickSobre(e, item) {
        Modal.openWindow(FormSobre, { sizing: Modal.sizing.lg }, {}, (e, response) => {
        });
    }

    handleClickMeusDados() {
        Modal.openWindow(FormMeusDados, { sizing: Modal.sizing.xl }, {}, (e, response) => {

        });
    }

    handleClickAlterarSenha() {
        Modal.openWindow(FormAlterarSenha, { sizing: Modal.sizing.sm }, {}, (e, response) => {

        });
    }

    handleClickMarcarTodasLida() {
        UsuarioService.marcarTodasNotificacoesComoLida((response) => {
            this.getNotificacoes();
        });
    }

    handleClickOpenNotificacoes() {

    }

    getNotificacoes(notificar = true) {
        if (Util.isActive()) {
            UsuarioService.getNotificacao((response) => {
                if (notificar && response.length > 0 && response.length > this.state.notificacoes.length) {
                    toast.warn("Vocês recebeu novas notificações");
                }
                this.setState({ notificacoes: response });
            });
        }
    }

    toggleMenu() {
        this.setState(prevState => ({
            menuCollapsed: !prevState.menuCollapsed
        }));
    }

    getResultFilter(tree) {
        return tree.filter(f =>
            Util.searchTextInObject(f, ["label", "description", "detail"], this.state.search)
        );
    }

    render() {

        var backgroundColor = "primary";
        var textColor = "dark";

        /*return (
            <div className="d-flex">

                <div className="flex-grow-1">
                    <nav className={`navbar navbar-expand-xl bg-${backgroundColor} py-0`}>
                        <div className="container-fluid">

                            <button className="btn btn-link p-0 text-dark" onClick={this.toggleMenu}>
                                <span className="navbar-toggler-icon"></span>
                            </button>

                            <button
                                className="btn btn-link p-0 text-dark"
                                onClick={this.toggleMenu}>
                                <img src="./assets/image/logo.png" style={{ width: "32px" }} alt="Logo" />
                            </button>


                            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                <ul className="navbar-nav me-auto mb-2 mb-lg-0">

                                </ul>

                                <ul className="d-flex navbar-nav align-items-center">
                                    <li className="nav-item dropdown">
                                        <div className="nav-link">
                                            <BtnButton className={`btn-link text-${textColor} position-relative`} onClick={this.handleOpenMenu}>
                                                <IconSearch />
                                            </BtnButton>
                                        </div>
                                    </li>

                                    <li className="nav-item dropdown">
                                        <div className="nav-link" role="button" id="navbarDropdownUser" data-bs-toggle="dropdown" aria-expanded="false">
                                            <BtnButton className={`${this.state.notificacoes.length > 0 ? 'btn-warning' : `btn-link text-${textColor}`} position-relative`} onClick={this.handleClickOpenNotificacoes}>
                                                <IconBellFill />
                                                {this.state.notificacoes.length > 0 ?
                                                    <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                                                        {this.state.notificacoes.length > 99 ? '99+' : this.state.notificacoes.length}
                                                    </span> : <></>}
                                            </BtnButton>
                                        </div>
                                        <div className="dropdown-menu dropdown-menu-app dropdown-menu-end" aria-labelledby="navbarDropdownUser">
                                            <div className="p-0 m-0 bg-warning text-center fw-bold">
                                                Notificações
                                            </div>
                                            <nav className="d-grid gap-2 overflow-auto" style={{ width: "400px", maxHeight: "350px" }}>
                                                {this.state.notificacoes.map((item, index) => (
                                                    <React.Fragment key={index}>
                                                        {item.dsUrl && item.dsUrl.length > 0 ?
                                                            <Div className={`border-bottom border-${backgroundColor}-subtle`}>
                                                                <Link to={item.dsUrl} className="btn btn-hover-light rounded-2 d-flex align-items-center gap-3 py-2 px-3 lh-sm text-start">
                                                                    <div>
                                                                        <strong className={`d-block text-${backgroundColor}`}>{item.dsTitulo}</strong>
                                                                        <small>
                                                                            <div className="mb-1" dangerouslySetInnerHTML={{ __html: Util.nl2br(item.txMensagem) }} />
                                                                            {Util.date2Br(item.dhInsert, true)}
                                                                        </small>
                                                                        <div>
                                                                            <small> {item.dsUrl} </small>
                                                                        </div>
                                                                    </div>
                                                                </Link>
                                                            </Div> :
                                                            <Div className={`border-bottom border-${backgroundColor}-subtle`}>
                                                                <Div className="btn btn-hover-light rounded-2 d-flex align-items-center gap-3 py-2 px-3 lh-sm text-start">
                                                                    <div>
                                                                        <strong className={`d-block text-${backgroundColor}`}>{item.dsTitulo}</strong>
                                                                        <small>
                                                                            <div className="mb-1" dangerouslySetInnerHTML={{ __html: Util.nl2br(item.txMensagem) }} />
                                                                            {Util.date2Br(item.dhInsert, true)}
                                                                        </small>
                                                                    </div>
                                                                </Div>
                                                            </Div>
                                                        }
                                                    </React.Fragment>
                                                ))}
                                                <Div className="gap-3 py-2 px-3 w-100 d-flex justify-content-center" show={this.state.notificacoes.length > 0}>
                                                    <BtnButton color={BtnButton.color.outlinePrimary} sizing={BtnButton.sizing.sm} onClick={this.handleClickMarcarTodasLida}>
                                                        Marcar Todas Como Lida
                                                    </BtnButton>
                                                </Div>
                                            </nav>
                                        </div>
                                    </li>
                                </ul>

                                <ul className="d-flex navbar-nav align-items-center">
                                    <li className="nav-item dropdown">
                                        <div className={`nav-link dropdown-toggle text-${textColor}`} role="button" id="navbarDropdownUser" data-bs-toggle="dropdown" aria-expanded="false">
                                            <IconPersonFill /> {this.usuario?.nmPrimeiro}
                                        </div>
                                        <ul className={`dropdown-menu dropdown-menu-${backgroundColor} dropdown-menu-app dropdown-menu-end`} aria-labelledby="navbarDropdownUser">
                                            <li><button type="button" className="dropdown-item" onClick={this.handleClickMeusDados}>Meus dados</button></li>
                                            <li><Link className="dropdown-item" to="/LstNotificacaoSistema">Notificações</Link></li>
                                            <li><Link className="dropdown-item" to="/LstSessao">Sessões</Link></li>
                                            <li><button type="button" className="dropdown-item" onClick={this.handleClickAlterarSenha}>Alterar Senha</button></li>
                                            <li><button type="button" className="dropdown-item" onClick={this.handleClickSobre}>Sobre</button></li>
                                            <li><button type="button" className="dropdown-item" onClick={this.handleClickSair}>Sair</button></li>
                                            <li><hr className="dropdown-divider" /></li>
                                            <li><span className="dropdown-item-text">Versão: {parseFloat(ConfigApp.version).toFixed(2)}</span></li>
                                            <li><span className="dropdown-item-text">React: {React.version}</span></li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </nav>

                    <div className="d-flex">

                        <div className={`sidebar bg-white border-end ${this.state.menuCollapsed ? 'd-none' : ''}`}
                            style={{
                                width: this.state.menuCollapsed ? "70px" : "280px",
                                minHeight: "100vh",
                                transition: "width 0.3s ease"
                            }}>

                            {!this.state.menuCollapsed && (
                                <div className="p-3 border-bottom">
                                    <div className="input-group">
                                        <span className="input-group-text bg-light border-0">
                                            <IconSearch size={14} />
                                        </span>
                                        <InputSearch className="form-control border-0 bg-light" placeholder="Pesquisar..." name="search" value={this.state.search} onChange={this.handleChange} />
                                    </div>
                                </div>
                            )}

                            <div className="nav-items py-3" style={{ overflowY: 'auto', height: 'calc(100vh - 140px)' }}>
                                <Link to="/" className="nav-link d-flex align-items-center px-3 py-2 text-dark">
                                    <IconHouse size={16} />
                                    {!this.state.menuCollapsed && <span className="ms-2">Home</span>}
                                </Link>

                                {Util.arrayOrderBy(Tree.getMenu(), "label").map((menu, indexMenu) => {
                                    let Icon = menu?.icon;
                                    var tree = this.getResultFilter(Util.arrayOrderBy(menu.tree, "label"));
                                    let itens = Util.arrayOrderBy(tree, "label").filter(item => item.permission === true);

                                    if (itens.length === 0) return null;

                                    return (
                                        <div key={indexMenu} className="nav-group mb-2 border-top">
                                            {!this.state.menuCollapsed && (
                                                <div className="nav-group-title px-3 py-2 text-secondary small fw-bold">
                                                    {menu.icon ? <menu.icon size={16} /> : <Icon size={16} />} {menu.label}
                                                </div>
                                            )}

                                            {itens.map((item, indexItem) => (
                                                <Link
                                                    key={indexItem}
                                                    to={item.to}
                                                    className="nav-link d-flex align-items-center px-3 py-1 text-dark"
                                                    title={this.state.menuCollapsed ? item.label : ''}>
                                                    {item.icon ? <item.icon size={16} /> : <Icon size={16} />}
                                                    {!this.state.menuCollapsed && <span className="ms-2 fs-8">{item.label}</span>}
                                                </Link>
                                            ))}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>

                        <div className="pt-4 w-100">
                            <Outlet />
                        </div>

                    </div>
                </div>
            </div>
        );
        */

        let showMenu = true;

        return (
            <>
                <nav className={`navbar navbar-expand-xl py-0`}>
                    <div className="w-100">
                        <div className={`container-fluid d-flex flex-between-center bg-${backgroundColor}`}>
                            <Link className={`"navbar-brand fw-bold text-${textColor}`} to="/">
                                <img src="./assets/image/logo.png" style={{ width: "36px" }} alt="..." />
                            </Link>
                            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                    <li className="nav-item">
                                        <Link className={`nav-link text-${textColor}`} to="/">
                                            <IconHouse />
                                        </Link>
                                    </li>
                                    {showMenu && Util.arrayOrderBy(Tree.getMenu(), "label").map((menu, indexMenu) => {
                                        var tree = Util.arrayOrderBy(menu.tree, "label");
                                        let Icon = menu?.icon;

                                        if (tree.length === 0) {
                                            return <React.Fragment key={indexMenu}></React.Fragment>;
                                        }

                                        let itens = Util.arrayOrderBy(menu.tree, "label").filter(item => item.permission === true);

                                        if (itens.length === 0) {
                                            return <React.Fragment key={indexMenu}></React.Fragment>;
                                        }

                                        return (
                                            <li className="nav-item dropdown" key={indexMenu}>
                                                <div className={`nav-link dropdown-toggle text-${textColor} fs-6-5`} role="button" id="navbarDropdownCadastros" data-bs-toggle="dropdown" aria-expanded="false">
                                                    {Icon ? <><Icon item={menu} />&nbsp;</> : <></>}{menu.label}
                                                </div>
                                                <ul className={`dropdown-menu dropdown-menu-${backgroundColor} dropdown-menu-app`} aria-labelledby="navbarDropdownCadastros">
                                                    {itens.map((item, indexItem) => {
                                                        let Icon = item?.icon;
                                                        return (
                                                            <li key={indexItem}><Link className="dropdown-item fs-6-5" to={item.to}>
                                                                {Icon ? <><Icon item={item} />&nbsp;</> : <></>}{item.label}
                                                            </Link></li>
                                                        );
                                                    })}
                                                </ul>
                                            </li>
                                        );
                                    })}
                                </ul>

                                <ul className="d-flex navbar-nav align-items-center">
                                    <li className="nav-item dropdown">
                                        <div className="nav-link">
                                            <BtnButton className={`btn-link text-${textColor} position-relative`} onClick={this.handleOpenMenu}>
                                                <IconSearch />
                                            </BtnButton>
                                        </div>
                                    </li>

                                    <li className="nav-item dropdown">
                                        <div className="nav-link" role="button" id="navbarDropdownUser" data-bs-toggle="dropdown" aria-expanded="false">
                                            <BtnButton className={`${this.state.notificacoes.length > 0 ? 'btn-warning' : `btn-link text-${textColor}`} position-relative`} onClick={this.handleClickOpenNotificacoes}>
                                                <IconBellFill />
                                                {this.state.notificacoes.length > 0 ?
                                                    <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                                                        {this.state.notificacoes.length > 99 ? '99+' : this.state.notificacoes.length}
                                                    </span> : <></>}
                                            </BtnButton>
                                        </div>
                                        <div className="dropdown-menu dropdown-menu-app dropdown-menu-end" aria-labelledby="navbarDropdownUser">
                                            <div className="p-0 m-0 bg-warning text-center fw-bold">
                                                Notificações
                                            </div>
                                            <nav className="d-grid gap-2 overflow-auto" style={{ width: "400px", maxHeight: "350px" }}>
                                                {this.state.notificacoes.map((item, index) => (
                                                    <React.Fragment key={index}>
                                                        {item.dsUrl && item.dsUrl.length > 0 ?

                                                            <Div className={`border-bottom border-${backgroundColor}-subtle`}>
                                                                <Link to={item.dsUrl} className="btn btn-hover-light rounded-2 d-flex align-items-center gap-3 py-2 px-3 lh-sm text-start">
                                                                    <div>
                                                                        <strong className={`d-block text-${backgroundColor}`}>{item.dsTitulo}</strong>
                                                                        <small>
                                                                            <div className="mb-1" dangerouslySetInnerHTML={{ __html: Util.nl2br(item.txMensagem) }} />
                                                                            {Util.date2Br(item.dhInsert, true)}
                                                                        </small>
                                                                        <div>
                                                                            <small> {item.dsUrl} </small>
                                                                        </div>
                                                                    </div>
                                                                </Link>
                                                            </Div> :
                                                            <Div className={`border-bottom border-${backgroundColor}-subtle`}>
                                                                <Div className="btn btn-hover-light rounded-2 d-flex align-items-center gap-3 py-2 px-3 lh-sm text-start">
                                                                    <div>
                                                                        <strong className={`d-block text-${backgroundColor}`}>{item.dsTitulo}</strong>
                                                                        <small>
                                                                            <div className="mb-1" dangerouslySetInnerHTML={{ __html: Util.nl2br(item.txMensagem) }} />
                                                                            {Util.date2Br(item.dhInsert, true)}
                                                                        </small>
                                                                    </div>
                                                                </Div>
                                                            </Div>
                                                        }
                                                    </React.Fragment>
                                                ))}
                                                <Div className="gap-3 py-2 px-3 w-100 d-flex justify-content-center" show={this.state.notificacoes.length > 0}>
                                                    <BtnButton color={BtnButton.color.outlinePrimary} sizing={BtnButton.sizing.sm} onClick={this.handleClickMarcarTodasLida}>
                                                        Marcar Todas Como Lida
                                                    </BtnButton>
                                                </Div>
                                            </nav>
                                        </div>
                                    </li>
                                </ul>

                                <ul className="d-flex navbar-nav align-items-center">
                                    <li className="nav-item dropdown">
                                        <div className={`nav-link dropdown-toggle text-${textColor}`} role="button" id="navbarDropdownUser" data-bs-toggle="dropdown" aria-expanded="false">
                                            <IconPersonFill /> {this.usuario?.nmPrimeiro}
                                        </div>
                                        <ul className={`dropdown-menu dropdown-menu-${backgroundColor} dropdown-menu-app dropdown-menu-end`} aria-labelledby="navbarDropdownUser">
                                            <li><button type="button" className="dropdown-item" onClick={this.handleClickMeusDados}>Meus dados</button></li>
                                            <li><Link className="dropdown-item" to="/LstNotificacaoSistema">Notificações</Link></li>
                                            <li><Link className="dropdown-item" to="/LstSessao">Sessões</Link></li>
                                            <li><button type="button" className="dropdown-item" onClick={this.handleClickAlterarSenha}>Alterar Senha</button></li>
                                            <li><button type="button" className="dropdown-item" onClick={this.handleClickSobre}>Sobre</button></li>
                                            <li><button type="button" className="dropdown-item" onClick={this.handleClickSair}>Sair</button></li>
                                            <li><hr className="dropdown-divider" /></li>
                                            <li><span className="dropdown-item-text">Versão: {parseFloat(ConfigApp.version).toFixed(2)}</span></li>
                                            <li><span className="dropdown-item-text">React: {React.version}</span></li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        {/*
                        <div className="navbar-collapse navbar-top-collapse justify-content-center collapse">
                            <ul className="navbar-nav navbar-nav-top">
                                <li className="nav-item">
                                    <Link className={`nav-link text-${textColor}`} to="/">
                                        <IconHouse />
                                    </Link>
                                </li>
                                {showMenu && Util.arrayOrderBy(Tree.getMenu(), "label").map((menu, indexMenu) => {
                                    var tree = Util.arrayOrderBy(menu.tree, "label");
                                    let Icon = menu?.icon;

                                    if (tree.length === 0) {
                                        return <React.Fragment key={indexMenu}></React.Fragment>;
                                    }

                                    let itens = Util.arrayOrderBy(menu.tree, "label").filter(item => item.permission === true);

                                    if (itens.length === 0) {
                                        return <React.Fragment key={indexMenu}></React.Fragment>;
                                    }

                                    return (
                                        <li className="nav-item dropdown" key={indexMenu}>
                                            <div className={`nav-link dropdown-toggle text-${textColor}`} role="button" id="navbarDropdownCadastros" data-bs-toggle="dropdown" aria-expanded="false">
                                                {Icon ? <><Icon item={menu} />&nbsp;</> : <></>}{menu.label}
                                            </div>
                                            <ul className={`dropdown-menu dropdown-menu-${backgroundColor} dropdown-menu-app`} aria-labelledby="navbarDropdownCadastros">
                                                {itens.map((item, indexItem) => {
                                                    let Icon = item?.icon;
                                                    return (
                                                        <li key={indexItem}><Link className="dropdown-item" to={item.to}>
                                                            {Icon ? <><Icon item={item} />&nbsp;</> : <></>}{item.label}
                                                        </Link></li>
                                                    );
                                                })}
                                            </ul>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>*/}
                    </div>
                </nav>



                <div className="mt-1 py-2 mx-2">
                    <Outlet />
                </div>

            </>
        )
    }
}
function With(props) {
    let navigate = useNavigate();
    let params = useParams();
    let location = useLocation();
    return <Layout {...props} navigate={navigate} params={params} location={location} />
}

export default With