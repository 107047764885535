
import FormFaturaCobrancaTipoPagamento from "./FormFaturaCobrancaTipoPagamento.js";
import ListFaturaCobrancaTipoPagamento from "./ListFaturaCobrancaTipoPagamento.js";
import { Permissao } from "../../controllers";
import { Util } from "../../utils";
import { DadoValorService, FaturaCobrancaTipoPagamentoService } from "../../services";

export default class Config {

	static getUrlForm() {
		return "/CadFaturaCobrancaTipoPagamento";
	}

	static getUrlList() {
		return "/LstFaturaCobrancaTipoPagamento";
	}

	static getComponentForm() {
		return FormFaturaCobrancaTipoPagamento;
	}

	static getPermissionForm() {
		return Permissao.getInstance().getPermissoes([69]);
	}

	static getPermissionList() {
		return Permissao.getInstance().getPermissoes([69]);
	}

	static getComponentList() {
		return ListFaturaCobrancaTipoPagamento;
	}

	static getService() {
		return FaturaCobrancaTipoPagamentoService;
	}

	static getKeyId() {
		return "idFaturaCobrancaTipoPagamento";
	}

	static getKeyDescription() {
		return "nmFaturaCobrancaTipoPagamento";
	}

	static setDefaultValues(state , fnCallBack = () => {}, className = undefined) {
		className = className === undefined ? "FormFaturaCobrancaTipoPagamento" : className;
		let keyNameScreen = Util.toToUpperTextCamelCase(className, "_") + ".";
		var prefix = "CONFIGURACAO.";
		DadoValorService.getValues(`${prefix}${keyNameScreen}`, (response)=> {
			Util.setValueObjectCamelCase(state.model, response, keyNameScreen, prefix);
			fnCallBack();
		}, fnCallBack);
	}
}