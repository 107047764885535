import AnsPerfilMaterialService from "../../services/AnsPerfilMaterialService";
import FormAnsPerfilMaterial from "./FormAnsPerfilMaterial";
import ListAnsPerfilMaterial from "./ListAnsPerfilMaterial";
import Permissao from "../../controllers/Permissao";

export default class Config {

	static getUrlForm() {
		return "/CadAnsPerfilMaterial";
	}

	static getUrlList() {
		return "/LstAnsPerfilMaterial";
	}

	static getComponentForm() {
		return FormAnsPerfilMaterial;
	}

	static getPermissionForm() {
		return Permissao.getInstance().getPermissoes([15]);
	}

	static getPermissionList() {
		return Permissao.getInstance().getPermissoes([15]);
	}

	static getComponentList() {
		return ListAnsPerfilMaterial;
	}

	static getService() {
		return AnsPerfilMaterialService;
	}

	static getKeyId() {
		return "idAnsPerfilMaterial";
	}

	static getKeyDescription() {
		return "nmAnsPerfilMaterial";
	}
}