import React from "react";
import Field from "./Field";

export default class Input extends Field {

    static sizing = {
        lg: "lg",
        sm : "sm",
        default: "",
    }

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    handleChange(e, value, name) {
        super.handleChange(e, value, name);
    }

    handleBlur(e, value, name) {
        super.handleBlur(e, value, name);
    }

    render(type) {
        if(this.props.show !== undefined && !Boolean(this.props.show)){
            return (<></>);
        }
        
        const props = Object.assign({}, this.props);
        delete props.show;
        props.type = type ? type : (this.props.type ? this.props.type : "text");
        props.value = props.value === undefined || props.value === null ? "" : props.value;
        props.onChange = this.handleChange;
        props.onBlur = this.handleBlur;
        props.ref = this.props.refParam ? this.props.refParam : this.ref;
        props.className = `form-control ${props.className ? props.className : ""} ${props.sizing ? "form-control-" + props.sizing : ""} ${props.readOnly !== undefined && props.readOnly ? "readonly-field" : ""}`;

        return super.render(<input {...this.getProps(props)} />);
    }
}