
import FormAnsStatus from "./FormAnsStatus.js";
import ListAnsStatus from "./ListAnsStatus.js";
import { Permissao } from "../../controllers";
import { Util } from "../../utils";
import { DadoValorService, AnsStatusService } from "../../services";

export default class Config {

	static getUrlForm() {
		return "/CadAnsStatus";
	}

	static getUrlList() {
		return "/LstAnsStatus";
	}

	static getComponentForm() {
		return FormAnsStatus;
	}

	static getPermissionForm() {
		return Permissao.getInstance().getPermissoes([60]);
	}

	static getPermissionList() {
		return Permissao.getInstance().getPermissoes([60]);
	}

	static getComponentList() {
		return ListAnsStatus;
	}

	static getService() {
		return AnsStatusService;
	}

	static getKeyId() {
		return "idAnsStatus";
	}

	static getKeyDescription() {
		return "nmAnsStatus";
	}

	static setDefaultValues(state , fnCallBack = () => {}, className = undefined) {
		className = className === undefined ? "FormAnsStatus" : className;
		let keyNameScreen = Util.toToUpperTextCamelCase(className, "_") + ".";
		var prefix = "CONFIGURACAO.";
		DadoValorService.getValues(`${prefix}${keyNameScreen}`, (response)=> {
			Util.setValueObjectCamelCase(state.model, response, keyNameScreen, prefix);
			fnCallBack();
		}, fnCallBack);
	}
}