import Http from "../controllers/Http";

export default class VwContaReceberService {

	static urlBase = "/vwContaReceber/";

	static getForCombo(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(VwContaReceberService.urlBase + 'forCombo', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static list(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(VwContaReceberService.urlBase + 'list', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneById(id, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.get(VwContaReceberService.urlBase + id)
			.then(fnSucess)
			.catch(fnError);
	}


	static getAllByContaReceber(contaReceber, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(VwContaReceberService.urlBase + 'contaReceber', contaReceber)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByContaReceber(contaReceber, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(VwContaReceberService.urlBase + 'contaReceber/' + contaReceber.idContaReceber)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByStatusConta(statusConta, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(VwContaReceberService.urlBase + 'statusConta', statusConta)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByStatusConta(statusConta, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(VwContaReceberService.urlBase + 'statusConta/' + statusConta.idStatusConta)
			.then(fnSucess)
			.catch(fnError);
	}
}