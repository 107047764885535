import Http from "../controllers/Http";

export default class FuncionarioCargoService {

	static urlBase = "/funcionarioCargo/";

	static getForCombo(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(FuncionarioCargoService.urlBase + 'forCombo', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static list(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(FuncionarioCargoService.urlBase + 'list', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneById(id, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.get(FuncionarioCargoService.urlBase + id)
			.then(fnSucess)
			.catch(fnError);
	}

	static create(data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(FuncionarioCargoService.urlBase, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static update(id, data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.put(FuncionarioCargoService.urlBase + id, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static delete(id, fnSucess = () => { }, fnError = () => { }) {
		if(Number.isInteger(id)){
			Http.getInstanceAppJson()
				.delete(FuncionarioCargoService.urlBase + id)
				.then(fnSucess)
				.catch(fnError);
		}else{
			Http.getInstanceAppJson()
			.post(FuncionarioCargoService.urlBase + 'delete', id)
			.then(fnSucess)
			.catch(fnError);
		}
	}

	static save(data, fnSucess = () => { }, fnError = () => { }) {
		if (data.idFuncionarioCargo) {
			this.update(data.idFuncionarioCargo, data, fnSucess, fnError);
		} else {
			this.create(data, fnSucess, fnError);
		}
	}

	static getAllByFuncionario(funcionario, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(FuncionarioCargoService.urlBase + 'funcionario', funcionario)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByFuncionario(funcionario, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(FuncionarioCargoService.urlBase + 'funcionario/' + funcionario.idFuncionario)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByCargo(cargo, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(FuncionarioCargoService.urlBase + 'cargo', cargo)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByCargo(cargo, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(FuncionarioCargoService.urlBase + 'cargo/' + cargo.idCargo)
			.then(fnSucess)
			.catch(fnError);
	}
}