import React from "react";
import { NumericFormat } from 'react-number-format';
import Field from "./Field";

export default class InputNumberFormat extends Field {

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    handleChange(values, sourceInfo) {
        const { floatValue } = values;

        var e = {
            target: {
                value: floatValue === undefined ? "" : floatValue,
                name: this.props.name
            }
        };

        super.handleChange(e, e?.target?.value, e?.target?.name);
        if (this.props.onChange) {
            this.props.onChange(e, e?.target?.value, e?.target?.name)
        }
    }

    handleBlur(e) {
        super.handleBlur(e, e?.target?.value, e?.target?.name);
        if (this.props.onBlur) {
            this.props.onBlur(e, e?.target?.value, e?.target?.name)
        }
    }

    render(pps) {
        var props = Object.assign({}, this.props);
        props = {...pps, ...props};        
        props.className = `form-control ${props.className ? props.className : ""} ${props.sizing ? "form-control-" + props.sizing : ""} ${props.readOnly !== undefined && props.readOnly ? "readonly-field" : ""}`;
        props.getInputRef = this.props.refParam ? this.props.refParam : this.ref;
        props.onValueChange = this.handleChange;
        props.onBlur = this.handleBlur;

        if(this.props.max !== undefined){
            props.isAllowed = ({ value }) => value <= this.props.max;
        }

        props.thousandSeparator= props.thousandSeparator !== undefined ? props.thousandSeparator : ".";
        props.decimalSeparator= props.decimalSeparator !== undefined ? props.decimalSeparator : ",";
        props.inputMode= props.inputMode !== undefined ? props.inputMode : "numeric";
        props.decimalScale= props.decimalScale !== undefined ? props.decimalScale : 2;
        props.placeholder= props.placeholder !== undefined ? props.placeholder : "99.99";
        props.fixedDecimalScale= props.fixedDecimalScale !== undefined ? props.fixedDecimalScale : true;

        delete props.onChange;
        delete props.max;

        return super.render(<NumericFormat {...props} />);
    }
}