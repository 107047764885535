//#NO-CHANGE-FILE#

import Config from "./Config";
import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import SelectTipoAnsPerfil from "../../components/SelectDB/SelectTipoAnsPerfil";
import ListAnsPerfilAvanco from "../AnsPerfilAvanco/ListAnsPerfilAvanco";
import ListAnsPerfilGolpe from "../AnsPerfilGolpe/ListAnsPerfilGolpe";
import ListAnsPerfilClassificacao from "../AnsPerfilClassificacao/ListAnsPerfilClassificacao";
import ListAnsPerfilPessoa from "../AnsPerfilPessoa/ListAnsPerfilPessoa";
import ListAnsPerfilFoto from "../AnsPerfilFoto/ListAnsPerfilFoto";
import ListAnsPerfilArquivo from "../AnsPerfilArquivo/ListAnsPerfilArquivo";
import ListAnsPerfilPlanta from "../AnsPerfilPlanta/ListAnsPerfilPlanta";
import Permissao from "../../controllers/Permissao";
import { BaseList, BaseCrud } from "../../siht/base";
import { FormButtons, FormItem, Row, TabNavigator, Tab, Panel, PanelBody, PanelFooter, Container, InputGroup, InputGroupText, ModalDialog, TabDropdown, DropDown, DropDownItem, BtnRadioGroup, InputGroupButton, DropDownDivider } from "../../siht/components";
import { InputText, InputDate, InputNumberFormat, Form, BtnSubmit, BtnRadio, H6, BtnButton, InputNumber } from "../../siht/elements";
import OneToOneFormEndereco from "../Endereco/OneToOneFormEndereco";
import SvgPerfil from "./SvgPerfil";
import SvgMapa from "./SvgMapa";
import { Crosshair2 as IconCrosshair2, Camera as IconCamera, GraphDownArrow as IconGraphDownArrow, Map as IconMap, NodePlusFill as IconNodePlusFill, PersonFill as IconPersonFill, PrinterFill as IconPrinterFill, Screwdriver as IconScrewdriver, SortDown as IconSortDown, SortNumericDown as IconSortNumericDown, FilterSquare as IconFilterSquare, Paperclip as IconPaperclip, MapFill as IconMapFill, Pencil as IconPencil, SignStopFill } from "react-bootstrap-icons";
import FormRelatorioAns001 from "../Relatorio/FormRelatorioAns001";
import FormRelatorioAns002 from "../Relatorio/FormRelatorioAns002";
import FormRelatorioAns004 from "../Relatorio/FormRelatorioAns004";
import { Modal } from "../../siht/controller";
import FormAlterarStatus from "./FormAlterarStatus";
import { FormItemLocalizacao } from "../../components/FormItem";
import { BtnRadioGroupSimNao, StatusCustom } from "../../components/Custom";
import { AnsPerfilService } from "../../services";
import { IconCopy, IconNew } from "../../icons";
import { toast } from "react-toastify";
import ManyToManyCheckAnsPerfilParadaByAnsCriterioParada from "./ManyToManyCheckAnsPerfilParadaByAnsCriterioParada";
class FormAnsPerfil extends BaseCrud {

	constructor(props) {
		super(props);
		this.init = this.init.bind(this);
		this.handleSuccess = this.handleSuccess.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleChangeModel = this.handleChangeModel.bind(this);
		this.handleSubmitForm = this.handleSubmitForm.bind(this);
		this.handleImprimirAns = this.handleImprimirAns.bind(this);
		this.handleRelatorioAns = this.handleRelatorioAns.bind(this);
		this.handleResultado = this.handleResultado.bind(this);
		this.handleChangeEndereco = this.handleChangeEndereco.bind(this);
		this.getAnsPerfisByOrdem = this.getAnsPerfisByOrdem.bind(this);
		this.handleChangePerfil = this.handleChangePerfil.bind(this);
		this.handleCopy = this.handleCopy.bind(this);

		this.state.origem = this.props?.location?.state?.origem !== undefined ? this.props?.location?.state?.origem : 'ORDEM';

		this.state.model = {
			idAnsPerfil: null,
			ordem: this.props.parent !== undefined ? this.props.parent : {},
			endereco: this.props.parent !== undefined && this.props.parent?.obra !== undefined && this.props.parent?.obra?.endereco !== undefined ? this.props.parent?.obra?.endereco : null,
			tipoAnsPerfil: null,
			nrTipoAnsPerfil: "001",
			dsSite: "",
			ansStatus: null,
			localizacao: null,
			dtInicio: "",
			dtFim: "",
			nrDenominador: 0,
			nrLinhaPagina: 20,
			dsEscala: "",

			vlProfundidadeLimite: 0,
			vlProfundidadeFuro: 0,
			vlDiametroAmostradorInterno: 0,
			vlDiametroAmostradorExterno: 0,

			flEncontrouAgua: false,
			vlProfundidadeNivelAgua: "",

			flUsouCirculacaoAgua: false,
			vlProfundidadeEnsaioInicio: 0,
			vlProfundidadeEnsaio1: 0,
			vlProfundidadeEnsaio2: 0,
			vlProfundidadeEnsaio3: 0,

			cdPenetracao: "PENETRAVEL",

			vlProfundidadeRevestimento: "",
			vlDiametroRevestimento: 0,
			cdRevestimento: "N",
			vlCota: 0,
			vlCotaBase: null,
			vlAltitude: null,

			vlAlturaQueda: 0,
			vlPesoQueda: 0,

			ansPerfilAvanco: [],
			ansPerfilGolpe: [],
			ansPerfilClassificacao: []
		};

		this.state.keySvgPerfil = Math.random();
		this.state.ansPerfils = [];
		this.state.ansIndex = null;
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init() {
		super.init(Config, () => {
			this.getAnsPerfisByOrdem();
		});
	}

	componentDidUpdate(nextProps) {
		if (nextProps.params.id && this.props.params.id && parseInt(nextProps.params.id) !== parseInt(this.props.params.id)) {
			this.init();
		}
	}

	getAnsPerfisByOrdem() {
		if (this.state.model.ordem) {
			AnsPerfilService.getAllByOrdem(this.state.model.ordem, (response) => {
				let index = response.findIndex(f => parseInt(f.idAnsPerfil) === parseInt(this.state.model.idAnsPerfil));
				this.setState({ ansPerfils: response, ansIndex: index });
			})
		}
	}

	handleSuccess(e) {
		Config.getService().save(this.state.model, (response) => {
			var url = "/CadOrdem/" + this.state.model.ordem.idOrdem;
			if (this.state.origem === "PERFIL") {
				url = "/LstAnsPerfil";
			}

			this.setState({ model: response.data, key: Math.random() }, () => {
				if (this.props.handleSuccess !== undefined) {
					this.props.handleSuccess(e, response, url);
				}

				if (this.localOpen === BaseCrud.localOpen.newPage && url) {
					//this.props.navigate(url);
					this.init(Config);
				}

				if (this.localOpen === BaseCrud.localOpen.embedded) {
					this.init(Config);
				}

				this.setState({ key: Math.random() })
			});
		});
	}

	handleClose(e) {
		var url = "/CadOrdem/" + this.state.model.ordem.idOrdem;
		if (this.state.origem === "PERFIL") {
			url = "/LstAnsPerfil";
		}

		if (this.props.handleClose !== undefined) {
			this.props.handleClose(e, url);
		}

		if (this.localOpen === BaseCrud.localOpen.newPage && url) {
			this.props.navigate(url, { state: { tabIndex: 5 } });
		}
	}

	handleCopy(e, item) {
		Modal.confirm("Confirmação", "Deseja realmente COPIAR esse Perfil?", null, Modal.style.warning, () => {
			Config.getService().duplicar(item, (response) => {
				toast.success("Perfil foi duplicada, novo perfil " + response.idAnsPerfil);
				this.props.navigate(Config.getUrlForm() + '/' + response.idAnsPerfil);
			});
		});
	}

	handleIrParaOrdem(e) {
		var url = "/CadOrdem/" + this.state.model.ordem.idOrdem;
		this.props.navigate(url, { state: { tabIndex: 5 } });
	}

	handleImprimirAns(e, ansPerfil = null) {
		if (ansPerfil) {
			Modal.openWindow(FormRelatorioAns001, { sizing: Modal.sizing.default }, { idAnsPerfis: [ansPerfil.idAnsPerfil] });
		} else {
			let idAnsPerfis = this.state.selecteds.flatMap((o) => (o.idAnsPerfil));
			Modal.openWindow(FormRelatorioAns001, { sizing: Modal.sizing.default }, { idAnsPerfis: idAnsPerfis });
		}
	}

	handleRelatorioAns(e, ansPerfil = null) {
		if (ansPerfil) {
			Modal.openWindow(FormRelatorioAns002, { sizing: Modal.sizing.default }, { idOrdens: [ansPerfil.ordem.idOrdem] });
		}
	}

	handleImprimirMapa(e, ansPerfil = null) {
		if (ansPerfil) {
			Modal.openWindow(FormRelatorioAns004, { sizing: Modal.sizing.default }, { idOrdens: [ansPerfil.ordem.idOrdem] });
		}
	}

	handleResultado() {
		let paginas = Math.ceil(this.state.model.vlProfundidadeLimite / this.state.model.nrLinhaPagina);
		let resultado = [];
		for (let i = 0; i < paginas; i++) {
			resultado.push(i + 1);
		}
		return resultado;
	}

	handleAlterarStatus(e, ansPerfil) {
		Modal.openWindow(FormAlterarStatus, { sizing: Modal.sizing.default }, { ansPerfil: ansPerfil }, (e, response) => {
			this.init();
		});
	}

	handleChangeEndereco() {
		if (!this.state.model.localizacao && this.state.model.endereco?.localizacao) {
			this.setModel({ localizacao: this.state.model.endereco?.localizacao });
		}
	}

	handleChangePerfil(e, ansPerfil) {
		this.props.navigate(Config.getUrlForm() + '/' + ansPerfil.idAnsPerfil);
	}

	handleNew(e) {
		Modal.openWindow(Config.getComponentForm(), { sizing: Modal.sizing.xl }, { parent: this.state.model.ordem }, (e, response) => {
			this.props.navigate(Config.getUrlForm() + '/' + response.data.idAnsPerfil);
		});
	}

	render() {
		return super.render(
			<Panel key={this.state.key} title="Cadastro de Perfil" localOpen={this.props.localOpen} onClose={this.handleClose} isModal={this.isModal()} container={{ sizing: Container.sizing.fluid, className: "" }} modal={{ sizing: this.props.sizing ? this.props.sizing : ModalDialog.sizing.xl }}>
				<PanelBody className="p-0 m-0">
					<TabNavigator key={this.state.key} index={this.state.tabIndex} onChangeTab={this.handleChangeTab} className="border-light">
						<Tab title={<><IconCrosshair2 /> Perfil</>} className="m-2">
							<Form onSubmit={this.handleSuccess} refParam={f => (this.form = f)}>
								<Row className="g-3 pb-2 px-2">
									<FormItem md={1} label="Código">
										<InputText name="idAnsPerfil" value={this.state.model.idAnsPerfil} readOnly={true} className="text-center" required={false} onChange={this.handleChangeModel} />
									</FormItem>
									<FormItem md={2} label="Início">
										<InputDate name="dtInicio" value={this.state.model.dtInicio} required={false} onChange={this.handleChangeModel} className="text-center" disabled={!Config.getPermissaoAnsPerfil(this.state.model).flPodeEditar} />
									</FormItem>
									<FormItem md={2} label="Fim">
										<InputDate name="dtFim" value={this.state.model.dtFim} min={this.state.model.dtInicio} required={false} onChange={this.handleChangeModel} className="text-center" disabled={!Config.getPermissaoAnsPerfil(this.state.model).flPodeEditar} />
									</FormItem>
									<FormItem md={3} label="Tipo de Perfil">
										<SelectTipoAnsPerfil name="tipoAnsPerfil" value={this.state.model.tipoAnsPerfil} required={true} onChange={this.handleChangeModel} placeholder="Selecione o Tipo" buttons={{ search: false, new: false, edit: false }} disabled={!Config.getPermissaoAnsPerfil(this.state.model).flPodeEditar} />
									</FormItem>
									<FormItem md={2} label="Tipo Número">
										<InputGroup required={true}>
											<InputGroupText>{this.state.model?.tipoAnsPerfil?.cdTipoAnsPerfil}</InputGroupText>
											<InputText name="nrTipoAnsPerfil" value={this.state.model.nrTipoAnsPerfil} maxLength={3} required={true} onChange={this.handleChangeModel} disabled={!Config.getPermissaoAnsPerfil(this.state.model).flPodeEditar} />
										</InputGroup>
									</FormItem>
									<FormItem md={2} label="Site">
										<InputText name="dsSite" value={this.state.model.dsSite} maxLength={100} required={false} onChange={this.handleChangeModel} disabled={!Config.getPermissaoAnsPerfil(this.state.model).flPodeEditar} />
									</FormItem>

									<FormItem md={2} label="Denominador">
										<InputNumber name="nrDenominador" className="text-center" value={this.state.model.nrDenominador} min={1} max={100} required={true} onChange={this.handleChangeModel} disabled={!Config.getPermissaoAnsPerfil(this.state.model).flPodeEditar} />
									</FormItem>
									<FormItem md={2} label="Linha por Página">
										<InputNumber name="nrLinhaPagina" className="text-center" value={this.state.model.nrLinhaPagina} min={1} max={100} required={true} onChange={this.handleChangeModel} disabled={!Config.getPermissaoAnsPerfil(this.state.model).flPodeEditar} />
									</FormItem>
									<FormItem md={2} label="Escala">
										<InputText name="dsEscala" value={this.state.model.dsEscala} maxLength={100} required={true} onChange={this.handleChangeModel} disabled={!Config.getPermissaoAnsPerfil(this.state.model).flPodeEditar} />
									</FormItem>

									<FormItem md={6} label="Status">
										<InputGroup opcional={true} >
											<StatusCustom opcional={true} type="InputText" color={this.state.model.ansStatus}>{this.state.model.ansStatus?.nmAnsStatus}</StatusCustom>
											<InputGroupButton>
												<BtnButton color={BtnButton.color.outlinePrimary} onClick={e => this.handleAlterarStatus(e, this.state.model)} show={Config.getPermissaoAnsPerfil(this.state.model).flPodeAlterarStatus}> <IconNodePlusFill /> </BtnButton>
											</InputGroupButton>
										</InputGroup>
									</FormItem>

									<FormItem md={6} label="Endereço">
										<OneToOneFormEndereco type="InputText" name="endereco" value={this.state.model.endereco} required={true} onChange={(e, v, p) => this.handleChangeModel(e, v, p, this.handleChangeEndereco)} placeholder="Informe o Endereço" disabled={!Config.getPermissaoAnsPerfil(this.state.model).flPodeEditar} />
									</FormItem>

									<FormItemLocalizacao md={4} name="localizacao" value={this.state.model.localizacao} required={false} onChange={this.handleChangeModel} placeholder="Selecione a Localizacao"
										disabled={!Config.getPermissaoAnsPerfil(this.state.model).flPodeEditar}
										slng={this.state.model.endereco?.localizacao?.vlLongitude}
										slat={this.state.model.endereco?.localizacao?.vlLatitude}
									/>

									<FormItem md={2} label="Altitude">
										<InputGroup>
											<InputNumberFormat placeholder="999,99 mts" disabled={this.state.model.vlAltitude === null || !Config.getPermissaoAnsPerfil(this.state.model).flPodeEditar} allowNegative={true} name="vlAltitude" value={this.state.model.vlAltitude} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} prefix="" suffix=" mts" required={true} onChange={this.handleChangeModel} />
											<InputGroupButton>
												<BtnButton color={this.state.model.vlAltitude === null ? BtnButton.color.dark : BtnButton.color.outlineDark} onClick={e => this.setState(state => { state.model.vlAltitude = state.model.vlAltitude === null ? 0 : null; return state; })}>
													<IconPencil />
												</BtnButton>
											</InputGroupButton>
										</InputGroup>
									</FormItem>
								</Row>
								<Row className="g-3 pt-2 pb-2 px-2">
									<H6 className="border-dark border-bottom mb-2 pb-1 fw-bold"> Sobre o Furo</H6>

									<FormItem md={2} label="Limite Contratado">
										<InputNumberFormat allowNegative={false} name="vlProfundidadeLimite" value={this.state.model.vlProfundidadeLimite} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} suffix=" mts" required={true} onChange={this.handleChangeModel} disabled={!Config.getPermissaoAnsPerfil(this.state.model).flPodeEditar} />
									</FormItem>

									<FormItem md={2} label="Limite Alcançado">
										<InputNumberFormat allowNegative={false} name="vlProfundidadeFuro" value={this.state.model.vlProfundidadeFuro} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} suffix=" mts" required={true} onChange={this.handleChangeModel} disabled={!Config.getPermissaoAnsPerfil(this.state.model).flPodeEditar} />
									</FormItem>

									<FormItem md={2} label="⌀ Interno">
										<InputNumberFormat allowNegative={false} name="vlDiametroAmostradorInterno" value={this.state.model.vlDiametroAmostradorInterno} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} suffix=" mm" required={true} onChange={this.handleChangeModel} disabled={!Config.getPermissaoAnsPerfil(this.state.model).flPodeEditar} />
									</FormItem>

									<FormItem md={2} label="⌀ Externo">
										<InputNumberFormat allowNegative={false} name="vlDiametroAmostradorExterno" value={this.state.model.vlDiametroAmostradorExterno} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} suffix=" mm" required={true} onChange={this.handleChangeModel} disabled={!Config.getPermissaoAnsPerfil(this.state.model).flPodeEditar} />
									</FormItem>

									<FormItem md={2} label="Altura da Queda">
										<InputNumberFormat allowNegative={false} name="vlAlturaQueda" value={this.state.model.vlAlturaQueda} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} prefix="" suffix=" cm" required={true} onChange={this.handleChangeModel} disabled={!Config.getPermissaoAnsPerfil(this.state.model).flPodeEditar} />
									</FormItem>

									<FormItem md={2} label="Peso da Queda">
										<InputNumberFormat allowNegative={false} name="vlPesoQueda" value={this.state.model.vlPesoQueda} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} prefix="" suffix=" Kgf" required={true} onChange={this.handleChangeModel} disabled={!Config.getPermissaoAnsPerfil(this.state.model).flPodeEditar} />
									</FormItem>

									<FormItem md={2} label="Valor da Cota">
										<InputNumberFormat allowNegative={false} name="vlCota" value={this.state.model.vlCota} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} prefix="" suffix=" mts" required={true} onChange={this.handleChangeModel} disabled={!Config.getPermissaoAnsPerfil(this.state.model).flPodeEditar} />
									</FormItem>

									<FormItem md={2} label="Cota Base">
										<InputGroup>
											<InputNumberFormat placeholder="999,99 mts" disabled={this.state.model.vlCotaBase === null || !Config.getPermissaoAnsPerfil(this.state.model).flPodeEditar} allowNegative={true} name="vlCotaBase" value={this.state.model.vlCotaBase} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} prefix="" suffix=" mts" required={true} onChange={this.handleChangeModel} />
											<InputGroupButton>
												<BtnButton color={this.state.model.vlCotaBase === null ? BtnButton.color.dark : BtnButton.color.outlineDark} onClick={e => this.setState(state => { state.model.vlCotaBase = state.model.vlCotaBase === null ? 0 : null; return state; })}>
													<IconPencil />
												</BtnButton>
											</InputGroupButton>
										</InputGroup>
									</FormItem>

									<FormItem md={8} label="Penetração?">
										<BtnRadioGroup name="cdPenetracao" color={BtnRadioGroup.color.primary} opcional={true} value={this.state.model.cdPenetracao} onClick={this.handleChangeModel} disabled={!Config.getPermissaoAnsPerfil(this.state.model).flPodeEditar}>
											<BtnRadio value="PENETRAVEL" label="Penetrável" />
											<BtnRadio value="IMPENETRAVEL_TRADO" label="Imp. Trado" />
											<BtnRadio value="IMPENETRAVEL_TREPANO" label="Imp. Trépano" />
											<BtnRadio value="IMPENETRAVEL_PERCUSSAO" label="Imp. Percussão" />
										</BtnRadioGroup>
									</FormItem>

								</Row>

								<Row className="g-3 pt-2 pb-2 px-2">
									<H6 className="border-dark border-bottom mb-2 pb-1 fw-bold"> Sobre a água</H6>

									<FormItem md={2} label="Encontrou Água?">
										<BtnRadioGroupSimNao name="flEncontrouAgua" value={this.state.model.flEncontrouAgua} required={true} onChange={this.handleChangeModel} disabled={!Config.getPermissaoAnsPerfil(this.state.model).flPodeEditar} />
									</FormItem>

									<FormItem md={2} label="Nível Água">
										<InputNumberFormat allowNegative={false} max={this.state.model.vlProfundidadeFuro} name="vlProfundidadeNivelAgua" value={this.state.model.flEncontrouAgua ? this.state.model.vlProfundidadeNivelAgua : 0} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} suffix=" mts" required={true} onChange={this.handleChangeModel} disabled={!Config.getPermissaoAnsPerfil(this.state.model).flPodeEditar || !this.state.model.flEncontrouAgua} />
									</FormItem>

									<FormItem md={2} label="Usou Água?">
										<BtnRadioGroupSimNao name="flUsouCirculacaoAgua" value={this.state.model.flUsouCirculacaoAgua} required={true} onChange={this.handleChangeModel} disabled={!Config.getPermissaoAnsPerfil(this.state.model).flPodeEditar} />
									</FormItem>

									<FormItem md={2} label="Início">
										<InputNumberFormat allowNegative={false} max={this.state.model.vlProfundidadeFuro} name="vlProfundidadeEnsaioInicio" value={this.state.model.vlProfundidadeEnsaioInicio} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} suffix=" mts" required={true} onChange={this.handleChangeModel} disabled={!Config.getPermissaoAnsPerfil(this.state.model).flPodeEditar || !this.state.model.flUsouCirculacaoAgua} />
									</FormItem>

									<FormItem md={1} label="10 min">
										<InputNumberFormat allowNegative={false} name="vlProfundidadeEnsaio1" value={this.state.model.vlProfundidadeEnsaio1} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} suffix=" cm" required={true} onChange={this.handleChangeModel} disabled={!Config.getPermissaoAnsPerfil(this.state.model).flPodeEditar || !this.state.model.flUsouCirculacaoAgua} />
									</FormItem>

									<FormItem md={1} label="20 min">
										<InputNumberFormat allowNegative={false} name="vlProfundidadeEnsaio2" value={this.state.model.vlProfundidadeEnsaio2} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} suffix=" cm" required={true} onChange={this.handleChangeModel} disabled={!Config.getPermissaoAnsPerfil(this.state.model).flPodeEditar || !this.state.model.flUsouCirculacaoAgua} />
									</FormItem>

									<FormItem md={1} label="30 min">
										<InputNumberFormat allowNegative={false} name="vlProfundidadeEnsaio3" value={this.state.model.vlProfundidadeEnsaio3} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} suffix=" cm" required={true} onChange={this.handleChangeModel} disabled={!Config.getPermissaoAnsPerfil(this.state.model).flPodeEditar || !this.state.model.flUsouCirculacaoAgua} />
									</FormItem>

									<FormItem md={1} label="Fim">
										<InputNumberFormat allowNegative={false} disabled={true} value={(this.state.model.vlProfundidadeEnsaioInicio + (this.state.model.vlProfundidadeEnsaio1 / 100) + (this.state.model.vlProfundidadeEnsaio2 / 100) + + (this.state.model.vlProfundidadeEnsaio3 / 100))} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} suffix=" mts" required={true} onChange={this.handleChangeModel} />
									</FormItem>
								</Row>

								<Row className="g-3 pt-2 pb-2 px-2">
									<H6 className="border-dark border-bottom mb-2 pb-1 fw-bold"> Sobre o Revestimento</H6>
									<FormItem md={4} label="Revestimento">
										<BtnRadioGroup name="cdRevestimento" color={BtnRadioGroup.color.primary} opcional={true} value={this.state.model.cdRevestimento} onClick={this.handleChangeModel} disabled={!Config.getPermissaoAnsPerfil(this.state.model).flPodeEditar}>
											<BtnRadio value="N" label="Não usou" />
											<BtnRadio value="P" label="Parcial" />
											<BtnRadio value="T" label="Total" />
										</BtnRadioGroup>
									</FormItem>

									{this.state.model.cdRevestimento === "N" ?
										<FormItem md={2} label="Prof. Revestimento">
											<InputNumberFormat allowNegative={false} value={0} readOnly={true} disabled={true} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} suffix=" mts" />
										</FormItem> : <></>}
									{this.state.model.cdRevestimento === "P" ?
										<FormItem md={2} label="Prof. Revestimento">
											<InputNumberFormat max={this.state.model.vlProfundidadeFuro} allowNegative={false} name="vlProfundidadeRevestimento" value={this.state.model.vlProfundidadeRevestimento} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} suffix=" mts" required={true} onChange={this.handleChangeModel} disabled={!Config.getPermissaoAnsPerfil(this.state.model).flPodeEditar} />
										</FormItem> : <></>}
									{this.state.model.cdRevestimento === "T" ?
										<FormItem md={2} label="Prof. Revestimento">
											<InputNumberFormat allowNegative={false} value={this.state.model.vlProfundidadeFuro} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} suffix=" mts" required={true} readOnly={true} />
										</FormItem> : <></>}

									{this.state.model.cdRevestimento !== "N" ?
										<FormItem md={2} label="⌀ Revestimento">
											<InputNumberFormat allowNegative={false} name="vlDiametroRevestimento" value={this.state.model.vlDiametroRevestimento} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} suffix=" mm" required={true} onChange={this.handleChangeModel} disabled={!Config.getPermissaoAnsPerfil(this.state.model).flPodeEditar} />
										</FormItem> : <></>}

									<BtnSubmit hidden > Enviar </BtnSubmit>
								</Row>
							</Form>
						</Tab>
						<Tab show={Permissao.getInstance().getPermissoes([61])} title={<><IconScrewdriver /> Avanço</>} disabled={!this.state.model.idAnsPerfil} opened={false} className="m-2">
							<ListAnsPerfilAvanco parent={this.state.model} localOpen={BaseList.localOpen.modal} />
						</Tab>
						<Tab show={Permissao.getInstance().getPermissoes([61]) && Boolean(this.state.model.tipoAnsPerfil?.cdTipoAnsPerfil !== "ST")} title={<><IconSortNumericDown /> Golpes</>} disabled={!this.state.model.idAnsPerfil} opened={false} className="m-2">
							<ListAnsPerfilGolpe parent={this.state.model} localOpen={BaseList.localOpen.modal} />
						</Tab>
						<Tab show={Permissao.getInstance().getPermissoes([61])} title={<><IconSortDown /> Classificação</>} disabled={!this.state.model.idAnsPerfil} opened={false} className="m-2">
							<ListAnsPerfilClassificacao parent={this.state.model} localOpen={BaseList.localOpen.modal} />
						</Tab>
						<Tab show={Permissao.getInstance().getPermissoes([61])} title={<><SignStopFill /> Parada</>} disabled={!this.state.model.idAnsPerfil} opened={false} className="m-2">
							<ManyToManyCheckAnsPerfilParadaByAnsCriterioParada typeCheck={ManyToManyCheckAnsPerfilParadaByAnsCriterioParada.typeCheck.box} parent={this.state.model} search={false} />
						</Tab>
						<Tab show={Permissao.getInstance().getPermissoes([66])} title={<><IconPersonFill /> Pessoas </>} disabled={!this.state.model.idAnsPerfil} opened={false} className="m-2">
							<ListAnsPerfilPessoa parent={this.state.model} localOpen={BaseList.localOpen.modal} />
						</Tab>
						<Tab show={Permissao.getInstance().getPermissoes([118])} title={<><IconCamera /> Fotos</>} disabled={!this.state.model.idAnsPerfil} opened={false} className="m-0">
							<ListAnsPerfilFoto parent={this.state.model} localOpen={BaseList.localOpen.modal} />
						</Tab>
						<Tab show={Permissao.getInstance().getPermissoes([124])} title={<><IconMap /> Plantas</>} disabled={!this.state.model.idAnsPerfil} opened={false} className="m-2">
							<ListAnsPerfilPlanta parent={this.state.model} localOpen={BaseList.localOpen.modal} />
						</Tab>
						<Tab show={Permissao.getInstance().getPermissoes([200])} title={<><IconPaperclip /> Documentos</>} disabled={!this.state.model.idAnsPerfil} opened={false} className="m-2">
							<ListAnsPerfilArquivo parent={this.state.model} localOpen={BaseList.localOpen.modal} />
						</Tab>
						<Tab show={Permissao.getInstance().getPermissoes([115])} title={<><IconGraphDownArrow /> Resultado</>} disabled={!this.state.model.idAnsPerfil} opened={false} className="p-0 m-0" onOpenTab={e => this.setState({ keySvgPerfil: Math.random() })} >
							<TabNavigator className="border-light">
								{this.handleResultado().map((pagina, index) => (
									<Tab title={`Página ${pagina}`} className="m-2" key={(this.state.keySvgPerfil + "." + index)}>
										<SvgPerfil parent={this.state.model} pagina={pagina} />
									</Tab>
								))}
							</TabNavigator>
						</Tab>
						<Tab show={Permissao.getInstance().getPermissoes([219])} title={<><IconMapFill /> Mapa</>} disabled={!this.state.model.idAnsPerfil} opened={false} className="m-2" onOpenTab={e => this.setState({ keySvgMapa: Math.random() })}>
							<SvgMapa key={(this.state.keySvgMapa)} ordem={this.state.model.ordem} />
						</Tab>

						<TabDropdown label="Opções" alignment={DropDown.alignment.end}>
							<DropDownItem title="Novo" type="button" onClick={e => this.handleNew(e, this.state.model)}> <IconNew /> Novo Perfil</DropDownItem>
							<DropDownItem title="Copiar" type="button" onClick={e => this.handleCopy(e, this.state.model)}> <IconCopy /> Copiar este Perfil</DropDownItem>
							<DropDownDivider />
							<DropDownItem title="Ir para Ordem" type="button" onClick={e => this.handleIrParaOrdem(e)}> <IconFilterSquare /> Ir para Ordem </DropDownItem>
							<DropDownItem title="Alterar Status" type="button" onClick={e => this.handleAlterarStatus(e, this.state.model)} show={Config.getPermissaoAnsPerfil(this.state.model).flPodeAlterarStatus}> <IconNodePlusFill /> Alterar Status </DropDownItem>
							<DropDownDivider />
							<DropDownItem show={Permissao.getInstance().getPermissoes([115])} title="Imprimir" type="button" disabled={!this.state.model.idAnsPerfil} onClick={e => this.handleImprimirAns(e, this.state.model)}> <IconPrinterFill /> Imprimir Perfil </DropDownItem>
							<DropDownItem show={Permissao.getInstance().getPermissoes([116])} title="Imprimir" type="button" disabled={!this.state.model.idAnsPerfil} onClick={e => this.handleRelatorioAns(e, this.state.model)}> <IconPrinterFill /> Imprimir Relatório </DropDownItem>
							<DropDownItem show={Permissao.getInstance().getPermissoes([219])} title="Imprimir" type="button" disabled={!this.state.model.idAnsPerfil} onClick={e => this.handleImprimirMapa(e, this.state.model)}> <IconMapFill /> Imprimir Mapa </DropDownItem>
						</TabDropdown>

						{this.state.ansPerfils.length > 1 ?
							<TabDropdown label={<><IconCrosshair2 /> Perfis ({this.state.ansPerfils.length})</>} alignment={DropDown.alignment.end}>
								{this.state.ansPerfils.map((item, index) => (
									<DropDownItem key={index} disabled={index === this.state.ansIndex} title="Alterar Status" type="button" onClick={e => this.handleChangePerfil(e, item)}>
										<IconCrosshair2 /> {item?.tipoAnsPerfil?.cdTipoAnsPerfil}-{item.nrTipoAnsPerfil} - {item.dsSite}
									</DropDownItem>
								))}
							</TabDropdown>
							: <></>}
					</TabNavigator>
				</PanelBody>
				<PanelFooter>
					<FormButtons show={this.state.tabIndex === 0} onClose={this.props.localOpen === BaseCrud.localOpen.embedded ? undefined : this.handleClose} onSubmit={this.handleSubmitForm} disabled={[!Config.getPermissaoAnsPerfil(this.state.model).flPodeEditar]} />
				</PanelFooter>
			</Panel>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <FormAnsPerfil {...props} navigate={navigate} params={params} location={location} />
}
export default With