import React from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import PainelService from "../../services/PainelService";
import ProgressBar from "./ProgressBar";
import Bloco from "./Bloco";
//import FormSenhaConfirmacao from "../Login/FormSenhaConfirmacao";
import Storage from "../../controllers/Storage";
import Auth from "../../controllers/Auth";
import { BaseComponent } from "../../siht/base";
import { BtnButton, Div } from "../../siht/elements";
import { ArrowClockwise as IconArrowClockwise, ArrowLeft as IconArrowLeft, EyeFill as IconEyeFill, EyeSlashFill as IconEyeSlashFill, Funnel as IconFunnel, PauseFill as IconPauseFill, PlayFill as IconPlayFill, SlashCircleFill as IconSlashCircleFill } from "react-bootstrap-icons";
import { FormItemEmpresaUnidade } from "../../components/FormItem";
import { Row } from "../../siht/components";

class Painel extends BaseComponent {

    constructor(props) {
        super(props);

        this.getPainel = this.getPainel.bind(this);
        this.fnCallback = this.fnCallback.bind(this);
        this.handleChangeTab = this.handleChangeTab.bind(this);
        this.handleClickPlayPause = this.handleClickPlayPause.bind(this);        
        this.handleClickFilter = this.handleClickFilter.bind(this);
        this.handleClickTempoUpdate = this.handleClickTempoUpdate.bind(this);
        this.handleClickVerValor = this.handleClickVerValor.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeFilter = this.handleChangeFilter.bind(this);

        this.state = {
            usuario: Auth.getDataToken(),
            nrTempoAlterarTab: 5,
            indexPainelActive: 0,
            data: [],            
            filterable: false,
            play: true,
            flVerValor: Storage.getVisualizacaoValor()
        };

        this.state.filter = {
            unidade : null
        }
    }

    componentDidMount() {
        super.componentDidMount();
        this.getPainel();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    getPainel() {
        this.setState({ data: [], indexPainelActive: 0 }, () => {
            
            PainelService.getPaineis(this.props.params?.id, (response) => {
                let paineis = response.filter(i => i.idPainel);
                paineis.forEach(painel => {
                    painel.blocos = response.filter(i => i.idBloco && parseInt(i.painel.idPainel) === parseInt(painel.idPainel));
                });
                paineis[0].blocos[0].flMostrar = true;
                this.setState({ data: paineis });
            });
        });
    }

    handleChangeTab(index) {
        if (index === undefined) {
            if (this.state.data.length > 0) {
                index = this.state.indexPainelActive === (this.state.data.length - 1) ? 0 : this.state.indexPainelActive + 1;
            }
        }

        if (index !== undefined) {
            this.setState({ indexPainelActive: index });
        }
    }

    handleClickTempoUpdate() {
        this.setState(state => {
            switch (parseInt(state.nrTempoAlterarTab)) {
                case 0:
                    state.nrTempoAlterarTab = 1;
                    break;
                case 1:
                    state.nrTempoAlterarTab = 5;
                    break;

                case 5:
                    state.nrTempoAlterarTab = 10;
                    break;

                case 10:
                    state.nrTempoAlterarTab = 30;
                    break;

                case 30:
                    state.nrTempoAlterarTab = 60;
                    break;

                default:
                    state.nrTempoAlterarTab = 0;
            }
            return state;
        });
    }

    handleClickPlayPause() {
        this.setState(state => {
            state.play = !Boolean(state.play);
            return state;
        });
    }

    handleClickFilter() {
        this.setState({ filterable: !this.state.filterable });
    }

    handleClickVerValor() {

        /*if (!Boolean(this.state.flVerValor) && Boolean(parseInt(this.state.usuario.flExigirSenhaVerValor))) {

            EventBus.dispatch("modal-add", {
                title: 'Confirmação de Senha',
                sizeModal: 'modal-md',
                config: {
                    isModal: true
                },
                form: FormSenhaConfirmacao,
                onClose: (response) => {
                },
                onSave: (response) => {
                    this.setState({ flVerValor: Storage.uptVisualizacaoValor() });
                }
            });
        } else {
            this.setState({ flVerValor: Storage.uptVisualizacaoValor() });
        }*/
    }

    fnCallback(indexPainel, indexBloco) {

        if ((indexBloco + 1) < (this.state.data[indexPainel].blocos.length)) {
            indexBloco++;

            this.setState(state => {
                state.data[indexPainel].blocos[indexBloco].flMostrar = true;
                return state;
            });
        } else if ((indexPainel + 1) < (this.state.data.length)) {
            indexPainel++;
            indexBloco = 0;

            this.setState(state => {
                if (state.data[indexPainel].blocos.length > 0) {
                    state.data[indexPainel].blocos[indexBloco].flMostrar = true;
                }
                return state;
            });
        }
    }

    render() {
        return (
            <Div className="m-0 p-3 d-flex">

                <nav className="pe-2">
                    <Div className="nav nav-pills flex-column" id="nav-tab" role="tablist">
                        {this.state.data.map((group, indexPainel) => (
                            <button key={indexPainel} className={`nav-link p-0 ${parseInt(indexPainel) === this.state.indexPainelActive ? "active" : ""}`} onClick={e => this.handleChangeTab(indexPainel)}>
                                {indexPainel + 1}
                            </button>
                        ))}
                    </Div>

                    <hr className="hr" />

                    <Div>

                        <BtnButton color={BtnButton.color.outlinePrimary} sizing={BtnButton.sizing.sm} className="m-2" onClick={this.handleClickVerValor}>
                            {Boolean(this.state.flVerValor) ? <IconEyeFill/> : <IconEyeSlashFill className="text-danger"/>}
                        </BtnButton>

                    </Div>
                    <Div>
                        <BtnButton color={BtnButton.color.outlinePrimary} sizing={BtnButton.sizing.sm} className="m-2" onClick={this.handleClickPlayPause}>
                            {Boolean(this.state.play) ?
                                <IconPauseFill/>
                                :
                                <IconPlayFill/>
                            }
                        </BtnButton>
                    </Div>

                    <Div>
                        <BtnButton color={BtnButton.color.outlinePrimary} sizing={BtnButton.sizing.sm} className="m-2" onClick={this.getPainel}>
                            <IconArrowClockwise/>
                        </BtnButton>
                    </Div>

                    <Div>
                        <BtnButton color={`${Boolean(this.state.filterable) ? BtnButton.color.primary : BtnButton.color.outlinePrimary}`} sizing={BtnButton.sizing.sm} className="m-2" onClick={this.handleClickFilter}>
                            <IconFunnel/>
                        </BtnButton>
                    </Div>

                    <BtnButton color={BtnButton.color.outlinePrimary} sizing={BtnButton.sizing.sm} className="m-2" onClick={this.handleClickTempoUpdate}>
                        { parseInt(this.state.nrTempoAlterarTab) > 0 ? <Div style={{ width: "16px" }}>{this.state.nrTempoAlterarTab}</Div> : <IconSlashCircleFill/> }
                    </BtnButton>

                    <Div>
                        <Link to="/" className="btn btn-outline-primary btn-sm m-2">
                            <IconArrowLeft/>
                        </Link>
                    </Div>
                </nav>

                <Div className="tab-content w-100">

                    {Boolean(this.state.filterable) ? 
                        <Row className="mb-3">
                            <FormItemEmpresaUnidade md={[6,6]} name="unidade" value={this.state.filter.unidade} required={false} onChange={(e,v,p) => this.handleChangeFilter(e,v,p, this.getPainel)} opcional={true}/>                            
                        </Row>
                    : <></>}

                    {this.state.data.map((painel, indexPainel) => (
                        <Div key={indexPainel} className={`tab-pane fade ${parseInt(indexPainel) === this.state.indexPainelActive ? "show active" : ""}`} role="tabpanel">
                            <Div className="row g-3">
                                {painel.blocos.filter(b => b.flMostrar).map((bloco, indexBloco) => (
                                    <Bloco idBloco={bloco.idBloco} filterable={this.state.filterable} play={this.state.play} key={indexBloco} filter={this.state.filter} fnCallback={b => this.fnCallback(indexPainel, indexBloco)} />
                                ))}

                            </Div>
                        </Div>
                    ))}

                </Div>

                <Div className="fixed-bottom">
                    {<ProgressBar play={parseInt(this.state.nrTempoAlterarTab) > 0} timeChange={(this.state.nrTempoAlterarTab * 60)} callback={this.handleChangeTab} />}
                </Div>

            </Div>
        )
    }
}

function With(props) {
    let navigate = useNavigate();
    let params = useParams();
    let location = useLocation();
    return <Painel {...props} navigate={navigate} params={params} location={location} />
}

export default With