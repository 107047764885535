import Http from "../controllers/Http";

export default class PessoaTelefoneTipoContatoService {

	static urlBase = "/pessoaTelefoneTipoContato/";

	static getForCombo(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(PessoaTelefoneTipoContatoService.urlBase + 'forCombo', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static list(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(PessoaTelefoneTipoContatoService.urlBase + 'list', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneById(id, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.get(PessoaTelefoneTipoContatoService.urlBase + id)
			.then(fnSucess)
			.catch(fnError);
	}

	static create(data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(PessoaTelefoneTipoContatoService.urlBase, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static update(id, data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.put(PessoaTelefoneTipoContatoService.urlBase + id, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static delete(id, fnSucess = () => { }, fnError = () => { }) {
		if(Number.isInteger(id)){
			Http.getInstanceAppJson()
				.delete(PessoaTelefoneTipoContatoService.urlBase + id)
				.then(fnSucess)
				.catch(fnError);
		}else{
			Http.getInstanceAppJson()
			.post(PessoaTelefoneTipoContatoService.urlBase + 'delete', id)
			.then(fnSucess)
			.catch(fnError);
		}
	}

	static save(data, fnSucess = () => { }, fnError = () => { }) {
		if (data.idPessoaTelefoneTipoContato) {
			this.update(data.idPessoaTelefoneTipoContato, data, fnSucess, fnError);
		} else {
			this.create(data, fnSucess, fnError);
		}
	}

	static getAllByPessoaTelefone(pessoaTelefone, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(PessoaTelefoneTipoContatoService.urlBase + 'pessoaTelefone', pessoaTelefone)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByPessoaTelefone(pessoaTelefone, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(PessoaTelefoneTipoContatoService.urlBase + 'pessoaTelefone/' + pessoaTelefone.idPessoaTelefone)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByTipoContato(tipoContato, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(PessoaTelefoneTipoContatoService.urlBase + 'tipoContato', tipoContato)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByTipoContato(tipoContato, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(PessoaTelefoneTipoContatoService.urlBase + 'tipoContato/' + tipoContato.idTipoContato)
			.then(fnSucess)
			.catch(fnError);
	}
}