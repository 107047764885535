//#NO-CHANGE-FILE#

import Config from "./Config";
import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import SelectCargo from "../../components/SelectDB/SelectCargo";
import SelectStatusFuncionario from "../../components/SelectDB/SelectStatusFuncionario";
import ListFuncionarioCargo from "../FuncionarioCargo/ListFuncionarioCargo";
import ListFuncionarioHistorico from "../FuncionarioHistorico/ListFuncionarioHistorico";
import ListFuncionarioDependente from "../FuncionarioDependente/ListFuncionarioDependente";
import ListFuncionarioSalario from "../FuncionarioSalario/ListFuncionarioSalario";
import Permissao from "../../controllers/Permissao";
import { BaseList, BaseCrud } from "../../siht/base";
import { FormButtons, FormItem, Row, TabNavigator, Tab, Panel, PanelBody, PanelFooter, Container, TabDropdown, DropDownItem, DropDown } from "../../siht/components";
import { InputText, InputDate, Form, BtnSubmit } from "../../siht/elements";
import { FormItemEmpresaUnidade, FormItemPessoaByCdTipoPessoa } from "../../components/FormItem";
import FormRelatorioFuncionario001 from "../Relatorio/FormRelatorioFuncionario001";
import { PrinterFill } from "react-bootstrap-icons";
import { Modal } from "../../siht/controller";
import SelectEscalaTrabalho from "../../components/SelectDB/SelectEscalaTrabalho";

class FormFuncionario extends BaseCrud {

	constructor(props) {
		super(props);
		this.init = this.init.bind(this);
		this.initModel = this.initModel.bind(this);
		this.handleSuccess = this.handleSuccess.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleChangeModel = this.handleChangeModel.bind(this);
		this.handleSubmitForm = this.handleSubmitForm.bind(this);

		this.handleImprimir = this.handleImprimir.bind(this);
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init() {
		super.init(Config);
	}

	initModel() {
		return {
			idFuncionario: null,
			cargo: null,
			pessoa: null,
			unidade: null,
			statusFuncionario: null,
			escalaTrabalho: null,
			cdMatricula: "",
			cdEsocial: "",
			dtAdmissao: "",
			dtDemissao: "",
			funcionarioCargo: [],
			funcionarioHistorico: [],
			funcionarioDependente: []
		}
	}

	handleSuccess(e) {
		super.handleSuccess(e, Config);
	}

	handleClose(e) {
		super.handleClose(e, Config);
	}

	handleImprimir(e, funcionario) {
		Modal.openWindow(FormRelatorioFuncionario001, { sizing: Modal.sizing.default }, { idFuncionarios: [funcionario.idFuncionario] });
	}

	render() {
		return super.render(

			<Panel key={this.state.key} title="Cadastro de Funcionário" onClose={this.handleClose} isModal={this.isModal()} container={{ sizing: Container.sizing.fluid, className: "" }}>
				<PanelBody className="p-0 m-0">
					<TabNavigator key={this.state.key} onChangeTab={this.handleChangeTab} className="border-light">
						<Tab title="Funcionário" className="m-2">
							<Form onSubmit={this.handleSuccess} refParam={f => (this.form = f)}>
								<Row className="g-3 pb-2 px-2">
									<FormItem md={2} label="Código">
										<InputText name="idFuncionario" value={this.state.model.idFuncionario} readOnly={true} className="readonly-field" required={false} onChange={this.handleChangeModel} />
									</FormItem>

									<FormItemPessoaByCdTipoPessoa disabled={this.state.model.idFuncionario} cdTipoPessoa="F" label="Pessoa" md={10} name="pessoa" value={this.state.model.pessoa} required={true} onChange={this.handleChangeModel} />

									<FormItem md={3} label="Matrícula">
										<InputText name="cdMatricula" value={this.state.model.cdMatricula} required={false} onChange={this.handleChangeModel} />
									</FormItem>

									<FormItem md={3} label="E-Social">
										<InputText name="cdEsocial" value={this.state.model.cdEsocial} required={false} onChange={this.handleChangeModel} />
									</FormItem>

									<FormItem md={3} label="Admissão">
										<InputDate name="dtAdmissao" value={this.state.model.dtAdmissao} required={true} onChange={this.handleChangeModel} />
									</FormItem>

									<FormItem md={3} label="Demissão">
										<InputDate name="dtDemissao" value={this.state.model.dtDemissao} required={false} onChange={this.handleChangeModel} />
									</FormItem>

									<FormItem md={12} label="Cargo">
										<SelectCargo name="cargo" value={this.state.model.cargo} required={true} onChange={this.handleChangeModel} placeholder="Selecione o Cargo" />
									</FormItem>

									<FormItem md={12} label="Escala de Trabalho">
										<SelectEscalaTrabalho name="escalaTrabalho" value={this.state.model.escalaTrabalho} required={false} onChange={this.handleChangeModel} placeholder="Selecione a Escala de Trabalho" />
									</FormItem>

									<FormItemEmpresaUnidade disabled={this.state.model.idFuncionario} md={[6, 6]} name="unidade" value={this.state.model.unidade} required={true} onChange={this.handleChangeModel} />

									<FormItem md={12} label="Status do Funcionário">
										<SelectStatusFuncionario name="statusFuncionario" value={this.state.model.statusFuncionario} required={true} onChange={this.handleChangeModel} placeholder="Selecione o Status do Funcionário" />
									</FormItem>

									<BtnSubmit hidden > Enviar </BtnSubmit>
								</Row>
							</Form>
						</Tab>
						<Tab show={Permissao.getInstance().getPermissoes([212])} title="Cargos" disabled={!this.state.model.idFuncionario} opened={false} className="m-2">
							<ListFuncionarioCargo parent={this.state.model} localOpen={BaseList.localOpen.modal} />
						</Tab>
						<Tab show={Permissao.getInstance().getPermissoes([214])} title="Histórico" disabled={!this.state.model.idFuncionario} opened={false} className="m-2">
							<ListFuncionarioHistorico parent={this.state.model} localOpen={BaseList.localOpen.modal} />
						</Tab>
						<Tab show={Permissao.getInstance().getPermissoes([216])} title="Dependente" disabled={!this.state.model.idFuncionario} opened={false} className="m-2">
							<ListFuncionarioDependente parent={this.state.model} localOpen={BaseList.localOpen.modal} />
						</Tab>
						<Tab show={Permissao.getInstance().getPermissoes([215])} title="Salário" disabled={!this.state.model.idFuncionario} opened={false} className="m-2">
							<ListFuncionarioSalario parent={this.state.model} localOpen={BaseList.localOpen.modal} />
						</Tab>
						<TabDropdown label="Opções" alignment={DropDown.alignment.end}>
							<DropDownItem title="Imprimir Perfil" type="button" onClick={e => this.handleImprimir(e, this.state.model)} show={Permissao.getInstance().getPermissoes([230])}> <PrinterFill /> Imprimir Perfil </DropDownItem>
						</TabDropdown>
					</TabNavigator>
				</PanelBody>
				<PanelFooter>
					<FormButtons show={this.state.tabIndex === 0} onClose={this.handleClose} onSubmit={this.handleSubmitForm} />
				</PanelFooter>
			</Panel>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <FormFuncionario {...props} navigate={navigate} params={params} location={location} />
}
export default With