import { Component } from "react";

export default class H6 extends Component {

    render() {
        if(this.props.show !== undefined && !Boolean(this.props.show)){
            return (<></>);
        }

        const props = Object.assign({}, this.props);
        delete props.show;

        return (
            <h6 {...props}> {this.props.children} </h6>
        );
    }
}