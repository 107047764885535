//#NO-CHANGE-FILE#

import Config from "./Config";
import React from "react";
import { useNavigate, useLocation, useParams } from "react-router";
import { BaseList } from "../../siht/base";
import { DataGrid, DataGridButtons, DataGridColumn, DataGridFooter } from "../../siht/components";
import { BtnLink, Button, Div, Span } from "../../siht/elements";
import { BtnEdit, BtnDelete, StatusCustom } from "../../components/Custom";
import { Util } from "../../utils";
import { Modal } from "../../siht/controller";
import FormOrdem from "../Ordem/FormOrdem";

class ListComissaoPessoaOrdem extends BaseList {

	constructor(props) {
		super(props);
		this.getItens = this.getItens.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleNew = this.handleNew.bind(this);
		this.handleEdit = this.handleEdit.bind(this);
		this.handleCopy = this.handleCopy.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.handleMore = this.handleMore.bind(this);
		this.onSubmitFilter = this.onSubmitFilter.bind(this);
		this.handleClear = this.handleClear.bind(this);
		this.handleFormSuccess = this.handleFormSuccess.bind(this);
		this.handleFormClose = this.handleFormClose.bind(this);
		this.getTotalValor = this.getTotalValor.bind(this);
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		super.init(Config);
	}

	getItens(more){
		if(this.props?.parent){
			super.getItens(Config.getService().getAllByComissao, this.props.parent, more);
		}
	}

	handleNew(e) {
		super.handleNew(e, Config);
	}

	handleEdit(e, item) {
		super.handleEdit(e, Config, item);
	}

	handleDelete(e, item) {
		super.handleDelete(e, Config, item);
	}

	handleCopy(e, item) {
		super.handleCopy(e, Config, item);
	}

	getFormEmbedded(){
		return super.getFormEmbedded(Config);
	}

	getTotalValor(){
		let total = 0;
		if(this.state.selecteds.length === 0){
			total = this.state.data.reduce((soma, item) => soma + item.vlValor, 0);
		}else{
			total = this.state.selecteds.reduce((soma, item) => soma + item.vlValor, 0);
		}

		return total;
	}

	handleViewOrdem (e, ordem) {
		Modal.openWindow(FormOrdem, {sizing : Modal.sizing.xl}, { id : ordem.idOrdem });
	}

	render() {
		let editable = this.props.editable !== undefined ? this.props.editable : true;

		return super.render(
			<Div className="p-2 mt-2">
				<Div className="d-flex justify-content-end flex-nowrap gap-2">
					<BtnDelete disabled={this.state.selecteds.length === 0 || !editable} sizing={Button.sizing.default} onClick={e => this.handleDelete(e, this.state.selecteds)}> Excluir </BtnDelete>
				</Div>

				{this.getFormEmbedded()}

				<DataGrid onDoubleClickRow={editable ? this.handleEdit : ()=>{}} responsive={true} data={this.state.data} search={true} isDisabled={i => false} sizing={DataGrid.sizing.default} select={true} selecteds={this.state.selecteds} onSelect={(selecteds)=>{this.setState({selecteds : selecteds})}} getItemValue={i=>i.idComissaoPessoaOrdem} onMore={this.handleMore}>

					<DataGridColumn col={6} label="Pessoa" field={i => <React.Fragment>
						<Div className="fw-bold">{i.pessoa?.nmPessoa}</Div>
						<Div>{i.cargo?.nmCargo} </Div>
					</React.Fragment>} className="text-start"/>

					<DataGridColumn col={1} label="Ordem" field={i => <>
						<BtnLink onClick={(e) => this.handleViewOrdem(e, i.ordem)}> {i.ordem?.idOrdem} </BtnLink>
					</>} className="text-start"/>

					<DataGridColumn md={4} label="Cliente" field={i => <React.Fragment>
						<Span className="fw-bold">{i.ordem?.pessoa?.nmPessoa}</Span> <br/>
						<StatusCustom color={i.ordem?.pessoa?.tipoPessoa}> PESSOA {i.ordem?.pessoa?.tipoPessoa?.nmTipoPessoa} </StatusCustom>
						<Div className="fw-bold fs-7" show={i.ordem?.pessoa?.tipoPessoa?.cdTipoPessoa === "F"}>{Util.formataCPF(i.ordem?.pessoa?.nrCpf)}</Div>
						<Div className="fw-bold fs-7" show={i.ordem?.pessoa?.tipoPessoa?.cdTipoPessoa === "J"}>{Util.formataCnpj(i.ordem?.pessoa?.nrCnpj)}</Div>
						</React.Fragment>
						}
					className="text-start"/>

					
					<DataGridColumn col={1} label="Comissão" field={i => Util.floatToReais(i.vlValor)} className="text-end fw-bold text-success"/>

					<DataGridButtons>
						<BtnEdit sizing={Button.sizing.default} onClick={this.handleEdit} disabled={!editable}/>
						<BtnDelete sizing={Button.sizing.default} onClick={this.handleDelete} disabled={!editable}/>
					</DataGridButtons>

					<DataGridFooter>
						<DataGridColumn colSpan={4} className="text-center"/>
						<DataGridColumn label="Valor"  className="text-end">
							<Span className="fw-bold text-success"> {Util.floatToReais(this.getTotalValor())} </Span>
						</DataGridColumn>
						<DataGridColumn colSpan={1} className="text-center"/>
					</DataGridFooter>
				</DataGrid>
			</Div>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <ListComissaoPessoaOrdem {...props} navigate={navigate} params={params} location={location}/>
}
export default With