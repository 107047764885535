import Http from "../controllers/Http";

export default class ComissaoOrdemService {

	static urlBase = "/comissaoOrdem/";

	static getForCombo(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(ComissaoOrdemService.urlBase + 'forCombo', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static list(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(ComissaoOrdemService.urlBase + 'list', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneById(id, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.get(ComissaoOrdemService.urlBase + id)
			.then(fnSucess)
			.catch(fnError);
	}

	static create(data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(ComissaoOrdemService.urlBase, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static update(id, data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.put(ComissaoOrdemService.urlBase + id, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static delete(id, fnSucess = () => { }, fnError = () => { }) {
		if(Number.isInteger(id)){
			Http.getInstanceAppJson()
				.delete(ComissaoOrdemService.urlBase + id)
				.then(fnSucess)
				.catch(fnError);
		}else{
			Http.getInstanceAppJson()
			.post(ComissaoOrdemService.urlBase + 'delete', id)
			.then(fnSucess)
			.catch(fnError);
		}
	}

	static save(data, fnSucess = () => { }, fnError = () => { }) {
		if (data.idComissaoOrdem) {
			this.update(data.idComissaoOrdem, data, fnSucess, fnError);
		} else {
			this.create(data, fnSucess, fnError);
		}
	}

	static getAllByComissao(comissao, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(ComissaoOrdemService.urlBase + 'comissao', comissao)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByComissao(comissao, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(ComissaoOrdemService.urlBase + 'comissao/' + comissao.idComissao)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByOrdem(ordem, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(ComissaoOrdemService.urlBase + 'ordem', ordem)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByOrdem(ordem, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(ComissaoOrdemService.urlBase + 'ordem/' + ordem.idOrdem)
			.then(fnSucess)
			.catch(fnError);
	}
}