import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { BaseCrud } from "../../siht/base";
import { FormButtons, FormItem, Row, Panel, PanelBody, PanelFooter, Container, ModalDialog } from "../../siht/components";
import { Div, Form, TextArea } from "../../siht/elements";
import Config from "./Config";
import SelectFaturaNotaFiscalMotivoCancelamento from "../../components/SelectDB/SelectFaturaNotaFiscalMotivoCancelamento";
import { Modal } from "../../siht/controller";

class FormEstornar extends BaseCrud {

	constructor(props) {
		super(props);
		this.init = this.init.bind(this);
		this.handleSuccess = this.handleSuccess.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleChangeModel = this.handleChangeModel.bind(this);
		this.handleSubmitForm = this.handleSubmitForm.bind(this);

		this.state.model.faturaNotaFiscais = this.props.faturaNotaFiscais;
		this.state.model.faturaNotaFiscalMotivoCancelamento = {};
		this.state.model.txMotivoCancelamento = "";
		this.state.model.flSubstituir = this.props.flSubstituir !== undefined ? this.props.flSubstituir : false;
		
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		super.init(Config);
	}

	handleSuccess(e) {
        Modal.confirm("Confirmação", "Deseja realmente CANCELAR a(s) nota(s) selecionada(s)?" , "Essa é uma ação irreversível", Modal.style.danger, () =>{
			Config.getService().estornar(this.state.model, () =>{
				super.handleClose(e, Config);
			});
		});
	}

	handleClose(e) {
		super.handleClose(e, Config);
	}

	render() {
		return super.render(
				<Div key={this.state.key}>
					<Form onSubmit={this.handleSuccess} refParam={f => (this.form = f)}>
						<Panel title="Cancelamento de Notas" onClose={this.handleClose} isModal={this.isModal()} container={{sizing: Container.sizing.xxl, className : ""}} modal={{sizing: this.props.sizing ? this.props.sizing : ModalDialog.sizing.xl}}>
							<PanelBody>
								<Row className="g-3 pb-2 px-2 align-items-center">
									
									<FormItem md={12} label="Motivo">
										<SelectFaturaNotaFiscalMotivoCancelamento name="faturaNotaFiscalMotivoCancelamento" value={this.state.model.faturaNotaFiscalMotivoCancelamento} required={true} onChange={this.handleChangeModel} placeholder="Selecione o Motivo"/>
                                    </FormItem>

									<FormItem md={12} label="Descrição do Motivo">
										<TextArea rows={5}  placeholder="Digite o motivo do cancelamento" name="txMotivoCancelamento" value={this.state.model.txMotivoCancelamento} required={this.state.model.faturaNotaFiscalMotivoCancelamento?.cdFaturaNotaFiscalMotivoCancelamento === "OUTROS"} onChange={this.handleChangeModel}/>
									</FormItem>
								</Row>
							</PanelBody>
							<PanelFooter>
								<FormButtons label={["Cancelar Notas"]} onClose={this.handleClose} />
							</PanelFooter>
						</Panel>
					</Form>
				</Div>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <FormEstornar {...props} navigate={navigate} params={params} location={location}/>
}
export default With