//#NO-CHANGE-FILE#

import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { BaseCrud } from "../../siht/base";
import { FormButtons, FormItem, Row, Panel, PanelBody, PanelFooter, Container, ModalDialog, BtnRadioGroup } from "../../siht/components";
import { InputText, InputNumberFormat, Form, BtnRadio, BtnButton } from "../../siht/elements";
import Config from "./Config";
import { Modal } from "../../siht/controller";
import BtnRadioGroupSimNao from "../../components/BtnRadioGroupSimNao";

class FormFaturaNotaFiscalCargaTributaria extends BaseCrud {

	constructor(props) {
		super(props);
		this.init = this.init.bind(this);
		this.initModel = this.initModel.bind(this);
		this.handleSuccess = this.handleSuccess.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleChangeModel = this.handleChangeModel.bind(this);
		this.handleSubmitForm = this.handleSubmitForm.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		super.init(Config, () =>{
			Config.getService().getOneByFaturaNotaFiscal(this.props.parent, ( response )=>{
				response.flTemCargaTributaria = true;
				this.setState({model : response});
			}, ()=>{
				this.setState({model : this.initModel()});
			});
		});
	}

	initModel(){
		return {
			idFaturaNotaFiscalCargaTributaria : null,
			faturaNotaFiscal : this.props.parent !== undefined ? this.props.parent : {},
			dsFonte : "",
			vlCargaTributaria : 0,
			cdTipoValor : "PERCENTUAL",
			flTemCargaTributaria : false
		}
	}

	handleSuccess(e) {
		super.handleSuccess(e, Config);
	}

	handleClose(e) {
		super.handleClose(e, Config);
	}

	handleDelete(){
		Modal.confirm("Confirmação", "Deseja realmente remover o vínculo da carga tributária?", false, Modal.style.warning, ()=>{
			Config.getService().delete(this.state.model.idFaturaNotaFiscalCargaTributaria, ()=> {
				this.init();
			});
		});
	}

	render() {
		let editable = this.props.editable !== undefined ? this.props.editable : true;

		return super.render(
			<Form onSubmit={this.handleSuccess} refParam={f => (this.form = f)} key={this.state.key}>
				<Panel key={this.state.key} title="Cadastro de FaturaNotaFiscalCargaTributaria" localOpen={this.props.localOpen}  onClose={this.handleClose} isModal={this.isModal()} container={{sizing: Container.sizing.xxl, className : ""}} modal={{sizing: this.props.sizing ? this.props.sizing : ModalDialog.sizing.xl}}>
					<PanelBody>
						<Row className="g-0 py-3 px-3">
							<FormItem md={2} label="Tem Carga Tributária?">
								<BtnRadioGroupSimNao disabled={!editable} name="flTemCargaTributaria" value={this.state.model.flTemCargaTributaria} required={true} onChange={(e,v,p) => this.handleChange(e,v,p, this.state.model)}/>
							</FormItem>
						</Row>
						<Row className="g-3 py-2 px-3"  show={this.state.model.flTemCargaTributaria}>
							<FormItem md={6} label="Fonte" >
								<InputText disabled={!editable} name="dsFonte" value={this.state.model.dsFonte} maxLength={100} required={true} onChange={(e,v,p) => this.handleChange(e,v,p, this.state.model)}/>
							</FormItem>
							<FormItem md={3} label="Tipo">
								<BtnRadioGroup disabled={!editable} name="cdTipoValor" color={BtnRadioGroup.color.primary} opcional={true} value={this.state.model.cdTipoValor} onClick={(e,v,p) => this.handleChange(e,v,p, this.state.model)} >
									<BtnRadio value="PERCENTUAL" label="Percentual" />
									<BtnRadio value="VALOR" label="Valor" />
								</BtnRadioGroup>
							</FormItem>
							<FormItem md={3} label="Percentual" show={this.state.model.cdTipoValor === "PERCENTUAL"}>
								<InputNumberFormat disabled={!editable} name="vlCargaTributaria" value={this.state.model.vlCargaTributaria} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} suffix=" %" required={true} onChange={(e,v,p) => this.handleChange(e,v,p, this.state.model)}/>
							</FormItem>
							<FormItem md={3} label="Valor" show={this.state.model.cdTipoValor === "VALOR"}>
								<InputNumberFormat disabled={!editable} name="vlCargaTributaria" value={this.state.model.vlCargaTributaria} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} prefix="R$ " required={true} onChange={(e,v,p) => this.handleChange(e,v,p, this.state.model)}/>
							</FormItem>
						</Row>
					</PanelBody>
					<PanelFooter>
						<FormButtons disabled={[!editable || !this.state.model.flTemCargaTributaria]}>
							<BtnButton color={BtnButton.color.outlineDanger} disabled={!editable} show={this.state.model.idFaturaNotaFiscalCargaTributaria} onClick={this.handleDelete}> Remover Dedução Vinculada </BtnButton>
						</FormButtons>
					</PanelFooter>
				</Panel>
			</Form>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <FormFaturaNotaFiscalCargaTributaria {...props} navigate={navigate} params={params} location={location}/>
}
export default With