//#NO-CHANGE-FILE#

import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import SelectCargo from "../../components/SelectDB/SelectCargo";
import { BaseCrud } from "../../siht/base";
import { FormButtons, FormItem, Row, Panel, PanelBody, PanelFooter, Container, ModalDialog } from "../../siht/components";
import { Form } from "../../siht/elements";
import Config from "./Config";
import { FormItemPessoaByCdTipoPessoa } from "../../components/FormItem";
import { FuncionarioService } from "../../services";

class FormAnsPerfilPessoa extends BaseCrud {

	constructor(props) {
		super(props);
		this.init = this.init.bind(this);
		this.handleSuccess = this.handleSuccess.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleChangeModel = this.handleChangeModel.bind(this);
		this.handleSubmitForm = this.handleSubmitForm.bind(this);
		this.handleChangePessoa = this.handleChangePessoa.bind(this);

		this.state.model = {
			idAnsPerfilPessoa : null,
			ansPerfil : this.props.parent !== undefined ? this.props.parent : {},
			cargo : null,
			pessoa : null
		};
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		super.init(Config);
	}

	handleSuccess(e) {
		super.handleSuccess(e, Config);
	}

	handleClose(e) {
		super.handleClose(e, Config);
	}

	handleChangePessoa(){
		FuncionarioService.getOneByPessoa(this.state.model.pessoa, response => {
			this.setModel({cargo : response.cargo});			
		});
	}

	render() {
		return super.render(

					<Form onSubmit={this.handleSuccess} refParam={f => (this.form = f)} key={this.state.key}>
						<Panel key={this.state.key} title="Cadastro de Pessoa" onClose={this.handleClose} isModal={this.isModal()} container={{sizing: Container.sizing.xxl, className : ""}} modal={{sizing: this.props.sizing ? this.props.sizing : ModalDialog.sizing.xl}}>
							<PanelBody>
								<Row className="g-3 pb-2 px-2">
									<FormItemPessoaByCdTipoPessoa cdTipoPessoa="F" label="Pessoa" md={12} name="pessoa" value={this.state.model.pessoa} required={true} onChange={(e,v,p) => this.handleChangeModel(e,v,p, this.handleChangePessoa)}/>
									
									<FormItem md={12} label="Cargo do Resposável">
										<SelectCargo name="cargo" value={this.state.model.cargo} required={true} onChange={this.handleChangeModel} placeholder="Selecione o Cargo"/>
									</FormItem>
									
								</Row>
							</PanelBody>
							<PanelFooter>
								<FormButtons onClose={this.handleClose} />
							</PanelFooter>
						</Panel>
					</Form>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <FormAnsPerfilPessoa {...props} navigate={navigate} params={params} location={location}/>
}
export default With