//#NO-CHANGE-FILE#

import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { BaseCrud } from "../../siht/base";
import { FormButtons, FormItem, Row, Panel, PanelBody, PanelFooter, Container, ModalDialog } from "../../siht/components";
import { InputNumberFormat, InputNumber, InputDate, Form } from "../../siht/elements";
import Config from "./Config";

class FormContaPagarParcela extends BaseCrud {

	constructor(props) {
		super(props);
		this.init = this.init.bind(this);
		this.initModel = this.initModel.bind(this);
		this.handleSuccess = this.handleSuccess.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleChangeModel = this.handleChangeModel.bind(this);
		this.handleSubmitForm = this.handleSubmitForm.bind(this);
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		super.init(Config);
	}

	initModel(){
		return {
			idContaPagarParcela : null,
			contaPagar : this.props.parent !== undefined ? this.props.parent : {},
			vlConta : 0,
			vlAberto : 0,
			nrParcela : null,
			dtVencimento : ""
		}
	}

	handleSuccess(e) {
		super.handleSuccess(e, Config);
	}

	handleClose(e) {
		super.handleClose(e, Config);
	}

	render() {
		var disabled=false;
		if(!disabled && this.state.model.contaPagar !== undefined){
			disabled = !this.state.model.contaPagar.flPodeEditar;
		}
		

		return super.render(

				<Form onSubmit={this.handleSuccess} refParam={f => (this.form = f)} key={this.state.key}>
					<Panel key={this.state.key} title="Cadastro de Conta a Pagar Parcela" localOpen={this.props.localOpen}  onClose={this.handleClose} isModal={this.isModal()} container={{sizing: Container.sizing.xxl, className : ""}} modal={{sizing: this.props.sizing ? this.props.sizing : ModalDialog.sizing.xl}}>
						<PanelBody>
							<Row className="g-3 pb-2 px-2">
								<FormItem md={6} label="Parcela">
									<InputNumber name="nrParcela" value={this.state.model.nrParcela} readOnly={true} disabled={true} className="text-center"/>
								</FormItem>

								<FormItem md={6} label="Aberto">
									<InputNumberFormat name="vlAberto" value={this.state.model.vlAberto} readOnly={true} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} prefix="R$ " disabled={true}/>
								</FormItem>

								<FormItem md={6} label="Valor">
									<InputNumberFormat disabled={disabled} name="vlConta" value={this.state.model.vlConta} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} prefix="R$ " required={true} onChange={this.handleChangeModel}/>
								</FormItem>

								<FormItem md={6} label="Vencimento">
									<InputDate disabled={disabled} name="dtVencimento" value={this.state.model.dtVencimento} required={true} onChange={this.handleChangeModel}/>
								</FormItem>
							</Row>
						</PanelBody>
						<PanelFooter>
							<FormButtons onClose={this.handleClose} disabled={[disabled]}/>
						</PanelFooter>
					</Panel>
				</Form>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <FormContaPagarParcela {...props} navigate={navigate} params={params} location={location}/>
}
export default With