import Http from "../controllers/Http";

export default class PessoaTelefoneService {

	static urlBase = "/pessoaTelefone/";

	static getForCombo(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(PessoaTelefoneService.urlBase + 'forCombo', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static list(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(PessoaTelefoneService.urlBase + 'list', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneById(id, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.get(PessoaTelefoneService.urlBase + id)
			.then(fnSucess)
			.catch(fnError);
	}

	static create(data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(PessoaTelefoneService.urlBase, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static update(id, data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.put(PessoaTelefoneService.urlBase + id, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static delete(id, fnSucess = () => { }, fnError = () => { }) {
		if(Number.isInteger(id)){
			Http.getInstanceAppJson()
				.delete(PessoaTelefoneService.urlBase + id)
				.then(fnSucess)
				.catch(fnError);
		}else{
			Http.getInstanceAppJson()
			.post(PessoaTelefoneService.urlBase + 'delete', id)
			.then(fnSucess)
			.catch(fnError);
		}
	}

	static save(data, fnSucess = () => { }, fnError = () => { }) {
		if (data.idPessoaTelefone) {
			this.update(data.idPessoaTelefone, data, fnSucess, fnError);
		} else {
			this.create(data, fnSucess, fnError);
		}
	}

	static getAllByPessoa(pessoa, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(PessoaTelefoneService.urlBase + 'pessoa', pessoa)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByPessoa(pessoa, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(PessoaTelefoneService.urlBase + 'pessoa/' + pessoa.idPessoa)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByTipoTelefone(tipoTelefone, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(PessoaTelefoneService.urlBase + 'tipoTelefone', tipoTelefone)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByTipoTelefone(tipoTelefone, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(PessoaTelefoneService.urlBase + 'tipoTelefone/' + tipoTelefone.idTipoTelefone)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByPais(pais, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(PessoaTelefoneService.urlBase + 'pais', pais)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByPais(pais, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(PessoaTelefoneService.urlBase + 'pais/' + pais.idPais)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByPessoaTelefoneTipoContato(pessoaTelefoneTipoContato, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(PessoaTelefoneService.urlBase + 'pessoaTelefoneTipoContato', pessoaTelefoneTipoContato)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByPessoaTelefoneTipoContato(pessoaTelefoneTipoContato, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(PessoaTelefoneService.urlBase + 'pessoaTelefoneTipoContato/' + pessoaTelefoneTipoContato.idPessoaTelefoneTipoContato)
			.then(fnSucess)
			.catch(fnError);
	}
}