//#NO-CHANGE-FILE#

import Http from "../controllers/Http";

export default class TipoPessoaService {

	static urlBase = "/tipoPessoa/";

	static getForCombo(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(TipoPessoaService.urlBase + 'forCombo', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static list(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(TipoPessoaService.urlBase + 'list', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneById(id, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.get(TipoPessoaService.urlBase + id)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByCdTipoPessoa(cdTipoPessoa, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.get(TipoPessoaService.urlBase + 'cdTipoPessoa/' + cdTipoPessoa)
			.then(fnSucess)
			.catch(fnError);
	}

	static create(data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(TipoPessoaService.urlBase, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static update(id, data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.put(TipoPessoaService.urlBase + id, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static delete(id, fnSucess = () => { }, fnError = () => { }) {
		if(Number.isInteger(id)){
			Http.getInstanceAppJson()
				.delete(TipoPessoaService.urlBase + id)
				.then(fnSucess)
				.catch(fnError);
		}else{
			Http.getInstanceAppJson()
			.post(TipoPessoaService.urlBase + 'delete', id)
			.then(fnSucess)
			.catch(fnError);
		}
	}

	static save(data, fnSucess = () => { }, fnError = () => { }) {
		if (data.idTipoPessoa) {
			this.update(data.idTipoPessoa, data, fnSucess, fnError);
		} else {
			this.create(data, fnSucess, fnError);
		}
	}
}