//#NO-CHANGE-FILE#

import React from "react";
import { useNavigate, useLocation, useParams } from "react-router";
import Config from "./Config";
import { BaseList } from "../../siht/base";
import { Badge, BtnGroup, BtnRadioGroup, DataGrid, DataGridColumn, FormItem, PanelFooter, Row, Tab, TabNavigator } from "../../siht/components";
import { BtnButton, BtnCheck, BtnRadio, Div } from "../../siht/elements";
import Util from "../../utils/Util";
import { ClockFill as IconClockFill, Play as IconPlay, Speedometer as IconSpeedometer } from "react-bootstrap-icons";
import Chart from "react-google-charts";

class ListVeiculoResumo extends BaseList {

	constructor(props) {
		super(props);
		this.getItens = this.getItens.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleNew = this.handleNew.bind(this);
		this.handleEdit = this.handleEdit.bind(this);
		this.handleCopy = this.handleCopy.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.handleMore = this.handleMore.bind(this);
		this.onSubmitFilter = this.onSubmitFilter.bind(this);
		this.handleClear = this.handleClear.bind(this);
		this.handleFormSuccess = this.handleFormSuccess.bind(this);
		this.handleFormClose = this.handleFormClose.bind(this);
		this.handleChangeModel = this.handleChangeModel.bind(this);

		this.state.model = {
			tempo : "SEGUNDOS",
			flDesligado : true,
			flLigado : true,
			flMovimento : true,
			flLigadoParado : true,
			flDesligadoParado : true,
		};
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		super.init(Config);
	}

	initStateFilter(){
        return {
            page : 1,
            pageSize : 10,
        };
    }

	getItens(more){
		if(this.props?.parent){
			super.getItens(Config.getService().getAllByVeiculo, {...this.props.parent, ...this.state.filter}, more);
		}
	}

	handleNew(e) {
		super.handleNew(e, Config);
	}

	handleEdit(e, item) {
		super.handleEdit(e, Config, item);
	}

	handleDelete(e, item) {
		super.handleDelete(e, Config, item);
	}

	handleCopy(e, item) {
		super.handleCopy(e, Config, item);
	}

	getFormEmbedded(){
		return super.getFormEmbedded(Config);
	}

	render() {

		let vlVelocidadeMaxima = parseInt(this.props?.parent?.vlVelocidadeMaxima);

		let dataChartVelocidade = [[
			{value : "DIA", label : "DIA", type : "string"}, 
			{value : "Velocidade Média", label : "Velocidade Média", type : "number"},
			{value : "Velocidade Máxima", label : "Velocidade Máxima", type : "number"},
			{value : "Velocidade Permitida", label : "Velocidade Permitida", type : "number"}
		]];

		let dataChartDistancia = [[
			{value : "DIA", label : "DIA", type : "string"}, 
			{value : "Distância", label : "Distância", type : "number"},
		]];

		let dataChartTempo = [[
			{value : "DIA", label : "DIA", type : "string"}, 
		]];

		if(this.state.model.flDesligado){
			dataChartTempo[0].push({value : "Desligado", label : "Desligado", type : "number"});
		}

		if(this.state.model.flLigado){
			dataChartTempo[0].push({value : "Ligado", label : "Ligado", type : "number"});
		}

		if(this.state.model.flMovimento){
			dataChartTempo[0].push({value : "Movimento", label : "Movimento", type : "number"});
		}

		if(this.state.model.flLigadoParado){
			dataChartTempo[0].push({value : "Lig. Parado", label : "Lig. Parado", type : "number"});
		}

		if(this.state.model.flDesligadoParado){
			dataChartTempo[0].push({value : "Deslig. Parado", label : "Deslig. Parado", type : "number"});
		}

		[...this.state.data].reverse().forEach(i => {
			dataChartVelocidade.push([
				Util.date2Br(i.dtResumo), 
				i.vlVelocidadeMedia,
				i.vlVelocidadeMaxima,
				vlVelocidadeMaxima
			]);

			dataChartDistancia.push([
				Util.date2Br(i.dtResumo), 
				i.vlDistaciaPercorrida				
			]);

			let itemTempo = [
				Util.date2Br(i.dtResumo)
			];

			if(this.state?.model.tempo === "SEGUNDOS"){

				if(this.state.model.flDesligado){
					itemTempo.push(parseInt(i.vlTempoDesligado));
				}
		
				if(this.state.model.flLigado){
					itemTempo.push(parseInt(i.vlTempoLigado));
				}
		
				if(this.state.model.flMovimento){
					itemTempo.push(parseInt(i.vlTempoLigadoMovimento));
				}
		
				if(this.state.model.flLigadoParado){
					itemTempo.push(parseInt(i.vlTempoLigadoParado));
				}
		
				if(this.state.model.flDesligadoParado){
					itemTempo.push(parseInt(i.vlTempoDesligadoParado));
				}
			
			}else if(this.state?.model.tempo === "MINUTOS"){
				if(this.state.model.flDesligado){
					itemTempo.push(parseInt(i.vlTempoDesligado / 60));
				}
		
				if(this.state.model.flLigado){
					itemTempo.push(parseInt(i.vlTempoLigado / 60));
				}
		
				if(this.state.model.flMovimento){
					itemTempo.push(parseInt(i.vlTempoLigadoMovimento / 60));
				}
		
				if(this.state.model.flLigadoParado){
					itemTempo.push(parseInt(i.vlTempoLigadoParado / 60));
				}
		
				if(this.state.model.flDesligadoParado){
					itemTempo.push(parseInt(i.vlTempoDesligadoParado / 60));
				}
			}else if(this.state?.model.tempo === "HORAS"){
				if(this.state.model.flDesligado){
					itemTempo.push(parseInt((i.vlTempoDesligado) / 60) / 60);
				}
		
				if(this.state.model.flLigado){
					itemTempo.push(parseInt((i.vlTempoLigado) / 60) / 60);
				}
		
				if(this.state.model.flMovimento){
					itemTempo.push(parseInt((i.vlTempoLigadoMovimento) / 60) / 60);
				}
		
				if(this.state.model.flLigadoParado){
					itemTempo.push(parseInt((i.vlTempoLigadoParado) / 60) / 60);
				}
		
				if(this.state.model.flDesligadoParado){
					itemTempo.push(parseInt((i.vlTempoDesligadoParado) / 60) / 60);
				}
			}

			dataChartTempo.push(itemTempo);
		});		

		return super.render(
			<Div className="p-0 m-0">
				<TabNavigator className="border-light p-0 m-0">
					<Tab title="Resumo" className="m-2">
						<DataGrid responsive={true} data={this.state.data} search={false} isDisabled={i => false} sizing={DataGrid.sizing.sm} caption={false} select={false} selecteds={this.state.selecteds} onSelect={(selecteds)=>{this.setState({selecteds : selecteds})}} getItemValue={i=>i.idVeiculoResumo} onMore={this.handleMore}>
							<DataGridColumn label="Data" field={i => Util.date2Br(i.dtResumo)} className="text-center fw-bold"/>
							<DataGridColumn label="Veloc. Média" field={i => <Badge color={Badge.color.light} className="w-100 text-nowrap border border-dark text-dark rounded px-1"> <IconSpeedometer/> {i.vlVelocidadeMedia} km/h </Badge>} className="text-center"/>
							<DataGridColumn label="Veloc. Máxima" field={i => <Badge color={i.vlVelocidadeMaxima > vlVelocidadeMaxima ? Badge.color.warning : Badge.color.success} className="w-100 text-nowrap border border-dark rounded px-1"> <IconSpeedometer/> {i.vlVelocidadeMaxima} km/h </Badge>} className="text-center"/>
							<DataGridColumn label="Distâcia" field={i => <Badge color={Badge.color.light} className="w-100 text-nowrap border border-dark text-dark rounded px-1"> <IconPlay/> {parseFloat(i.vlDistaciaPercorrida).toFixed(0)} km </Badge>} className="text-center"/>
							<DataGridColumn label="Desligado" field={i => <Badge color={Badge.color.light} className="w-100 text-nowrap border border-danger text-danger rounded px-1"> <IconClockFill/> {Util.convertMsToHM(i.vlTempoDesligado*1000)} </Badge>} className="text-center"/>
							<DataGridColumn label="Ligado" field={i => <Badge color={Badge.color.light} className="w-100 text-nowrap border border-success text-success rounded px-1"> <IconClockFill/> {Util.convertMsToHM(i.vlTempoLigado*1000)} </Badge>} className="text-center"/>
							<DataGridColumn label="Movimento" field={i => <Badge color={Badge.color.light} className="w-100 text-nowrap border border-secondary text-secondary rounded px-1"> <IconClockFill/> {Util.convertMsToHM(i.vlTempoLigadoMovimento*1000)} </Badge>} className="text-center"/>
							<DataGridColumn label="Lig. Parado" field={i => <Badge color={Badge.color.light} className="w-100 text-nowrap border border-danger text-danger rounded px-1"> <IconClockFill/> {Util.convertMsToHM(i.vlTempoLigadoParado*1000)} </Badge>} className="text-center"/>
							<DataGridColumn label="Deslig. Parado" field={i => <Badge color={Badge.color.light} className="w-100 text-nowrap border border-secondary text-secondary rounded px-1"> <IconClockFill/> {Util.convertMsToHM(i.vlTempoDesligadoParado*1000)} </Badge>} className="text-center"/>
						</DataGrid>

						<PanelFooter className="text-center py-2" show={this.state.data && this.state.data?.length > 0}>
							<BtnButton onClick={this.handleMore} disabled={!this.state.hasMore}> Buscar Mais Itens </BtnButton>
						</PanelFooter>
					</Tab>
					<Tab title="Velocidade" className="m-2 h100-calc-20" onOpenTab={e => this.setState({key : Math.random()})}>
						<Chart key={this.state.key} chartLanguage="pt-br" chartType="LineChart" width="100%" height={"100%"} data={dataChartVelocidade}
							options={{
								title: "Velocidade (km/h) por Dia",
								"legend": {
									"position": "bottom"
								},
								"chartArea": {
									"width": "chartwidth",
									"left": 50,
									"top": 50,
									"right": 20,
									"bottom" : 70
								},
								"curveType": "function",
								"pointSize": 6,
								vAxis: {
									minValue: 0,
									viewWindow: {
										min: 0
									}
								},
								series: {
									2 : {
										pointSize : 0,
										lineDashStyle: [2, 2, 20, 2, 20, 2],
										type : "area",
										color : "#149104"
									}
								}
								
							}}
							formatters={[]}
						/>
					</Tab>
					<Tab title="Distância" className="m-2 h100-calc-20" onOpenTab={e => this.setState({key : Math.random()})}>
						<Chart key={this.state.key} chartLanguage="pt-br" chartType="LineChart" width="100%" height={"100%"} data={dataChartDistancia}
								options={{
									title: "Distância (km) por Dia",
									"legend": {
										"position": "bottom"
									},
									"chartArea": {
										"width": "chartwidth",
										"left": 50,
										"top": 50,
										"right": 20,
										"bottom" : 70
									},
									"curveType": "function",
									"pointSize": 6,
									vAxis: {
										minValue: 0,
										viewWindow: {
											min: 0
										}
									},
								}}
								formatters={[]}
							/>
					</Tab>
					<Tab title="Tempo" className="m-2" onOpenTab={e => this.setState({key : Math.random()})}>

						<Row className="g-3">
							<FormItem md={4} label="Tempo">
								<BtnRadioGroup name="tempo" color={BtnRadioGroup.color.primary} opcional={true} value={this.state?.model.tempo} onChange={this.handleChangeModel}>
									<BtnRadio value="SEGUNDOS" label="Segundos" />
									<BtnRadio value="MINUTOS" label="Minutos" />
									<BtnRadio value="HORAS" label="Horas" />
								</BtnRadioGroup>
							</FormItem>

							<FormItem md={8} label="Mostrar">
								<BtnGroup opcional={true} sizing={BtnGroup.sizing.default} border={BtnGroup.border.primary}>
									<BtnCheck name="flDesligado" value={this.state.model.flDesligado} label="Desligado" onClick={this.handleChangeModel}/>
									<BtnCheck name="flLigado" value={this.state.model.flLigado} label="Ligado" onClick={this.handleChangeModel}/>
									<BtnCheck name="flMovimento" value={this.state.model.flMovimento} label="Movimento" onClick={this.handleChangeModel}/>
									<BtnCheck name="flLigadoParado" value={this.state.model.flLigadoParado} label="Ligado Parado" onClick={this.handleChangeModel}/>
									<BtnCheck name="flDesligadoParado" value={this.state.model.flDesligadoParado} label="Desligado Parado" onClick={this.handleChangeModel}/>
								</BtnGroup>
							</FormItem>
						</Row>

						<Div className=" h100-calc-25">
							<Chart key={this.state.key} chartLanguage="pt-br" chartType="LineChart" width="100%" height={"100%"} data={dataChartTempo}
									options={{
										title: "Tempo x Dia",
										"legend": {
											"position": "bottom"
										},
										
										"chartArea": {
											"width": "chartwidth",
											"left": 100,
											"top": 50,
											"right": 20,
											"bottom" : 70
										},
										"curveType": "function",
										"pointSize": 6,
										vAxis: {
											minValue: 0,
											viewWindow: {
												min: 0
											}
										},
										}
									}
									formatters={[]}
								/>
							</Div>
					</Tab>
				</TabNavigator>
			</Div>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <ListVeiculoResumo {...props} navigate={navigate} params={params} location={location}/>
}
export default With