import Http from "../controllers/Http";

export default class UsuarioGrupoTipoPrecoService {

	static urlBase = "/usuarioGrupoTipoPreco/";

	static getForCombo(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(UsuarioGrupoTipoPrecoService.urlBase + 'forCombo', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static list(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(UsuarioGrupoTipoPrecoService.urlBase + 'list', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneById(id, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.get(UsuarioGrupoTipoPrecoService.urlBase + id)
			.then(fnSucess)
			.catch(fnError);
	}

	static create(data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(UsuarioGrupoTipoPrecoService.urlBase, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static update(id, data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.put(UsuarioGrupoTipoPrecoService.urlBase + id, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static delete(id, fnSucess = () => { }, fnError = () => { }) {
		if(Number.isInteger(id)){
			Http.getInstanceAppJson()
				.delete(UsuarioGrupoTipoPrecoService.urlBase + id)
				.then(fnSucess)
				.catch(fnError);
		}else{
			Http.getInstanceAppJson()
			.post(UsuarioGrupoTipoPrecoService.urlBase + 'delete', id)
			.then(fnSucess)
			.catch(fnError);
		}
	}

	static save(data, fnSucess = () => { }, fnError = () => { }) {
		if (data.idUsuarioGrupoTipoPreco) {
			this.update(data.idUsuarioGrupoTipoPreco, data, fnSucess, fnError);
		} else {
			this.create(data, fnSucess, fnError);
		}
	}

	static getAllByUsuarioGrupo(usuarioGrupo, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(UsuarioGrupoTipoPrecoService.urlBase + 'usuarioGrupo', usuarioGrupo)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByUsuarioGrupo(usuarioGrupo, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(UsuarioGrupoTipoPrecoService.urlBase + 'usuarioGrupo/' + usuarioGrupo.idUsuarioGrupo)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByTipoPreco(tipoPreco, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(UsuarioGrupoTipoPrecoService.urlBase + 'tipoPreco', tipoPreco)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByTipoPreco(tipoPreco, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(UsuarioGrupoTipoPrecoService.urlBase + 'tipoPreco/' + tipoPreco.idTipoPreco)
			.then(fnSucess)
			.catch(fnError);
	}
}