import UsuarioEquipeService from "../../services/UsuarioEquipeService";
import FormUsuarioEquipe from "./FormUsuarioEquipe";
import ListUsuarioEquipe from "./ListUsuarioEquipe";
import Permissao from "../../controllers/Permissao";

export default class Config {

	static getUrlForm() {
		return "/CadUsuarioEquipe";
	}

	static getUrlList() {
		return "/LstUsuarioEquipe";
	}

	static getComponentForm() {
		return FormUsuarioEquipe;
	}

	static getPermissionForm() {
		return Permissao.getInstance().getPermissoes([8]);
	}

	static getPermissionList() {
		return Permissao.getInstance().getPermissoes([8]);
	}

	static getComponentList() {
		return ListUsuarioEquipe;
	}

	static getService() {
		return UsuarioEquipeService;
	}

	static getKeyId() {
		return "idUsuarioEquipe";
	}

	static getKeyDescription() {
		return "nmUsuarioEquipe";
	}
}