//#NO-CHANGE-FILE#

import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import SelectTipoPagina from "../../components/SelectDB/SelectTipoPagina";
import { BaseCrud } from "../../siht/base";
import { FormButtons, FormItem, Row, Panel, PanelBody, PanelFooter, Container, ModalDialog } from "../../siht/components";
import { InputText, Form, InputUrl } from "../../siht/elements";
import Config from "./Config";

class FormPessoaPagina extends BaseCrud {

	constructor(props) {
		super(props);
		this.init = this.init.bind(this);
		this.handleSuccess = this.handleSuccess.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleChangeModel = this.handleChangeModel.bind(this);
		this.handleSubmitForm = this.handleSubmitForm.bind(this);

		this.state.model = {
			idPessoaPagina : null,
			pessoa : this.props.parent !== undefined ? this.props.parent : {},
			tipoPagina : null,
			dsPagina : ""
		};
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		super.init(Config);
	}

	handleSuccess(e) {
		super.handleSuccess(e, Config);
	}

	handleClose(e) {
		super.handleClose(e, Config);
	}

	render() {
		return super.render (
			<Form onSubmit={this.handleSuccess} refParam={f => (this.form = f)} key={this.state.key}>
				<Panel key={this.state.key} title="Cadastro de PessoaPagina" onClose={this.handleClose} isModal={this.isModal()} container={{sizing: Container.sizing.xxl, className : ""}} modal={{sizing: this.props.sizing ? this.props.sizing : ModalDialog.sizing.xl}}>
					<PanelBody>
						<Row className="g-3 pb-2 px-2">
							<FormItem md={6} label="Código">
								<InputText name="idPessoaPagina" value={this.state.model.idPessoaPagina} readOnly={true} className="readonly-field" required={false} onChange={this.handleChangeModel}/>
							</FormItem>
							<FormItem md={12} label="Tipo da Página">
								<SelectTipoPagina name="tipoPagina" value={this.state.model.tipoPagina} required={true} onChange={this.handleChangeModel} placeholder="Selecione o Tipo de Pagina"/>
							</FormItem>
							<FormItem md={12} label="URL">
								<InputUrl name="dsPagina" value={this.state.model.dsPagina} maxLength={250} required={true} onChange={this.handleChangeModel}/>
							</FormItem>
						</Row>
					</PanelBody>
					<PanelFooter>
						<FormButtons onClose={this.handleClose} />
					</PanelFooter>
				</Panel>
			</Form>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <FormPessoaPagina {...props} navigate={navigate} params={params} location={location}/>
}
export default With