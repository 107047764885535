import Http from "../controllers/Http";

export default class RecorrenciaService {

	static urlBase = "/recorrencia/";

	static getForCombo(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(RecorrenciaService.urlBase + 'forCombo', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static list(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(RecorrenciaService.urlBase + 'list', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneById(id, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.get(RecorrenciaService.urlBase + id)
			.then(fnSucess)
			.catch(fnError);
	}

	static create(data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(RecorrenciaService.urlBase, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static update(id, data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.put(RecorrenciaService.urlBase + id, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static delete(id, fnSucess = () => { }, fnError = () => { }) {
		if(Number.isInteger(id)){
			Http.getInstanceAppJson()
				.delete(RecorrenciaService.urlBase + id)
				.then(fnSucess)
				.catch(fnError);
		}else{
			Http.getInstanceAppJson()
			.post(RecorrenciaService.urlBase + 'delete', id)
			.then(fnSucess)
			.catch(fnError);
		}
	}

	static save(data, fnSucess = () => { }, fnError = () => { }) {
		if (data.idRecorrencia) {
			this.update(data.idRecorrencia, data, fnSucess, fnError);
		} else {
			this.create(data, fnSucess, fnError);
		}
	}

	static getAllByTipoRecorrencia(tipoRecorrencia, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(RecorrenciaService.urlBase + 'tipoRecorrencia', tipoRecorrencia)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByTipoRecorrencia(tipoRecorrencia, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(RecorrenciaService.urlBase + 'tipoRecorrencia/' + tipoRecorrencia.idTipoRecorrencia)
			.then(fnSucess)
			.catch(fnError);
	}
}