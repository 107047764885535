import { Badge } from "../../siht/components";
import { BaseComponent } from "../../siht/base";

export default class BadgeSimNao extends BaseComponent {

    getValue(value){
        switch(value?.toString().toLowerCase()?.trim()){
            case "true": 
            case "yes": 
            case "1":
                return true;
    
            case "false":
            case "no": 
            case "0": 
            case null: 
            case undefined:
                return false;
    
            default: 
                return JSON.parse(value);
        }
    }
    
    render() {

        if(this.props.show !== undefined && !Boolean(this.props.show)){
            return (<></>);
        }

        const props = Object.assign({}, this.props);
        delete props.show;
        props.value = this.getValue(props.value);        

        if(props.value){
            return <Badge color={Badge.color.success}> Sim </Badge>
        }else{
            return <Badge color={Badge.color.danger}> Não </Badge>
        }
    }
}