//#NO-CHANGE-FILE#

import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { BaseCrud } from "../../siht/base";
import { Panel, PanelBody, PanelFooter, Container, ModalDialog } from "../../siht/components";
import { BtnButton, Button, Div, P, Span } from "../../siht/elements";
import ConfigApp from "../../ConfigApp";

class FormSobre extends BaseCrud {

	constructor(props) {
		super(props);
		this.init = this.init.bind(this);
		this.handleSuccess = this.handleSuccess.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleChangeModel = this.handleChangeModel.bind(this);
		this.handleSubmitForm = this.handleSubmitForm.bind(this);

		this.state.model = {
		};
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
	
	}

	handleSuccess(e) {
		super.handleSuccess(e, {});
	}

	handleClose(e) {
		super.handleClose(e, {});
	}

	render() {
		return super.render(
			<Panel key={this.state.key} title="Sobre o Sistema" onClose={this.handleClose} isModal={this.isModal()} container={{sizing: Container.sizing.xxl, className : ""}} modal={{sizing: this.props.sizing ? this.props.sizing : ModalDialog.sizing.xl}}>
				<PanelBody className="p-2 m-2">
					<Div>
                        <P> SMG - Sistema de Gestão de Serviços e Produtos </P>
                        <P> Desenvolvimento por <strong>DEGITALE</strong>  </P>
                        <P> Versão: <Span className="badge rounded-pill bg-primary my-3">{parseFloat(ConfigApp.version).toFixed(2)}</Span>  </P>
                    </Div>
				</PanelBody>
				<PanelFooter>
                    <BtnButton sizing={Button.sizing.default} color={Button.color.outlineSecondary} onClick={this.handleClose}> Fechar </BtnButton>
				</PanelFooter>
			</Panel>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <FormSobre {...props} navigate={navigate} params={params} location={location}/>
}
export default With