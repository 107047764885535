
import FormEstado from "./FormEstado.js";
import ListEstado from "./ListEstado.js";
import { Permissao } from "../../controllers";
import { Util } from "../../utils";
import { DadoValorService, EstadoService } from "../../services";

export default class Config {

	static getUrlForm() {
		return "/CadEstado";
	}

	static getUrlList() {
		return "/LstEstado";
	}

	static getComponentForm() {
		return FormEstado;
	}

	static getPermissionForm() {
		return Permissao.getInstance().getPermissoes([27]);
	}

	static getPermissionList() {
		return Permissao.getInstance().getPermissoes([27]);
	}

	static getComponentList() {
		return ListEstado;
	}

	static getService() {
		return EstadoService;
	}

	static getKeyId() {
		return "idEstado";
	}

	static getKeyDescription() {
		return "nmEstado";
	}

	static setDefaultValues(state , fnCallBack = () => {}, className = undefined) {
		className = className === undefined ? "FormEstado" : className;
		let keyNameScreen = Util.toToUpperTextCamelCase(className, "_") + ".";
		var prefix = "CONFIGURACAO.";
		DadoValorService.getValues(`${prefix}${keyNameScreen}`, (response)=> {
			Util.setValueObjectCamelCase(state.model, response, keyNameScreen, prefix);
			fnCallBack();
		}, fnCallBack);
	}
}