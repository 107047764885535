import Http from "../controllers/Http";

export default class ContaReceberService {

	static urlBase = "/contaReceber/";

	static getForCombo(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(ContaReceberService.urlBase + 'forCombo', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static list(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(ContaReceberService.urlBase + 'list', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneById(id, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.get(ContaReceberService.urlBase + id)
			.then(fnSucess)
			.catch(fnError);
	}

	static create(data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(ContaReceberService.urlBase, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static update(id, data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.put(ContaReceberService.urlBase + id, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static delete(id, fnSucess = () => { }, fnError = () => { }) {
		if(Number.isInteger(id)){
			Http.getInstanceAppJson()
				.delete(ContaReceberService.urlBase + id)
				.then(fnSucess)
				.catch(fnError);
		}else{
			Http.getInstanceAppJson()
			.post(ContaReceberService.urlBase + 'delete', id)
			.then(fnSucess)
			.catch(fnError);
		}
	}

	static save(data, fnSucess = () => { }, fnError = () => { }) {
		if (data.idContaReceber) {
			this.update(data.idContaReceber, data, fnSucess, fnError);
		} else {
			this.create(data, fnSucess, fnError);
		}
	}

	static getAllByVwContaReceber(vwContaReceber, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(ContaReceberService.urlBase + 'vwContaReceber', vwContaReceber)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByVwContaReceber(vwContaReceber, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(ContaReceberService.urlBase + 'vwContaReceber/' + vwContaReceber.idVwContaReceber)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByTipoContaReceber(tipoContaReceber, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(ContaReceberService.urlBase + 'tipoContaReceber', tipoContaReceber)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByTipoContaReceber(tipoContaReceber, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(ContaReceberService.urlBase + 'tipoContaReceber/' + tipoContaReceber.idTipoContaReceber)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByRecorrencia(recorrencia, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(ContaReceberService.urlBase + 'recorrencia', recorrencia)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByRecorrencia(recorrencia, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(ContaReceberService.urlBase + 'recorrencia/' + recorrencia.idRecorrencia)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByFatura(fatura, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(ContaReceberService.urlBase + 'fatura', fatura)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByFatura(fatura, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(ContaReceberService.urlBase + 'fatura/' + fatura.idFatura)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByPessoa(pessoa, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(ContaReceberService.urlBase + 'pessoa', pessoa)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByPessoa(pessoa, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(ContaReceberService.urlBase + 'pessoa/' + pessoa.idPessoa)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByUsuario(usuario, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(ContaReceberService.urlBase + 'usuario', usuario)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByUsuario(usuario, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(ContaReceberService.urlBase + 'usuario/' + usuario.idUsuario)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByUnidade(unidade, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(ContaReceberService.urlBase + 'unidade', unidade)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByUnidade(unidade, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(ContaReceberService.urlBase + 'unidade/' + unidade.idUnidade)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByContaReceberParcela(contaReceberParcela, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(ContaReceberService.urlBase + 'contaReceberParcela', contaReceberParcela)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByContaReceberParcela(contaReceberParcela, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(ContaReceberService.urlBase + 'contaReceberParcela/' + contaReceberParcela.idContaReceberParcela)
			.then(fnSucess)
			.catch(fnError);
	}
}