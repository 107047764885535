//#NO-CHANGE-FILE#

import React from "react";
import { useNavigate, useLocation, useParams } from "react-router";
import BtnEdit from "../../components/BtnEdit";
import BtnDelete from "../../components/BtnDelete";
import BtnNew from "../../components/BtnNew";
import { BaseList } from "../../siht/base";
import { DataGrid, DataGridButtons, DataGridColumn, PanelFooter } from "../../siht/components";
import { BtnButton, Button, Div } from "../../siht/elements";
import { Modal } from "../../siht/controller";
import Config from "./Config";
import Util from "../../utils/Util";
import FormContaPagar from "../ContaPagar/FormContaPagar"
import ListContaPagarParcela from "../ContaPagarParcela/ListContaPagarParcela";
import { ListOl as IconListOl} from "react-bootstrap-icons";
import { StatusCustom } from "../../components/Custom";

class ListContaPagarVeiculo extends BaseList {

	constructor(props) {
		super(props);
		this.getItens = this.getItens.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleNew = this.handleNew.bind(this);
		this.handleEdit = this.handleEdit.bind(this);
		this.handleCopy = this.handleCopy.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.handleMore = this.handleMore.bind(this);
		this.onSubmitFilter = this.onSubmitFilter.bind(this);
		this.handleClear = this.handleClear.bind(this);
		this.handleFormSuccess = this.handleFormSuccess.bind(this);
		this.handleFormClose = this.handleFormClose.bind(this);
		this.handleParcela = this.handleParcela.bind(this);
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		super.init(Config);
	}

	getItens(more){
		if(this.props?.parent){
			super.getItens(Config.getService().list, { contaPagarVeiculo : { veiculo : this.props.parent } }, more);
		}
	}

	handleNew(e) {
		Modal.openWindow(FormContaPagar, {sizing : Modal.sizing.xl}, { contaPagarVeiculo : { veiculo : this.props.parent } }, (e, response) => {
			this.getItens();
		});
	}

	handleEdit(e, item) {
		super.handleEdit(e, Config, item);
	}

	handleDelete(e, item) {
		super.handleDelete(e, Config, item);
	}

	handleCopy(e, item) {
		super.handleCopy(e, Config, item);
	}

	handleParcela(e, item) {
		Modal.open(ListContaPagarParcela, {sizing : Modal.sizing.xl}, { parent : item, title : "Parcelas da Conta"}, (e, response) => { 
			this.getItens();
		}, () => {
			this.getItens();
		});
	}

	render() {
		return super.render(
			<Div className="p-2 mt-2">
				<Div className="d-flex justify-content-end flex-nowrap gap-2">
					<BtnNew onClick={this.handleNew}/>
					<BtnDelete disabled={this.state.selecteds.length === 0} sizing={Button.sizing.default} onClick={e => this.handleDelete(e, this.state.selecteds)}> Excluir </BtnDelete>
				</Div>

				<DataGrid onDoubleClickRow={this.handleEdit} responsive={true} data={this.state.data} search={false} isDisabled={i => false} sizing={DataGrid.sizing.sm} select={true} selecteds={this.state.selecteds} onSelect={(selecteds)=>{this.setState({selecteds : selecteds})}} getItemValue={i=>i.idContaPagar} onMore={this.handleMore}>
				<DataGridColumn label="Código" field={i => i.idContaPagar} className="text-center"/>
					<DataGridColumn col={3} label="Unidade" field={i => i.unidade?.nmUnidade} className="text-start"/>
					<DataGridColumn col={3} label="Pessoa" field={i => i.pessoa?.nmPessoa} className="text-start"/>
					<DataGridColumn col={2} label="Título" field={i => i.nmContaPagar} className="text-start"/>
					<DataGridColumn col={2} label="Tipo" field={i => <StatusCustom className="w-100" color={i.tipoContaPagar}> {i.tipoContaPagar?.nmTipoContaPagar} </StatusCustom>} className="text-center"/>					
					<DataGridColumn col={2} label="Recorrencia" field={i =>
						<StatusCustom className="w-100" color={i.recorrencia?.tipoRecorrencia}> {i.recorrencia?.tipoRecorrencia?.nmTipoRecorrencia} </StatusCustom>
						
					} className="text-start"/>
					<DataGridColumn label="Parcelas" field={i => i.recorrencia.nrParcela} className="text-center"/>
					<DataGridColumn label="Valor" field={i => Util.floatToReais(i.recorrencia.vlRecorrencia)} className="text-end"/>

					<DataGridColumn label="Status" field={i =>
						<StatusCustom className="w-100" color={i.vwContaPagar?.statusConta}> {i.vwContaPagar?.statusConta?.nmStatusConta} </StatusCustom> 
					} className="text-center"/>

					<DataGridButtons>
						<BtnButton color={BtnButton.color.outlineDark} sizing={Button.sizing.default} onClick={this.handleParcela}>
							<IconListOl/>
						</BtnButton>
						<BtnEdit sizing={Button.sizing.default} onClick={this.handleEdit}/>
						<BtnDelete sizing={Button.sizing.default} onClick={this.handleDelete}/>
					</DataGridButtons>
				</DataGrid>

				<PanelFooter className="text-center py-2" show={this.state.data && this.state.data?.length > 0}>
                    <BtnButton onClick={this.handleMore} disabled={!this.state.hasMore}> Buscar Mais Itens </BtnButton>
                </PanelFooter>
			</Div>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <ListContaPagarVeiculo {...props} navigate={navigate} params={params} location={location}/>
}
export default With