//#NO-CHANGE-FILE#

import React from "react";
import { useNavigate, useLocation, useParams } from "react-router";
import BtnEdit from "../../components/BtnEdit";
import BtnDelete from "../../components/BtnDelete";
import Config from "./Config";
import { BaseList } from "../../siht/base";
import { DataGrid, DataGridButtons, DataGridColumn } from "../../siht/components";
import { BtnButton, Button, Div } from "../../siht/elements";
import Util from "../../utils/Util";
import { Modal } from "../../siht/controller";
import FormContaPagarBaixa from "../ContaPagarBaixa/FormContaPagarBaixa"
import { Coin as IconCoin} from "react-bootstrap-icons";
import { StatusCustom } from "../../components/Custom";

class ListContaPagarParcela extends BaseList {

	constructor(props) {
		super(props);
		this.getItens = this.getItens.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleNew = this.handleNew.bind(this);
		this.handleEdit = this.handleEdit.bind(this);
		this.handleCopy = this.handleCopy.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.handleMore = this.handleMore.bind(this);
		this.onSubmitFilter = this.onSubmitFilter.bind(this);
		this.handleClear = this.handleClear.bind(this);
		this.handleFormSuccess = this.handleFormSuccess.bind(this);
		this.handleFormClose = this.handleFormClose.bind(this);
		this.handleBaixar = this.handleBaixar.bind(this);
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		super.init(Config);
	}

	getItens(more){
		if(this.props?.parent){
			super.getItens(Config.getService().getAllByContaPagar, this.props.parent, more);
		}
	}

	handleNew(e) {
		super.handleNew(e, Config);
	}

	handleEdit(e, item) {
		super.handleEdit(e, Config, item);
	}

	handleDelete(e, item) {
		super.handleDelete(e, Config, item);
	}

	handleCopy(e, item) {
		super.handleCopy(e, Config, item);
	}

	handleBaixar(e, item) {
		Modal.openWindow(FormContaPagarBaixa, {sizing : Modal.sizing.default}, { parent: item }, (e, response) => { 
			this.getItens();
		});
	}

	render() {
		let editable = this.props.editable !== undefined ? this.props.editable : true;

		return super.render(
			<Div className="p-2 mt-2">
				<Div className="d-flex justify-content-end flex-nowrap gap-2">
					<BtnDelete disabled={this.state.selecteds.length === 0 || !editable} sizing={Button.sizing.default} onClick={e => this.handleDelete(e, this.state.selecteds)}> Excluir </BtnDelete>
				</Div>

				<DataGrid caption={false} onDoubleClickRow={editable ? this.handleEdit : ()=>{}} responsive={true} data={this.state.data} search={false} isDisabled={i => false} sizing={DataGrid.sizing.sm} select={true} selecteds={this.state.selecteds} onSelect={(selecteds)=>{this.setState({selecteds : selecteds})}} getItemValue={i=>i.idContaPagarParcela} onMore={this.handleMore}>
					<DataGridColumn label="Parcela" field={i => i.nrParcela} className="text-center"/>
					<DataGridColumn col={3} label="Vencimento" field={i => Util.date2Br(i.dtVencimento)} className="text-center"/>
					<DataGridColumn col={3} label="Valor" field={i => Util.floatToReais(i.vlConta)} className="text-end"/>
					<DataGridColumn col={3} label="Aberto" field={i => Util.floatToReais(i.vlAberto)} className="text-end"/>
					<DataGridColumn col={3} label="Status" field={i => <StatusCustom className="w-100" color={i.vwContaPagarParcela.statusConta}> {i.vwContaPagarParcela.statusConta.nmStatusConta} </StatusCustom>} className="text-center"/>
					<DataGridButtons>
						<BtnEdit disabled={i => !i.contaPagar.flPodeEditar || !editable} sizing={Button.sizing.default} onClick={this.handleEdit} />
						<BtnDelete disabled={i => !i.contaPagar.flPodeExcluir || !editable} sizing={Button.sizing.default} onClick={this.handleDelete}/>
						<BtnButton disabled={i => !i.contaPagar.flPodeBaixar} color={BtnButton.color.outlineSuccess} sizing={Button.sizing.default} onClick={this.handleBaixar}>
							<IconCoin/>
						</BtnButton>
					</DataGridButtons>
				</DataGrid>
			</Div>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <ListContaPagarParcela {...props} navigate={navigate} params={params} location={location}/>
}
export default With