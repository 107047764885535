
//#NO-CHANGE-FILE#

import Config from "./Config";
import React from "react";
import { useNavigate, useLocation, useParams } from "react-router";
import ManyToManyCheckUsuarioUnidadeByUnidade from "./ManyToManyCheckUsuarioUnidadeByUnidade";
import ManyToManyCheckUsuarioGrupoByGrupo from "./ManyToManyCheckUsuarioGrupoByGrupo";
import { BadgeSimNao, BtnCopy, BtnDelete, BtnEdit, BtnNew } from "../../components/Custom";
import {Circle as IconCircle, CircleFill as IconCircleFill, PersonFill as IconPersonFill, ThreeDotsVertical as IconThreeDotsVertical} from "react-bootstrap-icons"
import { BaseList } from "../../siht/base";
import { DataGrid, DataGridButtons, DataGridColumn, Panel, Container, DropDown, DropDownLabel, DropDownItem, DropDownDivider, Row, InputGroup, InputGroupText, InputGroupButton, PanelFooter } from "../../siht/components";
import { Button, Div, Form, InputSearch, BtnSubmit, BtnButton } from "../../siht/elements";
import { Modal } from "../../siht/controller";
import ConfigApp from "../../ConfigApp";
import Util from "../../utils/Util";
import { IconClear, IconCopy, IconDelete, IconEdit, IconNew, IconSearch, IconToogles } from "../../icons";

class ListUsuario extends BaseList {

	constructor(props) {
		super(props);
		this.getItens = this.getItens.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleNew = this.handleNew.bind(this);
		this.handleEdit = this.handleEdit.bind(this);
		this.handleCopy = this.handleCopy.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.handleMore = this.handleMore.bind(this);
		this.onSubmitFilter = this.onSubmitFilter.bind(this);
		this.handleClear = this.handleClear.bind(this);
		this.handleFormSuccess = this.handleFormSuccess.bind(this);
		this.handleFormClose = this.handleFormClose.bind(this);
		this.handleEditUsuarioUnidade = this.handleEditUsuarioUnidade.bind(this);
		this.handleEditUsuarioGrupo = this.handleEditUsuarioGrupo.bind(this);
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		super.init(Config);
	}

	getItens(more){
		super.getItens(Config.getService().list, false, more);
	}

	handleNew(e) {
		super.handleNew(e, Config);
	}

	handleEdit(e, item) {
		super.handleEdit(e, Config, item);
	}

	handleDelete(e, item) {
		super.handleDelete(e, Config, item);
	}

	handleCopy(e, item) {
		super.handleCopy(e, Config, item);
	}

	getFormEmbedded(){
		return super.getFormEmbedded(Config);
	}

	handleEditUsuarioUnidade(e, item){
		Modal.open(ManyToManyCheckUsuarioUnidadeByUnidade, {sizing : Modal.sizing.lg}, {title : "Unidades", search: true, parent : (item ? item : this.state.selecteds)}, () => this.getItens());
	}

	handleEditUsuarioGrupo(e, item){
		Modal.open(ManyToManyCheckUsuarioGrupoByGrupo, {sizing : Modal.sizing.lg}, {title : "Grupos", search: true, parent : (item ? item : this.state.selecteds)}, () => this.getItens());
	}

	render() {
		return super.render(
			<Panel title="Lista de Usuário(s)" isModal={this.isModal()} container={{sizing: Container.sizing.fluid, className : ""}}>
				<Div className="d-flex justify-content-end flex-nowrap gap-2">
					<BtnNew onClick={this.handleNew}/>
					<BtnDelete disabled={this.state.selecteds.length === 0} sizing={Button.sizing.default} onClick={e => this.handleDelete(e, this.state.selecteds)}> Excluir </BtnDelete>
					<DropDown alignment={DropDown.alignment.end}>
						<DropDownLabel> Mais Opções </DropDownLabel>
						<DropDownItem title="Novo Registro" type="button" onClick={this.handleNew}> <IconNew/> Novo </DropDownItem>
						<DropDownItem title="Excluir Registro(s) Selecionado(s)" type="button" disabled={this.state.selecteds.length === 0} onClick={e => this.handleDelete(e, this.state.selecteds)}> <IconDelete/> Excluir </DropDownItem>
						<DropDownDivider/>
						<DropDownItem disabled={this.state.selecteds.length === 0} type="button" onClick={this.handleEditUsuarioUnidade}> <IconToogles/> Unidades </DropDownItem>
						<DropDownItem disabled={this.state.selecteds.length === 0} type="button" onClick={this.handleEditUsuarioGrupo}> <IconToogles/> Grupos </DropDownItem>
					</DropDown>
				</Div>

				{this.getFormEmbedded()}

				<Form onSubmit={this.onSubmitFilter}>
					<Row className="mt-1 mb-2 g-3">
						<InputGroup className="flex-nowrap" sizing={InputGroup.sizing.default}>
							<InputGroupText><IconSearch/></InputGroupText>
							<InputSearch autoComplete="off" autoFocus placeholder="Digite sua busca" name="search" value={this.state.filter.search} required={false} opcional={true} onChange={(e,v,n) => this.handleChange(e,v,n, this.state.filter)}/>
							<InputGroupButton>
								<BtnSubmit title="Filtrar"> Buscar </BtnSubmit>
								<BtnButton title="Limpar Pesquisa" color={BtnButton.color.outlineDanger} onClick={this.handleClear}> <IconClear/> </BtnButton>
							</InputGroupButton>
						</InputGroup>
					</Row>
				</Form>

				<DataGrid responsive={true} data={this.state.data} search={false} isDisabled={i => false} sizing={DataGrid.sizing.sm} select={true} selecteds={this.state.selecteds} onSelect={(selecteds)=>{this.setState({selecteds : selecteds})}} getItemValue={i=>i.idUsuario} onMore={this.handleMore}>					
					<DataGridColumn label="#" field={i => <>
						{i?.arquivo?.cdUid && i?.arquivo?.arquivoBase?.cdHash ?
							<img className="bd-placeholder-img flex-shrink-0 me-2 rounded img-fluid" style={{maxWidth : "64px", maxHeight : "48px"}} src={ConfigApp.getUrlArquivo() + "public/inline/" + i.arquivo.cdUid + "/" + i.arquivo.arquivoBase.cdHash} alt="..." />
						: <IconPersonFill width={48} height={48}/>}
					</>} className="text-center"/>
					<DataGridColumn label="Código" field={i => i.idUsuario} className="text-center"/>
					<DataGridColumn col={4} label="Nome" field={i => i.nmUsuario} className="text-start"/>
					<DataGridColumn col={3} label="E-mail" field={i => i.dsEmail} className="text-start"/>
					<DataGridColumn col={2} label="Ult.Acesso" field={i => Util.date2Br(i.dhUltimoAcesso, true)} className="text-center"/>
					<DataGridColumn col={2} label="Ult.Valid." field={i => Util.date2Br(i.dhUltimaValidacao, true)} className="text-center"/>
					<DataGridColumn col={1} label="Ativo?" field={i => <BadgeSimNao value={i.flAtivo}/>} className="text-center"/>
					<DataGridColumn col={1} label="Online?" field={i => i.flOnline ? <IconCircleFill width={20} height={20} className="text-success"/> : <IconCircle width={20} height={20} className="text-secondary"/>} className="text-center"/>
					<DataGridButtons>
						<BtnEdit sizing={Button.sizing.sm} onClick={this.handleEdit}/>
						<BtnDelete sizing={Button.sizing.sm} onClick={this.handleDelete}/>
						<BtnCopy sizing={Button.sizing.sm} onClick={this.handleCopy}/>
						<DropDown alignment={DropDown.alignment.end}>
							<DropDownLabel sizing={Button.sizing.sm} toggle={false}> <IconThreeDotsVertical/> </DropDownLabel>
							<DropDownItem title="Editar Registro" type="button" onClick={this.handleEdit}> <IconEdit/> Editar </DropDownItem>
							<DropDownItem title="Excluir Registro" type="button" onClick={this.handleDelete}> <IconDelete/> Excluir </DropDownItem>
							<DropDownItem title="Copiar Registro" type="button" onClick={this.handleCopy}> <IconCopy/> Copiar </DropDownItem>
							<DropDownDivider/>
							<DropDownItem type="button" onClick={this.handleEditUsuarioUnidade}> <IconToogles/> Unidades </DropDownItem>
							<DropDownItem type="button" onClick={this.handleEditUsuarioGrupo}> <IconToogles/> Grupos </DropDownItem>
						</DropDown>
					</DataGridButtons>
				</DataGrid>

				<PanelFooter className="text-center py-2" show={this.state.data && this.state.data?.length > 0}>
					<BtnButton onClick={this.handleMore} disabled={!this.state.hasMore}> Buscar Mais Itens </BtnButton>
				</PanelFooter>
			</Panel>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <ListUsuario {...props} navigate={navigate} params={params} location={location}/>
}
export default With