//#NO-CHANGE-FILE#
import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import Config from "./Config";
import { BaseList } from "../../siht/base";
import { DataGrid, DataGridButtons, DataGridColumn } from "../../siht/components";
import { Button, Div, Span } from "../../siht/elements";
import Util from "../../utils/Util";
import Permissao from "../../controllers/Permissao";
import BtnView from "../../components/BtnView";
import { Modal } from "../../siht/controller";
import FormOrdem from "../Ordem/FormOrdem";
import { StatusCustom } from "../../components/Custom";

class ListFaturaOrdem extends BaseList {

	constructor(props) {
		super(props);
		this.getItens = this.getItens.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleView = this.handleView.bind(this);	
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		super.init(Config);
	}

	getItens(more){
		if(this.props?.parent){
			super.getItens(Config.getService().getAllByFatura, this.props.parent, more);
		}
	}

	handleView(e, item) {
		Modal.openWindow(FormOrdem, {sizing : Modal.sizing.xl}, { id : item.ordem.idOrdem });
	}

	render() {
		return super.render(
			<Div className="p-2 mt-2">

				<DataGrid data={this.state.data} search={false} isDisabled={i => false} sizing={DataGrid.sizing.sm} select={false} selecteds={this.state.selecteds} onSelect={(selecteds)=>{this.setState({selecteds : selecteds})}} getItemValue={i=>i.ordem.ordem.idOrdem} onMore={this.handleMore}>
					<DataGridColumn label="Ordem" field={i => i.ordem.idOrdem } className="text-center"/>

					<DataGridColumn md={3} label="Cliente" field={i => <React.Fragment> 							
							<Span className="fw-bold">{i.ordem.pessoa?.nmPessoa}</Span> <br/>
							<StatusCustom color={i.ordem.pessoa?.tipoPessoa}> PESSOA {i.ordem.pessoa?.tipoPessoa?.nmTipoPessoa} </StatusCustom>
							<Div className="fw-bold fs-7" show={i.ordem.pessoa?.tipoPessoa?.cdTipoPessoa === "F"}>{Util.formataCPF(i.ordem.pessoa?.nrCpf)}</Div>
							<Div className="fw-bold fs-7" show={i.ordem.pessoa?.tipoPessoa?.cdTipoPessoa === "J"}>{Util.formataCnpj(i.ordem.pessoa?.nrCnpj)}</Div>
						</React.Fragment>}
					className="text-start"/>

					<DataGridColumn mobile={false} md={3} label="Empresa / Unidade" field={i => <React.Fragment> 							
							<Span className="fw-bold">{i.ordem.unidade?.empresa?.nmEmpresa}</Span> <br/>
							{i.ordem.unidade?.nmUnidade}							
						</React.Fragment>} className="text-start"/>


					<DataGridColumn mobile={false} md={1} label="Tipo Ordem" field={i => 
						<React.Fragment> 
							<StatusCustom color={i.ordem.tipoOrdem}> {i.ordem.tipoOrdem.nmTipoOrdem} </StatusCustom>
						</React.Fragment>
					} className="text-start"/>

					<DataGridColumn mobile={false} md={3} label="Usuário" field={i => 
						<React.Fragment> 
							{i.ordem.usuario?.nmUsuario} <br/>
							<Span className="fst-italic fs-7">{Util.date2Br(i?.dhInsert, true)}</Span>
						</React.Fragment> } className="text-start"/>

					<DataGridColumn mobile={false} md={1} label="Valor" field={i => <React.Fragment> 
							{Permissao.getInstance().getPermissoes([76]) ? <Span className="fw-bold text-success"> {Util.floatToReais(i.ordem.vlFinalOrdem)} </Span>  : <Span className="fw-bold text-danger fs-7"> OCULTO </Span>  }
					</React.Fragment>} className="text-end"/>

					<DataGridColumn md={2} label="Status" field={i => 
						<React.Fragment>							
							<StatusCustom className="w-100" color={i.ordem.statusOrdem}> {i.ordem.statusOrdem?.nmStatusOrdem} </StatusCustom>
						</React.Fragment>
					} className="text-center"/>			

					<DataGridButtons>
						<BtnView sizing={Button.sizing.default} onClick={this.handleView}/>
					</DataGridButtons>
				</DataGrid>
			</Div>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <ListFaturaOrdem {...props} navigate={navigate} params={params} location={location}/>
}
export default With