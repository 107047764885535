//#NO-CHANGE-FILE#

import Auth from "../controllers/Auth";
import Http from "../controllers/Http";

export default class UsuarioService {

	static urlBase = "/usuario/";

	static alterarSenha(data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(UsuarioService.urlBase + 'alterarSenha', data)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByUsuarioLogado(fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.get(UsuarioService.urlBase + 'usuarioLogado')
			.then(fnSucess)
			.catch(fnError);
	}

	static getNotificacao(fnSucess = () => { }, fnError = () => { }) {
        if (Auth.isAuthenticated()) {
            Http.getInstanceAppJson({ showLoadding: false })
                .get(this.urlBase + 'notificacao')
                .then(fnSucess)
                .catch(fnError);
        }
    }

	static marcarTodasNotificacoesComoLida(fnSucess = () => { }, fnError = () => { }) {
        if (Auth.isAuthenticated()) {
            Http.getInstanceAppJson({ showLoadding: false })
                .get(this.urlBase + 'notificacao/marcarTodasComoLida')
                .then(fnSucess)
                .catch(fnError);
        }
    }

	static getForCombo(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(UsuarioService.urlBase + 'forCombo', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static list(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(UsuarioService.urlBase + 'list', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneById(id, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.get(UsuarioService.urlBase + id)
			.then(fnSucess)
			.catch(fnError);
	}

	static create(data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(UsuarioService.urlBase, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static update(id, data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.put(UsuarioService.urlBase + id, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static delete(id, fnSucess = () => { }, fnError = () => { }) {
		if(Number.isInteger(id)){
			Http.getInstanceAppJson()
				.delete(UsuarioService.urlBase + id)
				.then(fnSucess)
				.catch(fnError);
		}else{
			Http.getInstanceAppJson()
			.post(UsuarioService.urlBase + 'delete', id)
			.then(fnSucess)
			.catch(fnError);
		}
	}

	static save(data, fnSucess = () => { }, fnError = () => { }) {
		if (data.idUsuario) {
			this.update(data.idUsuario, data, fnSucess, fnError);
		} else {
			this.create(data, fnSucess, fnError);
		}
	}

	static getAllByPessoa(pessoa, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(UsuarioService.urlBase + 'pessoa', pessoa)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByPessoa(pessoa, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(UsuarioService.urlBase + 'pessoa/' + pessoa.idPessoa)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByArquivo(arquivo, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(UsuarioService.urlBase + 'arquivo', arquivo)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByArquivo(arquivo, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(UsuarioService.urlBase + 'arquivo/' + arquivo.idArquivo)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByUsuarioUnidade(usuarioUnidade, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(UsuarioService.urlBase + 'usuarioUnidade', usuarioUnidade)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByUsuarioUnidade(usuarioUnidade, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(UsuarioService.urlBase + 'usuarioUnidade/' + usuarioUnidade.idUsuarioUnidade)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByUsuarioTipoNotificacao(usuarioTipoNotificacao, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(UsuarioService.urlBase + 'usuarioTipoNotificacao', usuarioTipoNotificacao)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByUsuarioTipoNotificacao(usuarioTipoNotificacao, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(UsuarioService.urlBase + 'usuarioTipoNotificacao/' + usuarioTipoNotificacao.idUsuarioTipoNotificacao)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByUsuarioGrupo(usuarioGrupo, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(UsuarioService.urlBase + 'usuarioGrupo', usuarioGrupo)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByUsuarioGrupo(usuarioGrupo, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(UsuarioService.urlBase + 'usuarioGrupo/' + usuarioGrupo.idUsuarioGrupo)
			.then(fnSucess)
			.catch(fnError);
	}
}