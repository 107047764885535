
import FormTipoPrazo from "./FormTipoPrazo.js";
import ListTipoPrazo from "./ListTipoPrazo.js";
import { Permissao } from "../../controllers";
import { Util } from "../../utils";
import { DadoValorService, TipoPrazoService } from "../../services";

export default class Config {

	static getUrlForm() {
		return "/CadTipoPrazo";
	}

	static getUrlList() {
		return "/LstTipoPrazo";
	}

	static getComponentForm() {
		return FormTipoPrazo;
	}

	static getPermissionForm() {
		return Permissao.getInstance().getPermissoes([199]);
	}

	static getPermissionList() {
		return Permissao.getInstance().getPermissoes([199]);
	}

	static getComponentList() {
		return ListTipoPrazo;
	}

	static getService() {
		return TipoPrazoService;
	}

	static getKeyId() {
		return "idTipoPrazo";
	}

	static getKeyDescription() {
		return "nmTipoPrazo";
	}

	static setDefaultValues(state , fnCallBack = () => {}, className = undefined) {
		className = className === undefined ? "FormTipoPrazo" : className;
		let keyNameScreen = Util.toToUpperTextCamelCase(className, "_") + ".";
		var prefix = "CONFIGURACAO.";
		DadoValorService.getValues(`${prefix}${keyNameScreen}`, (response)=> {
			Util.setValueObjectCamelCase(state.model, response, keyNameScreen, prefix);
			fnCallBack();
		}, fnCallBack);
	}
}