import Http from "../controllers/Http";

export default class ContaTipoPagamentoDadoValorService {

	static urlBase = "/contaTipoPagamentoDadoValor/";

	static getForCombo(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(ContaTipoPagamentoDadoValorService.urlBase + 'forCombo', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static list(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(ContaTipoPagamentoDadoValorService.urlBase + 'list', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneById(id, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.get(ContaTipoPagamentoDadoValorService.urlBase + id)
			.then(fnSucess)
			.catch(fnError);
	}

	static create(data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(ContaTipoPagamentoDadoValorService.urlBase, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static update(id, data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.put(ContaTipoPagamentoDadoValorService.urlBase + id, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static delete(id, fnSucess = () => { }, fnError = () => { }) {
		if(Number.isInteger(id)){
			Http.getInstanceAppJson()
				.delete(ContaTipoPagamentoDadoValorService.urlBase + id)
				.then(fnSucess)
				.catch(fnError);
		}else{
			Http.getInstanceAppJson()
			.post(ContaTipoPagamentoDadoValorService.urlBase + 'delete', id)
			.then(fnSucess)
			.catch(fnError);
		}
	}

	static save(data, fnSucess = () => { }, fnError = () => { }) {
		if (data.idContaTipoPagamentoDadoValor) {
			this.update(data.idContaTipoPagamentoDadoValor, data, fnSucess, fnError);
		} else {
			this.create(data, fnSucess, fnError);
		}
	}

	static getAllByContaTipoPagamento(contaTipoPagamento, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(ContaTipoPagamentoDadoValorService.urlBase + 'contaTipoPagamento', contaTipoPagamento)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByContaTipoPagamento(contaTipoPagamento, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(ContaTipoPagamentoDadoValorService.urlBase + 'contaTipoPagamento/' + contaTipoPagamento.idContaTipoPagamento)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByDadoValor(dadoValor, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(ContaTipoPagamentoDadoValorService.urlBase + 'dadoValor', dadoValor)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByDadoValor(dadoValor, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(ContaTipoPagamentoDadoValorService.urlBase + 'dadoValor/' + dadoValor.idDadoValor)
			.then(fnSucess)
			.catch(fnError);
	}
}