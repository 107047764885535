//#NO-CHANGE-FILE#

import Http from "../controllers/Http";

export default class DadoService {

	static urlBase = "/dado/";

	static getOneByCdDado(cdDado, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.get(DadoService.urlBase + 'cdDado/' + cdDado)
			.then(fnSucess)
			.catch(fnError);
	}

	static getForCombo(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(DadoService.urlBase + 'forCombo', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static list(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(DadoService.urlBase + 'list', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneById(id, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.get(DadoService.urlBase + id)
			.then(fnSucess)
			.catch(fnError);
	}

	static create(data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(DadoService.urlBase, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static update(id, data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.put(DadoService.urlBase + id, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static delete(id, fnSucess = () => { }, fnError = () => { }) {
		if(Number.isInteger(id)){
			Http.getInstanceAppJson()
				.delete(DadoService.urlBase + id)
				.then(fnSucess)
				.catch(fnError);
		}else{
			Http.getInstanceAppJson()
			.post(DadoService.urlBase + 'delete', id)
			.then(fnSucess)
			.catch(fnError);
		}
	}

	static save(data, fnSucess = () => { }, fnError = () => { }) {
		if (data.idDado) {
			this.update(data.idDado, data, fnSucess, fnError);
		} else {
			this.create(data, fnSucess, fnError);
		}
	}

	static getAllByTipoDado(tipoDado, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(DadoService.urlBase + 'tipoDado', tipoDado)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByTipoDado(tipoDado, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(DadoService.urlBase + 'tipoDado/' + tipoDado.idTipoDado)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByDadoLocal(dadoLocal, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(DadoService.urlBase + 'dadoLocal', dadoLocal)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByDadoLocal(dadoLocal, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(DadoService.urlBase + 'dadoLocal/' + dadoLocal.idDadoLocal)
			.then(fnSucess)
			.catch(fnError);
	}
}