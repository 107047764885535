//#NO-CHANGE-FILE#

import Config from "./Config";
import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import SelectTipoFiliacao from "../../components/SelectDB/SelectTipoFiliacao";
import { BaseCrud } from "../../siht/base";
import { FormButtons, FormItem, Row, Panel, PanelBody, PanelFooter, Container, ModalDialog } from "../../siht/components";
import { InputText, Form } from "../../siht/elements";
import { FormItemPessoaByCdTipoPessoa } from "../../components/FormItem";

class FormPessoaFiliacao extends BaseCrud {

	constructor(props) {
		super(props);
		this.init = this.init.bind(this);
		this.initModel = this.initModel.bind(this);
		this.handleSuccess = this.handleSuccess.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleChangeModel = this.handleChangeModel.bind(this);
		this.handleSubmitForm = this.handleSubmitForm.bind(this);
		this.handleChangePessoaFiliacao = this.handleChangePessoaFiliacao.bind(this);
		this.handleChangeNmPessoaFiliacao = this.handleChangeNmPessoaFiliacao.bind(this);
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init() {
		super.init(Config);
	}

	initModel() {
		return {
			idPessoaFiliacao: null,
			pessoa: this.props.parent !== undefined ? this.props.parent : {},
			tipoFiliacao: null,
			filiacao: null,
			nmPessoaFiliacao: ""
		}
	}

	handleSuccess(e) {
		super.handleSuccess(e, Config);
	}

	handleClose(e) {
		super.handleClose(e, Config);
	}

	handleChangePessoaFiliacao(e) {
		this.setState({ model: { ...this.state.model, nmPessoaFiliacao: "" } });
	}

	handleChangeNmPessoaFiliacao(e) {
		this.setState({ model: { ...this.state.model, filiacao: null } });
	}

	render() {
		return super.render(

			<Form onSubmit={this.handleSuccess} refParam={f => (this.form = f)} key={this.state.key}>
				<Panel key={this.state.key} title={`Cadastro de Filiação ${this.state.model?.tipoFiliacao ? this.state.model?.tipoFiliacao?.nmTipoFiliacao : ""}`} localOpen={this.props.localOpen} onClose={this.handleClose} isModal={this.isModal()} container={{ sizing: Container.sizing.xxl, className: "" }} modal={{ sizing: this.props.sizing ? this.props.sizing : ModalDialog.sizing.xl }}>
					<PanelBody>
						<Row className="g-3 pb-2 px-2">
							<FormItem md={12} label="Tipo de Filiação">
								<SelectTipoFiliacao name="tipoFiliacao" value={this.state.model.tipoFiliacao} required={true} onChange={this.handleChangeModel} placeholder="Selecione Tipo de Filiação" />
							</FormItem>

							<FormItemPessoaByCdTipoPessoa help="Selecione uma pessoa ou digite o nome abaixo!" show={this.state.model.tipoFiliacao !== null && (this.state.model.nmPessoaFiliacao === null || this.state.model.nmPessoaFiliacao?.toString().length === 0)} disabled={this.state.model.idPessoaFiliacao !== null} cdTipoPessoa="F" label={`Nome ${this.state.model?.tipoFiliacao?.nmTipoFiliacao}`} md={12} name="filiacao" value={this.state.model.filiacao} required={true} onChange={(e, v, p) => this.handleChangeModel(e, v, p, this.handleChangePessoaFiliacao)} />

							<FormItem help="Digite o nome da filiação!" show={this.state.model.filiacao?.idPessoa === undefined && this.state.model.tipoFiliacao !== null} md={12} label={`Nome ${this.state.model?.tipoFiliacao?.nmTipoFiliacao}`}>
								<InputText name="nmPessoaFiliacao" value={this.state.model.nmPessoaFiliacao} autoFocus={true} maxLength={100} required={true} onChange={(e, v, p) => this.handleChangeModel(e, v, p, this.handleChangeNmPessoaFiliacao)} />
							</FormItem>
						</Row>
					</PanelBody>
					<PanelFooter>
						<FormButtons onClose={this.handleClose} />
					</PanelFooter>
				</Panel>
			</Form>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <FormPessoaFiliacao {...props} navigate={navigate} params={params} location={location} />
}
export default With