import React from "react";
import { Caption, Div, Form, InputCheckBox, InputSearch, TBody, TFoot, Table, TableResponsive, Td, Th, Tr } from "../elements";
import THead from "../elements/THead";
import DataGridColumn from "./DataGridColumn";
import DataGridButtons from "./DataGridButtons";
import { BaseComponent } from "../base";
import InputGroup from "./InputGroup";
import InputGroupText from "./InputGroupText";
import Row from "./Row";
import { Functions } from "../util";
import DataGridFooter from "./DataGridFooter";

export default class DataGrid extends BaseComponent {

    static sizing = {
        sm: "sm",
        default: "",
    }

    constructor(props) {
        super(props);

        this.handleClickCheckItem = this.handleClickCheckItem.bind(this);
        this.handleClickCheckAll = this.handleClickCheckAll.bind(this);
        this.handleClickRow = this.handleClickRow.bind(this);
        this.handleClickMore = this.handleClickMore.bind(this);

        this.state = {
            filter: {
                search: ""
            },
        };
    }

    handleClickRow(e, item, index) {
        var select = this.props.select !== undefined && this.props.select ? true : false;
        if (select) {
            let checked = this.props.selecteds.findIndex(i => (this.getItemValue(i)).toString() === (this.getItemValue(item)).toString()) === -1;
            this.handleClickCheckItem(e, checked, item);
        }
    }

    handleClickCheckItem(e, checked, item) {

        var selecteds = this.props.selecteds.map(a => Object.assign({}, a));

        this.setState(state => {
            if (checked) {
                selecteds.push(item);
            } else {
                let index = selecteds.findIndex(i => (this.getItemValue(i)).toString() === (this.getItemValue(item)).toString());
                selecteds.splice(index, 1);
            }
            return state;
        }, () => {
            if (this.props.onSelect !== undefined) {
                this.props.onSelect(selecteds);
            }
        });
    }

    handleClickCheckAll(e, checked) {
        var selecteds = this.props.selecteds.map(a => Object.assign({}, a));

        this.setState(state => {
            if (checked) {
                this.getData().filter(item => !this.isDisabled(item)).forEach(item => {
                    if (selecteds && selecteds.findIndex(i => (this.getItemValue(i)).toString() === (this.getItemValue(item)).toString()) === -1) {
                        selecteds.push(item);
                    }
                }, this);

            } else {
                this.getData().forEach(item => {
                    if (selecteds && selecteds.findIndex(i => (this.getItemValue(i)).toString() === (this.getItemValue(item)).toString()) !== -1) {
                        let index = selecteds.findIndex(i => (this.getItemValue(i)).toString() === (this.getItemValue(item)).toString());
                        selecteds.splice(index, 1);
                    }
                }, this);
            }

            return state;
        }, () => {
            if (this.props.onSelect !== undefined) {
                this.props.onSelect(selecteds);
            }
        });
    }

    handleClickMore() {
        if (this.props.onMore !== undefined) {
            this.props.onMore();
        }
    }

    getData() {
        if (this.state.filter !== undefined && this.state.filter.search !== undefined && this.state.filter.search.trim().length > 0 && this.props.data?.length > 0) {
            return this.props.data.filter(f =>
                Functions.searchTextInObject(f, Object.keys(this.props.data[0]), this.state.filter.search.trim())
            );
        } else {
            return this.props.data;
        }
    }

    getColSize(props) {
        var colValue = false;
        var colSize = false;

        if (props.col) {
            colValue = "";
            colSize = props.col;
        } else if (props.xs) {
            colValue = props.xs;
            colSize = "xs";
        } else if (props.sm) {
            colValue = props.sm;
            colSize = "sm";
        } else if (props.md) {
            colValue = props.md;
            colSize = "md";
        } else if (props.lg) {
            colValue = props.lg;
            colSize = "lg";
        } else if (props.xl) {
            colValue = props.xl;
            colSize = "xl";
        } else if (props.xxl) {
            colValue = props.xxl;
            colSize = "xxl";
        }

        if (colSize || colValue) {
            return "col" + (colSize ? "-" + colSize : "") + (colValue > 0 ? "-" + colValue : "");
        }

        return "";
    }

    getItemValue(item) {
        if (this.props.getItemValue !== undefined) {
            return this.props.getItemValue(item)
        } else {
            if (this.props.data.length > 0) {
                let keys = Object.keys(this.props.data[0]);
                if (keys.length > 0 && item[keys[0]] !== undefined) {
                    return item[keys[0]]
                }
            }
        }
        return false;
    }

    isAllCheckeds(data, selecteds) {
        data = data?.filter(i => !this.isDisabled(i));

        if (data.length === 0) {
            return false;
        }

        return data.filter(i => !selecteds?.find(x => (this.getItemValue(i))?.toString() === (this.getItemValue(x))?.toString())).length === 0;
    }

    isDisabled(item) {
        if (this.props.isDisabled !== undefined) {
            return this.props.isDisabled(item)
        }
        return false;
    }

    getButtons(children, item, index) {
        return React.Children.map(children, (child) => {
            if (!React.isValidElement(child)) return child;

            let obj = {
                ...child.props,
                onClick: e => child.props.onClick !== undefined ? child.props.onClick(e, item) : () => { },
                item: item,
                children: this.getButtons(child.props.children, item)
            };

            if (child.props.disabled instanceof Function) {
                obj.disabled = child.props.disabled(item, index);
            }

            if (child.props.show instanceof Function) {
                obj.show = child.props.show(item, index);
            }

            if (child.props.to instanceof Function) {
                obj.to = child.props.to(item, index);
            }

            if (child.props.href instanceof Function) {
                obj.href = child.props.href(item, index);
            }

            if (child.props.icon instanceof Function) {
                obj.icon = child.props.icon(item, index);
            }

            return React.cloneElement(child, obj);
        })
    };

    render() {
        if (this.props.show !== undefined && !Boolean(this.props.show)) {
            return (<></>);
        }

        const props = Object.assign({}, this.props);
        props.className = `${props.className !== undefined ? props.className : ""}`;

        delete props.show;

        var footer = React.Children.toArray(this.props.children).filter(child => child.type === DataGridFooter);
        var columns = React.Children.toArray(this.props.children).filter(child => child.type === DataGridColumn);
        var buttons = React.Children.toArray(this.props.children).find(child => child.type === DataGridButtons)?.props?.children;
        var buttonsProps = React.Children.toArray(this.props.children).find(child => child.type === DataGridButtons)?.props;
        var select = this.props.select !== undefined && this.props.select ? true : false;
        var data = this.getData();
        var isAllCheckeds = this.isAllCheckeds(data, this.props.selecteds);
        var responsive = this.props.responsive !== undefined ? this.props.responsive : true;

        if (buttons && !Array.isArray(buttons)) {
            buttons = [buttons];
        }

        delete props.responsive;
        delete props.onDoubleClickRow;

        return (
            <Div>
                <Row className="mt-1 mb-2 g-3">
                    {this.props.search === undefined || (this.props.search !== undefined && this.props.search) ?
                        <Form autoComplete="off">
                            <InputGroup className="flex-nowrap" sizing={InputGroup.sizing.default}>
                                <InputGroupText>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                                        <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                                    </svg>
                                </InputGroupText>
                                <InputSearch autoComplete="off" autoFocus placeholder="Digite sua busca" name="search-data-grid" value={this.state.filter.search} required={false} opcional={true} onChange={(e, v, p) => this.handleChange(e, v, 'search', this.state.filter)} />
                            </InputGroup>
                        </Form>
                        : <></>
                    }
                </Row>
                <TableResponsive responsive={responsive}>
                    <Table bordered={this.props.bordered !== undefined ? this.props.bordered : false} striped={this.props.striped !== undefined ? this.props.striped : false} hover={this.props.hover !== undefined ? this.props.hover : true} caption={Table.caption.top} sizing={this.props.sizing ? this.props.sizing : ""}>
                        <Caption className="text-end me-3" show={this.props.caption !== undefined ? this.props.caption : true}>
                            {select ? `${this.props.selecteds?.length} de ` : ""} {data.length} Registro(s)
                        </Caption>
                        <THead>
                            <Tr>
                                {select ? (
                                    <Th className={`text-center align-middle ${isAllCheckeds ? "item-selected-datagrid" : "item-no-selected-datagrid"}`}>
                                        <InputCheckBox onChange={(e, checked) => this.handleClickCheckAll(e, checked)}
                                            disabled={data.filter(i => !this.isDisabled(i)).length === 0}
                                            value={isAllCheckeds}
                                            className="form-check-datagrid"
                                        />
                                    </Th>
                                ) : <></>}
                                {columns.map((child, index) => {
                                    const props = Object.assign({}, child.props);
                                    let rotate = props.rotate !== undefined && props.rotate.label !== undefined ? props.rotate : false;
                                    props.className = `${this.getColSize(child.props)} ${props.className !== undefined ? props.className : ""} ${props.mobile !== undefined && props.mobile === false ? "d-none d-md-table-cell" : ""}`;
                                    delete props.field;
                                    delete props.mobile;
                                    delete props.rotate;

                                    if (rotate) {
                                        return (
                                            <Th key={index} {...props}>
                                                <div className="rotated-th">
                                                    <span className={`rotated-th__label-${rotate.label}`}>
                                                        {child.props.label}
                                                    </span>
                                                </div>
                                            </Th>);
                                    } else {
                                        return (
                                            <Th key={index} {...props}>
                                                {child.props.label}
                                            </Th>
                                        );
                                    }
                                })}
                                {buttons?.length > 0 ? (<th className={`text-center ${this.getColSize(buttonsProps)}`}> Ações </th>) : <></>}
                            </Tr>
                        </THead>
                        <TBody>
                            {data.map((item, index) => {
                                var isChecked = select && this.props.selecteds !== undefined ? this.props.selecteds.findIndex(i => (this.getItemValue(i))?.toString() === (this.getItemValue(item))?.toString()) !== -1 : false;
                                var isDisabled = this.isDisabled(item);

                                let className = ``;

                                /*if(className instanceof Function){
                                    className = props.className(item, child , index);
                                }*/

                                className = `${className}${isChecked ? " table-warning" : ""}`;

                                return (
                                    <Tr key={index} className={className} onDoubleClick={e => this.props?.onDoubleClickRow ? this.props?.onDoubleClickRow(e, item) : () => { }}>
                                        {select ? (
                                            <Td className={`text-center align-middle ${isChecked ? "item-selected-datagrid" : "item-no-selected-datagrid"}`}>
                                                <InputCheckBox disabled={isDisabled}
                                                    onChange={(e, checked) => this.handleClickCheckItem(e, checked, item)}
                                                    value={isChecked}
                                                    className="form-check-datagrid" />
                                            </Td>
                                        ) : <></>}
                                        {columns.map((child, indexChield) => {
                                            const props = Object.assign({}, child.props);

                                            if (props.className instanceof Function) {
                                                props.className = props.className(item, child, index);
                                            }

                                            props.className = `align-middle ${props.className !== undefined ? props.className : ""} ${props.mobile !== undefined && props.mobile === false ? "d-none d-md-table-cell" : ""}`;

                                            if (!isDisabled) {
                                                props.onClick = e => this.handleClickRow(e, item, index);
                                            }

                                            delete props.field;
                                            delete props.col;
                                            delete props.mobile;

                                            let value = "";

                                            if (child.props.children) {
                                                value = child.props.children;
                                            } else if (child.props.field instanceof Function) {
                                                value = child.props.field(item, index);
                                            } else if (item[child.props.field] !== undefined) {
                                                value = item[child.props.field];
                                            }

                                            return (
                                                <Td key={indexChield} {...props}>
                                                    {value}
                                                </Td>);
                                        })}

                                        {buttons?.length > 0 ? (
                                            <Td className="align-middle">
                                                <Div className={`text-end d-flex flex-nowrap gap-2 w-100 h-100 justify-content-center`}>
                                                    {this.getButtons(buttons, item, index)}
                                                </Div>
                                            </Td>
                                        ) : <></>}
                                    </Tr>
                                );
                            })}
                        </TBody>
                        {footer.length > 0 ?
                            <TFoot>
                                {footer.map((foot, index) => {
                                    let cols = React.Children.toArray(foot.props.children).filter(child => child.type === DataGridColumn);

                                    let render = cols.map((child, indexC) => {
                                        const props = Object.assign({}, child.props);
                                        props.className = `${this.getColSize(child.props)} ${props.className !== undefined ? props.className : ""} ${props.mobile !== undefined && props.mobile === false ? "d-none d-md-table-cell" : ""}`;
                                        delete props.field;
                                        delete props.mobile;

                                        return (<Td key={indexC} {...props}> {child.props.children} </Td>);
                                    });

                                    return (<Tr key={index}>{render}</Tr>);
                                })}

                            </TFoot>
                            : <></>}
                    </Table>

                </TableResponsive>
            </Div>
        );
    }
}