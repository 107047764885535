import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BaseComponent } from "../../siht/base";
import SelectMarca from "../../components/SelectDB/SelectMarca";
import SelectModelo from "../../components/SelectDB/SelectModelo";
import { FormItem } from "../../siht/components";

class FormItemMarcaModelo extends BaseComponent {

	constructor(props) {
		super(props);
		this.init = this.init.bind(this);        
        this.handleChangeMarca = this.handleChangeMarca.bind(this);
        this.handleChangeModelo = this.handleChangeModelo.bind(this);
	}

	componentDidMount() {
		super.componentDidMount();
        this.init();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		
	}

	handleChangeMarca(e, v, p){
        var value =  { marca : v ? v : {} };
        this.props.onChange(e, value, this.props.name);
	}

	handleChangeModelo(e, v, p){
        var value =  v ? v : { marca : this.props.value?.marca };
		this.props.onChange(e, value, this.props.name);
	}

	render() {

		var propsFormItem = ["col","xs","sm","md","lg","xl","xxl"];
		var propsItem = ["required" , "buttons" , "label", "placeholder", "disabled"];

		return super.render(
            <React.Fragment>
                <FormItem label="Marca" {...(this.getProps(propsFormItem,0))}>
                    <SelectMarca {...(this.getProps(propsItem,0))} name="marca" value={this.props.value?.marca} onChange={this.handleChangeMarca} placeholder="Selecione o Marca"/>
                </FormItem>
                <FormItem label="Modelo" {...(this.getProps(propsFormItem,1))}>
                    <SelectModelo {...(this.getProps(propsItem,1))} getOptionsWhen={f => f?.marca?.idMarca !== undefined} filter={{marca : this.props.value?.marca}} name="modelo" value={this.props.value} onChange={this.handleChangeModelo} placeholder="Selecione Nome do Modelo"/>
                </FormItem>
            </React.Fragment>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	return <FormItemMarcaModelo {...props} navigate={navigate} params={params} />
}
export default With