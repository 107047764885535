
import FormContaTipoPagamento from "./FormContaTipoPagamento.js";
import ListContaTipoPagamento from "./ListContaTipoPagamento.js";
import { Permissao } from "../../controllers";
import { Util } from "../../utils";
import { DadoValorService, ContaTipoPagamentoService } from "../../services";

export default class Config {

	static getUrlForm() {
		return "/CadContaTipoPagamento";
	}

	static getUrlList() {
		return "/LstContaTipoPagamento";
	}

	static getComponentForm() {
		return FormContaTipoPagamento;
	}

	static getPermissionForm() {
		return Permissao.getInstance().getPermissoes([90]);
	}

	static getPermissionList() {
		return Permissao.getInstance().getPermissoes([90]);
	}

	static getComponentList() {
		return ListContaTipoPagamento;
	}

	static getService() {
		return ContaTipoPagamentoService;
	}

	static getKeyId() {
		return "idContaTipoPagamento";
	}

	static getKeyDescription() {
		return "nmContaTipoPagamento";
	}

	static setDefaultValues(state , fnCallBack = () => {}, className = undefined) {
		className = className === undefined ? "FormContaTipoPagamento" : className;
		let keyNameScreen = Util.toToUpperTextCamelCase(className, "_") + ".";
		var prefix = "CONFIGURACAO.";
		DadoValorService.getValues(`${prefix}${keyNameScreen}`, (response)=> {
			Util.setValueObjectCamelCase(state.model, response, keyNameScreen, prefix);
			fnCallBack();
		}, fnCallBack);
	}
}