import Http from "../controllers/Http";

export default class GrupoTipoPrecoService {

	static urlBase = "/grupoTipoPreco/";

	static getForCombo(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(GrupoTipoPrecoService.urlBase + 'forCombo', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static list(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(GrupoTipoPrecoService.urlBase + 'list', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneById(id, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.get(GrupoTipoPrecoService.urlBase + id)
			.then(fnSucess)
			.catch(fnError);
	}

	static create(data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(GrupoTipoPrecoService.urlBase, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static update(id, data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.put(GrupoTipoPrecoService.urlBase + id, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static delete(id, fnSucess = () => { }, fnError = () => { }) {
		if(Number.isInteger(id)){
			Http.getInstanceAppJson()
				.delete(GrupoTipoPrecoService.urlBase + id)
				.then(fnSucess)
				.catch(fnError);
		}else{
			Http.getInstanceAppJson()
			.post(GrupoTipoPrecoService.urlBase + 'delete', id)
			.then(fnSucess)
			.catch(fnError);
		}
	}

	static save(data, fnSucess = () => { }, fnError = () => { }) {
		if (data.idGrupoTipoPreco) {
			this.update(data.idGrupoTipoPreco, data, fnSucess, fnError);
		} else {
			this.create(data, fnSucess, fnError);
		}
	}

	static getAllByTipoPreco(tipoPreco, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(GrupoTipoPrecoService.urlBase + 'tipoPreco', tipoPreco)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByTipoPreco(tipoPreco, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(GrupoTipoPrecoService.urlBase + 'tipoPreco/' + tipoPreco.idTipoPreco)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByGrupo(grupo, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(GrupoTipoPrecoService.urlBase + 'grupo', grupo)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByGrupo(grupo, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(GrupoTipoPrecoService.urlBase + 'grupo/' + grupo.idGrupo)
			.then(fnSucess)
			.catch(fnError);
	}
}