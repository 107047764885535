
import FormTipoAnsPerfil from "./FormTipoAnsPerfil.js";
import ListTipoAnsPerfil from "./ListTipoAnsPerfil.js";
import { Permissao } from "../../controllers";
import { Util } from "../../utils";
import { DadoValorService, TipoAnsPerfilService } from "../../services";

export default class Config {

	static getUrlForm() {
		return "/CadTipoAnsPerfil";
	}

	static getUrlList() {
		return "/LstTipoAnsPerfil";
	}

	static getComponentForm() {
		return FormTipoAnsPerfil;
	}

	static getPermissionForm() {
		return Permissao.getInstance().getPermissoes([113]);
	}

	static getPermissionList() {
		return Permissao.getInstance().getPermissoes([113]);
	}

	static getComponentList() {
		return ListTipoAnsPerfil;
	}

	static getService() {
		return TipoAnsPerfilService;
	}

	static getKeyId() {
		return "idTipoAnsPerfil";
	}

	static getKeyDescription() {
		return "nmTipoAnsPerfil";
	}

	static setDefaultValues(state , fnCallBack = () => {}, className = undefined) {
		className = className === undefined ? "FormTipoAnsPerfil" : className;
		let keyNameScreen = Util.toToUpperTextCamelCase(className, "_") + ".";
		var prefix = "CONFIGURACAO.";
		DadoValorService.getValues(`${prefix}${keyNameScreen}`, (response)=> {
			Util.setValueObjectCamelCase(state.model, response, keyNameScreen, prefix);
			fnCallBack();
		}, fnCallBack);
	}
}