
import FormTipoPagamento from "./FormTipoPagamento.js";
import ListTipoPagamento from "./ListTipoPagamento.js";
import { Permissao } from "../../controllers";
import { Util } from "../../utils";
import { DadoValorService, TipoPagamentoService } from "../../services";

export default class Config {

	static getUrlForm() {
		return "/CadTipoPagamento";
	}

	static getUrlList() {
		return "/LstTipoPagamento";
	}

	static getComponentForm() {
		return FormTipoPagamento;
	}

	static getPermissionForm() {
		return Permissao.getInstance().getPermissoes([20]);
	}

	static getPermissionList() {
		return Permissao.getInstance().getPermissoes([20]);
	}

	static getComponentList() {
		return ListTipoPagamento;
	}

	static getService() {
		return TipoPagamentoService;
	}

	static getKeyId() {
		return "idTipoPagamento";
	}

	static getKeyDescription() {
		return "nmTipoPagamento";
	}

	static setDefaultValues(state , fnCallBack = () => {}, className = undefined) {
		className = className === undefined ? "FormTipoPagamento" : className;
		let keyNameScreen = Util.toToUpperTextCamelCase(className, "_") + ".";
		var prefix = "CONFIGURACAO.";
		DadoValorService.getValues(`${prefix}${keyNameScreen}`, (response)=> {
			Util.setValueObjectCamelCase(state.model, response, keyNameScreen, prefix);
			fnCallBack();
		}, fnCallBack);
	}
}