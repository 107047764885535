import Http from "../controllers/Http";

export default class ContaReceberBaixaService {

	static urlBase = "/contaReceberBaixa/";

	static getForCombo(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(ContaReceberBaixaService.urlBase + 'forCombo', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static list(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(ContaReceberBaixaService.urlBase + 'list', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneById(id, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.get(ContaReceberBaixaService.urlBase + id)
			.then(fnSucess)
			.catch(fnError);
	}

	static create(data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(ContaReceberBaixaService.urlBase, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static update(id, data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.put(ContaReceberBaixaService.urlBase + id, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static delete(id, fnSucess = () => { }, fnError = () => { }) {
		if(Number.isInteger(id)){
			Http.getInstanceAppJson()
				.delete(ContaReceberBaixaService.urlBase + id)
				.then(fnSucess)
				.catch(fnError);
		}else{
			Http.getInstanceAppJson()
			.post(ContaReceberBaixaService.urlBase + 'delete', id)
			.then(fnSucess)
			.catch(fnError);
		}
	}

	static save(data, fnSucess = () => { }, fnError = () => { }) {
		if (data.idContaReceberBaixa) {
			this.update(data.idContaReceberBaixa, data, fnSucess, fnError);
		} else {
			this.create(data, fnSucess, fnError);
		}
	}

	static getAllByContaReceberParcela(contaReceberParcela, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(ContaReceberBaixaService.urlBase + 'contaReceberParcela', contaReceberParcela)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByContaReceberParcela(contaReceberParcela, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(ContaReceberBaixaService.urlBase + 'contaReceberParcela/' + contaReceberParcela.idContaReceberParcela)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByTipoBaixa(tipoBaixa, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(ContaReceberBaixaService.urlBase + 'tipoBaixa', tipoBaixa)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByTipoBaixa(tipoBaixa, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(ContaReceberBaixaService.urlBase + 'tipoBaixa/' + tipoBaixa.idTipoBaixa)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByUsuario(usuario, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(ContaReceberBaixaService.urlBase + 'usuario', usuario)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByUsuario(usuario, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(ContaReceberBaixaService.urlBase + 'usuario/' + usuario.idUsuario)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByFaturaCobrancaBaixa(faturaCobrancaBaixa, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(ContaReceberBaixaService.urlBase + 'faturaCobrancaBaixa', faturaCobrancaBaixa)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByFaturaCobrancaBaixa(faturaCobrancaBaixa, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(ContaReceberBaixaService.urlBase + 'faturaCobrancaBaixa/' + faturaCobrancaBaixa.idFaturaCobrancaBaixa)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByConta(conta, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(ContaReceberBaixaService.urlBase + 'conta', conta)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByConta(conta, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(ContaReceberBaixaService.urlBase + 'conta/' + conta.idConta)
			.then(fnSucess)
			.catch(fnError);
	}
}