import ManyToManyCheckTipoContato from "../../components/ManyToManyCheck/ManyToManyCheckTipoContato";
import PessoaTelefoneTipoContatoService from "../../services/PessoaTelefoneTipoContatoService";

export default class ManyToManyCheckPessoaTelefoneTipoContato extends ManyToManyCheckTipoContato {

    constructor(props) {
        super(props);
        
        this.onChangeAddItem = this.onChangeAddItem.bind(this);
		this.onChangeDelItem = this.onChangeDelItem.bind(this);
		this.onChangeAddAll = this.onChangeAddAll.bind(this);
		this.onChangeDelAll = this.onChangeDelAll.bind(this);
        this.getSelecteds = this.getSelecteds.bind(this);

        this.state.selectedsOriginal = [];
    }

    componentDidMount() {
        super.componentDidMount();
        this.getSelecteds();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    componentDidUpdate(nextProps) {
        if (nextProps.parent !== undefined && this.props.parent !== undefined) {
            if (!this.objEqual(nextProps.parent, this.props.parent)) {
                this.getSelecteds();
            }
        }
    }
	

	getColumns(){
		return 1;
	}

    getSelecteds(){
        if(this.props?.parent?.idPessoaTelefone  || (Array.isArray(this.props?.parent) && this.props?.parent.length > 0)){
            PessoaTelefoneTipoContatoService.getAllByPessoaTelefone(this.props.parent, (response) => {
                let selecteds = response.flatMap(i => i.tipoContato);
				let noSelecteds = [];

				if(Array.isArray(this.props?.parent)){
					selecteds = selecteds.filter((value, index, self) => self.findIndex((v) => parseInt(v.idTipoContato) === parseInt(value.idTipoContato)) === index);
					selecteds.forEach((selected, index) => {
						let pessoaTelefones = response.filter(i=> parseInt(i.tipoContato.idTipoContato) === (selected.idTipoContato)).flatMap(i => i.pessoaTelefone);
						if((pessoaTelefones.length !== this.props.parent.length)){
							noSelecteds.push(selected);
						}
					});

					selecteds = selecteds.filter(selected => !noSelecteds.find(ns => selected.idTipoContato === ns.idTipoContato));
				}

                this.setState({selecteds : selecteds, selectedsOriginal : response, noSelecteds : noSelecteds});
            });
        }
	}

    onChangeAddItem(e, item){     
		var parents = Array.isArray(this.props.parent) ? this.props.parent : [this.props.parent];

		parents.forEach(parent => {
			var selected = this.state.selectedsOriginal.find(i => parseInt(i.pessoaTelefone.idPessoaTelefone) === parseInt(parent.idPessoaTelefone) && parseInt(i.tipoContato.idTipoContato) === parseInt(item.idTipoContato));
			if(!selected){
				PessoaTelefoneTipoContatoService.save({
					tipoContato : item,
					pessoaTelefone : parent
				}, response =>{
					this.getSelecteds();
					super.onChangeAddItem(e, item);
				});
			}
		});
	};

	onChangeDelItem(e, item){
		var parents = Array.isArray(this.props.parent) ? this.props.parent : [this.props.parent];

		parents.forEach(parent => {			
			var selected = this.state.selectedsOriginal.find(i => parseInt(i.pessoaTelefone.idPessoaTelefone) === parseInt(parent.idPessoaTelefone) && parseInt(i.tipoContato.idTipoContato) === parseInt(item.idTipoContato));
			if(selected){
				PessoaTelefoneTipoContatoService.delete(selected.idPessoaTelefoneTipoContato, response =>{
					this.getSelecteds();
					super.onChangeDelItem(e, item);
				});
			};
		});
	};

	onChangeAddAll(e, itens){
		var parents = Array.isArray(this.props.parent) ? this.props.parent : [this.props.parent];

		parents.forEach(parent => {
			var obj = itens.filter(item => 
				!this.state.selectedsOriginal.find(i => parseInt(i.pessoaTelefone.idPessoaTelefone) === parseInt(parent.idPessoaTelefone) && parseInt(i.tipoContato.idTipoContato) === parseInt(item.idTipoContato))
			).map(item => {
				return {
					tipoContato : item,
					pessoaTelefone : parent
				}
			});

			if(obj.length > 0){
				PessoaTelefoneTipoContatoService.save(obj, response =>{
					this.getSelecteds();
					super.onChangeAddAll(e, itens);
				});
			}
		});
	};

	onChangeDelAll(e, itens){
		var parents = Array.isArray(this.props.parent) ? this.props.parent : [this.props.parent];

		parents.forEach(parent => {
			var selecteds = this.state.selectedsOriginal.filter(i => parseInt(i.pessoaTelefone.idPessoaTelefone) === parseInt(parent.idPessoaTelefone)).filter(i => itens.find(is => parseInt(i.tipoContato.idTipoContato) === parseInt(is.idTipoContato)));
			PessoaTelefoneTipoContatoService.delete(selecteds , response =>{
				this.getSelecteds();
				super.onChangeDelAll(e, itens);
			});
		});
	};

    render() {
        return super.render();
    }
}