import React from "react";
import Field from "./Field";
import { CheckSquare as IconCheckSquare, Square as IconSquare } from "react-bootstrap-icons";

export default class BtnCheck extends Field {

    static color = {
        primary : "primary",
        success : "success",
        danger : "danger",
        warning : "warning",
        dark : "dark",
        default : "primary"
    };
    
    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    getValue(value){
        switch(value?.toString()?.toLowerCase()?.trim()){
            case "true":
            case "yes": 
            case "1":
                return true;
    
            case "":
            case "false":
            case "no": 
            case "0": 
            case null: 
            case undefined:
                return false;
    
            default:
                return value;
        }
    }

    handleChange(e) {
        if (this.props.onChange) {
            this.props.onChange(e, e?.target?.checked, e?.target?.name);
        }
    }

    handleClick(e) {
        var checked = this.props.checked !== undefined ? this.props.checked : this.props.value === true;

        if (this.props.onClick) {
            this.props.onClick(e, !checked, this.props.name);
        }
    }

    render() {
        const props = Object.assign({}, this.props);
        props.type = "checkbox";
        props.onChange = this.handleChange;
        props.onClick = this.handleClick;
        
        props.ref = this.props.refParam ? this.props.refParam : this.ref;
        props.className = `btn-check ${props.className ? props.className : ""}`;
        props.checked = props.checked !== undefined ? props.checked : this.props.value === true;
        props.id = props.id !== undefined ? props.id : 'form-checkbox-input-' + Math.random();

        delete props.label;
        delete props.color;

        var icon = <></>;
        var label = Array.isArray(this.props.label) ? (this.getValue(this.props.value) ? this.props.label[0] : this.props.label[1]) : this.props.label

        if(props.checked){
            icon = <><IconCheckSquare/>&nbsp;</>;
        }else{
            icon = <><IconSquare/>&nbsp;</>;
        }

        return (
            <React.Fragment>
                <input {...this.getProps(props)} />
                <label className={`btn btn-outline-${this.props.color ? this.props.color : BtnCheck.color.default}`} htmlFor={this.props.id} onClick={this.handleClick}>
                    {icon}{label}
                </label>
            </React.Fragment>
        );
    }
}