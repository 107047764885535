import Config from "./Config";
import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import SelectSubmodulo from "../../components/SelectDB/SelectSubmodulo";
import ManyToManyCheckGrupoModuloByGrupo from "./ManyToManyCheckGrupoModuloByGrupo";
import Permissao from "../../controllers/Permissao";
import { BaseCrud } from "../../siht/base";
import { FormButtons, FormItem, Row, TabNavigator, Tab, Panel, PanelBody, PanelFooter, Container } from "../../siht/components";
import { InputText, TextArea, Form, BtnSubmit } from "../../siht/elements";
import { BtnRadioGroupSimNao } from "../../components/Custom";

class FormModulo extends BaseCrud {

	constructor(props) {
		super(props);
		this.init = this.init.bind(this);
		this.initModel = this.initModel.bind(this);
		this.handleSuccess = this.handleSuccess.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleChangeModel = this.handleChangeModel.bind(this);
		this.handleSubmitForm = this.handleSubmitForm.bind(this);
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		super.init(Config);
	}

	initModel(){
		return {
			idModulo : null,
			nmModulo : "",
			txModulo : "",
			submodulo : null,
			flAtivo : true,
			grupoModulo : []
		}
	}

	handleSuccess(e) {
		super.handleSuccess(e, Config);
	}

	handleClose(e) {
		super.handleClose(e, Config);
	}

	render() {
		return super.render(

					<Panel key={this.state.key} title="Cadastro de Módulo" onClose={this.handleClose} isModal={this.isModal()} container={{sizing: Container.sizing.xxl, className : ""}}>
						<PanelBody className="p-0 m-0">
							<TabNavigator key={this.state.key} onChangeTab={this.handleChangeTab} className="border-light">
								<Tab title="Módulo" className="m-2">
									<Form onSubmit={this.handleSuccess} refParam={f => (this.form = f)}>
										<Row className="g-3 pb-2 px-2">
											<FormItem md={6} label="Código">
												<InputText name="idModulo" value={this.state.model.idModulo} readOnly={true} className="readonly-field" required={false} onChange={this.handleChangeModel}/>
											</FormItem>
											<FormItem md={12} label="Nome da Módulo">
												<InputText name="nmModulo" value={this.state.model.nmModulo} autoFocus={true} maxLength={100} required={true} onChange={this.handleChangeModel}/>
											</FormItem>
											<FormItem md={12} label="TxModulo">
												<TextArea name="txModulo" value={this.state.model.txModulo} required={true} onChange={this.handleChangeModel}/>
											</FormItem>
											<FormItem md={12} label="Nome do Submodulo">
												<SelectSubmodulo name="submodulo" value={this.state.model.submodulo} required={true} onChange={this.handleChangeModel} placeholder="Selecione Nome do Submodulo"/>
											</FormItem>
											<FormItem md={2} label="Ativo?">
												<BtnRadioGroupSimNao name="flAtivo" value={this.state.model.flAtivo} required={true} onChange={this.handleChangeModel}/>
											</FormItem>
											<BtnSubmit hidden > Enviar </BtnSubmit>
										</Row>
									</Form>
								</Tab>
								<Tab show={Permissao.getInstance().getPermissoes([12])} title="Grupos" disabled={!this.state.model.idModulo} opened={false} className="m-2">
									<ManyToManyCheckGrupoModuloByGrupo typeCheck={ManyToManyCheckGrupoModuloByGrupo.typeCheck.box} parent={this.state.model} search={true}/>
								</Tab>
							</TabNavigator>
						</PanelBody>
						<PanelFooter>
							<FormButtons show={this.state.tabIndex === 0} onClose={this.handleClose} onSubmit={this.handleSubmitForm}/>
						</PanelFooter>
					</Panel>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <FormModulo {...props} navigate={navigate} params={params} location={location}/>
}
export default With