import Http from "../controllers/Http";

export default class VwUsuarioUnidadeService {

	static urlBase = "/vwUsuarioUnidade/";

	static getForCombo(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(VwUsuarioUnidadeService.urlBase + 'forCombo', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static list(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(VwUsuarioUnidadeService.urlBase + 'list', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneById(id, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.get(VwUsuarioUnidadeService.urlBase + id)
			.then(fnSucess)
			.catch(fnError);
	}


	static getAllByUsuario(usuario, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(VwUsuarioUnidadeService.urlBase + 'usuario', usuario)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByUsuario(usuario, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(VwUsuarioUnidadeService.urlBase + 'usuario/' + usuario.idUsuario)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByUnidade(unidade, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(VwUsuarioUnidadeService.urlBase + 'unidade', unidade)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByUnidade(unidade, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(VwUsuarioUnidadeService.urlBase + 'unidade/' + unidade.idUnidade)
			.then(fnSucess)
			.catch(fnError);
	}
}