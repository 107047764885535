//#NO-CHANGE-FILE#

import Config from "./Config";
import React from "react";
import { useNavigate, useLocation, useParams } from "react-router";
import { ThreeDotsVertical as IconThreeDotsVertical } from "react-bootstrap-icons"
import { BaseList } from "../../siht/base";
import { DataGrid, DataGridButtons, DataGridColumn, Panel, Container, Row, InputGroup, InputGroupText, InputGroupButton, DropDown, DropDownLabel, DropDownItem, PanelFooter } from "../../siht/components";
import { Button, Div, Form, InputSearch, BtnSubmit, BtnButton } from "../../siht/elements";
import { IconSearch, IconClear, IconEdit, IconDelete, IconCopy } from "../../icons";
import { BtnEdit, BtnDelete, BtnNew, BtnCopy } from "../../components/Custom";

class ListEscalaTrabalho extends BaseList {

	constructor(props) {
		super(props);
		this.getItens = this.getItens.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleNew = this.handleNew.bind(this);
		this.handleEdit = this.handleEdit.bind(this);
		this.handleCopy = this.handleCopy.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.handleMore = this.handleMore.bind(this);
		this.onSubmitFilter = this.onSubmitFilter.bind(this);
		this.handleClear = this.handleClear.bind(this);
		this.handleFormSuccess = this.handleFormSuccess.bind(this);
		this.handleFormClose = this.handleFormClose.bind(this);
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init() {
		super.init(Config);
	}

	getItens(more) {
		super.getItens(Config.getService().list, false, more);
	}

	handleNew(e) {
		super.handleNew(e, Config);
	}

	handleEdit(e, item) {
		super.handleEdit(e, Config, item);
	}

	handleDelete(e, item) {
		super.handleDelete(e, Config, item);
	}

	handleCopy(e, item) {
		super.handleCopy(e, Config, item);
	}

	getFormEmbedded() {
		return super.getFormEmbedded(Config);
	}

	render() {
		return super.render(
			<Panel title="Lista de EscalaTrabalho(s)" isModal={this.isModal()} container={{ sizing: Container.sizing.xxl, className: "" }}>
				<Div className="d-flex justify-content-end flex-nowrap gap-2">
					<BtnNew onClick={this.handleNew} />
					<BtnDelete disabled={this.state.selecteds.length === 0} sizing={Button.sizing.default} onClick={e => this.handleDelete(e, this.state.selecteds)}> Excluir </BtnDelete>
				</Div>

				{this.getFormEmbedded()}

				<Form onSubmit={this.onSubmitFilter}>
					<Row className="mt-1 mb-2 g-3">
						<InputGroup className="flex-nowrap" sizing={InputGroup.sizing.default}>
							<InputGroupText><IconSearch /></InputGroupText>
							<InputSearch autoComplete="off" autoFocus placeholder="Digite sua busca" name="search" value={this.state.filter.search} required={false} opcional={true} onChange={(e, v, n) => this.handleChange(e, v, n, this.state.filter)} />
							<InputGroupButton>
								<BtnSubmit title="Filtrar"> Buscar </BtnSubmit>
								<BtnButton title="Limpar Pesquisa" color={BtnButton.color.outlineDanger} onClick={this.handleClear}> <IconClear /> </BtnButton>
							</InputGroupButton>
						</InputGroup>
					</Row>
				</Form>

				<DataGrid onDoubleClickRow={this.handleEdit} responsive={true} data={this.state.data} search={false} isDisabled={i => false} sizing={DataGrid.sizing.sm} select={true} selecteds={this.state.selecteds} onSelect={(selecteds) => { this.setState({ selecteds: selecteds }) }} getItemValue={i => i.idEscalaTrabalho} onMore={this.handleMore}>
					<DataGridColumn label="Código" field={i => i.idEscalaTrabalho} className="text-center" />
					<DataGridColumn col={6} label="Nome da Escala" field={i => i.nmEscalaTrabalho} className="text-start" />
					<DataGridColumn col={3} label="D.S.R" field={i => i.dsDsr} className="text-start" />
					<DataGridColumn col={2} label="CH Semanal" field={i => i.vlChSemanal} className="text-center" />
					<DataGridButtons>
						<BtnEdit sizing={Button.sizing.sm} onClick={this.handleEdit} />
						<BtnDelete sizing={Button.sizing.sm} onClick={this.handleDelete} />
						<BtnCopy sizing={Button.sizing.sm} onClick={this.handleCopy} />
						<DropDown alignment={DropDown.alignment.end}>
							<DropDownLabel sizing={Button.sizing.sm} toggle={false}> <IconThreeDotsVertical /> </DropDownLabel>
							<DropDownItem title="Editar Registro" type="button" onClick={this.handleEdit}> <IconEdit /> Editar </DropDownItem>
							<DropDownItem title="Excluir Registro" type="button" onClick={this.handleDelete}> <IconDelete /> Excluir </DropDownItem>
							<DropDownItem title="Copiar Registro" type="button" onClick={this.handleCopy}> <IconCopy /> Copiar </DropDownItem>
						</DropDown>
					</DataGridButtons>
				</DataGrid>

				<PanelFooter className="text-center py-2" show={this.state.data && this.state.data?.length > 0}>
					<BtnButton onClick={this.handleMore} disabled={!this.state.hasMore}> Buscar Mais Itens </BtnButton>
				</PanelFooter>
			</Panel>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <ListEscalaTrabalho {...props} navigate={navigate} params={params} location={location} />
}
export default With