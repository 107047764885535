
import FormGrupoPainel from "./FormGrupoPainel.js";
import ListGrupoPainel from "./ListGrupoPainel.js";
import { Permissao } from "../../controllers";
import { Util } from "../../utils";
import { DadoValorService, GrupoPainelService } from "../../services";

export default class Config {

	static getUrlForm() {
		return "/CadGrupoPainel";
	}

	static getUrlList() {
		return "/LstGrupoPainel";
	}

	static getComponentForm() {
		return FormGrupoPainel;
	}

	static getPermissionForm() {
		return Permissao.getInstance().getPermissoes([101]);
	}

	static getPermissionList() {
		return Permissao.getInstance().getPermissoes([101]);
	}

	static getComponentList() {
		return ListGrupoPainel;
	}

	static getService() {
		return GrupoPainelService;
	}

	static getKeyId() {
		return "idGrupoPainel";
	}

	static getKeyDescription() {
		return "nmGrupoPainel";
	}

	static setDefaultValues(state , fnCallBack = () => {}, className = undefined) {
		className = className === undefined ? "FormGrupoPainel" : className;
		let keyNameScreen = Util.toToUpperTextCamelCase(className, "_") + ".";
		var prefix = "CONFIGURACAO.";
		DadoValorService.getValues(`${prefix}${keyNameScreen}`, (response)=> {
			Util.setValueObjectCamelCase(state.model, response, keyNameScreen, prefix);
			fnCallBack();
		}, fnCallBack);
	}
}