//#NO-CHANGE-FILE#
import Config from "./Config";
import Util from "../../utils/Util";
import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { BaseList } from "../../siht/base";
import { DataGrid, DataGridButtons, DataGridColumn, Row, InputGroup, InputGroupText, InputGroupButton, PanelFooter, FormItem, BtnRadioGroup, DataGridFooter, FormItemLabelDropDown } from "../../siht/components";
import { Button, Div, Form, InputSearch, BtnSubmit, BtnButton, Span, InputDate, InputNumber, BtnRadio, InputText, Option } from "../../siht/elements";
import { FormItemTipoPessoaPessoa, FormItemEmpresaUnidade } from "../../components/FormItem";
import { Modal } from "../../siht/controller";
import { PrinterFill as IconPrinterFill} from "react-bootstrap-icons";
import { IconClear, IconSearch } from "../../icons";
import { BtnView, StatusCustom } from "../../components/Custom";
import SelectStatusFatura from "../../components/SelectDBMultiple/SelectStatusFatura";
import FormRelatorioFatura001 from "../Relatorio/FormRelatorioFatura001";
import SelectTipoFatura from "../../components/SelectDB/SelectTipoFatura";

class ListFaturaFatura extends BaseList {

	constructor(props) {
		super(props);
		this.getItens = this.getItens.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleNew = this.handleNew.bind(this);
		this.handleEdit = this.handleEdit.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.handleMore = this.handleMore.bind(this);
		this.onSubmitFilter = this.onSubmitFilter.bind(this);
		this.handleClear = this.handleClear.bind(this);
		this.handleFormSuccess = this.handleFormSuccess.bind(this);
		this.handleFormClose = this.handleFormClose.bind(this);
		this.handleChangeFilter = this.handleChangeFilter.bind(this);		
		this.initStateFilter = this.initStateFilter.bind(this);
        this.handleImprimir = this.handleImprimir.bind(this);
        this.getTotal = this.getTotal.bind(this);

        this.cache = {
            active: true,
            id: "cache-ListFaturaFatura",
            state: {
                filter : true
            },
            clear: true,           
        };

        var tzoffset = (new Date()).getTimezoneOffset() * 60000;
		var dtHoje = new Date();
		this.dtHoje = new Date(dtHoje - tzoffset).toISOString().slice(0, 10);
	}

	initStateFilter(){

        return {
			unidade : null,
            pessoa : null,
			statusFaturas : null,
            tipoFatura : null,
			dtStart : "",
			dtEnd : "",
			idFatura : "",
			idFaturaCobranca : "",
            idFaturaAcordo : "",
			idOrdem : "",
			cdStatusVencimento : "T",
			cdIdentificadorExterno : "",
            search: "",
            page : 1,
            pageSize : 50,

            cdData : null,
			cdCodigo : null,
        };
    }

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		super.init(Config);
	}

	getItens(more){
		super.getItens(Config.getService().list, false, more);
	}

	handleNew(e) {
		super.handleNew(e, Config);
	}

	handleEdit(e, item) {
		super.handleEdit(e, Config, item);
	}

	handleDelete(e, item) {
		super.handleDelete(e, Config, item);
	}

	getFormEmbedded(){
		return super.getFormEmbedded(Config);
	}

    handleImprimir(e, fatura = null) {
		if(fatura){
			Modal.openWindow(FormRelatorioFatura001, {sizing : Modal.sizing.default}, { idFaturas : [fatura.idFatura] });
		}else{
			let idFaturas = this.state.selecteds.flatMap((o) => (o.idFatura));
			Modal.openWindow(FormRelatorioFatura001, {sizing : Modal.sizing.default}, { idFaturas : idFaturas });
		}
		
	}

    getTotal(prop){
		let total = 0;
		if(this.state.selecteds.length === 0){
			total = this.state.data.reduce((soma, item) => soma + item[prop], 0);
		}else{
			total = this.state.selecteds.reduce((soma, item) => soma + item[prop], 0);
		}

		return total;
	}

	render() {

		return super.render(
			<Div className="px-2" key={this.state.key}>
                <Div className="d-flex justify-content-end flex-nowrap gap-2">                    
                    <BtnButton color={Button.color.outlinePrimary} disabled={this.state.selecteds.length === 0} sizing={Button.sizing.default} onClick={e => this.handleImprimir(e, null)}> <IconPrinterFill/> Imprimir </BtnButton>
                </Div>

                <Form onSubmit={this.onSubmitFilter}>
                    <Row className="g-3">
                        <FormItem md={2} label={
							<FormItemLabelDropDown value={this.state.filter.cdCodigo} name="cdCodigo" onChange={this.handleChangeFilter}>
                                <Option value="ID_FATURA" label="Código da Fatura"  selected={true}/>
                                <Option value="ID_FATURA_COBRANCA" label="Código da Cobrança"/>
                                <Option value="ID_FATURA_ACORDO" label="Código do Acordo"/>
                                <Option value="CD_IDENTIFICADOR_EXTERNO" label="Identificador Externo"/>
                                <Option value="CD_PEDIDO_EXTERNO" label="Pedido Externo"/>
								<Option value="NR_NOTA_FISCAL" label="Número da Nota Fiscal"/>
								<Option value="NR_RPS" label="Número do RPS"/>
                                <Option value="ID_ORDEM" label="Código da Ordem"/>
							</FormItemLabelDropDown>
						}>
							{(() => {
								switch(this.state.filter.cdCodigo?.value){
									case "CD_IDENTIFICADOR_EXTERNO":
                                    case "CD_PEDIDO_EXTERNO": 
                                        return <InputText name="codigo" value={this.state.filter.codigo} opcional={true} required={false} onChange={this.handleChangeFilter}/>
									default: return <InputNumber name="codigo" value={this.state.filter.codigo} opcional={true} required={false} onChange={this.handleChangeFilter}/>
								}
							})()}
                        </FormItem>

                        <FormItem md={2} label={
							<FormItemLabelDropDown value={this.state.filter.cdData} name="cdData" onChange={this.handleChangeFilter} getOptionLabel={i => i?.label !== undefined ? `Data Inicial de ${i.label}` : i?.label}>								
								<Option value="CRIACAO_FATURA" label="Faturamento" selected={true}/>
								<Option value="VENCIMENTO_COBRANCA" label="Vencimento"/>
							</FormItemLabelDropDown>
						}>
							<InputDate name="dtStart" max={this.dtHoje} value={this.state.filter.dtStart} required={false} opcional={true} onChange={this.handleChangeFilter} className="text-center"/>
						</FormItem>
						
						<FormItem md={2} label={`Data Final de ${this.state.filter.cdData?.label}`}>
							<InputDate name="dtEnd" min={this.state.filter.dtStart} max={this.dtHoje} value={this.state.filter.dtEnd} required={false} opcional={true} onChange={this.handleChangeFilter} className="text-center"/>
						</FormItem>

                        <FormItem md={3} label="Status Fatura">
                            <SelectStatusFatura name="statusFaturas" value={this.state.filter.statusFaturas} required={false} opcional={true} onChange={this.handleChangeFilter} placeholder="Selecione o Status" buttons={{search : false, new : false, edit: false}}/>
                        </FormItem>
                        <FormItem md={3} label="Tipo Fatura">
                            <SelectTipoFatura name="tipoFatura" value={this.state.filter.tipoFatura} required={false} opcional={true} onChange={this.handleChangeFilter} placeholder="Selecione o Status" buttons={{search : false, new : false, edit: false}}/>
                        </FormItem>
                        <FormItem md={3} label="Status Pagamento">
                            <BtnRadioGroup name="cdStatusVencimento" color={BtnRadioGroup.color.primary} opcional={true} value={this.state.filter.cdStatusVencimento} onClick={this.handleChangeFilter}>
                                <BtnRadio value="T" label="Todos" />
                                <BtnRadio value="V" label="Vencido" />
                                <BtnRadio value="D" label="Em dia" />
                            </BtnRadioGroup>
                        </FormItem>

                        <FormItemEmpresaUnidade md={[4,5]} name="unidade" opcional={true} value={this.state.filter.unidade} required={false} onChange={this.handleChangeFilter} buttons={{search : false, new : false, edit: false}}/>
                    </Row>
                    <Row className="mt-1 g-3">                        
                        <FormItemTipoPessoaPessoa label={["Tipo Cliente", "Cliente"]} md={[4,8]} name="pessoa" value={this.state.filter.pessoa} required={false} onChange={this.handleChangeFilter} opcional={true}/>
                    </Row>
                    <Row className="mt-1 g-3">
                        <InputGroup className="flex-nowrap" sizing={InputGroup.sizing.default}>
                            <InputGroupText><IconSearch/></InputGroupText>
                            <InputSearch autoComplete="off" autoFocus placeholder="Digite sua busca" name="search" value={this.state.filter.search} required={false} opcional={true} onChange={(e,v,n) => this.handleChange(e,v,n, this.state.filter)}/>
                            <InputGroupButton>
                                <BtnSubmit title="Filtrar"> Buscar </BtnSubmit>
                                <BtnButton title="Limpar Pesquisa" color={BtnButton.color.outlineDanger} onClick={this.handleClear}> <IconClear/> </BtnButton>
                            </InputGroupButton>
                        </InputGroup>
                    </Row>
                </Form>

                <DataGrid onDoubleClickRow={this.handleEdit} responsive={true} data={this.state.data} search={false} isDisabled={i => false} sizing={DataGrid.sizing.sm} select={true} selecteds={this.state.selecteds} onSelect={(selecteds)=>{this.setState({selecteds : selecteds})}} getItemValue={i=>i.idFatura} onMore={this.handleMore}>
                    <DataGridColumn label="Fatura" field={i => i.idFatura} className="text-center"/>
                    
                    <DataGridColumn md={3} label="Cliente" field={i => <>
                        <Span className="fw-bold">{i.pessoa?.nmPessoa}</Span> <br/>
                        <StatusCustom color={i.pessoa?.tipoPessoa}> PESSOA {i.pessoa?.tipoPessoa?.nmTipoPessoa} </StatusCustom>
                        <Div className="fw-bold fs-7" show={i.pessoa?.tipoPessoa?.cdTipoPessoa === "F"}>{Util.formataCPF(i.pessoa?.nrCpf)}</Div>
                        <Div className="fw-bold fs-7" show={i.pessoa?.tipoPessoa?.cdTipoPessoa === "J"}>{Util.formataCnpj(i.pessoa?.nrCnpj)}</Div>
                        </>}
                    className="text-start"/>

                    {/*
                    <DataGridColumn mobile={false} col={2} label="Nome do Usuario" field={i => <>
                        <Div className="fw-bold">{i.usuario?.nmUsuario}</Div>
                        <Div className="fs-7">{Util.date2Br(i.dhInsert, true)}</Div>								
                    </>} className="text-start"/>*/}

                    <DataGridColumn mobile={false} md={2} label="Empresa / Unidade" field={i => <>
                            <Span className="fw-bold">{i.unidade?.empresa?.nmEmpresa}</Span> <br/>
                            {i.unidade?.nmUnidade}
                            <Div show={i.cdIdentificadorExterno} className="fs-7"> Ident. Ext: <Span className="fw-bold fs-7">{i.cdIdentificadorExterno}</Span></Div>
							<Div show={i.cdPedidoExterno} className="fs-7"> Pedido. Ext: <Span className="fw-bold fs-7">{i.cdPedidoExterno}</Span></Div>
                        </>} className="text-start"/>

                    <DataGridColumn mobile={false} label="Parcelas" field={i => i.nrParcela} className="text-center"/>
                    <DataGridColumn mobile={false} md={1} label="Bruto" field={i => <Span className="fw-bold text-dark"> {Util.floatToReais(i.vlBruto)} </Span>} className="text-end"/>
                    <DataGridColumn mobile={false} md={1} label="Fatura" field={i => <Span className="fw-bold text-primary"> {Util.floatToReais(i.vlFatura)} </Span>} className="text-end"/>
                    <DataGridColumn mobile={false} md={1} label="Descontos" field={i => <Span className="fw-bold text-danger"> {Util.floatToReais(i.vlDesconto)} </Span>} className="text-end"/>
                    <DataGridColumn mobile={false} md={1} label="Pago" field={i => <Span className="fw-bold text-success"> {Util.floatToReais(i.vlPago)} </Span>} className="text-end"/>
                    <DataGridColumn mobile={false} md={1} label="Aberto" field={i => <Span className="fw-bold text-danger"> {Util.floatToReais(i.vlAberto)} </Span>} className="text-end"/>

                    <DataGridColumn col={1} label="Status" field={i => <StatusCustom className="w-100" color={i.statusFatura}> {i.statusFatura?.nmStatusFatura} </StatusCustom>} className="text-center"/>
                    <DataGridColumn col={1} label="Tipo" field={i => <StatusCustom className="w-100" color={i.tipoFatura}> {i.tipoFatura?.nmTipoFatura} </StatusCustom>} className="text-center"/>

                    <DataGridButtons>
                        <BtnView sizing={Button.sizing.default} onClick={this.handleEdit}/>
                        {/*<DropDown alignment={DropDown.alignment.end}>
                            <DropDownLabel sizing={Button.sizing.default} toggle={false}> <IconThreeDotsVertical/> </DropDownLabel>
                            <DropDownItem title="Ver Fatura" type="button" onClick={this.handleEdit}> <IconEyeFill/> Ver Fatura </DropDownItem>                            
                            <DropDownItem title="Estornar Fatura" type="button" onClick={this.handleDelete}> <IconDelete/> Estornar Fatura </DropDownItem>
                        </DropDown>*/}
                    </DataGridButtons>

                    <DataGridFooter>
						<DataGridColumn colSpan={5} className="text-center"/>
						<DataGridColumn className="text-end">
							<Span className="fw-bold text-dark"> {Util.floatToReais(this.getTotal("vlBruto"))} </Span>
						</DataGridColumn>
                        <DataGridColumn className="text-end">
							<Span className="fw-bold text-primary"> {Util.floatToReais(this.getTotal("vlFatura"))} </Span>
						</DataGridColumn>
                        <DataGridColumn className="text-end">
							<Span className="fw-bold text-danger"> {Util.floatToReais(this.getTotal("vlDesconto"))} </Span>
						</DataGridColumn>
                        <DataGridColumn className="text-end">
							<Span className="fw-bold text-success"> {Util.floatToReais(this.getTotal("vlPago"))} </Span>
						</DataGridColumn>
                        <DataGridColumn className="text-end">
							<Span className="fw-bold text-danger"> {Util.floatToReais(this.getTotal("vlAberto"))} </Span>
						</DataGridColumn>
						<DataGridColumn colSpan={2} className="text-center"/>
					</DataGridFooter>

                </DataGrid>

                <PanelFooter className="text-center py-2" show={this.state.data && this.state.data?.length > 0}>
                    <BtnButton onClick={this.handleMore} disabled={!this.state.hasMore}> Buscar Mais Itens </BtnButton>
                </PanelFooter>
            </Div>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <ListFaturaFatura {...props} navigate={navigate} params={params} location={location}/>
}
export default With