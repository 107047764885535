import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import LoginService from "../../services/LoginService";
import Auth from "../../controllers/Auth";
import ConfigApp from "../../ConfigApp";
import { BaseComponent } from "../../siht/base";
import {  InputEmail, InputPassword } from "../../siht/elements";
import { Modal } from "../../siht/controller";
import FormLoginGrupo from "./FormLoginGrupo";
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import { toast } from "react-toastify";

class FormLogin extends BaseComponent {

    constructor(props) {
        super(props);

        this.handleChangeModel = this.handleChangeModel.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.init = this.init.bind(this);
        this.onSuccessLoginGoogle = this.onSuccessLoginGoogle.bind(this);

        this.state = {
            model: {
                usuario: "",
                senha: "",
                google : false,
                tokenProvisorio : false,
                grupo : {}
            },
            validated: false
        };

        Auth.logout();
    }

    componentDidMount() {
        super.componentDidMount();
        this.init();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    init(){
        
    }

    onSuccessLoginGoogle(response) {
        this.setState(state => {
            state.model.usuario = "";
            state.model.senha = "";
            state.model.google = response;
            return state;
        }, this.onSubmit);
    }

    onErrorLoginGoogle(response) {
        toast.error('Erro ao realizar login com o Google', {
            theme: "colored"
        });
    }

    onSubmit(event) {

        LoginService.login(this.state.model, (response) => {

            if(response.token && response.refreshToken){
                Auth.login(response.token, response.refreshToken);                
                this.props.navigate('/');
                return;
            }

            if(response.grupos !== undefined && Array.isArray(response.grupos)){
                if(response.grupos.length === 0){
                    Modal.info('Atenção','Usuário não está vinculado a nenhum grupo de acesso!', "Verifique as configurações do usuário e o vínculo com os grupos de acesso!", Modal.style.warning);
                    return;
                }else if(response.grupos.length > 0){
                    Modal.openWindow(FormLoginGrupo, {sizing : Modal.sizing.lg}, {title : "Grupos", grupos : response.grupos}, (e, grupo) => {
                        this.setState(state =>{
                            state.model.grupo = grupo;
                            state.model.tokenProvisorio = response.tokenProvisorio;
                            return state;
                        }, () =>{
                            this.onSubmit(event);
                        });     
                    }, () =>{
                        this.setState(state =>{
                            state.model.tokenProvisorio = false;
                            state.model.usuario = "";
                            state.model.senha = "";
                            state.model.google = false;
                            return state;
                        });
                    });
                }
            }
        }, () =>{
            this.setState(state =>{
                state.model.tokenProvisorio = false;
                state.model.google = false;
                return state;
            });
        });
    }

    render() {


        return (
            <div className="text-center page-login w-100">

                <div className="form-signin">
                    <svg data-name="Layer 1" id="Layer_1" viewBox="0 0 512 512" width="128" height="128" xmlns="http://www.w3.org/2000/svg"><path d="M196,282.66c85,62.83,150.46,72.28,182.12,72.28,12.63,0,19.88-1.5,20.84-1.72a6.22,6.22,0,0,0,1.41-11.64c-1.51-.75-152.35-77-270.13-270.65A6.22,6.22,0,0,0,119,72.06C118.59,73.27,76.67,194.47,196,282.66Zm7.39-10c-89-65.78-82.63-150.37-76.09-182.86C221.1,238.41,333.08,316,376.43,342.3,346.24,341.78,283.66,332,203.34,272.66Z"/><path d="M288.52,388.5c39.66,0,61-8.92,62.4-9.52a6.22,6.22,0,0,0-1.47-11.85c-.82-.13-82.08-13.58-147.67-49.21-64.51-35-80.06-76.85-80.21-77.27a6.32,6.32,0,0,0-6.33-4.11,6.24,6.24,0,0,0-5.63,5c-.2,1-18.61,103.12,84.6,132.6C232.41,385.09,264,388.5,288.52,388.5Zm31.27-14.72c-24.56,3.52-65.94,4.5-122.16-11.57-66.25-18.92-76.84-69.59-77.26-98.14,11.45,16.91,33.95,42.22,75.47,64.78C239.93,352.79,289.65,366.8,319.79,373.78Z"/><path d="M138.85,388.83a6.22,6.22,0,0,0,0,7.16c1.47,2.06,34.88,48.06,90.49,48.06,3,0,6-.13,9.07-.4,60-5.32,84.58-32.7,85.6-33.87A6.2,6.2,0,0,0,325,403a6.1,6.1,0,0,0-5.93-3.48c-1,0-105.4,5.53-173.43-13.1A6.26,6.26,0,0,0,138.85,388.83ZM162.21,403c49.42,9.84,108,10.18,137.91,9.54-12.23,7.3-32.61,16-62.78,18.68C203,434.32,176.9,416.53,162.21,403Z"/></svg>

                    <h1 className="h4 mb-3 fw-bold">SMG</h1>
                    <h2 className="h5 mb-3 fw-normal">Acesso Restrito</h2>

                    <form onSubmit={this.handleSubmit} noValidate className={`overflow-hidden needs-validation ${this.state.validated ? "was-validated" : ""} form-login`}>
                        <div className="mb-3">
                            <InputEmail sizing={InputEmail.sizing.lg} layout="floating" autoComplete="on" label="User" placeholder="Email" required md={12} value={this.state.model.usuario} name="usuario" onChange={this.handleChangeModel} />
                            <InputPassword  sizing={InputPassword.sizing.lg} layout="floating" autoComplete="on" label="Password" placeholder="Digite sua senha" col={12} value={this.state.model.senha} name="senha" onChange={this.handleChangeModel} required />
                        </div>
                        <button className="w-100 btn btn-lg btn-primary" type="submit">Entrar</button>

                        {ConfigApp.getInstance().clientIdGoogleAuth ?
                            <div className="pt-2">
                                <GoogleOAuthProvider clientId={ConfigApp.getInstance().clientIdGoogleAuth}>
                                    <GoogleLogin onSuccess={this.onSuccessLoginGoogle} onError={this.onErrorLoginGoogle} theme="filled_black"/>
                                </GoogleOAuthProvider>
                            </div> : <></>}

                        <span className="badge rounded-pill bg-primary my-3">Versão: {parseFloat(ConfigApp.version).toFixed(2)}</span>
                    </form>
                </div>
            </div>
        )
    }
}

function With(props) {
    let navigate = useNavigate();
    let params = useParams();
    let location = useLocation();
    return <FormLogin {...props} navigate={navigate} params={params} location={location}/>
}

export default With