import { BaseComponent } from "../siht/base";

export default class IconCopy extends BaseComponent {

	render() {

		if(this.props.show !== undefined && !Boolean(this.props.show)){
			return (<></>);
		}

		const props = Object.assign({}, this.props);
		props.xmlns = "http://www.w3.org/2000/svg";
		props.width = props.width === undefined ? 16 : props.width;
		props.height = props.height === undefined ? 16 : props.height;
		props.fill = "currentColor";
		props.viewBox=`0 0 16 16`

		return (
			<svg {...props}>
			  <path fillRule="evenodd" d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"/>
			</svg>
		);
	}
}