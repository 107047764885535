import AnsCaracteristicaSoloService from "../../services/AnsCaracteristicaSoloService";
import FormAnsCaracteristicaSolo from "./FormAnsCaracteristicaSolo";
import ListAnsCaracteristicaSolo from "./ListAnsCaracteristicaSolo";
import Permissao from "../../controllers/Permissao";

export default class Config {

	static getUrlForm() {
		return "/CadAnsCaracteristicaSolo";
	}

	static getUrlList() {
		return "/LstAnsCaracteristicaSolo";
	}

	static getComponentForm() {
		return FormAnsCaracteristicaSolo;
	}

	static getPermissionForm() {
		return Permissao.getInstance().getPermissoes([112]);
	}

	static getPermissionList() {
		return Permissao.getInstance().getPermissoes([112]);
	}

	static getComponentList() {
		return ListAnsCaracteristicaSolo;
	}

	static getService() {
		return AnsCaracteristicaSoloService;
	}

	static getKeyId() {
		return "idAnsCaracteristicaSolo";
	}

	static getKeyDescription() {
		return "nmAnsCaracteristicaSolo";
	}
}