import { Component } from "react";

export default class DropDownDivider extends Component {

    render() {
        if(this.props.show !== undefined && !this.props.show){
            return (<></>);
        }

        const props = Object.assign({}, this.props);
        props.className = `dropdown-divider ${props.className !== undefined ? props.className : ""}`;

        delete props.show;

        return (
            <li><hr {...props}/></li>
        );
    }
}