import { Component } from "react";
import TableResponsive from "./TableResponsive";

export default class Table extends Component {

    static sizing = {
        sm: "sm",
        lg: "lg",
        default: "",
    }

    static caption = {
        top: "top",
        bottom: "bottom",
        default: "",
    }

    render() {
        if(this.props.show !== undefined && !Boolean(this.props.show)){
            return (<></>);
        }

        const props = Object.assign({}, this.props);
        delete props.show;
        props.className = `table ${props.className ? props.className : ""}`;
        props.className+= props.sizing && props.sizing !== "" ? " table-" + props.sizing : "";
        props.className+= props.hover !== undefined && Boolean(props.hover) ? " table-hover" : "";        
        props.className+= props.striped !== undefined && Boolean(props.striped) ? " table-striped" : "";
        props.className+= props.bordered !== undefined && Boolean(props.bordered) ? " table-bordered" : "";
        props.className+= props.borderless !== undefined && Boolean(props.borderless) ? " table-borderless" : "";
        props.className+= props.caption !== "" ? " caption-" + props.caption : ""

        delete props.sizing;
        delete props.responsive;
        delete props.hover;
        delete props.striped;
        delete props.bordered;
        delete props.borderless;
        delete props.caption;

        if(this.props.responsive !== undefined && Boolean(this.props.responsive)){
            return (
                <TableResponsive sizing={TableResponsive.sizing.default}>
                    <table {...props}>{this.props.children}</table>
                </TableResponsive>
            );
        }

        return (
            <table {...props}>{this.props.children}</table>
        );
    }
}