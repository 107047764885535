import Config from "../../view/StatusOrdem/Config";
import SelectStatusOrdem from "./SelectStatusOrdem";

export default class SelectStatusOrdemByOrdem extends SelectStatusOrdem {

    getForComboService(){
        return Config.getService().getForComboByOrdem;
    }


    render() {
        return super.render();
    }
}