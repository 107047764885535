import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BaseComponent } from "../../siht/base";
import SelectTipoOrdem from "../../components/SelectDB/SelectTipoOrdem";
import SelectStatusOrdem from "../../components/SelectDBMultiple/SelectStatusOrdem";
import { FormItem } from "../../siht/components";

class FormItemTipoOrdemStatusOrdemMultiple extends BaseComponent {

	constructor(props) {
		super(props);
		this.init = this.init.bind(this);        
        this.handleChangeTipoOrdem = this.handleChangeTipoOrdem.bind(this);
        this.handleChangeStatusOrdem = this.handleChangeStatusOrdem.bind(this);
	}

	componentDidMount() {
		super.componentDidMount();
        this.init();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		
	}

	handleChangeTipoOrdem(e, v, p){

		var value =  {
			tipoOrdem : v ? v : {
			}
		};

		value.tipoOrdem.statusOrdem = [];
		
		this.props.onChange(e, value, this.props.name);
	}

	handleChangeStatusOrdem(e, values){
		var value = { 
			tipoOrdem : this.props.value?.tipoOrdem, 
			statusOrdem : values
		};

		this.props.onChange(e, value, this.props.name);		
	}

	render() {

		var propsFormItem = ["col","xs","sm","md","lg","xl","xxl"];
		var propsItem = ["required" , "buttons" , "label", "placeholder", "disabled", "opcional"];

		return super.render(
            <React.Fragment>
                <FormItem label="Tipo de Ordem" {...(this.getProps(propsFormItem,0))}>
                    <SelectTipoOrdem {...(this.getProps(propsItem,0))} name="tipoOrdem" value={this.props.value?.tipoOrdem} filter={{hasAccess : true}} onChange={this.handleChangeTipoOrdem} placeholder="Selecione o Tipo da Ordem"/>
                </FormItem>
                <FormItem label="Status da Ordem" {...(this.getProps(propsFormItem,1))}>
                    <SelectStatusOrdem rowCols={1} checkAll={true} search={true} {...(this.getProps(propsItem,1))} getOptionsWhen={f => f?.tipoOrdem?.idTipoOrdem !== undefined} filter={{tipoOrdem : this.props.value?.tipoOrdem, hasAccess : true}} name="statusOrdem" value={this.props.value?.statusOrdem} onChange={this.handleChangeStatusOrdem} placeholder="Selecione o Status da Ordem"/>
                </FormItem>
            </React.Fragment>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	return <FormItemTipoOrdemStatusOrdemMultiple {...props} navigate={navigate} params={params} />
}
export default With