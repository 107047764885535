import Config from "./Config";
import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import SelectConta from "../../components/SelectDB/SelectConta";
import SelectEndereco from "../../components/SelectDB/SelectEndereco";
import { BaseCrud } from "../../siht/base";
import { FormButtons, FormItem, Row, Panel, PanelBody, PanelFooter, Container, ModalDialog } from "../../siht/components";
import { InputText, InputNumber, InputDate, InputNumberFormat, Form } from "../../siht/elements";
import { BtnRadioGroupSimNao } from "../../components/Custom";

class FormFaturaCobrancaBoleto extends BaseCrud {

	constructor(props) {
		super(props);
		this.init = this.init.bind(this);
		this.initModel = this.initModel.bind(this);
		this.handleSuccess = this.handleSuccess.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleChangeModel = this.handleChangeModel.bind(this);
		this.handleSubmitForm = this.handleSubmitForm.bind(this);
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		super.init(Config);
	}

	initModel(){
		return {
			idFaturaCobrancaBoleto : null,
			faturaCobrancaTransacao : this.props.parent !== undefined ? this.props.parent : {},
			conta : null,
			endereco : null,
			nrDiaAgenda : null,
			dtVencimento : "",
			vlBoleto : 0,
			dtEmissao : "",
			cdNossoNumero : "",
			cdCodigoBarra : "",
			cdLinhaDigitavel : "",
			flRegistrar : true,
			flRegistrado : true,
			flCancelar : true,
			flCancelado : true,
			cdTipoJuros : "",
			cdTipoMulta : "",
			flJuros : true,
			flMulta : true,
			vlJuros : "",
			vlMulta : "",
			flProtesto : true,
			nrDiaProtesto : null,
			flNegativacao : true,
			nrDiaNegativacao : null,
			cdCarteira : ""
		}
	}

	handleSuccess(e) {
		super.handleSuccess(e, Config);
	}

	handleClose(e) {
		super.handleClose(e, Config);
	}

	render() {
		return super.render(

					<Form onSubmit={this.handleSuccess} refParam={f => (this.form = f)} key={this.state.key}>
						<Panel key={this.state.key} title="Cadastro de FaturaCobrancaBoleto" localOpen={this.props.localOpen}  onClose={this.handleClose} isModal={this.isModal()} container={{sizing: Container.sizing.xxl, className : ""}} modal={{sizing: this.props.sizing ? this.props.sizing : ModalDialog.sizing.xl}}>
							<PanelBody>
								<Row className="g-3 pb-2 px-2">
									<FormItem md={6} label="Código">
										<InputText name="idFaturaCobrancaBoleto" value={this.state.model.idFaturaCobrancaBoleto} readOnly={true} className="readonly-field" required={false} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={12} label="Nome do Conta">
										<SelectConta name="conta" value={this.state.model.conta} required={true} onChange={this.handleChangeModel} placeholder="Selecione Nome do Conta"/>
									</FormItem>
									<FormItem md={12} label="Nome do Endereco">
										<SelectEndereco name="endereco" value={this.state.model.endereco} required={true} onChange={this.handleChangeModel} placeholder="Selecione Nome do Endereco"/>
									</FormItem>
									<FormItem md={6} label="NrDiaAgenda">
										<InputNumber name="nrDiaAgenda" value={this.state.model.nrDiaAgenda} required={false} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={4} label="DtVencimento">
										<InputDate name="dtVencimento" value={this.state.model.dtVencimento} required={true} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={4} label="VlBoleto">
										<InputNumberFormat name="vlBoleto" value={this.state.model.vlBoleto} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} prefix="R$ " required={true} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={4} label="DtEmissao">
										<InputDate name="dtEmissao" value={this.state.model.dtEmissao} required={true} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={6} label="CdNossoNumero">
										<InputText name="cdNossoNumero" value={this.state.model.cdNossoNumero} maxLength={100} required={true} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={6} label="CdCodigoBarra">
										<InputText name="cdCodigoBarra" value={this.state.model.cdCodigoBarra} maxLength={100} required={true} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={6} label="CdLinhaDigitavel">
										<InputText name="cdLinhaDigitavel" value={this.state.model.cdLinhaDigitavel} maxLength={100} required={true} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={2} label="FlRegistrar">
										<BtnRadioGroupSimNao name="flRegistrar" value={this.state.model.flRegistrar} required={true} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={2} label="FlRegistrado">
										<BtnRadioGroupSimNao name="flRegistrado" value={this.state.model.flRegistrado} required={true} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={2} label="FlCancelar">
										<BtnRadioGroupSimNao name="flCancelar" value={this.state.model.flCancelar} required={true} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={2} label="FlCancelado">
										<BtnRadioGroupSimNao name="flCancelado" value={this.state.model.flCancelado} required={true} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={6} label="CdTipoJuros">
										<InputText name="cdTipoJuros" value={this.state.model.cdTipoJuros} maxLength={100} required={true} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={6} label="CdTipoMulta">
										<InputText name="cdTipoMulta" value={this.state.model.cdTipoMulta} maxLength={100} required={true} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={2} label="FlJuros">
										<BtnRadioGroupSimNao name="flJuros" value={this.state.model.flJuros} required={true} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={2} label="FlMulta">
										<BtnRadioGroupSimNao name="flMulta" value={this.state.model.flMulta} required={true} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={12} label="Juros">
										<InputText name="vlJuros" value={this.state.model.vlJuros} required={true} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={12} label="Multa">
										<InputText name="vlMulta" value={this.state.model.vlMulta} required={true} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={2} label="FlProtesto">
										<BtnRadioGroupSimNao name="flProtesto" value={this.state.model.flProtesto} required={true} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={6} label="NrDiaProtesto">
										<InputNumber name="nrDiaProtesto" value={this.state.model.nrDiaProtesto} required={false} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={2} label="FlNegativacao">
										<BtnRadioGroupSimNao name="flNegativacao" value={this.state.model.flNegativacao} required={true} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={6} label="NrDiaNegativacao">
										<InputNumber name="nrDiaNegativacao" value={this.state.model.nrDiaNegativacao} required={false} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={6} label="CdCarteira">
										<InputText name="cdCarteira" value={this.state.model.cdCarteira} maxLength={100} required={true} onChange={this.handleChangeModel}/>
									</FormItem>
								</Row>
							</PanelBody>
							<PanelFooter>
								<FormButtons onClose={this.handleClose} />
							</PanelFooter>
						</Panel>
					</Form>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <FormFaturaCobrancaBoleto {...props} navigate={navigate} params={params} location={location}/>
}
export default With