
import FormVeiculoCombustivel from "./FormVeiculoCombustivel.js";
import ListVeiculoCombustivel from "./ListVeiculoCombustivel.js";
import { Permissao } from "../../controllers";
import { Util } from "../../utils";
import { DadoValorService, VeiculoCombustivelService } from "../../services";

export default class Config {

	static getUrlForm() {
		return "/CadVeiculoCombustivel";
	}

	static getUrlList() {
		return "/LstVeiculoCombustivel";
	}

	static getComponentForm() {
		return FormVeiculoCombustivel;
	}

	static getPermissionForm() {
		return Permissao.getInstance().getPermissoes([181]);
	}

	static getPermissionList() {
		return Permissao.getInstance().getPermissoes([181]);
	}

	static getComponentList() {
		return ListVeiculoCombustivel;
	}

	static getService() {
		return VeiculoCombustivelService;
	}

	static getKeyId() {
		return "idVeiculoCombustivel";
	}

	static getKeyDescription() {
		return "nmVeiculoCombustivel";
	}

	static setDefaultValues(state , fnCallBack = () => {}, className = undefined) {
		className = className === undefined ? "FormVeiculoCombustivel" : className;
		let keyNameScreen = Util.toToUpperTextCamelCase(className, "_") + ".";
		var prefix = "CONFIGURACAO.";
		DadoValorService.getValues(`${prefix}${keyNameScreen}`, (response)=> {
			Util.setValueObjectCamelCase(state.model, response, keyNameScreen, prefix);
			fnCallBack();
		}, fnCallBack);
	}
}