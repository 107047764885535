import Http from "../controllers/Http";

export default class FuncionarioSalarioService {

	static urlBase = "/funcionarioSalario/";

	static getForCombo(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(FuncionarioSalarioService.urlBase + 'forCombo', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static list(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(FuncionarioSalarioService.urlBase + 'list', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneById(id, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.get(FuncionarioSalarioService.urlBase + id)
			.then(fnSucess)
			.catch(fnError);
	}

	static create(data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(FuncionarioSalarioService.urlBase, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static update(id, data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.put(FuncionarioSalarioService.urlBase + id, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static delete(id, fnSucess = () => { }, fnError = () => { }) {
		if(Number.isInteger(id)){
			Http.getInstanceAppJson()
				.delete(FuncionarioSalarioService.urlBase + id)
				.then(fnSucess)
				.catch(fnError);
		}else{
			Http.getInstanceAppJson()
			.post(FuncionarioSalarioService.urlBase + 'delete', id)
			.then(fnSucess)
			.catch(fnError);
		}
	}

	static save(data, fnSucess = () => { }, fnError = () => { }) {
		if (data.idFuncionarioSalario) {
			this.update(data.idFuncionarioSalario, data, fnSucess, fnError);
		} else {
			this.create(data, fnSucess, fnError);
		}
	}

	static getAllByFuncionario(funcionario, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(FuncionarioSalarioService.urlBase + 'funcionario', funcionario)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByFuncionario(funcionario, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(FuncionarioSalarioService.urlBase + 'funcionario/' + funcionario.idFuncionario)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByUsuario(usuario, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(FuncionarioSalarioService.urlBase + 'usuario', usuario)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByUsuario(usuario, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(FuncionarioSalarioService.urlBase + 'usuario/' + usuario.idUsuario)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByTipoEventoSalario(tipoEventoSalario, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(FuncionarioSalarioService.urlBase + 'tipoEventoSalario', tipoEventoSalario)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByTipoEventoSalario(tipoEventoSalario, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(FuncionarioSalarioService.urlBase + 'tipoEventoSalario/' + tipoEventoSalario.idTipoEventoSalario)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByTipoPagamentoSalario(tipoPagamentoSalario, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(FuncionarioSalarioService.urlBase + 'tipoPagamentoSalario', tipoPagamentoSalario)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByTipoPagamentoSalario(tipoPagamentoSalario, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(FuncionarioSalarioService.urlBase + 'tipoPagamentoSalario/' + tipoPagamentoSalario.idTipoPagamentoSalario)
			.then(fnSucess)
			.catch(fnError);
	}
}