
import FormEquipePessoa from "./FormEquipePessoa.js";
import ListEquipePessoa from "./ListEquipePessoa.js";
import { Permissao } from "../../controllers";
import { Util } from "../../utils";
import { DadoValorService, EquipePessoaService } from "../../services";

export default class Config {

	static getUrlForm() {
		return "/CadEquipePessoa";
	}

	static getUrlList() {
		return "/LstEquipePessoa";
	}

	static getComponentForm() {
		return FormEquipePessoa;
	}

	static getPermissionForm() {
		return Permissao.getInstance().getPermissoes([203]);
	}

	static getPermissionList() {
		return Permissao.getInstance().getPermissoes([203]);
	}

	static getComponentList() {
		return ListEquipePessoa;
	}

	static getService() {
		return EquipePessoaService;
	}

	static getKeyId() {
		return "idEquipePessoa";
	}

	static getKeyDescription() {
		return "nmEquipePessoa";
	}

	static setDefaultValues(state , fnCallBack = () => {}, className = undefined) {
		className = className === undefined ? "FormEquipePessoa" : className;
		let keyNameScreen = Util.toToUpperTextCamelCase(className, "_") + ".";
		var prefix = "CONFIGURACAO.";
		DadoValorService.getValues(`${prefix}${keyNameScreen}`, (response)=> {
			Util.setValueObjectCamelCase(state.model, response, keyNameScreen, prefix);
			fnCallBack();
		}, fnCallBack);
	}
}