//#NO-CHANGE-FILE#

import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import SelectPlugin from "../../components/SelectDB/SelectPlugin";
import SelectTipoNotaFiscal from "../../components/SelectDB/SelectTipoNotaFiscal";
import BtnRadioGroupSimNao from "../../components/BtnRadioGroupSimNao";
import { BaseCrud } from "../../siht/base";
import { FormButtons, FormItem, Row, Panel, PanelBody, PanelFooter, Container, ModalDialog, BtnRadioGroup } from "../../siht/components";
import { InputText, InputNumber, Form, BtnRadio } from "../../siht/elements";
import Config from "./Config";

class FormUnidadeTipoNotaFiscal extends BaseCrud {

	constructor(props) {
		super(props);
		this.init = this.init.bind(this);
		this.handleSuccess = this.handleSuccess.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleChangeModel = this.handleChangeModel.bind(this);
		this.handleSubmitForm = this.handleSubmitForm.bind(this);

		this.state.model = {
			idUnidadeTipoNotaFiscal : null,
			unidade : this.props.parent !== undefined ? this.props.parent : {},
			plugin : null,
			tipoNotaFiscal : null,
			flNumeracaoAutomatica : true,
			flProducao : true,
			flConsultaNacional : true,
			flEnviarEmail : true,
			flIbptAutomatico : true,
			flTemSubstituicao : true,
			flMultiServico : true,
			flAtivo : true,
			nrLote : "1",
			nrSerie : null,
			nrRps : null,
			cdTipoContrato : "NA"
		};
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		super.init(Config);
	}

	handleSuccess(e) {
		super.handleSuccess(e, Config);
	}

	handleClose(e) {
		super.handleClose(e, Config);
	}

	render() {
		return super.render(
			<Form onSubmit={this.handleSuccess} refParam={f => (this.form = f)} key={this.state.key}>
				<Panel key={this.state.key} title="Unidade x Tipo Nota Fiscal" onClose={this.handleClose} isModal={this.isModal()} container={{sizing: Container.sizing.xxl, className : ""}} modal={{sizing: this.props.sizing ? this.props.sizing : ModalDialog.sizing.xl}}>
					<PanelBody>
						<Row className="g-3 pb-2 px-2">
							<FormItem md={2} label="Código">
								<InputText name="idUnidadeTipoNotaFiscal" value={this.state.model.idUnidadeTipoNotaFiscal} readOnly={true} className="readonly-field text-center" required={false} onChange={this.handleChangeModel}/>
							</FormItem>

							<FormItem md={7} label="Tipo do Contrato?">
								<BtnRadioGroup name="cdTipoContrato" color={BtnRadioGroup.color.primary} opcional={true} value={this.state.model.cdTipoContrato} onClick={this.handleChangeModel}>
									<BtnRadio value="NA" label="Não se Aplica" />
									<BtnRadio value="BILHETAGEM" label="Bilhetagem" />
									<BtnRadio value="ILIMITADO" label="Ilimitado" />
								</BtnRadioGroup>
							</FormItem>

							<FormItem md={3} label="Ativo?" help="Indica se o documento está ativo para emissão. Para emissão de nota de serviço, ative esse documento.">
								<BtnRadioGroupSimNao name="flAtivo" value={this.state.model.flAtivo} required={true} onChange={this.handleChangeModel}/>
							</FormItem>
							
							<FormItem md={12} label="Plugin">
								<SelectPlugin name="plugin" filter={{cdFuncionalidadePlugin : "GATEWAY_NOTA_FISCAL"}} value={this.state.model.plugin} required={true} onChange={this.handleChangeModel} placeholder="Selecione Plugin" buttons={{search : false, new : false, edit: false}}/>
							</FormItem>
							<FormItem md={12} label="Tipo de Nota Fiscal">
								<SelectTipoNotaFiscal name="tipoNotaFiscal" value={this.state.model.tipoNotaFiscal} required={true} onChange={this.handleChangeModel} placeholder="Selecione o Tipo da Nota Fiscal" buttons={{search : false, new : false, edit: false}}/>
							</FormItem>

							<FormItem md={4} label="Ambiente?" help="Indica se a empresa irá emitir em produção ou homologação, se estiver desmarcado, aponta para homologação!">
								<BtnRadioGroupSimNao name="flProducao" value={this.state.model.flProducao} required={true} onChange={this.handleChangeModel} label={["Produção" , "Homologação"]}/>
							</FormItem>

							<FormItem md={4} label="Numeração Automática" help="Indica se haverá numeração automática de RPS (por padrão é ativo).">
								<BtnRadioGroupSimNao name="flNumeracaoAutomatica" value={this.state.model.flNumeracaoAutomatica} required={true} onChange={this.handleChangeModel}/>
							</FormItem>
							
							<FormItem md={4} label="Consulta Nacional" help="Indica se a consulta de DF-e no ambiente Nacional será realizada automaticamente pelo PlugNotas ou não.">
								<BtnRadioGroupSimNao name="flConsultaNacional" value={this.state.model.flConsultaNacional} required={true} onChange={this.handleChangeModel}/>
							</FormItem>
							<FormItem md={4} label="Enviar Email?" help="Habilita o envio de emails automaticamente, para todas as emissões realizadas por essa Empresa. Ao habilitar o envio automático de e-mails, será encaminhado também eventos como CC-e e cancelamentos.">
								<BtnRadioGroupSimNao name="flEnviarEmail" value={this.state.model.flEnviarEmail} required={true} onChange={this.handleChangeModel}/>
							</FormItem>
							<FormItem md={4} label="IBPT Automático" help="Habilita o cálculo automático do IBPT">
								<BtnRadioGroupSimNao name="flIbptAutomatico" value={this.state.model.flIbptAutomatico} required={true} onChange={this.handleChangeModel}/>
							</FormItem>
							<FormItem md={4} label="Tem Substituição de Nota" help="Flag que identifica se o orgão expedidor tem substituição de nota">
								<BtnRadioGroupSimNao name="flTemSubstituicao" value={this.state.model.flTemSubstituicao} required={true} onChange={this.handleChangeModel}/>
							</FormItem>
							<FormItem md={4} label="Aceita Multi Serviços" help="Flag que identifica se o orgão expedidor aceita multi serviços">
								<BtnRadioGroupSimNao name="flMultiServico" value={this.state.model.flMultiServico} required={true} onChange={this.handleChangeModel}/>
							</FormItem>
						</Row>
						<Row className="g-3 pb-2 px-2">
							<FormItem md={4} label="Lote">
								<InputNumber name="nrLote" value={this.state.model.nrLote} required={true} onChange={this.handleChangeModel}/>
							</FormItem>
							<FormItem md={4} label="Série">
								<InputText name="nrSerie" value={this.state.model.nrSerie} required={true} onChange={this.handleChangeModel}/>
							</FormItem>
							<FormItem md={4} label="Nota">
								<InputNumber name="nrRps" value={this.state.model.nrRps} required={true} onChange={this.handleChangeModel}/>
							</FormItem>
						</Row>
					</PanelBody>
					<PanelFooter>
						<FormButtons onClose={this.handleClose} />
					</PanelFooter>
				</Panel>
			</Form>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <FormUnidadeTipoNotaFiscal {...props} navigate={navigate} params={params} location={location}/>
}
export default With
