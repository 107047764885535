import ManyToManyCheckTipoPagamento from "../../components/ManyToManyCheck/ManyToManyCheckTipoPagamento";
import FaturaCobrancaTipoPagamentoService from "../../services/FaturaCobrancaTipoPagamentoService";

export default class ManyToManyCheckFaturaCobrancaTipoPagamento extends ManyToManyCheckTipoPagamento {

    constructor(props) {
        super(props);
        
        this.onChangeAddItem = this.onChangeAddItem.bind(this);
		this.onChangeDelItem = this.onChangeDelItem.bind(this);
		this.onChangeAddAll = this.onChangeAddAll.bind(this);
		this.onChangeDelAll = this.onChangeDelAll.bind(this);
        this.getSelecteds = this.getSelecteds.bind(this);

        this.state.selectedsOriginal = [];
    }

    componentDidMount() {
        super.componentDidMount();
        this.getSelecteds();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    componentDidUpdate(nextProps) {
        if (nextProps.parent !== undefined && this.props.parent !== undefined) {
            if (!this.objEqual(nextProps.parent, this.props.parent)) {
                this.getSelecteds();
            }
        }
    }
	

	getColumns(){
		return 1;
	}

    getSelecteds(){
        if(this.props?.parent?.idFaturaCobranca  || (Array.isArray(this.props?.parent) && this.props?.parent.length > 0)){
            FaturaCobrancaTipoPagamentoService.getAllByFaturaCobranca(this.props.parent, (response) => {
                let selecteds = response.flatMap(i => i.tipoPagamento);
				let noSelecteds = [];

				if(Array.isArray(this.props?.parent)){
					selecteds = selecteds.filter((value, index, self) => self.findIndex((v) => parseInt(v.idTipoPagamento) === parseInt(value.idTipoPagamento)) === index);
					selecteds.forEach((selected, index) => {
						let faturaCobrancas = response.filter(i=> parseInt(i.tipoPagamento.idTipoPagamento) === (selected.idTipoPagamento)).flatMap(i => i.faturaCobranca);
						if((faturaCobrancas.length !== this.props.parent.length)){
							noSelecteds.push(selected);
						}
					});

					selecteds = selecteds.filter(selected => !noSelecteds.find(ns => selected.idTipoPagamento === ns.idTipoPagamento));
				}

                this.setState({selecteds : selecteds, selectedsOriginal : response, noSelecteds : noSelecteds});
            });
        }
	}

    onChangeAddItem(e, item){     
		var parents = Array.isArray(this.props.parent) ? this.props.parent : [this.props.parent];

		parents.forEach(parent => {
			var selected = this.state.selectedsOriginal.find(i => parseInt(i.faturaCobranca.idFaturaCobranca) === parseInt(parent.idFaturaCobranca) && parseInt(i.tipoPagamento.idTipoPagamento) === parseInt(item.idTipoPagamento));
			if(!selected){
				FaturaCobrancaTipoPagamentoService.save({
					tipoPagamento : item,
					faturaCobranca : parent
				}, response =>{
					this.getSelecteds();
					super.onChangeAddItem(e, item);
				});
			}
		});
	};

	onChangeDelItem(e, item){
		var parents = Array.isArray(this.props.parent) ? this.props.parent : [this.props.parent];

		parents.forEach(parent => {			
			var selected = this.state.selectedsOriginal.find(i => parseInt(i.faturaCobranca.idFaturaCobranca) === parseInt(parent.idFaturaCobranca) && parseInt(i.tipoPagamento.idTipoPagamento) === parseInt(item.idTipoPagamento));
			if(selected){
				FaturaCobrancaTipoPagamentoService.delete(selected.idFaturaCobrancaTipoPagamento, response =>{
					this.getSelecteds();
					super.onChangeDelItem(e, item);
				});
			};
		});
	};

	onChangeAddAll(e, itens){
		var parents = Array.isArray(this.props.parent) ? this.props.parent : [this.props.parent];

		parents.forEach(parent => {
			var obj = itens.filter(item => 
				!this.state.selectedsOriginal.find(i => parseInt(i.faturaCobranca.idFaturaCobranca) === parseInt(parent.idFaturaCobranca) && parseInt(i.tipoPagamento.idTipoPagamento) === parseInt(item.idTipoPagamento))
			).map(item => {
				return {
					tipoPagamento : item,
					faturaCobranca : parent
				}
			});

			if(obj.length > 0){
				FaturaCobrancaTipoPagamentoService.save(obj, response =>{
					this.getSelecteds();
					super.onChangeAddAll(e, itens);
				});
			}
		});
	};

	onChangeDelAll(e, itens){
		var parents = Array.isArray(this.props.parent) ? this.props.parent : [this.props.parent];

		parents.forEach(parent => {
			var selecteds = this.state.selectedsOriginal.filter(i => parseInt(i.faturaCobranca.idFaturaCobranca) === parseInt(parent.idFaturaCobranca)).filter(i => itens.find(is => parseInt(i.tipoPagamento.idTipoPagamento) === parseInt(is.idTipoPagamento)));
			FaturaCobrancaTipoPagamentoService.delete(selecteds , response =>{
				this.getSelecteds();
				super.onChangeDelAll(e, itens);
			});
		});
	};

    render() {
        return super.render();
    }
}