import InputMask from "./InputMask";

export default class InputCardNumber extends InputMask {

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }


    handleChange(event) {
        //var value = event.target.value.toString().replace(/[^0-9]/g,'')

        /*if (value && value.length === 14 && !Functions.CNPJIsValid(value)) {
            event.target.setCustomValidity("CNPJ Inválido!");
        } else {
            event.target.setCustomValidity("");
        }*/

        super.handleChange(event);
    }

    render() {
        const props = {};
        props.mask = "9999 9999 9999 9999";
        //props.pattern = /\d{4} \d{4} \d{4} \d{4}/;
        props.placeholder = "0000 0000 0000 0000";

        return super.render(props);
    }
}