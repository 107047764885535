import React, { Component } from "react";
import { useNavigate, useParams } from "react-router";
import GoogleMapsMarker from "./GoogleMapsMarker";
import { Wrapper } from "@googlemaps/react-wrapper";
import _debounce from 'lodash';
import GoogleMapsPolyLine from "./GoogleMapsPolyLine";
import GoogleMapsRoute from "./GoogleMapsRoute";
import { Util } from "../../utils";

class GoogleMaps extends Component {

  constructor(props) {
    super(props);

    this.initMap = this.initMap.bind(this);
    this.setCenter = this.setCenter.bind(this);
    this.setZoom = this.setZoom.bind(this);
    this.addMarkers = this.addMarkers.bind(this);
    this.clearMarkers = this.clearMarkers.bind(this);
    this.clearPolylines = this.clearPolylines.bind(this);

    this.ref = React.createRef();
    this.map = null;
    this.directionsService = null;
    this.directionsRenderer = null;
    this.stepDisplay = null;

    this.markers = [];
    this.polylines = [];

    this.state = {
      lat: this.props.lat || "-15.802416514707035",
      lng: this.props.lng || "-47.90776318710937",
      zoom: this.props.zoom || 7
    };
  }

  componentDidUpdate(nextProps, prevState) {

    const oneMarker = this.markers.length === 1;

    if (prevState.lat !== this.props.lat || prevState.lng !== this.props.lng) {

      if (oneMarker) {
        var marker = this.markers[0];

        let lat = Util.isFunction(marker.position.lat) ? marker.position.lat().toString() : marker.position.lat.toString();
        let lng = Util.isFunction(marker.position.lng) ? marker.position.lng().toString() : marker.position.lng.toString();

        if (lat !== this.props.lat || lng !== this.props.lng) {
          this.setCenter(this.props.lat, this.props.lng);
          this.clearMarkers();
          this.clearPolylines();
          this.addMarkers();
        }

      } else {
        this.setCenter(this.props.lat, this.props.lng);
      }
    }

    if (prevState.zoom !== this.props.zoom) {
      this.setZoom(this.props.zoom);

      if (oneMarker) {
        this.setCenter(this.props.lat, this.props.lng);
      }
    }

    var markers1 = React.Children.toArray(this.props.children).filter(child => child.type === GoogleMapsMarker).map(child => child.props);
    var markers2 = React.Children.toArray(nextProps.children).filter(child => child.type === GoogleMapsMarker).map(child => child.props);

    var polylines1 = React.Children.toArray(this.props.children).filter(child => child.type === GoogleMapsPolyLine).map(child => child.props);
    var polylines2 = React.Children.toArray(nextProps.children).filter(child => child.type === GoogleMapsPolyLine).map(child => child.props);

    var route1 = React.Children.toArray(this.props.children).find(child => child.type === GoogleMapsRoute);
    var route2 = React.Children.toArray(nextProps.children).find(child => child.type === GoogleMapsRoute);

    if (!oneMarker && (!_debounce.isEqual(markers1, markers2) || !_debounce.isEqual(polylines1, polylines2))) {
      this.clearMarkers();
      this.clearPolylines();
      this.addMarkers();
    }

    if (route1 || route2) {
      if (!_debounce.isEqual(route1?.props, route2?.props)) {
        this.addRoute();
      }
    }

  }

  componentDidMount() {
    this.initMap();
  }

  initMap() {
    const latlng = { lat: parseFloat(this.state.lat), lng: parseFloat(this.state.lng) };

    this.map = new window.google.maps.Map(this.ref.current, {
      center: latlng,
      zoom: parseInt(this.state.zoom),
      mapId: this.props.apiKey
    });

    if (this.props.onZoomChange) {
      window.google.maps.event.addListener(this.map, 'zoom_changed', (e) => {
        this.props.onZoomChange(e, this.map, this.map.getZoom());
      });
    }

    this.directionsService = new window.google.maps.DirectionsService();
    this.stepDisplay = new window.google.maps.InfoWindow();

    this.addMarkers();
    this.addRoute();
  }

  addMarkers() {
    var markers = React.Children.toArray(this.props.children).filter(child => child.type === GoogleMapsMarker);
    var polylines = React.Children.toArray(this.props.children).filter(child => child.type === GoogleMapsPolyLine);
    const { AdvancedMarkerElement } = window.google.maps.marker;

    markers = [...markers, ...polylines.flatMap(p => p.props.markers)];

    markers.forEach(m => {
      let latlng = { lat: parseFloat(m.props.lat), lng: parseFloat(m.props.lng) };

      if (!latlng.lat || !latlng.lng) {
        return;
      }

      /*// Create a pin element.
      let pin = new PinElement({
        scale: 1.0,
        background : m.props.css?.background,
        borderColor : m.props.css?.borderColor,
        glyphColor : m.props.css?.glyphColor,        
      });

      const marker = new AdvancedMarkerElement({
          map : this.map,
          position: latlng,
          content: pin.element,
      });*/

      let marker = null;

      if (m.props.icon && (typeof m.props.icon === 'string' || m.props.icon instanceof String)) {

        let pin = document.createElement("div");
        pin.className = m.props.className;
        pin.innerHTML = `<img src='${m.props.icon}'>`;
        pin.style.backgroundColor = m.props.css?.background;
        pin.style.borderColor = m.props.css?.borderColor;
        pin.style.color = m.props.css?.background;

        marker = new AdvancedMarkerElement({
          map: this.map,
          position: latlng,
          content: pin,
        });

      } else {
        marker = new window.google.maps.Marker({
          position: latlng,
          map: this.map,
          draggable: m.props.draggable !== undefined ? m.props.draggable : false,
          title: m.props.title !== undefined && m.props.title.toString().length > 0 ? m.props.title.toString() : "",
          icon: m.props.icon !== undefined ? m.props.icon : "",
          zIndex: m.props.zIndex !== undefined ? m.props.zIndex : null,
        })
      }

      /*var map = this.map;
      marker.addListener("click", () => {
        infowindow.open({
          anchor: marker,
          map,
        });
      });*/

      if (m.props.ondragEnd) {
        marker.addListener("dragend", (e) => {
          m.props.ondragEnd(e, this.map, marker, {
            lat: Util.isFunction(marker.position.lat) ? marker.position.lat().toString() : marker.position.lat.toString(),
            lng: Util.isFunction(marker.position.lng) ? marker.position.lng().toString() : marker.position.lng.toString()
          });
        });
      }

      if (m.props.ondragStart) {
        marker.addListener("dragstart", (e) => {
          m.props.ondragStart(e, this.map, marker, {
            lat: Util.isFunction(marker.position.lat) ? marker.position.lat().toString() : marker.position.lat.toString(),
            lng: Util.isFunction(marker.position.lng) ? marker.position.lng().toString() : marker.position.lng.toString()
          });
        });
      }

      if (m.props.onClick) {
        marker.addListener("click", (e) => {
          m.props.onClick(e, this.map, marker, {
            lat: Util.isFunction(marker.position.lat) ? marker.position.lat().toString() : marker.position.lat.toString(),
            lng: Util.isFunction(marker.position.lng) ? marker.position.lng().toString() : marker.position.lng.toString()
          });
        });
      }

      this.markers.push(marker);
    });

    polylines.forEach((p, ix) => {

      let stroke = p.props.stroke !== undefined ? p.props.stroke : {};
      let coordinates = p.props.coordinates !== undefined ? p.props.coordinates : [];
      let geodesic = p.props.geodesic !== undefined ? p.props.geodesic : true;

      stroke.color = stroke?.color !== undefined ? stroke?.color : "#000000";
      stroke.opacity = stroke?.opacity !== undefined ? stroke?.opacity : 1.0;
      stroke.weight = stroke?.weight !== undefined ? stroke?.weight : 2;

      let polyline = new window.google.maps.Polyline({
        path: coordinates,
        geodesic: geodesic,
        strokeColor: stroke.color,
        strokeOpacity: stroke.opacity,
        strokeWeight: stroke.weight,
      });

      polyline.setMap(this.map);
      this.polylines.push(polyline);
    });
  }

  clearMarkers() {
    this.markers.forEach(marker => {
      marker.setMap(null);
    });

    this.markers = [];
  }

  clearPolylines() {
    this.polylines.forEach(polyline => {
      polyline.setMap(null);
    });

    this.polylines = [];
  }

  setCenter(lat, lng) {
    const latlng = { lat: parseFloat(lat), lng: parseFloat(lng) };

    if (isNaN(latlng.lat) || isNaN(latlng.lng)) {
      return;
    }

    this.setState({ lat: lat, lng: lng });

    if (this.map) {
      this.map.panTo(latlng);
    }
  }

  setZoom(zoom) {
    if (parseInt(zoom) >= 1 && parseInt(zoom) <= 22) {
      this.setState({ zoom: parseInt(zoom) });
      if (this.map) {
        this.map.setZoom(parseInt(zoom));
      }
    }
  }

  //https://developers.google.com/maps/documentation/javascript/examples/directions-complex
  addRoute() {

    var route = React.Children.toArray(this.props.children).find(child => child.type === GoogleMapsRoute);

    if (!route || !route.props.origin || !route.props.destination) {
      if (this.directionsRenderer) {
        this.directionsRenderer.setMap(null);
        this.directionsRenderer = null;
      }
      return;
    }

    if (!this.directionsRenderer) {
      this.directionsRenderer = new window.google.maps.DirectionsRenderer({ map: this.map });
    }

    let origin = `${route.props.origin.lat},${route.props.origin.lng}`;
    let destination = `${route.props.destination.lat},${route.props.destination.lng}`;
    let waypoints = route.props.waypoints !== undefined ? route.props.waypoints?.map(w => `${w.lat},${w.lng}`) : [];

    var waypts = [];
    for (let i = 0; i < waypoints.length; i++) {
      waypts.push({
        location: waypoints[i],
        stopover: true,
      });
    }

    //https://developers.google.com/maps/documentation/javascript/directions?hl=pt-br#TravelModes
    this.directionsService.route({
      origin: origin,
      destination: destination,
      travelMode: window.google.maps.TravelMode.DRIVING,
      waypoints: waypts,
      optimizeWaypoints: true,
    }).then((result) => {
      //document.getElementById("warnings-panel").innerHTML = "<b>" + result.routes[0].warnings + "</b>";
      this.directionsRenderer.setDirections(result);
      //console.log(result);

      //this.showSteps(result, markerArray, stepDisplay, map);
    })
      .catch((e) => {
        window.alert("Directions request failed due to " + e);
      });
  }

  /*showSteps(directionResult, markerArray, stepDisplay, map) {
    const myRoute = directionResult.routes[0].legs[0];
  
    for (let i = 0; i < myRoute.steps.length; i++) {
      const marker = (markerArray[i] =
        markerArray[i] || new window.google.maps.Marker());
  
      marker.setMap(map);
      marker.setPosition(myRoute.steps[i].start_location);
      this.attachInstructionText( stepDisplay, marker, myRoute.steps[i].instructions, map);
    }
  }
  
  attachInstructionText(stepDisplay, marker, text, map) {
    window.google.maps.event.addListener(marker, "click", () => {
      stepDisplay.setContent(text);
      stepDisplay.open(map, marker);
    });
  }*/


  render() {

    var style = {
      width: "100%",
    };

    if (this.props.width) {
      style.width = this.props.width;
    }

    if (this.props.height) {
      style.height = this.props.height;
    }

    return (
      <div ref={this.ref} style={style} className="h100-calc"></div>
    );
  }
}

function With(props) {
  let navigate = useNavigate();
  let params = useParams();

  return (
    <Wrapper apiKey={props.apiKey} libraries={["marker"]}>
      <GoogleMaps {...props} navigate={navigate} params={params} />
    </Wrapper>
  )
}
export default With
