//#NO-CHANGE-FILE#

import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { BaseCrud, BaseList } from "../../siht/base";
import { FormButtons, FormItem, Row, Panel, PanelBody, PanelFooter, Container, ModalDialog } from "../../siht/components";
import { Form, BtnButton, Div } from "../../siht/elements";
import Config from "./Config";
import { FormItemTipoPessoaPessoa } from "../../components/FormItem";
import FormPessoa from "../Pessoa/FormPessoa";
import BtnRadioGroupSimNao from "../../components/BtnRadioGroupSimNao";
import { Modal } from "../../siht/controller";

class FormFaturaNotaFiscalIntermediario extends BaseCrud {

	constructor(props) {
		super(props);
		this.init = this.init.bind(this);
		this.initModel = this.initModel.bind(this);
		this.handleSuccess = this.handleSuccess.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleChangeModel = this.handleChangeModel.bind(this);
		this.handleSubmitForm = this.handleSubmitForm.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		super.init(Config, () =>{
			Config.getService().getOneByFaturaNotaFiscal(this.props.parent, ( response )=>{
				this.setState({model : response});
			}, ()=>{
				this.setState({model : this.initModel()});
			});
		});
	}

	initModel(){
		return {
			flIntermediario : false,
			idFaturaNotaFiscalIntermediario : null,
			faturaNotaFiscal : this.props.parent,
			pessoa : null
		}
	}

	handleSuccess(e) {
		super.handleSuccess(e, Config);
	}

	handleClose(e) {
		super.handleClose(e, Config);
	}

	handleDelete(){
		Modal.confirm("Confirmação", "Deseja realmente remover o vínculo do Intermediário com a Nota Fiscal?", false, Modal.style.warning, ()=>{
			Config.getService().delete(this.state.model.idFaturaNotaFiscalIntermediario, () => {
				this.init();
			});
		});
	}

	render() {

		let editable = this.props.editable !== undefined ? this.props.editable : true;

		if(this.state.model?.idFaturaNotaFiscalIntermediario){
			return super.render(
				<>
					<FormPessoa editable={this.props.editable} key={this.state.key} id={this.state.model?.pessoa?.idPessoa} localOpen={BaseList.localOpen.embedded} handleSuccess={e => {}} handleClose={false}/>
					<Div className="px-3 py-3">
						<BtnButton color={BtnButton.color.outlineDanger} disabled={!editable} onClick={this.handleDelete}> Remover Vínculo do Intermediário </BtnButton>
					</Div>
				</>
			);
		}else{
			return super.render(
				<Form onSubmit={this.handleSuccess} refParam={f => (this.form = f)} key={this.state.key}>
					<Panel key={this.state.key} title="Cadastro de Intermediário" localOpen={this.props.localOpen}  onClose={this.handleClose} isModal={this.isModal()} container={{sizing: Container.sizing.xxl, className : ""}} modal={{sizing: this.props.sizing ? this.props.sizing : ModalDialog.sizing.xl}}>
						<PanelBody className="p-3">
							<Row className="g-3 pb-2 px-2">
								<FormItem md={2} label="Tem Intermediário?">
									<BtnRadioGroupSimNao disabled={!editable} name="flIntermediario" value={this.state.model.flIntermediario} required={true} onChange={(e,v,p) => this.handleChange(e,v,p, this.state.model)}/>
								</FormItem>
								<FormItemTipoPessoaPessoa disabled={!editable} name="pessoa" show={this.state.model.flIntermediario} label={["Tipo Pessoa", "Pessoa"]} md={[3,7]} value={this.state.model.pessoa} required={true} onChange={(e,v,p) => this.handleChange(e,v,p, this.state.model)}/>
							</Row>
						</PanelBody>
						<PanelFooter>
							<FormButtons disabled={[!editable || !this.state.model.flIntermediario]}/>
						</PanelFooter>
					</Panel>
				</Form>
			);
		}
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <FormFaturaNotaFiscalIntermediario {...props} navigate={navigate} params={params} location={location}/>
}
export default With