import { Navigate } from "react-router-dom";
import useAuth from "../controllers/Auth";

function RequireAuth({ children }) {

    //return children;
    return useAuth.isAuthenticated() === true
        ? children
        : <Navigate to="/Login" replace />;
}

export default RequireAuth;


