import Http from "../controllers/Http";

export default class ProdutoTipoOrdemService {

	static urlBase = "/produtoTipoOrdem/";

	static getForCombo(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(ProdutoTipoOrdemService.urlBase + 'forCombo', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static list(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(ProdutoTipoOrdemService.urlBase + 'list', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneById(id, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.get(ProdutoTipoOrdemService.urlBase + id)
			.then(fnSucess)
			.catch(fnError);
	}

	static create(data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(ProdutoTipoOrdemService.urlBase, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static update(id, data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.put(ProdutoTipoOrdemService.urlBase + id, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static delete(id, fnSucess = () => { }, fnError = () => { }) {
		if(Number.isInteger(id)){
			Http.getInstanceAppJson()
				.delete(ProdutoTipoOrdemService.urlBase + id)
				.then(fnSucess)
				.catch(fnError);
		}else{
			Http.getInstanceAppJson()
			.post(ProdutoTipoOrdemService.urlBase + 'delete', id)
			.then(fnSucess)
			.catch(fnError);
		}
	}

	static save(data, fnSucess = () => { }, fnError = () => { }) {
		if (data.idProdutoTipoOrdem) {
			this.update(data.idProdutoTipoOrdem, data, fnSucess, fnError);
		} else {
			this.create(data, fnSucess, fnError);
		}
	}

	static getAllByTipoOrdem(tipoOrdem, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(ProdutoTipoOrdemService.urlBase + 'tipoOrdem', tipoOrdem)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByTipoOrdem(tipoOrdem, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(ProdutoTipoOrdemService.urlBase + 'tipoOrdem/' + tipoOrdem.idTipoOrdem)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByProduto(produto, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(ProdutoTipoOrdemService.urlBase + 'produto', produto)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByProduto(produto, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(ProdutoTipoOrdemService.urlBase + 'produto/' + produto.idProduto)
			.then(fnSucess)
			.catch(fnError);
	}
}