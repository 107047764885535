import { Component } from "react";
import { toast } from "react-toastify";

export default class Form extends Component {

    constructor(props) {
        super(props);

        this.handleSubmit = this.handleSubmit.bind(this);

        this.state = {
            validated: false
        };
    }

    handleSubmit(e) {
        e.preventDefault();

        var form = e.currentTarget;

        if (form.checkValidity() === false) {
            this.setState({ validated: true });
            e.stopPropagation();
            toast.warning('Verifique os campos do formulário!');
            return;
        }

        if (this.props.onSubmit) {
            this.props.onSubmit(e);
            //this.setState({ validated: false });
        }
    }

    render() {
        if(this.props.show !== undefined && !Boolean(this.props.show)){
            return (<></>);
        }

        const props = Object.assign({}, this.props);
        props.onSubmit = this.handleSubmit;
        props.className = `needs-validation ${props.className ? props.className : ""} ${this.state.validated ? "was-validated" : ""}`;
        props.noValidate = true;
        props.ref = this.props.refParam ? this.props.refParam : this.ref;

        delete props.refParam;
        delete props.show;

        return (
            <form {...props}>
                {this.props.children}
            </form>
        );
    }
}