//#NO-CHANGE-FILE#

import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { BaseCrud } from "../../siht/base";
import { FormButtons, FormItem, Row, Panel, PanelBody, PanelFooter, Container, ModalDialog } from "../../siht/components";
import { InputPassword, Form } from "../../siht/elements";
import Config from "./Config";

class FormAlterarSenha extends BaseCrud {

	constructor(props) {
		super(props);
		this.init = this.init.bind(this);
		this.handleSuccess = this.handleSuccess.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleChangeModel = this.handleChangeModel.bind(this);
		this.handleSubmitForm = this.handleSubmitForm.bind(this);

		this.state.model = {
			dsSenhaAtual : "",
			dsSenha : "",
			dsConfirmacao : ""
		};
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		super.init(Config);
	}

	handleSuccess(e) {
		Config.getService().alterarSenha(this.state.model, (response) => {
			if(this.props.handleSuccess){
				this.props.handleSuccess(e, response);
			}
		});
	}

	handleClose(e) {
		super.handleClose(e, Config);
	}


	render() {
		return super.render(
				<Form onSubmit={this.handleSuccess} refParam={f => (this.form = f)} autoComplete="off">
					<Panel key={this.state.key} title="Alteração de Senha" onClose={this.handleClose} isModal={this.isModal()} container={{sizing: Container.sizing.xxl, className : ""}} modal={{sizing: this.props.sizing ? this.props.sizing : ModalDialog.sizing.xl}}>
						<PanelBody>
							<Row className="g-3 pb-2 px-2">
								<FormItem md={12} label="Senha Atual">
									<InputPassword autoComplete="new-password" placeholder="Senha" value={this.state.model.dsSenhaAtual} name="dsSenhaAtual" onChange={this.handleChangeModel} required={true}/>
								</FormItem>
								<FormItem md={12} label="Nova Senha">
									<InputPassword autoComplete="new-password" placeholder="Senha" value={this.state.model.dsSenha} name="dsSenha" onChange={this.handleChangeModel}
										validations={[
											{
												validation: this.state.model.dsConfirmacao === this.state.model.dsSenha,
												message: "Senha e Confirmação devem ser iguais"
											}
										]}
										required={true}/>
								</FormItem>
								<FormItem md={12} label="Confirmação">
									<InputPassword autoComplete="new-password" placeholder="Confirmação" col={6} value={this.state.model.dsConfirmacao} name="dsConfirmacao" onChange={this.handleChangeModel}
										validations={[
											{
												validation: this.state.model.dsConfirmacao === this.state.model.dsSenha,
												message: "Senha e Confirmação devem ser iguais"
											}
										]}
										required={true}/>
								</FormItem>
							</Row>
							
						</PanelBody>
						<PanelFooter>
							<FormButtons show={this.state.tabIndex === 0} onClose={this.handleClose} onSubmit={this.handleSubmitForm} label={"Alterar Senha"}/>
						</PanelFooter>
					</Panel>
					</Form>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <FormAlterarSenha {...props} navigate={navigate} params={params} location={location}/>
}
export default With