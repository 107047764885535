import { memo } from 'react';
import { Handle, Position, NodeResizer, useReactFlow } from '@xyflow/react';

const ResizableNodeSelected = (props) => {

  const reactFlow = useReactFlow();

  const setEditing = (editing) => {
    reactFlow.setNodes((nodes) =>
      nodes.map((node) => {
        if (node.id === props.id) {
          return {
            ...node,
            data: {
              ...node.data,
              editing: editing
            }
          };
        }
        return node;
      })
    );
  };

  const onChangeData = (event) => {
    reactFlow.setNodes((nodes) =>
      nodes.map((node) => {
        if (node.id === props.id) {
          return {
            ...node,
            data: {
              ...node.data,
              label: event.target.value
            }
          };
        }
        return node;
      })
    );
  };

  if (props.data?.editing === true) {
    return (<>
      <Handle type="target" position={props.targetPosition !== undefined ? props.targetPosition : Position.Left} />
      <input
        onChange={onChangeData}
        onBlur={() => setEditing(false)}
        value={props.data.label}
        autoFocus={true}
        style={{ width: "100%", border: "1px solid #dbdbdb" }}
      />
      <Handle type="source" position={props.sourcePosition !== undefined ? props.sourcePosition : Position.Right} />
    </>);
  }

  return (
    <>
      <NodeResizer
        color="#ff0071"
        isVisible={props.selected === undefined ? false : props.selected}
        minWidth={100}
        minHeight={30}
      />
      <Handle type="target" position={props.targetPosition !== undefined ? props.targetPosition : Position.Left} />
      <div style={{ padding: 10 }}>{props.data.label}</div>
      <Handle type="source" position={props.sourcePosition !== undefined ? props.sourcePosition : Position.Right} />
    </>
  );
};

export default memo(ResizableNodeSelected);