
import FormTipoDesconto from "./FormTipoDesconto.js";
import ListTipoDesconto from "./ListTipoDesconto.js";
import { Permissao } from "../../controllers";
import { Util } from "../../utils";
import { DadoValorService, TipoDescontoService } from "../../services";

export default class Config {

	static getUrlForm() {
		return "/CadTipoDesconto";
	}

	static getUrlList() {
		return "/LstTipoDesconto";
	}

	static getComponentForm() {
		return FormTipoDesconto;
	}

	static getPermissionForm() {
		return Permissao.getInstance().getPermissoes([169]);
	}

	static getPermissionList() {
		return Permissao.getInstance().getPermissoes([169]);
	}

	static getComponentList() {
		return ListTipoDesconto;
	}

	static getService() {
		return TipoDescontoService;
	}

	static getKeyId() {
		return "idTipoDesconto";
	}

	static getKeyDescription() {
		return "nmTipoDesconto";
	}

	static setDefaultValues(state , fnCallBack = () => {}, className = undefined) {
		className = className === undefined ? "FormTipoDesconto" : className;
		let keyNameScreen = Util.toToUpperTextCamelCase(className, "_") + ".";
		var prefix = "CONFIGURACAO.";
		DadoValorService.getValues(`${prefix}${keyNameScreen}`, (response)=> {
			Util.setValueObjectCamelCase(state.model, response, keyNameScreen, prefix);
			fnCallBack();
		}, fnCallBack);
	}
}