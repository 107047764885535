import { Functions } from "../util";
import InputMask from "./InputMask";

export default class InputCpf extends InputMask {
    
    constructor(props){
        super(props);
        
        this.options = {
            mask: '___.___.___-__',
            replacement: { _: /\d/ },
        };
    }

    handleChange(event) {
        var value = event.target.value.toString().replace(/[^0-9]/g,'')

        if (value && value.length === 11 && !Functions.CPFIsValid(value)) {
            event.target.setCustomValidity("CPF Inválido!");
        } else {
            event.target.setCustomValidity("");
        }

        super.handleChange(event);
    }

    render() {
        const props = {};
        props.pattern = "\\d{3}.\\d{3}.\\d{3}-\\d{2}";
        props.placeholder = "999.999.999-99";

        return super.render(props);
    }
}