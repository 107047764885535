
import FormPessoaConjuge from "./FormPessoaConjuge.js";
import ListPessoaConjuge from "./ListPessoaConjuge.js";
import { Permissao } from "../../controllers";
import { Util } from "../../utils";
import { DadoValorService, PessoaConjugeService } from "../../services";

export default class Config {

	static getUrlForm() {
		return "/CadPessoaConjuge";
	}

	static getUrlList() {
		return "/LstPessoaConjuge";
	}

	static getComponentForm() {
		return FormPessoaConjuge;
	}

	static getPermissionForm() {
		return Permissao.getInstance().getPermissoes([228]);
	}

	static getPermissionList() {
		return Permissao.getInstance().getPermissoes([228]);
	}

	static getComponentList() {
		return ListPessoaConjuge;
	}

	static getService() {
		return PessoaConjugeService;
	}

	static getKeyId() {
		return "idPessoaConjuge";
	}

	static getKeyDescription() {
		return "nmPessoaConjuge";
	}

	static setDefaultValues(state , fnCallBack = () => {}, className = undefined) {
		className = className === undefined ? "FormPessoaConjuge" : className;
		let keyNameScreen = Util.toToUpperTextCamelCase(className, "_") + ".";
		var prefix = "CONFIGURACAO.";
		DadoValorService.getValues(`${prefix}${keyNameScreen}`, (response)=> {
			Util.setValueObjectCamelCase(state.model, response, keyNameScreen, prefix);
			fnCallBack();
		}, fnCallBack);
	}
}