//#NO-CHANGE-FILE#

import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import ListDadoLocalGrupo from "../DadoLocalGrupo/ListDadoLocalGrupo";
import Permissao from "../../controllers/Permissao";
import { BaseList, BaseCrud } from "../../siht/base";
import { FormButtons, FormItem, Row, TabNavigator, Tab, Panel, PanelBody, PanelFooter, Container } from "../../siht/components";
import { InputText, Form, BtnSubmit } from "../../siht/elements";
import Config from "./Config";

class FormDadoLocal extends BaseCrud {

	constructor(props) {
		super(props);
		this.init = this.init.bind(this);
		this.initModel = this.initModel.bind(this);
		this.handleSuccess = this.handleSuccess.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleChangeModel = this.handleChangeModel.bind(this);
		this.handleSubmitForm = this.handleSubmitForm.bind(this);
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		super.init(Config);
	}

	initModel(){
		return {
			idDadoLocal : null,
			nmDadoLocal : "",
			cdDadoLocal : "",
			dadoLocalGrupo : []
		}
	}

	handleSuccess(e) {
		super.handleSuccess(e, Config);
	}

	handleClose(e) {
		super.handleClose(e, Config);
	}

	render() {
		return super.render(
				<Panel key={this.state.key} title="Cadastro de Local do Dado" onClose={this.handleClose} isModal={this.isModal()} container={{sizing: Container.sizing.xxl, className : ""}}>
					<PanelBody className="p-0 m-0">
						<TabNavigator key={this.state.key} onChangeTab={this.handleChangeTab} className="border-light">
							<Tab title="Local do Dado" className="m-2">
								<Form onSubmit={this.handleSuccess} refParam={f => (this.form = f)}>
									<Row className="g-3 pb-2 px-2">
										<FormItem md={6} label="Código">
											<InputText name="idDadoLocal" value={this.state.model.idDadoLocal} readOnly={true} className="readonly-field" required={false} onChange={this.handleChangeModel}/>
										</FormItem>
										<FormItem md={12} label="Nome da Local">
											<InputText name="nmDadoLocal" value={this.state.model.nmDadoLocal} autoFocus={true} maxLength={100} required={true} onChange={this.handleChangeModel}/>
										</FormItem>
										<FormItem md={6} label="Identificador">
											<InputText name="cdDadoLocal" value={this.state.model.cdDadoLocal} maxLength={100} required={true} onChange={this.handleChangeModel}/>
										</FormItem>
										<BtnSubmit hidden > Enviar </BtnSubmit>
									</Row>
								</Form>
							</Tab>
							<Tab show={Permissao.getInstance().getPermissoes([122])} title="Grupos" disabled={!this.state.model.idDadoLocal} opened={false} className="m-2">
								<ListDadoLocalGrupo parent={this.state.model} localOpen={BaseList.localOpen.modal}/>
							</Tab>
						</TabNavigator>
					</PanelBody>
					<PanelFooter>
						<FormButtons show={this.state.tabIndex === 0} onClose={this.handleClose} onSubmit={this.handleSubmitForm}/>
					</PanelFooter>
				</Panel>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <FormDadoLocal {...props} navigate={navigate} params={params} location={location}/>
}
export default With