
import FormDado from "./FormDado.js";
import ListDado from "./ListDado.js";
import { Permissao } from "../../controllers";
import { Util } from "../../utils";
import { DadoValorService, DadoService } from "../../services";

export default class Config {

	static getUrlForm() {
		return "/CadDado";
	}

	static getUrlList() {
		return "/LstDado";
	}

	static getComponentForm() {
		return FormDado;
	}

	static getPermissionForm() {
		return Permissao.getInstance().getPermissoes([120]);
	}

	static getPermissionList() {
		return Permissao.getInstance().getPermissoes([120]);
	}

	static getComponentList() {
		return ListDado;
	}

	static getService() {
		return DadoService;
	}

	static getKeyId() {
		return "idDado";
	}

	static getKeyDescription() {
		return "nmDado";
	}

	static setDefaultValues(state , fnCallBack = () => {}, className = undefined) {
		className = className === undefined ? "FormDado" : className;
		let keyNameScreen = Util.toToUpperTextCamelCase(className, "_") + ".";
		var prefix = "CONFIGURACAO.";
		DadoValorService.getValues(`${prefix}${keyNameScreen}`, (response)=> {
			Util.setValueObjectCamelCase(state.model, response, keyNameScreen, prefix);
			fnCallBack();
		}, fnCallBack);
	}
}