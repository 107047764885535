import React, { Component } from "react";
import PanelHeader from "./PanelHeader";
import PanelBody from "./PanelBody";
import PanelFooter from "./PanelFooter";
import ModalHeader from "./ModalHeader";
import ModalContent from "./ModalContent";
import ModalDialog from "./ModalDialog";
import Card from "./Card";
import CardBody from "./CardBody";
import CardHeader from "./CardHeader";
import ModalBody from "./ModalBody";
import ModalFooter from "./ModalFooter";
import CardFooter from "./CardFooter";
import Container from "./Container";
import { Div } from "../elements";
import PanelSubHeader from "./PanelSubHeader";
import { BaseCrud } from "../base";

export default class Panel extends Component {

    static sizing = {
        xs : "modal-xs",
        sm : "modal-sm",
        md : "modal-md",
        lg : "modal-lg",
        xl : "modal-xl",
        xxl : "modal-xxl",
        default : "modal-lg"
    }
    
    static border = {
        primary : "primary",
        dark : "dark"
    };

    render() {
        if(this.props.show !== undefined && !Boolean(this.props.show)){
            return (<></>);
        }

        var isModal = this.props.isModal !== undefined && this.props.isModal;
        
        const props = Object.assign({}, this.props);
        props.className = `${props.className !== undefined ? props.className : ""} ${props.border !== undefined ? "border-" + props.border : ""}`;

        var panelHeader = React.Children.toArray(this.props.children).find(child => child.type === PanelHeader);
        var panelSubHeader = React.Children.toArray(this.props.children).find(child => child.type === PanelSubHeader);
        var panelBody = React.Children.toArray(this.props.children).find(child => child.type === PanelBody);
        var panelFooter = React.Children.toArray(this.props.children).find(child => child.type === PanelFooter);        

        if(!panelBody){
            var ch = this.props.children;
            
            if(this.props.children && this.props.children.length > 0){
                ch = this.props?.children?.filter(child => child?.type !== PanelFooter && child?.type !== PanelHeader);
            }

            panelBody = ( <CardBody>{ch}</CardBody>);
        }

        if(!panelHeader){
            panelHeader = {
                props : {
                    title : this.props.title,
                    onClose : this.props.onClose
                }
            };
        }

        delete props.localOpen;
        delete props.isModal;
        delete props.onClose;
        delete props.border;
        delete props.show;
        delete props.title;
        delete props.container;

        if(isModal){

            if(this.props?.modal?.sizing){
                props.sizing = this.props?.modal?.sizing;
            }

            if(this.props?.modal?.scrollable !== undefined){
                props.scrollable = this.props?.modal?.scrollable;
            }
            
            return (                
                <ModalDialog {...props}>
                    <ModalContent>
                        {panelHeader.props?.children ? <ModalHeader {...panelHeader.props}> {panelHeader.props?.children} </ModalHeader> : <ModalHeader {...panelHeader.props}/>}
                        {panelSubHeader ? <Div {...panelSubHeader.props}> {panelSubHeader.props?.children} </Div> : <></>}
                        {panelBody ? <ModalBody {...panelBody.props}>{panelBody.props.children}</ModalBody> : <></>}
                        {panelFooter ? <ModalFooter {...panelFooter.props}>{panelFooter.props.children}</ModalFooter> : <></>}
                    </ModalContent>
                </ModalDialog>
            );
        }else if(this.props.localOpen !== undefined && this.props.localOpen === BaseCrud.localOpen.embedded){
            let propsFooter = Object.assign({}, panelFooter.props);
            propsFooter.className = `p-3 ${propsFooter.className !== undefined ? propsFooter.className : ""}`;
            
            return (<>
                        {panelBody ? <Div {...panelBody.props}>{panelBody.props.children}</Div> : <></>}
                        {panelFooter ? <Div {...propsFooter}>{panelFooter.props.children}</Div> : <></>}
                </>);
        }else {
            if(this.props.container){
                return (
                    <Container {...this.props.container}>
                        <Card {...props}>                            
                            {panelHeader.props?.children ? <CardHeader {...panelHeader.props}> {panelHeader.props?.children} </CardHeader> : <CardHeader {...panelHeader.props}/>}
                            {panelSubHeader ? <Div {...panelSubHeader.props}> {panelSubHeader.props?.children} </Div> : <></>}
                            {panelBody ? <CardBody {...panelBody.props}>{panelBody.props.children}</CardBody> : <></>}
                            {panelFooter ? <CardFooter {...panelFooter.props}>{panelFooter.props.children}</CardFooter> : <></>}
                        </Card>
                    </Container>
                );
            }else{
                return (
                    <Card {...props}>
                        {panelHeader.props?.children ? <CardHeader {...panelHeader.props}> {panelHeader.props?.children} </CardHeader> : <CardHeader {...panelHeader.props}/>}
                        {panelSubHeader ? <Div {...panelSubHeader.props}> {panelSubHeader.props?.children} </Div> : <></>}
                        {panelBody ? <CardBody {...panelBody.props}>{panelBody.props.children}</CardBody> : <></>}
                        {panelFooter ? <CardFooter {...panelFooter.props}>{panelFooter.props.children}</CardFooter> : <></>}
                    </Card>
                );
            }            
        }
    }
}