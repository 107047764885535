//#NO-CHANGE-FILE#

import Http from "../controllers/Http";

export default class AnsPerfilService {

	static urlBase = "/ansPerfil/";

	static duplicar(data, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(AnsPerfilService.urlBase + 'duplicar', data)
			.then(fnSucess)
			.catch(fnError);
	}

	static getSvgById(id, pagina,  fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.get(AnsPerfilService.urlBase + 'svg/' + id + '/' + pagina)
			.then(fnSucess)
			.catch(fnError);
	}

	static getSvgByMapa(idOrdem,  fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.get(AnsPerfilService.urlBase + 'svgMapa/' + idOrdem)
			.then(fnSucess)
			.catch(fnError);
	}

	static alterarStatus(ordem, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(AnsPerfilService.urlBase + 'alterarStatus', ordem)
			.then(fnSucess)
			.catch(fnError);
	}

	static listMapa(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(AnsPerfilService.urlBase + 'listMapa', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static getForCombo(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(AnsPerfilService.urlBase + 'forCombo', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static list(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(AnsPerfilService.urlBase + 'list', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneById(id, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.get(AnsPerfilService.urlBase + id)
			.then(fnSucess)
			.catch(fnError);
	}

	static create(data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(AnsPerfilService.urlBase, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static update(id, data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.put(AnsPerfilService.urlBase + id, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static delete(id, fnSucess = () => { }, fnError = () => { }) {
		if(Number.isInteger(id)){
			Http.getInstanceAppJson()
				.delete(AnsPerfilService.urlBase + id)
				.then(fnSucess)
				.catch(fnError);
		}else{
			Http.getInstanceAppJson()
			.post(AnsPerfilService.urlBase + 'delete', id)
			.then(fnSucess)
			.catch(fnError);
		}
	}

	static save(data, fnSucess = () => { }, fnError = () => { }) {
		if (data.idAnsPerfil) {
			this.update(data.idAnsPerfil, data, fnSucess, fnError);
		} else {
			this.create(data, fnSucess, fnError);
		}
	}

	static getAllByOrdem(ordem, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(AnsPerfilService.urlBase + 'ordem', ordem)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByOrdem(ordem, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(AnsPerfilService.urlBase + 'ordem/' + ordem.idOrdem)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByEndereco(endereco, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(AnsPerfilService.urlBase + 'endereco', endereco)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByEndereco(endereco, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(AnsPerfilService.urlBase + 'endereco/' + endereco.idEndereco)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByAnsStatus(ansStatus, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(AnsPerfilService.urlBase + 'ansStatus', ansStatus)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByAnsStatus(ansStatus, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(AnsPerfilService.urlBase + 'ansStatus/' + ansStatus.idAnsStatus)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByAnsPerfilAvanco(ansPerfilAvanco, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(AnsPerfilService.urlBase + 'ansPerfilAvanco', ansPerfilAvanco)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByAnsPerfilAvanco(ansPerfilAvanco, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(AnsPerfilService.urlBase + 'ansPerfilAvanco/' + ansPerfilAvanco.idAnsPerfilAvanco)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByAnsPerfilGolpe(ansPerfilGolpe, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(AnsPerfilService.urlBase + 'ansPerfilGolpe', ansPerfilGolpe)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByAnsPerfilGolpe(ansPerfilGolpe, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(AnsPerfilService.urlBase + 'ansPerfilGolpe/' + ansPerfilGolpe.idAnsPerfilGolpe)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByAnsPerfilClassificacao(ansPerfilClassificacao, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(AnsPerfilService.urlBase + 'ansPerfilClassificacao', ansPerfilClassificacao)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByAnsPerfilClassificacao(ansPerfilClassificacao, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(AnsPerfilService.urlBase + 'ansPerfilClassificacao/' + ansPerfilClassificacao.idAnsPerfilClassificacao)
			.then(fnSucess)
			.catch(fnError);
	}
}