import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { BaseComponent } from "../../siht/base";
import Atalhos from "./Atalhos";
import { Div, Span } from "../../siht/elements";
import { Tab, TabNavigator } from "../../siht/components";
import Menu  from "../Menu";
import ConfigApp from "../../ConfigApp";
import Dasboard from "./Dashboard";
import Permissao from "../../controllers/Permissao";
import UsuarioBlocoFavoritoService from "../../services/UsuarioBlocoFavoritoService";

class Home extends BaseComponent {

    constructor(props) {
        super(props);
        this.state = {
            usuarioBlocoFavoritos : []
        };
    }

    componentDidMount() {
        super.componentDidMount();
        this.getUsuarioBlocoFavoritos();

    }

    getUsuarioBlocoFavoritos(){
        if(Permissao.getInstance().getPermissoes([100])){
            UsuarioBlocoFavoritoService.list((response => {
                this.setState({usuarioBlocoFavoritos : response});
            }));
        }
    }

    

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    render() {
        return (
            <Div className="p-0 my-0">
                <TabNavigator className="border-light">
                    {this.state.usuarioBlocoFavoritos.length > 0 ?
                        <Tab title="Dashboard" className="m-2">
                            <Dasboard/>
                        </Tab>
                    : <></>}
                    <Tab title="Rápidos" className="m-2">
                        <Atalhos flRapido={true}/>
                    </Tab>
                    <Tab title="Todos" className="m-2">
                        <Atalhos/>
                    </Tab>
                    <Tab title="Busca" className="m-2">
                        <Div className="container px-5 py-3">
                            <Menu/>
                        </Div>
                    </Tab>
                </TabNavigator>
                <Div className="px-3 text-end">
                    <Span className="badge bg-primary my-3">Versão: {parseFloat(ConfigApp.version).toFixed(2)}</Span>
                </Div>
            </Div>
        )
    }
}

function With(props) {
    let navigate = useNavigate();
    let params = useParams();
    let location = useLocation();
    return <Home {...props} navigate={navigate} params={params} location={location} />
}

export default With