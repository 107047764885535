import Http from "../controllers/Http";

export default class VeiculoResumoService {

	static urlBase = "/veiculoResumo/";

	static getForCombo(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(VeiculoResumoService.urlBase + 'forCombo', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static list(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(VeiculoResumoService.urlBase + 'list', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneById(id, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.get(VeiculoResumoService.urlBase + id)
			.then(fnSucess)
			.catch(fnError);
	}

	static create(data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(VeiculoResumoService.urlBase, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static update(id, data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.put(VeiculoResumoService.urlBase + id, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static delete(id, fnSucess = () => { }, fnError = () => { }) {
		if(Number.isInteger(id)){
			Http.getInstanceAppJson()
				.delete(VeiculoResumoService.urlBase + id)
				.then(fnSucess)
				.catch(fnError);
		}else{
			Http.getInstanceAppJson()
			.post(VeiculoResumoService.urlBase + 'delete', id)
			.then(fnSucess)
			.catch(fnError);
		}
	}

	static save(data, fnSucess = () => { }, fnError = () => { }) {
		if (data.idVeiculoResumo) {
			this.update(data.idVeiculoResumo, data, fnSucess, fnError);
		} else {
			this.create(data, fnSucess, fnError);
		}
	}

	static getAllByVeiculo(veiculo, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(VeiculoResumoService.urlBase + 'veiculo', veiculo)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByVeiculo(veiculo, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(VeiculoResumoService.urlBase + 'veiculo/' + veiculo.idVeiculo)
			.then(fnSucess)
			.catch(fnError);
	}
}