//#NO-CHANGE-FILE#
import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import SelectFusoHorario from "../../components/SelectDB/SelectFusoHorario";
import { BaseCrud } from "../../siht/base";
import { FormButtons, FormItem, Row, Panel, PanelBody, PanelFooter, Container } from "../../siht/components";
import { InputText, Div, Form } from "../../siht/elements";
import Config from "./Config";
import BtnRadioGroupSimNao from "../../components/BtnRadioGroupSimNao";
import { FormItemLocalizacao, FormItemPaisEstado } from "../../components/FormItem";

class FormCidade extends BaseCrud {

	constructor(props) {
		super(props);
		this.init = this.init.bind(this);
		this.handleSuccess = this.handleSuccess.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleChangeModel = this.handleChangeModel.bind(this);
		this.handleSubmitForm = this.handleSubmitForm.bind(this);

		this.state.model = {
			idCidade : null,
			nmCidade : "",
			nrDdd : "",
			localizacao :null,
			cdIbge : "",
			estado : null,
			fusoHorario : null,
			flPadrao : true
		};
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		super.init(Config);
	}

	handleSuccess(e) {
		super.handleSuccess(e, Config);
	}

	handleClose(e) {
		super.handleClose(e, Config);
	}
	
	render() {
		return super.render(
				<Div key={this.state.key}>
					<Form onSubmit={this.handleSuccess} refParam={f => (this.form = f)}>
						<Panel title="Cadastro de Cidade" onClose={this.handleClose} isModal={this.isModal()} container={{sizing: Container.sizing.xxl, className : ""}}>
							<PanelBody>
								<Row className="g-3 pb-2 px-2">
									<FormItem md={6} label="Código">
										<InputText name="idCidade" value={this.state.model.idCidade} readOnly={true} className="readonly-field" required={false} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={12} label="Nome da Cidade">
										<InputText name="nmCidade" value={this.state.model.nmCidade} autoFocus={true} maxLength={100} required={true} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={6} label="NrDdd">
										<InputText name="nrDdd" value={this.state.model.nrDdd} maxLength={100} required={true} onChange={this.handleChangeModel}/>
									</FormItem>

									<FormItemPaisEstado md={[6,6]} name="estado" value={this.state.model.estado} required={true} onChange={this.handleChangeModel}/>

									<FormItemLocalizacao md={6} name="localizacao" value={this.state.model.localizacao} required={true} onChange={this.handleChangeModel} placeholder="Selecione a Localizacao"/>
									
									<FormItem md={6} label="CdIbge">
										<InputText name="cdIbge" value={this.state.model.cdIbge} maxLength={100} required={true} onChange={this.handleChangeModel}/>
									</FormItem>
									
									<FormItem md={12} label="Nome do FusoHorario">
										<SelectFusoHorario name="fusoHorario" value={this.state.model.fusoHorario} required={true} onChange={this.handleChangeModel} placeholder="Selecione Nome do FusoHorario"/>
									</FormItem>

									<FormItem md={2} label="FlPadrao">
										<BtnRadioGroupSimNao name="flPadrao" value={this.state.model.flPadrao} required={true} onChange={this.handleChangeModel}/>
									</FormItem>
								</Row>
							</PanelBody>
							<PanelFooter>
								<FormButtons onClose={this.handleClose} />
							</PanelFooter>
						</Panel>
					</Form>
				</Div>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <FormCidade {...props} navigate={navigate} params={params} location={location}/>
}
export default With