import Http from "../controllers/Http";

export default class ContaReceberParcelaService {

	static urlBase = "/contaReceberParcela/";

	static getForCombo(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(ContaReceberParcelaService.urlBase + 'forCombo', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static list(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(ContaReceberParcelaService.urlBase + 'list', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneById(id, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.get(ContaReceberParcelaService.urlBase + id)
			.then(fnSucess)
			.catch(fnError);
	}

	static create(data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(ContaReceberParcelaService.urlBase, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static update(id, data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.put(ContaReceberParcelaService.urlBase + id, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static delete(id, fnSucess = () => { }, fnError = () => { }) {
		if(Number.isInteger(id)){
			Http.getInstanceAppJson()
				.delete(ContaReceberParcelaService.urlBase + id)
				.then(fnSucess)
				.catch(fnError);
		}else{
			Http.getInstanceAppJson()
			.post(ContaReceberParcelaService.urlBase + 'delete', id)
			.then(fnSucess)
			.catch(fnError);
		}
	}

	static save(data, fnSucess = () => { }, fnError = () => { }) {
		if (data.idContaReceberParcela) {
			this.update(data.idContaReceberParcela, data, fnSucess, fnError);
		} else {
			this.create(data, fnSucess, fnError);
		}
	}

	static getAllByVwContaReceberParcela(vwContaReceberParcela, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(ContaReceberParcelaService.urlBase + 'vwContaReceberParcela', vwContaReceberParcela)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByVwContaReceberParcela(vwContaReceberParcela, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(ContaReceberParcelaService.urlBase + 'vwContaReceberParcela/' + vwContaReceberParcela.idVwContaReceberParcela)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByContaReceber(contaReceber, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(ContaReceberParcelaService.urlBase + 'contaReceber', contaReceber)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByContaReceber(contaReceber, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(ContaReceberParcelaService.urlBase + 'contaReceber/' + contaReceber.idContaReceber)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByFaturaCobranca(faturaCobranca, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(ContaReceberParcelaService.urlBase + 'faturaCobranca', faturaCobranca)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByFaturaCobranca(faturaCobranca, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(ContaReceberParcelaService.urlBase + 'faturaCobranca/' + faturaCobranca.idFaturaCobranca)
			.then(fnSucess)
			.catch(fnError);
	}
}