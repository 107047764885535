import React from "react";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { BaseCrud } from "../siht/base";
import { Div, InputSearch, Span } from "../siht/elements";
import Util from "../utils/Util";
import { Accordion, AccordionItem, InputGroup, InputGroupText } from "../siht/components";
import IconSearch from "../icons/IconSearch";
import Tree from "./Tree";

class Menu extends BaseCrud {

	constructor(props) {
		super(props);
		this.init = this.init.bind(this);
		this.handleChangeModel = this.handleChangeModel.bind(this);

		this.state.model = {
			search : ""
		};
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		
	}

	handleClose(e, url) {
		super.handleClose(e);
	}

	getResultFilter(tree) {
        return tree.filter(f =>
            Util.searchTextInObject(f, ["label","description","detail"], this.state.search)
        );
    }

	render() {
		return (
				<Div>
					<Div className="pb-2 pt-2">
						<InputGroup>
							<InputGroupText>
								<IconSearch/>
							</InputGroupText>
							<InputSearch placeholder="Digite sua busca" sizing={InputSearch.sizing.lg} autoFocus={true} name="search" value={this.state.model.search} onChange={this.handleChangeModel}/>
						</InputGroup>
					</Div>					

					<Accordion flush={false} alwaysOpen={true} className="mt-3">

					{Util.arrayOrderBy(Tree.getMenu(), "label").map((menu, indexMenu) => {
						var tree = this.getResultFilter(Util.arrayOrderBy(menu.tree, "label"));
						let Icon = menu?.icon;
						
						if(tree.length === 0){
							return <React.Fragment key={indexMenu}></React.Fragment>;
						}

						let itens = Util.arrayOrderBy(tree, "label").filter(item => item.permission === true);

						if(itens.length === 0){
							return <React.Fragment key={indexMenu}></React.Fragment>;
						}

						return (
							<AccordionItem className="p-1" open={true} key={indexMenu} label={<Span>{Icon ? <><Icon item={menu}/>&nbsp;</> : <></>}{menu.label} <strong>({itens.length})</strong></Span>}>
								<Div className="list-group list-group-flush">
									{itens.map((item, indexItem) => {
										let Icon = item?.icon;
										return(
											<Link className="list-group-item list-group-item-action" to={item.to} key={indexItem} onClick={e => this.handleClose(e, item.to)}>
												<div className="d-flex w-100 justify-content-between">
													<h6 className="mb-1">
														{Icon ? <><Icon item={item}/>&nbsp;</> : <></>}{item.label}
													</h6>
													<small>{item.to}</small>
												</div>
												
												{item.description!== undefined && item.description ? <p className="mb-1 fst-italic fs-7">{item.description}</p> : <></>}
												{/*item.detail!== undefined && item.detail ? <small>{item.detail}</small> : <></>*/}
											</Link>
										);
									})}
								</Div>
							</AccordionItem>
						);

						/*
						return (
							<Div className="pb-3"  key={indexMenu}>
								<Div className="fs-6 fw-bold text-primary pb-2 pt-1">
									{Icon ? <><Icon item={menu}/>&nbsp;</> : <></>}{menu.label}
								</Div>
								<Div className="list-group">
									{itens.map((item, indexItem) => {
										let Icon = item?.icon;
										return(
											<Link className="list-group-item list-group-item-action" to={item.to} key={indexItem} onClick={e => this.handleClose(e, item.to)}>
												<div className="d-flex w-100 justify-content-between">
													<h6 className="mb-1 fw-bold">
														{Icon ? <><Icon item={item}/>&nbsp;</> : <></>}{item.label}
													</h6>
													<small>{item.to}</small>
												</div>
												
												{item.description!== undefined && item.description ? <p className="mb-1 fst-italic fs-7">{item.description}</p> : <></>}
												{item.detail!== undefined && item.detail ? <small>{item.detail}</small> : <></>}
											</Link>
										);
									})}
								</Div>
							</Div>
						);*/
					})}

					</Accordion>
                </Div>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <Menu {...props} navigate={navigate} params={params} location={location}/>
}
export default With