import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { BaseCrud } from "../../siht/base";
import { FormButtons, FormItem, Row, Panel, PanelBody, PanelFooter, Container, ModalDialog, Col } from "../../siht/components";
import { InputText, Div, Form, InputValorReais, InputDate, BtnButton } from "../../siht/elements";
import Config from "./Config";
import Util from "../../utils/Util";

class FormFaturaCobrancaAlterarVencimento extends BaseCrud {

	constructor(props) {
		super(props);
		this.init = this.init.bind(this);
		this.handleSuccess = this.handleSuccess.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleChangeModel = this.handleChangeModel.bind(this);
		this.handleSubmitForm = this.handleSubmitForm.bind(this);
		this.handleClickDMais = this.handleClickDMais.bind(this);

        var tzoffset = (new Date()).getTimezoneOffset() * 60000;

		this.state.model = {
			faturaCobranca : this.props?.faturaCobranca,
            dtVencimento : this.props?.faturaCobranca?.dtVencimento,
		};

		if(Array.isArray(this.state.model.faturaCobranca) && this.state.model.faturaCobranca.length === 1){
			this.state.model.faturaCobranca = this.state.model.faturaCobranca[0];
		}

		var dtHoje = new Date();
        dtHoje.setDate(dtHoje.getDate());
        dtHoje = new Date(dtHoje - tzoffset).toISOString().slice(0, 10);

		this.state.dtHoje = dtHoje;
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		super.init(Config);
	}

	handleSuccess(e) {
		Config.getService().alterarVencimento(this.state.model, (response) =>{
            if (this.props.handleSuccess !== undefined) {
                this.props.handleSuccess(e, response);
            }
        });
	}

	handleClose(e) {
		super.handleClose(e, Config);
	}

	handleClickDMais(dias) {
        var tzoffset = (new Date()).getTimezoneOffset() * 60000;
        var dtVencimento = new Date();
        dtVencimento.setDate(dtVencimento.getDate() + dias);

        this.setState((state) => {
            state.model.dtVencimento = new Date(dtVencimento - tzoffset).toISOString().slice(0, 10);
            return state;
        });
    }

	render() {
		return super.render(
				<Div key={this.state.key}>
					<Form onSubmit={this.handleSuccess} refParam={f => (this.form = f)}>
						<Panel title="Alteração de Vencimento" onClose={this.handleClose} isModal={this.isModal()} container={{sizing: Container.sizing.xxl, className : ""}} modal={{sizing: this.props.sizing ? this.props.sizing : ModalDialog.sizing.xl}}>
							<PanelBody>
								<Row className="g-3 pb-3 px-2" show={!Array.isArray(this.state.model.faturaCobranca)}>
                                    <FormItem md={4} label="Valor da Cobrança">
                                        <InputValorReais readOnly={true} className="text-end text-primary fw-bold" required value={this.state.model.faturaCobranca?.vlCobranca} />
									</FormItem>
                                    <FormItem md={4} label="Valor Pago">
                                        <InputValorReais readOnly={true} className="text-end text-success fw-bold" required value={this.state.model.faturaCobranca?.vlBaixa} />
									</FormItem>
									<FormItem md={4} label="Valor Aberto">
                                        <InputValorReais readOnly={true} className="text-end text-danger fw-bold" required value={this.state.model.faturaCobranca?.vlCobranca - this.state.model.faturaCobranca?.vlBaixa} />
									</FormItem>
								</Row>
                                <Row className="g-3 pb-3 px-2">
									<FormItem md={3} label="Vencimento" show={!Array.isArray(this.state.model.faturaCobranca)}>
										<InputText readOnly={true} value={Util.date2Br(this.state.model.faturaCobranca?.dtVencimento)} className="text-center"/>
									</FormItem>
									<Col md={3} show={Array.isArray(this.state.model.faturaCobranca)}></Col>
									<Col md={6} className="d-flex d-flex align-items-end justify-content-center">
										<BtnButton color={BtnButton.color.success} className="fw-bold pt-0 pb-0 mx-2" onClick={(e) => { this.handleClickDMais(1) }}> D+1 </BtnButton>
										<BtnButton color={BtnButton.color.success} className="fw-bold pt-0 pb-0 mx-2" onClick={(e) => { this.handleClickDMais(2) }}> D+2 </BtnButton>
										<BtnButton color={BtnButton.color.warning} className="fw-bold pt-0 pb-0 mx-2" onClick={(e) => { this.handleClickDMais(5) }}> D+5 </BtnButton>
										<BtnButton color={BtnButton.color.warning} className="fw-bold pt-0 pb-0 mx-2" onClick={(e) => { this.handleClickDMais(10) }}> D+10 </BtnButton>
										<BtnButton color={BtnButton.color.danger} className="fw-bold pt-0 pb-0 mx-2" onClick={(e) => { this.handleClickDMais(15) }}> D+15 </BtnButton>
										<BtnButton color={BtnButton.color.danger} className="fw-bold pt-0 pb-0 mx-2" onClick={(e) => { this.handleClickDMais(30) }}> D+30 </BtnButton>
									</Col>
									<FormItem md={3} label="Novo Vencimento">
										<InputDate className="text-center" name="dtVencimento" value={this.state.model.dtVencimento} required={true} onChange={(e, v, p) => this.handleChangeModel(e, v, p)} min={this.state.dtHoje}/>
									</FormItem>
                                </Row>
							</PanelBody>
							<PanelFooter>
								<FormButtons onClose={this.handleClose} label={["Alterar Vencimento"]}/>
							</PanelFooter>
						</Panel>
					</Form>
				</Div>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <FormFaturaCobrancaAlterarVencimento {...props} navigate={navigate} params={params} location={location}/>
}
export default With