//#NO-CHANGE-FILE#

import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import SelectAnsMaterial from "../../components/SelectDB/SelectAnsMaterial";
import SelectAnsCor from "../../components/SelectDB/SelectAnsCor";
import { BaseCrud } from "../../siht/base";
import { FormButtons, FormItem, Row, Panel, PanelBody, PanelFooter, Container, ModalDialog, Col } from "../../siht/components";
import { InputNumberFormat, Form, Div, BtnButton, InputColor, TextArea } from "../../siht/elements";
import Config from "./Config";
import ConfigApp from "../../ConfigApp";
import Util from "../../utils/Util";

class FormAnsPerfilClassificacao extends BaseCrud {

	constructor(props) {
		super(props);
		this.init = this.init.bind(this);
		this.handleSuccess = this.handleSuccess.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleChangeModel = this.handleChangeModel.bind(this);
		this.handleSubmitForm = this.handleSubmitForm.bind(this);
		this.handleClickTotal = this.handleClickTotal.bind(this);
		this.handleChangeAnsMaterial = this.handleChangeAnsMaterial.bind(this);
		this.handleChangeAnsCor = this.handleChangeAnsCor.bind(this);

		this.state.model = {
			idAnsPerfilClassificacao: null,
			ansPerfil: this.props.parent !== undefined ? this.props.parent : {},
			ansMaterial: null,
			ansCor: null,
			vlProfundidade: 0,
			vlDeslocamentoVertical: 0,
			txAnsMaterial: "",
			flLimite: false,
			cdCor1: "#FFFFFF",
			cdCor2: "#FFFFFF"
		};
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init() {
		super.init(Config);
	}

	handleSuccess(e) {
		super.handleSuccess(e, Config);
	}

	handleClose(e) {
		super.handleClose(e, Config);
	}

	handleClickTotal() {
		this.setModel({ vlProfundidade: this.state.model.ansPerfil?.vlProfundidadeFuro });
	}

	handleChangeAnsMaterial() {

		if (this.state.model.ansMaterial && this.state.model.ansCor) {
			let txAnsMaterial = this.state.model.ansMaterial.nmAnsMaterial + " " + this.state.model.ansCor.nmAnsCor;
			this.setModel({ txAnsMaterial: txAnsMaterial });
		}
	}

	handleChangeAnsCor() {
		let txAnsMaterial = "";
		if (this.state.model.ansMaterial && this.state.model.ansCor) {
			txAnsMaterial = this.state.model.ansMaterial.nmAnsMaterial + " " + this.state.model.ansCor.nmAnsCor;
		}

		this.setModel({
			txAnsMaterial: txAnsMaterial,
			cdCor1: this.state.model.ansCor?.cdCor1,
			cdCor2: this.state.model.ansCor?.cdCor2
		});
	}

	render() {


		return super.render(
			<Form onSubmit={this.handleSuccess} refParam={f => (this.form = f)} key={this.state.key}>
				<Panel key={this.state.key} title="Cadastro de Classificação" onClose={this.handleClose} isModal={this.isModal()} container={{ sizing: Container.sizing.xxl, className: "" }} modal={{ sizing: this.props.sizing ? this.props.sizing : ModalDialog.sizing.xl }}>
					<PanelBody>
						<Row className="g-3 pb-2 px-2">
							<FormItem md={12} label="Material" show={!this.state.model.flLimite}>
								<SelectAnsMaterial name="ansMaterial" value={this.state.model.ansMaterial} required={true} onChange={(e, v, p) => this.handleChangeModel(e, v, p, this.handleChangeAnsMaterial)} placeholder="Selecione o Material" />
							</FormItem>
							<FormItem md={10} label="Cor" show={!this.state.model.flLimite}>
								<SelectAnsCor name="ansCor" value={this.state.model.ansCor} required={true} onChange={(e, v, p) => this.handleChangeModel(e, v, p, this.handleChangeAnsCor)} placeholder="Selecione a Cor" />
							</FormItem>

							<FormItem md={1} label="Cor 1" show={!this.state.model.flLimite}>
								<InputColor name="cdCor1" value={this.state.model.cdCor1} maxLength={7} required={true} onChange={this.handleChangeModel} />
							</FormItem>
							<FormItem md={1} label="Cor 2" show={!this.state.model.flLimite}>
								<InputColor name="cdCor2" value={this.state.model.cdCor2} maxLength={7} required={true} onChange={this.handleChangeModel} />
							</FormItem>

							{parseInt(this.state.model.ansMaterial?.arquivo?.idArquivo) > 0 && this.state.model.ansMaterial.arquivo?.cdUid && this.state.model.ansMaterial.arquivo?.arquivoBase?.cdHash ?
								<Col sizing={6}>
									<Div className="d-flex justify-content-center align-items-center">
										<img style={{ background: `linear-gradient(to right, ${this.state.model.cdCor1}, ${this.state.model.cdCor2})`, maxHeight: "150px" }} src={ConfigApp.getUrlArquivo() + "public/inline/" + this.state.model.ansMaterial.arquivo.cdUid + "/" + this.state.model.ansMaterial.arquivo.arquivoBase.cdHash} className="img-fluid img-thumbnail" alt="..." />
									</Div>
								</Col>
								: <Col sizing={6}></Col>}

							<Col sizing={6} style={{ background: `linear-gradient(to right, ${this.state.model.cdCor1}, ${this.state.model.cdCor2})` }}>
								<Div className="d-flex justify-content-center align-items-center w-100 h-100">
									{this.state.model.ansCor?.nmAnsCor}
								</Div>
							</Col>

							<FormItem md={12} label="Descrição do Material">
								<TextArea disabled={this.state.model.flLimite} name="txAnsMaterial" value={this.state.model.txAnsMaterial} maxLength={250} required={true} onChange={this.handleChangeModel} />
							</FormItem>

							<FormItem md={4} label="Profundidade">
								<InputNumberFormat disabled={this.state.model.flLimite} allowNegative={false} name="vlProfundidade" value={this.state.model.vlProfundidade} max={this.state.model.ansPerfil?.vlProfundidadeFuro} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} suffix=" mts" required={true} onChange={this.handleChangeModel} />
							</FormItem>

							<Col sizing={4} className="d-flex align-items-end">
								<BtnButton onClick={this.handleClickTotal}> MAX = {Util.floatToValor(this.state.model.ansPerfil?.vlProfundidadeFuro)} </BtnButton>
							</Col>

							<FormItem md={4} label="Deslocamento Vertical">
								<InputNumberFormat allowNegative={true} name="vlDeslocamentoVertical" value={this.state.model.vlDeslocamentoVertical} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} suffix=" mts" required={true} onChange={this.handleChangeModel} />
							</FormItem>

						</Row>
					</PanelBody>
					<PanelFooter>
						<FormButtons onClose={this.handleClose} />
					</PanelFooter>
				</Panel>
			</Form>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <FormAnsPerfilClassificacao {...props} navigate={navigate} params={params} location={location} />
}
export default With