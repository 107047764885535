//#NO-CHANGE-FILE#
import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { BaseCrud } from "../../siht/base";
import { FormButtons, FormItem, Row, Panel, PanelBody, PanelFooter, Container, BtnRadioGroup } from "../../siht/components";
import { InputNumberFormat, Div, Form, BtnRadio } from "../../siht/elements";
import FormItemTipoProdutoProduto from "../../components/FormItem/FormItemTipoProdutoProduto";
import Config from "./Config";
import ProdutoPrecoService from "../../services/ProdutoPrecoService";
import { toast } from "react-toastify";

class FormOrdemItem extends BaseCrud {

	constructor(props) {
		super(props);
		this.init = this.init.bind(this);
		this.handleSuccess = this.handleSuccess.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleChangeModel = this.handleChangeModel.bind(this);
		this.handleSubmitForm = this.handleSubmitForm.bind(this);

		this.handleChangeProduto = this.handleChangeProduto.bind(this);
		this.handleChangePercDesconto = this.handleChangePercDesconto.bind(this);
		this.handleChangeVlDesconto = this.handleChangeVlDesconto.bind(this);
		this.handleChangeTipoDesconto = this.handleChangeTipoDesconto.bind(this);

		this.state.model = {
			idOrdemItem : null,
			ordem : this.props.parent !== undefined ? this.props.parent : {},
			tipoPreco : this.props.tipoPreco !== undefined ? this.props.tipoPreco : null,
			produto : null,
			vlPreco : "",
			nrQuantidade : 1,
			pcDesconto : 0,
			vlDesconto : 0,
			cdTipoDesconto : "P"
		};
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		super.init(Config , () => {
			this.setModel({cdTipoDesconto : this.state.model.pcDesconto > 0 || !this.state.model.idOrdemItem ? "P" : "V"});
		});
	}

	handleSuccess(e) {
		super.handleSuccess(e, Config);
	}

	handleClose(e) {
		super.handleClose(e, Config);
	}

	handleChangeProduto(){

		if(this.state.model.produto?.idProduto && !this.state.model.tipoPreco){
			toast.error('Tipo de Preço não Informado!');
			return;
		}

		if(this.state.model.produto?.idProduto && this.state.model.tipoPreco){
			ProdutoPrecoService.getOneByProdutoTipoPreco(this.state.model.produto, this.state.model.tipoPreco, (response) => {
				this.setModel({vlPreco : response.vlPreco});
			}, () =>{
				this.setModel({vlPreco : 0});
			});
		}
	}

	handleChangePercDesconto(e, v, p){
		let pcDesconto =  parseFloat(this.state.model.pcDesconto) || 0;
		let vlDesconto = (parseFloat(this.state.model.vlPreco) * pcDesconto) / 100;
		this.setModel({vlDesconto : vlDesconto});
	}

	handleChangeVlDesconto(e, v, p){		
		//let pcDesconto = (parseFloat(this.state.model.vlDesconto) / parseFloat(this.state.model.vlPreco)) * 100;
		//this.setModel({pcDesconto : pcDesconto});
	}

	handleChangeTipoDesconto(){
		if(this.state.model.cdTipoDesconto === "V"){
			this.setModel({pcDesconto : 0});
		}
	}

	getValorOrdemItem(){
		let nrQuantidade = parseFloat(this.state.model.nrQuantidade);
		let vlPreco = parseFloat(this.state.model.vlPreco);
		return  parseFloat(vlPreco * nrQuantidade);
	}

	getValorFinal(){
		let nrQuantidade = parseFloat(this.state.model.nrQuantidade);
		let vlPreco = parseFloat(this.state.model.vlPreco);
		let vlDesconto = parseFloat(this.state.model.vlDesconto);
		return  parseFloat((vlPreco - vlDesconto) * nrQuantidade);
	}

	render() {
		return super.render(
				<Div key={this.state.key}>
					<Form onSubmit={this.handleSuccess} refParam={f => (this.form = f)}>
						<Panel title="Cadastro de Item" onClose={this.handleClose} isModal={this.isModal()} container={{sizing: Container.sizing.xxl, className : ""}}>
							<PanelBody>
								<Row className="g-3 pb-3 px-2">
									<FormItemTipoProdutoProduto disabled={this.state.model.idOrdemItem} tipoOrdem={this.state.model.ordem.tipoOrdem} tipoPreco={this.props.tipoPreco} md={[6,12]} name="produto" value={this.state.model.produto} required={true} onChange={(e, v, p) => this.handleChangeModel(e, v, p, this.handleChangeProduto)}/>
								</Row>
								<Row className="g-3 pb-3 px-2">
									<FormItem md={4} label="Preço Unitário">
										<InputNumberFormat name="vlPreco" value={parseFloat(this.state.model.vlPreco)} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} prefix="R$ " required={true} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={4} label="Quantidade">
										<InputNumberFormat className="text-center" name="nrQuantidade" value={parseFloat(this.state.model.nrQuantidade)} decimalScale={this.state.model.produto?.unidadeMedida?.nrCasasDecimais ? parseInt(this.state.model.produto.unidadeMedida.nrCasasDecimais) : 0} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} prefix="" required={true} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={4} label="Total do Item">
										<InputNumberFormat className="border border-primary readonly-field text-primary fw-bold" value={this.getValorOrdemItem()} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} prefix="R$ " required={false} readOnly={true}/>
									</FormItem>
								</Row>

								<Row className="g-3 pb-3 px-2">
									<FormItem md={4} label="Desconto">
										<BtnRadioGroup required={true} name="cdTipoDesconto" onChange={(e,v,p) => this.handleChangeModel(e,v,p, this.handleChangeTipoDesconto)} value={this.state.model.cdTipoDesconto}>
											<BtnRadio value="P" label="Percentual" color={BtnRadio.color.primary} />
											<BtnRadio value="V" label="Valor" color={BtnRadio.color.primary}/>
										</BtnRadioGroup>
									</FormItem>
									<FormItem md={4} label="Perc. Desconto">
										<InputNumberFormat disabled={this.state.model.cdTipoDesconto === "V"} name="pcDesconto" value={parseFloat(this.state.model.pcDesconto)} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} suffix=" %" required={true} onChange={(e, v, p) => this.handleChangeModel(e, v, p, this.handleChangePercDesconto)}/>
									</FormItem>
									<FormItem md={4} label="Valor Desconto">
										<InputNumberFormat className="border border-danger text-danger fw-bold" disabled={this.state.model.cdTipoDesconto === "P"} name="vlDesconto" value={parseFloat(this.state.model.vlDesconto)} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} prefix="R$ " required={true} onChange={(e, v, p) => this.handleChangeModel(e, v, p, this.handleChangeVlDesconto)}/>
									</FormItem>
								</Row>
							
								<Row className="g-3 pb-3 px-2">
									<FormItem md={8}></FormItem>
									<FormItem md={4} label="Valor Final">
										<InputNumberFormat className="border border-success readonly-field text-success fw-bold" value={this.getValorFinal()} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} prefix="R$ " required={false} readOnly={true}/>
									</FormItem>
								</Row>
							</PanelBody>
							<PanelFooter>
								<FormButtons onClose={this.handleClose} />
							</PanelFooter>
						</Panel>
					</Form>
				</Div>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <FormOrdemItem {...props} navigate={navigate} params={params} location={location}/>
}
export default With