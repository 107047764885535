//#NO-CHANGE-FILE#

import React from "react";
import { BaseComponent } from "../../siht/base";
import Config from "./Config";

class SvgPerfil extends BaseComponent {

	constructor(props) {
		super(props);

		this.state.svg = null;
	}

	componentDidMount() {
		super.componentDidMount();
		this.init();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		this.getSvg();
	}

	getSvg(){
		Config.getService().getSvgById(this.props.parent.idAnsPerfil, this.props.pagina, (response )=>{
			this.setState({svg : response.svg});
		});
	}
	
	render() {
		return super.render(
			<div className="d-flex justify-content-center">
				<span dangerouslySetInnerHTML={{ __html: this.state.svg }}/>
			</div>
		);
	}
}

function With(props) {
	return <SvgPerfil {...props}/>
}
export default With