//#NO-CHANGE-FILE#

import React from "react";
import { useNavigate, useLocation, useParams } from "react-router";
import BtnDelete from "../../components/BtnDelete";
import BtnNew from "../../components/BtnNew";
import Config from "./Config";
import { BaseList } from "../../siht/base";
import { DataGrid, DataGridButtons, DataGridColumn } from "../../siht/components";
import { Button, Div } from "../../siht/elements";
import { Modal } from "../../siht/controller";
import FormUpload from "../FormUpload";
import { toast } from "react-toastify";
import Util from "../../utils/Util";
import BtnDownload from "../../components/BtnDownload";

class ListContaReceberArquivo extends BaseList {

	constructor(props) {
		super(props);
		this.getItens = this.getItens.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleNew = this.handleNew.bind(this);
		this.handleEdit = this.handleEdit.bind(this);
		this.handleCopy = this.handleCopy.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.handleMore = this.handleMore.bind(this);
		this.onSubmitFilter = this.onSubmitFilter.bind(this);
		this.handleClear = this.handleClear.bind(this);
		this.handleFormSuccess = this.handleFormSuccess.bind(this);
		this.handleFormClose = this.handleFormClose.bind(this);
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		super.init(Config);
	}

	getItens(more){
		if(this.props?.parent){
			super.getItens(Config.getService().getAllByContaReceber, this.props.parent, more);
		}
	}

	handleNew(e) {
		Modal.openWindow(FormUpload, {sizing : Modal.sizing.default}, { multiple : true, accept : "image/*" }, (e, response) => {
			var model = [];

			response.forEach(arquivo => {
				model.push({
					arquivo : arquivo, 
					contaReceber : this.props.parent
				});
			});

			if(model.length > 0){
				Config.getService().save(model, () => this.getItens(false));
			}else{
				toast.error("Nenhuma arquivo foi enviado!")
			}
		});
	}

	handleEdit(e, item) {
		super.handleEdit(e, Config, item);
	}

	handleDelete(e, item) {
		super.handleDelete(e, Config, item);
	}

	handleCopy(e, item) {
		super.handleCopy(e, Config, item);
	}

	getFormEmbedded(){
		return super.getFormEmbedded(Config);
	}

	render() {
		return super.render(
			<Div className="p-2 mt-2">
				<Div className="d-flex justify-content-end flex-nowrap gap-2">
					<BtnNew onClick={this.handleNew}/>
					<BtnDelete disabled={this.state.selecteds.length === 0} sizing={Button.sizing.default} onClick={e => this.handleDelete(e, this.state.selecteds)}> Excluir </BtnDelete>
				</Div>

				<DataGrid onDoubleClickRow={this.handleEdit} responsive={true} data={this.state.data} search={true} isDisabled={i => false} sizing={DataGrid.sizing.sm} select={true} selecteds={this.state.selecteds} onSelect={(selecteds)=>{this.setState({selecteds : selecteds})}} getItemValue={i=>i.idContaReceberArquivo} onMore={this.handleMore}>
					<DataGridColumn label="Código" field={i => i.idContaReceberArquivo} className="text-center"/>
					<DataGridColumn col={4} label="Nome do Arquivo" field={i => i.arquivo?.nmArquivo} className="text-start"/>
					<DataGridColumn col={1} label="Tipo" field={i => i.arquivo?.arquivoBase?.cdTipo} className="text-start"/>
					<DataGridColumn col={1} label="Tamanho" field={i => Util.formatBytes(i.arquivo?.arquivoBase?.nrTamanho)} className="text-end"/>
					<DataGridColumn col={2} label="Data" field={i => Util.date2Br(i?.dhInsert, true)} className="text-center text-nowrap"/>
					<DataGridColumn col={4} label="Usuário" field={i => i.arquivo?.usuario?.nmUsuario} className="text-start"/>
					<DataGridButtons>
						<BtnDownload sizing={Button.sizing.default}/>
						<BtnDelete sizing={Button.sizing.default} onClick={this.handleDelete}/>
					</DataGridButtons>
				</DataGrid>
			</Div>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <ListContaReceberArquivo {...props} navigate={navigate} params={params} location={location}/>
}
export default With