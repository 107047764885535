import React, { Component } from "react";

export default class Modal extends Component {

    constructor(props) {
        super(props);

        this.handleKeyUpModal = this.handleKeyUpModal.bind(this);
    }

    handleKeyUpModal(event) {
        if (event.keyCode === 27) {
            this.props.handleClose(event);
        }
    }

    getDescription(description){
        if(description === undefined){
            return <></>;
        }

        if(Array.isArray(description)){
            return (
                <ul>
                    {description.map((item, index) =>
                        <React.Fragment key={index}>
                            {item instanceof Object ? 
                                <li className={item.className ? item.className : ""}>
                                    <span className={`fw-bold`}> {item.label} </span> 
                                    <span className={``}> {item.value} </span> 
                                </li>
                             : <li className="fw-bold">{item}</li> 
                             }
                        </React.Fragment>
                        
                    )}
                </ul>
            );
        }

        return (<div className="fs-6 fw-light fst-italic">{description}</div>);
    }

    render() {
        var WindowModal = this.props.window || null;
        var sizing = this.props.config.sizing ? this.props.config.sizing : ""; //modal-sm | modal-lg | modal-xl | "" = none = Medium
        var scrollable = this.props.config.scrollable !== undefined  ? this.props.config.scrollable : true;
        var showBtnClose = this.props.config.showBtnClose === undefined || true;
        var noCard = this.props.config.noCard !== undefined && this.props.config.noCard ? true : false;
        var modalConfirm = this.props.config.modalConfirm !== undefined && this.props.config.modalConfirm ? true : false;
        var modalInfo = this.props.config.modalInfo !== undefined && this.props.config.modalInfo ? true : false;

        if(modalConfirm){
            return (
                <>
                    <div className="fade modal-backdrop show"> </div>
                    <div className="modal d-block" id="exampleModal" tabIndex="-1"
                        onKeyUp={this.handleKeyUpModal}
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="false" aria-modal="true">
                        <div className={`modal-dialog ${scrollable ? 'modal-dialog-scrollable' : ''} ${sizing ? "modal-" + sizing : ""}`}>
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">
                                        {this.props.title}
                                    </h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={this.props.handleClose}></button>
                                </div>
                                <div className={`modal-confirmacao-${this.props.style ? this.props.style : "primary"} modal-body p-4`}>
                                    <div className="row">
                                        <div className="col-auto d-flex align-items-center justify-content-center">
                                            <svg className={`text-${this.props.style ? this.props.style : "primary"} bi bi-question-diamond-fill`} xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="currentColor" viewBox="0 0 16 16">
                                                <path d="M9.05.435c-.58-.58-1.52-.58-2.1 0L.436 6.95c-.58.58-.58 1.519 0 2.098l6.516 6.516c.58.58 1.519.58 2.098 0l6.516-6.516c.58-.58.58-1.519 0-2.098L9.05.435zM5.495 6.033a.237.237 0 0 1-.24-.247C5.35 4.091 6.737 3.5 8.005 3.5c1.396 0 2.672.73 2.672 2.24 0 1.08-.635 1.594-1.244 2.057-.737.559-1.01.768-1.01 1.486v.105a.25.25 0 0 1-.25.25h-.81a.25.25 0 0 1-.25-.246l-.004-.217c-.038-.927.495-1.498 1.168-1.987.59-.444.965-.736.965-1.371 0-.825-.628-1.168-1.314-1.168-.803 0-1.253.478-1.342 1.134-.018.137-.128.25-.266.25h-.825zm2.325 6.443c-.584 0-1.009-.394-1.009-.927 0-.552.425-.94 1.01-.94.609 0 1.028.388 1.028.94 0 .533-.42.927-1.029.927z" />
                                            </svg>
                                        </div>
                                        <div className="col">
                                            <span className="fs-6">{this.props.message}</span>
                                            {this.getDescription(this.props.description)}
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button autoFocus type="button" className={`btn btn-outline-secondary`} data-bs-dismiss="modal" onClick={this.props.handleClose}>
                                        {this.props.btnCancelText ? this.props.btnCancelText : "Cancelar"}
                                    </button>
                                    <button type="button" className={`btn btn-${this.props.style ? this.props.style : "primary"}`} onClick={this.props.handleSuccess}>
                                        {this.props.btnConfirmText ? this.props.btnConfirmText : "Confirmar"}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )
        }

        if(modalInfo){
            return (
                <>
                    <div className="fade modal-backdrop show"> </div>
                    <div className="modal d-block" id="exampleModal" tabIndex="-1"
                        onKeyUp={this.handleKeyUpModal}
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="false" aria-modal="true">
                        <div className={`modal-dialog ${scrollable ? 'modal-dialog-scrollable' : ''} ${sizing ? "modal-" + sizing : ""}`}>
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">
                                        {this.props.title}
                                    </h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={this.props.handleClose}></button>
                                </div>
                                <div className={`modal-confirmacao-${this.props.style ? this.props.style : "primary"} modal-body p-4`}>
                                    <div className="row">
                                        <div className="col-auto d-flex align-items-center justify-content-center">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="currentColor" className={`text-${this.props.style ? this.props.style : "primary"} bi bi-info-circle-fill`} viewBox="0 0 16 16">
                                                <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2"/>
                                            </svg>
                                        </div>
                                        <div className="col">
                                            <span className="fs-6">{this.props.message}</span>
                                            {this.getDescription(this.props.description)}
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button autoFocus type="button" className={`btn btn-${this.props.style ? this.props.style : "primary"}`} onClick={this.props.handleSuccess}>
                                        {this.props.btnConfirmText ? this.props.btnConfirmText : "OK"}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )
        }

        if(noCard){
            return (
                <div>
                    <div style={{ zIndex: 1000 + (this.props.zIndex * 2) }} className="fade modal-backdrop show"> </div>
                    <div style={{ zIndex: 1001 + (this.props.zIndex * 2) }} className="modal m-1 d-block" tabIndex="-1" onKeyUp={this.handleKeyUpModal} aria-hidden="false" aria-modal="true">                        
                        {WindowModal ? <WindowModal sizing={sizing ? "modal-" + sizing : ""}  {...this.props} handleSuccess={this.props.handleSuccess} handleClose={this.props.handleClose} /> : ""}                                
                    </div>
                </div>
            );
        }

        return (
            <>
                <div style={{ zIndex: 1000 + (this.props.zIndex * 2) }} className="fade modal-backdrop show"> </div>
                <div style={{ zIndex: 1001 + (this.props.zIndex * 2) }} className="modal m-1 d-block" tabIndex="-1"
                    onKeyUp={this.handleKeyUpModal}
                    aria-hidden="false" aria-modal="true">
                    <div className={`modal-dialog pe-4 ${scrollable ? 'modal-dialog-scrollable' : ''} ${sizing ? "modal-" + sizing : ""}`}>
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">
                                    {this.props.title}
                                </h5>
                                {showBtnClose ? <button type="button" autoFocus className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={this.props.handleClose}></button> : <></>}
                            </div>
                            <div className="modal-body p-3 pt-0 mx-2 my-3">
                                {WindowModal ? <WindowModal {...this.props} handleSuccess={this.props.handleSuccess} handleClose={this.props.handleClose} /> : ""}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
