import Config from "./Config";
import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import SelectUnidadeLocal from "../../components/SelectDB/SelectUnidadeLocal";
import { BaseCrud } from "../../siht/base";
import { FormButtons, FormItem, Row, Panel, PanelBody, PanelFooter, Container, ModalDialog } from "../../siht/components";
import { InputText, InputNumberFormat, Form } from "../../siht/elements";

class FormProdutoEstoque extends BaseCrud {

	constructor(props) {
		super(props);
		this.init = this.init.bind(this);
		this.initModel = this.initModel.bind(this);
		this.handleSuccess = this.handleSuccess.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleChangeModel = this.handleChangeModel.bind(this);
		this.handleSubmitForm = this.handleSubmitForm.bind(this);
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		super.init(Config);
	}

	initModel(){
		return {
			idProdutoEstoque : null,
			produto : this.props.parent !== undefined ? this.props.parent : {},
			unidadeLocal : null,
			vlEstoque : 0,
			vlEstoqueMinimo : 0,
			vlEstoqueMaximo : 0
		}
	}

	handleSuccess(e) {
		super.handleSuccess(e, Config);
	}

	handleClose(e) {
		super.handleClose(e, Config);
	}

	render() {
		return super.render(

					<Form onSubmit={this.handleSuccess} refParam={f => (this.form = f)} key={this.state.key}>
						<Panel key={this.state.key} title="Cadastro de ProdutoEstoque" localOpen={this.props.localOpen}  onClose={this.handleClose} isModal={this.isModal()} container={{sizing: Container.sizing.xxl, className : ""}} modal={{sizing: this.props.sizing ? this.props.sizing : ModalDialog.sizing.xl}}>
							<PanelBody>
								<Row className="g-3 pb-2 px-2">
									<FormItem md={6} label="Código">
										<InputText name="idProdutoEstoque" value={this.state.model.idProdutoEstoque} readOnly={true} className="readonly-field" required={false} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={12} label="Nome do UnidadeLocal">
										<SelectUnidadeLocal name="unidadeLocal" value={this.state.model.unidadeLocal} required={true} onChange={this.handleChangeModel} placeholder="Selecione Nome do UnidadeLocal"/>
									</FormItem>
									<FormItem md={4} label="VlEstoque">
										<InputNumberFormat name="vlEstoque" value={this.state.model.vlEstoque} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} prefix="R$ " required={true} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={4} label="VlEstoqueMinimo">
										<InputNumberFormat name="vlEstoqueMinimo" value={this.state.model.vlEstoqueMinimo} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} prefix="R$ " required={true} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={4} label="VlEstoqueMaximo">
										<InputNumberFormat name="vlEstoqueMaximo" value={this.state.model.vlEstoqueMaximo} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} prefix="R$ " required={true} onChange={this.handleChangeModel}/>
									</FormItem>
								</Row>
							</PanelBody>
							<PanelFooter>
								<FormButtons onClose={this.handleClose} />
							</PanelFooter>
						</Panel>
					</Form>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <FormProdutoEstoque {...props} navigate={navigate} params={params} location={location}/>
}
export default With