//#NO-CHANGE-FILE#

import FormAnsPerfilClassificacao from "./FormAnsPerfilClassificacao.js";
import ListAnsPerfilClassificacao from "./ListAnsPerfilClassificacao.js";
import { Permissao } from "../../controllers";
import { Util } from "../../utils";
import { DadoValorService, AnsPerfilClassificacaoService } from "../../services";

export default class Config {

	static getUrlForm() {
		return "/CadAnsPerfilClassificacao";
	}

	static getUrlList() {
		return "/LstAnsPerfilClassificacao";
	}

	static getComponentForm() {
		return FormAnsPerfilClassificacao;
	}

	static getPermissionForm() {
		return Permissao.getInstance().getPermissoes([62]);
	}

	static getPermissionList() {
		return Permissao.getInstance().getPermissoes([62]);
	}

	static getComponentList() {
		return ListAnsPerfilClassificacao;
	}

	static getService() {
		return AnsPerfilClassificacaoService;
	}

	static getKeyId() {
		return "idAnsPerfilClassificacao";
	}

	static getKeyDescription() {
		return "txAnsMaterial";
	}

	static setDefaultValues(state, fnCallBack = () => { }, className = undefined) {
		className = className === undefined ? "FormAnsPerfilClassificacao" : className;
		let keyNameScreen = Util.toToUpperTextCamelCase(className, "_") + ".";
		var prefix = "CONFIGURACAO.";
		DadoValorService.getValues(`${prefix}${keyNameScreen}`, (response) => {
			Util.setValueObjectCamelCase(state.model, response, keyNameScreen, prefix);
			fnCallBack();
		}, fnCallBack);
	}
}