//#NO-CHANGE-FILE#

import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { BaseCrud } from "../../siht/base";
import { FormButtons, FormItem, Row, Panel, PanelBody, PanelFooter, Container, ModalDialog, Col } from "../../siht/components";
import { InputText, Form, InputCheckSwitch } from "../../siht/elements";
import Config from "./Config";
import ConfigApp from "../../ConfigApp";

class FormAnsPerfilFoto extends BaseCrud {

	constructor(props) {
		super(props);
		this.init = this.init.bind(this);
		this.handleSuccess = this.handleSuccess.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleChangeModel = this.handleChangeModel.bind(this);
		this.handleSubmitForm = this.handleSubmitForm.bind(this);

		this.state.model = {
			idAnsPerfilFoto: null,
			ansPerfil: this.props.parent !== undefined ? this.props.parent : {},
			nmAnsPerfilFoto: "",
			flNomeAutomatico: true,
			arquivo: null
		};
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init() {
		super.init(Config);
	}

	handleSuccess(e) {
		super.handleSuccess(e, Config);
	}

	handleClose(e) {
		super.handleClose(e, Config);
	}

	render() {
		return super.render(
			<Form onSubmit={this.handleSuccess} refParam={f => (this.form = f)} key={this.state.key}>
				<Panel key={this.state.key} title="Cadastro de Fotos" onClose={this.handleClose} isModal={this.isModal()} container={{ sizing: Container.sizing.xxl, className: "" }} modal={{ sizing: this.props.sizing ? this.props.sizing : ModalDialog.sizing.xl }}>
					<PanelBody>
						<Row>
							<Col sizing={6} className="px-3 mb-3">
								<Row className="g-3 pb-2 px-2">
									<FormItem md={12} label="Descrição da Foto">
										<InputText name="nmAnsPerfilFoto" value={this.state.model.nmAnsPerfilFoto} autoFocus={true} maxLength={100} required={true} onChange={this.handleChangeModel} disabled={this.state.model.flNomeAutomatico} />
									</FormItem>
									<Col sizing={12} className="mb-3">
										<InputCheckSwitch label="Nome Automático Sequencial" name="flNomeAutomatico" checked={(this.state.model.flNomeAutomatico)} onChange={this.handleChangeModel} />
									</Col>
								</Row>
							</Col>
							<Col sizing={6} className="d-flex justify-content-center align-items-center">
								<img style={{ maxHeight: "300px" }} src={ConfigApp.getUrlArquivo() + "public/inline/" + this.state.model.arquivo?.cdUid + "/" + this.state.model.arquivo?.arquivoBase?.cdHash} className="img-fluid img-thumbnail" alt="..." />
							</Col>
						</Row>
					</PanelBody>
					<PanelFooter>
						<FormButtons onClose={this.handleClose} />
					</PanelFooter>
				</Panel>
			</Form>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <FormAnsPerfilFoto {...props} navigate={navigate} params={params} location={location} />
}
export default With