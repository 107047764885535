import UsuarioTipoOrdemService from "../../services/UsuarioTipoOrdemService";
import FormUsuarioTipoOrdem from "./FormUsuarioTipoOrdem";
import ListUsuarioTipoOrdem from "./ListUsuarioTipoOrdem";
import Permissao from "../../controllers/Permissao";

export default class Config {

	static getUrlForm() {
		return "/CadUsuarioTipoOrdem";
	}

	static getUrlList() {
		return "/LstUsuarioTipoOrdem";
	}

	static getComponentForm() {
		return FormUsuarioTipoOrdem;
	}

	static getPermissionForm() {
		return Permissao.getInstance().getPermissoes([15]);
	}

	static getPermissionList() {
		return Permissao.getInstance().getPermissoes([15]);
	}

	static getComponentList() {
		return ListUsuarioTipoOrdem;
	}

	static getService() {
		return UsuarioTipoOrdemService;
	}

	static getKeyId() {
		return "idUsuarioTipoOrdem";
	}

	static getKeyDescription() {
		return "nmUsuarioTipoOrdem";
	}
}