//#NO-CHANGE-FILE#

import React from "react";
import { useNavigate, useLocation, useParams } from "react-router";
import Config from "./Config";
import { BaseList } from "../../siht/base";
import { Panel, Container, PanelBody } from "../../siht/components";
import ListDadoValorByParent from "./ListDadoValorByParent";

class ListConfiguracao extends BaseList {

	constructor(props) {
		super(props);
		this.getItens = this.getItens.bind(this);
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		super.init(Config);
	}

	getItens(){
	}

	render() {

		return super.render(
			<Panel title="Lista de Configurações" isModal={this.isModal()} container={{sizing: Container.sizing.fluid, className : ""}}>
				<PanelBody className="p-0 m-0">
					<ListDadoValorByParent cdDadoLocal="CONFIGURACAO" parent={null} localOpen={BaseList.localOpen.modal}/>
				</PanelBody>
			</Panel>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <ListConfiguracao {...props} navigate={navigate} params={params} location={location}/>
}
export default With