//#NO-CHANGE-FILE#

import React from "react";
import { useNavigate, useLocation, useParams } from "react-router";
import Config from "./Config";
import { BaseList } from "../../siht/base";
import { DataGrid, DataGridColumn } from "../../siht/components";
import { Div, InputCheckSwitch } from "../../siht/elements";
import TipoNotificacaoService from "../../services/TipoNotificacaoService";

class ListPessoaTipoNotificacao extends BaseList {

	constructor(props) {
		super(props);
		this.getItens = this.getItens.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleNew = this.handleNew.bind(this);
		this.handleEdit = this.handleEdit.bind(this);
		this.handleCopy = this.handleCopy.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.handleMore = this.handleMore.bind(this);
		this.onSubmitFilter = this.onSubmitFilter.bind(this);
		this.handleClear = this.handleClear.bind(this);
		this.handleFormSuccess = this.handleFormSuccess.bind(this);
		this.handleFormClose = this.handleFormClose.bind(this);
		this.getTipoNotificacao = this.getTipoNotificacao.bind(this);
		this.handleChangeItem = this.handleChangeItem.bind(this);

		this.state.tipoNotificacao = [];
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		super.init(Config, () =>{			
		});

		this.getTipoNotificacao();
	}

	getTipoNotificacao(){
		TipoNotificacaoService.getForCombo({}, (response) => {
			this.setState({tipoNotificacao : response.filter(tp => tp.tipoNotificacaoDestino.cdTipoNotificacaoDestino === "PESSOA")});
		})
	}

	getItens(more){
		if(this.props?.parent){
			super.getItens(Config.getService().getAllByPessoa, this.props.parent, more);
		}
	}

	handleNew(e) {
		super.handleNew(e, Config);
	}

	handleEdit(e, item) {
		super.handleEdit(e, Config, item);
	}

	handleDelete(e, item) {
		super.handleDelete(e, Config, item);
	}

	handleCopy(e, item) {
		super.handleCopy(e, Config, item);
	}

	getFormEmbedded(){
		return super.getFormEmbedded(Config);
	}

	handleChangeItem(e, v, p, tipoNotificacao){

		let model = this.state.data.find(x => parseInt(x.tipoNotificacao.idTipoNotificacao) === parseInt(tipoNotificacao.idTipoNotificacao));

		if(!model){
			model = {
				idPessoaTipoNotificacao : null,
				tipoNotificacao : tipoNotificacao,
				pessoa : this.props.parent !== undefined ? this.props.parent : {},
				flSistema : false,
				flEmail : false,
				flWhatsapp : false
			};
		}

		model[p] = v;
		
		Config.getService().save(model, (response) => {
			this.getItens();
		});
	}

	render() {
		return super.render(
			<Div className="p-2 mt-2">
				<DataGrid responsive={true} data={this.state.tipoNotificacao} search={true} isDisabled={i => false} sizing={DataGrid.sizing.sm} select={false} selecteds={this.state.selecteds} onSelect={(selecteds)=>{this.setState({selecteds : selecteds})}} getItemValue={i=>i.idPessoaTipoNotificacao} onMore={this.handleMore}>
					<DataGridColumn label="Código" field={i => i.idTipoNotificacao} className="text-center"/>
					<DataGridColumn col={9} label="Nome do TipoNotificacao" field={i => i.nmTipoNotificacao} className="text-start"/>
					<DataGridColumn col={1} label="Sistema" field={i => 
						i.flSistema ?
							<InputCheckSwitch name="flSistema" checked={this.state.data.find(x => parseInt(x.tipoNotificacao.idTipoNotificacao) === parseInt(i.idTipoNotificacao))?.flSistema} onChange={(e,v,p) => this.handleChangeItem(e,v,p,i)} />
						: <></>
						} className="text-start"/>
					<DataGridColumn col={1} label="Email" field={i => 
						i.flEmail ?
							<InputCheckSwitch name="flEmail" checked={this.state.data.find(x => parseInt(x.tipoNotificacao.idTipoNotificacao) === parseInt(i.idTipoNotificacao))?.flEmail} onChange={(e,v,p) => this.handleChangeItem(e,v,p,i)} />
						: <></>
					} className="text-start"/>
					<DataGridColumn col={1} label="Whatsapp" field={i => 
						i.flWhatsapp ?
							<InputCheckSwitch name="flWhatsapp" checked={this.state.data.find(x => parseInt(x.tipoNotificacao.idTipoNotificacao) === parseInt(i.idTipoNotificacao))?.flWhatsapp} onChange={(e,v,p) => this.handleChangeItem(e,v,p,i)} />
						: <></>
					} className="text-start"/>
				</DataGrid>
			</Div>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <ListPessoaTipoNotificacao {...props} navigate={navigate} params={params} location={location}/>
}
export default With