import Field from "./Field";

export default class InputRadio extends Field {

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    handleChange(e) {
        super.handleChange(e, e?.target?.value, e?.target?.name);        
    }

    handleClick(e) {
        super.handleClick(e, e?.target?.value, e?.target?.name);        
    }

    render() {
        const props = Object.assign({}, this.props);
        props.type = "radio";
        props.onChange = this.handleChange;
        props.onClick = this.handleClick;
        props.ref = this.props.refParam ? this.props.refParam : this.ref;
        props.autoComplete = "off";
        props.className = `form-check-input ${props.className ? props.className : ""}`;

        delete props.label;

        return super.render(<input {...this.getProps(props)} />, false);
    }
}