import React from "react";
import Field from "./Field";
import { Functions } from "../util";
import { BtnRadioGroup } from "../components";
import BtnRadio from "./BtnRadio";

export default class BtnRadioGroupDB extends Field {

    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
        this.state.options = this.props.options || [];
    }

    componentDidUpdate(nextProps) {
        if (nextProps.filter !== undefined && this.props.filter !== undefined) {
            if (!this.objEqual(nextProps.filter, this.props.filter)) {
                this.getOptions();
            }
        }
    }

    componentDidMount() {
        this.getOptions();
    }

    componentWillUnmount() {
    }

    handleClick(e, selected) {
        if(selected === undefined){
            selected = this.state.options.find(item => this.getOptionValue(item)?.toString() === e?.target?.value?.toString());
            selected = selected === undefined ? "" : selected;
        }

        if(this.props.onClick){
            this.props.onClick(e, selected, this.props.name);
        }

        if(this.props.onChange){
            this.props.onChange(e, selected, this.props.name);
        }
    }

    isDisabled(item) {
        return this.props.isDisabled !== undefined && this.props.isDisabled instanceof Function ? this.props.isDisabled(item) : false;
    }

    getForComboService() {
        return null;
    }

    getDefault() {
        return null;
    }

    getOptions(callBack) {
        var filter = this.props.filter !== undefined ? this.props.filter : {};
        callBack = callBack !== undefined && Functions.isFunction(callBack) ? callBack : ()=>{};

        var getForComboService = this.getForComboService();

        if (this.getOptionsWhen(filter) && getForComboService !== null) {
            getForComboService((filter), (response) => {
                this.setState({ options: response }, () =>{                
                    if(!this.getOptionValue(this.props.value)){
                        let item = this.getDefault();

                        if(!item && response.length > 0 &&  this.props.defaultIndex !== undefined){
                            item = response[parseInt(this.props.defaultIndex)];
                        }

                        if(item){
                            this.handleClick({}, item, this.props.name);
                        }
                    }
                    callBack();
                });               
            });
        }else if(!this.props.options){
            this.setState({ options: [] }, callBack);
            
        }
    }

    getOptionValue(item) {
        return item?.value;
    }

    getOptionLabel(item) {
        return item?.label;
    }

    getOptionClass(item) {
        return "";
    }

    getOptionsWhen(item) {
        var filter = this.props.filter !== undefined ? this.props.filter : {};
        return this.props.getOptionsWhen === undefined || this.props.getOptionsWhen(filter);
    }

    render(feedback = true) {
        const props = Object.assign({}, this.props);
        delete props.show;
        delete props.onClick;
        delete props.defaultIndex;

        props.value = this.getOptionValue(this.props.value);
        props.value = props.value === undefined ? "" : props.value;
        props.ref = this.props.refParam ? this.props.refParam : this.ref;

        var element = (
            <BtnRadioGroup {...props}>
                {this.state.options.map((option, index) => (
                    <BtnRadio key={index}
                        onClick={e => this.handleClick(e, option)}
                        color={BtnRadio.color.primary}
                        className={this.getOptionClass(option)}
                        label={this.getOptionLabel(option)}
                        value={this.getOptionValue(option)}
                        disabled={this.isDisabled(option)}/> 
                ))}
            </BtnRadioGroup>
        );

        return super.render(element, feedback);
    }
}