
import FormFaturaNotaFiscalMotivoCancelamento from "./FormFaturaNotaFiscalMotivoCancelamento.js";
import ListFaturaNotaFiscalMotivoCancelamento from "./ListFaturaNotaFiscalMotivoCancelamento.js";
import { Permissao } from "../../controllers";
import { Util } from "../../utils";
import { DadoValorService, FaturaNotaFiscalMotivoCancelamentoService } from "../../services";

export default class Config {

	static getUrlForm() {
		return "/CadFaturaNotaFiscalMotivoCancelamento";
	}

	static getUrlList() {
		return "/LstFaturaNotaFiscalMotivoCancelamento";
	}

	static getComponentForm() {
		return FormFaturaNotaFiscalMotivoCancelamento;
	}

	static getPermissionForm() {
		return Permissao.getInstance().getPermissoes([165]);
	}

	static getPermissionList() {
		return Permissao.getInstance().getPermissoes([165]);
	}

	static getComponentList() {
		return ListFaturaNotaFiscalMotivoCancelamento;
	}

	static getService() {
		return FaturaNotaFiscalMotivoCancelamentoService;
	}

	static getKeyId() {
		return "idFaturaNotaFiscalMotivoCancelamento";
	}

	static getKeyDescription() {
		return "nmFaturaNotaFiscalMotivoCancelamento";
	}

	static setDefaultValues(state , fnCallBack = () => {}, className = undefined) {
		className = className === undefined ? "FormFaturaNotaFiscalMotivoCancelamento" : className;
		let keyNameScreen = Util.toToUpperTextCamelCase(className, "_") + ".";
		var prefix = "CONFIGURACAO.";
		DadoValorService.getValues(`${prefix}${keyNameScreen}`, (response)=> {
			Util.setValueObjectCamelCase(state.model, response, keyNameScreen, prefix);
			fnCallBack();
		}, fnCallBack);
	}
}