//#NO-CHANGE-FILE#

import OrdemService from "../../services/OrdemService";
import FormOrdem from "./FormOrdem";
import ListOrdem from "./ListOrdem";
import Permissao from "../../controllers/Permissao";

export default class Config {

	static getUrlForm() {
		return "/CadOrdem";
	}

	static getUrlList() {
		return "/LstOrdem";
	}

	static getComponentForm() {
		return FormOrdem;
	}

	static getPermissionForm() {
		return Permissao.getInstance().getPermissoes([44]);
	}

	static getPermissionList() {
		return Permissao.getInstance().getPermissoes([44]);
	}

	static getComponentList() {
		return ListOrdem;
	}

	static getService() {
		return OrdemService;
	}

	static getKeyId() {
		return "idOrdem";
	}

	static getKeyDescription() {
		return "nmOrdem";
	}

	static getPermissaoOrdem(ordem){

		if(ordem === undefined){
			ordem = { idOrdem : true };
		}

		return {
			flPodeEditar : ordem.statusOrdem?.flPodeEditar,
			flPodeImprimir : ordem?.idOrdem && Permissao.getInstance().getPermissoes([109]),
			flPodeFaturar : ordem?.idOrdem && Permissao.getInstance().getPermissoes([55]),
			flPodeCancelar : ordem?.idOrdem && Permissao.getInstance().getPermissoes([74]),
			flPodeReabrir : ordem?.idOrdem && Permissao.getInstance().getPermissoes([79]),
			flPodeAlterarStatus : ordem?.idOrdem && Permissao.getInstance().getPermissoes([80]),
			flPodeAplicarDesconto : ordem?.idOrdem && Permissao.getInstance().getPermissoes([75]),
			flPodeVerValorOrdem : Permissao.getInstance().getPermissoes([76]),
			flPodeVerFatura : ordem?.idOrdem && Permissao.getInstance().getPermissoes([68]),
			flPodeDuplicar : ordem?.idOrdem && Permissao.getInstance().getPermissoes([159]),
			flPodePagarComissao : ordem?.idOrdem && ordem?.flPagarComissao && Permissao.getInstance().getPermissoes([207]),
			flPodeMarcarPagarComissao : ordem?.idOrdem && Permissao.getInstance().getPermissoes([221]),
			flPodeAlterarPessoa : ordem.statusOrdem?.flPodeEditar,
		};
	}
}