//#NO-CHANGE-FILE#

import React from "react";
import { useNavigate, useLocation, useParams } from "react-router";
import { BtnCopy, BtnDelete, BtnEdit, BtnNew } from "../../components/Custom";
import Config from "./Config";
import ConfigAnsPerfil from "../AnsPerfil/Config";
import { BaseList } from "../../siht/base";
import { DataGrid, DataGridButtons, DataGridColumn } from "../../siht/components";
import { Button, Div } from "../../siht/elements";

class ListAnsPerfilPessoa extends BaseList {

	constructor(props) {
		super(props);
		this.getItens = this.getItens.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleNew = this.handleNew.bind(this);
		this.handleEdit = this.handleEdit.bind(this);
		this.handleCopy = this.handleCopy.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.handleMore = this.handleMore.bind(this);
		this.onSubmitFilter = this.onSubmitFilter.bind(this);
		this.handleClear = this.handleClear.bind(this);
		this.handleFormSuccess = this.handleFormSuccess.bind(this);
		this.handleFormClose = this.handleFormClose.bind(this);
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init() {
		super.init(Config);
	}

	getItens(more) {
		if (this.props?.parent) {
			super.getItens(Config.getService().getAllByAnsPerfil, this.props.parent, more);
		}
	}

	handleNew(e) {
		super.handleNew(e, Config);
	}

	handleEdit(e, item) {
		super.handleEdit(e, Config, item);
	}

	handleDelete(e, item, fnCallBack = () => { }) {
		super.handleDelete(e, Config, item, fnCallBack, (item) => {
			return item.pessoa.nmPessoa;
		});
	}

	handleCopy(e, item) {
		super.handleCopy(e, Config, item);
	}

	getFormEmbedded() {
		return super.getFormEmbedded(Config);
	}

	render() {
		return super.render(
			<Div className="p-2 mt-2">
				<Div className="d-flex justify-content-end flex-nowrap gap-2">
					<BtnNew onClick={this.handleNew} disabled={!ConfigAnsPerfil.getPermissaoAnsPerfil(this.props.parent).flPodeEditar} />
					<BtnDelete disabled={this.state.selecteds.length === 0 || !ConfigAnsPerfil.getPermissaoAnsPerfil(this.props.parent).flPodeEditar} sizing={Button.sizing.default} onClick={e => this.handleDelete(e, this.state.selecteds)}> Excluir </BtnDelete>
				</Div>

				{this.getFormEmbedded()}

				<DataGrid responsive={true} data={this.state.data} search={true} isDisabled={i => false} sizing={DataGrid.sizing.sm} select={ConfigAnsPerfil.getPermissaoAnsPerfil(this.props.parent).flPodeEditar} selecteds={this.state.selecteds} onSelect={(selecteds) => { this.setState({ selecteds: selecteds }) }} getItemValue={i => i.idAnsPerfilPessoa} onMore={this.handleMore}>
					<DataGridColumn label="Código" field={i => i.idAnsPerfilPessoa} className="text-center" />
					<DataGridColumn col={6} label="Nome da Pessoa" field={i => i.pessoa?.nmPessoa} className="text-start" />
					<DataGridColumn col={6} label="Cargo" field={i => i.cargo?.nmCargo} className="text-start" />
					<DataGridButtons>
						<BtnEdit sizing={Button.sizing.default} onClick={this.handleEdit} disabled={!ConfigAnsPerfil.getPermissaoAnsPerfil(this.props.parent).flPodeEditar} />
						<BtnDelete sizing={Button.sizing.default} onClick={this.handleDelete} disabled={!ConfigAnsPerfil.getPermissaoAnsPerfil(this.props.parent).flPodeEditar} />
						<BtnCopy sizing={Button.sizing.default} onClick={this.handleCopy} disabled={!ConfigAnsPerfil.getPermissaoAnsPerfil(this.props.parent).flPodeEditar} />

					</DataGridButtons>
				</DataGrid>
			</Div>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <ListAnsPerfilPessoa {...props} navigate={navigate} params={params} location={location} />
}
export default With