import React, { Component } from "react";
import Tab from "./Tab";
import TabFooter from "./TabFooter";
import TabDropdown from "./TabDropdown";

export default class TabNavigator extends Component {

    constructor(props) {
        super(props);

        this.handleClickTab = this.handleClickTab.bind(this);

        this.state = {
            index: props.index !== undefined ? parseInt(props.index) : 0,
            open : []
        };

        this.state.open.push(this.state.index);
    }

    handleClickTab(index, child) {
        if (index !== this.state.index) {
            this.setState(state => { 
                state.index = index;
                state.open.push(index);
                return state;
            }, e => {
                if (this.props.onChangeTab !== undefined) {
                    this.props.onChangeTab(index);
                }

                if (child.props.onChangeTab !== undefined) {
                    child.props.onChangeTab(index);
                }

                if (child.props.onOpenTab !== undefined) {
                    child.props.onOpenTab(index);
                }
            });
        }
    }

    render() {

        if (this.props.show !== undefined && !Boolean(this.props.show)) {
            return (<></>);
        }

        var tabs = React.Children.toArray(this.props.children).filter(child => (child.type === Tab || child.type === TabDropdown));
        var footer = React.Children.toArray(this.props.children).find(child => (child.type === TabFooter));

        const props = Object.assign({}, this.props);
        props.className = `card ${props.className !== undefined ? props.className : ""}`;

        delete props.onChangeTab;
        delete props.opened;
        delete props.show;
        

        return (
            <div {...props}>
                <div className="card-header">
                    {this.props.onClose !== undefined ?
                        <div className="position-relative">
                            <button type="button" className="position-absolute top-0 end-0 btn-close " onClick={this.props.onClose}></button>
                        </div>
                        : <></>}

                    <ul className="nav nav-tabs card-header-tabs">
                        {tabs.map((child, index) => {
                            let title = child.props.label || child.props.title;
                            let disabled = child.props.disabled === undefined ? false : child.props.disabled;

                            if(child.props.show !== undefined && child.props.show === false){
                                return <React.Fragment key={index}></React.Fragment>
                            }

                            if(child.type === Tab){
                                return (
                                    <li key={index} className="nav-item">
                                        <button disabled={disabled} onClick={e => this.handleClickTab(index, child)} className={`nav-link ${index === this.state.index ? "active" : ""}`} data-bs-toggle="tab" type="button" role="tab" aria-selected="true">
                                            {title}
                                        </button>
                                    </li>
                                );
                            }else if(child.type === TabDropdown){
                                return (
                                    <li key={index} className="nav-item dropdown">
                                        <button type="button" className="nav-link dropdown-toggle" data-bs-toggle="dropdown" role="tab" aria-expanded="false">{title}</button>
                                        <ul className="dropdown-menu">
                                            {child.props.children}
                                        </ul>
                                    </li>
                                );
                            }
                            return <React.Fragment key={index}></React.Fragment>
                        })}
                    </ul>
                </div>

                <div className="card-body p-0 m-0">
                    <div className="tab-content m-0 my-0">
                        {tabs.map((child, index) => {

                            if(child.type === Tab){

                                let pps = Object.assign({}, child.props);

                                if(pps.show !== undefined && pps.show === false){
                                    return <React.Fragment key={index}></React.Fragment>
                                }

                                pps.className = `tab-pane fade ${index === this.state.index ? "show active" : ""} ${pps.className ? pps.className : ""}`
                                delete pps.title;
                                delete pps.opened;
                                delete pps.onOpenTab;
                                delete pps.show;

                                return (
                                    <div key={index} role="tabpanel" {...pps}>
                                        {(child.props.opened !== undefined && child.props.opened === true ) || (this.props.opened !== undefined && this.props.opened === true ) || this.state.open.findIndex(i => parseInt(i) === parseInt(index)) !== -1 ? child : <></>}
                                    </div>
                                );
                            }

                            return <React.Fragment key={index}></React.Fragment>
                        })}
                    </div>
                </div>
                {footer ? footer : <></>}
            </div>
        );
    }
}