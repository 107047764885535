//#NO-CHANGE-FILE#
import React from "react";
import { useNavigate, useLocation, useParams } from "react-router";
import Config from "./Config";
import { BaseCrud, BaseList } from "../../siht/base";
import { BtnButton, Div, H6 } from "../../siht/elements";
import { Modal } from "../../siht/controller";
import FormDadoValor from "./FormDadoValor";
import FormDadoValorFormItem from "./FormDadoValorFormItem";
import DadoLocalService from "../../services/DadoLocalService";
import DadoService from "../../services/DadoService";
import DadoLocalGrupoService from "../../services/DadoLocalGrupoService";
import { Card, CardBody, CardHeader } from "../../siht/components";
import { IconDelete } from "../../icons";
import DadoValorService from "../../services/DadoValorService";
import { Plus as IconPlus} from "react-bootstrap-icons";

class ListDadoValorByParentForm extends BaseList {

	constructor(props) {
		super(props);
		this.getItens = this.getItens.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleNew = this.handleNew.bind(this);
		this.handleEdit = this.handleEdit.bind(this);
		this.handleCopy = this.handleCopy.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.handleMore = this.handleMore.bind(this);
		this.onSubmitFilter = this.onSubmitFilter.bind(this);
		this.handleClear = this.handleClear.bind(this);
		this.handleFormSuccess = this.handleFormSuccess.bind(this);
		this.handleFormClose = this.handleFormClose.bind(this);
		this.handleAddDadoValor = this.handleAddDadoValor.bind(this);
		this.handleChangeItem = this.handleChangeItem.bind(this);
		this.handleEventOnChange = this.handleEventOnChange.bind(this);

		this.getGrupos = this.getGrupos.bind(this);
		this.getDados = this.getDados.bind(this);

		this.state.dadoLocal = null;
		this.state.dadoLocalGrupo = [];
		this.state.dados = [];		
		this.state.valores = [];
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		this.getGrupos();
	}

	getGrupos(){
		DadoLocalService.getOneByCdDadoLocal(this.props.cdDadoLocal, (responseDadoLocal)=>{
			DadoLocalGrupoService.getAllByDadoLocal(responseDadoLocal, (responseDadoLocalGrupo) => {				
				this.setState({dadoLocal : responseDadoLocal, dadoLocalGrupo : responseDadoLocalGrupo}, this.getDados);
			});
		})
	}

	getDados(){
		DadoService.getForCombo({dadoLocal : this.state.dadoLocal, flFormulario : true}, response => {
			this.setState({dados : response}, this.getItens);
		});
	}

	getItens(){
		if(this.props?.parent){
			Config.getService().listByParent({ parent : this.props.parent, cdDadoLocal : this.props.cdDadoLocal}, response => {

				this.setState(state => {
					state.valores = response;

					state.dados.forEach(dado => {

						let valores = state.valores.filter(v => parseInt(v?.dadoValor?.dado?.idDado) === parseInt(dado.idDado));
						
						for(let i=0; i < (dado.nrMinimoItem - valores.length); i++){
							state.valores.push({
								dadoValor : {
									dado : Object.assign({}, dado)
								}
							});
						}
					});

					return state;
				}, () => {
					if(this.props.onChange !== undefined){
						this.props.onChange(null, JSON.parse(JSON.stringify(this.state.valores)), this.props.name);
					}
				});
			});
			
		}
	}

	handleNew(e) {
		Modal.openWindow(FormDadoValor, {sizing : Modal.sizing.default}, { cdDadoLocal : this.props.cdDadoLocal, parent : this.props.parent }, (e, response) => { 
			this.getItens(false);
		});
	}

	handleEdit(e, item) {
		Modal.openWindow(FormDadoValor, {sizing : Modal.sizing.default}, { cdDadoLocal : this.props.cdDadoLocal, parent : this.props.parent, id : item.dadoValor.idDadoValor }, (e, response) => { 
			this.getItens(false);
		});
	}

	handleDelete(e, item) {
		Modal.confirm("Confirmação de exclusão", "Deseja realmente excluir o item?", "Essa é uma ação irreversível", Modal.style.danger, () =>{
			Config.getService().delete({
				cdDadoLocal : this.props.cdDadoLocal, 
				parent : this.props.parent,
				item : item
			}, () => {
				this.getItens(false);
			});
		});
	}

	handleCopy(e, item) {
		Modal.openWindow(FormDadoValor, {sizing : Modal.sizing.default}, { copy : true, cdDadoLocal : this.props.cdDadoLocal, parent : this.props.parent, id : item.dadoValor.idDadoValor }, (e, response) => { 
			this.getItens(false);
		});
	}

	handleAddDadoValor(dado){
		this.setState(state => {
			state.valores.push({
				dadoValor : {
					dado : dado									
				}
			});
			return state;
		}, this.handleEventOnChange);
	}

	handleDelDadoValor(index, item){
		Modal.confirm("Confirmação", "Deseja realmente EXCLUIR o item?", "Essa é uma ação irreversível" , Modal.style.danger, () => {
			
			if(item?.dadoValor?.idDadoValor){
				DadoValorService.delete({
					cdDadoLocal : this.props.cdDadoLocal, 
					parent : this.props.parent,
					item : item
				}, () => {
					this.setState(state => {
						state.valores.splice(index, 1);
						return state;
					}, this.handleEventOnChange);
				});
			}else{
				this.setState(state => {
					state.valores.splice(index, 1);
					return state;
				}, this.handleEventOnChange);
			}
		});
		
	}

	handleEventOnChange(e){
		if(this.props.onChange !== undefined){
			this.props.onChange(e, JSON.parse(JSON.stringify(this.state.valores)), this.props.name);
		}
	}

	handleChangeItem(e, v, ix, dado){
		this.setState(state => {
			state.valores[ix].dadoValor = v;
			return state;
		}, this.handleEventOnChange);
	}

	render() {

		let editable = this.props.editable !== undefined ? this.props.editable : true;

		if(this.state.dados.length === 0){
			return <></>;
		}

		return super.render (
			<Div className="p-2 mt-3">	

				<H6 className="border-dark border-bottom mb-2 pb-1 fw-bold"> Dados Complementares </H6>

				{this.state.dadoLocalGrupo.map((dadoLocal, indexDadoLocalGrupo) => {

					let dados = this.state.dados.filter(d => parseInt(d?.dadoLocalGrupo.idDadoLocalGrupo) === parseInt(dadoLocal.idDadoLocalGrupo))

					if(dados.length === 0){
						return <></>;
					}

					return (
						<Div key={indexDadoLocalGrupo}>
							
							<Card className="my-3 border-primary">
								<CardHeader className="fw-bold bg-primary-subtle py-1">
									{dadoLocal.nmDadoLocalGrupo}
								</CardHeader>
								<CardBody>

									{dados.map((dado, indexDado) => {
									
										let valor = this.state.valores.filter(v => parseInt(v?.dadoValor?.dado?.idDado) === parseInt(dado.idDado));
										let count = 0;

										return (
											<React.Fragment key={indexDado}>
												
												<Div className="d-flex fs-7 border-primary border-bottom mb-3 pb-1 fw-bold" show={valor.length < parseInt(dado.nrMaximoItem) || parseInt(dado.nrMaximoItem) > 1}> 
													<Div className="flex-grow-1">
														{dado.nmDado}
													</Div>
													<Div show={valor.length < parseInt(dado.nrMaximoItem) && editable}>
														<BtnButton className="p-0 text-nowrap" disabled={!editable || !dado.flAtivo} color={BtnButton.color.link} sizing={BtnButton.sizing.sm} onClick={e => this.handleAddDadoValor(dado)}>
															<IconPlus/>
														</BtnButton>
													</Div>
												</Div>

												{this.state.valores.map((d, ixValor) => {
													if(parseInt(d?.dadoValor?.dado?.idDado) === parseInt(dado.idDado)){
														let label = `${dado.nmDado}`;
														if(parseInt(dado.nrMaximoItem) > 1){
															label = <>{dado.nmDado} <small className="fst-italic text-primary">({(count + 1)})</small></>;
														}

														count++;

														return (
															<Div className="d-flex" key={ixValor}>
																<Div className="flex-grow-1">
																	<FormDadoValorFormItem label={label} editable={editable && dado.flAtivo} onChange={(e, v) => this.handleChangeItem(e, v, ixValor, dado)} cdDadoLocal={this.props.cdDadoLocal} dado={dado} dadoValor={d?.dadoValor} parent={this.props.parent} localOpen={BaseCrud.localOpen.embedded}/>
																</Div>
																<Div className="d-flex align-items-center justify-content-center">
																	<BtnButton className="text-nowrap" sizing={BtnButton.sizing.sm} color={BtnButton.color.outlineDanger} onClick={e => this.handleDelDadoValor(ixValor, d)} disabled={parseInt(dado.nrMinimoItem) >= valor.length || !editable || !dado.flAtivo}>
																		<IconDelete/>
																	</BtnButton>
																</Div>
															</Div>
														);														
													}
													return <React.Fragment key={ixValor}></React.Fragment>;
												})}

												{/*<Div sizing={12} className="d-flex align-items-end justify-content-end" show={valor.length < parseInt(dado.nrMaximoItem) && editable}>
													<BtnButton disabled={!editable || !dado.flAtivo} color={BtnButton.color.outlinePrimary} sizing={BtnButton.sizing.sm} onClick={e => this.handleAddDadoValor(dado)}> Adicionar Valor </BtnButton>
												</Div>*/}
												
											</React.Fragment>
										);					
									})}
							</CardBody>
							</Card>
						</Div>
					);
				})}
			</Div>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <ListDadoValorByParentForm {...props} navigate={navigate} params={params} location={location}/>
}
export default With