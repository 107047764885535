import Http from "../controllers/Http";

export default class EquipeService {

	static urlBase = "/equipe/";

	static getForCombo(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(EquipeService.urlBase + 'forCombo', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static list(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(EquipeService.urlBase + 'list', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneById(id, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.get(EquipeService.urlBase + id)
			.then(fnSucess)
			.catch(fnError);
	}

	static create(data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(EquipeService.urlBase, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static update(id, data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.put(EquipeService.urlBase + id, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static delete(id, fnSucess = () => { }, fnError = () => { }) {
		if(Number.isInteger(id)){
			Http.getInstanceAppJson()
				.delete(EquipeService.urlBase + id)
				.then(fnSucess)
				.catch(fnError);
		}else{
			Http.getInstanceAppJson()
			.post(EquipeService.urlBase + 'delete', id)
			.then(fnSucess)
			.catch(fnError);
		}
	}

	static save(data, fnSucess = () => { }, fnError = () => { }) {
		if (data.idEquipe) {
			this.update(data.idEquipe, data, fnSucess, fnError);
		} else {
			this.create(data, fnSucess, fnError);
		}
	}

	static getAllByLocalizacao(localizacao, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(EquipeService.urlBase + 'localizacao', localizacao)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByLocalizacao(localizacao, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(EquipeService.urlBase + 'localizacao/' + localizacao.idLocalizacao)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByEquipePessoa(equipePessoa, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(EquipeService.urlBase + 'equipePessoa', equipePessoa)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByEquipePessoa(equipePessoa, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(EquipeService.urlBase + 'equipePessoa/' + equipePessoa.idEquipePessoa)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByEquipeUnidade(equipeUnidade, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(EquipeService.urlBase + 'equipeUnidade', equipeUnidade)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByEquipeUnidade(equipeUnidade, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(EquipeService.urlBase + 'equipeUnidade/' + equipeUnidade.idEquipeUnidade)
			.then(fnSucess)
			.catch(fnError);
	}
}