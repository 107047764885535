
import FormStatusConta from "./FormStatusConta.js";
import ListStatusConta from "./ListStatusConta.js";
import { Permissao } from "../../controllers";
import { Util } from "../../utils";
import { DadoValorService, StatusContaService } from "../../services";

export default class Config {

	static getUrlForm() {
		return "/CadStatusConta";
	}

	static getUrlList() {
		return "/LstStatusConta";
	}

	static getComponentForm() {
		return FormStatusConta;
	}

	static getPermissionForm() {
		return Permissao.getInstance().getPermissoes([193]);
	}

	static getPermissionList() {
		return Permissao.getInstance().getPermissoes([193]);
	}

	static getComponentList() {
		return ListStatusConta;
	}

	static getService() {
		return StatusContaService;
	}

	static getKeyId() {
		return "idStatusConta";
	}

	static getKeyDescription() {
		return "nmStatusConta";
	}

	static setDefaultValues(state , fnCallBack = () => {}, className = undefined) {
		className = className === undefined ? "FormStatusConta" : className;
		let keyNameScreen = Util.toToUpperTextCamelCase(className, "_") + ".";
		var prefix = "CONFIGURACAO.";
		DadoValorService.getValues(`${prefix}${keyNameScreen}`, (response)=> {
			Util.setValueObjectCamelCase(state.model, response, keyNameScreen, prefix);
			fnCallBack();
		}, fnCallBack);
	}
}