import React, { Component } from "react";

export default class BackgroundContainer extends Component {

    render() {
        return (
            <div className="background-container">
                <svg data-name="Layer 1" id="Layer_1" viewBox="0 0 512 512" width="75%" height="75%" xmlns="http://www.w3.org/2000/svg"><path d="M196,282.66c85,62.83,150.46,72.28,182.12,72.28,12.63,0,19.88-1.5,20.84-1.72a6.22,6.22,0,0,0,1.41-11.64c-1.51-.75-152.35-77-270.13-270.65A6.22,6.22,0,0,0,119,72.06C118.59,73.27,76.67,194.47,196,282.66Zm7.39-10c-89-65.78-82.63-150.37-76.09-182.86C221.1,238.41,333.08,316,376.43,342.3,346.24,341.78,283.66,332,203.34,272.66Z"/><path d="M288.52,388.5c39.66,0,61-8.92,62.4-9.52a6.22,6.22,0,0,0-1.47-11.85c-.82-.13-82.08-13.58-147.67-49.21-64.51-35-80.06-76.85-80.21-77.27a6.32,6.32,0,0,0-6.33-4.11,6.24,6.24,0,0,0-5.63,5c-.2,1-18.61,103.12,84.6,132.6C232.41,385.09,264,388.5,288.52,388.5Zm31.27-14.72c-24.56,3.52-65.94,4.5-122.16-11.57-66.25-18.92-76.84-69.59-77.26-98.14,11.45,16.91,33.95,42.22,75.47,64.78C239.93,352.79,289.65,366.8,319.79,373.78Z"/><path d="M138.85,388.83a6.22,6.22,0,0,0,0,7.16c1.47,2.06,34.88,48.06,90.49,48.06,3,0,6-.13,9.07-.4,60-5.32,84.58-32.7,85.6-33.87A6.2,6.2,0,0,0,325,403a6.1,6.1,0,0,0-5.93-3.48c-1,0-105.4,5.53-173.43-13.1A6.26,6.26,0,0,0,138.85,388.83ZM162.21,403c49.42,9.84,108,10.18,137.91,9.54-12.23,7.3-32.61,16-62.78,18.68C203,434.32,176.9,416.53,162.21,403Z"/></svg>
            </div>
        )
    }
}