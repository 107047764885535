import Http from "../controllers/Http";

export default class VwContaPagarParcelaService {

	static urlBase = "/vwContaPagarParcela/";

	static getForCombo(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(VwContaPagarParcelaService.urlBase + 'forCombo', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static list(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(VwContaPagarParcelaService.urlBase + 'list', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneById(id, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.get(VwContaPagarParcelaService.urlBase + id)
			.then(fnSucess)
			.catch(fnError);
	}


	static getAllByContaPagarParcela(contaPagarParcela, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(VwContaPagarParcelaService.urlBase + 'contaPagarParcela', contaPagarParcela)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByContaPagarParcela(contaPagarParcela, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(VwContaPagarParcelaService.urlBase + 'contaPagarParcela/' + contaPagarParcela.idContaPagarParcela)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByStatusConta(statusConta, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(VwContaPagarParcelaService.urlBase + 'statusConta', statusConta)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByStatusConta(statusConta, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(VwContaPagarParcelaService.urlBase + 'statusConta/' + statusConta.idStatusConta)
			.then(fnSucess)
			.catch(fnError);
	}
}