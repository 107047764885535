//#NO-CHANGE-FILE#

import Http from "../controllers/Http";

export default class AnsPerfilGolpeService {

	static urlBase = "/ansPerfilGolpe/";

	static promover(data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(AnsPerfilGolpeService.urlBase + 'promover', data)
			.then(fnSucess)
			.catch(fnError);
	}

	static rebaixar(data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(AnsPerfilGolpeService.urlBase + 'rebaixar', data)
			.then(fnSucess)
			.catch(fnError);
	}

	static getForCombo(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(AnsPerfilGolpeService.urlBase + 'forCombo', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static list(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(AnsPerfilGolpeService.urlBase + 'list', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneById(id, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.get(AnsPerfilGolpeService.urlBase + id)
			.then(fnSucess)
			.catch(fnError);
	}

	static create(data, fnSucess = () => { }, fnError = () => { }, showLoadding = true) {
		Http.getInstanceAppJson()
			.post(AnsPerfilGolpeService.urlBase, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static update(id, data, fnSucess = () => { }, fnError = () => { }, showLoadding = true) {		
		Http.getInstanceAppJson({showLoadding : showLoadding, showMessageResponseSuccess : showLoadding})
			.put(AnsPerfilGolpeService.urlBase + id, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static delete(id, fnSucess = () => { }, fnError = () => { }) {
		if(Number.isInteger(id)){
			Http.getInstanceAppJson()
				.delete(AnsPerfilGolpeService.urlBase + id)
				.then(fnSucess)
				.catch(fnError);
		}else{
			Http.getInstanceAppJson()
			.post(AnsPerfilGolpeService.urlBase + 'delete', id)
			.then(fnSucess)
			.catch(fnError);
		}
	}

	static save(data, fnSucess = () => { }, fnError = () => { }, showLoadding = true) {
		if (data.idAnsPerfilGolpe) {
			this.update(data.idAnsPerfilGolpe, data, fnSucess, fnError, showLoadding);
		} else {
			this.create(data, fnSucess, fnError);
		}
	}

	static getAllByAnsPerfil(ansPerfil, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(AnsPerfilGolpeService.urlBase + 'ansPerfil', ansPerfil)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByAnsPerfil(ansPerfil, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(AnsPerfilGolpeService.urlBase + 'ansPerfil/' + ansPerfil.idAnsPerfil)
			.then(fnSucess)
			.catch(fnError);
	}
}