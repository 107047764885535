//#NO-CHANGE-FILE#

import React from "react";
import { useNavigate, useLocation, useParams } from "react-router";
import Config from "./Config";
import { BaseList } from "../../siht/base";
import { DataGrid, DataGridColumn, Badge, DataGridButtons } from "../../siht/components";
import { Button, Div, Span } from "../../siht/elements";
import Util from "../../utils/Util";
import { BadgeSimNao, StatusCustom } from "../../components/Custom";
import BtnView from "../../components/BtnView";
import FormFaturaCobranca from "../FaturaCobranca/FormFaturaCobranca";
import { Modal } from "../../siht/controller";

class ListFaturaAcordoFaturaCobranca extends BaseList {

	constructor(props) {
		super(props);
		this.getItens = this.getItens.bind(this);		
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		super.init(Config);
	}

	getItens(more){
		if(this.props?.parent){
			super.getItens(Config.getService().getAllByFaturaAcordo, this.props.parent, more);
		}
	}

	handleEdit(e, item) {
		super.handleEdit(e, Config, item);
	}

	handleVerCobranca(e, item) {
		Modal.openWindow(FormFaturaCobranca, {sizing : Modal.sizing.xl}, { id : item.faturaCobranca.idFaturaCobranca }, (e, response) => { 
			//this.getItens();
		});
	}

	render() {
		return super.render(
			<Div className="p-2 mt-2">

				<DataGrid bordered={true} caption={false} striped={true} data={this.state.data} search={false} isDisabled={i => false} sizing={DataGrid.sizing.sm} select={false}>
												
					<DataGridColumn col={1} label="Fatura" field={i => i.faturaCobranca.fatura.idFatura} className="text-center"/>
					<DataGridColumn col={1} label="Cobrança" field={i => i.faturaCobranca.idFaturaCobranca} className="text-center"/>
					<DataGridColumn col={1} label="Parcela" field={i => i.faturaCobranca.nrParcela} className="text-center"/>
					<DataGridColumn col={1} label="Status" field={i => <StatusCustom className="w-100" color={i.faturaCobranca.statusFaturaCobranca}> {i.faturaCobranca.statusFaturaCobranca?.nmStatusFaturaCobranca} </StatusCustom>} className="text-center"/>
					
					<DataGridColumn col={1} label="Encargos" field={i => (<React.Fragment>
						<Div className="fs-7">Juros: {i.faturaCobranca.flJuros ? <Span className="fw-bold"> {Util.floatToValor(i.faturaCobranca.vlJurosHoje, 2)} % </Span> : <BadgeSimNao value={i.faturaCobranca.flJuros}/>} </Div>
						<Div className="fs-7">Multa: {i.faturaCobranca.flMulta ? <Span className="fw-bold"> {Util.floatToValor(i.faturaCobranca.vlMultaHoje, 2)} % </Span> : <BadgeSimNao value={i.faturaCobranca.flMulta}/>} </Div>
					</React.Fragment>)} className="text-end"/>
					
					<DataGridColumn col={1} label="Cobrança" field={i => <React.Fragment>
						<Span className="fw-bold text-primary"> {Util.floatToReais(i.faturaCobranca.vlCobranca)}  </Span>
					</React.Fragment>} className="text-end"/>

					<DataGridColumn col={1} label="Aberto" field={i => <React.Fragment>
						<Div className={`fw-bold ${i.faturaCobranca.vlAberto > 0 ? "text-danger" : "text-secondary"}`}> {Util.floatToReais(i.faturaCobranca.vlAberto)}  </Div>
						
						{i.faturaCobranca.flVencido && (i.faturaCobranca.flJuros || i.faturaCobranca.flMulta) ?
							<React.Fragment>
								<Div className="fs-7">Juros: {i.faturaCobranca.flJuros ? <Span className={`fw-bold ${i.faturaCobranca.flJuros === 0 ? "text-secondary" : "text-danger"}`}> {Util.floatToReais(i.faturaCobranca.vlJurosHoje, 2)} </Span> : <></>} </Div>
								<Div className="fs-7">Multa: {i.faturaCobranca.flMulta ? <Span className={`fw-bold ${i.faturaCobranca.flMulta === 0 ? "text-secondary" : "text-danger"}`}> {Util.floatToReais(i.faturaCobranca.vlMultaHoje, 2)} </Span> : <></>} </Div>
							</React.Fragment> : <></>
						}
					</React.Fragment>} className="text-end"/>

					<DataGridColumn col={2} label="Baixas" field={i => <React.Fragment>
						<Div className={`fw-bold ${i.faturaCobranca.vlBaixa === 0 ? "text-secondary" : "text-success"}`}> {Util.floatToReais(i.faturaCobranca.vlBaixa)}  </Div>
						<Div className={`fs-7`}> Pagamentos: <Span className={`fw-bold ${i.faturaCobranca.vlPago === 0 ? "text-secondary" : "text-success"}`}>{Util.floatToReais(i.faturaCobranca.vlPago)}</Span>  </Div>
						<Div className={`fs-7`}> Descontos: <Span className={`fw-bold ${i.faturaCobranca.vlDesconto === 0 ? "text-secondary" : "text-danger"}`}>{Util.floatToReais(i.faturaCobranca.vlDesconto)}</Span>  </Div>

						{i.faturaCobranca.vlBaixaEncargo > 0 ?
							<Div className={`fs-7`}> Encargos: <Span className={`fw-bold ${i.faturaCobranca.vlBaixaEncargo === 0 ? "text-secondary" : "text-danger"}`}>{Util.floatToReais(i.faturaCobranca.vlBaixaEncargo)}</Span>  </Div>
						: <></>}
					</React.Fragment>} className="text-end"/>

					<DataGridColumn col={1} label="Taxas" field={i => <React.Fragment>
						<Span className={`fw-bold ${i.faturaCobranca.vlBaixaTaxa === 0 ? "text-secondary" : "text-success"}`}> {Util.floatToReais(i.faturaCobranca.vlBaixaTaxa)}  </Span>
					</React.Fragment>} className="text-end"/>

					<DataGridColumn col={1} label="Baixas" field={i => <React.Fragment>
						<Span className={`fw-bold ${i.faturaCobranca.vlBaixaFinal === 0 ? "text-secondary" : "text-success"}`}> {Util.floatToReais(i.faturaCobranca.vlBaixaFinal)}  </Span>
					</React.Fragment>} className="text-end"/>

					<DataGridColumn col={1} label="Vencimento" field={i => <>
						<Div> {Util.date2Br(i.faturaCobranca.dtVencimento)} </Div>
						{i.faturaCobranca.flVencido && !i.faturaCobranca.statusFaturaCobranca.flPaga ? <Badge color={Badge.color.danger}>Vencido</Badge> : <></>}

					</>} className="text-center"/>

					<DataGridButtons>
                        <BtnView sizing={Button.sizing.default} onClick={this.handleVerCobranca}/>
					</DataGridButtons>

				</DataGrid>
			</Div>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <ListFaturaAcordoFaturaCobranca {...props} navigate={navigate} params={params} location={location}/>
}
export default With