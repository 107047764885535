import { Component } from "react";
import EventBus from "../controllers/EventBus";

export default class LoadingContainer extends Component {

    constructor(props) {
        super(props);

        this.addLoading = this.addLoading.bind(this);
        this.delLoading = this.delLoading.bind(this);

        this.count = 0;

        this.state = {
            count: this.count,
            message: "Carregando"
        };
    }

    componentDidMount() {
        EventBus.on("loading-add", (element) => {
            this.addLoading(element);
        });

        EventBus.on("loading-del", (element) => {
            this.delLoading(element);
        });
    }

    addLoading() {
        this.count++;
        this.setState({ count: this.count});
    }

    delLoading() {
        this.count--;
        setTimeout(() => {
            this.setState({ count: this.count});
        }, 10);
    }

    render() {
        //console.log(this.state.count);
        
        return (
            this.state.count > 0 ?
                <div className="loading-container-show">
                    <div className="loading-body">
                        {/*<div className='loading-message'>{this.state.message}</div>*/}
                        <div className="loading-container">
                            <div className="spinner-border text-primary" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </div>
                    </div>
                </div>
                : <></>
        );
    }
}
