import { Component } from "react";
import {BtnButton, Button} from "../../siht/elements";
import IconNew from "../../icons/IconNew";

export default class BtnNew extends Component {

    render() {
        if(this.props.show !== undefined && !Boolean(this.props.show)){
            return (<></>);
        }

        const props = Object.assign({}, this.props);
        props.color = Button.color.outlinePrimary;

        return (
            <BtnButton {...props}>
                <IconNew/> {this.props.children}
            </BtnButton>
        );
    }
}