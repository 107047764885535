import BtnButton from "../elements/BtnButton";
import Modal from "../controller/Modal";
import { Button, Field, InputText, TextArea } from "../elements";

export default class OneToOneForm extends Field {

    constructor(props) {
        super(props);

        this.handleClear = this.handleClear.bind(this);
        this.handleAdd = this.handleAdd.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.resultForm = this.resultForm.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.formCrud = null;
        this.state.props = {};
    }

    getPermissionFormCrud(){
        return false;
    }

    componentDidUpdate(nextProps) {
        super.componentDidUpdate(nextProps);
    }
    
    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    handleClear(){
        var e = {target : this.ref.current};
        super.handleChange(e, null, this.props.name);
    }

    resultForm(e, response){
        super.handleChange(e, response.data, this.props.name);
    }

    handleAdd(formCrud) {
        formCrud = formCrud || this.formCrud;

        let props = { };
        props = {...props, ...this.state.props};

        if(formCrud){
            Modal.openWindow(formCrud, {sizing : Modal.sizing.xl}, props, this.resultForm);
        }
    }

    handleEdit(formCrud) {
        formCrud = formCrud || this.formCrud;

        let props = { id : this.getOptionValue(this.props?.value) };
        props = {...props, ...this.state.props};

        if(formCrud){
            Modal.openWindow(formCrud, {sizing : Modal.sizing.xl}, props, this.resultForm);
        }
    }

    render() {

        if(this.props.show !== undefined && !Boolean(this.props.show)){
            return (<></>);
        }

        var buttons = {
            search: this.props?.buttons?.search !== undefined ? this.props?.buttons?.search : true,
            new : this.props?.buttons?.new !== undefined ? this.props?.buttons?.new : true,
            edit : this.props?.buttons?.edit !== undefined ? this.props?.buttons?.edit : true,
            clear : this.props?.buttons?.clear !== undefined ? this.props?.buttons?.clear : true,
        };

        if(this.props.buttons === false){
            buttons = {
                search: false,
                new : false,
                edit : false,
                clear : false,
            };
        }

        /*if(buttons.new){
            buttons.new = this.getPermissionFormCrud();
        }
        
        if(buttons.edit){
            buttons.edit = this.getPermissionFormCrud();
        }*/

        const props = Object.assign({}, this.props);
        props.value = this.getOptionLabel(props.value) || "";
        props.className = `readonly-field ${props.className !== undefined ? props.className : ""}`;
        props.onClick = props.value ? e => this.handleEdit() : e => this.handleAdd();

        delete props.show;

        var Component = props.type === "InputText" ? InputText : TextArea;

        delete props.onChange;
        delete props.type;
        delete props.flSomenteCidade;

        return (
            <div className="input-group">
                <Component {...props} feedback={false} style={{resize : "none"}}/>
                <BtnButton show={buttons.clear} color={Button.color.outlineDanger} sizing={this.props.sizing} onClick={this.handleClear} disabled={this.props.disabled || this.getOptionValue(this.props?.value) === undefined}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                    </svg>
                </BtnButton>                
                <BtnButton show={buttons.new} color={Button.color.outlineSuccess} sizing={this.props.sizing} onClick={e => this.handleAdd()} disabled={this.props.disabled}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-lg" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z" />
                    </svg>
                </BtnButton>
                <BtnButton show={buttons.edit} color={Button.color.outlinePrimary} sizing={this.props.sizing} onClick={e => this.handleEdit()} disabled={this.props.disabled || this.getOptionValue(this.props?.value) === undefined}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil-square" viewBox="0 0 16 16">
                        <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                        <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                    </svg>
                </BtnButton>
                {super.getFeedback()}
            </div>            
        );
    }
}