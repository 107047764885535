import React from "react";
import Field from "./Field";
import { Functions } from "../util";

export default class SelectMultiple extends Field {

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.state.options = this.props.options || [];
    }

    componentDidUpdate(nextProps) {
        super.componentDidUpdate(nextProps);
        if (nextProps.filter !== undefined && this.props.filter !== undefined) {
            if (!this.objEqual(nextProps.filter, this.props.filter)) {
                this.getOptions();
            }
        }
    }

    componentDidMount() {
        super.componentDidMount();
        this.getOptions();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    handleChange(e, selected, name) {

        if(selected === undefined){
            const options = [...e.target.selectedOptions];            
            const values = options.filter(option => option.value.toString().length > 0).map(option => option.value);
            selected = values.map(v => this.state.options.find(o => this.getOptionValue(o)?.toString() === v?.toString()));
        }

        if(!name){
            name = e?.target?.name;
        }

        super.handleChange(e, selected, name);
    }

    isDisabled(item) {
        return false;
    }

    getForComboService() {
        return null;
    }

    getDefault() {
        return null;
    }

    getOptions(callBack) {
        var filter = this.props.filter !== undefined ? this.props.filter : {};
        callBack = callBack !== undefined && Functions.isFunction(callBack) ? callBack : ()=>{};

        var getForComboService = this.getForComboService();

        if (this.getOptionsWhen(filter) && getForComboService !== null) {
            getForComboService((filter), (response) => {
                this.setState({ options: response }, () =>{                
                    if(!this.getOptionValue(this.props.value)){
                        let item = this.getDefault();
                        if(item){
                            this.handleChange({}, item, this.props.name);
                        }
                    }
                    callBack();
                });               
            });
        }else if(!this.props.options){
            this.setState({ options: [] }, callBack);
            
        }
    }

    getOptionValue(item) {
        return item?.value;
    }

    getOptionLabel(item) {
        return item?.label;
    }

    getOptionClass(item) {
        return "";
    }

    getOptionsWhen(item) {
        var filter = this.props.filter !== undefined ? this.props.filter : {};
        return this.props.getOptionsWhen === undefined || this.props.getOptionsWhen(filter);
    }

    render(feedback = true) {
        const props = Object.assign({}, this.props);
        delete props.show;
        props.className = `form-select ${props.className ? props.className : ""} ${props.sizing ? "form-select-" + props.sizing : ""} ${props.readOnly !== undefined && props.readOnly ? "readonly-field" : ""}`;
        props.onChange = this.handleChange;
        
        props.value = Array.isArray(props.value) ? props.value.map(i => this.getOptionValue(i)) : [];
        props.ref = this.props.refParam ? this.props.refParam : this.ref;

        var element =
            <select multiple={true} {...this.getProps(props)}>
                <option value="">{this.props.placeholder !== undefined ? this.props.placeholder : "Selecione"}</option>
                {this.state.options.map((option, index) => (
                    <option key={index}
                        className={this.getOptionClass(option)}
                        value={this.getOptionValue(option)}
                        disabled={this.isDisabled(option)}> {this.getOptionLabel(option)} </option>
                ))}
            </select>;

        return super.render(element, feedback);
    }
}