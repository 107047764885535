import Http from "../controllers/Http";

export default class FaturaCobrancaBaixaService {

	static urlBase = "/faturaCobrancaBaixa/";

	static getForCombo(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(FaturaCobrancaBaixaService.urlBase + 'forCombo', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static list(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(FaturaCobrancaBaixaService.urlBase + 'list', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneById(id, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.get(FaturaCobrancaBaixaService.urlBase + id)
			.then(fnSucess)
			.catch(fnError);
	}

	static create(data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(FaturaCobrancaBaixaService.urlBase, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static update(id, data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.put(FaturaCobrancaBaixaService.urlBase + id, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static delete(id, fnSucess = () => { }, fnError = () => { }) {
		if(Number.isInteger(id)){
			Http.getInstanceAppJson()
				.delete(FaturaCobrancaBaixaService.urlBase + id)
				.then(fnSucess)
				.catch(fnError);
		}else{
			Http.getInstanceAppJson()
			.post(FaturaCobrancaBaixaService.urlBase + 'delete', id)
			.then(fnSucess)
			.catch(fnError);
		}
	}

	static save(data, fnSucess = () => { }, fnError = () => { }) {
		if (data.idFaturaCobrancaBaixa) {
			this.update(data.idFaturaCobrancaBaixa, data, fnSucess, fnError);
		} else {
			this.create(data, fnSucess, fnError);
		}
	}

	static getAllByFaturaCobrancaTransacao(faturaCobrancaTransacao, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(FaturaCobrancaBaixaService.urlBase + 'faturaCobrancaTransacao', faturaCobrancaTransacao)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByFaturaCobrancaTransacao(faturaCobrancaTransacao, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(FaturaCobrancaBaixaService.urlBase + 'faturaCobrancaTransacao/' + faturaCobrancaTransacao.idFaturaCobrancaTransacao)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByFaturaCobranca(faturaCobranca, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(FaturaCobrancaBaixaService.urlBase + 'faturaCobranca', faturaCobranca)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByFaturaCobranca(faturaCobranca, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(FaturaCobrancaBaixaService.urlBase + 'faturaCobranca/' + faturaCobranca.idFaturaCobranca)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByTipoBaixa(tipoBaixa, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(FaturaCobrancaBaixaService.urlBase + 'tipoBaixa', tipoBaixa)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByTipoBaixa(tipoBaixa, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(FaturaCobrancaBaixaService.urlBase + 'tipoBaixa/' + tipoBaixa.idTipoBaixa)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByUsuario(usuario, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(FaturaCobrancaBaixaService.urlBase + 'usuario', usuario)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByUsuario(usuario, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(FaturaCobrancaBaixaService.urlBase + 'usuario/' + usuario.idUsuario)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByFaturaAcordo(faturaAcordo, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(FaturaCobrancaBaixaService.urlBase + 'faturaAcordo', faturaAcordo)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByFaturaAcordo(faturaAcordo, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(FaturaCobrancaBaixaService.urlBase + 'faturaAcordo/' + faturaAcordo.idFaturaAcordo)
			.then(fnSucess)
			.catch(fnError);
	}
}