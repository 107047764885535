
import FormComissaoPessoaOrdem from "./FormComissaoPessoaOrdem.js";
import ListComissaoPessoaOrdem from "./ListComissaoPessoaOrdem.js";
import { Permissao } from "../../controllers";
import { Util } from "../../utils";
import { DadoValorService, ComissaoPessoaOrdemService } from "../../services";

export default class Config {

	static getUrlForm() {
		return "/CadComissaoPessoaOrdem";
	}

	static getUrlList() {
		return "/LstComissaoPessoaOrdem";
	}

	static getComponentForm() {
		return FormComissaoPessoaOrdem;
	}

	static getPermissionForm() {
		return Permissao.getInstance().getPermissoes([207]);
	}

	static getPermissionList() {
		return Permissao.getInstance().getPermissoes([207]);
	}

	static getComponentList() {
		return ListComissaoPessoaOrdem;
	}

	static getService() {
		return ComissaoPessoaOrdemService;
	}

	static getKeyId() {
		return "idComissaoPessoaOrdem";
	}

	static getKeyDescription() {
		return "nmComissaoPessoaOrdem";
	}

	static setDefaultValues(state , fnCallBack = () => {}, className = undefined) {
		className = className === undefined ? "FormComissaoPessoaOrdem" : className;
		let keyNameScreen = Util.toToUpperTextCamelCase(className, "_") + ".";
		var prefix = "CONFIGURACAO.";
		DadoValorService.getValues(`${prefix}${keyNameScreen}`, (response)=> {
			Util.setValueObjectCamelCase(state.model, response, keyNameScreen, prefix);
			fnCallBack();
		}, fnCallBack);
	}
}