
import FormTipoTelefone from "./FormTipoTelefone.js";
import ListTipoTelefone from "./ListTipoTelefone.js";
import { Permissao } from "../../controllers";
import { Util } from "../../utils";
import { DadoValorService, TipoTelefoneService } from "../../services";

export default class Config {

	static getUrlForm() {
		return "/CadTipoTelefone";
	}

	static getUrlList() {
		return "/LstTipoTelefone";
	}

	static getComponentForm() {
		return FormTipoTelefone;
	}

	static getPermissionForm() {
		return Permissao.getInstance().getPermissoes([16]);
	}

	static getPermissionList() {
		return Permissao.getInstance().getPermissoes([16]);
	}

	static getComponentList() {
		return ListTipoTelefone;
	}

	static getService() {
		return TipoTelefoneService;
	}

	static getKeyId() {
		return "idTipoTelefone";
	}

	static getKeyDescription() {
		return "nmTipoTelefone";
	}

	static setDefaultValues(state , fnCallBack = () => {}, className = undefined) {
		className = className === undefined ? "FormTipoTelefone" : className;
		let keyNameScreen = Util.toToUpperTextCamelCase(className, "_") + ".";
		var prefix = "CONFIGURACAO.";
		DadoValorService.getValues(`${prefix}${keyNameScreen}`, (response)=> {
			Util.setValueObjectCamelCase(state.model, response, keyNameScreen, prefix);
			fnCallBack();
		}, fnCallBack);
	}
}