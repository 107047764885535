//#NO-CHANGE-FILE#

import Http from "../controllers/Http";

export default class FaturaCobrancaTransacaoService {

	static urlBase = "/faturaCobrancaTransacao/";

	static getAtiva(payload, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post('/public' + FaturaCobrancaTransacaoService.urlBase + 'ativa', payload)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByCdUid(cdUid, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.get('public' + FaturaCobrancaTransacaoService.urlBase + cdUid)
			.then(fnSucess)
			.catch(fnError);
	}

	static getStatusByCdUid(cdUid, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson({showLoadding : false})
			.get('public' + FaturaCobrancaTransacaoService.urlBase + 'status/' + cdUid)
			.then(fnSucess)
			.catch(fnError);
	}

	static pagarCartaoCredito(payload, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post('/public' + FaturaCobrancaTransacaoService.urlBase + 'pagar/cartao-credito/' + payload.faturaCobrancaTransacao.cdUid, payload)
			.then(fnSucess)
			.catch(fnError);
	}

	static estornar(faturaCobrancaTransacao, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(FaturaCobrancaTransacaoService.urlBase + 'estornar', faturaCobrancaTransacao)
			.then(fnSucess)
			.catch(fnError);
	}

	static expirar(faturaCobrancaTransacao, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(FaturaCobrancaTransacaoService.urlBase + 'expirar', faturaCobrancaTransacao)
			.then(fnSucess)
			.catch(fnError);
	}

	static getForCombo(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(FaturaCobrancaTransacaoService.urlBase + 'forCombo', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static list(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(FaturaCobrancaTransacaoService.urlBase + 'list', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneById(id, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.get(FaturaCobrancaTransacaoService.urlBase + id)
			.then(fnSucess)
			.catch(fnError);
	}

	static create(data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(FaturaCobrancaTransacaoService.urlBase, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static update(id, data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.put(FaturaCobrancaTransacaoService.urlBase + id, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static delete(id, fnSucess = () => { }, fnError = () => { }) {
		if(Number.isInteger(id)){
			Http.getInstanceAppJson()
				.delete(FaturaCobrancaTransacaoService.urlBase + id)
				.then(fnSucess)
				.catch(fnError);
		}else{
			Http.getInstanceAppJson()
			.post(FaturaCobrancaTransacaoService.urlBase + 'delete', id)
			.then(fnSucess)
			.catch(fnError);
		}
	}

	static save(data, fnSucess = () => { }, fnError = () => { }) {
		if (data.idFaturaCobrancaTransacao) {
			this.update(data.idFaturaCobrancaTransacao, data, fnSucess, fnError);
		} else {
			this.create(data, fnSucess, fnError);
		}
	}

	static getAllByFaturaCobranca(faturaCobranca, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(FaturaCobrancaTransacaoService.urlBase + 'faturaCobranca', faturaCobranca)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByFaturaCobranca(faturaCobranca, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(FaturaCobrancaTransacaoService.urlBase + 'faturaCobranca/' + faturaCobranca.idFaturaCobranca)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByTipoTransacao(tipoTransacao, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(FaturaCobrancaTransacaoService.urlBase + 'tipoTransacao', tipoTransacao)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByTipoTransacao(tipoTransacao, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(FaturaCobrancaTransacaoService.urlBase + 'tipoTransacao/' + tipoTransacao.idTipoTransacao)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByTipoPagamento(tipoPagamento, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(FaturaCobrancaTransacaoService.urlBase + 'tipoPagamento', tipoPagamento)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByTipoPagamento(tipoPagamento, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(FaturaCobrancaTransacaoService.urlBase + 'tipoPagamento/' + tipoPagamento.idTipoPagamento)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByConta(conta, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(FaturaCobrancaTransacaoService.urlBase + 'conta', conta)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByConta(conta, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(FaturaCobrancaTransacaoService.urlBase + 'conta/' + conta.idConta)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByUsuario(usuario, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(FaturaCobrancaTransacaoService.urlBase + 'usuario', usuario)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByUsuario(usuario, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(FaturaCobrancaTransacaoService.urlBase + 'usuario/' + usuario.idUsuario)
			.then(fnSucess)
			.catch(fnError);
	}
}