import Config from "./Config";
import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { BaseCrud } from "../../siht/base";
import { FormButtons, FormItem, Row, Panel, PanelBody, PanelFooter, Container, ModalDialog } from "../../siht/components";
import { InputText, InputColor, Form } from "../../siht/elements";
import { BtnRadioGroupSimNao } from "../../components/Custom";

class FormStatusNotaFiscal extends BaseCrud {

	constructor(props) {
		super(props);
		this.init = this.init.bind(this);
		this.initModel = this.initModel.bind(this);
		this.handleSuccess = this.handleSuccess.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleChangeModel = this.handleChangeModel.bind(this);
		this.handleSubmitForm = this.handleSubmitForm.bind(this);
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		super.init(Config);
	}

	initModel(){
		return {
			idStatusNotaFiscal : null,
			nmStatusNotaFiscal : "",
			cdStatusNotaFiscal : "",
			cdCorFundo : "#000000",
			cdCorTexto : "#FFFFFF",
			cdCorBorda : "#FFFFFF",
			flGerada : true,
			flEstorno : false,
			flIgnorada : true,
			flPodeEnviar : true,
			flPodeEstornar : true,
			flPodeEstornarFatura : true,
			flPodeIgnorar : true,
			flPodeEditar : true
		}
	}

	handleSuccess(e) {
		super.handleSuccess(e, Config);
	}

	handleClose(e) {
		super.handleClose(e, Config);
	}

	render() {
		return super.render(

					<Form onSubmit={this.handleSuccess} refParam={f => (this.form = f)} key={this.state.key}>
						<Panel key={this.state.key} title="Cadastro de Status da Nota Fiscal" localOpen={this.props.localOpen}  onClose={this.handleClose} isModal={this.isModal()} container={{sizing: Container.sizing.xxl, className : ""}} modal={{sizing: this.props.sizing ? this.props.sizing : ModalDialog.sizing.xl}}>
							<PanelBody>
								<Row className="g-3 pb-2 px-2">
									<FormItem md={6} label="Código">
										<InputText name="idStatusNotaFiscal" value={this.state.model.idStatusNotaFiscal} readOnly={true} className="readonly-field" required={false} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={12} label="Nome da Status da Nota Fiscal">
										<InputText name="nmStatusNotaFiscal" value={this.state.model.nmStatusNotaFiscal} autoFocus={true} maxLength={100} required={true} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={6} label="CdStatusNotaFiscal">
										<InputText name="cdStatusNotaFiscal" value={this.state.model.cdStatusNotaFiscal} maxLength={100} required={true} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={2} label="Cor Fundo">
										<InputColor name="cdCorFundo" value={this.state.model.cdCorFundo} maxLength={7} required={true} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={2} label="Cor Texto">
										<InputColor name="cdCorTexto" value={this.state.model.cdCorTexto} maxLength={7} required={true} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={2} label="Cor Borda">
										<InputColor name="cdCorBorda" value={this.state.model.cdCorBorda} maxLength={7} required={true} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={2} label="FlGerada">
										<BtnRadioGroupSimNao name="flGerada" value={this.state.model.flGerada} required={true} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={2} label="Estorno?">
										<BtnRadioGroupSimNao name="flEstorno" value={this.state.model.flEstorno} required={true} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={2} label="FlIgnorada">
										<BtnRadioGroupSimNao name="flIgnorada" value={this.state.model.flIgnorada} required={true} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={2} label="FlPodeEnviar">
										<BtnRadioGroupSimNao name="flPodeEnviar" value={this.state.model.flPodeEnviar} required={true} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={2} label="FlPodeEstornar">
										<BtnRadioGroupSimNao name="flPodeEstornar" value={this.state.model.flPodeEstornar} required={true} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={2} label="FlPodeEstornarFatura">
										<BtnRadioGroupSimNao name="flPodeEstornarFatura" value={this.state.model.flPodeEstornarFatura} required={true} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={2} label="FlPodeIgnorar">
										<BtnRadioGroupSimNao name="flPodeIgnorar" value={this.state.model.flPodeIgnorar} required={true} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={2} label="FlPodeEditar">
										<BtnRadioGroupSimNao name="flPodeEditar" value={this.state.model.flPodeEditar} required={true} onChange={this.handleChangeModel}/>
									</FormItem>
								</Row>
							</PanelBody>
							<PanelFooter>
								<FormButtons onClose={this.handleClose} />
							</PanelFooter>
						</Panel>
					</Form>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <FormStatusNotaFiscal {...props} navigate={navigate} params={params} location={location}/>
}
export default With