
import FormGrupoProduto from "./FormGrupoProduto.js";
import ListGrupoProduto from "./ListGrupoProduto.js";
import { Permissao } from "../../controllers";
import { Util } from "../../utils";
import { DadoValorService, GrupoProdutoService } from "../../services";

export default class Config {

	static getUrlForm() {
		return "/CadGrupoProduto";
	}

	static getUrlList() {
		return "/LstGrupoProduto";
	}

	static getComponentForm() {
		return FormGrupoProduto;
	}

	static getPermissionForm() {
		return Permissao.getInstance().getPermissoes([53]);
	}

	static getPermissionList() {
		return Permissao.getInstance().getPermissoes([53]);
	}

	static getComponentList() {
		return ListGrupoProduto;
	}

	static getService() {
		return GrupoProdutoService;
	}

	static getKeyId() {
		return "idGrupoProduto";
	}

	static getKeyDescription() {
		return "nmGrupoProduto";
	}

	static setDefaultValues(state , fnCallBack = () => {}, className = undefined) {
		className = className === undefined ? "FormGrupoProduto" : className;
		let keyNameScreen = Util.toToUpperTextCamelCase(className, "_") + ".";
		var prefix = "CONFIGURACAO.";
		DadoValorService.getValues(`${prefix}${keyNameScreen}`, (response)=> {
			Util.setValueObjectCamelCase(state.model, response, keyNameScreen, prefix);
			fnCallBack();
		}, fnCallBack);
	}
}