import { Util } from "../../utils";
import { Modal } from "../controller";
import BaseComponent from "./BaseComponent";

export default class BaseList extends BaseComponent {

    static localOpen = {
        modal: "MODAL",
        embedded: "EMBEDDED",
        newPage: "NEW_PAGE",
        default: "NEW_PAGE"
    };

    constructor(props) {
        super(props);

        this.handleChangeTab = this.handleChangeTab.bind(this);

        this.state = this.initState();
        this.state.filter = this.initStateFilter();
        this.state.data = [];
        this.state.selecteds = [];
        this.state.selected = {};
        this.state.hasMore = true;
        this.state.key = Math.random();
        this.state.edit = { id: "", key: 0 };
        this.state.localOpen = props.localOpen !== undefined ? props.localOpen : BaseList.localOpen.default
        this.state.tabIndex = 0;

        this.cache = {
            filter: {},
        };
    }

    handleMore() {
        this.getItens(true);
    }

    componentDidMount() {
        super.componentDidMount(() => {
            this.init();
        });
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    init(Config, fnCallBack = () => { }, refreshKey = true) {
        this.getItens();
        if (refreshKey) {
            this.setState({ key: Math.random() }, fnCallBack);
        } else {
            fnCallBack();
        }
    }

    initState() {
        return {
        };
    }

    initStateFilter() {
        return {
            search: "",
            page: 1,
            pageSize: 15,
        };
    }

    onSubmitFilter() {
        this.getItens();
    }

    handleClear() {
        this.setState(state => {
            state.filter = this.initStateFilter();
            state.key = Math.random();
            return state;
        }, this.getItens);
    }

    getItens(service, filter, more = false, fnCallBack = () => { }) {

        filter = filter ? filter : Object.assign({}, this.state.filter);

        if (!more) {
            filter.page = 1;
        } else {
            filter.page += 1;
        }

        //filter.page = filter.page !== undefined ? filter.page : state.filter.page;
        //filter.pageSize = filter.pageSize !== undefined ? filter.pageSize : this.state.filter.pageSize;

        service(filter, data => {
            if (more) {
                this.setState(state => {
                    state.data = state.data.concat(data);
                    state.key = Math.random();
                    state.hasMore = (data.length === parseInt(this.state.filter.pageSize)) && data.length > 0;
                    state.filter.page += 1;
                    return state;
                }, fnCallBack);

            } else {
                this.setState(state => {
                    state.data = data;
                    state.key = Math.random();
                    state.hasMore = (data.length === parseInt(this.state.filter.pageSize));
                    state.filter.page = 1;
                    state.selecteds = [];
                    return state;
                }, fnCallBack);
            }
        }, fnCallBack);

        return;
    }

    handleNew(e, Config, object = false, fnCallBack = () => { }) {

        object = object || {};
        object.parent = this.props.parent;

        switch (this.state.localOpen) {
            case BaseList.localOpen.newPage:
                this.props.navigate(Config.getUrlForm(), { state: object });
                break;

            case BaseList.localOpen.embedded:
                this.setState(state => {
                    state.edit = {
                        id: "",
                        key: Math.random()
                    };
                    return state;
                });
                break;

            case BaseList.localOpen.modal:
                Modal.openWindow(Config.getComponentForm(), { sizing: Modal.sizing.xl }, object, () => {
                    this.getItens();
                    fnCallBack();
                    if (this.props.onChange !== undefined && Util.isFunction(this.props.onChange)) {
                        let fn = this.props.onChange;
                        fn();
                    }
                });
                break;

            default:
                break;
        }
    }

    handleCopy(e, Config, item, object = false, fnCallBack = () => { }) {
        object = object || {};
        object.id = item[Config.getKeyId()];
        object.copy = true;

        switch (this.state.localOpen) {
            case BaseList.localOpen.newPage:
                this.props.navigate(Config.getUrlForm() + "/" + object.id, { state: { copy: 1 } });
                break;

            case BaseList.localOpen.embedded:
                this.setState(state => {
                    state.edit = {
                        id: object.id,
                        key: Math.random(),
                        copy: true //verificar se vai funcionar
                    };
                    return state;
                });
                break;

            case BaseList.localOpen.modal:
                Modal.openWindow(Config.getComponentForm(), { sizing: Modal.sizing.xl }, object, () => {
                    this.getItens();
                    fnCallBack();
                    if (this.props.onChange !== undefined && Util.isFunction(this.props.onChange)) {
                        let fn = this.props.onChange;
                        fn();
                    }
                });
                break;

            default:
                break;
        }
    }

    handleEdit(e, Config, item, object = false, fnCallBack = () => { }) {
        object = object || {};
        object.id = item[Config.getKeyId()];

        switch (this.state.localOpen) {
            case BaseList.localOpen.newPage:
                this.props.navigate(Config.getUrlForm() + "/" + object.id);
                break;

            case BaseList.localOpen.embedded:
                this.setState(state => {
                    state.edit = {
                        id: object.id,
                        key: Math.random()
                    };
                    return state;
                });
                break;

            case BaseList.localOpen.modal:
                Modal.openWindow(Config.getComponentForm(), { sizing: Modal.sizing.xl }, object, () => {
                    this.getItens();
                    fnCallBack();
                    if (this.props.onChange !== undefined && Util.isFunction(this.props.onChange)) {
                        let fn = this.props.onChange;
                        fn();
                    }
                });
                break;

            default:
                break;
        }
    }

    handleDelete(e, Config, item, fnCallBack = () => { }, fnDescription = null) {

        if (fnDescription === null || !Util.isFunction(fnDescription)) {
            fnDescription = (item) => {
                return item[Config.getKeyDescription()];
            }
        }

        let param = Array.isArray(item) ? item : item[Config.getKeyId()];
        let description = Array.isArray(item) ? param.map(i => fnDescription(i)) : fnDescription(item);

        Modal.confirm("Confirmação de exclusão", "Deseja realmente excluir o item?", description ? description : "Essa é uma ação irreversível", Modal.style.danger, () => {
            Config.getService().delete(param, () => {
                this.getItens();

                if (fnCallBack !== null && Util.isFunction(fnCallBack)) {
                    fnCallBack();
                }

                if (this.props.onChange !== undefined && Util.isFunction(this.props.onChange)) {
                    let fn = this.props.onChange;
                    fn();
                }
            })
        })
    }

    handleFormSuccess(e) {
        this.setState(state => {
            state.edit = {
                id: "",
                key: 0
            };
            return state;
        }, this.getItens);
    }

    handleFormClose(e) {
        this.setState(state => {
            state.edit = {
                id: "",
                key: 0
            };
            return state;
        });
    }

    getFormEmbedded(Config) {
        var Component = Config.getComponentForm();
        if (this.state.localOpen === BaseList.localOpen.embedded && this.state.edit.key > 0) {
            return <Component key={this.state.edit.key} id={this.state.edit.id} localOpen={BaseList.localOpen.embedded} parent={this.props.parent} handleSuccess={this.handleFormSuccess} handleClose={this.handleFormClose} />;
        }
        return <></>;
    }

    isModal() {
        return this.props.localOpen === BaseList.localOpen.modal;
    }

    handleChangeTab(index) {
        this.setState({ tabIndex: parseInt(index) });
    }

    render(render) {
        return render;
    }
}