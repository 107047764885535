import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Auth from "../../controllers/Auth";
import eventBus from "../../controllers/EventBus";
import LoginService from "../../services/LoginService";
import { BaseComponent } from "../../siht/base";

class ModalSessaoExpiradaLogin extends BaseComponent {

    constructor(props) {
        super(props);

        this.handleClose = this.handleClose.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onSubmit = this.onSubmit.bind(this);

        this.state = {
            element: {},
            show: false,
            eventBusOpen: "response-error-401",
            model: {
                usuario: "",
                senha: ""
            }
        };

    }

    componentDidMount() {
        eventBus.on(this.state.eventBusOpen, (element) => {
            var usuario = Auth.getUsuarioDataToken();

            if (Auth.isAuthenticated() && usuario) {
                this.setState({ show: true, element: element, model: { usuario: usuario, senha: "" } });
                Auth.logout();
            }
        });
    }

    componentWillUnmount() {
        this.removeAllEventBus();
    }

    removeAllEventBus() {
        eventBus.remove(this.state.eventBusOpen);
    }

    handleClose() {
        this.setState({ show: false });
        this.props.navigate('/login');
    }

    onSubmit(event) {
        LoginService.login(this.state.model, (response) => {
            this.setState({ show: false });
            toast.success("Olá, Bem vindo de volta!");
        });
    }

    render() {

        return (
            <>
                <div className={`fade modal-backdrop show ${this.state.show ? "" : "d-none"}`}> </div>
                <div className={`modal ${this.state.show ? "d-block" : "d-none"}`} id="exampleModal" tabIndex="-1"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="false" aria-modal="true">

                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Sessão bloqueada!</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={this.handleClose}></button>
                            </div>
                            <div className="modal-body">
                                <div className="modal-body p-5 pt-0">
                                    <div className="text-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" fill="currentColor" className="bi bi-person-x" viewBox="0 0 16 16">
                                            <path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H1s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C9.516 10.68 8.289 10 6 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z" />
                                            <path fillRule="evenodd" d="M12.146 5.146a.5.5 0 0 1 .708 0L14 6.293l1.146-1.147a.5.5 0 0 1 .708.708L14.707 7l1.147 1.146a.5.5 0 0 1-.708.708L14 7.707l-1.146 1.147a.5.5 0 0 1-.708-.708L13.293 7l-1.147-1.146a.5.5 0 0 1 0-.708z" />
                                        </svg>
                                    </div>
                                    <form onSubmit={this.handleSubmit} noValidate className={`needs-validation ${this.state.validated ? "was-validated" : ""}`}>
                                        <div className="form-floating mb-3">
                                            <input type="text" className="form-control rounded-4" id="usuario" value={this.state.model.usuario} disabled />
                                            <label htmlFor="usuario">Usuário</label>
                                        </div>
                                        <div className="form-floating mb-3">
                                            <input type="password" className="form-control rounded-4" id="senha" placeholder="Senha" value={this.state.model.senha} name="senha" onChange={this.handleChange} required={true} />
                                            <label htmlFor="senha">Password</label>
                                        </div>
                                        <button className="w-100 mb-2 btn btn-lg rounded-4 btn-primary" type="submit">Login</button>
                                        <hr className="my-4"></hr>
                                        <h2 className="fs-5 fw-bold mb-3 text-center">Ou</h2>
                                        <button className="w-100 py-2 mb-2 btn btn-outline-dark rounded-4" type="button" onClick={this.handleClose}>
                                            Sair
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </>
        )
    }
}

function With(props) {
    let navigate = useNavigate();
    let params = useParams();
    return <ModalSessaoExpiradaLogin {...props} navigate={navigate} params={params} />
}

export default With
