import Config from "./Config";
import React from "react";
import { useNavigate, useLocation, useParams } from "react-router";
import { BaseList } from "../../siht/base";
import { DataGrid, DataGridButtons, DataGridColumn, Panel, Container, Row, InputGroup, InputGroupText, InputGroupButton, DropDown, DropDownLabel, DropDownItem, Accordion, AccordionItem, FormItem, BtnRadioGroup, DataGridFooter, DropDownDivider } from "../../siht/components";
import { Button, Div, Form, InputSearch, BtnSubmit, BtnButton, Span, InputDate, BtnRadio } from "../../siht/elements";
import { FormItemEmpresaUnidade, FormItemTipoPessoaPessoa } from "../../components/FormItem";
import { ThreeDotsVertical as IconThreeDotsVertical, Clipboard as IconClipboard, ClockHistory as IconClockHistory, Coin as IconCoin, ListOl as IconListOl } from "react-bootstrap-icons";
import { Modal } from "../../siht/controller";
import { IconClear, IconDelete, IconSearch } from "../../icons";
import { BtnNew, StatusCustom } from "../../components/Custom";
import Util from "../../utils/Util";
import ContaReceberParcelaService from "../../services/ContaReceberParcelaService";
import ContaPagarParcelaService from "../../services/ContaPagarParcelaService";
import SelectStatusConta from "../../components/SelectDBMultiple/SelectStatusConta";
import FormContaReceber from "../ContaReceber/FormContaReceber"
import FormContaPagar from "../ContaPagar/FormContaPagar"
import FormContaReceberParcela from "../ContaReceberParcela/FormContaReceberParcela"
import FormContaPagarParcela from "../ContaPagarParcela/FormContaPagarParcela"
import FormContaReceberBaixa from "../ContaReceberBaixa/FormContaReceberBaixa"
import FormContaPagarBaixa from "../ContaPagarBaixa/FormContaPagarBaixa"
import ListContaReceberBaixa from "../ContaReceberBaixa/ListContaReceberBaixa"
import ListContaPagarBaixa from "../ContaPagarBaixa/ListContaPagarBaixa"
import FormFatura from "../Fatura/FormFatura"
import FormFaturaCobranca from "../FaturaCobranca/FormFaturaCobranca";
import ContaPagarService from "../../services/ContaPagarService";
import ContaReceberService from "../../services/ContaReceberService";


class ListContaExtrato extends BaseList {

	constructor(props) {
		super(props);
		this.getItens = this.getItens.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleChangeFilter = this.handleChangeFilter.bind(this);
		this.handleNew = this.handleNew.bind(this);		
		this.handleMore = this.handleMore.bind(this);
		this.onSubmitFilter = this.onSubmitFilter.bind(this);
		this.handleClear = this.handleClear.bind(this);
		this.handleFormSuccess = this.handleFormSuccess.bind(this);
		this.handleFormClose = this.handleFormClose.bind(this);

		this.getContaReceber = this.getContaReceber.bind(this);
		this.getContaPagar = this.getContaPagar.bind(this);
		this.getContas = this.getContas.bind(this);
		this.dataPrepare = this.dataPrepare.bind(this);

		this.handleNewContaReceber = this.handleNewContaReceber.bind(this);
		this.handleNewContaPagar = this.handleNewContaPagar.bind(this);
		this.handleEditConta = this.handleEditConta.bind(this);
		this.handleEditParcela = this.handleEditParcela.bind(this);
		this.handleBaixar = this.handleBaixar.bind(this);
		this.handleHistoricoBaixa = this.handleHistoricoBaixa.bind(this);
		this.handleEditFatura = this.handleEditFatura.bind(this);
		this.handleEditCobranca = this.handleEditCobranca.bind(this);
		this.handleDeleteConta = this.handleDeleteConta.bind(this);

		this.state.data = [];
		this.state.contaReceber = [];
		this.state.contaPagar = [];
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		super.init(Config);
	}

	initStateFilter(){
        return {
			cdTipo : "T",
			cdAgrupador : "UNIDADE",
			unidade : null,
            pessoa : null,
			statusContas : [],
			search: "",
			dtVencimentoStart : "",
			dtVencimentoEnd : "",			
        };
    }

	getItens(more){
		this.getContas();		
	}

	getContas(){
		this.setState({contaReceber : [], contaPagar : []}, () => {
			this.getContaReceber();
		});		
	}

	getContaReceber(){
		if(this.state.filter.cdTipo === "T" || this.state.filter.cdTipo === "R"){
			ContaReceberParcelaService.list(this.state.filter, response => {
				this.setState({contaReceber : response}, this.getContaPagar);
			});
		}else{
			this.getContaPagar();
		}
	}

	getContaPagar(){
		if(this.state.filter.cdTipo === "T" || this.state.filter.cdTipo === "P"){
			ContaPagarParcelaService.list(this.state.filter, response => {
				this.setState({contaPagar : response}, this.dataPrepare);
			});
		}else{
			this.dataPrepare();
		}
	}

	anoMes(date){
		return {
			ano : date.getFullYear(),
			mes : date.getMonth()
		};
	}

	dataPrepare(){
		this.setState(state => {
			let data = [];
			let contas = [];

			switch (this.state.filter.cdAgrupador) {
				case "UNIDADE":
					contas = Util.arrayOrderBy([...state.contaReceber, ...state.contaPagar], "dtVencimento");

					let unidades = contas.map(c => c?.contaReceber?.unidade || c?.contaPagar?.unidade).filter((obj, index, self) =>
						index === self.findIndex((c) => c?.idUnidade === obj?.idUnidade)
					);

					unidades = Util.arrayOrderBy(unidades, "nmUnidade");
		
					unidades.forEach(unidade => {
						data.push({
							nmGrupo : unidade.nmUnidade,
							contas : contas.filter(c => parseInt((c?.contaReceber?.unidade || c?.contaPagar?.unidade).idUnidade) === parseInt(unidade.idUnidade))
						});
					});

				break;
				
				case "ANO_MES":
					var arrMes = ["jan","fev","mar","abr","mai", "jun","jul","ago","set","out","nov","dez"];
					contas = Util.arrayOrderBy([...state.contaReceber, ...state.contaPagar], "dtVencimento");

					let datas = contas.map(c => this.anoMes(new Date(c?.dtVencimento))).filter((obj, index, self) =>
						index === self.findIndex((c) => c.ano === obj.ano && c.mes === obj.mes)
					);
		
					datas.forEach(anoMes => {
						data.push({
							nmGrupo : arrMes[anoMes.mes] + "/" + anoMes.ano,
							contas : contas.filter(c => this.anoMes(new Date(c?.dtVencimento)).ano === anoMes.ano && this.anoMes(new Date(c?.dtVencimento)).mes === anoMes.mes)
						});
					});
				break;

				case "ORIGEM":
					data.push({
						nmGrupo : "Receber",
						contas : Util.arrayOrderBy(state.contaReceber, "dtVencimento")
					});
		
					data.push({
						nmGrupo : "Pagar",
						contas : Util.arrayOrderBy(state.contaPagar, "dtVencimento")
					});
				break;

				case "PESSOA":
					contas = Util.arrayOrderBy([...state.contaReceber, ...state.contaPagar], "dtVencimento");

					let pessoas = contas.map(c => c?.contaReceber?.pessoa || c?.contaPagar?.pessoa).filter((obj, index, self) =>
						obj && index === self.findIndex((c) => c?.idPessoa === obj?.idPessoa)
					);

					pessoas = Util.arrayOrderBy(pessoas, "nmPessoa");
		
					pessoas.forEach(pessoa => {
						data.push({
							nmGrupo : pessoa?.nmPessoa,
							contas : contas.filter(c => parseInt((c?.contaReceber?.pessoa || c?.contaPagar?.pessoa)?.idPessoa) === parseInt(pessoa?.idPessoa))
						});
					});

					data.push({
						nmGrupo : "SEM VÍCULO DE PESSOA",
						contas : contas.filter(c => (c?.contaReceber?.pessoa || c?.contaPagar?.pessoa)?.idPessoa === undefined)
					});

				break;
			
				default:
					break;
			}

			state.data = data;
			return state;
		});		
	}

	handleNewContaReceber(e) {
		Modal.openWindow(FormContaReceber, {sizing : Modal.sizing.xl}, {}, (e, response) => { 
			this.getItens();
		});
	}

	handleNewContaPagar(e) {
		Modal.openWindow(FormContaPagar, {sizing : Modal.sizing.xl}, {}, (e, response) => { 
			this.getItens();
		});
	}

	handleEditConta(e, item) {
		if(item.contaReceber !== undefined){
			Modal.openWindow(FormContaReceber, {sizing : Modal.sizing.xl}, { id : item.contaReceber.idContaReceber}, (e, response) => { 
				this.getItens();
			});
		}else if(item.contaPagar !== undefined){
			Modal.openWindow(FormContaPagar, {sizing : Modal.sizing.xl}, { id : item.contaPagar.idContaPagar}, (e, response) => { 
				this.getItens();
			});
		}
	}

	handleEditParcela(e, item) {
		if(item.contaReceber !== undefined){
			Modal.openWindow(FormContaReceberParcela, {sizing : Modal.sizing.default}, { id : item.idContaReceberParcela}, (e, response) => { 
				this.getItens();
			});
		}else if(item.contaPagar !== undefined){
			Modal.openWindow(FormContaPagarParcela, {sizing : Modal.sizing.default}, { id : item.idContaPagarParcela}, (e, response) => { 
				this.getItens();
			});
		}
	}

	handleBaixar(e, item) {
		if(item.contaReceber !== undefined){
			Modal.openWindow(FormContaReceberBaixa, {sizing : Modal.sizing.default}, { parent: item }, (e, response) => { 
				this.getItens();
			});			
		}else if(item.contaPagar !== undefined){
			Modal.openWindow(FormContaPagarBaixa, {sizing : Modal.sizing.default}, { parent: item }, (e, response) => { 
				this.getItens();
			});
		}
	}

	handleHistoricoBaixa(e, item) {
		if(item.contaReceber !== undefined){
			Modal.open(ListContaReceberBaixa, {sizing : Modal.sizing.xl}, { parent: item }, this.getItens, this.getItens);
		}else if(item.contaPagar !== undefined){
			Modal.open(ListContaPagarBaixa, {sizing : Modal.sizing.xl}, { parent: item }, this.getItens, this.getItens);
		}
	}

	handleEditFatura(e, item) {
		Modal.openWindow(FormFatura, {sizing : Modal.sizing.xl}, { id : item.faturaCobranca?.fatura?.idFatura}, (e, response) => { 
			this.getItens();
		});		
	}

	handleEditCobranca(e, item) {
		Modal.openWindow(FormFaturaCobranca, {sizing : Modal.sizing.xl}, { id : item.faturaCobranca?.idFaturaCobranca}, (e, response) => { 
			this.getItens();
		});
	}

	handleDeleteConta(e, item) {
		Modal.confirm("Confirmação", "Deseja realmente EXCLUIR essa conta?" , null, Modal.style.danger, () =>{
			if(item.contaReceber !== undefined){
				ContaReceberService.delete(item.contaReceber.idContaReceber, () =>{
					this.getItens();
				});	
			}else if(item.contaPagar !== undefined){
				ContaPagarService.delete(item.contaPagar.idContaPagar, () =>{
					this.getItens();
				});	
			}
					
		});
	}

	getTotal(contas){
		return contas.reduce((soma, conta) => {
			return soma + conta.vlConta;
		  }, 0);

	}

	render() {
		return super.render(
			<Panel title="Carteira" isModal={this.isModal()} container={{sizing: Container.sizing.fluid, className : ""}}>
				<Div className="d-flex justify-content-end flex-nowrap gap-2">
					<BtnNew onClick={this.handleNewContaReceber}>Conta Receber</BtnNew>
					<BtnNew onClick={this.handleNewContaPagar}>Conta Pagar</BtnNew>
				</Div>

				<Form onSubmit={this.onSubmitFilter}>
					<Row className="g-3 mb-1 mt-1">
						<FormItem md={3} label="Agrupador">
							<BtnRadioGroup name="cdAgrupador" color={BtnRadioGroup.color.primary} opcional={true} value={this.state.filter.cdAgrupador} onClick={this.handleChangeFilter}>
                                <BtnRadio value="UNIDADE" label="Unidade"/>
								<BtnRadio value="ORIGEM" label="Origem"/>
								<BtnRadio value="ANO_MES" label="Mês"/>
								<BtnRadio value="PESSOA" label="Cliente"/>
                            </BtnRadioGroup>
                        </FormItem>

						<FormItem md={3} label="Origem">
							<BtnRadioGroup name="cdTipo" color={BtnRadioGroup.color.primary} opcional={true} value={this.state.filter.cdTipo} onClick={this.handleChangeFilter}>
                                <BtnRadio value="T" label="Todas" />
								<BtnRadio value="P" label="À pagar" />
								<BtnRadio value="R" label="À receber" />
                            </BtnRadioGroup>
                        </FormItem>

						<FormItem md={2} label="Data Inicial">
							<InputDate name="dtVencimentoStart" value={this.state.filter.dtVencimentoStart} required={false} opcional={true} onChange={this.handleChangeFilter}/>
						</FormItem>
						
						<FormItem md={2} label="Data Final">
							<InputDate name="dtVencimentoEnd" min={this.state.filter.dtVencimentoStart} value={this.state.filter.dtVencimentoEnd} required={false} opcional={true} onChange={this.handleChangeFilter}/>
						</FormItem>

						<FormItem md={2} label="Status da Conta">
							<SelectStatusConta name="statusContas" value={this.state.filter.statusContas} required={false} opcional={true} onChange={this.handleChangeFilter} placeholder="Selecione o Status" buttons={{search : false, new : false, edit: false}}/>
						</FormItem>
					</Row>
					<Row className="g-3 my-1">
						<FormItemEmpresaUnidade md={[3,3]} name="unidade" opcional={true} value={this.state.filter.unidade} required={false} onChange={this.handleChangeFilter} buttons={{search : false, new : false, edit: false}}/>
						<FormItemTipoPessoaPessoa label={["Tipo Cliente", "Cliente"]} md={[2,4]} name="pessoa" value={this.state.filter.pessoa} required={false} onChange={this.handleChangeFilter} opcional={true}/>
					</Row>
					<Row className="mt-1 mb-2 g-3">
						<InputGroup className="flex-nowrap" sizing={InputGroup.sizing.default}>
							<InputGroupText><IconSearch/></InputGroupText>
							<InputSearch autoComplete="off" autoFocus placeholder="Digite sua busca" name="search" value={this.state.filter.search} required={false} opcional={true} onChange={(e,v,n) => this.handleChange(e,v,n, this.state.filter)}/>
							<InputGroupButton>
								<BtnSubmit title="Filtrar"> Buscar </BtnSubmit>
								<BtnButton title="Limpar Pesquisa" color={BtnButton.color.outlineDanger} onClick={this.handleClear}> <IconClear/> </BtnButton>
							</InputGroupButton>
						</InputGroup>
					</Row>
				</Form>
				
				<Accordion flush={false} alwaysOpen={true} className="mt-3">
					{this.state.data.map((grupo, indexGrupo) => (
						<AccordionItem key={indexGrupo} label={<Span>{grupo.nmGrupo} <strong>({grupo.contas.length})</strong></Span>}>
								<DataGrid caption={false} responsive={true} data={grupo.contas} search={false} isDisabled={i => false} sizing={DataGrid.sizing.sm} select={false} selecteds={this.state.selecteds} onSelect={(selecteds)=>{this.setState({selecteds : selecteds})}} getItemValue={i=>i.idContaReceber} onMore={this.handleMore}>
								
									<DataGridColumn label="Origem" field={i => i.contaReceber !== undefined ?
										<StatusCustom className="w-100" color={{cdCorFundo : "#FFFFFF", cdCorTexto : "#079600", cdCorBorda : "#079600"}}> À RECEBER </StatusCustom>
										: <StatusCustom className="w-100" color={{cdCorFundo : "#FFFFFF", cdCorTexto : "#ff0000", cdCorBorda : "#ff0000"}}> À PAGAR </StatusCustom>
										
									} className="text-center"/>
		
									<DataGridColumn col={3} label="Título" field={i => i.contaReceber?.idContaReceber ? i.contaReceber?.nmContaReceber : i.contaPagar?.nmContaPagar} className="text-start"/>
		
									<DataGridColumn md={3} label="Empresa / Unidade" field={i => <React.Fragment>
										<Div className="fw-bold">{i.contaReceber !== undefined ? i.contaReceber?.unidade?.empresa?.nmEmpresa : i.contaPagar?.unidade?.empresa?.nmEmpresa}</Div>
										<Div>{i.contaReceber !== undefined ? i.contaReceber?.unidade?.nmUnidade : i.contaPagar?.unidade?.nmUnidade}</Div>
									</React.Fragment>} className="text-start"/>
									
									<DataGridColumn col={4} label="Pessoa" field={i => {
											let pessoa = i.contaReceber?.idContaReceber ? i.contaReceber?.pessoa : i.contaPagar?.pessoa;
		
											if(pessoa){
												return (
													<React.Fragment>
														<Span className="fw-bold">{pessoa?.nmPessoa}</Span> <br/>
														<StatusCustom color={pessoa.tipoPessoa}> PESSOA {pessoa.tipoPessoa?.nmTipoPessoa} </StatusCustom>
														<Div className="fw-bold fs-7" show={pessoa.tipoPessoa?.cdTipoPessoa === "F"}>{Util.formataCPF(pessoa.nrCpf)}</Div>
														<Div className="fw-bold fs-7" show={pessoa.tipoPessoa?.cdTipoPessoa === "J"}>{Util.formataCnpj(pessoa.nrCnpj)}</Div>
													</React.Fragment>
												)
											}
		
											return <></>;
										}} className="text-start"/>
		
									<DataGridColumn col={2} label="Tipo" field={i =>
										i.contaReceber !== undefined ?
											<StatusCustom className="w-100" color={i.contaReceber?.tipoContaReceber}> {i.contaReceber?.tipoContaReceber?.nmTipoContaReceber} </StatusCustom> 
											: <StatusCustom className="w-100" color={i.contaPagar?.tipoContaPagar}> {i.contaPagar?.tipoContaPagar?.nmTipoContaPagar} </StatusCustom> 
										
									} className="text-center"/>
		
									<DataGridColumn label="Vencimento" field={i => Util.date2Br(i.dtVencimento)} className="text-center"/>
									<DataGridColumn label="Parcela" field={i => <Span> {i.nrParcela} / {i.contaReceber !== undefined ? i.contaReceber?.recorrencia?.nrParcela : i.contaPagar?.recorrencia?.nrParcela} </Span>} className="text-center"/>
									<DataGridColumn label="Valor" field={i => <Span className={`fw-bold ${i.contaReceber !== undefined ? "text-success" : "text-danger"}`}> {Util.floatToReais(i.vlConta)} </Span>} className="text-end"/>
									
									<DataGridColumn label="Status" field={i => 
										i.contaReceber?.idContaReceber ?
										<StatusCustom className="w-100" color={i.vwContaReceberParcela?.statusConta}> {i.vwContaReceberParcela?.statusConta?.nmStatusConta} </StatusCustom> 
										: <StatusCustom className="w-100" color={i.vwContaPagarParcela?.statusConta}> {i.vwContaPagarParcela?.statusConta?.nmStatusConta} </StatusCustom> 
									} className="text-center"/>
		
									<DataGridButtons>
										<BtnButton disabled={i => !i?.contaReceber?.flPodeBaixar && !i?.contaPagar?.flPodeBaixar} color={BtnButton.color.outlineSuccess} sizing={Button.sizing.default} onClick={this.handleBaixar}>
											<IconCoin/>
										</BtnButton>
										<BtnButton color={BtnButton.color.outlinePrimary} sizing={Button.sizing.default} onClick={this.handleEditConta}>
											<IconClipboard/>
										</BtnButton>
										<BtnButton color={BtnButton.color.outlinePrimary} sizing={Button.sizing.default} onClick={this.handleEditParcela}>
											<IconListOl/>
										</BtnButton>

										<DropDown alignment={DropDown.alignment.end}>
											<DropDownLabel sizing={Button.sizing.default} toggle={false}> <IconThreeDotsVertical/> </DropDownLabel>
											<DropDownItem disabled={i => !i?.contaReceber?.flPodeBaixar && !i?.contaPagar?.flPodeBaixar} title="Baixar" type="button" onClick={this.handleBaixar}> <IconCoin/> Baixar Parcela </DropDownItem>											
											<DropDownItem  title="Conta" type="button" onClick={this.handleEditConta}> <IconClipboard/> Ver Conta </DropDownItem>
											<DropDownItem  title="Parcela" type="button" onClick={this.handleEditParcela}> <IconListOl/> Ver Parcela </DropDownItem>
											<DropDownItem title="Baixas" type="button" onClick={this.handleHistoricoBaixa}> <IconClockHistory/> Histórico de Baixas </DropDownItem>
											<DropDownItem disabled={i => !i?.contaReceber?.flPodeExcluir && !i?.contaPagar?.flPodeExcluir} title="Excluir Registro" type="button" onClick={this.handleDeleteConta}> <IconDelete/> Excluir Conta </DropDownItem>
											<DropDownDivider/>
											<DropDownItem disabled={i => i?.faturaCobranca === null || i?.faturaCobranca === undefined} title="Fatura" type="button" onClick={this.handleEditFatura}> <IconCoin/> Ver Fatura </DropDownItem>
											<DropDownItem disabled={i => i?.faturaCobranca === null || i?.faturaCobranca === undefined} title="Cobrança" type="button" onClick={this.handleEditCobranca}> <IconListOl/> Ver Cobrança </DropDownItem>
										</DropDown>
									</DataGridButtons>

									<DataGridFooter>
										<DataGridColumn colSpan={7} className="text-center"/>
										<DataGridColumn label="Valor" className="text-end">
											<Span className="fw-bold text-success"> {Util.floatToReais(this.getTotal(grupo.contas))} </Span>
										</DataGridColumn>
										<DataGridColumn colSpan={2} className="text-center"/>
									</DataGridFooter>

								</DataGrid>
						</AccordionItem>
					))}
				</Accordion>				
			</Panel>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <ListContaExtrato {...props} navigate={navigate} params={params} location={location}/>
}
export default With