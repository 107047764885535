//#NO-CHANGE-FILE#

import ManyToManyCheckAnsCriterioParada from "../../components/ManyToManyCheck/ManyToManyCheckAnsCriterioParada";
import AnsPerfilParadaService from "../../services/AnsPerfilParadaService";

export default class ManyToManyCheckAnsPerfilParada extends ManyToManyCheckAnsCriterioParada {

	constructor(props) {
		super(props);

		this.onChangeAddItem = this.onChangeAddItem.bind(this);
		this.onChangeDelItem = this.onChangeDelItem.bind(this);
		this.onChangeAddAll = this.onChangeAddAll.bind(this);
		this.onChangeDelAll = this.onChangeDelAll.bind(this);
		this.getSelecteds = this.getSelecteds.bind(this);

		this.state.selectedsOriginal = [];
	}

	componentDidMount() {
		super.componentDidMount();
		this.getSelecteds();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	componentDidUpdate(nextProps) {
		if (nextProps.parent !== undefined && this.props.parent !== undefined) {
			if (!this.objEqual(nextProps.parent, this.props.parent)) {
				this.getSelecteds();
			}
		}
	}

	getOptionLabel(item) {
		return <>{item?.nmAnsCriterioParada} <small className="text-muted fst-italic">({item?.txAnsCriterioParada})</small></>;
	}


	getColumns() {
		return 1;
	}

	getSelecteds() {
		if (this.props?.parent?.idAnsPerfil || (Array.isArray(this.props?.parent) && this.props?.parent.length > 0)) {
			AnsPerfilParadaService.getAllByAnsPerfil(this.props.parent, (response) => {
				let selecteds = response.flatMap(i => i.ansCriterioParada);
				let noSelecteds = [];

				if (Array.isArray(this.props?.parent)) {
					selecteds = selecteds.filter((value, index, self) => self.findIndex((v) => parseInt(v.idAnsCriterioParada) === parseInt(value.idAnsCriterioParada)) === index);
					selecteds.forEach((selected, index) => {
						let ansPerfils = response.filter(i => parseInt(i.ansCriterioParada.idAnsCriterioParada) === (selected.idAnsCriterioParada)).flatMap(i => i.ansPerfil);
						if ((ansPerfils.length !== this.props.parent.length)) {
							noSelecteds.push(selected);
						}
					});

					selecteds = selecteds.filter(selected => !noSelecteds.find(ns => selected.idAnsCriterioParada === ns.idAnsCriterioParada));
				}

				this.setState({ selecteds: selecteds, selectedsOriginal: response, noSelecteds: noSelecteds });
			});
		}
	}

	onChangeAddItem(e, item) {
		var parents = Array.isArray(this.props.parent) ? this.props.parent : [this.props.parent];

		parents.forEach(parent => {
			var selected = this.state.selectedsOriginal.find(i => parseInt(i.ansPerfil.idAnsPerfil) === parseInt(parent.idAnsPerfil) && parseInt(i.ansCriterioParada.idAnsCriterioParada) === parseInt(item.idAnsCriterioParada));
			if (!selected) {
				AnsPerfilParadaService.save({
					ansCriterioParada: item,
					ansPerfil: parent
				}, response => {
					this.getSelecteds();
					super.onChangeAddItem(e, item);
				});
			}
		});
	};

	onChangeDelItem(e, item) {
		var parents = Array.isArray(this.props.parent) ? this.props.parent : [this.props.parent];

		parents.forEach(parent => {
			var selected = this.state.selectedsOriginal.find(i => parseInt(i.ansPerfil.idAnsPerfil) === parseInt(parent.idAnsPerfil) && parseInt(i.ansCriterioParada.idAnsCriterioParada) === parseInt(item.idAnsCriterioParada));
			if (selected) {
				AnsPerfilParadaService.delete(selected.idAnsPerfilParada, response => {
					this.getSelecteds();
					super.onChangeDelItem(e, item);
				});
			};
		});
	};

	onChangeAddAll(e, itens) {
		var parents = Array.isArray(this.props.parent) ? this.props.parent : [this.props.parent];

		parents.forEach(parent => {
			var obj = itens.filter(item =>
				!this.state.selectedsOriginal.find(i => parseInt(i.ansPerfil.idAnsPerfil) === parseInt(parent.idAnsPerfil) && parseInt(i.ansCriterioParada.idAnsCriterioParada) === parseInt(item.idAnsCriterioParada))
			).map(item => {
				return {
					ansCriterioParada: item,
					ansPerfil: parent
				}
			});

			if (obj.length > 0) {
				AnsPerfilParadaService.save(obj, response => {
					this.getSelecteds();
					super.onChangeAddAll(e, itens);
				});
			}
		});
	};

	onChangeDelAll(e, itens) {
		var parents = Array.isArray(this.props.parent) ? this.props.parent : [this.props.parent];

		parents.forEach(parent => {
			var selecteds = this.state.selectedsOriginal.filter(i => parseInt(i.ansPerfil.idAnsPerfil) === parseInt(parent.idAnsPerfil)).filter(i => itens.find(is => parseInt(i.ansCriterioParada.idAnsCriterioParada) === parseInt(is.idAnsCriterioParada)));
			AnsPerfilParadaService.delete(selecteds, response => {
				this.getSelecteds();
				super.onChangeDelAll(e, itens);
			});
		});
	};

	render() {
		return super.render();
	}
}