//#NO-CHANGE-FILE#

import React from "react";
import { useNavigate, useLocation, useParams } from "react-router";
import Config from "./Config";
import { BaseList } from "../../siht/base";
import { DataGrid, DataGridButtons, DataGridColumn } from "../../siht/components";
import { Div, Span } from "../../siht/elements";
import { IconDownload } from "../../icons";
import ConfigApp from "../../ConfigApp";
import Util from "../../utils/Util";
import { Paperclip as IconPaperclip } from "react-bootstrap-icons";


class ListFaturaNotaFiscalArquivo extends BaseList {

	constructor(props) {
		super(props);
		this.getItens = this.getItens.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleNew = this.handleNew.bind(this);
		this.handleEdit = this.handleEdit.bind(this);
		this.handleCopy = this.handleCopy.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.handleMore = this.handleMore.bind(this);
		this.onSubmitFilter = this.onSubmitFilter.bind(this);
		this.handleClear = this.handleClear.bind(this);
		this.handleFormSuccess = this.handleFormSuccess.bind(this);
		this.handleFormClose = this.handleFormClose.bind(this);
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		super.init(Config);
	}

	getItens(more){
		if(this.props?.parent){
			super.getItens(Config.getService().getAllByFaturaNotaFiscal, this.props.parent, more);
		}
	}

	handleNew(e) {
		super.handleNew(e, Config);
	}

	handleEdit(e, item) {
		super.handleEdit(e, Config, item);
	}

	handleDelete(e, item) {
		super.handleDelete(e, Config, item);
	}

	handleCopy(e, item) {
		super.handleCopy(e, Config, item);
	}

	getFormEmbedded(){
		return super.getFormEmbedded(Config);
	}

	render() {
		return super.render(
			<Div className="p-2 mt-2">
				<DataGrid responsive={true} data={this.state.data} search={false} isDisabled={i => false} sizing={DataGrid.sizing.sm} select={false} selecteds={this.state.selecteds} onSelect={(selecteds)=>{this.setState({selecteds : selecteds})}} getItemValue={i=>i.idFaturaNotaFiscalArquivo} onMore={this.handleMore}>
					<DataGridColumn label="Código" field={i => i.idFaturaNotaFiscalArquivo} className="text-center"/>
					<DataGridColumn col={5} label="Nome do Arquivo" field={i => i.arquivo?.nmArquivo} className="text-start"/>
					<DataGridColumn col={4} label="Tipo do Arquivo" field={i => i.tipoArquivoNotaFiscal?.nmTipoArquivoNotaFiscal} className="text-start"/>
					<DataGridColumn col={3} label="Tamanho" field={i => <>
						<Span><IconPaperclip/> {Util.formatBytes(i.arquivo?.arquivoBase?.nrTamanho)}</Span>
					</>} className="text-start"/>
					<DataGridButtons>
						<a className="btn btn-outline-primary text-nowrap" rel="opener" href={i => ConfigApp.getUrlArquivo() + "public/download/" + i.arquivo?.cdUid +  "/" + i.arquivo?.arquivoBase?.cdHash}>
							<IconDownload/> Download
						</a>
					</DataGridButtons>
				</DataGrid>
			</Div>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <ListFaturaNotaFiscalArquivo {...props} navigate={navigate} params={params} location={location}/>
}
export default With