import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import Permissao from "../../controllers/Permissao";
import { BaseList, BaseCrud } from "../../siht/base";
import { FormButtons, FormItem, Row, TabNavigator, Tab, Panel, PanelBody, PanelFooter, Container, ModalDialog, DataGrid, DataGridColumn, Col, InputGroup, InputGroupButton, BtnRadioGroup, DataGridButtons } from "../../siht/components";
import { Div, Form, BtnSubmit, InputNumber, InputDate, InputNumberFormat, InputCheckSwitch, H6, Span, InputText, TextArea, BtnButton, BtnRadio, Button } from "../../siht/elements";
import Config from "./Config";
import Util from "../../utils/Util";
import { toast } from "react-toastify";
import FaturaService from "../../services/FaturaService";
import FormPessoa from "../Pessoa/FormPessoa";
import { Modal } from "../../siht/controller";
import OneToOneFormEndereco from "../Endereco/OneToOneFormEndereco";
import PessoaEnderecoService from "../../services/PessoaEnderecoService";
import SelectTipoTributacaoServico from "../../components/SelectDB/SelectTipoTributacaoServico";
import SelectTipoExigibilidadeServico from "../../components/SelectDB/SelectTipoExigibilidadeServico";
import BtnRadioGroupSimNao from "../../components/BtnRadioGroupSimNao";
import SelectNaturezaTributacaoNotaFiscal from "../../components/SelectDB/SelectNaturezaTributacaoNotaFiscal";
import OrdemService from "../../services/OrdemService";
import { ArrowClockwise as IconArrowClockwise, ArrowRepeat as IconArrowRepeat, BagDash as IconBagDash, Bank2 as IconBank2, Basket as IconBasket, Basket2Fill as IconBasket2Fill, CardChecklist as IconCardChecklist, Cash as IconCash, ClipboardMinus as IconClipboardMinus, Coin as IconCoin, ConeStriped as IconConeStriped, DashCircle as IconDashCircle, MailboxFlag as IconMailboxFlag, Pencil as IconPencil, People as IconPeople, Percent as IconPercent, Person as IconPerson, PersonFill as IconPersonFill, UniversalAccess as IconUniversalAccess } from "react-bootstrap-icons";
import { FormItemTipoPessoaPessoa } from "../../components/FormItem";
import FormOrdemView from "../Ordem/FormOrdemView";
import BtnView from "../../components/BtnView";
import FormOrdem from "../Ordem/FormOrdem";
import ContaTipoPagamentoService from "../../services/ContaTipoPagamentoService";
import { IconCopy } from "../../icons";
import { StatusCustom } from "../../components/Custom";

class FormFaturar extends BaseCrud {

	constructor(props) {
		super(props);
		this.init = this.init.bind(this);
		this.handleSuccess = this.handleSuccess.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleChangeModel = this.handleChangeModel.bind(this);
		this.handleSubmitForm = this.handleSubmitForm.bind(this);
		this.handleChangeNrParcela = this.handleChangeNrParcela.bind(this);
		this.buildParcelas = this.buildParcelas.bind(this);
		this.handleChangeContaTipoPagamento = this.handleChangeContaTipoPagamento.bind(this);
		this.handleChangeEnderecoFaturamento = this.handleChangeEnderecoFaturamento.bind(this);		
		this.getContaTipoPagamento = this.getContaTipoPagamento.bind(this);		
		this.getPessoaEndereco = this.getPessoaEndereco.bind(this);
		this.setTxDiscriminacao = this.setTxDiscriminacao.bind(this);		
		this.getDadoNotaFiscal = this.getDadoNotaFiscal.bind(this);
		this.handleClickCopiar = this.handleClickCopiar.bind(this);
		this.handleChangeTabNfseMunicipal = this.handleChangeTabNfseMunicipal.bind(this);
		this.handleChangeTabFatura = this.handleChangeTabFatura.bind(this);
		this.setVencimento = this.setVencimento.bind(this);

		var tzoffset = (new Date()).getTimezoneOffset() * 60000;
		var dtHoje = new Date();
        dtHoje.setDate(dtHoje.getDate());
        dtHoje = new Date(dtHoje - tzoffset).toISOString().slice(0, 10);

		this.state.dtHoje = dtHoje;

		let ordens = [];
		let unidade = null;
		let pessoa = null;
		let faturaAcordo = null;

		if(this.props.ordens !== undefined){
			ordens = this.props.ordens;
		}else if(this.props?.location?.state?.ordens !== undefined){
			ordens = this.props?.location?.state?.ordens;
		}

		if(this.props.unidade !== undefined){
			unidade = this.props.unidade;
		}else if(this.props?.location?.state?.unidade !== undefined){
			unidade = this.props?.location?.state?.unidade;
		}

		if(this.props.pessoa !== undefined){
			pessoa = this.props.pessoa;
		}else if(this.props?.location?.state?.pessoa !== undefined){
			pessoa = this.props?.location?.state?.pessoa;
		}

		if(this.props.faturaAcordo !== undefined){
			faturaAcordo = this.props.faturaAcordo;
		}else if(this.props?.location?.state?.faturaAcordo !== undefined){
			faturaAcordo = this.props?.location?.state?.faturaAcordo;
		}

		this.state.model = {
			faturaOrdem : ordens || [],
			faturaCobranca : [],
			faturaPessoa : [],
			faturaEndereco : [],
            unidade : unidade,
			pessoa : pessoa, 
			faturaAcordo : faturaAcordo,
			flAcordo : faturaAcordo !== null,
			flEnderecoEntregaMesmoFaturamento : true,
			enderecoFaturamento : null,
			enderecoEntrega : null,
            nrParcela : 1,
			nrIntervaloInicial : 0,
            nrIntervalo : 30,
            dtVencimentoInicial : this.state.dtHoje,

			vlBruto : 0,
			vlFatura : 0,
			vlRetencao : 0,

			vlJurosFuturo : 0,
			
			pcJurosFuturo: 0,
            
			flJurosFuturo : false,
			flTaxaCartao : false,
			flRegistrarBoleto : false,

			flNegativacao : false,
			flProtesto : false,
			nrDiaNegativacao : 30,
			nrDiaProtesto : 30,

			flJuros : true,
			pcJuros : 1,
			flMulta : true,
			pcMulta : 2,

			cdIdentificadorExterno : "",
			cdPedidoExterno : "",

			flPodeNFServicoMunicipal : false,
			flPodeNFServicoNacional : false,
			flPodeNFProduto : false,
			flPodeNFConsumidor : false,

			cdAgrupadorNFServicoMunicipal : "ORDEM",

			flNFServicoMunicipal : false,
			flNFServicoNacional : false,
			flNFProduto : false,
			flNFConsumidor : false,

			nfsMunicipal : []
		};

		this.state.contaTipoPagamento = [];		
		this.state.tipoNotaFiscal = [];
		this.state.tabIndexNfseMunicipal = 0;
		this.state.tabIndexFatura = 0;
	}

	initModelNFSeMunicipal(){
		return {			
			flEnvioAutomatico : false,
			unidadeTipoNotaFiscal : null,
			cdTributacaoMunicipio : "",
			cdClassificacaoCnae : "",
			cdItemListaServico : "",
			txDescricao : "",
			txDiscriminacao : "",
			txDescricaoComplementar : "",
			flEnviarEmail : true,
			flEnvioPrioritario : false,
			flTributacao : false,				
			cdResponsavelRetencao : "PRESTADOR",
			naturezaTributacaoNotaFiscal : null,
			
			vlBaseCalculo : 0,
			vlDeducoes : 0,
			vlDescontoCondicionado : 0,
			vlDescontoIncondicionado : 0,

			enderecoEntrega : null,

			intermediario : {
				flTemIntermediario : false,
				pessoa : null
			},

			cargaTributaria :{
				cdTipoValor : "PERCENTUAL",
				flTemCargaTributaria : false,
				dsFonte : "",
				vlCargaTributaria : 0,
				vlValor : 0
			},

			retencao : {
				pis : {
					flRetido : false,
					cdTipoValor : "PERCENTUAL",
					vlAliquota : null,
					vlValor : null,
					vlBaseCalculo : null,
				},
				cofins : {
					flRetido : false,
					cdTipoValor : "PERCENTUAL",
					vlAliquota : null,
					vlValor : null,					
					vlBaseCalculo : null,					
				},
				csll : {
					flRetido : false,
					cdTipoValor : "PERCENTUAL",
					vlAliquota : null,
					vlValor : null,					
				},
				inss : {
					flRetido : false,
					cdTipoValor : "PERCENTUAL",
					vlAliquota : null,
					vlValor : null,					
				},
				irrf : {
					flRetido : false,
					cdTipoValor : "PERCENTUAL",
					vlAliquota : null,
					vlValor : null,					
				},
				cpp : {
					flRetido : false,
					cdTipoValor : "PERCENTUAL",
					vlAliquota : null,
					vlValor : null,					
				},
				outras : {
					flRetido : false,
					cdTipoValor : "VALOR",
					vlAliquota : null,
					vlValor : null,					
				}					
			},

			iss : {
				tipoTributacaoServico : null,
				tipoExigibilidadeServico : null,			
				flRetido : false,
				cdTipoValor : "PERCENTUAL",
				vlAliquota : null,
				vlValor : null,				
				vlRetido : null,
				flValorRetido : false,
				dsProcessoSuspensao : "",
			},

			obra : {
				flTemObra  : false,
				cdArt : "",
				cdObra : "",
				cdInss : "",
				flMesmoEnderecoEntegra : false,
				endereco : null
			}
		};
	}

	componentDidMount() {
		super.componentDidMount();

		if(this.getId()){
			OrdemService.getOneById(this.getId(), (response) => {
				this.setState(state =>{
					state.model.faturaOrdem = [response];
					state.model.pessoa = response.pessoa;
					state.model.unidade = response.unidade;
					return state;
				}, ()=>{
					this.init();
				});
			});
		}
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		this.setDefaultValues(Config, () =>{
			this.buildParcelas(true);
			this.getPessoaEndereco();			
			this.getContaTipoPagamento();			
			this.getDadoNotaFiscal();
			this.setVencimento();
		});		
	}

	setVencimento(){
		var tzoffset = (new Date()).getTimezoneOffset() * 60000;
		var dtVencimentoInicial = new Date();
        dtVencimentoInicial.setDate(dtVencimentoInicial.getDate() + 1 + this.state.model.nrIntervaloInicial);
        dtVencimentoInicial = new Date(dtVencimentoInicial - tzoffset).toISOString().slice(0, 10);
		this.setModel({dtVencimentoInicial : dtVencimentoInicial});
	}

	getDadoNotaFiscal(){
		if(this.state.model.unidade && this.state.model.faturaOrdem.length > 0){

			//TODO:COLOCAR EM OUTRO MÉTODO DE PADROES
			if(this.state.model.faturaOrdem.length === 1){
				this.setState(state => {
					state.model.cdIdentificadorExterno = this.state.model.faturaOrdem[0].cdIdentificadorExterno;
					state.model.cdPedidoExterno = this.state.model.faturaOrdem[0].cdPedidoExterno;
					return state;
				});
			}

			let filter = {
				cdAgrupadorNFServicoMunicipal : this.state.model.cdAgrupadorNFServicoMunicipal, 
				unidade : this.state.model.unidade, 
				faturaOrdem : this.state.model.faturaOrdem
			};

			OrdemService.getDadoNotaFiscal(filter, (response) => {

				//TIPO DE NOTA FISCAL
				this.setState(state => {
					state.tipoNotaFiscal = response.tipoNotaFiscal;

					response.tipoNotaFiscal.forEach(unidadeTipoNotaFiscal => {

						state.model.nfsMunicipal = [];

						switch(unidadeTipoNotaFiscal.tipoNotaFiscal.cdTipoNotaFiscal){
							case "NFS_MUNICIPAL":
								state.model.flPodeNFServicoMunicipal = true;
								state.model.flNFServicoMunicipal = true;

								response.nfse.forEach(element => {
									let nfsMunicipal = this.initModelNFSeMunicipal();
									nfsMunicipal.unidadeTipoNotaFiscal = unidadeTipoNotaFiscal;
									nfsMunicipal.cdItemListaServico = element.cdItemListaServico;
									nfsMunicipal.cdTributacaoMunicipio = element.cdTributacaoMunicipio;
									nfsMunicipal.cdClassificacaoCnae = element.cdClassificacaoCnae;
									nfsMunicipal.txDiscriminacao = element.txDiscriminacao;									
									nfsMunicipal.cdIdentificadorExterno = element.cdIdentificadorExterno;
									nfsMunicipal.cdPedidoExterno = element.cdPedidoExterno;									
									nfsMunicipal.ordem = element.ordem;
									
									if(element.enderecoEntrega){
										nfsMunicipal.enderecoEntrega = element.enderecoEntrega;
									}

									//================================================================================================================================================================
									//RETENÇÕES

									if(element?.iss !== undefined){
										if(element.iss.flRetido){
											nfsMunicipal.iss.flRetido = true;
											nfsMunicipal.iss.cdTipoValor = element.iss.cdTipoValor;
											nfsMunicipal.iss.vlAliquota = element.iss.vlAliquota;
										}
									}
									
									let retencoes = ["iss","pis","cofins","csll","inss","irrf","cpp","outras"];
									
									retencoes.forEach(cdRetencao => {
										if(element?.retencao?.hasOwnProperty(cdRetencao)){
											if(element.retencao[cdRetencao].flRetido){
												nfsMunicipal.retencao[cdRetencao].flRetido = true;
												nfsMunicipal.retencao[cdRetencao].cdTipoValor = element.retencao[cdRetencao].cdTipoValor;
												
												if(element?.retencao[cdRetencao].hasOwnProperty("vlAliquota")){
													nfsMunicipal.retencao[cdRetencao].vlAliquota = element.retencao[cdRetencao].vlAliquota;
												}

												if(element?.retencao[cdRetencao].hasOwnProperty("vlValor")){
													nfsMunicipal.retencao[cdRetencao].vlValor = element.retencao[cdRetencao].vlValor;
												}
											}
										}
									});

									//================================================================================================================================================================

									state.model.nfsMunicipal.push(nfsMunicipal);
								});

								break;

							case "NFS_NACIONAL":
								state.model.flPodeNFServicoNacional = true;
								state.model.flNFServicoNacional = true;
								break;

							case "NF_PRODUTO":
								state.model.flPodeNFProduto = true;
								state.model.flNFProduto = true;
								break;
							case "NF_CONSUMIDOR":
								state.model.flPodeNFConsumidor = true;
								state.model.flNFConsumidor = true;
								break;

							default:
								break;
						}
					});
					return state;	
				}, e => {
					this.setTxDiscriminacao();
					this.buildParcelas(false);
				});
			});
		}	
	}

	setTxDiscriminacao(index = null){

		this.setState(state => {

			var agruprador = [];
			if(index !== null){
				agruprador  = state.model.nfsMunicipal.filter((item, ix) => ix === index);
			}else{
				agruprador  = state.model.nfsMunicipal;
			}

			agruprador.forEach((nfsMunicipal, ix) => {
				if(nfsMunicipal.txDiscriminacao){
					if(nfsMunicipal._txDiscriminacao === undefined){
						nfsMunicipal._txDiscriminacao = nfsMunicipal.txDiscriminacao;
					}

					let txDiscriminacao = nfsMunicipal._txDiscriminacao;
					txDiscriminacao = Util.replaceContent(nfsMunicipal.ordem, txDiscriminacao, "", false, false);
					txDiscriminacao = Util.replaceContent(nfsMunicipal, txDiscriminacao, "nfsMunicipal",false, false);
					txDiscriminacao = Util.replaceContent(state.model, txDiscriminacao, "", true, true);
					
					nfsMunicipal.txDiscriminacao = txDiscriminacao;
				}				
			});

			toast.warn("Atenção: O texto do detalhamento do serviço prestado na aba \"Item\" foi atualizado!");
			
			return state;
		});
	}	

	getContaTipoPagamento(){
		if(this.state.model.unidade){
			ContaTipoPagamentoService.getAllByUnidade(this.state.model.unidade, (response) => {
				this.setState({contaTipoPagamento : response});
			})
		}
	}

	getPessoaEndereco(){
		if(this.state.model.pessoa){
			PessoaEnderecoService.getAllByPessoa(this.state.model.pessoa, (response) =>{
				let enderecoFaturamento = response?.find(pessoaEndereco => pessoaEndereco.tipoEndereco.flFaturamento);
				let enderecoEntrega = response?.find(pessoaEndereco => pessoaEndereco.tipoEndereco.flEntrega);
				enderecoEntrega = enderecoEntrega ? enderecoEntrega : enderecoFaturamento;

				this.setModel({
					enderecoFaturamento : enderecoFaturamento?.endereco ? enderecoFaturamento?.endereco : null,
					enderecoEntrega : enderecoEntrega?.endereco ? enderecoEntrega?.endereco : null
				});
			});
		}
	}

	handleSuccess(e) {
		if(this.getValorDiferenca() !== 0) {
			toast.warn(`Existe uma diferença de ${Util.floatToReais(this.getValorDiferenca())} no valor das parcelas comparado com o valor da fatura que é ${Util.floatToReais(this.state.model.vlFatura)}!`);
			return;
		}

		let fnFaturar = () => {
			FaturaService.faturar(this.state.model, response => {
				this.props.navigate('/CadFatura/' + response.idFatura);
			});
		};

		if(this.getValorBruto() === 0){
			Modal.confirm("Confirmação", "O valor da fatura está zerada!" , "Deseja faturar mesmo assim?", Modal.style.warning, () => {
				fnFaturar();		
			});
		}else{
			fnFaturar();
		}        
	}

	handleClose(e) {

		if(this.state.model.flAcordo){
			this.props.navigate("/CadFaturaAcordo/" + this.state.model.faturaAcordo.idFaturaAcordo);
		}else if(this.getId()){
			this.props.navigate("/CadOrdem/" + this.getId());
		}else{
			this.props.navigate("/LstOrdem");
		}

	}

	handleChangeNrParcela() {
		this.buildParcelas(true);
	}

	getValorRetencao(vlFatura){

		let vlRetencao = 0;

		if(this.state.model.flNFServicoMunicipal){

			this.state.model.nfsMunicipal.forEach((nfsMunicipal) => {

				let retencoes = ["iss","pis","cofins","csll","inss","irrf","cpp","outras"];

				retencoes.forEach(cdRetencao => {

					let retencao = null;

					if(nfsMunicipal[cdRetencao] !== undefined){
						retencao = nfsMunicipal[cdRetencao];
					}else if(nfsMunicipal?.retencao[cdRetencao] !== undefined){
						retencao = nfsMunicipal?.retencao[cdRetencao];
					}

					if(retencao !== null && retencao?.flRetido){

						let vlBaseCalculo = retencao.vlBaseCalculo !== undefined && retencao.vlBaseCalculo !== null ? retencao.vlBaseCalculo : 0;

						if(vlBaseCalculo === 0){
							if(this.state.model.cdAgrupadorNFServicoMunicipal === "ORDEM"){
								vlBaseCalculo = nfsMunicipal.ordem.vlFinalOrdem;
							}else if(this.state.model.cdAgrupadorNFServicoMunicipal === "FATURA"){
								vlBaseCalculo = vlFatura;
							}
						}

						if(retencao.vlAliquota !== undefined && retencao.vlAliquota !== null){
							vlRetencao+= (parseFloat(retencao.vlAliquota) * vlBaseCalculo) / 100;
						}else if(retencao.vlValor !== undefined && retencao.vlValor !== null){
							vlRetencao+= retencao.vlValor;
						}

					}
				});			
			});
		}

		return vlRetencao;
	}

    getValorBruto(){
		var vlFinal = 0;
		if(this.state.model.flAcordo){
			vlFinal = parseFloat(this.state.model.faturaAcordo.vlAcordo);
		}else{
			vlFinal = parseFloat(this.state.model.faturaOrdem?.reduce((soma, ordem) => soma + parseFloat(ordem.vlFinalOrdem), 0));
		}

        return vlFinal;
    }

	getValorParcelas(){
        var vlCobranca = this.state.model.faturaCobranca?.reduce((soma, cobranca) => soma + parseFloat(cobranca.vlCobranca), 0);
        return parseFloat(vlCobranca);
    }

	getValorDiferenca(){
        var vlDiferenca = this.getValorParcelas() - parseFloat(this.state.model.vlFatura);
        return parseFloat(vlDiferenca.toFixed(2));
    }
	
	getJurosParcela(vlFatura, dtVencimento){
        let nrDiasJuros = Util.dateDiffNow(dtVencimento);
        nrDiasJuros = nrDiasJuros > 0 ? nrDiasJuros : 0;        
        let vlJuros = (((this.state.model.pcJurosFuturo / 30)/100) * nrDiasJuros) * vlFatura;
        vlJuros = parseFloat(vlJuros.toFixed(2));
        return vlJuros;
    }

	handleChangeContaTipoPagamento(e, contaTipoPagamento, cobranca) {
        var indexOfContaTipoPagamento = cobranca.faturaCobrancaContaTipoPagamento.findIndex(i => parseInt(contaTipoPagamento.idContaTipoPagamento) === parseInt(i.idContaTipoPagamento));
		var indexOfTipoPagamento = cobranca.faturaCobrancaContaTipoPagamento.findIndex(i => parseInt(contaTipoPagamento.tipoPagamento.idTipoPagamento) === parseInt(i.tipoPagamento.idTipoPagamento));
		
        if (e.target.checked) {

			if(indexOfTipoPagamento !== -1 && indexOfContaTipoPagamento !== indexOfTipoPagamento){
				toast.warn("Tipo de pagamento já está marcado para essa cobrança!");
				return;
			}

            if (indexOfContaTipoPagamento !== -1) {
                cobranca.faturaCobrancaContaTipoPagamento.splice(indexOfContaTipoPagamento, 1);
            }
            cobranca.faturaCobrancaContaTipoPagamento.push(contaTipoPagamento);
        } else {
            if (indexOfContaTipoPagamento !== -1) {
                cobranca.faturaCobrancaContaTipoPagamento.splice(indexOfContaTipoPagamento, 1);
            }
        }

        this.setState({ cobranca })
    }

	buildParcelas(buildVencimento = true) {
        this.setState(state => {
            state.model.vlJurosFuturo = 0.0;
			
			var vlBruto 	= parseFloat(this.getValorBruto());
			var vlRetencao 	= parseFloat(this.getValorRetencao(vlBruto));
			var vlFatura = vlBruto;

			vlFatura = vlFatura - vlRetencao;

			if(isNaN(parseInt(state.model.nrParcela)) || parseInt(state.model.nrParcela) < 1){
				state.model.faturaCobranca = [];
				return;
			}

			if(isNaN(parseInt(state.model.nrIntervalo)) || parseInt(state.model.nrIntervalo) < 0){
				state.model.faturaCobranca = [];
				return;
			}
            
            if(buildVencimento){
                state.model.faturaCobranca = [];

                var dtVencimento = null;
                for (let nrParcela = 1; nrParcela <= parseInt(state.model.nrParcela); nrParcela++) {
                    try {
                        dtVencimento = new Date(state.model.dtVencimentoInicial);
                        dtVencimento.setDate(dtVencimento.getDate() + (state.model.nrIntervalo * (nrParcela - 1)));
                        dtVencimento = dtVencimento.toISOString().slice(0, 10);
                    } catch (e) {
                        dtVencimento = new Date();
                        dtVencimento.setDate(dtVencimento.getDate() + (state.model.nrIntervalo * (nrParcela - 1)));
                        dtVencimento = dtVencimento.toISOString().slice(0, 10);
                    }

                    state.model.faturaCobranca.push({
                        dtVencimento: dtVencimento,
                        vlJuros : 0
                    });
                }
            }

			var vlCobranca = 0.0;
			var vlValor = 0.0;
			
            var vlJuros = 0.0;
            if(this.state.model.flJurosFuturo){
                for (let nrParcela = 1; nrParcela <= state.model.nrParcela; nrParcela++) {                
                    vlJuros = this.getJurosParcela(vlFatura, state.model.faturaCobranca[nrParcela - 1].dtVencimento);
                    state.model.faturaCobranca[nrParcela - 1].vlJuros =vlJuros;
                    state.model.vlJurosFuturo+=vlJuros;
                }
            }

            vlFatura+= parseFloat(state.model.vlJurosFuturo);

			vlFatura = parseFloat(vlFatura.toFixed(2));

            if (!parseInt(state.model.nrParcela) > 0) {
                return state;
            }
            
            var difValorCobranca = 0.0;
			/*
            if (Boolean(state.model.flTransporte) && parseInt(state.model.nrParcela) > 1 && state.model.cdCalculoFrente === "P") {

                if (state.model.cdComposicaoParcela === "F") {
                    vlCobranca = (vlOrdens / (parseInt(state.model.nrParcela) - 1));
                } else {
                    vlCobranca = (vlOrdens / parseInt(state.model.nrParcela));
                }

            } else {
                vlCobranca = (vlFatura / parseInt(state.model.nrParcela));
            }*/

			vlCobranca = (vlFatura / parseInt(state.model.nrParcela));
            vlCobranca = parseFloat(vlCobranca.toFixed(2));

            var vlTotal = 0.0;
            for (var nrParcela = 1; nrParcela <= state.model.nrParcela; nrParcela++) {

				vlValor = vlCobranca;
                
                /*if (Boolean(state.model.flTransporte) && parseInt(state.model.nrParcela) > 1 && parseInt(nrParcela) === 1 && state.model.cdCalculoFrente === "P") {
                    if (state.model.cdComposicaoParcela === "F") {
                        vlValor = this.getVlTransporte();
                    } else {
                        vlValor = vlCobranca + this.getVlTransporte();
                    }
                } */

                vlValor = parseFloat(vlValor.toFixed(2));
                vlTotal += vlValor;
                
                state.model.faturaCobranca[nrParcela - 1].nrParcela = nrParcela;
                state.model.faturaCobranca[nrParcela - 1].vlCobranca = vlValor;
                state.model.faturaCobranca[nrParcela - 1].flMulta = this.state.model.flMulta;
                state.model.faturaCobranca[nrParcela - 1].flJuros = this.state.model.flJuros;                
				state.model.faturaCobranca[nrParcela - 1].faturaCobrancaContaTipoPagamento = [];
            }
            
            difValorCobranca = vlFatura - parseFloat(vlTotal.toFixed(2));
            difValorCobranca = parseFloat(difValorCobranca.toFixed(2));
            state.model.faturaCobranca[state.model.faturaCobranca.length - 1].vlCobranca = state.model.faturaCobranca[state.model.faturaCobranca.length - 1].vlCobranca + difValorCobranca;
			
			state.model.vlFatura = vlFatura;
			state.model.vlBruto = vlBruto;
			state.model.vlRetencao = vlRetencao;

            return state;
        });
    }

	handleChangeEnderecoFaturamento(){
		if(!this.state.model.enderecoEntrega && this.state.model.enderecoFaturamento){
			this.setModel({ enderecoEntrega : this.state.model.enderecoFaturamento});
		}
	}

	handleClickCopiar(index, item = "GERAL"){
		this.setState(state =>{

			state.model.nfsMunicipal.forEach((nfsMunicipal, indexNfsMunicipal) => {
				if(indexNfsMunicipal !== index){
					switch (item) {
						case "GERAL":
							nfsMunicipal.flEnvioAutomatico = state.model.nfsMunicipal[index].flEnvioAutomatico;
							nfsMunicipal.txDescricao = state.model.nfsMunicipal[index].txDescricao;
							nfsMunicipal.txDescricaoComplementar = state.model.nfsMunicipal[index].txDescricaoComplementar;
							nfsMunicipal.flEnviarEmail = state.model.nfsMunicipal[index].flEnviarEmail;
							nfsMunicipal.flEnvioPrioritario = state.model.nfsMunicipal[index].flEnvioPrioritario;
							nfsMunicipal.naturezaTributacaoNotaFiscal = state.model.nfsMunicipal[index].naturezaTributacaoNotaFiscal;
							nfsMunicipal.enderecoEntrega = state.model.nfsMunicipal[index].enderecoEntrega;
							break;

						case "ITEM":
							nfsMunicipal.cdItemListaServico = state.model.nfsMunicipal[index].cdItemListaServico;
							nfsMunicipal.cdTributacaoMunicipio = state.model.nfsMunicipal[index].cdTributacaoMunicipio;
							nfsMunicipal.cdClassificacaoCnae = state.model.nfsMunicipal[index].cdClassificacaoCnae;
							nfsMunicipal.vlBaseCalculo = state.model.nfsMunicipal[index].vlBaseCalculo;
							nfsMunicipal.vlDeducoes = state.model.nfsMunicipal[index].vlDeducoes;
							nfsMunicipal.vlDescontoCondicionado = state.model.nfsMunicipal[index].vlDescontoCondicionado;
							nfsMunicipal.vlDescontoIncondicionado = state.model.nfsMunicipal[index].vlDescontoIncondicionado;
							nfsMunicipal.txDiscriminacao = state.model.nfsMunicipal[index].txDiscriminacao;
							nfsMunicipal.flTributacao = state.model.nfsMunicipal[index].flTributacao;
							nfsMunicipal.cdResponsavelRetencao = state.model.nfsMunicipal[index].cdResponsavelRetencao;
							break;

						case "ISS":
							nfsMunicipal.iss = Object.assign({}, state.model.nfsMunicipal[index].iss);
							break;

						case "RETENCAO":
							nfsMunicipal.retencao = Object.assign({}, state.model.nfsMunicipal[index].retencao);
							break;

						case "OBRA":
							nfsMunicipal.obra = Object.assign({}, state.model.nfsMunicipal[index].obra);
							break;

						case "DEDUCAO":
							//nfsMunicipal.obra = Object.assign({}, state.model.nfsMunicipal[index].obra);
							break;

						case "CARGA_TRIBUTARIA":
							nfsMunicipal.cargaTributaria = Object.assign({}, state.model.nfsMunicipal[index].cargaTributaria);
							break;

						case "INTERMEDIARIO":
							nfsMunicipal.intermediario = Object.assign({}, state.model.nfsMunicipal[index].intermediario);
						break;

						default:
							break;
					}
					
				}
			});

			return state;
		}, () => {			
			this.setTxDiscriminacao();
			this.buildParcelas(false);
		});
		
	}

	handleChangeTabNfseMunicipal(index){
        this.setState({tabIndexNfseMunicipal : parseInt(index)});
    }

	handleChangeTabFatura(index){
        this.setState({tabIndexFatura : parseInt(index)});
    }

	handleVerOrdem(ordem){
		Modal.openWindow(FormOrdem, {sizing : Modal.sizing.fullscreen}, { id : ordem.idOrdem });
	}

	render() {

		var vlDiferenca = this.getValorDiferenca();

		return super.render(
				<Div key={this.state.key}>
					<Panel title="Faturar" onClose={this.handleClose} isModal={this.isModal()} container={{sizing: Container.sizing.fluid, className : ""}} modal={{sizing: this.props.sizing ? this.props.sizing : ModalDialog.sizing.xl}}>
						<PanelBody className="p-0 m-0">
							<TabNavigator onChangeTab={this.handleChangeTab} className="border-light">
								<Tab title={<><IconCoin/> Fatura</>} className="p-0 m-0">
									<Form onSubmit={this.handleSuccess} refParam={f => (this.form = f)}>
										<TabNavigator className="border-light" onChangeTab={this.handleChangeTabFatura}>											
											<Tab title={<><IconCash/> Pagamento</>} className="m-2">
												<Row className="g-3">
													<FormItem md={3} label="Identificador Externo">
														<InputText name="cdIdentificadorExterno" value={this.state.model.cdIdentificadorExterno} maxLength={100} required={false} onChange={this.handleChangeModel}/>
													</FormItem>
													<FormItem md={3} label="Pedido Externo">
														<InputText name="cdPedidoExterno" value={this.state.model.cdPedidoExterno} maxLength={100} required={false} onChange={this.handleChangeModel}/>
													</FormItem>
												</Row>
												<Row className="g-0">
													<Col sizing={6}>
														<Row className="g-0 py-3 pb-2 px-2">
															<H6 className="border-dark border-bottom mb-2 pb-1 fw-bold"> Configuração do Pagamento </H6>
															<InputCheckSwitch label="Cobrar Juros por Atrasado?" name="flJuros" checked={this.state.model.flJuros} onChange={(e,v,p) => this.handleChangeModel(e, v, p, this.buildParcelas)} />
															<InputCheckSwitch label="Cobrar Multa por Atrasado?" name="flMulta" checked={this.state.model.flMulta} onChange={(e,v,p) => this.handleChangeModel(e, v, p, this.buildParcelas)} />
															<InputCheckSwitch label="Calcular Juros Futuros?" name="flJurosFuturo" checked={this.state.model.flJurosFuturo} onChange={(e,v,p) => this.handleChangeModel(e, v, p, this.buildParcelas)} />
															
															{this.state.model.faturaCobranca.find(fc => fc.faturaCobrancaContaTipoPagamento.find(fcctp => fcctp.tipoPagamento.cdTipoPagamento === "CARTAO_CREDITO" || fcctp.tipoPagamento.cdTipoPagamento === "CARTAO_DEBITO")) ?
																<InputCheckSwitch label="Cobrar Taxas do Cartão de Crédito?" name="flTaxaCartao" checked={this.state.model.flTaxaCartao} onChange={this.handleChangeModel} />
															: <></>}

															{this.state.model.faturaCobranca.find(fc => fc.faturaCobrancaContaTipoPagamento.find(fcctp => fcctp.tipoPagamento.cdTipoPagamento === "BOLETO")) ?
																<>
																	<InputCheckSwitch label="Registrar o(s) boleto(s) imediatamente?" name="flRegistrarBoleto" checked={this.state.model.flRegistrarBoleto} onChange={this.handleChangeModel} />																	
																</>
															: <></>}

															<InputCheckSwitch label={<Span className={this.state.model.flNegativacao ? "text-danger fw-bold" : ""}>Negativação após vencimento?</Span>} name="flNegativacao" checked={this.state.model.flNegativacao} onChange={(e,v,p) => this.handleChangeModel(e, v, p)} />
															<InputCheckSwitch label={<Span className={this.state.model.flProtesto ? "text-danger fw-bold" : ""}>Protestar após vencimento?</Span>}  name="flProtesto" checked={this.state.model.flProtesto} onChange={(e,v,p) => this.handleChangeModel(e, v, p)} />
															
														</Row>
													</Col>
													<Col sizing={6}>
														<Row className="g-3 py-3 pb-2 px-2">
															<FormItem md={6} label="Vencimento Inicial">
																<InputDate className="text-center" name="dtVencimentoInicial" value={this.state.model.dtVencimentoInicial} required={true} onChange={(e, v, p) => this.handleChangeModel(e, v, p, () => this.buildParcelas(true))} min={this.state.dtHoje}/>
															</FormItem>
															<FormItem md={6} label="Intervalo de dias">
																<InputNumber className="text-center" name="nrIntervalo" value={this.state.model.nrIntervalo} required={true} min={1} onChange={(e, v, p) => this.handleChangeModel(e, v, p, () => this.buildParcelas(true))}/>
															</FormItem>
															<FormItem md={4} label="Juros ao Mês">
																<InputNumberFormat className="text-center" disabled={!this.state.model.flJuros} name="pcJuros" value={parseFloat(this.state.model.pcJuros)} decimalScale={0} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} suffix=" %" required={true} onChange={(e, v, p) => this.handleChangeModel(e, v, p, () => this.buildParcelas(true))}/>
															</FormItem>
															<FormItem md={4} label="Multa">
																<InputNumberFormat className="text-center" disabled={!this.state.model.flMulta} name="pcMulta" value={parseFloat(this.state.model.pcMulta)} decimalScale={0} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} suffix=" %" required={true} onChange={(e, v, p) => this.handleChangeModel(e, v, p, () => this.buildParcelas(true))}/>
															</FormItem>
															<FormItem md={4} label="Juros Futuro">
																<InputNumberFormat className="text-center" disabled={!this.state.model.flJurosFuturo} name="pcJurosFuturo" value={parseFloat(this.state.model.pcJurosFuturo)} decimalScale={0} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} suffix=" %" required={true} onChange={(e, v, p) => this.handleChangeModel(e, v, p, () => this.buildParcelas(true))}/>
															</FormItem>
															<FormItem md={6} label="Dias para Negativação" show={this.state.model.flNegativacao} help="Quantidade de dia após o vencimento para negativar">
																<InputNumber className="text-center" name="nrDiaNegativacao" value={this.state.model.nrDiaNegativacao} required={true} min={1} onChange={(e, v, p) => this.handleChangeModel(e, v, p)}/>
															</FormItem>
															<FormItem md={6} label="Dias para Protesto" show={this.state.model.flProtesto} help="Quantidade de dia após o vencimento para protesto">
																<InputNumber className="text-center" name="nrDiaProtesto" value={this.state.model.nrDiaProtesto} required={true} min={1} onChange={(e, v, p) => this.handleChangeModel(e, v, p)}/>
															</FormItem>															
														</Row>
													</Col>
												</Row>

												<Row className="g-3 py-3 pb-2 px-2">
													<FormItem md={2} label="Número de Parcelas">
														<InputNumber className="text-center" name="nrParcela" value={this.state.model.nrParcela} required={true} min={1} onChange={(e, v, p) => this.handleChangeModel(e, v, p, () => this.buildParcelas(true))}/>
													</FormItem>

													<Col sizing={2}/>

													<FormItem md={2} label="Bruto">
														<InputNumberFormat className="border border-primary readonly-field text-primary fw-bold text-end" value={this.getValorBruto()} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} placeholder="R$ 0,00" prefix="R$ " required={false} readOnly={true}/>
													</FormItem>
													
													<FormItem md={2} label="Juros Futuros">
														<InputNumberFormat className="border border-danger readonly-field text-danger fw-bold text-end" value={this.state.model.vlJurosFuturo} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} placeholder="R$ 0,00" prefix="R$ " required={false} readOnly={true}/>
													</FormItem>

													<FormItem md={2} label="Retenção">
														<InputNumberFormat className="border border-warning readonly-field text-warning fw-bold text-end" value={this.state.model.vlRetencao} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} placeholder="R$ 0,00" prefix="R$ " required={false} readOnly={true}/>
													</FormItem>

													<FormItem md={2} label="Fatura">
														<InputNumberFormat className="border border-success readonly-field text-success fw-bold text-end" value={this.state.model.vlFatura} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} placeholder="R$ 0,00" prefix="R$ " required={false} readOnly={true}/>
													</FormItem>

													<BtnSubmit hidden > Enviar </BtnSubmit>
												</Row>

												<Div className="mx-2">
													<DataGrid data={this.state.model.faturaCobranca} search={false} isDisabled={i => false} sizing={DataGrid.sizing.sm} caption={false}>
																								
														<DataGridColumn col={6} label="Forma de Pagamento" field={(i, ix) => <React.Fragment>
															{this.state.contaTipoPagamento.map((contaTipoPagamento, indexContaTipoPagamento) => (
																<InputCheckSwitch key={indexContaTipoPagamento} disabled={false} sizing="md" 
																	label={<> {contaTipoPagamento.tipoPagamento.nmTipoPagamento} <Span className="fs-7 text-primary"> ( {contaTipoPagamento.conta.banco.nmBanco} - {contaTipoPagamento.conta.nmConta} - {contaTipoPagamento.conta.nrConta} )</Span> </>} 
																	name="flTipoPagamento" 
																	checked={i.faturaCobrancaContaTipoPagamento.find(i => parseInt(contaTipoPagamento.idContaTipoPagamento) === parseInt(i.idContaTipoPagamento)) !== undefined} 
																	onChange={e => { this.handleChangeContaTipoPagamento(e, contaTipoPagamento, i) }} />
															))}
														</React.Fragment> }/>

														<DataGridColumn label="Juros" field={(item, ix) => <React.Fragment> 
															<InputCheckSwitch  name="flJuros" checked={item.flJuros} onChange={(e,v,p) =>  this.handleChange(e, v, p, item)} />
														</React.Fragment> } className="text-start"/>
														
														<DataGridColumn label="Multa" field={(item, ix) => <React.Fragment> 
															<InputCheckSwitch  name="flMulta" checked={item.flMulta} onChange={(e,v,p) =>  this.handleChange(e, v, p, item)} />
														</React.Fragment> } className="text-start"/>

														<DataGridColumn label="Parcela" field={(i, ix) => ix + 1 } className="text-center"/>
														
														<DataGridColumn col={2} label="Vencimento" field={i => <React.Fragment> 
															<InputDate name="dtVencimento" value={i.dtVencimento} required={true} className="text-center" onChange={(e,v,p) => this.handleChange(e,v,p,i)} min={this.state.dtHoje}/>
														</React.Fragment> }/>

														<DataGridColumn col={2} label="Juros" field={i => <React.Fragment>
															<InputNumberFormat name="vlJuros" value={i.vlJuros} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} placeholder="R$ 0,00" prefix="R$ " required={false}  readOnly={true} className="text-end text-danger readonly-field"/>
														</React.Fragment>}/>										

														<DataGridColumn col={2} label="Valor" field={i => <React.Fragment>
															<InputNumberFormat name="vlCobranca" value={i.vlCobranca} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} placeholder="R$ 0,00" prefix="R$ " required={true} className="text-end" onChange={(e,v,p) => this.handleChange(e,v,p,i)}/>
														</React.Fragment>}/>										
													</DataGrid>

													<Row className="g-3 px-2 justify-content-end">
														<FormItem md={3} label="Valor Diferença" show={vlDiferenca !== 0}>
															<InputNumberFormat className="border border-danger bg-danger-subtle text-danger fw-bold text-end" value={vlDiferenca} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} placeholder="R$ 0,00" prefix="R$ " required={false} readOnly={true}/>
														</FormItem>

														<FormItem md={3} label="Soma Parcelas">
															<InputNumberFormat className="border border-success readonly-field text-success fw-bold text-end" value={this.getValorParcelas()} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} placeholder="R$ 0,00" prefix="R$ " required={false} readOnly={true}/>
														</FormItem>

														<BtnSubmit hidden > Enviar </BtnSubmit>
													</Row>
												</Div>
											</Tab>											
											<Tab title={<><IconMailboxFlag/> Endereço</>} className="m-2">
												<Row className="g-0 py-2 px-3">
													<InputCheckSwitch label="O endereço de ENTREGA é o mesmo do Endereço de Faturamento?" name="flEnderecoEntregaMesmoFaturamento" checked={this.state.model.flEnderecoEntregaMesmoFaturamento} onChange={this.handleChangeModel} />
												</Row>
												<Row className="g-3 py-3 pb-2 px-3 pt-0">
													<FormItem md={6} label="Endereço de Faturamento" help="Endereço do Cliente na Fatura">
														<OneToOneFormEndereco name="enderecoFaturamento" value={this.state.model.enderecoFaturamento} required={true} onChange={(e, v, p) => this.handleChangeModel(e, v, p, this.handleChangeEnderecoFaturamento)} placeholder="Informe o Endereço"/>
													</FormItem>
													<FormItem md={6} label="Endereço de Entrega" help="Para serviço, esse endereço será utilizado para vincular a cidade que o serviço foi prestado!">
														{this.state.model.flEnderecoEntregaMesmoFaturamento ? 
															<OneToOneFormEndereco name="enderecoEntrega" value={this.state.model.enderecoFaturamento} readOnly={true} required={false} disabled={true}/>
															:
															<OneToOneFormEndereco name="enderecoEntrega" value={this.state.model.enderecoEntrega} required={true} onChange={this.handleChangeModel} placeholder="Informe o Endereço"/>
														}
													</FormItem>
												</Row>
											</Tab>
											<Tab title={<><IconBank2/> Notas Fiscais</>} className="m-2" disabled={this.state.tipoNotaFiscal.length === 0}>
												<Row className="py-3 pb-2 px-2 ps-4" >
													<InputCheckSwitch show={this.state.model.flPodeNFServicoMunicipal} label="Emitir Nota Fiscal de Serviço (Municipal)?" name="flNFServicoMunicipal" checked={this.state.model.flNFServicoMunicipal} onChange={(e,v,p) => this.handleChangeModel(e,v,p, e => {this.buildParcelas(false); })} />
													<InputCheckSwitch show={this.state.model.flPodeNFServicoNacional} label="Emitir Nota Fiscal de Serviço (Nacional)?" name="flNFServicoNacional" checked={this.state.model.flNFServicoNacional} onChange={(e,v,p) => this.handleChangeModel(e,v,p, e => {this.buildParcelas(false); })} />
													<InputCheckSwitch show={this.state.model.flPodeNFProduto} label="Emitir Nota Fiscal de Produto?" name="flNFProduto" checked={this.state.model.flNFProduto} onChange={(e,v,p) => this.handleChangeModel(e,v,p, e => {this.buildParcelas(false); })} />
													<InputCheckSwitch show={this.state.model.flPodeNFConsumidor} label="Emitir Nota Fiscal de Consumidor?" name="flNFConsumidor" checked={this.state.model.flNFConsumidor} onChange={(e,v,p) => this.handleChangeModel(e,v,p, e => {this.buildParcelas(false); })} />
												</Row>
												<Row className="g-3 py-3 pb-2 px-2">
													<FormItem show={this.state.model.flPodeNFServicoMunicipal && this.state.model.flNFServicoMunicipal} md={4} label="Agrupamento da NFSe" help={<>Agrupamento por:<ul><li>Fatura: Gera uma única nota fiscal por fatura.</li><li>Ordem: Gera uma nota fiscal para cada ordem.</li></ul></>}>
														<BtnRadioGroup name="cdAgrupadorNFServicoMunicipal" color={BtnRadioGroup.color.primary} opcional={true} value={this.state.model.cdAgrupadorNFServicoMunicipal} onClick={(e,v,p) => this.handleChange(e,v,p, this.state.model, this.getDadoNotaFiscal)} >
															<BtnRadio value="FATURA" label="Fatura" />
															<BtnRadio value="ORDEM" label="Ordem" />																	
														</BtnRadioGroup>
													</FormItem>
												</Row>
											</Tab>
											{this.state.model.nfsMunicipal.map((nfsMunicipal, indexNfsMunicipal) => 
												<Tab key={indexNfsMunicipal + "-" + this.state.model.cdAgrupadorNFServicoMunicipal + "-" + this.state.tabIndexNfseMunicipal} title={<><IconUniversalAccess/> NFSe</>} className="p-0 m-0" show={this.state.model.flNFServicoMunicipal && this.state.model.flPodeNFServicoMunicipal}>
													<TabNavigator onChangeTab={this.handleChangeTabNfseMunicipal} className="border-light" index={this.state.tabIndexNfseMunicipal}>

														{this.state.model.cdAgrupadorNFServicoMunicipal === "ORDEM" ? 
															<Tab title={<><IconCardChecklist/> Ordem</>} className="m-2">
																<FormOrdemView key={nfsMunicipal.ordem?.idOrdem} id={nfsMunicipal.ordem?.idOrdem} localOpen={BaseList.localOpen.embedded} handleSuccess={e => {}} handleClose={false}/>
															</Tab>
														: <></>}
														
														<Tab title={<><IconBank2/> Nota</>} className="m-2">
															<Row className="g-3 py-3 pb-2 px-2">

																<FormItem md={3} label="Identificador Externo">
																	<InputText name="cdIdentificadorExterno" value={nfsMunicipal?.cdIdentificadorExterno} maxLength={100} required={false} onChange={(e,v,p) => this.handleChange(e,v,p, nfsMunicipal)}/>
																</FormItem>
																
																<FormItem md={3} label="Pedido Externo">
																	<InputText name="cdPedidoExterno" value={nfsMunicipal?.cdPedidoExterno} maxLength={100} required={false} onChange={(e,v,p) => this.handleChange(e,v,p, nfsMunicipal)}/>
																</FormItem>
																
																<FormItem md={2} label="Envio imediato?" help="Marcando essa opção como SIM, a nota fiscal será enviada automáticamente após o faturamento!">
																	<BtnRadioGroupSimNao name="flEnvioAutomatico" value={nfsMunicipal?.flEnvioAutomatico} required={true} onChange={(e,v,p) => this.handleChange(e,v,p, nfsMunicipal)}/>
																</FormItem>

																<FormItem md={2} label="Enviar E-mail?">
																	<BtnRadioGroupSimNao name="flEnviarEmail" value={nfsMunicipal?.flEnviarEmail} required={true} onChange={(e,v,p) => this.handleChange(e,v,p, nfsMunicipal)}/>
																</FormItem>

																<FormItem md={2} label="Envio Prioritário?" help="Prioriza o envio da NFS-e em relação às demais notas que estão aguardando serem processadas na fila. Campo válido somente para cidades que controlam a sequência de RPS e que estejam com o envio em lote habilitado no PlugNotas.">
																	<BtnRadioGroupSimNao name="flEnvioPrioritario" value={nfsMunicipal?.flEnvioPrioritario} required={true} onChange={(e,v,p) => this.handleChange(e,v,p, nfsMunicipal)}/>
																</FormItem>

																<FormItem md={12} label="Endereço de Entrega" help="Esse endereço será utilizado para vincular a cidade que o serviço foi prestado!">
																	<OneToOneFormEndereco type="InputText" flSomenteCidade={true} name="enderecoEntrega" value={nfsMunicipal.enderecoEntrega} required={true} onChange={(e,v,p) => this.handleChange(e,v,p, nfsMunicipal)} placeholder="Informe o Endereço"/>
																</FormItem>

																<FormItem md={12} label="Descrição do RPS e serviços prestados">
																	<TextArea name="txDescricao" value={nfsMunicipal?.txDescricao} required={false} onChange={(e,v,p) => this.handleChange(e,v,p, nfsMunicipal)}/>
																</FormItem>

																<FormItem md={12} label="Informações complementares a nota">
																	<TextArea name="txDescricaoComplementar" value={nfsMunicipal?.txDescricaoComplementar} required={false} onChange={(e,v,p) => this.handleChange(e,v,p, nfsMunicipal)}/>
																</FormItem>

																<FormItem md={6} label="Natureza da Tributação">
																	<SelectNaturezaTributacaoNotaFiscal filter={{index : this.state.tabIndexFatura}} getOptionsWhen={() => parseInt(indexNfsMunicipal) === parseInt(this.state.tabIndexFatura - 3 >= 0 ? this.state.tabIndexFatura -3 : 0)} name="naturezaTributacaoNotaFiscal" value={nfsMunicipal?.naturezaTributacaoNotaFiscal} required={false} onChange={(e,v,p) => this.handleChange(e,v,p, nfsMunicipal)} placeholder="Selecione a Natureza da Tributação" buttons={{search : false, new : false, edit: false}}/>
																</FormItem>
															</Row>
															<Div className="d-flex flex-row-reverse pt-2" show={this.state.model.nfsMunicipal.length > 1}>
																<BtnButton sizing={BtnButton.sizing.sm} color={BtnButton.color.outlinePrimary} onClick={e => this.handleClickCopiar(indexNfsMunicipal, "GERAL")}> <IconCopy/> Copiar para outras notas </BtnButton>
															</Div>
														</Tab>
														<Tab title={<><IconBasket/> Item</>} className="m-2">
															<Row className="g-3 py-3 pb-2 px-2">
																<FormItem md={3} label="Código de Serviço" help="Código de serviço, também conhecido como Código Item Lista Serviço, geralmente segue a LC116, podendo váriar de acordo com a prefeitura. Você pode encontrar esse dado no portal da prefeitura, em uma nota emitida, ou junto ao contador.">
																	<InputText name="cdItemListaServico" value={nfsMunicipal.cdItemListaServico} maxLength={100} required={true} onChange={(e,v,p) => this.handleChange(e,v,p, nfsMunicipal)}/>
																</FormItem>

																<FormItem md={3} label="Tributacao Municipio" help="Código tributação no Município">
																	<InputText name="cdTributacaoMunicipio" value={nfsMunicipal.cdTributacaoMunicipio} maxLength={100} required={false} onChange={(e,v,p) => this.handleChange(e,v,p, nfsMunicipal)}/>
																</FormItem>

																<FormItem md={3} label="Código CNAE" help="O código CNAE ou, por extenso, Classificação Nacional de Atividades Econômicas, é uma forma de padronizar, em todo o território nacional, os códigos de atividades econômicas e os critérios de enquadramento usados pelos mais diversos órgãos da administração tributária do Brasil.">
																	<InputText name="cdClassificacaoCnae" value={nfsMunicipal.cdClassificacaoCnae} maxLength={100} required={false} onChange={(e,v,p) => this.handleChange(e,v,p, nfsMunicipal)}/>
																</FormItem>

																<FormItem md={3} label="Base Cálculo">
																	<InputNumberFormat name="vlBaseCalculo" value={nfsMunicipal.vlBaseCalculo} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} placeholder="R$ 0,00" prefix="R$ " required={true} onChange={(e,v,p) => this.handleChange(e,v,p, nfsMunicipal)}/>
																</FormItem>

																<FormItem md={3} label="Deduções">
																	<InputNumberFormat name="vlDeducoes" value={nfsMunicipal.vlDeducoes} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} placeholder="R$ 0,00" prefix="R$ " required={true} onChange={(e,v,p) => this.handleChange(e,v,p, nfsMunicipal)}/>
																</FormItem>

																<FormItem md={3} label="Desconto condicionado">
																	<InputNumberFormat name="vlDescontoCondicionado" value={nfsMunicipal.vlDescontoCondicionado} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} placeholder="R$ 0,00" prefix="R$ " required={true} onChange={(e,v,p) => this.handleChange(e,v,p, nfsMunicipal)}/>
																</FormItem>

																<FormItem md={3} label="Desconto Incondicionado">
																	<InputNumberFormat name="vlDescontoIncondicionado" value={nfsMunicipal.vlDescontoIncondicionado} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} placeholder="R$ 0,00" prefix="R$ " required={true} onChange={(e,v,p) => this.handleChange(e,v,p, nfsMunicipal)}/>
																</FormItem>

																<FormItem md={12} label="Detalhamento do serviço prestado">
																	<InputGroup>
																		<TextArea rows={6} name="txDiscriminacao" value={nfsMunicipal.txDiscriminacao} required={true} onChange={(e,v,p) => this.handleChange(e,v,p, nfsMunicipal)}/>
																		<InputGroupButton>
																			<BtnButton onClick={e => this.setTxDiscriminacao(indexNfsMunicipal)}> <IconArrowClockwise/> </BtnButton>
																			<BtnButton show={this.state.model.nfsMunicipal.length > 1} color={BtnButton.color.dark} onClick={e => this.setTxDiscriminacao(null)}> <IconArrowRepeat/> </BtnButton>
																		</InputGroupButton>
																	</InputGroup>
																</FormItem>

																<FormItem md={3} label="Sujeito a Tributação?">
																	<BtnRadioGroupSimNao name="flTributacao" value={nfsMunicipal.flTributacao} required={true} onChange={(e,v,p) => this.handleChange(e,v,p, nfsMunicipal)}/>
																</FormItem>

																<FormItem md={5} label="Responsável Retenção">
																	<BtnRadioGroup name="cdResponsavelRetencao" color={BtnRadioGroup.color.primary} opcional={true} value={nfsMunicipal.cdResponsavelRetencao} onClick={(e,v,p) => this.handleChange(e,v,p, nfsMunicipal)} >
																		<BtnRadio value="PRESTADOR" label="Prestador" />
																		<BtnRadio value="TOMADOR" label="Tomador" />
																		<BtnRadio value="INTERMEDIARIO" label="Intermediário" disabled={!nfsMunicipal.intermediario.flTemIntermediario}/>
																	</BtnRadioGroup>
																</FormItem>
															</Row>

															<Div className="d-flex flex-row-reverse pt-2" show={this.state.model.nfsMunicipal.length > 1}>
																<BtnButton sizing={BtnButton.sizing.sm} color={BtnButton.color.outlinePrimary} onClick={e => this.handleClickCopiar(indexNfsMunicipal, "ITEM")}> <IconCopy/> Copiar para outras notas </BtnButton>
															</Div>
														</Tab>
														<Tab title={<><IconPercent/> ISS</>} className="m-2">
															<Row className="g-3 py-3 pb-2 px-2">

																<FormItem md={2} label="ISS Retido?">
																	<BtnRadioGroupSimNao name="flRetido" value={nfsMunicipal.iss.flRetido} required={true} onChange={(e,v,p) => this.handleChange(e,v,p, nfsMunicipal.iss, e => {this.setTxDiscriminacao(indexNfsMunicipal); this.buildParcelas(false); })}/>
																</FormItem>

																<FormItem md={2} label="Aliquota" show={true} help="Alíquota do ISS do serviço prestado. Você pode encontrar esse dado no portal da prefeitura, ou junto ao contador.">
																	<InputNumberFormat placeholder="0,00 %" name="vlAliquota" value={nfsMunicipal.iss.vlAliquota} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} suffix=" %" required={true} onChange={(e,v,p) => this.handleChange(e,v,p, nfsMunicipal.iss, () => this.buildParcelas(false))}/>
																</FormItem>

																<FormItem md={2} label="Valor" show={true}>
																	<InputGroup>
																		<InputNumberFormat disabled={nfsMunicipal.iss.vlValor === null} name="vlValor" value={nfsMunicipal.iss.vlValor} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} placeholder="R$ 0,00" prefix="R$ " required={true} onChange={(e,v,p) => this.handleChange(e,v,p, nfsMunicipal.iss, () => this.buildParcelas(false))}/>
																		<InputGroupButton>
																			<BtnButton color={nfsMunicipal.iss.vlValor !== null ? BtnButton.color.outlineDark : BtnButton.color.dark} onClick={e => this.setState(state => {nfsMunicipal.iss.vlValor = nfsMunicipal.iss.vlValor === null ? 0 : null; return state;})}> 
																				<IconPencil/>
																			</BtnButton>
																		</InputGroupButton>
																	</InputGroup>
																</FormItem>

																<FormItem md={2} label="Retido" show={true}>
																	<InputGroup>
																		<InputNumberFormat disabled={nfsMunicipal.iss.vlRetido === null} name="vlRetido" value={nfsMunicipal.iss.vlRetido} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} placeholder="R$ 0,00" prefix="R$ " required={true} onChange={(e,v,p) => this.handleChange(e,v,p, nfsMunicipal.iss, () => this.buildParcelas(false))}/>
																		<InputGroupButton>
																			<BtnButton color={nfsMunicipal.iss.vlRetido === null ? BtnButton.color.dark : BtnButton.color.outlineDark} onClick={e => this.setState(state => {nfsMunicipal.iss.vlRetido = nfsMunicipal.iss.vlRetido === null ? 0 : null; return state;})}> 
																				<IconPencil/>
																			</BtnButton>
																		</InputGroupButton>
																	</InputGroup>
																</FormItem>
																
																<FormItem md={4} label="Processo Suspensão" show={true} help="Número do Processo de Suspensão da Exigibilidade">
																	<InputText name="dsProcessoSuspensao" value={nfsMunicipal.iss.dsProcessoSuspensao} maxLength={100} required={false} onChange={(e,v,p) => this.handleChange(e,v,p, nfsMunicipal.iss)}/>
																</FormItem>

																<FormItem md={6} label="Tipo de Tributação do Serviço" show={true}>
																	<SelectTipoTributacaoServico filter={{index : this.state.tabIndexFatura}} getOptionsWhen={() => parseInt(indexNfsMunicipal) === parseInt(this.state.tabIndexFatura - 3 >= 0 ? this.state.tabIndexFatura -3 : 0)} name="tipoTributacaoServico" value={nfsMunicipal.iss.tipoTributacaoServico} required={false} onChange={(e,v,p) => this.handleChange(e,v,p, nfsMunicipal.iss)} placeholder="Selecione Tipo de Tributação do Serviço" buttons={{search : false, new : false, edit: false}}/>
																</FormItem>
																<FormItem md={6} label="Tipo de Exigibilidade do Serviço" show={true}>
																	<SelectTipoExigibilidadeServico filter={{index : this.state.tabIndexFatura}} getOptionsWhen={() => parseInt(indexNfsMunicipal) === parseInt(this.state.tabIndexFatura - 3 >= 0 ? this.state.tabIndexFatura -3 : 0)} name="tipoExigibilidadeServico" value={nfsMunicipal.iss.tipoExigibilidadeServico} required={false} onChange={(e,v,p) => this.handleChange(e,v,p, nfsMunicipal.iss)} placeholder="Selecione Tipo de Exigibilidade do Serviço" buttons={{search : false, new : false, edit: false}}/>
																</FormItem>															
															</Row>
															<Div className="d-flex flex-row-reverse pt-2" show={this.state.model.nfsMunicipal.length > 1}>
																<BtnButton sizing={BtnButton.sizing.sm} color={BtnButton.color.outlinePrimary} onClick={e => this.handleClickCopiar(indexNfsMunicipal, "ISS")}> <IconCopy/> Copiar para outras notas </BtnButton>
															</Div>
														</Tab>
														<Tab title={<><IconBagDash/> Retenção</>} className="m-2">
															<Row className="g-3 py-1 px-2">
																<FormItem md={2} label="PIS?">
																	<BtnRadioGroupSimNao name="flRetido" value={nfsMunicipal.retencao.pis.flRetido} required={true} onChange={(e,v,p) => this.handleChange(e,v,p, nfsMunicipal.retencao.pis, () => this.buildParcelas(false))}/>
																</FormItem>
																<FormItem md={3} label="Tipo" show={nfsMunicipal.retencao.pis.flRetido}>
																	<BtnRadioGroup name="cdTipoValor" color={BtnRadioGroup.color.primary} opcional={true} value={nfsMunicipal.retencao.pis.cdTipoValor} onClick={(e,v,p) => this.handleChange(e,v,p, nfsMunicipal.retencao.pis)} >
																		<BtnRadio value="PERCENTUAL" label="Percentual" />
																		<BtnRadio value="VALOR" label="Valor" />
																	</BtnRadioGroup>
																</FormItem>
																<FormItem md={2} label="Aliquota" show={nfsMunicipal.retencao.pis.flRetido && nfsMunicipal.retencao.pis.cdTipoValor === "PERCENTUAL"}>
																	<InputNumberFormat placeholder="0,00 %" name="vlAliquota" value={nfsMunicipal.retencao.pis.vlAliquota} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} suffix=" %" required={true} onChange={(e,v,p) => this.handleChange(e,v,p, nfsMunicipal.retencao.pis, () => this.buildParcelas(false))}/>
																</FormItem>																
																<FormItem md={2} label="Valor" show={nfsMunicipal.retencao.pis.flRetido && nfsMunicipal.retencao.pis.cdTipoValor === "VALOR"}>
																	<InputNumberFormat name="vlValor" value={nfsMunicipal.retencao.pis.vlValor} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} placeholder="R$ 0,00" prefix="R$ " required={true} onChange={(e,v,p) => this.handleChange(e,v,p, nfsMunicipal.retencao.pis, () => this.buildParcelas(false))}/>
																</FormItem>
																<FormItem md={2} label="Base Cálculo" show={nfsMunicipal.retencao.pis.flRetido && nfsMunicipal.retencao.pis.cdTipoValor === "PERCENTUAL"} help="Caso não informada, será utilizado o valor do serviço">																	
																	<InputGroup>
																		<InputNumberFormat disabled={nfsMunicipal.retencao.pis.vlBaseCalculo === null} name="vlBaseCalculo" value={nfsMunicipal.retencao.pis.vlBaseCalculo} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} placeholder="R$ 0,00" prefix="R$ " required={true} onChange={(e,v,p) => this.handleChange(e,v,p, nfsMunicipal.retencao.pis, () => this.buildParcelas(false))}/>
																		<InputGroupButton>
																			<BtnButton color={nfsMunicipal.retencao.pis.vlBaseCalculo !== null ? BtnButton.color.outlineDark : BtnButton.color.dark} onClick={e => this.setState(state => {nfsMunicipal.retencao.pis.vlBaseCalculo = nfsMunicipal.retencao.pis.vlBaseCalculo === null ? 0 : null; return state;})}>
																				<IconPencil/>
																			</BtnButton>
																		</InputGroupButton>
																	</InputGroup>
																</FormItem>
															</Row>
															<Row className="g-3 py-1 px-2">
																<FormItem md={2} label="COFINS?">
																	<BtnRadioGroupSimNao name="flRetido" value={nfsMunicipal.retencao.cofins.flRetido} required={true} onChange={(e,v,p) => this.handleChange(e,v,p, nfsMunicipal.retencao.cofins, () => this.buildParcelas(false))}/>
																</FormItem>
																<FormItem md={3} label="Tipo" show={nfsMunicipal.retencao.cofins.flRetido}>
																	<BtnRadioGroup name="cdTipoValor" color={BtnRadioGroup.color.primary} opcional={true} value={nfsMunicipal.retencao.cofins.cdTipoValor} onClick={(e,v,p) => this.handleChange(e,v,p, nfsMunicipal.retencao.cofins)} >
																		<BtnRadio value="PERCENTUAL" label="Percentual" />
																		<BtnRadio value="VALOR" label="Valor" />
																	</BtnRadioGroup>
																</FormItem>												
																<FormItem md={2} label="Aliquota" show={nfsMunicipal.retencao.cofins.flRetido && nfsMunicipal.retencao.cofins.cdTipoValor === "PERCENTUAL"}>
																	<InputNumberFormat placeholder="0,00 %" name="vlAliquota" value={nfsMunicipal.retencao.cofins.vlAliquota} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} suffix=" %" required={true} onChange={(e,v,p) => this.handleChange(e,v,p, nfsMunicipal.retencao.cofins, () => this.buildParcelas(false))}/>
																</FormItem>																
																<FormItem md={2} label="Valor" show={nfsMunicipal.retencao.cofins.flRetido && nfsMunicipal.retencao.cofins.cdTipoValor === "VALOR"}>																	
																	<InputNumberFormat name="vlValor" value={nfsMunicipal.retencao.cofins.vlValor} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} placeholder="R$ 0,00" prefix="R$ " required={true} onChange={(e,v,p) => this.handleChange(e,v,p, nfsMunicipal.retencao.cofins, () => this.buildParcelas(false))}/>
																</FormItem>
																<FormItem md={2} label="Base Cálculo" show={nfsMunicipal.retencao.cofins.flRetido && nfsMunicipal.retencao.cofins.cdTipoValor === "PERCENTUAL"} help="Caso não informada, será utilizado o valor do serviço">
																	<InputNumberFormat name="vlBaseCalculo" value={nfsMunicipal.retencao.cofins.vlBaseCalculo} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} placeholder="R$ 0,00" prefix="R$ " required={true} onChange={(e,v,p) => this.handleChange(e,v,p, nfsMunicipal.retencao.cofins, () => this.buildParcelas(false))}/>
																</FormItem>
															</Row>
															<Row className="g-3 py-1 px-2">
																<FormItem md={2} label="CSLL?">
																	<BtnRadioGroupSimNao name="flRetido" value={nfsMunicipal.retencao.csll.flRetido} required={true} onChange={(e,v,p) => this.handleChange(e,v,p, nfsMunicipal.retencao.csll, () => this.buildParcelas(false))}/>
																</FormItem>
																<FormItem md={3} label="Tipo" show={nfsMunicipal.retencao.csll.flRetido}>
																	<BtnRadioGroup name="cdTipoValor" color={BtnRadioGroup.color.primary} opcional={true} value={nfsMunicipal.retencao.csll.cdTipoValor} onClick={(e,v,p) => this.handleChange(e,v,p, nfsMunicipal.retencao.csll)} >
																		<BtnRadio value="PERCENTUAL" label="Percentual" />
																		<BtnRadio value="VALOR" label="Valor" />
																	</BtnRadioGroup>
																</FormItem>												
																<FormItem md={2} label="Aliquota" show={nfsMunicipal.retencao.csll.flRetido && nfsMunicipal.retencao.csll.cdTipoValor === "PERCENTUAL"}>
																	<InputNumberFormat placeholder="0,00 %" name="vlAliquota" value={nfsMunicipal.retencao.csll.vlAliquota} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} suffix=" %" required={true} onChange={(e,v,p) => this.handleChange(e,v,p, nfsMunicipal.retencao.csll, () => this.buildParcelas(false))}/>
																</FormItem>
																<FormItem md={2} label="Valor" show={nfsMunicipal.retencao.csll.flRetido && nfsMunicipal.retencao.csll.cdTipoValor === "VALOR"}>																	
																	<InputNumberFormat name="vlValor" value={nfsMunicipal.retencao.csll.vlValor} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} placeholder="R$ 0,00" prefix="R$ " required={true} onChange={(e,v,p) => this.handleChange(e,v,p, nfsMunicipal.retencao.csll, () => this.buildParcelas(false))}/>
																</FormItem>
															</Row>
															<Row className="g-3 py-1 px-2">
																<FormItem md={2} label="INSS?">
																	<BtnRadioGroupSimNao name="flRetido" value={nfsMunicipal.retencao.inss.flRetido} required={true} onChange={(e,v,p) => this.handleChange(e,v,p, nfsMunicipal.retencao.inss, () => this.buildParcelas(false))}/>
																</FormItem>
																<FormItem md={3} label="Tipo" show={nfsMunicipal.retencao.inss.flRetido}>
																	<BtnRadioGroup name="cdTipoValor" color={BtnRadioGroup.color.primary} opcional={true} value={nfsMunicipal.retencao.inss.cdTipoValor} onClick={(e,v,p) => this.handleChange(e,v,p, nfsMunicipal.retencao.inss)} >
																		<BtnRadio value="PERCENTUAL" label="Percentual" />
																		<BtnRadio value="VALOR" label="Valor" />
																	</BtnRadioGroup>
																</FormItem>												
																<FormItem md={2} label="Aliquota" show={nfsMunicipal.retencao.inss.flRetido && nfsMunicipal.retencao.inss.cdTipoValor === "PERCENTUAL"}>
																	<InputNumberFormat placeholder="0,00 %" name="vlAliquota" value={nfsMunicipal.retencao.inss.vlAliquota} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} suffix=" %" required={true} onChange={(e,v,p) => this.handleChange(e,v,p, nfsMunicipal.retencao.inss, () => this.buildParcelas(false))}/>
																</FormItem>
																<FormItem md={2} label="Valor" show={nfsMunicipal.retencao.inss.flRetido && nfsMunicipal.retencao.inss.cdTipoValor === "VALOR"}>																	
																	<InputNumberFormat name="vlValor" value={nfsMunicipal.retencao.inss.vlValor} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} placeholder="R$ 0,00" prefix="R$ " required={true} onChange={(e,v,p) => this.handleChange(e,v,p, nfsMunicipal.retencao.inss, () => this.buildParcelas(false))}/>
																</FormItem>
															</Row>
															<Row className="g-3 py-1 px-2">
																<FormItem md={2} label="IRRF?">
																	<BtnRadioGroupSimNao name="flRetido" value={nfsMunicipal.retencao.irrf.flRetido} required={true} onChange={(e,v,p) => this.handleChange(e,v,p, nfsMunicipal.retencao.irrf, () => this.buildParcelas(false))}/>
																</FormItem>
																<FormItem md={3} label="Tipo" show={nfsMunicipal.retencao.irrf.flRetido}>
																	<BtnRadioGroup name="cdTipoValor" color={BtnRadioGroup.color.primary} opcional={true} value={nfsMunicipal.retencao.irrf.cdTipoValor} onClick={(e,v,p) => this.handleChange(e,v,p, nfsMunicipal.retencao.irrf)} >
																		<BtnRadio value="PERCENTUAL" label="Percentual" />
																		<BtnRadio value="VALOR" label="Valor" />
																	</BtnRadioGroup>
																</FormItem>												
																<FormItem md={2} label="Aliquota" show={nfsMunicipal.retencao.irrf.flRetido && nfsMunicipal.retencao.irrf.cdTipoValor === "PERCENTUAL"}>
																	<InputNumberFormat placeholder="0,00 %" name="vlAliquota" value={nfsMunicipal.retencao.irrf.vlAliquota} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} suffix=" %" required={true} onChange={(e,v,p) => this.handleChange(e,v,p, nfsMunicipal.retencao.irrf, () => this.buildParcelas(false))}/>
																</FormItem>
																<FormItem md={2} label="Valor" show={nfsMunicipal.retencao.irrf.flRetido && nfsMunicipal.retencao.irrf.cdTipoValor === "VALOR"}>																	
																	<InputNumberFormat name="vlValor" value={nfsMunicipal.retencao.irrf.vlValor} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} placeholder="R$ 0,00" prefix="R$ " required={true} onChange={(e,v,p) => this.handleChange(e,v,p, nfsMunicipal.retencao.irrf, () => this.buildParcelas(false))}/>
																</FormItem>
															</Row>
															<Row className="g-3 py-1 px-2">
																<FormItem md={2} label="CPP?">
																	<BtnRadioGroupSimNao name="flRetido" value={nfsMunicipal.retencao.cpp.flRetido} required={true} onChange={(e,v,p) => this.handleChange(e,v,p, nfsMunicipal.retencao.cpp, () => this.buildParcelas(false))}/>
																</FormItem>
																<FormItem md={3} label="Tipo" show={nfsMunicipal.retencao.cpp.flRetido}>
																	<BtnRadioGroup name="cdTipoValor" color={BtnRadioGroup.color.primary} opcional={true} value={nfsMunicipal.retencao.cpp.cdTipoValor} onClick={(e,v,p) => this.handleChange(e,v,p, nfsMunicipal.retencao.cpp)} >
																		<BtnRadio value="PERCENTUAL" label="Percentual" />
																		<BtnRadio value="VALOR" label="Valor" />
																	</BtnRadioGroup>
																</FormItem>												
																<FormItem md={2} label="Aliquota" show={nfsMunicipal.retencao.cpp.flRetido && nfsMunicipal.retencao.cpp.cdTipoValor === "PERCENTUAL"}>
																	<InputNumberFormat placeholder="0,00 %" name="vlAliquota" value={nfsMunicipal.retencao.cpp.vlAliquota} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} suffix=" %" required={true} onChange={(e,v,p) => this.handleChange(e,v,p, nfsMunicipal.retencao.cpp, () => this.buildParcelas(false))}/>
																</FormItem>
																<FormItem md={2} label="Valor" show={nfsMunicipal.retencao.cpp.flRetido && nfsMunicipal.retencao.cpp.cdTipoValor === "VALOR"}>																	
																	<InputNumberFormat name="vlValor" value={nfsMunicipal.retencao.cpp.vlValor} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} placeholder="R$ 0,00" prefix="R$ " required={true} onChange={(e,v,p) => this.handleChange(e,v,p, nfsMunicipal.retencao.cpp, () => this.buildParcelas(false))}/>
																</FormItem>
															</Row>
															<Row className="g-3 py-1 px-2">
																<FormItem md={2} label="Outras?">
																	<BtnRadioGroupSimNao name="flRetido" value={nfsMunicipal.retencao.outras.flRetido} required={true} onChange={(e,v,p) => this.handleChange(e,v,p, nfsMunicipal.retencao.outras, () => this.buildParcelas(false))}/>
																</FormItem>
																<FormItem md={3} label="Tipo" show={nfsMunicipal.retencao.outras.flRetido}>
																	<BtnRadioGroup name="cdTipoValor" color={BtnRadioGroup.color.primary} opcional={true} value={nfsMunicipal.retencao.outras.cdTipoValor} onClick={(e,v,p) => this.handleChange(e,v,p, nfsMunicipal.retencao.outras)} disabled={true}>
																		<BtnRadio value="PERCENTUAL" label="Percentual" />
																		<BtnRadio value="VALOR" label="Valor" />
																	</BtnRadioGroup>
																</FormItem>
																<FormItem md={2} label="Valor" show={nfsMunicipal.retencao.outras.flRetido}>
																	<InputNumberFormat name="vlValor" value={nfsMunicipal.retencao.outras.vlValor} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} placeholder="R$ 0,00" prefix="R$ " required={true} onChange={(e,v,p) => this.handleChange(e,v,p, nfsMunicipal.retencao.outras, () => this.buildParcelas(false))}/>
																</FormItem>
															</Row>
															<Div className="d-flex flex-row-reverse pt-2" show={this.state.model.nfsMunicipal.length > 1}>
																<BtnButton sizing={BtnButton.sizing.sm} color={BtnButton.color.outlinePrimary} onClick={e => this.handleClickCopiar(indexNfsMunicipal, "RETENCAO")}> <IconCopy/> Copiar para outras notas </BtnButton>
															</Div>
														</Tab>
														<Tab title={<><IconConeStriped/> Obra</>} className="m-2">
															<Row className="g-0 py-2 px-3">
																<FormItem md={3} label="Vincular uma obra a emissão da nota fiscal?">
																	<BtnRadioGroupSimNao name="flTemObra" value={nfsMunicipal.obra.flTemObra} required={true} onChange={(e,v,p) => this.handleChange(e,v,p, nfsMunicipal.obra)}/>
																</FormItem>
															</Row>
															<Row className="g-3 py-2 px-3"  show={nfsMunicipal.obra.flTemObra}>
																<FormItem md={4} label="Código da ART" >
																	<InputText name="cdArt" value={nfsMunicipal.obra.cdArt} maxLength={100} required={false} onChange={(e,v,p) => this.handleChange(e,v,p, nfsMunicipal.obra)}/>
																</FormItem>
																<FormItem md={4} label="Código da obra (CO)." >
																	<InputText name="cdObra" value={nfsMunicipal.obra.cdObra} maxLength={100} required={false} onChange={(e,v,p) => this.handleChange(e,v,p, nfsMunicipal.obra)}/>
																</FormItem>
																<FormItem md={4} label="Código do INSS (CEI)." >
																	<InputText name="cdInss" value={nfsMunicipal.obra.cdInss} maxLength={100} required={false} onChange={(e,v,p) => this.handleChange(e,v,p, nfsMunicipal.obra)}/>
																</FormItem>
															</Row>
															<Row className="g-0 py-2 px-3" show={nfsMunicipal.obra.flTemObra}>
																<InputCheckSwitch label="Endereço da Obra é o mesmo endereço da entrega?" name="flMesmoEnderecoEntegra" value={nfsMunicipal.obra.flMesmoEnderecoEntegra} onChange={(e,v,p) => this.handleChange(e,v,p, nfsMunicipal.obra)}/>
															</Row>
															<Row className="g-0 py-2 px-3" show={nfsMunicipal.obra.flTemObra}>
																<FormItem md={12} label="Endereço da Obra">
																	{nfsMunicipal.obra.flMesmoEnderecoEntegra ?
																		<OneToOneFormEndereco value={this.state.model.flEnderecoEntregaMesmoFaturamento ? this.state.model.enderecoFaturamento : this.state.model.enderecoEntrega} readOnly={true} required={false} disabled={true}/>
																		: <OneToOneFormEndereco name="endereco" value={nfsMunicipal.obra.endereco} required={false} onChange={(e,v,p) => this.handleChange(e,v,p, nfsMunicipal.obra)} placeholder="Informe o Endereço"/>
																	}
																</FormItem>
															</Row>
															<Div className="d-flex flex-row-reverse pt-2" show={this.state.model.nfsMunicipal.length > 1}>
																<BtnButton sizing={BtnButton.sizing.sm} color={BtnButton.color.outlinePrimary} onClick={e => this.handleClickCopiar(indexNfsMunicipal, "OBRA")}> <IconCopy/> Copiar para outras notas </BtnButton>
															</Div>
														</Tab>
														<Tab title={<><IconClipboardMinus/> Dedução</>} className="m-2" disabled={true}>
														</Tab>
														<Tab title={<><IconDashCircle/> Carga Tributária</>} className="m-2">
															<Row className="g-0 py-2 px-3">
																<FormItem md={3} label="Tem Carga Tributária?">
																	<BtnRadioGroupSimNao name="flTemCargaTributaria" value={nfsMunicipal.cargaTributaria.flTemCargaTributaria} required={true} onChange={(e,v,p) => this.handleChange(e,v,p, nfsMunicipal.cargaTributaria)}/>
																</FormItem>
															</Row>
															<Row className="g-3 py-2 px-3"  show={nfsMunicipal.cargaTributaria.flTemCargaTributaria}>
																<FormItem md={6} label="Fonte" >
																	<InputText name="dsFonte" value={nfsMunicipal.cargaTributaria.dsFonte} maxLength={100} required={true} onChange={(e,v,p) => this.handleChange(e,v,p, nfsMunicipal.cargaTributaria)}/>
																</FormItem>
																<FormItem md={3} label="Tipo">
																	<BtnRadioGroup name="cdTipoValor" color={BtnRadioGroup.color.primary} opcional={true} value={nfsMunicipal.cargaTributaria.cdTipoValor} onClick={(e,v,p) => this.handleChange(e,v,p, nfsMunicipal.cargaTributaria)} >
																		<BtnRadio value="PERCENTUAL" label="Percentual" />
																		<BtnRadio value="VALOR" label="Valor" />
																	</BtnRadioGroup>
																</FormItem>
																<FormItem md={3} label="Percentual" show={nfsMunicipal.cargaTributaria.cdTipoValor === "PERCENTUAL"}>
																	<InputNumberFormat name="vlCargaTributaria" value={nfsMunicipal.cargaTributaria.vlCargaTributaria} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} suffix=" %" required={true} onChange={(e,v,p) => this.handleChange(e,v,p, nfsMunicipal.cargaTributaria)}/>
																</FormItem>
																<FormItem md={3} label="Valor" show={nfsMunicipal.cargaTributaria.cdTipoValor === "VALOR"}>
																	<InputNumberFormat name="vlCargaTributaria" value={nfsMunicipal.cargaTributaria.vlCargaTributaria} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} placeholder="R$ 0,00" prefix="R$ " required={true} onChange={(e,v,p) => this.handleChange(e,v,p, nfsMunicipal.cargaTributaria)}/>
																</FormItem>
															</Row>
															<Div className="d-flex flex-row-reverse pt-2" show={this.state.model.nfsMunicipal.length > 1}>
																<BtnButton sizing={BtnButton.sizing.sm} color={BtnButton.color.outlinePrimary} onClick={e => this.handleClickCopiar(indexNfsMunicipal, "CARGA_TRIBUTARIA")}> <IconCopy/> Copiar para outras notas </BtnButton>
															</Div>
														</Tab>
														<Tab title={<><IconPeople/> Intermediário</>} className="m-2">
															<Row className="g-3 py-3 px-3">
																<FormItem md={2} label="Tem Intermediário?">
																	<BtnRadioGroupSimNao name="flTemIntermediario" value={nfsMunicipal.intermediario.flTemIntermediario} required={true} onChange={(e,v,p) => this.handleChange(e,v,p, nfsMunicipal.intermediario)}/>
																</FormItem>
																<FormItemTipoPessoaPessoa name="pessoa" show={nfsMunicipal.intermediario.flTemIntermediario} label={["Tipo Pessoa", "Pessoa"]} md={[3,7]} value={nfsMunicipal.intermediario.pessoa} required={true} onChange={(e,v,p) => this.handleChange(e,v,p, nfsMunicipal.intermediario)}/>
															</Row>
															<Div className="d-flex flex-row-reverse pt-2" show={this.state.model.nfsMunicipal.length > 1}>
																<BtnButton sizing={BtnButton.sizing.sm} color={BtnButton.color.outlinePrimary} onClick={e => this.handleClickCopiar(indexNfsMunicipal, "INTERMEDIARIO")}> <IconCopy/> Copiar para outras notas </BtnButton>
															</Div>
														</Tab>														
													</TabNavigator>
												</Tab>
											)}
											<Tab title={<><IconUniversalAccess/> NFSe</>} className="p-0 m-0" show={this.state.model.flNFServicoNacional && this.state.model.flPodeNFServicoNacional}>

											</Tab>
											<Tab title={<><IconBasket2Fill/> NFe</>} className="p-0 m-0" show={this.state.model.flNFProduto && this.state.model.flPodeNFProduto}>

											</Tab>
											<Tab title={<><IconPerson/> NFCe</>} className="p-0 m-0" show={this.state.model.flNFConsumidor && this.state.model.flPodeNFConsumidor}>

											</Tab>
										</TabNavigator>
									</Form>
								</Tab>
								<Tab show={Permissao.getInstance().getPermissoes([70])} title={<><IconCardChecklist/> Ordens</>} opened={false} className="m-2">
									<DataGrid data={this.state.model.faturaOrdem} search={false} isDisabled={i => false} sizing={DataGrid.sizing.sm} >
										<DataGridColumn label="Ordem" field={i => i.idOrdem } className="text-center"/>
										<DataGridColumn col={3} label="Usuário" field={i =>
											<React.Fragment>
												{i.usuario?.nmUsuario} <br/>
												{Util.date2Br(i?.dhInsert, true)}
											</React.Fragment> } className="text-start"/>
										<DataGridColumn col={3} label="Unidade" field={i => i.unidade?.nmUnidade} className="text-start"/>
										<DataGridColumn col={4} label="Cliente" field={i => i.pessoa?.nmPessoa} className="text-start"/>					
										
										<DataGridColumn mobile={false} md={1} label="Tipo Ordem" field={i => 
											<React.Fragment>
												<StatusCustom color={i.tipoOrdem}> {i.tipoOrdem.nmTipoOrdem} </StatusCustom>
											</React.Fragment>
										} className="text-start"/>
										
										<DataGridColumn md={2} label="Status" field={i => 
											<React.Fragment>
												<StatusCustom className="w-100" color={i.statusOrdem}> {i.statusOrdem?.nmStatusOrdem} </StatusCustom>
											</React.Fragment>
										} className="text-center"/>

										<DataGridButtons>
											<BtnView sizing={Button.sizing.default} onClick={ (e, item) => this.handleVerOrdem(item)}/>
										</DataGridButtons>

									</DataGrid>
								</Tab>
								<Tab show={Permissao.getInstance().getPermissoes([72])} title={<><IconPersonFill/> Pessoa</>} opened={false} className="m-0 p-0">
									<FormPessoa key={this.state.model?.pessoa?.idPessoa} id={this.state.model?.pessoa?.idPessoa} localOpen={BaseList.localOpen.embedded} handleSuccess={e => {}} handleClose={false}/>
								</Tab>								
							</TabNavigator>
						</PanelBody>
						<PanelFooter>
							<FormButtons onClose={this.handleClose} onSubmit={this.handleSubmitForm} label="Faturar"/>
						</PanelFooter>
					</Panel>
				</Div>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <FormFaturar {...props} navigate={navigate} params={params} location={location}/>
}
export default With