
import FormUnidadeTipoNotaFiscal from "./FormUnidadeTipoNotaFiscal.js";
import ListUnidadeTipoNotaFiscal from "./ListUnidadeTipoNotaFiscal.js";
import { Permissao } from "../../controllers";
import { Util } from "../../utils";
import { DadoValorService, UnidadeTipoNotaFiscalService } from "../../services";

export default class Config {

	static getUrlForm() {
		return "/CadUnidadeTipoNotaFiscal";
	}

	static getUrlList() {
		return "/LstUnidadeTipoNotaFiscal";
	}

	static getComponentForm() {
		return FormUnidadeTipoNotaFiscal;
	}

	static getPermissionForm() {
		return Permissao.getInstance().getPermissoes([151]);
	}

	static getPermissionList() {
		return Permissao.getInstance().getPermissoes([151]);
	}

	static getComponentList() {
		return ListUnidadeTipoNotaFiscal;
	}

	static getService() {
		return UnidadeTipoNotaFiscalService;
	}

	static getKeyId() {
		return "idUnidadeTipoNotaFiscal";
	}

	static getKeyDescription() {
		return "nmUnidadeTipoNotaFiscal";
	}

	static setDefaultValues(state , fnCallBack = () => {}, className = undefined) {
		className = className === undefined ? "FormUnidadeTipoNotaFiscal" : className;
		let keyNameScreen = Util.toToUpperTextCamelCase(className, "_") + ".";
		var prefix = "CONFIGURACAO.";
		DadoValorService.getValues(`${prefix}${keyNameScreen}`, (response)=> {
			Util.setValueObjectCamelCase(state.model, response, keyNameScreen, prefix);
			fnCallBack();
		}, fnCallBack);
	}
}