//#NO-CHANGE-FILE#

import Config from "./Config";
import React from "react";
import { useNavigate, useLocation, useParams } from "react-router";
import { BaseList } from "../../siht/base";
import { DataGrid, DataGridButtons, DataGridColumn } from "../../siht/components";
import { Button, Div } from "../../siht/elements";
import { BtnEdit, BtnDelete, BtnNew, BadgeSimNao } from "../../components/Custom";
import { Util } from "../../utils";

class ListFuncionarioSalario extends BaseList {

	constructor(props) {
		super(props);
		this.getItens = this.getItens.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleNew = this.handleNew.bind(this);
		this.handleEdit = this.handleEdit.bind(this);
		this.handleCopy = this.handleCopy.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.handleMore = this.handleMore.bind(this);
		this.onSubmitFilter = this.onSubmitFilter.bind(this);
		this.handleClear = this.handleClear.bind(this);
		this.handleFormSuccess = this.handleFormSuccess.bind(this);
		this.handleFormClose = this.handleFormClose.bind(this);
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init() {
		super.init(Config);
	}

	getItens(more) {
		if (this.props?.parent) {
			super.getItens(Config.getService().getAllByFuncionario, this.props.parent, more);
		}
	}

	handleNew(e) {
		super.handleNew(e, Config);
	}

	handleEdit(e, item) {
		super.handleEdit(e, Config, item);
	}

	handleDelete(e, item) {
		super.handleDelete(e, Config, item);
	}

	handleCopy(e, item) {
		super.handleCopy(e, Config, item);
	}

	getFormEmbedded() {
		return super.getFormEmbedded(Config);
	}

	render() {
		return super.render(
			<Div className="p-2 mt-2">
				<Div className="d-flex justify-content-end flex-nowrap gap-2">
					<BtnNew onClick={this.handleNew} />
					<BtnDelete disabled={this.state.selecteds.length === 0} sizing={Button.sizing.default} onClick={e => this.handleDelete(e, this.state.selecteds)}> Excluir </BtnDelete>
				</Div>

				<DataGrid onDoubleClickRow={this.handleEdit} responsive={true} data={this.state.data} search={false} isDisabled={i => false} sizing={DataGrid.sizing.sm} select={true} selecteds={this.state.selecteds} onSelect={(selecteds) => { this.setState({ selecteds: selecteds }) }} getItemValue={i => i.idFuncionarioSalario} onMore={this.handleMore}>
					<DataGridColumn col={2} label="Evento" field={i => i.tipoEventoSalario?.nmTipoEventoSalario} className="text-start" />
					<DataGridColumn col={2} label="Pagamento" field={i => i.tipoPagamentoSalario?.nmTipoPagamentoSalario} className="text-start" />
					<DataGridColumn col={2} label="Salário" field={i => Util.floatToReais(i.vlSalario)} className="text-end" />
					<DataGridColumn col={1} label="Reajuste" field={i => Util.floatToReais(i.vlReajuste)} className="text-end" />
					<DataGridColumn col={1} label="Reajuste" field={i => `${Util.floatToValor(i.pcReajuste, 2)} %`} className="text-center" />
					<DataGridColumn col={1} label="Data" field={i => Util.date2Br(i.dtSalario)} className="text-center" />
					<DataGridColumn col={2} label="Usuário" field={i => i.usuario?.nmUsuario} className="text-start" />
					<DataGridColumn col={1} label="Atual" field={i => <BadgeSimNao value={i.flAtual} />} className="text-center" />
					<DataGridButtons>
						<BtnEdit sizing={Button.sizing.default} onClick={this.handleEdit} />
						<BtnDelete sizing={Button.sizing.default} onClick={this.handleDelete} />
					</DataGridButtons>
				</DataGrid>
			</Div>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <ListFuncionarioSalario {...props} navigate={navigate} params={params} location={location} />
}
export default With