import { Component } from "react";

export default class DataGridFooter extends Component {

    render() {
        if(this.props.show !== undefined && !Boolean(this.props.show)){
            return (<></>);
        }
        
        return <>{this.props.children}</>;
    }
}