import Http from "../controllers/Http";

export default class ContaPagarBaixaService {

	static urlBase = "/contaPagarBaixa/";

	static getForCombo(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(ContaPagarBaixaService.urlBase + 'forCombo', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static list(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(ContaPagarBaixaService.urlBase + 'list', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneById(id, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.get(ContaPagarBaixaService.urlBase + id)
			.then(fnSucess)
			.catch(fnError);
	}

	static create(data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(ContaPagarBaixaService.urlBase, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static update(id, data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.put(ContaPagarBaixaService.urlBase + id, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static delete(id, fnSucess = () => { }, fnError = () => { }) {
		if(Number.isInteger(id)){
			Http.getInstanceAppJson()
				.delete(ContaPagarBaixaService.urlBase + id)
				.then(fnSucess)
				.catch(fnError);
		}else{
			Http.getInstanceAppJson()
			.post(ContaPagarBaixaService.urlBase + 'delete', id)
			.then(fnSucess)
			.catch(fnError);
		}
	}

	static save(data, fnSucess = () => { }, fnError = () => { }) {
		if (data.idContaPagarBaixa) {
			this.update(data.idContaPagarBaixa, data, fnSucess, fnError);
		} else {
			this.create(data, fnSucess, fnError);
		}
	}

	static getAllByContaPagarParcela(contaPagarParcela, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(ContaPagarBaixaService.urlBase + 'contaPagarParcela', contaPagarParcela)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByContaPagarParcela(contaPagarParcela, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(ContaPagarBaixaService.urlBase + 'contaPagarParcela/' + contaPagarParcela.idContaPagarParcela)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByUsuario(usuario, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(ContaPagarBaixaService.urlBase + 'usuario', usuario)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByUsuario(usuario, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(ContaPagarBaixaService.urlBase + 'usuario/' + usuario.idUsuario)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByTipoBaixa(tipoBaixa, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(ContaPagarBaixaService.urlBase + 'tipoBaixa', tipoBaixa)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByTipoBaixa(tipoBaixa, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(ContaPagarBaixaService.urlBase + 'tipoBaixa/' + tipoBaixa.idTipoBaixa)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByConta(conta, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(ContaPagarBaixaService.urlBase + 'conta', conta)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByConta(conta, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(ContaPagarBaixaService.urlBase + 'conta/' + conta.idConta)
			.then(fnSucess)
			.catch(fnError);
	}
}