import Field from "./Field";
import { InputMask as ReactInputMask, format, unformat } from '@react-input/mask';

export default class InputMask extends Field {

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.handleBlur = this.handleBlur.bind(this);

        this.options = {
            replacement: { '#': /\d/, _: /[a-zA-Z]/, '$': /[a-zA-Z0-9]/, '*': /./ },
        };

        this.withoutMask = true;
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    setOptions() {

    }

    handleChange(e) {
        let value = e?.target?.value;

        if (this.withoutMask) {
            value = unformat(value, this.options);
        }

        super.handleChange(e, value, e?.target?.name);
    }

    handleBlur(e) {
        let value = e?.target?.value;

        if (this.withoutMask) {
            value = unformat(value, this.options);
        }

        super.handleBlur(e, value, e?.target?.name);
    }

    render(pps) {

        var props = Object.assign({}, this.props);
        props = { ...this.options, ...pps, ...props };
        props.ref = this.props.refParam ? this.props.refParam : this.ref;
        props.inputRef = (e) => { this.setState({ error: this.hasError(e) }) };
        props.className = `form-control ${props.className ? props.className : ""} ${props.sizing ? "form-control-" + props.sizing : ""} ${props.readOnly !== undefined && props.readOnly ? "readonly-field" : ""}`;
        props.onChange = this.handleChange;
        props.onBlur = this.handleBlur;

        if (this.options?.mask && props.value !== undefined && props.value !== null) {
            props.value = format(props.value, this.options);
        }

        if (props.withoutMask !== undefined) {
            this.withoutMask = props.withoutMask;
        }

        delete props.maxLength;
        delete props.withoutMask;
        delete props.inputRef;

        return super.render(<ReactInputMask {...props} />);
    }
}