//#NO-CHANGE-FILE#
import React from "react";
import { useNavigate, useLocation, useParams } from "react-router";
import { BtnCopy, BtnDelete, BtnEdit, BtnNew } from "../../components/Custom";
import Config from "./Config";
import { BaseList } from "../../siht/base";
import { Badge, DataGrid, DataGridButtons, DataGridColumn, Tab, TabNavigator } from "../../siht/components";
import { Button, Div } from "../../siht/elements";
import { Modal } from "../../siht/controller";
import FormDadoValor from "./FormDadoValor";
import Util from "../../utils/Util";
import DadoLocalGrupoService from "../../services/DadoLocalGrupoService";
import DadoLocalService from "../../services/DadoLocalService";
import { BadgeSimNao } from "../../components/Custom";

class ListDadoValorByParent extends BaseList {

	constructor(props) {
		super(props);
		this.getItens = this.getItens.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleNew = this.handleNew.bind(this);
		this.handleEdit = this.handleEdit.bind(this);
		this.handleCopy = this.handleCopy.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.handleMore = this.handleMore.bind(this);
		this.onSubmitFilter = this.onSubmitFilter.bind(this);
		this.handleClear = this.handleClear.bind(this);
		this.handleFormSuccess = this.handleFormSuccess.bind(this);
		this.handleFormClose = this.handleFormClose.bind(this);
		this.getGrupos = this.getGrupos.bind(this);

		this.state.editable = this.props.editable !== undefined ? this.props.editable : true;
		this.state.dadoLocalGrupo = [];
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		super.init(Config);
		this.getGrupos();
	}

	initStateFilter(){
        return {
            search: "",
            page : 1,
            pageSize : 100,
        };
    }

	getGrupos(){
		DadoLocalService.getOneByCdDadoLocal(this.props.cdDadoLocal, (responseDadoLocal)=>{
			DadoLocalGrupoService.getAllByDadoLocal(responseDadoLocal, (responseDadoLocalGrupo) => {				
				this.setState({dadoLocalGrupo : responseDadoLocalGrupo});
			});			
		})
		//DadoLocalGrupoService.getAllByDadoLocal(this.props.);
	}

	getItens(more){
		super.getItens(Config.getService().listByParent, {
			parent : this.props.parent !== undefined ? this.props.parent : null,
			cdDadoLocal : this.props.cdDadoLocal
		}, more);
	}

	handleNew(e, dadoLocalGrupo) {
		Modal.openWindow(FormDadoValor, {sizing : Modal.sizing.default}, { cdDadoLocal : this.props.cdDadoLocal, parent : this.props.parent, dadoLocalGrupo :  dadoLocalGrupo}, (e, response) => { 
			this.getItens(false);
		});
	}

	handleEdit(e, item) {
		Modal.openWindow(FormDadoValor, {sizing : Modal.sizing.default}, { cdDadoLocal : this.props.cdDadoLocal, parent : this.props.parent, id : item.dadoValor.idDadoValor }, (e, response) => { 
			this.getItens(false);
		});
	}

	handleDelete(e, item) {
		Modal.confirm("Confirmação de exclusão", "Deseja realmente excluir o item?", "Essa é uma ação irreversível", Modal.style.danger, () =>{
			Config.getService().delete({
				cdDadoLocal : this.props.cdDadoLocal, 
				parent : this.props.parent,
				item : item
			}, () => {
				this.getItens(false);
			});
		});
	}

	handleCopy(e, item) {
		Modal.openWindow(FormDadoValor, {sizing : Modal.sizing.default}, { copy : true, cdDadoLocal : this.props.cdDadoLocal, parent : this.props.parent, id : item.dadoValor.idDadoValor }, (e, response) => { 
			this.getItens(false);
		});
	}

	render() {

		return super.render(
			<TabNavigator onChangeTab={this.handleChangeTab} className="border-light">
				{this.state.dadoLocalGrupo.map((dadoLocalGrupo, index) => (
					<Tab title={dadoLocalGrupo.nmDadoLocalGrupo} className="m-2" key={index}>
						<Div className="p-2 mt-2">
							<Div className="d-flex justify-content-end flex-nowrap gap-2">
								<BtnNew onClick={e => this.handleNew(e, dadoLocalGrupo)} disabled={!this.state.editable}/>
								<BtnDelete disabled={this.state.selecteds.length === 0 || !this.state.editable} sizing={Button.sizing.default} onClick={e => this.handleDelete(e, this.state.selecteds)}> Excluir </BtnDelete>
							</Div>

							<DataGrid onDoubleClickRow={this.handleEdit} responsive={true} data={this.state.data.filter(item => parseInt(item.dadoValor.dado.dadoLocalGrupo.idDadoLocalGrupo) === parseInt(dadoLocalGrupo.idDadoLocalGrupo))} search={true} isDisabled={i => false} sizing={DataGrid.sizing.sm} select={true} selecteds={this.state.selecteds} onSelect={(selecteds)=>{this.setState({selecteds : selecteds})}} onMore={this.handleMore}>
								<DataGridColumn col={6} label="Dado" field={i => <>
										<Div>{i.dadoValor?.dado.nmDado}</Div>
										<Div className="fw-bold fst-italic fs-7">{i.dadoValor?.dado.tipoDado.nmTipoDado}</Div>
										<Div className="" style={{ fontFamily: "Courier New" }}>{i.dadoValor?.dado.cdDado}</Div>
										<Div show={!i.dadoValor?.dado.flAtivo}><Badge color={Badge.color.danger}> INATIVO </Badge></Div>
								</>} className="text-start"/>
								<DataGridColumn col={5} label="Valor" field={i => {

										if(i.dadoValor.dado.tipoDado.cdTipoDado === "DROPDOWN_LIST"){
											let dropDownItens = JSON.parse(i.dadoValor.dado?.txOpcao);
											let item = dropDownItens.find(x => x?.value?.toString() === i.dadoValor?.txValor?.toString());

											return (<>{item?.value} - {item?.label}</>);
										}else if(i.dadoValor.dado.tipoDado.cdTipoDado === "DROPDOWN_MULTIPLE"){
											let dropDownItens = JSON.parse(i.dadoValor.dado?.txOpcao);
											let dropDownValues = i.dadoValor?.txValor ? JSON.parse(i.dadoValor?.txValor) : [];								
											let itens = dropDownItens.filter(i =>  Array.isArray(dropDownValues) && dropDownValues?.find(v => i?.value?.toString() === v?.toString()));
											return itens.map((item, index) =>
												<React.Fragment key={index}>{item?.value} - {item?.label}<br/></React.Fragment>
											);
										}else if(i.dadoValor.dado.tipoDado.cdTipoDado === "COLOR"){
											return (<Div style={{backgroundColor : i.dadoValor?.txValor}}> &nbsp; </Div>);
										}else if(i.dadoValor.dado.tipoDado.cdTipoDado === "DECIMAL"){
											return (
												(i.dadoValor?.dado?.dsPrefixo ? i.dadoValor?.dado?.dsPrefixo + " " : "") + 
												Util.floatToValor(i.dadoValor?.txValor, i.dadoValor?.dado.nrDecimalEscala ? parseInt(i.dadoValor?.dado.nrDecimalEscala) : 2) +
												(i.dadoValor?.dado?.dsSufixo ? " " + i.dadoValor?.dado?.dsSufixo  : "")
											);
										}else if(i.dadoValor?.arquivo){
											return (<> {i.dadoValor?.arquivo.nmArquivo}</>);
										}else if(i.dadoValor.dado.tipoDado.cdTipoDado === "PASSWORD"){
											return (<>********************</>);
										}else{
											return (<>{i.dadoValor?.txValor}</>);
										}

								}} className="text-start"/>
								<DataGridColumn col={1} label="Ativo?" field={i => <BadgeSimNao value={i.dadoValor?.flAtivo}/>} className="text-center"/>
								<DataGridButtons>
									<BtnEdit sizing={Button.sizing.sm} onClick={this.handleEdit} disabled={i => !i.dadoValor.dado.flAtivo || !this.state.editable}/>
									<BtnDelete sizing={Button.sizing.sm} onClick={this.handleDelete} disabled={i => !i.dadoValor.dado.flAtivo || !this.state.editable}/>
									<BtnCopy sizing={Button.sizing.sm} onClick={this.handleCopy} disabled={i => !i.dadoValor.dado.flAtivo || !this.state.editable}/>
								</DataGridButtons>
							</DataGrid>
						</Div>
					</Tab>
				))}
				
			</TabNavigator>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <ListDadoValorByParent {...props} navigate={navigate} params={params} location={location}/>
}
export default With