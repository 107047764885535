
//#NO-CHANGE-FILE#

import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { BaseCrud } from "../../siht/base";
import { FormButtons, FormItem, Row, Panel, PanelBody, PanelFooter, Container, ModalDialog, BtnRadioGroup } from "../../siht/components";
import { InputText, Form, BtnRadio } from "../../siht/elements";
import Config from "./Config";

class FormAnsPerfilPlanta extends BaseCrud {

	constructor(props) {
		super(props);
		this.init = this.init.bind(this);
		this.handleSuccess = this.handleSuccess.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleChangeModel = this.handleChangeModel.bind(this);
		this.handleSubmitForm = this.handleSubmitForm.bind(this);

		this.state.model = {
			idAnsPerfilPlanta : null,
			ansPerfil : this.props.parent !== undefined ? this.props.parent : {},
			arquivo : null,
			nmAnsPerfilPlanta : "",
			cdOrientacao : ""
		};
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		super.init(Config);
	}

	handleSuccess(e) {
		super.handleSuccess(e, Config);
	}

	handleClose(e) {
		super.handleClose(e, Config);
	}

	render() {
		return super.render(

					<Form onSubmit={this.handleSuccess} refParam={f => (this.form = f)} key={this.state.key}>
						<Panel key={this.state.key} title="Cadastro de Fotos" onClose={this.handleClose} isModal={this.isModal()} container={{sizing: Container.sizing.xxl, className : ""}} modal={{sizing: this.props.sizing ? this.props.sizing : ModalDialog.sizing.xl}}>
							<PanelBody>
								<Row className="g-3 pb-2 px-2">									
									<FormItem md={12} label="Nome da Fotos">
										<InputText name="nmAnsPerfilPlanta" value={this.state.model.nmAnsPerfilPlanta} autoFocus={true} maxLength={100} required={true} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={4} label="Orientação">
										<BtnRadioGroup name="cdOrientacao" color={BtnRadioGroup.color.primary} opcional={true} value={this.state.model.cdOrientacao} onClick={this.handleChangeModel}>
											<BtnRadio value="L" label="Paisagem" />
											<BtnRadio value="P" label="Retrato" />
										</BtnRadioGroup>
									</FormItem>
								</Row>
							</PanelBody>
							<PanelFooter>
								<FormButtons onClose={this.handleClose} />
							</PanelFooter>
						</Panel>
					</Form>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <FormAnsPerfilPlanta {...props} navigate={navigate} params={params} location={location}/>
}
export default With