import Http from "../controllers/Http";

export default class FaturaEnderecoService {

	static urlBase = "/faturaEndereco/";

	static getForCombo(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(FaturaEnderecoService.urlBase + 'forCombo', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static list(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(FaturaEnderecoService.urlBase + 'list', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneById(id, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.get(FaturaEnderecoService.urlBase + id)
			.then(fnSucess)
			.catch(fnError);
	}

	static create(data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(FaturaEnderecoService.urlBase, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static update(id, data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.put(FaturaEnderecoService.urlBase + id, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static delete(id, fnSucess = () => { }, fnError = () => { }) {
		if(Number.isInteger(id)){
			Http.getInstanceAppJson()
				.delete(FaturaEnderecoService.urlBase + id)
				.then(fnSucess)
				.catch(fnError);
		}else{
			Http.getInstanceAppJson()
			.post(FaturaEnderecoService.urlBase + 'delete', id)
			.then(fnSucess)
			.catch(fnError);
		}
	}

	static save(data, fnSucess = () => { }, fnError = () => { }) {
		if (data.idFaturaEndereco) {
			this.update(data.idFaturaEndereco, data, fnSucess, fnError);
		} else {
			this.create(data, fnSucess, fnError);
		}
	}

	static getAllByFatura(fatura, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(FaturaEnderecoService.urlBase + 'fatura', fatura)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByFatura(fatura, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(FaturaEnderecoService.urlBase + 'fatura/' + fatura.idFatura)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByEndereco(endereco, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(FaturaEnderecoService.urlBase + 'endereco', endereco)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByEndereco(endereco, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(FaturaEnderecoService.urlBase + 'endereco/' + endereco.idEndereco)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByTipoEndereco(tipoEndereco, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(FaturaEnderecoService.urlBase + 'tipoEndereco', tipoEndereco)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByTipoEndereco(tipoEndereco, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(FaturaEnderecoService.urlBase + 'tipoEndereco/' + tipoEndereco.idTipoEndereco)
			.then(fnSucess)
			.catch(fnError);
	}
}