
import FormTipoComissaoData from "./FormTipoComissaoData.js";
import ListTipoComissaoData from "./ListTipoComissaoData.js";
import { Permissao } from "../../controllers";
import { Util } from "../../utils";
import { DadoValorService, TipoComissaoDataService } from "../../services";

export default class Config {

	static getUrlForm() {
		return "/CadTipoComissaoData";
	}

	static getUrlList() {
		return "/LstTipoComissaoData";
	}

	static getComponentForm() {
		return FormTipoComissaoData;
	}

	static getPermissionForm() {
		return Permissao.getInstance().getPermissoes([220]);
	}

	static getPermissionList() {
		return Permissao.getInstance().getPermissoes([220]);
	}

	static getComponentList() {
		return ListTipoComissaoData;
	}

	static getService() {
		return TipoComissaoDataService;
	}

	static getKeyId() {
		return "idTipoComissaoData";
	}

	static getKeyDescription() {
		return "nmTipoComissaoData";
	}

	static setDefaultValues(state , fnCallBack = () => {}, className = undefined) {
		className = className === undefined ? "FormTipoComissaoData" : className;
		let keyNameScreen = Util.toToUpperTextCamelCase(className, "_") + ".";
		var prefix = "CONFIGURACAO.";
		DadoValorService.getValues(`${prefix}${keyNameScreen}`, (response)=> {
			Util.setValueObjectCamelCase(state.model, response, keyNameScreen, prefix);
			fnCallBack();
		}, fnCallBack);
	}
}