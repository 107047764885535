import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BaseComponent } from "../../siht/base";
import { FormItem, InputMapOneMarker } from "../../siht/components";
import LocalizacaoService from "../../services/LocalizacaoService";
import ConfigApp from "../../ConfigApp";

class FormItemLocalizacao extends BaseComponent {

	constructor(props) {
		super(props);
		this.init = this.init.bind(this);        
        this.handleChangeLocalizacao = this.handleChangeLocalizacao.bind(this);
        
	}

	componentDidMount() {
		super.componentDidMount();
        this.init();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		
	}

    handleChangeLocalizacao(e, v){
		if(v?.lng && v?.lat){
			LocalizacaoService.create({vlLatitude : v.lat, vlLongitude : v.lng} , (response) => {
		        this.props.onChange(e, response.data, this.props.name);
			});
		}else{
			this.props.onChange(e, null, this.props.name);
		}
	}

	render() {

		var propsFormItem = ["col","xs","sm","md","lg","xl","xxl"];
		var propsItem = ["required" , "buttons" , "label", "placeholder", "disabled","slng", "slat","zoom","readOnly"];

		return super.render(
            <React.Fragment>
                <FormItem label="Localização" {...(this.getProps(propsFormItem,0))}>
                    <InputMapOneMarker apiKey={ConfigApp.getInstance().clientIdGoogleMaps}
                        lng={this.props.value?.vlLongitude}
                        lat={this.props.value?.vlLatitude}
                        {...(this.getProps(propsItem,0))}
                        name="localizacao"
                        placeholder={this.props.placeholder !== undefined ? this.props.placeholder : "Selecione a Localização"}
                        onChange={this.handleChangeLocalizacao}
                    />
                </FormItem>
            </React.Fragment>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	return <FormItemLocalizacao {...props} navigate={navigate} params={params} />
}
export default With