import { Component } from "react";

export default class TabFooter extends Component {

    render() {
        if(this.props.show !== undefined && !Boolean(this.props.show)){
            return (<></>);
        }
        
        return <div className="card-footer text-muted bg-white">{this.props.children}</div>;
    }
}