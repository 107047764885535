//#NO-CHANGE-FILE#

import Http from "../controllers/Http";

export default class BlocoService {

	static urlBase = "/bloco/";

	static getDados(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(BlocoService.urlBase + 'dados', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static promover(data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(BlocoService.urlBase + 'promover', data)
			.then(fnSucess)
			.catch(fnError);
	}

	static rebaixar(data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(BlocoService.urlBase + 'rebaixar', data)
			.then(fnSucess)
			.catch(fnError);
	}

	static getForCombo(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(BlocoService.urlBase + 'forCombo', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static list(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(BlocoService.urlBase + 'list', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneById(id, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.get(BlocoService.urlBase + id)
			.then(fnSucess)
			.catch(fnError);
	}

	static create(data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(BlocoService.urlBase, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static update(id, data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.put(BlocoService.urlBase + id, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static delete(id, fnSucess = () => { }, fnError = () => { }) {
		if(Number.isInteger(id)){
			Http.getInstanceAppJson()
				.delete(BlocoService.urlBase + id)
				.then(fnSucess)
				.catch(fnError);
		}else{
			Http.getInstanceAppJson()
			.post(BlocoService.urlBase + 'delete', id)
			.then(fnSucess)
			.catch(fnError);
		}
	}

	static save(data, fnSucess = () => { }, fnError = () => { }) {
		if (data.idBloco) {
			this.update(data.idBloco, data, fnSucess, fnError);
		} else {
			this.create(data, fnSucess, fnError);
		}
	}

	static getAllByPainel(painel, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(BlocoService.urlBase + 'painel', painel)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByPainel(painel, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(BlocoService.urlBase + 'painel/' + painel.idPainel)
			.then(fnSucess)
			.catch(fnError);
	}
}