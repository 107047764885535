//#NO-CHANGE-FILE#

import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import SelectTipoEndereco from "../../components/SelectDB/SelectTipoEndereco";
import { BaseCrud } from "../../siht/base";
import { FormButtons, FormItem, Row, Panel, PanelBody, PanelFooter, Container, ModalDialog, InputMapOneMarker } from "../../siht/components";
import { InputText, Form, InputCep } from "../../siht/elements";
import Config from "./Config";
import { FormItemPaisEstadoCidade } from "../../components/FormItem";
import ConfigApp from "../../ConfigApp";
import Util from "../../utils/Util";
import EnderecoService from "../../services/EnderecoService";
import SelectTipoLogradouro from "../../components/SelectDB/SelectTipoLogradouro";
import SelectTipoBairro from "../../components/SelectDB/SelectTipoBairro";

class FormPessoaEndereco extends BaseCrud {

	constructor(props) {
		super(props);
		this.init = this.init.bind(this);
		this.handleSuccess = this.handleSuccess.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleChangeModel = this.handleChangeModel.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmitForm = this.handleSubmitForm.bind(this);
		this.handleBuscarCep = this.handleBuscarCep.bind(this);

		this.state.model = {
			idPessoaEndereco : null,
			pessoa : this.props.parent !== undefined ? this.props.parent : {},
			tipoEndereco : null,
			endereco : {
				nrCep : "",
				tipoLogradouro : null,
				tipoBairro : null,
			}
		};
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		super.init(Config);
	}

	handleSuccess(e) {
		super.handleSuccess(e, Config);
	}

	handleClose(e) {
		super.handleClose(e, Config);
	}

	handleBuscarCep(){

		if(!this.state.model?.endereco?.nrCep){
			return;
		}

		if(Util.stringToNumber(this.state.model?.endereco?.nrCep).length !== 8){
			return;
		}

		EnderecoService.getOneByCep(this.state.model.endereco?.nrCep, (response) => {
			this.setState(state =>{
				state.model.endereco.cidade = response.cidade;
				state.model.endereco.dsLogradouro = response.logradouro;
				state.model.endereco.dsBairro = response.bairro;

				if(response.tipoLogradouro !== undefined){
					state.model.endereco.tipoLogradouro = response.tipoLogradouro;
				}
				if(response.tipoBairro !== undefined){
					state.model.endereco.tipoBairro = response.tipoBairro;
				}

				return state;
			});
		});
	}

	render() {
		return super.render(
			<Form onSubmit={this.handleSuccess} refParam={f => (this.form = f)} key={this.state.key}>
				<Panel key={this.state.key} title="Cadastro de Pessoa Endereço" onClose={this.handleClose} isModal={this.isModal()} container={{sizing: Container.sizing.xxl, className : ""}} modal={{sizing: this.props.sizing ? this.props.sizing : ModalDialog.sizing.xl}}>
					<PanelBody>
						<Row className="g-3 pb-2 px-2">
							<FormItem md={9} label="Tipo de Endereço">
								<SelectTipoEndereco name="tipoEndereco" value={this.state.model.tipoEndereco} required={true} onChange={this.handleChangeModel} placeholder="Selecione o Tipo de Endereço" buttons={{search : false, new : false, edit: false}}/>
							</FormItem>

							<FormItem md={3} label="CEP">
								<InputCep name="nrCep" autoFocus={true} value={this.state.model.endereco?.nrCep} maxLength={100} required={true} onChange={(e,v,p) => this.handleChange(e, v, p, this.state.model.endereco, this.handleBuscarCep)}/>
							</FormItem>

							<FormItemPaisEstadoCidade  md={[6,6,12]} name="cidade" value={this.state.model.endereco?.cidade} required={true} onChange={(e,v,p) => this.handleChange(e, v, p, this.state.model.endereco)}/>
							
							<FormItem md={5} label="Tipo Logradouro">
								<SelectTipoLogradouro name="tipoLogradouro" value={this.state.model.endereco?.tipoLogradouro} required={true} onChange={(e,v,p) => this.handleChange(e, v, p, this.state.model.endereco)} buttons={{search : false, new : false, edit: false}}/>
							</FormItem>
							
							<FormItem md={7} label={this.state.model.endereco?.tipoLogradouro?.nmTipoLogradouro}>
								<InputText name="dsLogradouro" value={this.state.model.endereco?.dsLogradouro} maxLength={100} required={true} onChange={(e,v,p) => this.handleChange(e, v, p, this.state.model.endereco)}/>
							</FormItem>
							<FormItem md={5} label="Número">
								<InputText name="dsNumero" value={this.state.model.endereco?.dsNumero} maxLength={100} required={true} onChange={(e,v,p) => this.handleChange(e, v, p, this.state.model.endereco)}/>
							</FormItem>	

							<FormItem md={7} label="Complemento">
								<InputText name="dsComplemento" value={this.state.model.endereco?.dsComplemento} maxLength={100} required={false} onChange={(e,v,p) => this.handleChange(e, v, p, this.state.model.endereco)}/>
							</FormItem>

							<FormItem md={6} label="Tipo Bairro">
								<SelectTipoBairro name="tipoBairro" value={this.state.model.endereco?.tipoBairro} required={true} onChange={(e,v,p) => this.handleChange(e, v, p, this.state.model.endereco)}/>
							</FormItem>

							<FormItem md={6} label={this.state.model.endereco?.tipoBairro?.nmTipoBairro}>
								<InputText name="dsBairro" value={this.state.model.endereco?.dsBairro} maxLength={100} required={true} onChange={(e,v,p) => this.handleChange(e, v, p, this.state.model.endereco)}/>
							</FormItem>
							
							<FormItem md={6} label="Localização">
								<InputMapOneMarker apiKey={ConfigApp.getInstance().clientIdGoogleMaps}
									lng={this.state.model.endereco?.vlLongitude}
									lat={this.state.model.endereco?.vlLatitude}
									required={false}
									onChange={(e,v) => this.setState(state => { state.model.endereco.vlLongitude = v.lng; state.model.endereco.vlLatitude = v.lat; return state; })} />
							</FormItem>
						</Row>
					</PanelBody>
					<PanelFooter>
						<FormButtons onClose={this.handleClose} />
					</PanelFooter>
				</Panel>
			</Form>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <FormPessoaEndereco {...props} navigate={navigate} params={params} location={location}/>
}
export default With