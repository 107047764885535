//#NO-CHANGE-FILE#

import Config from "./Config";
import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import SelectTipoComissao from "../../components/SelectDB/SelectTipoComissao";
import ManyToManyCheckComissaoCargoByCargo from "./ManyToManyCheckComissaoCargoByCargo";
import ManyToManyCheckComissaoUnidadeByUnidade from "./ManyToManyCheckComissaoUnidadeByUnidade";
import ListComissaoOrdem from "../ComissaoOrdem/ListComissaoOrdem";
import ListComissaoData from "../ComissaoData/ListComissaoData";
import ListComissaoPessoa from "../ComissaoPessoa/ListComissaoPessoa";
import ListComissaoPessoaOrdem from "../ComissaoPessoaOrdem/ListComissaoPessoaOrdem";
import ListComissaoHistorico from "../ComissaoHistorico/ListComissaoHistorico";
import Permissao from "../../controllers/Permissao";
import { BaseList, BaseCrud } from "../../siht/base";
import { FormButtons, FormItem, Row, TabNavigator, Tab, Panel, PanelBody, PanelFooter, Container, TabDropdown, DropDownItem, DropDown, ModalDialog } from "../../siht/components";
import { InputText, InputNumberFormat, InputDate, Form, BtnSubmit } from "../../siht/elements";
import BtnRadioGroupTipoValor from "../../components/BtnRadioGroupDB/BtnRadioGroupTipoValor";
import { BtnRadioGroupSimNao, StatusCustom } from "../../components/Custom";
import { Play as IconPlay, ClipboardMinus as IconClipboardMinus, XCircle as IconXCircle } from "react-bootstrap-icons";
import { ComissaoService } from "../../services";
import { FormItemEmpresaUnidade } from "../../components/FormItem";
import SelectTipoContaPagar from "../../components/SelectDB/SelectTipoContaPagar";
import ManyToManyCheckComissaoTipoOrdem from "./ManyToManyCheckComissaoTipoOrdemByTipoOrdem";
import { Modal } from "../../siht/controller";

class FormComissao extends BaseCrud {

	constructor(props) {
		super(props);
		this.init = this.init.bind(this);
		this.initModel = this.initModel.bind(this);
		this.handleSuccess = this.handleSuccess.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleChangeModel = this.handleChangeModel.bind(this);
		this.handleSubmitForm = this.handleSubmitForm.bind(this);

		this.handleProcessar = this.handleProcessar.bind(this);
		this.handleGerarPagamento = this.handleGerarPagamento.bind(this);
		this.handleChangeCallBack = this.handleChangeCallBack.bind(this);
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(flProcessar = false) {
		super.init(Config, () => {
			if (flProcessar && this.state.model.statusComissao?.flPodeProcessar) {
				Modal.confirm("Confirmação", "Deseja realizar o PROCESSAMENTO agora?", null, Modal.style.warning, () => {
					this.handleProcessar(null, this.state.model);
				});
			}

		});
	}

	initModel() {

		var tzoffset = (new Date()).getTimezoneOffset() * 60000;
		var dtPagamento = new Date();
		dtPagamento = new Date(dtPagamento - tzoffset).toISOString().slice(0, 10);

		return {
			idComissao: null,
			tipoValor: null,
			tipoComissao: null,
			unidade: null,
			tipoContaPagar: null,
			statusComissao: null,
			usuario: null,
			vlValor: 0,
			vlComissao: 0,
			dtPagamento: dtPagamento,
			flFixarUnidadePagamento: false,
			flVerificarPagamentoAnterior: true,
			flVerificarOrdem: true,
			comissaoOrdem: this.props?.location?.state?.ordens || [],
			comissaoCargo: [],
			comissaoPessoa: [],
			comissaoHistorico: []
		}
	}

	handleSuccess(e) {
		super.handleSuccess(e, Config);
	}

	handleClose(e) {
		super.handleClose(e, Config);
	}

	handleProcessar(e, comissao) {
		ComissaoService.processar(comissao, (response) => {
			this.init();
		});
	}

	handleGerarPagamento(e, comissao) {
		ComissaoService.gerarPagamento(comissao, (response) => {
			this.init();
		});
	}

	handleEstornar(e, comissao) {
		ComissaoService.estornar(comissao, (response) => {
			this.init();
		});
	}

	handleChangeCallBack() {
		this.init(true);
	}

	render() {

		//let flEdicao = this.state.model?.idComissao ? true : false;
		let flPodeEditar = !this.state.model?.idComissao || this.state.model.statusComissao?.flPodeEditar;

		return super.render(
			<Panel key={this.state.key} title="Cadastro de Comissão" onClose={this.handleClose} isModal={this.isModal()} container={{ sizing: Container.sizing.fluid, className: "" }} modal={{ sizing: this.props.sizing ? this.props.sizing : ModalDialog.sizing.xl }}>
				<PanelBody className="p-0 m-0">
					<TabNavigator key={this.state.key} className="border-light" onChangeTab={this.handleChangeTab} index={this.state.tabIndex}>
						<Tab title="Comissão" className="m-2">
							<Form onSubmit={this.handleSuccess} refParam={f => (this.form = f)}>
								<Row className="g-3 pb-2 px-2">
									<FormItem md={2} label="Código">
										<InputText name="idComissao" value={this.state.model.idComissao} readOnly={true} className="readonly-field text-center" required={false} onChange={this.handleChangeModel} />
									</FormItem>
									<FormItem md={7} label="Status da Comissão">
										<StatusCustom opcional={true} type="InputText" color={this.state.model.statusComissao}>{this.state.model.statusComissao?.nmStatusComissao}</StatusCustom>
									</FormItem>

									<FormItem md={3} label="Total de Comissões" help="Calculado/Recalculado após executar a rotina de processamento!">
										<InputNumberFormat disabled={true} value={this.state.model.vlComissao} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} prefix="R$ " required={true} onChange={this.handleChangeModel} className="text-end fw-bold text-success border border-success" />
									</FormItem>

									<FormItem md={3} label="Pagamento">
										<InputDate disabled={!flPodeEditar} name="dtPagamento" value={this.state.model.dtPagamento} required={true} onChange={this.handleChangeModel} className="text-center" />
									</FormItem>

									<FormItem md={4} label="Tipo de Valor">
										<BtnRadioGroupTipoValor disabled={!flPodeEditar} defaultIndex={1} name="tipoValor" value={this.state.model.tipoValor} required={true} onClick={this.handleChangeModel} placeholder="Selecione o Tipo de Valor" />
									</FormItem>
									<FormItem md={3} label="Valor Individual" show={this.state.model.tipoValor?.cdTipoValor === "VALOR"}>
										<InputNumberFormat disabled={!flPodeEditar} name="vlValor" value={this.state.model.vlValor} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} prefix="R$ " required={true} onChange={this.handleChangeModel} className="text-end" />
									</FormItem>
									<FormItem md={3} label="Valor Individual" show={this.state.model.tipoValor?.cdTipoValor === "PERCENTUAL"}>
										<InputNumberFormat disabled={!flPodeEditar} name="vlValor" value={this.state.model.vlValor} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} suffix=" %" required={true} onChange={this.handleChangeModel} className="text-end" />
									</FormItem>

									<FormItem md={6} label="Tipo de Comissão">
										<SelectTipoComissao disabled={!flPodeEditar} name="tipoComissao" value={this.state.model.tipoComissao} required={true} onChange={this.handleChangeModel} placeholder="Selecione o Tipo de Comissão" buttons={{ search: false, new: false, edit: false }} />
									</FormItem>

									<FormItem md={6} label="Tipo da Conta à Pagar">
										<SelectTipoContaPagar name="tipoContaPagar" value={this.state.model.tipoContaPagar} required={true} onChange={this.handleChangeModel} placeholder="Selecione o Tipo da Conta" disabled={!flPodeEditar} buttons={{ search: false, new: false, edit: false }} />
									</FormItem>

									<FormItem md={4} label="Verificar Pagamento Passado?" help="Quando marcado, não gera duplicada de pagamento para o mesmo tipo de comissão, logo o sistema vai capturar somente as comissões pendente de pagamento!">
										<BtnRadioGroupSimNao disabled={!flPodeEditar} name="flVerificarPagamentoAnterior" value={this.state.model.flVerificarPagamentoAnterior} required={true} onChange={this.handleChangeModel} />
									</FormItem>

									<FormItem md={4} label="Fixar Unidade Pagamento?" help="Se marcado, a Empresa/Unidade selecionada será utilizada para gerar o contas à pagar!">
										<BtnRadioGroupSimNao disabled={!flPodeEditar} name="flFixarUnidadePagamento" value={this.state.model.flFixarUnidadePagamento} required={true} onChange={this.handleChangeModel} />
									</FormItem>

									<FormItem md={4} label="Verificar Ordem?" help="Se marcado, somente as ordens marcadas para gerar comissão serão consideradas!" show={this.state.model?.tipoComissao?.cdTipoComissao === "ORDEM"}>
										<BtnRadioGroupSimNao disabled={!flPodeEditar} name="flVerificarOrdem" value={this.state.model.flVerificarOrdem} required={true} onChange={this.handleChangeModel} />
									</FormItem>

									{this.state.model.flFixarUnidadePagamento ?
										<FormItemEmpresaUnidade disabled={!flPodeEditar} md={[6, 6]} name="unidade" value={this.state.model.unidade} required={true} onChange={this.handleChangeModel} buttons={{ search: false, new: false, edit: false }} />
										: <></>}

									<BtnSubmit hidden > Enviar </BtnSubmit>
								</Row>
							</Form>
						</Tab>
						<Tab title="Configurações" opened={false} className="m-0 p-0" disabled={!this.state.model.idComissao}>
							<TabNavigator className="border-light" id="tabNavConfiguracoes" onChangeTab={index => this.handleChangeTab(index, "tabIndexConfiguracoes")} index={this.state.tabIndexConfiguracoes}>
								<Tab show={Permissao.getInstance().getPermissoes([207])} title="Cargos" disabled={!this.state.model.idComissao} opened={false} className="m-2">
									<ManyToManyCheckComissaoCargoByCargo parent={this.state.model} localOpen={BaseList.localOpen.modal} disabled={!flPodeEditar} onChange={this.handleChangeCallBack} />
								</Tab>
								<Tab show={Permissao.getInstance().getPermissoes([207])} title="Unidades" disabled={!this.state.model.idComissao} opened={false} className="m-2">
									<ManyToManyCheckComissaoUnidadeByUnidade parent={this.state.model} localOpen={BaseList.localOpen.modal} disabled={!flPodeEditar} onChange={this.handleChangeCallBack} />
								</Tab>
								<Tab show={Permissao.getInstance().getPermissoes([207])} title="Tipo de Ordem" disabled={!this.state.model.idComissao} opened={false} className="m-2">
									<ManyToManyCheckComissaoTipoOrdem parent={this.state.model} localOpen={BaseList.localOpen.modal} disabled={!flPodeEditar} onChange={this.handleChangeCallBack} />
								</Tab>
								<Tab show={Permissao.getInstance().getPermissoes([207])} title="Ordens" disabled={!this.state.model.idComissao} opened={false} className="m-2">
									<ListComissaoOrdem parent={this.state.model} localOpen={BaseList.localOpen.modal} editable={flPodeEditar} onChange={this.handleChangeCallBack} />
								</Tab>
								<Tab show={Permissao.getInstance().getPermissoes([207])} title="Datas" disabled={!this.state.model.idComissao} opened={false} className="m-2">
									<ListComissaoData parent={this.state.model} localOpen={BaseList.localOpen.modal} editable={flPodeEditar} onChange={this.handleChangeCallBack} />
								</Tab>
							</TabNavigator>
						</Tab>
						<Tab title="Resultados" opened={false} className="m-0 p-0" disabled={!this.state.model.idComissao}>
							<TabNavigator className="border-light" onChangeTab={index => this.handleChangeTab(index, "tabIndexResultado")} index={this.state.tabIndexResultado}>
								<Tab show={Permissao.getInstance().getPermissoes([207]) && this.state.model?.tipoComissao?.cdTipoComissao === "ORDEM"} title="Pessoa Ordem" disabled={!this.state.model.idComissao} opened={false} className="m-2">
									<ListComissaoPessoaOrdem parent={this.state.model} localOpen={BaseList.localOpen.modal} editable={flPodeEditar} />
								</Tab>
								<Tab show={Permissao.getInstance().getPermissoes([207])} title="Pagamento" disabled={!this.state.model.idComissao} opened={false} className="m-2">
									<ListComissaoPessoa parent={this.state.model} localOpen={BaseList.localOpen.modal} editable={flPodeEditar} />
								</Tab>
							</TabNavigator>
						</Tab>
						<Tab show={Permissao.getInstance().getPermissoes([207])} title="Histórico" disabled={!this.state.model.idComissao} opened={false} className="m-2">
							<ListComissaoHistorico parent={this.state.model} localOpen={BaseList.localOpen.modal} editable={flPodeEditar} />
						</Tab>
						<TabDropdown label="Opções" alignment={DropDown.alignment.end}>
							<DropDownItem title="Processar" type="button" onClick={e => this.handleProcessar(e, this.state.model)} disabled={!this.state.model.statusComissao?.flPodeProcessar}> <IconPlay /> Processar </DropDownItem>
							<DropDownItem title="Gerar Pagamento" type="button" onClick={e => this.handleGerarPagamento(e, this.state.model)} disabled={!this.state.model.statusComissao?.flPodeGerarPagamento}> <IconClipboardMinus /> Gerar Pagamento </DropDownItem>
							<DropDownItem title="Estornar" type="button" onClick={e => this.handleEstornar(e, this.state.model)} disabled={!this.state.model.statusComissao?.flPodeEstornar}> <IconXCircle /> Estornar </DropDownItem>
						</TabDropdown>
					</TabNavigator>
				</PanelBody>
				<PanelFooter>
					<FormButtons show={this.state.tabIndex === 0} onClose={this.handleClose} onSubmit={this.handleSubmitForm} disabled={[!flPodeEditar]} />
				</PanelFooter>
			</Panel>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <FormComissao {...props} navigate={navigate} params={params} location={location} />
}
export default With