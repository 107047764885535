import Config from "./Config";
import React from "react";
import { useNavigate, useLocation, useParams } from "react-router";
import { ThreeDotsVertical as IconThreeDotsVertical } from "react-bootstrap-icons"
import { BaseList } from "../../siht/base";
import { DataGrid, DataGridButtons, DataGridColumn, DropDown, DropDownLabel, DropDownItem } from "../../siht/components";
import { Button, Div } from "../../siht/elements";
import { IconEdit, IconDelete, IconCopy } from "../../icons";
import { BtnEdit, BtnDelete, BtnNew, BtnCopy } from "../../components/Custom";

class ListFaturaNotaFiscalItemObra extends BaseList {

	constructor(props) {
		super(props);
		this.getItens = this.getItens.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleNew = this.handleNew.bind(this);
		this.handleEdit = this.handleEdit.bind(this);
		this.handleCopy = this.handleCopy.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.handleMore = this.handleMore.bind(this);
		this.onSubmitFilter = this.onSubmitFilter.bind(this);
		this.handleClear = this.handleClear.bind(this);
		this.handleFormSuccess = this.handleFormSuccess.bind(this);
		this.handleFormClose = this.handleFormClose.bind(this);
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		super.init(Config);
	}

	getItens(more){
		if(this.props?.parent){
			super.getItens(Config.getService().getAllByFaturaNotaFiscalItem, this.props.parent, more);
		}
	}

	handleNew(e) {
		super.handleNew(e, Config);
	}

	handleEdit(e, item) {
		super.handleEdit(e, Config, item);
	}

	handleDelete(e, item) {
		super.handleDelete(e, Config, item);
	}

	handleCopy(e, item) {
		super.handleCopy(e, Config, item);
	}

	getFormEmbedded(){
		return super.getFormEmbedded(Config);
	}

	render() {
		return super.render(
			<Div className="p-2 mt-2">
				<Div className="d-flex justify-content-end flex-nowrap gap-2">
					<BtnNew onClick={this.handleNew}/>
					<BtnDelete disabled={this.state.selecteds.length === 0} sizing={Button.sizing.default} onClick={e => this.handleDelete(e, this.state.selecteds)}> Excluir </BtnDelete>
				</Div>

				{this.getFormEmbedded()}

				<DataGrid onDoubleClickRow={this.handleEdit} responsive={true} data={this.state.data} search={true} isDisabled={i => false} sizing={DataGrid.sizing.sm} select={true} selecteds={this.state.selecteds} onSelect={(selecteds)=>{this.setState({selecteds : selecteds})}} getItemValue={i=>i.idFaturaNotaFiscalItemObra} onMore={this.handleMore}>
						<DataGridColumn label="Código" field={i => i.idFaturaNotaFiscalItemObra} className="text-center"/>
						<DataGridColumn col={3} label="Nome do Endereco" field={i => i.endereco?.nmEndereco} className="text-start"/>
						<DataGridColumn col={3} label="CdArt" field={i => i.cdArt} className="text-center"/>
						<DataGridColumn col={3} label="CdObra" field={i => i.cdObra} className="text-center"/>
						<DataGridColumn col={3} label="CdInss" field={i => i.cdInss} className="text-center"/>
					<DataGridButtons>
						<BtnEdit sizing={Button.sizing.sm} onClick={this.handleEdit}/>
						<BtnDelete sizing={Button.sizing.sm} onClick={this.handleDelete}/>
						<BtnCopy sizing={Button.sizing.sm} onClick={this.handleCopy}/>
						<DropDown alignment={DropDown.alignment.end}>
							<DropDownLabel sizing={Button.sizing.sm} toggle={false}> <IconThreeDotsVertical/> </DropDownLabel>
							<DropDownItem title="Editar Registro" type="button" onClick={this.handleEdit}> <IconEdit/> Editar </DropDownItem>
							<DropDownItem title="Excluir Registro" type="button" onClick={this.handleDelete}> <IconDelete/> Excluir </DropDownItem>
							<DropDownItem title="Copiar Registro" type="button" onClick={this.handleCopy}> <IconCopy/> Copiar </DropDownItem>
						</DropDown>
					</DataGridButtons>
				</DataGrid>
			</Div>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <ListFaturaNotaFiscalItemObra {...props} navigate={navigate} params={params} location={location}/>
}
export default With