//#NO-CHANGE-FILE#

import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import SelectTipoTributacaoServico from "../../components/SelectDB/SelectTipoTributacaoServico";
import SelectTipoExigibilidadeServico from "../../components/SelectDB/SelectTipoExigibilidadeServico";
import BtnRadioGroupSimNao from "../../components/BtnRadioGroupSimNao";
import { BaseCrud } from "../../siht/base";
import { FormButtons, FormItem, Row, Panel, PanelBody, PanelFooter, Container, ModalDialog } from "../../siht/components";
import { InputText, InputNumberFormat, Form } from "../../siht/elements";
import Config from "./Config";

class FormFaturaNotaFiscalItemIss extends BaseCrud {

	constructor(props) {
		super(props);
		this.init = this.init.bind(this);
		this.handleSuccess = this.handleSuccess.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleChangeModel = this.handleChangeModel.bind(this);
		this.handleSubmitForm = this.handleSubmitForm.bind(this);

		this.state.model = {
			idFaturaNotaFiscalItemIss : null,
			faturaNotaFiscalItem : this.props.parent !== undefined ? this.props.parent : {},
			tipoTributacaoServico : null,
			tipoExigibilidadeServico : null,
			flRetido : true,
			vlAliquota : 0,
			vlValor : 0,
			vlRetido : 0,
			vlBaseCalculo : 0,
			dsProcessoSuspensao : ""
		};
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		super.init(Config, () =>{
			Config.getService().getOneByFaturaNotaFiscalItem(this.props.parent, ( response )=>{
				this.setState({model : response});
			})
		});
	}

	handleSuccess(e) {
		super.handleSuccess(e, Config);
	}

	handleClose(e) {
		super.handleClose(e, Config);
	}

	render() {
		let editable = this.props.editable !== undefined ? this.props.editable : true;

		return super.render(
			<Form onSubmit={this.handleSuccess} refParam={f => (this.form = f)} key={this.state.key}>
				<Panel key={this.state.key} title="Cadastro de FaturaNotaFiscalItemIss" localOpen={this.props.localOpen} onClose={this.handleClose} isModal={this.isModal()} container={{sizing: Container.sizing.xxl, className : ""}} modal={{sizing: this.props.sizing ? this.props.sizing : ModalDialog.sizing.xl}}>
					<PanelBody>
						<Row className="g-3 pb-2 px-2">
							<FormItem md={2} label="Código" show={false}>
								<InputText disabled={!editable} name="idFaturaNotaFiscalItemIss" value={this.state.model.idFaturaNotaFiscalItemIss} readOnly={true} className="readonly-field" required={false} onChange={this.handleChangeModel}/>
							</FormItem>
							<FormItem md={2} label="Retido?">
								<BtnRadioGroupSimNao disabled={!editable} name="flRetido" value={this.state.model.flRetido} required={true} onChange={this.handleChangeModel}/>
							</FormItem>

							<FormItem md={2} label="Aliquota" help="Alíquota do ISS do serviço prestado. Você pode encontrar esse dado no portal da prefeitura, ou junto ao contador.">
								<InputNumberFormat disabled={!editable} name="vlAliquota" value={this.state.model.vlAliquota} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} suffix=" %" required={true} onChange={(e,v,p) => this.handleChange(e,v,p, this.state.model)}/>
							</FormItem>
							<FormItem md={2} label="Valor">
								<InputNumberFormat disabled={!editable} name="vlValor" value={this.state.model.vlValor} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} prefix="R$ " required={false} onChange={(e,v,p) => this.handleChange(e,v,p, this.state.model)}/>
							</FormItem>
							<FormItem md={2} label="Retido">
								<InputNumberFormat disabled={!editable} name="vlRetido" value={this.state.model.vlRetido} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} prefix="R$ " required={false} onChange={(e,v,p) => this.handleChange(e,v,p, this.state.model)}/>
							</FormItem>
							<FormItem md={4} label="Processo Suspensão" help="Número do Processo de Suspensão da Exigibilidade">
								<InputText disabled={!editable} name="dsProcessoSuspensao" value={this.state.model.dsProcessoSuspensao} maxLength={100} required={false} onChange={(e,v,p) => this.handleChange(e,v,p, this.state.model)}/>
							</FormItem>

							<FormItem md={6} label="Tipo de Tributação do Serviço">
								<SelectTipoTributacaoServico disabled={!editable} name="tipoTributacaoServico" value={this.state.model.tipoTributacaoServico} required={false} onChange={(e,v,p) => this.handleChange(e,v,p, this.state.model)} placeholder="Selecione Tipo de Tributação do Serviço" buttons={{search : false, new : false, edit: false}}/>
							</FormItem>
							<FormItem md={6} label="Tipo de Exigibilidade do Serviço">
								<SelectTipoExigibilidadeServico disabled={!editable} name="tipoExigibilidadeServico" value={this.state.model.tipoExigibilidadeServico} required={false} onChange={(e,v,p) => this.handleChange(e,v,p, this.state.model)} placeholder="Selecione Tipo de Exigibilidade do Serviço" buttons={{search : false, new : false, edit: false}}/>
							</FormItem>
						</Row>
					</PanelBody>
					<PanelFooter>
						<FormButtons disabled={[!editable]}/>
					</PanelFooter>
				</Panel>
			</Form>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <FormFaturaNotaFiscalItemIss {...props} navigate={navigate} params={params} location={location}/>
}
export default With