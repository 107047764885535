//#NO-CHANGE-FILE#

import Config from "./Config";
import React from "react";
import { useNavigate, useLocation, useParams } from "react-router";
import { BaseList } from "../../siht/base";
import { Card, CardBody, Row, Col } from "../../siht/components";
import { Div } from "../../siht/elements";
import { StatusCustom } from "../../components/Custom";
import { Util } from "../../utils";

class ListComissaoHistorico extends BaseList {

	constructor(props) {
		super(props);
		this.getItens = this.getItens.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleNew = this.handleNew.bind(this);
		this.handleEdit = this.handleEdit.bind(this);
		this.handleCopy = this.handleCopy.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.handleMore = this.handleMore.bind(this);
		this.onSubmitFilter = this.onSubmitFilter.bind(this);
		this.handleClear = this.handleClear.bind(this);
		this.handleFormSuccess = this.handleFormSuccess.bind(this);
		this.handleFormClose = this.handleFormClose.bind(this);
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		super.init(Config);
	}

	getItens(more){
		if(this.props?.parent){
			super.getItens(Config.getService().getAllByComissao, this.props.parent, more);
		}
	}

	handleNew(e) {
		super.handleNew(e, Config);
	}

	handleEdit(e, item) {
		super.handleEdit(e, Config, item);
	}

	handleDelete(e, item) {
		super.handleDelete(e, Config, item);
	}

	handleCopy(e, item) {
		super.handleCopy(e, Config, item);
	}

	getFormEmbedded(){
		return super.getFormEmbedded(Config);
	}

	render() {

		var length = this.state.data.length;
		
		return super.render(
			<Div className="p-2 mt-2">
				<Div className="d-flex h-100 justify-content-center">

					<Card className="container p-3">
						<CardBody className="h-100">
							{this.state.data.reverse().map((historico, index) =>(
								<Row key={index}>
									<Col className="text-end fs-7 text-secondary" sizing={5}>
										{Util.date2Br(historico.dhInsert, false)} <br/>
										{Util.hour2Br(historico.dhInsert)}
									</Col>
									<Col sizing={2} className="text-center">
										<Div className="d-flex justify-content-center">
											<Div className="circleStatus" style={{backgroundColor : historico.statusComissao?.cdCorFundo, color : historico.statusComissao?.cdCorTexto, border : "1px solid " + historico.statusComissao?.cdCorBorda }}>
												&nbsp;
											</Div>
										</Div>

										{length !== (index + 1) ? 
											<Div className="d-flex justify-content-center h-100">
												<Div style={{ borderLeft : "2px dashed silver", height : "100%", minHeight : "80px", width : "2px"}}></Div>
											</Div>
										: <></>}
									</Col>
									<Col className="text-start text-dark" sizing={5}>
										<Div className="fw-bold">											
											Status alterado para &nbsp;<StatusCustom color={historico.statusComissao}> {historico.statusComissao.nmStatusComissao} </StatusCustom>
										</Div>
										<Div className="fs-7 text-secondary">
											{historico.usuario.nmUsuario}
										</Div>
										{historico.txObservacao  ? 
											<Div className="fs-7 py-2">
												<p className="mb-0 lh-sm fs-7" dangerouslySetInnerHTML={{ __html: Util.htmlFormatWhatsapp(historico.txObservacao) }}/>
											</Div>
										: <></>}
										
									</Col>
								</Row>

							))}
						</CardBody>
					</Card>
				</Div>
			</Div>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <ListComissaoHistorico {...props} navigate={navigate} params={params} location={location}/>
}
export default With