
//#NO-CHANGE-FILE#

import Config from "./Config";
import React from "react";
import { useNavigate, useLocation, useParams } from "react-router";
import { BadgeSimNao } from "../../components/Custom";
import { XCircle as IconXCircle } from "react-bootstrap-icons"
import { BaseList } from "../../siht/base";
import { DataGrid, DataGridButtons, DataGridColumn, Panel, Container } from "../../siht/components";
import { Button, BtnButton, Div } from "../../siht/elements";
import Util from "../../utils/Util";
import { Modal } from "../../siht/controller";

class ListUsuarioSessao extends BaseList {

	constructor(props) {
		super(props);
		this.getItens = this.getItens.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleNew = this.handleNew.bind(this);
		this.handleEdit = this.handleEdit.bind(this);
		this.handleCopy = this.handleCopy.bind(this);
		this.handleInativar = this.handleInativar.bind(this);
		this.handleMore = this.handleMore.bind(this);
		this.onSubmitFilter = this.onSubmitFilter.bind(this);
		this.handleClear = this.handleClear.bind(this);
		this.handleFormSuccess = this.handleFormSuccess.bind(this);
		this.handleFormClose = this.handleFormClose.bind(this);
		this.timeout = null;
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
		clearTimeout(this.timeout);
	}

	init() {
		super.init(Config);
	}

	delTimeOut() {
		if (this.timeout) {
			clearTimeout(this.timeout);
		}
	}

	addTimeOut() {
		this.delTimeOut();
		this.timeout = setTimeout(this.getItens, (1000 * 60));
	}


	initStateFilter() {
		return {
			search: "",
			page: null,
			pageSize: null,
		};
	}

	getItens() {
		Config.getService().getAllSessao(response => {
			this.setState({ data: response });
			this.addTimeOut();
		});
	}

	handleInativar(e, item) {
		let param = Array.isArray(item) ? item : [item];

		Modal.confirm("Confirmação", "Deseja realmente ENCERRAR a(s) SESSÕE(S) selecionadas?", null, Modal.style.danger, () => {
			Config.getService().inativarSessao(param, this.getItens);
		});
	}

	render() {
		return super.render(
			<Panel title="Lista de Sessões(s)" isModal={this.isModal()} container={{ sizing: Container.sizing.xxl, className: "" }}>

				<Div className="d-flex justify-content-end flex-nowrap gap-2">
					<BtnButton color={BtnButton.color.outlineDanger} disabled={this.state.selecteds.length === 0} sizing={Button.sizing.default} onClick={e => this.handleInativar(e, this.state.selecteds)}>
						<IconXCircle /> Encerrar Sessõe(s)
					</BtnButton>
				</Div>

				<DataGrid responsive={true} data={this.state.data} search={false} isDisabled={i => false} sizing={DataGrid.sizing.sm} select={true} selecteds={this.state.selecteds} onSelect={(selecteds) => { this.setState({ selecteds: selecteds }) }} getItemValue={i => i.idUsuarioToken} onMore={this.handleMore}>
					<DataGridColumn col={5} label="Token" field={i => i.cdToken} className="text-start" />
					<DataGridColumn col={2} label="Data" field={i => Util.date2Br(i.dhInsert, true)} className="text-center" />
					<DataGridColumn col={2} label="Validade" field={i => Util.date2Br(i.dhValidade, true)} className="text-center" />
					<DataGridColumn col={2} label="Validado em" field={i => Util.date2Br(i.dhValidacao, true)} className="text-center" />
					<DataGridColumn col={1} label="Ativo?" field={i => <BadgeSimNao value={i.flAtivo} />} className="text-center" />
					<DataGridButtons>
						<BtnButton color={BtnButton.color.outlineDanger} sizing={Button.sizing.default} onClick={this.handleInativar}>
							<IconXCircle />
						</BtnButton>
					</DataGridButtons>
				</DataGrid>
			</Panel>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <ListUsuarioSessao {...props} navigate={navigate} params={params} location={location} />
}
export default With