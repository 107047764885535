//#NO-CHANGE-FILE#

import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { BaseCrud } from "../../siht/base";
import { FormButtons, FormItem, Row, Panel, PanelBody, PanelFooter, Container, ModalDialog } from "../../siht/components";
import { InputText, Form, BtnButton } from "../../siht/elements";
import Config from "./Config";
import OneToOneFormEndereco from "../Endereco/OneToOneFormEndereco";
import { Modal } from "../../siht/controller";
import BtnRadioGroupSimNao from "../../components/BtnRadioGroupSimNao";

class FormFaturaNotaFiscalItemObra extends BaseCrud {

	constructor(props) {
		super(props);
		this.init = this.init.bind(this);
		this.handleSuccess = this.handleSuccess.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.handleChangeModel = this.handleChangeModel.bind(this);
		this.handleSubmitForm = this.handleSubmitForm.bind(this);
		this.initModel = this.initModel.bind(this);
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		super.init(Config, () =>{
			Config.getService().getOneByFaturaNotaFiscalItem(this.props.parent, ( response )=>{
				response.flTemObra = true;
				this.setState({model : response});
			}, ()=>{
				this.setState({model : this.initModel()});
			});
		});
	}

	initModel(){
		return {
			idFaturaNotaFiscalItemObra : null,
			faturaNotaFiscalItem : this.props.parent !== undefined ? this.props.parent : {},
			endereco : null,
			cdArt : "",
			cdObra : "",
			cdInss : "",
			flTemObra : false
		};
	}

	handleSuccess(e) {
		super.handleSuccess(e, Config);
	}

	handleClose(e) {
		super.handleClose(e, Config);
	}

	handleDelete(){
		Modal.confirm("Confirmação", "Deseja realmente remover o vínculo da Obra com a Nota Fiscal?", false, Modal.style.warning, ()=>{
			Config.getService().delete(this.state.model.idFaturaNotaFiscalItemObra, ()=> {
				this.init();
			});
		});
	}

	render() {
		let editable = this.props.editable !== undefined ? this.props.editable : true;

		return super.render(

					<Form onSubmit={this.handleSuccess} refParam={f => (this.form = f)} key={this.state.key}>
						<Panel key={this.state.key} title="Cadastro de FaturaNotaFiscalItemObra" localOpen={this.props.localOpen}  onClose={this.handleClose} isModal={this.isModal()} container={{sizing: Container.sizing.xxl, className : ""}} modal={{sizing: this.props.sizing ? this.props.sizing : ModalDialog.sizing.xl}}>
							<PanelBody>
								<Row className="g-0 py-3 px-3">
									<FormItem md={3} label="Tem Obra Vinculada?">
										<BtnRadioGroupSimNao disabled={!editable} name="flTemObra" value={this.state.model.flTemObra} required={true} onChange={(e,v,p) => this.handleChange(e,v,p, this.state.model)}/>
									</FormItem>
								</Row>
								<Row className="g-3 pb-2 px-2" show={this.state.model.flTemObra}>
									<FormItem md={3} label="Código">
										<InputText disabled={!editable} name="idFaturaNotaFiscalItemObra" value={this.state.model.idFaturaNotaFiscalItemObra} readOnly={true} className="readonly-field" required={false} onChange={this.handleChangeModel}/>
									</FormItem>									
									<FormItem md={3} label="Código da ART" >
										<InputText disabled={!editable} name="cdArt" value={this.state.model.cdArt} maxLength={100} required={false} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={3} label="Código da obra (CO)." >
										<InputText disabled={!editable} name="cdObra" value={this.state.model.cdObra} maxLength={100} required={false} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={3} label="Código do INSS (CEI)." >
										<InputText disabled={!editable} name="cdInss" value={this.state.model.cdInss} maxLength={100} required={false} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={12} label="Nome do Endereco">
										<OneToOneFormEndereco disabled={!editable} name="endereco" value={this.state.model.endereco} required={false} onChange={this.handleChangeModel} placeholder="Informe o Endereço"/>
									</FormItem>
								</Row>
							</PanelBody>
							<PanelFooter>
								<FormButtons disabled={[!editable]}>
									<BtnButton color={BtnButton.color.outlineDanger} disabled={!editable} show={this.state.model.idFaturaNotaFiscalItemObra} onClick={this.handleDelete}> Remover Obra Vinculada </BtnButton>
								</FormButtons>
							</PanelFooter>
						</Panel>
					</Form>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <FormFaturaNotaFiscalItemObra {...props} navigate={navigate} params={params} location={location}/>
}
export default With