
import FormAnsCargo from "./FormAnsCargo.js";
import ListAnsCargo from "./ListAnsCargo.js";
import { Permissao } from "../../controllers";
import { Util } from "../../utils";
import { DadoValorService, AnsCargoService } from "../../services";

export default class Config {

	static getUrlForm() {
		return "/CadAnsCargo";
	}

	static getUrlList() {
		return "/LstAnsCargo";
	}

	static getComponentForm() {
		return FormAnsCargo;
	}

	static getPermissionForm() {
		return Permissao.getInstance().getPermissoes([114]);
	}

	static getPermissionList() {
		return Permissao.getInstance().getPermissoes([114]);
	}

	static getComponentList() {
		return ListAnsCargo;
	}

	static getService() {
		return AnsCargoService;
	}

	static getKeyId() {
		return "idAnsCargo";
	}

	static getKeyDescription() {
		return "nmAnsCargo";
	}

	static setDefaultValues(state , fnCallBack = () => {}, className = undefined) {
		className = className === undefined ? "FormAnsCargo" : className;
		let keyNameScreen = Util.toToUpperTextCamelCase(className, "_") + ".";
		var prefix = "CONFIGURACAO.";
		DadoValorService.getValues(`${prefix}${keyNameScreen}`, (response)=> {
			Util.setValueObjectCamelCase(state.model, response, keyNameScreen, prefix);
			fnCallBack();
		}, fnCallBack);
	}
}