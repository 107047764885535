import Http from "../controllers/Http";

export default class VeiculoArquivoService {

	static urlBase = "/veiculoArquivo/";

	static getForCombo(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(VeiculoArquivoService.urlBase + 'forCombo', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static list(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(VeiculoArquivoService.urlBase + 'list', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneById(id, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.get(VeiculoArquivoService.urlBase + id)
			.then(fnSucess)
			.catch(fnError);
	}

	static create(data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(VeiculoArquivoService.urlBase, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static update(id, data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.put(VeiculoArquivoService.urlBase + id, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static delete(id, fnSucess = () => { }, fnError = () => { }) {
		if(Number.isInteger(id)){
			Http.getInstanceAppJson()
				.delete(VeiculoArquivoService.urlBase + id)
				.then(fnSucess)
				.catch(fnError);
		}else{
			Http.getInstanceAppJson()
			.post(VeiculoArquivoService.urlBase + 'delete', id)
			.then(fnSucess)
			.catch(fnError);
		}
	}

	static save(data, fnSucess = () => { }, fnError = () => { }) {
		if (data.idVeiculoArquivo) {
			this.update(data.idVeiculoArquivo, data, fnSucess, fnError);
		} else {
			this.create(data, fnSucess, fnError);
		}
	}

	static getAllByVeiculo(veiculo, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(VeiculoArquivoService.urlBase + 'veiculo', veiculo)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByVeiculo(veiculo, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(VeiculoArquivoService.urlBase + 'veiculo/' + veiculo.idVeiculo)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByArquivo(arquivo, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(VeiculoArquivoService.urlBase + 'arquivo', arquivo)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByArquivo(arquivo, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(VeiculoArquivoService.urlBase + 'arquivo/' + arquivo.idArquivo)
			.then(fnSucess)
			.catch(fnError);
	}
}