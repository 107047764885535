//#NO-CHANGE-FILE#

import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { BaseCrud } from "../siht/base";
import { FormItem, Row, Panel, PanelBody, PanelFooter, Container, ModalDialog, Col, ProgressBar, DataGrid, DataGridColumn } from "../siht/components";
import {  BtnButton, Button, Form, Span } from "../siht/elements";
import { InputFile } from "../siht/elements";
import ArquivoService from "../services/ArquivoService";
import Util from "../utils/Util";

class FormUpload extends BaseCrud {

	constructor(props) {
		super(props);
		this.init = this.init.bind(this);
		this.handleSuccess = this.handleSuccess.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleChangeModel = this.handleChangeModel.bind(this);
		this.handleSubmitForm = this.handleSubmitForm.bind(this);
		this.handleFileChangeDocumento = this.handleFileChangeDocumento.bind(this);
		this.upload = this.upload.bind(this);
		this.getProgress = this.getProgress.bind(this);

		this.state = {
			upload : [],
			files : [],
			arquivos : [],
			uploading : false,
			multiple : this.props.multiple !== undefined ? this.props.multiple : true,
			accept : this.props.accept !== undefined ? this.props.accept : "*/*"
		};
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		//super.init(Config);
	}

	handleSuccess(e) {
		//super.handleSuccess(e, Config);
	}

	handleClose(e) {
		super.handleClose(e, {});
	}

	upload(next = 0){

		this.setState( state => {
			if(state.upload[next] !== undefined){
				state.upload[next].dsStatus = "ENVIANDO";
			}
			return state;
		}, ()=> {
		
			if(next < this.state.files.length){
				ArquivoService.upload(this.state.files[next], (response) => {
					if (this.props.handleUpload !== undefined) {
						this.props.handleUpload(response.data);
					}

					this.setState( state => {
						state.upload[next].dsStatus = "COMPLETO";
						state.arquivos.push(response.data);
						return state;
					}, ()=>{ this.upload(++next) });
					
				}, () =>{
					this.setState( state => {
						state.upload[next].dsStatus = "ERRO";
						return state;
					}, ()=>{ this.upload(++next) });
				});
			}else{
				setTimeout(()=> {
					this.setState( {uploading : false} , ()=> {

						if(this.state.multiple){
							this.props.handleSuccess(false, this.state.arquivos);
						}else{
							let arquivo = this.state.arquivos.length === 1 ? this.state.arquivos[0] : null;
							this.props.handleSuccess(false, arquivo);
						}
					});
				}, 1000)
				
			}
		});
	}

	handleFileChangeDocumento(event) {
        var files = event.target?.files;
        if (files && files.length > 0) {
			let upload = Array.from( files );
			this.setState({upload : upload, files : [...files], arquivos : [], uploading : true}, this.upload);
        }
    }

	getProgress(){
		var progress = parseInt((this.state.arquivos.length / this.state.files.length) * 100);
		return progress ? progress : 0;
	}

	render() {
		let progress = this.getProgress();

		return super.render(
			
				<Panel key={this.state.key} title="Upload de Arquivo" onClose={this.handleClose} isModal={this.isModal()} container={{sizing: Container.sizing.xxl, className : ""}} modal={{sizing: this.props.sizing ? this.props.sizing : ModalDialog.sizing.xl, scrollable : true}}>
					<PanelBody>
						<Row className="g-3 pb-2 px-2">
							<Form onSubmit={this.handleSuccess} refParam={f => (this.form = f)} key={this.state.key}>
								<FormItem md={12} label={this.state.multiple ? "Selecione os Arquivo(s)" : "Selecione o Arquivo"}>
									<InputFile disabled={this.state.uploading} label={this.state.multiple ? "Selecione os Arquivo(s)" : "Selecione o Arquivo"} required={true} name="arquivo" multiple={this.state.multiple} onChange={(event) => { this.handleFileChangeDocumento(event) }} accept={this.state.accept} />
								</FormItem>
								<Col md={12} className="pt-2">
									<ProgressBar progress={progress} label={<Span className="fw-bold">{progress}%</Span>}/>
								</Col>
							</Form>
						</Row>
						<DataGrid responsive={true} data={this.state.upload} show={this.state.upload.length > 0} search={false} isDisabled={i => false} sizing={DataGrid.sizing.sm} select={false} caption={false}>
							<DataGridColumn col={4} label="Nome" field={i => i.name} className="fs-7 text-start"/>
							<DataGridColumn col={2} label="Tipo" field={i => i.type} className="fs-7 text-center"/>
							<DataGridColumn col={2} label="Tamanho" field={i => Util.formatBytes(i.size)} className="fs-7 text-end"/>
							<DataGridColumn col={3} label="Status" field={i => <>
								{i.dsStatus === "ENVIANDO" ? <ProgressBar progress={100} color={ProgressBar.color.warning} label={<Span className="fw-bold">{i.dsStatus}</Span>} striped={true} animated={true}/> : <></>}
								{i.dsStatus === "COMPLETO" ? <ProgressBar progress={100} color={ProgressBar.color.success} label={<Span className="fw-bold">{i.dsStatus}</Span>}/> : <></>}
								{i.dsStatus === "ERRO" ? <ProgressBar progress={100} color={ProgressBar.color.danger} label={<Span className="fw-bold">{i.dsStatus}</Span>}/> : <></>}
								{i.dsStatus === undefined ? <ProgressBar progress={100} color={ProgressBar.color.secondary} label={<Span className="fw-bold">AGUARDANDO</Span>}/> : <></>}
							</>} className="text-center"/>
						</DataGrid>
					</PanelBody>
					<PanelFooter>
						<BtnButton sizing={Button.sizing.default} color={Button.color.outlineSecondary} onClick={this.handleClose} disabled={this.state.uploading}> Fechar </BtnButton>
					</PanelFooter>
				</Panel>
			
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <FormUpload {...props} navigate={navigate} params={params} location={location}/>
}
export default With