import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import {  BaseCrud } from "../../siht/base";
import { Panel, PanelBody, PanelFooter, Container, ModalDialog, DataGrid, DataGridColumn, Badge } from "../../siht/components";
import { Div, Button, BtnButton, Span } from "../../siht/elements";
import Config from "./Config";
import Util from "../../utils/Util";
import FaturaCobrancaTransacaoService from "../../services/FaturaCobrancaTransacaoService";
import { IconClear } from "../../icons";
import { BadgeSimNao, StatusCustom } from "../../components/Custom";
import { Modal } from "../../siht/controller";
import Permissao from "../../controllers/Permissao";
import { CalendarMinus as IconCalendarMinus} from "react-bootstrap-icons";

class ListFaturaCobrancaTransacao extends BaseCrud {

	constructor(props) {
		super(props);
		this.init = this.init.bind(this);
		this.handleSuccess = this.handleSuccess.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleChangeModel = this.handleChangeModel.bind(this);
		this.handleSubmitForm = this.handleSubmitForm.bind(this);
		this.handleEstornar = this.handleEstornar.bind(this);
		this.handleExpirar = this.handleExpirar.bind(this);

		this.state.model = {
			faturaCobranca : this.props.faturaCobranca,
			faturaCobrancaTransacao : []
		};

		this.state.selecteds = [];

	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		if(this.state.model.faturaCobranca){
			FaturaCobrancaTransacaoService.getAllByFaturaCobranca(this.state.model.faturaCobranca, (response) => {
				this.setModel({faturaCobrancaTransacao : response});
			});
		}
	}

	handleEstornar(e, item) {
		Modal.confirm("Confirmação", "Deseja realmente ESTORNAR a Transação?" , null, Modal.style.danger, () =>{
			FaturaCobrancaTransacaoService.estornar(item, ()=>{
				this.init();
			});
		});
	}

	handleExpirar(e, item) {
		Modal.confirm("Confirmação", "Deseja realmente EXPIRAR a Transação?" , null, Modal.style.danger, () =>{
			FaturaCobrancaTransacaoService.expirar(item, ()=>{
				this.init();
			});
		});
	}

	handleSuccess(e) {
		
	}

	handleClose(e) {
		super.handleClose(e, Config);
	}

	render() {

		return super.render(
				<Panel localOpen={this.props.localOpen} key={this.state.key} title="Histórico de Transações" onClose={this.handleClose} isModal={this.isModal()} container={{sizing: Container.sizing.xxl, className : ""}} modal={{sizing: this.props.sizing ? this.props.sizing : ModalDialog.sizing.xl, scrollable : true}}>
					<PanelBody className="p-2 m-0">
						<DataGrid responsive={true} data={this.state.model.faturaCobrancaTransacao} search={false} isDisabled={i => false} sizing={DataGrid.sizing.sm} select={false} caption={false}>
							<DataGridColumn label="Código" field={i => i.idFaturaCobrancaTransacao} className="text-center"/>
							
							<DataGridColumn col={3} label="Tipo Transacao" field={i =>
								<React.Fragment>										
									<StatusCustom color={i.tipoTransacao}> {i.tipoTransacao?.nmTipoTransacao} </StatusCustom>
									<Div className="fw-bold fs-7"> {i.usuario?.nmUsuario} </Div>
									<Div className="text-secondary fs-7"> {Util.date2Br(i.dhInsert, true)} </Div>
									
								</React.Fragment>} className="text-start"/>

							<DataGridColumn col={3} label="Tipo Pagamento" field={i => <>
									{i.tipoPagamento ? <Div><StatusCustom color={i.tipoPagamento}> {i.tipoPagamento?.nmTipoPagamento} </StatusCustom></Div> : <></>}
									{i.conta?.banco?.idBanco ? <Div className=""> {i.conta?.banco?.nmBanco} / {i.conta?.nmConta} </Div> : <></>}
							</>} className="text-start"/>

							<DataGridColumn col={3} label="Transação" field={i => <>
								<Div className={`fs-6`}> Transação: <Span className={`fw-bold ${i.vlTransacao === 0 ? "text-secondary" : "text-success"}`}>{Util.floatToReais(i.vlTransacao)}</Span>  </Div>
								<Div className={`fs-7`}> Expira em: <Span className={`fw-bold ${i.flExpirada ? "text-danger" : "text-success"}`}>{Util.date2Br(i.dhExpiracao, true)}</Span>  </Div>								
							</>} className="text-end"/>

							<DataGridColumn col={1} label="Efetivada?" field={i => <BadgeSimNao value={i.flEfetivada}/>} className="text-center"/>

							<DataGridColumn col={1} label="Estorno" field={i => <>
								{i.flEstorno ? 
									<Badge color={Badge.color.danger}> ESTONADA </Badge> : 
									<BtnButton show={Permissao.getInstance().getPermissao(93)} className="text-nowrap" color={BtnButton.color.outlineDanger} sizing={Button.sizing.sm} onClick={e => this.handleEstornar(e, i)}> <IconClear/> Estornar </BtnButton>
								}								
							</>} className="text-center"/>

							<DataGridColumn col={1} label="Expiração" field={i => <>
								{i.flExpirada ? 
									<Badge color={Badge.color.danger}> EXPIRADA </Badge> : 
									<BtnButton show={Permissao.getInstance().getPermissao(161)} className="text-nowrap" color={BtnButton.color.outlineDanger} sizing={Button.sizing.sm} onClick={e => this.handleExpirar(e, i)}> <IconCalendarMinus/> Expirar </BtnButton>
								}									
							</>} className="text-center"/>
						</DataGrid>
					</PanelBody>
					<PanelFooter show={this.props.localOpen !== BaseCrud.localOpen.embedded}>
						<BtnButton  onClick={this.handleClose}> Fechar </BtnButton>
					</PanelFooter>
				</Panel>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <ListFaturaCobrancaTransacao {...props} navigate={navigate} params={params} location={location}/>
}
export default With