
import FormFaturaNotaFiscalHistorico from "./FormFaturaNotaFiscalHistorico.js";
import ListFaturaNotaFiscalHistorico from "./ListFaturaNotaFiscalHistorico.js";
import { Permissao } from "../../controllers";
import { Util } from "../../utils";
import { DadoValorService, FaturaNotaFiscalHistoricoService } from "../../services";

export default class Config {

	static getUrlForm() {
		return "/CadFaturaNotaFiscalHistorico";
	}

	static getUrlList() {
		return "/LstFaturaNotaFiscalHistorico";
	}

	static getComponentForm() {
		return FormFaturaNotaFiscalHistorico;
	}

	static getPermissionForm() {
		return Permissao.getInstance().getPermissoes([148]);
	}

	static getPermissionList() {
		return Permissao.getInstance().getPermissoes([148]);
	}

	static getComponentList() {
		return ListFaturaNotaFiscalHistorico;
	}

	static getService() {
		return FaturaNotaFiscalHistoricoService;
	}

	static getKeyId() {
		return "idFaturaNotaFiscalHistorico";
	}

	static getKeyDescription() {
		return "nmFaturaNotaFiscalHistorico";
	}

	static setDefaultValues(state , fnCallBack = () => {}, className = undefined) {
		className = className === undefined ? "FormFaturaNotaFiscalHistorico" : className;
		let keyNameScreen = Util.toToUpperTextCamelCase(className, "_") + ".";
		var prefix = "CONFIGURACAO.";
		DadoValorService.getValues(`${prefix}${keyNameScreen}`, (response)=> {
			Util.setValueObjectCamelCase(state.model, response, keyNameScreen, prefix);
			fnCallBack();
		}, fnCallBack);
	}
}