import Button from "./Button";

export default class BtnButton extends Button {

    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    render() {
        return super.render("button");
    }
}