//#NO-CHANGE-FILE#

import Http from "../controllers/Http";

export default class FaturaCobrancaBoletoService {

	static urlBase = "/faturaCobrancaBoleto/";

	static getOneByCdUid(cdUid, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.get('public' + FaturaCobrancaBoletoService.urlBase + cdUid)
			.then(fnSucess)
			.catch(fnError);
	}

	static getForCombo(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(FaturaCobrancaBoletoService.urlBase + 'forCombo', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static list(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(FaturaCobrancaBoletoService.urlBase + 'list', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneById(id, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.get(FaturaCobrancaBoletoService.urlBase + id)
			.then(fnSucess)
			.catch(fnError);
	}

	static create(data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(FaturaCobrancaBoletoService.urlBase, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static update(id, data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.put(FaturaCobrancaBoletoService.urlBase + id, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static delete(id, fnSucess = () => { }, fnError = () => { }) {
		if(Number.isInteger(id)){
			Http.getInstanceAppJson()
				.delete(FaturaCobrancaBoletoService.urlBase + id)
				.then(fnSucess)
				.catch(fnError);
		}else{
			Http.getInstanceAppJson()
			.post(FaturaCobrancaBoletoService.urlBase + 'delete', id)
			.then(fnSucess)
			.catch(fnError);
		}
	}

	static save(data, fnSucess = () => { }, fnError = () => { }) {
		if (data.idFaturaCobrancaBoleto) {
			this.update(data.idFaturaCobrancaBoleto, data, fnSucess, fnError);
		} else {
			this.create(data, fnSucess, fnError);
		}
	}

	static getAllByFaturaCobrancaTransacao(faturaCobrancaTransacao, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(FaturaCobrancaBoletoService.urlBase + 'faturaCobrancaTransacao', faturaCobrancaTransacao)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByFaturaCobrancaTransacao(faturaCobrancaTransacao, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(FaturaCobrancaBoletoService.urlBase + 'faturaCobrancaTransacao/' + faturaCobrancaTransacao.idFaturaCobrancaTransacao)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByConta(conta, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(FaturaCobrancaBoletoService.urlBase + 'conta', conta)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByConta(conta, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(FaturaCobrancaBoletoService.urlBase + 'conta/' + conta.idConta)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByEndereco(endereco, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(FaturaCobrancaBoletoService.urlBase + 'endereco', endereco)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByEndereco(endereco, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(FaturaCobrancaBoletoService.urlBase + 'endereco/' + endereco.idEndereco)
			.then(fnSucess)
			.catch(fnError);
	}
}