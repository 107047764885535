//#NO-CHANGE-FILE#

import React from "react";
import { useNavigate, useLocation, useParams } from "react-router";
import BtnEdit from "../../components/BtnEdit";
import BtnDelete from "../../components/BtnDelete";
import BtnNew from "../../components/BtnNew";
import Config from "./Config";
import { BaseList } from "../../siht/base";
import { Alert, DataGrid, DataGridButtons, DataGridColumn } from "../../siht/components";
import { Button, Div } from "../../siht/elements";
import FormUpload from "../FormUpload";
import { Modal } from "../../siht/controller";
import ConfigApp from "../../ConfigApp";
import { BtnDownload } from "../../components/Custom";

class ListAnsPerfilPlanta extends BaseList {

	constructor(props) {
		super(props);
		this.getItens = this.getItens.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleNew = this.handleNew.bind(this);
		this.handleEdit = this.handleEdit.bind(this);
		this.handleCopy = this.handleCopy.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.handleMore = this.handleMore.bind(this);
		this.onSubmitFilter = this.onSubmitFilter.bind(this);
		this.handleClear = this.handleClear.bind(this);
		this.handleFormSuccess = this.handleFormSuccess.bind(this);
		this.handleFormClose = this.handleFormClose.bind(this);
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init() {
		super.init(Config);
	}

	getItens(more) {
		if (this.props?.parent) {
			super.getItens(Config.getService().getAllByAnsPerfil, this.props.parent, more);
		}
	}

	handleNew(e) {
		Modal.openWindow(FormUpload, { sizing: Modal.sizing.default }, { multiple: true, accept: "image/*" }, (e, response) => {
			var model = [];
			response.forEach(arquivo => {
				model.push({
					cdOrientacao: arquivo.arquivoBase.nrComprimento > arquivo.arquivoBase.nrAltura ? "L" : "P",
					arquivo: arquivo,
					nmAnsPerfilPlanta: "Descrição da Planta",
					ansPerfil: this.props.parent
				});
			});
			Config.getService().save(model, () => this.getItens(false));
		});
	}

	handleEdit(e, item) {
		super.handleEdit(e, Config, item);
	}

	handleDelete(e, item) {
		super.handleDelete(e, Config, item);
	}

	handleCopy(e, item) {
		super.handleCopy(e, Config, item);
	}

	getFormEmbedded() {
		return super.getFormEmbedded(Config);
	}

	render() {
		return super.render(
			<Div className="p-2 mt-2">
				<Div className="d-flex justify-content-end flex-nowrap gap-2">
					<BtnNew onClick={this.handleNew} />
					<BtnDelete disabled={this.state.selecteds.length === 0} sizing={Button.sizing.default} onClick={e => this.handleDelete(e, this.state.selecteds)}> Excluir </BtnDelete>
				</Div>

				<Alert color={Alert.color.warning} className="py-2">
					<strong>Atenção:</strong> Utilizar preferencialmente o tamanho da(s) imagem(ns) de <strong>1250px x 1350px</strong> para retrato ou <strong>3000px x 1250px</strong> para paisagem!
				</Alert>

				<DataGrid responsive={true} data={this.state.data} search={false} isDisabled={i => false} sizing={DataGrid.sizing.sm} select={true} selecteds={this.state.selecteds} onSelect={(selecteds) => { this.setState({ selecteds: selecteds }) }} getItemValue={i => i.idAnsPerfilPlanta} onMore={this.handleMore}>
					<DataGridColumn label="Código" field={i => i.idAnsPerfilPlanta} className="text-center" />
					<DataGridColumn col={3} label="Descrição" field={i => i.nmAnsPerfilPlanta} className="text-start" />
					<DataGridColumn col={3} label="Nome do Arquivo" field={i => i.arquivo?.nmArquivo} className="text-start" />
					<DataGridColumn col={2} label="Orientação" field={i => {
						if (i.cdOrientacao.toString() === "P") {
							return "Retrato";
						} else if (i.cdOrientacao.toString() === "L") {
							return "Paisagem";
						}
					}} className="text-center" />
					<DataGridColumn col={4} label="Imagem" field={i => <>
						<img style={{ maxHeight: "150px" }} src={ConfigApp.getUrlArquivo() + "public/inline/" + i.arquivo?.cdUid + "/" + i.arquivo?.arquivoBase?.cdHash} className="img-fluid img-thumbnail" alt="..." />
					</>} className="text-start" />
					<DataGridButtons>
						<BtnDownload sizing={Button.sizing.default} />
						<BtnEdit sizing={Button.sizing.default} onClick={this.handleEdit} />
						<BtnDelete sizing={Button.sizing.default} onClick={this.handleDelete} />
					</DataGridButtons>
				</DataGrid>
			</Div>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <ListAnsPerfilPlanta {...props} navigate={navigate} params={params} location={location} />
}
export default With