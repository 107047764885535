//#NO-CHANGE-FILE#
import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import {  BaseCrud } from "../../siht/base";
import { FormItem, Row, Panel, PanelBody, PanelFooter, Container, ModalDialog } from "../../siht/components";
import { InputText, InputNumberFormat, Div,  Button, TextArea } from "../../siht/elements";
import Config from "./Config";
import Util from "../../utils/Util";
import { Modal } from "../../siht/controller";
import FormOrdem from "./FormOrdem"; 
import { BtnView, StatusCustom } from "../../components/Custom";

class FormOrdemView extends BaseCrud {

	constructor(props) {
		super(props);
		this.init = this.init.bind(this);
		this.handleSuccess = this.handleSuccess.bind(this);
		this.handleClose = this.handleClose.bind(this);
		
		this.state.model = {
			idOrdem : null,
			unidade : null,
			pessoa : null,
			statusOrdem : {
				flPodeEditar : true
			},
			tipoOrdem : null,
			usuario : null,
			tipoPreco : null,
			txObservacao : "",
			pcDesconto : "",
			vlDesconto : "",
			flEstorno : true,
			ordemItem : [],
			ordemComentario : [],
			ansPerfil : [],
			cdTipoDesconto : "P",
			cdIdentificadorExterno : "",
			cdPedidoExterno : ""
		};

	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		super.init(Config , () => {
		});
	}

	handleSuccess(e) {
		super.handleSuccess(e, Config);
	}

	handleClose(e) {
		super.handleClose(e, Config);
	}

	getRenderTotais(){

		if(!Config.getPermissaoOrdem(this.state.model).flPodeVerValorOrdem){
			return (<></>);
		}

		return (
			<Row className="g-3 pb-3 px-2 justify-content-end" show={this.state.model.idOrdem}>
				<FormItem md={3} label="Valor Total Itens">
					<InputNumberFormat className="border border-primary text-primary fw-bold" value={this.state.model.vlTotalItem} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} prefix="R$ " required={false} readOnly={true}/>
				</FormItem>
				<FormItem md={3} label="Valor Final Itens">
					<InputNumberFormat className="border border-primary text-primary fw-bold" value={this.state.model.vlFinalItem} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} prefix="R$ " required={false} readOnly={true}/>
				</FormItem>
				<FormItem md={3} label="Valor Final Ordem">
					<InputNumberFormat className="border border-success text-success fw-bold" value={this.state.model.vlFinalOrdem} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} prefix="R$ " required={false} readOnly={true}/>
				</FormItem>
			</Row>
		);
	}

	handleVerOrdem(){
		Modal.openWindow(FormOrdem, {sizing : Modal.sizing.fullscreen}, { id : this.state.model.idOrdem });
	}

	render() {

		return super.render(
				<Div key={this.state.key}>
					<Panel title="Cadastro de Ordem" onClose={this.handleClose} isModal={this.isModal()} localOpen={this.props.localOpen} container={{sizing: Container.sizing.fluid, className : ""}} modal={{sizing: this.props.sizing ? this.props.sizing : ModalDialog.sizing.xl}}>
						<PanelBody className="p-0 m-0">
							<Row className="g-3 pb-3 px-2">
								<FormItem md={2} label="Código">
									<InputText name="idOrdem" value={this.state.model.idOrdem} readOnly={true} required={false} />
								</FormItem>											
								<FormItem md={2} label="Data Ordem">
									<InputText value={Util.date2Br(this.state.model.dhInsert, true)} readOnly={true} required={false}/>
								</FormItem>
								<FormItem md={4} label="Usuário">
									<InputText value={this.state.model.usuario?.nmUsuario} readOnly={true} required={false}/>
								</FormItem>
								<FormItem md={4} label="Status">										
									<StatusCustom opcional={true} type="InputText" color={this.state.model.statusOrdem}>{this.state.model.statusOrdem?.nmStatusOrdem}</StatusCustom>
								</FormItem>
							</Row>
							<Row className="g-3 pb-3 px-2">
								<FormItem md={6} label="Empresa">
									<InputText value={this.state.model.unidade?.empresa?.nmEmpresa} readOnly={true} required={false}/>
								</FormItem>
								<FormItem md={6} label="Unidade">
									<InputText value={this.state.model.unidade?.nmUnidade} readOnly={true} required={false}/>
								</FormItem>

								<FormItem md={4} label="Tipo Cliente">
									<StatusCustom opcional={true} type="InputText" color={this.state.model.pessoa?.tipoPessoa}>{this.state.model.pessoa?.tipoPessoa?.nmTipoPessoa}</StatusCustom>
								</FormItem>
								<FormItem md={8} label="Cliente">
									<InputText value={this.state.model.pessoa?.nmPessoa} readOnly={true} required={false}/>
								</FormItem>

								<FormItem md={6} label="Tipo da Ordem">
									<StatusCustom opcional={true} type="InputText" color={this.state.model.tipoOrdem}>{this.state.model.tipoOrdem?.nmTipoOrdem}</StatusCustom>
								</FormItem>
								<FormItem md={6} label="Tipo Preço">									
									<StatusCustom opcional={true} type="InputText" color={this.state.model.tipoPreco}>{this.state.model.tipoPreco?.nmTipoPreco}</StatusCustom>
								</FormItem>
								<FormItem md={12} label="Observação">
									<TextArea rows={3}  placeholder="Digite a observação da ordem aqui" name="txObservacao" value={this.state.model.txObservacao} required={false} readOnly={true}/>
								</FormItem>
								<FormItem md={3} label="Identificador Externo">
									<InputText name="cdIdentificadorExterno" value={this.state.model.cdIdentificadorExterno} maxLength={100} required={false} readOnly={true} />
								</FormItem>
								<FormItem md={3} label="Pedido Externo">
									<InputText name="cdPedidoExterno" value={this.state.model.cdPedidoExterno} maxLength={100} required={false}  readOnly={true}/>
								</FormItem>
							</Row>
							{this.getRenderTotais()}
								
						</PanelBody>
						<PanelFooter>
							<BtnView sizing={Button.sizing.default} onClick={ () => this.handleVerOrdem()}> Ver Ordem Completa </BtnView>
						</PanelFooter>
					</Panel>
				</Div>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <FormOrdemView {...props} navigate={navigate} params={params} location={location}/>
}
export default With