
import React from "react";
import { BaseComponent } from "../base";
import { Functions } from "../util";
import { InputCheckBox, InputCheckSwitch, InputSearch } from "../elements";

export default class ManyToManyCheck extends BaseComponent {

    static typeCheck = {
        box : "box",
        switch : "switch"
    };

    constructor(props) {
        super(props);

        this.handleChangeAll = this.handleChangeAll.bind(this);
        this.handleChangeAllGroup = this.handleChangeAllGroup.bind(this);

        this.state = {};
        this.state.options = this.props.options !== undefined ? this.props.options : [];
        this.state.selecteds = this.props.selecteds !== undefined ? this.state.selecteds : [];
        this.state.noSelecteds = [];
        this.state.selectedsOriginal = [];
        this.getForComboService = null;
        this.state.busca = "";
    }

    componentDidUpdate(nextProps) {
        if (nextProps.filter !== undefined && this.props.filter !== undefined) {
            if (!this.objEqual(nextProps.filter, this.props.filter)) {
                this.getOptions();
            }
        }
    }

    componentDidMount() {
        super.componentDidMount();
        this.getOptions();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    handleChangeItem(event, item) {        
        if (event.target.checked) {
            this.onChangeAddItem(event, item);
            if (this.props.onChangeAddItem) {
                this.props.onChangeAddItem(event, item);
            }
        } else {
            this.onChangeDelItem(event, item);
            if (this.props.onChangeDelItem) {
                this.props.onChangeDelItem(event, item);
            }            
        }
    }

    handleChangeAll(event) {
        var checked = event.target.checked;
        var itens = [];

        if (checked) {
            this.getItens().forEach(function (item) {
                if (this.state.selecteds && this.state.selecteds.findIndex(i => (this.getOptionValue(i)).toString() === (this.getOptionValue(item)).toString()) === -1) {
                    itens.push(item);
                }
            }, this);

            this.onChangeAddAll(event, itens);
            if (this.props.onChangeAddAll) {
                this.props.onChangeAddAll(event, itens);
            }
        } else {

            this.getItens().forEach(function (item) {
                if (this.state.selecteds && this.state.selecteds.findIndex(i => (this.getOptionValue(i)).toString() === (this.getOptionValue(item)).toString()) !== -1) {
                    itens.push(item);
                }
            }, this);

            this.onChangeDelAll(event, itens);
            if (this.props.onChangeDelAll) {
                this.props.onChangeDelAll(event, itens);
            }
        }
    }

    handleChangeAllGroup(event, itemGroup) {
        var checked = event.target.checked;
        var itens = [];

        if (checked) {    
            this.getItens().filter(i => itemGroup === this.getOptionGroup(i)).forEach(function (item) {
                if (this.state.selecteds && this.state.selecteds.findIndex(i => (this.getOptionValue(i)).toString() === (this.getOptionValue(item)).toString()) === -1) {
                    itens.push(item);
                }
            }, this);

            this.onChangeAddAll(event, itens);
            if (this.props.onChangeAddAll) {
                this.props.onChangeAddAll(event, itens);
            }
        } else {

            this.getItens().filter(i => itemGroup === this.getOptionGroup(i)).forEach(function (item) {
                if (this.state.selecteds && this.state.selecteds.findIndex(i => (this.getOptionValue(i)).toString() === (this.getOptionValue(item)).toString()) !== -1) {
                    itens.push(item);
                }
            }, this);

            this.onChangeDelAll(event, itens);
            if (this.props.onChangeDelAll) {
                this.props.onChangeDelAll(event, itens);
            }
        }
    }

    onChangeAddItem(e, item){
        if(this.props.onChange !== undefined){
            this.props.onChange(e, item);
        }

        if(this.props.onChangeAddItem !== undefined){
            this.props.onChangeAddItem(e, item);
        }
	};

	onChangeDelItem(e, item){
        if(this.props.onChange !== undefined){
            this.props.onChange(e, item);
        }

        if(this.props.onChangeDelItem !== undefined){
            this.props.onChangeDelItem(e, item);
        }
	};

	onChangeAddAll(e, itens){
        if(this.props.onChange !== undefined){
            this.props.onChange(e, itens);
        }

        if(this.props.onChangeAddAll !== undefined){
            this.props.onChangeAddAll(e, itens);
        }
	};

	onChangeDelAll(e, itens){
        if(this.props.onChange !== undefined){
            this.props.onChange(e, itens);
        }

        if(this.props.onChangeDelAll !== undefined){
            this.props.onChangeDelAll(e, itens);
        }
	};

    equalsCheck(a, b) {
        return a.length === b.length && a.every((v, i) => v === b[i]);
    }

    getItens() {
        if (this.state.busca.length > 0 && this.state.options.length > 0) {
            return this.state.options.filter(item =>
                Functions.searchTextInObject(item, Object.keys(this.state.options[0]), this.state.busca)
            );
        }
        return this.state.options;
    }

    getSelectedsFiltered() {        
        return this.getItens().filter(item =>
            this.state.selecteds.findIndex(i => (this.getOptionValue(i)).toString() === (this.getOptionValue(item)).toString()) !== -1
        );
    }

    getOptions(callBack) {
        var filter = this.props.filter !== undefined ? this.props.filter : {};

        if (this.getOptionsWhen(filter) && this.getForComboService !== null) {
            this.getForComboService((filter), (response) => {
                this.setState({ options: response }, callBack);
            });
        }else{
            this.setState({ options: [] }, () => {
                if (callBack) {
                    callBack();
                }
            });
        }
    }

    getOptionValue(item) {
        return item?.value;
    }

    getOptionLabel(item) {
        return item?.label;
    }

    getColumns() {
        return 1;
    }

    getOptionsWhen(item) {
        var filter = this.props.filter !== undefined ? this.props.filter : {};
        return this.props.getOptionsWhen === undefined || this.props.getOptionsWhen(filter);
    }

    render() {

        if(this.props.show !== undefined && !Boolean(this.props.show)){
            return (<></>);
        }

        var search = this.props.search !== undefined && Boolean(this.props.search) ? true : false;
        var typeCheck = this.props.typeCheck !== undefined ? this.props.typeCheck : "Switch";
        var columns = this.props.columns !== undefined ? this.props.columns : this.getColumns();
        var disabled = this.props.disabled !== undefined && Boolean(this.props.disabled) ? true : false;        

        var Component = InputCheckBox;

        if (typeCheck === ManyToManyCheck.typeCheck.box) {
            Component = InputCheckBox;
        }

        if (typeCheck === ManyToManyCheck.typeCheck.switch) {
            Component = InputCheckSwitch;
        }

        var render=<></>;
        var groups = [];
        
        if(this.getOptionGroup !== undefined){
            groups = this.state.options.flatMap(this.getOptionGroup).filter(value => value !== undefined);
            groups = groups.filter((value, index) => groups.indexOf(value) === index);
        }

        if(groups.length > 0){
            render = 
                groups.map((itemGroup, indexGroup) => (
                    <div key={indexGroup} className={`col-md-${parseInt(12 / columns)}`}>
                        <div className="row p-2">
                            <div className="col-md-12 border-bottom p-1 mb-2">
                                <strong> {itemGroup} </strong>
                            </div>
                            <div className="col-md-12">
                                <Component classNameLabel="fw-bold" disabled={disabled} label="Marcar Todos" 
                                    onChange={e => this.handleChangeAllGroup(e, itemGroup)} 
                                    value={this.equalsCheck(this.getSelectedsFiltered().filter(i => itemGroup === this.getOptionGroup(i)), this.getItens().filter(i => itemGroup === this.getOptionGroup(i)))} 
                                    indeterminate={this.getSelectedsFiltered().filter(i => itemGroup === this.getOptionGroup(i)).length > 0 && this.getSelectedsFiltered().filter(i => itemGroup === this.getOptionGroup(i)).length < this.getItens().filter(i => itemGroup === this.getOptionGroup(i)).length}
                                />
                            </div>                                    
                            {this.getItens().filter(i => itemGroup === this.getOptionGroup(i) ).map((item, index) => (
                                <div className="col-md-12" key={index}>
                                    <Component 
                                        disabled={disabled} 
                                        indeterminate={this.state.noSelecteds.findIndex(i => (this.getOptionValue(i)).toString() === (this.getOptionValue(item)).toString()) !== -1}
                                        label={this.getOptionLabel(item)} 
                                        value={this.state.selecteds.findIndex(i => (this.getOptionValue(i)).toString() === (this.getOptionValue(item)).toString()) !== -1} 
                                        onChange={(e) => { this.handleChangeItem(e, item) }} 
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                ));
        }else{
            render =  
                this.getItens().map((item, index) => (
                    <div key={index} className={`col-md-${parseInt(12 / columns)}`}>
                        <Component 
                            disabled={disabled} 
                            indeterminate={this.state.noSelecteds.findIndex(i => (this.getOptionValue(i)).toString() === (this.getOptionValue(item)).toString()) !== -1}
                            label={this.getOptionLabel(item)} 
                            value={this.state.selecteds.findIndex(i => (this.getOptionValue(i)).toString() === (this.getOptionValue(item)).toString()) !== -1} 
                            onChange={(e) => { this.handleChangeItem(e, item) }} 
                        />
                    </div>
                ));
        }

        return (
            <div className="px-2">
                {Boolean(search) ?
                    <div className="pb-2">
                        <InputSearch placeholder="Digite sua busca" autoFocus autoComplete="off" value={this.state.busca} md={12} required={false} onChange={(e, value) => this.handleChange(e, value, 'busca', this.state)} onClear={this.onClear} />
                    </div>
                    : <></>}
                <Component 
                    classNameLabel="fw-bold" 
                    disabled={disabled} 
                    label="Marcar Todos" 
                    onChange={this.handleChangeAll} 
                    value={this.equalsCheck(this.getSelectedsFiltered(), this.getItens())} 
                    indeterminate={this.getSelectedsFiltered().length > 0 && this.getSelectedsFiltered().length < this.getItens().length}
                />
                <div className="row px-0 my-0">
                    {render}
                </div>
            </div>
        );
    }
}