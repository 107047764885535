import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { BaseCrud } from "../../siht/base";
import { FormButtons, Row, Panel, PanelBody, PanelFooter, Container, ModalDialog } from "../../siht/components";
import { Div, Form } from "../../siht/elements";
import Config from "./Config";
import { FormItemTipoPessoaPessoa } from "../../components/FormItem";

class FormAlterarStatus extends BaseCrud {

	constructor(props) {
		super(props);
		this.init = this.init.bind(this);
		this.handleSuccess = this.handleSuccess.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleChangeModel = this.handleChangeModel.bind(this);
		this.handleSubmitForm = this.handleSubmitForm.bind(this);

		this.state.model.ordem = this.props.ordem;
		this.state.model.pessoa = this.getPessoaUnica(this.state.model.ordem);
	}

	getPessoaUnica(ordens){
		if(ordens.length > 0){
			let pessoa = ordens[0].pessoa;
			let qtde = ordens.filter(o => parseInt(o.pessoa.idPessoa) !== parseInt(pessoa.idPessoa)).length;
			if(qtde === 0){
				return pessoa;
			}
		}

		return null;
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		super.init(Config);
	}

	handleSuccess(e) {
        Config.getService().alterarPessoa(this.state.model, response => {			
			this.props.handleSuccess(e, response);
        });
	}

	handleClose(e) {
		super.handleClose(e, Config);
	}

	render() {
		return super.render(
				<Div key={this.state.key}>
					<Form onSubmit={this.handleSuccess} refParam={f => (this.form = f)}>
						<Panel title="Alteração do Status da Ordem" onClose={this.handleClose} isModal={this.isModal()} container={{sizing: Container.sizing.xxl, className : ""}} modal={{sizing: this.props.sizing ? this.props.sizing : ModalDialog.sizing.xl}}>
							<PanelBody>
								<Row className="g-3 pb-2 px-2 align-items-center">
									<FormItemTipoPessoaPessoa type={["BtnRadioGroup"]} label={["Tipo Cliente", "Cliente"]} md={[6,12]} name="pessoa" value={this.state.model.pessoa} required={true} onChange={this.handleChangeModel}/>
								</Row>
							</PanelBody>
							<PanelFooter>
								<FormButtons label={["Alterar Cliente"]} onClose={this.handleClose} />
							</PanelFooter>
						</Panel>
					</Form>
				</Div>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <FormAlterarStatus {...props} navigate={navigate} params={params} location={location}/>
}
export default With