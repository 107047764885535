//#NO-CHANGE-FILE#

import React from "react";
import { useNavigate, useLocation, useParams } from "react-router";
import { BtnCopy, BtnDelete, BtnEdit, BtnNew } from "../../components/Custom";
import Config from "./Config";
import { BadgeSimNao } from "../../components/Custom";
import { BaseList } from "../../siht/base";
import { DataGrid, DataGridButtons, DataGridColumn } from "../../siht/components";
import { Button, Div } from "../../siht/elements";
import BtnUp from "../../components/BtnUp";
import BtnDown from "../../components/BtnDown";

class ListBloco extends BaseList {

	constructor(props) {
		super(props);
		this.getItens = this.getItens.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleNew = this.handleNew.bind(this);
		this.handleEdit = this.handleEdit.bind(this);
		this.handleCopy = this.handleCopy.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.handleMore = this.handleMore.bind(this);
		this.onSubmitFilter = this.onSubmitFilter.bind(this);
		this.handleClear = this.handleClear.bind(this);
		this.handleFormSuccess = this.handleFormSuccess.bind(this);
		this.handleFormClose = this.handleFormClose.bind(this);
		this.handlePromover = this.handlePromover.bind(this);
		this.handleRebaixar = this.handleRebaixar.bind(this);
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		super.init(Config);
	}

	getItens(more){
		if(this.props?.parent){
			super.getItens(Config.getService().getAllByPainel, this.props.parent, more);
		}
	}

	handleNew(e) {
		super.handleNew(e, Config);
	}

	handleEdit(e, item) {
		super.handleEdit(e, Config, item);
	}

	handleDelete(e, item) {
		super.handleDelete(e, Config, item);
	}

	handleCopy(e, item) {
		super.handleCopy(e, Config, item);
	}

	handlePromover(e, item) {
		Config.getService().promover(item, () =>{
			this.getItens();
		});
	}

	handleRebaixar(e, item) {
		Config.getService().rebaixar(item, () =>{
			this.getItens();
		});
	}

	getFormEmbedded(){
		return super.getFormEmbedded(Config);
	}

	render() {
		return super.render(
			<Div className="p-2 mt-2">
				<Div className="d-flex justify-content-end flex-nowrap gap-2">
					<BtnNew onClick={this.handleNew}/>
					<BtnDelete disabled={this.state.selecteds.length === 0} sizing={Button.sizing.default} onClick={e => this.handleDelete(e, this.state.selecteds)}> Excluir </BtnDelete>
				</Div>

				{this.getFormEmbedded()}

				<DataGrid responsive={true} data={this.state.data} search={false} isDisabled={i => false} sizing={DataGrid.sizing.sm} select={true} selecteds={this.state.selecteds} onSelect={(selecteds)=>{this.setState({selecteds : selecteds})}} getItemValue={i=>i.idBloco} onMore={this.handleMore}>
					<DataGridColumn label="Ordem" field={i => i.nrOrdem} className="text-center"/>
					<DataGridColumn label="Código" field={i => i.idBloco} className="text-center"/>
					<DataGridColumn col={3} label="Nome" field={i => i.nmBloco} className="text-start"/>
					<DataGridColumn col={3} label="Tipo " field={i => i.tipoBloco?.nmTipoBloco} className="text-start"/>
					<DataGridColumn col={3} label="Modelo " field={i => i.modeloBloco?.nmModeloBloco} className="text-start"/>						
					<DataGridColumn col={1} label="Atualização" field={i => i.nrTempoAtualizar} className="text-center"/>
					<DataGridColumn col={1} label="Tamanho" field={i => i.nrTamanhoColuna} className="text-center"/>						
					<DataGridColumn col={1} label="Ativo?" field={i => <BadgeSimNao value={i.flAtivo}/>} className="text-center"/>
						
					<DataGridButtons>
						<BtnEdit sizing={Button.sizing.sm} onClick={this.handleEdit}/>
						<BtnDelete sizing={Button.sizing.sm} onClick={this.handleDelete}/>
						<BtnCopy sizing={Button.sizing.sm} onClick={this.handleCopy}/>
						<BtnUp sizing={Button.sizing.sm} onClick={this.handlePromover} disabled={(item, index) => index === 0}/>
						<BtnDown sizing={Button.sizing.sm} onClick={this.handleRebaixar} disabled={(item, index) => index === (this.state.data.length - 1)}/>
					</DataGridButtons>
				</DataGrid>
			</Div>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <ListBloco {...props} navigate={navigate} params={params} location={location}/>
}
export default With