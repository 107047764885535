import Http from "../controllers/Http";

export default class OrdemItemService {

	static urlBase = "/ordemItem/";

	static getForCombo(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(OrdemItemService.urlBase + 'forCombo', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static list(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(OrdemItemService.urlBase + 'list', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneById(id, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.get(OrdemItemService.urlBase + id)
			.then(fnSucess)
			.catch(fnError);
	}

	static create(data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(OrdemItemService.urlBase, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static update(id, data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.put(OrdemItemService.urlBase + id, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static delete(id, fnSucess = () => { }, fnError = () => { }) {
		if(Number.isInteger(id)){
			Http.getInstanceAppJson()
				.delete(OrdemItemService.urlBase + id)
				.then(fnSucess)
				.catch(fnError);
		}else{
			Http.getInstanceAppJson()
			.post(OrdemItemService.urlBase + 'delete', id)
			.then(fnSucess)
			.catch(fnError);
		}
	}

	static save(data, fnSucess = () => { }, fnError = () => { }) {
		if (data.idOrdemItem) {
			this.update(data.idOrdemItem, data, fnSucess, fnError);
		} else {
			this.create(data, fnSucess, fnError);
		}
	}

	static getAllByOrdem(ordem, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(OrdemItemService.urlBase + 'ordem', ordem)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByOrdem(ordem, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(OrdemItemService.urlBase + 'ordem/' + ordem.idOrdem)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByProduto(produto, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(OrdemItemService.urlBase + 'produto', produto)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByProduto(produto, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(OrdemItemService.urlBase + 'produto/' + produto.idProduto)
			.then(fnSucess)
			.catch(fnError);
	}
}