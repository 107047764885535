//#NO-CHANGE-FILE#

import Config from "./Config";
import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import SelectTipoDocumento from "../../components/SelectDB/SelectTipoDocumento";
import { BaseCrud } from "../../siht/base";
import { FormButtons, FormItem, Row, Panel, PanelBody, PanelFooter, Container, ModalDialog } from "../../siht/components";
import { Form } from "../../siht/elements";

class FormPessoaDocumento extends BaseCrud {

	constructor(props) {
		super(props);
		this.init = this.init.bind(this);
		this.initModel = this.initModel.bind(this);
		this.handleSuccess = this.handleSuccess.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleChangeModel = this.handleChangeModel.bind(this);
		this.handleSubmitForm = this.handleSubmitForm.bind(this);
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		super.init(Config);
	}

	initModel(){
		return {
			idPessoaDocumento : null,
			pessoa : this.props.parent !== undefined ? this.props.parent : {},
			arquivo : null,
			tipoDocumento : null
		}
	}

	handleSuccess(e) {
		if(this.state.model.idPessoaDocumento !== null){
			super.handleSuccess(e, Config);
		}else{
			this.props.handleSuccess(e, this.state.model, null);
		}
	}

	handleClose(e) {
		super.handleClose(e, Config);
	}

	render() {
		return super.render(
			<Form onSubmit={this.handleSuccess} refParam={f => (this.form = f)} key={this.state.key}>
				<Panel key={this.state.key} title="Cadastro de PessoaDocumento" localOpen={this.props.localOpen}  onClose={this.handleClose} isModal={this.isModal()} container={{sizing: Container.sizing.xxl, className : ""}} modal={{sizing: this.props.sizing ? this.props.sizing : ModalDialog.sizing.xl}}>
					<PanelBody>
						<Row className="g-3 pb-2 px-2">							
							<FormItem md={12} label="Tipo de Documento">
								<SelectTipoDocumento name="tipoDocumento" value={this.state.model.tipoDocumento} required={true} onChange={this.handleChangeModel} placeholder="Selecione o Tipo de Documento"/>
							</FormItem>
						</Row>
					</PanelBody>
					<PanelFooter>
						<FormButtons onClose={this.handleClose} />
					</PanelFooter>
				</Panel>
			</Form>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <FormPessoaDocumento {...props} navigate={navigate} params={params} location={location}/>
}
export default With