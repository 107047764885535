//#NO-CHANGE-FILE#

import React from "react";
import { useNavigate, useLocation, useParams } from "react-router";
import BtnEdit from "../../components/BtnEdit";
import BtnDelete from "../../components/BtnDelete";
import BtnNew from "../../components/BtnNew";
import Config from "./Config";
import ConfigAnsPerfil from "../AnsPerfil/Config";
import { BaseList } from "../../siht/base";
import { Album } from "../../siht/components";
import { Button, Div } from "../../siht/elements";
import FormUpload from "../FormUpload";
import { Modal } from "../../siht/controller";
import ConfigApp from "../../ConfigApp";
import { toast } from "react-toastify";
import { BtnDownload } from "../../components/Custom";

class ListAnsPerfilFoto extends BaseList {

	constructor(props) {
		super(props);
		this.getItens = this.getItens.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleNew = this.handleNew.bind(this);
		this.handleEdit = this.handleEdit.bind(this);
		this.handleCopy = this.handleCopy.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.handleMore = this.handleMore.bind(this);
		this.onSubmitFilter = this.onSubmitFilter.bind(this);
		this.handleClear = this.handleClear.bind(this);
		this.handleFormSuccess = this.handleFormSuccess.bind(this);
		this.handleFormClose = this.handleFormClose.bind(this);
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init() {
		super.init(Config);
	}

	getItens(more) {
		if (this.props?.parent) {
			super.getItens(Config.getService().getAllByAnsPerfil, this.props.parent, more);
		}
	}

	handleNew(e) {
		Modal.openWindow(FormUpload, { sizing: Modal.sizing.default }, { multiple: true, accept: "image/*" }, (e, response) => {
			var model = [];
			response.forEach(arquivo => {
				model.push({
					nmAnsPerfilFoto: "",
					flNomeAutomatico: true,
					arquivo: arquivo,
					ansPerfil: this.props.parent
				});
			});
			if (model.length > 0) {
				Config.getService().save(model, () => this.getItens(false));
			} else {
				toast.error("Nenhuma foto foi enviada!")
			}
		});
	}

	handleEdit(e, item) {
		super.handleEdit(e, Config, item);
	}

	handleDelete(e, item) {
		super.handleDelete(e, Config, item);
	}

	handleCopy(e, item) {
		super.handleCopy(e, Config, item);
	}

	getFormEmbedded() {
		return super.getFormEmbedded(Config);
	}

	render() {
		return super.render(
			<Div className="px-2 py-3 bg-body-tertiary">
				<Div className="d-flex justify-content-end flex-nowrap gap-2">
					<BtnNew onClick={this.handleNew} disabled={!ConfigAnsPerfil.getPermissaoAnsPerfil(this.props.parent).flPodeEditar}> Adicionar Foto(s) </BtnNew>
					<BtnDelete disabled={this.state.selecteds.length === 0 || !ConfigAnsPerfil.getPermissaoAnsPerfil(this.props.parent).flPodeEditar} sizing={Button.sizing.default} onClick={e => this.handleDelete(e, this.state.selecteds)}> Excluir </BtnDelete>
				</Div>

				<Div className="pt-2">
					<Album data={this.state.data} select={true} selecteds={this.state.selecteds} onSelect={(selecteds) => { this.setState({ selecteds: selecteds }) }} getItemValue={i => i.idAnsPerfilFoto}
						onDoubleClickRow={this.handleEdit}
						image={item => ConfigApp.getUrlArquivo() + "public/inline/" + item.arquivo?.cdUid + "/" + item.arquivo?.arquivoBase?.cdHash}
						title={item => item.nmAnsPerfilFoto}
						description={item => item.arquivo?.nmArquivo}
						buttons={item => [
							<BtnDownload sizing={Button.sizing.sm} arquivo={item.arquivo} />,
							<BtnEdit sizing={Button.sizing.sm} onClick={(e) => this.handleEdit(e, item)} disabled={!ConfigAnsPerfil.getPermissaoAnsPerfil(this.props.parent).flPodeEditar}></BtnEdit>,
							<BtnDelete sizing={Button.sizing.sm} onClick={(e) => this.handleDelete(e, item)} disabled={!ConfigAnsPerfil.getPermissaoAnsPerfil(this.props.parent).flPodeEditar}></BtnDelete>
						]}>
					</Album>
				</Div>
			</Div>
		);

		/*return super.render(
			<Div className="px-2 py-3 bg-body-tertiary">
				<Div className="d-flex justify-content-end flex-nowrap gap-2">
					<BtnNew onClick={this.handleNew} disabled={!ConfigAnsPerfil.getPermissaoAnsPerfil(this.props.parent).flPodeEditar} />
					<BtnDelete disabled={this.state.selecteds.length === 0 || !ConfigAnsPerfil.getPermissaoAnsPerfil(this.props.parent).flPodeEditar} sizing={Button.sizing.default} onClick={e => this.handleDelete(e, this.state.selecteds)}> Excluir </BtnDelete>
				</Div>

				<DataGrid responsive={true} data={this.state.data} search={false} isDisabled={i => false} sizing={DataGrid.sizing.sm} select={ConfigAnsPerfil.getPermissaoAnsPerfil(this.props.parent).flPodeEditar} selecteds={this.state.selecteds} onSelect={(selecteds) => { this.setState({ selecteds: selecteds }) }} getItemValue={i => i.idAnsPerfilFoto} onMore={this.handleMore}>
					<DataGridColumn label="Código" field={i => i.idAnsPerfilFoto} className="text-center" />
					<DataGridColumn col={4} label="Nome" field={i => i.nmAnsPerfilFoto} className="text-start" />
					<DataGridColumn col={1} label="Nome. Aut.?" field={i => <BadgeSimNao value={i.flNomeAutomatico} />} className="text-center" />
					<DataGridColumn col={3} label="Nome do Arquivo" field={i => i.arquivo?.nmArquivo} className="text-start" />
					<DataGridColumn col={4} label="Imagem" field={i => <>
						<img style={{ maxHeight: "150px" }} src={ConfigApp.getUrlArquivo() + "public/inline/" + i.arquivo?.cdUid + "/" + i.arquivo?.arquivoBase?.cdHash} className="img-fluid img-thumbnail" alt="..." />
					</>} className="text-start" />

					<DataGridButtons>
						<BtnDownload sizing={Button.sizing.default} />
						<BtnEdit sizing={Button.sizing.default} onClick={this.handleEdit} disabled={!ConfigAnsPerfil.getPermissaoAnsPerfil(this.props.parent).flPodeEditar} />
						<BtnDelete sizing={Button.sizing.default} onClick={this.handleDelete} disabled={!ConfigAnsPerfil.getPermissaoAnsPerfil(this.props.parent).flPodeEditar} />
					</DataGridButtons>
				</DataGrid>
			</Div>
		);*/
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <ListAnsPerfilFoto {...props} navigate={navigate} params={params} location={location} />
}
export default With