
import FormTipoFatura from "./FormTipoFatura.js";
import ListTipoFatura from "./ListTipoFatura.js";
import { Permissao } from "../../controllers";
import { Util } from "../../utils";
import { DadoValorService, TipoFaturaService } from "../../services";

export default class Config {

	static getUrlForm() {
		return "/CadTipoFatura";
	}

	static getUrlList() {
		return "/LstTipoFatura";
	}

	static getComponentForm() {
		return FormTipoFatura;
	}

	static getPermissionForm() {
		return Permissao.getInstance().getPermissoes([166]);
	}

	static getPermissionList() {
		return Permissao.getInstance().getPermissoes([166]);
	}

	static getComponentList() {
		return ListTipoFatura;
	}

	static getService() {
		return TipoFaturaService;
	}

	static getKeyId() {
		return "idTipoFatura";
	}

	static getKeyDescription() {
		return "nmTipoFatura";
	}

	static setDefaultValues(state , fnCallBack = () => {}, className = undefined) {
		className = className === undefined ? "FormTipoFatura" : className;
		let keyNameScreen = Util.toToUpperTextCamelCase(className, "_") + ".";
		var prefix = "CONFIGURACAO.";
		DadoValorService.getValues(`${prefix}${keyNameScreen}`, (response)=> {
			Util.setValueObjectCamelCase(state.model, response, keyNameScreen, prefix);
			fnCallBack();
		}, fnCallBack);
	}
}