import Http from "../controllers/Http";

export default class FuncionarioService {

	static urlBase = "/funcionario/";

	static getForCombo(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(FuncionarioService.urlBase + 'forCombo', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static list(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(FuncionarioService.urlBase + 'list', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneById(id, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.get(FuncionarioService.urlBase + id)
			.then(fnSucess)
			.catch(fnError);
	}

	static create(data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(FuncionarioService.urlBase, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static update(id, data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.put(FuncionarioService.urlBase + id, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static delete(id, fnSucess = () => { }, fnError = () => { }) {
		if(Number.isInteger(id)){
			Http.getInstanceAppJson()
				.delete(FuncionarioService.urlBase + id)
				.then(fnSucess)
				.catch(fnError);
		}else{
			Http.getInstanceAppJson()
			.post(FuncionarioService.urlBase + 'delete', id)
			.then(fnSucess)
			.catch(fnError);
		}
	}

	static save(data, fnSucess = () => { }, fnError = () => { }) {
		if (data.idFuncionario) {
			this.update(data.idFuncionario, data, fnSucess, fnError);
		} else {
			this.create(data, fnSucess, fnError);
		}
	}

	static getAllByCargo(cargo, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(FuncionarioService.urlBase + 'cargo', cargo)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByCargo(cargo, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(FuncionarioService.urlBase + 'cargo/' + cargo.idCargo)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByPessoa(pessoa, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(FuncionarioService.urlBase + 'pessoa', pessoa)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByPessoa(pessoa, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(FuncionarioService.urlBase + 'pessoa/' + pessoa.idPessoa)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByUnidade(unidade, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(FuncionarioService.urlBase + 'unidade', unidade)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByUnidade(unidade, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(FuncionarioService.urlBase + 'unidade/' + unidade.idUnidade)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByStatusFuncionario(statusFuncionario, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(FuncionarioService.urlBase + 'statusFuncionario', statusFuncionario)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByStatusFuncionario(statusFuncionario, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(FuncionarioService.urlBase + 'statusFuncionario/' + statusFuncionario.idStatusFuncionario)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByEscalaTrabalho(escalaTrabalho, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(FuncionarioService.urlBase + 'escalaTrabalho', escalaTrabalho)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByEscalaTrabalho(escalaTrabalho, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(FuncionarioService.urlBase + 'escalaTrabalho/' + escalaTrabalho.idEscalaTrabalho)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByFuncionarioCargo(funcionarioCargo, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(FuncionarioService.urlBase + 'funcionarioCargo', funcionarioCargo)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByFuncionarioCargo(funcionarioCargo, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(FuncionarioService.urlBase + 'funcionarioCargo/' + funcionarioCargo.idFuncionarioCargo)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByFuncionarioHistorico(funcionarioHistorico, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(FuncionarioService.urlBase + 'funcionarioHistorico', funcionarioHistorico)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByFuncionarioHistorico(funcionarioHistorico, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(FuncionarioService.urlBase + 'funcionarioHistorico/' + funcionarioHistorico.idFuncionarioHistorico)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByFuncionarioDependente(funcionarioDependente, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(FuncionarioService.urlBase + 'funcionarioDependente', funcionarioDependente)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByFuncionarioDependente(funcionarioDependente, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(FuncionarioService.urlBase + 'funcionarioDependente/' + funcionarioDependente.idFuncionarioDependente)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByFuncionarioDocumento(funcionarioDocumento, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(FuncionarioService.urlBase + 'funcionarioDocumento', funcionarioDocumento)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByFuncionarioDocumento(funcionarioDocumento, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(FuncionarioService.urlBase + 'funcionarioDocumento/' + funcionarioDocumento.idFuncionarioDocumento)
			.then(fnSucess)
			.catch(fnError);
	}
}