import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { BaseCrud } from "../../siht/base";
import { FormButtons, FormItem, Row, Panel, PanelBody, PanelFooter, Container, ModalDialog, BtnRadioGroup } from "../../siht/components";
import { Form, BtnRadio } from "../../siht/elements";
import Util from "../../utils/Util";
import ConfigApp from "../../ConfigApp";

class FormRelatorioAns003 extends BaseCrud {

    constructor(props) {
        super(props);

        this.init = this.init.bind(this);
		this.handleSuccess = this.handleSuccess.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleChangeModel = this.handleChangeModel.bind(this);
		this.handleSubmitForm = this.handleSubmitForm.bind(this);

        var tzoffset = (new Date()).getTimezoneOffset() * 60000;
        var dtHoje = new Date();
        dtHoje.setDate(dtHoje.getDate());
        dtHoje = new Date(dtHoje - tzoffset).toISOString().slice(0, 10);

        this.state.model = {
            idOrdens: props.idOrdens,
            dtEmissao : dtHoje,
            orientation: "P",
            destination: "I",            
            flAgrupado: true,
            flMarcaDagua: true,

            flProteger: true,
            dsSenhaUsuario : "",
            dsSenhaProprietario : "",
            cdProtecao : ["print","print-highres"].map(v => {return {value : v}})
        };
    }
   
	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
	}

	handleSuccess(e) {
        let urlRelatorio = "relatorio/ans-004";
        Util.openNewWindow(ConfigApp.getUrlApi() + urlRelatorio, this.state.model);
	}

	handleClose(e) {
		super.handleClose(e, {});
	}

    render() {
        return (
            <Form onSubmit={this.handleSuccess} refParam={f => (this.form = f)} key={this.state.key}>
				<Panel key={this.state.key} title="Impressão do Mapa dos Perfis" onClose={this.handleClose} isModal={this.isModal()} container={{sizing: Container.sizing.xxl, className : ""}} modal={{sizing: this.props.sizing ? this.props.sizing : ModalDialog.sizing.xl}}>
                    <PanelBody>
                        <Row className="row mb-3">
                            <FormItem md={6} label="Destino do Relatório">
                                <BtnRadioGroup name="destination" color={BtnRadioGroup.color.primary} opcional={true} value={this.state.model.destination} onClick={this.handleChangeModel}>
                                    <BtnRadio value="I" label="Tela" />
                                    <BtnRadio value="D" label="Download" />
                                </BtnRadioGroup>
                            </FormItem>
                            <FormItem md={6} label="Disposição da Página">
                                <BtnRadioGroup name="orientation" color={BtnRadioGroup.color.primary} opcional={true} value={this.state.model.orientation} onClick={this.handleChangeModel} disabled={true}>
                                    <BtnRadio value="P" label="Vertical" />
                                    <BtnRadio value="L" label="Horizontal" />
                                </BtnRadioGroup>
                            </FormItem>
                        </Row>
                    </PanelBody>
                    <PanelFooter>
                        <FormButtons onClose={this.handleClose} label={['Imprimir','Fechar']}/>
                    </PanelFooter>
                </Panel>
            </Form>
        );
    }
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <FormRelatorioAns003 {...props} navigate={navigate} params={params} location={location}/>
}
export default With