import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BaseComponent } from "../../siht/base";
import SelectEmpresa from "../../components/SelectDB/SelectEmpresa";
import SelectUnidade from "../../components/SelectDB/SelectUnidade";
import { FormItem } from "../../siht/components";

class FormItemEmpresaUnidade extends BaseComponent {

	constructor(props) {
		super(props);
		this.init = this.init.bind(this);        
        this.handleChangeEmpresa = this.handleChangeEmpresa.bind(this);
        this.handleChangeUnidade = this.handleChangeUnidade.bind(this);
	}

	componentDidMount() {
		super.componentDidMount();
        this.init();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		
	}

	handleChangeEmpresa(e, v, p){
        var value =  { empresa : v ? v : {} };
        this.props.onChange(e, value, this.props.name);
	}

	handleChangeUnidade(e, v, p){		
        var value =  v ? v : { empresa : this.props.value?.empresa };
		this.props.onChange(e, value, this.props.name);		
	}

	render() {

		var propsFormItem = ["col","xs","sm","md","lg","xl","xxl"];
		var propsItem = ["required" , "buttons" , "label", "placeholder", "disabled", "opcional"];

		return super.render(
            <React.Fragment>
                <FormItem label="Empresa" {...(this.getProps(propsFormItem,0))}>
                    <SelectEmpresa {...(this.getProps(propsItem,0))} name="empresa" value={this.props.value?.empresa} filter={{hasAccess : true}} onChange={this.handleChangeEmpresa} placeholder="Selecione o Empresa"/>
                </FormItem>
                <FormItem label="Unidade" {...(this.getProps(propsFormItem,1))}>
                    <SelectUnidade  {...(this.getProps(propsItem,1))} getOptionsWhen={f => f?.empresa?.idEmpresa !== undefined} filter={{empresa : this.props.value?.empresa, hasAccess : true}} name="unidade" value={this.props.value} onChange={this.handleChangeUnidade} placeholder="Selecione a Unidade"/>
                </FormItem>
            </React.Fragment>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	return <FormItemEmpresaUnidade {...props} navigate={navigate} params={params} />
}
export default With