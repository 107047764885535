//#NO-CHANGE-FILE#

import { OneToOneForm } from "../../siht/components";
import FormEndereco from "./FormEndereco";

export default class OneToOneFormEndereco extends OneToOneForm {

    constructor(props) {
        super(props);

        this.formCrud = FormEndereco;
        this.state.props = {flSomenteCidade : this.props.flSomenteCidade !== undefined ? this.props.flSomenteCidade : false};
        this.formCrud = FormEndereco;
    }

    getOptionValue(item) {
        return item?.idEndereco;
    }
    
    getOptionLabel(item) {
        let label = ``;
        if(this.getOptionValue(item)){
            //label+=`${item?.tipoLogradouro.nmTipoLogradouro} ${item?.dsLogradouro}, ${item?.dsNumero ? item?.dsNumero : ""}, ${item?.tipoBairro.nmTipoBairro} ${item?.dsBairro}, ${item?.dsComplemento ? item?.dsComplemento : ""}`;
            //label+=`\n${item?.cidade?.nmCidade}, ${item?.cidade?.estado?.nmEstado}, ${item?.cidade?.estado?.pais?.nmPais} - ${item?.nrCep ? item?.nrCep : ""}`;
            label=item.enderecoCompleto;
        }
        
        return label;
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    render() {
        return super.render();
    }
}