//#NO-CHANGE-FILE#


import Config from "./Config";
import Util from "../../utils/Util"; import React from "react";
import { useNavigate, useLocation, useParams } from "react-router";
import { BaseList } from "../../siht/base";
import { DataGrid, DataGridButtons, DataGridColumn, Panel, Container, Row, InputGroup, InputGroupText, InputGroupButton, PanelFooter, Badge, FormItem } from "../../siht/components";
import { Button, Div, Form, InputSearch, BtnSubmit, BtnButton, InputDate } from "../../siht/elements";
import { Modal } from "../../siht/controller";
import { IconClear, IconSearch } from "../../icons";
import { BtnDelete, BtnRadioGroupSimNao, BtnView } from "../../components/Custom";
import SelectUsuario from "../../components/SelectDB/SelectUsuario";

class ListLogRequest extends BaseList {

	constructor(props) {
		super(props);
		this.getItens = this.getItens.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleNew = this.handleNew.bind(this);
		this.handleView = this.handleView.bind(this);
		this.handleCopy = this.handleCopy.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.handleMore = this.handleMore.bind(this);
		this.onSubmitFilter = this.onSubmitFilter.bind(this);
		this.handleClear = this.handleClear.bind(this);
		this.handleFormSuccess = this.handleFormSuccess.bind(this);
		this.handleFormClose = this.handleFormClose.bind(this);
		this.handleChangeFilter = this.handleChangeFilter.bind(this);
		this.addTimeOut = this.addTimeOut.bind(this);
		this.delTimeOut = this.delTimeOut.bind(this);
		this.refreshIdLogRequest = this.refreshIdLogRequest.bind(this);

		this.timeout = null;
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
		clearTimeout(this.timeout);
	}

	init() {
		super.init(Config);
	}

	delTimeOut() {
		if (this.timeout) {
			clearTimeout(this.timeout);
		}
	}

	addTimeOut() {

		this.delTimeOut();

		this.timeout = setTimeout(() => {
			if (this.state.filter.flTimeOut) {
				this.getItens(false, true);
			}
		}, (1000 * 15));
	}

	initStateFilter() {
		return {
			flResponse: false,
			flTimeOut: true,
			dtLogStart: "",
			dtLogEnd: "",
			usuario: null,
			search: "",
			page: 1,
			pageSize: 50,
			idLogRequest: null
		};
	}

	getItens(more, flAutomatico = false) {
		let method = flAutomatico ? Config.getService().list : Config.getService().listLoading;

		super.getItens(method, false, more, () => {
			if (this.state.data.length > 0 && this.state.filter.idLogRequest === null) {
				this.setState(state => {
					state.filter.idLogRequest = this.state.data[0].idLogRequest;
					return state;
				});
			}
			this.addTimeOut();
		});
	}

	refreshIdLogRequest() {
		this.setState(state => {
			state.filter.idLogRequest = null;
			return state;
		});
	}

	handleNew(e) {
		super.handleNew(e, Config);
	}

	handleView(e, item) {
		Modal.openWindow(Config.getComponentForm(), { sizing: Modal.sizing.fullscreen }, { id: item.idLogRequest });
	}

	handleDelete(e, item) {
		super.handleDelete(e, Config, item);
	}

	handleCopy(e, item) {
		super.handleCopy(e, Config, item);
	}

	getFormEmbedded() {
		return super.getFormEmbedded(Config);
	}

	isError(i) {
		if (parseInt(i.logResponse?.nrStatus) >= 200 && parseInt(i.logResponse?.nrStatus) < 300) {
			return false;
		}

		return true;
	}

	getRenderStatus(i) {
		if (parseInt(i.logResponse?.nrStatus) >= 200 && parseInt(i.logResponse?.nrStatus) < 300) {
			return <Badge color={Badge.color.success}>{i.logResponse?.nrStatus}</Badge>
		}

		if (parseInt(i.logResponse?.nrStatus) >= 400 && parseInt(i.logResponse?.nrStatus) < 500) {
			return <Badge color={Badge.color.danger}>{i.logResponse?.nrStatus}</Badge>
		}

		if (parseInt(i.logResponse?.nrStatus) >= 500 && parseInt(i.logResponse?.nrStatus) < 600) {
			return <Badge color={Badge.color.warning}>{i.logResponse?.nrStatus}</Badge>
		}

		return <Badge color={Badge.color.secondary}>{i.logResponse?.nrStatus}</Badge>
	}

	render() {
		return super.render(
			<Panel title="Lista de LogRequest(s)" isModal={this.isModal()} container={{ sizing: Container.sizing.fluid, className: "" }}>
				<Div className="d-flex justify-content-end flex-nowrap gap-2">
					<BtnDelete disabled={this.state.selecteds.length === 0} sizing={Button.sizing.default} onClick={e => this.handleDelete(e, this.state.selecteds)}> Excluir </BtnDelete>
				</Div>

				<Row className="g-3">
					<FormItem md={2} label="Response?">
						<BtnRadioGroupSimNao name="flResponse" value={this.state.filter.flResponse} required={true} onChange={this.handleChangeFilter} />
					</FormItem>

					<FormItem md={2} label="Atualização?">
						<BtnRadioGroupSimNao name="flTimeOut" value={this.state.filter.flTimeOut} required={true} onChange={this.handleChangeFilter} />
					</FormItem>

					<FormItem md={2} label="Data Inicial">
						<InputDate name="dtLogStart" value={this.state.filter.dtLogStart} required={false} opcional={true} onChange={this.handleChangeFilter} />
					</FormItem>
					<FormItem md={2} label="Data Final">
						<InputDate name="dtLogEnd" min={this.state.filter.dtLogStart} value={this.state.filter.dtLogEnd} required={false} opcional={true} onChange={this.handleChangeFilter} />
					</FormItem>

					<FormItem md={4} label="Usuário">
						<SelectUsuario name="usuario" value={this.state.filter.usuario} required={false} opcional={true} onChange={this.handleChangeFilter} placeholder="Selecione o Usuário" buttons={{ search: false, new: false, edit: false }} />
					</FormItem>

				</Row>

				<Form onSubmit={this.onSubmitFilter}>
					<Row className="mt-1 mb-2 g-3">
						<InputGroup className="flex-nowrap" sizing={InputGroup.sizing.default}>
							<InputGroupText><IconSearch /></InputGroupText>
							<InputSearch autoComplete="off" autoFocus placeholder="Digite sua busca" name="search" value={this.state.filter.search} required={false} opcional={true} onChange={(e, v, n) => this.handleChange(e, v, n, this.state.filter)} />
							<InputGroupButton>
								<BtnSubmit title="Filtrar"> Buscar </BtnSubmit>
								<BtnButton title="Limpar Pesquisa" color={BtnButton.color.outlineDanger} onClick={this.handleClear}> <IconClear /> </BtnButton>
							</InputGroupButton>
						</InputGroup>
					</Row>
				</Form>

				<DataGrid onDoubleClickRow={this.handleView} responsive={true} data={this.state.data} search={false} isDisabled={i => false} sizing={DataGrid.sizing.sm} select={true} selecteds={this.state.selecteds} onSelect={(selecteds) => { this.setState({ selecteds: selecteds }) }} getItemValue={i => i.idLogRequest} onMore={this.handleMore}>
					<DataGridColumn md="auto" label="#" field={i => <Div onClick={this.refreshIdLogRequest} class="spinner-grow text-danger spinner-grow-sm" role="status" show={this.state.filter.idLogRequest !== null && parseInt(i.idLogRequest) > parseInt(this.state.filter.idLogRequest) && this.isError(i)}></Div>} className="text-center" />
					<DataGridColumn md="auto" label="Código" field={i => i.idLogRequest} className="text-center" />
					<DataGridColumn md="auto" label="Data" field={i => Util.date2Br(i.dhInsert, true)} className="text-center" />
					<DataGridColumn md="auto" label="URL" field={i => i.dsUrl} className="text-start" />
					<DataGridColumn md="auto" label="Method" field={i => i.cdMethod} className="text-center" />
					<DataGridColumn md="auto" label="Status" field={i => this.getRenderStatus(i)} className="text-center" />
					<DataGridColumn md="auto" label="Usuário" field={i => i.usuario?.nmUsuario} className="text-start" />
					<DataGridColumn md="auto" label="Resposta" field={i => i.logResponse?.txBody} className="text-start" style={{ fontFamily: "Courier New" }} show={this.state.filter.flResponse} />

					{/*<DataGridColumn col={4} label="Url" field={i =>
						<div dangerouslySetInnerHTML={{ __html: Util.nl2br(i.txUrl) }} style={{ fontFamily: "Courier New" }}/>
					} className="text-start"/>*/}

					<DataGridButtons>
						<BtnView sizing={Button.sizing.default} onClick={this.handleView} />
					</DataGridButtons>
				</DataGrid>

				<PanelFooter className="text-center py-2" show={this.state.data && this.state.data?.length > 0}>
					<BtnButton onClick={this.handleMore} disabled={!this.state.hasMore}> Buscar Mais Itens </BtnButton>
				</PanelFooter>
			</Panel>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <ListLogRequest {...props} navigate={navigate} params={params} location={location} />
}
export default With