import { Component } from "react";

export default class ProgressBar extends Component {

    static color = {
        primary : "bg-primary",
        secondary : "bg-secondary",
        success : "bg-success",
        danger : "bg-danger",
        warning : "bg-warning",
        info : "bg-info",
        light : "bg-light",
        dark : "bg-dark",
        default : "bg-primary"
    };

    render() {
        if(this.props.show !== undefined && !Boolean(this.props.show)){
            return (<></>);
        }

        const props = Object.assign({}, this.props);
        props.className = `progress ${props.className !== undefined ? props.className : ""}`;

        var progress = props.progress !== undefined ? parseInt(props.progress) : 0;

        if(props.height !== undefined){
            props.style = {
                height : props.height
            }
        }

        delete props.show;
        delete props.progress;
        delete props.label;
        delete props.striped;
        delete props.animated;
        delete props.height;
        delete props.width;
        delete props.opcional;

        return (            
            <div {...props} className="progress" role="progressbar" aria-label="Progress Bar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100">
                <div className={`progress-bar ${props.color ? props.color : ""} ${this.props.striped !== undefined && this.props.striped ? "progress-bar-striped" : ""} ${this.props.animated !== undefined && this.props.animated ? "progress-bar-animated" : ""}`} style={{width: `${progress}%`}}>{this.props.label ? this.props.label : ""}</div>
            </div>
        );
    }
}