//#NO-CHANGE-FILE#

import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { BaseCrud } from "../../siht/base";
import { FormButtons, FormItem, Row, Panel, PanelBody, PanelFooter, Container, ModalDialog } from "../../siht/components";
import { InputText, InputCep, Form, InputCheckSwitch } from "../../siht/elements";
import Config from "./Config";
import { FormItemLocalizacao, FormItemPaisEstadoCidade } from "../../components/FormItem";
import Util from "../../utils/Util";
import SelectTipoLogradouro from "../../components/SelectDB/SelectTipoLogradouro";
import SelectTipoBairro from "../../components/SelectDB/SelectTipoBairro";

class FormEndereco extends BaseCrud {

	constructor(props) {
		super(props);
		this.init = this.init.bind(this);
		this.handleSuccess = this.handleSuccess.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleChangeModel = this.handleChangeModel.bind(this);
		this.handleSubmitForm = this.handleSubmitForm.bind(this);
		this.handleBuscarCep = this.handleBuscarCep.bind(this);
		
		this.state.model = {
			flSomenteCidade : this.props.flSomenteCidade !== undefined ? this.props.flSomenteCidade : false,
			flBuscarLocalizacao : true,
			idEndereco : null,
			cidade : null,
			tipoLogradouro : null,
			tipoBairro : null,
			dsLogradouro : "",
			dsNumero : "",
			nrCep : "",
			dsBairro : "",
			dsComplemento : "",
			localizacao :null,
		};
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		super.init(Config);
	}

	handleSuccess(e) {
		super.handleSuccess(e, Config);
	}

	handleClose(e) {
		super.handleClose(e, Config);
	}

	handleBuscarCep(){

		if(Util.stringToNumber(this.state.model?.nrCep).length !== 8){
			return;
		}

		Config.getService().getOneByCep(this.state.model?.nrCep, (response) => {
			this.setState(state =>{
				state.model.cidade = response.cidade;
				state.model.dsLogradouro = response.logradouro;
				state.model.dsBairro = response.bairro;

				if(response.tipoLogradouro !== undefined){
					state.model.tipoLogradouro = response.tipoLogradouro;
				}
				if(response.tipoBairro !== undefined){
					state.model.tipoBairro = response.tipoBairro;
				}
				return state;
			});
		});
	}
	
	render() {
		return super.render(

					<Form onSubmit={this.handleSuccess} refParam={f => (this.form = f)} key={this.state.key}>
						<Panel key={this.state.key} title="Cadastro de Endereco" onClose={this.handleClose} isModal={this.isModal()} container={{sizing: Container.sizing.xxl, className : ""}} modal={{sizing: this.props.sizing ? this.props.sizing : ModalDialog.sizing.xl}} localOpen={this.props.localOpen}>
							<PanelBody>
								<Row className="px-3 py-1" show={this.props.flSomenteCidade !== undefined}>
									<InputCheckSwitch label="Somente a cidade?" name="flSomenteCidade" checked={this.state.model.flSomenteCidade} onChange={this.handleChangeModel} />
								</Row>
								<Row className="g-3 pb-2 px-2">
									<FormItem md={3} label="CEP">
										<InputCep name="nrCep" autoFocus={true} value={this.state.model?.nrCep} required={!this.state.model.flSomenteCidade} onChange={(e,v,p) => this.handleChange(e, v, p, this.state.model, this.handleBuscarCep)}/>
									</FormItem>
								</Row>
								<Row className="g-3 pb-2 px-2">
									<FormItemPaisEstadoCidade  md={[6,6,12]} name="cidade" value={this.state.model?.cidade} required={true} onChange={this.handleChangeModel} />
									
									<FormItem md={5} label="Tipo Logradouro" show={!this.state.model.flSomenteCidade}>
										<SelectTipoLogradouro name="tipoLogradouro" value={this.state.model?.tipoLogradouro} required={true} onChange={this.handleChangeModel} buttons={{search : false, new : false, edit: false}}/>
									</FormItem>

									<FormItem md={7} label={this.state.model?.tipoLogradouro?.nmTipoLogradouro} show={!this.state.model.flSomenteCidade}>
										<InputText name="dsLogradouro" value={this.state.model?.dsLogradouro} maxLength={100} required={true} onChange={this.handleChangeModel}/>
									</FormItem>

									<FormItem md={5} label="Número" show={!this.state.model.flSomenteCidade}>
										<InputText name="dsNumero" value={this.state.model?.dsNumero} maxLength={100} required={true} onChange={this.handleChangeModel}/>
									</FormItem>	

									<FormItem md={7} label="Complemento" show={!this.state.model.flSomenteCidade}>
										<InputText name="dsComplemento" value={this.state.model?.dsComplemento} maxLength={100} required={false} onChange={this.handleChangeModel}/>
									</FormItem>

									<FormItem md={6} label="Tipo Bairro" show={!this.state.model.flSomenteCidade}>
										<SelectTipoBairro name="tipoBairro" value={this.state.model?.tipoBairro} required={true} onChange={this.handleChangeModel}/>
									</FormItem>

									<FormItem md={6} label={this.state.model?.tipoBairro?.nmTipoBairro} show={!this.state.model.flSomenteCidade}>
										<InputText name="dsBairro" value={this.state.model?.dsBairro} maxLength={100} required={true} onChange={this.handleChangeModel}/>
									</FormItem>
								</Row>

								<Row className="px-3 py-1">
									<InputCheckSwitch label="Buscar Localização Automática com Base no Endereço?" name="flBuscarLocalizacao" checked={this.state.model.flBuscarLocalizacao} onChange={this.handleChangeModel} />
								</Row>
								
								<Row className="g-3 pb-2 px-2">									
									<FormItemLocalizacao md={12} name="localizacao" value={this.state.model.localizacao} required={false} onChange={this.handleChangeModel} placeholder="Selecione a Localizacao" zoom={15} readOnly={this.state.model.flBuscarLocalizacao}/>
								</Row>
							</PanelBody>
							<PanelFooter>
								<FormButtons onClose={this.handleClose} />
							</PanelFooter>
						</Panel>
					</Form>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <FormEndereco {...props} navigate={navigate} params={params} location={location}/>
}
export default With