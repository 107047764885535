import Http from "../controllers/Http";

export default class FaturaNotaFiscalDeducaoItemService {

	static urlBase = "/faturaNotaFiscalDeducaoItem/";

	static getForCombo(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(FaturaNotaFiscalDeducaoItemService.urlBase + 'forCombo', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static list(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(FaturaNotaFiscalDeducaoItemService.urlBase + 'list', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneById(id, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.get(FaturaNotaFiscalDeducaoItemService.urlBase + id)
			.then(fnSucess)
			.catch(fnError);
	}

	static create(data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(FaturaNotaFiscalDeducaoItemService.urlBase, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static update(id, data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.put(FaturaNotaFiscalDeducaoItemService.urlBase + id, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static delete(id, fnSucess = () => { }, fnError = () => { }) {
		if(Number.isInteger(id)){
			Http.getInstanceAppJson()
				.delete(FaturaNotaFiscalDeducaoItemService.urlBase + id)
				.then(fnSucess)
				.catch(fnError);
		}else{
			Http.getInstanceAppJson()
			.post(FaturaNotaFiscalDeducaoItemService.urlBase + 'delete', id)
			.then(fnSucess)
			.catch(fnError);
		}
	}

	static save(data, fnSucess = () => { }, fnError = () => { }) {
		if (data.idFaturaNotaFiscalDeducaoItem) {
			this.update(data.idFaturaNotaFiscalDeducaoItem, data, fnSucess, fnError);
		} else {
			this.create(data, fnSucess, fnError);
		}
	}

	static getAllByFaturaNotaFiscalDeducao(faturaNotaFiscalDeducao, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(FaturaNotaFiscalDeducaoItemService.urlBase + 'faturaNotaFiscalDeducao', faturaNotaFiscalDeducao)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByFaturaNotaFiscalDeducao(faturaNotaFiscalDeducao, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(FaturaNotaFiscalDeducaoItemService.urlBase + 'faturaNotaFiscalDeducao/' + faturaNotaFiscalDeducao.idFaturaNotaFiscalDeducao)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByUnidadeMedida(unidadeMedida, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(FaturaNotaFiscalDeducaoItemService.urlBase + 'unidadeMedida', unidadeMedida)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByUnidadeMedida(unidadeMedida, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(FaturaNotaFiscalDeducaoItemService.urlBase + 'unidadeMedida/' + unidadeMedida.idUnidadeMedida)
			.then(fnSucess)
			.catch(fnError);
	}
}