import Http from "../controllers/Http";

export default class ComissaoPessoaOrdemService {

	static urlBase = "/comissaoPessoaOrdem/";

	static getForCombo(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(ComissaoPessoaOrdemService.urlBase + 'forCombo', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static list(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(ComissaoPessoaOrdemService.urlBase + 'list', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneById(id, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.get(ComissaoPessoaOrdemService.urlBase + id)
			.then(fnSucess)
			.catch(fnError);
	}

	static create(data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(ComissaoPessoaOrdemService.urlBase, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static update(id, data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.put(ComissaoPessoaOrdemService.urlBase + id, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static delete(id, fnSucess = () => { }, fnError = () => { }) {
		if(Number.isInteger(id)){
			Http.getInstanceAppJson()
				.delete(ComissaoPessoaOrdemService.urlBase + id)
				.then(fnSucess)
				.catch(fnError);
		}else{
			Http.getInstanceAppJson()
			.post(ComissaoPessoaOrdemService.urlBase + 'delete', id)
			.then(fnSucess)
			.catch(fnError);
		}
	}

	static save(data, fnSucess = () => { }, fnError = () => { }) {
		if (data.idComissaoPessoaOrdem) {
			this.update(data.idComissaoPessoaOrdem, data, fnSucess, fnError);
		} else {
			this.create(data, fnSucess, fnError);
		}
	}

	static getAllByComissao(comissao, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(ComissaoPessoaOrdemService.urlBase + 'comissao', comissao)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByComissao(comissao, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(ComissaoPessoaOrdemService.urlBase + 'comissao/' + comissao.idComissao)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByPessoa(pessoa, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(ComissaoPessoaOrdemService.urlBase + 'pessoa', pessoa)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByPessoa(pessoa, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(ComissaoPessoaOrdemService.urlBase + 'pessoa/' + pessoa.idPessoa)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByOrdem(ordem, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(ComissaoPessoaOrdemService.urlBase + 'ordem', ordem)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByOrdem(ordem, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(ComissaoPessoaOrdemService.urlBase + 'ordem/' + ordem.idOrdem)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByCargo(cargo, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(ComissaoPessoaOrdemService.urlBase + 'cargo', cargo)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByCargo(cargo, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(ComissaoPessoaOrdemService.urlBase + 'cargo/' + cargo.idCargo)
			.then(fnSucess)
			.catch(fnError);
	}
}