import Config from "./Config";
import React from "react";
import { useNavigate, useLocation, useParams } from "react-router";
import { BaseCrud } from "../../siht/base";
import Flow from "./Flow"
import { FluxoItemDestinoService, FluxoItemService, FluxoService, FluxoVersaoService } from "../../services";
import { Panel } from "@xyflow/react";
import { BtnButton, Button, Div, Select } from "../../siht/elements";
import { DropDown, DropDownDivider, DropDownItem, DropDownLabel } from "../../siht/components";
import { SelectFluxoVersao } from "../../components/SelectDB";
import { ArrowClockwise, Check as IconCheck, PlusCircleDotted, Trash3 as IconTrash3, Diagram2, Diagram3, CheckSquare, Square, CaretRightFill} from "react-bootstrap-icons";
import { Modal } from "../../siht/controller";
import { FormFluxo } from "../Fluxo";
import { FormFluxoVersao } from "../FluxoVersao";
import { toast } from "react-toastify";

class WorkFlow extends BaseCrud {

	constructor(props) {
		super(props);
		
		this.getId = this.getId.bind(this);
		
		this.handleChangeModel = this.handleChangeModel.bind(this);
		this.handleChangeState = this.handleChangeState.bind(this);
		this.handleChanges = this.handleChanges.bind(this);

		this.handleNodesDelete = this.handleNodesDelete.bind(this);
		this.handleEdgesDelete = this.handleEdgesDelete.bind(this);

		this.handleClickSalvar = this.handleClickSalvar.bind(this);
		this.handleClickReiniciar = this.handleClickReiniciar.bind(this);
		this.handleClickNovaVersao = this.handleClickNovaVersao.bind(this);
		this.handleClickLimparFluxo = this.handleClickLimparFluxo.bind(this);
		this.handleClickEditFluxo = this.handleClickEditFluxo.bind(this);
		this.handleClickEditFluxoVersao = this.handleClickEditFluxoVersao.bind(this);
		this.handleClickAutoSalvar = this.handleClickAutoSalvar.bind(this);

		this.getFluxoVersao = this.getFluxoVersao.bind(this);
		this.getFluxoItens = this.getFluxoItens.bind(this);

		this.state.fluxo = null;
		this.state.fluxoVersao = null;
		this.state.fluxoItens = [];
		this.state.fluxoItemDestinos = [];
		this.state.flSalvo = true;
		this.state.flAutoSalvar = false;

		this.state.nodes = [];
		this.state.edges = [];
		this.state.nodesDelete = [];
		this.state.edgesDelete = [];

		this.interval = null;
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		clearInterval(this.interval);
		super.componentWillUnmount();		
	}

	init(){
		this.getFluxo();

		this.interval = setInterval(()=> {			
			if(this.state.flAutoSalvar && !this.state.flSalvo){
				this.handleClickSalvar({showMessageResponseSuccess : false, showLoadding : false});
			}
		}, 5000);
	}

	getFluxo(){
		FluxoService.getOneById(this.getId(), response => {
			this.setState({fluxo : response }, this.getFluxoVersao);
		});
	}

	getFluxoVersao(){		
		FluxoVersaoService.getAllByFluxo(this.state.fluxo, response => {
			response.forEach(fluxoVersao => {
				if(fluxoVersao.flAtual){
					this.setState({fluxoVersao : fluxoVersao }, this.getFluxoItens);
				}
			});			
		});
	}

	getFluxoItens(){
		if(this.state.fluxoVersao){
			FluxoItemService.getAllByFluxoVersao(this.state.fluxoVersao, response => {			
				let nodes = response.map(fluxoItem => Config.fluxoItemToNode(fluxoItem));
				this.setState({ nodesDelete : [], edgesDelete : [], fluxoItens : response, nodes : nodes, key : Math.random() }, this.getFluxoItemDestinos);
			});
		}else{
			this.setState({ 
				nodesDelete : [],
				edgesDelete : [], 
				fluxoItens : [], 
				fluxoItemDestinos : [],
				nodes : [],
				edges: [],
				key : Math.random()
			});
		}
	}

	getFluxoItemDestinos(){
		FluxoItemDestinoService.getAllByFluxoVersao(this.state.fluxoVersao, response => {
			let edges = response.map(fluxoItemDestino => Config.fluxoItemDestinoToEdge(fluxoItemDestino));
			this.setState({ fluxoItemDestinos : response, edges : edges, key : Math.random() });
		});
	}

	handleNodesDelete(nodes){	
		this.setState(state =>{
			state.nodesDelete = state.nodesDelete.concat(nodes);
			return state;
		});
	}

	handleEdgesDelete(edges){
		this.setState(state =>{
			state.edgesDelete = state.edgesDelete.concat(edges);
			return state;
		});
	}

	handleClickSalvar(configRequest = {}){
		let fluxoVersao = {...this.state.fluxoVersao}

		fluxoVersao.fluxoItemDestinoDelete = this.state.edgesDelete.map(edge => Config.edgeToFluxoItemDestino(edge));
		fluxoVersao.fluxoItemDelete = this.state.nodesDelete.map(node => Config.nodeToFluxoItem(node));
		fluxoVersao.fluxoItem = this.state.nodes.map(node => Config.nodeToFluxoItem(node));
		fluxoVersao.fluxoItemDestino = this.state.edges.map(edge => Config.edgeToFluxoItemDestino(edge));

		FluxoVersaoService.save(fluxoVersao, response => {
			//this.getFluxoItens();
			this.setState({flSalvo : true});
		}, () => {
			this.setState({flAutoSalvar : false});
			toast.warning("Ops, algo deu errado, por isso o salvamento automático foi desativado!");
		}, configRequest);
	}

	handleClickNovaVersao(){
		let payload = {
			fluxo : this.state.fluxo,
			fluxoVersaoAnterior : this.state.fluxoVersao
		};

		FluxoVersaoService.save(payload, response => {
			this.setState({fluxoVersao : response.data, key : Math.random()}, this.getFluxoItens);
		});
	}

	handleClickLimparFluxo(){
		Modal.confirm("Confirmação", "Deseja realmente excluir todos esses itens da versão selecionada?" , null, Modal.style.danger, () =>{
			
		});
	}

	handleClickReiniciar(){
		this.getFluxoItens();
	}

	handleChanges(type, nodes, edges, changes){
		//console.log(type);
		//console.log(edges);
		
		this.setState({nodes: nodes, edges : edges, flSalvo : false});
	}

	handleClickEditFluxo(){
		Modal.openWindow(FormFluxo, {sizing : Modal.sizing.default}, { id : this.state.fluxo.idFluxo }, (e, response) => { 
			this.init();
		});
	}

	handleClickEditFluxoVersao(){
		Modal.openWindow(FormFluxoVersao, {sizing : Modal.sizing.default}, { id : this.state.fluxoVersao.idFluxoVersao }, (e, response) => { 
			this.init();
		});
	}

	handleClickAutoSalvar(){
		this.setState({flAutoSalvar : !this.state.flAutoSalvar}, () =>{
			if(this.state.flAutoSalvar){
				toast.success("Auto salvar está ativo!");
			}else{
				toast.warning("Auto salvar está desativado!");
			}
		});
	}

	render() {

		if(this.state.fluxo === null){
			return (<></>);
		}

		return (
			<div className="full">
				<Flow
					key={this.state.key}
					nodes={this.state.nodes}
					edges={this.state.edges}

					onChanges={this.handleChanges}
					onNodesDelete={this.handleNodesDelete}
					onEdgesDelete={this.handleEdgesDelete}
				>

				<Panel position="top-left" className="m-0 p-0 ms-2 mt-1">

					<Div className="p-0 m-0">
						<span className="p-0 m-0 fs-7"> {this.state.fluxo.nmFluxo} </span>					
					</Div>

					<Div className="d-grid gap-2 d-md-flex">
					
						<SelectFluxoVersao sizing={Select.sizing.sm} getOptionsWhen={f => f?.fluxo?.idFluxo !== undefined} filter={{fluxo : this.state.fluxo}} name="fluxoVersao" value={this.state.fluxoVersao} required={true} onChange={(e,v,p) => this.handleChangeState(e,v,p, this.getFluxoItens)} placeholder="Versão" buttons={{search : false, new : false, edit: false, clear : false}}/>
							
						<DropDown alignment={DropDown.alignment.end} direction={DropDown.direction.end}>
							<DropDownLabel color={Button.color.outlineSecondary} sizing={Button.sizing.sm} toggle={false} className="text-nowrap"> 
								Opções <CaretRightFill/> 
							</DropDownLabel>

							<DropDownItem show={true} disabled={!this.state.fluxoVersao} sizing={Button.sizing.sm} onClick={this.handleClickAutoSalvar}>
								{this.state.flAutoSalvar ? <CheckSquare/> :  <Square/> } Salvar Automático
							</DropDownItem>

							<DropDownDivider/>

							<DropDownItem show={true} disabled={!this.state.fluxoVersao} sizing={Button.sizing.sm} onClick={this.handleClickReiniciar}>
								<ArrowClockwise/> Reiniciar
							</DropDownItem>
							<DropDownItem show={true} disabled={!this.state.fluxoVersao} sizing={Button.sizing.sm} onClick={this.handleClickNovaVersao}>
								<PlusCircleDotted/> Nova Versão
							</DropDownItem>
							<DropDownItem show={true} disabled={!this.state.fluxoVersao} sizing={Button.sizing.sm} onClick={this.handleClickLimparFluxo}>
								<IconTrash3/> Limpar Itens
							</DropDownItem>

							<DropDownDivider/>

							<DropDownItem show={true} disabled={!this.state.fluxo} sizing={Button.sizing.sm} onClick={this.handleClickEditFluxo}>
								<Diagram2/> Editar Fluxo
							</DropDownItem>

							<DropDownItem show={true} disabled={!this.state.fluxoVersao} sizing={Button.sizing.sm} onClick={this.handleClickEditFluxoVersao}>
								<Diagram3/> Editar Versão
							</DropDownItem>

						</DropDown>

						<BtnButton className="text-nowrap" disabled={!this.state.fluxoVersao || this.state.flSalvo} color={BtnButton.color.primary} sizing={BtnButton.sizing.sm} onClick={this.handleClickSalvar}> 
							<IconCheck/> Salvar 
						</BtnButton>
					</Div>					
					
				</Panel>

				</Flow>
			</div>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <WorkFlow {...props} navigate={navigate} params={params} location={location}/>
}
export default With