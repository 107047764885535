import React, { Component } from "react";
import DropDownItem from "./DropDownItem";
import DropDownDivider from "./DropDownDivider";
import DropDownLabel from "./DropDownLabel";

export default class DropDown extends Component {

    static direction = {        
        up : "dropup",
        end : "dropend",
        start : "dropstart",
        down : "",
        downCenter : "dropdown-center",
        upCenter : "dropup-center dropup",
        default : ""
    }

    static alignment = {        
        up : "dropup",
        end : "dropdown-menu-end",
        start : "dropdown-menu-start",
        down : "",
        default : ""
    }

    render() {
        if(this.props.show !== undefined && !this.props.show){
            return (<></>);
        }

        const props = Object.assign({}, this.props);
        props.direction = props.direction === undefined ? DropDown.direction.default : props.direction;
        props.alignment = props.alignment === undefined ? DropDown.alignment.default : props.alignment;
        props.in        = props.in === undefined ? true : props.in;

        props.className = `dropdown ${props.in ? "dropdown-in" : ""} ${props.direction} ${props.alignment} ${props.className !== undefined ? props.className : ""} `;

        var label = React.Children.toArray(this.props.children).find(child => child.type === DropDownLabel);

        if(!label){
            //label = (<DropDownLabel disabled={this.props.disabled !== undefined ? this.props.disabled : false}> {this.props.label} </DropDownLabel>);
        }

        delete props.label;
        delete props.show;
        delete props.in;

        return (
            <div {...props}>
                {label}
                <ul className="dropdown-menu">
                    {React.Children.toArray(this.props.children).filter(child => child.type === DropDownItem || child.type === DropDownDivider)}
                </ul>
            </div>
        );        
    }
}