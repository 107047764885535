import ContaPagarParcelaService from "../../services/ContaPagarParcelaService";
import Permissao from "../../controllers/Permissao";
import Util from "../../utils/Util";
import DadoValorService from "../../services/DadoValorService";

export default class Config {

	static getUrlForm() {
		return "/CadContaPagarParcela";
	}

	static getUrlList() {
		return "/LstContaPagarParcela";
	}

	static getComponentForm() {
		//return FormContaPagarParcela;
	}

	static getPermissionForm() {
		return Permissao.getInstance().getPermissoes([190]);
	}

	static getPermissionList() {
		return Permissao.getInstance().getPermissoes([190]);
	}

	static getComponentList() {
		//return ListContaPagarParcela;
	}

	static getService() {
		return ContaPagarParcelaService;
	}

	static getKeyId() {
		return "idContaPagarParcela";
	}

	static getKeyDescription() {
		return "nmContaPagarParcela";
	}

	static setDefaultValues(state , fnCallBack = () => {}, className = undefined) {
		className = className === undefined ? "FormContaPagarParcela" : className;
		let keyNameScreen = Util.toToUpperTextCamelCase(className, "_") + ".";
		var prefix = "CONFIGURACAO.";
		DadoValorService.getValues(`${prefix}${keyNameScreen}`, (response)=> {
			Util.setValueObjectCamelCase(state.model, response, keyNameScreen, prefix);
			fnCallBack();
		}, fnCallBack);
	}
}