import Http from "../controllers/Http";

export default class ContaPagarService {

	static urlBase = "/contaPagar/";

	static getForCombo(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(ContaPagarService.urlBase + 'forCombo', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static list(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(ContaPagarService.urlBase + 'list', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneById(id, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.get(ContaPagarService.urlBase + id)
			.then(fnSucess)
			.catch(fnError);
	}

	static create(data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(ContaPagarService.urlBase, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static update(id, data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.put(ContaPagarService.urlBase + id, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static delete(id, fnSucess = () => { }, fnError = () => { }) {
		if(Number.isInteger(id)){
			Http.getInstanceAppJson()
				.delete(ContaPagarService.urlBase + id)
				.then(fnSucess)
				.catch(fnError);
		}else{
			Http.getInstanceAppJson()
			.post(ContaPagarService.urlBase + 'delete', id)
			.then(fnSucess)
			.catch(fnError);
		}
	}

	static save(data, fnSucess = () => { }, fnError = () => { }) {
		if (data.idContaPagar) {
			this.update(data.idContaPagar, data, fnSucess, fnError);
		} else {
			this.create(data, fnSucess, fnError);
		}
	}

	static getAllByVwContaPagar(vwContaPagar, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(ContaPagarService.urlBase + 'vwContaPagar', vwContaPagar)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByVwContaPagar(vwContaPagar, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(ContaPagarService.urlBase + 'vwContaPagar/' + vwContaPagar.idVwContaPagar)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByContaPagarVeiculo(contaPagarVeiculo, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(ContaPagarService.urlBase + 'contaPagarVeiculo', contaPagarVeiculo)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByContaPagarVeiculo(contaPagarVeiculo, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(ContaPagarService.urlBase + 'contaPagarVeiculo/' + contaPagarVeiculo.idContaPagarVeiculo)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByContaPagarComissaoPessoa(contaPagarComissaoPessoa, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(ContaPagarService.urlBase + 'contaPagarComissaoPessoa', contaPagarComissaoPessoa)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByContaPagarComissaoPessoa(contaPagarComissaoPessoa, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(ContaPagarService.urlBase + 'contaPagarComissaoPessoa/' + contaPagarComissaoPessoa.idContaPagarComissaoPessoa)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByPessoa(pessoa, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(ContaPagarService.urlBase + 'pessoa', pessoa)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByPessoa(pessoa, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(ContaPagarService.urlBase + 'pessoa/' + pessoa.idPessoa)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByTipoContaPagar(tipoContaPagar, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(ContaPagarService.urlBase + 'tipoContaPagar', tipoContaPagar)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByTipoContaPagar(tipoContaPagar, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(ContaPagarService.urlBase + 'tipoContaPagar/' + tipoContaPagar.idTipoContaPagar)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByUnidade(unidade, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(ContaPagarService.urlBase + 'unidade', unidade)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByUnidade(unidade, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(ContaPagarService.urlBase + 'unidade/' + unidade.idUnidade)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByUsuario(usuario, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(ContaPagarService.urlBase + 'usuario', usuario)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByUsuario(usuario, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(ContaPagarService.urlBase + 'usuario/' + usuario.idUsuario)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByRecorrencia(recorrencia, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(ContaPagarService.urlBase + 'recorrencia', recorrencia)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByRecorrencia(recorrencia, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(ContaPagarService.urlBase + 'recorrencia/' + recorrencia.idRecorrencia)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByContaPagarParcela(contaPagarParcela, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(ContaPagarService.urlBase + 'contaPagarParcela', contaPagarParcela)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByContaPagarParcela(contaPagarParcela, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(ContaPagarService.urlBase + 'contaPagarParcela/' + contaPagarParcela.idContaPagarParcela)
			.then(fnSucess)
			.catch(fnError);
	}
}