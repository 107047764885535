//#NO-CHANGE-FILE#

import React from "react";
import { useNavigate, useLocation, useParams } from "react-router";
import BtnEdit from "../../components/BtnEdit";
import Config from "./Config";
import { BaseList } from "../../siht/base";
import { DataGrid, DataGridButtons, DataGridColumn } from "../../siht/components";
import { Button, Div } from "../../siht/elements";
import Formatter from "../../utils/Formatter";
import { BadgeSimNao } from "../../components/Custom";

class ListFaturaNotaFiscalItemRetencao extends BaseList {

	constructor(props) {
		super(props);
		this.getItens = this.getItens.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleNew = this.handleNew.bind(this);
		this.handleEdit = this.handleEdit.bind(this);
		this.handleCopy = this.handleCopy.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.handleMore = this.handleMore.bind(this);
		this.onSubmitFilter = this.onSubmitFilter.bind(this);
		this.handleClear = this.handleClear.bind(this);
		this.handleFormSuccess = this.handleFormSuccess.bind(this);
		this.handleFormClose = this.handleFormClose.bind(this);
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		super.init(Config);
	}

	getItens(more){
		if(this.props?.parent){
			super.getItens(Config.getService().getAllByFaturaNotaFiscalItem, this.props.parent, more);
		}
	}

	handleNew(e) {
		super.handleNew(e, Config);
	}

	handleEdit(e, item) {
		super.handleEdit(e, Config, item);
	}

	handleDelete(e, item) {
		super.handleDelete(e, Config, item);
	}

	handleCopy(e, item) {
		super.handleCopy(e, Config, item);
	}

	getFormEmbedded(){
		return super.getFormEmbedded(Config);
	}

	render() {
		let editable = this.props.editable !== undefined ? this.props.editable : true;

		return super.render(
			<Div className="p-2 mt-2">
				<DataGrid onDoubleClickRow={this.handleEdit} responsive={true} data={this.state.data} search={false} isDisabled={i => false} sizing={DataGrid.sizing.sm} select={false} selecteds={this.state.selecteds} onSelect={(selecteds)=>{this.setState({selecteds : selecteds})}} getItemValue={i=>i.idFaturaNotaFiscalItemRetencao} onMore={this.handleMore}>
					<DataGridColumn label="Código" field={i => i.idFaturaNotaFiscalItemRetencao} className="text-center"/>
					<DataGridColumn col={4} label="Retencao" field={i => i.cdRetencao} className="text-center"/>
					<DataGridColumn col={2} label="Retido?" field={i => <BadgeSimNao value={i.flRetido}/>} className="text-center"/>
					<DataGridColumn col={2} label="Aliquota" field={i => i.vlAliquota ? Formatter.floatToValor(i.vlAliquota, 2) + " %" : ""} className="text-center"/>
					<DataGridColumn col={2} label="Valor" field={i => i.vlValor ? Formatter.floatToReais(i.vlValor, 2) : ""} className="text-center"/>
					<DataGridColumn col={2} label="Base Calculo" field={i => i.vlBaseCalculo ? Formatter.floatToReais(i.vlBaseCalculo, 2) : ""} className="text-center"/>
					<DataGridButtons>
						<BtnEdit sizing={Button.sizing.sm} onClick={this.handleEdit} disabled={!editable}/>
					</DataGridButtons>
				</DataGrid>
			</Div>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <ListFaturaNotaFiscalItemRetencao {...props} navigate={navigate} params={params} location={location}/>
}
export default With