import { BtnButton, Field, InputText } from "../elements";
import InputGroup from "./InputGroup";
import InputGroupButton from "./InputGroupButton";
import { Modal } from "../controller";
import { FormMapOneMarker } from "../components";
import { GeoFill as IconGeoFill, X as IconX} from "react-bootstrap-icons";

export default class InputMapOneMarker extends Field {

    constructor(props) {
        super(props);
        this.handleClear = this.handleClear.bind(this);
        this.handleClickMap = this.handleClickMap.bind(this);
    }

    handleClickMap(e) {
        var object = {
            lng: this.props?.lng !== undefined && this.props.lng ? this.props.lng : this.props?.slng,
            lat: this.props?.lat !== undefined && this.props.lat ? this.props.lat : this.props?.slat,
            zoom: this.props?.zoom !== undefined && this.props.zoom ? this.props.zoom : this.props?.szoom,
            apiKey: this.props?.apiKey,
            readOnly : this.props?.readOnly !== undefined ? this.props.readOnly : false,
        };

        Modal.openWindow(FormMapOneMarker, {}, object, (e, response) => {
            if (this.props.onChange) {
                this.props.onChange(e, response)
            }
        });
    }

    handleClear() {
        var e = { target: this.ref.current };
        if (this.props.onChange) {
            this.props.onChange(e, { lng: null, lat: null })
        }
    }

    render() {
        if (this.props.show !== undefined && !Boolean(this.props.show)) {
            return (<></>);
        }

        const props = Object.assign({}, this.props);

        props.value = ``;
        props.className = `readonly-field ${props.className !== undefined ? props.className : ""}`;
        props.feedback = false;

        if (props.lat && props.lng) {
            props.value = `${props.lat},${props.lng}`;
        }

        delete props.onChange;

        return (
            <InputGroup>
                <InputText {...props} onClick={this.handleClickMap}/>
                <InputGroupButton>                    
                    <BtnButton show={this.props?.readOnly === undefined || !this.props?.readOnly} className="ps-2 pe-2" color={BtnButton.color.outlineDanger} onClick={this.handleClear} disabled={!props.value || props.disabled}>
                        <IconX/>
                    </BtnButton>
                    <BtnButton className="ps-2 pe-2" color={BtnButton.color.outlineDark} onClick={this.handleClickMap} disabled={props.disabled}>
                        <IconGeoFill />
                    </BtnButton>
                </InputGroupButton>
                {super.getFeedback()}
            </InputGroup>
        );
    }
}