//#NO-CHANGE-FILE#

import Config from "./Config";
import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import ListEquipePessoa from "../EquipePessoa/ListEquipePessoa";
import ManyToManyCheckEquipeUnidadeByUnidade from "./ManyToManyCheckEquipeUnidadeByUnidade";
import Permissao from "../../controllers/Permissao";
import { BaseList, BaseCrud } from "../../siht/base";
import { FormButtons, FormItem, Row, TabNavigator, Tab, Panel, PanelBody, PanelFooter, Container } from "../../siht/components";
import { InputText, Form, BtnSubmit } from "../../siht/elements";
import { FormItemLocalizacao } from "../../components/FormItem";

class FormEquipe extends BaseCrud {

	constructor(props) {
		super(props);
		this.init = this.init.bind(this);
		this.initModel = this.initModel.bind(this);
		this.handleSuccess = this.handleSuccess.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleChangeModel = this.handleChangeModel.bind(this);
		this.handleSubmitForm = this.handleSubmitForm.bind(this);
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		super.init(Config);
	}

	initModel(){
		return {
			idEquipe : null,
			nmEquipe : "",
			localizacao : null,
			equipePessoa : [],
			usuarioGrupoEquipe : [],
			equipeUnidade : []
		}
	}

	handleSuccess(e) {
		super.handleSuccess(e, Config);
	}

	handleClose(e) {
		super.handleClose(e, Config);
	}

	render() {
		return super.render(

					<Panel key={this.state.key} title="Cadastro de Equipe" onClose={this.handleClose} isModal={this.isModal()} container={{sizing: Container.sizing.xxl, className : ""}}>
						<PanelBody className="p-0 m-0">
							<TabNavigator key={this.state.key} onChangeTab={this.handleChangeTab} className="border-light">
								<Tab title="Equipe" className="m-2">
									<Form onSubmit={this.handleSuccess} refParam={f => (this.form = f)}>
										<Row className="g-3 pb-2 px-2">
											<FormItem md={6} label="Código">
												<InputText name="idEquipe" value={this.state.model.idEquipe} readOnly={true} className="readonly-field" required={false} onChange={this.handleChangeModel}/>
											</FormItem>
											<FormItem md={12} label="Nome da Equipe">
												<InputText name="nmEquipe" value={this.state.model.nmEquipe} autoFocus={true} maxLength={100} required={true} onChange={this.handleChangeModel}/>
											</FormItem>
											<FormItemLocalizacao md={6} name="localizacao" value={this.state.model.localizacao} required={false} onChange={this.handleChangeModel} placeholder="Selecione a Localizacao"/>
											<BtnSubmit hidden > Enviar </BtnSubmit>
										</Row>
									</Form>
								</Tab>
								<Tab show={Permissao.getInstance().getPermissoes([203])} title="Pessoas" disabled={!this.state.model.idEquipe} opened={false} className="m-2">
									<ListEquipePessoa parent={this.state.model} localOpen={BaseList.localOpen.modal}/>
								</Tab>
								<Tab show={Permissao.getInstance().getPermissoes([204])} title="Unidades" disabled={!this.state.model.idEquipe} opened={false} className="m-2">
									<ManyToManyCheckEquipeUnidadeByUnidade typeCheck={ManyToManyCheckEquipeUnidadeByUnidade.typeCheck.box} parent={this.state.model} search={true}/>
								</Tab>
							</TabNavigator>
						</PanelBody>
						<PanelFooter>
							<FormButtons show={this.state.tabIndex === 0} onClose={this.handleClose} onSubmit={this.handleSubmitForm}/>
						</PanelFooter>
					</Panel>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <FormEquipe {...props} navigate={navigate} params={params} location={location}/>
}
export default With