//#NO-CHANGE-FILE#
import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import Config from "./Config";
import ConfigApp from "../../ConfigApp";
import FormOrdemComentario from "./FormOrdemComentario";
import { BaseCrud, BaseList } from "../../siht/base";
import { Card, CardFooter, CardBody } from "../../siht/components";
import { Div, P, Span } from "../../siht/elements";
import Util from "../../utils/Util";
import Auth from "../../controllers/Auth";
import { IconDownload } from "../../icons";
import { PersonFill as IconPersonFill, Paperclip as IconPaperclip} from "react-bootstrap-icons";

class ListOrdemComentario extends BaseList {

	constructor(props) {
		super(props);
		this.getItens = this.getItens.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleNew = this.handleNew.bind(this);
		this.handleEdit = this.handleEdit.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.handleMore = this.handleMore.bind(this);
		this.onSubmitFilter = this.onSubmitFilter.bind(this);
		this.handleClear = this.handleClear.bind(this);
		this.handleFormSuccess = this.handleFormSuccess.bind(this);
		this.handleFormClose = this.handleFormClose.bind(this);
		this.toScroll = this.toScroll.bind(this);

		this.refScrool = React.createRef();
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		super.init(Config);
	}

	getItens(){
		if(this.props?.parent){
			super.getItens(Config.getService().getAllByOrdem, this.props.parent, false, () =>{
				this.toScroll(this.refScrool);
			});
		}
	}

	handleNew(e) {
		super.handleNew(e, Config);
	}

	handleEdit(e, item) {
		super.handleEdit(e, Config, item);
	}

	handleDelete(e, item) {
		super.handleDelete(e, Config, item);
	}

	getFormEmbedded(){
		return super.getFormEmbedded(Config);
	}

	toScroll(ref) {
        setTimeout(() => {
            ref?.current?.scrollIntoView();
        }, 300);
    }

	render() {

		return super.render(
			<Div className="chat">
				<Div className="d-flex h-100 justify-content-center container">

					<Card className="mt-3 mb-3 w-100">
						<CardBody className="overflow-y-scroll overflow-x-hidden h-100" style={{backgroundColor : "#e5dcd7"}}>

						{this.state.data.map((comentario, index) => {

							let isProprietario = comentario.usuario.idUsuario === Auth.getIdUsuarioDataToken() ? true : false;
							let imgUsuario = <></>;

							if(comentario?.usuario?.arquivo?.cdUid){
								imgUsuario = <img style={{ maxHeight: "40px" }} src={ConfigApp.getUrlArquivo() + "public/inline/" + comentario?.usuario?.arquivo?.cdUid + "/" + comentario?.usuario?.arquivo?.arquivoBase?.cdHash} className="rounded-circle" alt="..." />;
							}else{
								imgUsuario = <IconPersonFill className="text-primary" width="40" height="40"/>;
							}
							
							return (
								<Div key={index}>
									<Div className={`d-flex mb-2 justify-content-${isProprietario ? "end" : "start"} flex-1 w-100`}>
										<Div className="">
											<Div className="d-flex flex-end-center hover-actions-trigger">
												
												{isProprietario ? <Div className="w-50"></Div>: <></>}

												<Div className="pb-1 me-2">
													<Div className={`mb-1 dialog_box ${isProprietario ? "right" : "left"} ${isProprietario ? "menssagem-enviada text-dark" : "bg-white text-dark border border-body-secondary"} rounded-2 p-3 pt-2`} data-bs-theme="light">
														
														<div className="d-flex hover-actions-trigger">

															{!isProprietario ? <div className="me-3 flex-shrink-0"> {imgUsuario} </div> : <></> }
															
															<div>
																<P className={`mb-0 fs-8 fw-bold text-nowrap ${isProprietario ? "text-primary" : "text-danger"}`}>{comentario.usuario.nmUsuario}</P>
																<p className="mb-0 lh-sm fs-6" dangerouslySetInnerHTML={{ __html: Util.htmlFormatWhatsapp(comentario.txComentario) }}/>
																<p className="mb-0 lh-sm fs-7">															
																	{parseInt(comentario.arquivo?.idArquivo) > 0 ? <>
																		<div className="d-flex justify-content-between pt-1">
																			<Span><IconPaperclip/> {Util.formatBytes(comentario.arquivo.arquivoBase.nrTamanho)}</Span>
																			<a className="fs-7 link-dark link-underline-opacity-0 link" rel="opener" href={ConfigApp.getUrlArquivo() + "public/download/" + comentario?.arquivo?.cdUid +  "/" + comentario?.arquivo?.arquivoBase?.cdHash}>
																				<IconDownload/> Download
																			</a>
																		</div>
																	</> : <></>}
																</p>
															</div>

															{isProprietario ?  <div className="ms-3 flex-shrink-0"> {imgUsuario} </div> : <></>}
														</div>
													</Div>
													<Div className={isProprietario ? "text-end me-2" : "ms-2 text-start"} >
														<P className="mb-0 fs-8 text-body-tertiary text-opacity-85 fw-semibold">{Util.date2Br(comentario.dhInsert, true)}</P>
													</Div>
												</Div>
												
												{!isProprietario ? <Div className="w-50"></Div>: <></>}
											</Div>
										</Div>
									</Div>
								</Div>
							);
						})}
						<div ref={this.refScrool}></div>
						</CardBody>
						<CardFooter>
							<FormOrdemComentario localOpen={BaseCrud.localOpen.embedded} parent={this.props.parent} handleSuccess={e => this.init()}/>
						</CardFooter>
					</Card>
				</Div>
			</Div>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <ListOrdemComentario {...props} navigate={navigate} params={params} location={location}/>
}
export default With