
import FormDadoLocal from "./FormDadoLocal.js";
import ListDadoLocal from "./ListDadoLocal.js";
import { Permissao } from "../../controllers";
import { Util } from "../../utils";
import { DadoValorService, DadoLocalService } from "../../services";

export default class Config {

	static getUrlForm() {
		return "/CadDadoLocal";
	}

	static getUrlList() {
		return "/LstDadoLocal";
	}

	static getComponentForm() {
		return FormDadoLocal;
	}

	static getPermissionForm() {
		return Permissao.getInstance().getPermissoes([122]);
	}

	static getPermissionList() {
		return Permissao.getInstance().getPermissoes([122]);
	}

	static getComponentList() {
		return ListDadoLocal;
	}

	static getService() {
		return DadoLocalService;
	}

	static getKeyId() {
		return "idDadoLocal";
	}

	static getKeyDescription() {
		return "nmDadoLocal";
	}

	static setDefaultValues(state , fnCallBack = () => {}, className = undefined) {
		className = className === undefined ? "FormDadoLocal" : className;
		let keyNameScreen = Util.toToUpperTextCamelCase(className, "_") + ".";
		var prefix = "CONFIGURACAO.";
		DadoValorService.getValues(`${prefix}${keyNameScreen}`, (response)=> {
			Util.setValueObjectCamelCase(state.model, response, keyNameScreen, prefix);
			fnCallBack();
		}, fnCallBack);
	}
}