import InputMask from "./InputMask";

export default class InputCep extends InputMask {
    
    constructor(props){
        super(props);
        
        this.options = {
            mask: '_____-___',
            replacement: { _: /\d/ },
        };
    }

    render() {
        const props = {};
        props.pattern = "\\d{5}-?\\d{3}";
        props.placeholder = "99999-999";

        return super.render(props);
    }
}