import Http from "../controllers/Http";

export default class ClassificacaoProdutoDadoValorService {

	static urlBase = "/classificacaoProdutoDadoValor/";

	static getForCombo(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(ClassificacaoProdutoDadoValorService.urlBase + 'forCombo', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static list(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(ClassificacaoProdutoDadoValorService.urlBase + 'list', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneById(id, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.get(ClassificacaoProdutoDadoValorService.urlBase + id)
			.then(fnSucess)
			.catch(fnError);
	}

	static create(data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(ClassificacaoProdutoDadoValorService.urlBase, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static update(id, data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.put(ClassificacaoProdutoDadoValorService.urlBase + id, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static delete(id, fnSucess = () => { }, fnError = () => { }) {
		if(Number.isInteger(id)){
			Http.getInstanceAppJson()
				.delete(ClassificacaoProdutoDadoValorService.urlBase + id)
				.then(fnSucess)
				.catch(fnError);
		}else{
			Http.getInstanceAppJson()
			.post(ClassificacaoProdutoDadoValorService.urlBase + 'delete', id)
			.then(fnSucess)
			.catch(fnError);
		}
	}

	static save(data, fnSucess = () => { }, fnError = () => { }) {
		if (data.idClassificacaoProdutoDadoValor) {
			this.update(data.idClassificacaoProdutoDadoValor, data, fnSucess, fnError);
		} else {
			this.create(data, fnSucess, fnError);
		}
	}

	static getAllByClassificacaoProduto(classificacaoProduto, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(ClassificacaoProdutoDadoValorService.urlBase + 'classificacaoProduto', classificacaoProduto)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByClassificacaoProduto(classificacaoProduto, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(ClassificacaoProdutoDadoValorService.urlBase + 'classificacaoProduto/' + classificacaoProduto.idClassificacaoProduto)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByDadoValor(dadoValor, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(ClassificacaoProdutoDadoValorService.urlBase + 'dadoValor', dadoValor)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByDadoValor(dadoValor, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(ClassificacaoProdutoDadoValorService.urlBase + 'dadoValor/' + dadoValor.idDadoValor)
			.then(fnSucess)
			.catch(fnError);
	}
}