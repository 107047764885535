//#NO-CHANGE-FILE#

import React from "react";
import { useNavigate, useLocation, useParams } from "react-router";
import { BtnCopy, BtnDelete, BtnEdit, BtnNew, StatusCustom } from "../../components/Custom";
import Config from "./Config";
import {ThreeDotsVertical as IconThreeDotsVertical} from "react-bootstrap-icons"
import { IconCopy, IconDelete, IconEdit } from "../../icons";
import { BaseList } from "../../siht/base";
import { DataGrid, DataGridButtons, DataGridColumn, DropDown, DropDownLabel, DropDownItem, ModalDialog } from "../../siht/components";
import { BtnButton, Button, Div } from "../../siht/elements";
import FormRelatorioAns001 from "../Relatorio/FormRelatorioAns001";
import FormRelatorioAns002 from "../Relatorio/FormRelatorioAns002";
import { Modal } from "../../siht/controller";
import { PrinterFill as IconPrinterFill } from "react-bootstrap-icons";
import Util from "../../utils/Util";
import Permissao from "../../controllers/Permissao";
import { toast } from "react-toastify";

class ListAnsPerfilByOrdem extends BaseList {

	constructor(props) {
		super(props);
		this.getItens = this.getItens.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleNew = this.handleNew.bind(this);
		this.handleEdit = this.handleEdit.bind(this);
		this.handleCopy = this.handleCopy.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.handleMore = this.handleMore.bind(this);
		this.onSubmitFilter = this.onSubmitFilter.bind(this);
		this.handleClear = this.handleClear.bind(this);
		this.handleFormSuccess = this.handleFormSuccess.bind(this);
		this.handleFormClose = this.handleFormClose.bind(this);
		this.handleImprimirAns = this.handleImprimirAns.bind(this);
		this.handleRelatorioAns = this.handleRelatorioAns.bind(this);
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		super.init(Config);
	}

	getItens(more){
		if(this.props?.parent){
			super.getItens(Config.getService().getAllByOrdem, this.props.parent, more);
		}
	}

	handleNew(e) {
		super.handleNew(e, Config, {sizing : ModalDialog.sizing.fullscreen });
	}

	handleEdit(e, item) {
		this.props.navigate(Config.getUrlForm() + "/" + item.idAnsPerfil);
	}

	handleDelete(e, item) {
		super.handleDelete(e, Config, item);
	}

	handleCopy(e, item) {
		Modal.confirm("Confirmação", "Deseja realmente COPIAR esse Perfil?" , null, Modal.style.warning, () =>{
			Config.getService().duplicar(item, (response)=> {
				toast.success("Perfil foi duplicada, novo perfil " + response.idAnsPerfil);
				this.props.navigate(Config.getUrlForm() + '/' + response.idAnsPerfil);
			});
		});
	}

	getFormEmbedded(){
		return super.getFormEmbedded(Config);
	}

	handleImprimirAns(e, ansPerfil = null) {
		if(ansPerfil){
			Modal.openWindow(FormRelatorioAns001, {sizing : Modal.sizing.default}, { idAnsPerfis : [ansPerfil.idAnsPerfil] });
		}else{
			let idAnsPerfis = this.state.selecteds.flatMap((o) => (o.idAnsPerfil));
			Modal.openWindow(FormRelatorioAns001, {sizing : Modal.sizing.default}, { idAnsPerfis : idAnsPerfis });
		}
	}

	handleRelatorioAns(e) {
		Modal.openWindow(FormRelatorioAns002, {sizing : Modal.sizing.default}, { idOrdens : [this.props.parent.idOrdem] });
	}

	render() {

		/*return super.render(
			<Div>
				<TabNavigator>
				{this.state.data.map(item => (
					<Tab label="teste">
						<FormAnsPerfil id={item.idAnsPerfil} localOpen={BaseCrud.localOpen.embedded}/>
					</Tab>
				))}
				</TabNavigator>
			</Div>			
		);*/

		return super.render(
			<Div className="p-2 mt-2">
				<Div className="d-flex justify-content-end flex-nowrap gap-2">					
					<BtnNew onClick={this.handleNew}/>
					<BtnButton disabled={this.state.selecteds.length === 0} color={BtnButton.color.outlinePrimary} sizing={Button.sizing.default} onClick={this.handleImprimirAns} show={Permissao.getInstance().getPermissoes([115])}> <IconPrinterFill/> </BtnButton>
					<BtnButton disabled={this.state.data.length === 0} color={BtnButton.color.outlineDanger} sizing={Button.sizing.default} onClick={this.handleRelatorioAns} show={Permissao.getInstance().getPermissoes([116])}> <IconPrinterFill/> </BtnButton>
					<BtnDelete disabled={this.state.selecteds.length === 0} sizing={Button.sizing.default} onClick={e => this.handleDelete(e, this.state.selecteds)}> Excluir </BtnDelete>
				</Div>

				{this.getFormEmbedded()}

				<DataGrid responsive={true} data={this.state.data} search={false} isDisabled={i => false} sizing={DataGrid.sizing.sm} select={true} selecteds={this.state.selecteds} onSelect={(selecteds)=>{this.setState({selecteds : selecteds})}} getItemValue={i=>i.idAnsPerfil} onMore={this.handleMore}>
					<DataGridColumn col={1} label="Número" field={i => <>
						{i.tipoAnsPerfil?.cdTipoAnsPerfil}-{i.nrTipoAnsPerfil}
					</>} className="text-start"/>

					<DataGridColumn col={1} label="Tipo Perfil" field={i => i.tipoAnsPerfil?.nmTipoAnsPerfil} className="text-start"/>
					
					<DataGridColumn col={2} label="Status" field={i => <StatusCustom className="w-100" color={i.ansStatus}> {i.ansStatus?.nmAnsStatus} </StatusCustom>} className="text-center"/>

					<DataGridColumn mobile={false} col={2} label="Endereço" field={i => <>
						<Div className="fw-bold">{i.endereco?.dsLogradouro}, {i.endereco?.dsNumero}, {i.endereco?.dsComplemento}</Div>
							<Div>{i.endereco?.dsBairro}, {i.endereco?.nrCep}</Div>
							<Div>{i.endereco?.cidade?.nmCidade}</Div>
							<Div>{i.endereco?.cidade?.estado?.nmEstado} - {i.endereco?.cidade?.estado?.pais?.nmPais}</Div>
					</>} className="text-start"/>
					
					<DataGridColumn mobile={false} col={1} label="Inicio" field={i => Util.date2Br(i.dtInicio)} className="text-center"/>
					<DataGridColumn mobile={false} col={1} label="Fim" field={i => Util.date2Br(i.dtFim)} className="text-center"/>
					<DataGridColumn mobile={false} col={1} label="Limite" field={i => Util.floatToValor(i.vlProfundidade, 2)} className="text-center"/>
					<DataGridColumn mobile={false} col={1} label="NívelAgua" field={i => Util.floatToValor(i.vlNivelAgua, 2)} className="text-center"/>
					<DataGridColumn mobile={false} col={1} label="Cota" field={i => <>{Util.floatToValor(i.vlCota,2)} %</> } className="text-center"/>
					<DataGridColumn mobile={false} col={1} label="Site" field={i => i.dsSite} className="text-start"/>
					<DataGridButtons>
						<BtnEdit sizing={Button.sizing.default} onClick={this.handleEdit}/>
						<BtnDelete sizing={Button.sizing.default} onClick={this.handleDelete}/>
						<BtnCopy sizing={Button.sizing.default} onClick={this.handleCopy}/>
						<BtnButton color={BtnButton.color.outlinePrimary} sizing={Button.sizing.default} onClick={this.handleImprimirAns} show={Permissao.getInstance().getPermissoes([115])}> <IconPrinterFill/> </BtnButton>
						<DropDown alignment={DropDown.alignment.end}>
							<DropDownLabel sizing={Button.sizing.default} toggle={false}> <IconThreeDotsVertical/> </DropDownLabel>
							<DropDownItem title="Editar Registro" type="button" onClick={this.handleEdit}> <IconEdit/> Editar </DropDownItem>
							<DropDownItem title="Excluir Registro" type="button" onClick={this.handleDelete}> <IconDelete/> Excluir </DropDownItem>
							<DropDownItem title="Copiar Registro" type="button" onClick={this.handleCopy}> <IconCopy/> Copiar </DropDownItem>
						</DropDown>
					</DataGridButtons>
				</DataGrid>
			</Div>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <ListAnsPerfilByOrdem {...props} navigate={navigate} params={params} location={location}/>
}
export default With