import Http from "../controllers/Http";

export default class VwContaReceberParcelaService {

	static urlBase = "/vwContaReceberParcela/";

	static getForCombo(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(VwContaReceberParcelaService.urlBase + 'forCombo', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static list(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(VwContaReceberParcelaService.urlBase + 'list', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneById(id, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.get(VwContaReceberParcelaService.urlBase + id)
			.then(fnSucess)
			.catch(fnError);
	}


	static getAllByContaReceberParcela(contaReceberParcela, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(VwContaReceberParcelaService.urlBase + 'contaReceberParcela', contaReceberParcela)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByContaReceberParcela(contaReceberParcela, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(VwContaReceberParcelaService.urlBase + 'contaReceberParcela/' + contaReceberParcela.idContaReceberParcela)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByStatusConta(statusConta, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(VwContaReceberParcelaService.urlBase + 'statusConta', statusConta)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByStatusConta(statusConta, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(VwContaReceberParcelaService.urlBase + 'statusConta/' + statusConta.idStatusConta)
			.then(fnSucess)
			.catch(fnError);
	}
}