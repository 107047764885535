
import FormAnsPerfilPlanta from "./FormAnsPerfilPlanta.js";
import ListAnsPerfilPlanta from "./ListAnsPerfilPlanta.js";
import { Permissao } from "../../controllers";
import { Util } from "../../utils";
import { DadoValorService, AnsPerfilPlantaService } from "../../services";

export default class Config {

	static getUrlForm() {
		return "/CadAnsPerfilPlanta";
	}

	static getUrlList() {
		return "/LstAnsPerfilPlanta";
	}

	static getComponentForm() {
		return FormAnsPerfilPlanta;
	}

	static getPermissionForm() {
		return Permissao.getInstance().getPermissoes([124]);
	}

	static getPermissionList() {
		return Permissao.getInstance().getPermissoes([124]);
	}

	static getComponentList() {
		return ListAnsPerfilPlanta;
	}

	static getService() {
		return AnsPerfilPlantaService;
	}

	static getKeyId() {
		return "idAnsPerfilPlanta";
	}

	static getKeyDescription() {
		return "nmAnsPerfilPlanta";
	}

	static setDefaultValues(state , fnCallBack = () => {}, className = undefined) {
		className = className === undefined ? "FormAnsPerfilPlanta" : className;
		let keyNameScreen = Util.toToUpperTextCamelCase(className, "_") + ".";
		var prefix = "CONFIGURACAO.";
		DadoValorService.getValues(`${prefix}${keyNameScreen}`, (response)=> {
			Util.setValueObjectCamelCase(state.model, response, keyNameScreen, prefix);
			fnCallBack();
		}, fnCallBack);
	}
}