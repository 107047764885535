//#NO-CHANGE-FILE#

import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import BtnRadioGroupSimNao from "../../components/BtnRadioGroupSimNao";
import { BaseCrud } from "../../siht/base";
import { FormButtons, FormItem, Row, Panel, PanelBody, PanelFooter, Container, ModalDialog } from "../../siht/components";
import { InputText, InputNumberFormat, Form } from "../../siht/elements";
import Config from "./Config";

class FormFaturaNotaFiscalItemRetencao extends BaseCrud {

	constructor(props) {
		super(props);
		this.init = this.init.bind(this);
		this.initModel = this.initModel.bind(this);
		this.handleSuccess = this.handleSuccess.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleChangeModel = this.handleChangeModel.bind(this);
		this.handleSubmitForm = this.handleSubmitForm.bind(this);
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		super.init(Config);
	}

	initModel(){
		return {
			idFaturaNotaFiscalItemRetencao : null,
			faturaNotaFiscalItem : this.props.parent !== undefined ? this.props.parent : {},
			cdRetencao : "",
			flRetido : true,
			vlAliquota : 0,
			vlValor : 0,
			vlBaseCalculo : 0
		}
	}

	handleSuccess(e) {
		super.handleSuccess(e, Config);
	}

	handleClose(e) {
		super.handleClose(e, Config);
	}

	render() {
		return super.render(

					<Form onSubmit={this.handleSuccess} refParam={f => (this.form = f)} key={this.state.key}>
						<Panel key={this.state.key} title="Cadastro de FaturaNotaFiscalItemRetencao" localOpen={this.props.localOpen}  onClose={this.handleClose} isModal={this.isModal()} container={{sizing: Container.sizing.xxl, className : ""}} modal={{sizing: this.props.sizing ? this.props.sizing : ModalDialog.sizing.xl}}>
							<PanelBody>
								<Row className="g-3 pb-2 px-2">
									<FormItem md={3} label="Código">
										<InputText name="idFaturaNotaFiscalItemRetencao" value={this.state.model.idFaturaNotaFiscalItemRetencao} readOnly={true} className="readonly-field" required={false} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={6} label="Retenção">
										<InputText name="cdRetencao" disabled={this.state.model.idFaturaNotaFiscalItemRetencao} value={this.state.model.cdRetencao} maxLength={100} required={true} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={3} label="Retido?">
										<BtnRadioGroupSimNao name="flRetido" value={this.state.model.flRetido} required={true} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={4} label="Aliquota" show={this.state.model.flRetido}>
										<InputNumberFormat name="vlAliquota" value={this.state.model.vlAliquota} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} prefix="R$ " required={true} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={4} label="Valor" show={this.state.model.flRetido}>
										<InputNumberFormat name="vlValor" value={this.state.model.vlValor} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} prefix="R$ " onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={4} label="Base de Calculo" show={this.state.model.flRetido}>
										<InputNumberFormat name="vlBaseCalculo" value={this.state.model.vlBaseCalculo} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} prefix="R$ " onChange={this.handleChangeModel}/>
									</FormItem>
								</Row>
							</PanelBody>
							<PanelFooter>
								<FormButtons onClose={this.handleClose} />
							</PanelFooter>
						</Panel>
					</Form>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <FormFaturaNotaFiscalItemRetencao {...props} navigate={navigate} params={params} location={location}/>
}
export default With