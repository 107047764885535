import Config from "./Config";
import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import SelectUsuario from "../../components/SelectDB/SelectUsuario";
import SelectLocalizacao from "../../components/SelectDB/SelectLocalizacao";
import { BaseCrud } from "../../siht/base";
import { FormButtons, FormItem, Row, Panel, PanelBody, PanelFooter, Container, ModalDialog } from "../../siht/components";
import { InputText, Form } from "../../siht/elements";

class FormVeiculoTrajeto extends BaseCrud {

	constructor(props) {
		super(props);
		this.init = this.init.bind(this);
		this.initModel = this.initModel.bind(this);
		this.handleSuccess = this.handleSuccess.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleChangeModel = this.handleChangeModel.bind(this);
		this.handleSubmitForm = this.handleSubmitForm.bind(this);
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		super.init(Config);
	}

	initModel(){
		return {
			idVeiculoTrajeto : null,
			veiculo : this.props.parent !== undefined ? this.props.parent : {},
			usuario : null,
			localizacao : null
		}
	}

	handleSuccess(e) {
		super.handleSuccess(e, Config);
	}

	handleClose(e) {
		super.handleClose(e, Config);
	}

	render() {
		return super.render(

					<Form onSubmit={this.handleSuccess} refParam={f => (this.form = f)} key={this.state.key}>
						<Panel key={this.state.key} title="Cadastro de VeiculoTrajeto" localOpen={this.props.localOpen}  onClose={this.handleClose} isModal={this.isModal()} container={{sizing: Container.sizing.xxl, className : ""}} modal={{sizing: this.props.sizing ? this.props.sizing : ModalDialog.sizing.xl}}>
							<PanelBody>
								<Row className="g-3 pb-2 px-2">
									<FormItem md={6} label="Código">
										<InputText name="idVeiculoTrajeto" value={this.state.model.idVeiculoTrajeto} readOnly={true} className="readonly-field" required={false} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={12} label="Nome do Usuario">
										<SelectUsuario name="usuario" value={this.state.model.usuario} required={true} onChange={this.handleChangeModel} placeholder="Selecione Nome do Usuario"/>
									</FormItem>
									<FormItem md={12} label="Nome do Localizacao">
										<SelectLocalizacao name="localizacao" value={this.state.model.localizacao} required={true} onChange={this.handleChangeModel} placeholder="Selecione Nome do Localizacao"/>
									</FormItem>
								</Row>
							</PanelBody>
							<PanelFooter>
								<FormButtons onClose={this.handleClose} />
							</PanelFooter>
						</Panel>
					</Form>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <FormVeiculoTrajeto {...props} navigate={navigate} params={params} location={location}/>
}
export default With