import { Component } from "react";
import BtnButton from "../elements/BtnButton";
import BtnSubmit from "../elements/BtnSubmit";
import { Button } from "../elements";

export default class FormButtons extends Component {
    constructor(props) {
        super(props);

        this.handleClose = this.handleClose.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleClose(e){
        if(this.props.onClose){
            this.props.onClose(e);
        }
    }

    handleSubmit(e){
        if(this.props.onSubmit){
            this.props.onSubmit(e);
        }
    }

    render() {
        if (this.props.show !== undefined && !Boolean(this.props.show)) {
            return (<></>);
        }

        const props = Object.assign({}, this.props);
        props.className = `d-grid gap-2 d-md-flex ${props.className !== undefined ? props.className : ""}`;

        delete props.onClose;
        delete props.show;

        var labelSubmit = "Salvar";
        var labelCancel = "Cancelar";

        var disabledSubmit = false;
        var disabledCancel = false;

        if(this.props.label !== undefined){
            if(Array.isArray(this.props.label)){
                if(this.props.label[0] !== undefined){
                    labelSubmit = this.props.label[0];
                }

                if(this.props.label[1] !== undefined){
                    labelCancel = this.props.label[1];
                }
            }else{
                labelSubmit = this.props.label;
            }            
        }


        if(this.props.disabled !== undefined){
            if(Array.isArray(this.props.disabled)){
                if(this.props.disabled[0] !== undefined){
                    disabledSubmit = this.props.disabled[0];
                }

                if(this.props.disabled[1] !== undefined){
                    disabledCancel = this.props.disabled[1];
                }
            }else{
                disabledSubmit = this.props.disabled;
            }            
        }

        if(this.props.onSubmit){
            return (
                <div {...props}>
                    {this.props.onSubmit !== undefined ? <BtnButton sizing={Button.sizing.default} color={Button.color.primary} onClick={this.handleSubmit} disabled={disabledSubmit}> {labelSubmit} </BtnButton> : <></>}
                    {this.props.onClose !== undefined ? <BtnButton sizing={Button.sizing.default} color={Button.color.outlineSecondary} onClick={this.handleClose} disabled={disabledCancel}> {labelCancel} </BtnButton> : <></>}
                    {this.props.children}
                </div>
            );
        }else{
            return (
                <div {...props}>
                    <BtnSubmit sizing={Button.sizing.default} color={Button.color.primary} disabled={disabledSubmit}> {labelSubmit} </BtnSubmit>
                    {this.props.onClose !== undefined ? <BtnButton sizing={Button.sizing.default} color={Button.color.outlineSecondary} onClick={this.handleClose} disabled={disabledCancel}> {labelCancel} </BtnButton> : <></>}
                    {this.props.children}
                </div>
            );
        }
    }
}