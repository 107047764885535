import React from "react";
import { useNavigate, useLocation, useParams } from "react-router";
import Config from "./Config";
import { BaseCrud } from "../../siht/base";
import {  Panel, Container, Row, ModalDialog, FormItem } from "../../siht/components";
import { TextArea } from "../../siht/elements";
import FaturaNotaFiscalLogService from "../../services/FaturaNotaFiscalLogService";
import Util from "../../utils/Util";

class ListFaturaNotaFiscalLogByFaturaNotaFiscal extends BaseCrud {

	constructor(props) {
		super(props);
		this.init = this.init.bind(this);
		this.handleSuccess = this.handleSuccess.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleChangeModel = this.handleChangeModel.bind(this);
		this.handleSubmitForm = this.handleSubmitForm.bind(this);

		this.state.data = [];
		this.state.model = {
			faturaNotaFiscal : this.props.faturaNotaFiscal
		};
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		if(this.state.model.faturaNotaFiscal){
			FaturaNotaFiscalLogService.getAllByFaturaNotaFiscal(this.state.model.faturaNotaFiscal, (response) => {
				this.setState({data : response});
			});
		}
	}

	handleClose(e) {
		super.handleClose(e, Config);
	}

	render() {
		return super.render(
			<Panel localOpen={this.props.localOpen} key={this.state.key} title="Histórico de Processamento" onClose={this.handleClose} isModal={this.isModal()} container={{sizing: Container.sizing.xxl, className : ""}} modal={{sizing: this.props.sizing ? this.props.sizing : ModalDialog.sizing.xl, scrollable : true}}>

				<Row className="g-3 pb-2 px-2">
				{this.state.data.map((faturaNotaFiscalLog, index) =>(
					<FormItem md={12} label={`${Util.date2Br(faturaNotaFiscalLog.dhInsert, true)} - ${faturaNotaFiscalLog.statusNotaFiscal.nmStatusNotaFiscal}`}>
						<TextArea rows={7} value={faturaNotaFiscalLog.dsLog} opcional={true} readOnly={true}/>
					</FormItem>
				))}
				</Row>
			</Panel>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <ListFaturaNotaFiscalLogByFaturaNotaFiscal {...props} navigate={navigate} params={params} location={location}/>
}
export default With