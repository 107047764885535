import React from "react";
import Field from "./Field";
import { Functions } from "../util";
import Option from "./Option";
import Util from "../../utils/Util";

export default class Select extends Field {

    static sizing = {
        lg: "lg",
        sm : "sm",
        default: "",
    }

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.setOptions = this.setOptions.bind(this);
        this.state.options = this.props.options || [];

        if(this.props.children){
            this.state.options = React.Children.toArray(this.props.children).filter(child => child.type === Option);
            this.state.options = this.state.options.map(o => o.props);
        }
    }

    componentDidUpdate(nextProps) {
        super.componentDidUpdate(nextProps);
        if (nextProps.filter !== undefined && this.props.filter !== undefined) {
            if (!this.objEqual(nextProps.filter, this.props.filter)) {
                this.getOptions();
            }
        }
    }

    componentDidMount() {
        super.componentDidMount();
        this.getOptions();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    handleChange(e, selected, name) {
        if(selected === undefined){
            selected = this.state.options.find(item => this.getOptionValue(item)?.toString() === e?.target?.value?.toString());
            selected = selected === undefined ? "" : selected;
        }

        if(!name){
            name = e?.target?.name;
        }

        if(this.props.children){
            super.handleChange(e, selected.value, name);
        }else{
            super.handleChange(e, selected, name);
        }
    }

    isDisabled(item) {
        return this.props.isDisabled !== undefined && this.props.isDisabled instanceof Function ? this.props.isDisabled(item) : false;
    }

    getForComboService() {
        return null;
    }

    getDefault() {
        return null;
    }

    getOptions(callBack) {
        var filter = this.props.filter !== undefined ? this.props.filter : {};
        callBack = callBack !== undefined && Functions.isFunction(callBack) ? callBack : ()=>{};

        var getForComboService = this.getForComboService();

        if (this.getOptionsWhen(filter) && getForComboService !== null) {
            
            getForComboService((filter), (response) => {
                /*if(this.props?.cache?.key !== undefined){
                    localStorage.setItem(`cache-for-combo-${this.props.cache.key}`, JSON.stringify(response));
                }*/

                this.setOptions(response, callBack);
            });
        }else if(!this.props.options && !this.props.children){
            this.setOptions([], callBack);
        }
    }

    setOptions(response, callBack){
        this.setState({ options: response }, () => {
            if(!this.getOptionValue(this.props.value)) {
                let item = this.getDefault();
                if(item){
                    this.handleChange({}, item, this.props.name);
                }
            }
            callBack();
        });  
    }

    getOptionValue(item) {
        if(Util.isObject(item)){
            return item?.value;
        }
        
        return item;
    }

    getOptionLabel(item) {
        if(item?.label !== undefined){
            return item?.label;
        }else{
            return this.state.options.find(i => i.value.toString() === item.value.toString())?.label;
        }
    }

    getOptionClass(item) {
        return "";
    }

    getOptionsWhen(item) {
        var filter = this.props.filter !== undefined ? this.props.filter : {};
        return this.props.getOptionsWhen === undefined || this.props.getOptionsWhen(filter);
    }

    render(feedback = true) {
        const props = Object.assign({}, this.props);
        delete props.show;
        props.className = `form-select ${props.className ? props.className : ""} ${props.sizing ? "form-select-" + props.sizing : ""} ${props.readOnly !== undefined && props.readOnly ? "readonly-field" : ""}`;
        props.onChange = this.handleChange;
        props.value = this.getOptionValue(this.props.value);
        props.value = props.value === undefined ? "" : props.value;
        props.ref = this.props.refParam ? this.props.refParam : this.ref;

        var element =
            <select {...this.getProps(props)}>
                <option value="">{this.props.placeholder !== undefined ? this.props.placeholder : "Selecione"}</option>
                {this.state.options.map((option, index) => (
                    <option key={index}
                        className={this.getOptionClass(option)}
                        value={this.getOptionValue(option)}
                        disabled={this.isDisabled(option)}> {this.getOptionLabel(option)} </option>
                ))}
            </select>;

        return super.render(element, feedback);
    }
}