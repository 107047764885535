
import FormFuncionarioHistorico from "./FormFuncionarioHistorico.js";
import ListFuncionarioHistorico from "./ListFuncionarioHistorico.js";
import { Permissao } from "../../controllers";
import { Util } from "../../utils";
import { DadoValorService, FuncionarioHistoricoService } from "../../services";

export default class Config {

	static getUrlForm() {
		return "/CadFuncionarioHistorico";
	}

	static getUrlList() {
		return "/LstFuncionarioHistorico";
	}

	static getComponentForm() {
		return FormFuncionarioHistorico;
	}

	static getPermissionForm() {
		return Permissao.getInstance().getPermissoes([214]);
	}

	static getPermissionList() {
		return Permissao.getInstance().getPermissoes([214]);
	}

	static getComponentList() {
		return ListFuncionarioHistorico;
	}

	static getService() {
		return FuncionarioHistoricoService;
	}

	static getKeyId() {
		return "idFuncionarioHistorico";
	}

	static getKeyDescription() {
		return "nmFuncionarioHistorico";
	}

	static setDefaultValues(state , fnCallBack = () => {}, className = undefined) {
		className = className === undefined ? "FormFuncionarioHistorico" : className;
		let keyNameScreen = Util.toToUpperTextCamelCase(className, "_") + ".";
		var prefix = "CONFIGURACAO.";
		DadoValorService.getValues(`${prefix}${keyNameScreen}`, (response)=> {
			Util.setValueObjectCamelCase(state.model, response, keyNameScreen, prefix);
			fnCallBack();
		}, fnCallBack);
	}
}