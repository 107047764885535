//#NO-CHANGE-FILE#

import ConfigApp from "../ConfigApp";
import Http from "../controllers/Http";
import Util from "../utils/Util";

export default class ArquivoService {

	static urlBase = "/arquivo/";

	static upload(file, fnSucess = () => { }, fnError = () => { }) {

        const formData = new FormData();
        formData.append("arquivo", file, file.name);

        Http.getInstanceAppJson({ timeout: 300, apiUrlBase : ConfigApp.getUrlArquivo(), showLoadding : false })
            .post("/upload/", formData, { headers: { "Content-Type": "multipart/form-data" }})
            .then(fnSucess)
            .catch(fnError);
    }

	static zip(arquivos) {
		Util.openNewWindow(ConfigApp.getUrlApi() + 'arquivo/download/zip', arquivos);
    }

	static getForCombo(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(ArquivoService.urlBase + 'forCombo', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static list(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(ArquivoService.urlBase + 'list', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneById(id, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.get(ArquivoService.urlBase + id)
			.then(fnSucess)
			.catch(fnError);
	}

	static create(data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(ArquivoService.urlBase, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static update(id, data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.put(ArquivoService.urlBase + id, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static delete(id, fnSucess = () => { }, fnError = () => { }) {
		if(Number.isInteger(id)){
			Http.getInstanceAppJson()
				.delete(ArquivoService.urlBase + id)
				.then(fnSucess)
				.catch(fnError);
		}else{
			Http.getInstanceAppJson()
			.post(ArquivoService.urlBase + 'delete', id)
			.then(fnSucess)
			.catch(fnError);
		}
	}

	static save(data, fnSucess = () => { }, fnError = () => { }) {
		if (data.idArquivo) {
			this.update(data.idArquivo, data, fnSucess, fnError);
		} else {
			this.create(data, fnSucess, fnError);
		}
	}

	static getAllByArquivoBase(arquivoBase, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(ArquivoService.urlBase + 'arquivoBase', arquivoBase)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByArquivoBase(arquivoBase, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(ArquivoService.urlBase + 'arquivoBase/' + arquivoBase.idArquivoBase)
			.then(fnSucess)
			.catch(fnError);
	}
}