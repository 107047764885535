import InputNumberFormat from "./InputNumberFormat";

export default class InputValorReais extends InputNumberFormat {
    
    render() {
        const props = {};

        props.thousandSeparator=".";
        props.decimalSeparator=",";
        props.inputMode="numeric";
        props.prefix='R$ ';
        props.placeholder="R$ 9.999,99";
        props.fixedDecimalScale=true;
        props.decimalScale=2;

        return super.render(props);
    }
}