import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BaseComponent } from "../../siht/base";
import SelectTipoProduto from "../../components/SelectDB/SelectTipoProduto";
import SelectProduto from "../../components/SelectDB/SelectProduto";
import { FormItem } from "../../siht/components";

class FormItemTipoProdutoProduto extends BaseComponent {

	constructor(props) {
		super(props);
		this.init = this.init.bind(this);        
        this.handleChangeTipoProduto = this.handleChangeTipoProduto.bind(this);
        this.handleChangeProduto = this.handleChangeProduto.bind(this);
	}

	componentDidMount() {
		super.componentDidMount();
        this.init();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		
	}

	handleChangeTipoProduto(e, v, p){
        var value =  { tipoProduto : v ? v : {} };
        this.props.onChange(e, value, this.props.name);
	}

	handleChangeProduto(e, v, p){
        var value =  v ? v : { tipoProduto : this.props.value?.tipoProduto };
		this.props.onChange(e, value, this.props.name);
	}

	render() {

		var propsFormItem = ["col","xs","sm","md","lg","xl","xxl"];
		var propsItem = ["required" , "buttons" , "label", "placeholder", "disabled", "opcional"];

		var filterProduto = {
			tipoProduto : this.props.value?.tipoProduto, 
			tipoPreco : this.props?.tipoPreco !== undefined ? this.props?.tipoPreco : null, 
			tipoOrdem : this.props?.tipoOrdem !== undefined ? this.props?.tipoOrdem : null, 
		};

		return super.render(
            <React.Fragment>
                <FormItem label="Tipo Produto" {...(this.getProps(propsFormItem,0))}>
                    <SelectTipoProduto {...(this.getProps(propsItem,0))} name="tipoProduto" value={this.props.value?.tipoProduto} onChange={this.handleChangeTipoProduto} placeholder="Selecione o Tipo de Produto" buttons={{search : false, new : false, edit: false}}/>
                </FormItem>
                <FormItem label="Produto" {...(this.getProps(propsFormItem,1))}>
                    <SelectProduto {...(this.getProps(propsItem,1))} getOptionsWhen={f => f?.tipoProduto?.idTipoProduto !== undefined} filter={filterProduto} name="produto" value={this.props.value} onChange={this.handleChangeProduto} placeholder="Selecione Nome do Produto"/>
                </FormItem>
            </React.Fragment>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	return <FormItemTipoProdutoProduto {...props} navigate={navigate} params={params} />
}
export default With