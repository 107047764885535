import React, { useCallback, useEffect, useRef, useState  } from 'react';
import { ReactFlow, useNodesState, useEdgesState, addEdge, Controls, Background, MiniMap, applyNodeChanges, Panel, applyEdgeChanges, MarkerType, useReactFlow, ReactFlowProvider, ControlButton, Position, useUpdateNodeInternals } from '@xyflow/react';
import { Functions } from '../../siht/util';
import { GraphUpArrow as IconGraphUpArrow, NodePlus as IconNodePlus, NodeMinus as IconNodeMinus, Shuffle as IconShuffle, PencilSquare as IconPencilSquare, PaintBucket} from 'react-bootstrap-icons';

import '@xyflow/react/dist/style.css';
import './custom.css';
import { BtnButton } from '../../siht/elements';

import TaskNode from './CustomNode/TaskNode';
import StartNode from './CustomNode/StartNode';
import EndNode from './CustomNode/EndNode';
import AnnotationNode from './CustomNode/AnnotationNode';
import ConditionNode from './CustomNode/ConditionNode';
import GroupNode from './CustomNode/GroupNode';

import ButtonEdge from './CustomEdge/ButtonEdge';

import NodeContextMenu from './ContextMenu/NodeContextMenu';
import PaneContextMenu from './ContextMenu/PaneContextMenu';
import Config from './Config';

const nodeTypes = {
  start: StartNode,
  end: EndNode,
  annotation: AnnotationNode,
  condition : ConditionNode,
  group : GroupNode,
  task : TaskNode
};

const edgeTypes = {
  default: ButtonEdge,
  straight: ButtonEdge,
  step: ButtonEdge,
  smoothstep: ButtonEdge,
};

const Flow = (props) => {

  const reactFlowWrapper = useRef(null);

  const [nodes] = useNodesState(props.nodes !== undefined ? props.nodes : []);
  const [edges] = useEdgesState(props.edges !== undefined ? props.edges : []);
  const [selectedNode, setSelectedNode] = useState(null);
  const [selectedNodes, setSelectedNodes] = useState([]);
  const [selectedEdges, setSelectedEdges] = useState([]);
  const [nodeMenu, setNodeMenu] = useState(null);
  const [paneMenu, setPaneMenu] = useState(null);
  const reactFlow = useReactFlow();
  const updateNodeInternals = useUpdateNodeInternals();

  const [config, setConfig] = useState({
    addNodeOnEdgeDrop : true,
    showMiniMap : props?.showMiniMap === undefined || props?.showMiniMap === true
  });

  const defaultViewport = { x: 0, y: 0, zoom: 1.5 };

  const nodeOrigin = [0.5, 0];

  const edgeInit = Config.edgeInit;
  const nodeInit = Config.nodeInit;

  const updatePostionEdgesConnect = useCallback((node) => {  

    let positions = [];

    positions = [
      [Position.Bottom, Position.Top],
      [Position.Right, Position.Top],
      [Position.Left, Position.Top],
      [Position.Left, Position.Right],
      [Position.Bottom, Position.Right],
      [Position.Top, Position.Right],
      [Position.Top, Position.Left],
      [Position.Bottom, Position.Left],
      [Position.Right, Position.Left],
      [Position.Right, Position.Bottom],
      [Position.Top, Position.Bottom],        
      [Position.Left, Position.Bottom],
    ];

    if(node.type === "start"){
      positions = [
        [Position.Bottom, null],
        [Position.Left, null],
        [Position.Top, null],
        [Position.Right, null]
      ];
    }

    if(node.type === "end"){
      positions = [
        [null, Position.Top],
        [null, Position.Right],
        [null, Position.Bottom],
        [null, Position.Left]
      ];
    }

    let sourcePosition = node.sourcePosition !== undefined ? node.sourcePosition : Position.Bottom ;
    let targetPosition = node.targetPosition !== undefined ? node.targetPosition : Position.Top;

    let index = positions.findIndex(ps => (ps[0] === sourcePosition || ps[0] === null) && (ps[1] === targetPosition || ps[1] === null));
    index = index < positions.length -1 ? index+1 : 0;
    
    sourcePosition = positions[index][0];
    targetPosition = positions[index][1];    

    return { ...node, sourcePosition: sourcePosition, targetPosition: targetPosition };
  });

  const updateArrowStyle = useCallback((node) => {
    return { 
      ...node, 
      data : {
        ...node.data,
        arrowStyle : node.data.arrowStyle === "left" ? "right" : "left"
      }      
    };
  });

  const updateTypeEdges = useCallback((edge) => {  
    let types = ["straight","step","smoothstep", "default"]; //"bezier" = null
    let type = edge.type !== undefined ? edge.type : "default";
    let index = types.findIndex(tp => tp === type);
    index = index < types.length -1 ? index+1 : 0;
    type = types[index];

    return { ...edge, type: type};
  });

  const onSelectionChange = useCallback(({ nodes, edges }) => {
    setSelectedNodes(nodes);
    setSelectedEdges(edges);

    setSelectedNode(null);
    if(nodes.length === 1){
      setSelectedNode(nodes[0]);
    }
  }, [setSelectedNodes, setSelectedEdges, setSelectedNode]);
  
  const onConnect = useCallback(
    (params) =>
      reactFlow.setEdges((eds) =>
        addEdge({
            ...params,
            ...edgeInit
          }, eds
        ),
      ),
    [],
  );

  const onConnectStart = useCallback((event, {nodeId}) => {
    if(props.onConnectStart !== undefined && Functions.isFunction(props.onConnectStart)){
      let callback = props.onConnectStart;
      callback(event, nodeId);
    }
  });

  const onConnectEnd = useCallback(
    (event, connectionState) => {

      if(config.addNodeOnEdgeDrop){
        if (!connectionState.isValid) {
          
          const idNode = `node.temp.${Math.random(100)}`;          
          const idEdge = `edge.temp.${Math.random(100)}`;
          const { clientX, clientY } = 'changedTouches' in event ? event.changedTouches[0] : event;
          
          const newNode = {
            id : idNode,
            position: reactFlow.screenToFlowPosition({
              x: clientX,
              y: clientY,
            }),
            data: { label: `Node ${idNode}` },
            origin: [0.5, 0.0],
            ...nodeInit
          };
  
          reactFlow.setNodes((nds) => nds.concat(newNode));
          reactFlow.setEdges((eds) =>
            eds.concat({ 
                id: idEdge, 
                source: connectionState.fromNode.id, 
                target: idNode,
                ...edgeInit,
            }),
          );
        }
      }

      if(props.onConnectEnd !== undefined && Functions.isFunction(props.onConnectEnd)){
        let callback = props.onConnectEnd;
        callback(event, connectionState);
      }

      if(props.onChanges !== undefined && Functions.isFunction(props.onChanges)){
        let callback = props.onChanges;
        callback("connect", reactFlow.getNodes(), reactFlow.getEdges());
      }
    },
    [reactFlow],
  );

  const onNodesChange = useCallback (
    (changes) => reactFlow.setNodes((nds) => {
      let result = applyNodeChanges(changes, nds);

      if(props.onNodesChange !== undefined && Functions.isFunction(props.onNodesChange)){
        let callback = props.onNodesChange;
        callback(changes, nds, result);
      }

      if(props.onChanges !== undefined && Functions.isFunction(props.onChanges)){
        let callback = props.onChanges;
        callback("nodes", reactFlow.getNodes(), reactFlow.getEdges(), changes);
      }

      return result;
    }),
    [],
  );  

  const onNodesDelete = useCallback(
    (deleteds) => {
      if(props.onNodesDelete !== undefined && Functions.isFunction(props.onNodesDelete)){
        let callback = props.onNodesDelete;
        callback(deleteds);
      }      
      return deleteds;
    },
    [],
  );

  const onNodeDoubleClick = useCallback((event, node) => {    
    reactFlow.setNodes((nds) =>
      nds.map((nd) => {
        if(nd.id === node.id){
          switch(node.type){
            case "annotation":
              nd = updateArrowStyle(nd);
              break;
            default:
              nd = updatePostionEdgesConnect(nd);
          }
          
        }
        return nd;
      })
    );
  });

  const onNodeDragStop = useCallback((event, node) => {

    if(node.type === "group" || !node){
      return;
    }

    const intersections = reactFlow.getIntersectingNodes(node).map((n) => n.id);
    const nodeInternal = reactFlow.getInternalNode(node.id).internals;

    if (intersections.length > 0) {
      const parent = nodes.find(_ => _.type === "group" && intersections.includes(_.id));

      if (parent) {
        const parentInternal = reactFlow.getInternalNode(parent.id).internals;
        
        reactFlow.updateNode(node.id, {
          parentId: parent.id,
          position: { 
            x: nodeInternal.positionAbsolute.x - parentInternal.positionAbsolute.x + (node.measured.width / 2),
            y: nodeInternal.positionAbsolute.y  - parentInternal.positionAbsolute.y
          }
        });
      }
    }else{
      const parent = reactFlow.getNode(node.parentId);
      reactFlow.updateNode(node.id, {
        parentId: undefined,
        position: { 
          x: nodeInternal.positionAbsolute.x + (node.measured.width / 2),
          y: nodeInternal.positionAbsolute.y
        }
      });
    }

  }, [nodes]);

  const onEdgeDoubleClick = useCallback((event, edge) => {    
    reactFlow.setEdges((eds) =>
      eds.map((eds) => {
        if(eds.id === edge.id){
          eds = updateTypeEdges(eds);
        }
        return eds;
      })
    );
  });

  const onEdgesChange = useCallback(
    (changes) => reactFlow.setEdges((eds) => {
      let result = applyEdgeChanges(changes, eds);

      if(props.onEdgesChange !== undefined && Functions.isFunction(props.onEdgesChange)){
          let callback = props.onEdgesChange;
          callback(changes, eds, result);
      }

      if(props.onChanges !== undefined && Functions.isFunction(props.onChanges)){
        let callback = props.onChanges;
        callback("edges", reactFlow.getNodes(), reactFlow.getEdges(), changes);
      }

      return result;

    }),
    [reactFlow],
  );

  const onEdgesDelete = useCallback(
    (deleteds) => {

      if(props.onEdgesDelete !== undefined && Functions.isFunction(props.onEdgesDelete)){
        let callback = props.onEdgesDelete;
        callback(deleteds);
      }      
      return deleteds;
    },
    [nodes, edges],
  );

  const onClickNodeAdd = useCallback(
    () => {
      const idNode = `node.temp.${Math.random(100)}`;;
      
      const newNode = {
        id : idNode,
        position: reactFlow.screenToFlowPosition({
          x: 200,
          y: 100,
        }),
        data: { label: `Node ${idNode}` },
        origin: [0.5, 0.0],
        ...nodeInit
      };

      if(props.onNodesAdd !== undefined && Functions.isFunction(props.onNodesAdd)){
        let callback = props.onNodesAdd;
        callback(newNode);
      }

      reactFlow.setNodes((nds) => nds.concat(newNode));
    },
    [reactFlow],
  );

  const onClickNodeEdit = useCallback(
    () => {        
      reactFlow.setNodes((nds) => 
        nds.map((node) => {          
          
          if(selectedNode?.id === node.id){
            node = {
              ...node,
              data : {
                ...node.data,
                editing : true
              }
            };

            setSelectedNode(node);
          }
    
          return node;
        })
      );
      
    },
    [reactFlow, selectedNode],
  );

  const onClickNodeUpdatePositionEdges = useCallback(() => {  
      reactFlow.setNodes((nds) => 
        nds.map((node) => {
          if(selectedNodes.find(snds => snds.id === node.id)){
            node = updatePostionEdgesConnect(node);
          }
          return node;
        })
      );
  });

  const onClickEdgeUpdateType = useCallback(() => {
    let types = ["straight","step","smoothstep", "default"]; //"bezier" = null

    reactFlow.setEdges((eds) =>
      eds.map((edge) => {
        let type = edge.type !== undefined ? edge.type : "default";

        if(selectedEdges.find(seds => seds.id === edge.id)){
          let index = types.findIndex(tp => tp === type);
          index = index < types.length -1 ? index+1 : 0;
          type = types[index];
        }
        
        return {
          ...edge,
          type: type,
        };

      }));
    },
    [selectedEdges],
  );

  const onNodeContextMenu = useCallback(
    (event, node) => {
      // Prevent native context menu from showing
      event.preventDefault();
 
      // Calculate position of the context menu. We want to make sure it
      // doesn't get positioned off-screen.
      const pane = reactFlowWrapper.current.getBoundingClientRect();

      setNodeMenu({
        id: node.id,
        top: event.clientY < pane.height - 200 && event.clientY,
        left: event.clientX < pane.width - 200 && event.clientX,
        right: event.clientX >= pane.width - 200 && pane.width - event.clientX,
        bottom: event.clientY >= pane.height - 200 && pane.height - event.clientY,
      });
    },
    [setNodeMenu],
  );

  const onPaneContextMenu = useCallback(
    (event, node) => {
      // Prevent native context menu from showing
      event.preventDefault();
 
      // Calculate position of the context menu. We want to make sure it
      // doesn't get positioned off-screen.
      const pane = reactFlowWrapper.current.getBoundingClientRect();
      setPaneMenu({        
        top: event.clientY < pane.height - 200 && event.clientY,
        left: event.clientX < pane.width - 200 && event.clientX,
        right: event.clientX >= pane.width - 200 && pane.width - event.clientX,
        bottom: event.clientY >= pane.height - 200 && pane.height - event.clientY,
      });
    },
    [setPaneMenu],
  );

  const onChangeNodeBackgroundColor = useCallback(
    (e) => {
      let value = e.target.value;

      reactFlow.setNodes((nds) =>
        nds.map((node) => {
          if(selectedNodes.find(snds => snds.id === node.id)){
            return {
              ...node,
              style : {
                ...node.style,
                backgroundColor : value
              }
            };
          }
          return node;
          
        })
      );
    },
    [selectedNodes],
  );

  const onPaneClick = useCallback(() => {
    setNodeMenu(null);
    setPaneMenu(null)
  }, [setNodeMenu, setPaneMenu]);

  const [nodeName, setNodeName] = useState();

  useEffect(() => {
    reactFlow.setNodes((nds) =>
      nds.map((node) => {
        if(selectedNode?.id === node.id){

          node = {
            ...node,
            data: {
              ...node.data,
              label: nodeName,
            },
          };

          setSelectedNode(node);
        }
 
        return node;
      }),
    );
  }, [nodeName, reactFlow]);

  const onChangeProp = useCallback(
    (e, prop) => {
      let value = e.target.value;

      reactFlow.setNodes((nds) =>
        nds.map((node) => {
          if(selectedNode?.id === node.id){
            node = {
              ...node,
              data: {
                ...node.data,
                label: value,
              },
            };
  
            setSelectedNode(node);
          }
   
          return node;          
        })
      );
    },
    [setSelectedNode, selectedNode],
  );

  return (
    <div className="wrapper" ref={reactFlowWrapper}>
      <ReactFlow
        defaultNodes={nodes}
        defaultEdges={edges}
        
        defaultViewport={defaultViewport}
        minZoom={0.2}
        maxZoom={4}
        attributionPosition="bottom-left"
        
        onConnect={onConnect}
        onConnectStart={onConnectStart}
        onConnectEnd={onConnectEnd}        

        nodeTypes={nodeTypes}
        onNodesChange={onNodesChange}
        onNodesDelete={onNodesDelete}
        onNodeDoubleClick={onNodeDoubleClick}
        
        onNodeDragStop={onNodeDragStop}

        edgeTypes={edgeTypes}
        onEdgesChange={onEdgesChange}        
        onEdgesDelete={onEdgesDelete}
        onEdgeDoubleClick={onEdgeDoubleClick}

        onSelectionChange={onSelectionChange}
        
        onPaneClick={onPaneClick}
        onNodeContextMenu={onNodeContextMenu}
        onPaneContextMenu={onPaneContextMenu}
        
        style={{ backgroundColor: "#F7F9FB" }}
        nodeOrigin={nodeOrigin}
        
        fitView
        fitViewOptions={{ padding: 2 }}        
      >

          <Background/>

          {nodeMenu && <NodeContextMenu onClick={onPaneClick} {...nodeMenu} />}
          {paneMenu && <PaneContextMenu nodeInit={nodeInit} onClick={onPaneClick} {...paneMenu} />}

          <Controls position="top-right">
            <ControlButton onClick={onClickNodeAdd} title="Adicionar Nó">
              <IconNodePlus/>
            </ControlButton>

            <ControlButton onClick={onClickNodeEdit} title="Editar Nó" disabled={selectedNodes.length !== 1}>
              <IconPencilSquare/>
            </ControlButton>

            <ControlButton onClick={onClickNodeUpdatePositionEdges} disabled={selectedNodes.length === 0} title="Alterar posição das ligações">
              <IconShuffle/>
            </ControlButton>

            <ControlButton onClick={onClickEdgeUpdateType} disabled={selectedEdges.length === 0}  title="Alterar tipo da ligação">
              <IconGraphUpArrow/>
            </ControlButton>

            <ControlButton disabled={selectedNodes.length === 0} title="Alterar cor de Fundo">
              {selectedNodes.length === 0 ? <PaintBucket/> : <input type="color" onChange={onChangeNodeBackgroundColor} style={{height : "15px", width : "20px"}}/>}
            </ControlButton>
            
          </Controls>

          {config.showMiniMap ? <MiniMap /> : <></>}
          
          <Panel position="top-right" style={{paddingRight : "40px"}}>
          </Panel>

          {props.children !== undefined && props.children}

        </ReactFlow>
      </div>
  );
};

function With(props) {
	return <ReactFlowProvider> <Flow {...props}/> </ReactFlowProvider>
}

export default With