import React from "react";
import { Div, InputCheckBox, P, Small } from "../elements";
import { BaseComponent } from "../base";
import { Functions } from "../util";

export default class Album extends BaseComponent {

    static sizing = {
        sm: "sm",
        default: "",
    }

    constructor(props) {
        super(props);

        this.handleClickCheckItem = this.handleClickCheckItem.bind(this);
        this.handleClickCheckAll = this.handleClickCheckAll.bind(this);
        this.handleClickRow = this.handleClickRow.bind(this);
        this.handleClickMore = this.handleClickMore.bind(this);

        this.state = {
            filter: {
                search: ""
            },
        };
    }

    handleClickRow(e, item, index) {
        var select = this.props.select !== undefined && this.props.select ? true : false;
        if (select) {
            let checked = this.props.selecteds.findIndex(i => (this.getItemValue(i)).toString() === (this.getItemValue(item)).toString()) === -1;
            this.handleClickCheckItem(e, checked, item);
        }
    }

    handleClickCheckItem(e, checked, item) {

        var selecteds = this.props.selecteds.map(a => Object.assign({}, a));

        this.setState(state => {
            if (checked) {
                selecteds.push(item);
            } else {
                let index = selecteds.findIndex(i => (this.getItemValue(i)).toString() === (this.getItemValue(item)).toString());
                selecteds.splice(index, 1);
            }
            return state;
        }, () => {
            if (this.props.onSelect !== undefined) {
                this.props.onSelect(selecteds);
            }
        });
    }

    handleClickCheckAll(e, checked) {
        var selecteds = this.props.selecteds.map(a => Object.assign({}, a));

        this.setState(state => {
            if (checked) {
                this.getData().filter(item => !this.isDisabled(item)).forEach(item => {
                    if (selecteds && selecteds.findIndex(i => (this.getItemValue(i)).toString() === (this.getItemValue(item)).toString()) === -1) {
                        selecteds.push(item);
                    }
                }, this);

            } else {
                this.getData().forEach(item => {
                    if (selecteds && selecteds.findIndex(i => (this.getItemValue(i)).toString() === (this.getItemValue(item)).toString()) !== -1) {
                        let index = selecteds.findIndex(i => (this.getItemValue(i)).toString() === (this.getItemValue(item)).toString());
                        selecteds.splice(index, 1);
                    }
                }, this);
            }

            return state;
        }, () => {
            if (this.props.onSelect !== undefined) {
                this.props.onSelect(selecteds);
            }
        });
    }

    handleClickMore() {
        if (this.props.onMore !== undefined) {
            this.props.onMore();
        }
    }

    getData() {
        if (this.state.filter !== undefined && this.state.filter.search !== undefined && this.state.filter.search.trim().length > 0 && this.props.data?.length > 0) {
            return this.props.data.filter(f =>
                Functions.searchTextInObject(f, Object.keys(this.props.data[0]), this.state.filter.search.trim())
            );
        } else {
            return this.props.data;
        }
    }

    getColSize(props) {
        var colValue = false;
        var colSize = false;

        if (props.col) {
            colValue = "";
            colSize = props.col;
        } else if (props.xs) {
            colValue = props.xs;
            colSize = "xs";
        } else if (props.sm) {
            colValue = props.sm;
            colSize = "sm";
        } else if (props.md) {
            colValue = props.md;
            colSize = "md";
        } else if (props.lg) {
            colValue = props.lg;
            colSize = "lg";
        } else if (props.xl) {
            colValue = props.xl;
            colSize = "xl";
        } else if (props.xxl) {
            colValue = props.xxl;
            colSize = "xxl";
        }

        if (colSize || colValue) {
            return "col" + (colSize ? "-" + colSize : "") + (colValue > 0 ? "-" + colValue : "");
        }

        return "";
    }

    getItemValue(item) {
        if (this.props.getItemValue !== undefined) {
            return this.props.getItemValue(item)
        } else {
            if (this.props.data.length > 0) {
                let keys = Object.keys(this.props.data[0]);
                if (keys.length > 0 && item[keys[0]] !== undefined) {
                    return item[keys[0]]
                }
            }
        }
        return false;
    }

    isAllCheckeds(data, selecteds) {
        data = data?.filter(i => !this.isDisabled(i));

        if (data.length === 0) {
            return false;
        }

        return data.filter(i => !selecteds?.find(x => (this.getItemValue(i))?.toString() === (this.getItemValue(x))?.toString())).length === 0;
    }

    isDisabled(item) {
        if (this.props.isDisabled !== undefined) {
            return this.props.isDisabled(item)
        }
        return false;
    }

    getButtons(children, item, index) {
        return React.Children.map(children, (child) => {
            if (!React.isValidElement(child)) return child;

            let obj = {
                ...child.props,
                onClick: e => child.props.onClick !== undefined ? child.props.onClick(e, item) : () => { },
                item: item,
                children: this.getButtons(child.props.children, item)
            };

            if (child.props.disabled instanceof Function) {
                obj.disabled = child.props.disabled(item, index);
            }

            if (child.props.show instanceof Function) {
                obj.show = child.props.show(item, index);
            }

            if (child.props.to instanceof Function) {
                obj.to = child.props.to(item, index);
            }

            if (child.props.href instanceof Function) {
                obj.href = child.props.href(item, index);
            }

            if (child.props.icon instanceof Function) {
                obj.icon = child.props.icon(item, index);
            }

            return React.cloneElement(child, obj);
        })
    };

    render() {
        if (this.props.show !== undefined && !Boolean(this.props.show)) {
            return (<></>);
        }

        const props = Object.assign({}, this.props);
        props.className = `${props.className !== undefined ? props.className : ""}`;

        delete props.show;

        var select = this.props.select !== undefined && this.props.select ? true : false;
        var data = this.getData();
        var isAllCheckeds = this.isAllCheckeds(data, this.props.selecteds);

        delete props.onDoubleClickRow;

        return (
            <Div>
                {select ?
                    <Div className="my-3 d-flex justify-content-end">
                        <InputCheckBox
                            onChange={(e, checked) => this.handleClickCheckAll(e, checked)}
                            disabled={data.filter(i => !this.isDisabled(i)).length === 0}
                            value={isAllCheckeds}
                            label={`${this.props.selecteds?.length} de ` + data.length + " Registro(s)"}
                        />
                    </Div> : <></>}

                <Div className="row row-cols-1 row-cols-sm-2 row-cols-md-4 g-3">
                    {data.map(item => {
                        var isChecked = this.props.selecteds !== undefined ? this.props.selecteds.findIndex(i => parseInt(i.idAnsPerfilFoto) === parseInt(item.idAnsPerfilFoto)) !== -1 : false;
                        var isDisabled = this.isDisabled(item);

                        return (
                            <Div className="col">
                                <Div className={`card shadow-sm h-100 ${isChecked ? "border-primary border border-2" : ""}`}>
                                    {this.props.image && <img className="bd-placeholder-img card-img-top img-fluid" src={this.props.image(item)} alt={this.props.title(item)} onClick={select && !isDisabled ? (e) => this.handleClickRow(e, item) : undefined} onDoubleClick={e => this.props?.onDoubleClickRow && !isDisabled ? this.props?.onDoubleClickRow(e, item) : () => { }} />}

                                    <Div className={`card-body ${isChecked ? "bg-warning bg-opacity-10" : ""}`}>
                                        <P className="card-text">
                                            <Div className="text-center">
                                                {this.props.title && <Div className="text-center">{this.props.title(item)}</Div>}
                                                {this.props.description && <Div className="text-center fs-7 f"><Small>{this.props.description(item)}</Small></Div>}
                                            </Div>
                                        </P>
                                    </Div>

                                    <Div className="card-footer">
                                        <Div className="d-flex justify-content-between align-items-center">
                                            <Div>
                                                {select && <InputCheckBox value={isChecked} onChange={(e, checked) => this.handleClickCheckItem(e, checked, item)} disabled={isDisabled} />}
                                            </Div>
                                            {this.props.buttons &&
                                                <Div className="btn-group">
                                                    {this.props.buttons(item)}
                                                </Div>
                                            }
                                        </Div>
                                    </Div>
                                </Div>
                            </Div>
                        );
                    })}

                </Div>

            </Div>
        );
    }
}