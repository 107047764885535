//#NO-CHANGE-FILE#

import Http from "../controllers/Http";

export default class FaturaNotaFiscalService {

	static urlBase = "/faturaNotaFiscal/";

	static alterarStatus(data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(FaturaNotaFiscalService.urlBase + 'alterarStatus',  data)
			.then(fnSucess)
			.catch(fnError);
	}

	static enviar(data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(FaturaNotaFiscalService.urlBase + 'enviar',  data)
			.then(fnSucess)
			.catch(fnError);
	}

	static estornar(data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(FaturaNotaFiscalService.urlBase + 'estornar',  data)
			.then(fnSucess)
			.catch(fnError);
	}

	static ignorar(data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(FaturaNotaFiscalService.urlBase + 'ignorar',  data)
			.then(fnSucess)
			.catch(fnError);
	}

	static reativar(data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(FaturaNotaFiscalService.urlBase + 'reativar',  data)
			.then(fnSucess)
			.catch(fnError);
	}

	static substituir(data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(FaturaNotaFiscalService.urlBase + 'substituir',  data)
			.then(fnSucess)
			.catch(fnError);
	}

	static getForCombo(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(FaturaNotaFiscalService.urlBase + 'forCombo', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static list(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(FaturaNotaFiscalService.urlBase + 'list', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneById(id, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.get(FaturaNotaFiscalService.urlBase + id)
			.then(fnSucess)
			.catch(fnError);
	}

	static create(data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(FaturaNotaFiscalService.urlBase, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static update(id, data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.put(FaturaNotaFiscalService.urlBase + id, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static delete(id, fnSucess = () => { }, fnError = () => { }) {
		if(Number.isInteger(id)){
			Http.getInstanceAppJson()
				.delete(FaturaNotaFiscalService.urlBase + id)
				.then(fnSucess)
				.catch(fnError);
		}else{
			Http.getInstanceAppJson()
			.post(FaturaNotaFiscalService.urlBase + 'delete', id)
			.then(fnSucess)
			.catch(fnError);
		}
	}

	static save(data, fnSucess = () => { }, fnError = () => { }) {
		if (data.idFaturaNotaFiscal) {
			this.update(data.idFaturaNotaFiscal, data, fnSucess, fnError);
		} else {
			this.create(data, fnSucess, fnError);
		}
	}

	static getAllByFatura(fatura, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(FaturaNotaFiscalService.urlBase + 'fatura', fatura)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByFatura(fatura, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(FaturaNotaFiscalService.urlBase + 'fatura/' + fatura.idFatura)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByStatusNotaFiscal(statusNotaFiscal, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(FaturaNotaFiscalService.urlBase + 'statusNotaFiscal', statusNotaFiscal)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByStatusNotaFiscal(statusNotaFiscal, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(FaturaNotaFiscalService.urlBase + 'statusNotaFiscal/' + statusNotaFiscal.idStatusNotaFiscal)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByTipoProduto(tipoProduto, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(FaturaNotaFiscalService.urlBase + 'tipoProduto', tipoProduto)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByTipoProduto(tipoProduto, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(FaturaNotaFiscalService.urlBase + 'tipoProduto/' + tipoProduto.idTipoProduto)
			.then(fnSucess)
			.catch(fnError);
	}
}