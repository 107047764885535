import { Component } from "react";
import {BtnButton, Button} from "../../siht/elements";
import { IconEdit } from "../../icons";

export default class BtnEdit extends Component {

    render() {
        if(this.props.show !== undefined && !Boolean(this.props.show)){
            return (<></>);
        }

        const props = Object.assign({}, this.props);
        delete props.show;
        props.color = Button.color.outlinePrimary;
        props.className = `${props.className !== undefined ? props.className : ""} text-nowrap`;
        props.title="Editar Registro";
        
        return (
            <BtnButton {...props}>
                <IconEdit/> {this.props.children}
            </BtnButton>
        );
    }
}