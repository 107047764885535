import React from "react";
import { Route, Routes } from "react-router-dom";

import Home from "../view/Home/Home";
import Layout from "../view/Layout";
import RequireAuth from "./RequireAuth";

import FormLogin from "../view/Login/FormLogin";
import Painel from "../view/Painel/Painel";
import ListMapaLocalizacao from "../view/Mapa/ListMapaLocalizacao";
import FormFaturar from "../view/Faturar/FormFaturar";
import FormAcordo from "../view/Fatura/FormAcordo";
import ListConfiguracao from "../view/DadoValor/ListConfiguracao";
import FormPagarEscolha from "../view/Pagamento/FormPagarEscolha";
import FormPagarBoleto from "../view/Pagamento/FormPagarBoleto";
import FormPagarCartaoCredito from "../view/Pagamento/FormPagarCartaoCredito";
import FormPagarPix from "../view/Pagamento/FormPagarPix";
import ListContaExtrato from "../view/ContaExtrato/ListContaExtrato";
import ListUsuarioSessao from "../view/UsuarioToken/ListUsuarioSessao";
import WorkFlow from "../view/Teste/WorkFlow";



/*[BEGIN_IMPORT_ALL]*/

/*[BEGIN_IMPORT_FUSO_HORARIO]*/
import FormFusoHorario from "../view/FusoHorario/FormFusoHorario";
import ListFusoHorario from "../view/FusoHorario/ListFusoHorario";
/*[END_IMPORT_FUSO_HORARIO]*/

/*[BEGIN_IMPORT_MARCA]*/
import FormMarca from "../view/Marca/FormMarca";
import ListMarca from "../view/Marca/ListMarca";
/*[END_IMPORT_MARCA]*/

/*[BEGIN_IMPORT_MODELO]*/
import FormModelo from "../view/Modelo/FormModelo";
import ListModelo from "../view/Modelo/ListModelo";
/*[END_IMPORT_MODELO]*/

/*[BEGIN_IMPORT_PAIS]*/
import FormPais from "../view/Pais/FormPais";
import ListPais from "../view/Pais/ListPais";
/*[END_IMPORT_PAIS]*/

/*[BEGIN_IMPORT_ESTADO]*/
import FormEstado from "../view/Estado/FormEstado";
import ListEstado from "../view/Estado/ListEstado";
/*[END_IMPORT_ESTADO]*/

/*[BEGIN_IMPORT_TIPO_EMAIL]*/
import FormTipoEmail from "../view/TipoEmail/FormTipoEmail";
import ListTipoEmail from "../view/TipoEmail/ListTipoEmail";
/*[END_IMPORT_TIPO_EMAIL]*/

/*[BEGIN_IMPORT_UNIDADE]*/
import FormUnidade from "../view/Unidade/FormUnidade";
import ListUnidade from "../view/Unidade/ListUnidade";
/*[END_IMPORT_UNIDADE]*/

/*[BEGIN_IMPORT_USUARIO]*/
import FormUsuario from "../view/Usuario/FormUsuario";
import ListUsuario from "../view/Usuario/ListUsuario";
/*[END_IMPORT_USUARIO]*/

/*[BEGIN_IMPORT_PESSOA]*/
import FormPessoa from "../view/Pessoa/FormPessoa";
import ListPessoa from "../view/Pessoa/ListPessoa";
/*[END_IMPORT_PESSOA]*/

/*[BEGIN_IMPORT_EQUIPE]*/
import FormEquipe from "../view/Equipe/FormEquipe";
import ListEquipe from "../view/Equipe/ListEquipe";
/*[END_IMPORT_EQUIPE]*/

/*[BEGIN_IMPORT_USUARIO_UNIDADE]*/
import FormUsuarioUnidade from "../view/UsuarioUnidade/FormUsuarioUnidade";
import ListUsuarioUnidade from "../view/UsuarioUnidade/ListUsuarioUnidade";
/*[END_IMPORT_USUARIO_UNIDADE]*/

/*[BEGIN_IMPORT_GRUPO]*/
import FormGrupo from "../view/Grupo/FormGrupo";
import ListGrupo from "../view/Grupo/ListGrupo";
/*[END_IMPORT_GRUPO]*/

/*[BEGIN_IMPORT_USUARIO_GRUPO]*/
import FormUsuarioGrupo from "../view/UsuarioGrupo/FormUsuarioGrupo";
import ListUsuarioGrupo from "../view/UsuarioGrupo/ListUsuarioGrupo";
/*[END_IMPORT_USUARIO_GRUPO]*/

/*[BEGIN_IMPORT_CIDADE]*/
import FormCidade from "../view/Cidade/FormCidade";
import ListCidade from "../view/Cidade/ListCidade";
/*[END_IMPORT_CIDADE]*/

/*[BEGIN_IMPORT_MODULO]*/
import FormModulo from "../view/Modulo/FormModulo";
import ListModulo from "../view/Modulo/ListModulo";
/*[END_IMPORT_MODULO]*/

/*[BEGIN_IMPORT_GRUPO_UNIDADE]*/
import FormGrupoUnidade from "../view/GrupoUnidade/FormGrupoUnidade";
import ListGrupoUnidade from "../view/GrupoUnidade/ListGrupoUnidade";
/*[END_IMPORT_GRUPO_UNIDADE]*/

/*[BEGIN_IMPORT_GRUPO_MODULO]*/
import FormGrupoModulo from "../view/GrupoModulo/FormGrupoModulo";
import ListGrupoModulo from "../view/GrupoModulo/ListGrupoModulo";
/*[END_IMPORT_GRUPO_MODULO]*/

/*[BEGIN_IMPORT_TIPO_ORDEM]*/
import FormTipoOrdem from "../view/TipoOrdem/FormTipoOrdem";
import ListTipoOrdem from "../view/TipoOrdem/ListTipoOrdem";
/*[END_IMPORT_TIPO_ORDEM]*/

/*[BEGIN_IMPORT_GRUPO_TIPO_ORDEM]*/
import FormGrupoTipoOrdem from "../view/GrupoTipoOrdem/FormGrupoTipoOrdem";
import ListGrupoTipoOrdem from "../view/GrupoTipoOrdem/ListGrupoTipoOrdem";
/*[END_IMPORT_GRUPO_TIPO_ORDEM]*/

/*[BEGIN_IMPORT_SUBMODULO]*/
import FormSubmodulo from "../view/Submodulo/FormSubmodulo";
import ListSubmodulo from "../view/Submodulo/ListSubmodulo";
/*[END_IMPORT_SUBMODULO]*/

/*[BEGIN_IMPORT_TIPO_PESSOA]*/
import FormTipoPessoa from "../view/TipoPessoa/FormTipoPessoa";
import ListTipoPessoa from "../view/TipoPessoa/ListTipoPessoa";
/*[END_IMPORT_TIPO_PESSOA]*/

/*[BEGIN_IMPORT_USUARIO_EQUIPE]*/
import FormUsuarioEquipe from "../view/UsuarioEquipe/FormUsuarioEquipe";
import ListUsuarioEquipe from "../view/UsuarioEquipe/ListUsuarioEquipe";
/*[END_IMPORT_USUARIO_EQUIPE]*/

/*[BEGIN_IMPORT_TIPO_PRECO]*/
import FormTipoPreco from "../view/TipoPreco/FormTipoPreco";
import ListTipoPreco from "../view/TipoPreco/ListTipoPreco";
/*[END_IMPORT_TIPO_PRECO]*/

/*[BEGIN_IMPORT_TIPO_CONTATO]*/
import FormTipoContato from "../view/TipoContato/FormTipoContato";
import ListTipoContato from "../view/TipoContato/ListTipoContato";
/*[END_IMPORT_TIPO_CONTATO]*/

/*[BEGIN_IMPORT_TIPO_PRODUTO]*/
import FormTipoProduto from "../view/TipoProduto/FormTipoProduto";
import ListTipoProduto from "../view/TipoProduto/ListTipoProduto";
/*[END_IMPORT_TIPO_PRODUTO]*/

/*[BEGIN_IMPORT_TIPO_TELEFONE]*/
import FormTipoTelefone from "../view/TipoTelefone/FormTipoTelefone";
import ListTipoTelefone from "../view/TipoTelefone/ListTipoTelefone";
/*[END_IMPORT_TIPO_TELEFONE]*/

/*[BEGIN_IMPORT_TIPO_ENDERECO]*/
import FormTipoEndereco from "../view/TipoEndereco/FormTipoEndereco";
import ListTipoEndereco from "../view/TipoEndereco/ListTipoEndereco";
/*[END_IMPORT_TIPO_ENDERECO]*/

/*[BEGIN_IMPORT_TIPO_PAGAMENTO]*/
import FormTipoPagamento from "../view/TipoPagamento/FormTipoPagamento";
import ListTipoPagamento from "../view/TipoPagamento/ListTipoPagamento";
/*[END_IMPORT_TIPO_PAGAMENTO]*/

/*[BEGIN_IMPORT_TIPO_TRANSACAO]*/
import FormTipoTransacao from "../view/TipoTransacao/FormTipoTransacao";
import ListTipoTransacao from "../view/TipoTransacao/ListTipoTransacao";
/*[END_IMPORT_TIPO_TRANSACAO]*/

/*[BEGIN_IMPORT_UNIDADE_LOCAL]*/
import FormUnidadeLocal from "../view/UnidadeLocal/FormUnidadeLocal";
import ListUnidadeLocal from "../view/UnidadeLocal/ListUnidadeLocal";
/*[END_IMPORT_UNIDADE_LOCAL]*/

/*[BEGIN_IMPORT_PESSOA_EMAIL]*/
import FormPessoaEmail from "../view/PessoaEmail/FormPessoaEmail";
import ListPessoaEmail from "../view/PessoaEmail/ListPessoaEmail";
/*[END_IMPORT_PESSOA_EMAIL]*/

/*[BEGIN_IMPORT_PESSOA_TELEFONE]*/
import FormPessoaTelefone from "../view/PessoaTelefone/FormPessoaTelefone";
import ListPessoaTelefone from "../view/PessoaTelefone/ListPessoaTelefone";
/*[END_IMPORT_PESSOA_TELEFONE]*/

/*[BEGIN_IMPORT_ENDERECO]*/
import FormEndereco from "../view/Endereco/FormEndereco";
import ListEndereco from "../view/Endereco/ListEndereco";
/*[END_IMPORT_ENDERECO]*/

/*[BEGIN_IMPORT_PESSOA_ENDERECO]*/
import FormPessoaEndereco from "../view/PessoaEndereco/FormPessoaEndereco";
import ListPessoaEndereco from "../view/PessoaEndereco/ListPessoaEndereco";
/*[END_IMPORT_PESSOA_ENDERECO]*/

/*[BEGIN_IMPORT_PRODUTO]*/
import FormProduto from "../view/Produto/FormProduto";
import ListProduto from "../view/Produto/ListProduto";
/*[END_IMPORT_PRODUTO]*/

/*[BEGIN_IMPORT_GRUPO_PRODUTO]*/
import FormGrupoProduto from "../view/GrupoProduto/FormGrupoProduto";
import ListGrupoProduto from "../view/GrupoProduto/ListGrupoProduto";
/*[END_IMPORT_GRUPO_PRODUTO]*/

/*[BEGIN_IMPORT_CLASSIFICACAO_PRODUTO]*/
import FormClassificacaoProduto from "../view/ClassificacaoProduto/FormClassificacaoProduto";
import ListClassificacaoProduto from "../view/ClassificacaoProduto/ListClassificacaoProduto";
/*[END_IMPORT_CLASSIFICACAO_PRODUTO]*/

/*[BEGIN_IMPORT_UNIDADE_MEDIDA]*/
import FormUnidadeMedida from "../view/UnidadeMedida/FormUnidadeMedida";
import ListUnidadeMedida from "../view/UnidadeMedida/ListUnidadeMedida";
/*[END_IMPORT_UNIDADE_MEDIDA]*/

/*[BEGIN_IMPORT_PRODUTO_ESTOQUE]*/
import FormProdutoEstoque from "../view/ProdutoEstoque/FormProdutoEstoque";
import ListProdutoEstoque from "../view/ProdutoEstoque/ListProdutoEstoque";
/*[END_IMPORT_PRODUTO_ESTOQUE]*/

/*[BEGIN_IMPORT_ORDEM]*/
import FormOrdem from "../view/Ordem/FormOrdem";
import ListOrdem from "../view/Ordem/ListOrdem";
/*[END_IMPORT_ORDEM]*/

/*[BEGIN_IMPORT_STATUS_ORDEM]*/
import FormStatusOrdem from "../view/StatusOrdem/FormStatusOrdem";
import ListStatusOrdem from "../view/StatusOrdem/ListStatusOrdem";
/*[END_IMPORT_STATUS_ORDEM]*/

/*[BEGIN_IMPORT_ORDEM_ITEM]*/
import FormOrdemItem from "../view/OrdemItem/FormOrdemItem";
import ListOrdemItem from "../view/OrdemItem/ListOrdemItem";
/*[END_IMPORT_ORDEM_ITEM]*/

/*[BEGIN_IMPORT_ORDEM_COMENTARIO]*/
import FormOrdemComentario from "../view/OrdemComentario/FormOrdemComentario";
import ListOrdemComentario from "../view/OrdemComentario/ListOrdemComentario";
/*[END_IMPORT_ORDEM_COMENTARIO]*/

/*[BEGIN_IMPORT_PESSOA_TELEFONE_TIPO_CONTATO]*/
import FormPessoaTelefoneTipoContato from "../view/PessoaTelefoneTipoContato/FormPessoaTelefoneTipoContato";
import ListPessoaTelefoneTipoContato from "../view/PessoaTelefoneTipoContato/ListPessoaTelefoneTipoContato";
/*[END_IMPORT_PESSOA_TELEFONE_TIPO_CONTATO]*/

/*[BEGIN_IMPORT_USUARIO_MODULO]*/
import FormUsuarioModulo from "../view/UsuarioModulo/FormUsuarioModulo";
import ListUsuarioModulo from "../view/UsuarioModulo/ListUsuarioModulo";
/*[END_IMPORT_USUARIO_MODULO]*/

/*[BEGIN_IMPORT_GRUPO_TIPO_PRECO]*/
import FormGrupoTipoPreco from "../view/GrupoTipoPreco/FormGrupoTipoPreco";
import ListGrupoTipoPreco from "../view/GrupoTipoPreco/ListGrupoTipoPreco";
/*[END_IMPORT_GRUPO_TIPO_PRECO]*/

/*[BEGIN_IMPORT_USUARIO_TIPO_PRECO]*/
import FormUsuarioTipoPreco from "../view/UsuarioTipoPreco/FormUsuarioTipoPreco";
import ListUsuarioTipoPreco from "../view/UsuarioTipoPreco/ListUsuarioTipoPreco";
/*[END_IMPORT_USUARIO_TIPO_PRECO]*/

/*[BEGIN_IMPORT_USUARIO_TIPO_ORDEM]*/
import FormUsuarioTipoOrdem from "../view/UsuarioTipoOrdem/FormUsuarioTipoOrdem";
import ListUsuarioTipoOrdem from "../view/UsuarioTipoOrdem/ListUsuarioTipoOrdem";
/*[END_IMPORT_USUARIO_TIPO_ORDEM]*/

/*[BEGIN_IMPORT_ANS_PERFIL]*/
import FormAnsPerfil from "../view/AnsPerfil/FormAnsPerfil";
import ListAnsPerfil from "../view/AnsPerfil/ListAnsPerfil";
/*[END_IMPORT_ANS_PERFIL]*/

/*[BEGIN_IMPORT_ANS_MATERIAL]*/
import FormAnsMaterial from "../view/AnsMaterial/FormAnsMaterial";
import ListAnsMaterial from "../view/AnsMaterial/ListAnsMaterial";
/*[END_IMPORT_ANS_MATERIAL]*/

/*[BEGIN_IMPORT_ANS_PERFIL_MATERIAL]*/
import FormAnsPerfilMaterial from "../view/AnsPerfilMaterial/FormAnsPerfilMaterial";
import ListAnsPerfilMaterial from "../view/AnsPerfilMaterial/ListAnsPerfilMaterial";
/*[END_IMPORT_ANS_PERFIL_MATERIAL]*/

/*[BEGIN_IMPORT_ANS_CATEGORIA_MATERIAL]*/
import FormAnsCategoriaMaterial from "../view/AnsCategoriaMaterial/FormAnsCategoriaMaterial";
import ListAnsCategoriaMaterial from "../view/AnsCategoriaMaterial/ListAnsCategoriaMaterial";
/*[END_IMPORT_ANS_CATEGORIA_MATERIAL]*/

/*[BEGIN_IMPORT_USUARIO_GRUPO_UNIDADE]*/
import FormUsuarioGrupoUnidade from "../view/UsuarioGrupoUnidade/FormUsuarioGrupoUnidade";
import ListUsuarioGrupoUnidade from "../view/UsuarioGrupoUnidade/ListUsuarioGrupoUnidade";
/*[END_IMPORT_USUARIO_GRUPO_UNIDADE]*/

/*[BEGIN_IMPORT_USUARIO_GRUPO_MODULO]*/
import FormUsuarioGrupoModulo from "../view/UsuarioGrupoModulo/FormUsuarioGrupoModulo";
import ListUsuarioGrupoModulo from "../view/UsuarioGrupoModulo/ListUsuarioGrupoModulo";
/*[END_IMPORT_USUARIO_GRUPO_MODULO]*/

/*[BEGIN_IMPORT_USUARIO_GRUPO_TIPO_PRECO]*/
import FormUsuarioGrupoTipoPreco from "../view/UsuarioGrupoTipoPreco/FormUsuarioGrupoTipoPreco";
import ListUsuarioGrupoTipoPreco from "../view/UsuarioGrupoTipoPreco/ListUsuarioGrupoTipoPreco";
/*[END_IMPORT_USUARIO_GRUPO_TIPO_PRECO]*/

/*[BEGIN_IMPORT_USUARIO_GRUPO_TIPO_ORDEM]*/
import FormUsuarioGrupoTipoOrdem from "../view/UsuarioGrupoTipoOrdem/FormUsuarioGrupoTipoOrdem";
import ListUsuarioGrupoTipoOrdem from "../view/UsuarioGrupoTipoOrdem/ListUsuarioGrupoTipoOrdem";
/*[END_IMPORT_USUARIO_GRUPO_TIPO_ORDEM]*/

/*[BEGIN_IMPORT_USUARIO_GRUPO_EQUIPE]*/
import FormUsuarioGrupoEquipe from "../view/UsuarioGrupoEquipe/FormUsuarioGrupoEquipe";
import ListUsuarioGrupoEquipe from "../view/UsuarioGrupoEquipe/ListUsuarioGrupoEquipe";
/*[END_IMPORT_USUARIO_GRUPO_EQUIPE]*/

/*[BEGIN_IMPORT_EMPRESA]*/
import FormEmpresa from "../view/Empresa/FormEmpresa";
import ListEmpresa from "../view/Empresa/ListEmpresa";
/*[END_IMPORT_EMPRESA]*/

/*[BEGIN_IMPORT_PRODUTO_PRECO]*/
import FormProdutoPreco from "../view/ProdutoPreco/FormProdutoPreco";
import ListProdutoPreco from "../view/ProdutoPreco/ListProdutoPreco";
/*[END_IMPORT_PRODUTO_PRECO]*/

/*[BEGIN_IMPORT_ANS_COR]*/
import FormAnsCor from "../view/AnsCor/FormAnsCor";
import ListAnsCor from "../view/AnsCor/ListAnsCor";
/*[END_IMPORT_ANS_COR]*/

/*[BEGIN_IMPORT_ANS_STATUS]*/
import FormAnsStatus from "../view/AnsStatus/FormAnsStatus";
import ListAnsStatus from "../view/AnsStatus/ListAnsStatus";
/*[END_IMPORT_ANS_STATUS]*/

/*[BEGIN_IMPORT_ANS_FERRAMENTA]*/
import FormAnsFerramenta from "../view/AnsFerramenta/FormAnsFerramenta";
import ListAnsFerramenta from "../view/AnsFerramenta/ListAnsFerramenta";
/*[END_IMPORT_ANS_FERRAMENTA]*/

/*[BEGIN_IMPORT_ANS_PERFIL_CLASSIFICACAO]*/
import FormAnsPerfilClassificacao from "../view/AnsPerfilClassificacao/FormAnsPerfilClassificacao";
import ListAnsPerfilClassificacao from "../view/AnsPerfilClassificacao/ListAnsPerfilClassificacao";
/*[END_IMPORT_ANS_PERFIL_CLASSIFICACAO]*/

/*[BEGIN_IMPORT_ANS_PERFIL_GOLPE]*/
import FormAnsPerfilGolpe from "../view/AnsPerfilGolpe/FormAnsPerfilGolpe";
import ListAnsPerfilGolpe from "../view/AnsPerfilGolpe/ListAnsPerfilGolpe";
/*[END_IMPORT_ANS_PERFIL_GOLPE]*/

/*[BEGIN_IMPORT_ANS_PERFIL_AVANCO]*/
import FormAnsPerfilAvanco from "../view/AnsPerfilAvanco/FormAnsPerfilAvanco";
import ListAnsPerfilAvanco from "../view/AnsPerfilAvanco/ListAnsPerfilAvanco";
/*[END_IMPORT_ANS_PERFIL_AVANCO]*/

/*[BEGIN_IMPORT_ANS_PERFIL_REVESTIMENTO]*/
import FormAnsPerfilRevestimento from "../view/AnsPerfilRevestimento/FormAnsPerfilRevestimento";
import ListAnsPerfilRevestimento from "../view/AnsPerfilRevestimento/ListAnsPerfilRevestimento";
/*[END_IMPORT_ANS_PERFIL_REVESTIMENTO]*/

/*[BEGIN_IMPORT_CARGO]*/
import FormCargo from "../view/Cargo/FormCargo";
import ListCargo from "../view/Cargo/ListCargo";
/*[END_IMPORT_CARGO]*/

/*[BEGIN_IMPORT_ANS_PERFIL_PESSOA]*/
import FormAnsPerfilPessoa from "../view/AnsPerfilPessoa/FormAnsPerfilPessoa";
import ListAnsPerfilPessoa from "../view/AnsPerfilPessoa/ListAnsPerfilPessoa";
/*[END_IMPORT_ANS_PERFIL_PESSOA]*/

/*[BEGIN_IMPORT_FATURA]*/
import FormFatura from "../view/Fatura/FormFatura";
import ListFatura from "../view/Fatura/ListFatura";
/*[END_IMPORT_FATURA]*/

/*[BEGIN_IMPORT_FATURA_COBRANCA]*/
import FormFaturaCobranca from "../view/FaturaCobranca/FormFaturaCobranca";
import ListFaturaCobranca from "../view/FaturaCobranca/ListFaturaCobranca";
/*[END_IMPORT_FATURA_COBRANCA]*/

/*[BEGIN_IMPORT_FATURA_ORDEM]*/
import FormFaturaOrdem from "../view/FaturaOrdem/FormFaturaOrdem";
import ListFaturaOrdem from "../view/FaturaOrdem/ListFaturaOrdem";
/*[END_IMPORT_FATURA_ORDEM]*/

/*[BEGIN_IMPORT_FATURA_ENDERECO]*/
import FormFaturaEndereco from "../view/FaturaEndereco/FormFaturaEndereco";
import ListFaturaEndereco from "../view/FaturaEndereco/ListFaturaEndereco";
/*[END_IMPORT_FATURA_ENDERECO]*/

/*[BEGIN_IMPORT_FATURA_COBRANCA_TIPO_PAGAMENTO]*/
import FormFaturaCobrancaTipoPagamento from "../view/FaturaCobrancaTipoPagamento/FormFaturaCobrancaTipoPagamento";
import ListFaturaCobrancaTipoPagamento from "../view/FaturaCobrancaTipoPagamento/ListFaturaCobrancaTipoPagamento";
/*[END_IMPORT_FATURA_COBRANCA_TIPO_PAGAMENTO]*/

/*[BEGIN_IMPORT_FATURA_NOTA_FISCAL]*/
import FormFaturaNotaFiscal from "../view/FaturaNotaFiscal/FormFaturaNotaFiscal";
import ListFaturaNotaFiscal from "../view/FaturaNotaFiscal/ListFaturaNotaFiscal";
/*[END_IMPORT_FATURA_NOTA_FISCAL]*/

/*[BEGIN_IMPORT_ORDEM_HISTORICO]*/
import FormOrdemHistorico from "../view/OrdemHistorico/FormOrdemHistorico";
import ListOrdemHistorico from "../view/OrdemHistorico/ListOrdemHistorico";
/*[END_IMPORT_ORDEM_HISTORICO]*/

/*[BEGIN_IMPORT_RECURSO_ORDEM]*/
import FormRecursoOrdem from "../view/RecursoOrdem/FormRecursoOrdem";
import ListRecursoOrdem from "../view/RecursoOrdem/ListRecursoOrdem";
/*[END_IMPORT_RECURSO_ORDEM]*/

/*[BEGIN_IMPORT_TIPO_ORDEM_RECURSO_ORDEM]*/
import FormTipoOrdemRecursoOrdem from "../view/TipoOrdemRecursoOrdem/FormTipoOrdemRecursoOrdem";
import ListTipoOrdemRecursoOrdem from "../view/TipoOrdemRecursoOrdem/ListTipoOrdemRecursoOrdem";
/*[END_IMPORT_TIPO_ORDEM_RECURSO_ORDEM]*/

/*[BEGIN_IMPORT_STATUS_FATURA]*/
import FormStatusFatura from "../view/StatusFatura/FormStatusFatura";
import ListStatusFatura from "../view/StatusFatura/ListStatusFatura";
/*[END_IMPORT_STATUS_FATURA]*/

/*[BEGIN_IMPORT_BANCO]*/
import FormBanco from "../view/Banco/FormBanco";
import ListBanco from "../view/Banco/ListBanco";
/*[END_IMPORT_BANCO]*/

/*[BEGIN_IMPORT_CONTA]*/
import FormConta from "../view/Conta/FormConta";
import ListConta from "../view/Conta/ListConta";
/*[END_IMPORT_CONTA]*/

/*[BEGIN_IMPORT_CONTA_TIPO_PAGAMENTO]*/
import FormContaTipoPagamento from "../view/ContaTipoPagamento/FormContaTipoPagamento";
import ListContaTipoPagamento from "../view/ContaTipoPagamento/ListContaTipoPagamento";
/*[END_IMPORT_CONTA_TIPO_PAGAMENTO]*/

/*[BEGIN_IMPORT_TIPO_BAIXA]*/
import FormTipoBaixa from "../view/TipoBaixa/FormTipoBaixa";
import ListTipoBaixa from "../view/TipoBaixa/ListTipoBaixa";
/*[END_IMPORT_TIPO_BAIXA]*/

/*[BEGIN_IMPORT_FATURA_COBRANCA_BAIXA]*/
import FormFaturaCobrancaBaixa from "../view/FaturaCobrancaBaixa/FormFaturaCobrancaBaixa";
import ListFaturaCobrancaBaixa from "../view/FaturaCobrancaBaixa/ListFaturaCobrancaBaixa";
/*[END_IMPORT_FATURA_COBRANCA_BAIXA]*/

/*[BEGIN_IMPORT_FATURA_COBRANCA_TRANSACAO]*/
import FormFaturaCobrancaTransacao from "../view/FaturaCobrancaTransacao/FormFaturaCobrancaTransacao";
import ListFaturaCobrancaTransacao from "../view/FaturaCobrancaTransacao/ListFaturaCobrancaTransacao";
/*[END_IMPORT_FATURA_COBRANCA_TRANSACAO]*/

/*[BEGIN_IMPORT_STATUS_FATURA_COBRANCA]*/
import FormStatusFaturaCobranca from "../view/StatusFaturaCobranca/FormStatusFaturaCobranca";
import ListStatusFaturaCobranca from "../view/StatusFaturaCobranca/ListStatusFaturaCobranca";
/*[END_IMPORT_STATUS_FATURA_COBRANCA]*/

/*[BEGIN_IMPORT_FATURA_COBRANCA_BOLETO]*/
import FormFaturaCobrancaBoleto from "../view/FaturaCobrancaBoleto/FormFaturaCobrancaBoleto";
import ListFaturaCobrancaBoleto from "../view/FaturaCobrancaBoleto/ListFaturaCobrancaBoleto";
/*[END_IMPORT_FATURA_COBRANCA_BOLETO]*/

/*[BEGIN_IMPORT_BLOCO]*/
import FormBloco from "../view/Bloco/FormBloco";
import ListBloco from "../view/Bloco/ListBloco";
/*[END_IMPORT_BLOCO]*/

/*[BEGIN_IMPORT_PAINEL]*/
import FormPainel from "../view/Painel/FormPainel";
import ListPainel from "../view/Painel/ListPainel";
/*[END_IMPORT_PAINEL]*/

/*[BEGIN_IMPORT_TIPO_BLOCO]*/
import FormTipoBloco from "../view/TipoBloco/FormTipoBloco";
import ListTipoBloco from "../view/TipoBloco/ListTipoBloco";
/*[END_IMPORT_TIPO_BLOCO]*/

/*[BEGIN_IMPORT_MODELO_BLOCO]*/
import FormModeloBloco from "../view/ModeloBloco/FormModeloBloco";
import ListModeloBloco from "../view/ModeloBloco/ListModeloBloco";
/*[END_IMPORT_MODELO_BLOCO]*/

/*[BEGIN_IMPORT_TIPO_COMANDO]*/
import FormTipoComando from "../view/TipoComando/FormTipoComando";
import ListTipoComando from "../view/TipoComando/ListTipoComando";
/*[END_IMPORT_TIPO_COMANDO]*/

/*[BEGIN_IMPORT_GRUPO_PAINEL]*/
import FormGrupoPainel from "../view/GrupoPainel/FormGrupoPainel";
import ListGrupoPainel from "../view/GrupoPainel/ListGrupoPainel";
/*[END_IMPORT_GRUPO_PAINEL]*/

/*[BEGIN_IMPORT_TIPO_FILTRO]*/
import FormTipoFiltro from "../view/TipoFiltro/FormTipoFiltro";
import ListTipoFiltro from "../view/TipoFiltro/ListTipoFiltro";
/*[END_IMPORT_TIPO_FILTRO]*/

/*[BEGIN_IMPORT_ARQUIVO]*/
import FormArquivo from "../view/Arquivo/FormArquivo";
import ListArquivo from "../view/Arquivo/ListArquivo";
/*[END_IMPORT_ARQUIVO]*/

/*[BEGIN_IMPORT_ARQUIVO_BASE]*/
import FormArquivoBase from "../view/ArquivoBase/FormArquivoBase";
import ListArquivoBase from "../view/ArquivoBase/ListArquivoBase";
/*[END_IMPORT_ARQUIVO_BASE]*/

/*[BEGIN_IMPORT_TIPO_PAGINA]*/
import FormTipoPagina from "../view/TipoPagina/FormTipoPagina";
import ListTipoPagina from "../view/TipoPagina/ListTipoPagina";
/*[END_IMPORT_TIPO_PAGINA]*/

/*[BEGIN_IMPORT_PESSOA_PAGINA]*/
import FormPessoaPagina from "../view/PessoaPagina/FormPessoaPagina";
import ListPessoaPagina from "../view/PessoaPagina/ListPessoaPagina";
/*[END_IMPORT_PESSOA_PAGINA]*/

/*[BEGIN_IMPORT_TIPO_NOTIFICACAO]*/
import FormTipoNotificacao from "../view/TipoNotificacao/FormTipoNotificacao";
import ListTipoNotificacao from "../view/TipoNotificacao/ListTipoNotificacao";
/*[END_IMPORT_TIPO_NOTIFICACAO]*/

/*[BEGIN_IMPORT_USUARIO_TIPO_NOTIFICACAO]*/
import FormUsuarioTipoNotificacao from "../view/UsuarioTipoNotificacao/FormUsuarioTipoNotificacao";
import ListUsuarioTipoNotificacao from "../view/UsuarioTipoNotificacao/ListUsuarioTipoNotificacao";
/*[END_IMPORT_USUARIO_TIPO_NOTIFICACAO]*/

/*[BEGIN_IMPORT_NOTIFICACAO_SISTEMA]*/
import FormNotificacaoSistema from "../view/NotificacaoSistema/FormNotificacaoSistema";
import ListNotificacaoSistema from "../view/NotificacaoSistema/ListNotificacaoSistema";
/*[END_IMPORT_NOTIFICACAO_SISTEMA]*/

/*[BEGIN_IMPORT_NOTIFICACAO_EMAIL]*/
import FormNotificacaoEmail from "../view/NotificacaoEmail/FormNotificacaoEmail";
import ListNotificacaoEmail from "../view/NotificacaoEmail/ListNotificacaoEmail";
/*[END_IMPORT_NOTIFICACAO_EMAIL]*/

/*[BEGIN_IMPORT_NOTIFICACAO_WHATSAPP]*/
import FormNotificacaoWhatsapp from "../view/NotificacaoWhatsapp/FormNotificacaoWhatsapp";
import ListNotificacaoWhatsapp from "../view/NotificacaoWhatsapp/ListNotificacaoWhatsapp";
/*[END_IMPORT_NOTIFICACAO_WHATSAPP]*/

/*[BEGIN_IMPORT_ANS_CARACTERISTICA_SOLO]*/
import FormAnsCaracteristicaSolo from "../view/AnsCaracteristicaSolo/FormAnsCaracteristicaSolo";
import ListAnsCaracteristicaSolo from "../view/AnsCaracteristicaSolo/ListAnsCaracteristicaSolo";
/*[END_IMPORT_ANS_CARACTERISTICA_SOLO]*/

/*[BEGIN_IMPORT_ANS_CARACTERISTICA_MATERIAL]*/
import FormAnsCaracteristicaMaterial from "../view/AnsCaracteristicaMaterial/FormAnsCaracteristicaMaterial";
import ListAnsCaracteristicaMaterial from "../view/AnsCaracteristicaMaterial/ListAnsCaracteristicaMaterial";
/*[END_IMPORT_ANS_CARACTERISTICA_MATERIAL]*/

/*[BEGIN_IMPORT_TIPO_ANS_PERFIL]*/
import FormTipoAnsPerfil from "../view/TipoAnsPerfil/FormTipoAnsPerfil";
import ListTipoAnsPerfil from "../view/TipoAnsPerfil/ListTipoAnsPerfil";
/*[END_IMPORT_TIPO_ANS_PERFIL]*/

/*[BEGIN_IMPORT_ANS_CARGO]*/
import FormAnsCargo from "../view/AnsCargo/FormAnsCargo";
import ListAnsCargo from "../view/AnsCargo/ListAnsCargo";
/*[END_IMPORT_ANS_CARGO]*/

/*[BEGIN_IMPORT_ANS_PERFIL_FOTO]*/
import FormAnsPerfilFoto from "../view/AnsPerfilFoto/FormAnsPerfilFoto";
import ListAnsPerfilFoto from "../view/AnsPerfilFoto/ListAnsPerfilFoto";
/*[END_IMPORT_ANS_PERFIL_FOTO]*/

/*[BEGIN_IMPORT_DADO]*/
import FormDado from "../view/Dado/FormDado";
import ListDado from "../view/Dado/ListDado";
/*[END_IMPORT_DADO]*/

/*[BEGIN_IMPORT_TIPO_DADO]*/
import FormTipoDado from "../view/TipoDado/FormTipoDado";
import ListTipoDado from "../view/TipoDado/ListTipoDado";
/*[END_IMPORT_TIPO_DADO]*/

/*[BEGIN_IMPORT_DADO_VALOR]*/
import FormDadoValor from "../view/DadoValor/FormDadoValor";
import ListDadoValor from "../view/DadoValor/ListDadoValor";
/*[END_IMPORT_DADO_VALOR]*/

/*[BEGIN_IMPORT_DADO_LOCAL]*/
import FormDadoLocal from "../view/DadoLocal/FormDadoLocal";
import ListDadoLocal from "../view/DadoLocal/ListDadoLocal";
/*[END_IMPORT_DADO_LOCAL]*/

/*[BEGIN_IMPORT_PESSOA_DADO_VALOR]*/
import FormPessoaDadoValor from "../view/PessoaDadoValor/FormPessoaDadoValor";
import ListPessoaDadoValor from "../view/PessoaDadoValor/ListPessoaDadoValor";
/*[END_IMPORT_PESSOA_DADO_VALOR]*/

/*[BEGIN_IMPORT_ANS_PERFIL_PLANTA]*/
import FormAnsPerfilPlanta from "../view/AnsPerfilPlanta/FormAnsPerfilPlanta";
import ListAnsPerfilPlanta from "../view/AnsPerfilPlanta/ListAnsPerfilPlanta";
/*[END_IMPORT_ANS_PERFIL_PLANTA]*/

/*[BEGIN_IMPORT_TIPO_BAIRRO]*/
import FormTipoBairro from "../view/TipoBairro/FormTipoBairro";
import ListTipoBairro from "../view/TipoBairro/ListTipoBairro";
/*[END_IMPORT_TIPO_BAIRRO]*/

/*[BEGIN_IMPORT_TIPO_LOGRADOURO]*/
import FormTipoLogradouro from "../view/TipoLogradouro/FormTipoLogradouro";
import ListTipoLogradouro from "../view/TipoLogradouro/ListTipoLogradouro";
/*[END_IMPORT_TIPO_LOGRADOURO]*/

/*[BEGIN_IMPORT_STATUS_NOTA_FISCAL]*/
import FormStatusNotaFiscal from "../view/StatusNotaFiscal/FormStatusNotaFiscal";
import ListStatusNotaFiscal from "../view/StatusNotaFiscal/ListStatusNotaFiscal";
/*[END_IMPORT_STATUS_NOTA_FISCAL]*/

/*[BEGIN_IMPORT_FATURA_NOTA_FISCAL_LOG]*/
import FormFaturaNotaFiscalLog from "../view/FaturaNotaFiscalLog/FormFaturaNotaFiscalLog";
import ListFaturaNotaFiscalLog from "../view/FaturaNotaFiscalLog/ListFaturaNotaFiscalLog";
/*[END_IMPORT_FATURA_NOTA_FISCAL_LOG]*/

/*[BEGIN_IMPORT_UNIDADE_DADO_VALOR]*/
import FormUnidadeDadoValor from "../view/UnidadeDadoValor/FormUnidadeDadoValor";
import ListUnidadeDadoValor from "../view/UnidadeDadoValor/ListUnidadeDadoValor";
/*[END_IMPORT_UNIDADE_DADO_VALOR]*/

/*[BEGIN_IMPORT_TIPO_ARQUIVO_NOTA_FISCAL]*/
import FormTipoArquivoNotaFiscal from "../view/TipoArquivoNotaFiscal/FormTipoArquivoNotaFiscal";
import ListTipoArquivoNotaFiscal from "../view/TipoArquivoNotaFiscal/ListTipoArquivoNotaFiscal";
/*[END_IMPORT_TIPO_ARQUIVO_NOTA_FISCAL]*/

/*[BEGIN_IMPORT_FATURA_NOTA_FISCAL_ARQUIVO]*/
import FormFaturaNotaFiscalArquivo from "../view/FaturaNotaFiscalArquivo/FormFaturaNotaFiscalArquivo";
import ListFaturaNotaFiscalArquivo from "../view/FaturaNotaFiscalArquivo/ListFaturaNotaFiscalArquivo";
/*[END_IMPORT_FATURA_NOTA_FISCAL_ARQUIVO]*/

/*[BEGIN_IMPORT_ORDEM_ENDERECO]*/
import FormOrdemEndereco from "../view/OrdemEndereco/FormOrdemEndereco";
import ListOrdemEndereco from "../view/OrdemEndereco/ListOrdemEndereco";
/*[END_IMPORT_ORDEM_ENDERECO]*/

/*[BEGIN_IMPORT_NATUREZA_TRIBUTACAO_NOTA_FISCAL]*/
import FormNaturezaTributacaoNotaFiscal from "../view/NaturezaTributacaoNotaFiscal/FormNaturezaTributacaoNotaFiscal";
import ListNaturezaTributacaoNotaFiscal from "../view/NaturezaTributacaoNotaFiscal/ListNaturezaTributacaoNotaFiscal";
/*[END_IMPORT_NATUREZA_TRIBUTACAO_NOTA_FISCAL]*/

/*[BEGIN_IMPORT_TIPO_REGIME_TRIBUTARIO]*/
import FormTipoRegimeTributario from "../view/TipoRegimeTributario/FormTipoRegimeTributario";
import ListTipoRegimeTributario from "../view/TipoRegimeTributario/ListTipoRegimeTributario";
/*[END_IMPORT_TIPO_REGIME_TRIBUTARIO]*/

/*[BEGIN_IMPORT_TIPO_REGIME_TRIBUTARIO_ESPECIAL]*/
import FormTipoRegimeTributarioEspecial from "../view/TipoRegimeTributarioEspecial/FormTipoRegimeTributarioEspecial";
import ListTipoRegimeTributarioEspecial from "../view/TipoRegimeTributarioEspecial/ListTipoRegimeTributarioEspecial";
/*[END_IMPORT_TIPO_REGIME_TRIBUTARIO_ESPECIAL]*/

/*[BEGIN_IMPORT_PESSOA_ATIVIDADE]*/
import FormPessoaAtividade from "../view/PessoaAtividade/FormPessoaAtividade";
import ListPessoaAtividade from "../view/PessoaAtividade/ListPessoaAtividade";
/*[END_IMPORT_PESSOA_ATIVIDADE]*/

/*[BEGIN_IMPORT_PESSOA_INSCRICAO_ESTADUAL]*/
import FormPessoaInscricaoEstadual from "../view/PessoaInscricaoEstadual/FormPessoaInscricaoEstadual";
import ListPessoaInscricaoEstadual from "../view/PessoaInscricaoEstadual/ListPessoaInscricaoEstadual";
/*[END_IMPORT_PESSOA_INSCRICAO_ESTADUAL]*/

/*[BEGIN_IMPORT_FATURA_NOTA_FISCAL_HISTORICO]*/
import FormFaturaNotaFiscalHistorico from "../view/FaturaNotaFiscalHistorico/FormFaturaNotaFiscalHistorico";
import ListFaturaNotaFiscalHistorico from "../view/FaturaNotaFiscalHistorico/ListFaturaNotaFiscalHistorico";
/*[END_IMPORT_FATURA_NOTA_FISCAL_HISTORICO]*/

/*[BEGIN_IMPORT_PRODUTO_TIPO_ORDEM]*/
import FormProdutoTipoOrdem from "../view/ProdutoTipoOrdem/FormProdutoTipoOrdem";
import ListProdutoTipoOrdem from "../view/ProdutoTipoOrdem/ListProdutoTipoOrdem";
/*[END_IMPORT_PRODUTO_TIPO_ORDEM]*/

/*[BEGIN_IMPORT_GRUPO_PRODUTO_TIPO_ORDEM]*/
import FormGrupoProdutoTipoOrdem from "../view/GrupoProdutoTipoOrdem/FormGrupoProdutoTipoOrdem";
import ListGrupoProdutoTipoOrdem from "../view/GrupoProdutoTipoOrdem/ListGrupoProdutoTipoOrdem";
/*[END_IMPORT_GRUPO_PRODUTO_TIPO_ORDEM]*/

/*[BEGIN_IMPORT_CONTA_DADO_VALOR]*/
import FormContaDadoValor from "../view/ContaDadoValor/FormContaDadoValor";
import ListContaDadoValor from "../view/ContaDadoValor/ListContaDadoValor";
/*[END_IMPORT_CONTA_DADO_VALOR]*/

/*[BEGIN_IMPORT_DADO_LOCAL_GRUPO]*/
import FormDadoLocalGrupo from "../view/DadoLocalGrupo/FormDadoLocalGrupo";
import ListDadoLocalGrupo from "../view/DadoLocalGrupo/ListDadoLocalGrupo";
/*[END_IMPORT_DADO_LOCAL_GRUPO]*/

/*[BEGIN_IMPORT_FATURA_NOTA_FISCAL_ITEM]*/
import FormFaturaNotaFiscalItem from "../view/FaturaNotaFiscalItem/FormFaturaNotaFiscalItem";
import ListFaturaNotaFiscalItem from "../view/FaturaNotaFiscalItem/ListFaturaNotaFiscalItem";
/*[END_IMPORT_FATURA_NOTA_FISCAL_ITEM]*/

/*[BEGIN_IMPORT_TIPO_TRIBUTACAO_SERVICO]*/
import FormTipoTributacaoServico from "../view/TipoTributacaoServico/FormTipoTributacaoServico";
import ListTipoTributacaoServico from "../view/TipoTributacaoServico/ListTipoTributacaoServico";
/*[END_IMPORT_TIPO_TRIBUTACAO_SERVICO]*/

/*[BEGIN_IMPORT_TIPO_EXIGIBILIDADE_SERVICO]*/
import FormTipoExigibilidadeServico from "../view/TipoExigibilidadeServico/FormTipoExigibilidadeServico";
import ListTipoExigibilidadeServico from "../view/TipoExigibilidadeServico/ListTipoExigibilidadeServico";
/*[END_IMPORT_TIPO_EXIGIBILIDADE_SERVICO]*/

/*[BEGIN_IMPORT_FATURA_NOTA_FISCAL_ITEM_ISS]*/
import FormFaturaNotaFiscalItemIss from "../view/FaturaNotaFiscalItemIss/FormFaturaNotaFiscalItemIss";
import ListFaturaNotaFiscalItemIss from "../view/FaturaNotaFiscalItemIss/ListFaturaNotaFiscalItemIss";
/*[END_IMPORT_FATURA_NOTA_FISCAL_ITEM_ISS]*/

/*[BEGIN_IMPORT_FATURA_NOTA_FISCAL_ITEM_RETENCAO]*/
import FormFaturaNotaFiscalItemRetencao from "../view/FaturaNotaFiscalItemRetencao/FormFaturaNotaFiscalItemRetencao";
import ListFaturaNotaFiscalItemRetencao from "../view/FaturaNotaFiscalItemRetencao/ListFaturaNotaFiscalItemRetencao";
/*[END_IMPORT_FATURA_NOTA_FISCAL_ITEM_RETENCAO]*/

/*[BEGIN_IMPORT_FATURA_NOTA_FISCAL_ITEM_OBRA]*/
import FormFaturaNotaFiscalItemObra from "../view/FaturaNotaFiscalItemObra/FormFaturaNotaFiscalItemObra";
import ListFaturaNotaFiscalItemObra from "../view/FaturaNotaFiscalItemObra/ListFaturaNotaFiscalItemObra";
/*[END_IMPORT_FATURA_NOTA_FISCAL_ITEM_OBRA]*/

/*[BEGIN_IMPORT_TIPO_NOTA_FISCAL]*/
import FormTipoNotaFiscal from "../view/TipoNotaFiscal/FormTipoNotaFiscal";
import ListTipoNotaFiscal from "../view/TipoNotaFiscal/ListTipoNotaFiscal";
/*[END_IMPORT_TIPO_NOTA_FISCAL]*/

/*[BEGIN_IMPORT_UNIDADE_TIPO_NOTA_FISCAL]*/
import FormUnidadeTipoNotaFiscal from "../view/UnidadeTipoNotaFiscal/FormUnidadeTipoNotaFiscal";
import ListUnidadeTipoNotaFiscal from "../view/UnidadeTipoNotaFiscal/ListUnidadeTipoNotaFiscal";
/*[END_IMPORT_UNIDADE_TIPO_NOTA_FISCAL]*/

/*[BEGIN_IMPORT_PLUGIN]*/
import FormPlugin from "../view/Plugin/FormPlugin";
import ListPlugin from "../view/Plugin/ListPlugin";
/*[END_IMPORT_PLUGIN]*/

/*[BEGIN_IMPORT_PLUGIN_DADO_VALOR]*/
import FormPluginDadoValor from "../view/PluginDadoValor/FormPluginDadoValor";
import ListPluginDadoValor from "../view/PluginDadoValor/ListPluginDadoValor";
/*[END_IMPORT_PLUGIN_DADO_VALOR]*/

/*[BEGIN_IMPORT_PRODUTO_DADO_VALOR]*/
import FormProdutoDadoValor from "../view/ProdutoDadoValor/FormProdutoDadoValor";
import ListProdutoDadoValor from "../view/ProdutoDadoValor/ListProdutoDadoValor";
/*[END_IMPORT_PRODUTO_DADO_VALOR]*/

/*[BEGIN_IMPORT_TIPO_ORDEM_DADO_VALOR]*/
import FormTipoOrdemDadoValor from "../view/TipoOrdemDadoValor/FormTipoOrdemDadoValor";
import ListTipoOrdemDadoValor from "../view/TipoOrdemDadoValor/ListTipoOrdemDadoValor";
/*[END_IMPORT_TIPO_ORDEM_DADO_VALOR]*/

/*[BEGIN_IMPORT_CLASSIFICACAO_PRODUTO_DADO_VALOR]*/
import FormClassificacaoProdutoDadoValor from "../view/ClassificacaoProdutoDadoValor/FormClassificacaoProdutoDadoValor";
import ListClassificacaoProdutoDadoValor from "../view/ClassificacaoProdutoDadoValor/ListClassificacaoProdutoDadoValor";
/*[END_IMPORT_CLASSIFICACAO_PRODUTO_DADO_VALOR]*/

/*[BEGIN_IMPORT_FUNCIONALIDADE_PLUGIN]*/
import FormFuncionalidadePlugin from "../view/FuncionalidadePlugin/FormFuncionalidadePlugin";
import ListFuncionalidadePlugin from "../view/FuncionalidadePlugin/ListFuncionalidadePlugin";
/*[END_IMPORT_FUNCIONALIDADE_PLUGIN]*/

/*[BEGIN_IMPORT_PLUGIN_FUNCIONALIDADE_PLUGIN]*/
import FormPluginFuncionalidadePlugin from "../view/PluginFuncionalidadePlugin/FormPluginFuncionalidadePlugin";
import ListPluginFuncionalidadePlugin from "../view/PluginFuncionalidadePlugin/ListPluginFuncionalidadePlugin";
/*[END_IMPORT_PLUGIN_FUNCIONALIDADE_PLUGIN]*/

/*[BEGIN_IMPORT_FATURA_NOTA_FISCAL_INTERMEDIARIO]*/
import FormFaturaNotaFiscalIntermediario from "../view/FaturaNotaFiscalIntermediario/FormFaturaNotaFiscalIntermediario";
import ListFaturaNotaFiscalIntermediario from "../view/FaturaNotaFiscalIntermediario/ListFaturaNotaFiscalIntermediario";
/*[END_IMPORT_FATURA_NOTA_FISCAL_INTERMEDIARIO]*/

/*[BEGIN_IMPORT_FATURA_NOTA_FISCAL_DEDUCAO]*/
import FormFaturaNotaFiscalDeducao from "../view/FaturaNotaFiscalDeducao/FormFaturaNotaFiscalDeducao";
import ListFaturaNotaFiscalDeducao from "../view/FaturaNotaFiscalDeducao/ListFaturaNotaFiscalDeducao";
/*[END_IMPORT_FATURA_NOTA_FISCAL_DEDUCAO]*/

/*[BEGIN_IMPORT_FATURA_NOTA_FISCAL_CARGA_TRIBUTARIA]*/
import FormFaturaNotaFiscalCargaTributaria from "../view/FaturaNotaFiscalCargaTributaria/FormFaturaNotaFiscalCargaTributaria";
import ListFaturaNotaFiscalCargaTributaria from "../view/FaturaNotaFiscalCargaTributaria/ListFaturaNotaFiscalCargaTributaria";
/*[END_IMPORT_FATURA_NOTA_FISCAL_CARGA_TRIBUTARIA]*/

/*[BEGIN_IMPORT_FATURA_NOTA_FISCAL_DEDUCAO_ITEM]*/
import FormFaturaNotaFiscalDeducaoItem from "../view/FaturaNotaFiscalDeducaoItem/FormFaturaNotaFiscalDeducaoItem";
import ListFaturaNotaFiscalDeducaoItem from "../view/FaturaNotaFiscalDeducaoItem/ListFaturaNotaFiscalDeducaoItem";
/*[END_IMPORT_FATURA_NOTA_FISCAL_DEDUCAO_ITEM]*/

/*[BEGIN_IMPORT_FATURA_NOTA_FISCAL_ENDERECO]*/
import FormFaturaNotaFiscalEndereco from "../view/FaturaNotaFiscalEndereco/FormFaturaNotaFiscalEndereco";
import ListFaturaNotaFiscalEndereco from "../view/FaturaNotaFiscalEndereco/ListFaturaNotaFiscalEndereco";
/*[END_IMPORT_FATURA_NOTA_FISCAL_ENDERECO]*/

/*[BEGIN_IMPORT_EMPRESA_DADO_VALOR]*/
import FormEmpresaDadoValor from "../view/EmpresaDadoValor/FormEmpresaDadoValor";
import ListEmpresaDadoValor from "../view/EmpresaDadoValor/ListEmpresaDadoValor";
/*[END_IMPORT_EMPRESA_DADO_VALOR]*/

/*[BEGIN_IMPORT_CONFIGURACAO_DADO_VALOR]*/
import FormConfiguracaoDadoValor from "../view/ConfiguracaoDadoValor/FormConfiguracaoDadoValor";
import ListConfiguracaoDadoValor from "../view/ConfiguracaoDadoValor/ListConfiguracaoDadoValor";
/*[END_IMPORT_CONFIGURACAO_DADO_VALOR]*/

/*[BEGIN_IMPORT_FATURA_COBRANCA_TIPO_PAGAMENTO_PARCELA]*/
import FormFaturaCobrancaTipoPagamentoParcela from "../view/FaturaCobrancaTipoPagamentoParcela/FormFaturaCobrancaTipoPagamentoParcela";
import ListFaturaCobrancaTipoPagamentoParcela from "../view/FaturaCobrancaTipoPagamentoParcela/ListFaturaCobrancaTipoPagamentoParcela";
/*[END_IMPORT_FATURA_COBRANCA_TIPO_PAGAMENTO_PARCELA]*/

/*[BEGIN_IMPORT_FATURA_COBRANCA_PIX]*/
import FormFaturaCobrancaPix from "../view/FaturaCobrancaPix/FormFaturaCobrancaPix";
import ListFaturaCobrancaPix from "../view/FaturaCobrancaPix/ListFaturaCobrancaPix";
/*[END_IMPORT_FATURA_COBRANCA_PIX]*/

/*[BEGIN_IMPORT_FATURA_COBRANCA_CARTAO]*/
import FormFaturaCobrancaCartao from "../view/FaturaCobrancaCartao/FormFaturaCobrancaCartao";
import ListFaturaCobrancaCartao from "../view/FaturaCobrancaCartao/ListFaturaCobrancaCartao";
/*[END_IMPORT_FATURA_COBRANCA_CARTAO]*/

/*[BEGIN_IMPORT_WEBHOOK]*/
import FormWebhook from "../view/Webhook/FormWebhook";
import ListWebhook from "../view/Webhook/ListWebhook";
/*[END_IMPORT_WEBHOOK]*/

/*[BEGIN_IMPORT_CONTA_TIPO_PAGAMENTO_DADO_VALOR]*/
import FormContaTipoPagamentoDadoValor from "../view/ContaTipoPagamentoDadoValor/FormContaTipoPagamentoDadoValor";
import ListContaTipoPagamentoDadoValor from "../view/ContaTipoPagamentoDadoValor/ListContaTipoPagamentoDadoValor";
/*[END_IMPORT_CONTA_TIPO_PAGAMENTO_DADO_VALOR]*/

/*[BEGIN_IMPORT_LOG_REQUEST]*/
import FormLogRequest from "../view/LogRequest/FormLogRequest";
import ListLogRequest from "../view/LogRequest/ListLogRequest";
/*[END_IMPORT_LOG_REQUEST]*/

/*[BEGIN_IMPORT_LOG_RESPONSE]*/
import FormLogResponse from "../view/LogResponse/FormLogResponse";
import ListLogResponse from "../view/LogResponse/ListLogResponse";
/*[END_IMPORT_LOG_RESPONSE]*/

/*[BEGIN_IMPORT_FATURA_NOTA_FISCAL_MOTIVO_CANCELAMENTO]*/
import FormFaturaNotaFiscalMotivoCancelamento from "../view/FaturaNotaFiscalMotivoCancelamento/FormFaturaNotaFiscalMotivoCancelamento";
import ListFaturaNotaFiscalMotivoCancelamento from "../view/FaturaNotaFiscalMotivoCancelamento/ListFaturaNotaFiscalMotivoCancelamento";
/*[END_IMPORT_FATURA_NOTA_FISCAL_MOTIVO_CANCELAMENTO]*/

/*[BEGIN_IMPORT_TIPO_FATURA]*/
import FormTipoFatura from "../view/TipoFatura/FormTipoFatura";
import ListTipoFatura from "../view/TipoFatura/ListTipoFatura";
/*[END_IMPORT_TIPO_FATURA]*/

/*[BEGIN_IMPORT_FATURA_ACORDO]*/
import FormFaturaAcordo from "../view/FaturaAcordo/FormFaturaAcordo";
import ListFaturaAcordo from "../view/FaturaAcordo/ListFaturaAcordo";
/*[END_IMPORT_FATURA_ACORDO]*/

/*[BEGIN_IMPORT_FATURA_ACORDO_FATURA_COBRANCA]*/
import FormFaturaAcordoFaturaCobranca from "../view/FaturaAcordoFaturaCobranca/FormFaturaAcordoFaturaCobranca";
import ListFaturaAcordoFaturaCobranca from "../view/FaturaAcordoFaturaCobranca/ListFaturaAcordoFaturaCobranca";
/*[END_IMPORT_FATURA_ACORDO_FATURA_COBRANCA]*/

/*[BEGIN_IMPORT_STATUS_FATURA_ACORDO]*/
import FormStatusFaturaAcordo from "../view/StatusFaturaAcordo/FormStatusFaturaAcordo";
import ListStatusFaturaAcordo from "../view/StatusFaturaAcordo/ListStatusFaturaAcordo";
/*[END_IMPORT_STATUS_FATURA_ACORDO]*/

/*[BEGIN_IMPORT_FATURA_COBRANCA_BOLETO_INSTRUCAO]*/
import FormFaturaCobrancaBoletoInstrucao from "../view/FaturaCobrancaBoletoInstrucao/FormFaturaCobrancaBoletoInstrucao";
import ListFaturaCobrancaBoletoInstrucao from "../view/FaturaCobrancaBoletoInstrucao/ListFaturaCobrancaBoletoInstrucao";
/*[END_IMPORT_FATURA_COBRANCA_BOLETO_INSTRUCAO]*/

/*[BEGIN_IMPORT_TIPO_DESCONTO]*/
import FormTipoDesconto from "../view/TipoDesconto/FormTipoDesconto";
import ListTipoDesconto from "../view/TipoDesconto/ListTipoDesconto";
/*[END_IMPORT_TIPO_DESCONTO]*/

/*[BEGIN_IMPORT_FATURA_DESCONTO]*/
import FormFaturaDesconto from "../view/FaturaDesconto/FormFaturaDesconto";
import ListFaturaDesconto from "../view/FaturaDesconto/ListFaturaDesconto";
/*[END_IMPORT_FATURA_DESCONTO]*/

/*[BEGIN_IMPORT_ORDEM_DADO_VALOR]*/
import FormOrdemDadoValor from "../view/OrdemDadoValor/FormOrdemDadoValor";
import ListOrdemDadoValor from "../view/OrdemDadoValor/ListOrdemDadoValor";
/*[END_IMPORT_ORDEM_DADO_VALOR]*/

/*[BEGIN_IMPORT_OBRA]*/
import FormObra from "../view/Obra/FormObra";
import ListObra from "../view/Obra/ListObra";
/*[END_IMPORT_OBRA]*/

/*[BEGIN_IMPORT_VEICULO]*/
import FormVeiculo from "../view/Veiculo/FormVeiculo";
import ListVeiculo from "../view/Veiculo/ListVeiculo";
/*[END_IMPORT_VEICULO]*/

/*[BEGIN_IMPORT_TIPO_VEICULO]*/
import FormTipoVeiculo from "../view/TipoVeiculo/FormTipoVeiculo";
import ListTipoVeiculo from "../view/TipoVeiculo/ListTipoVeiculo";
/*[END_IMPORT_TIPO_VEICULO]*/

/*[BEGIN_IMPORT_USUARIO_BLOCO_FAVORITO]*/
import FormUsuarioBlocoFavorito from "../view/UsuarioBlocoFavorito/FormUsuarioBlocoFavorito";
import ListUsuarioBlocoFavorito from "../view/UsuarioBlocoFavorito/ListUsuarioBlocoFavorito";
/*[END_IMPORT_USUARIO_BLOCO_FAVORITO]*/

/*[BEGIN_IMPORT_PESSOA_UNIDADE]*/
import FormPessoaUnidade from "../view/PessoaUnidade/FormPessoaUnidade";
import ListPessoaUnidade from "../view/PessoaUnidade/ListPessoaUnidade";
/*[END_IMPORT_PESSOA_UNIDADE]*/

/*[BEGIN_IMPORT_PESSOA_VEICULO]*/
import FormPessoaVeiculo from "../view/PessoaVeiculo/FormPessoaVeiculo";
import ListPessoaVeiculo from "../view/PessoaVeiculo/ListPessoaVeiculo";
/*[END_IMPORT_PESSOA_VEICULO]*/

/*[BEGIN_IMPORT_VEICULO_ODOMETRO]*/
import FormVeiculoOdometro from "../view/VeiculoOdometro/FormVeiculoOdometro";
import ListVeiculoOdometro from "../view/VeiculoOdometro/ListVeiculoOdometro";
/*[END_IMPORT_VEICULO_ODOMETRO]*/

/*[BEGIN_IMPORT_VEICULO_LOCALIZACAO]*/
import FormVeiculoLocalizacao from "../view/VeiculoLocalizacao/FormVeiculoLocalizacao";
import ListVeiculoLocalizacao from "../view/VeiculoLocalizacao/ListVeiculoLocalizacao";
/*[END_IMPORT_VEICULO_LOCALIZACAO]*/

/*[BEGIN_IMPORT_LOCALIZACAO]*/
import FormLocalizacao from "../view/Localizacao/FormLocalizacao";
import ListLocalizacao from "../view/Localizacao/ListLocalizacao";
/*[END_IMPORT_LOCALIZACAO]*/

/*[BEGIN_IMPORT_VEICULO_PLACA]*/
import FormVeiculoPlaca from "../view/VeiculoPlaca/FormVeiculoPlaca";
import ListVeiculoPlaca from "../view/VeiculoPlaca/ListVeiculoPlaca";
/*[END_IMPORT_VEICULO_PLACA]*/

/*[BEGIN_IMPORT_COMBUSTIVEL]*/
import FormCombustivel from "../view/Combustivel/FormCombustivel";
import ListCombustivel from "../view/Combustivel/ListCombustivel";
/*[END_IMPORT_COMBUSTIVEL]*/

/*[BEGIN_IMPORT_TRANSMISSAO]*/
import FormTransmissao from "../view/Transmissao/FormTransmissao";
import ListTransmissao from "../view/Transmissao/ListTransmissao";
/*[END_IMPORT_TRANSMISSAO]*/

/*[BEGIN_IMPORT_VEICULO_COMBUSTIVEL]*/
import FormVeiculoCombustivel from "../view/VeiculoCombustivel/FormVeiculoCombustivel";
import ListVeiculoCombustivel from "../view/VeiculoCombustivel/ListVeiculoCombustivel";
/*[END_IMPORT_VEICULO_COMBUSTIVEL]*/

/*[BEGIN_IMPORT_PESSOA_LOCALIZACAO]*/
import FormPessoaLocalizacao from "../view/PessoaLocalizacao/FormPessoaLocalizacao";
import ListPessoaLocalizacao from "../view/PessoaLocalizacao/ListPessoaLocalizacao";
/*[END_IMPORT_PESSOA_LOCALIZACAO]*/

/*[BEGIN_IMPORT_VEICULO_DADO_VALOR]*/
import FormVeiculoDadoValor from "../view/VeiculoDadoValor/FormVeiculoDadoValor";
import ListVeiculoDadoValor from "../view/VeiculoDadoValor/ListVeiculoDadoValor";
/*[END_IMPORT_VEICULO_DADO_VALOR]*/

/*[BEGIN_IMPORT_VEICULO_ARQUIVO]*/
import FormVeiculoArquivo from "../view/VeiculoArquivo/FormVeiculoArquivo";
import ListVeiculoArquivo from "../view/VeiculoArquivo/ListVeiculoArquivo";
/*[END_IMPORT_VEICULO_ARQUIVO]*/

/*[BEGIN_IMPORT_VEICULO_TRAJETO]*/
import FormVeiculoTrajeto from "../view/VeiculoTrajeto/FormVeiculoTrajeto";
import ListVeiculoTrajeto from "../view/VeiculoTrajeto/ListVeiculoTrajeto";
/*[END_IMPORT_VEICULO_TRAJETO]*/

/*[BEGIN_IMPORT_ORDEM_ART]*/
import FormOrdemArt from "../view/OrdemArt/FormOrdemArt";
import ListOrdemArt from "../view/OrdemArt/ListOrdemArt";
/*[END_IMPORT_ORDEM_ART]*/

/*[BEGIN_IMPORT_VEICULO_RESUMO]*/
import FormVeiculoResumo from "../view/VeiculoResumo/FormVeiculoResumo";
import ListVeiculoResumo from "../view/VeiculoResumo/ListVeiculoResumo";
/*[END_IMPORT_VEICULO_RESUMO]*/

/*[BEGIN_IMPORT_RECORRENCIA]*/
import FormRecorrencia from "../view/Recorrencia/FormRecorrencia";
import ListRecorrencia from "../view/Recorrencia/ListRecorrencia";
/*[END_IMPORT_RECORRENCIA]*/

/*[BEGIN_IMPORT_CONTA_PAGAR]*/
import FormContaPagar from "../view/ContaPagar/FormContaPagar";
import ListContaPagar from "../view/ContaPagar/ListContaPagar";
/*[END_IMPORT_CONTA_PAGAR]*/

/*[BEGIN_IMPORT_CONTA_RECEBER]*/
import FormContaReceber from "../view/ContaReceber/FormContaReceber";
import ListContaReceber from "../view/ContaReceber/ListContaReceber";
/*[END_IMPORT_CONTA_RECEBER]*/

/*[BEGIN_IMPORT_TIPO_RECORRENCIA]*/
import FormTipoRecorrencia from "../view/TipoRecorrencia/FormTipoRecorrencia";
import ListTipoRecorrencia from "../view/TipoRecorrencia/ListTipoRecorrencia";
/*[END_IMPORT_TIPO_RECORRENCIA]*/

/*[BEGIN_IMPORT_CONTA_PAGAR_PARCELA]*/
import FormContaPagarParcela from "../view/ContaPagarParcela/FormContaPagarParcela";
import ListContaPagarParcela from "../view/ContaPagarParcela/ListContaPagarParcela";
/*[END_IMPORT_CONTA_PAGAR_PARCELA]*/

/*[BEGIN_IMPORT_TIPO_CONTA_PAGAR]*/
import FormTipoContaPagar from "../view/TipoContaPagar/FormTipoContaPagar";
import ListTipoContaPagar from "../view/TipoContaPagar/ListTipoContaPagar";
/*[END_IMPORT_TIPO_CONTA_PAGAR]*/

/*[BEGIN_IMPORT_TIPO_CONTA_RECEBER]*/
import FormTipoContaReceber from "../view/TipoContaReceber/FormTipoContaReceber";
import ListTipoContaReceber from "../view/TipoContaReceber/ListTipoContaReceber";
/*[END_IMPORT_TIPO_CONTA_RECEBER]*/

/*[BEGIN_IMPORT_STATUS_CONTA]*/
import FormStatusConta from "../view/StatusConta/FormStatusConta";
import ListStatusConta from "../view/StatusConta/ListStatusConta";
/*[END_IMPORT_STATUS_CONTA]*/

/*[BEGIN_IMPORT_CONTA_PAGAR_BAIXA]*/
import FormContaPagarBaixa from "../view/ContaPagarBaixa/FormContaPagarBaixa";
import ListContaPagarBaixa from "../view/ContaPagarBaixa/ListContaPagarBaixa";
/*[END_IMPORT_CONTA_PAGAR_BAIXA]*/

/*[BEGIN_IMPORT_CONTA_RECEBER_BAIXA]*/
import FormContaReceberBaixa from "../view/ContaReceberBaixa/FormContaReceberBaixa";
import ListContaReceberBaixa from "../view/ContaReceberBaixa/ListContaReceberBaixa";
/*[END_IMPORT_CONTA_RECEBER_BAIXA]*/

/*[BEGIN_IMPORT_CONTA_RECEBER_PARCELA]*/
import FormContaReceberParcela from "../view/ContaReceberParcela/FormContaReceberParcela";
import ListContaReceberParcela from "../view/ContaReceberParcela/ListContaReceberParcela";
/*[END_IMPORT_CONTA_RECEBER_PARCELA]*/

/*[BEGIN_IMPORT_CONTA_RECEBER_ARQUIVO]*/
import FormContaReceberArquivo from "../view/ContaReceberArquivo/FormContaReceberArquivo";
import ListContaReceberArquivo from "../view/ContaReceberArquivo/ListContaReceberArquivo";
/*[END_IMPORT_CONTA_RECEBER_ARQUIVO]*/

/*[BEGIN_IMPORT_CONTA_PAGAR_ARQUIVO]*/
import FormContaPagarArquivo from "../view/ContaPagarArquivo/FormContaPagarArquivo";
import ListContaPagarArquivo from "../view/ContaPagarArquivo/ListContaPagarArquivo";
/*[END_IMPORT_CONTA_PAGAR_ARQUIVO]*/

/*[BEGIN_IMPORT_CONTA_PAGAR_VINCULO]*/
import FormContaPagarVinculo from "../view/ContaPagarVinculo/FormContaPagarVinculo";
import ListContaPagarVinculo from "../view/ContaPagarVinculo/ListContaPagarVinculo";
/*[END_IMPORT_CONTA_PAGAR_VINCULO]*/

/*[BEGIN_IMPORT_CONTA_PAGAR_VEICULO]*/
import FormContaPagarVeiculo from "../view/ContaPagarVeiculo/FormContaPagarVeiculo";
import ListContaPagarVeiculo from "../view/ContaPagarVeiculo/ListContaPagarVeiculo";
/*[END_IMPORT_CONTA_PAGAR_VEICULO]*/

/*[BEGIN_IMPORT_ORDEM_ARQUIVO]*/
import FormOrdemArquivo from "../view/OrdemArquivo/FormOrdemArquivo";
import ListOrdemArquivo from "../view/OrdemArquivo/ListOrdemArquivo";
/*[END_IMPORT_ORDEM_ARQUIVO]*/

/*[BEGIN_IMPORT_FATURA_ARQUIVO]*/
import FormFaturaArquivo from "../view/FaturaArquivo/FormFaturaArquivo";
import ListFaturaArquivo from "../view/FaturaArquivo/ListFaturaArquivo";
/*[END_IMPORT_FATURA_ARQUIVO]*/

/*[BEGIN_IMPORT_FLUXO]*/
import FormFluxo from "../view/Fluxo/FormFluxo";
import ListFluxo from "../view/Fluxo/ListFluxo";
/*[END_IMPORT_FLUXO]*/

/*[BEGIN_IMPORT_FLUXO_VERSAO]*/
import FormFluxoVersao from "../view/FluxoVersao/FormFluxoVersao";
import ListFluxoVersao from "../view/FluxoVersao/ListFluxoVersao";
/*[END_IMPORT_FLUXO_VERSAO]*/

/*[BEGIN_IMPORT_FLUXO_ITEM_TAREFA]*/
import FormFluxoItemTarefa from "../view/FluxoItemTarefa/FormFluxoItemTarefa";
import ListFluxoItemTarefa from "../view/FluxoItemTarefa/ListFluxoItemTarefa";
/*[END_IMPORT_FLUXO_ITEM_TAREFA]*/

/*[BEGIN_IMPORT_TIPO_FLUXO_ITEM]*/
import FormTipoFluxoItem from "../view/TipoFluxoItem/FormTipoFluxoItem";
import ListTipoFluxoItem from "../view/TipoFluxoItem/ListTipoFluxoItem";
/*[END_IMPORT_TIPO_FLUXO_ITEM]*/

/*[BEGIN_IMPORT_FLUXO_ITEM]*/
import FormFluxoItem from "../view/FluxoItem/FormFluxoItem";
import ListFluxoItem from "../view/FluxoItem/ListFluxoItem";
/*[END_IMPORT_FLUXO_ITEM]*/

/*[BEGIN_IMPORT_FLUXO_ITEM_DESTINO]*/
import FormFluxoItemDestino from "../view/FluxoItemDestino/FormFluxoItemDestino";
import ListFluxoItemDestino from "../view/FluxoItemDestino/ListFluxoItemDestino";
/*[END_IMPORT_FLUXO_ITEM_DESTINO]*/

/*[BEGIN_IMPORT_TIPO_PRAZO]*/
import FormTipoPrazo from "../view/TipoPrazo/FormTipoPrazo";
import ListTipoPrazo from "../view/TipoPrazo/ListTipoPrazo";
/*[END_IMPORT_TIPO_PRAZO]*/

/*[BEGIN_IMPORT_ANS_PERFIL_ARQUIVO]*/
import FormAnsPerfilArquivo from "../view/AnsPerfilArquivo/FormAnsPerfilArquivo";
import ListAnsPerfilArquivo from "../view/AnsPerfilArquivo/ListAnsPerfilArquivo";
/*[END_IMPORT_ANS_PERFIL_ARQUIVO]*/

/*[BEGIN_IMPORT_TIPO_NOTIFICACAO_DESTINO]*/
import FormTipoNotificacaoDestino from "../view/TipoNotificacaoDestino/FormTipoNotificacaoDestino";
import ListTipoNotificacaoDestino from "../view/TipoNotificacaoDestino/ListTipoNotificacaoDestino";
/*[END_IMPORT_TIPO_NOTIFICACAO_DESTINO]*/

/*[BEGIN_IMPORT_PESSOA_TIPO_NOTIFICACAO]*/
import FormPessoaTipoNotificacao from "../view/PessoaTipoNotificacao/FormPessoaTipoNotificacao";
import ListPessoaTipoNotificacao from "../view/PessoaTipoNotificacao/ListPessoaTipoNotificacao";
/*[END_IMPORT_PESSOA_TIPO_NOTIFICACAO]*/

/*[BEGIN_IMPORT_NOTIFICACAO_EMAIL_ARQUIVO]*/
import FormNotificacaoEmailArquivo from "../view/NotificacaoEmailArquivo/FormNotificacaoEmailArquivo";
import ListNotificacaoEmailArquivo from "../view/NotificacaoEmailArquivo/ListNotificacaoEmailArquivo";
/*[END_IMPORT_NOTIFICACAO_EMAIL_ARQUIVO]*/

/*[BEGIN_IMPORT_NOTIFICACAO_WHATSAPP_ARQUIVO]*/
import FormNotificacaoWhatsappArquivo from "../view/NotificacaoWhatsappArquivo/FormNotificacaoWhatsappArquivo";
import ListNotificacaoWhatsappArquivo from "../view/NotificacaoWhatsappArquivo/ListNotificacaoWhatsappArquivo";
/*[END_IMPORT_NOTIFICACAO_WHATSAPP_ARQUIVO]*/

/*[BEGIN_IMPORT_EQUIPE_PESSOA]*/
import FormEquipePessoa from "../view/EquipePessoa/FormEquipePessoa";
import ListEquipePessoa from "../view/EquipePessoa/ListEquipePessoa";
/*[END_IMPORT_EQUIPE_PESSOA]*/

/*[BEGIN_IMPORT_EQUIPE_UNIDADE]*/
import FormEquipeUnidade from "../view/EquipeUnidade/FormEquipeUnidade";
import ListEquipeUnidade from "../view/EquipeUnidade/ListEquipeUnidade";
/*[END_IMPORT_EQUIPE_UNIDADE]*/

/*[BEGIN_IMPORT_ORDEM_PESSOA]*/
import FormOrdemPessoa from "../view/OrdemPessoa/FormOrdemPessoa";
import ListOrdemPessoa from "../view/OrdemPessoa/ListOrdemPessoa";
/*[END_IMPORT_ORDEM_PESSOA]*/

/*[BEGIN_IMPORT_ORDEM_EQUIPE]*/
import FormOrdemEquipe from "../view/OrdemEquipe/FormOrdemEquipe";
import ListOrdemEquipe from "../view/OrdemEquipe/ListOrdemEquipe";
/*[END_IMPORT_ORDEM_EQUIPE]*/

/*[BEGIN_IMPORT_COMISSAO]*/
import FormComissao from "../view/Comissao/FormComissao";
import ListComissao from "../view/Comissao/ListComissao";
/*[END_IMPORT_COMISSAO]*/

/*[BEGIN_IMPORT_TIPO_VALOR]*/
import FormTipoValor from "../view/TipoValor/FormTipoValor";
import ListTipoValor from "../view/TipoValor/ListTipoValor";
/*[END_IMPORT_TIPO_VALOR]*/

/*[BEGIN_IMPORT_TIPO_COMISSAO]*/
import FormTipoComissao from "../view/TipoComissao/FormTipoComissao";
import ListTipoComissao from "../view/TipoComissao/ListTipoComissao";
/*[END_IMPORT_TIPO_COMISSAO]*/

/*[BEGIN_IMPORT_COMISSAO_CARGO]*/
import FormComissaoCargo from "../view/ComissaoCargo/FormComissaoCargo";
import ListComissaoCargo from "../view/ComissaoCargo/ListComissaoCargo";
/*[END_IMPORT_COMISSAO_CARGO]*/

/*[BEGIN_IMPORT_STATUS_COMISSAO]*/
import FormStatusComissao from "../view/StatusComissao/FormStatusComissao";
import ListStatusComissao from "../view/StatusComissao/ListStatusComissao";
/*[END_IMPORT_STATUS_COMISSAO]*/

/*[BEGIN_IMPORT_COMISSAO_PESSOA]*/
import FormComissaoPessoa from "../view/ComissaoPessoa/FormComissaoPessoa";
import ListComissaoPessoa from "../view/ComissaoPessoa/ListComissaoPessoa";
/*[END_IMPORT_COMISSAO_PESSOA]*/

/*[BEGIN_IMPORT_COMISSAO_HISTORICO]*/
import FormComissaoHistorico from "../view/ComissaoHistorico/FormComissaoHistorico";
import ListComissaoHistorico from "../view/ComissaoHistorico/ListComissaoHistorico";
/*[END_IMPORT_COMISSAO_HISTORICO]*/

/*[BEGIN_IMPORT_COMISSAO_PESSOA_ORDEM]*/
import FormComissaoPessoaOrdem from "../view/ComissaoPessoaOrdem/FormComissaoPessoaOrdem";
import ListComissaoPessoaOrdem from "../view/ComissaoPessoaOrdem/ListComissaoPessoaOrdem";
/*[END_IMPORT_COMISSAO_PESSOA_ORDEM]*/

/*[BEGIN_IMPORT_FUNCIONARIO]*/
import FormFuncionario from "../view/Funcionario/FormFuncionario";
import ListFuncionario from "../view/Funcionario/ListFuncionario";
/*[END_IMPORT_FUNCIONARIO]*/

/*[BEGIN_IMPORT_CONTA_PAGAR_COMISSAO_PESSOA]*/
import FormContaPagarComissaoPessoa from "../view/ContaPagarComissaoPessoa/FormContaPagarComissaoPessoa";
import ListContaPagarComissaoPessoa from "../view/ContaPagarComissaoPessoa/ListContaPagarComissaoPessoa";
/*[END_IMPORT_CONTA_PAGAR_COMISSAO_PESSOA]*/

/*[BEGIN_IMPORT_COMISSAO_UNIDADE]*/
import FormComissaoUnidade from "../view/ComissaoUnidade/FormComissaoUnidade";
import ListComissaoUnidade from "../view/ComissaoUnidade/ListComissaoUnidade";
/*[END_IMPORT_COMISSAO_UNIDADE]*/

/*[BEGIN_IMPORT_COMISSAO_ORDEM]*/
import FormComissaoOrdem from "../view/ComissaoOrdem/FormComissaoOrdem";
import ListComissaoOrdem from "../view/ComissaoOrdem/ListComissaoOrdem";
/*[END_IMPORT_COMISSAO_ORDEM]*/

/*[BEGIN_IMPORT_FUNCIONARIO_CARGO]*/
import FormFuncionarioCargo from "../view/FuncionarioCargo/FormFuncionarioCargo";
import ListFuncionarioCargo from "../view/FuncionarioCargo/ListFuncionarioCargo";
/*[END_IMPORT_FUNCIONARIO_CARGO]*/

/*[BEGIN_IMPORT_FUNCIONARIO_SALARIO]*/
import FormFuncionarioSalario from "../view/FuncionarioSalario/FormFuncionarioSalario";
import ListFuncionarioSalario from "../view/FuncionarioSalario/ListFuncionarioSalario";
/*[END_IMPORT_FUNCIONARIO_SALARIO]*/

/*[BEGIN_IMPORT_STATUS_FUNCIONARIO]*/
import FormStatusFuncionario from "../view/StatusFuncionario/FormStatusFuncionario";
import ListStatusFuncionario from "../view/StatusFuncionario/ListStatusFuncionario";
/*[END_IMPORT_STATUS_FUNCIONARIO]*/

/*[BEGIN_IMPORT_FUNCIONARIO_HISTORICO]*/
import FormFuncionarioHistorico from "../view/FuncionarioHistorico/FormFuncionarioHistorico";
import ListFuncionarioHistorico from "../view/FuncionarioHistorico/ListFuncionarioHistorico";
/*[END_IMPORT_FUNCIONARIO_HISTORICO]*/

/*[BEGIN_IMPORT_TIPO_DEPENDENTE]*/
import FormTipoDependente from "../view/TipoDependente/FormTipoDependente";
import ListTipoDependente from "../view/TipoDependente/ListTipoDependente";
/*[END_IMPORT_TIPO_DEPENDENTE]*/

/*[BEGIN_IMPORT_FUNCIONARIO_DEPENDENTE]*/
import FormFuncionarioDependente from "../view/FuncionarioDependente/FormFuncionarioDependente";
import ListFuncionarioDependente from "../view/FuncionarioDependente/ListFuncionarioDependente";
/*[END_IMPORT_FUNCIONARIO_DEPENDENTE]*/

/*[BEGIN_IMPORT_COMISSAO_TIPO_ORDEM]*/
import FormComissaoTipoOrdem from "../view/ComissaoTipoOrdem/FormComissaoTipoOrdem";
import ListComissaoTipoOrdem from "../view/ComissaoTipoOrdem/ListComissaoTipoOrdem";
/*[END_IMPORT_COMISSAO_TIPO_ORDEM]*/

/*[BEGIN_IMPORT_COMISSAO_DATA]*/
import FormComissaoData from "../view/ComissaoData/FormComissaoData";
import ListComissaoData from "../view/ComissaoData/ListComissaoData";
/*[END_IMPORT_COMISSAO_DATA]*/

/*[BEGIN_IMPORT_TIPO_COMISSAO_DATA]*/
import FormTipoComissaoData from "../view/TipoComissaoData/FormTipoComissaoData";
import ListTipoComissaoData from "../view/TipoComissaoData/ListTipoComissaoData";
/*[END_IMPORT_TIPO_COMISSAO_DATA]*/

/*[BEGIN_IMPORT_ESTADO_CIVIL]*/
import FormEstadoCivil from "../view/EstadoCivil/FormEstadoCivil";
import ListEstadoCivil from "../view/EstadoCivil/ListEstadoCivil";
/*[END_IMPORT_ESTADO_CIVIL]*/

/*[BEGIN_IMPORT_PESSOA_FOTO]*/
import FormPessoaFoto from "../view/PessoaFoto/FormPessoaFoto";
import ListPessoaFoto from "../view/PessoaFoto/ListPessoaFoto";
/*[END_IMPORT_PESSOA_FOTO]*/

/*[BEGIN_IMPORT_TIPO_DOCUMENTO]*/
import FormTipoDocumento from "../view/TipoDocumento/FormTipoDocumento";
import ListTipoDocumento from "../view/TipoDocumento/ListTipoDocumento";
/*[END_IMPORT_TIPO_DOCUMENTO]*/

/*[BEGIN_IMPORT_PESSOA_DOCUMENTO]*/
import FormPessoaDocumento from "../view/PessoaDocumento/FormPessoaDocumento";
import ListPessoaDocumento from "../view/PessoaDocumento/ListPessoaDocumento";
/*[END_IMPORT_PESSOA_DOCUMENTO]*/

/*[BEGIN_IMPORT_FUNCIONARIO_DOCUMENTO]*/
import FormFuncionarioDocumento from "../view/FuncionarioDocumento/FormFuncionarioDocumento";
import ListFuncionarioDocumento from "../view/FuncionarioDocumento/ListFuncionarioDocumento";
/*[END_IMPORT_FUNCIONARIO_DOCUMENTO]*/

/*[BEGIN_IMPORT_NACIONALIDADE]*/
import FormNacionalidade from "../view/Nacionalidade/FormNacionalidade";
import ListNacionalidade from "../view/Nacionalidade/ListNacionalidade";
/*[END_IMPORT_NACIONALIDADE]*/

/*[BEGIN_IMPORT_GENERO]*/
import FormGenero from "../view/Genero/FormGenero";
import ListGenero from "../view/Genero/ListGenero";
/*[END_IMPORT_GENERO]*/

/*[BEGIN_IMPORT_TIPO_FILIACAO]*/
import FormTipoFiliacao from "../view/TipoFiliacao/FormTipoFiliacao";
import ListTipoFiliacao from "../view/TipoFiliacao/ListTipoFiliacao";
/*[END_IMPORT_TIPO_FILIACAO]*/

/*[BEGIN_IMPORT_PESSOA_FILIACAO]*/
import FormPessoaFiliacao from "../view/PessoaFiliacao/FormPessoaFiliacao";
import ListPessoaFiliacao from "../view/PessoaFiliacao/ListPessoaFiliacao";
/*[END_IMPORT_PESSOA_FILIACAO]*/

/*[BEGIN_IMPORT_PESSOA_CONJUGE]*/
import FormPessoaConjuge from "../view/PessoaConjuge/FormPessoaConjuge";
import ListPessoaConjuge from "../view/PessoaConjuge/ListPessoaConjuge";
/*[END_IMPORT_PESSOA_CONJUGE]*/

/*[BEGIN_IMPORT_TIPO_EVENTO_SALARIO]*/
import FormTipoEventoSalario from "../view/TipoEventoSalario/FormTipoEventoSalario";
import ListTipoEventoSalario from "../view/TipoEventoSalario/ListTipoEventoSalario";
/*[END_IMPORT_TIPO_EVENTO_SALARIO]*/

/*[BEGIN_IMPORT_TIPO_PAGAMENTO_SALARIO]*/
import FormTipoPagamentoSalario from "../view/TipoPagamentoSalario/FormTipoPagamentoSalario";
import ListTipoPagamentoSalario from "../view/TipoPagamentoSalario/ListTipoPagamentoSalario";
/*[END_IMPORT_TIPO_PAGAMENTO_SALARIO]*/

/*[BEGIN_IMPORT_ESCALA_TRABALHO]*/
import FormEscalaTrabalho from "../view/EscalaTrabalho/FormEscalaTrabalho";
import ListEscalaTrabalho from "../view/EscalaTrabalho/ListEscalaTrabalho";
/*[END_IMPORT_ESCALA_TRABALHO]*/

/*[BEGIN_IMPORT_USUARIO_TOKEN]*/
import FormUsuarioToken from "../view/UsuarioToken/FormUsuarioToken";
import ListUsuarioToken from "../view/UsuarioToken/ListUsuarioToken";
/*[END_IMPORT_USUARIO_TOKEN]*/

/*[BEGIN_IMPORT_ANS_CRITERIO_PARADA]*/
import FormAnsCriterioParada from "../view/AnsCriterioParada/FormAnsCriterioParada";
import ListAnsCriterioParada from "../view/AnsCriterioParada/ListAnsCriterioParada";
/*[END_IMPORT_ANS_CRITERIO_PARADA]*/

/*[BEGIN_IMPORT_ANS_PERFIL_PARADA]*/
import FormAnsPerfilParada from "../view/AnsPerfilParada/FormAnsPerfilParada";
import ListAnsPerfilParada from "../view/AnsPerfilParada/ListAnsPerfilParada";
/*[END_IMPORT_ANS_PERFIL_PARADA]*/

/*[AUTOMATIC_IMPORT]*/

const AppRoutes = () => {

	return (
		<Routes>
			<Route path="/Login" element={<FormLogin />} />
			<Route path="/pagamento/:cdUid" element={<FormPagarEscolha />} />
			<Route path="/pagamento/boleto/:cdUid" element={<FormPagarBoleto />} />
			<Route path="/pagamento/pix/:cdUid" element={<FormPagarPix />} />
			<Route path="/pagamento/cartao-credito/:cdUid" element={<FormPagarCartaoCredito />} />
			<Route path="/pagamento/cartao-debito/:cdUid" element={<FormPagarEscolha />} />

			<Route path="Painel" element={<RequireAuth><Painel /></RequireAuth>} />
			<Route path="Painel/:id" element={<RequireAuth><Painel /></RequireAuth>} />

			<Route path="WorkFlow/:id" element={<WorkFlow />} />

			<Route path="/" element={<RequireAuth><Layout /></RequireAuth>}>
				<Route index element={<Home />} />
				<Route path="LstMapaItens" element={<ListMapaLocalizacao />} />
				<Route path="CadFaturar/:id" element={<FormFaturar />} />
				<Route path="CadFaturar" element={<FormFaturar />} />
				<Route path="CadAcordo" element={<FormAcordo />} />
				<Route path="LstConfiguracao" element={<ListConfiguracao />} />
				<Route path="LstContaExtrato" element={<ListContaExtrato />} />
				<Route path="LstSessao" element={<ListUsuarioSessao />} />

				{/*[BEGIN_ROUTE_FUSO_HORARIO]*/}
				<Route path="CadFusoHorario" element={<FormFusoHorario />} />
				<Route path="CadFusoHorario/:id" element={<FormFusoHorario />} />
				<Route path="LstFusoHorario" element={<ListFusoHorario />} />
				{/*[END_ROUTE_FUSO_HORARIO]*/}

				{/*[BEGIN_ROUTE_MARCA]*/}
				<Route path="CadMarca" element={<FormMarca />} />
				<Route path="CadMarca/:id" element={<FormMarca />} />
				<Route path="LstMarca" element={<ListMarca />} />
				{/*[END_ROUTE_MARCA]*/}

				{/*[BEGIN_ROUTE_MODELO]*/}
				<Route path="CadModelo" element={<FormModelo />} />
				<Route path="CadModelo/:id" element={<FormModelo />} />
				<Route path="LstModelo" element={<ListModelo />} />
				{/*[END_ROUTE_MODELO]*/}

				{/*[BEGIN_ROUTE_PAIS]*/}
				<Route path="CadPais" element={<FormPais />} />
				<Route path="CadPais/:id" element={<FormPais />} />
				<Route path="LstPais" element={<ListPais />} />
				{/*[END_ROUTE_PAIS]*/}

				{/*[BEGIN_ROUTE_ESTADO]*/}
				<Route path="CadEstado" element={<FormEstado />} />
				<Route path="CadEstado/:id" element={<FormEstado />} />
				<Route path="LstEstado" element={<ListEstado />} />
				{/*[END_ROUTE_ESTADO]*/}

				{/*[BEGIN_ROUTE_TIPO_EMAIL]*/}
				<Route path="CadTipoEmail" element={<FormTipoEmail />} />
				<Route path="CadTipoEmail/:id" element={<FormTipoEmail />} />
				<Route path="LstTipoEmail" element={<ListTipoEmail />} />
				{/*[END_ROUTE_TIPO_EMAIL]*/}

				{/*[BEGIN_ROUTE_UNIDADE]*/}
				<Route path="CadUnidade" element={<FormUnidade />} />
				<Route path="CadUnidade/:id" element={<FormUnidade />} />
				<Route path="LstUnidade" element={<ListUnidade />} />
				{/*[END_ROUTE_UNIDADE]*/}

				{/*[BEGIN_ROUTE_USUARIO]*/}
				<Route path="CadUsuario" element={<FormUsuario />} />
				<Route path="CadUsuario/:id" element={<FormUsuario />} />
				<Route path="LstUsuario" element={<ListUsuario />} />
				{/*[END_ROUTE_USUARIO]*/}

				{/*[BEGIN_ROUTE_PESSOA]*/}
				<Route path="CadPessoa" element={<FormPessoa />} />
				<Route path="CadPessoa/:id" element={<FormPessoa />} />
				<Route path="LstPessoa" element={<ListPessoa />} />
				{/*[END_ROUTE_PESSOA]*/}

				{/*[BEGIN_ROUTE_EQUIPE]*/}
				<Route path="CadEquipe" element={<FormEquipe />} />
				<Route path="CadEquipe/:id" element={<FormEquipe />} />
				<Route path="LstEquipe" element={<ListEquipe />} />
				{/*[END_ROUTE_EQUIPE]*/}

				{/*[BEGIN_ROUTE_USUARIO_UNIDADE]*/}
				<Route path="CadUsuarioUnidade" element={<FormUsuarioUnidade />} />
				<Route path="CadUsuarioUnidade/:id" element={<FormUsuarioUnidade />} />
				<Route path="LstUsuarioUnidade" element={<ListUsuarioUnidade />} />
				{/*[END_ROUTE_USUARIO_UNIDADE]*/}

				{/*[BEGIN_ROUTE_GRUPO]*/}
				<Route path="CadGrupo" element={<FormGrupo />} />
				<Route path="CadGrupo/:id" element={<FormGrupo />} />
				<Route path="LstGrupo" element={<ListGrupo />} />
				{/*[END_ROUTE_GRUPO]*/}

				{/*[BEGIN_ROUTE_USUARIO_GRUPO]*/}
				<Route path="CadUsuarioGrupo" element={<FormUsuarioGrupo />} />
				<Route path="CadUsuarioGrupo/:id" element={<FormUsuarioGrupo />} />
				<Route path="LstUsuarioGrupo" element={<ListUsuarioGrupo />} />
				{/*[END_ROUTE_USUARIO_GRUPO]*/}

				{/*[BEGIN_ROUTE_CIDADE]*/}
				<Route path="CadCidade" element={<FormCidade />} />
				<Route path="CadCidade/:id" element={<FormCidade />} />
				<Route path="LstCidade" element={<ListCidade />} />
				{/*[END_ROUTE_CIDADE]*/}

				{/*[BEGIN_ROUTE_MODULO]*/}
				<Route path="CadModulo" element={<FormModulo />} />
				<Route path="CadModulo/:id" element={<FormModulo />} />
				<Route path="LstModulo" element={<ListModulo />} />
				{/*[END_ROUTE_MODULO]*/}

				{/*[BEGIN_ROUTE_GRUPO_UNIDADE]*/}
				<Route path="CadGrupoUnidade" element={<FormGrupoUnidade />} />
				<Route path="CadGrupoUnidade/:id" element={<FormGrupoUnidade />} />
				<Route path="LstGrupoUnidade" element={<ListGrupoUnidade />} />
				{/*[END_ROUTE_GRUPO_UNIDADE]*/}

				{/*[BEGIN_ROUTE_GRUPO_MODULO]*/}
				<Route path="CadGrupoModulo" element={<FormGrupoModulo />} />
				<Route path="CadGrupoModulo/:id" element={<FormGrupoModulo />} />
				<Route path="LstGrupoModulo" element={<ListGrupoModulo />} />
				{/*[END_ROUTE_GRUPO_MODULO]*/}

				{/*[BEGIN_ROUTE_TIPO_ORDEM]*/}
				<Route path="CadTipoOrdem" element={<FormTipoOrdem />} />
				<Route path="CadTipoOrdem/:id" element={<FormTipoOrdem />} />
				<Route path="LstTipoOrdem" element={<ListTipoOrdem />} />
				{/*[END_ROUTE_TIPO_ORDEM]*/}

				{/*[BEGIN_ROUTE_GRUPO_TIPO_ORDEM]*/}
				<Route path="CadGrupoTipoOrdem" element={<FormGrupoTipoOrdem />} />
				<Route path="CadGrupoTipoOrdem/:id" element={<FormGrupoTipoOrdem />} />
				<Route path="LstGrupoTipoOrdem" element={<ListGrupoTipoOrdem />} />
				{/*[END_ROUTE_GRUPO_TIPO_ORDEM]*/}

				{/*[BEGIN_ROUTE_SUBMODULO]*/}
				<Route path="CadSubmodulo" element={<FormSubmodulo />} />
				<Route path="CadSubmodulo/:id" element={<FormSubmodulo />} />
				<Route path="LstSubmodulo" element={<ListSubmodulo />} />
				{/*[END_ROUTE_SUBMODULO]*/}

				{/*[BEGIN_ROUTE_TIPO_PESSOA]*/}
				<Route path="CadTipoPessoa" element={<FormTipoPessoa />} />
				<Route path="CadTipoPessoa/:id" element={<FormTipoPessoa />} />
				<Route path="LstTipoPessoa" element={<ListTipoPessoa />} />
				{/*[END_ROUTE_TIPO_PESSOA]*/}

				{/*[BEGIN_ROUTE_USUARIO_EQUIPE]*/}
				<Route path="CadUsuarioEquipe" element={<FormUsuarioEquipe />} />
				<Route path="CadUsuarioEquipe/:id" element={<FormUsuarioEquipe />} />
				<Route path="LstUsuarioEquipe" element={<ListUsuarioEquipe />} />
				{/*[END_ROUTE_USUARIO_EQUIPE]*/}

				{/*[BEGIN_ROUTE_TIPO_PRECO]*/}
				<Route path="CadTipoPreco" element={<FormTipoPreco />} />
				<Route path="CadTipoPreco/:id" element={<FormTipoPreco />} />
				<Route path="LstTipoPreco" element={<ListTipoPreco />} />
				{/*[END_ROUTE_TIPO_PRECO]*/}

				{/*[BEGIN_ROUTE_TIPO_CONTATO]*/}
				<Route path="CadTipoContato" element={<FormTipoContato />} />
				<Route path="CadTipoContato/:id" element={<FormTipoContato />} />
				<Route path="LstTipoContato" element={<ListTipoContato />} />
				{/*[END_ROUTE_TIPO_CONTATO]*/}

				{/*[BEGIN_ROUTE_TIPO_PRODUTO]*/}
				<Route path="CadTipoProduto" element={<FormTipoProduto />} />
				<Route path="CadTipoProduto/:id" element={<FormTipoProduto />} />
				<Route path="LstTipoProduto" element={<ListTipoProduto />} />
				{/*[END_ROUTE_TIPO_PRODUTO]*/}

				{/*[BEGIN_ROUTE_TIPO_TELEFONE]*/}
				<Route path="CadTipoTelefone" element={<FormTipoTelefone />} />
				<Route path="CadTipoTelefone/:id" element={<FormTipoTelefone />} />
				<Route path="LstTipoTelefone" element={<ListTipoTelefone />} />
				{/*[END_ROUTE_TIPO_TELEFONE]*/}

				{/*[BEGIN_ROUTE_TIPO_ENDERECO]*/}
				<Route path="CadTipoEndereco" element={<FormTipoEndereco />} />
				<Route path="CadTipoEndereco/:id" element={<FormTipoEndereco />} />
				<Route path="LstTipoEndereco" element={<ListTipoEndereco />} />
				{/*[END_ROUTE_TIPO_ENDERECO]*/}

				{/*[BEGIN_ROUTE_TIPO_PAGAMENTO]*/}
				<Route path="CadTipoPagamento" element={<FormTipoPagamento />} />
				<Route path="CadTipoPagamento/:id" element={<FormTipoPagamento />} />
				<Route path="LstTipoPagamento" element={<ListTipoPagamento />} />
				{/*[END_ROUTE_TIPO_PAGAMENTO]*/}

				{/*[BEGIN_ROUTE_TIPO_TRANSACAO]*/}
				<Route path="CadTipoTransacao" element={<FormTipoTransacao />} />
				<Route path="CadTipoTransacao/:id" element={<FormTipoTransacao />} />
				<Route path="LstTipoTransacao" element={<ListTipoTransacao />} />
				{/*[END_ROUTE_TIPO_TRANSACAO]*/}

				{/*[BEGIN_ROUTE_UNIDADE_LOCAL]*/}
				<Route path="CadUnidadeLocal" element={<FormUnidadeLocal />} />
				<Route path="CadUnidadeLocal/:id" element={<FormUnidadeLocal />} />
				<Route path="LstUnidadeLocal" element={<ListUnidadeLocal />} />
				{/*[END_ROUTE_UNIDADE_LOCAL]*/}

				{/*[BEGIN_ROUTE_PESSOA_EMAIL]*/}
				<Route path="CadPessoaEmail" element={<FormPessoaEmail />} />
				<Route path="CadPessoaEmail/:id" element={<FormPessoaEmail />} />
				<Route path="LstPessoaEmail" element={<ListPessoaEmail />} />
				{/*[END_ROUTE_PESSOA_EMAIL]*/}

				{/*[BEGIN_ROUTE_PESSOA_TELEFONE]*/}
				<Route path="CadPessoaTelefone" element={<FormPessoaTelefone />} />
				<Route path="CadPessoaTelefone/:id" element={<FormPessoaTelefone />} />
				<Route path="LstPessoaTelefone" element={<ListPessoaTelefone />} />
				{/*[END_ROUTE_PESSOA_TELEFONE]*/}

				{/*[BEGIN_ROUTE_ENDERECO]*/}
				<Route path="CadEndereco" element={<FormEndereco />} />
				<Route path="CadEndereco/:id" element={<FormEndereco />} />
				<Route path="LstEndereco" element={<ListEndereco />} />
				{/*[END_ROUTE_ENDERECO]*/}

				{/*[BEGIN_ROUTE_PESSOA_ENDERECO]*/}
				<Route path="CadPessoaEndereco" element={<FormPessoaEndereco />} />
				<Route path="CadPessoaEndereco/:id" element={<FormPessoaEndereco />} />
				<Route path="LstPessoaEndereco" element={<ListPessoaEndereco />} />
				{/*[END_ROUTE_PESSOA_ENDERECO]*/}

				{/*[BEGIN_ROUTE_PRODUTO]*/}
				<Route path="CadProduto" element={<FormProduto />} />
				<Route path="CadProduto/:id" element={<FormProduto />} />
				<Route path="LstProduto" element={<ListProduto />} />
				{/*[END_ROUTE_PRODUTO]*/}

				{/*[BEGIN_ROUTE_GRUPO_PRODUTO]*/}
				<Route path="CadGrupoProduto" element={<FormGrupoProduto />} />
				<Route path="CadGrupoProduto/:id" element={<FormGrupoProduto />} />
				<Route path="LstGrupoProduto" element={<ListGrupoProduto />} />
				{/*[END_ROUTE_GRUPO_PRODUTO]*/}

				{/*[BEGIN_ROUTE_CLASSIFICACAO_PRODUTO]*/}
				<Route path="CadClassificacaoProduto" element={<FormClassificacaoProduto />} />
				<Route path="CadClassificacaoProduto/:id" element={<FormClassificacaoProduto />} />
				<Route path="LstClassificacaoProduto" element={<ListClassificacaoProduto />} />
				{/*[END_ROUTE_CLASSIFICACAO_PRODUTO]*/}

				{/*[BEGIN_ROUTE_UNIDADE_MEDIDA]*/}
				<Route path="CadUnidadeMedida" element={<FormUnidadeMedida />} />
				<Route path="CadUnidadeMedida/:id" element={<FormUnidadeMedida />} />
				<Route path="LstUnidadeMedida" element={<ListUnidadeMedida />} />
				{/*[END_ROUTE_UNIDADE_MEDIDA]*/}

				{/*[BEGIN_ROUTE_PRODUTO_ESTOQUE]*/}
				<Route path="CadProdutoEstoque" element={<FormProdutoEstoque />} />
				<Route path="CadProdutoEstoque/:id" element={<FormProdutoEstoque />} />
				<Route path="LstProdutoEstoque" element={<ListProdutoEstoque />} />
				{/*[END_ROUTE_PRODUTO_ESTOQUE]*/}

				{/*[BEGIN_ROUTE_ORDEM]*/}
				<Route path="CadOrdem" element={<FormOrdem />} />
				<Route path="CadOrdem/:id" element={<FormOrdem />} />
				<Route path="LstOrdem" element={<ListOrdem />} />
				{/*[END_ROUTE_ORDEM]*/}

				{/*[BEGIN_ROUTE_STATUS_ORDEM]*/}
				<Route path="CadStatusOrdem" element={<FormStatusOrdem />} />
				<Route path="CadStatusOrdem/:id" element={<FormStatusOrdem />} />
				<Route path="LstStatusOrdem" element={<ListStatusOrdem />} />
				{/*[END_ROUTE_STATUS_ORDEM]*/}

				{/*[BEGIN_ROUTE_ORDEM_ITEM]*/}
				<Route path="CadOrdemItem" element={<FormOrdemItem />} />
				<Route path="CadOrdemItem/:id" element={<FormOrdemItem />} />
				<Route path="LstOrdemItem" element={<ListOrdemItem />} />
				{/*[END_ROUTE_ORDEM_ITEM]*/}

				{/*[BEGIN_ROUTE_ORDEM_COMENTARIO]*/}
				<Route path="CadOrdemComentario" element={<FormOrdemComentario />} />
				<Route path="CadOrdemComentario/:id" element={<FormOrdemComentario />} />
				<Route path="LstOrdemComentario" element={<ListOrdemComentario />} />
				{/*[END_ROUTE_ORDEM_COMENTARIO]*/}

				{/*[BEGIN_ROUTE_PESSOA_TELEFONE_TIPO_CONTATO]*/}
				<Route path="CadPessoaTelefoneTipoContato" element={<FormPessoaTelefoneTipoContato />} />
				<Route path="CadPessoaTelefoneTipoContato/:id" element={<FormPessoaTelefoneTipoContato />} />
				<Route path="LstPessoaTelefoneTipoContato" element={<ListPessoaTelefoneTipoContato />} />
				{/*[END_ROUTE_PESSOA_TELEFONE_TIPO_CONTATO]*/}

				{/*[BEGIN_ROUTE_USUARIO_MODULO]*/}
				<Route path="CadUsuarioModulo" element={<FormUsuarioModulo />} />
				<Route path="CadUsuarioModulo/:id" element={<FormUsuarioModulo />} />
				<Route path="LstUsuarioModulo" element={<ListUsuarioModulo />} />
				{/*[END_ROUTE_USUARIO_MODULO]*/}

				{/*[BEGIN_ROUTE_GRUPO_TIPO_PRECO]*/}
				<Route path="CadGrupoTipoPreco" element={<FormGrupoTipoPreco />} />
				<Route path="CadGrupoTipoPreco/:id" element={<FormGrupoTipoPreco />} />
				<Route path="LstGrupoTipoPreco" element={<ListGrupoTipoPreco />} />
				{/*[END_ROUTE_GRUPO_TIPO_PRECO]*/}

				{/*[BEGIN_ROUTE_USUARIO_TIPO_PRECO]*/}
				<Route path="CadUsuarioTipoPreco" element={<FormUsuarioTipoPreco />} />
				<Route path="CadUsuarioTipoPreco/:id" element={<FormUsuarioTipoPreco />} />
				<Route path="LstUsuarioTipoPreco" element={<ListUsuarioTipoPreco />} />
				{/*[END_ROUTE_USUARIO_TIPO_PRECO]*/}

				{/*[BEGIN_ROUTE_USUARIO_TIPO_ORDEM]*/}
				<Route path="CadUsuarioTipoOrdem" element={<FormUsuarioTipoOrdem />} />
				<Route path="CadUsuarioTipoOrdem/:id" element={<FormUsuarioTipoOrdem />} />
				<Route path="LstUsuarioTipoOrdem" element={<ListUsuarioTipoOrdem />} />
				{/*[END_ROUTE_USUARIO_TIPO_ORDEM]*/}

				{/*[BEGIN_ROUTE_ANS_PERFIL]*/}
				<Route path="CadAnsPerfil" element={<FormAnsPerfil />} />
				<Route path="CadAnsPerfil/:id" element={<FormAnsPerfil />} />
				<Route path="LstAnsPerfil" element={<ListAnsPerfil />} />
				{/*[END_ROUTE_ANS_PERFIL]*/}

				{/*[BEGIN_ROUTE_ANS_MATERIAL]*/}
				<Route path="CadAnsMaterial" element={<FormAnsMaterial />} />
				<Route path="CadAnsMaterial/:id" element={<FormAnsMaterial />} />
				<Route path="LstAnsMaterial" element={<ListAnsMaterial />} />
				{/*[END_ROUTE_ANS_MATERIAL]*/}

				{/*[BEGIN_ROUTE_ANS_PERFIL_MATERIAL]*/}
				<Route path="CadAnsPerfilMaterial" element={<FormAnsPerfilMaterial />} />
				<Route path="CadAnsPerfilMaterial/:id" element={<FormAnsPerfilMaterial />} />
				<Route path="LstAnsPerfilMaterial" element={<ListAnsPerfilMaterial />} />
				{/*[END_ROUTE_ANS_PERFIL_MATERIAL]*/}

				{/*[BEGIN_ROUTE_ANS_CATEGORIA_MATERIAL]*/}
				<Route path="CadAnsCategoriaMaterial" element={<FormAnsCategoriaMaterial />} />
				<Route path="CadAnsCategoriaMaterial/:id" element={<FormAnsCategoriaMaterial />} />
				<Route path="LstAnsCategoriaMaterial" element={<ListAnsCategoriaMaterial />} />
				{/*[END_ROUTE_ANS_CATEGORIA_MATERIAL]*/}

				{/*[BEGIN_ROUTE_USUARIO_GRUPO_UNIDADE]*/}
				<Route path="CadUsuarioGrupoUnidade" element={<FormUsuarioGrupoUnidade />} />
				<Route path="CadUsuarioGrupoUnidade/:id" element={<FormUsuarioGrupoUnidade />} />
				<Route path="LstUsuarioGrupoUnidade" element={<ListUsuarioGrupoUnidade />} />
				{/*[END_ROUTE_USUARIO_GRUPO_UNIDADE]*/}

				{/*[BEGIN_ROUTE_USUARIO_GRUPO_MODULO]*/}
				<Route path="CadUsuarioGrupoModulo" element={<FormUsuarioGrupoModulo />} />
				<Route path="CadUsuarioGrupoModulo/:id" element={<FormUsuarioGrupoModulo />} />
				<Route path="LstUsuarioGrupoModulo" element={<ListUsuarioGrupoModulo />} />
				{/*[END_ROUTE_USUARIO_GRUPO_MODULO]*/}

				{/*[BEGIN_ROUTE_USUARIO_GRUPO_TIPO_PRECO]*/}
				<Route path="CadUsuarioGrupoTipoPreco" element={<FormUsuarioGrupoTipoPreco />} />
				<Route path="CadUsuarioGrupoTipoPreco/:id" element={<FormUsuarioGrupoTipoPreco />} />
				<Route path="LstUsuarioGrupoTipoPreco" element={<ListUsuarioGrupoTipoPreco />} />
				{/*[END_ROUTE_USUARIO_GRUPO_TIPO_PRECO]*/}

				{/*[BEGIN_ROUTE_USUARIO_GRUPO_TIPO_ORDEM]*/}
				<Route path="CadUsuarioGrupoTipoOrdem" element={<FormUsuarioGrupoTipoOrdem />} />
				<Route path="CadUsuarioGrupoTipoOrdem/:id" element={<FormUsuarioGrupoTipoOrdem />} />
				<Route path="LstUsuarioGrupoTipoOrdem" element={<ListUsuarioGrupoTipoOrdem />} />
				{/*[END_ROUTE_USUARIO_GRUPO_TIPO_ORDEM]*/}

				{/*[BEGIN_ROUTE_USUARIO_GRUPO_EQUIPE]*/}
				<Route path="CadUsuarioGrupoEquipe" element={<FormUsuarioGrupoEquipe />} />
				<Route path="CadUsuarioGrupoEquipe/:id" element={<FormUsuarioGrupoEquipe />} />
				<Route path="LstUsuarioGrupoEquipe" element={<ListUsuarioGrupoEquipe />} />
				{/*[END_ROUTE_USUARIO_GRUPO_EQUIPE]*/}

				{/*[BEGIN_ROUTE_EMPRESA]*/}
				<Route path="CadEmpresa" element={<FormEmpresa />} />
				<Route path="CadEmpresa/:id" element={<FormEmpresa />} />
				<Route path="LstEmpresa" element={<ListEmpresa />} />
				{/*[END_ROUTE_EMPRESA]*/}

				{/*[BEGIN_ROUTE_PRODUTO_PRECO]*/}
				<Route path="CadProdutoPreco" element={<FormProdutoPreco />} />
				<Route path="CadProdutoPreco/:id" element={<FormProdutoPreco />} />
				<Route path="LstProdutoPreco" element={<ListProdutoPreco />} />
				{/*[END_ROUTE_PRODUTO_PRECO]*/}

				{/*[BEGIN_ROUTE_ANS_COR]*/}
				<Route path="CadAnsCor" element={<FormAnsCor />} />
				<Route path="CadAnsCor/:id" element={<FormAnsCor />} />
				<Route path="LstAnsCor" element={<ListAnsCor />} />
				{/*[END_ROUTE_ANS_COR]*/}

				{/*[BEGIN_ROUTE_ANS_STATUS]*/}
				<Route path="CadAnsStatus" element={<FormAnsStatus />} />
				<Route path="CadAnsStatus/:id" element={<FormAnsStatus />} />
				<Route path="LstAnsStatus" element={<ListAnsStatus />} />
				{/*[END_ROUTE_ANS_STATUS]*/}

				{/*[BEGIN_ROUTE_ANS_FERRAMENTA]*/}
				<Route path="CadAnsFerramenta" element={<FormAnsFerramenta />} />
				<Route path="CadAnsFerramenta/:id" element={<FormAnsFerramenta />} />
				<Route path="LstAnsFerramenta" element={<ListAnsFerramenta />} />
				{/*[END_ROUTE_ANS_FERRAMENTA]*/}

				{/*[BEGIN_ROUTE_ANS_PERFIL_CLASSIFICACAO]*/}
				<Route path="CadAnsPerfilClassificacao" element={<FormAnsPerfilClassificacao />} />
				<Route path="CadAnsPerfilClassificacao/:id" element={<FormAnsPerfilClassificacao />} />
				<Route path="LstAnsPerfilClassificacao" element={<ListAnsPerfilClassificacao />} />
				{/*[END_ROUTE_ANS_PERFIL_CLASSIFICACAO]*/}

				{/*[BEGIN_ROUTE_ANS_PERFIL_GOLPE]*/}
				<Route path="CadAnsPerfilGolpe" element={<FormAnsPerfilGolpe />} />
				<Route path="CadAnsPerfilGolpe/:id" element={<FormAnsPerfilGolpe />} />
				<Route path="LstAnsPerfilGolpe" element={<ListAnsPerfilGolpe />} />
				{/*[END_ROUTE_ANS_PERFIL_GOLPE]*/}

				{/*[BEGIN_ROUTE_ANS_PERFIL_AVANCO]*/}
				<Route path="CadAnsPerfilAvanco" element={<FormAnsPerfilAvanco />} />
				<Route path="CadAnsPerfilAvanco/:id" element={<FormAnsPerfilAvanco />} />
				<Route path="LstAnsPerfilAvanco" element={<ListAnsPerfilAvanco />} />
				{/*[END_ROUTE_ANS_PERFIL_AVANCO]*/}

				{/*[BEGIN_ROUTE_ANS_PERFIL_REVESTIMENTO]*/}
				<Route path="CadAnsPerfilRevestimento" element={<FormAnsPerfilRevestimento />} />
				<Route path="CadAnsPerfilRevestimento/:id" element={<FormAnsPerfilRevestimento />} />
				<Route path="LstAnsPerfilRevestimento" element={<ListAnsPerfilRevestimento />} />
				{/*[END_ROUTE_ANS_PERFIL_REVESTIMENTO]*/}

				{/*[BEGIN_ROUTE_CARGO]*/}
				<Route path="CadCargo" element={<FormCargo />} />
				<Route path="CadCargo/:id" element={<FormCargo />} />
				<Route path="LstCargo" element={<ListCargo />} />
				{/*[END_ROUTE_CARGO]*/}

				{/*[BEGIN_ROUTE_ANS_PERFIL_PESSOA]*/}
				<Route path="CadAnsPerfilPessoa" element={<FormAnsPerfilPessoa />} />
				<Route path="CadAnsPerfilPessoa/:id" element={<FormAnsPerfilPessoa />} />
				<Route path="LstAnsPerfilPessoa" element={<ListAnsPerfilPessoa />} />
				{/*[END_ROUTE_ANS_PERFIL_PESSOA]*/}

				{/*[BEGIN_ROUTE_FATURA]*/}
				<Route path="CadFatura" element={<FormFatura />} />
				<Route path="CadFatura/:id" element={<FormFatura />} />
				<Route path="LstFatura" element={<ListFatura />} />
				{/*[END_ROUTE_FATURA]*/}

				{/*[BEGIN_ROUTE_FATURA_COBRANCA]*/}
				<Route path="CadFaturaCobranca" element={<FormFaturaCobranca />} />
				<Route path="CadFaturaCobranca/:id" element={<FormFaturaCobranca />} />
				<Route path="LstFaturaCobranca" element={<ListFaturaCobranca />} />
				{/*[END_ROUTE_FATURA_COBRANCA]*/}

				{/*[BEGIN_ROUTE_FATURA_ORDEM]*/}
				<Route path="CadFaturaOrdem" element={<FormFaturaOrdem />} />
				<Route path="CadFaturaOrdem/:id" element={<FormFaturaOrdem />} />
				<Route path="LstFaturaOrdem" element={<ListFaturaOrdem />} />
				{/*[END_ROUTE_FATURA_ORDEM]*/}

				{/*[BEGIN_ROUTE_FATURA_ENDERECO]*/}
				<Route path="CadFaturaEndereco" element={<FormFaturaEndereco />} />
				<Route path="CadFaturaEndereco/:id" element={<FormFaturaEndereco />} />
				<Route path="LstFaturaEndereco" element={<ListFaturaEndereco />} />
				{/*[END_ROUTE_FATURA_ENDERECO]*/}

				{/*[BEGIN_ROUTE_FATURA_COBRANCA_TIPO_PAGAMENTO]*/}
				<Route path="CadFaturaCobrancaTipoPagamento" element={<FormFaturaCobrancaTipoPagamento />} />
				<Route path="CadFaturaCobrancaTipoPagamento/:id" element={<FormFaturaCobrancaTipoPagamento />} />
				<Route path="LstFaturaCobrancaTipoPagamento" element={<ListFaturaCobrancaTipoPagamento />} />
				{/*[END_ROUTE_FATURA_COBRANCA_TIPO_PAGAMENTO]*/}

				{/*[BEGIN_ROUTE_FATURA_NOTA_FISCAL]*/}
				<Route path="CadFaturaNotaFiscal" element={<FormFaturaNotaFiscal />} />
				<Route path="CadFaturaNotaFiscal/:id" element={<FormFaturaNotaFiscal />} />
				<Route path="LstFaturaNotaFiscal" element={<ListFaturaNotaFiscal />} />
				{/*[END_ROUTE_FATURA_NOTA_FISCAL]*/}

				{/*[BEGIN_ROUTE_ORDEM_HISTORICO]*/}
				<Route path="CadOrdemHistorico" element={<FormOrdemHistorico />} />
				<Route path="CadOrdemHistorico/:id" element={<FormOrdemHistorico />} />
				<Route path="LstOrdemHistorico" element={<ListOrdemHistorico />} />
				{/*[END_ROUTE_ORDEM_HISTORICO]*/}

				{/*[BEGIN_ROUTE_RECURSO_ORDEM]*/}
				<Route path="CadRecursoOrdem" element={<FormRecursoOrdem />} />
				<Route path="CadRecursoOrdem/:id" element={<FormRecursoOrdem />} />
				<Route path="LstRecursoOrdem" element={<ListRecursoOrdem />} />
				{/*[END_ROUTE_RECURSO_ORDEM]*/}

				{/*[BEGIN_ROUTE_TIPO_ORDEM_RECURSO_ORDEM]*/}
				<Route path="CadTipoOrdemRecursoOrdem" element={<FormTipoOrdemRecursoOrdem />} />
				<Route path="CadTipoOrdemRecursoOrdem/:id" element={<FormTipoOrdemRecursoOrdem />} />
				<Route path="LstTipoOrdemRecursoOrdem" element={<ListTipoOrdemRecursoOrdem />} />
				{/*[END_ROUTE_TIPO_ORDEM_RECURSO_ORDEM]*/}

				{/*[BEGIN_ROUTE_STATUS_FATURA]*/}
				<Route path="CadStatusFatura" element={<FormStatusFatura />} />
				<Route path="CadStatusFatura/:id" element={<FormStatusFatura />} />
				<Route path="LstStatusFatura" element={<ListStatusFatura />} />
				{/*[END_ROUTE_STATUS_FATURA]*/}

				{/*[BEGIN_ROUTE_BANCO]*/}
				<Route path="CadBanco" element={<FormBanco />} />
				<Route path="CadBanco/:id" element={<FormBanco />} />
				<Route path="LstBanco" element={<ListBanco />} />
				{/*[END_ROUTE_BANCO]*/}

				{/*[BEGIN_ROUTE_CONTA]*/}
				<Route path="CadConta" element={<FormConta />} />
				<Route path="CadConta/:id" element={<FormConta />} />
				<Route path="LstConta" element={<ListConta />} />
				{/*[END_ROUTE_CONTA]*/}

				{/*[BEGIN_ROUTE_CONTA_TIPO_PAGAMENTO]*/}
				<Route path="CadContaTipoPagamento" element={<FormContaTipoPagamento />} />
				<Route path="CadContaTipoPagamento/:id" element={<FormContaTipoPagamento />} />
				<Route path="LstContaTipoPagamento" element={<ListContaTipoPagamento />} />
				{/*[END_ROUTE_CONTA_TIPO_PAGAMENTO]*/}

				{/*[BEGIN_ROUTE_TIPO_BAIXA]*/}
				<Route path="CadTipoBaixa" element={<FormTipoBaixa />} />
				<Route path="CadTipoBaixa/:id" element={<FormTipoBaixa />} />
				<Route path="LstTipoBaixa" element={<ListTipoBaixa />} />
				{/*[END_ROUTE_TIPO_BAIXA]*/}

				{/*[BEGIN_ROUTE_FATURA_COBRANCA_BAIXA]*/}
				<Route path="CadFaturaCobrancaBaixa" element={<FormFaturaCobrancaBaixa />} />
				<Route path="CadFaturaCobrancaBaixa/:id" element={<FormFaturaCobrancaBaixa />} />
				<Route path="LstFaturaCobrancaBaixa" element={<ListFaturaCobrancaBaixa />} />
				{/*[END_ROUTE_FATURA_COBRANCA_BAIXA]*/}

				{/*[BEGIN_ROUTE_FATURA_COBRANCA_TRANSACAO]*/}
				<Route path="CadFaturaCobrancaTransacao" element={<FormFaturaCobrancaTransacao />} />
				<Route path="CadFaturaCobrancaTransacao/:id" element={<FormFaturaCobrancaTransacao />} />
				<Route path="LstFaturaCobrancaTransacao" element={<ListFaturaCobrancaTransacao />} />
				{/*[END_ROUTE_FATURA_COBRANCA_TRANSACAO]*/}

				{/*[BEGIN_ROUTE_STATUS_FATURA_COBRANCA]*/}
				<Route path="CadStatusFaturaCobranca" element={<FormStatusFaturaCobranca />} />
				<Route path="CadStatusFaturaCobranca/:id" element={<FormStatusFaturaCobranca />} />
				<Route path="LstStatusFaturaCobranca" element={<ListStatusFaturaCobranca />} />
				{/*[END_ROUTE_STATUS_FATURA_COBRANCA]*/}

				{/*[BEGIN_ROUTE_FATURA_COBRANCA_BOLETO]*/}
				<Route path="CadFaturaCobrancaBoleto" element={<FormFaturaCobrancaBoleto />} />
				<Route path="CadFaturaCobrancaBoleto/:id" element={<FormFaturaCobrancaBoleto />} />
				<Route path="LstFaturaCobrancaBoleto" element={<ListFaturaCobrancaBoleto />} />
				{/*[END_ROUTE_FATURA_COBRANCA_BOLETO]*/}

				{/*[BEGIN_ROUTE_BLOCO]*/}
				<Route path="CadBloco" element={<FormBloco />} />
				<Route path="CadBloco/:id" element={<FormBloco />} />
				<Route path="LstBloco" element={<ListBloco />} />
				{/*[END_ROUTE_BLOCO]*/}

				{/*[BEGIN_ROUTE_PAINEL]*/}
				<Route path="CadPainel" element={<FormPainel />} />
				<Route path="CadPainel/:id" element={<FormPainel />} />
				<Route path="LstPainel" element={<ListPainel />} />
				{/*[END_ROUTE_PAINEL]*/}

				{/*[BEGIN_ROUTE_TIPO_BLOCO]*/}
				<Route path="CadTipoBloco" element={<FormTipoBloco />} />
				<Route path="CadTipoBloco/:id" element={<FormTipoBloco />} />
				<Route path="LstTipoBloco" element={<ListTipoBloco />} />
				{/*[END_ROUTE_TIPO_BLOCO]*/}

				{/*[BEGIN_ROUTE_MODELO_BLOCO]*/}
				<Route path="CadModeloBloco" element={<FormModeloBloco />} />
				<Route path="CadModeloBloco/:id" element={<FormModeloBloco />} />
				<Route path="LstModeloBloco" element={<ListModeloBloco />} />
				{/*[END_ROUTE_MODELO_BLOCO]*/}

				{/*[BEGIN_ROUTE_TIPO_COMANDO]*/}
				<Route path="CadTipoComando" element={<FormTipoComando />} />
				<Route path="CadTipoComando/:id" element={<FormTipoComando />} />
				<Route path="LstTipoComando" element={<ListTipoComando />} />
				{/*[END_ROUTE_TIPO_COMANDO]*/}

				{/*[BEGIN_ROUTE_GRUPO_PAINEL]*/}
				<Route path="CadGrupoPainel" element={<FormGrupoPainel />} />
				<Route path="CadGrupoPainel/:id" element={<FormGrupoPainel />} />
				<Route path="LstGrupoPainel" element={<ListGrupoPainel />} />
				{/*[END_ROUTE_GRUPO_PAINEL]*/}

				{/*[BEGIN_ROUTE_TIPO_FILTRO]*/}
				<Route path="CadTipoFiltro" element={<FormTipoFiltro />} />
				<Route path="CadTipoFiltro/:id" element={<FormTipoFiltro />} />
				<Route path="LstTipoFiltro" element={<ListTipoFiltro />} />
				{/*[END_ROUTE_TIPO_FILTRO]*/}

				{/*[BEGIN_ROUTE_ARQUIVO]*/}
				<Route path="CadArquivo" element={<FormArquivo />} />
				<Route path="CadArquivo/:id" element={<FormArquivo />} />
				<Route path="LstArquivo" element={<ListArquivo />} />
				{/*[END_ROUTE_ARQUIVO]*/}

				{/*[BEGIN_ROUTE_ARQUIVO_BASE]*/}
				<Route path="CadArquivoBase" element={<FormArquivoBase />} />
				<Route path="CadArquivoBase/:id" element={<FormArquivoBase />} />
				<Route path="LstArquivoBase" element={<ListArquivoBase />} />
				{/*[END_ROUTE_ARQUIVO_BASE]*/}

				{/*[BEGIN_ROUTE_TIPO_PAGINA]*/}
				<Route path="CadTipoPagina" element={<FormTipoPagina />} />
				<Route path="CadTipoPagina/:id" element={<FormTipoPagina />} />
				<Route path="LstTipoPagina" element={<ListTipoPagina />} />
				{/*[END_ROUTE_TIPO_PAGINA]*/}

				{/*[BEGIN_ROUTE_PESSOA_PAGINA]*/}
				<Route path="CadPessoaPagina" element={<FormPessoaPagina />} />
				<Route path="CadPessoaPagina/:id" element={<FormPessoaPagina />} />
				<Route path="LstPessoaPagina" element={<ListPessoaPagina />} />
				{/*[END_ROUTE_PESSOA_PAGINA]*/}

				{/*[BEGIN_ROUTE_TIPO_NOTIFICACAO]*/}
				<Route path="CadTipoNotificacao" element={<FormTipoNotificacao />} />
				<Route path="CadTipoNotificacao/:id" element={<FormTipoNotificacao />} />
				<Route path="LstTipoNotificacao" element={<ListTipoNotificacao />} />
				{/*[END_ROUTE_TIPO_NOTIFICACAO]*/}

				{/*[BEGIN_ROUTE_USUARIO_TIPO_NOTIFICACAO]*/}
				<Route path="CadUsuarioTipoNotificacao" element={<FormUsuarioTipoNotificacao />} />
				<Route path="CadUsuarioTipoNotificacao/:id" element={<FormUsuarioTipoNotificacao />} />
				<Route path="LstUsuarioTipoNotificacao" element={<ListUsuarioTipoNotificacao />} />
				{/*[END_ROUTE_USUARIO_TIPO_NOTIFICACAO]*/}

				{/*[BEGIN_ROUTE_NOTIFICACAO_SISTEMA]*/}
				<Route path="CadNotificacaoSistema" element={<FormNotificacaoSistema />} />
				<Route path="CadNotificacaoSistema/:id" element={<FormNotificacaoSistema />} />
				<Route path="LstNotificacaoSistema" element={<ListNotificacaoSistema />} />
				{/*[END_ROUTE_NOTIFICACAO_SISTEMA]*/}

				{/*[BEGIN_ROUTE_NOTIFICACAO_EMAIL]*/}
				<Route path="CadNotificacaoEmail" element={<FormNotificacaoEmail />} />
				<Route path="CadNotificacaoEmail/:id" element={<FormNotificacaoEmail />} />
				<Route path="LstNotificacaoEmail" element={<ListNotificacaoEmail />} />
				{/*[END_ROUTE_NOTIFICACAO_EMAIL]*/}

				{/*[BEGIN_ROUTE_NOTIFICACAO_WHATSAPP]*/}
				<Route path="CadNotificacaoWhatsapp" element={<FormNotificacaoWhatsapp />} />
				<Route path="CadNotificacaoWhatsapp/:id" element={<FormNotificacaoWhatsapp />} />
				<Route path="LstNotificacaoWhatsapp" element={<ListNotificacaoWhatsapp />} />
				{/*[END_ROUTE_NOTIFICACAO_WHATSAPP]*/}

				{/*[BEGIN_ROUTE_ANS_CARACTERISTICA_SOLO]*/}
				<Route path="CadAnsCaracteristicaSolo" element={<FormAnsCaracteristicaSolo />} />
				<Route path="CadAnsCaracteristicaSolo/:id" element={<FormAnsCaracteristicaSolo />} />
				<Route path="LstAnsCaracteristicaSolo" element={<ListAnsCaracteristicaSolo />} />
				{/*[END_ROUTE_ANS_CARACTERISTICA_SOLO]*/}

				{/*[BEGIN_ROUTE_ANS_CARACTERISTICA_MATERIAL]*/}
				<Route path="CadAnsCaracteristicaMaterial" element={<FormAnsCaracteristicaMaterial />} />
				<Route path="CadAnsCaracteristicaMaterial/:id" element={<FormAnsCaracteristicaMaterial />} />
				<Route path="LstAnsCaracteristicaMaterial" element={<ListAnsCaracteristicaMaterial />} />
				{/*[END_ROUTE_ANS_CARACTERISTICA_MATERIAL]*/}

				{/*[BEGIN_ROUTE_TIPO_ANS_PERFIL]*/}
				<Route path="CadTipoAnsPerfil" element={<FormTipoAnsPerfil />} />
				<Route path="CadTipoAnsPerfil/:id" element={<FormTipoAnsPerfil />} />
				<Route path="LstTipoAnsPerfil" element={<ListTipoAnsPerfil />} />
				{/*[END_ROUTE_TIPO_ANS_PERFIL]*/}

				{/*[BEGIN_ROUTE_ANS_CARGO]*/}
				<Route path="CadAnsCargo" element={<FormAnsCargo />} />
				<Route path="CadAnsCargo/:id" element={<FormAnsCargo />} />
				<Route path="LstAnsCargo" element={<ListAnsCargo />} />
				{/*[END_ROUTE_ANS_CARGO]*/}

				{/*[BEGIN_ROUTE_ANS_PERFIL_FOTO]*/}
				<Route path="CadAnsPerfilFoto" element={<FormAnsPerfilFoto />} />
				<Route path="CadAnsPerfilFoto/:id" element={<FormAnsPerfilFoto />} />
				<Route path="LstAnsPerfilFoto" element={<ListAnsPerfilFoto />} />
				{/*[END_ROUTE_ANS_PERFIL_FOTO]*/}

				{/*[BEGIN_ROUTE_DADO]*/}
				<Route path="CadDado" element={<FormDado />} />
				<Route path="CadDado/:id" element={<FormDado />} />
				<Route path="LstDado" element={<ListDado />} />
				{/*[END_ROUTE_DADO]*/}

				{/*[BEGIN_ROUTE_TIPO_DADO]*/}
				<Route path="CadTipoDado" element={<FormTipoDado />} />
				<Route path="CadTipoDado/:id" element={<FormTipoDado />} />
				<Route path="LstTipoDado" element={<ListTipoDado />} />
				{/*[END_ROUTE_TIPO_DADO]*/}

				{/*[BEGIN_ROUTE_DADO_VALOR]*/}
				<Route path="CadDadoValor" element={<FormDadoValor />} />
				<Route path="CadDadoValor/:id" element={<FormDadoValor />} />
				<Route path="LstDadoValor" element={<ListDadoValor />} />
				{/*[END_ROUTE_DADO_VALOR]*/}

				{/*[BEGIN_ROUTE_DADO_LOCAL]*/}
				<Route path="CadDadoLocal" element={<FormDadoLocal />} />
				<Route path="CadDadoLocal/:id" element={<FormDadoLocal />} />
				<Route path="LstDadoLocal" element={<ListDadoLocal />} />
				{/*[END_ROUTE_DADO_LOCAL]*/}

				{/*[BEGIN_ROUTE_PESSOA_DADO_VALOR]*/}
				<Route path="CadPessoaDadoValor" element={<FormPessoaDadoValor />} />
				<Route path="CadPessoaDadoValor/:id" element={<FormPessoaDadoValor />} />
				<Route path="LstPessoaDadoValor" element={<ListPessoaDadoValor />} />
				{/*[END_ROUTE_PESSOA_DADO_VALOR]*/}

				{/*[BEGIN_ROUTE_ANS_PERFIL_PLANTA]*/}
				<Route path="CadAnsPerfilPlanta" element={<FormAnsPerfilPlanta />} />
				<Route path="CadAnsPerfilPlanta/:id" element={<FormAnsPerfilPlanta />} />
				<Route path="LstAnsPerfilPlanta" element={<ListAnsPerfilPlanta />} />
				{/*[END_ROUTE_ANS_PERFIL_PLANTA]*/}

				{/*[BEGIN_ROUTE_TIPO_BAIRRO]*/}
				<Route path="CadTipoBairro" element={<FormTipoBairro />} />
				<Route path="CadTipoBairro/:id" element={<FormTipoBairro />} />
				<Route path="LstTipoBairro" element={<ListTipoBairro />} />
				{/*[END_ROUTE_TIPO_BAIRRO]*/}

				{/*[BEGIN_ROUTE_TIPO_LOGRADOURO]*/}
				<Route path="CadTipoLogradouro" element={<FormTipoLogradouro />} />
				<Route path="CadTipoLogradouro/:id" element={<FormTipoLogradouro />} />
				<Route path="LstTipoLogradouro" element={<ListTipoLogradouro />} />
				{/*[END_ROUTE_TIPO_LOGRADOURO]*/}

				{/*[BEGIN_ROUTE_STATUS_NOTA_FISCAL]*/}
				<Route path="CadStatusNotaFiscal" element={<FormStatusNotaFiscal />} />
				<Route path="CadStatusNotaFiscal/:id" element={<FormStatusNotaFiscal />} />
				<Route path="LstStatusNotaFiscal" element={<ListStatusNotaFiscal />} />
				{/*[END_ROUTE_STATUS_NOTA_FISCAL]*/}

				{/*[BEGIN_ROUTE_FATURA_NOTA_FISCAL_LOG]*/}
				<Route path="CadFaturaNotaFiscalLog" element={<FormFaturaNotaFiscalLog />} />
				<Route path="CadFaturaNotaFiscalLog/:id" element={<FormFaturaNotaFiscalLog />} />
				<Route path="LstFaturaNotaFiscalLog" element={<ListFaturaNotaFiscalLog />} />
				{/*[END_ROUTE_FATURA_NOTA_FISCAL_LOG]*/}

				{/*[BEGIN_ROUTE_UNIDADE_DADO_VALOR]*/}
				<Route path="CadUnidadeDadoValor" element={<FormUnidadeDadoValor />} />
				<Route path="CadUnidadeDadoValor/:id" element={<FormUnidadeDadoValor />} />
				<Route path="LstUnidadeDadoValor" element={<ListUnidadeDadoValor />} />
				{/*[END_ROUTE_UNIDADE_DADO_VALOR]*/}

				{/*[BEGIN_ROUTE_TIPO_ARQUIVO_NOTA_FISCAL]*/}
				<Route path="CadTipoArquivoNotaFiscal" element={<FormTipoArquivoNotaFiscal />} />
				<Route path="CadTipoArquivoNotaFiscal/:id" element={<FormTipoArquivoNotaFiscal />} />
				<Route path="LstTipoArquivoNotaFiscal" element={<ListTipoArquivoNotaFiscal />} />
				{/*[END_ROUTE_TIPO_ARQUIVO_NOTA_FISCAL]*/}

				{/*[BEGIN_ROUTE_FATURA_NOTA_FISCAL_ARQUIVO]*/}
				<Route path="CadFaturaNotaFiscalArquivo" element={<FormFaturaNotaFiscalArquivo />} />
				<Route path="CadFaturaNotaFiscalArquivo/:id" element={<FormFaturaNotaFiscalArquivo />} />
				<Route path="LstFaturaNotaFiscalArquivo" element={<ListFaturaNotaFiscalArquivo />} />
				{/*[END_ROUTE_FATURA_NOTA_FISCAL_ARQUIVO]*/}

				{/*[BEGIN_ROUTE_ORDEM_ENDERECO]*/}
				<Route path="CadOrdemEndereco" element={<FormOrdemEndereco />} />
				<Route path="CadOrdemEndereco/:id" element={<FormOrdemEndereco />} />
				<Route path="LstOrdemEndereco" element={<ListOrdemEndereco />} />
				{/*[END_ROUTE_ORDEM_ENDERECO]*/}

				{/*[BEGIN_ROUTE_NATUREZA_TRIBUTACAO_NOTA_FISCAL]*/}
				<Route path="CadNaturezaTributacaoNotaFiscal" element={<FormNaturezaTributacaoNotaFiscal />} />
				<Route path="CadNaturezaTributacaoNotaFiscal/:id" element={<FormNaturezaTributacaoNotaFiscal />} />
				<Route path="LstNaturezaTributacaoNotaFiscal" element={<ListNaturezaTributacaoNotaFiscal />} />
				{/*[END_ROUTE_NATUREZA_TRIBUTACAO_NOTA_FISCAL]*/}

				{/*[BEGIN_ROUTE_TIPO_REGIME_TRIBUTARIO]*/}
				<Route path="CadTipoRegimeTributario" element={<FormTipoRegimeTributario />} />
				<Route path="CadTipoRegimeTributario/:id" element={<FormTipoRegimeTributario />} />
				<Route path="LstTipoRegimeTributario" element={<ListTipoRegimeTributario />} />
				{/*[END_ROUTE_TIPO_REGIME_TRIBUTARIO]*/}

				{/*[BEGIN_ROUTE_TIPO_REGIME_TRIBUTARIO_ESPECIAL]*/}
				<Route path="CadTipoRegimeTributarioEspecial" element={<FormTipoRegimeTributarioEspecial />} />
				<Route path="CadTipoRegimeTributarioEspecial/:id" element={<FormTipoRegimeTributarioEspecial />} />
				<Route path="LstTipoRegimeTributarioEspecial" element={<ListTipoRegimeTributarioEspecial />} />
				{/*[END_ROUTE_TIPO_REGIME_TRIBUTARIO_ESPECIAL]*/}

				{/*[BEGIN_ROUTE_PESSOA_ATIVIDADE]*/}
				<Route path="CadPessoaAtividade" element={<FormPessoaAtividade />} />
				<Route path="CadPessoaAtividade/:id" element={<FormPessoaAtividade />} />
				<Route path="LstPessoaAtividade" element={<ListPessoaAtividade />} />
				{/*[END_ROUTE_PESSOA_ATIVIDADE]*/}

				{/*[BEGIN_ROUTE_PESSOA_INSCRICAO_ESTADUAL]*/}
				<Route path="CadPessoaInscricaoEstadual" element={<FormPessoaInscricaoEstadual />} />
				<Route path="CadPessoaInscricaoEstadual/:id" element={<FormPessoaInscricaoEstadual />} />
				<Route path="LstPessoaInscricaoEstadual" element={<ListPessoaInscricaoEstadual />} />
				{/*[END_ROUTE_PESSOA_INSCRICAO_ESTADUAL]*/}

				{/*[BEGIN_ROUTE_FATURA_NOTA_FISCAL_HISTORICO]*/}
				<Route path="CadFaturaNotaFiscalHistorico" element={<FormFaturaNotaFiscalHistorico />} />
				<Route path="CadFaturaNotaFiscalHistorico/:id" element={<FormFaturaNotaFiscalHistorico />} />
				<Route path="LstFaturaNotaFiscalHistorico" element={<ListFaturaNotaFiscalHistorico />} />
				{/*[END_ROUTE_FATURA_NOTA_FISCAL_HISTORICO]*/}

				{/*[BEGIN_ROUTE_PRODUTO_TIPO_ORDEM]*/}
				<Route path="CadProdutoTipoOrdem" element={<FormProdutoTipoOrdem />} />
				<Route path="CadProdutoTipoOrdem/:id" element={<FormProdutoTipoOrdem />} />
				<Route path="LstProdutoTipoOrdem" element={<ListProdutoTipoOrdem />} />
				{/*[END_ROUTE_PRODUTO_TIPO_ORDEM]*/}

				{/*[BEGIN_ROUTE_GRUPO_PRODUTO_TIPO_ORDEM]*/}
				<Route path="CadGrupoProdutoTipoOrdem" element={<FormGrupoProdutoTipoOrdem />} />
				<Route path="CadGrupoProdutoTipoOrdem/:id" element={<FormGrupoProdutoTipoOrdem />} />
				<Route path="LstGrupoProdutoTipoOrdem" element={<ListGrupoProdutoTipoOrdem />} />
				{/*[END_ROUTE_GRUPO_PRODUTO_TIPO_ORDEM]*/}

				{/*[BEGIN_ROUTE_CONTA_DADO_VALOR]*/}
				<Route path="CadContaDadoValor" element={<FormContaDadoValor />} />
				<Route path="CadContaDadoValor/:id" element={<FormContaDadoValor />} />
				<Route path="LstContaDadoValor" element={<ListContaDadoValor />} />
				{/*[END_ROUTE_CONTA_DADO_VALOR]*/}

				{/*[BEGIN_ROUTE_DADO_LOCAL_GRUPO]*/}
				<Route path="CadDadoLocalGrupo" element={<FormDadoLocalGrupo />} />
				<Route path="CadDadoLocalGrupo/:id" element={<FormDadoLocalGrupo />} />
				<Route path="LstDadoLocalGrupo" element={<ListDadoLocalGrupo />} />
				{/*[END_ROUTE_DADO_LOCAL_GRUPO]*/}

				{/*[BEGIN_ROUTE_FATURA_NOTA_FISCAL_ITEM]*/}
				<Route path="CadFaturaNotaFiscalItem" element={<FormFaturaNotaFiscalItem />} />
				<Route path="CadFaturaNotaFiscalItem/:id" element={<FormFaturaNotaFiscalItem />} />
				<Route path="LstFaturaNotaFiscalItem" element={<ListFaturaNotaFiscalItem />} />
				{/*[END_ROUTE_FATURA_NOTA_FISCAL_ITEM]*/}

				{/*[BEGIN_ROUTE_TIPO_TRIBUTACAO_SERVICO]*/}
				<Route path="CadTipoTributacaoServico" element={<FormTipoTributacaoServico />} />
				<Route path="CadTipoTributacaoServico/:id" element={<FormTipoTributacaoServico />} />
				<Route path="LstTipoTributacaoServico" element={<ListTipoTributacaoServico />} />
				{/*[END_ROUTE_TIPO_TRIBUTACAO_SERVICO]*/}

				{/*[BEGIN_ROUTE_TIPO_EXIGIBILIDADE_SERVICO]*/}
				<Route path="CadTipoExigibilidadeServico" element={<FormTipoExigibilidadeServico />} />
				<Route path="CadTipoExigibilidadeServico/:id" element={<FormTipoExigibilidadeServico />} />
				<Route path="LstTipoExigibilidadeServico" element={<ListTipoExigibilidadeServico />} />
				{/*[END_ROUTE_TIPO_EXIGIBILIDADE_SERVICO]*/}

				{/*[BEGIN_ROUTE_FATURA_NOTA_FISCAL_ITEM_ISS]*/}
				<Route path="CadFaturaNotaFiscalItemIss" element={<FormFaturaNotaFiscalItemIss />} />
				<Route path="CadFaturaNotaFiscalItemIss/:id" element={<FormFaturaNotaFiscalItemIss />} />
				<Route path="LstFaturaNotaFiscalItemIss" element={<ListFaturaNotaFiscalItemIss />} />
				{/*[END_ROUTE_FATURA_NOTA_FISCAL_ITEM_ISS]*/}

				{/*[BEGIN_ROUTE_FATURA_NOTA_FISCAL_ITEM_RETENCAO]*/}
				<Route path="CadFaturaNotaFiscalItemRetencao" element={<FormFaturaNotaFiscalItemRetencao />} />
				<Route path="CadFaturaNotaFiscalItemRetencao/:id" element={<FormFaturaNotaFiscalItemRetencao />} />
				<Route path="LstFaturaNotaFiscalItemRetencao" element={<ListFaturaNotaFiscalItemRetencao />} />
				{/*[END_ROUTE_FATURA_NOTA_FISCAL_ITEM_RETENCAO]*/}

				{/*[BEGIN_ROUTE_FATURA_NOTA_FISCAL_ITEM_OBRA]*/}
				<Route path="CadFaturaNotaFiscalItemObra" element={<FormFaturaNotaFiscalItemObra />} />
				<Route path="CadFaturaNotaFiscalItemObra/:id" element={<FormFaturaNotaFiscalItemObra />} />
				<Route path="LstFaturaNotaFiscalItemObra" element={<ListFaturaNotaFiscalItemObra />} />
				{/*[END_ROUTE_FATURA_NOTA_FISCAL_ITEM_OBRA]*/}

				{/*[BEGIN_ROUTE_TIPO_NOTA_FISCAL]*/}
				<Route path="CadTipoNotaFiscal" element={<FormTipoNotaFiscal />} />
				<Route path="CadTipoNotaFiscal/:id" element={<FormTipoNotaFiscal />} />
				<Route path="LstTipoNotaFiscal" element={<ListTipoNotaFiscal />} />
				{/*[END_ROUTE_TIPO_NOTA_FISCAL]*/}

				{/*[BEGIN_ROUTE_UNIDADE_TIPO_NOTA_FISCAL]*/}
				<Route path="CadUnidadeTipoNotaFiscal" element={<FormUnidadeTipoNotaFiscal />} />
				<Route path="CadUnidadeTipoNotaFiscal/:id" element={<FormUnidadeTipoNotaFiscal />} />
				<Route path="LstUnidadeTipoNotaFiscal" element={<ListUnidadeTipoNotaFiscal />} />
				{/*[END_ROUTE_UNIDADE_TIPO_NOTA_FISCAL]*/}

				{/*[BEGIN_ROUTE_PLUGIN]*/}
				<Route path="CadPlugin" element={<FormPlugin />} />
				<Route path="CadPlugin/:id" element={<FormPlugin />} />
				<Route path="LstPlugin" element={<ListPlugin />} />
				{/*[END_ROUTE_PLUGIN]*/}

				{/*[BEGIN_ROUTE_PLUGIN_DADO_VALOR]*/}
				<Route path="CadPluginDadoValor" element={<FormPluginDadoValor />} />
				<Route path="CadPluginDadoValor/:id" element={<FormPluginDadoValor />} />
				<Route path="LstPluginDadoValor" element={<ListPluginDadoValor />} />
				{/*[END_ROUTE_PLUGIN_DADO_VALOR]*/}

				{/*[BEGIN_ROUTE_PRODUTO_DADO_VALOR]*/}
				<Route path="CadProdutoDadoValor" element={<FormProdutoDadoValor />} />
				<Route path="CadProdutoDadoValor/:id" element={<FormProdutoDadoValor />} />
				<Route path="LstProdutoDadoValor" element={<ListProdutoDadoValor />} />
				{/*[END_ROUTE_PRODUTO_DADO_VALOR]*/}

				{/*[BEGIN_ROUTE_TIPO_ORDEM_DADO_VALOR]*/}
				<Route path="CadTipoOrdemDadoValor" element={<FormTipoOrdemDadoValor />} />
				<Route path="CadTipoOrdemDadoValor/:id" element={<FormTipoOrdemDadoValor />} />
				<Route path="LstTipoOrdemDadoValor" element={<ListTipoOrdemDadoValor />} />
				{/*[END_ROUTE_TIPO_ORDEM_DADO_VALOR]*/}

				{/*[BEGIN_ROUTE_CLASSIFICACAO_PRODUTO_DADO_VALOR]*/}
				<Route path="CadClassificacaoProdutoDadoValor" element={<FormClassificacaoProdutoDadoValor />} />
				<Route path="CadClassificacaoProdutoDadoValor/:id" element={<FormClassificacaoProdutoDadoValor />} />
				<Route path="LstClassificacaoProdutoDadoValor" element={<ListClassificacaoProdutoDadoValor />} />
				{/*[END_ROUTE_CLASSIFICACAO_PRODUTO_DADO_VALOR]*/}

				{/*[BEGIN_ROUTE_FUNCIONALIDADE_PLUGIN]*/}
				<Route path="CadFuncionalidadePlugin" element={<FormFuncionalidadePlugin />} />
				<Route path="CadFuncionalidadePlugin/:id" element={<FormFuncionalidadePlugin />} />
				<Route path="LstFuncionalidadePlugin" element={<ListFuncionalidadePlugin />} />
				{/*[END_ROUTE_FUNCIONALIDADE_PLUGIN]*/}

				{/*[BEGIN_ROUTE_PLUGIN_FUNCIONALIDADE_PLUGIN]*/}
				<Route path="CadPluginFuncionalidadePlugin" element={<FormPluginFuncionalidadePlugin />} />
				<Route path="CadPluginFuncionalidadePlugin/:id" element={<FormPluginFuncionalidadePlugin />} />
				<Route path="LstPluginFuncionalidadePlugin" element={<ListPluginFuncionalidadePlugin />} />
				{/*[END_ROUTE_PLUGIN_FUNCIONALIDADE_PLUGIN]*/}

				{/*[BEGIN_ROUTE_FATURA_NOTA_FISCAL_INTERMEDIARIO]*/}
				<Route path="CadFaturaNotaFiscalIntermediario" element={<FormFaturaNotaFiscalIntermediario />} />
				<Route path="CadFaturaNotaFiscalIntermediario/:id" element={<FormFaturaNotaFiscalIntermediario />} />
				<Route path="LstFaturaNotaFiscalIntermediario" element={<ListFaturaNotaFiscalIntermediario />} />
				{/*[END_ROUTE_FATURA_NOTA_FISCAL_INTERMEDIARIO]*/}

				{/*[BEGIN_ROUTE_FATURA_NOTA_FISCAL_DEDUCAO]*/}
				<Route path="CadFaturaNotaFiscalDeducao" element={<FormFaturaNotaFiscalDeducao />} />
				<Route path="CadFaturaNotaFiscalDeducao/:id" element={<FormFaturaNotaFiscalDeducao />} />
				<Route path="LstFaturaNotaFiscalDeducao" element={<ListFaturaNotaFiscalDeducao />} />
				{/*[END_ROUTE_FATURA_NOTA_FISCAL_DEDUCAO]*/}

				{/*[BEGIN_ROUTE_FATURA_NOTA_FISCAL_CARGA_TRIBUTARIA]*/}
				<Route path="CadFaturaNotaFiscalCargaTributaria" element={<FormFaturaNotaFiscalCargaTributaria />} />
				<Route path="CadFaturaNotaFiscalCargaTributaria/:id" element={<FormFaturaNotaFiscalCargaTributaria />} />
				<Route path="LstFaturaNotaFiscalCargaTributaria" element={<ListFaturaNotaFiscalCargaTributaria />} />
				{/*[END_ROUTE_FATURA_NOTA_FISCAL_CARGA_TRIBUTARIA]*/}

				{/*[BEGIN_ROUTE_FATURA_NOTA_FISCAL_DEDUCAO_ITEM]*/}
				<Route path="CadFaturaNotaFiscalDeducaoItem" element={<FormFaturaNotaFiscalDeducaoItem />} />
				<Route path="CadFaturaNotaFiscalDeducaoItem/:id" element={<FormFaturaNotaFiscalDeducaoItem />} />
				<Route path="LstFaturaNotaFiscalDeducaoItem" element={<ListFaturaNotaFiscalDeducaoItem />} />
				{/*[END_ROUTE_FATURA_NOTA_FISCAL_DEDUCAO_ITEM]*/}

				{/*[BEGIN_ROUTE_FATURA_NOTA_FISCAL_ENDERECO]*/}
				<Route path="CadFaturaNotaFiscalEndereco" element={<FormFaturaNotaFiscalEndereco />} />
				<Route path="CadFaturaNotaFiscalEndereco/:id" element={<FormFaturaNotaFiscalEndereco />} />
				<Route path="LstFaturaNotaFiscalEndereco" element={<ListFaturaNotaFiscalEndereco />} />
				{/*[END_ROUTE_FATURA_NOTA_FISCAL_ENDERECO]*/}

				{/*[BEGIN_ROUTE_EMPRESA_DADO_VALOR]*/}
				<Route path="CadEmpresaDadoValor" element={<FormEmpresaDadoValor />} />
				<Route path="CadEmpresaDadoValor/:id" element={<FormEmpresaDadoValor />} />
				<Route path="LstEmpresaDadoValor" element={<ListEmpresaDadoValor />} />
				{/*[END_ROUTE_EMPRESA_DADO_VALOR]*/}

				{/*[BEGIN_ROUTE_CONFIGURACAO_DADO_VALOR]*/}
				<Route path="CadConfiguracaoDadoValor" element={<FormConfiguracaoDadoValor />} />
				<Route path="CadConfiguracaoDadoValor/:id" element={<FormConfiguracaoDadoValor />} />
				<Route path="LstConfiguracaoDadoValor" element={<ListConfiguracaoDadoValor />} />
				{/*[END_ROUTE_CONFIGURACAO_DADO_VALOR]*/}

				{/*[BEGIN_ROUTE_FATURA_COBRANCA_TIPO_PAGAMENTO_PARCELA]*/}
				<Route path="CadFaturaCobrancaTipoPagamentoParcela" element={<FormFaturaCobrancaTipoPagamentoParcela />} />
				<Route path="CadFaturaCobrancaTipoPagamentoParcela/:id" element={<FormFaturaCobrancaTipoPagamentoParcela />} />
				<Route path="LstFaturaCobrancaTipoPagamentoParcela" element={<ListFaturaCobrancaTipoPagamentoParcela />} />
				{/*[END_ROUTE_FATURA_COBRANCA_TIPO_PAGAMENTO_PARCELA]*/}

				{/*[BEGIN_ROUTE_FATURA_COBRANCA_PIX]*/}
				<Route path="CadFaturaCobrancaPix" element={<FormFaturaCobrancaPix />} />
				<Route path="CadFaturaCobrancaPix/:id" element={<FormFaturaCobrancaPix />} />
				<Route path="LstFaturaCobrancaPix" element={<ListFaturaCobrancaPix />} />
				{/*[END_ROUTE_FATURA_COBRANCA_PIX]*/}

				{/*[BEGIN_ROUTE_FATURA_COBRANCA_CARTAO]*/}
				<Route path="CadFaturaCobrancaCartao" element={<FormFaturaCobrancaCartao />} />
				<Route path="CadFaturaCobrancaCartao/:id" element={<FormFaturaCobrancaCartao />} />
				<Route path="LstFaturaCobrancaCartao" element={<ListFaturaCobrancaCartao />} />
				{/*[END_ROUTE_FATURA_COBRANCA_CARTAO]*/}

				{/*[BEGIN_ROUTE_WEBHOOK]*/}
				<Route path="CadWebhook" element={<FormWebhook />} />
				<Route path="CadWebhook/:id" element={<FormWebhook />} />
				<Route path="LstWebhook" element={<ListWebhook />} />
				{/*[END_ROUTE_WEBHOOK]*/}

				{/*[BEGIN_ROUTE_CONTA_TIPO_PAGAMENTO_DADO_VALOR]*/}
				<Route path="CadContaTipoPagamentoDadoValor" element={<FormContaTipoPagamentoDadoValor />} />
				<Route path="CadContaTipoPagamentoDadoValor/:id" element={<FormContaTipoPagamentoDadoValor />} />
				<Route path="LstContaTipoPagamentoDadoValor" element={<ListContaTipoPagamentoDadoValor />} />
				{/*[END_ROUTE_CONTA_TIPO_PAGAMENTO_DADO_VALOR]*/}

				{/*[BEGIN_ROUTE_LOG_REQUEST]*/}
				<Route path="CadLogRequest" element={<FormLogRequest />} />
				<Route path="CadLogRequest/:id" element={<FormLogRequest />} />
				<Route path="LstLogRequest" element={<ListLogRequest />} />
				{/*[END_ROUTE_LOG_REQUEST]*/}

				{/*[BEGIN_ROUTE_LOG_RESPONSE]*/}
				<Route path="CadLogResponse" element={<FormLogResponse />} />
				<Route path="CadLogResponse/:id" element={<FormLogResponse />} />
				<Route path="LstLogResponse" element={<ListLogResponse />} />
				{/*[END_ROUTE_LOG_RESPONSE]*/}

				{/*[BEGIN_ROUTE_FATURA_NOTA_FISCAL_MOTIVO_CANCELAMENTO]*/}
				<Route path="CadFaturaNotaFiscalMotivoCancelamento" element={<FormFaturaNotaFiscalMotivoCancelamento />} />
				<Route path="CadFaturaNotaFiscalMotivoCancelamento/:id" element={<FormFaturaNotaFiscalMotivoCancelamento />} />
				<Route path="LstFaturaNotaFiscalMotivoCancelamento" element={<ListFaturaNotaFiscalMotivoCancelamento />} />
				{/*[END_ROUTE_FATURA_NOTA_FISCAL_MOTIVO_CANCELAMENTO]*/}

				{/*[BEGIN_ROUTE_TIPO_FATURA]*/}
				<Route path="CadTipoFatura" element={<FormTipoFatura />} />
				<Route path="CadTipoFatura/:id" element={<FormTipoFatura />} />
				<Route path="LstTipoFatura" element={<ListTipoFatura />} />
				{/*[END_ROUTE_TIPO_FATURA]*/}

				{/*[BEGIN_ROUTE_FATURA_ACORDO]*/}
				<Route path="CadFaturaAcordo" element={<FormFaturaAcordo />} />
				<Route path="CadFaturaAcordo/:id" element={<FormFaturaAcordo />} />
				<Route path="LstFaturaAcordo" element={<ListFaturaAcordo />} />
				{/*[END_ROUTE_FATURA_ACORDO]*/}

				{/*[BEGIN_ROUTE_FATURA_ACORDO_FATURA_COBRANCA]*/}
				<Route path="CadFaturaAcordoFaturaCobranca" element={<FormFaturaAcordoFaturaCobranca />} />
				<Route path="CadFaturaAcordoFaturaCobranca/:id" element={<FormFaturaAcordoFaturaCobranca />} />
				<Route path="LstFaturaAcordoFaturaCobranca" element={<ListFaturaAcordoFaturaCobranca />} />
				{/*[END_ROUTE_FATURA_ACORDO_FATURA_COBRANCA]*/}

				{/*[BEGIN_ROUTE_STATUS_FATURA_ACORDO]*/}
				<Route path="CadStatusFaturaAcordo" element={<FormStatusFaturaAcordo />} />
				<Route path="CadStatusFaturaAcordo/:id" element={<FormStatusFaturaAcordo />} />
				<Route path="LstStatusFaturaAcordo" element={<ListStatusFaturaAcordo />} />
				{/*[END_ROUTE_STATUS_FATURA_ACORDO]*/}

				{/*[BEGIN_ROUTE_FATURA_COBRANCA_BOLETO_INSTRUCAO]*/}
				<Route path="CadFaturaCobrancaBoletoInstrucao" element={<FormFaturaCobrancaBoletoInstrucao />} />
				<Route path="CadFaturaCobrancaBoletoInstrucao/:id" element={<FormFaturaCobrancaBoletoInstrucao />} />
				<Route path="LstFaturaCobrancaBoletoInstrucao" element={<ListFaturaCobrancaBoletoInstrucao />} />
				{/*[END_ROUTE_FATURA_COBRANCA_BOLETO_INSTRUCAO]*/}

				{/*[BEGIN_ROUTE_TIPO_DESCONTO]*/}
				<Route path="CadTipoDesconto" element={<FormTipoDesconto />} />
				<Route path="CadTipoDesconto/:id" element={<FormTipoDesconto />} />
				<Route path="LstTipoDesconto" element={<ListTipoDesconto />} />
				{/*[END_ROUTE_TIPO_DESCONTO]*/}

				{/*[BEGIN_ROUTE_FATURA_DESCONTO]*/}
				<Route path="CadFaturaDesconto" element={<FormFaturaDesconto />} />
				<Route path="CadFaturaDesconto/:id" element={<FormFaturaDesconto />} />
				<Route path="LstFaturaDesconto" element={<ListFaturaDesconto />} />
				{/*[END_ROUTE_FATURA_DESCONTO]*/}

				{/*[BEGIN_ROUTE_ORDEM_DADO_VALOR]*/}
				<Route path="CadOrdemDadoValor" element={<FormOrdemDadoValor />} />
				<Route path="CadOrdemDadoValor/:id" element={<FormOrdemDadoValor />} />
				<Route path="LstOrdemDadoValor" element={<ListOrdemDadoValor />} />
				{/*[END_ROUTE_ORDEM_DADO_VALOR]*/}

				{/*[BEGIN_ROUTE_OBRA]*/}
				<Route path="CadObra" element={<FormObra />} />
				<Route path="CadObra/:id" element={<FormObra />} />
				<Route path="LstObra" element={<ListObra />} />
				{/*[END_ROUTE_OBRA]*/}

				{/*[BEGIN_ROUTE_VEICULO]*/}
				<Route path="CadVeiculo" element={<FormVeiculo />} />
				<Route path="CadVeiculo/:id" element={<FormVeiculo />} />
				<Route path="LstVeiculo" element={<ListVeiculo />} />
				{/*[END_ROUTE_VEICULO]*/}

				{/*[BEGIN_ROUTE_TIPO_VEICULO]*/}
				<Route path="CadTipoVeiculo" element={<FormTipoVeiculo />} />
				<Route path="CadTipoVeiculo/:id" element={<FormTipoVeiculo />} />
				<Route path="LstTipoVeiculo" element={<ListTipoVeiculo />} />
				{/*[END_ROUTE_TIPO_VEICULO]*/}

				{/*[BEGIN_ROUTE_USUARIO_BLOCO_FAVORITO]*/}
				<Route path="CadUsuarioBlocoFavorito" element={<FormUsuarioBlocoFavorito />} />
				<Route path="CadUsuarioBlocoFavorito/:id" element={<FormUsuarioBlocoFavorito />} />
				<Route path="LstUsuarioBlocoFavorito" element={<ListUsuarioBlocoFavorito />} />
				{/*[END_ROUTE_USUARIO_BLOCO_FAVORITO]*/}

				{/*[BEGIN_ROUTE_PESSOA_UNIDADE]*/}
				<Route path="CadPessoaUnidade" element={<FormPessoaUnidade />} />
				<Route path="CadPessoaUnidade/:id" element={<FormPessoaUnidade />} />
				<Route path="LstPessoaUnidade" element={<ListPessoaUnidade />} />
				{/*[END_ROUTE_PESSOA_UNIDADE]*/}

				{/*[BEGIN_ROUTE_PESSOA_VEICULO]*/}
				<Route path="CadPessoaVeiculo" element={<FormPessoaVeiculo />} />
				<Route path="CadPessoaVeiculo/:id" element={<FormPessoaVeiculo />} />
				<Route path="LstPessoaVeiculo" element={<ListPessoaVeiculo />} />
				{/*[END_ROUTE_PESSOA_VEICULO]*/}

				{/*[BEGIN_ROUTE_VEICULO_ODOMETRO]*/}
				<Route path="CadVeiculoOdometro" element={<FormVeiculoOdometro />} />
				<Route path="CadVeiculoOdometro/:id" element={<FormVeiculoOdometro />} />
				<Route path="LstVeiculoOdometro" element={<ListVeiculoOdometro />} />
				{/*[END_ROUTE_VEICULO_ODOMETRO]*/}

				{/*[BEGIN_ROUTE_VEICULO_LOCALIZACAO]*/}
				<Route path="CadVeiculoLocalizacao" element={<FormVeiculoLocalizacao />} />
				<Route path="CadVeiculoLocalizacao/:id" element={<FormVeiculoLocalizacao />} />
				<Route path="LstVeiculoLocalizacao" element={<ListVeiculoLocalizacao />} />
				{/*[END_ROUTE_VEICULO_LOCALIZACAO]*/}

				{/*[BEGIN_ROUTE_LOCALIZACAO]*/}
				<Route path="CadLocalizacao" element={<FormLocalizacao />} />
				<Route path="CadLocalizacao/:id" element={<FormLocalizacao />} />
				<Route path="LstLocalizacao" element={<ListLocalizacao />} />
				{/*[END_ROUTE_LOCALIZACAO]*/}

				{/*[BEGIN_ROUTE_VEICULO_PLACA]*/}
				<Route path="CadVeiculoPlaca" element={<FormVeiculoPlaca />} />
				<Route path="CadVeiculoPlaca/:id" element={<FormVeiculoPlaca />} />
				<Route path="LstVeiculoPlaca" element={<ListVeiculoPlaca />} />
				{/*[END_ROUTE_VEICULO_PLACA]*/}

				{/*[BEGIN_ROUTE_COMBUSTIVEL]*/}
				<Route path="CadCombustivel" element={<FormCombustivel />} />
				<Route path="CadCombustivel/:id" element={<FormCombustivel />} />
				<Route path="LstCombustivel" element={<ListCombustivel />} />
				{/*[END_ROUTE_COMBUSTIVEL]*/}

				{/*[BEGIN_ROUTE_TRANSMISSAO]*/}
				<Route path="CadTransmissao" element={<FormTransmissao />} />
				<Route path="CadTransmissao/:id" element={<FormTransmissao />} />
				<Route path="LstTransmissao" element={<ListTransmissao />} />
				{/*[END_ROUTE_TRANSMISSAO]*/}

				{/*[BEGIN_ROUTE_VEICULO_COMBUSTIVEL]*/}
				<Route path="CadVeiculoCombustivel" element={<FormVeiculoCombustivel />} />
				<Route path="CadVeiculoCombustivel/:id" element={<FormVeiculoCombustivel />} />
				<Route path="LstVeiculoCombustivel" element={<ListVeiculoCombustivel />} />
				{/*[END_ROUTE_VEICULO_COMBUSTIVEL]*/}

				{/*[BEGIN_ROUTE_PESSOA_LOCALIZACAO]*/}
				<Route path="CadPessoaLocalizacao" element={<FormPessoaLocalizacao />} />
				<Route path="CadPessoaLocalizacao/:id" element={<FormPessoaLocalizacao />} />
				<Route path="LstPessoaLocalizacao" element={<ListPessoaLocalizacao />} />
				{/*[END_ROUTE_PESSOA_LOCALIZACAO]*/}

				{/*[BEGIN_ROUTE_VEICULO_DADO_VALOR]*/}
				<Route path="CadVeiculoDadoValor" element={<FormVeiculoDadoValor />} />
				<Route path="CadVeiculoDadoValor/:id" element={<FormVeiculoDadoValor />} />
				<Route path="LstVeiculoDadoValor" element={<ListVeiculoDadoValor />} />
				{/*[END_ROUTE_VEICULO_DADO_VALOR]*/}

				{/*[BEGIN_ROUTE_VEICULO_ARQUIVO]*/}
				<Route path="CadVeiculoArquivo" element={<FormVeiculoArquivo />} />
				<Route path="CadVeiculoArquivo/:id" element={<FormVeiculoArquivo />} />
				<Route path="LstVeiculoArquivo" element={<ListVeiculoArquivo />} />
				{/*[END_ROUTE_VEICULO_ARQUIVO]*/}

				{/*[BEGIN_ROUTE_VEICULO_TRAJETO]*/}
				<Route path="CadVeiculoTrajeto" element={<FormVeiculoTrajeto />} />
				<Route path="CadVeiculoTrajeto/:id" element={<FormVeiculoTrajeto />} />
				<Route path="LstVeiculoTrajeto" element={<ListVeiculoTrajeto />} />
				{/*[END_ROUTE_VEICULO_TRAJETO]*/}

				{/*[BEGIN_ROUTE_ORDEM_ART]*/}
				<Route path="CadOrdemArt" element={<FormOrdemArt />} />
				<Route path="CadOrdemArt/:id" element={<FormOrdemArt />} />
				<Route path="LstOrdemArt" element={<ListOrdemArt />} />
				{/*[END_ROUTE_ORDEM_ART]*/}

				{/*[BEGIN_ROUTE_VEICULO_RESUMO]*/}
				<Route path="CadVeiculoResumo" element={<FormVeiculoResumo />} />
				<Route path="CadVeiculoResumo/:id" element={<FormVeiculoResumo />} />
				<Route path="LstVeiculoResumo" element={<ListVeiculoResumo />} />
				{/*[END_ROUTE_VEICULO_RESUMO]*/}

				{/*[BEGIN_ROUTE_RECORRENCIA]*/}
				<Route path="CadRecorrencia" element={<FormRecorrencia />} />
				<Route path="CadRecorrencia/:id" element={<FormRecorrencia />} />
				<Route path="LstRecorrencia" element={<ListRecorrencia />} />
				{/*[END_ROUTE_RECORRENCIA]*/}

				{/*[BEGIN_ROUTE_CONTA_PAGAR]*/}
				<Route path="CadContaPagar" element={<FormContaPagar />} />
				<Route path="CadContaPagar/:id" element={<FormContaPagar />} />
				<Route path="LstContaPagar" element={<ListContaPagar />} />
				{/*[END_ROUTE_CONTA_PAGAR]*/}

				{/*[BEGIN_ROUTE_CONTA_RECEBER]*/}
				<Route path="CadContaReceber" element={<FormContaReceber />} />
				<Route path="CadContaReceber/:id" element={<FormContaReceber />} />
				<Route path="LstContaReceber" element={<ListContaReceber />} />
				{/*[END_ROUTE_CONTA_RECEBER]*/}

				{/*[BEGIN_ROUTE_TIPO_RECORRENCIA]*/}
				<Route path="CadTipoRecorrencia" element={<FormTipoRecorrencia />} />
				<Route path="CadTipoRecorrencia/:id" element={<FormTipoRecorrencia />} />
				<Route path="LstTipoRecorrencia" element={<ListTipoRecorrencia />} />
				{/*[END_ROUTE_TIPO_RECORRENCIA]*/}

				{/*[BEGIN_ROUTE_CONTA_PAGAR_PARCELA]*/}
				<Route path="CadContaPagarParcela" element={<FormContaPagarParcela />} />
				<Route path="CadContaPagarParcela/:id" element={<FormContaPagarParcela />} />
				<Route path="LstContaPagarParcela" element={<ListContaPagarParcela />} />
				{/*[END_ROUTE_CONTA_PAGAR_PARCELA]*/}

				{/*[BEGIN_ROUTE_TIPO_CONTA_PAGAR]*/}
				<Route path="CadTipoContaPagar" element={<FormTipoContaPagar />} />
				<Route path="CadTipoContaPagar/:id" element={<FormTipoContaPagar />} />
				<Route path="LstTipoContaPagar" element={<ListTipoContaPagar />} />
				{/*[END_ROUTE_TIPO_CONTA_PAGAR]*/}

				{/*[BEGIN_ROUTE_TIPO_CONTA_RECEBER]*/}
				<Route path="CadTipoContaReceber" element={<FormTipoContaReceber />} />
				<Route path="CadTipoContaReceber/:id" element={<FormTipoContaReceber />} />
				<Route path="LstTipoContaReceber" element={<ListTipoContaReceber />} />
				{/*[END_ROUTE_TIPO_CONTA_RECEBER]*/}

				{/*[BEGIN_ROUTE_STATUS_CONTA]*/}
				<Route path="CadStatusConta" element={<FormStatusConta />} />
				<Route path="CadStatusConta/:id" element={<FormStatusConta />} />
				<Route path="LstStatusConta" element={<ListStatusConta />} />
				{/*[END_ROUTE_STATUS_CONTA]*/}

				{/*[BEGIN_ROUTE_CONTA_PAGAR_BAIXA]*/}
				<Route path="CadContaPagarBaixa" element={<FormContaPagarBaixa />} />
				<Route path="CadContaPagarBaixa/:id" element={<FormContaPagarBaixa />} />
				<Route path="LstContaPagarBaixa" element={<ListContaPagarBaixa />} />
				{/*[END_ROUTE_CONTA_PAGAR_BAIXA]*/}

				{/*[BEGIN_ROUTE_CONTA_RECEBER_BAIXA]*/}
				<Route path="CadContaReceberBaixa" element={<FormContaReceberBaixa />} />
				<Route path="CadContaReceberBaixa/:id" element={<FormContaReceberBaixa />} />
				<Route path="LstContaReceberBaixa" element={<ListContaReceberBaixa />} />
				{/*[END_ROUTE_CONTA_RECEBER_BAIXA]*/}

				{/*[BEGIN_ROUTE_CONTA_RECEBER_PARCELA]*/}
				<Route path="CadContaReceberParcela" element={<FormContaReceberParcela />} />
				<Route path="CadContaReceberParcela/:id" element={<FormContaReceberParcela />} />
				<Route path="LstContaReceberParcela" element={<ListContaReceberParcela />} />
				{/*[END_ROUTE_CONTA_RECEBER_PARCELA]*/}

				{/*[BEGIN_ROUTE_CONTA_RECEBER_ARQUIVO]*/}
				<Route path="CadContaReceberArquivo" element={<FormContaReceberArquivo />} />
				<Route path="CadContaReceberArquivo/:id" element={<FormContaReceberArquivo />} />
				<Route path="LstContaReceberArquivo" element={<ListContaReceberArquivo />} />
				{/*[END_ROUTE_CONTA_RECEBER_ARQUIVO]*/}

				{/*[BEGIN_ROUTE_CONTA_PAGAR_ARQUIVO]*/}
				<Route path="CadContaPagarArquivo" element={<FormContaPagarArquivo />} />
				<Route path="CadContaPagarArquivo/:id" element={<FormContaPagarArquivo />} />
				<Route path="LstContaPagarArquivo" element={<ListContaPagarArquivo />} />
				{/*[END_ROUTE_CONTA_PAGAR_ARQUIVO]*/}

				{/*[BEGIN_ROUTE_CONTA_PAGAR_VINCULO]*/}
				<Route path="CadContaPagarVinculo" element={<FormContaPagarVinculo />} />
				<Route path="CadContaPagarVinculo/:id" element={<FormContaPagarVinculo />} />
				<Route path="LstContaPagarVinculo" element={<ListContaPagarVinculo />} />
				{/*[END_ROUTE_CONTA_PAGAR_VINCULO]*/}

				{/*[BEGIN_ROUTE_CONTA_PAGAR_VEICULO]*/}
				<Route path="CadContaPagarVeiculo" element={<FormContaPagarVeiculo />} />
				<Route path="CadContaPagarVeiculo/:id" element={<FormContaPagarVeiculo />} />
				<Route path="LstContaPagarVeiculo" element={<ListContaPagarVeiculo />} />
				{/*[END_ROUTE_CONTA_PAGAR_VEICULO]*/}

				{/*[BEGIN_ROUTE_ORDEM_ARQUIVO]*/}
				<Route path="CadOrdemArquivo" element={<FormOrdemArquivo />} />
				<Route path="CadOrdemArquivo/:id" element={<FormOrdemArquivo />} />
				<Route path="LstOrdemArquivo" element={<ListOrdemArquivo />} />
				{/*[END_ROUTE_ORDEM_ARQUIVO]*/}

				{/*[BEGIN_ROUTE_FATURA_ARQUIVO]*/}
				<Route path="CadFaturaArquivo" element={<FormFaturaArquivo />} />
				<Route path="CadFaturaArquivo/:id" element={<FormFaturaArquivo />} />
				<Route path="LstFaturaArquivo" element={<ListFaturaArquivo />} />
				{/*[END_ROUTE_FATURA_ARQUIVO]*/}

				{/*[BEGIN_ROUTE_FLUXO]*/}
				<Route path="CadFluxo" element={<FormFluxo />} />
				<Route path="CadFluxo/:id" element={<FormFluxo />} />
				<Route path="LstFluxo" element={<ListFluxo />} />
				{/*[END_ROUTE_FLUXO]*/}

				{/*[BEGIN_ROUTE_FLUXO_VERSAO]*/}
				<Route path="CadFluxoVersao" element={<FormFluxoVersao />} />
				<Route path="CadFluxoVersao/:id" element={<FormFluxoVersao />} />
				<Route path="LstFluxoVersao" element={<ListFluxoVersao />} />
				{/*[END_ROUTE_FLUXO_VERSAO]*/}

				{/*[BEGIN_ROUTE_FLUXO_ITEM_TAREFA]*/}
				<Route path="CadFluxoItemTarefa" element={<FormFluxoItemTarefa />} />
				<Route path="CadFluxoItemTarefa/:id" element={<FormFluxoItemTarefa />} />
				<Route path="LstFluxoItemTarefa" element={<ListFluxoItemTarefa />} />
				{/*[END_ROUTE_FLUXO_ITEM_TAREFA]*/}

				{/*[BEGIN_ROUTE_TIPO_FLUXO_ITEM]*/}
				<Route path="CadTipoFluxoItem" element={<FormTipoFluxoItem />} />
				<Route path="CadTipoFluxoItem/:id" element={<FormTipoFluxoItem />} />
				<Route path="LstTipoFluxoItem" element={<ListTipoFluxoItem />} />
				{/*[END_ROUTE_TIPO_FLUXO_ITEM]*/}

				{/*[BEGIN_ROUTE_FLUXO_ITEM]*/}
				<Route path="CadFluxoItem" element={<FormFluxoItem />} />
				<Route path="CadFluxoItem/:id" element={<FormFluxoItem />} />
				<Route path="LstFluxoItem" element={<ListFluxoItem />} />
				{/*[END_ROUTE_FLUXO_ITEM]*/}

				{/*[BEGIN_ROUTE_FLUXO_ITEM_DESTINO]*/}
				<Route path="CadFluxoItemDestino" element={<FormFluxoItemDestino />} />
				<Route path="CadFluxoItemDestino/:id" element={<FormFluxoItemDestino />} />
				<Route path="LstFluxoItemDestino" element={<ListFluxoItemDestino />} />
				{/*[END_ROUTE_FLUXO_ITEM_DESTINO]*/}

				{/*[BEGIN_ROUTE_TIPO_PRAZO]*/}
				<Route path="CadTipoPrazo" element={<FormTipoPrazo />} />
				<Route path="CadTipoPrazo/:id" element={<FormTipoPrazo />} />
				<Route path="LstTipoPrazo" element={<ListTipoPrazo />} />
				{/*[END_ROUTE_TIPO_PRAZO]*/}

				{/*[BEGIN_ROUTE_ANS_PERFIL_ARQUIVO]*/}
				<Route path="CadAnsPerfilArquivo" element={<FormAnsPerfilArquivo />} />
				<Route path="CadAnsPerfilArquivo/:id" element={<FormAnsPerfilArquivo />} />
				<Route path="LstAnsPerfilArquivo" element={<ListAnsPerfilArquivo />} />
				{/*[END_ROUTE_ANS_PERFIL_ARQUIVO]*/}

				{/*[BEGIN_ROUTE_TIPO_NOTIFICACAO_DESTINO]*/}
				<Route path="CadTipoNotificacaoDestino" element={<FormTipoNotificacaoDestino />} />
				<Route path="CadTipoNotificacaoDestino/:id" element={<FormTipoNotificacaoDestino />} />
				<Route path="LstTipoNotificacaoDestino" element={<ListTipoNotificacaoDestino />} />
				{/*[END_ROUTE_TIPO_NOTIFICACAO_DESTINO]*/}

				{/*[BEGIN_ROUTE_PESSOA_TIPO_NOTIFICACAO]*/}
				<Route path="CadPessoaTipoNotificacao" element={<FormPessoaTipoNotificacao />} />
				<Route path="CadPessoaTipoNotificacao/:id" element={<FormPessoaTipoNotificacao />} />
				<Route path="LstPessoaTipoNotificacao" element={<ListPessoaTipoNotificacao />} />
				{/*[END_ROUTE_PESSOA_TIPO_NOTIFICACAO]*/}

				{/*[BEGIN_ROUTE_NOTIFICACAO_EMAIL_ARQUIVO]*/}
				<Route path="CadNotificacaoEmailArquivo" element={<FormNotificacaoEmailArquivo />} />
				<Route path="CadNotificacaoEmailArquivo/:id" element={<FormNotificacaoEmailArquivo />} />
				<Route path="LstNotificacaoEmailArquivo" element={<ListNotificacaoEmailArquivo />} />
				{/*[END_ROUTE_NOTIFICACAO_EMAIL_ARQUIVO]*/}

				{/*[BEGIN_ROUTE_NOTIFICACAO_WHATSAPP_ARQUIVO]*/}
				<Route path="CadNotificacaoWhatsappArquivo" element={<FormNotificacaoWhatsappArquivo />} />
				<Route path="CadNotificacaoWhatsappArquivo/:id" element={<FormNotificacaoWhatsappArquivo />} />
				<Route path="LstNotificacaoWhatsappArquivo" element={<ListNotificacaoWhatsappArquivo />} />
				{/*[END_ROUTE_NOTIFICACAO_WHATSAPP_ARQUIVO]*/}

				{/*[BEGIN_ROUTE_EQUIPE_PESSOA]*/}
				<Route path="CadEquipePessoa" element={<FormEquipePessoa />} />
				<Route path="CadEquipePessoa/:id" element={<FormEquipePessoa />} />
				<Route path="LstEquipePessoa" element={<ListEquipePessoa />} />
				{/*[END_ROUTE_EQUIPE_PESSOA]*/}

				{/*[BEGIN_ROUTE_EQUIPE_UNIDADE]*/}
				<Route path="CadEquipeUnidade" element={<FormEquipeUnidade />} />
				<Route path="CadEquipeUnidade/:id" element={<FormEquipeUnidade />} />
				<Route path="LstEquipeUnidade" element={<ListEquipeUnidade />} />
				{/*[END_ROUTE_EQUIPE_UNIDADE]*/}

				{/*[BEGIN_ROUTE_ORDEM_PESSOA]*/}
				<Route path="CadOrdemPessoa" element={<FormOrdemPessoa />} />
				<Route path="CadOrdemPessoa/:id" element={<FormOrdemPessoa />} />
				<Route path="LstOrdemPessoa" element={<ListOrdemPessoa />} />
				{/*[END_ROUTE_ORDEM_PESSOA]*/}

				{/*[BEGIN_ROUTE_ORDEM_EQUIPE]*/}
				<Route path="CadOrdemEquipe" element={<FormOrdemEquipe />} />
				<Route path="CadOrdemEquipe/:id" element={<FormOrdemEquipe />} />
				<Route path="LstOrdemEquipe" element={<ListOrdemEquipe />} />
				{/*[END_ROUTE_ORDEM_EQUIPE]*/}

				{/*[BEGIN_ROUTE_COMISSAO]*/}
				<Route path="CadComissao" element={<FormComissao />} />
				<Route path="CadComissao/:id" element={<FormComissao />} />
				<Route path="LstComissao" element={<ListComissao />} />
				{/*[END_ROUTE_COMISSAO]*/}

				{/*[BEGIN_ROUTE_TIPO_VALOR]*/}
				<Route path="CadTipoValor" element={<FormTipoValor />} />
				<Route path="CadTipoValor/:id" element={<FormTipoValor />} />
				<Route path="LstTipoValor" element={<ListTipoValor />} />
				{/*[END_ROUTE_TIPO_VALOR]*/}

				{/*[BEGIN_ROUTE_TIPO_COMISSAO]*/}
				<Route path="CadTipoComissao" element={<FormTipoComissao />} />
				<Route path="CadTipoComissao/:id" element={<FormTipoComissao />} />
				<Route path="LstTipoComissao" element={<ListTipoComissao />} />
				{/*[END_ROUTE_TIPO_COMISSAO]*/}

				{/*[BEGIN_ROUTE_COMISSAO_CARGO]*/}
				<Route path="CadComissaoCargo" element={<FormComissaoCargo />} />
				<Route path="CadComissaoCargo/:id" element={<FormComissaoCargo />} />
				<Route path="LstComissaoCargo" element={<ListComissaoCargo />} />
				{/*[END_ROUTE_COMISSAO_CARGO]*/}

				{/*[BEGIN_ROUTE_STATUS_COMISSAO]*/}
				<Route path="CadStatusComissao" element={<FormStatusComissao />} />
				<Route path="CadStatusComissao/:id" element={<FormStatusComissao />} />
				<Route path="LstStatusComissao" element={<ListStatusComissao />} />
				{/*[END_ROUTE_STATUS_COMISSAO]*/}

				{/*[BEGIN_ROUTE_COMISSAO_PESSOA]*/}
				<Route path="CadComissaoPessoa" element={<FormComissaoPessoa />} />
				<Route path="CadComissaoPessoa/:id" element={<FormComissaoPessoa />} />
				<Route path="LstComissaoPessoa" element={<ListComissaoPessoa />} />
				{/*[END_ROUTE_COMISSAO_PESSOA]*/}

				{/*[BEGIN_ROUTE_COMISSAO_HISTORICO]*/}
				<Route path="CadComissaoHistorico" element={<FormComissaoHistorico />} />
				<Route path="CadComissaoHistorico/:id" element={<FormComissaoHistorico />} />
				<Route path="LstComissaoHistorico" element={<ListComissaoHistorico />} />
				{/*[END_ROUTE_COMISSAO_HISTORICO]*/}

				{/*[BEGIN_ROUTE_COMISSAO_PESSOA_ORDEM]*/}
				<Route path="CadComissaoPessoaOrdem" element={<FormComissaoPessoaOrdem />} />
				<Route path="CadComissaoPessoaOrdem/:id" element={<FormComissaoPessoaOrdem />} />
				<Route path="LstComissaoPessoaOrdem" element={<ListComissaoPessoaOrdem />} />
				{/*[END_ROUTE_COMISSAO_PESSOA_ORDEM]*/}

				{/*[BEGIN_ROUTE_FUNCIONARIO]*/}
				<Route path="CadFuncionario" element={<FormFuncionario />} />
				<Route path="CadFuncionario/:id" element={<FormFuncionario />} />
				<Route path="LstFuncionario" element={<ListFuncionario />} />
				{/*[END_ROUTE_FUNCIONARIO]*/}

				{/*[BEGIN_ROUTE_CONTA_PAGAR_COMISSAO_PESSOA]*/}
				<Route path="CadContaPagarComissaoPessoa" element={<FormContaPagarComissaoPessoa />} />
				<Route path="CadContaPagarComissaoPessoa/:id" element={<FormContaPagarComissaoPessoa />} />
				<Route path="LstContaPagarComissaoPessoa" element={<ListContaPagarComissaoPessoa />} />
				{/*[END_ROUTE_CONTA_PAGAR_COMISSAO_PESSOA]*/}

				{/*[BEGIN_ROUTE_COMISSAO_UNIDADE]*/}
				<Route path="CadComissaoUnidade" element={<FormComissaoUnidade />} />
				<Route path="CadComissaoUnidade/:id" element={<FormComissaoUnidade />} />
				<Route path="LstComissaoUnidade" element={<ListComissaoUnidade />} />
				{/*[END_ROUTE_COMISSAO_UNIDADE]*/}

				{/*[BEGIN_ROUTE_COMISSAO_ORDEM]*/}
				<Route path="CadComissaoOrdem" element={<FormComissaoOrdem />} />
				<Route path="CadComissaoOrdem/:id" element={<FormComissaoOrdem />} />
				<Route path="LstComissaoOrdem" element={<ListComissaoOrdem />} />
				{/*[END_ROUTE_COMISSAO_ORDEM]*/}

				{/*[BEGIN_ROUTE_FUNCIONARIO_CARGO]*/}
				<Route path="CadFuncionarioCargo" element={<FormFuncionarioCargo />} />
				<Route path="CadFuncionarioCargo/:id" element={<FormFuncionarioCargo />} />
				<Route path="LstFuncionarioCargo" element={<ListFuncionarioCargo />} />
				{/*[END_ROUTE_FUNCIONARIO_CARGO]*/}

				{/*[BEGIN_ROUTE_FUNCIONARIO_SALARIO]*/}
				<Route path="CadFuncionarioSalario" element={<FormFuncionarioSalario />} />
				<Route path="CadFuncionarioSalario/:id" element={<FormFuncionarioSalario />} />
				<Route path="LstFuncionarioSalario" element={<ListFuncionarioSalario />} />
				{/*[END_ROUTE_FUNCIONARIO_SALARIO]*/}

				{/*[BEGIN_ROUTE_STATUS_FUNCIONARIO]*/}
				<Route path="CadStatusFuncionario" element={<FormStatusFuncionario />} />
				<Route path="CadStatusFuncionario/:id" element={<FormStatusFuncionario />} />
				<Route path="LstStatusFuncionario" element={<ListStatusFuncionario />} />
				{/*[END_ROUTE_STATUS_FUNCIONARIO]*/}

				{/*[BEGIN_ROUTE_FUNCIONARIO_HISTORICO]*/}
				<Route path="CadFuncionarioHistorico" element={<FormFuncionarioHistorico />} />
				<Route path="CadFuncionarioHistorico/:id" element={<FormFuncionarioHistorico />} />
				<Route path="LstFuncionarioHistorico" element={<ListFuncionarioHistorico />} />
				{/*[END_ROUTE_FUNCIONARIO_HISTORICO]*/}

				{/*[BEGIN_ROUTE_TIPO_DEPENDENTE]*/}
				<Route path="CadTipoDependente" element={<FormTipoDependente />} />
				<Route path="CadTipoDependente/:id" element={<FormTipoDependente />} />
				<Route path="LstTipoDependente" element={<ListTipoDependente />} />
				{/*[END_ROUTE_TIPO_DEPENDENTE]*/}

				{/*[BEGIN_ROUTE_FUNCIONARIO_DEPENDENTE]*/}
				<Route path="CadFuncionarioDependente" element={<FormFuncionarioDependente />} />
				<Route path="CadFuncionarioDependente/:id" element={<FormFuncionarioDependente />} />
				<Route path="LstFuncionarioDependente" element={<ListFuncionarioDependente />} />
				{/*[END_ROUTE_FUNCIONARIO_DEPENDENTE]*/}

				{/*[BEGIN_ROUTE_COMISSAO_TIPO_ORDEM]*/}
				<Route path="CadComissaoTipoOrdem" element={<FormComissaoTipoOrdem />} />
				<Route path="CadComissaoTipoOrdem/:id" element={<FormComissaoTipoOrdem />} />
				<Route path="LstComissaoTipoOrdem" element={<ListComissaoTipoOrdem />} />
				{/*[END_ROUTE_COMISSAO_TIPO_ORDEM]*/}

				{/*[BEGIN_ROUTE_COMISSAO_DATA]*/}
				<Route path="CadComissaoData" element={<FormComissaoData />} />
				<Route path="CadComissaoData/:id" element={<FormComissaoData />} />
				<Route path="LstComissaoData" element={<ListComissaoData />} />
				{/*[END_ROUTE_COMISSAO_DATA]*/}

				{/*[BEGIN_ROUTE_TIPO_COMISSAO_DATA]*/}
				<Route path="CadTipoComissaoData" element={<FormTipoComissaoData />} />
				<Route path="CadTipoComissaoData/:id" element={<FormTipoComissaoData />} />
				<Route path="LstTipoComissaoData" element={<ListTipoComissaoData />} />
				{/*[END_ROUTE_TIPO_COMISSAO_DATA]*/}

				{/*[BEGIN_ROUTE_ESTADO_CIVIL]*/}
				<Route path="CadEstadoCivil" element={<FormEstadoCivil />} />
				<Route path="CadEstadoCivil/:id" element={<FormEstadoCivil />} />
				<Route path="LstEstadoCivil" element={<ListEstadoCivil />} />
				{/*[END_ROUTE_ESTADO_CIVIL]*/}

				{/*[BEGIN_ROUTE_PESSOA_FOTO]*/}
				<Route path="CadPessoaFoto" element={<FormPessoaFoto />} />
				<Route path="CadPessoaFoto/:id" element={<FormPessoaFoto />} />
				<Route path="LstPessoaFoto" element={<ListPessoaFoto />} />
				{/*[END_ROUTE_PESSOA_FOTO]*/}

				{/*[BEGIN_ROUTE_TIPO_DOCUMENTO]*/}
				<Route path="CadTipoDocumento" element={<FormTipoDocumento />} />
				<Route path="CadTipoDocumento/:id" element={<FormTipoDocumento />} />
				<Route path="LstTipoDocumento" element={<ListTipoDocumento />} />
				{/*[END_ROUTE_TIPO_DOCUMENTO]*/}

				{/*[BEGIN_ROUTE_PESSOA_DOCUMENTO]*/}
				<Route path="CadPessoaDocumento" element={<FormPessoaDocumento />} />
				<Route path="CadPessoaDocumento/:id" element={<FormPessoaDocumento />} />
				<Route path="LstPessoaDocumento" element={<ListPessoaDocumento />} />
				{/*[END_ROUTE_PESSOA_DOCUMENTO]*/}

				{/*[BEGIN_ROUTE_FUNCIONARIO_DOCUMENTO]*/}
				<Route path="CadFuncionarioDocumento" element={<FormFuncionarioDocumento />} />
				<Route path="CadFuncionarioDocumento/:id" element={<FormFuncionarioDocumento />} />
				<Route path="LstFuncionarioDocumento" element={<ListFuncionarioDocumento />} />
				{/*[END_ROUTE_FUNCIONARIO_DOCUMENTO]*/}

				{/*[BEGIN_ROUTE_NACIONALIDADE]*/}
				<Route path="CadNacionalidade" element={<FormNacionalidade />} />
				<Route path="CadNacionalidade/:id" element={<FormNacionalidade />} />
				<Route path="LstNacionalidade" element={<ListNacionalidade />} />
				{/*[END_ROUTE_NACIONALIDADE]*/}

				{/*[BEGIN_ROUTE_GENERO]*/}
				<Route path="CadGenero" element={<FormGenero />} />
				<Route path="CadGenero/:id" element={<FormGenero />} />
				<Route path="LstGenero" element={<ListGenero />} />
				{/*[END_ROUTE_GENERO]*/}

				{/*[BEGIN_ROUTE_TIPO_FILIACAO]*/}
				<Route path="CadTipoFiliacao" element={<FormTipoFiliacao />} />
				<Route path="CadTipoFiliacao/:id" element={<FormTipoFiliacao />} />
				<Route path="LstTipoFiliacao" element={<ListTipoFiliacao />} />
				{/*[END_ROUTE_TIPO_FILIACAO]*/}

				{/*[BEGIN_ROUTE_PESSOA_FILIACAO]*/}
				<Route path="CadPessoaFiliacao" element={<FormPessoaFiliacao />} />
				<Route path="CadPessoaFiliacao/:id" element={<FormPessoaFiliacao />} />
				<Route path="LstPessoaFiliacao" element={<ListPessoaFiliacao />} />
				{/*[END_ROUTE_PESSOA_FILIACAO]*/}

				{/*[BEGIN_ROUTE_PESSOA_CONJUGE]*/}
				<Route path="CadPessoaConjuge" element={<FormPessoaConjuge />} />
				<Route path="CadPessoaConjuge/:id" element={<FormPessoaConjuge />} />
				<Route path="LstPessoaConjuge" element={<ListPessoaConjuge />} />
				{/*[END_ROUTE_PESSOA_CONJUGE]*/}

				{/*[BEGIN_ROUTE_TIPO_EVENTO_SALARIO]*/}
				<Route path="CadTipoEventoSalario" element={<FormTipoEventoSalario />} />
				<Route path="CadTipoEventoSalario/:id" element={<FormTipoEventoSalario />} />
				<Route path="LstTipoEventoSalario" element={<ListTipoEventoSalario />} />
				{/*[END_ROUTE_TIPO_EVENTO_SALARIO]*/}

				{/*[BEGIN_ROUTE_TIPO_PAGAMENTO_SALARIO]*/}
				<Route path="CadTipoPagamentoSalario" element={<FormTipoPagamentoSalario />} />
				<Route path="CadTipoPagamentoSalario/:id" element={<FormTipoPagamentoSalario />} />
				<Route path="LstTipoPagamentoSalario" element={<ListTipoPagamentoSalario />} />
				{/*[END_ROUTE_TIPO_PAGAMENTO_SALARIO]*/}

				{/*[BEGIN_ROUTE_ESCALA_TRABALHO]*/}
				<Route path="CadEscalaTrabalho" element={<FormEscalaTrabalho />} />
				<Route path="CadEscalaTrabalho/:id" element={<FormEscalaTrabalho />} />
				<Route path="LstEscalaTrabalho" element={<ListEscalaTrabalho />} />
				{/*[END_ROUTE_ESCALA_TRABALHO]*/}

				{/*[BEGIN_ROUTE_USUARIO_TOKEN]*/}
				<Route path="CadUsuarioToken" element={<FormUsuarioToken />} />
				<Route path="CadUsuarioToken/:id" element={<FormUsuarioToken />} />
				<Route path="LstUsuarioToken" element={<ListUsuarioToken />} />
				{/*[END_ROUTE_USUARIO_TOKEN]*/}

				{/*[BEGIN_ROUTE_ANS_CRITERIO_PARADA]*/}
				<Route path="CadAnsCriterioParada" element={<FormAnsCriterioParada />} />
				<Route path="CadAnsCriterioParada/:id" element={<FormAnsCriterioParada />} />
				<Route path="LstAnsCriterioParada" element={<ListAnsCriterioParada />} />
				{/*[END_ROUTE_ANS_CRITERIO_PARADA]*/}

				{/*[BEGIN_ROUTE_ANS_PERFIL_PARADA]*/}
				<Route path="CadAnsPerfilParada" element={<FormAnsPerfilParada />} />
				<Route path="CadAnsPerfilParada/:id" element={<FormAnsPerfilParada />} />
				<Route path="LstAnsPerfilParada" element={<ListAnsPerfilParada />} />
				{/*[END_ROUTE_ANS_PERFIL_PARADA]*/}

				{/*[AUTOMATIC_ROUTE]*/}

			</Route>
		</Routes>
	)
}

export default AppRoutes;