import React from "react";
import { Link } from "react-router-dom";
import Auth from "../../controllers/Auth";
import Util from "../../utils/Util";
import { BaseComponent } from "../../siht/base";
import Tree from "../Tree";
import { BtnButton, Div } from "../../siht/elements";
import { Modal } from "../../siht/controller";
import FormAlterarSenha from "../Usuario/FormAlterarSenha";
import { HouseDoorFill as IconHouseDoorFill} from "react-bootstrap-icons";


export default class Atalhos extends BaseComponent {

    constructor(props) {
        super(props);

        this.getRenderTree = this.getRenderTree.bind(this);
        this.handleClickTree = this.handleClickTree.bind(this);

        this.state = {
            usuario: {},
        };

        this.state.usuario = Auth.getDataToken();

        this.state.tree = [];
        this.state.nav = [];
    }

    componentDidMount() {
        super.componentDidMount();
        this.setState({tree : this.getMenu()});
    }

    getMenu(){        
        var tree = [];
        if(this.props.flRapido){
            tree = (Tree.getMenu().flatMap(item => item.tree))?.filter(item => item.shortchut && item.permission);
        }else{
            tree = Tree.getMenu();
        }
        return tree;
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    handleClickAlterarSenha(){
        Modal.openWindow(FormAlterarSenha, {sizing : Modal.sizing.sm}, {  }, (e, response) => { 
            
		});
    }

    handleClickTree(item, index = null){
        let nav = [];
        let tree = [];        
        if(item !== undefined){
            nav = this.state.nav;
            tree = item.tree

            if(index !== null){
                nav.splice((index + 1), (nav.length - 1));
            }else{
                nav.push(item);                
            }
        }else{
            tree = this.getMenu();
        }
        

        this.setState({tree : tree, nav : nav});
    }

    getRenderTree(tree){

        tree = Util.arrayOrderBy(tree, "label");

        return tree.map((item, index) => {

            let Icon = item?.icon !== undefined ? item?.icon : false;
            let color = item?.color !== undefined ? item.color : BtnButton.color.outlinePrimary;

            if(item.tree !== undefined && item.tree.filter(item => item.permission === undefined || item.permission).length > 0){
                return (
                    <Div key={index} className="col-lg-2 col-md-3 col-sm-4 col-6">
                        <BtnButton color={color} className={`text-nowrap`} onClick={e => this.handleClickTree(item)}>
                            {Icon ? <Icon width="25%" height="25%" item={item} /> : <></>}
                            <h6>{item.label}</h6>
                        </BtnButton>
                    </Div>
                );
            }else if(item.to !== undefined){
                return (
                    <Div key={index} className="col-lg-2 col-md-3 col-sm-4 col-6">
                        <Link to={item.to} className={`btn btn-${color} text-nowrap`}>
                            {Icon ? <Icon width="25%" height="25%" item={item} /> : <></>}
                            <h6>{item.label}</h6>
                        </Link>
                    </Div>
                );
            }

            return <React.Fragment key={index}></React.Fragment>

        });
    }

    render() {

        return (
            <Div>
                <Div show={this.props.flRapido === undefined}>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb breadcrumb-chevron p-3 bg-body-tertiary rounded-3">
                            {this.state.nav.length === 0 ? 
                                <li className="breadcrumb-item active">
                                    <IconHouseDoorFill/> Home
                                </li> : 
                                <li className="breadcrumb-item">
                                    <Link className="link-body-emphasis fw-semibold text-decoration-none" onClick={e => this.handleClickTree()}>
                                        <IconHouseDoorFill/> Home
                                    </Link>
                                </li>
                            }

                            {this.state.nav.map((item, index) => {
                            
                                let Icon = item?.icon !== undefined ? item?.icon : false;

                                return (
                                    this.state.nav.length === (index + 1) ?

                                    <li key={index} className="breadcrumb-item active" aria-current="page">
                                        {Icon ? <Icon item={item} /> : <></>} {item.label}
                                    </li> : 

                                    <li key={index} className="breadcrumb-item" aria-current="page">
                                        <Link className="link-body-emphasis fw-semibold text-decoration-none" onClick={e => this.handleClickTree(item, index)}>
                                            {Icon ? <Icon item={item} /> : <></>} {item.label}
                                        </Link>                                
                                    </li>
                                );
                        })}
                        </ol>
                    </nav>
                </Div>

                <Div className="row g-3">                    
                    {this.getRenderTree(this.state.tree)}
                </Div>
            </Div>
        );
    }
}