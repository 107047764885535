import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BaseComponent } from "../../siht/base";
import SelectPais from "../../components/SelectDB/SelectPais";
import SelectEstado from "../../components/SelectDB/SelectEstado";
import { FormItem } from "../../siht/components";

class FormItemPaisEstado extends BaseComponent {

	constructor(props) {
		super(props);
		this.init = this.init.bind(this);        
        this.handleChangePais = this.handleChangePais.bind(this);
        this.handleChangeEstado = this.handleChangeEstado.bind(this);
	}

	componentDidMount() {
		super.componentDidMount();
        this.init();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		
	}

	handleChangePais(e, v, p){
        var value =  { pais : v ? v : {} };
        this.props.onChange(e, value, this.props.name);
	}

	handleChangeEstado(e, v, p){
        var value =  v ? v : { pais : this.props.value?.pais };
		this.props.onChange(e, value, this.props.name);
	}

	render() {

		var propsFormItem = ["col","xs","sm","md","lg","xl","xxl"];
		var propsItem = ["required" , "buttons" , "label", "placeholder", "disabled"];

		return super.render(
            <React.Fragment>
                <FormItem label="Pais" {...(this.getProps(propsFormItem,0))}>
                    <SelectPais {...(this.getProps(propsItem,0))} name="pais" value={this.props.value?.pais} onChange={this.handleChangePais} placeholder="Selecione o Pais"/>
                </FormItem>
                <FormItem label="Estado" {...(this.getProps(propsFormItem,1))}>
                    <SelectEstado {...(this.getProps(propsItem,1))} getOptionsWhen={f => f?.pais?.idPais !== undefined} filter={{pais : this.props.value?.pais}} name="estado" value={this.props.value} onChange={this.handleChangeEstado} placeholder="Selecione Nome do Estado"/>
                </FormItem>
            </React.Fragment>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	return <FormItemPaisEstado {...props} navigate={navigate} params={params} />
}
export default With