//#NO-CHANGE-FILE#

import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import SelectAnsFerramenta from "../../components/SelectDB/SelectAnsFerramenta";
import { BaseCrud } from "../../siht/base";
import { FormButtons, FormItem, Row, Panel, PanelBody, PanelFooter, Container, ModalDialog, Col } from "../../siht/components";
import { InputText, InputNumberFormat, Form, BtnButton } from "../../siht/elements";
import Config from "./Config";
import Util from "../../utils/Util";

class FormAnsPerfilAvanco extends BaseCrud {

	constructor(props) {
		super(props);
		this.init = this.init.bind(this);
		this.handleSuccess = this.handleSuccess.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleChangeModel = this.handleChangeModel.bind(this);
		this.handleSubmitForm = this.handleSubmitForm.bind(this);
		this.handleClickTotal = this.handleClickTotal.bind(this);

		this.state.model = {
			idAnsPerfilAvanco : null,
			ansPerfil : this.props.parent !== undefined ? this.props.parent : {},
			ansFerramenta : null,
			vlProfundidade : 0
		};
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		super.init(Config);
	}

	handleSuccess(e) {
		super.handleSuccess(e, Config);
	}

	handleClose(e) {
		super.handleClose(e, Config);
	}

	handleClickTotal(){
		this.setModel({vlProfundidade : this.state.model.ansPerfil?.vlProfundidadeFuro});
	}

	render() {
		return super.render(
			<Form onSubmit={this.handleSuccess} refParam={f => (this.form = f)} key={this.state.key}>
				<Panel key={this.state.key} title="Cadastro de Avanço" onClose={this.handleClose} isModal={this.isModal()} container={{sizing: Container.sizing.xxl, className : ""}} modal={{sizing: this.props.sizing ? this.props.sizing : ModalDialog.sizing.xl}}>
					<PanelBody>
						<Row className="g-3 pb-2 px-2">
							<FormItem md={6} label="Código">
								<InputText name="idAnsPerfilAvanco" value={this.state.model.idAnsPerfilAvanco} readOnly={true} className="readonly-field" required={false} onChange={this.handleChangeModel}/>
							</FormItem>
							<FormItem md={12} label="Ferramenta">
								<SelectAnsFerramenta name="ansFerramenta" value={this.state.model.ansFerramenta} required={true} onChange={this.handleChangeModel} placeholder="Selecione a Ferramenta"/>
							</FormItem>
							<FormItem md={4} label="Profundidade">
								<InputNumberFormat allowNegative={false} name="vlProfundidade" value={this.state.model.vlProfundidade} max={this.state.model.ansPerfil?.vlProfundidadeFuro} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} suffix=" mts" required={true} onChange={this.handleChangeModel}/>
							</FormItem>
							<Col sizing={4} className="d-flex align-items-end">
								<BtnButton onClick={this.handleClickTotal}> MAX = {Util.floatToValor(this.state.model.ansPerfil?.vlProfundidadeFuro)} </BtnButton>
							</Col>
						</Row>
					</PanelBody>
					<PanelFooter>
						<FormButtons onClose={this.handleClose} />
					</PanelFooter>
				</Panel>
			</Form>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <FormAnsPerfilAvanco {...props} navigate={navigate} params={params} location={location}/>
}
export default With