import React from "react";
import { Link } from "react-router-dom";
import { BaseCrud } from "../../siht/base";
import { Div, Input, InputNumberFormat } from "../../siht/elements";
import FaturaCobrancaTransacaoService from "../../services/FaturaCobrancaTransacaoService";
import Config from "./Config";
import Util from "../../utils/Util";
import { Col, FormItem, Row } from "../../siht/components";
import { CheckCircle as IconCheckCircle, ArrowLeftShort as IconArrowLeftShort} from "react-bootstrap-icons";

export default class FormPagar extends BaseCrud {

	constructor(props) {
		super(props);
		this.init = this.init.bind(this);
		this.initModel = this.initModel.bind(this);
		this.handleSuccess = this.handleSuccess.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleChangeModel = this.handleChangeModel.bind(this);
		this.handleSubmitForm = this.handleSubmitForm.bind(this);
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(fnCallback = ()=>{}){
        this.setState({ key : Math.random() }, () => {
			this.getFaturaCobrancaTransacao(fnCallback);
		});
	}

    getFaturaCobrancaTransacao(fnCallback = ()=>{}){
		if(this.props.params?.cdUid){
            FaturaCobrancaTransacaoService.getOneByCdUid(this.props.params?.cdUid, response =>{
                this.setModel({faturaCobrancaTransacao : response}, fnCallback);
            });
        }
    }

	initModel(){
		return {
            faturaCobrancaTransacao : null,
		}
	}

	getLabelFormItem(label){
        return (<span className="fs-5 fw-bold">{label}</span>);
    }

	render(render) {

		let config = {};

		switch(this.state.model?.faturaCobrancaTransacao?.tipoPagamento?.cdTipoPagamento){
			case "CARTAO_CREDITO":
				config = {
					titulo : "Cartão de Crédito"
				};
				break;
			case "PIX":
				config = {
					titulo : "PIX"
				};
				break;
			case "BOLETO":
				config = {
					titulo : "Boleto Bancário"
				};
				break;
			default:
				config = {
					titulo : ""
				};
		}

		return super.render (
			<Div className="container py-3">
                {Config.getRenderHeader(this.state.model?.faturaCobrancaTransacao?.faturaCobranca?.fatura?.unidade)}

                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to={`/pagamento/${this.state.model?.faturaCobrancaTransacao?.faturaCobranca?.cdUid}`}>Pagamento de Fatura</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">Pagamento com {config.titulo}</li>
                    </ol>
                </nav>
                
                <Div className="pricing-header p-3 pb-md-4 mx-auto text-center">
                    <h1 className="display-5 fw-normal">Pagamento com <strong>{config.titulo}</strong></h1>
                    <p className="fs-5 text-muted">
                        Pagamento da transação no valor de <strong>{Util.floatToReais(this.state.model?.faturaCobrancaTransacao?.vlTransacao)}</strong> com vencimento para <strong>{Util.date2Br(this.state?.model.faturaCobrancaTransacao?.dtVencimento)}</strong>
                    </p>
                </Div>

                {this.state.model?.faturaCobrancaTransacao?.faturaCobranca?.flVencido ?
                    <Div className="alert alert-danger" role="alert">
                        <strong>Atenção:</strong> Essa cobrança está vencida há {this.state.model.faturaCobranca?.nrDiasVencido} dia(s)!
                    </Div>
                    : <></>}

                {this.state.model.faturaCobranca?.statusFaturaCobranca?.flPaga ? 
					<Div className="text-center">
						<Div className="alert alert-success" role="alert">
							<IconCheckCircle width={40} height={40}/>
							<h3>Essa cobrança foi quitada!</h3>
						</Div>
					</Div>
				 : 

				<Row className="g-5" show={this.state.model.faturaCobrancaBoleto !== null}>
					<Col layout={Col.layout.lg} sizing={8} className="order-lg-1 order-2" >
						<Row className="mb-3 g-3">
							{render}
						</Row>
					</Col>
					<Col layout={Col.layout.lg} sizing={4} className="order-lg-2">
						<Div className="border border-secondary bg-body-tertiary p-3">
							<Row className="mb-5 g-3 mt-2">
								<FormItem md={12} label={this.getLabelFormItem("Valor da Fatura")}>
									<InputNumberFormat sizing={Input.sizing.lg} className="border border-primary readonly-field text-primary fw-bold text-end" value={this.state.model.faturaCobrancaTransacao?.vlCobranca} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} placeholder="R$ 0,00" prefix="R$ " required={false} readOnly={true}/>
								</FormItem>
								<FormItem md={12} label={this.getLabelFormItem("Valor em Aberto")}>
									<InputNumberFormat sizing={Input.sizing.lg} className="border border-primary readonly-field text-primary fw-bold text-end" value={this.state.model.faturaCobrancaTransacao?.faturaCobranca?.vlAberto} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} placeholder="R$ 0,00" prefix="R$ " required={false} readOnly={true}/>
								</FormItem>
								<FormItem md={12} label={this.getLabelFormItem("Você vai Pagar")}>
									<InputNumberFormat sizing={Input.sizing.lg} className="border border-success readonly-field text-success fw-bold text-end" value={this.state.model.faturaCobrancaTransacao?.vlTransacao} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} placeholder="R$ 0,00" prefix="R$ " required={false} readOnly={true}/>
								</FormItem>
							</Row>
						</Div>
					</Col>
				</Row>
				 
				 }

				<Div className="text-end text-decoration-none pt-3">
                    <Link to={`/pagamento/${this.state.model?.faturaCobrancaTransacao?.faturaCobranca?.cdUid}`}>
                        <IconArrowLeftShort/> Escolher outra forma de pagamento
                    </Link>
                </Div>

                {Config.getRenderFooter(this.state.model?.faturaCobrancaTransacao?.faturaCobranca?.fatura?.unidade)}
            </Div>

		);
	}
}