import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BaseComponent } from "../../siht/base";
import SelectTipoPessoa from "../../components/SelectDB/SelectTipoPessoa";
import { FormItem } from "../../siht/components";
import InputPessoa from "../InputDB/InputPessoa";
import BtnRadioGroupTipoPessoa from "../BtnRadioGroupDB/BtnRadioGroupTipoPessoa";

class FormItemTipoPessoaPessoa extends BaseComponent {

	constructor(props) {
		super(props);
		this.init = this.init.bind(this);        
        this.handleChangeTipoPessoa = this.handleChangeTipoPessoa.bind(this);
        this.handleChangePessoa = this.handleChangePessoa.bind(this);
	}

	componentDidMount() {
		super.componentDidMount();
        this.init();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		
	}

	handleChangeTipoPessoa(e, v, p){
        var value =  { tipoPessoa : v ? v : {} };
        this.props.onChange(e, value, this.props.name);
	}

	handleChangePessoa(e, v, p){
        var value =  v ? v : { tipoPessoa : this.props.value?.tipoPessoa };
		this.props.onChange(e, value, this.props.name);
	}

	render() {

		var propsFormItem = ["col","xs","sm","md","lg","xl","xxl"];
		var propsItem = ["required" , "buttons" , "label", "placeholder", "disabled", "opcional"];

        var labelTipoPessoa = this.props.label !== undefined && Array.isArray(this.props.label) && this.props.label[0] !== undefined ? this.props.label[0] : "Pessoa";
        var labelPessoa = this.props.label !== undefined && Array.isArray(this.props.label) && this.props.label[1] !== undefined ? this.props.label[1] : "Pessoa";

		return super.render(
            <React.Fragment>
                <FormItem label={labelTipoPessoa} {...(this.getProps(propsFormItem,0))}>
					{this.props.type !== undefined && Array.isArray(this.props.type) && this.props.type[0] === "BtnRadioGroup" ?
						<BtnRadioGroupTipoPessoa {...(this.getProps(propsItem,0))} name="tipoPessoa" value={this.props.value?.tipoPessoa} onClick={this.handleChangeTipoPessoa} placeholder={`Selecione o ${labelTipoPessoa}`} buttons={{search : false, new : false, edit: false}}/>
					:
						<SelectTipoPessoa {...(this.getProps(propsItem,0))} name="tipoPessoa" value={this.props.value?.tipoPessoa} onChange={this.handleChangeTipoPessoa} placeholder={`Selecione o ${labelTipoPessoa}`} buttons={{search : false, new : false, edit: false}}/>
					}
                </FormItem>
                <FormItem label={labelPessoa} {...(this.getProps(propsFormItem,1))}>
                    <InputPessoa {...(this.getProps(propsItem,1))} getOptionsWhen={f => f?.tipoPessoa?.idTipoPessoa !== undefined} filter={{tipoPessoa : this.props.value?.tipoPessoa}} name="pessoa" value={this.props.value} onChange={this.handleChangePessoa} placeholder={`Selecione o ${labelPessoa}`}/>
                </FormItem>
            </React.Fragment>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	return <FormItemTipoPessoaPessoa {...props} navigate={navigate} params={params} />
}
export default With