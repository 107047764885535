import { BaseComponent } from "../siht/base";

export default class IconNew extends BaseComponent {

    render() {

        if(this.props.show !== undefined && !Boolean(this.props.show)){
            return (<></>);
        }

        const props = Object.assign({}, this.props);
        props.xmlns = "http://www.w3.org/2000/svg";
        props.width = props.width === undefined ? 16 : props.width;
        props.height = props.height === undefined ? 16 : props.height;
        props.fill = "currentColor";
        props.className = `bi bi-plus-lg ${props.className !== undefined ? props.className : ""}`;
        props.viewBox=`0 0 16 16`
        return (
            <svg {...props}>
                <path fillRule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z" />
            </svg>
        );
    }
}