import Http from "../controllers/Http";

export default class OrdemHistoricoService {

	static urlBase = "/ordemHistorico/";

	static getForCombo(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(OrdemHistoricoService.urlBase + 'forCombo', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static list(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(OrdemHistoricoService.urlBase + 'list', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneById(id, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.get(OrdemHistoricoService.urlBase + id)
			.then(fnSucess)
			.catch(fnError);
	}

	static create(data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(OrdemHistoricoService.urlBase, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static update(id, data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.put(OrdemHistoricoService.urlBase + id, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static delete(id, fnSucess = () => { }, fnError = () => { }) {
		if(Number.isInteger(id)){
			Http.getInstanceAppJson()
				.delete(OrdemHistoricoService.urlBase + id)
				.then(fnSucess)
				.catch(fnError);
		}else{
			Http.getInstanceAppJson()
			.post(OrdemHistoricoService.urlBase + 'delete', id)
			.then(fnSucess)
			.catch(fnError);
		}
	}

	static save(data, fnSucess = () => { }, fnError = () => { }) {
		if (data.idOrdemHistorico) {
			this.update(data.idOrdemHistorico, data, fnSucess, fnError);
		} else {
			this.create(data, fnSucess, fnError);
		}
	}

	static getAllByOrdem(ordem, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(OrdemHistoricoService.urlBase + 'ordem', ordem)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByOrdem(ordem, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(OrdemHistoricoService.urlBase + 'ordem/' + ordem.idOrdem)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByStatusOrdem(statusOrdem, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(OrdemHistoricoService.urlBase + 'statusOrdem', statusOrdem)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByStatusOrdem(statusOrdem, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(OrdemHistoricoService.urlBase + 'statusOrdem/' + statusOrdem.idStatusOrdem)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByUsuario(usuario, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(OrdemHistoricoService.urlBase + 'usuario', usuario)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByUsuario(usuario, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(OrdemHistoricoService.urlBase + 'usuario/' + usuario.idUsuario)
			.then(fnSucess)
			.catch(fnError);
	}
}