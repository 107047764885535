import Http from "../controllers/Http";

export default class GrupoService {

	static urlBase = "/grupo/";

	static getForCombo(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(GrupoService.urlBase + 'forCombo', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static list(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(GrupoService.urlBase + 'list', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneById(id, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.get(GrupoService.urlBase + id)
			.then(fnSucess)
			.catch(fnError);
	}

	static create(data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(GrupoService.urlBase, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static update(id, data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.put(GrupoService.urlBase + id, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static delete(id, fnSucess = () => { }, fnError = () => { }) {
		if(Number.isInteger(id)){
			Http.getInstanceAppJson()
				.delete(GrupoService.urlBase + id)
				.then(fnSucess)
				.catch(fnError);
		}else{
			Http.getInstanceAppJson()
			.post(GrupoService.urlBase + 'delete', id)
			.then(fnSucess)
			.catch(fnError);
		}
	}

	static save(data, fnSucess = () => { }, fnError = () => { }) {
		if (data.idGrupo) {
			this.update(data.idGrupo, data, fnSucess, fnError);
		} else {
			this.create(data, fnSucess, fnError);
		}
	}

	static getAllByGrupoUnidade(grupoUnidade, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(GrupoService.urlBase + 'grupoUnidade', grupoUnidade)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByGrupoUnidade(grupoUnidade, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(GrupoService.urlBase + 'grupoUnidade/' + grupoUnidade.idGrupoUnidade)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByGrupoModulo(grupoModulo, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(GrupoService.urlBase + 'grupoModulo', grupoModulo)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByGrupoModulo(grupoModulo, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(GrupoService.urlBase + 'grupoModulo/' + grupoModulo.idGrupoModulo)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByGrupoTipoOrdem(grupoTipoOrdem, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(GrupoService.urlBase + 'grupoTipoOrdem', grupoTipoOrdem)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByGrupoTipoOrdem(grupoTipoOrdem, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(GrupoService.urlBase + 'grupoTipoOrdem/' + grupoTipoOrdem.idGrupoTipoOrdem)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByGrupoTipoPreco(grupoTipoPreco, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(GrupoService.urlBase + 'grupoTipoPreco', grupoTipoPreco)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByGrupoTipoPreco(grupoTipoPreco, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(GrupoService.urlBase + 'grupoTipoPreco/' + grupoTipoPreco.idGrupoTipoPreco)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByGrupoPainel(grupoPainel, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(GrupoService.urlBase + 'grupoPainel', grupoPainel)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByGrupoPainel(grupoPainel, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(GrupoService.urlBase + 'grupoPainel/' + grupoPainel.idGrupoPainel)
			.then(fnSucess)
			.catch(fnError);
	}
}