import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Util from "../../utils/Util";
import { Chart } from "react-google-charts";
import BlocoService from "../../services/BlocoService";
import FormBloco from "../Bloco/FormBloco";
import Permissao from "../../controllers/Permissao";
import ConfigApp from "../../ConfigApp";
import { BaseComponent } from "../../siht/base";
import { BtnButton, Div, InputDate, InputMonth, InputNumber, Select, Span } from "../../siht/elements";
import MostraValor from "../../components/MostraValor";
import { Card, CardBody, CardFooter, CardHeader, FormItem, GoogleMaps, GoogleMapsMarker, Row } from "../../siht/components";
import { Modal } from "../../siht/controller";
import { ArrowClockwise as IconArrowClockwise, ArrowsFullscreen as IconArrowsFullscreen, PauseFill as IconPauseFill, Star as IconStar, StarFill as IconStarFill } from "react-bootstrap-icons";
import UsuarioBlocoFavoritoService from "../../services/UsuarioBlocoFavoritoService";
import { IconEdit } from "../../icons";

class Bloco extends BaseComponent {

    constructor(props) {
        super(props);

        this.getDadosBloco = this.getDadosBloco.bind(this);
        this.handleClickEditBloco = this.handleClickEditBloco.bind(this);
        this.handleClickExpandirBloco = this.handleClickExpandirBloco.bind(this);        
        this.addTimeoutBloco = this.addTimeoutBloco.bind(this);
        this.delTimeoutBloco = this.delTimeoutBloco.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleClickView = this.handleClickView.bind(this);
        this.handleChangeModel = this.handleChangeModel.bind(this);
        this.submitForm = this.submitForm.bind(this);
        this.handleChangeFilter = this.handleChangeFilter.bind(this);

        this.state = {
            model: {
                idBloco: props.idBloco !== undefined ? props.idBloco : "",                
                nmBloco: "",
                nrTempoAtualizar: "",
                tipoBloco: {},
                modeloBloco: {},
                dsUrl: "",
                nrTamanhoColuna: 3,
                flAtivo: true,
                txConfiguracao: {},
                tipoFiltro : {},
                nrIntervaloFiltro: 0,
                flFavorito : false
            },

            filter : {
                vlStart : "",
                vlEnd : "",
                unidade : this.props.filter?.unidade
            }
        };

        this.state.filtroNrDia = [
            { value: "1", label: "1 dia" },
            { value: "5", label: "5 dias"},
            { value: "30", label: "1 mês"},
            { value: "180", label: "6 meses"},
            { value: "365", label: "1 ano"},            
            { value: "0", label: "MAX"},
        ];

        this.timeout = null;

        this.state.refForm = React.createRef();
    }

    componentDidMount() {
        super.componentDidMount();
        this.getBloco();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
        this.delTimeoutBloco();
    }

    addTimeoutBloco() {
        if (this.props.autoUpdate === undefined || Boolean(this.props.autoUpdate)) {
            var nrTempoAtualizar = this.state.model.nrTempoAtualizar !== undefined && this.state.model.nrTempoAtualizar ? parseInt(this.state.model.nrTempoAtualizar) : 60;
            this.timeout = setTimeout(() => {
                this.getDadosBloco();
            }, (1000 * nrTempoAtualizar));
        }
    }

    delTimeoutBloco() {
        clearTimeout(this.timeout);
    }

    onSubmit(event) {
        this.setStateCache();
        this.getDadosBloco();
    }

    getBloco() {
        if (parseInt(this.state.model.idBloco) > 0) {
            BlocoService.getOneById(this.state.model.idBloco, response =>{
                this.setState(state=>{
                    state.model = {
                        ...state.model,
                        ...response
                    }

                    if(this.props.vlStart !== undefined){
                        state.filter.vlStart = this.props.vlStart;
                        state.filter.vlEnd = this.props.vlEnd !== undefined ? this.props.vlEnd : "";
                    }else{

                        var tzoffset = (new Date()).getTimezoneOffset() * 60000;
                        var dtNow = new Date();
                        var dtEnd = new Date(dtNow - tzoffset);
                        var dtStart = new Date();
                        dtStart = new Date(dtStart - tzoffset);

                        switch(state.model.tipoFiltro?.cdTipoFiltro){
                            case "CD":
                                dtStart.setDate(dtStart.getDate() - parseInt(state.model.nrIntervaloFiltro));
                                state.filter.vlStart = Util.dateFormatYYYYMMDD(dtStart);
                                break;
                            case "CM":
                                dtStart.setDate(1);
                                dtStart.setMonth(dtStart.getMonth() - parseInt(state.model.nrIntervaloFiltro));
                                state.filter.vlStart = Util.dateFormatYYYYMM(dtStart);
                                break;
                            case "CA":
                                state.filter.vlStart = dtStart.getFullYear() - parseInt(state.model.nrIntervaloFiltro);
                                break;
                            case "ID":
                                dtStart.setDate(dtStart.getDate() - parseInt(state.model.nrIntervaloFiltro));
                                state.filter.vlStart = Util.dateFormatYYYYMMDD(dtStart);
                                state.filter.vlEnd = Util.dateFormatYYYYMMDD(dtEnd);
                                break;
                            case "IM":
                                dtStart.setDate(1);
                                dtStart.setMonth(dtStart.getMonth() - parseInt(state.model.nrIntervaloFiltro));
                                state.filter.vlStart = Util.dateFormatYYYYMM(dtStart);
                                state.filter.vlEnd = Util.dateFormatYYYYMM(dtEnd);
                                break;
                            case "IA":
                                state.filter.vlStart = dtStart.getFullYear() - parseInt(state.model.nrIntervaloFiltro);
                                state.filter.vlEnd = dtStart.getFullYear();
                                break;
                            default:
                                state.filter.vlStart = "";
                                state.filter.vlEnd = "";
                        }
                    }

                    return state;
                }, this.getDadosBloco);
            });
        }
    }

    getDadosBloco() {        
        
        if (parseInt(this.state.model.idBloco) > 0) {
            if (this.props.play === undefined || Boolean(this.props.play)) {
                this.delTimeoutBloco();

                this.setState(state => {
                    state.model.flAtualizando = true;
                    state.model.flErro = false;
                    return state;
                });

                let payload = {
                    ...this.state.model,
                    ...{ filter : this.state.filter }
                };

                BlocoService.getDados(payload ,(response) => {
                    this.setState(state => {

                        if(response.tipoBloco?.cdTipoBloco?.toString() === "graphic" && response?.modeloBloco?.cdModeloBloco?.toString() === "Calendar"){

                            var content = [                                
                                [
                                    { type: "date", id: "Date",},
                                    { type: "number", id: "Valor"},
                                ]
                            ];
                            
                            response.content.forEach(function(element, index){
                                if(index > 0){
                                    content.push([new Date(parseInt(element[0]), parseInt(element[1])-1, parseInt(element[2])), element[3]]);
                                }
                            });
                            
                            response.content = content;
                        }

                        response.txConfiguracao = JSON.parse(response.txConfiguracao);
                        
                        response.dhAtualizacao = new Date();

                        state.model = {
                            ...state.model,
                            ...response
                        };
                        
                        state.model.flAtualizando = false;
                        state.model.flErro = false;

                        return state;
                    }, () => {
                        if (Boolean(this.state.model.flAtivo)) {
                            this.addTimeoutBloco();
                        }
                        if (this.props.fnCallback !== undefined) {
                            this.props.fnCallback(this.state.model);
                        }
                    });
                }, () => {
                    this.setState(state => {
                        state.model.flAtualizando = false;
                        state.model.flErro = true;
                        return state;
                    }, () => {
                        this.addTimeoutBloco();
                        if (this.props.fnCallback !== undefined) {
                            this.props.fnCallback(this.state.model);
                        }
                    })
                });
            }
        }
    }

    handleClickFavoritar() {
        UsuarioBlocoFavoritoService.favoritar(this.state.model, () => {
            this.getBloco();
            if (this.props.fnCallbackFavoritar !== undefined) {
                this.props.fnCallbackFavoritar(this.state.model);
            }
        });
    }

    handleClickEditBloco() {
        Modal.openWindow(FormBloco, {sizing : Modal.sizing.xl}, { id : this.state.model.idBloco }, (e, response) => { 
			this.getDadosBloco();
		});
    }

    handleClickExpandirBloco() {
        Modal.open(Bloco, {sizing : Modal.sizing.fullscreen}, { idBloco : this.state.model.idBloco, expandir : true, buttons : false }, (e, response) => { });
    }

    handleClickView(output){
        var urlRelatorio = "relatorio/bloco-001";

        var model = {
            orientation: "P",
            destination: "I",
            output : output,
            flMarcaDagua: true,
        };

        model = {...this.state.model, ...model};
        Util.openNewWindow(ConfigApp.getUrlApi() + urlRelatorio, model);
    }

    fnFormat(fn, value) {
        switch (fn) {
            case "fnValorReais":
                return Util.floatToReais(value);
            default:
                return value;
        }

    }

    submitForm() {
        this.state.refForm.dispatchEvent(
            new Event("submit", { bubbles: true, cancelable: true })
        )
    };

    render() {
        var buttons = this.props.buttons === undefined || this.props.buttons ? true : false;
        var pgBarColor = ["#ed561b", "#50B432", "#058DC7", "#990099", "#DC3912", "#FF9900", "#3366CC", "#109618", "#ED7D31", "#70AD47", "#9E480E", "#000000"];
        var renderBody = <></>;
        var renderTitle = <></>;
        var renderFooter = <></>;

        if (Boolean(this.state.model.flAtivo) || this.props.forceShow) {
            if (this.state.model.progressBar !== undefined) {
                renderFooter =
                    <CardFooter className="bg-white border-dark">
                        <Div className="mb-1 d-flex flex-row gap-1 flex-wrap">
                            {this.state.model.progressBar?.map((itemPgBar, indexPgBar) => (
                                <Div key={indexPgBar} className="d-flex flex-row align-items-center gap-1 ">
                                    <Span style={{ height: "8px", width: "8px", backgroundColor: pgBarColor[indexPgBar] }}>&nbsp;</Span>
                                    <Span className={`fs-7 fw-normal text-nowrap`}>
                                        {itemPgBar.label}
                                        {parseInt(itemPgBar.value) <= 5 ? " [" + itemPgBar.value + "%]" : ""}
                                    </Span>
                                </Div>
                            ))}
                        </Div>
                        <Div className="progress" style={{ height: "20px" }}>
                            {this.state.model.progressBar?.map((itemPgBar, indexPgBar) => (
                                <Div key={indexPgBar} className={`progress-bar fw-bold`} role="progressbar" aria-label="Segment one" style={{ width: itemPgBar.value + "%", backgroundColor: pgBarColor[indexPgBar] }} aria-valuenow="15" aria-valuemin="0" aria-valuemax="100">
                                    {parseInt(itemPgBar.value) > 5 ? itemPgBar.value + "%" : ""}
                                </Div>
                            ))}
                        </Div>
                    </CardFooter>;
            }

            //renderTitle = this.state.model.nmBloco ? <Div className="card-title text-center fs-5"> {this.state.model.nmBloco} </Div> : <></>;

            var isValor = this.state.model.txConfiguracao?.isValor !== undefined ? this.state.model.txConfiguracao?.isValor : false;
            var msgOculto = <></>;
            
            switch (this.state.model.tipoBloco.cdTipoBloco) {
                case "number":
                    var content = "";

                    if (this.state.model.content !== undefined) {
                        content = this.state.model.txConfiguracao?.fnFormat !== undefined ? this.fnFormat(this.state.model.txConfiguracao.fnFormat, this.state.model.content) : this.state.model.content;
                    }
                    content = content.toString();

                    var className = this.state.model.txConfiguracao?.className !== undefined ? this.state.model.txConfiguracao?.className : "display-1 fw-bold text-nowrap";

                    renderTitle = this.state.model.nmBloco ? <Div className="card-title text-center fs-2">{this.state.model.nmBloco}</Div> : <></>;
                    renderBody =
                        <Div className={`card-text text-center`}>
                            <Span className={`align-middle ${className}`}>
                                {isValor ? <MostraValor>{content}</MostraValor> : content}
                            </Span>
                        </Div>;
                    break;

                case "list-group-numbered":
                    renderBody =
                        <ol className="list-group list-group-numbered">
                            {this.state.model.content.map((col, indexCol) => {

                                var value = false;
                                if (col.value !== undefined) {
                                    value = col.fnFormat !== undefined ? this.fnFormat(col.fnFormat, col.value) : col.value;
                                    value = isValor ? <MostraValor>{value}</MostraValor> : value;
                                }

                                return (
                                    <li key={indexCol} className="list-group-item d-flex justify-content-between align-items-start pt-1 pb-1">
                                        <Div className="ms-2 me-auto">
                                            {col.label ? <Div className="fw-bold"> {col.label}</Div> : <></>}
                                            {col.title}
                                        </Div>
                                        {col.value !== undefined ?
                                            <Span className="badge bg-primary rounded-pill">
                                                {value}
                                            </Span> : <></>}
                                    </li>);
                            })}
                        </ol>;
                    break;

                case "list-group-custom":
                    renderBody =
                        <Div className="list-group">
                            {this.state.model.content.map((col, indexCol) => {
                                var value = false;
                                if (col.value !== undefined) {
                                    value = col.fnFormat !== undefined ? this.fnFormat(col.fnFormat, col.value) : col.value;
                                    value = isValor ? <MostraValor>{value}</MostraValor> : value;
                                }

                                return (
                                    <Div key={indexCol} className="list-group-item">
                                        <Div className="d-flex w-100 justify-content-between">
                                            {col.label ? <h5 className="mb-1">{col.label}</h5> : <></>}
                                            {col.value !== undefined ?
                                                <small className="text-muted">
                                                    {value}
                                                </small> : <></>}
                                        </Div>
                                        {col.title ? <p className="mb-1">{col.title}</p> : <></>}
                                        {col.subtitle ? <small>{col.subtitle}</small> : <></>}
                                    </Div>);
                            })}
                        </Div>;

                    renderBody = isValor ? <MostraValor>{renderBody}</MostraValor> : renderBody;

                    break;

                case "table":

                    renderBody =
                        <Div className="overflow-auto" style={{ maxHeight: (this.props.expandir !== undefined && Boolean(this.props.expandir) ? 400 : 220) + "px", minHeight: "150px" }}>
                            <table className="table table-striped table-sm fs-5">
                                <thead>
                                    <tr>
                                        {this.state.model?.txConfiguracao?.tableHeader?.map((colHeader, indexColHeader) => (
                                            <th key={indexColHeader} className={`col ps-2 pe-2 ${colHeader.className !== undefined ? colHeader.className : ""}`}>{colHeader.label}</th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.model.content?.map((colBody, indexColBody) => (
                                        <tr key={indexColBody}>
                                            {this.state.model.txConfiguracao?.tableHeader.map((colHeader, indexColHeader) => (
                                                <td key={indexColHeader} className={`col ps-2 pe-2 pt-0 pb-0 ${colHeader.className !== undefined ? colHeader.className : ""}`}>
                                                    {colHeader.fnFormat !== undefined ? this.fnFormat(colHeader.fnFormat, colBody[colHeader.value]) : colBody[colHeader.value]}
                                                </td>
                                            ))}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </Div>;

                    msgOculto = (
                        <Div className="d-flex justify-content-center align-items-center" style={{ height: "220px", minHeight: "150px" }}>
                            <Div className="text-danger fw-bold fs-2">OCULTO</Div>
                        </Div>);
                    renderBody = isValor ? <MostraValor value={msgOculto}>{renderBody}</MostraValor> : renderBody;

                    break;

                case "graphic":

                    renderBody =
                        <Chart
                            chartLanguage="pt-br"
                            chartType={this.state.model.modeloBloco.cdModeloBloco}
                            width="100%"
                            height={`${this.props.expandir !== undefined && Boolean(this.props.expandir) ? "100%" : "250px"}`}
                            data={this.state.model.content}
                            options={this.state.model.txConfiguracao}
                            formatters={this.state.model.txConfiguracao?.formatters !== undefined ? this.state.model.txConfiguracao.formatters : []}
                        />;

                    msgOculto = (
                        <Div className="d-flex justify-content-center align-items-center" style={{ height: "250px", minHeight: "150px" }}>
                            <Div className="text-danger fw-bold fs-2">OCULTO</Div>
                        </Div>);
                    renderBody = isValor ? <MostraValor value={msgOculto}>{renderBody}</MostraValor> : renderBody;

                    break;

                case "iframe":
                    renderBody =
                        <iframe
                            title={this.state.model.nmBloco}
                            width="100%"
                            height={`${this.props.expandir !== undefined && Boolean(this.props.expandir) ? "100%" : "550px"}`}
                            src={this.state.model.dsUrl}></iframe>;
                    break;

                case "google-maps":

                    renderBody = 
                        <GoogleMaps apiKey={ConfigApp.getInstance().clientIdGoogleMaps} lat="-23.442955362297" lng="-51.916717513525" zoom="3">
                            {this.state.model.content?.map((item, index) => (
                                <GoogleMapsMarker key={index} lat={item?.latitude} lng={item?.longitude} title={item?.label}/>
                            ))}
                        </GoogleMaps>;
                break;

                default:
                    renderBody = <></>;
            }

            return (
                <Div className={`${this.props.expandir !== undefined && Boolean(this.props.expandir) ? "col-lg-12 h-100" : "col-lg-" + this.state.model?.nrTamanhoColuna}`}>
                    <Card className="shadow-lg h-100 border-dark">
                        <CardHeader className={`d-flex flex-nowrap pt-0 pb-0 border-dark ${this.state.model.flErro !== undefined && Boolean(this.state.model.flErro) ? "text-bg-danger" : "text-bg-light"}`}>
                            {this.state.model.flAtualizando !== undefined && Boolean(this.state.model.flAtualizando) ?
                                <Div className="spinner-border spinner-border-sm" role="status">
                                    <Span className="visually-hidden">...</Span>
                                </Div> : <></>
                            }
                            {!(this.props.play === undefined || Boolean(this.props?.play)) ?
                                    <IconPauseFill/>
                                : <></>
                            }

                            {this.state.model.nmBloco}

                            <Div className="d-flex ms-auto me-0" show={buttons}>

                                <BtnButton color={BtnButton.color.link} className="pb-0 pt-0 ps-0" onClick={e=> this.handleClickFavoritar()}>
                                    {this.state?.model?.flFavorito ? <IconStarFill width="15" height="15"/> :  <IconStar width="15" height="15"/>}
                                </BtnButton>

                                {/*this.state.model.tipoBloco.cdTipoBloco === "graphic" ? 
                                <>

                                    <BtnButton color={BtnButton.color.link}  className="pb-0 pt-0 ps-0 btn-sm text-danger" onClick={e=> this.handleClickView("PDF")}>
                                        <IconFileEarmarkPdfFill width="15" height="15"/>
                                    </BtnButton>

                                    <BtnButton color={BtnButton.color.link}  className="pb-0 pt-0 ps-0 btn-sm text-success" onClick={e=> this.handleClickView("XLS")}>
                                        <IconFileEarmarkSpreadsheetFill width="15" height="15"/>
                                    </BtnButton>
                                </>
                                : <></>*/}

                                {Permissao.getInstance().getPermissao(96) ? 
                                    <>
                                        <BtnButton color={BtnButton.color.link}  className="pb-0 pt-0 ps-0 btn-sm text-dark" onClick={e => this.getBloco()}>
                                            <IconArrowClockwise width="15" height="15"/>
                                        </BtnButton>

                                        <BtnButton color={BtnButton.color.link}  className="pb-0 pt-0 ps-0 btn-sm text-dark" onClick={e => this.handleClickEditBloco()}>
                                            <IconEdit width="15" height="15"/>
                                        </BtnButton>
                                    </>

                                : <></>}

                                {!Boolean(this.props.expandir) ?
                                    <BtnButton color={BtnButton.color.link}  className="pb-0 pt-0 ps-0 pe-0 btn-sm text-dark" onClick={this.handleClickExpandirBloco}>
                                        <IconArrowsFullscreen width="15" height="15"/>
                                    </BtnButton>
                                    : <></>}
                            </Div>
                        </CardHeader>
                        <CardBody className="p-0" align="center">
                            <form ref={ref => this.state.refForm = ref} onSubmit={this.handleSubmit} noValidate className={`needs-validation ${this.state.validated ? "was-validated" : ""}`} >
                                
                                { Boolean(this.state.model.tipoFiltro?.cdTipoFiltro === "CD") && Boolean(this.props?.filterable)? 
                                    <Row className="pb-3 ps-3 pe-3" align="left">
                                        <FormItem md={6} label="Data">
                                            <InputDate sizing="sm" value={this.state.filter.vlStart} name="vlStart" onChange={(e,v,p) => { this.handleChangeModel(e,v,p, this.submitForm) }} required={true} />                                    
                                        </FormItem>
                                    </Row>
                                :<></>}
                                { Boolean(this.state.model.tipoFiltro?.cdTipoFiltro === "CM") && Boolean(this.props?.filterable)? 
                                    <Row className="pb-3 ps-3 pe-3" align="left">
                                        <FormItem md={6} label="Mês">
                                            <InputMonth  sizing="sm" value={this.state.filter.vlStart} name="vlStart" onChange={(e,v,p) => { this.handleChangeFilter(e,v,p, this.submitForm) }} required={true} />
                                        </FormItem>
                                    </Row>
                                :<></>}
                                { Boolean(this.state.model.tipoFiltro?.cdTipoFiltro === "CA") && Boolean(this.props?.filterable)? 
                                    <Row className="pb-3 ps-3 pe-3" align="left">
                                        <FormItem md={6} label="Ano">
                                            <InputNumber sizing="sm" value={this.state.filter.vlStart} name="vlStart" onChange={(e,v,p) => { this.handleChangeFilter(e,v,p, this.submitForm) }} required={true} />                                    
                                        </FormItem>
                                    </Row>
                                :<></>}

                                { Boolean(this.state.model.tipoFiltro?.cdTipoFiltro === "ID") && Boolean(this.props?.filterable)? 
                                    <Row className="pb-3 ps-3 pe-3" align="left">
                                        <FormItem md={6} label="Data Inicial">
                                            <InputDate sizing="sm" value={this.state.filter.vlStart} name="vlStart" onChange={(e,v,p) => { this.handleChangeFilter(e,v,p, this.submitForm) }} required={true} />
                                        </FormItem>
                                        <FormItem md={6} label="Data Final">
                                            <InputDate sizing="sm" value={this.state.filter.vlEnd} name="vlEnd" onChange={(e,v,p) => { this.handleChangeFilter(e,v,p, this.submitForm) }} min={this.state.filter.vlStart} required={true} />
                                        </FormItem>
                                    </Row>
                                :<></>}
                                { Boolean(this.state.model.tipoFiltro?.cdTipoFiltro === "IM") && Boolean(this.props?.filterable)? 
                                    <Row className="pb-3 ps-3 pe-3" align="left">
                                        <FormItem md={6} label="Mês Inicial">
                                            <InputMonth sizing="sm" value={this.state.filter.vlStart} name="vlStart" onChange={(e,v,p) => { this.handleChangeFilter(e,v,p, this.submitForm) }} required={true} />
                                        </FormItem>
                                        <FormItem md={6} label="Mês Final">
                                            <InputMonth sizing="sm" value={this.state.filter.vlEnd} name="vlEnd" onChange={(e,v,p) => { this.handleChangeFilter(e,v,p, this.submitForm) }} min={this.state.filter.vlStart} required={true} />
                                        </FormItem>
                                    </Row>
                                :<></>}
                                { Boolean(this.state.model.tipoFiltro?.cdTipoFiltro === "IA") && Boolean(this.props?.filterable)? 
                                    <Row className="pb-3 ps-3 pe-3" align="left">
                                        <FormItem md={6} label="Ano Inicial">
                                            <InputNumber sizing="sm" value={this.state.filter.vlStart} name="vlStart" onChange={(e,v,p) => { this.handleChangeFilter(e,v,p, this.submitForm) }} required={true} />
                                        </FormItem>
                                        <FormItem md={6} label="Ano Final">
                                            <InputNumber sizing="sm"  value={this.state.filter.vlEnd} name="vlEnd" onChange={(e,v,p) => { this.handleChangeFilter(e,v,p, this.submitForm) }} min={this.state.filter.vlStart} required={true} />
                                        </FormItem>
                                    </Row>
                                :<></>}
                                { Boolean(this.state.model.tipoFiltro?.cdTipoFiltro === "ND") && Boolean(this.props?.filterable)? 
                                    <Row className="pb-3 ps-3 pe-3" align="left">
                                        <FormItem md={12} label="Número de Dias">
                                            <Select sizing="sm" className="w-100" name="vlStart" value={this.state.filter.vlStart} options={this.state.filtroNrDia} onChange={(e,v,p) => { this.handleChangeFilter(e,v,p, this.submitForm) }} required={true} />
                                        </FormItem>
                                    </Row>
                                :<></>}

                                { Boolean(this.state.model.tipoFiltro?.cdTipoFiltro === "N") && Boolean(this.props?.filterable)? 
                                    <Div className="alert alert-warning pt-1 pb-1" role="alert">
                                        Nenhum filtro configurado!
                                    </Div>
                                :<></>}
                            </form>
                            {renderTitle}
                            {renderBody}
                        </CardBody>

                        {renderFooter}

                        <Div className="fs-9 text-secondary text-center">
                            {this.state.model.dhAtualizacao !== undefined ?
                                "atualizado em " +
                                this.state.model.dhAtualizacao.getDate() + "/" + (this.state.model.dhAtualizacao.getMonth() + 1) + "/" + this.state.model.dhAtualizacao.getFullYear() + " " +
                                this.state.model.dhAtualizacao.getHours() + ":" + this.state.model.dhAtualizacao.getMinutes()
                                : ""}
                        </Div>
                    </Card>
                </Div>);
        } else {
            return (<></>);
        }
    }
}

function With(props) {
    let navigate = useNavigate();
    let params = useParams();
    let location = useLocation();
    return <Bloco {...props} navigate={navigate} params={params} location={location} />
}

export default With