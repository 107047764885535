//#NO-CHANGE-FILE#

import Http from "../controllers/Http";

export default class UsuarioBlocoFavoritoService {

	static urlBase = "/usuarioBlocoFavorito/";

	static favoritar(bloco, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(UsuarioBlocoFavoritoService.urlBase + 'favoritar', bloco)
			.then(fnSucess)
			.catch(fnError);
	}

	static list(fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(UsuarioBlocoFavoritoService.urlBase + 'list', {})
			.then(fnSucess)
			.catch(fnError);
	}	
}