
import FormAnsPerfilAvanco from "./FormAnsPerfilAvanco.js";
import ListAnsPerfilAvanco from "./ListAnsPerfilAvanco.js";
import { Permissao } from "../../controllers";
import { Util } from "../../utils";
import { DadoValorService, AnsPerfilAvancoService } from "../../services";

export default class Config {

	static getUrlForm() {
		return "/CadAnsPerfilAvanco";
	}

	static getUrlList() {
		return "/LstAnsPerfilAvanco";
	}

	static getComponentForm() {
		return FormAnsPerfilAvanco;
	}

	static getPermissionForm() {
		return Permissao.getInstance().getPermissoes([63]);
	}

	static getPermissionList() {
		return Permissao.getInstance().getPermissoes([63]);
	}

	static getComponentList() {
		return ListAnsPerfilAvanco;
	}

	static getService() {
		return AnsPerfilAvancoService;
	}

	static getKeyId() {
		return "idAnsPerfilAvanco";
	}

	static getKeyDescription() {
		return "nmAnsPerfilAvanco";
	}

	static setDefaultValues(state , fnCallBack = () => {}, className = undefined) {
		className = className === undefined ? "FormAnsPerfilAvanco" : className;
		let keyNameScreen = Util.toToUpperTextCamelCase(className, "_") + ".";
		var prefix = "CONFIGURACAO.";
		DadoValorService.getValues(`${prefix}${keyNameScreen}`, (response)=> {
			Util.setValueObjectCamelCase(state.model, response, keyNameScreen, prefix);
			fnCallBack();
		}, fnCallBack);
	}
}