import ConfigApp from "../../ConfigApp";
import { Cash as IconCash, CreditCard as IconCreditCard, CreditCard2BackFill as IconCreditCard2BackFill, QrCode as IconQrCode, Upc as IconUpc } from "react-bootstrap-icons";
import { Div } from "../../siht/elements";

export default class Config {

	static getRenderHeader(unidade) {

        if(!unidade){
            return <></>;
        }

		return (
            <header>
                <Div className="d-flex flex-column flex-md-row align-items-center pb-3 mb-4 border-bottom">

                    {parseInt(ConfigApp.getInstance().logoSistema?.idArquivo) > 0 && ConfigApp.getInstance().logoSistema?.cdUid && ConfigApp.getInstance().logoSistema?.arquivoBase?.cdHash ?												
                            <img style={{ maxHeight: "150px" }} src={ConfigApp.getUrlArquivo() + "public/inline/" + ConfigApp.getInstance().logoSistema.cdUid + "/" + ConfigApp.getInstance().logoSistema.arquivoBase.cdHash} className="img" alt="..." />
                    : <></>}

                    <nav className="mt-2 mt-md-0 ms-md-auto">
                        <Div><strong>{unidade?.empresa?.nmEmpresa}</strong></Div>
                        <Div>{unidade?.nmUnidade}</Div>
                    </nav>
                </Div>
            </header>

        );
	}

    static getRenderFooter(unidade) {
        
        if(!unidade){
            return <></>;
        }

		return(
            <footer className="pt-4 my-md-5 pt-md-5 border-top">
                <Div className="row">
                    <Div className="col-12 col-md">
                        {unidade?.nmUnidade}
                        <small className="d-block mb-3 text-muted">&copy; Todos os direitos reservados</small>
                    </Div>
                </Div>
            </footer>
        );
	}

    static getIconTipoPagamento(tipoPagamento) {
        switch (tipoPagamento.cdTipoPagamento) {
            case "BOLETO":
                return <IconUpc width={32} height={32}/>

            case "PIX_IMEDIATO":
            case "PIX_VENCIMENTO":
                return <IconQrCode width={32} height={32}/>

            case "CARTAO_CREDITO":
                return <IconCreditCard width={32} height={32}/>

            case "CARTAO_DEBITO":
                return <IconCreditCard2BackFill width={32} height={32}/>
            default:
                return <IconCash width={32} height={32}/>
        }
    }

    static getUrlPagamento(faturaCobrancaTransacao) {
        switch (faturaCobrancaTransacao.tipoPagamento.cdTipoPagamento) {
            case "BOLETO":
                return `/pagamento/boleto/${faturaCobrancaTransacao.cdUid}`;

            case "PIX_IMEDIATO":
            case "PIX_VENCIMENTO":
                return `/pagamento/pix/${faturaCobrancaTransacao.cdUid}`;

            case "CARTAO_CREDITO":
                return `/pagamento/cartao-credito/${faturaCobrancaTransacao.cdUid}`;

            case "CARTAO_DEBITO":
                return `/pagamento/carta-debito/${faturaCobrancaTransacao.cdUid}`;
            default:
                return null
        }
    }
}