import Http from "../controllers/Http";

export default class UnidadeService {

	static urlBase = "/unidade/";

	static getForCombo(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(UnidadeService.urlBase + 'forCombo', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static list(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(UnidadeService.urlBase + 'list', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneById(id, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.get(UnidadeService.urlBase + id)
			.then(fnSucess)
			.catch(fnError);
	}

	static create(data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(UnidadeService.urlBase, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static update(id, data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.put(UnidadeService.urlBase + id, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static delete(id, fnSucess = () => { }, fnError = () => { }) {
		if(Number.isInteger(id)){
			Http.getInstanceAppJson()
				.delete(UnidadeService.urlBase + id)
				.then(fnSucess)
				.catch(fnError);
		}else{
			Http.getInstanceAppJson()
			.post(UnidadeService.urlBase + 'delete', id)
			.then(fnSucess)
			.catch(fnError);
		}
	}

	static save(data, fnSucess = () => { }, fnError = () => { }) {
		if (data.idUnidade) {
			this.update(data.idUnidade, data, fnSucess, fnError);
		} else {
			this.create(data, fnSucess, fnError);
		}
	}

	static getAllByEmpresa(empresa, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(UnidadeService.urlBase + 'empresa', empresa)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByEmpresa(empresa, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(UnidadeService.urlBase + 'empresa/' + empresa.idEmpresa)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByPessoa(pessoa, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(UnidadeService.urlBase + 'pessoa', pessoa)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByPessoa(pessoa, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(UnidadeService.urlBase + 'pessoa/' + pessoa.idPessoa)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByGrupoUnidade(grupoUnidade, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(UnidadeService.urlBase + 'grupoUnidade', grupoUnidade)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByGrupoUnidade(grupoUnidade, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(UnidadeService.urlBase + 'grupoUnidade/' + grupoUnidade.idGrupoUnidade)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByUnidadeTipoNotaFiscal(unidadeTipoNotaFiscal, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(UnidadeService.urlBase + 'unidadeTipoNotaFiscal', unidadeTipoNotaFiscal)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByUnidadeTipoNotaFiscal(unidadeTipoNotaFiscal, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(UnidadeService.urlBase + 'unidadeTipoNotaFiscal/' + unidadeTipoNotaFiscal.idUnidadeTipoNotaFiscal)
			.then(fnSucess)
			.catch(fnError);
	}
}