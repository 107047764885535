import Http from "../controllers/Http";

export default class AnsMaterialService {

	static urlBase = "/ansMaterial/";

	static getForCombo(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(AnsMaterialService.urlBase + 'forCombo', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static list(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(AnsMaterialService.urlBase + 'list', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneById(id, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.get(AnsMaterialService.urlBase + id)
			.then(fnSucess)
			.catch(fnError);
	}

	static create(data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(AnsMaterialService.urlBase, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static update(id, data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.put(AnsMaterialService.urlBase + id, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static delete(id, fnSucess = () => { }, fnError = () => { }) {
		if(Number.isInteger(id)){
			Http.getInstanceAppJson()
				.delete(AnsMaterialService.urlBase + id)
				.then(fnSucess)
				.catch(fnError);
		}else{
			Http.getInstanceAppJson()
			.post(AnsMaterialService.urlBase + 'delete', id)
			.then(fnSucess)
			.catch(fnError);
		}
	}

	static save(data, fnSucess = () => { }, fnError = () => { }) {
		if (data.idAnsMaterial) {
			this.update(data.idAnsMaterial, data, fnSucess, fnError);
		} else {
			this.create(data, fnSucess, fnError);
		}
	}

	static getAllByAnsCaracteristicaMaterial(ansCaracteristicaMaterial, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(AnsMaterialService.urlBase + 'ansCaracteristicaMaterial', ansCaracteristicaMaterial)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByAnsCaracteristicaMaterial(ansCaracteristicaMaterial, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(AnsMaterialService.urlBase + 'ansCaracteristicaMaterial/' + ansCaracteristicaMaterial.idAnsCaracteristicaMaterial)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByAnsCategoriaMaterial(ansCategoriaMaterial, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(AnsMaterialService.urlBase + 'ansCategoriaMaterial', ansCategoriaMaterial)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByAnsCategoriaMaterial(ansCategoriaMaterial, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(AnsMaterialService.urlBase + 'ansCategoriaMaterial/' + ansCategoriaMaterial.idAnsCategoriaMaterial)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByArquivo(arquivo, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(AnsMaterialService.urlBase + 'arquivo', arquivo)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByArquivo(arquivo, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(AnsMaterialService.urlBase + 'arquivo/' + arquivo.idArquivo)
			.then(fnSucess)
			.catch(fnError);
	}
}