//#NO-CHANGE-FILE#

import FaturaCobrancaService from "../../services/FaturaCobrancaService";
import FormFaturaCobranca from "./FormFaturaCobranca";
import ListFaturaCobranca from "./ListFaturaCobranca";
import Permissao from "../../controllers/Permissao";

export default class Config {

	static getUrlForm() {
		return "/CadFaturaCobranca";
	}

	static getUrlList() {
		return "/LstFaturaCobranca";
	}

	static getComponentForm() {
		return FormFaturaCobranca;
	}

	static getPermissionForm() {
		return Permissao.getInstance().getPermissoes([69]);
	}

	static getPermissionList() {
		return Permissao.getInstance().getPermissoes([69]);
	}

	static getComponentList() {
		return ListFaturaCobranca;
	}

	static getService() {
		return FaturaCobrancaService;
	}

	static getKeyId() {
		return "idFaturaCobranca";
	}

	static getKeyDescription() {
		return "nmFaturaCobranca";
	}

	static getPermissaoCobranca(faturaCobranca){

		if(faturaCobranca === undefined){
			faturaCobranca = { idFatura : true };
		}

		return {
			flPodeImprimir : true,
			flPodeBaixarManualmente : Permissao.getInstance().getPermissoes([85]),
			flPodeAlteraVencimento : Permissao.getInstance().getPermissoes([86]),
			flPodeAlterarTipoPagamento : Permissao.getInstance().getPermissoes([87]),
			flPodeVerHistoricoTransacao : Permissao.getInstance().getPermissoes([88]),
			flPodeVerHistoricoBaixa : Permissao.getInstance().getPermissoes([89]),
			flPodeGerarAcordo : Permissao.getInstance().getPermissoes([167]),
		};
	}
}