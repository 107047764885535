//#NO-CHANGE-FILE#

import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import SelectEmpresa from "../../components/SelectDB/SelectEmpresa";
import BtnRadioGroupSimNao from "../../components/BtnRadioGroupSimNao";
import ManyToManyCheckGrupoUnidadeByGrupo from "./ManyToManyCheckGrupoUnidadeByGrupo";
import Permissao from "../../controllers/Permissao";
import { BaseCrud, BaseList } from "../../siht/base";
import { FormButtons, FormItem, Row, TabNavigator, Tab, Panel, PanelBody, PanelFooter, Container, Col } from "../../siht/components";
import { InputText, Form, BtnSubmit } from "../../siht/elements";
import Config from "./Config";
import FormPessoa from "../Pessoa/FormPessoa";
import ListDadoValorByParent from "../DadoValor/ListDadoValorByParent";
import { PatchPlus as IconPatchPlus} from "react-bootstrap-icons";
import { FormItemPessoaByCdTipoPessoa } from "../../components/FormItem";
import ListUnidadeTipoNotaFiscal from "../UnidadeTipoNotaFiscal/ListUnidadeTipoNotaFiscal";
import ListDadoValorByParentForm from "../DadoValor/ListDadoValorByParentForm";

class FormUnidade extends BaseCrud {

	constructor(props) {
		super(props);
		this.init = this.init.bind(this);
		this.handleSuccess = this.handleSuccess.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleChangeModel = this.handleChangeModel.bind(this);
		this.handleSubmitForm = this.handleSubmitForm.bind(this);

		this.state.model = {
			idUnidade : null,
			empresa : null,
			pessoa : null,
			nmUnidade : "",
			flAtivo : true,
			flPadrao : false,
			grupoUnidade : [],
			unidadeDadoValor : []
		};
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		super.init(Config);
	}

	handleSuccess(e) {
		super.handleSuccess(e, Config);
	}

	handleClose(e) {
		super.handleClose(e, Config);
	}

	render() {
		return super.render(

					<Panel key={this.state.key} title="Cadastro de Unidade" onClose={this.handleClose} isModal={this.isModal()} container={{sizing: Container.sizing.xxl, className : ""}}>
						<PanelBody className="p-0 m-0">
							<TabNavigator key={this.state.key} onChangeTab={this.handleChangeTab} className="border-light">
								<Tab title="Unidade" className="m-2">
									<Form onSubmit={this.handleSuccess} refParam={f => (this.form = f)}>
										<Row className="g-3 pb-2 px-2">
											<FormItem md={2} label="Código">
												<InputText name="idUnidade" value={this.state.model.idUnidade} readOnly={true} className="readonly-field" required={false} onChange={this.handleChangeModel}/>
											</FormItem>
											<Col md={6}></Col>											
											<FormItem md={2} label="Padrão?">
												<BtnRadioGroupSimNao name="flPadrao" value={this.state.model.flPadrao} required={true} onChange={this.handleChangeModel}/>
											</FormItem>
											<FormItem md={2} label="Ativo?">
												<BtnRadioGroupSimNao name="flAtivo" value={this.state.model.flAtivo} required={true} onChange={this.handleChangeModel}/>
											</FormItem>

											<FormItem md={12} label="Empresa">
												<SelectEmpresa name="empresa" value={this.state.model.empresa} required={true} onChange={this.handleChangeModel} placeholder="Selecione a Empresa"/>
											</FormItem>

											<FormItemPessoaByCdTipoPessoa md={12} cdTipoPessoa="J" name="pessoa" value={this.state.model.pessoa} required={true} onChange={this.handleChangeModel} placeholder="Selecione Nome do Pessoa"/>
											
											<FormItem md={12} label="Nome da Unidade">
												<InputText name="nmUnidade" value={this.state.model.nmUnidade} autoFocus={true} maxLength={100} required={true} onChange={this.handleChangeModel}/>
											</FormItem>

											<ListDadoValorByParentForm cdDadoLocal="UNIDADE" parent={this.state.model} onChange={this.handleChangeModel} name="unidadeDadoValor"/>
											
											<BtnSubmit hidden > Enviar </BtnSubmit>
										</Row>
									</Form>
								</Tab>
								<Tab title="Pessoa" disabled={!this.state.model.idUnidade}>
									<FormPessoa key={this.state.model?.pessoa?.idPessoa} id={this.state.model?.pessoa?.idPessoa} localOpen={BaseCrud.localOpen.embedded} handleSuccess={e => {}} handleClose={false}/>
								</Tab>
								<Tab show={Permissao.getInstance().getPermissoes([151])} title="Nota Fiscal" disabled={!this.state.model.idUnidade} opened={false} className="m-2">
									<ListUnidadeTipoNotaFiscal parent={this.state.model} localOpen={BaseList.localOpen.modal}/>
								</Tab>
								<Tab show={Permissao.getInstance().getPermissoes([11])} title="Grupos" disabled={!this.state.model.idUnidade} opened={false} className="m-2">
									<ManyToManyCheckGrupoUnidadeByGrupo typeCheck={ManyToManyCheckGrupoUnidadeByGrupo.typeCheck.box} parent={this.state.model} search={true}/>
								</Tab>
								<Tab show={Permissao.getInstance().getPermissoes([129])} title={<><IconPatchPlus/> Complementar</>} disabled={!this.state.model.idUnidade} opened={false} className="p-0 m-0">
									<ListDadoValorByParent cdDadoLocal="UNIDADE" parent={this.state.model} localOpen={BaseList.localOpen.modal}/>
								</Tab>
							</TabNavigator>
						</PanelBody>
						<PanelFooter>
							<FormButtons show={this.state.tabIndex === 0} onClose={this.handleClose} onSubmit={this.handleSubmitForm}/>
						</PanelFooter>
					</Panel>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <FormUnidade {...props} navigate={navigate} params={params} location={location}/>
}
export default With