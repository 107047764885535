//#NO-CHANGE-FILE#
import FaturaService from "../../services/FaturaService";
import FormFatura from "./FormFatura";
import ListFatura from "./ListFatura";
import Permissao from "../../controllers/Permissao";

export default class Config {

	static getUrlForm() {
		return "/CadFatura";
	}

	static getUrlList() {
		return "/LstFatura";
	}

	static getComponentForm() {
		return FormFatura;
	}

	static getPermissionForm() {
		return Permissao.getInstance().getPermissoes([68]);
	}

	static getPermissionList() {
		return Permissao.getInstance().getPermissoes([68]);
	}

	static getComponentList() {
		return ListFatura;
	}

	static getService() {
		return FaturaService;
	}

	static getKeyId() {
		return "idFatura";
	}

	static getKeyDescription() {
		return "nmFatura";
	}

	static getPermissaoFatura(fatura){

		if(fatura === undefined){
			fatura = { idFatura : true };
		}

		return {
			flPodeImprimir : Permissao.getInstance().getPermissoes([110]),
			flPodeEstornarFatura : Permissao.getInstance().getPermissoes([84]),
			flPodeBaixarManualmente : Permissao.getInstance().getPermissoes([85]),
			flPodeCriarNotaFiscalServico : Permissao.getInstance().getPermissoes([94]),
		};
	}
}