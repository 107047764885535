//#NO-CHANGE-FILE#

import Config from "../FaturaCobranca/Config";
import Util from "../../utils/Util";
import React from "react";
import { useNavigate, useLocation, useParams, Link } from "react-router-dom";
import {ThreeDotsVertical as IconThreeDotsVertical} from "react-bootstrap-icons"
import { BaseList } from "../../siht/base";
import { DataGrid, DataGridButtons, DataGridColumn, Row, InputGroup, InputGroupText, InputGroupButton, DropDown, DropDownLabel, DropDownItem, PanelFooter, FormItem, BtnRadioGroup, Badge, DataGridFooter, FormItemLabelDropDown } from "../../siht/components";
import { Button, Div, Form, InputSearch, BtnSubmit, BtnButton, Span, InputDate, InputNumber, BtnRadio, Option, InputText } from "../../siht/elements";
import { ArrowLeftRight as IconArrowLeftRight, Calendar2Week as IconCalendar2Week, Check2Circle as IconCheck2Circle, Coin as IconCoin, CurrencyExchange as IconCurrencyExchange, HandThumbsUp as IconHandThumbsUp, Link45deg as IconLink45deg, ListOl as IconListOl } from "react-bootstrap-icons";
import { BadgeSimNao, BtnView, StatusCustom } from "../../components/Custom";
import { IconClear, IconSearch } from "../../icons";
import { FormItemTipoPessoaPessoa } from "../../components/FormItem";
import { Modal } from "../../siht/controller";
import FormItemEmpresaUnidade from "../../components/FormItem/FormItemEmpresaUnidade";
import FormFaturaCobranca from "../FaturaCobranca/FormFaturaCobranca";
import FormFatura from "./FormFatura";
import FormFaturaCobrancaAlterarVencimento from "../FaturaCobranca/FormFaturaCobrancaAlterarVencimento";
import ListFaturaCobrancaTransacao from "../FaturaCobranca/ListFaturaCobrancaTransacao";
import ListFaturaCobrancaBaixa from "../FaturaCobranca/ListFaturaCobrancaBaixa";
import FormFaturaCobrancaBaixaManual from "../FaturaCobranca/FormFaturaCobrancaBaixaManual";
import SelectStatusFatura from "../../components/SelectDBMultiple/SelectStatusFatura";
import SelectStatusFaturaCobranca from "../../components/SelectDBMultiple/SelectStatusFaturaCobranca";


class ListFaturaCobranca extends BaseList {

	constructor(props) {
		super(props);
		this.getItens = this.getItens.bind(this);
		this.handleChange = this.handleChange.bind(this);		
		this.handleMore = this.handleMore.bind(this);
		this.onSubmitFilter = this.onSubmitFilter.bind(this);
		this.handleClear = this.handleClear.bind(this);
		this.handleFormSuccess = this.handleFormSuccess.bind(this);
		this.handleFormClose = this.handleFormClose.bind(this);
		this.handleChangeFilter = this.handleChangeFilter.bind(this);		
		this.initStateFilter = this.initStateFilter.bind(this);
        this.handleVerCobranca = this.handleVerCobranca.bind(this);
        this.handleVerFatura = this.handleVerFatura.bind(this);
        this.handleAlterarVencimento = this.handleAlterarVencimento.bind(this);
        this.handleClickGerarAcordo = this.handleClickGerarAcordo.bind(this);
        this.handleBaixaManual = this.handleBaixaManual.bind(this);
		this.handleHistoricoBaixas = this.handleHistoricoBaixas.bind(this);
		this.handleHistoricoTransacoes = this.handleHistoricoTransacoes.bind(this);
        this.getTotal = this.getTotal.bind(this);

	}

	initStateFilter(){

        return {
			unidade : null,
			statusFaturas : null,
            statusFaturaCobrancas : null,
            pessoa : null,
			dtStart : "",
			dtEnd : "",
			idFatura : "",
			idFaturaCobranca : "",
			idOrdem : "",
			cdStatusVencimento : "T",
			nrNF : "",
            search: "",
            page : 1,
            pageSize : 50,

            cdData : null,
			cdCodigo : null,
        };
    }

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		super.init(Config);
	}

	getItens(more){
		super.getItens(Config.getService().list, false, more);
	}

	handleVerCobranca(e, item) {
		Modal.openWindow(FormFaturaCobranca, {sizing : Modal.sizing.xl}, { id : item.idFaturaCobranca }, (e, response) => { 
			//this.getItens();
		});
	}

    handleVerFatura(e, item) {
		Modal.openWindow(FormFatura, {sizing : Modal.sizing.xl}, { id : item.fatura.idFatura }, (e, response) => { 
			this.getItens();
		});
	}

    handleAlterarVencimento(e, item = null){
        item = item !== null ? item : this.state.selecteds;

		Modal.openWindow(FormFaturaCobrancaAlterarVencimento, {sizing : Modal.sizing.xl}, { faturaCobranca : item }, (e, response) => { 
			this.getItens();
		});
	}

    handleHistoricoBaixas(e, item){
		Modal.openWindow(ListFaturaCobrancaBaixa, {sizing : Modal.sizing.fullscreen, scrollable : true}, { faturaCobranca : item }, (e, response) => { 
			this.getItens();
		}, () => {
			this.getItens();
		});
	}

	handleHistoricoTransacoes(e, item){
		Modal.openWindow(ListFaturaCobrancaTransacao, {sizing : Modal.sizing.fullscreen, scrollable : true}, { faturaCobranca : item }, (e, response) => { 
			this.getItens();
		}, () => {
			this.getItens();
		});
	}
	
	handleBaixaManual(e, item){
		Modal.openWindow(FormFaturaCobrancaBaixaManual, {sizing : Modal.sizing.xl}, { faturaCobranca : item }, (e, response) => { 
			this.getItens();
		});
	}
	
    handleClickGerarAcordo(e, item = null){

        if (this.state.selecteds.length > 0) {
            let unidade = this.state.selecteds[0].fatura.unidade;
            let pessoa = this.state.selecteds[0].fatura.pessoa.pessoaCopia;

            this.props.navigate('/CadAcordo', { state : { faturaCobranca : this.state.selecteds, unidade : unidade, pessoa : pessoa }});
        }
	}

    podeAlterarVencimentoItemSelecionados() {		
        
        if (this.state.selecteds.length > 0) {
			return this.state.selecteds.length === (this.state.selecteds.filter(cobranca => {
				return !cobranca?.fatura.statusFatura?.flEstornada && !cobranca?.statusFaturaCobranca?.flEstornada;
			}).length);
        }

        return false;
    }

    podeGerarAcordoItemSelecionados() {		
        
        if (this.state.selecteds.length > 0) {

            let unidade = this.state.selecteds[0].fatura.unidade;
            let pessoa = this.state.selecteds[0].fatura.pessoa.pessoaCopia;

			return this.state.selecteds.length === (this.state.selecteds.filter(cobranca => {
				return (
                        !cobranca?.fatura.statusFatura?.flEstornada && !cobranca?.statusFaturaCobranca?.flEstornada
                        && parseInt(cobranca?.fatura?.unidade?.idUnidade) === parseInt(unidade.idUnidade)
                        && parseInt(cobranca?.fatura?.pessoa?.pessoaCopia?.idPessoa) === parseInt(pessoa.idPessoa)
                    );
			}).length);
        }

        return false;
    }

    getTotal(prop){
		let total = 0;
		if(this.state.selecteds.length === 0){
			total = this.state.data.reduce((soma, item) => soma + item[prop], 0);
		}else{
			total = this.state.selecteds.reduce((soma, item) => soma + item[prop], 0);
		}

		return total;
	}

	render() {

		return super.render(
			<Div className="px-2" key={this.state.key}>
                <Div className="d-flex justify-content-end flex-nowrap gap-2 pb-3">
                    <BtnButton show={Config.getPermissaoCobranca().flPodeGerarAcordo} color={BtnButton.color.outlinePrimary} disabled={this.state.selecteds.length === 0 || !this.podeGerarAcordoItemSelecionados()} sizing={BtnButton.sizing.default} onClick={e => this.handleClickGerarAcordo(e, null)}> <IconHandThumbsUp/> Gerar Acordo </BtnButton>
                    <BtnButton show={Config.getPermissaoCobranca().flPodeAlteraVencimento} color={BtnButton.color.outlinePrimary} disabled={this.state.selecteds.length === 0 || !this.podeAlterarVencimentoItemSelecionados()} sizing={BtnButton.sizing.default} onClick={e => this.handleAlterarVencimento(e, null)}> <IconCalendar2Week/> Alterar Vencimento </BtnButton>
                </Div>

                <Form onSubmit={this.onSubmitFilter}>
                    <Row className="g-3">
                        <FormItem md={2} label={
							<FormItemLabelDropDown value={this.state.filter.cdCodigo} name="cdCodigo" onChange={this.handleChangeFilter}>
                                <Option value="ID_FATURA" label="Código da Fatura"  selected={true}/>
                                <Option value="ID_FATURA_COBRANCA" label="Código da Cobrança"/>
                                <Option value="ID_FATURA_ACORDO" label="Código do Acordo"/>
                                <Option value="CD_IDENTIFICADOR_EXTERNO" label="Identificador Externo"/>
                                <Option value="CD_PEDIDO_EXTERNO" label="Pedido Externo"/>
								<Option value="NR_NOTA_FISCAL" label="Número da Nota Fiscal"/>
								<Option value="NR_RPS" label="Número do RPS"/>
                                <Option value="ID_ORDEM" label="Código da Ordem"/>
							</FormItemLabelDropDown>
						}>
							{(() => {
								switch(this.state.filter.cdCodigo?.value){
									case "CD_IDENTIFICADOR_EXTERNO":
                                    case "CD_PEDIDO_EXTERNO": 
                                        return <InputText name="codigo" value={this.state.filter.codigo} opcional={true} required={false} onChange={this.handleChangeFilter}/>
									default: return <InputNumber name="codigo" value={this.state.filter.codigo} opcional={true} required={false} onChange={this.handleChangeFilter}/>
								}
							})()}
                        </FormItem>

                        <FormItem md={2} label={
							<FormItemLabelDropDown value={this.state.filter.cdData} name="cdData" onChange={this.handleChangeFilter} getOptionLabel={i => i?.label !== undefined ? `Data Inicial de ${i.label}` : i?.label}>								
								<Option value="VENCIMENTO_COBRANCA" label="Vencimento" selected={true}/>
                                <Option value="CRIACAO_FATURA" label="Faturamento" />
							</FormItemLabelDropDown>
						}>
							<InputDate name="dtStart" max={this.dtHoje} value={this.state.filter.dtStart} required={false} opcional={true} onChange={this.handleChangeFilter} className="text-center"/>
						</FormItem>

                        <FormItem md={2} label={`Data Final de ${this.state.filter.cdData?.label}`}>
							<InputDate name="dtEnd" min={this.state.filter.dtStart} max={this.dtHoje} value={this.state.filter.dtEnd} required={false} opcional={true} onChange={this.handleChangeFilter} className="text-center"/>
						</FormItem>

                        <FormItem md={3} label="Status Fatura">                            
                            <SelectStatusFatura name="statusFaturas" value={this.state.filter.statusFaturas} required={false} opcional={true} onChange={this.handleChangeFilter} placeholder="Selecione o Status" buttons={{search : false, new : false, edit: false}}/>
                        </FormItem>

                        <FormItem md={3} label="Status Cobrança">
                            <SelectStatusFaturaCobranca name="statusFaturaCobrancas" value={this.state.filter.statusFaturaCobrancas} required={false} opcional={true} onChange={this.handleChangeFilter} placeholder="Selecione o Status" buttons={{search : false, new : false, edit: false}}/>
                        </FormItem>

                        <FormItem md={4} label="Status Pagamento">
                            <BtnRadioGroup name="cdStatusVencimento" color={BtnRadioGroup.color.primary} opcional={true} value={this.state.filter.cdStatusVencimento} onClick={this.handleChangeFilter}>
                                <BtnRadio value="T" label="Todos" />
                                <BtnRadio value="V" label="Vencido" />
                                <BtnRadio value="D" label="Em dia" />
                            </BtnRadioGroup>
                        </FormItem>                        
                        
                        <FormItemEmpresaUnidade md={[4,4]} name="unidade" opcional={true} value={this.state.filter.unidade} required={false} onChange={this.handleChangeFilter} buttons={{search : false, new : false, edit: false}}/>
                        <FormItemTipoPessoaPessoa label={["Tipo Cliente", "Cliente"]} md={[4,8]} name="pessoa" value={this.state.filter.pessoa} required={false} onChange={this.handleChangeFilter} opcional={true}/>
                    </Row>
                    <Row className="mt-1 g-3">
                        <InputGroup className="flex-nowrap" sizing={InputGroup.sizing.default}>
                            <InputGroupText><IconSearch/></InputGroupText>
                            <InputSearch autoComplete="off" autoFocus placeholder="Digite sua busca" name="search" value={this.state.filter.search} required={false} opcional={true} onChange={(e,v,n) => this.handleChange(e,v,n, this.state.filter)}/>
                            <InputGroupButton>
                                <BtnSubmit title="Filtrar"> Buscar </BtnSubmit>
                                <BtnButton title="Limpar Pesquisa" color={BtnButton.color.outlineDanger} onClick={this.handleClear}> <IconClear/> </BtnButton>
                            </InputGroupButton>
                        </InputGroup>
                    </Row>
                </Form>

                <DataGrid responsive={true} data={this.state.data} search={false} isDisabled={i => false} sizing={DataGrid.sizing.sm} select={true} selecteds={this.state.selecteds} onSelect={(selecteds)=>{this.setState({selecteds : selecteds})}} getItemValue={i=>i.idFaturaCobranca} onMore={this.handleMore}>
                    
                    <DataGridColumn md={1} label="Fatura" field={i => <>
                        {i.fatura.idFatura}
                        <StatusCustom className="w-100" color={i.fatura.statusFatura}> {i.fatura.statusFatura?.nmStatusFatura} </StatusCustom>
                    </>} className="text-center"/>

                    <DataGridColumn md={1} label="Cobrança" field={i => <>
                        {i.idFaturaCobranca}
                        <StatusCustom className="w-100" color={i.statusFaturaCobranca}> {i.statusFaturaCobranca?.nmStatusFaturaCobranca} </StatusCustom>
                    </>} className="text-center"/>

                    <DataGridColumn md={2} label="Cliente" field={i => <>
                        <Span className="fw-bold">{i.fatura?.pessoa?.nmPessoa}</Span> <br/>
                        <StatusCustom color={i.fatura.pessoa?.tipoPessoa}> PESSOA {i.fatura.pessoa?.tipoPessoa?.nmTipoPessoa} </StatusCustom>
                        <Div className="fw-bold fs-7" show={i.fatura.pessoa?.tipoPessoa?.cdTipoPessoa === "F"}>{Util.formataCPF(i.fatura.pessoa?.nrCpf)}</Div>
                        <Div className="fw-bold fs-7" show={i.fatura.pessoa?.tipoPessoa?.cdTipoPessoa === "J"}>{Util.formataCnpj(i.fatura.pessoa?.nrCnpj)}</Div>
                        </>}
                    className="text-start"/>

                    <DataGridColumn md={2} mobile={false} label="Empresa / Unidade" field={i => <>
                            <Span className="fw-bold">{i.fatura?.unidade?.empresa?.nmEmpresa}</Span> <br/>
                            {i.fatura?.unidade?.nmUnidade}
                        </>} className="text-start"/>

					<DataGridColumn label="Parcela" field={i => i.nrParcela} className="text-center"/>					
                    <DataGridColumn md={1} label="Vencimento" field={i => <>
						<Div> {Util.date2Br(i.dtVencimento)} </Div>
						{i.flVencido && !i.statusFaturaCobranca.flPaga ? <Badge color={Badge.color.danger}>Vencido</Badge> : <></>}
					</>} className="text-center"/>
                    
					<DataGridColumn md={1} label="Encargos" field={i => (<React.Fragment>
						<Div className="fs-7">Juros: {i.flJuros ? <Span className="fw-bold"> {Util.floatToValor(i.vlJuros, 2)} % </Span> : <BadgeSimNao value={i.flJuros}/>} </Div>
						<Div className="fs-7">Multa: {i.flMulta ? <Span className="fw-bold"> {Util.floatToValor(i.vlMulta, 2)} % </Span> : <BadgeSimNao value={i.flMulta}/>} </Div>
					</React.Fragment>)} className="text-end"/>
					
					<DataGridColumn md={1} label="Cobrança" field={i => <React.Fragment>
						<Span className="fw-bold text-primary"> {Util.floatToReais(i.vlCobranca)}  </Span>
					</React.Fragment>} className="text-end"/>

					<DataGridColumn md={1} label="Aberto" field={i => <React.Fragment>
						<Div className={`fw-bold ${i.vlAberto > 0 ? "text-danger" : "text-secondary"}`}> {Util.floatToReais(i.vlAberto)}  </Div>
						
						{i.flVencido && (i.flJuros || i.flMulta) ?
							<React.Fragment>
								<Div className="fs-7">Juros: {i.flJuros ? <Span className={`fw-bold ${i.flJuros === 0 ? "text-secondary" : "text-danger"}`}> {Util.floatToReais(i.vlJurosHoje, 2)} </Span> : <></>} </Div>
								<Div className="fs-7">Multa: {i.flMulta ? <Span className={`fw-bold ${i.flMulta === 0 ? "text-secondary" : "text-danger"}`}> {Util.floatToReais(i.vlMultaHoje, 2)} </Span> : <></>} </Div>
							</React.Fragment> : <></>
						}
					</React.Fragment>} className="text-end"/>

                    <DataGridColumn col={1} label="Cobrado" field={i => <React.Fragment>
						<Div className={`fw-bold ${i.vlCobrado > 0 ? "text-danger" : "text-secondary"}`}> {Util.floatToReais(i.vlCobrado)}  </Div>
					</React.Fragment>} className="text-end"/>

					<DataGridColumn md={1} label="Baixas" field={i => <React.Fragment>
						<Div className={`fw-bold ${i.vlBaixa === 0 ? "text-secondary" : "text-success"}`}> {Util.floatToReais(i.vlBaixa)}  </Div>
						<Div className={`fs-7`}> Pagamentos: <Span className={`fw-bold ${i.vlPago === 0 ? "text-secondary" : "text-success"}`}>{Util.floatToReais(i.vlPago)}</Span>  </Div>
						<Div className={`fs-7`}> Descontos: <Span className={`fw-bold ${i.vlDesconto === 0 ? "text-secondary" : "text-danger"}`}>{Util.floatToReais(i.vlDesconto)}</Span>  </Div>
                        <Div className={`fs-7`}> Total: <Span className={`fw-bold ${i.vlBaixaFinal === 0 ? "text-secondary" : "text-success"}`}>{Util.floatToReais(i.vlBaixaFinal)}</Span>  </Div>

						{i.vlBaixaEncargo > 0 ?
							<Div className={`fs-7`}> Encargos: <Span className={`fw-bold ${i.vlBaixaEncargo === 0 ? "text-secondary" : "text-danger"}`}>{Util.floatToReais(i.vlBaixaEncargo)}</Span>  </Div>
						: <></>}
					</React.Fragment>} className="text-end"/>

					<DataGridButtons>
                        <Link target="_blank" to={i => `/pagamento/${i.cdUid}`} className="btn btn-outline-success">
                            <IconLink45deg/>
                        </Link>
                        <BtnView sizing={Button.sizing.default} onClick={this.handleVerCobranca}/>

                        <DropDown alignment={DropDown.alignment.end}>
							<DropDownLabel sizing={Button.sizing.default} toggle={false}> <IconThreeDotsVertical/> </DropDownLabel>
                            <DropDownItem title="Ver Cobrança" type="button" onClick={this.handleVerCobranca}> <IconListOl/> Ver Cobrança </DropDownItem>
							<DropDownItem title="Ver Fatura" type="button" onClick={this.handleVerFatura}> <IconCoin/> Ver Fatura </DropDownItem>

                            <DropDownItem title="Histórico de Baixas" type="button" onClick={this.handleHistoricoBaixas} disabled={this.props?.parent?.statusFatura?.flEstornada || !Config.getPermissaoCobranca().flPodeVerHistoricoBaixa}> <IconCheck2Circle/> Histórico de Baixas </DropDownItem>
							<DropDownItem title="Histórico de Transações" type="button" onClick={this.handleHistoricoTransacoes} disabled={this.props?.parent?.statusFatura?.flEstornada || !Config.getPermissaoCobranca().flPodeVerHistoricoTransacao}> <IconArrowLeftRight/> Histórico de Transações </DropDownItem>
							<DropDownItem title="Baixar Manualmente" type="button" onClick={this.handleBaixaManual} disabled={this.props?.parent?.statusFatura?.flEstornada || !Config.getPermissaoCobranca().flPodeBaixarManualmente}> <IconCurrencyExchange/> Baixar Manualmente </DropDownItem>
							<DropDownItem title="Alterar Vencimento" type="button" onClick={this.handleAlterarVencimento} disabled={this.props?.parent?.statusFatura?.flEstornada || !Config.getPermissaoCobranca().flPodeAlteraVencimento}> <IconCalendar2Week/> Alterar Vencimento </DropDownItem>
						</DropDown>

					</DataGridButtons>

                    <DataGridFooter>
						<DataGridColumn colSpan={8} className="text-center"/>
						<DataGridColumn label="Valor"  className="text-end">
							<Span className="fw-bold text-primary"> {Util.floatToReais(this.getTotal("vlCobranca"))} </Span>
						</DataGridColumn>
                        <DataGridColumn label="Valor"  className="text-end">
							<Span className="fw-bold text-danger"> {Util.floatToReais(this.getTotal("vlAberto"))} </Span>
						</DataGridColumn>
                        <DataGridColumn label="Valor"  className="text-end">
							<Span className="fw-bold text-danger"> {Util.floatToReais(this.getTotal("vlCobrado"))} </Span>
						</DataGridColumn>
                        <DataGridColumn label="Valor"  className="text-end">
                            <Div className={`text-nowrap fw-bold text-success`}> {Util.floatToReais(this.getTotal("vlBaixa"))}  </Div>
                            <Div className={`text-nowrap fs-7`}> Pagamentos: <Span className={`fw-bold text-success`}>{Util.floatToReais(this.getTotal("vlPago"))}</Span>  </Div>
                            <Div className={`text-nowrap fs-7`}> Descontos: <Span className={`fw-bold text-danger`}>{Util.floatToReais(this.getTotal("vlDesconto"))}</Span>  </Div>
                            <Div className={`text-nowrap fs-7`}> Total: <Span className={`fw-bold text-success`}>{Util.floatToReais(this.getTotal("vlBaixaFinal"))}</Span>  </Div>
						</DataGridColumn>
						<DataGridColumn colSpan={2} className="text-center"/>
					</DataGridFooter>

                </DataGrid>

                <PanelFooter className="text-center py-2" show={this.state.data && this.state.data?.length > 0}>
                    <BtnButton onClick={this.handleMore} disabled={!this.state.hasMore}> Buscar Mais Itens </BtnButton>
                </PanelFooter>
            </Div>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <ListFaturaCobranca {...props} navigate={navigate} params={params} location={location}/>
}
export default With