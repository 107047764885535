import { Component } from "react";

export default class InputGroup extends Component {

    static sizing = {
        sm: "sm",
        default: false,
        lg: "lg",
    }

    render() {
        if(this.props.show !== undefined && !Boolean(this.props.show)){
            return (<></>);
        }

        const props = Object.assign({}, this.props);
        props.className = `input-group ${props.className !== undefined ? props.className : ""} ${props.sizing !== undefined && props.sizing ? `input-group-${props.sizing}` : ""}`;

        delete props.sizing;
        delete props.show;
        delete props.opcional;

        return (
            <div {...props}> 
                {this.props.children} 
            </div>
        );
    }
}