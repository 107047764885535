//#NO-CHANGE-FILE#
import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import BtnEdit from "../../components/BtnEdit";
import BtnDelete from "../../components/BtnDelete";
import BtnNew from "../../components/BtnNew";
import Config from "./Config";
import { BaseList } from "../../siht/base";
import { DataGrid, DataGridButtons, DataGridColumn } from "../../siht/components";
import { Button, Div } from "../../siht/elements";
import Util from "../../utils/Util";
import { StatusCustom } from "../../components/Custom";

class ListProdutoPreco extends BaseList {

	constructor(props) {
		super(props);
		this.getItens = this.getItens.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleNew = this.handleNew.bind(this);
		this.handleEdit = this.handleEdit.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.handleMore = this.handleMore.bind(this);
		this.onSubmitFilter = this.onSubmitFilter.bind(this);
		this.handleClear = this.handleClear.bind(this);
		this.handleFormSuccess = this.handleFormSuccess.bind(this);
		this.handleFormClose = this.handleFormClose.bind(this);
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		super.init(Config);
	}

	getItens(more){
		if(this.props?.parent){
			super.getItens(Config.getService().getAllByProduto, this.props.parent, more);
		}
	}

	handleNew(e) {
		super.handleNew(e, Config);
	}

	handleEdit(e, item) {
		super.handleEdit(e, Config, item);
	}

	handleDelete(e, item) {
		super.handleDelete(e, Config, item);
	}

	getFormEmbedded(){
		return super.getFormEmbedded(Config);
	}

	render() {
		return super.render(
			<Div className="p-2 mt-2">
				<Div className="d-flex justify-content-end flex-nowrap gap-2">
					<BtnNew onClick={this.handleNew}/>
					<BtnDelete disabled={this.state.selecteds.length === 0} sizing={Button.sizing.default} onClick={e => this.handleDelete(e, this.state.selecteds)}> Excluir </BtnDelete>
				</Div>

				{this.getFormEmbedded()}

				<DataGrid data={this.state.data} search={false} isDisabled={i => false} sizing={DataGrid.sizing.sm} select={true} selecteds={this.state.selecteds} onSelect={(selecteds)=>{this.setState({selecteds : selecteds})}} getItemValue={i=>i.idProdutoPreco} onMore={this.handleMore}>
					<DataGridColumn label="Código" field={i => i.idProdutoPreco} className="text-center"/>
					<DataGridColumn col={6} label="Tipo do Preco" field={i => 
						<StatusCustom className='w-100 fs-6' color={i.tipoPreco}> {i.tipoPreco.nmTipoPreco} </StatusCustom>
					} className="text-start"/>
					<DataGridColumn col={6} label="Preço" field={i =>
						i.vlPreco === null ? 
							<span className="text-danger text-end col-6 fs-7 fst-italic"> 
								SEM ACESSO
							</span> : 
							<span className="fw-bold text-success text-end col-6"> 
								{Util.floatToReais(i.vlPreco)}
							</span>
					} className="text-center"/>
					
					<DataGridButtons>
						<BtnEdit disabled={i => i.vlPreco === null} sizing={Button.sizing.default} onClick={this.handleEdit}/>
						<BtnDelete disabled={i => i.vlPreco === null} sizing={Button.sizing.default} onClick={this.handleDelete}/>
					</DataGridButtons>
				</DataGrid>
			</Div>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <ListProdutoPreco {...props} navigate={navigate} params={params} location={location}/>
}
export default With