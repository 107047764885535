//#NO-CHANGE-FILE#

import Config from "./Config";
import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { BaseCrud } from "../../siht/base";
import { FormItem, Row, Panel, PanelBody, PanelFooter, Container, ModalDialog } from "../../siht/components";
import { InputText, TextArea, Form, BtnButton } from "../../siht/elements";
import { Util } from "../../utils";

class FormUsuarioToken extends BaseCrud {

	constructor(props) {
		super(props);
		this.init = this.init.bind(this);
		this.initModel = this.initModel.bind(this);
		this.handleClose = this.handleClose.bind(this);
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init() {
		super.init(Config);
	}

	initModel() {
		return {
			idUsuarioToken: null,
			usuario: this.props.parent !== undefined ? this.props.parent : {},
			cdToken: "",
			flAtivo: true,
			txDado: ""
		}
	}

	handleClose(e) {
		super.handleClose(e, Config);
	}

	render() {
		return super.render(

			<Form onSubmit={this.handleSuccess} refParam={f => (this.form = f)} key={this.state.key}>
				<Panel key={this.state.key} title="Cadastro de UsuarioToken" localOpen={this.props.localOpen} onClose={this.handleClose} isModal={this.isModal()} container={{ sizing: Container.sizing.xxl, className: "" }} modal={{ sizing: this.props.sizing ? this.props.sizing : ModalDialog.sizing.xl }}>
					<PanelBody>
						<Row className="g-3 pb-2 px-2">
							<FormItem md={6} label="Token">
								<InputText name="cdToken" value={this.state.model.cdToken} readOnly={true} />
							</FormItem>
							<FormItem md={2} label="Data">
								<InputText name="dhInsert" value={Util.date2Br(this.state.model.dhInsert, true)} readOnly={true} required={false} className="text-center" />
							</FormItem>
							<FormItem md={2} label="Validade">
								<InputText name="dhValidade" value={Util.date2Br(this.state.model.dhValidade, true)} readOnly={true} required={false} className="text-center" />
							</FormItem>
							<FormItem md={2} label="Validado em">
								<InputText name="dhValidacao" value={Util.date2Br(this.state.model.dhValidacao, true)} readOnly={true} required={false} className="text-center" />
							</FormItem>
							<FormItem md={12} label="TxDado">
								<TextArea name="txDado" value={this.state.model.txDado} readOnly={true} className="text-bg-dark" style={{ height: "400px", fontFamily: "Courier New" }} />
							</FormItem>
						</Row>
					</PanelBody>
					<PanelFooter>
						<BtnButton color={BtnButton.color.outlineSecondary} onClick={this.handleClose}> Fechar </BtnButton>
					</PanelFooter>
				</Panel>
			</Form>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <FormUsuarioToken {...props} navigate={navigate} params={params} location={location} />
}
export default With