
import FormUsuarioUnidade from "./FormUsuarioUnidade.js";
import ListUsuarioUnidade from "./ListUsuarioUnidade.js";
import { Permissao } from "../../controllers";
import { Util } from "../../utils";
import { DadoValorService, UsuarioUnidadeService } from "../../services";

export default class Config {

	static getUrlForm() {
		return "/CadUsuarioUnidade";
	}

	static getUrlList() {
		return "/LstUsuarioUnidade";
	}

	static getComponentForm() {
		return FormUsuarioUnidade;
	}

	static getPermissionForm() {
		return Permissao.getInstance().getPermissoes([78]);
	}

	static getPermissionList() {
		return Permissao.getInstance().getPermissoes([78]);
	}

	static getComponentList() {
		return ListUsuarioUnidade;
	}

	static getService() {
		return UsuarioUnidadeService;
	}

	static getKeyId() {
		return "idUsuarioUnidade";
	}

	static getKeyDescription() {
		return "nmUsuarioUnidade";
	}

	static setDefaultValues(state , fnCallBack = () => {}, className = undefined) {
		className = className === undefined ? "FormUsuarioUnidade" : className;
		let keyNameScreen = Util.toToUpperTextCamelCase(className, "_") + ".";
		var prefix = "CONFIGURACAO.";
		DadoValorService.getValues(`${prefix}${keyNameScreen}`, (response)=> {
			Util.setValueObjectCamelCase(state.model, response, keyNameScreen, prefix);
			fnCallBack();
		}, fnCallBack);
	}
}