import Permissao from "../controllers/Permissao";
import { CardHeading as IconCardHeading, Alarm as IconAlarm, AlignBottom as IconAlignBottom, Archive as IconArchive, ArrowLeftRight as IconArrowLeftRight, ArrowRightShort as IconArrowRightShort, Bank as IconBank, Bank2 as IconBank2, BarChartSteps as IconBarChartSteps, Basket as IconBasket, BookmarkStar as IconBookmarkStar, Box2 as IconBox2, BoxArrowInDownLeft as IconBoxArrowInDownLeft, BoxSeam as IconBoxSeam, BuildingFillDown as IconBuildingFillDown, Buildings as IconBuildings, CardChecklist as IconCardChecklist, CarFront as IconCarFront, Cash as IconCash, CircleSquare as IconCircleSquare, ClipboardMinus as IconClipboardMinus, ClipboardPlus as IconClipboardPlus, Coin as IconCoin, ConeStriped as IconConeStriped, Crosshair2 as IconCrosshair2, Database as IconDatabase, DatabaseFillGear as IconDatabaseFillGear, DatabaseUp as IconDatabaseUp, Diagram3Fill as IconDiagram3Fill, Diagram3 as IconDiagram3, FilterSquare as IconFilterSquare, FuelPump as IconFuelPump, Gear as IconGear, Geo as IconGeo, GeoAlt as IconGeoAlt, GeoFill as IconGeoFill, GraphUp as IconGraphUp, HandThumbsUp as IconHandThumbsUp, InboxFill as IconInboxFill, Key as IconKey, Map as IconMap, Palette as IconPalette, People as IconPeople, PeopleFill as IconPeopleFill, PersonCircle as IconPersonCircle, PersonFill as IconPersonFill, PersonLinesFill as IconPersonLinesFill, PersonRolodex as IconPersonRolodex, Plugin as IconPlugin, Rulers as IconRulers, SendX as IconSendX, ShopWindow as IconShopWindow, Speedometer as IconSpeedometer, Square as IconSquare, SquareHalf as IconSquareHalf, Tag as IconTag, ViewStacked as IconViewStacked, Wallet as IconWallet, Wallet2 as IconWallet2, PersonBoundingBox as IconPersonBoundingBox, SignStopFill as IconSignStopFill } from "react-bootstrap-icons";
import { BtnButton } from "../siht/elements";
import { IconHandHoldingUsd } from "../icons";

export default class Tree {

	static getMenu() {

		return [
			{
				label: "Tipagem", icon: IconBarChartSteps, tree: [
					/*[BEGIN_MENU_ITEM_TIPO_EMAIL]*//*#NO-CHANGE-MENU#*/
					{ label: "Tipo de Email", description: "", detail: "/LstTipoEmail", to: "/LstTipoEmail", permission: Permissao.getInstance().getPermissao(17), shortchut: false, icon: IconFilterSquare },
					/*[END_MENU_ITEM_TIPO_EMAIL]*/

					/*[BEGIN_MENU_ITEM_TIPO_ORDEM]*//*#NO-CHANGE-MENU#*/
					{ label: "Tipo de Ordem", description: "", detail: "/LstTipoOrdem", to: "/LstTipoOrdem", permission: Permissao.getInstance().getPermissao(22), shortchut: false, icon: IconFilterSquare },
					/*[END_MENU_ITEM_TIPO_ORDEM]*/

					/*[BEGIN_MENU_ITEM_TIPO_PRECO]*//*#NO-CHANGE-MENU#*/
					{ label: "Tipo de Preço", description: "", detail: "/LstTipoPreco", to: "/LstTipoPreco", permission: Permissao.getInstance().getPermissao(23), shortchut: false, icon: IconFilterSquare },
					/*[END_MENU_ITEM_TIPO_PRECO]*/

					/*[BEGIN_MENU_ITEM_TIPO_PESSOA]*//*#NO-CHANGE-MENU#*/
					{ label: "Tipo de Pessoa", description: "", detail: "/LstTipoPessoa", to: "/LstTipoPessoa", permission: Permissao.getInstance().getPermissao(15), shortchut: false, icon: IconFilterSquare },
					/*[END_MENU_ITEM_TIPO_PESSOA]*/

					/*[BEGIN_MENU_ITEM_TIPO_CONTATO]*//*#NO-CHANGE-MENU#*/
					{ label: "Tipo de Contato", description: "", detail: "/LstTipoContato", to: "/LstTipoContato", permission: Permissao.getInstance().getPermissao(19), shortchut: false, icon: IconFilterSquare },
					/*[END_MENU_ITEM_TIPO_CONTATO]*/

					/*[BEGIN_MENU_ITEM_TIPO_PRODUTO]*//*#NO-CHANGE-MENU#*/
					{ label: "Tipo de Produto", description: "", detail: "/LstTipoProduto", to: "/LstTipoProduto", permission: Permissao.getInstance().getPermissao(21), shortchut: false, icon: IconFilterSquare },
					/*[END_MENU_ITEM_TIPO_PRODUTO]*/

					/*[BEGIN_MENU_ITEM_TIPO_TELEFONE]*//*#NO-CHANGE-MENU#*/
					{ label: "Tipo de Telefone", description: "", detail: "/LstTipoTelefone", to: "/LstTipoTelefone", permission: Permissao.getInstance().getPermissao(16), shortchut: false, icon: IconFilterSquare },
					/*[END_MENU_ITEM_TIPO_TELEFONE]*/

					/*[BEGIN_MENU_ITEM_TIPO_ENDERECO]*//*#NO-CHANGE-MENU#*/
					{ label: "Tipo de Endereço", description: "", detail: "/LstTipoEndereco", to: "/LstTipoEndereco", permission: Permissao.getInstance().getPermissao(18), shortchut: false, icon: IconFilterSquare },
					/*[END_MENU_ITEM_TIPO_ENDERECO]*/

					/*[BEGIN_MENU_ITEM_TIPO_PAGAMENTO]*//*#NO-CHANGE-MENU#*/
					{ label: "Tipo de Pagamento", description: "", detail: "/LstTipoPagamento", to: "/LstTipoPagamento", permission: Permissao.getInstance().getPermissao(20), shortchut: false, icon: IconFilterSquare },
					/*[END_MENU_ITEM_TIPO_PAGAMENTO]*/

					/*[BEGIN_MENU_ITEM_TIPO_TRANSACAO]*//*#NO-CHANGE-MENU#*/
					{ label: "Tipo de Transação", description: "", detail: "/LstTipoTransacao", to: "/LstTipoTransacao", permission: Permissao.getInstance().getPermissao(24), shortchut: false, icon: IconFilterSquare },
					/*[END_MENU_ITEM_TIPO_TRANSACAO]*/

					/*[BEGIN_MENU_ITEM_TIPO_BAIXA]*//*#NO-CHANGE-MENU#*/
					{ label: "Tipo de Baixa", description: "", detail: "/LstTipoBaixa", to: "/LstTipoBaixa", permission: Permissao.getInstance().getPermissao(91), shortchut: false, icon: IconFilterSquare },
					/*[END_MENU_ITEM_TIPO_BAIXA]*/

					/*[BEGIN_MENU_ITEM_TIPO_PAGINA]*//*#NO-CHANGE-MENU#*/
					{ label: "Tipo de Página", description: "", detail: "/LstTipoPagina", to: "/LstTipoPagina", permission: Permissao.getInstance().getPermissao(105), shortchut: false, icon: IconFilterSquare },
					/*[END_MENU_ITEM_TIPO_PAGINA]*/

					/*[BEGIN_MENU_ITEM_TIPO_NOTIFICACAO]*//*#NO-CHANGE-MENU#*/
					{ label: "Tipo de Notificação", description: "", detail: "/LstTipoNotificacao", to: "/LstTipoNotificacao", permission: Permissao.getInstance().getPermissao(107), shortchut: false, icon: IconFilterSquare },
					/*[END_MENU_ITEM_TIPO_NOTIFICACAO]*/

					/*[BEGIN_MENU_ITEM_TIPO_DADO]*//*#NO-CHANGE-MENU#*/
					{ label: "Tipo de Dado", description: "", detail: "/LstTipoDado", to: "/LstTipoDado", permission: Permissao.getInstance().getPermissao(119), shortchut: false, icon: IconFilterSquare },
					/*[END_MENU_ITEM_TIPO_DADO]*/

					/*[BEGIN_MENU_ITEM_TIPO_BAIRRO]*//*#NO-CHANGE-MENU#*/
					{ label: "Tipo de Bairro", description: "", detail: "/LstTipoBairro", to: "/LstTipoBairro", permission: Permissao.getInstance().getPermissao(127), shortchut: false, icon: IconFilterSquare },
					/*[END_MENU_ITEM_TIPO_BAIRRO]*/

					/*[BEGIN_MENU_ITEM_TIPO_LOGRADOURO]*//*#NO-CHANGE-MENU#*/
					{ label: "Tipo de Logradouro", description: "", detail: "/LstTipoLogradouro", to: "/LstTipoLogradouro", permission: Permissao.getInstance().getPermissao(126), shortchut: false, icon: IconFilterSquare },
					/*[END_MENU_ITEM_TIPO_LOGRADOURO]*/

					/*[BEGIN_MENU_ITEM_TIPO_REGIME_TRIBUTARIO]*//*#NO-CHANGE-MENU#*/
					{ label: "Tipo de Regime de Tributação", description: "", detail: "/LstTipoRegimeTributario", to: "/LstTipoRegimeTributario", permission: Permissao.getInstance().getPermissao(138), shortchut: false, icon: IconFilterSquare },
					/*[END_MENU_ITEM_TIPO_REGIME_TRIBUTARIO]*/

					/*[BEGIN_MENU_ITEM_TIPO_REGIME_TRIBUTARIO_ESPECIAL]*//*#NO-CHANGE-MENU#*/
					{ label: "Tipo de Regime de Tributação Especial", description: "", detail: "/LstTipoRegimeTributarioEspecial", to: "/LstTipoRegimeTributarioEspecial", permission: Permissao.getInstance().getPermissao(139), shortchut: false, icon: IconFilterSquare },
					/*[END_MENU_ITEM_TIPO_REGIME_TRIBUTARIO_ESPECIAL]*/

					/*[BEGIN_MENU_ITEM_TIPO_FATURA]*//*#NO-CHANGE-MENU#*/
					{ label: "Tipo de Fatura", description: "", detail: "/LstTipoFatura", to: "/LstTipoFatura", permission: Permissao.getInstance().getPermissao(166), shortchut: false, icon: IconFilterSquare },
					/*[END_MENU_ITEM_TIPO_FATURA]*/

					/*[BEGIN_MENU_ITEM_TIPO_DESCONTO]*//*#NO-CHANGE-MENU#*/
					{ label: "Tipo de Desconto", description: "", detail: "/LstTipoDesconto", to: "/LstTipoDesconto", permission: Permissao.getInstance().getPermissao(169), shortchut: false, icon: IconFilterSquare },
					/*[END_MENU_ITEM_TIPO_DESCONTO]*/

					/*[BEGIN_MENU_ITEM_TIPO_VEICULO]*//*#NO-CHANGE-MENU#*/
					{ label: "Tipo de Veículo", description: "", detail: "/LstTipoVeiculo", to: "/LstTipoVeiculo", permission: Permissao.getInstance().getPermissao(172), shortchut: false, icon: IconFilterSquare },
					/*[END_MENU_ITEM_TIPO_VEICULO]*/

					/*[BEGIN_MENU_ITEM_TIPO_RECORRENCIA]*//*#NO-CHANGE-MENU#*/
					{ label: "Tipo de Recorrência", description: "", detail: "/LstTipoRecorrencia", to: "/LstTipoRecorrencia", permission: Permissao.getInstance().getPermissao(188), shortchut: false, icon: IconFilterSquare },
					/*[END_MENU_ITEM_TIPO_RECORRENCIA]*/

					/*[BEGIN_MENU_ITEM_TIPO_CONTA_PAGAR]*//*#NO-CHANGE-MENU#*/
					{ label: "Tipo de Conta a Pagar", description: "", detail: "/LstTipoContaPagar", to: "/LstTipoContaPagar", permission: Permissao.getInstance().getPermissao(191), shortchut: false, icon: IconFilterSquare },
					/*[END_MENU_ITEM_TIPO_CONTA_PAGAR]*/

					/*[BEGIN_MENU_ITEM_TIPO_CONTA_RECEBER]*//*#NO-CHANGE-MENU#*/
					{ label: "Tipo de Conta a Receber", description: "", detail: "/LstTipoContaReceber", to: "/LstTipoContaReceber", permission: Permissao.getInstance().getPermissao(192), shortchut: false, icon: IconFilterSquare },
					/*[END_MENU_ITEM_TIPO_CONTA_RECEBER]*/

					/*[BEGIN_MENU_ITEM_TIPO_PRAZO]*//*#NO-CHANGE-MENU#*/
					{ label: "Tipo de Prazo", description: "", detail: "/LstTipoPrazo", to: "/LstTipoPrazo", permission: Permissao.getInstance().getPermissao(199), shortchut: false, icon: IconFilterSquare },
					/*[END_MENU_ITEM_TIPO_PRAZO]*/

					/*[BEGIN_MENU_ITEM_TIPO_NOTIFICACAO_DESTINO]*//*#NO-CHANGE-MENU#*/
					{ label: "Tipo de Notificação Destino", description: "", detail: "/LstTipoNotificacaoDestino", to: "/LstTipoNotificacaoDestino", permission: Permissao.getInstance().getPermissao(201), shortchut: false, icon: IconFilterSquare },
					/*[END_MENU_ITEM_TIPO_NOTIFICACAO_DESTINO]*/

					/*[BEGIN_MENU_ITEM_TIPO_VALOR]*//*#NO-CHANGE-MENU#*/
					{ label: "Tipo de Valor", description: "", detail: "/LstTipoValor", to: "/LstTipoValor", permission: Permissao.getInstance().getPermissao(209), shortchut: false, icon: IconFilterSquare },

					/*[END_MENU_ITEM_TIPO_VALOR]*//*[BEGIN_MENU_ITEM_TIPO_COMISSAO]*//*#NO-CHANGE-MENU#*/
					{ label: "Tipo de Comissão", description: "", detail: "/LstTipoComissao", to: "/LstTipoComissao", permission: Permissao.getInstance().getPermissao(210), shortchut: false, icon: IconFilterSquare },
					/*[END_MENU_ITEM_TIPO_COMISSAO]*/

					/*[BEGIN_MENU_ITEM_TIPO_DEPENDENTE]*//*#NO-CHANGE-MENU#*/
					{ label: "Tipo de Dependente", description: "", detail: "/LstTipoDependente", to: "/LstTipoDependente", permission: Permissao.getInstance().getPermissao(217), shortchut: false, icon: IconFilterSquare },
					/*[END_MENU_ITEM_TIPO_DEPENDENTE]*/

					/*[BEGIN_MENU_ITEM_TIPO_COMISSAO_DATA]*//*#NO-CHANGE-MENU#*/
					{ label: "Tipo de Comissão Data", description: "", detail: "/LstTipoComissaoData", to: "/LstTipoComissaoData", permission: Permissao.getInstance().getPermissao(220), shortchut: false, icon: IconFilterSquare },
					/*[END_MENU_ITEM_TIPO_COMISSAO_DATA]*/

					/*[BEGIN_MENU_ITEM_TIPO_DOCUMENTO]*//*#NO-CHANGE-MENU#*/
					{ label: "Tipo de Documento", description: "", detail: "/LstTipoDocumento", to: "/LstTipoDocumento", permission: Permissao.getInstance().getPermissao(224), shortchut: false, icon: IconFilterSquare },
					/*[END_MENU_ITEM_TIPO_DOCUMENTO]*/

					/*[BEGIN_MENU_ITEM_TIPO_FILIACAO]*//*#NO-CHANGE-MENU#*/
					{ label: "Tipo de Filiação", description: "", detail: "/LstTipoFiliacao", to: "/LstTipoFiliacao", permission: Permissao.getInstance().getPermissao(231), shortchut: false, icon: IconFilterSquare },
					/*[END_MENU_ITEM_TIPO_FILIACAO]*/

					/*[BEGIN_MENU_ITEM_TIPO_EVENTO_SALARIO]*//*#NO-CHANGE-MENU#*/
					{ label: "Tipo de Evento Salário", description: "", detail: "/LstTipoEventoSalario", to: "/LstTipoEventoSalario", permission: Permissao.getInstance().getPermissao(233), shortchut: false, icon: IconFilterSquare },
					/*[END_MENU_ITEM_TIPO_EVENTO_SALARIO]*/

					/*[BEGIN_MENU_ITEM_TIPO_PAGAMENTO_SALARIO]*//*#NO-CHANGE-MENU#*/
					{ label: "Tipo de Pagamento Salário", description: "", detail: "/LstTipoPagamentoSalario", to: "/LstTipoPagamentoSalario", permission: Permissao.getInstance().getPermissao(234), shortchut: false, icon: IconFilterSquare },
					/*[END_MENU_ITEM_TIPO_PAGAMENTO_SALARIO]*/
				]
			},
			{
				label: "Base", icon: IconAlignBottom, tree: [
					/*[BEGIN_MENU_ITEM_PAIS]*//*#NO-CHANGE-MENU#*/
					{ label: "País", description: "", detail: "/LstPais", to: "/LstPais", permission: Permissao.getInstance().getPermissao(26), shortchut: false, icon: IconGeo },
					/*[END_MENU_ITEM_PAIS]*/

					/*[BEGIN_MENU_ITEM_ESTADO]*//*#NO-CHANGE-MENU#*/
					{ label: "Estado", description: "", detail: "/LstEstado", to: "/LstEstado", permission: Permissao.getInstance().getPermissao(27), shortchut: false, icon: IconGeoFill },
					/*[END_MENU_ITEM_ESTADO]*/

					/*[BEGIN_MENU_ITEM_CIDADE]*//*#NO-CHANGE-MENU#*/
					{ label: "Cidade", description: "", detail: "/LstCidade", to: "/LstCidade", permission: Permissao.getInstance().getPermissao(28), shortchut: false, icon: IconGeoAlt },
					/*[END_MENU_ITEM_CIDADE]*/

					/*[BEGIN_MENU_ITEM_FUSO_HORARIO]*//*#NO-CHANGE-MENU#*/
					{ label: "Fuso Horário", description: "", detail: "/LstFusoHorario", to: "/LstFusoHorario", permission: Permissao.getInstance().getPermissao(25), shortchut: false, icon: IconAlarm },
					/*[END_MENU_ITEM_FUSO_HORARIO]*/

					/*[BEGIN_MENU_ITEM_UNIDADE_MEDIDA]*//*#NO-CHANGE-MENU#*/
					{ label: "Unidade de Medida", description: "", detail: "/LstUnidadeMedida", to: "/LstUnidadeMedida", permission: Permissao.getInstance().getPermissao(32), shortchut: false, icon: IconRulers },
					/*[END_MENU_ITEM_UNIDADE_MEDIDA]*/

					/*[BEGIN_MENU_ITEM_PESSOA]*//*#NO-CHANGE-MENU#*/
					{ label: "Pessoa", description: "", detail: "/LstPessoa", to: "/LstPessoa", permission: Permissao.getInstance().getPermissao(35), shortchut: true, icon: IconPersonFill },
					/*[END_MENU_ITEM_PESSOA]*/

					/*[BEGIN_MENU_ITEM_CARGO]*//*#NO-CHANGE-MENU#*/
					{ label: "Cargo", description: "", detail: "/LstCargo", to: "/LstCargo", permission: Permissao.getInstance().getPermissao(67), shortchut: false, icon: IconPersonRolodex },
					/*[END_MENU_ITEM_CARGO]*/


					/*[BEGIN_MENU_ITEM_BANCO]*//*#NO-CHANGE-MENU#*/
					{ label: "Banco", description: "", detail: "/LstBanco", to: "/LstBanco", permission: Permissao.getInstance().getPermissao(42), shortchut: false, icon: IconBank },
					/*[END_MENU_ITEM_BANCO]*/

					/*[BEGIN_MENU_ITEM_CONTA]*//*#NO-CHANGE-MENU#*/
					{ label: "Conta", description: "", detail: "/LstConta", to: "/LstConta", permission: Permissao.getInstance().getPermissao(43), shortchut: false, icon: IconWallet },
					/*[END_MENU_ITEM_CONTA]*/

					{ label: "Configurações", description: "", detail: "/LstConfiguracao", to: "/LstConfiguracao", permission: Permissao.getInstance().getPermissao(39), shortchut: true, icon: IconGear },

					/*[BEGIN_MENU_ITEM_PLUGIN]*//*#NO-CHANGE-MENU#*/
					{ label: "Plugin", description: "", detail: "/LstPlugin", to: "/LstPlugin", permission: Permissao.getInstance().getPermissao(152), shortchut: false, icon: IconPlugin },
					/*[END_MENU_ITEM_PLUGIN]*/

					/*[BEGIN_MENU_ITEM_FUNCIONALIDADE_PLUGIN]*//*#NO-CHANGE-MENU#*/
					{ label: "Funcionalidade Plugin", description: "", detail: "/LstFuncionalidadePlugin", to: "/LstFuncionalidadePlugin", permission: Permissao.getInstance().getPermissao(158), shortchut: false, icon: IconArrowRightShort },
					/*[END_MENU_ITEM_FUNCIONALIDADE_PLUGIN]*/

					/*[BEGIN_MENU_ITEM_WEBHOOK]*//*#NO-CHANGE-MENU#*/
					{ label: "Webhook", description: "", detail: "/LstWebhook", to: "/LstWebhook", permission: Permissao.getInstance().getPermissao(162), shortchut: false, icon: IconBoxArrowInDownLeft },
					/*[END_MENU_ITEM_WEBHOOK]*/

					/*[BEGIN_MENU_ITEM_LOG_REQUEST]*//*#NO-CHANGE-MENU#*/
					{ label: "Requests", description: "", detail: "/LstLogRequest", to: "/LstLogRequest", permission: Permissao.getInstance().getPermissao(164), shortchut: true, icon: IconArrowLeftRight },
					/*[END_MENU_ITEM_LOG_REQUEST]*/

					/*[BEGIN_MENU_ITEM_OBRA]*//*#NO-CHANGE-MENU#*/
					{ label: "Obra", description: "Controle de Obras", detail: "/LstObra", to: "/LstObra", permission: Permissao.getInstance().getPermissao(171), shortchut: true, icon: IconConeStriped },
					/*[END_MENU_ITEM_OBRA]*/

					/*[BEGIN_MENU_ITEM_VEICULO]*//*#NO-CHANGE-MENU#*/
					{ label: "Veículo", description: "Controle de Veículo", detail: "/LstVeiculo", to: "/LstVeiculo", permission: Permissao.getInstance().getPermissao(173), shortchut: true, icon: IconCarFront },
					/*[END_MENU_ITEM_VEICULO]*/

					/*[BEGIN_MENU_ITEM_COMBUSTIVEL]*//*#NO-CHANGE-MENU#*/
					{ label: "Combustível", description: "", detail: "/LstCombustivel", to: "/LstCombustivel", permission: Permissao.getInstance().getPermissao(179), shortchut: false, icon: IconFuelPump },
					/*[END_MENU_ITEM_COMBUSTIVEL]*/

					/*[BEGIN_MENU_ITEM_TRANSMISSAO]*//*#NO-CHANGE-MENU#*/
					{ label: "Transmissão", description: "", detail: "/LstTransmissao", to: "/LstTransmissao", permission: Permissao.getInstance().getPermissao(180), shortchut: false, icon: IconDiagram3Fill },
					/*[END_MENU_ITEM_TRANSMISSAO]*/

					{ label: "Mapa", description: "", detail: "/LstMapaItens", to: "/LstMapaItens", permission: Permissao.getInstance().getPermissao(125), shortchut: true, icon: IconMap },

					/*[BEGIN_MENU_ITEM_ESTADO_CIVIL]*//*#NO-CHANGE-MENU#*/
					{ label: "Estado Civil", description: "", detail: "/LstEstadoCivil", to: "/LstEstadoCivil", permission: Permissao.getInstance().getPermissao(222), shortchut: false, icon: IconArrowRightShort },
					/*[END_MENU_ITEM_ESTADO_CIVIL]*/

					/*[BEGIN_MENU_ITEM_NACIONALIDADE]*//*#NO-CHANGE-MENU#*/
					{ label: "Nacionalidade", description: "", detail: "/LstNacionalidade", to: "/LstNacionalidade", permission: Permissao.getInstance().getPermissao(227), shortchut: false, icon: IconArrowRightShort },
					/*[END_MENU_ITEM_NACIONALIDADE]*/

					/*[BEGIN_MENU_ITEM_GENERO]*//*#NO-CHANGE-MENU#*/
					{ label: "Genero", description: "", detail: "/LstGenero", to: "/LstGenero", permission: Permissao.getInstance().getPermissao(229), shortchut: false, icon: IconArrowRightShort },
					/*[END_MENU_ITEM_GENERO]*/

					/*[BEGIN_MENU_ITEM_ESCALA_TRABALHO]*//*#NO-CHANGE-MENU#*/
					{ label: "Escala de Trabalho", description: "", detail: "/LstEscalaTrabalho", to: "/LstEscalaTrabalho", permission: Permissao.getInstance().getPermissao(235), shortchut: false, icon: IconArrowRightShort },
					/*[END_MENU_ITEM_ESCALA_TRABALHO]*/
				]
			},
			{
				label: "Empresa", icon: IconBuildings, tree: [
					/*[BEGIN_MENU_ITEM_EMPRESA]*//*#NO-CHANGE-MENU#*/
					{ label: "Empresa", description: "", detail: "/LstEmpresa", to: "/LstEmpresa", permission: Permissao.getInstance().getPermissao(50), shortchut: true, icon: IconBuildings },
					/*[END_MENU_ITEM_EMPRESA]*/

					/*[BEGIN_MENU_ITEM_UNIDADE]*//*#NO-CHANGE-MENU#*/
					{ label: "Unidade", description: "", detail: "/LstUnidade", to: "/LstUnidade", permission: Permissao.getInstance().getPermissao(30), shortchut: true, icon: IconShopWindow },
					/*[END_MENU_ITEM_UNIDADE]*/

					/*[BEGIN_MENU_ITEM_UNIDADE_LOCAL]*//*#NO-CHANGE-MENU#*/
					{ label: "Local", description: "", detail: "/LstUnidadeLocal", to: "/LstUnidadeLocal", permission: Permissao.getInstance().getPermissao(31), shortchut: false, icon: IconInboxFill },
					/*[END_MENU_ITEM_UNIDADE_LOCAL]*/

					/*[BEGIN_MENU_ITEM_EQUIPE]*//*#NO-CHANGE-MENU#*/
					{ label: "Equipe", description: "", detail: "/LstEquipe", to: "/LstEquipe", permission: Permissao.getInstance().getPermissao(29), shortchut: false, icon: IconPeopleFill },
					/*[END_MENU_ITEM_EQUIPE]*/

					/*[BEGIN_MENU_ITEM_FUNCIONARIO]*//*#NO-CHANGE-MENU#*/
					{ label: "Funcionário", description: "", detail: "/LstFuncionario", to: "/LstFuncionario", permission: Permissao.getInstance().getPermissao(211), shortchut: false, icon: IconPersonBoundingBox },
					/*[END_MENU_ITEM_FUNCIONARIO]*/

					/*[BEGIN_MENU_ITEM_STATUS_FUNCIONARIO]*//*#NO-CHANGE-MENU#*/
					{ label: "Status do Funcionário", description: "", detail: "/LstStatusFuncionario", to: "/LstStatusFuncionario", permission: Permissao.getInstance().getPermissao(213), shortchut: false, icon: IconCircleSquare },
					/*[END_MENU_ITEM_STATUS_FUNCIONARIO]*/
				]
			},
			{
				label: "Usuário", icon: IconPersonLinesFill, tree: [
					/*[BEGIN_MENU_ITEM_USUARIO]*//*#NO-CHANGE-MENU#*/
					{ label: "Usuário", description: "", detail: "/LstUsuario", to: "/LstUsuario", permission: Permissao.getInstance().getPermissao(3), shortchut: true, icon: IconPersonCircle },
					/*[END_MENU_ITEM_USUARIO]*/

					/*[BEGIN_MENU_ITEM_GRUPO]*//*#NO-CHANGE-MENU#*/
					{ label: "Grupo", description: "", detail: "/LstGrupo", to: "/LstGrupo", permission: Permissao.getInstance().getPermissao(10), shortchut: false, icon: IconPeople },
					/*[END_MENU_ITEM_GRUPO]*/

					/*[BEGIN_MENU_ITEM_MODULO]*//*#NO-CHANGE-MENU#*/
					{ label: "Módulo", description: "", detail: "/LstModulo", to: "/LstModulo", permission: Permissao.getInstance().getPermissao(2), shortchut: false, icon: IconKey },
					/*[END_MENU_ITEM_MODULO]*/

					/*[BEGIN_MENU_ITEM_SUBMODULO]*//*#NO-CHANGE-MENU#*/
					{ label: "Sub-Módulo", description: "", detail: "/LstSubmodulo", to: "/LstSubmodulo", permission: Permissao.getInstance().getPermissao(1), shortchut: false, icon: IconViewStacked },
					/*[END_MENU_ITEM_SUBMODULO]*/
				]
			},
			{
				label: "Dado", icon: IconDatabase, tree: [
					/*[BEGIN_MENU_ITEM_DADO]*//*#NO-CHANGE-MENU#*/
					{ label: "Dado", description: "", detail: "/LstDado", to: "/LstDado", permission: Permissao.getInstance().getPermissao(120), shortchut: false, icon: IconDatabaseUp },
					/*[END_MENU_ITEM_DADO]*/

					/*[BEGIN_MENU_ITEM_DADO_LOCAL]*//*#NO-CHANGE-MENU#*/
					{ label: "Local", description: "", detail: "/LstDadoLocal", to: "/LstDadoLocal", permission: Permissao.getInstance().getPermissao(122), shortchut: false, icon: IconDatabaseFillGear },
					/*[END_MENU_ITEM_DADO_LOCAL]*/
				]
			},
			{
				label: "Automáticos", icon: IconArrowRightShort, tree: [
					/*[AUTOMATIC_MENU_ITEM]*/
				]
			},
			{
				label: "Nota Fiscal", icon: IconBank2, tree: [
					/*[BEGIN_MENU_ITEM_STATUS_NOTA_FISCAL]*//*#NO-CHANGE-MENU#*/
					{ label: "Status da Nota Fiscal", description: "", detail: "/LstStatusNotaFiscal", to: "/LstStatusNotaFiscal", permission: Permissao.getInstance().getPermissao(128), shortchut: false, icon: IconCircleSquare },
					/*[END_MENU_ITEM_STATUS_NOTA_FISCAL]*/

					/*[BEGIN_MENU_ITEM_FATURA_NOTA_FISCAL]*//*#NO-CHANGE-MENU#*/
					{ label: "Nota Fiscal", description: "", detail: "/LstFaturaNotaFiscal", to: "/LstFaturaNotaFiscal", permission: Permissao.getInstance().getPermissao(130), shortchut: true, icon: IconBank2 },
					/*[END_MENU_ITEM_FATURA_NOTA_FISCAL]*/

					/*[BEGIN_MENU_ITEM_TIPO_ARQUIVO_NOTA_FISCAL]*//*#NO-CHANGE-MENU#*/
					{ label: "Tipo de Arquivo", description: "", detail: "/LstTipoArquivoNotaFiscal", to: "/LstTipoArquivoNotaFiscal", permission: Permissao.getInstance().getPermissao(135), shortchut: false, icon: IconFilterSquare },
					/*[END_MENU_ITEM_TIPO_ARQUIVO_NOTA_FISCAL]*/

					/*[BEGIN_MENU_ITEM_TIPO_TRIBUTACAO_SERVICO]*//*#NO-CHANGE-MENU#*/
					{ label: "Tipo de Tributação", description: "", detail: "/LstTipoTributacaoServico", to: "/LstTipoTributacaoServico", permission: Permissao.getInstance().getPermissao(146), shortchut: false, icon: IconFilterSquare },
					/*[END_MENU_ITEM_TIPO_TRIBUTACAO_SERVICO]*/

					/*[BEGIN_MENU_ITEM_TIPO_EXIGIBILIDADE_SERVICO]*//*#NO-CHANGE-MENU#*/
					{ label: "Tipo Exigibilidade", description: "", detail: "/LstTipoExigibilidadeServico", to: "/LstTipoExigibilidadeServico", permission: Permissao.getInstance().getPermissao(147), shortchut: false, icon: IconFilterSquare },
					/*[END_MENU_ITEM_TIPO_EXIGIBILIDADE_SERVICO]*/

					/*[BEGIN_MENU_ITEM_TIPO_NOTA_FISCAL]*//*#NO-CHANGE-MENU#*/
					{ label: "Tipo de Nota Fiscal", description: "", detail: "/LstTipoNotaFiscal", to: "/LstTipoNotaFiscal", permission: Permissao.getInstance().getPermissao(150), shortchut: false, icon: IconFilterSquare },
					/*[END_MENU_ITEM_TIPO_NOTA_FISCAL]*/

					/*[BEGIN_MENU_ITEM_FATURA_NOTA_FISCAL_MOTIVO_CANCELAMENTO]*//*#NO-CHANGE-MENU#*/
					{ label: "Motivo Cancelamento", description: "", detail: "/LstFaturaNotaFiscalMotivoCancelamento", to: "/LstFaturaNotaFiscalMotivoCancelamento", permission: Permissao.getInstance().getPermissao(165), shortchut: false, icon: IconSendX },
					/*[END_MENU_ITEM_FATURA_NOTA_FISCAL_MOTIVO_CANCELAMENTO]*/

					/*[BEGIN_MENU_ITEM_NATUREZA_TRIBUTACAO_NOTA_FISCAL]*//*#NO-CHANGE-MENU#*/
					{ label: "Natureza Tributação", description: "", detail: "/LstNaturezaTributacaoNotaFiscal", to: "/LstNaturezaTributacaoNotaFiscal", permission: Permissao.getInstance().getPermissao(137), shortchut: false, icon: IconBuildingFillDown },
					/*[END_MENU_ITEM_NATUREZA_TRIBUTACAO_NOTA_FISCAL]*/
				]
			},
			{
				label: "Dasboard", icon: IconGraphUp, tree: [
					/*[BEGIN_MENU_ITEM_PAINEL]*//*#NO-CHANGE-MENU#*/
					{ label: "Painel", description: "", detail: "/LstPainel", to: "/LstPainel", permission: Permissao.getInstance().getPermissao(95), shortchut: false, icon: IconSquare },
					/*[END_MENU_ITEM_PAINEL]*/
					/*[BEGIN_MENU_ITEM_TIPO_BLOCO]*//*#NO-CHANGE-MENU#*/
					{ label: "Tipo Bloco", description: "", detail: "/LstTipoBloco", to: "/LstTipoBloco", permission: Permissao.getInstance().getPermissao(97), shortchut: false, icon: IconFilterSquare },
					/*[END_MENU_ITEM_TIPO_BLOCO]*/
					/*[BEGIN_MENU_ITEM_MODELO_BLOCO]*//*#NO-CHANGE-MENU#*/
					{ label: "Modelo do Bloco", description: "", detail: "/LstModeloBloco", to: "/LstModeloBloco", permission: Permissao.getInstance().getPermissao(98), shortchut: false, icon: IconSquareHalf },
					/*[END_MENU_ITEM_MODELO_BLOCO]*/
					/*[BEGIN_MENU_ITEM_TIPO_COMANDO]*//*#NO-CHANGE-MENU#*/
					{ label: "Tipo Comando", description: "", detail: "/LstTipoComando", to: "/LstTipoComando", permission: Permissao.getInstance().getPermissao(99), shortchut: false, icon: IconFilterSquare },
					/*[END_MENU_ITEM_TIPO_COMANDO]*/
					/*[BEGIN_MENU_ITEM_TIPO_FILTRO]*//*#NO-CHANGE-MENU#*/
					{ label: "Tipo de Filtro", description: "", detail: "/LstTipoFiltro", to: "/LstTipoFiltro", permission: Permissao.getInstance().getPermissao(102), shortchut: false, icon: IconFilterSquare },
					/*[END_MENU_ITEM_TIPO_FILTRO]*/
					{ label: "Dashboard", description: "", detail: "/painel", to: "/painel", permission: Permissao.getInstance().getPermissao(100), shortchut: true, icon: IconSpeedometer },
				]
			},
			{
				label: "Ordem", icon: IconBasket, tree: [
					/*[BEGIN_MENU_ITEM_ORDEM]*//*#NO-CHANGE-MENU#*/
					{ label: "Ordem", description: "Controle de Ordens de Serviços e Produtos, aqui você pode realizar a abertura de novas ordens, alterar status das ordens, criar uma fatura de uma ou várias ordens.", detail: "/LstOrdem", to: "/LstOrdem", permission: Permissao.getInstance().getPermissao(44), shortchut: true, icon: IconCardChecklist, color: BtnButton.color.outlinePrimary },
					/*[END_MENU_ITEM_ORDEM]*/

					/*[BEGIN_MENU_ITEM_ORDEM]*//*#NO-CHANGE-MENU#*/
					{ label: "Nova Ordem", description: "Criar uma nova ordem", detail: "/CadOrdem", to: "/CadOrdem", permission: Permissao.getInstance().getPermissao(44), shortchut: true, icon: IconCardHeading, color: BtnButton.color.outlineDark },
					/*[END_MENU_ITEM_ORDEM]*/

					/*[BEGIN_MENU_ITEM_STATUS_ORDEM]*//*#NO-CHANGE-MENU#*/
					{ label: "Status da Ordem", description: "", detail: "/LstStatusOrdem", to: "/LstStatusOrdem", permission: Permissao.getInstance().getPermissao(48), shortchut: false, icon: IconCircleSquare },
					/*[END_MENU_ITEM_STATUS_ORDEM]*/

					/*[BEGIN_MENU_ITEM_RECURSO_ORDEM]*//*#NO-CHANGE-MENU#*/
					{ label: "Recurso", description: "", detail: "/LstRecursoOrdem", to: "/LstRecursoOrdem", permission: Permissao.getInstance().getPermissao(81), shortchut: false, icon: IconArrowRightShort },
					/*[END_MENU_ITEM_RECURSO_ORDEM]*/
				]
			},
			{
				label: "Financeiro", icon: IconCash, tree: [
					/*[BEGIN_MENU_ITEM_FATURA]*//*#NO-CHANGE-MENU#*/
					{ label: "Fatura", description: "", detail: "/LstFatura", to: "/LstFatura", permission: Permissao.getInstance().getPermissao(68), shortchut: true, icon: IconCoin, color: BtnButton.color.outlineSuccess },
					/*[END_MENU_ITEM_FATURA]*/

					/*[BEGIN_MENU_ITEM_STATUS_FATURA]*//*#NO-CHANGE-MENU#*/
					{ label: "Status da Fatura", description: "", detail: "/LstStatusFatura", to: "/LstStatusFatura", permission: Permissao.getInstance().getPermissao(83), shortchut: false, icon: IconCircleSquare },
					/*[END_MENU_ITEM_STATUS_FATURA]*/

					/*[BEGIN_MENU_ITEM_STATUS_FATURA_COBRANCA]*//*#NO-CHANGE-MENU#*/
					{ label: "Status da Cobrança", description: "", detail: "/LstStatusFaturaCobranca", to: "/LstStatusFaturaCobranca", permission: Permissao.getInstance().getPermissao(92), shortchut: false, icon: IconCircleSquare },
					/*[END_MENU_ITEM_STATUS_FATURA_COBRANCA]*/

					/*[BEGIN_MENU_ITEM_FATURA_ACORDO]*//*#NO-CHANGE-MENU#*/
					{ label: "Acordo", description: "", detail: "/LstFaturaAcordo", to: "/LstFaturaAcordo", permission: Permissao.getInstance().getPermissao(167), shortchut: true, icon: IconHandThumbsUp },
					/*[END_MENU_ITEM_FATURA_ACORDO]*/

					/*[BEGIN_MENU_ITEM_STATUS_FATURA_ACORDO]*//*#NO-CHANGE-MENU#*/
					{ label: "Status do Acordo", description: "", detail: "/LstStatusFaturaAcordo", to: "/LstStatusFaturaAcordo", permission: Permissao.getInstance().getPermissao(168), shortchut: false, icon: IconCircleSquare },
					/*[END_MENU_ITEM_STATUS_FATURA_ACORDO]*/

					/*[BEGIN_MENU_ITEM_CONTA_PAGAR]*//*#NO-CHANGE-MENU#*/
					{ label: "Conta a Pagar", description: "", detail: "/LstContaPagar", to: "/LstContaPagar", permission: Permissao.getInstance().getPermissao(190), shortchut: false, icon: IconClipboardMinus },
					/*[END_MENU_ITEM_CONTA_PAGAR]*/

					/*[BEGIN_MENU_ITEM_CONTA_RECEBER]*//*#NO-CHANGE-MENU#*/
					{ label: "Conta a Receber", description: "", detail: "/LstContaReceber", to: "/LstContaReceber", permission: Permissao.getInstance().getPermissao(189), shortchut: false, icon: IconClipboardPlus },
					/*[END_MENU_ITEM_CONTA_RECEBER]*/

					/*[BEGIN_MENU_ITEM_STATUS_CONTA]*//*#NO-CHANGE-MENU#*/
					{ label: "Status da Conta", description: "", detail: "/LstStatusConta", to: "/LstStatusConta", permission: Permissao.getInstance().getPermissao(193), shortchut: false, icon: IconCircleSquare },
					/*[END_MENU_ITEM_STATUS_CONTA]*/

					{ label: "Carteira", description: "", detail: "/LstContaExtrato", to: "/LstContaExtrato", permission: Permissao.getInstance().getPermissao(194), shortchut: true, icon: IconWallet2 },

					/*[BEGIN_MENU_ITEM_COMISSAO]*//*#NO-CHANGE-MENU#*/
					{ label: "Comissão", description: "", detail: "/LstComissao", to: "/LstComissao", permission: Permissao.getInstance().getPermissao(207), shortchut: false, icon: IconHandHoldingUsd },
					/*[END_MENU_ITEM_COMISSAO]*/

					/*[BEGIN_MENU_ITEM_STATUS_COMISSAO]*//*#NO-CHANGE-MENU#*/
					{ label: "Status da Comissão", description: "", detail: "/LstStatusComissao", to: "/LstStatusComissao", permission: Permissao.getInstance().getPermissao(208), shortchut: false, icon: IconCircleSquare },
					/*[END_MENU_ITEM_STATUS_COMISSAO]*/
				]
			},
			{
				label: "ANS", icon: IconCrosshair2, tree: [
					/*[BEGIN_MENU_ITEM_ANS_COR]*//*#NO-CHANGE-MENU#*/
					{ label: "Cor", description: "", detail: "/LstAnsCor", to: "/LstAnsCor", permission: Permissao.getInstance().getPermissao(59), shortchut: false, icon: IconPalette },
					/*[END_MENU_ITEM_ANS_COR]*/

					/*[BEGIN_MENU_ITEM_ANS_STATUS]*//*#NO-CHANGE-MENU#*/
					{ label: "Status", description: "", detail: "/LstAnsStatus", to: "/LstAnsStatus", permission: Permissao.getInstance().getPermissao(60), shortchut: false, icon: IconCircleSquare },
					/*[END_MENU_ITEM_ANS_STATUS]*/

					/*[BEGIN_MENU_ITEM_ANS_FERRAMENTA]*//*#NO-CHANGE-MENU#*/
					{ label: "Ferramenta", description: "", detail: "/LstAnsFerramenta", to: "/LstAnsFerramenta", permission: Permissao.getInstance().getPermissao(58), shortchut: false, icon: IconArrowRightShort },
					/*[END_MENU_ITEM_ANS_FERRAMENTA]*/

					/*[BEGIN_MENU_ITEM_ANS_MATERIAL]*//*#NO-CHANGE-MENU#*/
					{ label: "Material", description: "", detail: "/LstAnsMaterial", to: "/LstAnsMaterial", permission: Permissao.getInstance().getPermissao(57), shortchut: false, icon: IconArrowRightShort },
					/*[END_MENU_ITEM_ANS_MATERIAL]*/

					/*[BEGIN_MENU_ITEM_ANS_CATEGORIA_MATERIAL]*//*#NO-CHANGE-MENU#*/
					{ label: "Categoria do Material", description: "", detail: "/LstAnsCategoriaMaterial", to: "/LstAnsCategoriaMaterial", permission: Permissao.getInstance().getPermissao(56), shortchut: false, icon: IconArrowRightShort },
					/*[END_MENU_ITEM_ANS_CATEGORIA_MATERIAL]*/

					/*[BEGIN_MENU_ITEM_ANS_CARACTERISTICA_MATERIAL]*//*#NO-CHANGE-MENU#*/
					{ label: "Caractetística do Material", description: "", detail: "/LstAnsCaracteristicaMaterial", to: "/LstAnsCaracteristicaMaterial", permission: Permissao.getInstance().getPermissao(112), shortchut: false, icon: IconArrowRightShort },
					/*[END_MENU_ITEM_ANS_CARACTERISTICA_MATERIAL]*/

					/*[BEGIN_MENU_ITEM_TIPO_ANS_PERFIL]*//*#NO-CHANGE-MENU#*/
					{ label: "Tipo de Ans Perfil", description: "", detail: "/LstTipoAnsPerfil", to: "/LstTipoAnsPerfil", permission: Permissao.getInstance().getPermissao(113), shortchut: false, icon: IconFilterSquare },
					/*[END_MENU_ITEM_TIPO_ANS_PERFIL]*/

					/*[BEGIN_MENU_ITEM_ANS_CARGO]*//*#NO-CHANGE-MENU#*/
					//{ label: "Cargo de Perfil", description : "", detail : "/LstAnsCargo", to: "/LstAnsCargo", permission: Permissao.getInstance().getPermissao(114), shortchut : false, icon : IconArrowRightShort },
					/*[END_MENU_ITEM_ANS_CARGO]*/

					/*[BEGIN_MENU_ITEM_ANS_PERFIL]*//*#NO-CHANGE-MENU#*/
					{ label: "Perfil", description: "", detail: "/LstAnsPerfil", to: "/LstAnsPerfil", permission: Permissao.getInstance().getPermissao(61), shortchut: true, icon: IconCrosshair2, color: BtnButton.color.outlineInfo },
					/*[END_MENU_ITEM_ANS_PERFIL]*/

					/*[BEGIN_MENU_ITEM_ANS_CRITERIO_PARADA]*//*#NO-CHANGE-MENU#*/
					{ label: "Critério de Parada", description: "", detail: "/LstAnsCriterioParada", to: "/LstAnsCriterioParada", permission: Permissao.getInstance().getPermissao(237), shortchut: false, icon: IconSignStopFill },
					/*[END_MENU_ITEM_ANS_CRITERIO_PARADA]*/


				]
			},
			{
				label: "Produto", icon: IconBox2, tree: [
					/*[BEGIN_MENU_ITEM_MARCA]*//*#NO-CHANGE-MENU#*/
					{ label: "Marca", description: "", detail: "/LstMarca", to: "/LstMarca", permission: Permissao.getInstance().getPermissao(33), shortchut: false, icon: IconBookmarkStar },
					/*[END_MENU_ITEM_MARCA]*/

					/*[BEGIN_MENU_ITEM_MODELO]*//*#NO-CHANGE-MENU#*/
					{ label: "Modelo", description: "", detail: "/LstModelo", to: "/LstModelo", permission: Permissao.getInstance().getPermissao(34), shortchut: false, icon: IconTag },
					/*[END_MENU_ITEM_MODELO]*/

					/*[BEGIN_MENU_ITEM_PRODUTO]*//*#NO-CHANGE-MENU#*/
					{ label: "Produto", description: "", detail: "/LstProduto", to: "/LstProduto", permission: Permissao.getInstance().getPermissao(49), shortchut: false, icon: IconBoxSeam },
					/*[END_MENU_ITEM_PRODUTO]*/

					/*[BEGIN_MENU_ITEM_GRUPO_PRODUTO]*//*#NO-CHANGE-MENU#*/
					{ label: "Grupo de Produto", description: "", detail: "/LstGrupoProduto", to: "/LstGrupoProduto", permission: Permissao.getInstance().getPermissao(53), shortchut: false, icon: IconArchive },
					/*[END_MENU_ITEM_GRUPO_PRODUTO]*/

					/*[BEGIN_MENU_ITEM_CLASSIFICACAO_PRODUTO]*//*#NO-CHANGE-MENU#*/
					{ label: "Classificação de Produto", description: "", detail: "/LstClassificacaoProduto", to: "/LstClassificacaoProduto", permission: Permissao.getInstance().getPermissao(54), shortchut: false, icon: IconCardChecklist },
					/*[END_MENU_ITEM_CLASSIFICACAO_PRODUTO]*/
				]
			},
			{
				label: "Workflow", icon: IconDiagram3, tree: [
					/*[BEGIN_MENU_ITEM_FLUXO]*//*#NO-CHANGE-MENU#*/
					{ label: "Fluxo", description: "", detail: "/LstFluxo", to: "/LstFluxo", permission: Permissao.getInstance().getPermissao(197), shortchut: false, icon: IconArrowRightShort },
					/*[END_MENU_ITEM_FLUXO]*/

					/*[BEGIN_MENU_ITEM_FLUXO_VERSAO]*//*#NO-CHANGE-MENU#*/
					{ label: "Fluxo Versão", description: "", detail: "/LstFluxoVersao", to: "/LstFluxoVersao", permission: Permissao.getInstance().getPermissao(197), shortchut: false, icon: IconArrowRightShort },
					/*[END_MENU_ITEM_FLUXO_VERSAO]*/
				]
			}
		];
	}

}