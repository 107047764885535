//#NO-CHANGE-FILE#

import React from "react";
import { useNavigate, useLocation, useParams } from "react-router";
import Config from "./Config";
import { BaseList } from "../../siht/base";
import { GoogleMaps, GoogleMapsMarker, GoogleMapsPolyLine, BtnRadioGroup, FormItem, InputGroupButton, InputGroup, Row, Col } from "../../siht/components";
import { BtnButton, BtnRadio, BtnSubmit, Div, Form, InputDateTimeLocal } from "../../siht/elements";
import ConfigApp from "../../ConfigApp";
import VeiculoService from "../../services/VeiculoService";
import { IconClear } from "../../icons";

class ListVeiculoTrajeto extends BaseList {

	constructor(props) {
		super(props);
		this.getItens = this.getItens.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleNew = this.handleNew.bind(this);
		this.handleEdit = this.handleEdit.bind(this);
		this.handleCopy = this.handleCopy.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.handleMore = this.handleMore.bind(this);
		this.onSubmitFilter = this.onSubmitFilter.bind(this);
		this.handleClear = this.handleClear.bind(this);
		this.handleFormSuccess = this.handleFormSuccess.bind(this);
		this.handleFormClose = this.handleFormClose.bind(this);
		this.handleChangeFilter = this.handleChangeFilter.bind(this);
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		super.init(Config);
	}

	initStateFilter(){
		var tzoffset = (new Date()).getTimezoneOffset() * 60000;

		var dtHoje = new Date();
        dtHoje.setDate(dtHoje.getDate());
        var dhLocalizacaoStart = new Date(dtHoje - tzoffset).toISOString().slice(0, 10)+"T00:00";
		var dhLocalizacaoEnd = new Date(dtHoje - tzoffset).toISOString().slice(0, 10)+"T23:59";
		
        return {
			tipo : "TRAJETO",
			dhLocalizacaoStart : dhLocalizacaoStart,
			dhLocalizacaoEnd : dhLocalizacaoEnd,
			veiculo : this.props.parent
        };
    }

	getItens(){
		VeiculoService.listMapa(this.state.filter, response => {
			if(Array.isArray(response) && response.length > 0){
				this.setState({data : response[0].veiculoTrajetos});
			}else{
				this.setState({data : []});
			}
		});
	}

	handleNew(e) {
		super.handleNew(e, Config);
	}

	handleEdit(e, item) {
		super.handleEdit(e, Config, item);
	}

	handleDelete(e, item) {
		super.handleDelete(e, Config, item);
	}

	handleCopy(e, item) {
		super.handleCopy(e, Config, item);
	}

	getFormEmbedded(){
		return super.getFormEmbedded(Config);
	}

	render() {

		let latlng = {
			lat : this.props.parent?.localizacao?.vlLatitude ? this.props.parent?.localizacao?.vlLatitude : null,
			lng : this.props.parent?.localizacao?.vlLongitude ? this.props.parent?.localizacao?.vlLongitude : null,
			zoom : 15
		};

		let ultimaPosicao = {};
		let primeiraPosicao = {};

		if(this.state.filter.tipo === "TRAJETO" && this.state.data?.length > 0){

			primeiraPosicao = this.state.data[0];
			ultimaPosicao = this.state.data[this.state.data.length - 1];
			
			latlng = {
				lat : ultimaPosicao?.localizacao?.vlLatitude,
				lng : ultimaPosicao?.localizacao?.vlLongitude,
				zoom : 9
			};
		}

		return super.render(
			<Div className="p-2 mt-2">

				<Form className="pb-2" onSubmit={this.onSubmitFilter}>
					<Row className="g-3">
						<FormItem md={3} label="Visualização">
							<BtnRadioGroup name="tipo" color={BtnRadioGroup.color.primary} opcional={true} value={this.state?.filter.tipo} onChange={this.handleChangeFilter}>
								<BtnRadio value="POSICAO" label="Última Posição" />
								<BtnRadio value="TRAJETO" label="Trajeto" />
							</BtnRadioGroup>
						</FormItem>

						<FormItem md={3} label="Data Inicial">
							<InputDateTimeLocal disabled={this.state.filter.tipo !== "TRAJETO"} name="dhLocalizacaoStart" value={this.state?.filter?.dhLocalizacaoStart} required={true} opcional={true} onChange={this.handleChangeFilter}/>
						</FormItem>

						<FormItem md={3} label="Data Final">
							<InputDateTimeLocal disabled={this.state.filter.tipo !== "TRAJETO"} name="dhLocalizacaoEnd" min={this.state?.filter?.dhLocalizacaoStart} value={this.state?.filter?.dhLocalizacaoEnd} required={true} opcional={true} onChange={this.handleChangeFilter}/>
						</FormItem>

						<Col md={3} className="d-flex justify-content-end align-items-end">
							<Div>
								<InputGroup className="flex-nowrap" sizing={InputGroup.sizing.default}>												
									<InputGroupButton>
										<BtnSubmit title="Filtrar"> Buscar </BtnSubmit>
										<BtnButton title="Limpar Pesquisa" color={BtnButton.color.outlineDanger} onClick={this.handleClear}> <IconClear/> </BtnButton>
									</InputGroupButton>
								</InputGroup>
							</Div>
						</Col>
					</Row>
				</Form>

				{ConfigApp.getInstance().clientIdGoogleMaps ?
					<GoogleMaps key={this.state.key} apiKey={ConfigApp.getInstance().clientIdGoogleMaps} lat={latlng.lat} lng={latlng.lng} zoom={latlng.zoom}>

						{this.state.filter.tipo === "POSICAO" ?
							<GoogleMapsMarker icon="./assets/image/icon_veiculo.png" zIndex={2} lat={this.props.parent?.localizacao?.vlLatitude} lng={this.props.parent?.localizacao?.vlLongitude} title={this.props.parent.idEquipe}/>
						: <></>}

						{this.state.filter.tipo === "TRAJETO" ?
							<GoogleMapsPolyLine
								stroke={{color : "#191970", opacity : 1.0, weight : 5}}
								markers={[
									<GoogleMapsMarker icon={{path: GoogleMapsMarker.SymbolPath.CIRCLE,scale: 5.5, fillColor: "#191970", fillOpacity: 1, strokeWeight: 1.0 }} lat={primeiraPosicao?.localizacao?.vlLatitude} lng={primeiraPosicao?.localizacao?.vlLongitude}/>,
									<GoogleMapsMarker icon="./assets/image/icon_veiculo.png" lat={ultimaPosicao?.localizacao?.vlLatitude} lng={ultimaPosicao?.localizacao?.vlLongitude}/>,
								]}
								coordinates={this.state.data?.map(ivl => {return {lat: ivl?.localizacao?.vlLatitude, lng: ivl?.localizacao?.vlLongitude}})}/>
						: <></>}
					</GoogleMaps>
				:<></>}
				
			</Div>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <ListVeiculoTrajeto {...props} navigate={navigate} params={params} location={location}/>
}
export default With