
import FormComissaoTipoOrdem from "./FormComissaoTipoOrdem.js";
import ListComissaoTipoOrdem from "./ListComissaoTipoOrdem.js";
import { Permissao } from "../../controllers";
import { Util } from "../../utils";
import { DadoValorService, ComissaoTipoOrdemService } from "../../services";

export default class Config {

	static getUrlForm() {
		return "/CadComissaoTipoOrdem";
	}

	static getUrlList() {
		return "/LstComissaoTipoOrdem";
	}

	static getComponentForm() {
		return FormComissaoTipoOrdem;
	}

	static getPermissionForm() {
		return Permissao.getInstance().getPermissoes([207]);
	}

	static getPermissionList() {
		return Permissao.getInstance().getPermissoes([207]);
	}

	static getComponentList() {
		return ListComissaoTipoOrdem;
	}

	static getService() {
		return ComissaoTipoOrdemService;
	}

	static getKeyId() {
		return "idComissaoTipoOrdem";
	}

	static getKeyDescription() {
		return "nmComissaoTipoOrdem";
	}

	static setDefaultValues(state , fnCallBack = () => {}, className = undefined) {
		className = className === undefined ? "FormComissaoTipoOrdem" : className;
		let keyNameScreen = Util.toToUpperTextCamelCase(className, "_") + ".";
		var prefix = "CONFIGURACAO.";
		DadoValorService.getValues(`${prefix}${keyNameScreen}`, (response)=> {
			Util.setValueObjectCamelCase(state.model, response, keyNameScreen, prefix);
			fnCallBack();
		}, fnCallBack);
	}
}