//#NO-CHANGE-FILE#
import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import BtnRadioGroupSimNao from "../../components/BtnRadioGroupSimNao";
import ListFaturaCobranca from "../FaturaCobranca/ListFaturaCobranca";
import ListFaturaDesconto from "../FaturaDesconto/ListFaturaDesconto";
import ListFaturaNotaFiscalByFatura from "../FaturaNotaFiscal/ListFaturaNotaFiscalByFatura";
import ListFaturaOrdem from "../FaturaOrdem/ListFaturaOrdem";
import ListFaturaEndereco from "../FaturaEndereco/ListFaturaEndereco";
import ListFaturaArquivo from "../FaturaArquivo/ListFaturaArquivo";
import Permissao from "../../controllers/Permissao";
import { BaseList, BaseCrud } from "../../siht/base";
import { FormItem, Row, TabNavigator, Tab, Panel, PanelBody, PanelFooter, Container, TabDropdown, DropDownItem, DropDown, ModalDialog } from "../../siht/components";
import { InputText, InputNumberFormat, Div, BtnButton, Button } from "../../siht/elements";
import Config from "./Config";
import { Modal } from "../../siht/controller";
import FaturaOrdemService from "../../services/FaturaOrdemService";
import { IconClear } from "../../icons";
import { Paperclip as IconPaperclip, Bank2 as IconBank2, CardChecklist as IconCardChecklist, Coin as IconCoin, DashCircleDotted as IconDashCircleDotted, HandThumbsUp as IconHandThumbsUp, ListOl as IconListOl, MailboxFlag as IconMailboxFlag, PersonFill as IconPersonFill, PrinterFill as IconPrinterFill } from "react-bootstrap-icons";
import Util from "../../utils/Util";
import FormPessoa from "../Pessoa/FormPessoa";
import FormFaturaAcordo from "../FaturaAcordo/FormFaturaAcordo";
import FormRelatorioFatura001 from "../Relatorio/FormRelatorioFatura001";
import { StatusCustom } from "../../components/Custom";

class FormFatura extends BaseCrud {

	constructor(props) {
		super(props);
		this.init = this.init.bind(this);
		this.handleSuccess = this.handleSuccess.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleChangeModel = this.handleChangeModel.bind(this);
		this.handleSubmitForm = this.handleSubmitForm.bind(this);

		this.handleEstornar = this.handleEstornar.bind(this);
		this.handleImprimir = this.handleImprimir.bind(this);

		this.state.model = {
			idFatura : null,
			statusFatura : null,
			usuario : null,
			unidade : null,
			pessoa : null,
			vlBruto : "",
			vlJurosFuturo : "",
			vlFatura : "",
			flJurosFuturo : true,
			flTaxaCartao : true,
			flEstorno : false,
			faturaCobranca : [],
			faturaOrdem : [],
			faturaEndereco : []
		};
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		super.init(Config, ()=> {
			FaturaOrdemService.getAllByFatura(this.state.model, response =>{
				this.setModel({faturaOrdem : response})
			})
		});
	}

	handleSuccess(e) {
		super.handleSuccess(e, Config);
	}

	handleClose(e) {
		super.handleClose(e, Config);
	}

	handleEstornar(e) {
		Modal.confirm("Confirmação", "Deseja realmente estornar a fatura?" , "Essa é uma ação irreversível", Modal.style.danger, () =>{
			Config.getService().estornar(this.state.model, () => {				
				if(this.state.model.faturaOrdem.length === 1){
					this.props.navigate('/CadOrdem/' + this.state.model.faturaOrdem[0].ordem.idOrdem);
				}else{
					this.props.navigate('/LstOrdem');
				}
			})	
		})		
	}

	handleImprimir(e, fatura) {
		Modal.openWindow(FormRelatorioFatura001, {sizing : Modal.sizing.default}, { idFaturas : [fatura.idFatura] });
	}

	render() {

		return super.render(
				<Div key={this.state.key}>
					<Panel title="Cadastro de Fatura" onClose={this.handleClose} isModal={this.isModal()} container={{sizing: Container.sizing.fluid, className : ""}} modal={{sizing: this.props.sizing ? this.props.sizing : ModalDialog.sizing.xl}}>
						<PanelBody className="p-0 m-0">
							<TabNavigator onChangeTab={this.handleChangeTab} className="border-light">
								<Tab title={<><IconCoin/> Fatura</>} className="m-2">									
									<Row className="g-3 pb-3 px-2">
										<FormItem md={2} label="Código">
											<InputText name="idFatura" value={this.state.model.idFatura} readOnly={true} className="readonly-field" required={false} onChange={this.handleChangeModel}/>
										</FormItem>
										<FormItem md={2} label="Data Fatura" show={this.state.model.idOrdem}>
											<InputText value={Util.date2Br(this.state.model.dhInsert, true)} readOnly={true}/>
										</FormItem>
										
										<FormItem md={5} label="Usuario">
											<InputText value={this.state.model.usuario?.nmUsuario} readOnly={true}/>
										</FormItem>

										<FormItem md={3} label="Tipo da Fatura">
											<StatusCustom className="text-center" opcional={true} type="InputText" color={this.state.model?.tipoFatura}>{this.state.model.tipoFatura?.nmTipoFatura}</StatusCustom>
										</FormItem>

										<FormItem md={3} label="Status da Fatura">
											<StatusCustom className="text-center" opcional={true} type="InputText" color={this.state.model?.statusFatura}>{this.state.model.statusFatura?.nmStatusFatura}</StatusCustom>
										</FormItem>

										<FormItem md={4} label="Empresa">
											<InputText value={this.state.model.unidade?.empresa?.nmEmpresa} readOnly={true}/>
										</FormItem>
										<FormItem md={5} label="Unidade">
											<InputText value={this.state.model.unidade?.nmUnidade} readOnly={true}/>
										</FormItem>
										<FormItem md={12} label="Cliente">
											<InputText value={this.state.model.pessoa?.nmPessoa} readOnly={true}/>
										</FormItem>										
									</Row>
									<Row className="g-3 pb-3 px-2">
										<FormItem md={2} label="Valor Bruto">
											<InputNumberFormat className="fw-bold text-end text-primary" readOnly={true} name="vlBruto" value={this.state.model.vlBruto} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} prefix="R$ " required={true} onChange={this.handleChangeModel}/>
										</FormItem>
										<FormItem md={2} label="Juros Futuro">
											<InputNumberFormat className="fw-bold text-end text-danger" readOnly={true} name="vlJurosFuturo" value={this.state.model.vlJurosFuturo} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} prefix="R$ " required={true} onChange={this.handleChangeModel}/>
										</FormItem>
										<FormItem md={2} label="Valor Fatura">
											<InputNumberFormat className="fw-bold text-end text-primary" readOnly={true} name="vlFatura" value={this.state.model.vlFatura} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} prefix="R$ " required={true} onChange={this.handleChangeModel}/>
										</FormItem>
										<FormItem md={2} label="Total Pago">
											<InputNumberFormat className="fw-bold text-end text-success" readOnly={true} name="vlBaixa" value={this.state.model.vlBaixa} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} prefix="R$ " required={true} onChange={this.handleChangeModel}/>
										</FormItem>
										<FormItem md={2} label="Total Aberto">
											<InputNumberFormat className="fw-bold text-end text-danger" readOnly={true} name="vlAberto" value={this.state.model.vlAberto} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} prefix="R$ " required={true} onChange={this.handleChangeModel}/>
										</FormItem>										
									</Row>
									<Row className="g-3 pb-3 px-2">
										<FormItem md={2} label="Juros Futuros?">
											<BtnRadioGroupSimNao name="flJurosFuturo" value={this.state.model.flJurosFuturo} required={true} disabled={true}/>
										</FormItem>
										<FormItem md={2} label="Taxa de Cartão?">
											<BtnRadioGroupSimNao name="flTaxaCartao" value={this.state.model.flTaxaCartao} required={true} disabled={true}/>
										</FormItem>
										<FormItem md={3} label="Identificador Externo">
											<InputText name="cdIdentificadorExterno" value={this.state.model.cdIdentificadorExterno} maxLength={100} disabled={true} required={true}/>
										</FormItem>
										<FormItem md={3} label="Pedido Externo">
											<InputText name="cdPedidoExterno" value={this.state.model.cdPedidoExterno} maxLength={100} disabled={true} required={true}/>
										</FormItem>
									</Row>
								</Tab>
								<Tab title={<><IconPersonFill/> Pessoa</>} opened={false}>
									<FormPessoa editable={!this.state.model.statusFatura?.flEstornada} key={this.state.model?.pessoa?.idPessoa} id={this.state.model?.pessoa?.idPessoa} localOpen={BaseCrud.localOpen.embedded} handleSuccess={e => {}} handleClose={false}/>
								</Tab>
								<Tab show={Permissao.getInstance().getPermissoes([69])} title={<><IconListOl/> Cobranças</>} disabled={!this.state.model.idFatura} opened={false} className="m-2">
									<ListFaturaCobranca parent={this.state.model} localOpen={BaseList.localOpen.modal}/>
								</Tab>
								<Tab show={this.state.model.faturaAcordo?.idFaturaAcordo !== undefined} title={<><IconHandThumbsUp/> Acordo</>} disabled={!this.state.model.idFatura} opened={false}>
									<FormFaturaAcordo key={this.state.model?.faturaAcordo?.idFaturaAcordo} id={this.state.model?.faturaAcordo?.idFaturaAcordo} localOpen={BaseCrud.localOpen.embedded} handleSuccess={e => {}} handleClose={false}/>
								</Tab>
								<Tab title={<><IconDashCircleDotted/> Descontos</>} disabled={!this.state.model.idFatura} opened={false}>
									<ListFaturaDesconto parent={this.state.model} localOpen={BaseList.localOpen.modal}/>
								</Tab>
								<Tab show={Permissao.getInstance().getPermissoes([73])} title={<><IconBank2/> Nota Fiscal</>} disabled={!this.state.model.idFatura} opened={false} className="m-2">
									<ListFaturaNotaFiscalByFatura parent={this.state.model} localOpen={BaseList.localOpen.modal}/>
								</Tab>
								<Tab show={Permissao.getInstance().getPermissoes([70])} title={<><IconCardChecklist/> Ordens</>} disabled={!this.state.model.idFatura} opened={false} className="m-2">
									<ListFaturaOrdem parent={this.state.model} localOpen={BaseList.localOpen.modal}/>
								</Tab>								
								<Tab show={Permissao.getInstance().getPermissoes([71])} title={<><IconMailboxFlag/> Endereço</>} disabled={!this.state.model.idFatura} opened={false} className="m-2">
									<ListFaturaEndereco parent={this.state.model} localOpen={BaseList.localOpen.modal}/>
								</Tab>
								<Tab show={Permissao.getInstance().getPermissoes([196])} title={<><IconPaperclip/> Arquivos</>} disabled={!this.state.model.idFatura} opened={false} className="m-2">
									<ListFaturaArquivo key={this.state.key} parent={this.state.model} localOpen={BaseList.localOpen.modal} editable={true}/>
								</Tab>
								<TabDropdown label="Opções" alignment={DropDown.alignment.end} show={this.state.model.idOrdem}>
									<DropDownItem title="Estornar" type="button" onClick={this.handleEstornar} disabled={!Config.getPermissaoFatura(this.state.model).flPodeEstornarFatura || !this.state.model.statusFatura?.flPodeEstornar || this.state.model.flEstorno}> <IconClear/> Estornar Fatura </DropDownItem>
									<DropDownItem title="Imprimir" type="button" onClick={e => this.handleImprimir(e, this.state.model)} show={Config.getPermissaoFatura(this.state.model).flPodeImprimir}> <IconPrinterFill/> Imprimir Fatura </DropDownItem>
								</TabDropdown>
							</TabNavigator>
						</PanelBody>
						<PanelFooter>
							<BtnButton sizing={Button.sizing.default} color={Button.color.outlineSecondary} onClick={this.handleClose} > Voltar </BtnButton>
						</PanelFooter>
					</Panel>
				</Div>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <FormFatura {...props} navigate={navigate} params={params} location={location}/>
}
export default With