import Http from "../controllers/Http";

export default class UsuarioGrupoService {

	static urlBase = "/usuarioGrupo/";

	static getForCombo(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(UsuarioGrupoService.urlBase + 'forCombo', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static list(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(UsuarioGrupoService.urlBase + 'list', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneById(id, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.get(UsuarioGrupoService.urlBase + id)
			.then(fnSucess)
			.catch(fnError);
	}

	static create(data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(UsuarioGrupoService.urlBase, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static update(id, data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.put(UsuarioGrupoService.urlBase + id, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static delete(id, fnSucess = () => { }, fnError = () => { }) {
		if(Number.isInteger(id)){
			Http.getInstanceAppJson()
				.delete(UsuarioGrupoService.urlBase + id)
				.then(fnSucess)
				.catch(fnError);
		}else{
			Http.getInstanceAppJson()
			.post(UsuarioGrupoService.urlBase + 'delete', id)
			.then(fnSucess)
			.catch(fnError);
		}
	}

	static save(data, fnSucess = () => { }, fnError = () => { }) {
		if (data.idUsuarioGrupo) {
			this.update(data.idUsuarioGrupo, data, fnSucess, fnError);
		} else {
			this.create(data, fnSucess, fnError);
		}
	}

	static getAllByGrupo(grupo, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(UsuarioGrupoService.urlBase + 'grupo', grupo)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByGrupo(grupo, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(UsuarioGrupoService.urlBase + 'grupo/' + grupo.idGrupo)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByUsuario(usuario, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(UsuarioGrupoService.urlBase + 'usuario', usuario)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByUsuario(usuario, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(UsuarioGrupoService.urlBase + 'usuario/' + usuario.idUsuario)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByUsuarioGrupoEquipe(usuarioGrupoEquipe, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(UsuarioGrupoService.urlBase + 'usuarioGrupoEquipe', usuarioGrupoEquipe)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByUsuarioGrupoEquipe(usuarioGrupoEquipe, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(UsuarioGrupoService.urlBase + 'usuarioGrupoEquipe/' + usuarioGrupoEquipe.idUsuarioGrupoEquipe)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByUsuarioGrupoUnidade(usuarioGrupoUnidade, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(UsuarioGrupoService.urlBase + 'usuarioGrupoUnidade', usuarioGrupoUnidade)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByUsuarioGrupoUnidade(usuarioGrupoUnidade, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(UsuarioGrupoService.urlBase + 'usuarioGrupoUnidade/' + usuarioGrupoUnidade.idUsuarioGrupoUnidade)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByUsuarioGrupoModulo(usuarioGrupoModulo, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(UsuarioGrupoService.urlBase + 'usuarioGrupoModulo', usuarioGrupoModulo)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByUsuarioGrupoModulo(usuarioGrupoModulo, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(UsuarioGrupoService.urlBase + 'usuarioGrupoModulo/' + usuarioGrupoModulo.idUsuarioGrupoModulo)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByUsuarioGrupoTipoOrdem(usuarioGrupoTipoOrdem, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(UsuarioGrupoService.urlBase + 'usuarioGrupoTipoOrdem', usuarioGrupoTipoOrdem)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByUsuarioGrupoTipoOrdem(usuarioGrupoTipoOrdem, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(UsuarioGrupoService.urlBase + 'usuarioGrupoTipoOrdem/' + usuarioGrupoTipoOrdem.idUsuarioGrupoTipoOrdem)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByUsuarioGrupoTipoPreco(usuarioGrupoTipoPreco, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(UsuarioGrupoService.urlBase + 'usuarioGrupoTipoPreco', usuarioGrupoTipoPreco)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByUsuarioGrupoTipoPreco(usuarioGrupoTipoPreco, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(UsuarioGrupoService.urlBase + 'usuarioGrupoTipoPreco/' + usuarioGrupoTipoPreco.idUsuarioGrupoTipoPreco)
			.then(fnSucess)
			.catch(fnError);
	}
}