
import FormConfiguracaoDadoValor from "./FormConfiguracaoDadoValor.js";
import ListConfiguracaoDadoValor from "./ListConfiguracaoDadoValor.js";
import { Permissao } from "../../controllers";
import { Util } from "../../utils";
import { DadoValorService, ConfiguracaoDadoValorService } from "../../services";

export default class Config {

	static getUrlForm() {
		return "/CadConfiguracaoDadoValor";
	}

	static getUrlList() {
		return "/LstConfiguracaoDadoValor";
	}

	static getComponentForm() {
		return FormConfiguracaoDadoValor;
	}

	static getPermissionForm() {
		return Permissao.getInstance().getPermissoes([39]);
	}

	static getPermissionList() {
		return Permissao.getInstance().getPermissoes([39]);
	}

	static getComponentList() {
		return ListConfiguracaoDadoValor;
	}

	static getService() {
		return ConfiguracaoDadoValorService;
	}

	static getKeyId() {
		return "idConfiguracaoDadoValor";
	}

	static getKeyDescription() {
		return "nmConfiguracaoDadoValor";
	}

	static setDefaultValues(state , fnCallBack = () => {}, className = undefined) {
		className = className === undefined ? "FormConfiguracaoDadoValor" : className;
		let keyNameScreen = Util.toToUpperTextCamelCase(className, "_") + ".";
		var prefix = "CONFIGURACAO.";
		DadoValorService.getValues(`${prefix}${keyNameScreen}`, (response)=> {
			Util.setValueObjectCamelCase(state.model, response, keyNameScreen, prefix);
			fnCallBack();
		}, fnCallBack);
	}
}