//#NO-CHANGE-FILE#

import Http from "../controllers/Http";

export default class UnidadeTipoNotaFiscalService {

	static urlBase = "/unidadeTipoNotaFiscal/";

	static cadastrarEmpresa(data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(UnidadeTipoNotaFiscalService.urlBase , data)
			.then(fnSucess)
			.catch(fnError);
	}

	static getForCombo(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(UnidadeTipoNotaFiscalService.urlBase + 'forCombo', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static list(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(UnidadeTipoNotaFiscalService.urlBase + 'list', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneById(id, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.get(UnidadeTipoNotaFiscalService.urlBase + id)
			.then(fnSucess)
			.catch(fnError);
	}

	static create(data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(UnidadeTipoNotaFiscalService.urlBase, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static update(id, data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.put(UnidadeTipoNotaFiscalService.urlBase + id, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static delete(id, fnSucess = () => { }, fnError = () => { }) {
		if(Number.isInteger(id)){
			Http.getInstanceAppJson()
				.delete(UnidadeTipoNotaFiscalService.urlBase + id)
				.then(fnSucess)
				.catch(fnError);
		}else{
			Http.getInstanceAppJson()
			.post(UnidadeTipoNotaFiscalService.urlBase + 'delete', id)
			.then(fnSucess)
			.catch(fnError);
		}
	}

	static save(data, fnSucess = () => { }, fnError = () => { }) {
		if (data.idUnidadeTipoNotaFiscal) {
			this.update(data.idUnidadeTipoNotaFiscal, data, fnSucess, fnError);
		} else {
			this.create(data, fnSucess, fnError);
		}
	}

	static getAllByUnidade(unidade, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(UnidadeTipoNotaFiscalService.urlBase + 'unidade', unidade)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByUnidadeOrdem(data, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(UnidadeTipoNotaFiscalService.urlBase + 'unidadeOrdem', data)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByUnidade(unidade, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(UnidadeTipoNotaFiscalService.urlBase + 'unidade/' + unidade.idUnidade)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByPlugin(plugin, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(UnidadeTipoNotaFiscalService.urlBase + 'plugin', plugin)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByPlugin(plugin, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(UnidadeTipoNotaFiscalService.urlBase + 'plugin/' + plugin.idPlugin)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByTipoNotaFiscal(tipoNotaFiscal, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(UnidadeTipoNotaFiscalService.urlBase + 'tipoNotaFiscal', tipoNotaFiscal)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByTipoNotaFiscal(tipoNotaFiscal, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(UnidadeTipoNotaFiscalService.urlBase + 'tipoNotaFiscal/' + tipoNotaFiscal.idTipoNotaFiscal)
			.then(fnSucess)
			.catch(fnError);
	}
}