//#NO-CHANGE-FILE#

import Http from "../controllers/Http";

export default class OrdemService {

	static urlBase = "/ordem/";

	static duplicar(data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(OrdemService.urlBase + 'duplicar', data)
			.then(fnSucess)
			.catch(fnError);
	}

	static marcarComissao(ordens, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(OrdemService.urlBase + 'marcarComissao', ordens)
			.then(fnSucess)
			.catch(fnError);
	}

	static desmarcarComissao(ordens, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(OrdemService.urlBase + 'desmarcarComissao', ordens)
			.then(fnSucess)
			.catch(fnError);
	}

	static getDadoNotaFiscal(data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(OrdemService.urlBase + 'dadoNotaFiscal', data)
			.then(fnSucess)
			.catch(fnError);
	}

	static aplicarDesconto(ordem, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(OrdemService.urlBase + 'aplicarDesconto', ordem)
			.then(fnSucess)
			.catch(fnError);
	}

	static cancelar(ordem, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(OrdemService.urlBase + 'cancelar', ordem)
			.then(fnSucess)
			.catch(fnError);
	}

	static getTipoProdutoByOrdem(ordens, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(OrdemService.urlBase + 'tipoProduto', ordens)
			.then(fnSucess)
			.catch(fnError);
	}

	static reabrir(ordem, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(OrdemService.urlBase + 'reabrir', ordem)
			.then(fnSucess)
			.catch(fnError);
	}

	static alterarStatus(ordem, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(OrdemService.urlBase + 'alterarStatus', ordem)
			.then(fnSucess)
			.catch(fnError);
	}

	static alterarPessoa(ordem, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(OrdemService.urlBase + 'alterarPessoa', ordem)
			.then(fnSucess)
			.catch(fnError);
	}

	static getForCombo(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(OrdemService.urlBase + 'forCombo', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static list(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(OrdemService.urlBase + 'list', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static listMapa(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(OrdemService.urlBase + 'listMapa', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneById(id, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.get(OrdemService.urlBase + id)
			.then(fnSucess)
			.catch(fnError);
	}

	static create(data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(OrdemService.urlBase, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static update(id, data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.put(OrdemService.urlBase + id, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static delete(id, fnSucess = () => { }, fnError = () => { }) {
		if (Number.isInteger(id)) {
			Http.getInstanceAppJson()
				.delete(OrdemService.urlBase + id)
				.then(fnSucess)
				.catch(fnError);
		} else {
			Http.getInstanceAppJson()
				.post(OrdemService.urlBase + 'delete', id)
				.then(fnSucess)
				.catch(fnError);
		}
	}

	static save(data, fnSucess = () => { }, fnError = () => { }) {
		if (data.idOrdem) {
			this.update(data.idOrdem, data, fnSucess, fnError);
		} else {
			this.create(data, fnSucess, fnError);
		}
	}

	static getAllByUnidade(unidade, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(OrdemService.urlBase + 'unidade', unidade)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByUnidade(unidade, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.get(OrdemService.urlBase + 'unidade/' + unidade.idUnidade)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByPessoa(pessoa, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(OrdemService.urlBase + 'pessoa', pessoa)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByPessoa(pessoa, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.get(OrdemService.urlBase + 'pessoa/' + pessoa.idPessoa)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByStatusOrdem(statusOrdem, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(OrdemService.urlBase + 'statusOrdem', statusOrdem)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByStatusOrdem(statusOrdem, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.get(OrdemService.urlBase + 'statusOrdem/' + statusOrdem.idStatusOrdem)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByTipoOrdem(tipoOrdem, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(OrdemService.urlBase + 'tipoOrdem', tipoOrdem)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByTipoOrdem(tipoOrdem, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.get(OrdemService.urlBase + 'tipoOrdem/' + tipoOrdem.idTipoOrdem)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByUsuario(usuario, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(OrdemService.urlBase + 'usuario', usuario)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByUsuario(usuario, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.get(OrdemService.urlBase + 'usuario/' + usuario.idUsuario)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByTipoPreco(tipoPreco, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(OrdemService.urlBase + 'tipoPreco', tipoPreco)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByTipoPreco(tipoPreco, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.get(OrdemService.urlBase + 'tipoPreco/' + tipoPreco.idTipoPreco)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByOrdemItem(ordemItem, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(OrdemService.urlBase + 'ordemItem', ordemItem)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByOrdemItem(ordemItem, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.get(OrdemService.urlBase + 'ordemItem/' + ordemItem.idOrdemItem)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByOrdemComentario(ordemComentario, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(OrdemService.urlBase + 'ordemComentario', ordemComentario)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByOrdemComentario(ordemComentario, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.get(OrdemService.urlBase + 'ordemComentario/' + ordemComentario.idOrdemComentario)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByAnsPerfil(ansPerfil, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(OrdemService.urlBase + 'ansPerfil', ansPerfil)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByAnsPerfil(ansPerfil, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.get(OrdemService.urlBase + 'ansPerfil/' + ansPerfil.idAnsPerfil)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneBy(object, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(OrdemService.urlBase + 'oneBy', object)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllBy(object, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(OrdemService.urlBase + 'allBy', object)
			.then(fnSucess)
			.catch(fnError);
	}
}