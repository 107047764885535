//#NO-CHANGE-FILE#

import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import SelectDado from "../../components/SelectDB/SelectDado";
import { BaseCrud } from "../../siht/base";
import { FormButtons, FormItem, Row, Panel, PanelBody, PanelFooter, Container, ModalDialog, Col } from "../../siht/components";
import { InputText, TextArea, Form,  BtnButton, Button, InputNumber, InputDate, InputNumberFormat, Select, InputTime, Div, SelectMultiple, InputMask, InputColor, InputPassword, InputEmail } from "../../siht/elements";
import Config from "./Config";
import FormUpload from "../FormUpload";
import { Modal } from "../../siht/controller";
import { IconDelete, IconDownload } from "../../icons";
import Util from "../../utils/Util";
import BtnRadioGroupSimNao from "../../components/BtnRadioGroupSimNao";
import DadoLocalService from "../../services/DadoLocalService";
import { toast } from "react-toastify";
import ConfigApp from "../../ConfigApp";
import { BoxArrowInUp as IconBoxArrowInUp} from "react-bootstrap-icons";

class FormDadoValor extends BaseCrud {

	constructor(props) {
		super(props);
		this.init = this.init.bind(this);
		this.handleSuccess = this.handleSuccess.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleChangeModel = this.handleChangeModel.bind(this);
		this.handleSubmitForm = this.handleSubmitForm.bind(this);
		this.handleAddArquivo = this.handleAddArquivo.bind(this);
		this.handleDeleteArquivo = this.handleDeleteArquivo.bind(this);
		this.handleChangeDropdownList = this.handleChangeDropdownList.bind(this);
		this.handleChangeDropdownListMultiple = this.handleChangeDropdownListMultiple.bind(this);
		this.decimalIsAllowed = this.decimalIsAllowed.bind(this);

		this.state.model = {
			idDadoValor : null,
			dado : this.props.dado !== undefined ? this.props.dado : null,
			txValor : "",
			arquivo : null,
			cdDadoLocal : this.props.cdDadoLocal,
			parent : this.props.parent,
			flAtivo : true
		};

		this.state.dadoLocal = null;
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		super.init(Config, () => {
			this.setState(state => {
				state.model.cdDadoLocal = this.props.cdDadoLocal;
				state.model.parent = this.props.parent;

				if(this.state?.model?.dado?.tipoDado?.cdTipoDado === "DROPDOWN_MULTIPLE"){
					state.model.txValor = state.model.txValor ? JSON.parse(state.model.txValor) : [];
				}

				return state;
			});

			if(this.props.cdDadoLocal !== undefined){
				DadoLocalService.getOneByCdDadoLocal(this.props.cdDadoLocal, response => {
					this.setState({dadoLocal : response});
				});
			}
		});
	}

	getItensDropdownList(){
		var dropDownItens = [];
		if(this.state?.model?.dado?.tipoDado?.cdTipoDado === "DROPDOWN_LIST" || this.state?.model?.dado?.tipoDado?.cdTipoDado === "DROPDOWN_MULTIPLE"){
			try {
				dropDownItens = JSON.parse(this.state?.model?.dado?.txOpcao);
			} catch (error) {
				toast.warn("Erro ao converter JSON com os itens do Dropdwon List");
			}
			
		}
		
		return dropDownItens;
	}

	getValueDropdownList(multiple = false){
		if(multiple){
			let txValor = this.state.model?.txValor;
			return this.getItensDropdownList().filter(i =>  Array.isArray(txValor) && txValor?.find(v => i?.value?.toString() === v?.toString()));
		}else{
			return this.getItensDropdownList().find(i => i?.value?.toString() === this.state.model?.txValor?.toString());
		}
	}

	handleSuccess(e) {
		super.handleSuccess(e, Config);
	}

	handleClose(e) {
		super.handleClose(e, Config);
	}

	handleAddArquivo(accept = "*/*") {
		Modal.openWindow(FormUpload, {sizing : Modal.sizing.default}, { multiple : false, accept : accept }, (e, response) => {
			this.setModel({arquivo : response});
		});
	}

	handleDeleteArquivo() {
		this.setModel({arquivo : null});
	}

	handleChangeDropdownList(e,v,p){
		this.setModel({txValor : v.value});
	}

	handleChangeDropdownListMultiple(e,v,p){
		this.setModel({txValor : v.map(i => i.value)});
	}

	decimalIsAllowed({ value }){

		if(!isNaN(parseFloat(value))){
			/*if(this.state.model.dado.vlMinimoDecimal && parseFloat(value) < this.state.model.dado.vlMinimoDecimal && this.state.model.txValor.toString().length === 0){
				this.setModel({txValor : this.state.model.dado.vlMinimoDecimal});
				return false;
			}*/

			if(this.state.model.dado.vlMaximoDecimal){
				return parseFloat(value) <= this.state.model.dado.vlMaximoDecimal;
			}
		}
		
		return true;
	}

	render() {

		return super.render(
			<Form onSubmit={this.handleSuccess} refParam={f => (this.form = f)} key={this.state.key}>
				<Panel key={this.state.key} title="Cadastro de Dado Valor" onClose={this.handleClose} isModal={this.isModal()} container={{sizing: Container.sizing.xxl, className : ""}} modal={{sizing: this.props.sizing ? this.props.sizing : ModalDialog.sizing.xl}}>
					<PanelBody>
						<Row className="g-3 pb-2 px-2">

							<FormItem md={3} label="Ativo?">
								<BtnRadioGroupSimNao name="flAtivo" value={this.state.model.flAtivo} required={true} onChange={this.handleChangeModel}/>
							</FormItem>

							<FormItem md={12} label="Dado" show={this.props.dado === undefined}>
								<SelectDado disabled={this.state.model.idDadoValor} getOptionsWhen={() => this.state.dadoLocal !== null} filter={{dadoLocal : this.state.dadoLocal, dadoLocalGrupo : this.props.dadoLocalGrupo, flAtivo : true}} name="dado" value={this.state.model.dado} required={true} onChange={this.handleChangeModel} placeholder="Selecione Nome do Dado"/>
							</FormItem>

							<FormItem md={12} label="Observação" show={this.state.model.dado?.txObservacao?.length > 0}>
								<TextArea rows={3} name="txObservacao" value={this.state.model.dado?.txObservacao} readOnly={true}/>
							</FormItem>


								{this.state?.model?.dado?.tipoDado?.cdTipoDado === "MASK" ?
									<FormItem md={12} label={this.state.model.dado?.nmDado}>
										<InputMask mask={this.state.model.dado.dsMascara} pattern={(this.state.model.dado?.dsExpressaoRegular)} autoFocus={true} name="txValor" value={this.state.model.txValor} required={true} onChange={this.handleChangeModel} maxLength={this.state.model.dado.nrMaximoCaractere}/>
									</FormItem>
								: <></> }

								{this.state?.model?.dado?.tipoDado?.cdTipoDado === "VARCHAR" ?
									<FormItem md={12} label={this.state.model.dado?.nmDado}>
										<InputText autoFocus={true} name="txValor" value={this.state.model.txValor} pattern={(this.state.model.dado?.dsExpressaoRegular)} required={true} onChange={this.handleChangeModel} maxLength={this.state.model.dado.nrMaximoCaractere}/>
									</FormItem>
								: <></> }

								{this.state?.model?.dado?.tipoDado?.cdTipoDado === "EMAIL" ?
									<FormItem md={12} label={this.state.model.dado?.nmDado}>
										<InputEmail autoFocus={true} name="txValor" value={this.state.model.txValor} pattern={(this.state.model.dado?.dsExpressaoRegular)} required={true} onChange={this.handleChangeModel} maxLength={this.state.model.dado.nrMaximoCaractere}/>
									</FormItem>
								: <></> }

								{this.state?.model?.dado?.tipoDado?.cdTipoDado === "PASSWORD" ?
									<FormItem md={12} label={this.state.model.dado?.nmDado}>
										<InputPassword autoFocus={true} name="txValor" value={this.state.model.txValor} pattern={(this.state.model.dado?.dsExpressaoRegular)} required={true} onChange={this.handleChangeModel} maxLength={this.state.model.dado.nrMaximoCaractere}/>
									</FormItem>
								: <></> }

								{this.state?.model?.dado?.tipoDado?.cdTipoDado === "COLOR" ?
									<FormItem md={12} label={this.state.model.dado?.nmDado}>
										<InputColor autoFocus={true} name="txValor" value={this.state.model.txValor} required={true} onChange={this.handleChangeModel} maxLength={this.state.model.dado.nrMaximoCaractere}/>
									</FormItem>
								: <></> }
							
								{this.state?.model?.dado?.tipoDado?.cdTipoDado === "TEXT" ?
									<FormItem md={12} label={this.state.model.dado?.nmDado}>
										<TextArea rows={6} autoFocus={true} name="txValor" value={this.state.model.txValor} required={true} onChange={this.handleChangeModel}/>
									</FormItem>
								: <></> }

								{this.state?.model?.dado?.tipoDado?.cdTipoDado === "JSON" ?
									<>
									<FormItem md={12} label={this.state.model.dado?.nmDado}>
										<TextArea rows={6} autoFocus={true} name="txValor" value={this.state.model.txValor} required={true} onChange={this.handleChangeModel} className="text-bg-dark" style={{ height: "400px", fontFamily: "Courier New" }}/>
									</FormItem>
									{!Util.isJsonString(this.state.model.txValor) ? <div className="alert alert-danger pt-1 pb-1" role="alert"> JSON Inválido </div> : <></>}
									</>
								: <></> }

								{this.state?.model?.dado?.tipoDado?.cdTipoDado === "INTEGER" ?
									<FormItem md={6} label={this.state.model.dado?.nmDado}>
										<InputNumber autoFocus={true} name="txValor" value={parseInt(this.state.model.txValor)} required={true} onChange={this.handleChangeModel} min={this.state.model.dado.nrMinimoNumero} max={this.state.model.dado.nrMaximoNumero}/>
									</FormItem>
								: <></> }

								{this.state?.model?.dado?.tipoDado?.cdTipoDado === "INTEGER" && this.state.model.dado.nrMinimoNumero !== null ?
									<Col md={3} label={this.state.model.dado?.nmDado} className="d-flex align-items-end">
										Mínimo: <strong>{this.state.model.dado.nrMinimoNumero}</strong>
									</Col>
								: <></> }

								{this.state?.model?.dado?.tipoDado?.cdTipoDado === "INTEGER" && this.state.model.dado.nrMaximoNumero !== null ?
									<Col md={3} label={this.state.model.dado?.nmDado} className="d-flex align-items-end">
										Máximo: <strong>{this.state.model.dado.nrMaximoNumero}</strong>
									</Col>
								: <></> }

								{this.state?.model?.dado?.tipoDado?.cdTipoDado === "DECIMAL" ?
									<FormItem md={6} label={this.state.model.dado?.nmDado}>
										<InputNumberFormat autoFocus={true} name="txValor"
										prefix={this.state.model.dado?.dsPrefixo ? this.state.model.dado?.dsPrefixo + " " : ""}
										suffix={this.state.model.dado?.dsSufixo ? " " + this.state.model.dado?.dsSufixo : ""}
										isAllowed={this.decimalIsAllowed}
										value={parseFloat(this.state.model.txValor)} decimalScale={this.state.model.dado.nrDecimalEscala ? parseInt(this.state.model.dado.nrDecimalEscala) : 2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} required={true} onChange={this.handleChangeModel}/>
									</FormItem>
								: <></> }

								{this.state?.model?.dado?.tipoDado?.cdTipoDado === "DECIMAL" && this.state.model.dado.vlMinimoDecimal !== null ?
									<Col md={3} label={this.state.model.dado?.nmDado} className="d-flex align-items-end">
										Mínimo: <strong>{(Util.floatToValor(this.state.model.dado.vlMinimoDecimal, this.state.model.dado.nrDecimalEscala ? parseInt(this.state.model.dado.nrDecimalEscala) : 2))}</strong>
									</Col>
								: <></> }

								{this.state?.model?.dado?.tipoDado?.cdTipoDado === "DECIMAL" && this.state.model.dado.vlMaximoDecimal !== null ?
									<Col md={3} label={this.state.model.dado?.nmDado} className="d-flex align-items-end">
										Máximo: <strong>{(Util.floatToValor(this.state.model.dado.vlMaximoDecimal, this.state.model.dado.nrDecimalEscala ? parseInt(this.state.model.dado.nrDecimalEscala) : 2))}</strong>
									</Col>
								: <></> }

								{this.state?.model?.dado?.tipoDado?.cdTipoDado === "DATE" ?
									<FormItem md={4} label={this.state.model.dado?.nmDado}>
										<InputDate autoFocus={true} name="txValor" value={this.state.model.txValor} required={true} onChange={this.handleChangeModel}/>
									</FormItem>
								: <></> }

								{this.state?.model?.dado?.tipoDado?.cdTipoDado === "TIME" ?
									<FormItem md={4} label={this.state.model.dado?.nmDado}>
										<InputTime autoFocus={true} name="txValor" value={this.state.model.txValor} required={true} onChange={this.handleChangeModel}/>
									</FormItem>
								: <></> }

								{this.state?.model?.dado?.tipoDado?.cdTipoDado === "DATE_TIME" ?
									<>
										<FormItem md={4} label={`${this.state.model.dado?.nmDado} - Data`}>
											<InputDate autoFocus={true} name="txValor" value={this.state.model.txValor} required={true} onChange={this.handleChangeModel}/>
										</FormItem>

										<FormItem md={4} label={`${this.state.model.dado?.nmDado} - Hora`}>
											<InputTime autoFocus={true} name="txValor" value={this.state.model.txValor} required={true} onChange={this.handleChangeModel}/>
										</FormItem>
									</>
								: <></> }

								{this.state?.model?.dado?.tipoDado?.cdTipoDado === "BOOLEAN" ?
									<FormItem md={2} label={this.state.model.dado?.nmDado}>
										<BtnRadioGroupSimNao autoFocus={true} name="txValor" value={this.state.model.txValor} required={true} onChange={this.handleChangeModel}/>
									</FormItem>
								: <></> }

								{this.state?.model?.dado?.tipoDado?.cdTipoDado === "DROPDOWN_LIST" ?
									<FormItem md={12} label={this.state.model.dado?.nmDado}>
										<Select autoFocus={true} name="txValor" value={this.getValueDropdownList()} required={true} onChange={this.handleChangeDropdownList} options={this.getItensDropdownList()}/>
									</FormItem>
								: <></> }

								{this.state?.model?.dado?.tipoDado?.cdTipoDado === "DROPDOWN_MULTIPLE" ?
									<FormItem md={12} label={this.state.model.dado?.nmDado}>
										<SelectMultiple size={10} autoFocus={true} name="txValor" value={this.getValueDropdownList(true)} required={true} onChange={this.handleChangeDropdownListMultiple} options={this.getItensDropdownList()}/>
									</FormItem>
								: <></> }

								{this.state?.model?.dado?.tipoDado?.cdTipoDado === "FILE" ?
								<>
									<FormItem md={5} label={this.state.model.dado?.nmDado}>
										<InputText  value={this.state.model.arquivo ? this.state.model.arquivo?.nmArquivo : ""} readOnly={true} required={true}/>
									</FormItem>
									<FormItem md={2} label="Tamanho">
										<InputText  value={Util.formatBytes(this.state.model.arquivo?.arquivoBase?.nrTamanho)} readOnly={true} required={false}/>
									</FormItem>
									<FormItem md={3} label="Tipo">
										<InputText  value={this.state.model.arquivo ? this.state.model.arquivo?.arquivoBase?.cdTipo : ""} readOnly={true} required={false}/>
									</FormItem>
									<Col sizing={2} className="d-flex align-items-end justify-content-end">
										{parseInt(this.state.model?.arquivo?.idArquivo) > 0 ?
											<BtnButton className="w-100" color={BtnButton.color.danger} sizing={Button.sizing.default} onClick={this.handleDeleteArquivo}> <IconDelete/> Excluir </BtnButton>
											: <BtnButton className="w-100" sizing={Button.sizing.default} multiple={false} onClick={e => this.handleAddArquivo("*/*")} color={BtnButton.color.outlinePrimary}> <IconBoxArrowInUp/>Adicionar</BtnButton>}
									</Col>
									<Col sizing={12} className="d-flex align-items-end justify-content-end">
										{parseInt(this.state.model?.arquivo?.idArquivo) > 0 && this.state.model.arquivo?.cdUid && this.state.model.arquivo?.arquivoBase?.cdHash ?
											<Div>
												<a rel="opener" className="link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover" href={ConfigApp.getUrlArquivo() + "public/download/" + this.state.model.arquivo.cdUid + "/" + this.state.model.arquivo.arquivoBase.cdHash}>
													<IconDownload/> {this.state.model.arquivo?.nmArquivo}
												</a>
											</Div> : <></>}
									</Col>
								</>: <></> }

								{this.state?.model?.dado?.tipoDado?.cdTipoDado === "IMAGE" ?
								<>
									<FormItem md={5} label={this.state.model.dado?.nmDado}>
										<InputText  value={this.state.model.arquivo ? this.state.model.arquivo?.nmArquivo : ""} readOnly={true} required={true}/>
									</FormItem>
									<FormItem md={2} label="Tamanho">
										<InputText  value={Util.formatBytes(this.state.model.arquivo?.arquivoBase?.nrTamanho)} readOnly={true} required={false}/>
									</FormItem>
									<FormItem md={3} label="Tipo">
										<InputText  value={this.state.model.arquivo ? this.state.model.arquivo?.arquivoBase?.cdTipo : ""} readOnly={true} required={false}/>
									</FormItem>
									<Col sizing={2} className="d-flex align-items-end justify-content-end">
										{parseInt(this.state.model?.arquivo?.idArquivo) > 0 ?
											<BtnButton className="w-100" color={BtnButton.color.danger} sizing={Button.sizing.default} onClick={this.handleDeleteArquivo}> <IconDelete/> Excluir </BtnButton>
											: <BtnButton className="w-100" sizing={Button.sizing.default} multiple={false} onClick={e => this.handleAddArquivo("image/*")} color={BtnButton.color.outlinePrimary}> <IconBoxArrowInUp/>Adicionar</BtnButton>}
									</Col>
									<Col sizing={12} className="d-flex align-items-center justify-content-center">
										{parseInt(this.state.model?.arquivo?.idArquivo) > 0 && this.state.model.arquivo?.cdUid && this.state.model.arquivo?.arquivoBase?.cdHash ?
											<Div>
													<img style={{ maxHeight: "150px" }} src={ConfigApp.getUrlArquivo() + "public/inline/" + this.state.model.arquivo.cdUid + "/" + this.state.model.arquivo.arquivoBase.cdHash} className="img-fluid img-thumbnail" alt="..." />
											</Div> : <></>}
									</Col>
								</>: <></> }
						</Row>
					</PanelBody>
					<PanelFooter>
						<FormButtons onClose={this.handleClose} />
					</PanelFooter>
				</Panel>
			</Form>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <FormDadoValor {...props} navigate={navigate} params={params} location={location}/>
}
export default With