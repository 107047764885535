//#NO-CHANGE-FILE#
import Config from "./Config";
import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import ConfigOrdem from "../Ordem/Config";
import {ThreeDotsVertical as IconThreeDotsVertical} from "react-bootstrap-icons"
import { IconEdit, IconDelete } from "../../icons";
import { BaseList } from "../../siht/base";
import { DataGrid, DataGridButtons, DataGridColumn, DropDown, DropDownLabel, DropDownItem } from "../../siht/components";
import { Button, Div, Span } from "../../siht/elements";
import Util from "../../utils/Util";
import { BtnDelete, BtnEdit, BtnNew, StatusCustom } from "../../components/Custom";

class ListOrdemItem extends BaseList {

	constructor(props) {
		super(props);
		this.getItens = this.getItens.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleNew = this.handleNew.bind(this);
		this.handleEdit = this.handleEdit.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.handleMore = this.handleMore.bind(this);
		this.onSubmitFilter = this.onSubmitFilter.bind(this);
		this.handleClear = this.handleClear.bind(this);
		this.handleFormSuccess = this.handleFormSuccess.bind(this);
		this.handleFormClose = this.handleFormClose.bind(this);
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		super.init(Config);
	}

	getItens(more){
		if(this.props?.parent){
			super.getItens(Config.getService().getAllByOrdem, this.props.parent, more);
		}
	}

	handleNew(e) {
		super.handleNew(e, Config, {tipoPreco : this.props.parent.tipoPreco}, this.props?.onAdd);
	}

	handleEdit(e, item) {
		super.handleEdit(e, Config, item, this.props?.onEdit, this.props?.onAdd);
	}

	handleDelete(e, item) {
		super.handleDelete(e, Config, item, this.props?.onDelete, this.props?.onAdd);
	}

	getFormEmbedded(){
		return super.getFormEmbedded(Config);
	}

	render() {
		return super.render(
			<Div className="p-2 mt-2" key={this.state.key}>
				<Div className="d-flex justify-content-end flex-nowrap gap-2" show={ConfigOrdem.getPermissaoOrdem(this.props.parent).flPodeVerValorOrdem}>
					<BtnNew onClick={this.handleNew} disabled={!this.props?.parent.statusOrdem?.flPodeInserirItem}/>
					<BtnDelete disabled={this.state.selecteds.length === 0 || !this.props?.parent.statusOrdem?.flPodeInserirItem} sizing={Button.sizing.default} onClick={e => this.handleDelete(e, this.state.selecteds)}> Excluir </BtnDelete>
				</Div>

				<DataGrid data={this.state.data} search={true} isDisabled={i => false} sizing={DataGrid.sizing.default} select={true} selecteds={this.state.selecteds} onSelect={(selecteds)=>{this.setState({selecteds : selecteds})}} getItemValue={i=>i.idOrdemItem} onMore={this.handleMore}>
					
					<DataGridColumn label="Código" field={i => i.idOrdemItem} className="text-center"/>
					
					<DataGridColumn col={4} label="Produto" field={i => <React.Fragment>
						{i.produto?.nmProduto}<br/>							
						<StatusCustom color={i.produto?.tipoProduto}> {i.produto?.tipoProduto.nmTipoProduto} </StatusCustom>
					</React.Fragment>} className="text-start"/>

					<DataGridColumn col={2} label="Preço" field={i => 
						i.vlPreco !== null ? Util.floatToReais(i.vlPreco) : <Span className="fw-bold text-danger fs-7"> OCULTO </Span> 
					} className="text-end"/>
					<DataGridColumn col={1} label="Qtde" field={i => 
						<React.Fragment>
							{Util.floatToValor(i.nrQuantidade, i.produto?.unidadeMedida?.nrCasasDecimais)} 
							<Span className="fw-bold fs-7">{i.produto?.unidadeMedida?.cdUnidadeMedida}</Span>
						</React.Fragment>
					} className="text-center"/>
					<DataGridColumn col={1} label="Total Item" field={i => i.vlTotalItem !==null ? Util.floatToReais(i.vlTotalItem) : <Span className="fw-bold text-danger fs-7"> OCULTO </Span>} className="text-end"/>
					<DataGridColumn col={1} label="% Desc." field={i => i.pcDesconto !== null ? Util.floatToValor(i.pcDesconto, 2) : <Span className="fw-bold text-danger fs-7"> OCULTO </Span>} className="text-end"/>
					<DataGridColumn col={1} label="Desconto" field={i => i.vlDesconto !==null ? Util.floatToReais(i.vlDesconto) : <Span className="fw-bold text-danger fs-7"> OCULTO </Span>} className="text-end"/>
					<DataGridColumn col={2} label="Final" field={i => i.vlFinalItem !==null ? Util.floatToReais(i.vlFinalItem) : <Span className="fw-bold text-danger fs-7"> OCULTO </Span>} className="text-end fw-bold"/>
					<DataGridButtons>
						<BtnEdit sizing={Button.sizing.default} onClick={this.handleEdit} disabled={!this.props?.parent.statusOrdem?.flPodeInserirItem}/>
						<BtnDelete sizing={Button.sizing.default} onClick={this.handleDelete} disabled={!this.props?.parent.statusOrdem?.flPodeInserirItem}/>
						<DropDown alignment={DropDown.alignment.end}>
							<DropDownLabel sizing={Button.sizing.default} toggle={false}> <IconThreeDotsVertical/> </DropDownLabel>
							<DropDownItem title="Editar Registro" type="button" onClick={this.handleEdit} disabled={!this.props?.parent.statusOrdem?.flPodeInserirItem}> <IconEdit/> Editar </DropDownItem>
							<DropDownItem title="Excluir Registro" type="button" onClick={this.handleDelete} disabled={!this.props?.parent.statusOrdem?.flPodeInserirItem}> <IconDelete/> Excluir </DropDownItem>
						</DropDown>
					</DataGridButtons>
				</DataGrid>

			</Div>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <ListOrdemItem {...props} navigate={navigate} params={params} location={location}/>
}
export default With