//#NO-CHANGE-FILE#

import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import SelectTipoDado from "../../components/SelectDB/SelectTipoDado";
import { BaseCrud } from "../../siht/base";
import { FormButtons, FormItem, Row, Panel, PanelBody, PanelFooter, Container, ModalDialog, Col, InputGroup, InputGroupText } from "../../siht/components";
import { InputText, TextArea, Form, InputNumber, InputNumberFormat } from "../../siht/elements";
import Config from "./Config";
import Util from "../../utils/Util";
import { FormItemDadoLocalDadoLocalGrupo } from "../../components/FormItem";
import BtnRadioGroupSimNao from "../../components/BtnRadioGroupSimNao";

class FormDado extends BaseCrud {

	constructor(props) {
		super(props);
		this.init = this.init.bind(this);
		this.handleSuccess = this.handleSuccess.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleChangeModel = this.handleChangeModel.bind(this);
		this.handleSubmitForm = this.handleSubmitForm.bind(this);

		let dadoLocalGrupo = null;

		if(this.props.dadoLocalGrupo){
			dadoLocalGrupo = this.props.dadoLocalGrupo;
		}else if(this.props.location?.state?.dadoLocalGrupo){
			dadoLocalGrupo = this.props.location?.state?.dadoLocalGrupo;
		}

		this.state.model = {
			idDado : null,
			nmDado : "",
			flPrivado : false,
			flFormulario : false,
			flAtivo : true,
			txObservacao : "",
			cdDado : "",
			tipoDado : null,			
			dadoLocalGrupo : dadoLocalGrupo,
			txOpcao : '[{"value" : "1", "label" : "Teste"}]',
			nrMinimoItem : 0,
			nrMaximoItem : 1,
			nrMaximoCaractere : "",
			nrMinimoNumero : "",
			nrMaximoNumero : "",
			nrDecimalPrecisao : "",
			nrDecimalEscala : "",
			vlMinimoDecimal : "",
			vlMaximoDecimal : "",
			dsMascara : "",
			dsExpressaoRegular : "",
			dsPrefixo : "",
			dsSufixo : "",
		};
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		super.init(Config, () => {
			this.setState(state =>{
				state.model.cdDado = state.model.cdDado.substring(this.state.model.dadoLocalGrupo?.dadoLocal?.cdDadoLocal.length + 1, state.model.cdDado.length); //this.state.model.dadoLocalGrupo?.dadoLocal?.cdDadoLocal
				return state;
			});
		});
	}

	handleSuccess(e) {
		super.handleSuccess(e, Config);
	}

	handleClose(e) {
		super.handleClose(e, Config);
	}

	render() {
		return super.render(
			<Form onSubmit={this.handleSuccess} refParam={f => (this.form = f)} key={this.state.key}>
				<Panel key={this.state.key} title="Cadastro de Dado" onClose={this.handleClose} isModal={this.isModal()} container={{sizing: Container.sizing.xxl, className : ""}} modal={{sizing: this.props.sizing ? this.props.sizing : ModalDialog.sizing.xl}}>
					<PanelBody>
						<Row className="g-3 pb-2 px-2">
							<FormItem md={2} label="Código">
								<InputText name="idDado" value={this.state.model.idDado} readOnly={true} className="readonly-field" required={false} onChange={this.handleChangeModel}/>
							</FormItem>
							<FormItem md={4} label="Nome da Dado">
								<InputText name="nmDado" value={this.state.model.nmDado} autoFocus={true} maxLength={100} required={true} onChange={this.handleChangeModel}/>
							</FormItem>
							<FormItem md={2} label="Ativo?">
								<BtnRadioGroupSimNao name="flAtivo" value={this.state.model.flAtivo} required={true} onChange={this.handleChangeModel}/>
							</FormItem>
							<FormItem md={2} label="Formulário?">
								<BtnRadioGroupSimNao name="flFormulario" value={this.state.model.flFormulario} required={true} onChange={this.handleChangeModel}/>
							</FormItem>
							<FormItem md={2} label="Privado?">
								<BtnRadioGroupSimNao name="flPrivado" value={this.state.model.flPrivado} required={true} onChange={this.handleChangeModel}/>
							</FormItem>

							<FormItem md={12} label="Observação">
								<TextArea rows={3} name="txObservacao" value={this.state.model.txObservacao} required={false} onChange={this.handleChangeModel}/>
							</FormItem>

							<FormItemDadoLocalDadoLocalGrupo md={[6,6]} name="dadoLocalGrupo" value={this.state.model.dadoLocalGrupo} required={true} onChange={this.handleChangeModel} buttons={{search : false, new : false, edit: false}}/>

							<FormItem md={5} label="Identificador">
								<InputGroup required={true}>
									<InputGroupText className="fw-bold" style={{ fontFamily: "Courier New" }}>
										{this.state.model.dadoLocalGrupo?.dadoLocal?.cdDadoLocal}.
									</InputGroupText>
									<InputText className="fw-bold" name="cdDado" value={this.state.model.cdDado} maxLength={100} required={true} onChange={this.handleChangeModel} style={{ fontFamily: "Courier New" }}/>
								</InputGroup>
							</FormItem>
							<FormItem md={2} label="Min. Itens">
								<InputNumber name="nrMinimoItem" value={this.state.model.nrMinimoItem} required={true} onChange={this.handleChangeModel} min={0} className="text-center"/>
							</FormItem>							
							<FormItem md={2} label="Max. Itens">
								<InputNumber name="nrMaximoItem" value={this.state.model.nrMaximoItem} required={true} onChange={this.handleChangeModel} min={parseInt(this.state.model.nrMinimoItem) === 0 ? 1 : parseInt(this.state.model.nrMinimoItem)} className="text-center"/>
							</FormItem>									
							<FormItem md={3} label="Tipo de Dado">
								<SelectTipoDado name="tipoDado" value={this.state.model.tipoDado} required={true} onChange={this.handleChangeModel} placeholder="Selecione Nome do TipoDado" buttons={{search : false, new : false, edit: false}}/>
							</FormItem>									
							<FormItem md={12} label="Opções" show={this.state.model.tipoDado?.cdTipoDado === "DROPDOWN_LIST" || this.state.model.tipoDado?.cdTipoDado === "DROPDOWN_MULTIPLE"}>
								<TextArea name="txOpcao" value={this.state.model.txOpcao} required={false} onChange={this.handleChangeModel} className="text-bg-dark" style={{ height: "400px", fontFamily: "Courier New" }}/>
							</FormItem>
							<Col sizing={12} show={this.state.model.tipoDado?.cdTipoDado === "DROPDOWN_LIST" || this.state.model.tipoDado?.cdTipoDado === "DROPDOWN_MULTIPLE"}>
								{!Util.isJsonString(this.state.model.txOpcao) ? <div className="alert alert-danger pt-1 pb-1" role="alert"> JSON Inválido </div> : <></>}
							</Col>

							<FormItem md={3} label="Tamanho Máximo" show={this.state.model.tipoDado?.cdTipoDado === "VARCHAR" || this.state.model.tipoDado?.cdTipoDado === "TEXT" || this.state.model.tipoDado?.cdTipoDado === "PASSWORD"}>
								<InputNumber name="nrMaximoCaractere" value={this.state.model.nrMaximoCaractere} onChange={this.handleChangeModel}/>
							</FormItem>
							<FormItem md={3} label="Mínimo" show={this.state.model.tipoDado?.cdTipoDado === "INTEGER"}>
								<InputNumber name="nrMinimoNumero" value={this.state.model.nrMinimoNumero} onChange={this.handleChangeModel}/>
							</FormItem>
							<FormItem md={3} label="Máximo" show={this.state.model.tipoDado?.cdTipoDado === "INTEGER"}>
								<InputNumber name="nrMaximoNumero" value={this.state.model.nrMaximoNumero} onChange={this.handleChangeModel}/>
							</FormItem>
							<FormItem md={2} label="Precisão" show={this.state.model.tipoDado?.cdTipoDado === "DECIMAL"}>
								<InputNumber name="nrDecimalPrecisao" value={this.state.model.nrDecimalPrecisao} onChange={this.handleChangeModel}/>
							</FormItem>
							<FormItem md={2} label="Escala" show={this.state.model.tipoDado?.cdTipoDado === "DECIMAL"}>
								<InputNumber name="nrDecimalEscala" value={this.state.model.nrDecimalEscala} onChange={this.handleChangeModel}/>
							</FormItem>
							<FormItem md={2} label="Mínimo" show={this.state.model.tipoDado?.cdTipoDado === "DECIMAL"}>
								<InputNumberFormat name="vlMinimoDecimal" value={parseFloat(this.state.model.vlMinimoDecimal)} decimalScale={this.state.model.nrDecimalEscala ? parseInt(this.state.model.nrDecimalEscala) : 2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} prefix="" required={false} onChange={this.handleChangeModel}/>
							</FormItem>
							<FormItem md={2} label="Máximo" show={this.state.model.tipoDado?.cdTipoDado === "DECIMAL"}>										
								<InputNumberFormat name="vlMaximoDecimal" value={parseFloat(this.state.model.vlMaximoDecimal)} decimalScale={this.state.model.nrDecimalEscala ? parseInt(this.state.model.nrDecimalEscala) : 2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} prefix="" required={false} onChange={this.handleChangeModel}/>
							</FormItem>
							<FormItem md={2} label="Prefixo" show={this.state.model.tipoDado?.cdTipoDado === "DECIMAL"}>
								<InputText name="dsPrefixo" value={this.state.model.dsPrefixo} maxLength={20} required={false} onChange={this.handleChangeModel}/>
							</FormItem>
							<FormItem md={2} label="Sufixo" show={this.state.model.tipoDado?.cdTipoDado === "DECIMAL"}>
								<InputText name="dsSufixo" value={this.state.model.dsSufixo} maxLength={20} required={false} onChange={this.handleChangeModel}/>
							</FormItem>
							<FormItem md={5} label="Máscara" show={this.state.model.tipoDado?.cdTipoDado === "MASK"}>
								<InputText name="dsMascara" value={this.state.model.dsMascara} maxLength={100} required={true} onChange={this.handleChangeModel}/>
							</FormItem>
							<FormItem md={5} label="Expressão Regular" show={this.state.model.tipoDado?.cdTipoDado === "MASK" || this.state.model.tipoDado?.cdTipoDado === "VARCHAR" || this.state.model.tipoDado?.cdTipoDado === "PASSWORD"}>
								<InputText name="dsExpressaoRegular" value={this.state.model.dsExpressaoRegular} maxLength={100} required={false} onChange={this.handleChangeModel}/>
							</FormItem>
							
						</Row>
					</PanelBody>
					<PanelFooter>
						<FormButtons onClose={this.handleClose} />
					</PanelFooter>
				</Panel>
			</Form>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <FormDado {...props} navigate={navigate} params={params} location={location}/>
}
export default With