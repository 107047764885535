import Http from "../controllers/Http";

export default class FuncionarioHistoricoService {

	static urlBase = "/funcionarioHistorico/";

	static getForCombo(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(FuncionarioHistoricoService.urlBase + 'forCombo', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static list(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(FuncionarioHistoricoService.urlBase + 'list', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneById(id, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.get(FuncionarioHistoricoService.urlBase + id)
			.then(fnSucess)
			.catch(fnError);
	}

	static create(data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(FuncionarioHistoricoService.urlBase, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static update(id, data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.put(FuncionarioHistoricoService.urlBase + id, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static delete(id, fnSucess = () => { }, fnError = () => { }) {
		if(Number.isInteger(id)){
			Http.getInstanceAppJson()
				.delete(FuncionarioHistoricoService.urlBase + id)
				.then(fnSucess)
				.catch(fnError);
		}else{
			Http.getInstanceAppJson()
			.post(FuncionarioHistoricoService.urlBase + 'delete', id)
			.then(fnSucess)
			.catch(fnError);
		}
	}

	static save(data, fnSucess = () => { }, fnError = () => { }) {
		if (data.idFuncionarioHistorico) {
			this.update(data.idFuncionarioHistorico, data, fnSucess, fnError);
		} else {
			this.create(data, fnSucess, fnError);
		}
	}

	static getAllByFuncionario(funcionario, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(FuncionarioHistoricoService.urlBase + 'funcionario', funcionario)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByFuncionario(funcionario, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(FuncionarioHistoricoService.urlBase + 'funcionario/' + funcionario.idFuncionario)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByStatusFuncionario(statusFuncionario, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(FuncionarioHistoricoService.urlBase + 'statusFuncionario', statusFuncionario)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByStatusFuncionario(statusFuncionario, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(FuncionarioHistoricoService.urlBase + 'statusFuncionario/' + statusFuncionario.idStatusFuncionario)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByUsuario(usuario, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(FuncionarioHistoricoService.urlBase + 'usuario', usuario)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByUsuario(usuario, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(FuncionarioHistoricoService.urlBase + 'usuario/' + usuario.idUsuario)
			.then(fnSucess)
			.catch(fnError);
	}
}