import Config from "./Config";
import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import SelectPessoa from "../../components/SelectDB/SelectPessoa";
import ListFaturaNotaFiscalDeducaoItem from "../FaturaNotaFiscalDeducaoItem/ListFaturaNotaFiscalDeducaoItem";
import Permissao from "../../controllers/Permissao";
import { BaseList, BaseCrud } from "../../siht/base";
import { FormButtons, FormItem, Row, TabNavigator, Tab, Panel, PanelBody, PanelFooter, Container } from "../../siht/components";
import { InputText, InputNumberFormat, InputDate, Form, BtnSubmit } from "../../siht/elements";

class FormFaturaNotaFiscalDeducao extends BaseCrud {

	constructor(props) {
		super(props);
		this.init = this.init.bind(this);
		this.initModel = this.initModel.bind(this);
		this.handleSuccess = this.handleSuccess.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleChangeModel = this.handleChangeModel.bind(this);
		this.handleSubmitForm = this.handleSubmitForm.bind(this);
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		super.init(Config);
	}

	initModel(){
		return {
			idFaturaNotaFiscalDeducao : null,
			faturaNotaFiscal : this.props.parent !== undefined ? this.props.parent : {},
			pessoa : null,
			cdTipoValor : "",
			cdTipoDeducao : "",
			vlDeducao : 0,
			cdNfReferetencia : "",
			dtNfReferencia : "",
			vlTotalReferencia : 0,
			faturaNotaFiscalDeducaoItem : []
		}
	}

	handleSuccess(e) {
		super.handleSuccess(e, Config);
	}

	handleClose(e) {
		super.handleClose(e, Config);
	}

	render() {
		return super.render(

					<Panel key={this.state.key} title="Cadastro de FaturaNotaFiscalDeducao" onClose={this.handleClose} isModal={this.isModal()} container={{sizing: Container.sizing.xxl, className : ""}}>
						<PanelBody className="p-0 m-0">
							<TabNavigator key={this.state.key} onChangeTab={this.handleChangeTab} className="border-light">
								<Tab title="FaturaNotaFiscalDeducao" className="m-2">
									<Form onSubmit={this.handleSuccess} refParam={f => (this.form = f)}>
										<Row className="g-3 pb-2 px-2">
											<FormItem md={6} label="Código">
												<InputText name="idFaturaNotaFiscalDeducao" value={this.state.model.idFaturaNotaFiscalDeducao} readOnly={true} className="readonly-field" required={false} onChange={this.handleChangeModel}/>
											</FormItem>
											<FormItem md={12} label="Nome do Pessoa">
												<SelectPessoa name="pessoa" value={this.state.model.pessoa} required={true} onChange={this.handleChangeModel} placeholder="Selecione Nome do Pessoa"/>
											</FormItem>
											<FormItem md={6} label="CdTipoValor">
												<InputText name="cdTipoValor" value={this.state.model.cdTipoValor} maxLength={100} required={true} onChange={this.handleChangeModel}/>
											</FormItem>
											<FormItem md={6} label="CdTipoDeducao">
												<InputText name="cdTipoDeducao" value={this.state.model.cdTipoDeducao} maxLength={100} required={true} onChange={this.handleChangeModel}/>
											</FormItem>
											<FormItem md={4} label="VlDeducao">
												<InputNumberFormat name="vlDeducao" value={this.state.model.vlDeducao} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} prefix="R$ " required={true} onChange={this.handleChangeModel}/>
											</FormItem>
											<FormItem md={6} label="CdNfReferetencia">
												<InputText name="cdNfReferetencia" value={this.state.model.cdNfReferetencia} maxLength={100} required={true} onChange={this.handleChangeModel}/>
											</FormItem>
											<FormItem md={4} label="DtNfReferencia">
												<InputDate name="dtNfReferencia" value={this.state.model.dtNfReferencia} required={true} onChange={this.handleChangeModel}/>
											</FormItem>
											<FormItem md={4} label="VlTotalReferencia">
												<InputNumberFormat name="vlTotalReferencia" value={this.state.model.vlTotalReferencia} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} prefix="R$ " required={true} onChange={this.handleChangeModel}/>
											</FormItem>
											<BtnSubmit hidden > Enviar </BtnSubmit>
										</Row>
									</Form>
								</Tab>
								<Tab show={Permissao.getInstance().getPermissoes([130])} title="Itens" disabled={!this.state.model.idFaturaNotaFiscalDeducao} opened={false} className="m-2">
									<ListFaturaNotaFiscalDeducaoItem parent={this.state.model} localOpen={BaseList.localOpen.modal}/>
								</Tab>
							</TabNavigator>
						</PanelBody>
						<PanelFooter>
							<FormButtons show={this.state.tabIndex === 0} onClose={this.handleClose} onSubmit={this.handleSubmitForm}/>
						</PanelFooter>
					</Panel>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <FormFaturaNotaFiscalDeducao {...props} navigate={navigate} params={params} location={location}/>
}
export default With