//#NO-CHANGE-FILE#

import FaturaNotaFiscalService from "../../services/FaturaNotaFiscalService";
import FormFaturaNotaFiscal from "./FormFaturaNotaFiscal";
import ListFaturaNotaFiscal from "./ListFaturaNotaFiscal";
import Permissao from "../../controllers/Permissao";
import Util from "../../utils/Util";
import DadoValorService from "../../services/DadoValorService";

export default class Config {

	static getUrlForm() {
		return "/CadFaturaNotaFiscal";
	}

	static getUrlList() {
		return "/LstFaturaNotaFiscal";
	}

	static getComponentForm() {
		return FormFaturaNotaFiscal;
	}

	static getPermissionForm() {
		return Permissao.getInstance().getPermissoes([130]);
	}

	static getPermissionList() {
		return Permissao.getInstance().getPermissoes([130]);
	}

	static getComponentList() {
		return ListFaturaNotaFiscal;
	}

	static getService() {
		return FaturaNotaFiscalService;
	}

	static getKeyId() {
		return "idFaturaNotaFiscal";
	}

	static getKeyDescription() {
		return "nmFaturaNotaFiscal";
	}

	static setDefaultValues(state , fnCallBack = () => {}, className = undefined) {
		className = className === undefined ? "FormFaturaNotaFiscal" : className;
		let keyNameScreen = Util.toToUpperTextCamelCase(className, "_") + ".";
		var prefix = "CONFIGURACAO.";
		DadoValorService.getValues(`${prefix}${keyNameScreen}`, (response)=> {
			Util.setValueObjectCamelCase(state.model, response, keyNameScreen, prefix);
			fnCallBack();
		}, fnCallBack);
	}

	static getPermissaoFaturaNotaFiscal(faturaNotaFiscal){

		if(faturaNotaFiscal === undefined){
			faturaNotaFiscal = { idFaturaNotaFiscal : true };
		}

		return {
			flPodeEnviar : Permissao.getInstance().getPermissoes([131]),
			flPodeEstornar : Permissao.getInstance().getPermissoes([132]),
			flPodeIgnorar : Permissao.getInstance().getPermissoes([133]),
			flPodeReativar : Permissao.getInstance().getPermissoes([134]),
			flPodeSubstituir : Permissao.getInstance().getPermissoes([149]),
		};
	}
}