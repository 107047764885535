import { BaseComponent } from "../siht/base";

export default class IconDownload extends BaseComponent {

	render() {

		if(this.props.show !== undefined && !Boolean(this.props.show)){
			return (<></>);
		}

		const props = Object.assign({}, this.props);
		props.xmlns = "http://www.w3.org/2000/svg";
		props.width = props.width === undefined ? 16 : props.width;
		props.height = props.height === undefined ? 16 : props.height;
		props.fill = "currentColor";
		props.viewBox=`0 0 16 16`

		return (
			<svg {...props}>
			  <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5"/>
			  <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z"/>
			</svg>
		);
	}
}