
import FormPessoaTipoNotificacao from "./FormPessoaTipoNotificacao.js";
import ListPessoaTipoNotificacao from "./ListPessoaTipoNotificacao.js";
import { Permissao } from "../../controllers";
import { Util } from "../../utils";
import { DadoValorService, PessoaTipoNotificacaoService } from "../../services";

export default class Config {

	static getUrlForm() {
		return "/CadPessoaTipoNotificacao";
	}

	static getUrlList() {
		return "/LstPessoaTipoNotificacao";
	}

	static getComponentForm() {
		return FormPessoaTipoNotificacao;
	}

	static getPermissionForm() {
		return Permissao.getInstance().getPermissoes([202]);
	}

	static getPermissionList() {
		return Permissao.getInstance().getPermissoes([202]);
	}

	static getComponentList() {
		return ListPessoaTipoNotificacao;
	}

	static getService() {
		return PessoaTipoNotificacaoService;
	}

	static getKeyId() {
		return "idPessoaTipoNotificacao";
	}

	static getKeyDescription() {
		return "nmPessoaTipoNotificacao";
	}

	static setDefaultValues(state , fnCallBack = () => {}, className = undefined) {
		className = className === undefined ? "FormPessoaTipoNotificacao" : className;
		let keyNameScreen = Util.toToUpperTextCamelCase(className, "_") + ".";
		var prefix = "CONFIGURACAO.";
		DadoValorService.getValues(`${prefix}${keyNameScreen}`, (response)=> {
			Util.setValueObjectCamelCase(state.model, response, keyNameScreen, prefix);
			fnCallBack();
		}, fnCallBack);
	}
}