import Http from "../controllers/Http";

export default class FaturaNotaFiscalItemObraService {

	static urlBase = "/faturaNotaFiscalItemObra/";

	static getForCombo(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(FaturaNotaFiscalItemObraService.urlBase + 'forCombo', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static list(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(FaturaNotaFiscalItemObraService.urlBase + 'list', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneById(id, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.get(FaturaNotaFiscalItemObraService.urlBase + id)
			.then(fnSucess)
			.catch(fnError);
	}

	static create(data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(FaturaNotaFiscalItemObraService.urlBase, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static update(id, data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.put(FaturaNotaFiscalItemObraService.urlBase + id, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static delete(id, fnSucess = () => { }, fnError = () => { }) {
		if(Number.isInteger(id)){
			Http.getInstanceAppJson()
				.delete(FaturaNotaFiscalItemObraService.urlBase + id)
				.then(fnSucess)
				.catch(fnError);
		}else{
			Http.getInstanceAppJson()
			.post(FaturaNotaFiscalItemObraService.urlBase + 'delete', id)
			.then(fnSucess)
			.catch(fnError);
		}
	}

	static save(data, fnSucess = () => { }, fnError = () => { }) {
		if (data.idFaturaNotaFiscalItemObra) {
			this.update(data.idFaturaNotaFiscalItemObra, data, fnSucess, fnError);
		} else {
			this.create(data, fnSucess, fnError);
		}
	}

	static getAllByFaturaNotaFiscalItem(faturaNotaFiscalItem, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(FaturaNotaFiscalItemObraService.urlBase + 'faturaNotaFiscalItem', faturaNotaFiscalItem)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByFaturaNotaFiscalItem(faturaNotaFiscalItem, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(FaturaNotaFiscalItemObraService.urlBase + 'faturaNotaFiscalItem/' + faturaNotaFiscalItem.idFaturaNotaFiscalItem)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByEndereco(endereco, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(FaturaNotaFiscalItemObraService.urlBase + 'endereco', endereco)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByEndereco(endereco, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(FaturaNotaFiscalItemObraService.urlBase + 'endereco/' + endereco.idEndereco)
			.then(fnSucess)
			.catch(fnError);
	}
}