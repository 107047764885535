
import FormTipoDocumento from "./FormTipoDocumento.js";
import ListTipoDocumento from "./ListTipoDocumento.js";
import { Permissao } from "../../controllers";
import { Util } from "../../utils";
import { DadoValorService, TipoDocumentoService } from "../../services";

export default class Config {

	static getUrlForm() {
		return "/CadTipoDocumento";
	}

	static getUrlList() {
		return "/LstTipoDocumento";
	}

	static getComponentForm() {
		return FormTipoDocumento;
	}

	static getPermissionForm() {
		return Permissao.getInstance().getPermissoes([224]);
	}

	static getPermissionList() {
		return Permissao.getInstance().getPermissoes([224]);
	}

	static getComponentList() {
		return ListTipoDocumento;
	}

	static getService() {
		return TipoDocumentoService;
	}

	static getKeyId() {
		return "idTipoDocumento";
	}

	static getKeyDescription() {
		return "nmTipoDocumento";
	}

	static setDefaultValues(state , fnCallBack = () => {}, className = undefined) {
		className = className === undefined ? "FormTipoDocumento" : className;
		let keyNameScreen = Util.toToUpperTextCamelCase(className, "_") + ".";
		var prefix = "CONFIGURACAO.";
		DadoValorService.getValues(`${prefix}${keyNameScreen}`, (response)=> {
			Util.setValueObjectCamelCase(state.model, response, keyNameScreen, prefix);
			fnCallBack();
		}, fnCallBack);
	}
}