import { Component } from "react";

export default class Container extends Component {

    static sizing = {
        sm:"sm",
        md:"md",
        lg:"lg",
        xl:"xl",
        xxl:"xxl",
        fluid:"fluid",
        default: "",
    }

    render() {

        if(this.props.show !== undefined && !Boolean(this.props.show)){
            return (<></>);
        }

        const props = Object.assign({}, this.props);
        delete props.show;        
        props.className = `container${props.sizing !== undefined ? `-${props.sizing}` : ""} ${props.className !== undefined ? props.className : ""}`;

        delete props.sizing;

        return (
            <div {...props}> {this.props.children} </div>
        );
    }
}