//#NO-CHANGE-FILE#

import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import SelectTipoPagamento from "../../components/SelectDB/SelectTipoPagamento";
import SelectTipoTransacao from "../../components/SelectDB/SelectTipoTransacao";
import SelectPlugin from "../../components/SelectDB/SelectPlugin";
import ListDadoValorByParent from "../DadoValor/ListDadoValorByParent";
import Permissao from "../../controllers/Permissao";
import { BaseList, BaseCrud } from "../../siht/base";
import { FormButtons, FormItem, Row, TabNavigator, Tab, Panel, PanelBody, PanelFooter, Container, ModalDialog } from "../../siht/components";
import { InputText, InputNumber, Form, BtnSubmit, BtnButton } from "../../siht/elements";
import Config from "./Config";
import BtnRadioGroupSimNao from "../../components/BtnRadioGroupSimNao";
import { Modal } from "../../siht/controller";
import ListDadoValorByParentForm from "../DadoValor/ListDadoValorByParentForm";
import { PatchPlus as IconPatchPlus} from "react-bootstrap-icons";

class FormContaTipoPagamento extends BaseCrud {

	constructor(props) {
		super(props);
		this.init = this.init.bind(this);
		this.initModel = this.initModel.bind(this);
		this.handleSuccess = this.handleSuccess.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleChangeModel = this.handleChangeModel.bind(this);
		this.handleSubmitForm = this.handleSubmitForm.bind(this);
		this.handleClickToken = this.handleClickToken.bind(this);
		this.handleClickWebhook = this.handleClickWebhook.bind(this);
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		super.init(Config);
	}

	initModel(){
		return {
			idContaTipoPagamento : null,
			conta : this.props.parent !== undefined ? this.props.parent : {},
			tipoPagamento : null,
			tipoTransacao : null,
			plugin : null,
			nrParcelaMaxima : null,
			nrDiaAgenda : null,
			flAtivo : true,
			contaTipoPagamentoDadoValor : []
		}
	}

	handleSuccess(e) {
		super.handleSuccess(e, Config);
	}

	handleClose(e) {
		super.handleClose(e, Config);
	}

	handleClickToken(e) {
		Config.getService().getToken(this.state.model.idContaTipoPagamento, (response) =>{
			Modal.info("Atenção", "Sucesso ao capturar Token", null, Modal.style.primary);	
		}, (response) =>{
			Modal.info("Atenção", response.message, "Erro ao capturar token", Modal.style.danger);
		});
	}

	handleClickWebhook(e) {
		Config.getService().getWebhook(this.state.model.idContaTipoPagamento, (response) =>{
			Modal.info("Atenção", "Sucesso ao processar Webhook:", response?.data?.url, Modal.style.primary);	
		}, (response) =>{
			Modal.info("Atenção", response.message, "Erro ao capturar webook", Modal.style.danger);
		});
	}

	render() {
		return super.render(
			<Panel key={this.state.key} title="Cadastro de Conta x Tipo Pagamento" onClose={this.handleClose} localOpen={this.props.localOpen} isModal={this.isModal()} container={{sizing: Container.sizing.xxl, className : ""}} modal={{sizing: this.props.sizing ? this.props.sizing : ModalDialog.sizing.xl}}>
				<PanelBody className="p-0 m-0">
					<TabNavigator key={this.state.key} onChangeTab={this.handleChangeTab} className="border-light">
						<Tab title="Conta x Tipo Pagamento" className="m-2">
							<Form onSubmit={this.handleSuccess} refParam={f => (this.form = f)}>
								<Row className="g-3 pb-2 px-2">
									<FormItem md={6} label="Código">
										<InputText name="idContaTipoPagamento" value={this.state.model.idContaTipoPagamento} readOnly={true} className="readonly-field" required={false} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={3} label="Ativo?">
										<BtnRadioGroupSimNao name="flAtivo" value={this.state.model.flAtivo} required={true} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={12} label="Tipo de Pagamento">
										<SelectTipoPagamento name="tipoPagamento" value={this.state.model.tipoPagamento} required={true} onChange={this.handleChangeModel} placeholder="Selecione o Tipo de Pagamento"/>
									</FormItem>
									<FormItem md={12} label="Tipo Transação">
										<SelectTipoTransacao name="tipoTransacao" value={this.state.model.tipoTransacao} required={true} onChange={this.handleChangeModel} placeholder="Selecione o Tipo de Transação"/>
									</FormItem>
									<FormItem md={12} label="Nome do Plugin">
										<SelectPlugin name="plugin" value={this.state.model.plugin} required={false} onChange={this.handleChangeModel} placeholder="Selecione o Plugin"/>
									</FormItem>
									<FormItem md={6} label="Parcela Máxima">
										<InputNumber name="nrParcelaMaxima" value={this.state.model.nrParcelaMaxima} required={false} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={6} label="Dia de Agenda">
										<InputNumber name="nrDiaAgenda" value={this.state.model.nrDiaAgenda} required={true} onChange={this.handleChangeModel}/>
									</FormItem>
									
									<ListDadoValorByParentForm cdDadoLocal="CONTA_TIPO_PAGAMENTO" parent={this.state.model} onChange={this.handleChangeModel} name="contaTipoPagamentoDadoValor"/>

									<BtnSubmit hidden > Enviar </BtnSubmit>
								</Row>
							</Form>
						</Tab>
						<Tab show={Permissao.getInstance().getPermissoes([163])} title={<><IconPatchPlus/> Complementar</>} disabled={!this.state.model.idContaTipoPagamento} opened={false} className="p-0 m-0">
							<ListDadoValorByParent cdDadoLocal="CONTA_TIPO_PAGAMENTO" parent={this.state.model} localOpen={BaseList.localOpen.modal}/>
						</Tab>
					</TabNavigator>
				</PanelBody>
				<PanelFooter>
					<FormButtons show={this.state.tabIndex === 0} onClose={this.handleClose} onSubmit={this.handleSubmitForm}>
						{this.state.model.tipoPagamento?.cdTipoPagamento === "PIX_IMEDIATO" || this.state.model.tipoPagamento?.cdTipoPagamento === "PIX_VENCIMENTO" ?
							<BtnButton color={BtnButton.color.outlineWarning} onClick={this.handleClickWebhook}> Criar Webhook </BtnButton>
						: <></>}
						<BtnButton color={BtnButton.color.outlineWarning} onClick={this.handleClickToken}> Testar Token </BtnButton>
					</FormButtons>
				</PanelFooter>
			</Panel>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <FormContaTipoPagamento {...props} navigate={navigate} params={params} location={location}/>
}
export default With