//#NO-CHANGE-FILE#

import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { BaseList } from "../../siht/base";
import {  Panel, Container, TabNavigator, Tab, PanelBody } from "../../siht/components";
import ListFaturaFatura from "./ListFaturaFatura";
import ListFaturaCobranca from "./ListFaturaCobranca";
import { Coin as IconCoin, ListOl as IconListOl} from "react-bootstrap-icons";

class ListFatura extends BaseList {

	constructor(props) {
		super(props);
		this.handleChange = this.handleChange.bind(this);
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
	}

	render() {

		return super.render(
			<Panel title="Lista de Fatura(s)" isModal={this.isModal()} container={{sizing: Container.sizing.fluid, className : ""}}>
				<PanelBody className="p-0 m-0">
					<TabNavigator onChangeTab={this.handleChangeTab} className="border-light">
						<Tab title={<><IconCoin/> Faturas</>} className="m-2">
							<ListFaturaFatura/>
						</Tab>
						<Tab title={<><IconListOl/> Cobranças</>} className="m-2">
							<ListFaturaCobranca/>
						</Tab>
					</TabNavigator>
				</PanelBody>
			</Panel>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <ListFatura {...props} navigate={navigate} params={params} location={location}/>
}
export default With