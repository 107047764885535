import BtnButton from "./BtnButton";
import Modal from "../controller/Modal";
import Field from "./Field";
import InputText from "./InputText";
import Button from "./Button";
import { DropDown, DropDownItem, DropDownLabel, InputGroup, PanelSearch } from "../components";
import Span from "./Span";
import { IconEdit, IconNew } from "../../icons";

export default class InputSearchDB extends Field {

    constructor(props) {
        super(props);

        this.handleSearch = this.handleSearch.bind(this);
        this.handleClear = this.handleClear.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleAdd = this.handleAdd.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.resultForm = this.resultForm.bind(this);
    }

    componentDidUpdate(nextProps) {
        super.componentDidUpdate(nextProps);
    }
    
    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }
    
    handleClear(){
        var e = {target : this.ref.current};
        super.handleChange(e, null, this.props.name);
    }

    handleSearch(config = {}){        
        Modal.openWindow(PanelSearch, config, {             
            filter : this.props.filter,
            value : this.props.value,
            searchDB : true,
            getConfigSearch : this.getConfigSearch,
            getOptionValue : this.getOptionValue,
            getOptionLabel : this.getOptionLabel,
            getForComboService : this.getForComboService(),
            getDefault : this.getDefault,
            handleChange : super.handleChange
        }, (selected) =>{
            var e = {target : this.ref.current};
            super.handleChange(e, selected, this.props.name);
        });
    }

    getValue(){
        var value = this.getOptionValue(this.props.value);
        var label = this.getOptionLabel(this.props.value);

        if(value && label){
            return `${value} - ${label}`
        }else{
            return "";
        }
    }

    resultForm(e, response){
        //this.getOptions(() => super.handleChange(e, response.data, this.props.name));
        super.handleChange(e, response.data, this.props.name);
    }

    handleAdd() {
        if(this.getFormCrud()){
            Modal.openWindow(this.getFormCrud(), {sizing : Modal.sizing.xl}, {}, this.resultForm);
        }
    }

    handleEdit() {
        if(this.getFormCrud()){
            Modal.openWindow(this.getFormCrud(), {sizing : Modal.sizing.xl}, { id : this.getOptionValue(this.props?.value) }, this.resultForm);
        }
    }

    render() {
        if(this.getConfigSearch === undefined){
            return super.render();
        }

        var buttons = {
            search: this.props?.buttons?.search !== undefined ? this.props?.buttons?.search : true,
            new : this.props?.buttons?.new !== undefined ? this.props?.buttons?.new : true,
            edit : this.props?.buttons?.edit !== undefined ? this.props?.buttons?.edit : true,
            clear : this.props?.buttons?.clear !== undefined ? this.props?.buttons?.clear : true,
        };

        if(this.props.buttons === false){
            buttons = {
                search: false,
                new : false,
                edit : false,
                clear : false,
            };
        }

        if(buttons.new){
            buttons.new = this.getPermissionFormCrud();
        }
        
        if(buttons.edit){
            buttons.edit = this.getPermissionFormCrud();
        }

        const props = Object.assign({}, this.props);
        props.value = this.getValue();
        props.className = `readonly-field ${props.className ? props.className : ""}`;
        props.ref = this.props.refParam ? this.props.refParam : this.ref;
        props.feedback = false;
        props.onFocus = this.handleSearch;

        return (
            <InputGroup>
                {super.render(<InputText {...props}/>, false)}
                <BtnButton tabIndex="-1" className="ps-2 pe-2" show={buttons.clear} color={Button.color.outlineDanger} sizing={this.props.sizing} onClick={this.handleClear} disabled={this.props.disabled || !this.getOptionValue(this.props.value)}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                    </svg>
                </BtnButton>
                <BtnButton tabIndex="-1" className="ps-2 pe-2" show={buttons.search} color={Button.color.outlineDark} sizing={this.props.sizing} onClick={this.handleSearch} disabled={this.props.disabled}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                        <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                    </svg>
                </BtnButton>
                {buttons.new || buttons.edit ?
                <>
                    <DropDownLabel tabIndex="-1" className="dropdown-toggle-split"> <Span className="visually-hidden">Toggle Dropdown</Span> </DropDownLabel>
                    <DropDown alignment={DropDown.alignment.end}>                    
                        <DropDownItem show={buttons.new} title="Novo Registro" type="button" disabled={this.props.disabled} onClick={e => this.handleAdd()}> <IconNew/> Novo </DropDownItem>
                        <DropDownItem show={buttons.edit} title="Excluir Registro(s) Selecionado(s)" type="button" disabled={this.props.disabled || this.getOptionValue(this.props?.value) === undefined} onClick={e => this.handleEdit()}> <IconEdit/> Editar </DropDownItem>
                    </DropDown>
                </> : <></>
                }
                {super.getFeedback()}
            </InputGroup>
        );
    }
}