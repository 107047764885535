//#NO-CHANGE-FILE#
import Config from "./Config";
import Util from "../../utils/Util";
import React from "react";
import { useNavigate, useLocation, useParams } from "react-router";
import {ThreeDotsVertical as IconThreeDotsVertical} from "react-bootstrap-icons"
import { BaseList } from "../../siht/base";
import { DataGrid, DataGridButtons, DataGridColumn, Panel, Container, Row, InputGroup, InputGroupText, InputGroupButton, DropDown, DropDownLabel, DropDownItem, PanelFooter, FormItem, DataGridFooter } from "../../siht/components";
import { Button, Div, Form, InputSearch, BtnSubmit, BtnButton, Span, InputNumber, InputDate, InputText } from "../../siht/elements";
import { Check2Circle as IconCheck2Circle, Coin as IconCoin, FileEarmarkPdf as IconFileEarmarkPdf, FiletypeXml as IconFiletypeXml, FileZipFill as IconFileZipFill, ListCheck as IconListCheck, SendFill as IconSendFill, SlashCircleFill as IconSlashCircleFill, XCircleFill as IconXCircleFill } from "react-bootstrap-icons";
import { IconByTypeFile, IconClear, IconSearch } from "../../icons";
import { FormItemTipoPessoaPessoa, FormItemEmpresaUnidade } from "../../components/FormItem";
import { Modal } from "../../siht/controller";
import { BtnEdit, StatusCustom } from "../../components/Custom";
import SelectStatusFatura from "../../components/SelectDB/SelectStatusFatura";
import SelectStatusNotaFiscal from "../../components/SelectDB/SelectStatusNotaFiscal";
import SelectTipoNotaFiscal from "../../components/SelectDB/SelectTipoNotaFiscal";
import FormFatura from "../Fatura/FormFatura";
import ConfigApp from "../../ConfigApp";
import ArquivoService from "../../services/ArquivoService";
import ListFaturaNotaFiscalLogByFaturaNotaFiscal from "../FaturaNotaFiscalLog/ListFaturaNotaFiscalLogByFaturaNotaFiscal"
import FormEstornar from "./FormEstornar";


class ListFaturaNotaFiscal extends BaseList {

	constructor(props) {
		super(props);
		this.getItens = this.getItens.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleChangeFilter = this.handleChangeFilter.bind(this);		
		this.handleNew = this.handleNew.bind(this);
		this.handleEdit = this.handleEdit.bind(this);
		this.handleCopy = this.handleCopy.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.handleMore = this.handleMore.bind(this);
		this.onSubmitFilter = this.onSubmitFilter.bind(this);
		this.handleClear = this.handleClear.bind(this);
		this.handleFormSuccess = this.handleFormSuccess.bind(this);
		this.handleFormClose = this.handleFormClose.bind(this);
		
		this.podeEnviar = this.podeEnviar.bind(this);
		this.podeEstornar = this.podeEstornar.bind(this);
		this.podeIgnorar = this.podeIgnorar.bind(this);
		this.podeReativar = this.podeReativar.bind(this);
		this.podeSubstituir = this.podeSubstituir.bind(this);
		
		this.handleEnviarNotaFiscal = this.handleEnviarNotaFiscal.bind(this);
		this.handleEstornarNotaFiscal = this.handleEstornarNotaFiscal.bind(this);
		this.handleIgnorarNotaFiscal = this.handleIgnorarNotaFiscal.bind(this);
		this.handleReativarNotaFiscal = this.handleReativarNotaFiscal.bind(this);
		this.handleSubstituirNotaFiscal = this.handleSubstituirNotaFiscal.bind(this);

		this.handleDownloadArquivo = this.handleDownloadArquivo.bind(this);

		this.handleVerFatura = this.handleVerFatura.bind(this);
		this.getTotal = this.getTotal.bind(this);
	}

	initStateFilter(){

        return {
			unidade : null,
            pessoa : null,
			statusFatura : null,
			statusNotaFiscal : null,
			tipoNotaFiscal : null,
			dtFaturaStart : "",
			dtFaturaEnd : "",
			idFatura : "",
			nrRps : "",
            search: "",
            page : 1,
            pageSize : 200,
        };
    }

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		super.init(Config);
	}

	getItens(more){
		super.getItens(Config.getService().list, false, more);
	}

	handleNew(e) {
		super.handleNew(e, Config);
	}

	handleEdit(e, item) {
		super.handleEdit(e, Config, item);
	}

	handleDelete(e, item) {
		super.handleDelete(e, Config, item);
	}

	handleCopy(e, item) {
		super.handleCopy(e, Config, item);
	}

	getFormEmbedded(){
		return super.getFormEmbedded(Config);
	}

	podeEnviar() {
        if (this.state.selecteds.length > 0) {
			return this.state.selecteds.length === (this.state.selecteds.filter(item => {
				return (
					(item.statusNotaFiscal?.flPodeEnviar && !item.fatura.statusFatura?.flEstornada)
				)
			}).length);
        }

        return false;
    }

	podeEstornar() {
        if (this.state.selecteds.length > 0) {
			return this.state.selecteds.length === (this.state.selecteds.filter(item => {
				return (
					(item.statusNotaFiscal?.flPodeEstornar)					
				)
			}).length);
        }

        return false;
    }

	podeIgnorar() {
        if (this.state.selecteds.length > 0) {
			return this.state.selecteds.length === (this.state.selecteds.filter(item => {
				return (
					(item.statusNotaFiscal?.flPodeIgnorar)					
				)
			}).length);
        }

        return false;
    }

	podeReativar() {
        if (this.state.selecteds.length > 0) {
			return this.state.selecteds.length === (this.state.selecteds.filter(item => {
				return (
					(item.statusNotaFiscal?.flIgnorada)					
				)
			}).length);
        }

        return false;
    }

	podeSubstituir() {
        if (this.state.selecteds.length > 0) {
			return this.state.selecteds.length === (this.state.selecteds.filter(item => {
				return (
					(item.statusNotaFiscal?.flPodeEstornar && item.flTemSubstituicao)					
				)
			}).length);
        }

        return false;
    }

	handleEnviarNotaFiscal(e) {
		Modal.confirm("Confirmação", "Deseja realmente ENVIAR a(s) nota(s) selecionada(s)?" , "Essa é uma ação irreversível", Modal.style.warning, () =>{
			Config.getService().enviar(this.state.selecteds, () =>{
				this.getItens(false);
			});	
		});        
	}

	handleEstornarNotaFiscal(e) {
		Modal.openWindow(FormEstornar, {sizing : Modal.sizing.default}, { faturaNotaFiscais : this.state.selecteds , flSubstituir : false}, (e, response) => () => this.getItens(false), () => this.getItens(false));
	}

	handleIgnorarNotaFiscal(e) {
		Modal.confirm("Confirmação", "Deseja realmente IGNORAR a(s) nota(s) selecionada(s)?" , null, Modal.style.warning, () =>{
			Config.getService().ignorar(this.state.selecteds, () =>{
				this.getItens(false);
			});
		});
	}

	handleReativarNotaFiscal(e) {
		Modal.confirm("Confirmação", "Deseja realmente REATIVAR a(s) nota(s) selecionada(s)?" , null, Modal.style.primary, () =>{
			Config.getService().reativar(this.state.selecteds, () =>{
				this.getItens(false);
			});
		});
	}

	handleSubstituirNotaFiscal(e) {
		Modal.openWindow(FormEstornar, {sizing : Modal.sizing.default}, { faturaNotaFiscais : this.state.selecteds , flSubstituir : true}, (e, response) => () => this.getItens(false), () => this.getItens(false));
	}

	handleLogProcessamento(e, item) {
		Modal.openWindow(ListFaturaNotaFiscalLogByFaturaNotaFiscal, {sizing : Modal.sizing.xl, scrollable : true}, { faturaNotaFiscal : item }, (e, response) => { 
			this.getItens(false);
		});
	}

	handleVerFatura(e, item) {
		Modal.openWindow(FormFatura, {sizing : Modal.sizing.xl}, { id : item.fatura.idFatura }, (e, response) => { 
			this.getItens();
		});
	}

	handleDownloadArquivo(cdTipoArquivoNotaFiscal = null){
		let arquivos = [];

		this.state.selecteds.forEach(faturaNotaFiscal => {
			if(cdTipoArquivoNotaFiscal === null){
				faturaNotaFiscal.faturaNotaFiscalArquivos.forEach(faturaNotaFiscalArquivo => {
					arquivos.push(faturaNotaFiscalArquivo.arquivo);
				});
			}else{
				faturaNotaFiscal.faturaNotaFiscalArquivos.filter(faturaNotaFiscalArquivo => faturaNotaFiscalArquivo.tipoArquivoNotaFiscal.cdTipoArquivoNotaFiscal.toString() === cdTipoArquivoNotaFiscal.toString()).forEach(faturaNotaFiscalArquivo => {
					arquivos.push(faturaNotaFiscalArquivo.arquivo);
				});
			}
		});

		ArquivoService.zip({
			arquivos : arquivos
		});
	}

	getTotal(prop){
		let total = 0;
		if(this.state.selecteds.length === 0){
			total = this.state.data.reduce((soma, item) => soma + item[prop], 0);
		}else{
			total = this.state.selecteds.reduce((soma, item) => soma + item[prop], 0);
		}

		return total;
	}

	render() {
		return super.render(
			<Panel title="Lista de Nota Fiscal(s)" isModal={this.isModal()} container={{sizing: Container.sizing.fluid, className : ""}}>
				<Div className="d-flex justify-content-end flex-nowrap gap-2 mb-2">
					<DropDown alignment={DropDown.alignment.end} >
						<DropDownLabel disabled={this.state.selecteds.length === 0} sizing={Button.sizing.default} toggle={false}> <IconListCheck/> Com os Selecionados <IconThreeDotsVertical/> </DropDownLabel>
						<DropDownItem show={Config.getPermissaoFaturaNotaFiscal().flPodeEnviar} disabled={!this.podeEnviar()} onClick={e => this.handleEnviarNotaFiscal(e, null)} type="button" title="Enviar"> <IconSendFill/> Enviar </DropDownItem>
						<DropDownItem show={Config.getPermissaoFaturaNotaFiscal().flPodeEstornar} disabled={!this.podeEstornar()} onClick={e => this.handleEstornarNotaFiscal(e, null)} type="button" title="Cancelar"> <IconXCircleFill/> Cancelar </DropDownItem>
						<DropDownItem show={Config.getPermissaoFaturaNotaFiscal().flPodeIgnorar} disabled={!this.podeIgnorar()} onClick={e => this.handleIgnorarNotaFiscal(e, null)} type="button" title="Ignorar"> <IconSlashCircleFill/> Ignorar </DropDownItem>
						<DropDownItem show={Config.getPermissaoFaturaNotaFiscal().flPodeReativar} disabled={!this.podeReativar()} onClick={e => this.handleReativarNotaFiscal(e, null)} type="button" title="Reativar"> <IconCheck2Circle/> Reativar </DropDownItem>

						<DropDownItem show={Config.getPermissaoFaturaNotaFiscal().flPodeSubstituir} disabled={!this.podeSubstituir()} onClick={e => this.handleSubstituirNotaFiscal(e, null)} type="button" title="Substituir"> <IconXCircleFill/> Substituir </DropDownItem>
						
						<DropDownItem title="Baixar Todos Arquivos" type="button" onClick={e => this.handleDownloadArquivo()}> <IconFileZipFill/> Baixar Todos Arquivos</DropDownItem>
						<DropDownItem title="Baixar XML" type="button" onClick={e => this.handleDownloadArquivo("XML")}> <IconFiletypeXml/> Baixar XML da Nota Fiscal</DropDownItem>
						<DropDownItem title="Imprimir Nota Fiscal" type="button" onClick={e => this.handleDownloadArquivo("PDF")}> <IconFileEarmarkPdf/> Baixar PDF da Nota Fiscal </DropDownItem>
						<DropDownItem title="Imprimir RPS" type="button" onClick={e => this.handleDownloadArquivo("RPS")}> <IconFileEarmarkPdf/> Baixar RPS da Nota Fiscal </DropDownItem>
					</DropDown>
				</Div>

				<Form onSubmit={this.onSubmitFilter}>
					<Row className="g-3">
                        <FormItem md={1} label="Fatura">
                            <InputNumber name="idFatura" value={this.state.filter.idFatura} opcional={true} required={false} onChange={this.handleChangeFilter}/>
                        </FormItem>
                        <FormItem md={1} label="RPS">
                            <InputText name="nrRps" value={this.state.filter.nrRps} opcional={true} required={false} onChange={this.handleChangeFilter}/>
                        </FormItem>
						<FormItem md={1} label="Nota">
                            <InputText name="nrNota" value={this.state.filter.nrNota} opcional={true} required={false} onChange={this.handleChangeFilter}/>
                        </FormItem>
                        <FormItem md={2} label="Data Inicial">
                            <InputDate name="dtFaturaStart" value={this.state.filter.dtFaturaStart} required={false} opcional={true} onChange={this.handleChangeFilter}/>
                        </FormItem>
                        <FormItem md={2} label="Data Final">
                            <InputDate name="dtFaturaEnd" min={this.state.filter.dtFaturaStart} value={this.state.filter.dtFaturaEnd} required={false} opcional={true} onChange={this.handleChangeFilter}/>
                        </FormItem>
                        <FormItem md={2} label="Status Fatura">
                            <SelectStatusFatura name="statusFatura" value={this.state.filter.statusFatura} required={false} opcional={true} onChange={this.handleChangeFilter} placeholder="Selecione o Status" buttons={{search : false, new : false, edit: false}}/>
                        </FormItem>
						<FormItem md={3} label="Status Nota">
                            <SelectStatusNotaFiscal name="statusNotaFiscal" value={this.state.filter.statusNotaFiscal} required={false} opcional={true} onChange={this.handleChangeFilter} placeholder="Selecione o Status" buttons={{search : false, new : false, edit: false}}/>
                        </FormItem>
						<FormItem md={2} label="Tipo NotaFiscal">
                            <SelectTipoNotaFiscal name="tipoNotaFiscal" value={this.state.filter.tipoNotaFiscal} required={false} opcional={true} onChange={this.handleChangeFilter} placeholder="Selecione o Status" buttons={{search : false, new : false, edit: false}}/>
                        </FormItem>

                        <FormItemEmpresaUnidade md={[2,3]} name="unidade" opcional={true} value={this.state.filter.unidade} required={false} onChange={this.handleChangeFilter} buttons={{search : false, new : false, edit: false}}/>
                        <FormItemTipoPessoaPessoa label={["Tipo Cliente", "Cliente"]} md={[2,3]} name="pessoa" value={this.state.filter.pessoa} required={false} onChange={this.handleChangeFilter} opcional={true}/>
                    </Row>
					<Row className="mt-1 mb-2 g-3">
						<InputGroup className="flex-nowrap" sizing={InputGroup.sizing.default}>
							<InputGroupText><IconSearch/></InputGroupText>
							<InputSearch autoComplete="off" autoFocus placeholder="Digite sua busca" name="search" value={this.state.filter.search} required={false} opcional={true} onChange={(e,v,n) => this.handleChange(e,v,n, this.state.filter)}/>
							<InputGroupButton>
								<BtnSubmit title="Filtrar"> Buscar </BtnSubmit>
								<BtnButton title="Limpar Pesquisa" color={BtnButton.color.outlineDanger} onClick={this.handleClear}> <IconClear/> </BtnButton>
							</InputGroupButton>
						</InputGroup>
					</Row>
				</Form>

				<DataGrid onDoubleClickRow={this.handleEdit} responsive={true} data={this.state.data} search={false} isDisabled={i => false} sizing={DataGrid.sizing.sm} select={true} selecteds={this.state.selecteds} onSelect={(selecteds)=>{this.setState({selecteds : selecteds})}} getItemValue={i=>i.idFaturaNotaFiscal} onMore={this.handleMore}>
					<DataGridColumn label="Código" field={i => i.idFaturaNotaFiscal} className="text-center"/>

					<DataGridColumn mobile={false} md={2}  label="Fatura" field={i => <>
						<Div className="text-nowrap">{i.fatura?.idFatura} - {Util.date2Br(i.fatura.dhInsert)}</Div>
						<StatusCustom className="w-100" color={i.fatura.statusFatura}> {i.fatura.statusFatura?.nmStatusFatura} </StatusCustom>
					</>} className="text-center"/>

					<DataGridColumn md={3} label="Cliente" field={i => <>
                        <Span className="fw-bold">{i.fatura.pessoa?.nmPessoa}</Span> <br/>
                        <StatusCustom color={i.fatura.pessoa?.tipoPessoa}> PESSOA {i.fatura.pessoa?.tipoPessoa?.nmTipoPessoa} </StatusCustom>
                        <Div className="fw-bold fs-7" show={i.fatura.pessoa?.tipoPessoa?.cdTipoPessoa === "F"}>{Util.formataCPF(i.fatura.pessoa?.nrCpf)}</Div>
                        <Div className="fw-bold fs-7" show={i.fatura.pessoa?.tipoPessoa?.cdTipoPessoa === "J"}>{Util.formataCnpj(i.fatura.pessoa?.nrCnpj)}</Div>
						<Div show={i.cdIdentificadorExterno} className="fs-7"> Ident. Ext: <Span className="fw-bold fs-7">{i.cdIdentificadorExterno}</Span></Div>
						<Div show={i.cdPedidoExterno} className="fs-7"> Pedido. Ext: <Span className="fw-bold fs-7">{i.cdPedidoExterno}</Span></Div>
                        </>}
                    className="text-start"/>

					<DataGridColumn mobile={false} md={3} label="Empresa / Unidade" field={i => <>
                            <Span className="fw-bold">{i.fatura.unidade?.empresa?.nmEmpresa}</Span> <br/>
                            {i.fatura.unidade?.nmUnidade}
                        </>} className="text-start"/>

					<DataGridColumn mobile={false} label="Valor" field={i => <Span className="fw-bold text-primary"> {Util.floatToReais(i.vlNotaFiscal)} </Span>} className="text-end"/>
					<DataGridColumn col={1} label="Tipo" field={i => <>
						<StatusCustom className="w-100" color={i.tipoNotaFiscal}> {i.tipoNotaFiscal?.nmTipoNotaFiscal} </StatusCustom>
						<Div> {i.plugin.nmPlugin} </Div>
					</>} className="text-center"/>
					<DataGridColumn col={1} label="Status NF" field={i => <>
						<StatusCustom className="w-100" color={i.statusNotaFiscal}> {i.statusNotaFiscal?.nmStatusNotaFiscal} </StatusCustom>
						{i.flSubstituir ? <> Com Subsituição </> : <></>}
						{i.flSubstituicao ? <> Subsituição da Nota </> : <></>}
					</>} className="text-center"/>
					<DataGridColumn label="Série" field={i => i.nrSerie} className="text-center"/>
					<DataGridColumn label="RPS" field={i => i.nrRps } className="text-center"/>
					<DataGridColumn label="Nota" field={i => i.nrNota } className="text-center"/>

					<DataGridColumn col={2} label="Arquivos" field={i => <>
						{i.faturaNotaFiscalArquivos?.map((faturaNotaFiscalArquivo, index) => (
							<Div className="pb-1" key={index}>
								<a className="link-dark link-underline-opacity-0 link" rel="opener" href={ConfigApp.getUrlArquivo() + "public/download/" + faturaNotaFiscalArquivo?.arquivo?.cdUid +  "/" + faturaNotaFiscalArquivo?.arquivo?.arquivoBase?.cdHash}>
									<IconByTypeFile width={24} height={24} type={faturaNotaFiscalArquivo.arquivo.arquivoBase.cdTipo}/> {faturaNotaFiscalArquivo.arquivo.nmArquivo}
								</a>
							</Div>
						))}
					</>} className="text-start"/>

					<DataGridButtons>
						<BtnEdit sizing={Button.sizing.default} onClick={this.handleEdit}/>
						<DropDown alignment={DropDown.alignment.end}>
							<DropDownLabel sizing={Button.sizing.default} toggle={false}> <IconThreeDotsVertical/> </DropDownLabel>
							<DropDownItem title="Ver Fatura" type="button" onClick={this.handleVerFatura}> <IconCoin/> Ver Fatura </DropDownItem>
							<DropDownItem title="Log de Processamento" type="button" onClick={this.handleLogProcessamento}> <IconListCheck/> Log de Processamento</DropDownItem>
							<DropDownItem title="PDF da Nota Fiscal" type="button" onClick={this.handleXML}> <IconFileEarmarkPdf/> PDF da Nota Fiscal </DropDownItem>
							<DropDownItem title="RPS da Nota Fiscal" type="button" onClick={this.handleXML}> <IconFileEarmarkPdf/> RPS da Nota Fiscal </DropDownItem>
							<DropDownItem title="XML da Nota Fiscal" type="button" onClick={this.handleXML}> <IconFiletypeXml/> XML da Nota Fiscal </DropDownItem>							
						</DropDown>
					</DataGridButtons>

					<DataGridFooter>
						<DataGridColumn colSpan={5} className="text-center"/>
						<DataGridColumn className="text-end">
							<Span className="fw-bold text-primary"> {Util.floatToReais(this.getTotal("vlNotaFiscal"))} </Span>
						</DataGridColumn>
						<DataGridColumn colSpan={7} className="text-center"/>
					</DataGridFooter>
					
				</DataGrid>

				<PanelFooter className="text-center py-2" show={this.state.data && this.state.data?.length > 0}>
					<BtnButton onClick={this.handleMore} disabled={!this.state.hasMore}> Buscar Mais Itens </BtnButton>
				</PanelFooter>
			</Panel>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <ListFaturaNotaFiscal {...props} navigate={navigate} params={params} location={location}/>
}
export default With