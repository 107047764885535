//#NO-CHANGE-FILE#

import React from "react";
import { useNavigate, useLocation, useParams } from "react-router";
import BtnDelete from "../../components/BtnDelete";
import Config from "./Config";
import { BaseList } from "../../siht/base";
import { DataGrid, DataGridButtons, DataGridColumn } from "../../siht/components";
import { Button, Div, Span } from "../../siht/elements";
import Util from "../../utils/Util";
import { StatusCustom } from "../../components/Custom";

class ListContaReceberBaixa extends BaseList {

	constructor(props) {
		super(props);
		this.getItens = this.getItens.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleNew = this.handleNew.bind(this);
		this.handleEdit = this.handleEdit.bind(this);
		this.handleCopy = this.handleCopy.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.handleMore = this.handleMore.bind(this);
		this.onSubmitFilter = this.onSubmitFilter.bind(this);
		this.handleClear = this.handleClear.bind(this);
		this.handleFormSuccess = this.handleFormSuccess.bind(this);
		this.handleFormClose = this.handleFormClose.bind(this);
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		super.init(Config);
	}

	getItens(more){
		if(this.props?.parent){
			super.getItens(Config.getService().getAllByContaReceberParcela, this.props.parent, more);
		}
	}

	handleNew(e) {
		super.handleNew(e, Config);
	}

	handleEdit(e, item) {
		super.handleEdit(e, Config, item);
	}

	handleDelete(e, item) {
		super.handleDelete(e, Config, item);
	}

	handleCopy(e, item) {
		super.handleCopy(e, Config, item);
	}

	getFormEmbedded(){
		return super.getFormEmbedded(Config);
	}

	render() {
		return super.render(
			<Div className="p-2 mt-0">
				<DataGrid caption={false} responsive={true} data={this.state.data} search={false} isDisabled={i => false} sizing={DataGrid.sizing.sm} select={false} selecteds={this.state.selecteds} onSelect={(selecteds)=>{this.setState({selecteds : selecteds})}} getItemValue={i=>i.idContaReceberBaixa} onMore={this.handleMore}>
					<DataGridColumn label="Código" field={i => i.idContaReceberBaixa} className="text-center"/>
					<DataGridColumn col={4} label="Tipo Baixa" field={i => 
						<React.Fragment>										
							<StatusCustom color={i.tipoBaixa}> {i.tipoBaixa?.nmTipoBaixa} </StatusCustom>
							<Div className="fw-bold fs-7"> {i.usuario?.nmUsuario} </Div>
							<Div className="text-secondary fs-7"> {Util.date2Br(i.dhInsert, true)} </Div>
						</React.Fragment>} 
					className="text-start"/>

					<DataGridColumn col={4} label="Conta" field={i => i.conta?.nmConta} className="text-start"/>

					<DataGridColumn col={3} label="Baixa" field={i => <>
						<Div className={`fs-6`}> Baixa: <Span className={`fw-bold ${i.vlBaixa === 0 ? "text-secondary" : "text-success"}`}>{Util.floatToReais(i.vlBaixa)}</Span>  </Div>
						<Div className={`fs-6`}> Multa: <Span className={`fw-bold ${i.vlMulta === 0 ? "text-secondary" : "text-success"}`}>{Util.floatToReais(i.vlMulta)}</Span>  </Div>
						<Div className={`fs-6`}> Juros: <Span className={`fw-bold ${i.vlJuros === 0 ? "text-secondary" : "text-success"}`}>{Util.floatToReais(i.vlJuros)}</Span>  </Div>
						<Div className={`fs-6`}> Taxas: <Span className={`fw-bold ${i.vlTaxa === 0 ? "text-secondary" : "text-success"}`}>{Util.floatToReais(i.vlTaxa)}</Span>  </Div>
					</>} className="text-end"/>

					<DataGridColumn col={1} label="Data" field={i => Util.date2Br(i.dtBaixa)} className="text-center"/>
						
					<DataGridButtons>
						<BtnDelete sizing={Button.sizing.default} onClick={this.handleDelete}/>
					</DataGridButtons>
				</DataGrid>
			</Div>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <ListContaReceberBaixa {...props} navigate={navigate} params={params} location={location}/>
}
export default With