//#NO-CHANGE-FILE#
import Config from "./Config";
import Util from "../../utils/Util";
import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { IconEdit, IconCopy, IconSearch, IconClear, IconConstructorHatHelmetProtectionWorker, IconHandHoldingUsd } from "../../icons";
import { BaseList } from "../../siht/base";
import { DataGrid, DataGridButtons, DataGridColumn, Panel, Container, Row, InputGroup, InputGroupText, InputGroupButton, DropDown, DropDownLabel, DropDownItem, PanelFooter, FormItem, DataGridFooter, BtnRadioGroup, Col, DropDownDivider, FormItemLabelDropDown } from "../../siht/components";
import { Button, Div, Form, InputSearch, BtnSubmit, BtnButton, InputDate, Span, InputText, InputNumber, Select, BtnRadio, Option, SelectMultipleDropDown } from "../../siht/elements";
import { Modal } from "../../siht/controller";
import { ThreeDotsVertical as IconThreeDotsVertical, ListCheck as IconListCheck, NodePlusFill as IconNodePlusFill, PersonFill as IconPersonFill, PrinterFill as IconPrinterFill, Recycle as IconRecycle, SortAlphaDown as IconSortAlphaDown, SortAlphaDownAlt as IconSortAlphaDownAlt, Coin as IconCoin, Crosshair2 as IconCrosshair2, PersonCheck as IconPersonCheck, PersonSlash as IconPersonSlash, Ban as IconBan } from "react-bootstrap-icons";
import { FormItemEmpresaUnidade, FormItemTipoOrdemStatusOrdemMultiple, FormItemTipoPessoaPessoa } from "../../components/FormItem";
import { toast } from "react-toastify";
import { BtnEdit, BtnNew, StatusCustom } from "../../components/Custom";
import FormAlterarStatus from "./FormAlterarStatus";
import FormAlterarPessoa from "./FormAlterarPessoa";
import FormRelatorioOrdem001 from "../Relatorio/FormRelatorioOrdem001";
import FormRelatorioOrdem002 from "../Relatorio/FormRelatorioOrdem002";
import { Tooltip } from "react-tooltip";


class ListOrdem extends BaseList {

	constructor(props) {
		super(props);
		this.getItens = this.getItens.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleChangeFilter = this.handleChangeFilter.bind(this);
		this.handleNew = this.handleNew.bind(this);
		this.handleEdit = this.handleEdit.bind(this);
		this.handleDuplicar = this.handleDuplicar.bind(this);
		this.handleMore = this.handleMore.bind(this);
		this.onSubmitFilter = this.onSubmitFilter.bind(this);
		this.handleClear = this.handleClear.bind(this);
		this.handleFormSuccess = this.handleFormSuccess.bind(this);
		this.handleFormClose = this.handleFormClose.bind(this);

		this.podeFaturarItemSelecionados = this.podeFaturarItemSelecionados.bind(this);

		this.handleFaturar = this.handleFaturar.bind(this);
		this.handleImprimir001 = this.handleImprimir001.bind(this);
		this.handleCancelar = this.handleCancelar.bind(this);
		this.handleReabrir = this.handleReabrir.bind(this);
		this.handleAlterarStatus = this.handleAlterarStatus.bind(this);
		this.handleAlterarPessoa = this.handleAlterarPessoa.bind(this);
		this.handleVerFatura = this.handleVerFatura.bind(this);
		this.handleMarcarComissao = this.handleMarcarComissao.bind(this);
		this.handleDesmarcarComissao = this.handleDesmarcarComissao.bind(this);

		this.state.flPodeFaturarAgrupado = true; //TODO: Buscar essa configuração do sistema

		this.cache = {
			active: true,
			id: "cache-ListOrdem",
			state: {
				filter: true
			},
			clear: true
		};

		var tzoffset = (new Date()).getTimezoneOffset() * 60000;
		var dtHoje = new Date();
		this.dtHoje = new Date(dtHoje - tzoffset).toISOString().slice(0, 10);
	}

	initStateFilter() {
		return {
			idOrdem: "",
			flIgnorarFaturada: false,
			flIgnorarCancelada: false,
			complementar: [],
			cdIdentificadorExterno: "",
			statusOrdems: null,

			dtStart: "",
			dtEnd: "",
			unidade: null,
			pessoa: null,
			search: "",

			cdOrdenacao: "STATUS_ORDEM.NR_ORDEM",
			cdTipoOrdenacao: "ASC",
			page: 1,
			pageSize: 50,

			cdData: null,
			cdCodigo: null,
		};
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init() {
		super.init(Config);
	}

	getItens(more) {
		super.getItens(Config.getService().list, false, more);
	}

	handleNew(e) {
		super.handleNew(e, Config);
	}

	handleEdit(e, item) {
		super.handleEdit(e, Config, item);
	}

	handleDuplicar(e, item) {
		Config.getService().duplicar(item, (response) => {
			toast.success("Ordem foi duplicada, nova ordem: " + response.idOrdem);
			this.props.navigate(Config.getUrlForm() + '/' + response.idOrdem);
		});
	}

	handleFaturar(e, item = null) {

		let ordens = item !== null ? [item] : this.state.selecteds;
		let pessoa = ordens[0].pessoa;
		let unidade = ordens[0].unidade;

		if (ordens.length === 1) {
			this.props.navigate('/CadFaturar/' + ordens[0].idOrdem);
			return;
		} else {
			this.props.navigate('/CadFaturar', { state: { ordens: ordens, pessoa: pessoa, unidade: unidade } });
			return
		}
	}

	handleCancelar(e, ordem) {
		Modal.confirm("Confirmação", "Deseja realmente CANCELAR essa ordem?", null, Modal.style.danger, () => {
			Config.getService().cancelar(ordem, () => {
				this.getItens();
			});
		});
	}

	handleReabrir(e, ordem) {
		Modal.confirm("Confirmação", "Deseja realmente REABRIR essa ordem?", null, Modal.style.warning, () => {
			Config.getService().reabrir(ordem, () => {
				this.getItens();
			});
		});
	}

	handleAlterarStatus(e, ordem) {
		if (ordem === null) {
			Modal.openWindow(FormAlterarStatus, { sizing: Modal.sizing.default }, { ordem: this.state.selecteds }, (e, response) => {
				this.getItens();
			});
		} else {
			Modal.openWindow(FormAlterarStatus, { sizing: Modal.sizing.default }, { ordem: ordem }, (e, response) => {
				this.getItens();
			});
		}
	}

	handleAlterarPessoa(e, ordem) {
		if (ordem === null) {
			Modal.openWindow(FormAlterarPessoa, { sizing: Modal.sizing.default }, { ordem: this.state.selecteds }, (e, response) => {
				this.getItens();
			});
		} else {
			Modal.openWindow(FormAlterarPessoa, { sizing: Modal.sizing.default }, { ordem: [ordem] }, (e, response) => {
				this.getItens();
			});
		}
	}

	handleVerFatura(e, item = null) {
		this.props.navigate('/CadFatura/' + item?.fatura?.idFatura);
	}

	handleComissao(e, item = null) {
		let ordens = item !== null ? [item] : this.state.selecteds;
		this.props.navigate('/CadComissao', { state: { ordens: ordens } });
		return;
	}

	handleMarcarComissao(e) {
		Modal.confirm("Confirmação", "Deseja realmente MARCAR para Pagar Comissão?", null, Modal.style.warning, () => {
			Config.getService().marcarComissao(this.state.selecteds, () => {
				toast.success("Ordem(ns) marcada(s) para Pagar Comissão!");
				this.getItens();
			});
		});
	}

	handleDesmarcarComissao(e) {
		Modal.confirm("Confirmação", "Deseja realmente DESMARCAR para Pagar Comissão?", null, Modal.style.warning, () => {
			Config.getService().desmarcarComissao(this.state.selecteds, () => {
				toast.success("Ordem(ns) desmarcada(s) para Pagar Comissão!");
				this.getItens();
			});
		});
	}

	getFormEmbedded() {
		return super.getFormEmbedded(Config);
	}

	podeFaturarItemSelecionados() {

		if (!this.state.flPodeFaturarAgrupado && this.state.selecteds.length > 1) {
			return false;
		}

		if (this.state.selecteds.length > 0) {

			var ordem = this.state.selecteds[0];

			return this.state.selecteds.length === (this.state.selecteds.filter(item => {
				return (
					(item.statusOrdem?.flPodeFaturar)
					&& parseInt(item?.unidade?.idUnidade) === parseInt(ordem?.unidade?.idUnidade)
					&& parseInt(item?.pessoa?.idPessoa) === parseInt(ordem?.pessoa?.idPessoa)
				)
			}).length);
		}
		return false;
	}

	podeAlterarStatusSelecionados() {

		var ordem = this.state.selecteds[0];

		if (this.state.selecteds.length > 0) {
			return this.state.selecteds.length === (this.state.selecteds.filter(item => {
				return item.statusOrdem?.flPodeAlterarStatus && parseInt(item?.tipoOrdem?.idTipoOrdem) === parseInt(ordem?.tipoOrdem?.idTipoOrdem);
			}).length);
		}

		return false;
	}

	podeAlterarPessoaSelecionados() {

		if (this.state.selecteds.length > 0) {
			return this.state.selecteds.length === (this.state.selecteds.filter(item => {
				return item.statusOrdem?.flPodeEditar;
			}).length);
		}

		return false;
	}

	podePagarComissaoItemSelecionados() {

		if (this.state.selecteds.length > 0) {
			return this.state.selecteds.length === (this.state.selecteds.filter(item => {
				return item?.flPagarComissao && item.statusOrdem?.flPodePagarComissao;
			}).length);
		}
		return false;
	}

	handleImprimir001(e, ordem = null) {
		if (ordem) {
			Modal.openWindow(FormRelatorioOrdem001, { sizing: Modal.sizing.default }, { idOrdens: [ordem.idOrdem] });
		} else {
			let idOrdens = this.state.selecteds.flatMap((o) => (o.idOrdem));
			Modal.openWindow(FormRelatorioOrdem001, { sizing: Modal.sizing.default }, { idOrdens: idOrdens });
		}
	}

	handleImprimir002(e, ordem = null) {
		if (ordem) {
			Modal.openWindow(FormRelatorioOrdem002, { sizing: Modal.sizing.default }, { idOrdens: [ordem.idOrdem] });
		} else {
			let idOrdens = this.state.selecteds.flatMap((o) => (o.idOrdem));
			Modal.openWindow(FormRelatorioOrdem002, { sizing: Modal.sizing.default }, { idOrdens: idOrdens });
		}
	}

	getTotalOrdem() {
		let total = 0;
		if (this.state.selecteds.length === 0) {
			total = this.state.data.reduce((soma, item) => soma + item.vlFinalOrdem, 0);
		} else {
			total = this.state.selecteds.reduce((soma, item) => soma + item.vlFinalOrdem, 0);
		}

		return total;
	}

	render() {

		return super.render(
			<Panel title="Lista de Ordem(s)" isModal={this.isModal()} container={{ sizing: Container.sizing.fluid, className: "" }} key={this.state.key}>
				<Div className="d-flex justify-content-end flex-nowrap gap-2 pb-3">
					<BtnNew onClick={this.handleNew}> Nova Ordem </BtnNew>
					<DropDown alignment={DropDown.alignment.end} >
						<DropDownLabel disabled={this.state.selecteds.length === 0} sizing={Button.sizing.default} toggle={false}> <IconListCheck /> Com os Selecionados <IconThreeDotsVertical /> </DropDownLabel>
						<DropDownItem show={Config.getPermissaoOrdem().flPodeFaturar} disabled={this.state.selecteds.length === 0 || !this.podeFaturarItemSelecionados()} sizing={Button.sizing.default} onClick={e => this.handleFaturar(e, null)}> <IconCoin /> Faturar </DropDownItem>
						<DropDownItem show={Config.getPermissaoOrdem().flPodeAlterarPessoa} disabled={this.state.selecteds.length === 0 || !this.podeAlterarPessoaSelecionados()} sizing={Button.sizing.default} onClick={e => this.handleAlterarStatus(e, null)}> <IconNodePlusFill /> Alterar Status </DropDownItem>
						<DropDownItem show={Config.getPermissaoOrdem().flPodeAlterarStatus} disabled={this.state.selecteds.length === 0 || !this.podeAlterarStatusSelecionados()} sizing={Button.sizing.default} onClick={e => this.handleAlterarPessoa(e, null)}> <IconPersonFill /> Alterar Cliente </DropDownItem>
						<DropDownDivider />
						<DropDownItem show={Config.getPermissaoOrdem().flPodeImprimir} disabled={this.state.selecteds.length === 0} sizing={Button.sizing.default} onClick={e => this.handleImprimir001(e, null)}> <IconPrinterFill /> Imprimir </DropDownItem>
						<DropDownItem show={Config.getPermissaoOrdem().flPodeImprimir} disabled={this.state.selecteds.length === 0} sizing={Button.sizing.default} onClick={e => this.handleImprimir002(e, null)}> <IconPrinterFill /> Imprimir Listagem </DropDownItem>
						<DropDownDivider />
						<DropDownItem show={Config.getPermissaoOrdem().flPodePagarComissao} disabled={this.state.selecteds.length === 0 || !this.podePagarComissaoItemSelecionados()} sizing={Button.sizing.default} onClick={e => this.handleComissao(e, null)}> <IconHandHoldingUsd /> Gerar Comissão </DropDownItem>
						<DropDownItem show={Config.getPermissaoOrdem().flPodeMarcarPagarComissao} disabled={this.state.selecteds.length === 0} sizing={Button.sizing.default} onClick={e => this.handleMarcarComissao(e, null)}> <IconPersonCheck className="text-success" /> Marcar para Pagar Comissão </DropDownItem>
						<DropDownItem show={Config.getPermissaoOrdem().flPodeMarcarPagarComissao} disabled={this.state.selecteds.length === 0} sizing={Button.sizing.default} onClick={e => this.handleDesmarcarComissao(e, null)}> <IconPersonSlash className="text-danger" /> Marcar para não Pagar Comissão </DropDownItem>
					</DropDown>
				</Div>

				{this.getFormEmbedded()}

				<Form onSubmit={this.onSubmitFilter}>
					<Row className="g-3">
						<FormItem md={2} label={
							<FormItemLabelDropDown value={this.state.filter.cdCodigo} name="cdCodigo" onChange={this.handleChangeFilter}>
								<Option value="ID_ORDEM" label="Código da Ordem" selected={true} />
								<Option value="CD_IDENTIFICADOR_EXTERNO" label="Identificador Externo" />
								<Option value="CD_PEDIDO_EXTERNO" label="Pedido Externo" />
								<Option value="ID_FATURA" label="Código da Fatura" />
								<Option value="NR_NOTA_FISCAL" label="Número da Nota Fiscal" />
								<Option value="NR_RPS" label="Número do RPS" />
							</FormItemLabelDropDown>
						}>
							{(() => {
								switch (this.state.filter.cdCodigo?.value) {
									case "CD_IDENTIFICADOR_EXTERNO":
									case "CD_PEDIDO_EXTERNO":
										return <InputText name="codigo" value={this.state.filter.codigo} opcional={true} required={false} onChange={this.handleChangeFilter} />
									default: return <InputNumber name="codigo" value={this.state.filter.codigo} opcional={true} required={false} onChange={this.handleChangeFilter} />
								}
							})()}
						</FormItem>

						<FormItemTipoOrdemStatusOrdemMultiple md={[3, 3]} name="statusOrdems" opcional={true} value={this.state.filter.statusOrdems} required={false} onChange={this.handleChangeFilter} buttons={{ search: false, new: false, edit: false }} />

						<FormItem md={2} label={
							<FormItemLabelDropDown value={this.state.filter.cdData} name="cdData" onChange={this.handleChangeFilter} getOptionLabel={i => i?.label !== undefined ? `Data Inicial de ${i.label}` : i?.label}>
								<Option value="CRIACAO_ORDEM" label="Ordem" selected={true} />
								<Option value="CRIACAO_FATURA" label="Faturamento" />
							</FormItemLabelDropDown>
						}>
							<InputDate name="dtStart" max={this.dtHoje} value={this.state.filter.dtStart} required={false} opcional={true} onChange={this.handleChangeFilter} className="text-center" />
						</FormItem>

						<FormItem md={2} label={`Data Final de ${this.state.filter.cdData?.label}`}>
							<InputDate name="dtEnd" min={this.state.filter.dtStart} max={this.dtHoje} value={this.state.filter.dtEnd} required={false} opcional={true} onChange={this.handleChangeFilter} className="text-center" />
						</FormItem>

						<FormItemEmpresaUnidade md={[3, 3]} name="unidade" opcional={true} value={this.state.filter.unidade} required={false} onChange={this.handleChangeFilter} buttons={{ search: false, new: false, edit: false }} />
						<FormItemTipoPessoaPessoa label={["Tipo Cliente", "Cliente"]} md={[2, 4]} name="pessoa" value={this.state.filter.pessoa} required={false} onChange={this.handleChangeFilter} opcional={true} />

						<FormItem md={5} label="Complementar">
							<SelectMultipleDropDown name="complementar" opcional={true} value={this.state.filter.complementar} required={false} onChange={this.handleChangeFilter} buttons={{ search: false, new: false, edit: false }}>

								<Option value="IGNORAR_FATURADA" icon={<IconCoin className="text-dark" />} label="Ignorar Faturadas" />
								<Option value="IGNORAR_CANCELADA" icon={<IconBan className="text-dark" />} label="Ignorar Canceladas" />

								<Option value="PAGA_COMISSAO" icon={<IconPersonCheck className="text-dark" />} label="Marcada para Pagar Comissão" />
								<Option value="NAO_PAGA_COMISSAO" icon={<IconPersonSlash className="text-dark" />} label="Marcada para não Pagar Comissão" />

								<Option value="COM_COMISSAO" icon={<IconHandHoldingUsd className="text-dark" />} label="Com Comissão Paga" />
								<Option value="SEM_COMISSAO" icon={<IconHandHoldingUsd className="text-dark" />} label="Sem Comissão Paga" />

								<Option value="COM_PERFIL" icon={<IconCrosshair2 className="text-dark" />} label="Com Perfil Cadastrado" />
								<Option value="SEM_PERFIL" icon={<IconCrosshair2 className="text-dark" />} label="Sem Perfil Cadastrado" />
							</SelectMultipleDropDown>
						</FormItem>

						<Col sizing={1} />

						<FormItem md={3} label="Ordenar por">
							<InputGroup>
								<Select name="cdOrdenacao" required={true} value={this.state.filter.cdOrdenacao} onChange={(e, v, n) => this.handleChange(e, v, n, this.state.filter)}>
									<Option value="ORDEM.ID_ORDEM" label="Código da Ordem" />
									<Option value="PESSOA.NM_PESSOA" label="Nome do Cliente" />
									<Option value="UNIDADE.NM_UNIDADE" label="Nome da Unidade" />
									<Option value="TIPO_ORDEM.NM_TIPO_ORDEM" label="Nome do Tipo da Ordem" />
									<Option value="STATUS_ORDEM.NR_ORDEM" label="Status da Ordem" />
									<Option value="ORDEM.VL_ORDEM" label="Valor da Ordem" />
								</Select>
								<InputGroupButton>
									<BtnButton onClick={e => this.setState(state => { state.filter.cdTipoOrdenacao = state.filter.cdTipoOrdenacao === "ASC" ? "DESC" : "ASC"; return state; })}>
										{this.state.filter?.cdTipoOrdenacao === "ASC" ? <IconSortAlphaDown /> : <IconSortAlphaDownAlt />}
									</BtnButton>
								</InputGroupButton>
							</InputGroup>
						</FormItem>

						<FormItem md={3} label="Itens por Página">
							<BtnRadioGroup name="pageSize" color={BtnRadioGroup.color.primary} opcional={true} value={this.state.filter.pageSize} onClick={this.handleChangeFilter}>
								<BtnRadio value={50} label="50" />
								<BtnRadio value={100} label="100" />
								<BtnRadio value={500} label="500" />
							</BtnRadioGroup>
						</FormItem>
					</Row>

					<Row className="mt-1 mb-2 g-3">
						<InputGroup className="flex-nowrap" sizing={InputGroup.sizing.default}>
							<InputGroupText><IconSearch /></InputGroupText>
							<InputSearch autoComplete="off" autoFocus placeholder="Digite sua busca" name="search" value={this.state.filter.search} required={false} opcional={true} onChange={(e, v, n) => this.handleChange(e, v, n, this.state.filter)} />
							<InputGroupButton>
								<BtnSubmit title="Filtrar"> Buscar </BtnSubmit>
								<BtnButton title="Limpar Pesquisa" color={BtnButton.color.outlineDanger} onClick={this.handleClear}> <IconClear /> </BtnButton>
							</InputGroupButton>
						</InputGroup>
					</Row>
				</Form>

				<DataGrid onDoubleClickRow={this.handleEdit} responsive={true} data={this.state.data} search={false} isDisabled={i => false} sizing={DataGrid.sizing.sm} select={true} selecteds={this.state.selecteds} onSelect={(selecteds) => { this.setState({ selecteds: selecteds }) }} getItemValue={i => i.idOrdem} onMore={this.handleMore}>
					<DataGridColumn label="#" field={i => i.idOrdem} className="text-center" />

					<DataGridColumn md={2} label="Cliente" field={i => <React.Fragment>
						<Span className="fw-bold">{i.pessoa?.nmPessoa}</Span> <br />
						<StatusCustom color={i.pessoa?.tipoPessoa}> PESSOA {i.pessoa?.tipoPessoa?.nmTipoPessoa} </StatusCustom>
						<Div className="fw-bold fs-7" show={i.pessoa?.tipoPessoa?.cdTipoPessoa === "F"}>{Util.formataCPF(i.pessoa?.nrCpf)}</Div>
						<Div className="fw-bold fs-7" show={i.pessoa?.tipoPessoa?.cdTipoPessoa === "J"}>{Util.formataCnpj(i.pessoa?.nrCnpj)}</Div>
					</React.Fragment>
					}
						className="text-start" />

					<DataGridColumn mobile={false} md={2} label="Empresa / Unidade" field={i => <React.Fragment>
						<Div className="fw-bold">{i.unidade?.empresa?.nmEmpresa}</Div>
						<Div>{i.unidade?.nmUnidade} </Div>
					</React.Fragment>} className="text-start" />

					<DataGridColumn mobile={false} md={3} label="Ordem" field={i =>
						<React.Fragment>
							<StatusCustom className="w-100" color={i.tipoOrdem}> {i.tipoOrdem.nmTipoOrdem} </StatusCustom>

							{/*<Div className="mt-2 p-1 fs-7 bg-success bg-opacity-10 border-bottom border-top border-success rounded">
								<Div className="fw-bold">{i.usuario?.nmUsuario} </Div>
								<Div className="fst-italic fs-8">{Util.date2Br(i?.dhInsert, true)}</Div>
							</Div>*/}

							<Div show={i.cdIdentificadorExterno || i.cdPedidoExterno} className="mt-2 p-1 fs-8 bg-opacity-10 border-bottom border-top border-primary rounded">
								<Div show={i.cdIdentificadorExterno} className="fs-7"> Ident. Ext: <Span className="fw-bold fs-7">{i.cdIdentificadorExterno}</Span></Div>
								<Div show={i.cdPedidoExterno} className="fs-7"> Pedido. Ext: <Span className="fw-bold fs-7">{i.cdPedidoExterno}</Span></Div>
							</Div>

							<Div show={i.ordemEnderecos?.length > 0} className="mt-2 p-1 fs-8 bg-opacity-10 border-bottom border-top border-warning rounded">
								<Span className="fw-bold">ENDEREÇO DE:</Span>
								{i.ordemEnderecos.map((oe, index) => (
									<Div key={index}>
										<Span className="fw-bold">- {oe.tipoEndereco.nmTipoEndereco}</Span>: {oe?.endereco?.enderecoCompleto}
									</Div>
								))}
							</Div>

							<Div show={i.ordemDadoValors?.length > 0} className="mt-2 p-1 fs-8 bg-opacity-10 border-bottom border-top border-success rounded">
								<Span className="fw-bold">COMPLEMENTAR:</Span>
								{i.ordemDadoValors.map((ov, index) => (
									<Div key={index}>
										<Span className="fw-bold">- {ov.dadoValor.dado.nmDado}</Span>: {ov.dadoValor.arquivo ? ov.dadoValor.arquivo.nmArquivo : ov.dadoValor.txValor}
									</Div>
								))}
							</Div>

							<Div className="mt-1 text-center">
								{i.ordemPessoas !== undefined && i.ordemPessoas.map((ordemPessoa, index) => {
									let idHelp = Math.random();
									let vlValor = i.comissaoPessoaOrdems.reduce((soma, cpo) => soma += (parseInt(cpo.pessoa.idPessoa) === parseInt(ordemPessoa.pessoa.idPessoa) ? cpo.vlValor : 0), 0);

									return (
										<React.Fragment key={index}>
											<span className="me-1" data-tooltip-id={`my-tooltip-styles-${idHelp}`}>
												{vlValor > 0 ?
													<IconConstructorHatHelmetProtectionWorker className="text-success" width={32} height={32} /> :
													<IconConstructorHatHelmetProtectionWorker className="text-dark" width={32} height={32} />
												}
											</span>
											<Tooltip className={`${vlValor > 0 ? "text-bg-success" : "text-bg-dark"}`} id={`my-tooltip-styles-${idHelp}`} style={{ zIndex: 99999999999999 }}>
												<div className="fw-bold" style={{ maxWidth: `300px`, textAlign: "center" }}>
													{ordemPessoa.pessoa.nmPessoa}
												</div>
												<div style={{ maxWidth: `300px`, textAlign: "center" }}>
													{ordemPessoa?.cargo?.nmCargo}
												</div>
												{vlValor > 0 ?
													<div className="fw-bold" style={{ maxWidth: `300px`, textAlign: "center" }}>
														{Util.floatToReais(vlValor)}
													</div>
													: <></>}
											</Tooltip>
										</React.Fragment>
									);
								})}
							</Div>

							<Div className="mt-1 text-center">
								{i.ansPerfils !== undefined && i.ansPerfils.map((ansPerfil, index) => {
									return (
										<React.Fragment key={index}>
											<span className="me-1">
												<IconCrosshair2 className="text-dark" width={28} height={28} />
											</span>
										</React.Fragment>
									);
								})}
							</Div>
						</React.Fragment>
					} className="text-start" />

					<DataGridColumn mobile={false} md={2} label="Usuário" field={i =>
						<React.Fragment>
							{i.usuario?.nmUsuario} <br />
							<Span className="fst-italic fs-7">{Util.date2Br(i?.dhInsert, true)}</Span>
						</React.Fragment>} className="text-start" />

					<DataGridColumn label="" field={i => i.flPagarComissao ? <IconHandHoldingUsd width={24} height={24} className="text-success" /> : <IconHandHoldingUsd width={24} height={24} className="text-secondary text-opacity-25" />} className="text-center" />

					<DataGridColumn mobile={false} md={1} label="Valor" field={i => <React.Fragment>
						{i.vlFinalOrdem !== null ?
							<Span className="fw-bold text-success"> {Util.floatToReais(i.vlFinalOrdem)} </Span> :
							<Span className="fw-bold text-danger fs-7"> OCULTO </Span>
						}
					</React.Fragment>} className="text-end" />

					<DataGridColumn md={2} label="Status" field={i =>
						<React.Fragment>
							<StatusCustom className="w-100" color={i.statusOrdem}> {i.statusOrdem?.nmStatusOrdem} </StatusCustom>
						</React.Fragment>
					} className="text-center" />

					<DataGridButtons>
						<BtnEdit sizing={Button.sizing.default} onClick={this.handleEdit} />

						<DropDown alignment={DropDown.alignment.end}>
							<DropDownLabel sizing={Button.sizing.default} toggle={false}> <IconThreeDotsVertical /> </DropDownLabel>
							<DropDownItem title="Editar" type="button" onClick={this.handleEdit}> <IconEdit /> Editar Ordem </DropDownItem>
							<DropDownItem title="Duplicar" type="button" onClick={this.handleDuplicar} show={ordem => Config.getPermissaoOrdem(ordem).flPodeDuplicar}> <IconCopy /> Duplicar Ordem </DropDownItem>
							<DropDownItem title="Imprimir" type="button" onClick={this.handleImprimir001} show={ordem => Config.getPermissaoOrdem(ordem).flPodeImprimir}> <IconPrinterFill /> Imprimir Ordem </DropDownItem>
							<DropDownItem title="Faturar" type="button" onClick={this.handleFaturar} disabled={ordem => !ordem.statusOrdem?.flPodeFaturar} show={ordem => Config.getPermissaoOrdem(ordem).flPodeFaturar}> <IconCoin /> Faturar Ordem </DropDownItem>
							<DropDownItem title="Cancelar" type="button" onClick={this.handleCancelar} disabled={ordem => !ordem.statusOrdem?.flPodeCancelar} show={ordem => !ordem.statusOrdem?.flPodeReabrir && Config.getPermissaoOrdem(ordem).flPodeCancelar}> <IconClear /> Cancelar Ordem </DropDownItem>
							<DropDownItem title="Reabrir" type="button" onClick={this.handleReabrir} disabled={ordem => !ordem.statusOrdem?.flPodeReabrir} show={ordem => ordem.statusOrdem?.flPodeReabrir && Config.getPermissaoOrdem(ordem).flPodeReabrir}> <IconRecycle /> Reabrir Ordem </DropDownItem>
							<DropDownItem title="Alterar Status" type="button" onClick={this.handleAlterarStatus} disabled={ordem => !ordem.statusOrdem?.flPodeAlterarStatus} show={ordem => Config.getPermissaoOrdem(ordem).flPodeAlterarStatus}> <IconNodePlusFill /> Alterar Status </DropDownItem>
							<DropDownItem title="Ver Fatura" type="button" onClick={this.handleVerFatura} show={ordem => Config.getPermissaoOrdem(ordem).flPodeVerFatura && ordem?.statusOrdem?.flFaturada}> <IconCoin /> Ver Fatura </DropDownItem>
						</DropDown>

					</DataGridButtons>

					<DataGridFooter>
						<DataGridColumn mobile={false} colSpan={7} className="text-center" />
						<DataGridColumn mobile={false} label="Valor" className="text-end">
							<Span className="fw-bold text-success"> {Util.floatToReais(this.getTotalOrdem())} </Span>
						</DataGridColumn>
						<DataGridColumn mobile={false} colSpan={2} className="text-center" />
					</DataGridFooter>

				</DataGrid>

				<PanelFooter className="text-center py-2" show={this.state.data && this.state.data?.length > 0}>
					<BtnButton onClick={this.handleMore} disabled={!this.state.hasMore}> Buscar Mais Itens </BtnButton>
				</PanelFooter>
			</Panel>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <ListOrdem {...props} navigate={navigate} params={params} location={location} />
}
export default With