//#NO-CHANGE-FILE#

import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { BaseCrud } from "../../siht/base";
import { FormButtons, FormItem, Row, Panel, PanelBody, PanelFooter, Container, ModalDialog, BtnRadioGroup } from "../../siht/components";
import { InputNumberFormat, InputDate, TextArea, Form, InputCheckSwitch } from "../../siht/elements";
import Config from "./Config";

import BtnRadioGroupTipoBaixa from "../../components/BtnRadioGroupDB/BtnRadioGroupTipoBaixa";
import { FormItemBancoConta } from "../../components/FormItem";

class FormContaReceberBaixa extends BaseCrud {

	constructor(props) {
		super(props);
		this.init = this.init.bind(this);
		this.initModel = this.initModel.bind(this);
		this.handleSuccess = this.handleSuccess.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleChangeModel = this.handleChangeModel.bind(this);
		this.handleSubmitForm = this.handleSubmitForm.bind(this);
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		super.init(Config);
	}

	initModel(){
		var tzoffset = (new Date()).getTimezoneOffset() * 60000;
        var dtBaixa = new Date();

		return {
			flContaUnidade : true,
			idContaReceberBaixa : null,
			contaReceberParcela : this.props.parent !== undefined ? this.props.parent : {},
			usuario : null,
			tipoBaixa : null,
			conta : null,
			vlBaixa : this.props.parent.vlAberto,
			vlMulta : 0,
			vlJuros : 0,
			vlTaxa : 0,
			dtBaixa : new Date(dtBaixa - tzoffset).toISOString().slice(0, 10),
			txObservacao : ""
		}
	}

	handleSuccess(e) {
		super.handleSuccess(e, Config);
	}

	handleClose(e) {
		super.handleClose(e, Config);
	}

	render() {
		return super.render(

					<Form onSubmit={this.handleSuccess} refParam={f => (this.form = f)} key={this.state.key}>
						<Panel key={this.state.key} title="Cadastro de Conta a Receber Baixa" localOpen={this.props.localOpen}  onClose={this.handleClose} isModal={this.isModal()} container={{sizing: Container.sizing.xxl, className : ""}} modal={{sizing: this.props.sizing ? this.props.sizing : ModalDialog.sizing.xl}}>
							<PanelBody>
								<Row className="g-3 pb-2 px-2">
									<FormItem md={12} label="Tipo da Baixa">										
										<BtnRadioGroupTipoBaixa filter={{flBaixaManual : true}} sizing={BtnRadioGroup.sizing.sm} name="tipoBaixa" value={this.state.model.tipoBaixa} required={true} onClick={this.handleChangeModel} placeholder="Selecione o Tipo de Baixa" buttons={{search : false, new : false, edit: false}}/>
									</FormItem>
								</Row>
								<Row className="g-0 py-0 pb-1 px-2" show={this.state.model.tipoBaixa?.flPagamento}>
									<InputCheckSwitch label="Somente as contas da Unidade?" name="flContaUnidade" checked={this.state.model.flContaUnidade} onChange={(e,v,p) => this.handleChangeModel(e, v, p, this.handleChangeContaUnidade)} />
								</Row>
								<Row className="g-3 pb-3 px-2">
									{this.state.model.flContaUnidade ?
										<FormItemBancoConta filter={{unidade : this.state.model.contaReceberParcela.contaReceber?.unidade}} show={this.state.model.tipoBaixa?.flPagamento} md={[6,6]} value={this.state.model.conta} name="conta" required={true} onChange={this.handleChangeModel} buttons={{search : false, new : false, edit: false}}/>
										: <FormItemBancoConta show={this.state.model.tipoBaixa?.flPagamento} md={[6,6]} value={this.state.model.conta} name="conta" required={true} onChange={this.handleChangeModel} buttons={{search : false, new : false, edit: false}}/>
									}
                                </Row>
								<Row className="g-3 pb-3 px-2">
									<FormItem md={3} label={`Valor ${this.state.model.tipoBaixa?.flPagamento ? "do Pagamento" : "do Desconto"}`}>
										<InputNumberFormat autoFocus name="vlBaixa" value={this.state.model.vlBaixa} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} prefix="R$ " required={true} onChange={this.handleChangeModel} className="text-end"/>
									</FormItem>
									<FormItem md={3} label="Data Baixa">
										<InputDate name="dtBaixa" value={this.state.model.dtBaixa} required={true} onChange={this.handleChangeModel} className="text-center"/>
									</FormItem>
									<FormItem md={2} label="Multa" show={this.state.model.tipoBaixa?.flPagamento}>
										<InputNumberFormat name="vlMulta" value={this.state.model.vlMulta} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} prefix="R$ " required={true} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={2} label="Juros" show={this.state.model.tipoBaixa?.flPagamento}>
										<InputNumberFormat name="vlJuros" value={this.state.model.vlJuros} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} prefix="R$ " required={true} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={2} label="Taxa" show={this.state.model.tipoBaixa?.flPagamento}>
										<InputNumberFormat name="vlTaxa" value={this.state.model.vlTaxa} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} prefix="R$ " required={true} onChange={this.handleChangeModel}/>
									</FormItem>									
								</Row>
								<Row className="g-3 pb-3 px-2">
									<FormItem md={12} label="Observação">
										<TextArea name="txObservacao" value={this.state.model.txObservacao} required={false} onChange={this.handleChangeModel}/>
									</FormItem>
								</Row>
							</PanelBody>
							<PanelFooter>
								<FormButtons onClose={this.handleClose} />
							</PanelFooter>
						</Panel>
					</Form>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <FormContaReceberBaixa {...props} navigate={navigate} params={params} location={location}/>
}
export default With