//#NO-CHANGE-FILE#
import FaturaService from "../../services/FaturaService";
import FormFaturar from "./FormFaturar";
import ListFatura from "../Fatura/ListFatura";
import Permissao from "../../controllers/Permissao";
import Util from "../../utils/Util";
import DadoValorService from "../../services/DadoValorService";

export default class Config {

	static getUrlForm() {
		return "/CadFatura";
	}

	static getUrlList() {
		return "/LstFatura";
	}

	static getComponentForm() {
		return FormFaturar;
	}

	static getPermissionForm() {
		return Permissao.getInstance().getPermissoes([68]);
	}

	static getPermissionList() {
		return Permissao.getInstance().getPermissoes([68]);
	}

	static getComponentList() {
		return ListFatura;
	}

	static getService() {
		return FaturaService;
	}

	static getKeyId() {
		return "idFatura";
	}

	static getKeyDescription() {
		return "nmFatura";
	}

	static setDefaultValues(state , fnCallBack = () => {}, className = undefined) {
		className = className === undefined ? "FormFaturar" : className;
		let keyNameScreen = Util.toToUpperTextCamelCase(className, "_") + ".";var prefix = "CONFIGURACAO.";
		DadoValorService.getValues(`${prefix}${keyNameScreen}`, (response)=> {
			Util.setValueObjectCamelCase(state.model, response, keyNameScreen, prefix);
			fnCallBack();
		}, fnCallBack);
	}
}