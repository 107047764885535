import ManyToManyCheckFuncionalidadePlugin from "../../components/ManyToManyCheck/ManyToManyCheckFuncionalidadePlugin";
import PluginFuncionalidadePluginService from "../../services/PluginFuncionalidadePluginService";

export default class ManyToManyCheckPluginFuncionalidadePlugin extends ManyToManyCheckFuncionalidadePlugin {

    constructor(props) {
        super(props);
        
        this.onChangeAddItem = this.onChangeAddItem.bind(this);
		this.onChangeDelItem = this.onChangeDelItem.bind(this);
		this.onChangeAddAll = this.onChangeAddAll.bind(this);
		this.onChangeDelAll = this.onChangeDelAll.bind(this);
        this.getSelecteds = this.getSelecteds.bind(this);

        this.state.selectedsOriginal = [];
    }

    componentDidMount() {
        super.componentDidMount();
        this.getSelecteds();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    componentDidUpdate(nextProps) {
        if (nextProps.parent !== undefined && this.props.parent !== undefined) {
            if (!this.objEqual(nextProps.parent, this.props.parent)) {
                this.getSelecteds();
            }
        }
    }
	

	getColumns(){
		return 1;
	}

    getSelecteds(){
        if(this.props?.parent?.idPlugin  || (Array.isArray(this.props?.parent) && this.props?.parent.length > 0)){
            PluginFuncionalidadePluginService.getAllByPlugin(this.props.parent, (response) => {
                let selecteds = response.flatMap(i => i.funcionalidadePlugin);
				let noSelecteds = [];

				if(Array.isArray(this.props?.parent)){
					selecteds = selecteds.filter((value, index, self) => self.findIndex((v) => parseInt(v.idFuncionalidadePlugin) === parseInt(value.idFuncionalidadePlugin)) === index);
					selecteds.forEach((selected, index) => {
						let plugins = response.filter(i=> parseInt(i.funcionalidadePlugin.idFuncionalidadePlugin) === (selected.idFuncionalidadePlugin)).flatMap(i => i.plugin);
						if((plugins.length !== this.props.parent.length)){
							noSelecteds.push(selected);
						}
					});

					selecteds = selecteds.filter(selected => !noSelecteds.find(ns => selected.idFuncionalidadePlugin === ns.idFuncionalidadePlugin));
				}

                this.setState({selecteds : selecteds, selectedsOriginal : response, noSelecteds : noSelecteds});
            });
        }
	}

    onChangeAddItem(e, item){     
		var parents = Array.isArray(this.props.parent) ? this.props.parent : [this.props.parent];

		parents.forEach(parent => {
			var selected = this.state.selectedsOriginal.find(i => parseInt(i.plugin.idPlugin) === parseInt(parent.idPlugin) && parseInt(i.funcionalidadePlugin.idFuncionalidadePlugin) === parseInt(item.idFuncionalidadePlugin));
			if(!selected){
				PluginFuncionalidadePluginService.save({
					funcionalidadePlugin : item,
					plugin : parent
				}, response =>{
					this.getSelecteds();
					super.onChangeAddItem(e, item);
				});
			}
		});
	};

	onChangeDelItem(e, item){
		var parents = Array.isArray(this.props.parent) ? this.props.parent : [this.props.parent];

		parents.forEach(parent => {			
			var selected = this.state.selectedsOriginal.find(i => parseInt(i.plugin.idPlugin) === parseInt(parent.idPlugin) && parseInt(i.funcionalidadePlugin.idFuncionalidadePlugin) === parseInt(item.idFuncionalidadePlugin));
			if(selected){
				PluginFuncionalidadePluginService.delete(selected.idPluginFuncionalidadePlugin, response =>{
					this.getSelecteds();
					super.onChangeDelItem(e, item);
				});
			};
		});
	};

	onChangeAddAll(e, itens){
		var parents = Array.isArray(this.props.parent) ? this.props.parent : [this.props.parent];

		parents.forEach(parent => {
			var obj = itens.filter(item => 
				!this.state.selectedsOriginal.find(i => parseInt(i.plugin.idPlugin) === parseInt(parent.idPlugin) && parseInt(i.funcionalidadePlugin.idFuncionalidadePlugin) === parseInt(item.idFuncionalidadePlugin))
			).map(item => {
				return {
					funcionalidadePlugin : item,
					plugin : parent
				}
			});

			if(obj.length > 0){
				PluginFuncionalidadePluginService.save(obj, response =>{
					this.getSelecteds();
					super.onChangeAddAll(e, itens);
				});
			}
		});
	};

	onChangeDelAll(e, itens){
		var parents = Array.isArray(this.props.parent) ? this.props.parent : [this.props.parent];

		parents.forEach(parent => {
			var selecteds = this.state.selectedsOriginal.filter(i => parseInt(i.plugin.idPlugin) === parseInt(parent.idPlugin)).filter(i => itens.find(is => parseInt(i.funcionalidadePlugin.idFuncionalidadePlugin) === parseInt(is.idFuncionalidadePlugin)));
			PluginFuncionalidadePluginService.delete(selecteds , response =>{
				this.getSelecteds();
				super.onChangeDelAll(e, itens);
			});
		});
	};

    render() {
        return super.render();
    }
}