import Http from "../controllers/Http";

export default class FuncionarioDocumentoService {

	static urlBase = "/funcionarioDocumento/";

	static getForCombo(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(FuncionarioDocumentoService.urlBase + 'forCombo', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static list(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(FuncionarioDocumentoService.urlBase + 'list', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneById(id, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.get(FuncionarioDocumentoService.urlBase + id)
			.then(fnSucess)
			.catch(fnError);
	}

	static create(data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(FuncionarioDocumentoService.urlBase, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static update(id, data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.put(FuncionarioDocumentoService.urlBase + id, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static delete(id, fnSucess = () => { }, fnError = () => { }) {
		if(Number.isInteger(id)){
			Http.getInstanceAppJson()
				.delete(FuncionarioDocumentoService.urlBase + id)
				.then(fnSucess)
				.catch(fnError);
		}else{
			Http.getInstanceAppJson()
			.post(FuncionarioDocumentoService.urlBase + 'delete', id)
			.then(fnSucess)
			.catch(fnError);
		}
	}

	static save(data, fnSucess = () => { }, fnError = () => { }) {
		if (data.idFuncionarioDocumento) {
			this.update(data.idFuncionarioDocumento, data, fnSucess, fnError);
		} else {
			this.create(data, fnSucess, fnError);
		}
	}

	static getAllByFuncionario(funcionario, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(FuncionarioDocumentoService.urlBase + 'funcionario', funcionario)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByFuncionario(funcionario, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(FuncionarioDocumentoService.urlBase + 'funcionario/' + funcionario.idFuncionario)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByArquivo(arquivo, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(FuncionarioDocumentoService.urlBase + 'arquivo', arquivo)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByArquivo(arquivo, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(FuncionarioDocumentoService.urlBase + 'arquivo/' + arquivo.idArquivo)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByTipoDocumento(tipoDocumento, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(FuncionarioDocumentoService.urlBase + 'tipoDocumento', tipoDocumento)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByTipoDocumento(tipoDocumento, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(FuncionarioDocumentoService.urlBase + 'tipoDocumento/' + tipoDocumento.idTipoDocumento)
			.then(fnSucess)
			.catch(fnError);
	}
}