import Http from "../controllers/Http";

export default class GrupoProdutoService {

	static urlBase = "/grupoProduto/";

	static getForCombo(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(GrupoProdutoService.urlBase + 'forCombo', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static list(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(GrupoProdutoService.urlBase + 'list', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneById(id, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.get(GrupoProdutoService.urlBase + id)
			.then(fnSucess)
			.catch(fnError);
	}

	static create(data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(GrupoProdutoService.urlBase, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static update(id, data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.put(GrupoProdutoService.urlBase + id, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static delete(id, fnSucess = () => { }, fnError = () => { }) {
		if(Number.isInteger(id)){
			Http.getInstanceAppJson()
				.delete(GrupoProdutoService.urlBase + id)
				.then(fnSucess)
				.catch(fnError);
		}else{
			Http.getInstanceAppJson()
			.post(GrupoProdutoService.urlBase + 'delete', id)
			.then(fnSucess)
			.catch(fnError);
		}
	}

	static save(data, fnSucess = () => { }, fnError = () => { }) {
		if (data.idGrupoProduto) {
			this.update(data.idGrupoProduto, data, fnSucess, fnError);
		} else {
			this.create(data, fnSucess, fnError);
		}
	}

	static getAllByGrupoProdutoTipoOrdem(grupoProdutoTipoOrdem, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(GrupoProdutoService.urlBase + 'grupoProdutoTipoOrdem', grupoProdutoTipoOrdem)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByGrupoProdutoTipoOrdem(grupoProdutoTipoOrdem, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(GrupoProdutoService.urlBase + 'grupoProdutoTipoOrdem/' + grupoProdutoTipoOrdem.idGrupoProdutoTipoOrdem)
			.then(fnSucess)
			.catch(fnError);
	}
}