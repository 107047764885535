//#NO-CHANGE-FILE#

import Http from "../controllers/Http";

export default class PainelService {

	static urlBase = "/painel/";

	static getPaineis(id, fnSucess = () => { }, fnError = () => { }) {
		let url = PainelService.urlBase + 'paineis';
		if (id) {
			url += '/' + id;
		}

		Http.getInstanceAppJson()
			.get(url)
			.then(fnSucess)
			.catch(fnError);
	}

	static promover(data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(PainelService.urlBase + 'promover', data)
			.then(fnSucess)
			.catch(fnError);
	}

	static rebaixar(data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(PainelService.urlBase + 'rebaixar', data)
			.then(fnSucess)
			.catch(fnError);
	}

	static getForCombo(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(PainelService.urlBase + 'forCombo', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static list(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(PainelService.urlBase + 'list', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneById(id, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.get(PainelService.urlBase + id)
			.then(fnSucess)
			.catch(fnError);
	}

	static create(data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(PainelService.urlBase, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static update(id, data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.put(PainelService.urlBase + id, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static delete(id, fnSucess = () => { }, fnError = () => { }) {
		if (Number.isInteger(id)) {
			Http.getInstanceAppJson()
				.delete(PainelService.urlBase + id)
				.then(fnSucess)
				.catch(fnError);
		} else {
			Http.getInstanceAppJson()
				.post(PainelService.urlBase + 'delete', id)
				.then(fnSucess)
				.catch(fnError);
		}
	}

	static save(data, fnSucess = () => { }, fnError = () => { }) {
		if (data.idPainel) {
			this.update(data.idPainel, data, fnSucess, fnError);
		} else {
			this.create(data, fnSucess, fnError);
		}
	}

	static getAllByBloco(bloco, fnSucess = () => { }, fnError = () => { }) {
		;
		Http.getInstanceAppJson()
			.post(PainelService.urlBase + 'bloco', bloco)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByBloco(bloco, fnSucess = () => { }, fnError = () => { }) {
		;
		Http.getInstanceAppJson()
			.get(PainelService.urlBase + 'bloco/' + bloco.idBloco)
			.then(fnSucess)
			.catch(fnError);
	}
}