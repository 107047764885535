
import FormUsuarioGrupoTipoOrdem from "./FormUsuarioGrupoTipoOrdem.js";
import ListUsuarioGrupoTipoOrdem from "./ListUsuarioGrupoTipoOrdem.js";
import { Permissao } from "../../controllers";
import { Util } from "../../utils";
import { DadoValorService, UsuarioGrupoTipoOrdemService } from "../../services";

export default class Config {

	static getUrlForm() {
		return "/CadUsuarioGrupoTipoOrdem";
	}

	static getUrlList() {
		return "/LstUsuarioGrupoTipoOrdem";
	}

	static getComponentForm() {
		return FormUsuarioGrupoTipoOrdem;
	}

	static getPermissionForm() {
		return Permissao.getInstance().getPermissoes([8]);
	}

	static getPermissionList() {
		return Permissao.getInstance().getPermissoes([8]);
	}

	static getComponentList() {
		return ListUsuarioGrupoTipoOrdem;
	}

	static getService() {
		return UsuarioGrupoTipoOrdemService;
	}

	static getKeyId() {
		return "idUsuarioGrupoTipoOrdem";
	}

	static getKeyDescription() {
		return "nmUsuarioGrupoTipoOrdem";
	}

	static setDefaultValues(state , fnCallBack = () => {}, className = undefined) {
		className = className === undefined ? "FormUsuarioGrupoTipoOrdem" : className;
		let keyNameScreen = Util.toToUpperTextCamelCase(className, "_") + ".";
		var prefix = "CONFIGURACAO.";
		DadoValorService.getValues(`${prefix}${keyNameScreen}`, (response)=> {
			Util.setValueObjectCamelCase(state.model, response, keyNameScreen, prefix);
			fnCallBack();
		}, fnCallBack);
	}
}