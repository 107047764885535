import { Component } from "react";

export default class DropDownItem extends Component {

    render() {
        if(this.props.show !== undefined && !this.props.show){
            return (<></>);
        }

        const props = Object.assign({}, this.props);
        props.className = `dropdown-item ${props.className !== undefined ? props.className : ""} ${props.active !== undefined && props.active === true ? "active": ""}`;
        props.type = props.type || "button";
        
        delete props.show;
        delete props.sizing;
        delete props.active;

        if(props.type === "button"){
            return (
                <button {...props}>{this.props.children}</button>
            );
        }else{
            delete props.type;
            return (
                <li><a {...props}>{this.props.children}</a></li>
            );
        }
    }
}