import ContaPagarVinculoService from "../../services/ContaPagarVinculoService";
import FormContaPagarVinculo from "./FormContaPagarVinculo";
import ListContaPagarVinculo from "./ListContaPagarVinculo";
import Permissao from "../../controllers/Permissao";
import Util from "../../utils/Util";
import DadoValorService from "../../services/DadoValorService";

export default class Config {

	static getUrlForm() {
		return "/CadContaPagarVinculo";
	}

	static getUrlList() {
		return "/LstContaPagarVinculo";
	}

	static getComponentForm() {
		return FormContaPagarVinculo;
	}

	static getPermissionForm() {
		return Permissao.getInstance().getPermissoes([190]);
	}

	static getPermissionList() {
		return Permissao.getInstance().getPermissoes([190]);
	}

	static getComponentList() {
		return ListContaPagarVinculo;
	}

	static getService() {
		return ContaPagarVinculoService;
	}

	static getKeyId() {
		return "idContaPagarVinculo";
	}

	static getKeyDescription() {
		return "nmContaPagarVinculo";
	}

	static setDefaultValues(state , fnCallBack = () => {}, className = undefined) {
		className = className === undefined ? "FormContaPagarVinculo" : className;
		let keyNameScreen = Util.toToUpperTextCamelCase(className, "_") + ".";
		var prefix = "CONFIGURACAO.";
		DadoValorService.getValues(`${prefix}${keyNameScreen}`, (response)=> {
			Util.setValueObjectCamelCase(state.model, response, keyNameScreen, prefix);
			fnCallBack();
		}, fnCallBack);
	}
}