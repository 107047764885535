//#NO-CHANGE-FILE#

import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { BaseCrud, BaseList } from "../../siht/base";
import { FormButtons, FormItem, Row, Panel, PanelBody, PanelFooter, Container, ModalDialog, TabNavigator, Tab } from "../../siht/components";
import { InputText, Form, TextArea, InputDate } from "../../siht/elements";
import Config from "./Config";
import ListFaturaNotaFiscalLog from "../FaturaNotaFiscalLog/ListFaturaNotaFiscalLog"
import ListFaturaNotaFiscalHistorico from "../FaturaNotaFiscalHistorico/ListFaturaNotaFiscalHistorico"
import ListFaturaNotaFiscalArquivo from "../FaturaNotaFiscalArquivo/ListFaturaNotaFiscalArquivo"
import FormFaturaNotaFiscalItem from "../FaturaNotaFiscalItem/FormFaturaNotaFiscalItem"
import FormFaturaNotaFiscalIntermediario from "../FaturaNotaFiscalIntermediario/FormFaturaNotaFiscalIntermediario"
import FormFaturaNotaFiscalCargaTributaria from "../FaturaNotaFiscalCargaTributaria/FormFaturaNotaFiscalCargaTributaria"
import ListFaturaNotaFiscalDeducao from "../FaturaNotaFiscalDeducao/ListFaturaNotaFiscalDeducao"
import ListFaturaNotaFiscalEndereco from "../FaturaNotaFiscalEndereco/ListFaturaNotaFiscalEndereco"
import BtnRadioGroupSimNao from "../../components/BtnRadioGroupSimNao";
import Permissao from "../../controllers/Permissao";
import { Bank2 as IconBank2, Basket as IconBasket, ClipboardMinus as IconClipboardMinus, ClockHistory as IconClockHistory, DashCircle as IconDashCircle, Files as IconFiles, ListOl as IconListOl, MailboxFlag as IconMailboxFlag, People as IconPeople } from "react-bootstrap-icons";
import SelectNaturezaTributacaoNotaFiscal from "../../components/SelectDB/SelectNaturezaTributacaoNotaFiscal";
import { StatusCustom } from "../../components/Custom";
import SelectStatusNotaFiscal from "../../components/SelectDB/SelectStatusNotaFiscal";
import { Modal } from "../../siht/controller";

class FormFaturaNotaFiscal extends BaseCrud {

	constructor(props) {
		super(props);
		this.init = this.init.bind(this);
		this.handleSuccess = this.handleSuccess.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleChangeModel = this.handleChangeModel.bind(this);
		this.handleSubmitForm = this.handleSubmitForm.bind(this);
		this.handleChangeStatusNotaFiscal = this.handleChangeStatusNotaFiscal.bind(this);

		this.state.model = {
			idFaturaNotaFiscal : null,
			fatura : null,
			statusNotaFiscal : null,
			naturezaTributacaoNotaFiscal : null,
			tipoProduto : null,
			nrSerie : null,
			nrLote : null,
			nrRps : null,
			nrNota : null,
			cdVerificacao : "",
			cdProtocoloEnvio : "",
			cdProtocoloCancelamento : "",
			cdNota : "",
			cdNotaSubstituicao : "",
			

			txDescricao : "",
			txDescricaoComplementar : "",
			dtCompetencia : "",
			dtEmissao : "",			
			dtVencimento : "",
			flEnviarEmail : true,
			flSubstituir : false,
			flSubstituicao : false,

			cdIdentificadorExterno : "",
			cdPedidoExterno : ""
		};
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		super.init(Config);
	}

	handleSuccess(e) {
		super.handleSuccess(e, Config);
	}

	handleClose(e) {
		super.handleClose(e, Config);
	}

	handleChangeStatusNotaFiscal(e) {
		Modal.confirm("Confirmação", "Deseja realmente alterar o status de " + this.state.model?.statusNotaFiscal?.nmStatusNotaFiscal + " para  " + this.state.model?._statusNotaFiscal?.nmStatusNotaFiscal +  " ?" , null, Modal.style.warning, () =>{
			Config.getService().alterarStatus({
				faturaNotaFiscal : this.state.model,
				statusNotaFiscal : this.state.model._statusNotaFiscal
			}, () => {
				this.init();
			});
		});
		
	}

	render() {

		let flPodeEditar = this.state.model.statusNotaFiscal?.flPodeEditar;

		return super.render(
			<Panel key={this.state.key} title="Cadastro de Nota Fiscal" onClose={this.handleClose} isModal={this.isModal()} container={{sizing: Container.sizing.fluid, className : ""}} modal={{sizing: this.props.sizing ? this.props.sizing : ModalDialog.sizing.xl}}>
				<PanelBody className="p-0 m-0">
					<TabNavigator onChangeTab={this.handleChangeTab} className="border-light">
						<Tab title={<><IconBank2/> Nota</>} className="m-2">
							<Form onSubmit={this.handleSuccess} refParam={f => (this.form = f)} key={this.state.key}>
								<Row className="g-3 pb-2 px-2">
									<FormItem md={1} label="Código">
										<InputText name="idFaturaNotaFiscal" value={this.state.model.idFaturaNotaFiscal} readOnly={true} className="readonly-field" required={false} onChange={this.handleChangeModel}/>
									</FormItem>
									
									<FormItem md={3} label="Status" show={!Permissao.getInstance().getPermissoes([218])}>
										<StatusCustom opcional={true} type="InputText" color={this.state.model.statusNotaFiscal}>{this.state.model.statusNotaFiscal?.nmStatusNotaFiscal}</StatusCustom>
									</FormItem>

									<FormItem md={3} label="Status" show={Permissao.getInstance().getPermissoes([218])}>
										<SelectStatusNotaFiscal name="_statusNotaFiscal" value={this.state.model.statusNotaFiscal} required={false} onChange={(e,v,p) => this.handleChangeModel(e,v,p,this.handleChangeStatusNotaFiscal)} placeholder="Selecione a Status da Nota Fiscal" buttons={{search : false, new : false, edit: false}}/>
									</FormItem>

									<FormItem md={2} label="Série">
										<InputText name="nrSerie" value={this.state.model.nrSerie} opcional={true} readOnly={true} className="text-center"/>
									</FormItem>

									<FormItem md={2} label="Lote">
										<InputText name="nrLote" value={this.state.model.nrLote} opcional={true} readOnly={true} className="text-center"/>
									</FormItem>

									<FormItem md={2} label="Número">
										<InputText name="nrRps" value={this.state.model.nrRps} opcional={true} readOnly={true} className="text-center"/>
									</FormItem>

									<FormItem md={2} label="Nota">
										<InputText name="nrNota" value={this.state.model.nrNota} opcional={true} readOnly={true} className="text-center"/>
									</FormItem>

									<FormItem md={2} label="Verificação">
										<InputText name="cdVerificacao" value={this.state.model.cdVerificacao} opcional={true} readOnly={true} className="text-center"/>
									</FormItem>

									<FormItem md={3} label="Tipo da Nota">
										<StatusCustom opcional={true} type="InputText" color={this.state.model.tipoNotaFiscal}>{this.state.model.tipoNotaFiscal?.nmTipoNotaFiscal}</StatusCustom>
									</FormItem>

									<FormItem md={2} label="Emissão">
										<InputDate name="dtEmissao" value={this.state.model.dtEmissao} required={true} onChange={this.handleChangeModel} disabled={!flPodeEditar} className="text-center"/>
									</FormItem>

									<FormItem md={2} label="Competência">
										<InputDate name="dtCompetencia" value={this.state.model.dtCompetencia} required={true} onChange={this.handleChangeModel} disabled={!flPodeEditar} className="text-center"/>
									</FormItem>

									<FormItem md={2} label="Vencimento">
										<InputDate name="dtVencimento" value={this.state.model.dtVencimento} required={false} onChange={this.handleChangeModel} disabled={!flPodeEditar} className="text-center"/>
									</FormItem>

									<FormItem md={2} label="Envio Prioritário?">
										<BtnRadioGroupSimNao name="flEnvioPrioritario" value={this.state.model.flEnvioPrioritario} required={true} onChange={this.handleChangeModel} disabled={!flPodeEditar}/>
									</FormItem>

									<FormItem md={2} label="Enviar E-mail?">
										<BtnRadioGroupSimNao name="flEnviarEmail" value={this.state.model.flEnviarEmail} required={true} onChange={this.handleChangeModel} disabled={!flPodeEditar}/>
									</FormItem>

									<FormItem md={2} label="Substituir?">
										<BtnRadioGroupSimNao name="flSubstituir" value={this.state.model.flSubstituir} required={true} onChange={this.handleChangeModel} disabled={true}/>
									</FormItem>

									<FormItem md={2} label="Substituição?">
										<BtnRadioGroupSimNao name="flSubstituicao" value={this.state.model.flSubstituicao} required={true} onChange={this.handleChangeModel} disabled={!flPodeEditar}/>
									</FormItem>

									<FormItem md={4} label="Nota Substituição">
										<InputText name="cdNotaSubstituicao" value={this.state.model.cdNotaSubstituicao} opcional={true} readOnly={true}/>
									</FormItem>

									<FormItem md={12} label="Descrição">
										<TextArea name="txDescricao" value={this.state.model.txDescricao} required={false} onChange={this.handleChangeModel} disabled={!flPodeEditar}/>
									</FormItem>

									<FormItem md={12} label="Informações Complementares">
										<TextArea name="txDescricaoComplementar" value={this.state.model.txDescricaoComplementar} required={false} onChange={this.handleChangeModel} disabled={!flPodeEditar}/>
									</FormItem>

									<FormItem md={3} label="Protocolo Envio">
										<InputText name="cdProtocoloEnvio" value={this.state.model.cdProtocoloEnvio} opcional={true} readOnly={true}/>
									</FormItem>
									<FormItem md={3} label="Protocolo Cancelamento">
										<InputText name="cdProtocoloCancelamento" value={this.state.model.cdProtocoloCancelamento} opcional={true} readOnly={true}/>
									</FormItem>
									<FormItem md={3} label="Nota">
										<InputText name="cdNota" value={this.state.model.cdNota} opcional={true} readOnly={true}/>
									</FormItem>
									
									<FormItem md={6} label="Natureza da Tributação">
										<SelectNaturezaTributacaoNotaFiscal disabled={!flPodeEditar} name="naturezaTributacaoNotaFiscal" value={this.state.model.naturezaTributacaoNotaFiscal} required={false} onChange={this.handleChangeModel} placeholder="Selecione a Natureza da Tributação"/>
									</FormItem>

									<FormItem md={3} label="Identificador Externo">
										<InputText name="cdIdentificadorExterno" value={this.state.model.cdIdentificadorExterno} maxLength={100} required={false} onChange={this.handleChangeModel} disabled={!flPodeEditar}/>
									</FormItem>
									<FormItem md={3} label="Pedido Externo">
										<InputText name="cdPedidoExterno" value={this.state.model.cdPedidoExterno} maxLength={100} required={false} onChange={this.handleChangeModel} disabled={!flPodeEditar}/>
									</FormItem>
								</Row>
							</Form>
						</Tab>
						<Tab title={<><IconBasket/> Item</>} className="m-0 p-0" show={Permissao.getInstance().getPermissoes([148])}>
							<FormFaturaNotaFiscalItem editable={flPodeEditar} parent={this.state.model} localOpen={BaseCrud.localOpen.embedded} handleSuccess={e => {}} handleClose={false}/>
						</Tab>
						<Tab title={<><IconClipboardMinus/> Dedução</>} className="m-2" show={Permissao.getInstance().getPermissoes([130])}>
							<ListFaturaNotaFiscalDeducao parent={this.state.model} localOpen={BaseList.localOpen.modal}/>
						</Tab>		
						<Tab title={<><IconDashCircle/> Carga Tributária</>} className="m-0 p-0" show={Permissao.getInstance().getPermissoes([130])}>
							<FormFaturaNotaFiscalCargaTributaria editable={flPodeEditar} parent={this.state.model} localOpen={BaseCrud.localOpen.embedded} handleSuccess={e => {}} handleClose={false}/>
						</Tab>					
						<Tab title={<><IconPeople/> Intermediário</>} className="m-0 p-0" show={Permissao.getInstance().getPermissoes([130])}>
							<FormFaturaNotaFiscalIntermediario editable={flPodeEditar} parent={this.state.model} localOpen={BaseCrud.localOpen.embedded} handleSuccess={e => {}} handleClose={false}/>
						</Tab>
						<Tab title={<><IconMailboxFlag/> Endereço</>} className="m-0 p-0" show={Permissao.getInstance().getPermissoes([130])}>
							<ListFaturaNotaFiscalEndereco editable={flPodeEditar} parent={this.state.model} localOpen={BaseCrud.localOpen.modal} handleSuccess={e => {}} handleClose={false}/>
						</Tab>
						<Tab title={<><IconFiles/> Arquivos</>} className="m-2" show={Permissao.getInstance().getPermissoes([130])}>
							<ListFaturaNotaFiscalArquivo parent={this.state.model} localOpen={BaseList.localOpen.modal}/>
						</Tab>
						<Tab title={<><IconClockHistory/> Histórico</>} className="m-2" show={Permissao.getInstance().getPermissoes([142])}>
							<ListFaturaNotaFiscalHistorico parent={this.state.model} localOpen={BaseList.localOpen.modal}/>
						</Tab>
						<Tab title={<><IconListOl/> Log de Processamento</>} className="m-2">
							<ListFaturaNotaFiscalLog parent={this.state.model} localOpen={BaseList.localOpen.modal}/>
						</Tab>						
					</TabNavigator>
				</PanelBody>
				<PanelFooter>
					<FormButtons show={this.state.tabIndex === 0} onClose={this.handleClose} onSubmit={this.handleSubmitForm}/>
				</PanelFooter>
			</Panel>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <FormFaturaNotaFiscal {...props} navigate={navigate} params={params} location={location}/>
}
export default With