import React, { useCallback } from 'react';
import { useReactFlow } from '@xyflow/react';
import { Copy as IconCopy, Trash as IconTrash } from 'react-bootstrap-icons';
 
export default function ContextMenu({
  id,
  top,
  left,
  right,
  bottom,
  ...props
}) {
    const { getNode, addNodes,  deleteElements, getEdges, getNodes } = useReactFlow();

    const onClickDuplicateNode = useCallback(() => {
        const node = getNode(id);
        const idNode = `node.temp.${Math.random(100)}`;

        const position = {
            x: node.position.x + 50,
            y: node.position.y + 50,
        };
    
        addNodes({
            ...node,
            selected: false,
            dragging: false,
            id: `${idNode}`,
            position,
        });
    }, [id, getNode, addNodes]);
    
    const onClickDeleteNode = useCallback(() => {
        deleteElements({
            nodes : getNodes().filter((node) => node.id === id),
            edges : getEdges().filter((edge) => edge.source === id),
        });
    }, [id, getNodes, getEdges, deleteElements]);
    
    return (
        <div style={{ top, left, right, bottom }} className="context-node-menu" {...props}>
            {/*<p style={{ margin: '0.5em'}}>
                <small>node: {id}</small>
            </p>*/}
            <button onClick={onClickDuplicateNode}>
                <IconCopy/> Duplicar
            </button>
            <button onClick={onClickDeleteNode}>
                <IconTrash/> Excluir
            </button>
        </div>
    );
}