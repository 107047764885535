//#NO-CHANGE-FILE#

import Http from "../controllers/Http";

export default class ContaTipoPagamentoService {

	static urlBase = "/contaTipoPagamento/";

	static getAllByUnidade(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(ContaTipoPagamentoService.urlBase + 'unidade', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static getToken(id, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.get(ContaTipoPagamentoService.urlBase + 'token/' + id)
			.then(fnSucess)
			.catch(fnError);
	}

	static getWebhook(id, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.get(ContaTipoPagamentoService.urlBase + 'webhook/' + id)
			.then(fnSucess)
			.catch(fnError);
	}

	static getForCombo(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(ContaTipoPagamentoService.urlBase + 'forCombo', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static list(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(ContaTipoPagamentoService.urlBase + 'list', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneById(id, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.get(ContaTipoPagamentoService.urlBase + id)
			.then(fnSucess)
			.catch(fnError);
	}

	static create(data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(ContaTipoPagamentoService.urlBase, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static update(id, data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.put(ContaTipoPagamentoService.urlBase + id, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static delete(id, fnSucess = () => { }, fnError = () => { }) {
		if(Number.isInteger(id)){
			Http.getInstanceAppJson()
				.delete(ContaTipoPagamentoService.urlBase + id)
				.then(fnSucess)
				.catch(fnError);
		}else{
			Http.getInstanceAppJson()
			.post(ContaTipoPagamentoService.urlBase + 'delete', id)
			.then(fnSucess)
			.catch(fnError);
		}
	}

	static save(data, fnSucess = () => { }, fnError = () => { }) {
		if (data.idContaTipoPagamento) {
			this.update(data.idContaTipoPagamento, data, fnSucess, fnError);
		} else {
			this.create(data, fnSucess, fnError);
		}
	}

	static getAllByConta(conta, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(ContaTipoPagamentoService.urlBase + 'conta', conta)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByConta(conta, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(ContaTipoPagamentoService.urlBase + 'conta/' + conta.idConta)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByTipoPagamento(tipoPagamento, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(ContaTipoPagamentoService.urlBase + 'tipoPagamento', tipoPagamento)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByTipoPagamento(tipoPagamento, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(ContaTipoPagamentoService.urlBase + 'tipoPagamento/' + tipoPagamento.idTipoPagamento)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByTipoTransacao(tipoTransacao, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(ContaTipoPagamentoService.urlBase + 'tipoTransacao', tipoTransacao)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByTipoTransacao(tipoTransacao, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(ContaTipoPagamentoService.urlBase + 'tipoTransacao/' + tipoTransacao.idTipoTransacao)
			.then(fnSucess)
			.catch(fnError);
	}
}