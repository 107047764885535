//#NO-CHANGE-FILE#
import Config from "./Config";
import Util from "../../utils/Util";
import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { BaseList } from "../../siht/base";
import { DataGrid, DataGridButtons, DataGridColumn, Panel, Container, Row, InputGroup, InputGroupText, InputGroupButton, PanelFooter, FormItem } from "../../siht/components";
import { Button, Div, Form, InputSearch, BtnSubmit, BtnButton } from "../../siht/elements";
import { BadgeSimNao, BtnDelete, BtnEdit, BtnNew, StatusCustom } from "../../components/Custom";
import { IconClear, IconSearch } from "../../icons";
import SelectTipoPessoa from "../../components/SelectDB/SelectTipoPessoa";

class ListPessoa extends BaseList {

	constructor(props) {
		super(props);
		this.getItens = this.getItens.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleNew = this.handleNew.bind(this);
		this.handleEdit = this.handleEdit.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.handleMore = this.handleMore.bind(this);
		this.onSubmitFilter = this.onSubmitFilter.bind(this);
		this.handleClear = this.handleClear.bind(this);
		this.handleFormSuccess = this.handleFormSuccess.bind(this);
		this.handleFormClose = this.handleFormClose.bind(this);
		this.handleChangeFilter = this.handleChangeFilter.bind(this);
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		super.init(Config);
	}

	initStateFilter(){
        return {
			tipoPessoa : null,
            search: "",
            page : 1,
            pageSize : 100,
        };
    }

	getItens(more){
		super.getItens(Config.getService().list, false, more);
	}

	handleNew(e) {
		super.handleNew(e, Config);
	}

	handleEdit(e, item) {
		super.handleEdit(e, Config, item);
	}

	handleDelete(e, item) {
		super.handleDelete(e, Config, item);
	}

	getFormEmbedded(){
		return super.getFormEmbedded(Config);
	}

	render() {
		return super.render(
			<Panel title="Lista de Pessoa(s)" isModal={this.isModal()} container={{sizing: Container.sizing.xxl, className : ""}}>
				<Div className="d-flex justify-content-end flex-nowrap gap-2">
					<BtnNew onClick={this.handleNew}> Nova Pessoa </BtnNew>
				</Div>

				{this.getFormEmbedded()}

				<Form onSubmit={this.onSubmitFilter}>
					<Row>
						<FormItem md={6} label="Tipo de Pessoa">
							<SelectTipoPessoa name="tipoPessoa" value={this.state.filter.tipoPessoa} required={false} opcional={true} onChange={this.handleChangeFilter} placeholder="Selecione o Tipo de Pessoa" buttons={{search : false, new : false, edit: false}}/>
						</FormItem>						
					</Row>
					<Row className="mt-1 mb-2 g-3">
						<InputGroup className="flex-nowrap" sizing={InputGroup.sizing.default}>
							<InputGroupText><IconSearch/></InputGroupText>
							<InputSearch autoComplete="off" autoFocus placeholder="Digite sua busca" name="search" value={this.state.filter.search} required={false} opcional={true} onChange={(e,v,n) => this.handleChange(e,v,n, this.state.filter)}/>
							<InputGroupButton>
								<BtnSubmit title="Filtrar"> Buscar </BtnSubmit>
								<BtnButton title="Limpar Pesquisa" color={BtnButton.color.outlineDanger} onClick={this.handleClear}> <IconClear/> </BtnButton>
							</InputGroupButton>
						</InputGroup>
					</Row>
				</Form>

				<DataGrid onDoubleClickRow={this.handleEdit} responsive={true} data={this.state.data} search={false} isDisabled={i => false} sizing={DataGrid.sizing.sm} select={true} selecteds={this.state.selecteds} onSelect={(selecteds)=>{this.setState({selecteds : selecteds})}} getItemValue={i=>i.idPessoa} onMore={this.handleMore}>
					<DataGridColumn label="Código" field={i => i.idPessoa} className="text-center"/>
					<DataGridColumn col={8} label="Nome da Pessoa" field={i => i.nmPessoa} className="text-start"/>
					<DataGridColumn col={1} label="Tipo Pessoa" field={i => <StatusCustom className="w-100" color={i.tipoPessoa}> PESSOA {i.tipoPessoa?.nmTipoPessoa} </StatusCustom>} className="text-center"/>
					
					<DataGridColumn col={2} label="CPF / CNPJ" field={i => 
						<React.Fragment>
							{i.tipoPessoa?.cdTipoPessoa === "F" ? Util.formataCPF(i.nrCpf) : <></>}
							{i.tipoPessoa?.cdTipoPessoa === "J" ? Util.formataCnpj(i.nrCnpj) : <></>}
						</React.Fragment>
					} className="text-center text-nowrap"/>
					
					<DataGridColumn col={1} label="Ativo?" field={i => <BadgeSimNao value={i.flAtivo}/>} className="text-center"/>					

					<DataGridButtons>
						<BtnEdit sizing={Button.sizing.sm} onClick={this.handleEdit}/>
						<BtnDelete sizing={Button.sizing.sm} onClick={this.handleDelete}/>
						{/*<DropDown alignment={DropDown.alignment.end}>
							<DropDownLabel sizing={Button.sizing.sm} toggle={false}> <IconThreeDotsVertical/> </DropDownLabel>
							<DropDownItem title="Editar Registro" type="button" onClick={this.handleEdit}> <IconEdit/> Editar </DropDownItem>
							<DropDownItem title="Excluir Registro" type="button" onClick={this.handleDelete}> <IconDelete/> Excluir </DropDownItem>
						</DropDown>*/}
					</DataGridButtons>
				</DataGrid>

				<PanelFooter className="text-center py-2" show={this.state.data && this.state.data?.length > 0}>
					<BtnButton onClick={this.handleMore} disabled={!this.state.hasMore}> Buscar Mais Itens </BtnButton>
				</PanelFooter>
			</Panel>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <ListPessoa {...props} navigate={navigate} params={params} location={location}/>
}
export default With