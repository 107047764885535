//#NO-CHANGE-FILE#

import Http from "../controllers/Http";

export default class FluxoVersaoService {

	static urlBase = "/fluxoVersao/";

	static getForCombo(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(FluxoVersaoService.urlBase + 'forCombo', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static list(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(FluxoVersaoService.urlBase + 'list', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneById(id, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.get(FluxoVersaoService.urlBase + id)
			.then(fnSucess)
			.catch(fnError);
	}

	static create(data, fnSucess = () => { }, fnError = () => { }, configRequest = {}) {
		Http.getInstanceAppJson(configRequest)
			.post(FluxoVersaoService.urlBase, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static update(id, data, fnSucess = () => { }, fnError = () => { }, configRequest = {}) {
		Http.getInstanceAppJson(configRequest)
			.put(FluxoVersaoService.urlBase + id, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static delete(id, fnSucess = () => { }, fnError = () => { }) {
		if(Number.isInteger(id)){
			Http.getInstanceAppJson()
				.delete(FluxoVersaoService.urlBase + id)
				.then(fnSucess)
				.catch(fnError);
		}else{
			Http.getInstanceAppJson()
			.post(FluxoVersaoService.urlBase + 'delete', id)
			.then(fnSucess)
			.catch(fnError);
		}
	}

	static save(data, fnSucess = () => { }, fnError = () => { }, configRequest = {}) {
		if (data.idFluxoVersao) {
			this.update(data.idFluxoVersao, data, fnSucess, fnError, configRequest);
		} else {
			this.create(data, fnSucess, fnError, configRequest);
		}
	}

	static getAllByFluxo(fluxo, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(FluxoVersaoService.urlBase + 'fluxo', fluxo)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByFluxo(fluxo, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(FluxoVersaoService.urlBase + 'fluxo/' + fluxo.idFluxo)
			.then(fnSucess)
			.catch(fnError);
	}
}