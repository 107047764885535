//#NO-CHANGE-FILE#

import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { BaseCrud, BaseList } from "../../siht/base";
import { FormButtons, FormItem, Row, TabNavigator, Tab, Panel, PanelBody, PanelFooter, Container, Col, ModalDialog } from "../../siht/components";
import { InputText, InputEmail, Form, BtnSubmit, BtnButton, Button, Div } from "../../siht/elements";
import Config from "./Config";
import ConfigApp from "../../ConfigApp";
import { Modal } from "../../siht/controller";
import FormUpload from "../FormUpload";
import { IconDelete } from "../../icons";
import ListUsuarioTipoNotificacao from "../UsuarioTipoNotificacao/ListUsuarioTipoNotificacao";
import FormPessoa from "../Pessoa/FormPessoa";
import { Camera as IconCamera} from "react-bootstrap-icons";

class FormMeusDados extends BaseCrud {

	constructor(props) {
		super(props);
		this.init = this.init.bind(this);
		this.handleSuccess = this.handleSuccess.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleChangeModel = this.handleChangeModel.bind(this);
		this.handleSubmitForm = this.handleSubmitForm.bind(this);
		this.handleArquivo = this.handleArquivo.bind(this);
		this.handleDeleteArquivo = this.handleDeleteArquivo.bind(this);

		this.state.model = {
			idUsuario : null,
			pessoa : null,
			nmUsuario : "",
			dsEmail : "",
			dsSenha : "",
			dsConfirmacao : "",
			flAtivo : true,
			flAlterarSenha : false,
			flAutomatico : false,
			arquivo : null
		};
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		Config.getService().getOneByUsuarioLogado((response) => {
			this.setState({ model: this.middleResponse(response), key : Math.random() }, ()=>{
				this.setModel({dsSenha : "", flAlterarSenha : false, dsConfirmacao : ""});
			});
		});
	}

	handleSuccess(e) {
		super.handleSuccess(e, Config);
	}

	handleClose(e) {
		super.handleClose(e, Config);
	}

	handleArquivo() {
		Modal.openWindow(FormUpload, {sizing : Modal.sizing.default}, { multiple : false, accept : "image/*" }, (e, response) => {
			this.setModel({arquivo : response});
		});
	}

	handleDeleteArquivo() {
		this.setModel({arquivo : null});
	}

	render() {
		return super.render(
			<Panel key={this.state.key} title="Cadastro de Usuário" onClose={this.handleClose} isModal={this.isModal()} container={{sizing: Container.sizing.xxl, className : ""}} modal={{sizing: this.props.sizing ? this.props.sizing : ModalDialog.sizing.xl}}>
				<PanelBody className="p-0 m-0">
					<TabNavigator key={this.state.key} onChangeTab={this.handleChangeTab} className="border-light">
						<Tab title="Usuário" className="m-2">
							<Form onSubmit={this.handleSuccess} refParam={f => (this.form = f)}>
								<Row className="g-3 pb-2 px-2">
									<FormItem md={12} label="Nome do Usuário">
										<InputText name="nmUsuario" value={this.state.model.nmUsuario} autoFocus={true} maxLength={100} required={true} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={12} label="Email">
										<InputEmail name="dsEmail" value={this.state.model.dsEmail} maxLength={100} required={true} onChange={this.handleChangeModel}/>
									</FormItem>
								</Row>
								
								<Row className="g-3 pb-2 px-2 pt-3">
									<Col md={12}>
										{parseInt(this.state.model?.arquivo?.idArquivo) > 0 && this.state.model.arquivo?.cdUid && this.state.model.arquivo?.arquivoBase?.cdHash ?
											<Div>
												<Div>
													<img style={{ maxHeight: "150px" }} src={ConfigApp.getUrlArquivo() + "public/inline/" + this.state.model.arquivo.cdUid + "/" + this.state.model.arquivo.arquivoBase.cdHash} className="img-fluid img-thumbnail" alt="..." />
												</Div>
												<Div>
													<BtnButton color={BtnButton.color.danger} sizing={Button.sizing.sm} onClick={this.handleDeleteArquivo}> <IconDelete/> Excluir Foto </BtnButton>
												</Div>
											</Div>
											: <BtnButton sizing={Button.sizing.default} multiple={false} onClick={this.handleArquivo} color={BtnButton.color.outlinePrimary}> <IconCamera/> Enviar Foto de Perfil </BtnButton>}
									</Col>
								</Row>
								<BtnSubmit hidden > Enviar </BtnSubmit>
							</Form>
						</Tab>
						<Tab title="Pessoa">
							<FormPessoa key={this.state.model?.pessoa?.idPessoa} id={this.state.model?.pessoa?.idPessoa} localOpen={BaseCrud.localOpen.embedded} handleSuccess={e => {}} handleClose={false}/>
						</Tab>
						<Tab title="Notificações" disabled={!this.state.model.idUsuario} opened={false} className="m-2">
							<ListUsuarioTipoNotificacao parent={this.state.model} localOpen={BaseList.localOpen.modal}/>
						</Tab>
					</TabNavigator>
				</PanelBody>
				<PanelFooter>
					<FormButtons show={this.state.tabIndex === 0} onClose={this.handleClose} onSubmit={this.handleSubmitForm}/>
				</PanelFooter>
			</Panel>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <FormMeusDados {...props} navigate={navigate} params={params} location={location}/>
}
export default With