//#NO-CHANGE-FILE#

import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import SelectTipoPessoa from "../../components/SelectDB/SelectTipoPessoa";
import SelectTipoRegimeTributario from "../../components/SelectDB/SelectTipoRegimeTributario";
import SelectTipoRegimeTributarioEspecial from "../../components/SelectDB/SelectTipoRegimeTributarioEspecial";
import BtnRadioGroupSimNao from "../../components/BtnRadioGroupSimNao";
import ListPessoaEmail from "../PessoaEmail/ListPessoaEmail";
import ListPessoaTelefone from "../PessoaTelefone/ListPessoaTelefone";
import ListPessoaEndereco from "../PessoaEndereco/ListPessoaEndereco";
import ListPessoaPagina from "../PessoaPagina/ListPessoaPagina";
import ListPessoaUnidade from "../PessoaUnidade/ListPessoaUnidade";
import ListPessoaVeiculo from "../PessoaVeiculo/ListPessoaVeiculo";
import ListPessoaLocalizacao from "../PessoaLocalizacao/ListPessoaLocalizacao";
import ListPessoaFoto from "../PessoaFoto/ListPessoaFoto";
import ListPessoaDocumento from "../PessoaDocumento/ListPessoaDocumento";
import ListPessoaConjuge from "../PessoaConjuge/ListPessoaConjuge";
import ListPessoaFiliacao from "../PessoaFiliacao/ListPessoaFiliacao";
import ListPessoaAtividade from "../PessoaAtividade/ListPessoaAtividade";
import ListPessoaInscricaoEstadual from "../PessoaInscricaoEstadual/ListPessoaInscricaoEstadual";
import ListDadoValorByParent from "../DadoValor/ListDadoValorByParent";
import Permissao from "../../controllers/Permissao";
import { BaseCrud, BaseList } from "../../siht/base";
import { FormButtons, FormItem, Row, TabNavigator, Tab, Panel, PanelBody, PanelFooter, Container, Col, InputGroup, InputGroupButton, BtnRadioGroup, ModalDialog, InputMapOneMarker } from "../../siht/components";
import { InputText, InputCpf, InputDate, InputCnpj, Div, Form, BtnSubmit, H6, InputCheckSwitch, BtnButton, BtnRadio, Button } from "../../siht/elements";
import Config from "./Config";
import { Bell as IconBell, ArchiveFill as IconArchiveFill, Bank as IconBank, CarFront as IconCarFront, Envelope as IconEnvelope, Link45deg as IconLink45deg, MailboxFlag as IconMailboxFlag, PatchPlus as IconPatchPlus, PersonFill as IconPersonFill, PinMapFill as IconPinMapFill, ShopWindow as IconShopWindow, Signpost as IconSignpost, Telephone as IconTelephone, Camera as IconCamera, FilePerson as IconFilePerson, PeopleFill as IconPeopleFill, PersonFillUp as IconPersonFillUp } from "react-bootstrap-icons";
import { Modal } from "../../siht/controller";
import { toast } from "react-toastify";
import Util from "../../utils/Util";
import Validator from "../../utils/Validator";
import ConfigApp from "../../ConfigApp";
import ListDadoValorByParentForm from "../DadoValor/ListDadoValorByParentForm";
import ListPessoaTipoNotificacao from "../PessoaTipoNotificacao/ListPessoaTipoNotificacao";
import { IconDelete, IconSearch } from "../../icons";
import SelectEstadoCivil from "../../components/SelectDB/SelectEstadoCivil";
import FormUpload from "../FormUpload";
import SelectNacionalidade from "../../components/SelectDB/SelectNacionalidade";
import { FormItemPaisEstadoCidade } from "../../components/FormItem";
import { StatusCustom } from "../../components/Custom";
import SelectGenero from "../../components/SelectDB/SelectGenero";

class FormPessoa extends BaseCrud {

	constructor(props) {
		super(props);
		this.init = this.init.bind(this);
		this.handleSuccess = this.handleSuccess.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleChangeModel = this.handleChangeModel.bind(this);
		this.handleSubmitForm = this.handleSubmitForm.bind(this);
		this.handleChangeNomePessoa = this.handleChangeNomePessoa.bind(this);
		this.handleBuscarCnpj = this.handleBuscarCnpj.bind(this);
		this.handleBuscarCpfDtNascimento = this.handleBuscarCpfDtNascimento.bind(this);
		this.handleArquivo = this.handleArquivo.bind(this);
		this.handleDeleteArquivo = this.handleDeleteArquivo.bind(this);

		this.state.editable = this.props.editable !== undefined ? this.props.editable : true;
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init() {
		super.init(Config);
	}

	initModel() {
		return {
			idPessoa: null,
			nmPessoa: "",
			tipoPessoa: null,
			tipoRegimeTributario: null,
			tipoRegimeTributarioEspecial: null,
			flIncentivadorCultural: false,
			flIncentivoFiscal: false,
			flOptanteSimplesNacional: false,
			flOrgaoPublico: false,
			flMei: false,
			flAtivo: true,
			flCopia: true,
			nmPessoaFisica: "",
			nmPessoaSocial: "",
			nrCpf: "",
			dtNascimento: "",
			nrPassaporte: "",
			nmEmpresarial: "",
			nmFantasia: "",
			nrCnpj: "",
			nrInscricaoMunicipal: "",
			dtAbertura: "",
			cdTipoEstabelecimento: "MATRIZ",
			cdCtps: "",
			cdPis: "",
			cdTituloEleitor: "",
			cdReservista: "",
			pessoaEmail: [],
			pessoaTelefone: [],
			pessoaEndereco: [],
			pessoaDadoValor: [],
			cdRg: "",
			dsRgLocal: "",
			dtRgExpedicao: "",
			estadoCivil: null,
			arquivo: null,
			cidade: null,
			genero: null,
			nacionalidade: null,
			pessoaImportar: {
				pessoaEmail: [],
				pessoaTelefone: [],
				pessoaEndereco: [],
			}
		}
	}

	handleSuccess(e) {
		super.handleSuccess(e, Config);
	}

	handleClose(e) {
		super.handleClose(e, Config);
	}

	handleChangeNomePessoa() {
		this.setState(state => {
			if (state.model.tipoPessoa?.cdTipoPessoa === "F" || state.model.tipoPessoa?.cdTipoPessoa === "E") {
				state.model.nmPessoaFisica = state.model.nmPessoa;
			}

			if (state.model.tipoPessoa?.cdTipoPessoa === "J") {
				state.model.nmEmpresarial = state.model.nmPessoa;
			}

			return state;
		});
	}

	handleBuscarCnpj() {

		Config.getService().getOneByCnpj(Util.stringToNumber(this.state.model?.nrCnpj), (response) => {

			let description = [];

			if (response.nmEmpresarial !== undefined && response.nmEmpresarial) {
				description.push({ label: `Razão Social:`, value: response.nmEmpresarial });
			}

			if (response.nmFantasia !== undefined && response.nmFantasia) {
				description.push({ label: `Nome Fantasia:`, value: response.nmFantasia });
			}

			if (response.dtAbertura !== undefined && response.dtAbertura) {
				description.push({ label: `Data de Abertura:`, value: Util.date2Br(response.dtAbertura) });
			}

			if (response.flOptanteSimplesNacional !== undefined && response.flOptanteSimplesNacional) {
				description.push({ label: `Simples Nacional:`, value: response.flOptanteSimplesNacional ? "SIM" : "NÃO" });
			}

			if (response.cdTipoEstabelecimento !== undefined && response.cdTipoEstabelecimento) {
				description.push({ label: `Tipo:`, value: response.cdTipoEstabelecimento });
			}

			if (response.tipoRegimeTributario !== undefined && response.tipoRegimeTributario) {
				description.push({ label: `Regime Tributário:`, value: response.tipoRegimeTributario?.nmTipoRegimeTributario });
			}

			if (response.emails !== undefined && Array.isArray(response.emails)) {
				description = description.concat(response.emails.map(item => { return { label: `Email:`, value: item.dsEmail } }));
			}

			if (response.telefones !== undefined && Array.isArray(response.telefones)) {
				description = description.concat(response.telefones.map(item => { return { label: `Telefone:`, value: item.nrTelefone } }));
			}

			if (response.atividades !== undefined && Array.isArray(response.atividades)) {
				description.push("Atividades Econômicas:")
				description = description.concat(response.atividades.map(item => { return { label: ``, value: item.cdAtividade + ' - ' + item.nmAtividade, className: "text-secondary fs-7 m-0 p-0" } }));
			}

			if (response.inscricoesEstaduais !== undefined && Array.isArray(response.inscricoesEstaduais)) {
				description.push("Inscrições Estaduais:")
				description = description.concat(response.inscricoesEstaduais.map(item => { return { label: ``, value: item.estado?.nmEstado + ' - ' + item.nrInscricaoEstadual, className: "text-secondary fs-7 m-0 p-0" } }));
			}


			Modal.confirmLg("Confirma a subtituição dos dados da pessoa?", "", description, Modal.style.warning, () => {
				this.setState(state => {

					if (response.nmEmpresarial !== undefined && response.nmEmpresarial) {
						state.model.nmPessoa = response.nmEmpresarial;
						state.model.nmEmpresarial = response.nmEmpresarial;
					}

					if (response.nmFantasia !== undefined && response.nmFantasia) {
						state.model.nmFantasia = response.nmFantasia;
					}

					if (response.dtAbertura !== undefined && response.dtAbertura) {
						state.model.dtAbertura = response.dtAbertura;
					}

					if (response.flOptanteSimplesNacional !== undefined && response.flOptanteSimplesNacional) {
						state.model.flOptanteSimplesNacional = response.flOptanteSimplesNacional;
					}

					if (response.cdTipoEstabelecimento !== undefined && response.cdTipoEstabelecimento) {
						state.model.cdTipoEstabelecimento = response.cdTipoEstabelecimento;
					}

					if (response.tipoRegimeTributario !== undefined && response.tipoRegimeTributario) {
						state.model.tipoRegimeTributario = response.tipoRegimeTributario;
					}

					state.model.pessoaImportar = {
						pessoaEmail: response.emails,
						pessoaTelefone: response.telefones,
						pessoaEndereco: response.endereco,
						pessoaAtividade: response.atividades,
						pessoaInscricaoEstadual: response.inscricoesEstaduais,
					};

					return state;
				}, () => {
					toast.warn("Lembre-se de salvar os dados para efetivar a importação!")
				});
			})
		});
	}

	handleBuscarCpfDtNascimento() {

		var cpf = Util.stringToNumber(this.state.model?.nrCpf);
		var dtNascimento = this.state.model.dtNascimento

		Config.getService().getOneByCpfDtNascimento(cpf, dtNascimento, (response) => {

			Modal.confirm("Confirmação", "Confirma a subtituição dos dados da pessoa?", false, Modal.style.warning, () => {
				this.setState(state => {
					if (response.nmPessoaFisica !== undefined && response.nmPessoaFisica) {
						state.model.nmPessoa = response.nmPessoaFisica;
						state.model.nmPessoaFisica = response.nmPessoaFisica;
					}

					return state;
				}, () => {
					toast.warn("Lembre-se de salvar os dados para efetivar a importação!")
				});
			})
		});
	}

	handleArquivo() {
		Modal.openWindow(FormUpload, { sizing: Modal.sizing.default }, { multiple: false, accept: "image/*" }, (e, response) => {
			this.setModel({ arquivo: response });
		});
	}

	handleDeleteArquivo() {
		this.setModel({ arquivo: null });
	}

	render() {

		return super.render(
			<Panel key={this.state.key} title="Cadastro de Pessoa" localOpen={this.props.localOpen} onClose={this.handleClose} isModal={this.isModal()} container={{ sizing: Container.sizing.fluid, className: "" }} modal={{ sizing: this.props.sizing ? this.props.sizing : ModalDialog.sizing.xl }}>
				<PanelBody className="p-0 m-0">
					<TabNavigator onChangeTab={this.handleChangeTab} className="border-light">
						<Tab title={<><IconPersonFill /> Pessoa</>} className="m-2">
							<Form onSubmit={this.handleSuccess} refParam={f => (this.form = f)}>
								<Row className="g-3 py-2 px-2">
									<FormItem md={1} label="Código">
										<InputText name="idPessoa" value={this.state.model.idPessoa} readOnly={true} className="readonly-field" required={false} />
									</FormItem>

									<FormItem md={3} label="Tipo de Pessoa">
										{!this.state.model.idPessoa ?
											<SelectTipoPessoa name="tipoPessoa" autoFocus={!this.state.model.idPessoa} disabled={this.state.model.idPessoa || !this.state.editable} value={this.state.model.tipoPessoa} required={true} onChange={this.handleChangeModel} placeholder="Selecione o Tipo de Pessoa" buttons={{ search: false, new: false, edit: false }} />
											: <StatusCustom type="InputText" opcional={true} color={this.state.model.tipoPessoa}>{`PESSOA ${this.state.model.tipoPessoa?.nmTipoPessoa}`}</StatusCustom>}
									</FormItem>

									<FormItem md={3} label="CNPJ" show={this.state.model.tipoPessoa?.cdTipoPessoa === "J"}>
										<InputGroup>
											<InputCnpj name="nrCnpj" autoFocus={!this.state.model.idPessoa} value={this.state.model.nrCnpj} maxLength={14} required={true} onChange={this.handleChangeModel} disabled={this.state.model.idPessoa || !this.state.editable} />
											<InputGroupButton>
												<BtnButton disabled={!Validator.isCNPJ(this.state.model?.nrCnpj)} onClick={this.handleBuscarCnpj}> <IconSearch /> </BtnButton>
											</InputGroupButton>
										</InputGroup>
									</FormItem>

									<FormItem md={3} label="Data de Nascimento" show={this.state.model.tipoPessoa?.cdTipoPessoa === "F"}>
										<InputDate name="dtNascimento" autoFocus={!this.state.model.idPessoa} value={this.state.model.dtNascimento} required={true} onChange={this.handleChangeModel} disabled={!this.state.editable} />
									</FormItem>

									<FormItem md={3} label="CPF" show={this.state.model.tipoPessoa?.cdTipoPessoa === "F"}>
										<InputGroup className="has-validation">
											<InputCpf name="nrCpf" value={this.state.model.nrCpf} maxLength={14} required={true} onChange={this.handleChangeModel} disabled={this.state.model.idPessoa || !this.state.editable} />
											<InputGroupButton>
												<BtnButton disabled={true && !this.state.model?.dtNascimento && !Validator.isCPF(this.state.model?.nrCpf)} onClick={this.handleBuscarCpfDtNascimento}> <IconSearch /> </BtnButton>
											</InputGroupButton>
										</InputGroup>
									</FormItem>

									<FormItem md={3} label="Passaporte" show={this.state.model.tipoPessoa?.cdTipoPessoa === "E"}>
										<InputText name="nrPassaporte" value={this.state.model.nrPassaporte} maxLength={100} required={true} onChange={this.handleChangeModel} disabled={!this.state.editable} />
									</FormItem>

									<FormItem md={2} label="Ativo?">
										<BtnRadioGroupSimNao name="flAtivo" value={this.state.model.flAtivo} required={true} onChange={this.handleChangeModel} disabled={!this.state.editable} />
									</FormItem>

									<FormItem md={3} label="Última Localização">
										<InputMapOneMarker apiKey={ConfigApp.getInstance().clientIdGoogleMaps}
											lng={this.state.model?.localizacao?.vlLongitude}
											lat={this.state.model?.localizacao?.vlLatitude}
											required={false}
											readOnly={true} />
									</FormItem>
								</Row>

								{this.state.model.tipoPessoa?.cdTipoPessoa === "F" ?
									<Div className="pt-3 px-2">
										<H6 className="border-dark border-bottom mb-2 pb-1 fw-bold"> Dados da Pessoa Física </H6>
										<Row className="g-3 py-2">
											<FormItem md={12} label="Nome Pessoa Física">
												<InputText name="nmPessoaFisica" autoFocus={this.state.model.idPessoa} value={this.state.model.nmPessoaFisica} maxLength={250} required={true} onChange={this.handleChangeModel} disabled={!this.state.editable} />
											</FormItem>
											<FormItem md={6} label="Nome Social" show={false}>
												<InputText name="nmPessoaSocial" value={this.state.model.nmPessoaSocial} maxLength={250} required={false} onChange={this.handleChangeModel} disabled={!this.state.editable} />
											</FormItem>
											<FormItem md={4} label="Identidade" help="Número da Identidade">
												<InputText name="cdRg" value={this.state.model.cdRg} maxLength={100} required={false} onChange={this.handleChangeModel} disabled={!this.state.editable} />
											</FormItem>
											<FormItem md={4} label="Local Emissão" help="Local de Emissão da Identidade">
												<InputText name="dsRgLocal" value={this.state.model.dsRgLocal} maxLength={100} required={false} onChange={this.handleChangeModel} disabled={!this.state.editable} />
											</FormItem>
											<FormItem md={4} label="Data de Emissão" help="Data de Emissão da Identidade">
												<InputDate name="dtRgExpedicao" value={this.state.model.dtRgExpedicao} required={false} onChange={this.handleChangeModel} disabled={!this.state.editable} />
											</FormItem>

											<FormItemPaisEstadoCidade md={[2, 4, 6]} name="cidade" value={this.state?.model?.cidade} required={false} onChange={this.handleChangeModel}
												buttons={[{ search: false, new: false, edit: false, clear: false }, { search: false, new: false, edit: false, clear: false }, { search: true, new: false, edit: false, clear: true }]}
												label={["País Nasc.", "Estado Nasc.", "Cidade Nasc."]}
											/>

											<FormItem md={4} label="Nacionalidade" help="Nacionalidade da Pessoa">
												<SelectNacionalidade name="nacionalidade" disabled={!this.state.editable} value={this.state.model.nacionalidade} required={false} onChange={this.handleChangeModel} placeholder="Selecione a Nacionalidade" />
											</FormItem>

											<FormItem md={4} label="Estado Civil" help="Estado Civil da Pessoa">
												<SelectEstadoCivil name="estadoCivil" disabled={!this.state.editable} value={this.state.model.estadoCivil} required={false} onChange={this.handleChangeModel} placeholder="Selecione o Estado Civil" buttons={{ search: false, new: false, edit: false }} />
											</FormItem>

											<FormItem md={4} label="Gênero" help="Gênero da Pessoa">
												<SelectGenero name="genero" disabled={!this.state.editable} value={this.state.model.genero} required={false} onChange={this.handleChangeModel} placeholder="Selecione o Gênero" buttons={{ search: false, new: false, edit: false }} />
											</FormItem>

											<FormItem md={4} label="CTPS" help="Número da Carteira de Trabalho e Previdência Social">
												<InputText name="cdCtps" value={this.state.model.cdCtps} maxLength={100} required={false} onChange={this.handleChangeModel} disabled={!this.state.editable} />
											</FormItem>

											<FormItem md={4} label="PIS" help="Número do PIS (Programa de Integração Social)">
												<InputText name="cdPis" value={this.state.model.cdPis} maxLength={100} required={false} onChange={this.handleChangeModel} disabled={!this.state.editable} />
											</FormItem>

											<FormItem md={4} label="Título de Eleitor" help="Número do Título de Eleitor">
												<InputText name="cdTituloEleitor" value={this.state.model.cdTituloEleitor} maxLength={100} required={false} onChange={this.handleChangeModel} disabled={!this.state.editable} />
											</FormItem>

											<FormItem md={4} label="Reservista" help="Número da Carteira de Reservista">
												<InputText name="cdReservista" value={this.state.model.cdReservista} maxLength={100} required={false} onChange={this.handleChangeModel} disabled={!this.state.editable} />
											</FormItem>

										</Row>
									</Div> : <></>}

								{this.state.model.tipoPessoa?.cdTipoPessoa === "E" ?
									<Div className="pt-3 px-2">
										<H6 className="border-dark border-bottom mb-2 pb-1 fw-bold"> Dados da Pessoa Estrangeria </H6>
										<Row className="g-3 py-2">
											<FormItem md={12} label="Nome Pessoa Física">
												<InputText name="nmPessoaFisica" autoFocus={this.state.model.idPessoa} value={this.state.model.nmPessoaFisica} maxLength={250} required={true} onChange={this.handleChangeModel} disabled={!this.state.editable} />
											</FormItem>
											<FormItem md={6} label="Nome Social" show={false}>
												<InputText name="nmPessoaSocial" value={this.state.model.nmPessoaSocial} maxLength={250} required={false} onChange={this.handleChangeModel} disabled={!this.state.editable} />
											</FormItem>
											<FormItem md={3} label="Data de Nascimento">
												<InputDate name="dtNascimento" value={this.state.model.dtNascimento} required={true} onChange={this.handleChangeModel} disabled={!this.state.editable} />
											</FormItem>
											<FormItem md={4} label="Passaporte">
												<InputText name="nrPassaporte" value={this.state.model.nrPassaporte} maxLength={100} required={false} onChange={this.handleChangeModel} disabled={!this.state.editable} />
											</FormItem>
											<FormItem md={4} label="Nacionalidade">
												<SelectNacionalidade name="nacionalidade" disabled={!this.state.editable} value={this.state.model.nacionalidade} required={false} onChange={this.handleChangeModel} placeholder="Selecione a Nacionalidade" buttons={{ search: false, new: false, edit: false }} />
											</FormItem>
										</Row>
									</Div> : <></>}

								{this.state.model.tipoPessoa?.cdTipoPessoa === "J" ?
									<Div className="pt-3 px-2">
										<H6 className="border-dark border-bottom mb-2 pb-1 fw-bold"> Dados da Pessoa Jurídica </H6>
										<Row className="g-3 py-2">
											<FormItem md={9} label="Nome Empresarial">
												<InputText name="nmEmpresarial" autoFocus={this.state.model.idPessoa} value={this.state.model.nmEmpresarial} maxLength={250} required={true} onChange={this.handleChangeModel} disabled={!this.state.editable} />
											</FormItem>
											<FormItem md={3} label="Data de Abertura">
												<InputDate name="dtAbertura" value={this.state.model.dtAbertura} required={true} onChange={this.handleChangeModel} disabled={!this.state.editable} />
											</FormItem>
											<FormItem md={9} label="Nome Fantasia">
												<InputText name="nmFantasia" value={this.state.model.nmFantasia} maxLength={250} required={false} onChange={this.handleChangeModel} disabled={!this.state.editable} />
											</FormItem>
											<FormItem md={3} label="Tipo">
												<BtnRadioGroup name="cdTipoEstabelecimento" color={BtnRadioGroup.color.primary} opcional={true} value={this.state.model.cdTipoEstabelecimento} onClick={this.handleChangeModel} disabled={!this.state.editable}>
													<BtnRadio value="MATRIZ" label="Matriz" />
													<BtnRadio value="FILIAL" label="Filial" />
												</BtnRadioGroup>
											</FormItem>
										</Row>
										<Div className="pt-3 px-2">
											<H6 className="border-dark border-bottom mb-2 pb-1 fw-bold"> Fiscal </H6>
											<Row className="g-3 py-2">
												<FormItem md={6} label="Regime Tributário">
													<SelectTipoRegimeTributario name="tipoRegimeTributario" disabled={!this.state.editable} value={this.state.model.tipoRegimeTributario} required={false} onChange={this.handleChangeModel} placeholder="Selecione o Tipo do Regime Tributário" buttons={{ search: false, new: false, edit: false }} />
												</FormItem>

												<FormItem md={6} label="Regime Tributário Especial">
													<SelectTipoRegimeTributarioEspecial name="tipoRegimeTributarioEspecial" disabled={!this.state.editable} value={this.state.model.tipoRegimeTributarioEspecial} required={false} onChange={this.handleChangeModel} placeholder="Selecione o Tipo do Regime Tributário" buttons={{ search: false, new: false, edit: false }} />
												</FormItem>

												<FormItem md={4} label="Inscrição Municipal">
													<InputText name="nrInscricaoMunicipal" value={this.state.model.nrInscricaoMunicipal} maxLength={100} required={false} onChange={this.handleChangeModel} disabled={!this.state.editable} />
												</FormItem>
											</Row>
											<Row className="g-0 py-2">
												<InputCheckSwitch label="É Optante pelo simples nacional?" name="flOptanteSimplesNacional" checked={this.state.model.flOptanteSimplesNacional} onChange={this.handleChangeModel} />
												<InputCheckSwitch label="É Incentivador Cultural?" name="flIncentivadorCultural" checked={this.state.model.flIncentivadorCultural} onChange={this.handleChangeModel} />
												<InputCheckSwitch label="Possuí algum tipo de incentivo fiscal?" name="flIncentivoFiscal" checked={this.state.model.flIncentivoFiscal} onChange={this.handleChangeModel} />
												<InputCheckSwitch label="Essa empresa é um orgão público?" name="flOrgaoPublico" checked={this.state.model.flOrgaoPublico} onChange={this.handleChangeModel} />
												<InputCheckSwitch label="É um Microempreendedor Individual (MEI)?" name="flMei" checked={this.state.model.flMei} onChange={this.handleChangeModel} />
											</Row>
										</Div>
									</Div>
									: <></>}

								<Row className="g-3 pb-2 px-2 pt-3">
									<Col md={12}>
										{parseInt(this.state.model?.arquivo?.idArquivo) > 0 && this.state.model.arquivo?.cdUid && this.state.model.arquivo?.arquivoBase?.cdHash ?
											<Div>
												<Div>
													<img style={{ maxHeight: "150px" }} src={ConfigApp.getUrlArquivo() + "public/inline/" + this.state.model.arquivo.cdUid + "/" + this.state.model.arquivo.arquivoBase.cdHash} className="img-fluid img-thumbnail" alt="..." />
												</Div>
												<Div>
													<BtnButton color={BtnButton.color.danger} sizing={Button.sizing.sm} onClick={this.handleDeleteArquivo}> <IconDelete /> Excluir Foto </BtnButton>
												</Div>
											</Div>
											: <BtnButton sizing={Button.sizing.default} multiple={false} onClick={this.handleArquivo} color={BtnButton.color.outlinePrimary}> <IconCamera /> Enviar Foto </BtnButton>}
									</Col>
								</Row>

								<ListDadoValorByParentForm cdDadoLocal="PESSOA" parent={this.state.model} onChange={this.handleChangeModel} name="pessoaDadoValor" />

								<BtnSubmit hidden > Enviar </BtnSubmit>
							</Form>
						</Tab>
						<Tab show={Permissao.getInstance().getPermissoes([36])} title={<><IconEnvelope /> E-mail</>} disabled={!this.state.model.idPessoa} opened={false} className="m-2">
							<ListPessoaEmail editable={this.state.editable} parent={this.state.model} localOpen={BaseList.localOpen.modal} />
						</Tab>
						<Tab show={Permissao.getInstance().getPermissoes([37])} title={<><IconTelephone /> Telefone</>} disabled={!this.state.model.idPessoa} opened={false} className="m-2">
							<ListPessoaTelefone editable={this.state.editable} parent={this.state.model} localOpen={BaseList.localOpen.modal} />
						</Tab>
						<Tab show={Permissao.getInstance().getPermissoes([38])} title={<><IconMailboxFlag /> Endereço</>} disabled={!this.state.model.idPessoa} opened={false} className="m-2">
							<ListPessoaEndereco editable={this.state.editable} parent={this.state.model} localOpen={BaseList.localOpen.modal} />
						</Tab>
						<Tab show={Permissao.getInstance().getPermissoes([106])} title={<><IconLink45deg /> Página</>} disabled={!this.state.model.idPessoa} opened={false} className="m-2">
							<ListPessoaPagina editable={this.state.editable} parent={this.state.model} localOpen={BaseList.localOpen.modal} />
						</Tab>
						<Tab show={Permissao.getInstance().getPermissoes([174])} title={<><IconShopWindow /> Unidade</>} disabled={!this.state.model.idPessoa} opened={false} className="m-2">
							<ListPessoaUnidade editable={this.state.editable} parent={this.state.model} localOpen={BaseList.localOpen.modal} />
						</Tab>
						<Tab show={Permissao.getInstance().getPermissoes([175])} title={<><IconCarFront /> Veículo</>} disabled={!this.state.model.idPessoa} opened={false} className="m-2">
							<ListPessoaVeiculo editable={this.state.editable} parent={this.state.model} localOpen={BaseList.localOpen.modal} />
						</Tab>
						<Tab show={Permissao.getInstance().getPermissoes([182])} title={<><IconPinMapFill /> Localização</>} disabled={!this.state.model.idPessoa} opened={false} className="m-2">
							<ListPessoaLocalizacao editable={this.state.editable} parent={this.state.model} localOpen={BaseList.localOpen.modal} />
						</Tab>
						<Tab show={Permissao.getInstance().getPermissoes([223])} title={<><IconCamera /> Foto</>} disabled={!this.state.model.idPessoa} opened={false} className="m-2">
							<ListPessoaFoto editable={this.state.editable} parent={this.state.model} localOpen={BaseList.localOpen.modal} />
						</Tab>
						<Tab show={Permissao.getInstance().getPermissoes([225])} title={<><IconFilePerson /> Documento</>} disabled={!this.state.model.idPessoa} opened={false} className="m-2">
							<ListPessoaDocumento editable={this.state.editable} parent={this.state.model} localOpen={BaseList.localOpen.modal} />
						</Tab>
						<Tab show={Permissao.getInstance().getPermissoes([228])} title={<><IconPeopleFill /> Conjuge</>} disabled={!this.state.model.idPessoa} opened={false} className="m-2">
							<ListPessoaConjuge editable={this.state.editable} parent={this.state.model} localOpen={BaseList.localOpen.modal} />
						</Tab>
						<Tab show={Permissao.getInstance().getPermissoes([232])} title={<><IconPersonFillUp /> Filiação</>} disabled={!this.state.model.idPessoa} opened={false} className="m-2">
							<ListPessoaFiliacao editable={this.state.editable} parent={this.state.model} localOpen={BaseList.localOpen.modal} />
						</Tab>

						<Tab show={this.state.model.tipoPessoa?.cdTipoPessoa === "J" && Permissao.getInstance().getPermissoes([140, 141])} title={<><IconArchiveFill /> Fiscal</>} disabled={!this.state.model.idPessoa} opened={false} className="p-0 m-0">
							<TabNavigator className="border-light">
								<Tab show={Permissao.getInstance().getPermissoes([140])} title={<><IconSignpost /> Atividades</>} disabled={!this.state.model.idPessoa} opened={false} className="m-2">
									<ListPessoaAtividade editable={this.state.editable} parent={this.state.model} localOpen={BaseList.localOpen.modal} />
								</Tab>
								<Tab show={Permissao.getInstance().getPermissoes([141])} title={<><IconBank /> Inscrição Estadual</>} disabled={!this.state.model.idPessoa} opened={false} className="m-2">
									<ListPessoaInscricaoEstadual editable={this.state.editable} parent={this.state.model} localOpen={BaseList.localOpen.modal} />
								</Tab>
							</TabNavigator>
						</Tab>

						<Tab show={Permissao.getInstance().getPermissoes([202])} title={<><IconBell /> Notificações</>} disabled={!this.state.model.idPessoa} opened={false} className="m-2">
							<ListPessoaTipoNotificacao parent={this.state.model} localOpen={BaseList.localOpen.modal} />
						</Tab>

						<Tab show={Permissao.getInstance().getPermissoes([117])} title={<><IconPatchPlus /> Complementar</>} disabled={!this.state.model.idPessoa} opened={false} className="p-0 m-0">
							<ListDadoValorByParent cdDadoLocal="PESSOA" editable={this.state.editable} parent={this.state.model} localOpen={BaseList.localOpen.modal} />
						</Tab>
					</TabNavigator>
				</PanelBody>
				<PanelFooter>
					<FormButtons show={this.state.tabIndex === 0 && this.props.localOpen !== BaseCrud.localOpen.embedded} onClose={this.handleClose} onSubmit={this.handleSubmitForm} disabled={[!this.state.editable]} />
				</PanelFooter>
			</Panel>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <FormPessoa {...props} navigate={navigate} params={params} location={location} />
}
export default With