//#NO-CHANGE-FILE#

import Config from "./Config";
import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import SelectCargo from "../../components/SelectDB/SelectCargo";
import { BaseCrud } from "../../siht/base";
import { FormButtons, FormItem, Row, Panel, PanelBody, PanelFooter, Container, ModalDialog } from "../../siht/components";
import { InputText, InputNumberFormat, Form } from "../../siht/elements";
import BtnRadioGroupTipoValor from "../../components/BtnRadioGroupDB/BtnRadioGroupTipoValor";

class FormComissaoCargo extends BaseCrud {

	constructor(props) {
		super(props);
		this.init = this.init.bind(this);
		this.initModel = this.initModel.bind(this);
		this.handleSuccess = this.handleSuccess.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleChangeModel = this.handleChangeModel.bind(this);
		this.handleSubmitForm = this.handleSubmitForm.bind(this);
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		super.init(Config);
	}

	initModel(){
		return {
			idComissaoCargo : null,
			tipoValor : null,
			comissao : this.props.parent !== undefined ? this.props.parent : {},
			cargo : null,
			vlValor : 0
		}
	}

	handleSuccess(e) {
		super.handleSuccess(e, Config);
	}

	handleClose(e) {
		super.handleClose(e, Config);
	}

	render() {
		return super.render(
			<Form onSubmit={this.handleSuccess} refParam={f => (this.form = f)} key={this.state.key}>
				<Panel key={this.state.key} title="Cadastro de Comissão Cargo" localOpen={this.props.localOpen}  onClose={this.handleClose} isModal={this.isModal()} container={{sizing: Container.sizing.xxl, className : ""}} modal={{sizing: this.props.sizing ? this.props.sizing : ModalDialog.sizing.xl}}>
					<PanelBody>
						<Row className="g-3 pb-2 px-2">
							<FormItem md={6} label="Código">
								<InputText name="idComissaoCargo" value={this.state.model.idComissaoCargo} readOnly={true} className="readonly-field" required={false} onChange={this.handleChangeModel}/>
							</FormItem>
							<FormItem md={3} label="Tipo de Valor">
								<BtnRadioGroupTipoValor defaultIndex={0} name="tipoValor" value={this.state.model.tipoValor} required={true} onClick={this.handleChangeModel} placeholder="Selecione o Tipo de Valor"/>
							</FormItem>
							<FormItem md={3} label="Valor" show={this.state.model.tipoValor?.cdTipoValor === "VALOR"}>
								<InputNumberFormat name="vlValor" value={this.state.model.vlValor} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} prefix="R$ " required={true} onChange={this.handleChangeModel}/>
							</FormItem>
							<FormItem md={3} label="Valor" show={this.state.model.tipoValor?.cdTipoValor === "PERCENTUAL"}>
								<InputNumberFormat name="vlValor" value={this.state.model.vlValor} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} suffix=" %" required={true} onChange={this.handleChangeModel}/>
							</FormItem>
							<FormItem md={12} label="Cargo">
								<SelectCargo name="cargo" value={this.state.model.cargo} required={true} onChange={this.handleChangeModel} placeholder="Selecione Nome do Cargo"/>
							</FormItem>									
						</Row>
					</PanelBody>
					<PanelFooter>
						<FormButtons onClose={this.handleClose} />
					</PanelFooter>
				</Panel>
			</Form>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <FormComissaoCargo {...props} navigate={navigate} params={params} location={location}/>
}
export default With