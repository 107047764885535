//#NO-CHANGE-FILE#

import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import ListContaPagarParcela from "../ContaPagarParcela/ListContaPagarParcela";
import ListContaPagarArquivo from "../ContaPagarArquivo/ListContaPagarArquivo";
import Permissao from "../../controllers/Permissao";
import { BaseList, BaseCrud } from "../../siht/base";
import { FormButtons, FormItem, Row, TabNavigator, Tab, Panel, PanelBody, PanelFooter, Container, ModalDialog, ProgressBar } from "../../siht/components";
import { InputText, TextArea, Form, BtnSubmit, H6, InputDate, InputNumberFormat } from "../../siht/elements";
import Config from "./Config";
import FormRecorrencia from "../Recorrencia/FormRecorrencia";
import FormItemTipoPessoaPessoa from "../../components/FormItem/FormItemTipoPessoaPessoa";
import FormItemEmpresaUnidade from "../../components/FormItem/FormItemEmpresaUnidade";
import SelectTipoContaPagar from "../../components/SelectDB/SelectTipoContaPagar";
import { StatusCustom } from "../../components/Custom";

class FormContaPagar extends BaseCrud {

	constructor(props) {
		super(props);
		this.init = this.init.bind(this);
		this.initModel = this.initModel.bind(this);
		this.handleSuccess = this.handleSuccess.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleChangeModel = this.handleChangeModel.bind(this);
		this.handleSubmitForm = this.handleSubmitForm.bind(this);
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		super.init(Config);
	}

	initModel(){

		var tzoffset = (new Date()).getTimezoneOffset() * 60000;
        var dtContaPagar = new Date();

		var unidade = null;

		if(this.props.contaPagarVeiculo && this.props.contaPagarVeiculo.veiculo && this.props.contaPagarVeiculo.veiculo?.unidade){
			unidade = this.props.contaPagarVeiculo.veiculo?.unidade;
		}

		return {
			idContaPagar : null,
			tipoContaPagar : null,
			contaPagarVeiculo : this.props.contaPagarVeiculo || null,
			pessoa : null,
			unidade : unidade,
			usuario : null,
			recorrencia : null,
			nmContaPagar : "",
			dtContaPagar : new Date(dtContaPagar - tzoffset).toISOString().slice(0, 10),
			txObservacao : "",
			flPodeEditar : true,
			flPodeExcluir : true,
			contaPagarParcela : []
		}
	}

	handleSuccess(e) {
		super.handleSuccess(e, Config);
	}

	handleClose(e) {
		super.handleClose(e, Config);
	}

	render() {

		return super.render(
			<Panel key={this.state.key} title="Cadastro de Conta a Pagar" onClose={this.handleClose} isModal={this.isModal()} localOpen={this.props.localOpen} container={{sizing: Container.sizing.xxl, className : ""}} modal={{sizing: this.props.sizing ? this.props.sizing : ModalDialog.sizing.xl}}>
				<PanelBody className="p-0 m-0">
					<TabNavigator key={this.state.key} onChangeTab={this.handleChangeTab} className="border-light">
						<Tab title="Conta a Pagar" className="m-2">

							<Form onSubmit={this.handleSuccess} refParam={f => (this.form = f)}>
								<Row className="g-3 py-2 px-2" show={this.state.model.idContaPagar}>
									<FormItem md={12} label="Progresso do Pagamento">
										<ProgressBar height="30px" opcional={true} color={ProgressBar.color.success} progress={((this.state.model?.nrParcelaPaga/this.state.model?.recorrencia?.nrParcela)*100)} label={`${parseInt((this.state.model?.nrParcelaPaga/this.state.model?.recorrencia?.nrParcela)*100)}%`}/>
									</FormItem>
								</Row>

								{/* CONTA PAGAR VEÍCULO */}

								<Row className="g-3 pt-0 pb-0 px-2" show={this.state.model.contaPagarVeiculo !== null}>
									<H6 className="border-dark border-bottom fw-bold"> Veículo </H6>
								</Row>

								<Row className="g-3 pb-2 px-2" show={this.state.model.contaPagarVeiculo !== null}>
									<FormItem md={5} label="Veículo">
										<InputText value={`${this.state.model.contaPagarVeiculo?.veiculo?.modelo?.marca?.nmMarca} - ${this.state.model.contaPagarVeiculo?.veiculo?.modelo?.nmModelo}`} readOnly={true} className="fw-bold"/>
									</FormItem>
									<FormItem md={3} label="Placa">
										<InputText value={`${this.state.model.contaPagarVeiculo?.veiculo?.veiculoPlaca ? `${this.state.model.contaPagarVeiculo.veiculo.veiculoPlaca?.dsPlaca} - ${this.state.model.contaPagarVeiculo.veiculo.veiculoPlaca?.pais?.nmPais}` : ""}`} readOnly={true} className="fw-bold"/>
									</FormItem>
									<FormItem md={2} label="Odômetro">
										<InputNumberFormat value={this.state.model.contaPagarVeiculo?.veiculo.vlQuilometragem} decimalScale={0} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} suffix=" KM" placeholder="99.999" required={false} readOnly={true} className="fw-bold"/>
									</FormItem>
									<FormItem md={2} label="Odômetro">
										<InputNumberFormat name="vlQuilometragem" value={this.state.model.contaPagarVeiculo?.vlQuilometragem} decimalScale={0} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} suffix=" KM" placeholder="99.999" required={true} onChange={(e,v,p)=> this.handleChange(e,v,p, "model.contaPagarVeiculo")}/>
									</FormItem>
								</Row>

								<Row className="g-3 pt-2 pb-0 px-2" show={this.props.contaPagarVeiculo !== null}>
									<H6 className="border-dark border-bottom fw-bold"> Conta </H6>
								</Row>

								<Row className="g-3 pb-2 px-2">
									<FormItem md={2} label="Código">
										<InputText name="idContaPagar" value={this.state.model.idContaPagar} readOnly={true} className="readonly-field" required={false} onChange={this.handleChangeModel}/>
									</FormItem>

									<FormItem md={8} label="Título da Conta a Pagar">
										<InputText name="nmContaPagar" value={this.state.model.nmContaPagar} autoFocus={true} maxLength={100} required={true} onChange={this.handleChangeModel} disabled={!this.state.model.flPodeEditar}/>
									</FormItem>

									<FormItem md={2} label="Data da Conta">
										<InputDate name="dtContaPagar" value={this.state.model.dtContaPagar} required={true} opcional={true}  className="text-center"  onChange={this.handleChangeModel} disabled={!this.state.model.flPodeEditar}/>
									</FormItem>

									<FormItem md={8} label="Tipo da Conta">
										<SelectTipoContaPagar name="tipoContaPagar" value={this.state.model.tipoContaPagar} required={false} onChange={this.handleChangeModel} placeholder="Selecione o Tipo da Conta" disabled={!this.state.model.flPodeEditar}/>
									</FormItem>

									<FormItem md={4} label="Status">	
										<StatusCustom show={this.state.model.idContaPagar} opcional={true} type="InputText" color={this.state.model?.vwContaPagar?.statusConta}>{this.state.model?.vwContaPagar?.statusConta?.nmStatusConta}</StatusCustom>
										<StatusCustom show={!this.state.model.idContaPagar} opcional={true} type="InputText" color={{cdCorFundo : "#FFFFFF", cdCorTexto : "#079600", cdCorBorda : "#079600"}}> NOVA CONTA </StatusCustom>
									</FormItem>

									<FormItem md={12} label="Observação">
										<TextArea name="txObservacao" value={this.state.model.txObservacao} required={false} onChange={this.handleChangeModel} disabled={!this.state.model.flPodeEditar}/>
									</FormItem>

									<FormItemEmpresaUnidade disabled={this.state.model.idContaPagar || !this.state.model.flPodeEditar} md={[6,6]} name="unidade" value={this.state.model.unidade} required={true} onChange={this.handleChangeModel}/>

									<FormItemTipoPessoaPessoa type={["BtnRadioGroup"]} label={["Tipo Fornecedor", "Fornecedor"]} md={[4,8]} name="pessoa" value={this.state.model.pessoa} required={false} onChange={this.handleChangeModel} disabled={!this.state.model.flPodeEditar}/>
								</Row>

								<Row className="g-3 pb-0 pt-2 px-2">
									<H6 className="border-dark border-bottom fw-bold"> Recorrência </H6>
								</Row>

								<FormRecorrencia name="recorrencia" onChange={this.handleChangeModel} value={this.state.model.recorrencia} disabled={!this.state.model.flPodeEditar}/>

								<BtnSubmit hidden > Enviar </BtnSubmit>
							</Form>
							
						</Tab>
						<Tab show={Permissao.getInstance().getPermissoes([190])} title="Parcelas" disabled={!this.state.model.idContaPagar} opened={false} className="m-2">
							<ListContaPagarParcela parent={this.state.model} localOpen={BaseList.localOpen.modal} editable={this.state.model.flPodeEditar}/>
						</Tab>
						<Tab show={Permissao.getInstance().getPermissoes([190])} title="Arquivos" disabled={!this.state.model.idContaPagar} opened={false} className="m-2">
							<ListContaPagarArquivo parent={this.state.model} localOpen={BaseList.localOpen.modal}/>
						</Tab>
					</TabNavigator>
				</PanelBody>
				<PanelFooter>
					<FormButtons show={this.state.tabIndex === 0} onClose={this.handleClose} onSubmit={this.handleSubmitForm} disabled={[!this.state.model.flPodeEditar]}/>
				</PanelFooter>
			</Panel>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <FormContaPagar {...props} navigate={navigate} params={params} location={location}/>
}
export default With