//#NO-CHANGE-FILE#

import Config from "./Config";
import Util from "../../utils/Util";
import React from "react";
import { useNavigate, useLocation, useParams } from "react-router";
import { BaseList } from "../../siht/base";
import { DataGrid, DataGridButtons, DataGridColumn, Panel, Container, Row, InputGroup, InputGroupText, InputGroupButton, PanelFooter, Badge, FormItem, BtnRadioGroup } from "../../siht/components";
import { Button, Div, Form, InputSearch, BtnSubmit, BtnButton, BtnRadio } from "../../siht/elements";
import { Link } from "react-router-dom";
import { Envelope as IconEnvelope, EnvelopeCheckFill as IconEnvelopeCheckFill, EnvelopeOpen as IconEnvelopeOpen, Link45deg as IconLink45deg } from "react-bootstrap-icons";
import { IconClear, IconSearch } from "../../icons";

class ListNotificacaoSistema extends BaseList {

	constructor(props) {
		super(props);
		this.getItens = this.getItens.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleChangeFilter = this.handleChangeFilter.bind(this);
		this.handleNew = this.handleNew.bind(this);
		this.handleEdit = this.handleEdit.bind(this);
		this.handleCopy = this.handleCopy.bind(this);
		this.handleMarcarComoLida = this.handleMarcarComoLida.bind(this);
		this.handleMore = this.handleMore.bind(this);
		this.onSubmitFilter = this.onSubmitFilter.bind(this);
		this.handleClear = this.handleClear.bind(this);
		this.handleFormSuccess = this.handleFormSuccess.bind(this);
		this.handleFormClose = this.handleFormClose.bind(this);
		this.initStateFilter = this.initStateFilter.bind(this);

	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	initStateFilter(){

        return {
			cdStatusNotificacao : "T",
            search: "",
            page : 1,
            pageSize : 30,
        };
    }

	init(){
		super.init(Config);
	}

	getItens(more){
		super.getItens(Config.getService().list, false, more);
	}

	handleNew(e) {
		super.handleNew(e, Config);
	}

	handleEdit(e, item) {
		super.handleEdit(e, Config, item);
	}

	handleMarcarComoLida(e, item = null) {
		if(item){
			item.flLida = true;
			Config.getService().save(item, () => this.getItens(false));
		}else{
			this.state.selecteds.filter(i => !i.flLida).forEach(item => {
				item.flLida = true;
				Config.getService().save(item, ()=> {
					//this.setState(this.state.selecteds);
				});
			});	
		}
		
	}

	handleCopy(e, item) {
		super.handleCopy(e, Config, item);
	}

	getFormEmbedded(){
		return super.getFormEmbedded(Config);
	}

	render() {
		return super.render(
			<Panel title="Lista de Notificações do Sistema" isModal={this.isModal()} container={{sizing: Container.sizing.xxl, className : ""}}>
				<Div className="d-flex justify-content-end flex-nowrap gap-2">
					<BtnButton disabled={this.state.selecteds.filter(i => !i.flLida).length === 0} sizing={Button.sizing.default} onClick={e => this.handleMarcarComoLida(e)}> Marcar Selecionadas como Lida </BtnButton>
				</Div>

				<Form onSubmit={this.onSubmitFilter}>

					<FormItem md={4} label="Status Notificação">
						<BtnRadioGroup name="cdStatusNotificacao" color={BtnRadioGroup.color.primary} opcional={true} value={this.state.filter.cdStatusNotificacao} onClick={(e,v,p) => this.handleChangeFilter(e,v,p, e => this.getItens(false))}>
							<BtnRadio value="T" label="Todas" />
							<BtnRadio value="N" label="Não Lida" />
							<BtnRadio value="L" label="Lida" />
						</BtnRadioGroup>
					</FormItem>

					<Row className="mt-1 mb-2 g-3">
						<InputGroup className="flex-nowrap" sizing={InputGroup.sizing.default}>
							<InputGroupText><IconSearch/></InputGroupText>
							<InputSearch autoComplete="off" autoFocus placeholder="Digite sua busca" name="search" value={this.state.filter.search} required={false} opcional={true} onChange={(e,v,n) => this.handleChange(e,v,n, this.state.filter)}/>
							<InputGroupButton>
								<BtnSubmit title="Filtrar"> Buscar </BtnSubmit>
								<BtnButton title="Limpar Pesquisa" color={BtnButton.color.outlineDanger} onClick={this.handleClear}> <IconClear/> </BtnButton>
							</InputGroupButton>
						</InputGroup>
					</Row>
				</Form>

				<DataGrid responsive={true} data={this.state.data} search={false} isDisabled={i => false} sizing={DataGrid.sizing.sm} select={true} selecteds={this.state.selecteds} onSelect={(selecteds)=>{this.setState({selecteds : selecteds})}} getItemValue={i=>i.idNotificacaoSistema} onMore={this.handleMore}>

					<DataGridColumn field={i => <>
						{i.flLida ? <IconEnvelopeOpen width="36" height="36" className="text-secondary"/> : <IconEnvelope className="text-primary" width="36" height="36"/>}
					</>} className="text-start"/>

					<DataGridColumn col={12} label="Notificação" field={i => <>
						<Div className={`fw-bold ${i.flLida ? "text-secondary" : "text-primary"}`} > <div className="mb-1" dangerouslySetInnerHTML={{ __html: Util.nl2br(i.dsTitulo) }} /> </Div>
						<Div className={`fs-7 ${i.flLida ? "" : "fw-semibold"}`}> <div className="mb-1" dangerouslySetInnerHTML={{ __html: Util.nl2br(i.txMensagem) }} /></Div>
						<Div className="fs-7"> {i.dsUrl} </Div>
						<Div className="fs-7"> {Util.date2Br(i.dhInsert, true)} </Div>						
					</>} className="text-start"/>

					<DataGridColumn field={i => <>
						{!i.flLida ? <Badge color={Badge.color.warning}> não lida </Badge> : <></>}
					</>} className="text-start"/>

					<DataGridButtons>
						<BtnButton color={BtnButton.color.outlineWarning} disabled={i=> i.flLida} onClick={this.handleMarcarComoLida}> <IconEnvelopeCheckFill/> </BtnButton>
						<Link to={i => i.dsUrl} disabled={i => !i.dsUrl} className="btn btn-primary"> <IconLink45deg/> </Link>
					</DataGridButtons>
				</DataGrid>

				<PanelFooter className="text-center py-2" show={this.state.data && this.state.data?.length > 0}>
					<BtnButton onClick={this.handleMore} disabled={!this.state.hasMore}> Buscar Mais Itens </BtnButton>
				</PanelFooter>
			</Panel>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <ListNotificacaoSistema {...props} navigate={navigate} params={params} location={location}/>
}
export default With