import Http from "../controllers/Http";

export default class FaturaNotaFiscalItemService {

	static urlBase = "/faturaNotaFiscalItem/";

	static getForCombo(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(FaturaNotaFiscalItemService.urlBase + 'forCombo', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static list(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(FaturaNotaFiscalItemService.urlBase + 'list', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneById(id, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.get(FaturaNotaFiscalItemService.urlBase + id)
			.then(fnSucess)
			.catch(fnError);
	}

	static create(data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(FaturaNotaFiscalItemService.urlBase, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static update(id, data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.put(FaturaNotaFiscalItemService.urlBase + id, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static delete(id, fnSucess = () => { }, fnError = () => { }) {
		if(Number.isInteger(id)){
			Http.getInstanceAppJson()
				.delete(FaturaNotaFiscalItemService.urlBase + id)
				.then(fnSucess)
				.catch(fnError);
		}else{
			Http.getInstanceAppJson()
			.post(FaturaNotaFiscalItemService.urlBase + 'delete', id)
			.then(fnSucess)
			.catch(fnError);
		}
	}

	static save(data, fnSucess = () => { }, fnError = () => { }) {
		if (data.idFaturaNotaFiscalItem) {
			this.update(data.idFaturaNotaFiscalItem, data, fnSucess, fnError);
		} else {
			this.create(data, fnSucess, fnError);
		}
	}

	static getAllByFaturaNotaFiscal(faturaNotaFiscal, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(FaturaNotaFiscalItemService.urlBase + 'faturaNotaFiscal', faturaNotaFiscal)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByFaturaNotaFiscal(faturaNotaFiscal, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(FaturaNotaFiscalItemService.urlBase + 'faturaNotaFiscal/' + faturaNotaFiscal.idFaturaNotaFiscal)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByUnidadeMedida(unidadeMedida, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(FaturaNotaFiscalItemService.urlBase + 'unidadeMedida', unidadeMedida)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByUnidadeMedida(unidadeMedida, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(FaturaNotaFiscalItemService.urlBase + 'unidadeMedida/' + unidadeMedida.idUnidadeMedida)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByFaturaNotaFiscalItemIss(faturaNotaFiscalItemIss, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(FaturaNotaFiscalItemService.urlBase + 'faturaNotaFiscalItemIss', faturaNotaFiscalItemIss)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByFaturaNotaFiscalItemIss(faturaNotaFiscalItemIss, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(FaturaNotaFiscalItemService.urlBase + 'faturaNotaFiscalItemIss/' + faturaNotaFiscalItemIss.idFaturaNotaFiscalItemIss)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByFaturaNotaFiscalItemRetencao(faturaNotaFiscalItemRetencao, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(FaturaNotaFiscalItemService.urlBase + 'faturaNotaFiscalItemRetencao', faturaNotaFiscalItemRetencao)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByFaturaNotaFiscalItemRetencao(faturaNotaFiscalItemRetencao, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(FaturaNotaFiscalItemService.urlBase + 'faturaNotaFiscalItemRetencao/' + faturaNotaFiscalItemRetencao.idFaturaNotaFiscalItemRetencao)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByFaturaNotaFiscalItemObra(faturaNotaFiscalItemObra, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(FaturaNotaFiscalItemService.urlBase + 'faturaNotaFiscalItemObra', faturaNotaFiscalItemObra)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByFaturaNotaFiscalItemObra(faturaNotaFiscalItemObra, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(FaturaNotaFiscalItemService.urlBase + 'faturaNotaFiscalItemObra/' + faturaNotaFiscalItemObra.idFaturaNotaFiscalItemObra)
			.then(fnSucess)
			.catch(fnError);
	}
}