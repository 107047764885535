import { Component } from "react";

export default class Span extends Component {

    render() {
        if(this.props.show !== undefined && !Boolean(this.props.show)){
            return (<></>);
        }

        const props = Object.assign({}, this.props);
        delete props.show;

        return (
            <span {...props}> {this.props.children} </span>
        );
    }
}