import { File as IconFile, FileEarmarkImageFill as IconFileEarmarkImageFill, FileEarmarkPdfFill as IconFileEarmarkPdfFill, FiletypeXml as IconFiletypeXml } from "react-bootstrap-icons";
import { BaseComponent } from "../siht/base";

export default class IconByTypeFile extends BaseComponent {

    render() {

        if(this.props.show !== undefined && !Boolean(this.props.show)){
            return (<></>);
        }

        const props = Object.assign({}, this.props);
        let type = this.props.type;

        delete props.type;

        switch(type){
            case "image/jpeg":
            case "image/png":
            case "image/webp":
            case "jpeg":
            case "jpg":
            case "png":
                return <IconFileEarmarkImageFill {...props}/>
            case "application/pdf":
                props.className = `text-danger ${props.className ? props.className : ""}`;
                return <IconFileEarmarkPdfFill {...props}/>
            case "text/xml":
                props.className = `text-success ${props.className ? props.className : ""}`;
                return <IconFiletypeXml {...props}/>
            default:
                return <IconFile {...props}/>
        }
    }
}