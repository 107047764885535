import React, { memo } from 'react';
import { Handle, NodeResizer, Position, useReactFlow } from '@xyflow/react';
import { Stop as IconStop } from 'react-bootstrap-icons';

export default memo(( props ) => {

  const reactFlow = useReactFlow();
    
  const setEditing = (editing) => {
    reactFlow.setNodes((nodes) =>
      nodes.map((node) => {
        if(node.id === props.id){
          return {
            ...node,
            data : {
              ...node.data,
              editing : editing
            }
          };
        }
        return node;
      })
    );
  };
  
  const onChangeData = (event) => {
    reactFlow.setNodes((nodes) =>
      nodes.map((node) => {
        if(node.id === props.id){
          return {
            ...node,
            data : {
              ...node.data,
              label : event.target.value
            }
          };
        }
        return node;
      })
    );
  };

  if(props.data?.editing === true){
    return (<>
      <Handle type="target" position={props.targetPosition !== undefined ? props.targetPosition : Position.Left}/>
      <input 
        onChange={onChangeData}
        onBlur={() => setEditing(false)}
        value={props.data.label}
        autoFocus={true}
        style={{width : "100%", border:"1px solid #dbdbdb"}}
        />
      <Handle type="source" position={props.sourcePosition !== undefined ? props.sourcePosition : Position.Right}/>
    </>);
  }
  
  return (
    <>
    <NodeResizer
      color="#ff0071"
      isVisible={props.selected === undefined ? false : props.selected}
      minWidth={100}
      minHeight={30}
          />
      <div className="d-flex align-items-center fw-bold">
        <IconStop width={24} height={24}/>
        {props.data.label}
      </div>
      <Handle type="target" position={props.targetPosition !== undefined ? props.targetPosition : Position.Left}/>
    </>
  );
});