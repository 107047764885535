//#NO-CHANGE-FILE#

import Http from "../controllers/Http";

export default class ProdutoPrecoService {

	static urlBase = "/produtoPreco/";

	static getForCombo(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(ProdutoPrecoService.urlBase + 'forCombo', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static list(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(ProdutoPrecoService.urlBase + 'list', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneById(id, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.get(ProdutoPrecoService.urlBase + id)
			.then(fnSucess)
			.catch(fnError);
	}

	static create(data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(ProdutoPrecoService.urlBase, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static update(id, data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.put(ProdutoPrecoService.urlBase + id, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static delete(id, fnSucess = () => { }, fnError = () => { }) {
		if(Number.isInteger(id)){
			Http.getInstanceAppJson()
				.delete(ProdutoPrecoService.urlBase + id)
				.then(fnSucess)
				.catch(fnError);
		}else{
			Http.getInstanceAppJson()
			.post(ProdutoPrecoService.urlBase + 'delete', id)
			.then(fnSucess)
			.catch(fnError);
		}
	}

	static save(data, fnSucess = () => { }, fnError = () => { }) {
		if (data.idProdutoPreco) {
			this.update(data.idProdutoPreco, data, fnSucess, fnError);
		} else {
			this.create(data, fnSucess, fnError);
		}
	}

	static getAllByTipoPreco(tipoPreco, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(ProdutoPrecoService.urlBase + 'tipoPreco', tipoPreco)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByTipoPreco(tipoPreco, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(ProdutoPrecoService.urlBase + 'tipoPreco/' + tipoPreco.idTipoPreco)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByProduto(produto, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(ProdutoPrecoService.urlBase + 'produto', produto)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByProduto(produto, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(ProdutoPrecoService.urlBase + 'produto/' + produto.idProduto)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByProdutoTipoPreco(produto, tipoPreco,  fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(ProdutoPrecoService.urlBase + 'produto/' + produto.idProduto + '/tipoPreco/' + tipoPreco.idTipoPreco)
			.then(fnSucess)
			.catch(fnError);
	}
}