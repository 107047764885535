import React from "react";
import {  Div, InputCheckSwitch, InputText } from "../elements";
import { BaseComponent } from "../base";
import { Functions } from "../util";
import InputGroup from "./InputGroup";
import InputGroupText from "./InputGroupText";
import Row from "./Row";
import ListGroup from "./ListGroup";
import ListGroupItem from "./ListGroupItem";

export default class DataListGroup extends BaseComponent {
    
    static sizing = {
        sm: "sm",
        default: "",
    }
    
    constructor(props) {
        super(props);

        this.handleClickCheckItem = this.handleClickCheckItem.bind(this);
        this.handleClickCheckAll = this.handleClickCheckAll.bind(this);
        this.handleClickRow = this.handleClickRow.bind(this);

        this.state = {
            filter: {
                search: ""
            },
            selectMultiple : false
        };
    }

    handleClickRow(e, item, index){
        var select = this.props.select !== undefined && this.props.select ? true : false;
        if(select){
            let checked = this.props.selecteds.findIndex(i => (this.getItemValue(i)).toString() === (this.getItemValue(item)).toString()) === -1;
            this.handleClickCheckItem(e, checked, item);
        }
    }

    handleClickCheckItem(e, checked, item){

        var selecteds = this.props.selecteds.map(a => Object.assign({}, a));

        if(!this.state.selectMultiple){
            selecteds = [];
        }

        this.setState(state => {
            if(checked){
                selecteds.push(item);
            }else{
                let index = selecteds.findIndex(i => (this.getItemValue(i)).toString() === (this.getItemValue(item)).toString());
                selecteds.splice(index, 1);
            }
            return state;
        }, () => {
            if(this.props.onSelect !== undefined){
                this.props.onSelect(selecteds);
            }
        });
    }

    handleClickCheckAll(e, checked){
        var selecteds = this.props.selecteds.map(a => Object.assign({}, a));

        this.setState(state => {
            if (checked) {
                this.getData().filter(item => !this.isDisabled(item)).forEach(item =>  {
                    if (selecteds && selecteds.findIndex(i => (this.getItemValue(i)).toString() === (this.getItemValue(item)).toString()) === -1) {
                        selecteds.push(item);
                    }
                }, this);

            } else {
                this.getData().forEach(item =>  {
                    if (selecteds && selecteds.findIndex(i => (this.getItemValue(i)).toString() === (this.getItemValue(item)).toString()) !== -1) {
                        let index = selecteds.findIndex(i => (this.getItemValue(i)).toString() === (this.getItemValue(item)).toString());
                        selecteds.splice(index, 1);
                    }
                }, this);
            }

            return state;
        }, ()=>{
            if(this.props.onSelect !== undefined){
                this.props.onSelect(selecteds);
            }
        });
    }

    

    getData() {
        if(this.state.filter !== undefined && this.props.data?.length > 0){
            return this.props.data.filter(f =>
                Functions.searchTextInObject(f, Object.keys(this.props.data[0]), this.state.filter.search)
            );
        }else{
            return this.props.data;
        }
    }

    getColSize(props) {
        var colValue = false;
        var colSize = false;

        if (props.col) {
            colValue = "";
            colSize = props.col;
        } else if (props.xs) {
            colValue = props.xs;
            colSize = "xs";
        } else if (props.sm) {
            colValue = props.sm;
            colSize = "sm";
        } else if (props.md) {
            colValue = props.md;
            colSize = "md";
        } else if (props.lg) {
            colValue = props.lg;
            colSize = "lg";
        } else if (props.xl) {
            colValue = props.xl;
            colSize = "xl";
        } else if (props.xxl) {
            colValue = props.xxl;
            colSize = "xxl";
        }
        
        return "col" + (colSize  ? "-" + colSize : "") + (colValue > 0 ? "-" + colValue : "");
    }

    getItemValue(item) {
        if(this.props.getItemValue !== undefined){
            return this.props.getItemValue(item)
        }
        return false;
    }

    isAllCheckeds(data, selecteds) {
        data = data.filter(i => !this.isDisabled(i));
        
        if(data.length === 0){
            return false;
        }

        return data.filter(i => !selecteds.find(x => (this.getItemValue(i)).toString() === (this.getItemValue(x)).toString())).length === 0;
    }

    isDisabled(item) {
        if(this.props.isDisabled !== undefined){
            return this.props.isDisabled(item)
        }
        return false;
    }

    render() {
        if(this.props.show !== undefined && !Boolean(this.props.show)){
            return (<></>);
        }

        const props = Object.assign({}, this.props);
        props.className = `${props.className !== undefined ? props.className : ""}`;

        //var columns = React.Children.toArray(this.props.children).filter(child => child.type === DataGridColumn);
        //var buttons = React.Children.toArray(this.props.children).find(child => child.type === DataGridButtons)?.props?.children;
        var select = this.props.select !== undefined && this.props.select ? true : false;
        var data = this.getData();        

        delete props.show;

        return (
            <>
                <Row className="mt-1 mb-2 g-3">
                    {this.props.search === undefined || (this.props.search!== undefined && this.props.search) ?
                        <InputGroup className="flex-nowrap" sizing={InputGroup.sizing.lg}>
                            <InputGroupText>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                                </svg>
                            </InputGroupText>
                            <InputText placeholder="Digite sua busca" name="search" value={this.state.filter.search} required={false} opcional={true} onChange={(e,v,n) => this.handleChange(e,v,n, this.state.filter)}/>
                        </InputGroup>
                        : <></>
                    }
                </Row>

                <InputCheckSwitch label="Seleção Multípla?" name="selectMultiple" value={this.state.selectMultiple} onChange={(e,v,p) => this.handleChange(e,v,p)}/>
                
                <Div className="">
                    <ListGroup itens={this.state.data} className="overflow-scroll">
                        {data.map((item, index) => {
                            var checked = select ? this.props.selecteds.findIndex(i => (this.getItemValue(i)).toString() === (this.getItemValue(item)).toString()) !== -1 : false;
                            //var isDisabled = this.isDisabled(item);

                            return (
                                <ListGroupItem key={index} active={checked} action={true} onClick={e => this.handleClickRow(e, item, index)}>
                                    {this.props.children(item)}
                                </ListGroupItem>
                            );
                            })}
                    </ListGroup>
                </Div>
            </>
        );
    }
}