import Http from "../controllers/Http";

export default class ProdutoService {

	static urlBase = "/produto/";

	static getForCombo(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(ProdutoService.urlBase + 'forCombo', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static list(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(ProdutoService.urlBase + 'list', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneById(id, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.get(ProdutoService.urlBase + id)
			.then(fnSucess)
			.catch(fnError);
	}

	static create(data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(ProdutoService.urlBase, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static update(id, data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.put(ProdutoService.urlBase + id, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static delete(id, fnSucess = () => { }, fnError = () => { }) {
		if(Number.isInteger(id)){
			Http.getInstanceAppJson()
				.delete(ProdutoService.urlBase + id)
				.then(fnSucess)
				.catch(fnError);
		}else{
			Http.getInstanceAppJson()
			.post(ProdutoService.urlBase + 'delete', id)
			.then(fnSucess)
			.catch(fnError);
		}
	}

	static save(data, fnSucess = () => { }, fnError = () => { }) {
		if (data.idProduto) {
			this.update(data.idProduto, data, fnSucess, fnError);
		} else {
			this.create(data, fnSucess, fnError);
		}
	}

	static getAllByTipoProduto(tipoProduto, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(ProdutoService.urlBase + 'tipoProduto', tipoProduto)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByTipoProduto(tipoProduto, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(ProdutoService.urlBase + 'tipoProduto/' + tipoProduto.idTipoProduto)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByGrupoProduto(grupoProduto, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(ProdutoService.urlBase + 'grupoProduto', grupoProduto)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByGrupoProduto(grupoProduto, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(ProdutoService.urlBase + 'grupoProduto/' + grupoProduto.idGrupoProduto)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByUnidadeMedida(unidadeMedida, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(ProdutoService.urlBase + 'unidadeMedida', unidadeMedida)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByUnidadeMedida(unidadeMedida, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(ProdutoService.urlBase + 'unidadeMedida/' + unidadeMedida.idUnidadeMedida)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByClassificacaoProduto(classificacaoProduto, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(ProdutoService.urlBase + 'classificacaoProduto', classificacaoProduto)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByClassificacaoProduto(classificacaoProduto, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(ProdutoService.urlBase + 'classificacaoProduto/' + classificacaoProduto.idClassificacaoProduto)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByModelo(modelo, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(ProdutoService.urlBase + 'modelo', modelo)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByModelo(modelo, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(ProdutoService.urlBase + 'modelo/' + modelo.idModelo)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByProdutoEstoque(produtoEstoque, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(ProdutoService.urlBase + 'produtoEstoque', produtoEstoque)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByProdutoEstoque(produtoEstoque, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(ProdutoService.urlBase + 'produtoEstoque/' + produtoEstoque.idProdutoEstoque)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByProdutoPreco(produtoPreco, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(ProdutoService.urlBase + 'produtoPreco', produtoPreco)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByProdutoPreco(produtoPreco, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(ProdutoService.urlBase + 'produtoPreco/' + produtoPreco.idProdutoPreco)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByProdutoTipoOrdem(produtoTipoOrdem, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(ProdutoService.urlBase + 'produtoTipoOrdem', produtoTipoOrdem)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByProdutoTipoOrdem(produtoTipoOrdem, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(ProdutoService.urlBase + 'produtoTipoOrdem/' + produtoTipoOrdem.idProdutoTipoOrdem)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByProdutoDadoValor(produtoDadoValor, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(ProdutoService.urlBase + 'produtoDadoValor', produtoDadoValor)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByProdutoDadoValor(produtoDadoValor, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(ProdutoService.urlBase + 'produtoDadoValor/' + produtoDadoValor.idProdutoDadoValor)
			.then(fnSucess)
			.catch(fnError);
	}
}