//#NO-CHANGE-FILE#

import React from "react";
import { useNavigate, useLocation, useParams } from "react-router";
import Config from "./Config";
import { BaseList } from "../../siht/base";
import { DataGrid, DataGridButtons, DataGridColumn } from "../../siht/components";
import { Button, Div } from "../../siht/elements";
import { IconByTypeFile } from "../../icons";
import { Modal } from "../../siht/controller";
import ConfigApp from "../../ConfigApp";
import Util from "../../utils/Util";
import ListFaturaNotaFiscalLogByFaturaNotaFiscal from "../FaturaNotaFiscalLog/ListFaturaNotaFiscalLogByFaturaNotaFiscal"
import BtnView from "../../components/BtnView";
import { StatusCustom } from "../../components/Custom";

class ListFaturaNotaFiscalByFatura extends BaseList {

	constructor(props) {
		super(props);
		this.getItens = this.getItens.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleNew = this.handleNew.bind(this);
		this.handleEdit = this.handleEdit.bind(this);
		this.handleCopy = this.handleCopy.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.handleMore = this.handleMore.bind(this);
		this.onSubmitFilter = this.onSubmitFilter.bind(this);
		this.handleClear = this.handleClear.bind(this);
		this.handleFormSuccess = this.handleFormSuccess.bind(this);
		this.handleFormClose = this.handleFormClose.bind(this);

		this.podeEnviar = this.podeEnviar.bind(this);
		this.podeEstornar = this.podeEstornar.bind(this);
		this.podeIgnorar = this.podeIgnorar.bind(this);
		this.podeReativar = this.podeReativar.bind(this);

		this.handleEnviarNotaFiscal = this.handleEnviarNotaFiscal.bind(this);
		this.handleEstornarNotaFiscal = this.handleEstornarNotaFiscal.bind(this);
		this.handleIgnorarNotaFiscal = this.handleIgnorarNotaFiscal.bind(this);
		this.handleReativarNotaFiscal = this.handleReativarNotaFiscal.bind(this);

		this.handleLogProcessamento = this.handleLogProcessamento.bind(this);

		
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		super.init(Config);
	}

	getItens(more){
		if(this.props?.parent){
			super.getItens(Config.getService().getAllByFatura, this.props.parent, more);
		}
	}

	handleNew(e) {
		super.handleNew(e, Config);
	}

	handleEdit(e, item) {
		super.handleEdit(e, Config, item);
	}

	handleDelete(e, item) {
		super.handleDelete(e, Config, item);
	}

	handleCopy(e, item) {
		super.handleCopy(e, Config, item);
	}

	getFormEmbedded(){
		return super.getFormEmbedded(Config);
	}

	podeEnviar(item) {
        return item.statusNotaFiscal?.flPodeEnviar && !item.fatura.statusFatura?.flEstornada;
    }

	podeEstornar(item) {
		return item.statusNotaFiscal?.flPodeEstornar;
    }

	podeIgnorar(item) {
		return item.statusNotaFiscal?.flPodeIgnorar;
    }

	podeReativar(item) {
		return item.statusNotaFiscal?.flIgnorada;
		
    }

	handleEnviarNotaFiscal(e, item) {
		Modal.confirm("Confirmação", "Deseja realmente ENVIAR a(s) nota(s) selecionada(s)?" , "Essa é uma ação irreversível", Modal.style.warning, () =>{
			Config.getService().enviar(item, () =>{
				this.getItens(false);
			});	
		});        
	}

	handleEstornarNotaFiscal(e, item) {
		Modal.confirm("Confirmação", "Deseja realmente CANCELAR a(s) nota(s) selecionada(s)?" , "Essa é uma ação irreversível", Modal.style.danger, () =>{
			Config.getService().estornar(item, () =>{
				this.getItens(false);
			});
		});
	}

	handleIgnorarNotaFiscal(e, item) {
		Modal.confirm("Confirmação", "Deseja realmente IGNORAR a(s) nota(s) selecionada(s)?" , null, Modal.style.warning, () =>{
			Config.getService().ignorar(item, () =>{
				this.getItens(false);
			});
		});
	}

	handleReativarNotaFiscal(e, item) {
		Modal.confirm("Confirmação", "Deseja realmente REATIVAR a(s) nota(s) selecionada(s)?" , null, Modal.style.primary, () =>{
			Config.getService().reativar(item, () =>{
				this.getItens(false);
			});
		});
	}

	handleLogProcessamento(e, item) {
		Modal.openWindow(ListFaturaNotaFiscalLogByFaturaNotaFiscal, {sizing : Modal.sizing.xl, scrollable : true}, { faturaNotaFiscal : item }, (e, response) => { 
			this.getItens(false);
		});
	}

	handleArquivo(e, item, cdTipoArquivoNotaFiscal){
		let arquivo = item.faturaNotaFiscalArquivos?.find(i => i.tipoArquivoNotaFiscal.cdTipoArquivoNotaFiscal === cdTipoArquivoNotaFiscal)?.arquivo;
		if(arquivo){
			let url = ConfigApp.getUrlArquivo() + "public/download/" + arquivo?.cdUid +  "/" + arquivo?.arquivoBase?.cdHash
			Util.openNewWindow(url, false, "GET");
		}else{
			Modal.info("Atenção", "Arquivo não encontrado!", null, Modal.style.warning);
		}
	}

	temArquivo(item, cdTipoArquivoNotaFiscal){
		let arquivo = item.faturaNotaFiscalArquivos?.find(i => i.tipoArquivoNotaFiscal.cdTipoArquivoNotaFiscal === cdTipoArquivoNotaFiscal)?.arquivo;
		return arquivo ? true : false;
	}

	handleView(e, item) {
		Modal.openWindow(Config.getComponentForm(), {sizing : Modal.sizing.fullscreen}, { id : item.idFaturaNotaFiscal });
	}

	render() {
		return super.render(
			<Div className="p-2 mt-2">
				<DataGrid responsive={true} data={this.state.data} search={false} isDisabled={i => false} sizing={DataGrid.sizing.sm} select={false} selecteds={this.state.selecteds} onSelect={(selecteds)=>{this.setState({selecteds : selecteds})}} getItemValue={i=>i.idFaturaNotaFiscal} onMore={this.handleMore}>
					<DataGridColumn label="Código" field={i => i.idFaturaNotaFiscal} className="text-center"/>
					<DataGridColumn col={2} label="Status" field={i => <StatusCustom color={i.statusNotaFiscal}> {i.statusNotaFiscal?.nmStatusNotaFiscal} </StatusCustom>} className="text-center"/>
					<DataGridColumn col={2} label="Tipo" field={i => <StatusCustom color={i.tipoNotaFiscal}> {i.tipoNotaFiscal?.nmTipoNotaFiscal} </StatusCustom>} className="text-center"/>
					<DataGridColumn col={1} label="Série" field={i => i.nrSerie} className="text-center"/>
					<DataGridColumn col={1} label="Nota" field={i => i.nrNota} className="text-center"/>
					<DataGridColumn col={6} label="Arquivos" field={i => <>
						{i.faturaNotaFiscalArquivos.map((faturaNotaFiscalArquivo, index) => (
							<Div className="pb-1" key={index}>
								<a className="link-dark link-underline-opacity-0 link" rel="opener" href={ConfigApp.getUrlArquivo() + "public/download/" + faturaNotaFiscalArquivo?.arquivo?.cdUid +  "/" + faturaNotaFiscalArquivo?.arquivo?.arquivoBase?.cdHash}>
									<IconByTypeFile width={24} height={24} type={faturaNotaFiscalArquivo.arquivo.arquivoBase.cdTipo}/> {faturaNotaFiscalArquivo.arquivo.nmArquivo}
								</a>
							</Div>
						))}
					</>} className="text-start"/>
					<DataGridButtons>
						<BtnView sizing={Button.sizing.default} onClick={this.handleView}/>
						{/*
						<DropDown alignment={DropDown.alignment.end}>
							<DropDownLabel sizing={Button.sizing.default} toggle={false}> <IconThreeDotsVertical/> </DropDownLabel>
							<DropDownItem title="Log de Processamento" type="button" onClick={this.handleLogProcessamento}> <IconListCheck/> Log de Processamento</DropDownItem>
							<DropDownItem title="PDF da Nota Fiscal" type="button" disabled={(item) => !this.temArquivo(item, "PDF")} onClick={(e, item) => this.handleArquivo(e, item, "PDF")}> <IconFileEarmarkPdf/> PDF da Nota Fiscal </DropDownItem>
							<DropDownItem title="RPS da Nota Fiscal" type="button" disabled={(item) => !this.temArquivo(item, "RPS")} onClick={(e, item) => this.handleArquivo(e, item, "RPS")}> <IconFileEarmarkPdf/> RPS da Nota Fiscal </DropDownItem>
							<DropDownItem title="XML da Nota Fiscal" type="button" disabled={(item) => !this.temArquivo(item, "XML")} onClick={(e, item) => this.handleArquivo(e, item, "XML")}> <IconFiletypeXml/> XML da Nota Fiscal </DropDownItem>							
							<DropDownItem title="Enviar" type="button" show={Config.getPermissaoFaturaNotaFiscal().flPodeEnviar} disabled={item => !this.podeEnviar(item)} onClick={this.handleEnviarNotaFiscal}> <IconSendFill/> Enviar </DropDownItem>
							<DropDownItem title="Cancelar" type="button" show={Config.getPermissaoFaturaNotaFiscal().flPodeEstornar} disabled={item => !this.podeEstornar(item)} onClick={this.handleEstornarNotaFiscal}> <IconXCircleFill/> Cancelar </DropDownItem>
							<DropDownItem title="Ignorar" type="button" show={Config.getPermissaoFaturaNotaFiscal().flPodeIgnorar} disabled={item => !this.podeIgnorar(item)} onClick={this.handleIgnorarNotaFiscal}> <IconSlashCircleFill/> Ignorar </DropDownItem>
							<DropDownItem title="Reativar" type="button" show={Config.getPermissaoFaturaNotaFiscal().flPodeReativar} disabled={item => !this.podeReativar(item)} onClick={this.handleReativarNotaFiscal}> <IconCheck2Circle/> Reativar </DropDownItem>
						</DropDown>*/}
					</DataGridButtons>
				</DataGrid>
			</Div>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <ListFaturaNotaFiscalByFatura {...props} navigate={navigate} params={params} location={location}/>
}
export default With