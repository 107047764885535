import { BaseComponent } from "../siht/base";

export default class IconConstructorHatHelmetProtectionWorker extends BaseComponent {

    render() {

        if(this.props.show !== undefined && !Boolean(this.props.show)){
            return (<></>);
        }

        const props = Object.assign({}, this.props);
        props.xmlns = "http://www.w3.org/2000/svg";
        props.width = props.width === undefined ? 16 : props.width;
        props.height = props.height === undefined ? 16 : props.height;
        props.viewBox=`0 0 32 32`
        props.style={ fill : "currentColor"};

        return (
            <svg {...props}>
                <path d="M27,28.2c-0.5-2.8-2.5-5.1-5.1-5.8c-1.3-0.3-2.2-1.5-2.3-2.9c1.2-1,2.1-2.6,2.3-4.4c0-0.3,0-0.6-0.2-0.8S21.2,14,20.9,14
                    c-1.8,0-3.4-1-4.3-2.5c-0.2-0.3-0.5-0.5-0.9-0.5H11c-0.6,0-1,0.4-1,1v2c0,2.2,0.9,4.2,2.4,5.5c-0.2,1.4-1.1,2.6-2.5,3
                    c-2.5,0.7-4.4,2.9-5,5.7c-0.1,0.5,0,0.9,0.3,1.3C5.6,29.8,6,30,6.5,30h19.1c0.4,0,0.8-0.2,1.1-0.5C26.9,29.1,27.1,28.7,27,28.2z"/>
                <path d="M24,11h-0.2v-0.2c0-1.8-0.6-3.4-1.6-4.8l-0.7,2.5c-0.1,0.4-0.5,0.7-1,0.7c-0.1,0-0.2,0-0.3,0c-0.5-0.2-0.8-0.7-0.7-1.2
                    l1.2-4.1c0-0.3-0.2-0.6-0.5-0.8c-2.6-1.3-5.7-1.3-8.3,0c-0.3,0.2-0.5,0.5-0.5,0.8l1.2,4.1c0.2,0.5-0.2,1.1-0.7,1.2
                    c-0.1,0-0.2,0-0.3,0c-0.4,0-0.8-0.3-1-0.7L9.9,6c-1,1.4-1.6,3-1.6,4.8V11H8c-0.6,0-1,0.4-1,1s0.4,1,1,1h16c0.6,0,1-0.4,1-1
                    S24.6,11,24,11z"/>
            </svg>
        );
    }
}