import UsuarioModuloService from "../../services/UsuarioModuloService";
import FormUsuarioModulo from "./FormUsuarioModulo";
import ListUsuarioModulo from "./ListUsuarioModulo";
import Permissao from "../../controllers/Permissao";

export default class Config {

	static getUrlForm() {
		return "/CadUsuarioModulo";
	}

	static getUrlList() {
		return "/LstUsuarioModulo";
	}

	static getComponentForm() {
		return FormUsuarioModulo;
	}

	static getPermissionForm() {
		return Permissao.getInstance().getPermissoes([15]);
	}

	static getPermissionList() {
		return Permissao.getInstance().getPermissoes([15]);
	}

	static getComponentList() {
		return ListUsuarioModulo;
	}

	static getService() {
		return UsuarioModuloService;
	}

	static getKeyId() {
		return "idUsuarioModulo";
	}

	static getKeyDescription() {
		return "nmUsuarioModulo";
	}
}