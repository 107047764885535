import Http from "../controllers/Http";

export default class FaturaNotaFiscalHistoricoService {

	static urlBase = "/faturaNotaFiscalHistorico/";

	static getForCombo(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(FaturaNotaFiscalHistoricoService.urlBase + 'forCombo', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static list(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(FaturaNotaFiscalHistoricoService.urlBase + 'list', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneById(id, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.get(FaturaNotaFiscalHistoricoService.urlBase + id)
			.then(fnSucess)
			.catch(fnError);
	}

	static create(data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(FaturaNotaFiscalHistoricoService.urlBase, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static update(id, data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.put(FaturaNotaFiscalHistoricoService.urlBase + id, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static delete(id, fnSucess = () => { }, fnError = () => { }) {
		if(Number.isInteger(id)){
			Http.getInstanceAppJson()
				.delete(FaturaNotaFiscalHistoricoService.urlBase + id)
				.then(fnSucess)
				.catch(fnError);
		}else{
			Http.getInstanceAppJson()
			.post(FaturaNotaFiscalHistoricoService.urlBase + 'delete', id)
			.then(fnSucess)
			.catch(fnError);
		}
	}

	static save(data, fnSucess = () => { }, fnError = () => { }) {
		if (data.idFaturaNotaFiscalHistorico) {
			this.update(data.idFaturaNotaFiscalHistorico, data, fnSucess, fnError);
		} else {
			this.create(data, fnSucess, fnError);
		}
	}

	static getAllByFaturaNotaFiscal(faturaNotaFiscal, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(FaturaNotaFiscalHistoricoService.urlBase + 'faturaNotaFiscal', faturaNotaFiscal)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByFaturaNotaFiscal(faturaNotaFiscal, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(FaturaNotaFiscalHistoricoService.urlBase + 'faturaNotaFiscal/' + faturaNotaFiscal.idFaturaNotaFiscal)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByStatusNotaFiscal(statusNotaFiscal, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(FaturaNotaFiscalHistoricoService.urlBase + 'statusNotaFiscal', statusNotaFiscal)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByStatusNotaFiscal(statusNotaFiscal, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(FaturaNotaFiscalHistoricoService.urlBase + 'statusNotaFiscal/' + statusNotaFiscal.idStatusNotaFiscal)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByUsuario(usuario, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(FaturaNotaFiscalHistoricoService.urlBase + 'usuario', usuario)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByUsuario(usuario, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(FaturaNotaFiscalHistoricoService.urlBase + 'usuario/' + usuario.idUsuario)
			.then(fnSucess)
			.catch(fnError);
	}
}