import Http from "../controllers/Http";

export default class TipoOrdemDadoValorService {

	static urlBase = "/tipoOrdemDadoValor/";

	static getForCombo(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(TipoOrdemDadoValorService.urlBase + 'forCombo', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static list(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(TipoOrdemDadoValorService.urlBase + 'list', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneById(id, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.get(TipoOrdemDadoValorService.urlBase + id)
			.then(fnSucess)
			.catch(fnError);
	}

	static create(data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(TipoOrdemDadoValorService.urlBase, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static update(id, data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.put(TipoOrdemDadoValorService.urlBase + id, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static delete(id, fnSucess = () => { }, fnError = () => { }) {
		if(Number.isInteger(id)){
			Http.getInstanceAppJson()
				.delete(TipoOrdemDadoValorService.urlBase + id)
				.then(fnSucess)
				.catch(fnError);
		}else{
			Http.getInstanceAppJson()
			.post(TipoOrdemDadoValorService.urlBase + 'delete', id)
			.then(fnSucess)
			.catch(fnError);
		}
	}

	static save(data, fnSucess = () => { }, fnError = () => { }) {
		if (data.idTipoOrdemDadoValor) {
			this.update(data.idTipoOrdemDadoValor, data, fnSucess, fnError);
		} else {
			this.create(data, fnSucess, fnError);
		}
	}

	static getAllByTipoOrdem(tipoOrdem, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(TipoOrdemDadoValorService.urlBase + 'tipoOrdem', tipoOrdem)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByTipoOrdem(tipoOrdem, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(TipoOrdemDadoValorService.urlBase + 'tipoOrdem/' + tipoOrdem.idTipoOrdem)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByDadoValor(dadoValor, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(TipoOrdemDadoValorService.urlBase + 'dadoValor', dadoValor)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByDadoValor(dadoValor, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(TipoOrdemDadoValorService.urlBase + 'dadoValor/' + dadoValor.idDadoValor)
			.then(fnSucess)
			.catch(fnError);
	}
}