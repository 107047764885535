import { Functions } from "../util";
import InputMask from "./InputMask";

export default class InputCnpj extends InputMask {

    constructor(props){
        super(props);

        this.options = {
            mask: '__.___.___/____-__',
            replacement: { _: /\d/ },
        };
    }
    
    handleChange(event) {
        var value = event.target.value.toString().replace(/[^0-9]/g,'')

        if (value && value.length === 14 && !Functions.CNPJIsValid(value)) {
            event.target.setCustomValidity("CNPJ Inválido!");
        } else {
            event.target.setCustomValidity("");
        }

        super.handleChange(event);
    }

    render() {
        const props = {};
        props.pattern = "\\d{2}.\\d{3}.\\d{3}/\\d{4}-\\d{2}";
        props.placeholder = "99.999.999/9999-99";
        
        return super.render(props);
    }
}