//#NO-CHANGE-FILE#

import React from "react";
import { useNavigate, useLocation, useParams } from "react-router";
import Config from "./Config";
import { BaseList } from "../../siht/base";
import { DataGrid, DataGridColumn } from "../../siht/components";
import { Div, TextArea } from "../../siht/elements";
import Util from "../../utils/Util";

class ListFaturaNotaFiscalLog extends BaseList {

	constructor(props) {
		super(props);
		this.getItens = this.getItens.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleNew = this.handleNew.bind(this);
		this.handleEdit = this.handleEdit.bind(this);
		this.handleCopy = this.handleCopy.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.handleMore = this.handleMore.bind(this);
		this.onSubmitFilter = this.onSubmitFilter.bind(this);
		this.handleClear = this.handleClear.bind(this);
		this.handleFormSuccess = this.handleFormSuccess.bind(this);
		this.handleFormClose = this.handleFormClose.bind(this);
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		super.init(Config);
	}

	getItens(more){
		if(this.props?.parent){
			super.getItens(Config.getService().getAllByFaturaNotaFiscal, this.props.parent, more);
		}
	}

	handleNew(e) {
		super.handleNew(e, Config);
	}

	handleEdit(e, item) {
		super.handleEdit(e, Config, item);
	}

	handleDelete(e, item) {
		super.handleDelete(e, Config, item);
	}

	handleCopy(e, item) {
		super.handleCopy(e, Config, item);
	}

	getFormEmbedded(){
		return super.getFormEmbedded(Config);
	}

	render() {
		return super.render(
			<Div className="p-2 mt-2">
				<DataGrid hover={false} caption={false} responsive={true} data={this.state.data} search={false} isDisabled={i => false} sizing={DataGrid.sizing.sm} select={false} selecteds={this.state.selecteds} onSelect={(selecteds)=>{this.setState({selecteds : selecteds})}} getItemValue={i=>i.idFaturaNotaFiscalLog} onMore={this.handleMore}>
					<DataGridColumn show={false} label="Código" field={i => i.idFaturaNotaFiscalLog} className="text-center"/>
					<DataGridColumn col={1} label="Data" field={i => Util.date2Br(i.dhInsert, true)} className="text-center"/>
					<DataGridColumn col={3} label="Status" field={i => i.statusNotaFiscal.nmStatusNotaFiscal} className="text-center"/>
					<DataGridColumn col={8} label="Log" field={i => 
						<TextArea rows={5} value={i.dsLog} opcional={true} readOnly={true} style={{ fontFamily: "Courier New" }}/>
					} className="text-center"/>
				</DataGrid>
			</Div>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <ListFaturaNotaFiscalLog {...props} navigate={navigate} params={params} location={location}/>
}
export default With