import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import {  BaseCrud } from "../../siht/base";
import { Panel, PanelBody, PanelFooter, Container, ModalDialog, DataGrid, DataGridColumn, Badge } from "../../siht/components";
import { Div, Button, BtnButton, Span } from "../../siht/elements";
import Config from "./Config";
import Util from "../../utils/Util";
import FaturaCobrancaBaixaService from "../../services/FaturaCobrancaBaixaService";
import FaturaCobrancaTransacaoService from "../../services/FaturaCobrancaTransacaoService";
import { IconClear } from "../../icons";
import { Modal } from "../../siht/controller";
import { StatusCustom } from "../../components/Custom";

class ListFaturaCobrancaBaixa extends BaseCrud {

	constructor(props) {
		super(props);
		this.init = this.init.bind(this);
		this.handleSuccess = this.handleSuccess.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleChangeModel = this.handleChangeModel.bind(this);
		this.handleSubmitForm = this.handleSubmitForm.bind(this);
		this.handleEstornar = this.handleEstornar.bind(this);

		this.state.model = {
			faturaCobranca : this.props.faturaCobranca,
			faturaCobrancaBaixa : []
		};

		this.state.selecteds = [];

	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		if(this.state.model.faturaCobranca){
			FaturaCobrancaBaixaService.getAllByFaturaCobranca(this.state.model.faturaCobranca, (response) => {
				this.setModel({faturaCobrancaBaixa : response});
			});
		}
	}

	handleEstornar(e, item) {

		var faturaCobrancaTransacao = null;

		if(item === null){
			faturaCobrancaTransacao = this.state.selecteds.map(item =>item.faturaCobrancaTransacao );
		}else{
			faturaCobrancaTransacao = item.faturaCobrancaTransacao;
		}

		Modal.confirm("Confirmação", "Deseja realmente ESTORNAR a Transação?" , null, Modal.style.danger, () =>{
			FaturaCobrancaTransacaoService.estornar(faturaCobrancaTransacao, this.init, this.init);
		});
	}
	
	handleSuccess(e) {
		
	}

	handleClose(e) {
		super.handleClose(e, Config);
	}

	render() {

		return super.render(
				<Panel localOpen={this.props.localOpen} key={this.state.key} title="Histórico de Baixas" onClose={this.handleClose} isModal={this.isModal()} container={{sizing: Container.sizing.xxl, className : ""}} modal={{sizing: this.props.sizing ? this.props.sizing : ModalDialog.sizing.xl, scrollable : true}}>
					<PanelBody className="p-2 m-0">
						
						<Div className="d-flex justify-content-end flex-nowrap gap-2 px-3 py-3">
							<BtnButton disabled={this.state.selecteds.length === 0} className="text-nowrap" color={BtnButton.color.outlineDanger} sizing={Button.sizing.sm} onClick={e => this.handleEstornar(e, null)}> <IconClear/> Estornar Selecionadas </BtnButton>
						</Div>

						<DataGrid responsive={true} data={this.state.model.faturaCobrancaBaixa} search={false} isDisabled={i => false} sizing={DataGrid.sizing.sm} select={true} caption={false}
							selecteds={this.state.selecteds} onSelect={(selecteds)=>{this.setState({selecteds : selecteds})}} getItemValue={i=>i.idFaturaCobrancaBaixa}
							>
							<DataGridColumn label="Baixa" field={i => i.idFaturaCobrancaBaixa} className="text-center"/>
							<DataGridColumn label="Transação" field={i => i.faturaCobrancaTransacao.idFaturaCobrancaTransacao} className="text-center"/>
							
							<DataGridColumn col={2} label="Tipo Transacao" field={i =>
								<React.Fragment>										
									<StatusCustom color={i.faturaCobrancaTransacao.tipoTransacao}> {i.faturaCobrancaTransacao.tipoTransacao?.nmTipoTransacao} </StatusCustom>
									<Div className="fw-bold fs-7"> {i.usuario?.nmUsuario} </Div>
									<Div className="text-secondary fs-7"> {Util.date2Br(i.dhInsert, true)} </Div>
								</React.Fragment>} className="text-start"/>

							<DataGridColumn col={3} label="Tipo Baixa" field={i => <>
									{i.tipoBaixa ? <Div><StatusCustom color={i.tipoBaixa}> {i.tipoBaixa?.nmTipoBaixa} </StatusCustom></Div> : <></>}
									{i.faturaCobrancaTransacao?.conta?.banco?.idBanco ? <Div className=""> {i.faturaCobrancaTransacao?.conta?.banco?.nmBanco} / {i.faturaCobrancaTransacao?.conta?.nmConta} </Div> : <></>}
									{i.faturaCobrancaTransacao?.conta?.banco?.idBanco ? <Div className=""> {i.faturaCobrancaTransacao?.conta?.nrAgencia}{i.faturaCobrancaTransacao?.conta?.nrDigitoAgencia.toString().length > 0 ? '-' + i.faturaCobrancaTransacao?.conta?.nrDigitoAgencia : ''} / {i.faturaCobrancaTransacao?.conta?.nrConta}{i.faturaCobrancaTransacao?.conta?.nrDigitoConta.toString().length > 0 ? '-' + i.faturaCobrancaTransacao?.conta?.nrDigitoConta : ''} </Div> : <></>}
							</>} className="text-start"/>

							<DataGridColumn col={1} label="Dt. Baixa" field={i => <>
									<Span>{Util.date2Br(i.dtBaixa)}</Span>
							</>} className="text-center"/>

							<DataGridColumn col={2} label="Observação" field={i => i.txBaixa} className="text-start fs-6"/>

							<DataGridColumn col={3} label="Baixa" field={i => <>
									<Div className={`fs-6`}> Baixa: <Span className={`fw-bold ${i.vlBaixa === 0 ? "text-secondary" : "text-success"}`}>{Util.floatToReais(i.vlBaixa)}</Span>  </Div>
									<Div className={`fs-6`}> Multa: <Span className={`fw-bold ${i.vlMulta === 0 ? "text-secondary" : "text-success"}`}>{Util.floatToReais(i.vlMulta)}</Span>  </Div>
									<Div className={`fs-6`}> Juros: <Span className={`fw-bold ${i.vlJuros === 0 ? "text-secondary" : "text-success"}`}>{Util.floatToReais(i.vlJuros)}</Span>  </Div>
									<Div className={`fs-6`}> Taxas: <Span className={`fw-bold ${i.vlTaxa === 0 ? "text-secondary" : "text-success"}`}>{Util.floatToReais(i.vlTaxa)}</Span>  </Div>
							</>} className="text-end"/>
							
							<DataGridColumn col={1} label="Estorno" field={i => <>
								{i.flEstorno ? 
									<Badge color={Badge.color.danger}> ESTONADA </Badge> : 
									<BtnButton className="text-nowrap" color={BtnButton.color.outlineDanger} sizing={Button.sizing.sm} onClick={e => this.handleEstornar(e, i)}> <IconClear/> Estornar </BtnButton>
								}								
							</>} className="text-center"/>
							
						</DataGrid>
					</PanelBody>
					<PanelFooter show={this.props.localOpen !== BaseCrud.localOpen.embedded}>
						<BtnButton  onClick={this.handleClose}> Fechar </BtnButton>
					</PanelFooter>
				</Panel>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <ListFaturaCobrancaBaixa {...props} navigate={navigate} params={params} location={location}/>
}
export default With