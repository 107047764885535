import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { Input, InputCopy } from "../../siht/elements";
import Util from "../../utils/Util";
import { FormItem } from "../../siht/components";
import FormPagar from "./FormPagar";
import FaturaCobrancaTransacaoService from "../../services/FaturaCobrancaTransacaoService";

class FormPagarPix extends FormPagar {

	constructor(props) {
		super(props);
		this.init = this.init.bind(this);
		this.initModel = this.initModel.bind(this);
		this.handleSuccess = this.handleSuccess.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleChangeModel = this.handleChangeModel.bind(this);
		this.handleSubmitForm = this.handleSubmitForm.bind(this);
        this.setTempoDecorrido = this.setTempoDecorrido.bind(this);

        this.getFaturaCobrancaTransacao = this.getFaturaCobrancaTransacao.bind(this);
        this.getStatusTransacao = this.getStatusTransacao.bind(this);

        this.state.controle = {
            interval: null,
            decorrido: 0
        };

        this.state.pix = {
            qrCode: "",
            cdPixCode: ""
        };
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
        clearInterval(this.state.controle.interval);
	}

	init(){
        super.init(this.getPix);
	}

    startControle() {
        var tempo = 60 * 1000 * 5; // 5 Minutos
        var decorrido = tempo;

        this.setState(state => {
            state.controle.interval = setInterval(() => {
                if (decorrido <= 0) {
                    clearInterval(this.state.controle.interval);
                    this.props.navigate('/pagamento/' + this.state.faturaCobrancaTransacao?.faturaCobranca?.cdUid);
                    return;
                }

                if (decorrido % 5000 === 0) {
                    this.getStatusTransacao();
                }

                decorrido -= 1000;
                this.setTempoDecorrido(decorrido);
            }, 1000);
            return state;
        });
    }

    setTempoDecorrido(decorrido) {
        this.setState(state => {
            state.controle.decorrido = decorrido;
            return state;
        });
    }

    getPix() {
        this.setState({ pix:  this.state.model.faturaCobrancaTransacao?.faturaCobrancaPix});
        this.startControle();
    }

    getStatusTransacao() {
        FaturaCobrancaTransacaoService.getStatusByCdUid(this.state.model.faturaCobrancaTransacao?.cdUid, (response) => {
            if (response.flEfetivada) {
                clearInterval(this.state.controle.interval);
                this.props.navigate('/pagamento/' + this.state.model?.faturaCobrancaTransacao?.faturaCobranca?.cdUid);
            }
        });
    }

	render() {
		return super.render (
           
            <>
                <FormItem md={6} label={this.getLabelFormItem("PIX Copia e Cola")}>
                    <InputCopy rows={10} sizing={Input.sizing.lg} value={this.state.pix.cdPixCode} readOnly/>
                </FormItem>

                <div className="col-md-6 pt-2 mx-auto">
                    <img className="img-fluid" src={this.state.pix?.qrCode} alt="QR Code PIX" />
                </div>
                <div className="col-md-12 pt-2 text-center">
                    <span className="badge text-bg-warning">
                        <h2 className="fw-bold">{Util.convertMsToHM(this.state.controle.decorrido)}</h2>
                    </span>
                </div>                
            </>
                       
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <FormPagarPix {...props} navigate={navigate} params={params} location={location}/>
}
export default With