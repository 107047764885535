import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { BaseCrud } from "../../siht/base";
import { FormButtons, FormItem, Row, Panel, PanelBody, PanelFooter, Container, ModalDialog, BtnRadioGroup } from "../../siht/components";
import { Form, BtnRadio } from "../../siht/elements";
import Util from "../../utils/Util";
import ConfigApp from "../../ConfigApp";

class FormRelatorioFuncionario001 extends BaseCrud {

    constructor(props) {
        super(props);

        this.init = this.init.bind(this);
        this.handleSuccess = this.handleSuccess.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleChangeModel = this.handleChangeModel.bind(this);
        this.handleSubmitForm = this.handleSubmitForm.bind(this);

        this.urlRelatorio = "relatorio/funcionario-001";

        this.state.model = {
            idFuncionarios: props.idFuncionarios,
            orientation: "P",
            destination: "I",
            flMarcaDagua: true
        };
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    init() {
    }

    handleSuccess(e) {
        Util.openNewWindow(ConfigApp.getUrlApi() + this.urlRelatorio, this.state.model);
    }

    handleClose(e) {
        super.handleClose(e, {});
    }

    render() {
        return (
            <Form onSubmit={this.handleSuccess} refParam={f => (this.form = f)} key={this.state.key}>
                <Panel key={this.state.key} title="Impressão do Perfil do Funcionário" onClose={this.handleClose} isModal={this.isModal()} container={{ sizing: Container.sizing.xxl, className: "" }} modal={{ sizing: this.props.sizing ? this.props.sizing : ModalDialog.sizing.xl }}>
                    <PanelBody>
                        <Row className="row mb-3">
                            <FormItem md={6} label="Disposição da Página">
                                <BtnRadioGroup name="orientation" color={BtnRadioGroup.color.primary} opcional={true} value={this.state.model.orientation} onClick={this.handleChangeModel}>
                                    <BtnRadio value="P" label="Vertical" />
                                    <BtnRadio value="L" label="Horizontal" />
                                </BtnRadioGroup>
                            </FormItem>
                            <FormItem md={6} label="Destino do Relatório">
                                <BtnRadioGroup name="destination" color={BtnRadioGroup.color.primary} opcional={true} value={this.state.model.destination} onClick={this.handleChangeModel}>
                                    <BtnRadio value="I" label="Tela" />
                                    <BtnRadio value="D" label="Download" />
                                </BtnRadioGroup>
                            </FormItem>
                        </Row>
                    </PanelBody>
                    <PanelFooter>
                        <FormButtons onClose={this.handleClose} label={['Imprimir', 'Fechar']} />
                    </PanelFooter>
                </Panel>
            </Form>
        );
    }
}

function With(props) {
    let navigate = useNavigate();
    let params = useParams();
    let location = useLocation();
    return <FormRelatorioFuncionario001 {...props} navigate={navigate} params={params} location={location} />
}
export default With