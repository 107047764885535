import { Component } from "react";
import { BtnButton } from "../elements";

export default class DropDownLabel extends Component {

    render() {
        if(this.props.show !== undefined && !this.props.show){
            return (<></>);
        }

        const props = Object.assign({}, this.props);
        props.className = `${props.toggle === undefined || props.toggle === true ? "dropdown-toggle" : ""} ${props.className !== undefined ? props.className : ""}`;
        props.color = props.color === undefined ? BtnButton.color.outlineDark : props.color;

        delete props.toggle;
        delete props.show;

        return (<BtnButton {...props} data-bs-toggle="dropdown" aria-expanded="false">{this.props.children}</BtnButton>);
    }
}