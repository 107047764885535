//#NO-CHANGE-FILE#

import Http from "../controllers/Http";

export default class FaturaCobrancaTipoPagamentoService {

	static urlBase = "/faturaCobrancaTipoPagamento/";

	static getOneByCdUid(cdUid, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.get('public' + FaturaCobrancaTipoPagamentoService.urlBase + cdUid)
			.then(fnSucess)
			.catch(fnError);
	}

	static getParcelaForCombo(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(FaturaCobrancaTipoPagamentoService.urlBase + 'parcela/forCombo', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static getForCombo(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(FaturaCobrancaTipoPagamentoService.urlBase + 'forCombo', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static list(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(FaturaCobrancaTipoPagamentoService.urlBase + 'list', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneById(id, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.get(FaturaCobrancaTipoPagamentoService.urlBase + id)
			.then(fnSucess)
			.catch(fnError);
	}

	static create(data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(FaturaCobrancaTipoPagamentoService.urlBase, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static update(id, data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.put(FaturaCobrancaTipoPagamentoService.urlBase + id, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static delete(id, fnSucess = () => { }, fnError = () => { }) {
		if(Number.isInteger(id)){
			Http.getInstanceAppJson()
				.delete(FaturaCobrancaTipoPagamentoService.urlBase + id)
				.then(fnSucess)
				.catch(fnError);
		}else{
			Http.getInstanceAppJson()
			.post(FaturaCobrancaTipoPagamentoService.urlBase + 'delete', id)
			.then(fnSucess)
			.catch(fnError);
		}
	}

	static save(data, fnSucess = () => { }, fnError = () => { }) {
		if (data.idFaturaCobrancaTipoPagamento) {
			this.update(data.idFaturaCobrancaTipoPagamento, data, fnSucess, fnError);
		} else {
			this.create(data, fnSucess, fnError);
		}
	}

	static getAllByFaturaCobranca(faturaCobranca, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(FaturaCobrancaTipoPagamentoService.urlBase + 'faturaCobranca', faturaCobranca)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByFaturaCobranca(faturaCobranca, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(FaturaCobrancaTipoPagamentoService.urlBase + 'faturaCobranca/' + faturaCobranca.idFaturaCobranca)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByTipoPagamento(tipoPagamento, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(FaturaCobrancaTipoPagamentoService.urlBase + 'tipoPagamento', tipoPagamento)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByTipoPagamento(tipoPagamento, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(FaturaCobrancaTipoPagamentoService.urlBase + 'tipoPagamento/' + tipoPagamento.idTipoPagamento)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByTipoTransacao(tipoTransacao, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(FaturaCobrancaTipoPagamentoService.urlBase + 'tipoTransacao', tipoTransacao)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByTipoTransacao(tipoTransacao, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(FaturaCobrancaTipoPagamentoService.urlBase + 'tipoTransacao/' + tipoTransacao.idTipoTransacao)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByConta(conta, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(FaturaCobrancaTipoPagamentoService.urlBase + 'conta', conta)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByConta(conta, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(FaturaCobrancaTipoPagamentoService.urlBase + 'conta/' + conta.idConta)
			.then(fnSucess)
			.catch(fnError);
	}
}