import Config from "./Config";
import React from "react";
import { useNavigate, useLocation, useParams } from "react-router";
import { ThreeDotsVertical as IconThreeDotsVertical } from "react-bootstrap-icons"
import ManyToManyCheckUsuarioGrupoEquipeByEquipe from "./ManyToManyCheckUsuarioGrupoEquipeByEquipe";
import ManyToManyCheckUsuarioGrupoUnidadeByUnidade from "./ManyToManyCheckUsuarioGrupoUnidadeByUnidade";
import ManyToManyCheckUsuarioGrupoModuloByModulo from "./ManyToManyCheckUsuarioGrupoModuloByModulo";
import ManyToManyCheckUsuarioGrupoTipoOrdemByTipoOrdem from "./ManyToManyCheckUsuarioGrupoTipoOrdemByTipoOrdem";
import ManyToManyCheckUsuarioGrupoTipoPrecoByTipoPreco from "./ManyToManyCheckUsuarioGrupoTipoPrecoByTipoPreco";
import { BaseList } from "../../siht/base";
import { DataGrid, DataGridButtons, DataGridColumn, DropDown, DropDownLabel, DropDownItem, DropDownDivider } from "../../siht/components";
import { Button, Div } from "../../siht/elements";
import { IconNew, IconDelete, IconToogles, IconEdit, IconCopy } from "../../icons";
import { BtnEdit, BtnDelete, BtnNew, BtnCopy } from "../../components/Custom";
import { Modal } from "../../siht/controller";

class ListUsuarioGrupo extends BaseList {

	constructor(props) {
		super(props);
		this.getItens = this.getItens.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleNew = this.handleNew.bind(this);
		this.handleEdit = this.handleEdit.bind(this);
		this.handleCopy = this.handleCopy.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.handleMore = this.handleMore.bind(this);
		this.onSubmitFilter = this.onSubmitFilter.bind(this);
		this.handleClear = this.handleClear.bind(this);
		this.handleFormSuccess = this.handleFormSuccess.bind(this);
		this.handleFormClose = this.handleFormClose.bind(this);
		this.handleEditUsuarioGrupoEquipe = this.handleEditUsuarioGrupoEquipe.bind(this);
		this.handleEditUsuarioGrupoUnidade = this.handleEditUsuarioGrupoUnidade.bind(this);
		this.handleEditUsuarioGrupoModulo = this.handleEditUsuarioGrupoModulo.bind(this);
		this.handleEditUsuarioGrupoTipoOrdem = this.handleEditUsuarioGrupoTipoOrdem.bind(this);
		this.handleEditUsuarioGrupoTipoPreco = this.handleEditUsuarioGrupoTipoPreco.bind(this);
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		super.init(Config);
	}

	getItens(more){
		if(this.props?.parent){
			super.getItens(Config.getService().getAllByUsuario, this.props.parent, more);
		}
	}

	handleNew(e) {
		super.handleNew(e, Config);
	}

	handleEdit(e, item) {
		super.handleEdit(e, Config, item);
	}

	handleDelete(e, item) {
		super.handleDelete(e, Config, item);
	}

	handleCopy(e, item) {
		super.handleCopy(e, Config, item);
	}

	getFormEmbedded(){
		return super.getFormEmbedded(Config);
	}

	handleEditUsuarioGrupoEquipe(e, item){
		Modal.open(ManyToManyCheckUsuarioGrupoEquipeByEquipe, {sizing : Modal.sizing.lg}, {title : "Equipes", search: true, parent : (item ? item : this.state.selecteds)}, () => this.getItens());
	}

	handleEditUsuarioGrupoUnidade(e, item){
		Modal.open(ManyToManyCheckUsuarioGrupoUnidadeByUnidade, {sizing : Modal.sizing.lg}, {title : "Unidades", search: true, parent : (item ? item : this.state.selecteds)}, () => this.getItens());
	}

	handleEditUsuarioGrupoModulo(e, item){
		Modal.open(ManyToManyCheckUsuarioGrupoModuloByModulo, {sizing : Modal.sizing.lg}, {title : "Módulos", search: true, parent : (item ? item : this.state.selecteds)}, () => this.getItens());
	}

	handleEditUsuarioGrupoTipoOrdem(e, item){
		Modal.open(ManyToManyCheckUsuarioGrupoTipoOrdemByTipoOrdem, {sizing : Modal.sizing.lg}, {title : "Tipo de Ordem", search: true, parent : (item ? item : this.state.selecteds)}, () => this.getItens());
	}

	handleEditUsuarioGrupoTipoPreco(e, item){
		Modal.open(ManyToManyCheckUsuarioGrupoTipoPrecoByTipoPreco, {sizing : Modal.sizing.lg}, {title : "Tipo de Preço", search: true, parent : (item ? item : this.state.selecteds)}, () => this.getItens());
	}

	render() {
		return super.render(
			<Div className="p-2 mt-2">
				<Div className="d-flex justify-content-end flex-nowrap gap-2">
					<BtnNew onClick={this.handleNew}/>
					<BtnDelete disabled={this.state.selecteds.length === 0} sizing={Button.sizing.default} onClick={e => this.handleDelete(e, this.state.selecteds)}> Excluir </BtnDelete>
					<DropDown alignment={DropDown.alignment.end}>
						<DropDownLabel> Mais Opções </DropDownLabel>
						<DropDownItem title="Novo Registro" type="button" onClick={this.handleNew}> <IconNew/> Novo </DropDownItem>
						<DropDownItem title="Excluir Registro(s) Selecionado(s)" type="button" disabled={this.state.selecteds.length === 0} onClick={e => this.handleDelete(e, this.state.selecteds)}> <IconDelete/> Excluir </DropDownItem>
						<DropDownDivider/>
						<DropDownItem disabled={this.state.selecteds.length === 0} type="button" onClick={this.handleEditUsuarioGrupoEquipe}> <IconToogles/> Equipes </DropDownItem>
						<DropDownItem disabled={this.state.selecteds.length === 0} type="button" onClick={this.handleEditUsuarioGrupoUnidade}> <IconToogles/> Unidades </DropDownItem>
						<DropDownItem disabled={this.state.selecteds.length === 0} type="button" onClick={this.handleEditUsuarioGrupoModulo}> <IconToogles/> Módulos </DropDownItem>
						<DropDownItem disabled={this.state.selecteds.length === 0} type="button" onClick={this.handleEditUsuarioGrupoTipoOrdem}> <IconToogles/> Tipo de Ordem </DropDownItem>
						<DropDownItem disabled={this.state.selecteds.length === 0} type="button" onClick={this.handleEditUsuarioGrupoTipoPreco}> <IconToogles/> Tipo de Preço </DropDownItem>
					</DropDown>
				</Div>

				{this.getFormEmbedded()}

				<DataGrid onDoubleClickRow={this.handleEdit} responsive={true} data={this.state.data} search={true} isDisabled={i => false} sizing={DataGrid.sizing.sm} select={true} selecteds={this.state.selecteds} onSelect={(selecteds)=>{this.setState({selecteds : selecteds})}} getItemValue={i=>i.idUsuarioGrupo} onMore={this.handleMore}>
						<DataGridColumn label="Código" field={i => i.idUsuarioGrupo} className="text-center"/>
						<DataGridColumn col={12} label="Grupo" field={i => i.grupo?.nmGrupo} className="text-start"/>
					<DataGridButtons>
						<BtnEdit sizing={Button.sizing.sm} onClick={this.handleEdit}/>
						<BtnDelete sizing={Button.sizing.sm} onClick={this.handleDelete}/>
						<BtnCopy sizing={Button.sizing.sm} onClick={this.handleCopy}/>
						<DropDown alignment={DropDown.alignment.end}>
							<DropDownLabel sizing={Button.sizing.sm} toggle={false}> <IconThreeDotsVertical/> </DropDownLabel>
							<DropDownItem title="Editar Registro" type="button" onClick={this.handleEdit}> <IconEdit/> Editar </DropDownItem>
							<DropDownItem title="Excluir Registro" type="button" onClick={this.handleDelete}> <IconDelete/> Excluir </DropDownItem>
							<DropDownItem title="Copiar Registro" type="button" onClick={this.handleCopy}> <IconCopy/> Copiar </DropDownItem>
						<DropDownDivider/>
							<DropDownItem type="button" onClick={this.handleEditUsuarioGrupoEquipe}> <IconToogles/> Equipes </DropDownItem>
							<DropDownItem type="button" onClick={this.handleEditUsuarioGrupoUnidade}> <IconToogles/> Unidades </DropDownItem>
							<DropDownItem type="button" onClick={this.handleEditUsuarioGrupoModulo}> <IconToogles/> Módulos </DropDownItem>
							<DropDownItem type="button" onClick={this.handleEditUsuarioGrupoTipoOrdem}> <IconToogles/> Tipo de Ordem </DropDownItem>
							<DropDownItem type="button" onClick={this.handleEditUsuarioGrupoTipoPreco}> <IconToogles/> Tipo de Preço </DropDownItem>
						</DropDown>
					</DataGridButtons>
				</DataGrid>
			</Div>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <ListUsuarioGrupo {...props} navigate={navigate} params={params} location={location}/>
}
export default With