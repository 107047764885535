import { NodeResizer, useReactFlow } from '@xyflow/react';
import { memo } from 'react';

function AnnotationNode(props) {

  const arrowStyleRight = {
    right: 0,
    bottom: 0,
    transform: 'translate(-30px,10px) rotate(-80deg)',
  };

  const arrowStyleLeft = {
    left: 0,
    bottom: 0,
    transform: 'translate(5px, 25px) scale(1, -1) rotate(100deg)',
  };

  const reactFlow = useReactFlow();

  const setEditing = (editing) => {
    reactFlow.setNodes((nodes) =>
      nodes.map((node) => {
        if (node.id === props.id) {
          return {
            ...node,
            data: {
              ...node.data,
              editing: editing
            }
          };
        }
        return node;
      })
    );
  };

  const onChangeData = (event) => {
    reactFlow.setNodes((nodes) =>
      nodes.map((node) => {
        if (node.id === props.id) {
          return {
            ...node,
            data: {
              ...node.data,
              label: event.target.value
            }
          };
        }
        return node;
      })
    );
  };

  if (props.data?.editing === true) {
    return (<>
      <textarea
        onChange={onChangeData}
        onBlur={() => setEditing(false)}
        value={props.data.label}
        autoFocus={true}
        style={{ width: "100%", height: "100%", resize: "none", border: "1px solid #dbdbdb" }}
      />
    </>);
  }

  let arrowStyle = props.data?.arrowStyle === "left" ? arrowStyleLeft : arrowStyleRight;

  return (
    <>
      <NodeResizer
        color="#ff0071"
        isVisible={props.selected === undefined ? false : props.selected}
        minWidth={100}
        minHeight={30}
      />

      <div className='annotation-content'>
        <div className='annotation-level'>{props.data.level}.</div>
        <div>{props.data.label}</div>
      </div>

      {arrowStyle && (
        <div className="annotation-arrow" style={arrowStyle}>
          ⤹
        </div>
      )}
    </>
  );
}

export default memo(AnnotationNode);