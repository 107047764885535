//#NO-CHANGE-FILE#

import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import SelectAnsCaracteristicaMaterial from "../../components/SelectDB/SelectAnsCaracteristicaMaterial";
import SelectAnsCategoriaMaterial from "../../components/SelectDB/SelectAnsCategoriaMaterial";
import { BaseCrud } from "../../siht/base";
import { FormButtons, FormItem, Row, Panel, PanelBody, PanelFooter, Container, ModalDialog, Col } from "../../siht/components";
import { InputText, InputColor, Form, Div, BtnButton, Button } from "../../siht/elements";
import Config from "./Config";
import { IconDelete } from "../../icons";
import { Modal } from "../../siht/controller";
import FormUpload from "../FormUpload";
import ConfigApp from "../../ConfigApp";
import { Camera as IconCamera } from "react-bootstrap-icons";

class FormAnsMaterial extends BaseCrud {

	constructor(props) {
		super(props);
		this.init = this.init.bind(this);
		this.handleSuccess = this.handleSuccess.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleChangeModel = this.handleChangeModel.bind(this);
		this.handleSubmitForm = this.handleSubmitForm.bind(this);
		this.handleArquivo = this.handleArquivo.bind(this);
		this.handleDeleteArquivo = this.handleDeleteArquivo.bind(this);

		this.state.model = {
			idAnsMaterial : null,
			ansCaracteristicaMaterial : null,
			ansCategoriaMaterial : null,
			arquivo : null,
			nmAnsMaterial : "",
			cdCorFundo : "#FFFFFF"
		};
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		super.init(Config);
	}

	handleSuccess(e) {
		super.handleSuccess(e, Config);
	}

	handleClose(e) {
		super.handleClose(e, Config);
	}

	handleArquivo() {
		Modal.openWindow(FormUpload, {sizing : Modal.sizing.default}, { multiple : false, accept : "image/*" }, (e, response) => {
			this.setModel({arquivo : response});
		});
	}

	handleDeleteArquivo() {
		this.setModel({arquivo : null});
	}

	render() {
		return super.render(
			<Form onSubmit={this.handleSuccess} refParam={f => (this.form = f)} key={this.state.key}>
				<Panel key={this.state.key} title="Cadastro de Material" onClose={this.handleClose} isModal={this.isModal()} container={{sizing: Container.sizing.xxl, className : ""}} modal={{sizing: this.props.sizing ? this.props.sizing : ModalDialog.sizing.xl}}>
					<PanelBody>
						<Row className="g-3 pb-2 px-2">
							<FormItem md={3} label="Código">
								<InputText name="idAnsMaterial" value={this.state.model.idAnsMaterial} readOnly={true} className="readonly-field" required={false} onChange={this.handleChangeModel}/>
							</FormItem>
							<FormItem md={7} label="Nome da Material">
								<InputText name="nmAnsMaterial" value={this.state.model.nmAnsMaterial} autoFocus={true} maxLength={100} required={true} onChange={this.handleChangeModel}/>
							</FormItem>
							<FormItem md={2} label="Cor Fundo">
								<InputColor name="cdCorFundo" value={this.state.model.cdCorFundo} maxLength={7} required={true} onChange={this.handleChangeModel}/>
							</FormItem>

							<FormItem md={6} label="Característica">
								<SelectAnsCaracteristicaMaterial name="ansCaracteristicaMaterial" value={this.state.model.ansCaracteristicaMaterial} required={false} onChange={this.handleChangeModel} placeholder="Selecione a Característica"/>
							</FormItem>
							<FormItem md={6} label="Categoria">
								<SelectAnsCategoriaMaterial name="ansCategoriaMaterial" value={this.state.model.ansCategoriaMaterial} required={true} onChange={this.handleChangeModel} placeholder="Selecione a Categoria"/>
							</FormItem>

							<FormItem md={12} label="Imagem do Perfil (500 x 400)">
								<Col md={12}>
									{parseInt(this.state.model?.arquivo?.idArquivo) > 0 && this.state.model.arquivo?.cdUid && this.state.model.arquivo?.arquivoBase?.cdHash ?
										<Div>
											<Div>
												<img style={{ maxHeight: "150px" }} src={ConfigApp.getUrlArquivo() + "public/inline/" + this.state.model.arquivo.cdUid + "/" + this.state.model.arquivo.arquivoBase.cdHash} className="img-fluid img-thumbnail" alt="..." />
											</Div>
											<Div>
												<BtnButton color={BtnButton.color.danger} sizing={Button.sizing.sm} onClick={this.handleDeleteArquivo}> <IconDelete/> Excluir </BtnButton>
											</Div>
										</Div>
										: <BtnButton sizing={Button.sizing.default} multiple={false} onClick={this.handleArquivo} color={BtnButton.color.outlinePrimary}> <IconCamera/> Enviar Imagem </BtnButton>}
								</Col>
							</FormItem>
							
							
						</Row>
					</PanelBody>
					<PanelFooter>
						<FormButtons onClose={this.handleClose} />
					</PanelFooter>
				</Panel>
			</Form>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <FormAnsMaterial {...props} navigate={navigate} params={params} location={location}/>
}
export default With