//#NO-CHANGE-FILE#

import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { BaseCrud } from "../../siht/base";
import {  FormItem, Row, Col } from "../../siht/components";
import { InputText, TextArea, BtnButton, Button, InputNumber, InputDate, InputNumberFormat, Select, InputTime, Div, SelectMultiple, InputMask, InputColor, InputPassword, InputEmail } from "../../siht/elements";
import Config from "./Config";
import FormUpload from "../FormUpload";
import { Modal } from "../../siht/controller";
import { IconDelete, IconDownload } from "../../icons";
import Util from "../../utils/Util";
import BtnRadioGroupSimNao from "../../components/BtnRadioGroupSimNao";
import DadoLocalService from "../../services/DadoLocalService";
import { toast } from "react-toastify";
import ConfigApp from "../../ConfigApp";
import { BoxArrowInUp as IconBoxArrowInUp} from "react-bootstrap-icons";

class FormDadoValorFormItem extends BaseCrud {

	constructor(props) {
		super(props);
		this.init = this.init.bind(this);
		this.handleSuccess = this.handleSuccess.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleChangeModel = this.handleChangeModel.bind(this);
		this.handleSubmitForm = this.handleSubmitForm.bind(this);
		this.handleAddArquivo = this.handleAddArquivo.bind(this);
		this.handleDeleteArquivo = this.handleDeleteArquivo.bind(this);
		this.handleChangeDropdownList = this.handleChangeDropdownList.bind(this);
		this.handleChangeDropdownListMultiple = this.handleChangeDropdownListMultiple.bind(this);
		this.handleChangeItem = this.handleChangeItem.bind(this);
		this.decimalIsAllowed = this.decimalIsAllowed.bind(this);

		this.state.model = {
			idDadoValor : null,
			dado : this.props.dado !== undefined ? this.props.dado : null,
			txValor : "",
			arquivo : null,
			cdDadoLocal : this.props.cdDadoLocal,
			parent : Object.assign({}, this.props.parent)
		};

		if(this.props.dadoValor !== undefined && parseInt(this.props.dadoValor.idDadoValor) > 0){
			this.state.model = this.props.dadoValor;
		}

		this.state.dadoLocal = null;
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		this.setState(state => {
			state.model.cdDadoLocal = this.props.cdDadoLocal;
			state.model.parent = Object.assign({}, this.props.parent);

			if(this.state?.model?.dado?.tipoDado?.cdTipoDado === "DROPDOWN_MULTIPLE"){
				state.model.txValor = state.model.txValor ? JSON.parse(state.model.txValor) : [];
			}

			return state;
		});

		if(this.props.cdDadoLocal !== undefined){
			DadoLocalService.getOneByCdDadoLocal(this.props.cdDadoLocal, response => {
				this.setState({dadoLocal : response});
			});
		}
	}

	getItensDropdownList(){
		var dropDownItens = [];
		if(this.state?.model?.dado?.tipoDado?.cdTipoDado === "DROPDOWN_LIST" || this.state?.model?.dado?.tipoDado?.cdTipoDado === "DROPDOWN_MULTIPLE"){
			try {
				dropDownItens = JSON.parse(this.state?.model?.dado?.txOpcao);
			} catch (error) {
				toast.warn("Erro ao converter JSON com os itens do Dropdwon List");
			}
			
		}
		
		return dropDownItens;
	}

	getValueDropdownList(multiple = false){
		if(multiple){
			let txValor = this.state.model?.txValor;
			return this.getItensDropdownList().filter(i =>  Array.isArray(txValor) && txValor?.find(v => i?.value?.toString() === v?.toString()));
		}else{
			return this.getItensDropdownList().find(i => i?.value?.toString() === this.state.model?.txValor?.toString());
		}
	}

	handleSuccess(e) {
		super.handleSuccess(e, Config);
	}

	handleClose(e) {
		super.handleClose(e, Config);
	}

	handleChangeItem(e){
		if(this.props.onChange !== undefined){
			this.props.onChange(e, this.state.model);
		}
	}

	handleAddArquivo(accept = "*/*") {
		Modal.openWindow(FormUpload, {sizing : Modal.sizing.default}, { multiple : false, accept : accept }, (e, response) => {
			this.setModel({arquivo : response}, this.handleChangeItem);
		});
	}

	handleDeleteArquivo() {
		this.setModel({arquivo : null}, this.handleChangeItem);
	}

	handleChangeDropdownList(e,v,p){
		this.setModel({txValor : v.value});
	}

	handleChangeDropdownListMultiple(e,v,p){
		this.setModel({txValor : v.map(i => i.value)});
	}

	decimalIsAllowed({ value }){

		if(!isNaN(parseFloat(value))){
			/*if(this.state.model.dado.vlMinimoDecimal && parseFloat(value) < this.state.model.dado.vlMinimoDecimal && this.state.model.txValor.toString().length === 0){
				this.setModel({txValor : this.state.model.dado.vlMinimoDecimal});
				return false;
			}*/

			if(this.state.model.dado.vlMaximoDecimal){
				return parseFloat(value) <= this.state.model.dado.vlMaximoDecimal;
			}
		}
		
		return true;
	}

	render() {

		let editable = this.props.editable !== undefined ? this.props.editable : true;
		let label = this.props.label !== undefined ? this.props.label : this.state.model.dado?.nmDado;
		let placeholder = this.props.placeholder !== undefined ? this.props.placeholder : null;
		let help= this.state.model.dado.txObservacao ? this.state.model.dado.txObservacao : "";


		if(this.state?.model?.dado?.tipoDado?.cdTipoDado === "INTEGER"){
			if(this.state.model.dado.nrMinimoNumero !== null){
				help+=` * Mínimo: ${this.state.model.dado.nrMinimoNumero}`
			}

			if(this.state.model.dado.nrMinimoNumero !== null){
				help+=` * Máximo: ${this.state.model.dado.nrMaximoNumero}`
			}
		}

		if(this.state?.model?.dado?.tipoDado?.cdTipoDado === "DECIMAL"){
			if(this.state.model.dado.vlMinimoDecimal !== null){
				help+=` * Mínimo: ${(Util.floatToValor(this.state.model.dado.vlMinimoDecimal, this.state.model.dado.nrDecimalEscala ? parseInt(this.state.model.dado.nrDecimalEscala) : 2))}`
			}

			if(this.state.model.dado.vlMaximoDecimal !== null){
				help+=` * Máximo: ${(Util.floatToValor(this.state.model.dado.vlMaximoDecimal, this.state.model.dado.nrDecimalEscala ? parseInt(this.state.model.dado.nrDecimalEscala) : 2))}`
			}
		}

		return super.render(
			<Row className="g-3 pb-2 px-2">

					{this.state?.model?.dado?.tipoDado?.cdTipoDado === "MASK" ?
						<FormItem help={help} md={12} label={label}>
							<InputMask placeholder={placeholder} mask={this.state.model.dado.dsMascara} pattern={(this.state.model.dado?.dsExpressaoRegular)} name="txValor" value={this.state.model.txValor} required={true} disabled={!editable} onChange={(e, v, p) => this.handleChangeModel(e,v,p, this.handleChangeItem)} maxLength={this.state.model.dado.nrMaximoCaractere}/>
						</FormItem>
					: <></> }

					{this.state?.model?.dado?.tipoDado?.cdTipoDado === "VARCHAR" ?
						<FormItem help={help} md={12} label={label}>
							<InputText placeholder={placeholder} name="txValor" value={this.state.model.txValor} pattern={(this.state.model.dado?.dsExpressaoRegular)} required={true} disabled={!editable} onChange={(e, v, p) => this.handleChangeModel(e,v,p, this.handleChangeItem)} maxLength={this.state.model.dado.nrMaximoCaractere}/>
						</FormItem>
					: <></> }

					{this.state?.model?.dado?.tipoDado?.cdTipoDado === "EMAIL" ?
						<FormItem help={help} md={12} label={label}>
							<InputEmail placeholder={placeholder} name="txValor" value={this.state.model.txValor} pattern={(this.state.model.dado?.dsExpressaoRegular)} required={true} onChange={this.handleChangeModel} maxLength={this.state.model.dado.nrMaximoCaractere}/>
						</FormItem>
					: <></> }

					{this.state?.model?.dado?.tipoDado?.cdTipoDado === "PASSWORD" ?
						<FormItem help={help} md={12} label={label}>
							<InputPassword placeholder={placeholder} name="txValor" value={this.state.model.txValor} pattern={(this.state.model.dado?.dsExpressaoRegular)} required={true} disabled={!editable} onChange={(e, v, p) => this.handleChangeModel(e,v,p, this.handleChangeItem)} maxLength={this.state.model.dado.nrMaximoCaractere}/>
						</FormItem>
					: <></> }

					{this.state?.model?.dado?.tipoDado?.cdTipoDado === "COLOR" ?
						<FormItem help={help} md={12} label={label}>
							<InputColor name="txValor" value={this.state.model.txValor} required={true} disabled={!editable} onChange={(e, v, p) => this.handleChangeModel(e,v,p, this.handleChangeItem)} maxLength={this.state.model.dado.nrMaximoCaractere}/>
						</FormItem>
					: <></> }
				
					{this.state?.model?.dado?.tipoDado?.cdTipoDado === "TEXT" ?
						<FormItem help={help} md={12} label={label}>
							<TextArea placeholder={placeholder} rows={6} name="txValor" value={this.state.model.txValor} required={true} disabled={!editable} onChange={(e, v, p) => this.handleChangeModel(e,v,p, this.handleChangeItem)}/>
						</FormItem>
					: <></> }

					{this.state?.model?.dado?.tipoDado?.cdTipoDado === "JSON" ?
						<>
						<FormItem help={help} md={12} label={label}>
							<TextArea placeholder={placeholder} rows={6} name="txValor" value={this.state.model.txValor} required={true} disabled={!editable} onChange={(e, v, p) => this.handleChangeModel(e,v,p, this.handleChangeItem)} className="text-bg-dark" style={{ height: "400px", fontFamily: "Courier New" }}/>
						</FormItem>
						{!Util.isJsonString(this.state.model.txValor) ? <div className="alert alert-danger pt-1 pb-1" role="alert"> JSON Inválido </div> : <></>}
						</>
					: <></> }

					{this.state?.model?.dado?.tipoDado?.cdTipoDado === "INTEGER" ?
						<FormItem help={help} md={12} label={label}>
							<InputNumber placeholder={placeholder} name="txValor" value={parseInt(this.state.model.txValor)} required={true} disabled={!editable} onChange={(e, v, p) => this.handleChangeModel(e,v,p, this.handleChangeItem)} min={this.state.model.dado.nrMinimoNumero ? this.state.model.dado.nrMinimoNumero : null} max={this.state.model.dado.nrMaximoNumero ? this.state.model.dado.nrMaximoNumero : null}/>
						</FormItem>
					: <></> }

					{/*this.state?.model?.dado?.tipoDado?.cdTipoDado === "INTEGER" && this.state.model.dado.nrMinimoNumero !== null ?
						<Col md={3} label={label} className="d-flex align-items-end">
							Mínimo: <strong>{this.state.model.dado.nrMinimoNumero}</strong>
						</Col>
					: <></> */}

					{/*this.state?.model?.dado?.tipoDado?.cdTipoDado === "INTEGER" && this.state.model.dado.nrMaximoNumero !== null ?
						<Col md={3} label={label} className="d-flex align-items-end">
							Máximo: <strong>{this.state.model.dado.nrMaximoNumero}</strong>
						</Col>
					: <></> */}

					{this.state?.model?.dado?.tipoDado?.cdTipoDado === "DECIMAL" ?
						<FormItem help={help} md={12} label={label}>
							<InputNumberFormat name="txValor"
								placeholder={placeholder}
								prefix={this.state.model.dado?.dsPrefixo ? this.state.model.dado?.dsPrefixo + " " : ""}
								suffix={this.state.model.dado?.dsSufixo ? " " + this.state.model.dado?.dsSufixo : ""}
								isAllowed={this.decimalIsAllowed}
								value={parseFloat(this.state.model.txValor)} decimalScale={this.state.model.dado.nrDecimalEscala ? parseInt(this.state.model.dado.nrDecimalEscala) : 2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} required={true} disabled={!editable} onChange={(e, v, p) => this.handleChangeModel(e,v,p, this.handleChangeItem)}
							/>
						</FormItem>
					: <></> }

					{/*this.state?.model?.dado?.tipoDado?.cdTipoDado === "DECIMAL" && this.state.model.dado.vlMinimoDecimal !== null ?
						<Col md={3} label={label} className="d-flex align-items-end">
							Mínimo: <strong>{(Util.floatToValor(this.state.model.dado.vlMinimoDecimal, this.state.model.dado.nrDecimalEscala ? parseInt(this.state.model.dado.nrDecimalEscala) : 2))}</strong>
						</Col>
					: <></> */}

					{/*this.state?.model?.dado?.tipoDado?.cdTipoDado === "DECIMAL" && this.state.model.dado.vlMaximoDecimal !== null ?
						<Col md={3} label={label} className="d-flex align-items-end">
							Máximo: <strong>{(Util.floatToValor(this.state.model.dado.vlMaximoDecimal, this.state.model.dado.nrDecimalEscala ? parseInt(this.state.model.dado.nrDecimalEscala) : 2))}</strong>
						</Col>
					: <></> */}

					{this.state?.model?.dado?.tipoDado?.cdTipoDado === "DATE" ?
						<FormItem help={help} md={4} label={label}>
							<InputDate name="txValor" value={this.state.model.txValor} required={true} disabled={!editable} onChange={(e, v, p) => this.handleChangeModel(e,v,p, this.handleChangeItem)}/>
						</FormItem>
					: <></> }

					{this.state?.model?.dado?.tipoDado?.cdTipoDado === "TIME" ?
						<FormItem help={help} md={4} label={label}>
							<InputTime name="txValor" value={this.state.model.txValor} required={true} disabled={!editable} onChange={(e, v, p) => this.handleChangeModel(e,v,p, this.handleChangeItem)}/>
						</FormItem>
					: <></> }

					{this.state?.model?.dado?.tipoDado?.cdTipoDado === "DATE_TIME" ?
						<>
							<FormItem help={help} md={4} label={`${label} - Data`}>
								<InputDate name="txValor" value={this.state.model.txValor} required={true} disabled={!editable} onChange={(e, v, p) => this.handleChangeModel(e,v,p, this.handleChangeItem)}/>
							</FormItem>

							<FormItem help={help} md={4} label={`${label} - Hora`}>
								<InputTime name="txValor" value={this.state.model.txValor} required={true} disabled={!editable} onChange={(e, v, p) => this.handleChangeModel(e,v,p, this.handleChangeItem)}/>
							</FormItem>
						</>
					: <></> }

					{this.state?.model?.dado?.tipoDado?.cdTipoDado === "BOOLEAN" ?
						<FormItem help={help} md={2} label={label}>
							<BtnRadioGroupSimNao name="txValor" value={this.state.model.txValor} required={true} disabled={!editable} onChange={(e, v, p) => this.handleChangeModel(e,v,p, this.handleChangeItem)}/>
						</FormItem>
					: <></> }

					{this.state?.model?.dado?.tipoDado?.cdTipoDado === "DROPDOWN_LIST" ?
						<FormItem help={help} md={12} label={label}>
							<Select placeholder={placeholder} name="txValor" value={this.getValueDropdownList()} required={true} onChange={this.handleChangeDropdownList} options={this.getItensDropdownList()} disabled={!editable}/>
						</FormItem>
					: <></> }

					{this.state?.model?.dado?.tipoDado?.cdTipoDado === "DROPDOWN_MULTIPLE" ?
						<FormItem help={help} md={12} label={label}>
							<SelectMultiple placeholder={placeholder} size={10} name="txValor" value={this.getValueDropdownList(true)} required={true} onChange={this.handleChangeDropdownListMultiple} options={this.getItensDropdownList()} disabled={!editable}/>
						</FormItem>
					: <></> }

					{this.state?.model?.dado?.tipoDado?.cdTipoDado === "FILE" ?
					<>
						<FormItem help={help} md={5} label={label}>
							<InputText placeholder={"Selecione o arquivo"} value={this.state.model.arquivo ? this.state.model.arquivo?.nmArquivo : ""} disabled={!editable} required={true} onClick={e => this.handleAddArquivo("*/*")}/>
						</FormItem>
						<FormItem md={2} label="Tamanho">
							<InputText value={Util.formatBytes(this.state.model.arquivo?.arquivoBase?.nrTamanho)} disabled={true} required={true}/>
						</FormItem>
						<FormItem md={3} label="Tipo">
							<InputText  value={this.state.model.arquivo ? this.state.model.arquivo?.arquivoBase?.cdTipo : ""} disabled={true} required={true}/>
						</FormItem>
						<Col sizing={2} className="d-flex align-items-end justify-content-end">
							{parseInt(this.state.model?.arquivo?.idArquivo) > 0 ?
								<BtnButton disabled={!editable} className="w-100" color={BtnButton.color.danger} sizing={Button.sizing.default} onClick={this.handleDeleteArquivo}> <IconDelete/> Excluir </BtnButton>
								: <BtnButton disabled={!editable} className="w-100" sizing={Button.sizing.default} multiple={false} onClick={e => this.handleAddArquivo("*/*")} color={BtnButton.color.outlinePrimary}> <IconBoxArrowInUp/>Selecionar Arquivo</BtnButton>}
						</Col>
						<Col sizing={12} className="d-flex align-items-end justify-content-end">
							{parseInt(this.state.model?.arquivo?.idArquivo) > 0 && this.state.model.arquivo?.cdUid && this.state.model.arquivo?.arquivoBase?.cdHash ?
								<Div>
									<a rel="opener" className="link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover" href={ConfigApp.getUrlArquivo() + "public/download/" + this.state.model.arquivo.cdUid + "/" + this.state.model.arquivo.arquivoBase.cdHash}>
										<IconDownload/> {this.state.model.arquivo?.nmArquivo}
									</a>
								</Div> : <></>}
						</Col>
					</>: <></> }

					{this.state?.model?.dado?.tipoDado?.cdTipoDado === "IMAGE" ?
					<>
						<FormItem help={help} md={5} label={label}>
							<InputText placeholder={"Selecione a imagem"} value={this.state.model.arquivo ? this.state.model.arquivo?.nmArquivo : ""} disabled={!editable} required={true} onClick={e => this.handleAddArquivo("*/*")}/>
						</FormItem>
						<FormItem md={2} label="Tamanho">
							<InputText  value={Util.formatBytes(this.state.model.arquivo?.arquivoBase?.nrTamanho)} disabled={true} required={true}/>
						</FormItem>
						<FormItem md={3} label="Tipo">
							<InputText  value={this.state.model.arquivo ? this.state.model.arquivo?.arquivoBase?.cdTipo : ""} disabled={true} required={true}/>
						</FormItem>
						<Col sizing={2} className="d-flex align-items-end justify-content-end">
							{parseInt(this.state.model?.arquivo?.idArquivo) > 0 ?
								<BtnButton disabled={!editable} className="w-100" color={BtnButton.color.danger} sizing={Button.sizing.default} onClick={this.handleDeleteArquivo}> <IconDelete/> Excluir </BtnButton>
								: <BtnButton disabled={!editable} className="w-100" sizing={Button.sizing.default} multiple={false} onClick={e => this.handleAddArquivo("image/*")} color={BtnButton.color.outlinePrimary}> <IconBoxArrowInUp/>Selecionar Imagem</BtnButton>}
						</Col>
						<Col sizing={12} className="d-flex align-items-center justify-content-center">
							{parseInt(this.state.model?.arquivo?.idArquivo) > 0 && this.state.model.arquivo?.cdUid && this.state.model.arquivo?.arquivoBase?.cdHash ?
								<Div>
										<img style={{ maxHeight: "150px" }} src={ConfigApp.getUrlArquivo() + "public/inline/" + this.state.model.arquivo.cdUid + "/" + this.state.model.arquivo.arquivoBase.cdHash} className="img-fluid img-thumbnail" alt="..." />
								</Div> : <></>}
						</Col>
					</>: <></> }
			</Row>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <FormDadoValorFormItem {...props} navigate={navigate} params={params} location={location}/>
}
export default With