import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { Input, InputCopy } from "../../siht/elements";
import ConfigApp from "../../ConfigApp";
import { FormItem } from "../../siht/components";
import FormPagar from "./FormPagar";
import { Upc as IconUpc} from "react-bootstrap-icons";

class FormPagarBoleto extends FormPagar {

	constructor(props) {
		super(props);
		this.init = this.init.bind(this);
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
        super.init();
	}

	render() {
		return super.render (<>
            <FormItem md={12} label={this.getLabelFormItem("Linha Digitável")}>
                <InputCopy sizing={Input.sizing.lg} value={this.state.model.faturaCobrancaTransacao?.faturaCobrancaBoleto?.cdLinhaDigitavel} readOnly/>
            </FormItem>
            <a href={ConfigApp.getUrlApi() + "public/boleto/pdf/" + this.props.params?.cdUid} rel="opener noreferrer" target="_blank" className="btn btn-lg btn-success btn-block">
                <IconUpc/> Visualizar Boleto
            </a>                    
        </>);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <FormPagarBoleto {...props} navigate={navigate} params={params} location={location}/>
}
export default With