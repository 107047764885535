import Config from "./Config";
import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import SelectGrupo from "../../components/SelectDB/SelectGrupo";
import ManyToManyCheckUsuarioGrupoEquipeByEquipe from "./ManyToManyCheckUsuarioGrupoEquipeByEquipe";
import ManyToManyCheckUsuarioGrupoUnidadeByUnidade from "./ManyToManyCheckUsuarioGrupoUnidadeByUnidade";
import ManyToManyCheckUsuarioGrupoModuloByModulo from "./ManyToManyCheckUsuarioGrupoModuloByModulo";
import ManyToManyCheckUsuarioGrupoTipoOrdemByTipoOrdem from "./ManyToManyCheckUsuarioGrupoTipoOrdemByTipoOrdem";
import ManyToManyCheckUsuarioGrupoTipoPrecoByTipoPreco from "./ManyToManyCheckUsuarioGrupoTipoPrecoByTipoPreco";
import Permissao from "../../controllers/Permissao";
import { BaseCrud } from "../../siht/base";
import { FormButtons, FormItem, Row, TabNavigator, Tab, Panel, PanelBody, PanelFooter, Container } from "../../siht/components";
import { InputText, Form, BtnSubmit } from "../../siht/elements";

class FormUsuarioGrupo extends BaseCrud {

	constructor(props) {
		super(props);
		this.init = this.init.bind(this);
		this.initModel = this.initModel.bind(this);
		this.handleSuccess = this.handleSuccess.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleChangeModel = this.handleChangeModel.bind(this);
		this.handleSubmitForm = this.handleSubmitForm.bind(this);
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		super.init(Config);
	}

	initModel(){
		return {
			idUsuarioGrupo : null,
			grupo : null,
			usuario : this.props.parent !== undefined ? this.props.parent : {},
			usuarioGrupoEquipe : [],
			usuarioGrupoUnidade : [],
			usuarioGrupoModulo : [],
			usuarioGrupoTipoOrdem : [],
			usuarioGrupoTipoPreco : []
		}
	}

	handleSuccess(e) {
		super.handleSuccess(e, Config);
	}

	handleClose(e) {
		super.handleClose(e, Config);
	}

	render() {
		return super.render(

					<Panel key={this.state.key} title="Cadastro de UsuarioGrupo" onClose={this.handleClose} isModal={this.isModal()} container={{sizing: Container.sizing.xxl, className : ""}}>
						<PanelBody className="p-0 m-0">
							<TabNavigator key={this.state.key} onChangeTab={this.handleChangeTab} className="border-light">
								<Tab title="UsuarioGrupo" className="m-2">
									<Form onSubmit={this.handleSuccess} refParam={f => (this.form = f)}>
										<Row className="g-3 pb-2 px-2">
											<FormItem md={6} label="Código">
												<InputText name="idUsuarioGrupo" value={this.state.model.idUsuarioGrupo} readOnly={true} className="readonly-field" required={false} onChange={this.handleChangeModel}/>
											</FormItem>
											<FormItem md={12} label="Grupo">
												<SelectGrupo name="grupo" value={this.state.model.grupo} required={true} onChange={this.handleChangeModel} placeholder="Selecione Grupo"/>
											</FormItem>
											<BtnSubmit hidden > Enviar </BtnSubmit>
										</Row>
									</Form>
								</Tab>
								<Tab show={Permissao.getInstance().getPermissoes([5])} title="Equipes" disabled={!this.state.model.idUsuarioGrupo} opened={false} className="m-2">
									<ManyToManyCheckUsuarioGrupoEquipeByEquipe typeCheck={ManyToManyCheckUsuarioGrupoEquipeByEquipe.typeCheck.box} parent={this.state.model} search={true}/>
								</Tab>
								<Tab show={Permissao.getInstance().getPermissoes([6])} title="Unidades" disabled={!this.state.model.idUsuarioGrupo} opened={false} className="m-2">
									<ManyToManyCheckUsuarioGrupoUnidadeByUnidade typeCheck={ManyToManyCheckUsuarioGrupoUnidadeByUnidade.typeCheck.box} parent={this.state.model} search={true}/>
								</Tab>
								<Tab show={Permissao.getInstance().getPermissoes([7])} title="Módulos" disabled={!this.state.model.idUsuarioGrupo} opened={false} className="m-2">
									<ManyToManyCheckUsuarioGrupoModuloByModulo typeCheck={ManyToManyCheckUsuarioGrupoModuloByModulo.typeCheck.box} parent={this.state.model} search={true}/>
								</Tab>
								<Tab show={Permissao.getInstance().getPermissoes([8])} title="Tipo de Ordem" disabled={!this.state.model.idUsuarioGrupo} opened={false} className="m-2">
									<ManyToManyCheckUsuarioGrupoTipoOrdemByTipoOrdem typeCheck={ManyToManyCheckUsuarioGrupoTipoOrdemByTipoOrdem.typeCheck.box} parent={this.state.model} search={true}/>
								</Tab>
								<Tab show={Permissao.getInstance().getPermissoes([9])} title="Tipo de Preço" disabled={!this.state.model.idUsuarioGrupo} opened={false} className="m-2">
									<ManyToManyCheckUsuarioGrupoTipoPrecoByTipoPreco typeCheck={ManyToManyCheckUsuarioGrupoTipoPrecoByTipoPreco.typeCheck.box} parent={this.state.model} search={true}/>
								</Tab>
							</TabNavigator>
						</PanelBody>
						<PanelFooter>
							<FormButtons show={this.state.tabIndex === 0} onClose={this.handleClose} onSubmit={this.handleSubmitForm}/>
						</PanelFooter>
					</Panel>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <FormUsuarioGrupo {...props} navigate={navigate} params={params} location={location}/>
}
export default With