//#NO-CHANGE-FILE#
import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import SelectTipoProduto from "../../components/SelectDB/SelectTipoProduto";
import SelectGrupoProduto from "../../components/SelectDB/SelectGrupoProduto";
import SelectUnidadeMedida from "../../components/SelectDB/SelectUnidadeMedida";
import SelectClassificacaoProduto from "../../components/SelectDB/SelectClassificacaoProduto";
import BtnRadioGroupSimNao from "../../components/BtnRadioGroupSimNao";
import ListProdutoEstoque from "../ProdutoEstoque/ListProdutoEstoque";
import ListProdutoPreco from "../ProdutoPreco/ListProdutoPreco";
import Permissao from "../../controllers/Permissao";
import { BaseList, BaseCrud } from "../../siht/base";
import { FormButtons, FormItem, Row, TabNavigator, Tab, Panel, PanelBody, PanelFooter, Container, ModalDialog } from "../../siht/components";
import { InputText, Div, Form, BtnSubmit, TextArea, InputCheckSwitch } from "../../siht/elements";
import Config from "./Config";
import FormItemMarcaModelo from "../../components/FormItem/FormItemMarcaModelo";
import ManyToManyCheckProdutoTipoOrdemByTipoOrdem from "./ManyToManyCheckProdutoTipoOrdemByTipoOrdem";
import ListDadoValorByParent from "../DadoValor/ListDadoValorByParent";
import ListDadoValorByParentForm from "../DadoValor/ListDadoValorByParentForm";
import { PatchPlus as IconPatchPlus} from "react-bootstrap-icons";

class FormProduto extends BaseCrud {

	constructor(props) {
		super(props);
		this.init = this.init.bind(this);
		this.handleSuccess = this.handleSuccess.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleChangeModel = this.handleChangeModel.bind(this);
		this.handleSubmitForm = this.handleSubmitForm.bind(this);

		this.state.model = {
			idProduto : null,
			tipoProduto : null,
			grupoProduto : null,
			unidadeMedida : null,
			classificacaoProduto : null,
			modelo : null,
			marca : null,
			nmProduto : "",
			txProduto : "",
			flControlaEstoque : false,
			cdBarras : "",
			cdIntegracao : "",
			flAtivo : true,
			flRestringirTipoOrdem : false,
			flEmbalagem : false,
			produtoEstoque : [],
			produtoPreco : [],
			produtoDadoValor : []
		};
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		super.init(Config);
	}

	handleSuccess(e) {
		super.handleSuccess(e, Config);
	}

	handleClose(e) {
		super.handleClose(e, Config);
	}

	render() {
		return super.render(
				<Div key={this.state.key}>
					<Panel title="Cadastro de Produto" onClose={this.handleClose} isModal={this.isModal()} localOpen={this.props.localOpen} container={{sizing: Container.sizing.fluid, className : ""}} modal={{sizing: this.props.sizing ? this.props.sizing : ModalDialog.sizing.xl}}>
						<PanelBody className="p-0 m-0">
							<TabNavigator onChangeTab={this.handleChangeTab} className="border-light">
								<Tab title="Produto" className="m-2">
									<Form onSubmit={this.handleSuccess} refParam={f => (this.form = f)}>
										<Row className="g-3 pb-2 px-2">
											<FormItem md={2} label="Código">
												<InputText name="idProduto" value={this.state.model.idProduto} readOnly={true} className="readonly-field" required={false} onChange={this.handleChangeModel}/>
											</FormItem>
											<FormItem md={5} label="Tipo do Produto">
												<SelectTipoProduto disabled={this.state.model.idProduto} name="tipoProduto" value={this.state.model.tipoProduto} required={true} onChange={this.handleChangeModel} placeholder="Selecione o Tipo do Produto" buttons={{search : true, new : false, edit: false}}/>
											</FormItem>
											<FormItem md={5} label="Unidade de Medida">
												<SelectUnidadeMedida name="unidadeMedida" value={this.state.model.unidadeMedida} required={true} onChange={this.handleChangeModel} placeholder="Selecione a Unidade de Medida" buttons={{search : true, new : false, edit: false}}/>
											</FormItem>
											<FormItem md={12} label="Nome do Produto">
												<InputText name="nmProduto" value={this.state.model.nmProduto} autoFocus={true} maxLength={100} required={true} onChange={this.handleChangeModel}/>
											</FormItem>
											<FormItem md={12} label="Descrição do Produto">
												<TextArea name="txProduto" value={this.state.model.txProduto} required={false} onChange={this.handleChangeModel}/>
											</FormItem>
											<FormItem md={6} label="Grupo do Produto">
												<SelectGrupoProduto name="grupoProduto" value={this.state.model.grupoProduto} required={false} onChange={this.handleChangeModel} placeholder="Selecione o Grupo do Produto" buttons={{search : true, new : false, edit: false}}/>
											</FormItem>											
											<FormItem md={6} label="Classificação do Produto">
												<SelectClassificacaoProduto name="classificacaoProduto" value={this.state.model.classificacaoProduto} required={false} onChange={this.handleChangeModel} placeholder="Selecione a Classificação do Produto" buttons={{search : true, new : false, edit: false}}/>
											</FormItem>

											<FormItemMarcaModelo md={[6,6]} name="modelo" value={this.state.model.modelo} required={false} onChange={this.handleChangeModel} show={this.state.model?.tipoProduto?.cdTipoProduto === 'P'}/>

											<FormItem md={6} label="Código de Barras" show={this.state.model?.tipoProduto?.cdTipoProduto === 'P'}>
												<InputText name="cdBarras" value={this.state.model.cdBarras} maxLength={100} required={false} onChange={this.handleChangeModel}/>
											</FormItem>
											<FormItem md={6} label="Código de Integração">
												<InputText name="cdIntegracao" value={this.state.model.cdIntegracao} maxLength={100} required={false} onChange={this.handleChangeModel}/>
											</FormItem>
											<FormItem md={2} label="Ativo?">
												<BtnRadioGroupSimNao name="flAtivo" value={this.state.model.flAtivo} required={true} onChange={this.handleChangeModel}/>
											</FormItem>

											<ListDadoValorByParentForm cdDadoLocal="PRODUTO" parent={this.state.model} onChange={this.handleChangeModel} name="produtoDadoValor"/>
											
											<BtnSubmit hidden > Enviar </BtnSubmit>
										</Row>
										<Row className="mx-1 mb-3 g-1">
											<InputCheckSwitch label="É Embalagem?" name="flEmbalagem" value={this.state.model.flEmbalagem} onChange={this.handleChangeModel} show={this.state.model?.tipoProduto?.cdTipoProduto === 'P'}/>
											<InputCheckSwitch label="Controla Estoque?" name="flControlaEstoque" value={this.state.model.flControlaEstoque} onChange={this.handleChangeModel} show={this.state.model?.tipoProduto?.cdTipoProduto === 'P'}/>
											<InputCheckSwitch disabled={this.state.model.grupoProduto && this.state.model.grupoProduto.flRestringirTipoOrdem} label="Registringir Produto por Tipo de Ordem?" name="flRestringirTipoOrdem" value={this.state.model.flRestringirTipoOrdem} onChange={this.handleChangeModel}/>
										</Row>
									</Form>
								</Tab>
								<Tab show={Permissao.getInstance().getPermissoes([51]) && this.state.model.flControlaEstoque} title="Estoque" disabled={!this.state.model.idProduto} opened={false} className="m-2">
									<ListProdutoEstoque parent={this.state.model} localOpen={BaseList.localOpen.modal}/>
								</Tab>
								<Tab show={Permissao.getInstance().getPermissoes([52])} title="Preço" disabled={!this.state.model.idProduto} opened={false} className="m-2">
									<ListProdutoPreco parent={this.state.model} localOpen={BaseList.localOpen.modal}/>
								</Tab>
								<Tab show={Permissao.getInstance().getPermissoes([143]) && this.state.model.flRestringirTipoOrdem && !this.state.model.grupoProduto?.flRestringirTipoOrdem} title="Tipo de Ordem" disabled={!this.state.model.idProduto} opened={false} className="m-2">
									<ManyToManyCheckProdutoTipoOrdemByTipoOrdem typeCheck={ManyToManyCheckProdutoTipoOrdemByTipoOrdem.typeCheck.box} parent={this.state.model} search={true}/>
								</Tab>
								<Tab show={Permissao.getInstance().getPermissoes([155])} title={<><IconPatchPlus/> Complementar</>} disabled={!this.state.model.idProduto} opened={false} className="p-0 m-0">
									<ListDadoValorByParent cdDadoLocal="PRODUTO" parent={this.state.model} localOpen={BaseList.localOpen.modal}/>
								</Tab>								
							</TabNavigator>
						</PanelBody>
						<PanelFooter>
							<FormButtons show={this.state.tabIndex === 0} onClose={this.handleClose} onSubmit={this.handleSubmitForm}/>
						</PanelFooter>
					</Panel>
				</Div>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <FormProduto {...props} navigate={navigate} params={params} location={location}/>
}
export default With