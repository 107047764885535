//#NO-CHANGE-FILE#

import React from "react";
import { useNavigate, useLocation, useParams } from "react-router";
import { BtnCopy, BtnDelete, BtnNew } from "../../components/Custom";
import Config from "./Config";
import ConfigAnsPerfil from "../AnsPerfil/Config";
import { BaseList } from "../../siht/base";
import { Col, DataGrid, DataGridButtons, DataGridColumn, FormItem, InputGroup, Row } from "../../siht/components";
import { BtnButton, BtnSubmit, Button, Div, Form, InputCheckSwitch, InputNumber } from "../../siht/elements";
import BtnUp from "../../components/BtnUp";
import BtnDown from "../../components/BtnDown";
import { Check2Circle as IconCheck2Circle } from "react-bootstrap-icons";
import { IconEdit } from "../../icons";

class ListAnsPerfilGolpe extends BaseList {

	constructor(props) {
		super(props);
		this.getItens = this.getItens.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleNew = this.handleNew.bind(this);
		this.handleCopy = this.handleCopy.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.handleMore = this.handleMore.bind(this);
		this.onSubmitFilter = this.onSubmitFilter.bind(this);
		this.handleClear = this.handleClear.bind(this);
		this.handleFormSuccess = this.handleFormSuccess.bind(this);
		this.handleFormClose = this.handleFormClose.bind(this);
		this.initStateFilter = this.initStateFilter.bind(this);
		this.handlePromover = this.handlePromover.bind(this);
		this.handleRebaixar = this.handleRebaixar.bind(this);
		this.handleChangeItem = this.handleChangeItem.bind(this);
		this.handleSalvar = this.handleSalvar.bind(this);
		//this.handleAutoSave = this.handleAutoSave.bind(this);
		this.handleAutoSaveItem = this.handleAutoSaveItem.bind(this);

		this.state.nrDenominador = this.props.parent.nrDenominador;
		this.state.nrItemAdd = parseInt(this.props.parent.vlProfundidadeFuro);
		this.state.flAutoSave = true;
		this.state.flDemominador = true;
		this.intervalSave = null;
	}

	componentDidMount() {
		super.componentDidMount();

		//this.intervalSave = setInterval(this.handleAutoSave, 10 * 1000);
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init() {
		super.init(Config);
	}

	initStateFilter() {
		return {
			search: ""
		};
	}

	getItens(more) {
		if (this.props?.parent) {
			super.getItens(Config.getService().getAllByAnsPerfil, this.props.parent, more);
		}
	}

	handleNew(e, qtde = 1) {

		var itens = [];
		for (let i = 0; i < qtde; i++) {
			itens.push({
				idAnsPerfilGolpe: null,
				ansPerfil: this.props.parent,
				nrGolpe1: 0,
				nrProfundidade1: this.state.nrDenominador,
				nrGolpe2: 0,
				nrProfundidade2: this.state.nrDenominador,
				nrGolpe3: 0,
				nrProfundidade3: this.state.nrDenominador,
				nrResistencia1: 0,
				nrResistencia2: 0
			});
		}

		if (itens.length > 0) {
			Config.getService().save(itens, () => {
				this.getItens();
			});
		}
	}

	handleDelete(e, item, fnCallBack = () => { }) {
		super.handleDelete(e, Config, item, fnCallBack, (item) => {
			return item.nrOrdem;
		});
	}

	handleCopy(e, item) {
		item.idAnsPerfilGolpe = null;
		Config.getService().save(item, () => {
			this.getItens();
		});
	}

	handlePromover(e, item) {
		Config.getService().promover(item, () => {
			this.getItens();
		});
	}

	handleRebaixar(e, item) {
		Config.getService().rebaixar(item, () => {
			this.getItens();
		});
	}

	handleChangeItem(e, v, p, item, index) {
		this.setState(state => {
			let nrGolpe1 = parseInt(state.data[index].nrGolpe1);
			let nrGolpe2 = parseInt(state.data[index].nrGolpe2);
			let nrGolpe3 = parseInt(state.data[index].nrGolpe3);

			//let nrProfundidade1 = parseInt(state.data[index].nrProfundidade1);
			//let nrProfundidade2 = parseInt(state.data[index].nrProfundidade2);
			//let nrProfundidade3 = parseInt(state.data[index].nrProfundidade3);

			let nrResistencia1 = (nrGolpe1 + nrGolpe2);
			let nrResistencia2 = (nrGolpe2 + nrGolpe3);

			state.data[index][p] = v;
			state.data[index].flPendente = true;

			state.data[index].nrResistencia1 = nrResistencia1;
			state.data[index].nrResistencia2 = nrResistencia2;

			return state;
		});
	}

	handleAutoSaveItem(index) {
		let item = this.state.data[index];

		if (this.state.flAutoSave && item.flPendente && this.isValido(item)) {
			Config.getService().save(item, () => {
				this.setState(state => {
					state.data[index].flPendente = false;
					return state;
				});
			}, () => { }, false);
		}
	}

	/*handleAutoSave(){
		if(this.state.flAutoSave){
			this.handleSalvar(true);
		}
	}*/

	isValido(item) {
		return (
			!isNaN(parseInt(item.nrGolpe1)) && parseInt(item.nrGolpe1) >= 0 &&
			!isNaN(parseInt(item.nrProfundidade1)) && parseInt(item.nrProfundidade1) >= 0 &&

			!isNaN(parseInt(item.nrGolpe2)) && parseInt(item.nrGolpe2) >= 0 &&
			!isNaN(parseInt(item.nrProfundidade2)) && parseInt(item.nrProfundidade2) >= 0 &&

			!isNaN(parseInt(item.nrGolpe3)) && parseInt(item.nrGolpe3) >= 0 &&
			!isNaN(parseInt(item.nrProfundidade3)) && parseInt(item.nrProfundidade3) >= 0
		);
	}

	handleSalvar(flAutoSave = false) {
		this.state.data.filter(i => i?.flPendente && this.isValido(i)).forEach(model => {
			Config.getService().save(model, () => {
				this.getItens();
			}, () => { });
		});
	}

	render() {
		return super.render(
			<Div className="p-2 mt-2">
				<Div className="d-flex justify-content-end flex-nowrap gap-2">
					<InputCheckSwitch label="Mostrar denominador?" name="flDemominador" checked={Boolean(this.state.flDemominador)} onChange={this.handleChange} />
					<InputCheckSwitch label="Salvar Automático?" name="flAutoSave" checked={Boolean(this.state.flAutoSave)} onChange={this.handleChange} />
					<BtnButton onClick={this.handleSalvar} disabled={this.state.data.filter(i => i.flPendente).length === 0 || !ConfigAnsPerfil.getPermissaoAnsPerfil(this.props.parent).flPodeEditar}> Salvar </BtnButton>
					<BtnNew onClick={this.handleNew} disabled={!ConfigAnsPerfil.getPermissaoAnsPerfil(this.props.parent).flPodeEditar} />
					<BtnDelete disabled={this.state.selecteds.length === 0 || !ConfigAnsPerfil.getPermissaoAnsPerfil(this.props.parent).flPodeEditar} sizing={Button.sizing.default} onClick={e => this.handleDelete(e, this.state.selecteds)}> Excluir </BtnDelete>
				</Div>

				<DataGrid responsive={true} data={this.state.data} search={false} isDisabled={i => false} sizing={DataGrid.sizing.sm} select={ConfigAnsPerfil.getPermissaoAnsPerfil(this.props.parent).flPodeEditar} selecteds={this.state.selecteds} onSelect={(selecteds) => { this.setState({ selecteds: selecteds }) }} getItemValue={i => i.idAnsPerfilGolpe} onMore={this.handleMore}>

					<DataGridColumn label="" field={i =>
						<>{i.flPendente ? <IconEdit width="16" height="16" className="text-warning" /> : <IconCheck2Circle width="24" height="24" className="text-success" />}</>
					} className="text-center" />

					<DataGridColumn label="Profundidade" field={i => `${i.nrOrdem} mt`} className="text-center text-nowrap" />

					<DataGridColumn col={3} label="Golpe 1" field={(i, ix) => <>
						<InputGroup>
							<InputNumber disabled={!ConfigAnsPerfil.getPermissaoAnsPerfil(this.props.parent).flPodeEditar} className="text-center fw-bold" name="nrGolpe1" value={i.nrGolpe1} min={0} onChange={(e, v, p) => this.handleChangeItem(e, v, p, i, ix)} onBlur={e => this.handleAutoSaveItem(ix)} />
							<InputNumber disabled={!ConfigAnsPerfil.getPermissaoAnsPerfil(this.props.parent).flPodeEditar} show={this.state.flDemominador} className="text-center bg-primary-subtle" name="nrProfundidade1" value={i.nrProfundidade1} min={0} onChange={(e, v, p) => this.handleChangeItem(e, v, p, i, ix)} onBlur={e => this.handleAutoSaveItem(ix)} />
						</InputGroup>
					</>} className="text-center" />

					<DataGridColumn col={3} label="Golpe 2" field={(i, ix) => <>
						<InputGroup>
							<InputNumber disabled={!ConfigAnsPerfil.getPermissaoAnsPerfil(this.props.parent).flPodeEditar} className="text-center fw-bold" name="nrGolpe2" value={i.nrGolpe2} min={0} onChange={(e, v, p) => this.handleChangeItem(e, v, p, i, ix)} onBlur={e => this.handleAutoSaveItem(ix)} />
							<InputNumber disabled={!ConfigAnsPerfil.getPermissaoAnsPerfil(this.props.parent).flPodeEditar} show={this.state.flDemominador} className="text-center bg-primary-subtle" name="nrProfundidade2" value={i.nrProfundidade2} min={0} onChange={(e, v, p) => this.handleChangeItem(e, v, p, i, ix)} onBlur={e => this.handleAutoSaveItem(ix)} />
						</InputGroup>
					</>} className="text-center" />

					<DataGridColumn col={3} label="Golpe 3" field={(i, ix) => <>
						<InputGroup>
							<InputNumber disabled={!ConfigAnsPerfil.getPermissaoAnsPerfil(this.props.parent).flPodeEditar} className="text-center fw-bold" name="nrGolpe3" value={i.nrGolpe3} min={0} onChange={(e, v, p) => this.handleChangeItem(e, v, p, i, ix)} onBlur={e => this.handleAutoSaveItem(ix)} />
							<InputNumber disabled={!ConfigAnsPerfil.getPermissaoAnsPerfil(this.props.parent).flPodeEditar} show={this.state.flDemominador} className="text-center bg-primary-subtle" name="nrProfundidade3" value={i.nrProfundidade3} min={0} onChange={(e, v, p) => this.handleChangeItem(e, v, p, i, ix)} onBlur={e => this.handleAutoSaveItem(ix)} />
						</InputGroup>
					</>} className="text-center" />

					<DataGridColumn col={1} label="1º e 2º" field={i => <> {i.nrResistencia1}  </>} className="text-center" />
					<DataGridColumn col={1} label="2º e 3º" field={i => <> {i.nrResistencia2} </>} className="text-center" />

					<DataGridButtons>
						<BtnDelete sizing={Button.sizing.default} onClick={this.handleDelete} tabIndex={-1} disabled={!ConfigAnsPerfil.getPermissaoAnsPerfil(this.props.parent).flPodeEditar} />
						<BtnCopy sizing={Button.sizing.default} onClick={this.handleCopy} tabIndex={-1} disabled={!ConfigAnsPerfil.getPermissaoAnsPerfil(this.props.parent).flPodeEditar} />
						<BtnUp sizing={Button.sizing.default} onClick={this.handlePromover} disabled={(item, index) => index === 0 || !ConfigAnsPerfil.getPermissaoAnsPerfil(this.props.parent).flPodeEditar} tabIndex={-1} />
						<BtnDown sizing={Button.sizing.default} onClick={this.handleRebaixar} disabled={(item, index) => index === (this.state.data.length - 1) || !ConfigAnsPerfil.getPermissaoAnsPerfil(this.props.parent).flPodeEditar} tabIndex={-1} />
					</DataGridButtons>
				</DataGrid>

				<Div>
					<Form onSubmit={e => this.handleNew(e, this.state.nrItemAdd)} refParam={f => (this.form = f)}>
						<Row>
							<FormItem md={2} label="Denominador">
								<InputNumber name="nrDenominador" min={1} max={100} value={this.state.nrDenominador} className="text-center" required={true} onChange={this.handleChange} disabled={!ConfigAnsPerfil.getPermissaoAnsPerfil(this.props.parent).flPodeEditar} />
							</FormItem>
							<FormItem md={2} label="Linhas">
								<InputNumber name="nrItemAdd" min={1} max={20} value={this.state.nrItemAdd} className="text-center" required={true} onChange={this.handleChange} disabled={!ConfigAnsPerfil.getPermissaoAnsPerfil(this.props.parent).flPodeEditar} />
							</FormItem>
							<Col className="d-flex align-items-end">
								<BtnSubmit disabled={!ConfigAnsPerfil.getPermissaoAnsPerfil(this.props.parent).flPodeEditar}> Adicionar Linhas </BtnSubmit>
							</Col>
						</Row>
					</Form>
				</Div>

			</Div>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <ListAnsPerfilGolpe {...props} navigate={navigate} params={params} location={location} />
}
export default With