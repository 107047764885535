
import FormFluxoItemDestino from "./FormFluxoItemDestino.js";
import ListFluxoItemDestino from "./ListFluxoItemDestino.js";
import { Permissao } from "../../controllers";
import { Util } from "../../utils";
import { DadoValorService, FluxoItemDestinoService } from "../../services";

export default class Config {

	static getUrlForm() {
		return "/CadFluxoItemDestino";
	}

	static getUrlList() {
		return "/LstFluxoItemDestino";
	}

	static getComponentForm() {
		return FormFluxoItemDestino;
	}

	static getPermissionForm() {
		return Permissao.getInstance().getPermissoes([197]);
	}

	static getPermissionList() {
		return Permissao.getInstance().getPermissoes([197]);
	}

	static getComponentList() {
		return ListFluxoItemDestino;
	}

	static getService() {
		return FluxoItemDestinoService;
	}

	static getKeyId() {
		return "idFluxoItemDestino";
	}

	static getKeyDescription() {
		return "nmFluxoItemDestino";
	}

	static setDefaultValues(state , fnCallBack = () => {}, className = undefined) {
		className = className === undefined ? "FormFluxoItemDestino" : className;
		let keyNameScreen = Util.toToUpperTextCamelCase(className, "_") + ".";
		var prefix = "CONFIGURACAO.";
		DadoValorService.getValues(`${prefix}${keyNameScreen}`, (response)=> {
			Util.setValueObjectCamelCase(state.model, response, keyNameScreen, prefix);
			fnCallBack();
		}, fnCallBack);
	}
}