//#NO-CHANGE-FILE#

import Config from "./Config";
import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { BaseCrud } from "../../siht/base";
import { FormButtons, FormItem, Row, Panel, PanelBody, PanelFooter, Container, ModalDialog } from "../../siht/components";
import { Form, InputText } from "../../siht/elements";
import { BtnRadioGroupSimNao } from "../../components/Custom";
import { FormItemPessoaByCdTipoPessoa } from "../../components/FormItem";

class FormPessoaConjuge extends BaseCrud {

	constructor(props) {
		super(props);
		this.init = this.init.bind(this);
		this.initModel = this.initModel.bind(this);
		this.handleSuccess = this.handleSuccess.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleChangeModel = this.handleChangeModel.bind(this);
		this.handleSubmitForm = this.handleSubmitForm.bind(this);
		this.handleChangeNmPessoaConjuge = this.handleChangeNmPessoaConjuge.bind(this);
		this.handleChangePessoaConjuge = this.handleChangePessoaConjuge.bind(this);
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init() {
		super.init(Config);
	}

	initModel() {
		return {
			idPessoaConjuge: null,
			pessoa: this.props.parent !== undefined ? this.props.parent : {},
			conjuge: null,
			nmPessoaConjuge: "",
			flAtivo: true
		}
	}

	handleSuccess(e) {
		super.handleSuccess(e, Config);
	}

	handleClose(e) {
		super.handleClose(e, Config);
	}

	handleChangeNmPessoaConjuge(e) {
		this.setState({ model: { ...this.state.model, conjuge: null } });
	}

	handleChangePessoaConjuge(e) {
		this.setState({ model: { ...this.state.model, nmPessoaConjuge: "" } });
	}

	render() {

		return super.render(
			<Form onSubmit={this.handleSuccess} refParam={f => (this.form = f)} key={this.state.key}>
				<Panel key={this.state.key} title="Cadastro de Conjuge" localOpen={this.props.localOpen} onClose={this.handleClose} isModal={this.isModal()} container={{ sizing: Container.sizing.xxl, className: "" }} modal={{ sizing: this.props.sizing ? this.props.sizing : ModalDialog.sizing.xl }}>
					<PanelBody>
						<Row className="g-3 pb-2 px-2">
							<FormItemPessoaByCdTipoPessoa help="Selecione uma pessoa ou digite o nome abaixo!" show={this.state.model.nmPessoaConjuge === null || this.state.model.nmPessoaConjuge?.toString().length === 0} cdTipoPessoa="F" label={`Nome do Conjuge`} md={12} name="conjuge" value={this.state.model.conjuge} required={true} onChange={(e, v, p) => this.handleChangeModel(e, v, p, this.handleChangePessoaConjuge)} />

							<FormItem help="Digite o nome do conjuge!" show={this.state.model.conjuge === null || this.state.model.conjuge?.idPessoa === undefined} md={12} label={`Nome do Conjuge`}>
								<InputText name="nmPessoaConjuge" value={this.state.model.nmPessoaConjuge} autoFocus={true} maxLength={100} required={true} onChange={(e, v, p) => this.handleChangeModel(e, v, p, this.handleChangeNmPessoaConjuge)} />
							</FormItem>

							<FormItem md={3} label="Ativo?">
								<BtnRadioGroupSimNao name="flAtivo" value={this.state.model.flAtivo} required={true} onChange={this.handleChangeModel} />
							</FormItem>
						</Row>
					</PanelBody>
					<PanelFooter>
						<FormButtons onClose={this.handleClose} />
					</PanelFooter>
				</Panel>
			</Form>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <FormPessoaConjuge {...props} navigate={navigate} params={params} location={location} />
}
export default With