import React, { Component } from "react";

export default class Button extends Component {

    static sizing = {
        sm: "sm",
        lg: "lg",
        default: false
    };

    static color = {
        primary: "primary",
        secondary: "secondary",
        success: "success",
        danger: "danger",
        warning: "warning",
        info: "info",
        light: "light",
        dark: "dark",
        link: "link",
        outlinePrimary: "outline-primary",
        outlineSecondary: "outline-secondary",
        outlineSuccess: "outline-success",
        outlineDanger: "outline-danger",
        outlineWarning: "outline-warning",
        outlineInfo: "outline-info",
        outlineLight: "outline-light",
        outlineDark: "outline-dark",
        default: "primary"
    };

    constructor(props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    handleClick(e) {
        if (this.props.onClick) {
            this.props.onClick(e);
        }
    }

    render(type) {
        if (this.props.show !== undefined && !Boolean(this.props.show)) {
            return (<></>);
        }

        var props = Object.assign({}, this.props);
        props.color = props.disabled === true ? Button.color.outlineSecondary : props.color;
        props.type = type ? type : (this.props.type ? this.props.type : "button");
        props.className = `btn ${props.className !== undefined ? props.className : ""} ${this.props.sizing ? "btn-" + this.props.sizing : ""} btn-${props.color ? props.color : Button.color.default}`;
        props.onClick = this.handleClick;

        delete props.color;
        delete props.sizing;
        delete props.show;

        return (
            <button {...props}>
                {this.props.children}
            </button>
        );

    }
}