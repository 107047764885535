//#NO-CHANGE-FILE#
import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import Config from "./Config";
import { BaseList } from "../../siht/base";
import { Row, Col, Card, CardBody } from "../../siht/components";
import { Div } from "../../siht/elements";
import Util from "../../utils/Util";
import { StatusCustom } from "../../components/Custom";

class ListOrdemHistorico extends BaseList {

	constructor(props) {
		super(props);
		this.getItens = this.getItens.bind(this);
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		super.init(Config);
	}

	getItens(more){
		if(this.props?.parent){
			super.getItens(Config.getService().getAllByOrdem, this.props.parent, more);
		}
	}

	render() {

		var length = this.state.data.length;

		return super.render(
			<Div className="p-2 mt-2">
				<Div className="d-flex h-100 justify-content-center">

					<Card className="container p-3">
						<CardBody className="h-100">
							{this.state.data.reverse().map((historico, index) =>(
								<Row key={index}>
									<Col className="text-end fs-7 text-secondary" sizing={5}>
										{Util.date2Br(historico.dhInsert, false)} <br/>
										{Util.hour2Br(historico.dhInsert)}
									</Col>
									<Col sizing={2} className="text-center">
										<Div className="d-flex justify-content-center">
											<Div className="circleStatus" style={{backgroundColor : historico.statusOrdem?.cdCorFundo, color : historico.statusOrdem?.cdCorTexto, border : "1px solid " + historico.statusOrdem?.cdCorBorda }}>
												&nbsp;
											</Div>
										</Div>

										{length !== (index + 1) ? 
											<Div className="d-flex justify-content-center h-100">
												<Div style={{ borderLeft : "2px dashed silver", height : "100%", minHeight : "80px", width : "2px"}}></Div>
											</Div>
										: <></>}
									</Col>
									<Col className="text-start text-dark" sizing={5}>
										<Div className="fw-bold">											
											Status alterado para &nbsp;<StatusCustom color={historico.statusOrdem}> {historico.statusOrdem.nmStatusOrdem} </StatusCustom>
										</Div>
										<Div className="fs-7 text-secondary">
											{historico.usuario.nmUsuario}
										</Div>
										{historico.txObservacao  ? 
											<Div className="fs-7 py-2">
												<p className="mb-0 lh-sm fs-7" dangerouslySetInnerHTML={{ __html: Util.htmlFormatWhatsapp(historico.txObservacao) }}/>
											</Div>
										: <></>}
										
									</Col>
								</Row>

							))}
						</CardBody>
					</Card>
				</Div>
			</Div>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <ListOrdemHistorico {...props} navigate={navigate} params={params} location={location}/>
}
export default With