
import FormPessoaEmail from "./FormPessoaEmail.js";
import ListPessoaEmail from "./ListPessoaEmail.js";
import { Permissao } from "../../controllers";
import { Util } from "../../utils";
import { DadoValorService, PessoaEmailService } from "../../services";

export default class Config {

	static getUrlForm() {
		return "/CadPessoaEmail";
	}

	static getUrlList() {
		return "/LstPessoaEmail";
	}

	static getComponentForm() {
		return FormPessoaEmail;
	}

	static getPermissionForm() {
		return Permissao.getInstance().getPermissoes([36]);
	}

	static getPermissionList() {
		return Permissao.getInstance().getPermissoes([36]);
	}

	static getComponentList() {
		return ListPessoaEmail;
	}

	static getService() {
		return PessoaEmailService;
	}

	static getKeyId() {
		return "idPessoaEmail";
	}

	static getKeyDescription() {
		return "nmPessoaEmail";
	}

	static setDefaultValues(state , fnCallBack = () => {}, className = undefined) {
		className = className === undefined ? "FormPessoaEmail" : className;
		let keyNameScreen = Util.toToUpperTextCamelCase(className, "_") + ".";
		var prefix = "CONFIGURACAO.";
		DadoValorService.getValues(`${prefix}${keyNameScreen}`, (response)=> {
			Util.setValueObjectCamelCase(state.model, response, keyNameScreen, prefix);
			fnCallBack();
		}, fnCallBack);
	}
}