import React, { Component } from "react";
import { BtnRadio } from "../elements";
import BtnGroup from "./BtnGroup";

export default class BtnRadioGroup extends Component {

    static color = {
        primary : "primary",
        success : "success",
        danger : "danger",
        warning : "warning",
        dark : "dark"
    };

    static sizing = {
        sm: "sm",
        default: false,
        lg: "lg",
    }

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    getValue(value){
        switch(value?.toString()?.toLowerCase()?.trim()){
            case "true":
            case "yes": 
            case "1":
                return true;
    
            case "":
            case "false":
            case "no": 
            case "0": 
            case null: 
            case undefined:
                return false;
    
            default:
                return value;
        }
    }

    handleChange(e, selected, name) {
        name = this.props.name;
        if(selected === undefined || !name){
            //selected = this.state.options.find(item => this.getOptionValue(item)?.toString() === e?.target?.value?.toString());
            //selected = selected === undefined ? "" : selected;
        }

        selected = this.getValue(selected);

        if(this.props.onChange){
            let callBack = this.props.onChangeCallBack !== undefined ?  () => this.props.onChangeCallBack(e, selected, name) : false;
            this.props.onChange(e, selected, this.props.name, callBack);
        }
    }

    handleClick(e, selected, name) {
        name = this.props.name;

        if(selected === undefined || !name){
            //selected = this.state.options.find(item => this.getOptionValue(item)?.toString() === e?.target?.value?.toString());
            //selected = selected === undefined ? "" : selected;
        }

        selected = this.getValue(selected);
        
        if(this.props.onClick){
            this.props.onClick(e, selected, this.props.name);
        }
    }

    render() {
        if (this.props.show !== undefined && !Boolean(this.props.show)) {
            return (<></>);
        }

        var inputs = [];

        if (this.props.options !== undefined) {
            inputs = this.props.options.map((option, index) => <BtnRadio key={index} {...option} />);
        } else {
            inputs = React.Children.toArray(this.props.children).filter(child => child.type === BtnRadio);
        }

        var name = ("rbg" + Math.random());        

        const props = Object.assign({}, this.props);
        props.className = `btn-group w-100 ${props.className !== undefined ? props.className : ""} ${props.sizing ? `btn-group-${props.sizing}` : ""}`;
        delete props.onChange;
        delete props.onClick;
        delete props.options;
        delete props.name;
        delete props.value;
        delete props.color;
        delete props.show;
        delete props.opcional;
        
        return (
            <BtnGroup {...props}>
                {inputs.map((child, index) => {

                    var disabled =  false;

                    if(this.props.disabled !== undefined){
                        disabled = this.props.disabled;
                    }else if(child.props.disabled !== undefined){
                        disabled = child.props.disabled;
                    }

                    let element = React.cloneElement(child, {
                        key : index,
                        name: name,
                        onChange: this.props.onChange ? this.handleChange : () => {},
                        onClick: child.props.onClick ? child.props.onClick : (this.props.onClick ? this.handleClick : () => {}),
                        id: child.props.id !== undefined ? child.props.id : "frmItem" + (child.props.name ? child.props.name : Math.random()),
                        checked: this.props?.value?.toString() === child?.props?.value?.toString(),
                        disabled : disabled
                    });

                    return (element);
                })}
            </BtnGroup>
        );


    }
}