
import FormContaReceberParcela from "./FormContaReceberParcela.js";
import ListContaReceberParcela from "./ListContaReceberParcela.js";
import { Permissao } from "../../controllers";
import { Util } from "../../utils";
import { DadoValorService, ContaReceberParcelaService } from "../../services";

export default class Config {

	static getUrlForm() {
		return "/CadContaReceberParcela";
	}

	static getUrlList() {
		return "/LstContaReceberParcela";
	}

	static getComponentForm() {
		return FormContaReceberParcela;
	}

	static getPermissionForm() {
		return Permissao.getInstance().getPermissoes([189]);
	}

	static getPermissionList() {
		return Permissao.getInstance().getPermissoes([189]);
	}

	static getComponentList() {
		return ListContaReceberParcela;
	}

	static getService() {
		return ContaReceberParcelaService;
	}

	static getKeyId() {
		return "idContaReceberParcela";
	}

	static getKeyDescription() {
		return "nmContaReceberParcela";
	}

	static setDefaultValues(state , fnCallBack = () => {}, className = undefined) {
		className = className === undefined ? "FormContaReceberParcela" : className;
		let keyNameScreen = Util.toToUpperTextCamelCase(className, "_") + ".";
		var prefix = "CONFIGURACAO.";
		DadoValorService.getValues(`${prefix}${keyNameScreen}`, (response)=> {
			Util.setValueObjectCamelCase(state.model, response, keyNameScreen, prefix);
			fnCallBack();
		}, fnCallBack);
	}
}