//#NO-CHANGE-FILE#

import React from "react";
import { useNavigate, useLocation, useParams } from "react-router";
import Config from "./Config";
import { BaseList } from "../../siht/base";
import { DataGrid, DataGridColumn } from "../../siht/components";
import { Div, Span } from "../../siht/elements";
import Util from "../../utils/Util";
import { StatusCustom } from "../../components/Custom";

class ListFaturaDesconto extends BaseList {

	constructor(props) {
		super(props);
		this.getItens = this.getItens.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleNew = this.handleNew.bind(this);
		this.handleEdit = this.handleEdit.bind(this);
		this.handleCopy = this.handleCopy.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.handleMore = this.handleMore.bind(this);
		this.onSubmitFilter = this.onSubmitFilter.bind(this);
		this.handleClear = this.handleClear.bind(this);
		this.handleFormSuccess = this.handleFormSuccess.bind(this);
		this.handleFormClose = this.handleFormClose.bind(this);
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		super.init(Config);
	}

	initStateFilter(){
        return {
            search: "",
            page : null,
            pageSize : null,
        };
    }

	getItens(more){
		if(this.props?.parent){
			super.getItens(Config.getService().getAllByFatura, this.props.parent, more);
		}
	}

	handleNew(e) {
		super.handleNew(e, Config);
	}

	handleEdit(e, item) {
		super.handleEdit(e, Config, item);
	}

	handleDelete(e, item) {
		super.handleDelete(e, Config, item);
	}

	handleCopy(e, item) {
		super.handleCopy(e, Config, item);
	}

	getFormEmbedded(){
		return super.getFormEmbedded(Config);
	}

	render() {
		return super.render(
			<Div className="p-2 mt-2">
				<DataGrid onDoubleClickRow={this.handleEdit} responsive={true} data={this.state.data} search={false} isDisabled={i => false} sizing={DataGrid.sizing.sm} select={false} selecteds={this.state.selecteds}>
					<DataGridColumn label="Código" field={i => i.idFaturaDesconto} className="text-center"/>
					<DataGridColumn col={3} label="Tipo de Baixa" field={i => <StatusCustom color={i.tipoBaixa}> {i.tipoBaixa?.nmTipoBaixa} </StatusCustom>} className="text-start"/>
					<DataGridColumn col={3} label="Tipo de Desconto" field={i => <StatusCustom color={i.tipoDesconto}> {i.tipoDesconto?.nmTipoDesconto} </StatusCustom>} className="text-start"/>					
					<DataGridColumn col={4} label="Observação" field={i => i.txDesconto} className="text-start"/>
					<DataGridColumn col={2} label="Desconto" field={i => <Span className="fw-bold text-danger">{Util.floatToReais(i.vlDesconto)}</Span>} className="text-end"/>
				</DataGrid>
			</Div>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <ListFaturaDesconto {...props} navigate={navigate} params={params} location={location}/>
}
export default With