import { Component } from "react";
import EventBus from "../../controllers/EventBus";
import { toast } from "react-toastify";

export default class BaseComponent extends Component {

    constructor(props) {
        super(props);

        this.setStateCache = this.setStateCache.bind(this);
        this.getStateCache = this.getStateCache.bind(this);

        EventBus.cleanWaiting();

        this.cache = {
            active: false,
            id: "cache-" + this.constructor.name,
            state: {},
            clear: true
        };

        this.delays = {};
        this.state = {};
    }

    componentDidUpdate(nextProps) {
    }

    componentDidMount(fnCallBack) {
        this.getStateCache(fnCallBack);
    }

    componentWillUnmount() {
        this.setStateCache();
    }

    setModel(values, fnCallBack = () => { }) {
        this.setState((state) => {
            Object.keys(values).forEach(key => {                
                state.model[key] = values[key];
            });            
            return state;
        }, fnCallBack);
    }

    isObject(obj) {
        return obj === Object(obj)
    }

    setObject(state, cache, storage = {}) {
        
        if(cache){
            Object.keys(cache).forEach(key => {
                try {
                    if (this.isObject(cache[key])) {
                        storage[key] = storage[key] === undefined ? {} : storage[key];
                        this.setObject(state[key], cache[key], storage[key]);
                    } else {
                        if (cache[key] && state[key]) {
                            storage[key] = state[key];
                        }
                    }
                } catch (error) {
                    console.log("Error Cache:" + key);
                    console.log(error);
                }
            });
        }
       

        return storage;
    }


    setStateCache() {
        var storage = this.setObject(this.state, this.cache.state);
        if (this.cache.active) {            
            localStorage.setItem(this.cache.id, JSON.stringify(storage));
        }
    }

    getStateCache(fnCallBack) {
        if (this.cache.active && localStorage.getItem(this.cache.id)) {
            try {
                var cache = JSON.parse(localStorage.getItem(this.cache.id));
                this.setState(state => {
                    this.setObject(cache, this.cache.state, state);
                    return state;
                }, fnCallBack);
            } catch (error) { }
            if (this.cache.clear) {
                this.clearCache();
            }
        } else {
            if (fnCallBack) {
                fnCallBack();
            }
        }
    }

    clearCache() {
        localStorage.removeItem(this.cache.id);
    }

    handleChangeFilter(e, value, prop, fnCallBack = false) {
        this.handleChange(e, value, prop, this.state.filter, fnCallBack);
    }

    handleChangeModel(e, value, prop, fnCallBack = false) {
        this.handleChange(e, value, prop, this.state.model, fnCallBack);
    }

    handleChangeState(e, value, prop, fnCallBack = false) {
        this.handleChange(e, value, prop, this.state, fnCallBack);
    }

    /*setStateSplit(state, v, p){
        const myArray = state.split(" ");
    }*/

    handleChange(e, value, prop, state = false, fnCallBack = false) {

        if (value === undefined) {
            return;
        }

        if (!state) {
            state = this.state;
            state[prop] = value;
        }else if(typeof state === 'string' || state instanceof String){
            let prps = state.split(".");

            if(prps.length > 0){
                let _state = this.state;            
                state = this.state;

                prps.forEach((s) => {
                    _state = _state[s];
                });

                _state[prop] = value;
            }

        }else{
            state[prop] = value;
        }        

        if (fnCallBack) {
            this.setState(state, () => fnCallBack(e, value, state, prop));
        } else {
            this.setState(state);
        }
    }

    handleSubmit(event) {
        event.preventDefault();

        var form = event.currentTarget;

        if (form.checkValidity() === false) {
            this.setState({ validated: true });
            event.stopPropagation();
            toast.warning('Verifique os campos do formulário!');
            return;
        }

        if (this.onSubmit) {
            this.onSubmit(event);
        }
    }

    /*getProps() {
        return Object.fromEntries(Object.entries(this.props).filter(([key, value]) => key.indexOf('app-special-') !== 0));
    }*/

    receivEventDispach(event, data) {

    }

    getMap(arr, fn){
        return arr ? arr.map(fn) : [];
    }

    objEqual(object1, object2) {
        const keys1 = Object.keys(object1);
        const keys2 = Object.keys(object2);

        if (keys1.length !== keys2.length) {
            return false;
        }

        for (let key of keys1) {
            if (object1[key] !== object2[key]) {
                return false;
            }
        }

        return true;
    }

    getProps(props, index){

		var obj = {};

		Object.keys(this.props).filter(key => props.includes(key)).forEach((key) => {
			if(Array.isArray(this.props[key])){
				obj[key] = this.props[key][index];
			}else{
				obj[key] = this.props[key];
			}
		});

		return obj;
	}

    render(render) {
        if(this.props.show !== undefined && !Boolean(this.props.show)){
            return (<></>);
        }

        return render;
    }
}