import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { BaseCrud } from "../../siht/base";
import { Div } from "../../siht/elements";
import Util from "../../utils/Util";
import FaturaCobrancaService from "../../services/FaturaCobrancaService";
import FaturaCobrancaTransacaoService from "../../services/FaturaCobrancaTransacaoService";
import Config from "./Config";
import { CheckCircle as IconCheckCircle} from "react-bootstrap-icons";

class FormPagarEscolha extends BaseCrud {

	constructor(props) {
		super(props);
		this.init = this.init.bind(this);
		this.initModel = this.initModel.bind(this);
		this.handleSuccess = this.handleSuccess.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleChangeModel = this.handleChangeModel.bind(this);
		this.handleSubmitForm = this.handleSubmitForm.bind(this);

        this.handleClickPagamento = this.handleClickPagamento.bind(this);
        this.getFaturaCobranca = this.getFaturaCobranca.bind(this);        
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
        this.setState({ key : Math.random() }, this.getFaturaCobranca);
	}

    getFaturaCobranca(){
        if(this.state.model.faturaCobranca?.cdUid){
            FaturaCobrancaService.getOneByCdUid(this.state.model.faturaCobranca?.cdUid, response =>{
                this.setModel({faturaCobranca : response});
            });
        }
    }  

    handleClickPagamento(e, faturaCobrancaTipoPagamento){

        let payload = {
            faturaCobranca : this.state.model.faturaCobranca,
            faturaCobrancaTipoPagamento : faturaCobrancaTipoPagamento
        };

        FaturaCobrancaTransacaoService.getAtiva(payload, faturaCobrancaTransacao =>{
            this.props.navigate(Config.getUrlPagamento(faturaCobrancaTransacao))
        });
    }    

	initModel(){
		return {
            faturaCobranca : { 
                cdUid: this.props.params?.cdUid
            }
		}
	}

	render() {
		return super.render (            
            <Div className="container py-3" key={this.state.key}>
                {Config.getRenderHeader(this.state.model.faturaCobranca?.fatura?.unidade)}

                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item active" aria-current="page">Pagamento de Fatura</li>
                    </ol>
                </nav>

                <Div className="pricing-header p-3 pb-md-4 mx-auto text-center">
                    <h1 className="display-5 fw-normal">Pagamento de Fatura</h1>
                    <p className="fs-5 text-muted">Pagamento da cobrança código <strong>{this.state.model.faturaCobranca?.idFaturaCobranca}</strong>, no valor de <strong>{Util.floatToReais(this.state.model.faturaCobranca?.vlCobranca)}</strong> com vencimento em <strong>{Util.date2Br(this.state.model.faturaCobranca?.dtVencimento)}</strong>, escolha abaixo a sua forma de pagamento referente a essa cobrança:
                    </p>
                </Div>


                {!this.state.model.faturaCobranca?.statusFaturaCobranca?.flPaga && this.state.model.faturaCobranca?.flVencido ?
                    <Div className="alert alert-danger" role="alert">
                        <strong>Atenção:</strong> Essa cobrança está vencida há {this.state.model.faturaCobranca?.nrDiasVencido} dia(s)!
                    </Div>
                    : <></>}

                {this.state.model.faturaCobranca?.statusFaturaCobranca?.flPaga ?

                    <Div className="text-center">
                        <Div className="alert alert-success" role="alert">
                            <IconCheckCircle width={40} height={40}/>
                            <h3>Essa cobrança foi quitada!</h3>
                        </Div>
                    </Div>
                    :
                    <Div>
                        <Div className="list-group">
                            {this.state.model.faturaCobranca?.faturaCobrancaTipoPagamentos?.map((faturaCobrancaTipoPagamento, index) => (
                                <React.Fragment key={index}>
                                        <button onClick={e => this.handleClickPagamento(e, faturaCobrancaTipoPagamento)} className="list-group-item list-group-item-action d-flex gap-3 py-3" aria-current="true">
                                            {Config.getIconTipoPagamento(faturaCobrancaTipoPagamento.tipoPagamento)}
                                            <Div className="d-flex gap-2 w-100 justify-content-between">
                                                <Div>
                                                    <p className="mb-0 fs-4 fw-bold">
                                                        {faturaCobrancaTipoPagamento.tipoPagamento.nmTipoPagamento}
                                                    </p>

                                                    <p className="mb-0 opacity-75 fs-5">
                                                        {parseInt(faturaCobrancaTipoPagamento.nrParcelaMaxima) === 1 ? "pagamento à vista" : `parcelamento em até ${faturaCobrancaTipoPagamento.nrParcelaMaxima}x`}
                                                    </p>

                                                    {/*<p className="mb-0 opacity-75 fs-5"> À pagar: <strong className="text-primary">{Util.floatToReais(this.state.model.faturaCobranca?.vlAberto)}</strong> </p>*/}
                                                    
                                                    {this.state.model.faturaCobranca?.vlMultaHoje > 0 ?
                                                        <p className="mb-0 opacity-75 fs-5">+ Multa: <strong>{Util.floatToReais(this.state.model.faturaCobranca?.vlMultaHoje)}</strong> <small>(por atraso)</small></p>
                                                        : <></>}

                                                    {this.state.model.faturaCobranca?.vlJurosHoje > 0 ?
                                                        <p className="mb-0 opacity-75 fs-5">+ Juros: <strong>{Util.floatToReais(this.state.model.faturaCobranca?.vlJurosHoje)}</strong> <small>(por atraso)</small></p>
                                                        : <></>}
                                                    {faturaCobrancaTipoPagamento?.faturaCobrancaTipoPagamentoParcelas ?
                                                        <ul>                                                        
                                                        {faturaCobrancaTipoPagamento?.faturaCobrancaTipoPagamentoParcelas?.map((faturaCobrancaTipoPagamentoParcela, index2) =>(
                                                            <li key={index2}>
                                                                {faturaCobrancaTipoPagamentoParcela.nrParcela}x 
                                                                {parseFloat(faturaCobrancaTipoPagamentoParcela.pcTaxa) > 0 ? ` com acréscimo de ${Util.floatToValor(faturaCobrancaTipoPagamentoParcela.pcTaxa, 2)}% de taxa ` : " sem acréscimo "}
                                                                <strong className="text-primary">{Util.floatToReais(faturaCobrancaTipoPagamentoParcela.vlTransacao)}</strong>
                                                            </li>
                                                        ))}
                                                        </ul>
                                                    :<></>}
                                                </Div>
                                                <small className="fs-4 fw-bold text-nowrap text-secondary">{Util.floatToReais(
                                                    this.state.model.faturaCobranca?.vlAberto + this.state.model.faturaCobranca?.vlJurosHoje  + this.state.model.faturaCobranca?.vlMultaHoje
                                                )} </small>
                                            </Div>
                                        </button>                                       
                                </React.Fragment>
                            ))}
                        </Div>
                    </Div>
                }

                {Config.getRenderFooter(this.state.model.faturaCobranca?.fatura?.unidade)}
            </Div>
                    
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <FormPagarEscolha {...props} navigate={navigate} params={params} location={location}/>
}
export default With