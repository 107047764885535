import React from "react";
import Field from "./Field";

export default class TextArea extends Field {

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    handleChange(e) {
        super.handleChange(e, e?.target?.value, e?.target?.name);
    }

    handleBlur(e) {
        super.handleBlur(e, e?.target?.value, e?.target?.name);
    }

    render() {
        const props = Object.assign({}, this.props);
        delete props.show;
        props.onChange = this.handleChange;
        props.onBlur = this.handleBlur;
        props.value = props.value === null ? "" : props.value;
        props.ref = this.props.refParam ? this.props.refParam : this.ref;
        props.className = `form-control ${props.className ? props.className : ""} ${props.sizing ? "form-control-" + props.sizing : ""} ${props.readOnly !== undefined && props.readOnly ? "readonly-field" : ""}`;

        return super.render(<textarea {...this.getProps(props)} />);
    }
}