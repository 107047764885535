//#NO-CHANGE-FILE#

import Http from "../controllers/Http";

export default class PessoaService {

	static urlBase = "/pessoa/";

	static getOneByCnpj(cnpj, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(PessoaService.urlBase + 'cnpj/' + cnpj)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByCpf(cpf, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(PessoaService.urlBase + 'cpf/' + cpf)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByCpfDtNascimento(cpf, dtNascimento, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(PessoaService.urlBase + 'cpf/' + cpf + '/' + dtNascimento)
			.then(fnSucess)
			.catch(fnError);
	}

	static getForCombo(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(PessoaService.urlBase + 'forCombo', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static list(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(PessoaService.urlBase + 'list', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneById(id, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.get(PessoaService.urlBase + id)
			.then(fnSucess)
			.catch(fnError);
	}

	static create(data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(PessoaService.urlBase, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static update(id, data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.put(PessoaService.urlBase + id, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static delete(id, fnSucess = () => { }, fnError = () => { }) {
		if(Number.isInteger(id)){
			Http.getInstanceAppJson()
				.delete(PessoaService.urlBase + id)
				.then(fnSucess)
				.catch(fnError);
		}else{
			Http.getInstanceAppJson()
			.post(PessoaService.urlBase + 'delete', id)
			.then(fnSucess)
			.catch(fnError);
		}
	}

	static save(data, fnSucess = () => { }, fnError = () => { }) {
		if (data.idPessoa) {
			this.update(data.idPessoa, data, fnSucess, fnError);
		} else {
			this.create(data, fnSucess, fnError);
		}
	}

	static getAllByTipoPessoa(tipoPessoa, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(PessoaService.urlBase + 'tipoPessoa', tipoPessoa)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByTipoPessoa(tipoPessoa, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(PessoaService.urlBase + 'tipoPessoa/' + tipoPessoa.idTipoPessoa)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByTipoRegimeTributario(tipoRegimeTributario, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(PessoaService.urlBase + 'tipoRegimeTributario', tipoRegimeTributario)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByTipoRegimeTributario(tipoRegimeTributario, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(PessoaService.urlBase + 'tipoRegimeTributario/' + tipoRegimeTributario.idTipoRegimeTributario)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByTipoRegimeTributarioEspecial(tipoRegimeTributarioEspecial, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(PessoaService.urlBase + 'tipoRegimeTributarioEspecial', tipoRegimeTributarioEspecial)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByTipoRegimeTributarioEspecial(tipoRegimeTributarioEspecial, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(PessoaService.urlBase + 'tipoRegimeTributarioEspecial/' + tipoRegimeTributarioEspecial.idTipoRegimeTributarioEspecial)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByPessoaEmail(pessoaEmail, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(PessoaService.urlBase + 'pessoaEmail', pessoaEmail)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByPessoaEmail(pessoaEmail, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(PessoaService.urlBase + 'pessoaEmail/' + pessoaEmail.idPessoaEmail)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByPessoaTelefone(pessoaTelefone, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(PessoaService.urlBase + 'pessoaTelefone', pessoaTelefone)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByPessoaTelefone(pessoaTelefone, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(PessoaService.urlBase + 'pessoaTelefone/' + pessoaTelefone.idPessoaTelefone)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByPessoaEndereco(pessoaEndereco, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(PessoaService.urlBase + 'pessoaEndereco', pessoaEndereco)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByPessoaEndereco(pessoaEndereco, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(PessoaService.urlBase + 'pessoaEndereco/' + pessoaEndereco.idPessoaEndereco)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByPessoaPagina(pessoaPagina, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(PessoaService.urlBase + 'pessoaPagina', pessoaPagina)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByPessoaPagina(pessoaPagina, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(PessoaService.urlBase + 'pessoaPagina/' + pessoaPagina.idPessoaPagina)
			.then(fnSucess)
			.catch(fnError);
	}
}