
import FormFaturaNotaFiscalItemObra from "./FormFaturaNotaFiscalItemObra.js";
import ListFaturaNotaFiscalItemObra from "./ListFaturaNotaFiscalItemObra.js";
import { Permissao } from "../../controllers";
import { Util } from "../../utils";
import { DadoValorService, FaturaNotaFiscalItemObraService } from "../../services";

export default class Config {

	static getUrlForm() {
		return "/CadFaturaNotaFiscalItemObra";
	}

	static getUrlList() {
		return "/LstFaturaNotaFiscalItemObra";
	}

	static getComponentForm() {
		return FormFaturaNotaFiscalItemObra;
	}

	static getPermissionForm() {
		return Permissao.getInstance().getPermissoes([142]);
	}

	static getPermissionList() {
		return Permissao.getInstance().getPermissoes([142]);
	}

	static getComponentList() {
		return ListFaturaNotaFiscalItemObra;
	}

	static getService() {
		return FaturaNotaFiscalItemObraService;
	}

	static getKeyId() {
		return "idFaturaNotaFiscalItemObra";
	}

	static getKeyDescription() {
		return "nmFaturaNotaFiscalItemObra";
	}

	static setDefaultValues(state , fnCallBack = () => {}, className = undefined) {
		className = className === undefined ? "FormFaturaNotaFiscalItemObra" : className;
		let keyNameScreen = Util.toToUpperTextCamelCase(className, "_") + ".";
		var prefix = "CONFIGURACAO.";
		DadoValorService.getValues(`${prefix}${keyNameScreen}`, (response)=> {
			Util.setValueObjectCamelCase(state.model, response, keyNameScreen, prefix);
			fnCallBack();
		}, fnCallBack);
	}
}