import Button from "./Button";

export default class BtnSubmit extends Button {

    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    render() {
        return super.render("submit");
    }
}