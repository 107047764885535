//#NO-CHANGE-FILE#

import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import SelectUnidadeMedida from "../../components/SelectDB/SelectUnidadeMedida";
import BtnRadioGroupSimNao from "../../components/BtnRadioGroupSimNao";
import FormFaturaNotaFiscalItemIss from "../FaturaNotaFiscalItemIss/FormFaturaNotaFiscalItemIss";
import ListFaturaNotaFiscalItemRetencao from "../FaturaNotaFiscalItemRetencao/ListFaturaNotaFiscalItemRetencao";
import FormFaturaNotaFiscalItemObra from "../FaturaNotaFiscalItemObra/FormFaturaNotaFiscalItemObra";
import Permissao from "../../controllers/Permissao";
import { BaseList, BaseCrud } from "../../siht/base";
import { FormButtons, FormItem, Row, TabNavigator, Tab, Panel, PanelBody, PanelFooter, Container, BtnRadioGroup } from "../../siht/components";
import { InputText, InputNumber, InputNumberFormat, Form, BtnSubmit, TextArea, BtnRadio } from "../../siht/elements";
import Config from "./Config";
import { BagDash as IconBagDash, Basket as IconBasket, ConeStriped as IconConeStriped, Percent as IconPercent } from "react-bootstrap-icons";

class FormFaturaNotaFiscalItem extends BaseCrud {

	constructor(props) {
		super(props);
		this.init = this.init.bind(this);
		this.handleSuccess = this.handleSuccess.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleChangeModel = this.handleChangeModel.bind(this);
		this.handleSubmitForm = this.handleSubmitForm.bind(this);

		this.state.model = {
			idFaturaNotaFiscalItem : null,
			faturaNotaFiscal : this.props.parent !== undefined ? this.props.parent : {},
			dsDiscriminacao : "",
			cdTributacaoMunicipio : "",
			cdClassificacaoCnae : "",
			cdItemListaServico : "",
			unidadeMedida : null,
			nrQuantidade : null,
			vlItem : null,
			vlBaseCalculo : null,
			vlDeducoes : null,
			vlDescontoCondicionado : null,
			vlDescontoIncondicionado : null,
			vlLiquido : null,
			vlUnitario : null,
			flTributavel : true,
			faturaNotaFiscalItemIss : [],
			faturaNotaFiscalItemRetencao : [],
			faturaNotaFiscalItemObra : []
		};
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		super.init(Config, () =>{
			Config.getService().getOneByFaturaNotaFiscal(this.props.parent, ( response )=>{
				this.setState({model : response});
			})
		});
	}

	handleSuccess(e) {
		super.handleSuccess(e, Config);
	}

	handleClose(e) {
		super.handleClose(e, Config);
	}

	render() {
		let editable = this.props.editable !== undefined ? this.props.editable : true;

		return super.render(
					<Panel key={this.state.key} title="Cadastro de Item" localOpen={this.props.localOpen} onClose={this.handleClose} isModal={this.isModal()} container={{sizing: Container.sizing.xxl, className : ""}}>
						<PanelBody className="p-0 m-0">
							<TabNavigator key={this.state.key} onChangeTab={this.handleChangeTab} className="border-light">
								<Tab title={<><IconBasket/> Item</>} className="m-2">
									<Form onSubmit={this.handleSuccess} refParam={f => (this.form = f)}>
										<Row className="g-3 pb-2 px-2">
											{/*
											<FormItem md={3} label="Código">
												<InputText disabled={!editable} name="idFaturaNotaFiscalItem" value={this.state.model.idFaturaNotaFiscalItem} readOnly={true} className="readonly-field" required={false} onChange={this.handleChangeModel}/>
											</FormItem>*/}

											<FormItem md={4} label="Código de Serviço" help="Código de serviço, também conhecido como Código Item Lista Serviço, geralmente segue a LC116, podendo váriar de acordo com a prefeitura. Você pode encontrar esse dado no portal da prefeitura, em uma nota emitida, ou junto ao contador.">
												<InputText disabled={!editable} name="cdItemListaServico" value={this.state.model.cdItemListaServico} maxLength={100} required={true} onChange={this.handleChangeModel}/>
											</FormItem>

											<FormItem md={4} label="Tributação Municipio" help="Código tributação no Município">
												<InputText disabled={!editable} name="cdTributacaoMunicipio" value={this.state.model.cdTributacaoMunicipio} maxLength={100} required={false} onChange={this.handleChangeModel}/>
											</FormItem>

											<FormItem md={4} label="Código CNAE" help="O código CNAE ou, por extenso, Classificação Nacional de Atividades Econômicas, é uma forma de padronizar, em todo o território nacional, os códigos de atividades econômicas e os critérios de enquadramento usados pelos mais diversos órgãos da administração tributária do Brasil.">
												<InputText disabled={!editable} name="cdClassificacaoCnae" value={this.state.model.cdClassificacaoCnae} maxLength={100} required={false} onChange={this.handleChangeModel}/>
											</FormItem>

											<FormItem md={3} label="Base Calculo">
												<InputNumberFormat disabled={!editable} name="vlBaseCalculo" value={this.state.model.vlBaseCalculo} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} prefix="R$ " required={false} onChange={this.handleChangeModel}/>
											</FormItem>

											<FormItem md={3} label="Deduções">
												<InputNumberFormat disabled={!editable} name="vlDeducoes" value={this.state.model.vlDeducoes} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} prefix="R$ " required={false} onChange={this.handleChangeModel}/>
											</FormItem>

											<FormItem md={3} label="Desconto condicionado">
												<InputNumberFormat disabled={!editable} name="vlDescontoCondicionado" value={this.state.model.vlDescontoCondicionado} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} prefix="R$ " required={true} onChange={this.handleChangeModel}/>
											</FormItem>

											<FormItem md={3} label="Desconto Incondicionado">
												<InputNumberFormat disabled={!editable} name="vlDescontoIncondicionado" value={this.state.model.vlDescontoIncondicionado} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} prefix="R$ " required={true} onChange={this.handleChangeModel}/>
											</FormItem>

											<FormItem md={3} label="Valor do Item">
												<InputNumberFormat disabled={!editable} name="vlItem" value={this.state.model.vlItem} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} prefix="R$ " required={true} onChange={this.handleChangeModel}/>
											</FormItem>
											
											<FormItem md={3} label="Líquido">
												<InputNumberFormat disabled={!editable} name="vlLiquido" placeholder="Ex: 99,99" value={this.state.model.vlLiquido} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} prefix="R$ " required={false} onChange={this.handleChangeModel}/>
											</FormItem>

											<FormItem md={3} label="Unitário">
												<InputNumberFormat disabled={!editable} name="vlUnitario" value={this.state.model.vlUnitario} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} prefix="R$ " required={false} onChange={this.handleChangeModel}/>
											</FormItem>

											<FormItem md={12} label="Detalhamento do serviço prestado">
												<TextArea rows={6} disabled={!editable} name="dsDiscriminacao" value={this.state.model.dsDiscriminacao} required={true} onChange={this.handleChangeModel}/>													
											</FormItem>
											
											<FormItem md={1} label="Quantidade">
												<InputNumber disabled={!editable} name="nrQuantidade" value={this.state.model.nrQuantidade} required={true} onChange={this.handleChangeModel}/>
											</FormItem>

											<FormItem md={3} label="Unidade de Medida">
												<SelectUnidadeMedida disabled={!editable} name="unidadeMedida" value={this.state.model.unidadeMedida} required={true} onChange={this.handleChangeModel} placeholder="Selecione Unidade de Medida" buttons={{search : false, new : false, edit: false}}/>
											</FormItem>

											<FormItem md={3} label="Sujeito a Tributação?">
												<BtnRadioGroupSimNao disabled={!editable} name="flTributacao" value={this.state.model.flTributavel} onChange={this.handleChangeModel} required={true}/>
											</FormItem>

											<FormItem md={5} label="Responsável Retenção">
												<BtnRadioGroup name="cdResponsavelRetencao" color={BtnRadioGroup.color.primary} opcional={true} value={this.state.model.cdResponsavelRetencao} onClick={this.handleChangeModel} disabled={!editable}>
													<BtnRadio value="PRESTADOR" label="Prestador" />
													<BtnRadio value="TOMADOR" label="Tomador" />
													<BtnRadio value="INTERMEDIARIO" label="Intermediário" />
												</BtnRadioGroup>
											</FormItem>

											<BtnSubmit hidden > Enviar </BtnSubmit>
										</Row>
									</Form>
								</Tab>								
								<Tab show={Permissao.getInstance().getPermissoes([142])} title={<><IconPercent/> ISS</>} disabled={!this.state.model.idFaturaNotaFiscalItem} opened={false} className="m-2">
									<FormFaturaNotaFiscalItemIss editable={editable} localOpen={BaseCrud.localOpen.embedded} parent={this.state.model}/>
								</Tab>
								<Tab show={Permissao.getInstance().getPermissoes([142])} title={<><IconBagDash/> Retenção</>} disabled={!this.state.model.idFaturaNotaFiscalItem} opened={false} className="m-2">
									<ListFaturaNotaFiscalItemRetencao editable={editable} parent={this.state.model} localOpen={BaseList.localOpen.modal}/>
								</Tab>
								<Tab show={Permissao.getInstance().getPermissoes([142])} title={<><IconConeStriped/> Obra</>} disabled={!this.state.model.idFaturaNotaFiscalItem} opened={false} className="m-2">
									<FormFaturaNotaFiscalItemObra editable={editable} localOpen={BaseCrud.localOpen.embedded} parent={this.state.model}/>
								</Tab>								
							</TabNavigator>
						</PanelBody>
						<PanelFooter>
							<FormButtons show={this.state.tabIndex === 0} onSubmit={this.handleSubmitForm} disabled={[!editable]}/>
						</PanelFooter>
					</Panel>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <FormFaturaNotaFiscalItem {...props} navigate={navigate} params={params} location={location}/>
}
export default With