import Http from "../controllers/Http";

export default class VeiculoCombustivelService {

	static urlBase = "/veiculoCombustivel/";

	static getForCombo(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(VeiculoCombustivelService.urlBase + 'forCombo', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static list(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(VeiculoCombustivelService.urlBase + 'list', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneById(id, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.get(VeiculoCombustivelService.urlBase + id)
			.then(fnSucess)
			.catch(fnError);
	}

	static create(data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(VeiculoCombustivelService.urlBase, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static update(id, data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.put(VeiculoCombustivelService.urlBase + id, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static delete(id, fnSucess = () => { }, fnError = () => { }) {
		if(Number.isInteger(id)){
			Http.getInstanceAppJson()
				.delete(VeiculoCombustivelService.urlBase + id)
				.then(fnSucess)
				.catch(fnError);
		}else{
			Http.getInstanceAppJson()
			.post(VeiculoCombustivelService.urlBase + 'delete', id)
			.then(fnSucess)
			.catch(fnError);
		}
	}

	static save(data, fnSucess = () => { }, fnError = () => { }) {
		if (data.idVeiculoCombustivel) {
			this.update(data.idVeiculoCombustivel, data, fnSucess, fnError);
		} else {
			this.create(data, fnSucess, fnError);
		}
	}

	static getAllByVeiculo(veiculo, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(VeiculoCombustivelService.urlBase + 'veiculo', veiculo)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByVeiculo(veiculo, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(VeiculoCombustivelService.urlBase + 'veiculo/' + veiculo.idVeiculo)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByCombustivel(combustivel, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(VeiculoCombustivelService.urlBase + 'combustivel', combustivel)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByCombustivel(combustivel, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(VeiculoCombustivelService.urlBase + 'combustivel/' + combustivel.idCombustivel)
			.then(fnSucess)
			.catch(fnError);
	}
}