import Http from "../controllers/Http";

export default class PessoaInscricaoEstadualService {

	static urlBase = "/pessoaInscricaoEstadual/";

	static getForCombo(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(PessoaInscricaoEstadualService.urlBase + 'forCombo', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static list(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(PessoaInscricaoEstadualService.urlBase + 'list', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneById(id, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.get(PessoaInscricaoEstadualService.urlBase + id)
			.then(fnSucess)
			.catch(fnError);
	}

	static create(data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(PessoaInscricaoEstadualService.urlBase, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static update(id, data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.put(PessoaInscricaoEstadualService.urlBase + id, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static delete(id, fnSucess = () => { }, fnError = () => { }) {
		if(Number.isInteger(id)){
			Http.getInstanceAppJson()
				.delete(PessoaInscricaoEstadualService.urlBase + id)
				.then(fnSucess)
				.catch(fnError);
		}else{
			Http.getInstanceAppJson()
			.post(PessoaInscricaoEstadualService.urlBase + 'delete', id)
			.then(fnSucess)
			.catch(fnError);
		}
	}

	static save(data, fnSucess = () => { }, fnError = () => { }) {
		if (data.idPessoaInscricaoEstadual) {
			this.update(data.idPessoaInscricaoEstadual, data, fnSucess, fnError);
		} else {
			this.create(data, fnSucess, fnError);
		}
	}

	static getAllByPessoa(pessoa, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(PessoaInscricaoEstadualService.urlBase + 'pessoa', pessoa)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByPessoa(pessoa, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(PessoaInscricaoEstadualService.urlBase + 'pessoa/' + pessoa.idPessoa)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByEstado(estado, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(PessoaInscricaoEstadualService.urlBase + 'estado', estado)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByEstado(estado, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(PessoaInscricaoEstadualService.urlBase + 'estado/' + estado.idEstado)
			.then(fnSucess)
			.catch(fnError);
	}
}