import React from "react";
import BaseComponent from "./BaseComponent";

export default class BaseCrud extends BaseComponent {

    static localOpen = {
        modal : "MODAL",
        embedded : "EMBEDDED",
        newPage : "NEW_PAGE",
        default : "NEW_PAGE"
    }; 

    constructor(props) {
        super(props);

        this.handleChangeTab = this.handleChangeTab.bind(this);
        this.setDefaultValues = this.setDefaultValues.bind(this);

        this.refForm = React.createRef();
        this.state.model = {};
        this.state.key = Math.random();
        //this.state.tabIndex = this.props?.location?.state?.tabIndex !== undefined ? this.props?.location?.state?.tabIndex : 0;
        this.state.tabIndex = 0;
        this.localOpen = props.localOpen !== undefined ? props.localOpen : BaseCrud.localOpen.default
    }

    /*componentDidUpdate(nextProps) {
        super.componentDidUpdate(nextProps);
        if (parseInt(nextProps.id) !== parseInt(this.props.id)) {
            this.init();
        }
    }*/

    componentDidMount() {
        this.setState(state => {
            if(this.initModel instanceof Function){
                state.model = this.initModel();
            }
			return state;
		}, () =>{
            this.init();
        });
    }

    componentWillUnmount() {

    }

    init(Config, fnCallBack = () =>{}, refreshKey = true) {

        let fnInit = () => {
            if (this.getId()) {
                Config.getService().getOneById(this.getId(), (response) => {
                    if(this.props.copy || this.props?.location?.state?.copy){
                        response[Config.getKeyId()] = "";
                    }
                    this.setState({ model: this.middleResponse(response), key : refreshKey ? Math.random() : this.state.key }, fnCallBack);
                }, () =>{
                    this.setState({ key : refreshKey ? Math.random() : this.state.key }, fnCallBack);
                });
            }else{
                this.setState({ key : refreshKey ? Math.random() : this.state.key }, fnCallBack);
            }
        }

        this.setDefaultValues(Config, fnInit);
    }

    setDefaultValues(Config, fnInit = () => {}){
        if(Config.setDefaultValues !== undefined && Config.setDefaultValues instanceof Function){
            var _state = this.state;
            Config.setDefaultValues(_state, () =>{
                this.setState(state => {
                    state = _state;
                    return state;
                }, fnInit);
            });
            
        }else{
            fnInit();
        }
    }

    getId() {
        if (this.props.id) {
            return this.props.id;
        } else if (this.localOpen === BaseCrud.localOpen.newPage && this.props.params?.id) {
            return this.props.params?.id;
        }
        return false;
    }

    handleClose(e, Config) {
        var url = Config && Config.getUrlList ? Config.getUrlList() : null;

        if (this.props.handleClose !== undefined) {
            this.props.handleClose(e, url);
        }

        if (this.localOpen === BaseCrud.localOpen.newPage && url) {
            this.props.navigate(url);
        }
    }

    handleSuccess(e, Config) {
        Config.getService().save(this.state.model, (response) => {
			var url = !this.getId() ? Config.getUrlForm() + "/" + response.data[Config.getKeyId()] : Config.getUrlList();
			this.setState({model : response.data, key : Math.random()}, () => {
                if (this.props.handleSuccess !== undefined) {
                    this.props.handleSuccess(e, response, url);
                }
        
                if (this.localOpen === BaseCrud.localOpen.newPage && url) {
                    this.props.navigate(url);
                    this.init(Config);
                    return;
                }

                if(this.localOpen === BaseCrud.localOpen.embedded){
                    this.init(Config);
                }

                this.setState({key : Math.random()}, () => {                    
                    this.init(Config);
                })
			});
		});
    }

    handleSubmitForm(){
        this.form?.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }));
    }

    handleChangeTab(index, prop = "tabIndex"){
        this.setState({[prop] : parseInt(index)});
    }

    middleResponse(response){
        for (const [key, value] of Object.entries(response)) {
            if(key.substring(0,2) === "dt" && response[key]?.date !== undefined){
                response[key] = value.date.toString()?.substring(0, 10);
            }
          }
        return response;
    }

    isModal(){
        return this.props.localOpen === BaseCrud.localOpen.modal;
    }

    render(render){
        return render;
    }
}