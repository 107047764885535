import Http from "../controllers/Http";

export default class CidadeService {

	static urlBase = "/cidade/";

	static getForCombo(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(CidadeService.urlBase + 'forCombo', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static list(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(CidadeService.urlBase + 'list', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneById(id, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.get(CidadeService.urlBase + id)
			.then(fnSucess)
			.catch(fnError);
	}

	static create(data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(CidadeService.urlBase, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static update(id, data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.put(CidadeService.urlBase + id, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static delete(id, fnSucess = () => { }, fnError = () => { }) {
		if(Number.isInteger(id)){
			Http.getInstanceAppJson()
				.delete(CidadeService.urlBase + id)
				.then(fnSucess)
				.catch(fnError);
		}else{
			Http.getInstanceAppJson()
			.post(CidadeService.urlBase + 'delete', id)
			.then(fnSucess)
			.catch(fnError);
		}
	}

	static save(data, fnSucess = () => { }, fnError = () => { }) {
		if (data.idCidade) {
			this.update(data.idCidade, data, fnSucess, fnError);
		} else {
			this.create(data, fnSucess, fnError);
		}
	}

	static getAllByLocalizacao(localizacao, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(CidadeService.urlBase + 'localizacao', localizacao)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByLocalizacao(localizacao, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(CidadeService.urlBase + 'localizacao/' + localizacao.idLocalizacao)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByEstado(estado, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(CidadeService.urlBase + 'estado', estado)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByEstado(estado, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(CidadeService.urlBase + 'estado/' + estado.idEstado)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByFusoHorario(fusoHorario, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(CidadeService.urlBase + 'fusoHorario', fusoHorario)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByFusoHorario(fusoHorario, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(CidadeService.urlBase + 'fusoHorario/' + fusoHorario.idFusoHorario)
			.then(fnSucess)
			.catch(fnError);
	}
}