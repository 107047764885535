import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { BaseCrud } from "../../siht/base";
import { FormButtons, FormItem, Row, Panel, PanelBody, PanelFooter, Container, ModalDialog, Col, DataGrid, DataGridColumn, Badge } from "../../siht/components";
import { Div, Form, InputValorReais, Span, TextArea } from "../../siht/elements";
import Config from "./Config";
import BtnRadioGroupSimNao from "../../components/BtnRadioGroupSimNao";
import Util from "../../utils/Util";
import { BadgeSimNao, StatusCustom } from "../../components/Custom";
import FaturaAcordoService from "../../services/FaturaAcordoService";

class FormAcordo extends BaseCrud {

	constructor(props) {
		super(props);
		this.init = this.init.bind(this);
		this.handleSuccess = this.handleSuccess.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleChangeModel = this.handleChangeModel.bind(this);
		this.handleSubmitForm = this.handleSubmitForm.bind(this);
        this.setValores = this.setValores.bind(this);

        let faturaCobranca = [];
        let unidade = {};
        let pessoa = {};

        if(this.props.faturaCobranca !== undefined){
			faturaCobranca = this.props.faturaCobranca;
		}else if(this.props?.location?.state?.faturaCobranca !== undefined){
			faturaCobranca = this.props?.location?.state?.faturaCobranca;
		}

        if(this.props.unidade !== undefined){
			unidade = this.props.unidade;
		}else if(this.props?.location?.state?.unidade !== undefined){
			unidade = this.props?.location?.state?.unidade;
		}

        if(this.props.pessoa !== undefined){
			pessoa = this.props.pessoa;
		}else if(this.props?.location?.state?.pessoa !== undefined){
			pessoa = this.props?.location?.state?.pessoa;
		}

		this.state.model = {
            faturaCobranca : faturaCobranca,
            unidade : unidade,
            pessoa : pessoa,
            flEncargos : true,
            vlCobranca : 0,
            vlBaixa : 0,
            vlJurosHoje : 0,
            vlMultaHoje : 0,
            vlCobrado : 0,
            vlAberto : 0,
            vlAcordo : 0,

            vlDesconto: 0,
            vlAcrescimo: 0,
            txObservacao : ""
        }
		
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		super.init(Config, () =>{
            this.setValores();
        });
	}

    setValores(){
        this.setState(state =>{
            state.model.vlCobranca = state.model.faturaCobranca.reduce((soma, item) => soma+= item.vlCobranca, 0);
            state.model.vlBaixa = state.model.faturaCobranca.reduce((soma, item) => soma+= item.vlBaixa, 0);
            state.model.vlAberto = state.model.faturaCobranca.reduce((soma, item) => soma+= item.vlAberto, 0);            
            state.model.vlJurosHoje = state.model.faturaCobranca.reduce((soma, item) => soma+= item.vlJurosHoje, 0);
            state.model.vlMultaHoje = state.model.faturaCobranca.reduce((soma, item) => soma+= item.vlMultaHoje, 0);
            state.model.vlCobrado = state.model.faturaCobranca.reduce((soma, item) => soma+= item.vlCobrado, 0);
            
            if(state.model.flEncargos){
                state.model.vlAcordo = state.model.faturaCobranca.reduce((soma, item) => soma+= item.vlCobrado, 0);  
            }else{
                state.model.vlAcordo = state.model.faturaCobranca.reduce((soma, item) => soma+= item.vlAberto, 0);  
            }

            state.model.vlAcordo-=state.model.vlDesconto;
            state.model.vlAcordo+=state.model.vlAcrescimo;

            return state;
        });
    }

	handleSuccess(e) {
        FaturaAcordoService.save(this.state.model, (response) =>{
            let faturaAcordo = response.data;
            //this.props.navigate('/CadFaturar', { state : { ordens : [], pessoa : faturaAcordo.pessoa, unidade : faturaAcordo.unidade, faturaAcordo : faturaAcordo }});
            this.props.navigate('/CadFaturaAcordo/' + faturaAcordo.idFaturaAcordo);
        });
		
	}

	handleClose(e) {
		super.handleClose(e, Config);
	}

	render() {
		return super.render(
				<Div key={this.state.key}>
					<Form onSubmit={this.handleSuccess} refParam={f => (this.form = f)}>
						<Panel title="Acordo" onClose={this.handleClose} isModal={this.isModal()} container={{sizing: Container.sizing.default, className : ""}} modal={{sizing: this.props.sizing ? this.props.sizing : ModalDialog.sizing.xl}}>
							<PanelBody>

                                <DataGrid bordered={true} caption={false} striped={true} data={this.state.model.faturaCobranca} search={false} isDisabled={i => false} sizing={DataGrid.sizing.sm} select={false}>
                                    
                                    <DataGridColumn col={1} label="Fatura" field={i => i.fatura.idFatura} className="text-center"/>
                                    <DataGridColumn col={1} label="Cobrança" field={i => i.idFaturaCobranca} className="text-center"/>
                                    <DataGridColumn col={1} label="Parcela" field={i => i.nrParcela} className="text-center"/>
                                    <DataGridColumn col={1} label="Status" field={i => <StatusCustom className="w-100" color={i.statusFaturaCobranca}> {i.statusFaturaCobranca?.nmStatusFaturaCobranca} </StatusCustom>} className="text-center"/>
                                    
                                    <DataGridColumn col={1} label="Encargos" field={i => (<React.Fragment>
                                        <Div className="fs-7">Juros: {i.flJuros ? <Span className="fw-bold"> {Util.floatToValor(i.vlJurosHoje, 2)} % </Span> : <BadgeSimNao value={i.flJuros}/>} </Div>
                                        <Div className="fs-7">Multa: {i.flMulta ? <Span className="fw-bold"> {Util.floatToValor(i.vlMultaHoje, 2)} % </Span> : <BadgeSimNao value={i.flMulta}/>} </Div>
                                    </React.Fragment>)} className="text-end"/>
                                    
                                    <DataGridColumn col={1} label="Cobrança" field={i => <React.Fragment>
                                        <Span className="fw-bold text-primary"> {Util.floatToReais(i.vlCobranca)}  </Span>
                                    </React.Fragment>} className="text-end"/>

                                    <DataGridColumn col={1} label="Aberto" field={i => <React.Fragment>
                                        <Div className={`fw-bold ${i.vlAberto > 0 ? "text-danger" : "text-secondary"}`}> {Util.floatToReais(i.vlAberto)}  </Div>
                                        
                                        {i.flVencido && (i.flJuros || i.flMulta) ?
                                            <React.Fragment>
                                                <Div className="fs-7">Juros: {i.flJuros ? <Span className={`fw-bold ${i.flJuros === 0 ? "text-secondary" : "text-danger"}`}> {Util.floatToReais(i.vlJurosHoje, 2)} </Span> : <></>} </Div>
                                                <Div className="fs-7">Multa: {i.flMulta ? <Span className={`fw-bold ${i.flMulta === 0 ? "text-secondary" : "text-danger"}`}> {Util.floatToReais(i.vlMultaHoje, 2)} </Span> : <></>} </Div>
                                            </React.Fragment> : <></>
                                        }
                                    </React.Fragment>} className="text-end"/>

                                    <DataGridColumn col={2} label="Baixas" field={i => <React.Fragment>
                                        <Div className={`fw-bold ${i.vlBaixa === 0 ? "text-secondary" : "text-success"}`}> {Util.floatToReais(i.vlBaixa)}  </Div>
                                        <Div className={`fs-7`}> Pagamentos: <Span className={`fw-bold ${i.vlPago === 0 ? "text-secondary" : "text-success"}`}>{Util.floatToReais(i.vlPago)}</Span>  </Div>
                                        <Div className={`fs-7`}> Descontos: <Span className={`fw-bold ${i.vlDesconto === 0 ? "text-secondary" : "text-danger"}`}>{Util.floatToReais(i.vlDesconto)}</Span>  </Div>

                                        {i.vlBaixaEncargo > 0 ?
                                            <Div className={`fs-7`}> Encargos: <Span className={`fw-bold ${i.vlBaixaEncargo === 0 ? "text-secondary" : "text-danger"}`}>{Util.floatToReais(i.vlBaixaEncargo)}</Span>  </Div>
                                        : <></>}
                                    </React.Fragment>} className="text-end"/>

                                    <DataGridColumn col={1} label="Taxas" field={i => <React.Fragment>
                                        <Span className={`fw-bold ${i.vlBaixaTaxa === 0 ? "text-secondary" : "text-success"}`}> {Util.floatToReais(i.vlBaixaTaxa)}  </Span>
                                    </React.Fragment>} className="text-end"/>

                                    <DataGridColumn col={1} label="Baixas" field={i => <React.Fragment>
                                        <Span className={`fw-bold ${i.vlBaixaFinal === 0 ? "text-secondary" : "text-success"}`}> {Util.floatToReais(i.vlBaixaFinal)}  </Span>
                                    </React.Fragment>} className="text-end"/>

                                    <DataGridColumn col={1} label="Vencimento" field={i => <>
                                        <Div> {Util.date2Br(i.dtVencimento)} </Div>
                                        {i.flVencido && !i.statusFaturaCobranca.flPaga ? <Badge color={Badge.color.danger}>Vencido</Badge> : <></>}

                                    </>} className="text-center"/>

                                </DataGrid>

                                <Row className="g-3 pb-3 px-2">
                                    <FormItem md={2} label="Valor Cobrança">
                                        <InputValorReais readOnly={true} className="text-end text-primary fw-bold" required value={this.state.model.vlCobranca} />
									</FormItem>
                                    <FormItem md={2} label="Valor Pago">
                                        <InputValorReais readOnly={true} className="text-end text-success fw-bold" required value={this.state.model.vlBaixa} />
									</FormItem>
									<FormItem md={2} label="Valor Aberto">
                                        <InputValorReais readOnly={true} className="text-end text-danger fw-bold" required value={this.state.model.vlAberto} />
									</FormItem>
									<FormItem md={2} label="Juros">
                                        <InputValorReais readOnly={true} className="text-end text-danger fw-bold" required value={this.state.model.vlJurosHoje} />
									</FormItem>
                                    <FormItem md={2} label="Multa">
                                        <InputValorReais readOnly={true} className="text-end text-danger fw-bold" required value={this.state.model.vlMultaHoje} />
									</FormItem>
                                    <FormItem md={2} label="Valor Cobrado">
                                        <InputValorReais readOnly={true} className="text-end text-danger fw-bold" required value={this.state.model.vlCobrado} />
									</FormItem>
								</Row>
								<Row className="g-3 pb-2 px-2 align-items-center">

                                    <FormItem md={4} label="Cobrar Encargos">
                                        <BtnRadioGroupSimNao name="flEncargos" value={this.state.model.flEncargos} required={true} onChange={(e,v,p) => this.handleChangeModel(e,v,p, this.setValores)}/>
                                    </FormItem>
                                    
                                    <Col sizing={2}/>

                                    <FormItem md={2} label="Valor Desconto">
                                        <InputValorReais className="text-end text-danger" required value={this.state.model.vlDesconto} name="vlDesconto" onChange={(e,v,p) => this.handleChangeModel(e,v,p, this.setValores)} />
                                    </FormItem>

                                    <FormItem md={2} label="Valor Acréscimo">
                                        <InputValorReais className="text-end text-primary" required value={this.state.model.vlAcrescimo} name="vlAcrescimo" onChange={(e,v,p) => this.handleChangeModel(e,v,p, this.setValores)} />
                                    </FormItem>

                                    <FormItem md={2} label="Valor Acordo">
                                        <InputValorReais readOnly={true} className="text-end text-success fw-bold bg-dark" required value={this.state.model.vlAcordo} />
									</FormItem>

                                    <FormItem md={12} label="Observação">
                                        <TextArea style={{resize : "none"}} name="txObservacao" value={this.state.model.txObservacao} required={false} onChange={this.handleChangeModel}/>
                                    </FormItem>
                                </Row>

							</PanelBody>
							<PanelFooter>
								<FormButtons label={["Gerar Acordo"]} onClose={this.handleClose} />
							</PanelFooter>
						</Panel>
					</Form>
				</Div>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <FormAcordo {...props} navigate={navigate} params={params} location={location}/>
}
export default With