
import FormStatusFatura from "./FormStatusFatura.js";
import ListStatusFatura from "./ListStatusFatura.js";
import { Permissao } from "../../controllers";
import { Util } from "../../utils";
import { DadoValorService, StatusFaturaService } from "../../services";

export default class Config {

	static getUrlForm() {
		return "/CadStatusFatura";
	}

	static getUrlList() {
		return "/LstStatusFatura";
	}

	static getComponentForm() {
		return FormStatusFatura;
	}

	static getPermissionForm() {
		return Permissao.getInstance().getPermissoes([83]);
	}

	static getPermissionList() {
		return Permissao.getInstance().getPermissoes([83]);
	}

	static getComponentList() {
		return ListStatusFatura;
	}

	static getService() {
		return StatusFaturaService;
	}

	static getKeyId() {
		return "idStatusFatura";
	}

	static getKeyDescription() {
		return "nmStatusFatura";
	}

	static setDefaultValues(state , fnCallBack = () => {}, className = undefined) {
		className = className === undefined ? "FormStatusFatura" : className;
		let keyNameScreen = Util.toToUpperTextCamelCase(className, "_") + ".";
		var prefix = "CONFIGURACAO.";
		DadoValorService.getValues(`${prefix}${keyNameScreen}`, (response)=> {
			Util.setValueObjectCamelCase(state.model, response, keyNameScreen, prefix);
			fnCallBack();
		}, fnCallBack);
	}
}