import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class BtnLink extends Component {

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    render() {
        if(this.props.show !== undefined && !Boolean(this.props.show)){
            return (<></>);
        }
        
        const props = Object.assign({}, this.props);
        props.className = `btn btn-link ${props.className !== undefined ? props.className : ""} ${this.props.sizing ? "btn-" + this.props.sizing : ""}`;
        
        return (
            <Link {...props}>
                {this.props.children}
            </Link>
        );

    }
}