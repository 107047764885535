
import React, { Component } from "react";
import AppRoutes from "./routes/AppRoutes";
import { ToastContainer } from "react-toastify";
import Auth from "./controllers/Auth";
import LoginService from "./services/LoginService";
import LoadingContainer from "./container/LoadingContainer";
import ToastBusContainer from "./container/ToastBusContainer";
import { BrowserRouter } from "react-router-dom";
import ModalSessaoExpirada from "./components/modal/ModalSessaoExpirada";
import ModalSessaoExpiradaLogin from "./components/modal/ModalSessaoExpiradaLogin";
import ModalContainer from "./siht/container/ModalContainer";
import ConfigApp from "./ConfigApp";
import Util from "./utils/Util";
import DadoValorService from "./services/DadoValorService";
import BackgroundContainer from "./container/BackgroundContainer";

export default class App extends Component {

  constructor(props) {
    super(props)

    this.state = {
      token: false,
      config: false
    };

    this.validateToken = this.validateToken.bind(this);
    this.validateLogged = this.validateLogged.bind(this);
    this.refreshToken = this.refreshToken.bind(this);
    this.getConfiguracoes = this.getConfiguracoes.bind(this);
  }

  componentDidMount() {
    this.validateToken();
    this.validateLogged();
    this.refreshToken();
    this.getConfiguracoes();
  }

  getConfiguracoes() {
    let cdConfiguracao = ["CONFIGURACAO.GOOGLE_CLIENT_ID_GOOGLE_MAPS", "CONFIGURACAO.GOOGLE_CLIENT_ID_GOOGLE_AUTH", "CONFIGURACAO.LOGO_SISTEMA"];
    DadoValorService.getPublicValues(cdConfiguracao, (response) => {
      ConfigApp.getInstance().clientIdGoogleAuth = response["CONFIGURACAO.GOOGLE_CLIENT_ID_GOOGLE_AUTH"];
      ConfigApp.getInstance().clientIdGoogleMaps = response["CONFIGURACAO.GOOGLE_CLIENT_ID_GOOGLE_MAPS"];
      ConfigApp.getInstance().logoSistema = response["CONFIGURACAO.LOGO_SISTEMA"];
      this.setState({ config: true });
    }, () => {
      this.setState({ config: true });
    });
  }

  refreshToken() {
    setInterval(function () {
      if (Util.isActive()) {
        LoginService.refreshToken();
      }
    }, (ConfigApp.timeRefreshToken * 1000));
  }

  validateLogged() {
    setInterval(function () {
      LoginService.validateLogged();
    }, (ConfigApp.timeValidateLogged * 1000));
  }

  validateToken() {
    if (Auth.getToken()) {
      LoginService.validateToken(() => {
        this.setState({ token: true });
      }, () => {
        this.setState({ token: true });
      });
    } else {
      this.setState({ token: true });
    }
  }

  render() {

    return (
      <BrowserRouter basename="/">
        <ModalContainer />
        <ModalSessaoExpirada />
        <ModalSessaoExpiradaLogin />
        <LoadingContainer />
        <ToastBusContainer />
        <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        {this.state.token && this.state.config ? <AppRoutes /> : <BackgroundContainer />}
      </BrowserRouter>
    );
  }
}


