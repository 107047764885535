import Config from "./Config";
import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { BaseCrud } from "../../siht/base";
import { FormButtons, FormItem, Row, Panel, PanelBody, PanelFooter, Container, ModalDialog } from "../../siht/components";
import { InputText, InputNumber, Form } from "../../siht/elements";

class FormAnsPerfilGolpe extends BaseCrud {

	constructor(props) {
		super(props);
		this.init = this.init.bind(this);
		this.initModel = this.initModel.bind(this);
		this.handleSuccess = this.handleSuccess.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleChangeModel = this.handleChangeModel.bind(this);
		this.handleSubmitForm = this.handleSubmitForm.bind(this);
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		super.init(Config);
	}

	initModel(){
		return {
			idAnsPerfilGolpe : null,
			ansPerfil : this.props.parent !== undefined ? this.props.parent : {},
			nrGolpe1 : 0,
			nrGolpe2 : 0,
			nrGolpe3 : 0,
			nrProfundidade1 : 0,
			nrProfundidade2 : 0,
			nrProfundidade3 : 0,
			nrResistencia1 : null,
			nrResistencia2 : null,
			nrOrdem : null
		}
	}

	handleSuccess(e) {
		super.handleSuccess(e, Config);
	}

	handleClose(e) {
		super.handleClose(e, Config);
	}

	render() {
		return super.render(

					<Form onSubmit={this.handleSuccess} refParam={f => (this.form = f)} key={this.state.key}>
						<Panel key={this.state.key} title="Cadastro de Golpe" localOpen={this.props.localOpen}  onClose={this.handleClose} isModal={this.isModal()} container={{sizing: Container.sizing.xxl, className : ""}} modal={{sizing: this.props.sizing ? this.props.sizing : ModalDialog.sizing.xl}}>
							<PanelBody>
								<Row className="g-3 pb-2 px-2">
									<FormItem md={6} label="Código">
										<InputText name="idAnsPerfilGolpe" value={this.state.model.idAnsPerfilGolpe} readOnly={true} className="readonly-field" required={false} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={6} label="Golpe 1">
										<InputNumber name="nrGolpe1" value={this.state.model.nrGolpe1} required={false} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={6} label="Golpe 2">
										<InputNumber name="nrGolpe2" value={this.state.model.nrGolpe2} required={false} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={6} label="Golpe 3">
										<InputNumber name="nrGolpe3" value={this.state.model.nrGolpe3} required={false} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={6} label="Profundidade 1">
										<InputNumber name="nrProfundidade1" value={this.state.model.nrProfundidade1} required={false} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={6} label="Profundidade 2">
										<InputNumber name="nrProfundidade2" value={this.state.model.nrProfundidade2} required={false} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={6} label="Profundidade 3">
										<InputNumber name="nrProfundidade3" value={this.state.model.nrProfundidade3} required={false} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={6} label="NrResistencia1">
										<InputNumber name="nrResistencia1" value={this.state.model.nrResistencia1} required={false} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={6} label="NrResistencia2">
										<InputNumber name="nrResistencia2" value={this.state.model.nrResistencia2} required={false} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={6} label="NrOrdem">
										<InputNumber name="nrOrdem" value={this.state.model.nrOrdem} required={false} onChange={this.handleChangeModel}/>
									</FormItem>
								</Row>
							</PanelBody>
							<PanelFooter>
								<FormButtons onClose={this.handleClose} />
							</PanelFooter>
						</Panel>
					</Form>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <FormAnsPerfilGolpe {...props} navigate={navigate} params={params} location={location}/>
}
export default With