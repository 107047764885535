import React from "react";
import Field from "./Field";
import { Functions } from "../util";
import Util from "../../utils/Util";
import Option from "./Option";

export default class SelectMultipleDropDown extends Field {

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeBusca = this.handleChangeBusca.bind(this);
        this.handleChangeAll = this.handleChangeAll.bind(this);
        this.handleClickDrop = this.handleClickDrop.bind(this);
        this.getItens = this.getItens.bind(this);
        this.getOptions = this.getOptions.bind(this);
        
        this.state.options = this.props.options || [];
        this.state.busca = "";
        this.refBusca = React.createRef();

        if(this.props.children){
            this.state.options = React.Children.toArray(this.props.children).filter(child => child.type === Option);
            this.state.options = this.state.options.map(o => o.props);
        }
    }

    componentDidUpdate(nextProps) {
        super.componentDidUpdate(nextProps);
        if (nextProps.filter !== undefined && this.props.filter !== undefined) {
            if (!this.objEqual(nextProps.filter, this.props.filter)) {
                this.getOptions();
            }
        }
    }

    componentDidMount() {
        super.componentDidMount();
        this.getOptions();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    handleClickDrop(event) {
        if(this.props.search !== undefined){
            this.refBusca.current.focus();
        }        
        this.setState({busca : ""});
    }

    handleChangeAll(e) {

        let values = Array.isArray(this.props.value) ? this.props.value : [];
        
        this.getItens(false).forEach(item => {
            let index = values.findIndex(v => this.getOptionValue(item)?.toString() === this.getOptionValue(v)?.toString());

            if(e.target.checked && index === -1){
                values.push({...item, icon : undefined});
            }else if(!e.target.checked && index !== -1){
                values.splice(index, 1);
            }
        });

        super.handleChange(e, values, this.props.name);
    }

    handleChangeBusca(event) {
        this.setState({busca : event.target.value});
    }

    handleChange(e, item, name) {

        let values = Array.isArray(this.props.value) ? this.props.value : [];

        if(!name){
            name = e?.target?.name;
        }

        let index = values.findIndex(v => this.getOptionValue(item)?.toString() === this.getOptionValue(v)?.toString());

        if(index === -1){            
            values.push({...item, icon : undefined});
        }else{
            values.splice(index, 1);
        }

        super.handleChange(e, values, name);
    }

    isDisabled(item) {
        if(item.disabled !== undefined){
            return item.disabled;
        }
        return false;
    }

    getForComboService() {
        return null;
    }

    getDefault() {
        return null;
    }

    getOptions(callBack) {
        var filter = this.props.filter !== undefined ? this.props.filter : {};
        callBack = callBack !== undefined && Functions.isFunction(callBack) ? callBack : ()=>{};

        var getForComboService = this.getForComboService();

        if (this.getOptionsWhen(filter) && getForComboService !== null) {
            getForComboService((filter), (response) => {
                this.setState({ options: response }, () =>{                
                    if(!this.getOptionValue(this.props.value)){
                        let item = this.getDefault();
                        if(item){
                            this.handleChange({}, item, this.props.name);
                        }
                    }
                    callBack();
                });               
            });
        }else if(!this.props.options && !this.props.children){
            this.setState({ options: [] }, callBack);
        }
    }

    getOptionValue(item) {
        return item?.value;
    }

    getOptionLabel(item) {
        if(item?.label !== undefined){
            return item?.label;
        }else{
            return this.state.options.find(i => i.value.toString() === item.value.toString())?.label;
        }
    }

    //TODO: IMPLEMENTAR
    getOptionClass(item) {
        return "";
    }

    getOptionsWhen(item) {
        var filter = this.props.filter !== undefined ? this.props.filter : {};
        return this.props.getOptionsWhen === undefined || this.props.getOptionsWhen(filter);
    }

    getItens(isDisabled = true){
        var itens = this.state.options;

        if(this.state.busca.toString().length > 0){
            itens = this.state.options.filter(f =>
                Util.searchTextInObject(f, this.getConfigSearch().map(element => element.key) , this.state.busca)
            );

            if(this.props.disabledValues && !isDisabled){
                itens = itens.filter(f => !this.isDisabled(f));
            }
        }

        return itens;
    }

    render(feedback = true) {
        const props = Object.assign({}, this.props);
        delete props.show;
        delete props.search;
        delete props.rowCols;
        delete props.checkAll;

        props.value = Array.isArray(props.value) ? props.value : [];
        props.ref = this.props.refParam ? this.props.refParam : this.ref;
        var idAll = `${this.props.name}000` + Math.random().toString();

        var className = `btn btn-menu-dropdown-checkbox dropdown-toggle w-100 text-start ${this.props.className ? this.props.className : ""} ${this.props.sizing ? "btn-" + this.props.sizing : ""}`;

        var element =
            <div className="dropdown">
                
                <button type="button" className={className} data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside" onClick={this.handleClickDrop} disabled={this.state.options.length === 0}>

                    {this.props.placeholder && props.value?.length === 0 ? <>{this.props.placeholder}</> : <></>}

                    {props.value.map((item, index) => (
                        <React.Fragment key={index}>
                            {item.icon && <span className="me-2">{item.icon}</span>}
                            {this.getOptionLabel(item)}{props.value.length - 1 === index ? "" : ", "}
                        </React.Fragment>
                    ))}
                </button>

                <div className="dropdown-menu p-3 w-100">

                    {this.props.search !== undefined && this.props.search ?
                        <input className="form-control form-control-sm mb-1" type="text" sizing="sm" onChange={this.handleChangeBusca} value={this.state.busca} ref={this.refBusca}/>
                    : <></>}

                    {this.props.checkAll !== undefined && this.state.options.length > 0 ? 
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox"
                                checked={props.value?.length === this.getItens(false)?.length}
                                id={idAll}
                                onChange={e => this.handleChangeAll(e)}
                            />
                            <label htmlFor={idAll} className="form-check-label fw-bold user-select-none">Selecionar Todos</label>
                        </div>
                    : <></>}
                    
                    <div className={`row row-cols-${this.props.rowCols !== undefined ? this.props.rowCols : 1} scrollable-menu`}>
                        {this.getItens().map((option, index) => {
                            let idItem = `${this.props.name}${index}${Math.random().toString()}`;
                            return (
                                <div className="col" key={index}>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" id={idItem} value={option.value}
                                            disabled={this.isDisabled(option)}
                                            checked={props.value.findIndex(v => this.getOptionValue(option)?.toString() === this.getOptionValue(v)?.toString()) !== -1}
                                            onChange={e => this.handleChange(e, option, this.props.name)}
                                        />
                                        {option.icon && <span className="me-2">{option.icon}</span>}
                                        <label className="form-check-label user-select-none" htmlFor={idItem}>{this.getOptionLabel(option)}</label>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>;

        return super.render(element, feedback);
    }
}