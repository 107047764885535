//#NO-CHANGE-FILE#

import React from "react";
import { useNavigate, useLocation, useParams } from "react-router";
import { BtnCopy, BtnDelete, BtnEdit, BtnNew } from "../../components/Custom";
import Config from "./Config";
import ConfigAnsPerfil from "../AnsPerfil/Config";
import { BaseList } from "../../siht/base";
import { DataGrid, DataGridButtons, DataGridColumn } from "../../siht/components";
import { Button, Div } from "../../siht/elements";
import Util from "../../utils/Util";
import ConfigApp from "../../ConfigApp";

class ListAnsPerfilClassificacao extends BaseList {

	constructor(props) {
		super(props);
		this.getItens = this.getItens.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleNew = this.handleNew.bind(this);
		this.handleEdit = this.handleEdit.bind(this);
		this.handleCopy = this.handleCopy.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.handleMore = this.handleMore.bind(this);
		this.onSubmitFilter = this.onSubmitFilter.bind(this);
		this.handleClear = this.handleClear.bind(this);
		this.handleFormSuccess = this.handleFormSuccess.bind(this);
		this.handleFormClose = this.handleFormClose.bind(this);
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init() {
		super.init(Config);
	}

	getItens(more) {
		if (this.props?.parent) {
			super.getItens(Config.getService().getAllByAnsPerfil, this.props.parent, more);
		}
	}

	handleNew(e) {
		super.handleNew(e, Config);
	}

	handleEdit(e, item) {
		super.handleEdit(e, Config, item);
	}

	handleDelete(e, item) {
		super.handleDelete(e, Config, item);
	}

	handleCopy(e, item) {
		super.handleCopy(e, Config, item);
	}

	getFormEmbedded() {
		return super.getFormEmbedded(Config);
	}

	render() {
		return super.render(
			<Div className="p-2 mt-2">
				<Div className="d-flex justify-content-end flex-nowrap gap-2">
					<BtnNew onClick={this.handleNew} disabled={!ConfigAnsPerfil.getPermissaoAnsPerfil(this.props.parent).flPodeEditar} />
					<BtnDelete disabled={this.state.selecteds.length === 0 || !ConfigAnsPerfil.getPermissaoAnsPerfil(this.props.parent).flPodeEditar} sizing={Button.sizing.default} onClick={e => this.handleDelete(e, this.state.selecteds)}> Excluir </BtnDelete>
				</Div>

				<DataGrid responsive={true} data={this.state.data} search={false} isDisabled={i => false} sizing={DataGrid.sizing.sm} select={ConfigAnsPerfil.getPermissaoAnsPerfil(this.props.parent).flPodeEditar} selecteds={this.state.selecteds} onSelect={(selecteds) => { this.setState({ selecteds: selecteds }) }} getItemValue={i => i.idAnsPerfilClassificacao} onMore={this.handleMore}>
					<DataGridColumn label="Código" field={i => i.idAnsPerfilClassificacao} className="text-center" />
					<DataGridColumn col={5} label="Material" field={i => <>
						{i.flMaterialCadastrado ? i.ansMaterial?.nmAnsMaterial : i.txAnsMaterial}
					</>} className="text-start" />

					<DataGridColumn col={3} label="Material" field={i => <>
						{parseInt(i.ansMaterial?.arquivo?.idArquivo) > 0 && i.ansMaterial?.arquivo?.cdUid && i.ansMaterial?.arquivo?.arquivoBase?.cdHash ?
							<Div className="d-flex justify-content-center align-items-center">
								<img style={{ background: `linear-gradient(to right, ${i.cdCor1}, ${i.cdCor2})`, maxHeight: "100px" }} src={ConfigApp.getUrlArquivo() + "public/inline/" + i.ansMaterial.arquivo.cdUid + "/" + i.ansMaterial.arquivo.arquivoBase.cdHash} className="img-fluid img-thumbnail" alt="..." />
							</Div>
							:
							<Div className="d-flex justify-content-center align-items-center">
								<Div style={{ background: `linear-gradient(to right, ${i.cdCor1}, ${i.cdCor2})`, height: "100px", width: "200px" }}> &nbsp; </Div>
							</Div>
						}
					</>} className="text-center" />

					<DataGridColumn col={2} label="Profundidade" field={i => !i.flLimite ? <> {Util.floatToValor(i.vlProfundidade, 2)} mts </> : <>Limite</>} className="text-center" />
					<DataGridColumn col={2} label="Deslocamento" field={i => <> {Util.floatToValor(i.vlDeslocamentoVertical, 2)} mts </>} className="text-center" />
					<DataGridButtons>
						<BtnEdit sizing={Button.sizing.default} onClick={this.handleEdit} disabled={!ConfigAnsPerfil.getPermissaoAnsPerfil(this.props.parent).flPodeEditar} />
						<BtnDelete sizing={Button.sizing.default} onClick={this.handleDelete} disabled={i => i.flLimite || !ConfigAnsPerfil.getPermissaoAnsPerfil(this.props.parent).flPodeEditar} />
						<BtnCopy sizing={Button.sizing.default} onClick={this.handleCopy} disabled={i => i.flLimite || !ConfigAnsPerfil.getPermissaoAnsPerfil(this.props.parent).flPodeEditar} />
					</DataGridButtons>
				</DataGrid>
			</Div>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <ListAnsPerfilClassificacao {...props} navigate={navigate} params={params} location={location} />
}
export default With