
import FormTipoValor from "./FormTipoValor.js";
import ListTipoValor from "./ListTipoValor.js";
import { Permissao } from "../../controllers";
import { Util } from "../../utils";
import { DadoValorService, TipoValorService } from "../../services";

export default class Config {

	static getUrlForm() {
		return "/CadTipoValor";
	}

	static getUrlList() {
		return "/LstTipoValor";
	}

	static getComponentForm() {
		return FormTipoValor;
	}

	static getPermissionForm() {
		return Permissao.getInstance().getPermissoes([209]);
	}

	static getPermissionList() {
		return Permissao.getInstance().getPermissoes([209]);
	}

	static getComponentList() {
		return ListTipoValor;
	}

	static getService() {
		return TipoValorService;
	}

	static getKeyId() {
		return "idTipoValor";
	}

	static getKeyDescription() {
		return "nmTipoValor";
	}

	static setDefaultValues(state , fnCallBack = () => {}, className = undefined) {
		className = className === undefined ? "FormTipoValor" : className;
		let keyNameScreen = Util.toToUpperTextCamelCase(className, "_") + ".";
		var prefix = "CONFIGURACAO.";
		DadoValorService.getValues(`${prefix}${keyNameScreen}`, (response)=> {
			Util.setValueObjectCamelCase(state.model, response, keyNameScreen, prefix);
			fnCallBack();
		}, fnCallBack);
	}
}