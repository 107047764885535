import { Component } from "react";

export default class ListGroupItem extends Component {
    
    render() {
        if(this.props.show !== undefined && !Boolean(this.props.show)){
            return (<></>);
        }

        const props = Object.assign({}, this.props);
        props.className = `list-group-item ${props.className !== undefined ? props.className : ""}  ${props.action !== undefined && props.action ? "list-group-item-action" : "'"} ${props.active !== undefined && props.active ? "active" : "'"}`;

        delete props.active;
        delete props.action;
        delete props.show;

        return (
            <div {...props}> {this.props.children} </div>
        );
    }
}