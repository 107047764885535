import { Component } from "react";

export default class ModalDialog extends Component {

    static sizing = {
        xs : "modal-xs",
        sm : "modal-sm",
        md : "modal-md",
        lg : "modal-lg",
        xl : "modal-xl",
        xxl : "modal-xxl",
        default : "modal-lg",
        fullscreen : "modal-fullscreen"
    }

    render() {
        if(this.props.show !== undefined && !Boolean(this.props.show)){
            return (<></>);
        }

        const props = Object.assign({}, this.props);
        props.scrollable = props.scrollable === undefined || (props.scrollable!== undefined && props.scrollable) ? true : false;
        props.className = `modal-dialog ${props.scrollable ? 'modal-dialog-scrollable' : ''} ${props.className !== undefined ? props.className : ""} ${props.sizing ? props.sizing : ModalDialog.sizing.default} ${props.fullscreen ? 'modal-fullscreen' : ''} ${props.sizing === ModalDialog.sizing.fullscreen ? 'p-4' : ''}`;

        delete props.modal;
        delete props.show;
        delete props.scrollable;
        delete props.sizing;
        delete props.fullscreen;

        return (            
            <div {...props}> {this.props.children} </div>
        );
    }
}