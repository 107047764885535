//#NO-CHANGE-FILE#

import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import BtnRadioGroupSimNao from "../../components/BtnRadioGroupSimNao";
import { BaseCrud } from "../../siht/base";
import { FormButtons, FormItem, Row, Panel, PanelBody, PanelFooter, Container, ModalDialog } from "../../siht/components";
import { InputText, Form } from "../../siht/elements";
import Config from "./Config";

class FormPessoaAtividade extends BaseCrud {

	constructor(props) {
		super(props);
		this.init = this.init.bind(this);
		this.handleSuccess = this.handleSuccess.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleChangeModel = this.handleChangeModel.bind(this);
		this.handleSubmitForm = this.handleSubmitForm.bind(this);

		this.state.model = {
			idPessoaAtividade : null,
			pessoa : this.props.parent !== undefined ? this.props.parent : {},
			nmAtividade : "",
			cdAtividade : "",
			flPrincipal : true
		};
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		super.init(Config);
	}

	handleSuccess(e) {
		super.handleSuccess(e, Config);
	}

	handleClose(e) {
		super.handleClose(e, Config);
	}

	render() {
		return super.render(
			<Form onSubmit={this.handleSuccess} refParam={f => (this.form = f)} key={this.state.key}>
				<Panel key={this.state.key} title="Cadastro de PessoaAtividade" onClose={this.handleClose} isModal={this.isModal()} container={{sizing: Container.sizing.xxl, className : ""}} modal={{sizing: this.props.sizing ? this.props.sizing : ModalDialog.sizing.xl}}>
					<PanelBody>
						<Row className="g-3 pb-2 px-2">
							<FormItem md={3} label="Código">
								<InputText name="idPessoaAtividade" value={this.state.model.idPessoaAtividade} readOnly={true} className="readonly-field" required={false} onChange={this.handleChangeModel}/>
							</FormItem>
							<FormItem md={12} label="Nome da Atividade">
								<InputText name="nmAtividade" value={this.state.model.nmAtividade} maxLength={100} required={true} onChange={this.handleChangeModel}/>
							</FormItem>
							<FormItem md={10} label="Identificador">
								<InputText name="cdAtividade" value={this.state.model.cdAtividade} maxLength={100} required={true} onChange={this.handleChangeModel} disabled={this.state.model.idPessoaAtividade}/>
							</FormItem>
							<FormItem md={2} label="Principal?">
								<BtnRadioGroupSimNao name="flPrincipal" value={this.state.model.flPrincipal} required={true} onChange={this.handleChangeModel}/>
							</FormItem>
						</Row>
					</PanelBody>
					<PanelFooter>
						<FormButtons onClose={this.handleClose} />
					</PanelFooter>
				</Panel>
			</Form>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <FormPessoaAtividade {...props} navigate={navigate} params={params} location={location}/>
}
export default With