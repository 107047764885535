//#NO-CHANGE-FILE#

import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import BtnRadioGroupSimNao from "../../components/BtnRadioGroupSimNao";
import ManyToManyCheckUsuarioUnidadeByUnidade from "./ManyToManyCheckUsuarioUnidadeByUnidade";
import ManyToManyCheckUsuarioGrupoByGrupo from "./ManyToManyCheckUsuarioGrupoByGrupo";
import Permissao from "../../controllers/Permissao";
import { BaseCrud, BaseList } from "../../siht/base";
import { FormButtons, FormItem, Row, TabNavigator, Tab, Panel, PanelBody, PanelFooter, Container, Col } from "../../siht/components";
import { InputText, InputEmail, InputPassword, Form, BtnSubmit, BtnButton, Button, Div } from "../../siht/elements";
import Config from "./Config";
import ConfigApp from "../../ConfigApp";
import { Modal } from "../../siht/controller";
import FormUpload from "../FormUpload";
import { IconDelete } from "../../icons";
import ListUsuarioTipoNotificacao from "../UsuarioTipoNotificacao/ListUsuarioTipoNotificacao";
import { FormItemTipoPessoaPessoa } from "../../components/FormItem";
import { Camera as IconCamera } from "react-bootstrap-icons";
import ListUsuarioToken from "../UsuarioToken/ListUsuarioToken";

class FormUsuario extends BaseCrud {

	constructor(props) {
		super(props);
		this.init = this.init.bind(this);
		this.handleSuccess = this.handleSuccess.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleChangeModel = this.handleChangeModel.bind(this);
		this.handleSubmitForm = this.handleSubmitForm.bind(this);
		this.handleArquivo = this.handleArquivo.bind(this);
		this.handleDeleteArquivo = this.handleDeleteArquivo.bind(this);

		this.state.model = {
			idUsuario: null,
			pessoa: null,
			nmUsuario: "",
			dsEmail: "",
			dsSenha: "",
			dsConfirmacao: "",
			flAtivo: true,
			flAlterarSenha: false,
			flAutomatico: false,
			arquivo: null,
			usuarioUnidade: [],
			usuarioGrupo: []
		};
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init() {
		super.init(Config, () => {
			this.setModel({ dsSenha: "", flAlterarSenha: false, dsConfirmacao: "" });
		});
	}

	handleSuccess(e) {
		super.handleSuccess(e, Config);
	}

	handleClose(e) {
		super.handleClose(e, Config);
	}

	handleArquivo() {
		Modal.openWindow(FormUpload, { sizing: Modal.sizing.default }, { multiple: false, accept: "image/*" }, (e, response) => {
			this.setModel({ arquivo: response });
		});
	}

	handleDeleteArquivo() {
		this.setModel({ arquivo: null });
	}

	render() {
		return super.render(

			<Panel key={this.state.key} title="Cadastro de Usuário" onClose={this.handleClose} isModal={this.isModal()} container={{ sizing: Container.sizing.xxl, className: "" }}>
				<PanelBody className="p-0 m-0">
					<TabNavigator key={this.state.key} onChangeTab={this.handleChangeTab} className="border-light">
						<Tab title="Usuário" className="m-2">
							<Form onSubmit={this.handleSuccess} refParam={f => (this.form = f)}>
								<Row className="g-3 pb-2 px-2">
									<FormItem md={3} label="Código">
										<InputText name="idUsuario" value={this.state.model.idUsuario} readOnly={true} className="readonly-field" required={false} onChange={this.handleChangeModel} />
									</FormItem>
									<FormItem md={2} label="Automático?" show={Permissao.getInstance().getPermissoes([103])}>
										<BtnRadioGroupSimNao name="flAutomatico" value={this.state.model.flAutomatico} required={true} onChange={this.handleChangeModel} disabled={this.state.model.idUsuario} />
									</FormItem>
									<FormItem md={2} label="Ativo?">
										<BtnRadioGroupSimNao name="flAtivo" value={this.state.model.flAtivo} required={true} onChange={this.handleChangeModel} />
									</FormItem>
									<Col sizing={5}></Col>

									{!this.state.model.flAutomatico ? <FormItemTipoPessoaPessoa label={["Tipo Pessoa", "Pessoa"]} md={[4, 8]} name="pessoa" value={this.state.model.pessoa} required={true} onChange={this.handleChangeModel} /> : <></>}

									<FormItem md={6} label="Nome do Usuário">
										<InputText name="nmUsuario" value={this.state.model.nmUsuario} autoFocus={true} maxLength={100} required={true} onChange={this.handleChangeModel} />
									</FormItem>
									<FormItem md={6} label="Email">
										<InputEmail name="dsEmail" value={this.state.model.dsEmail} maxLength={100} required={true} onChange={this.handleChangeModel} />
									</FormItem>

									{this.state.model.idUsuario && !this.state.model.flAutomatico ?
										<FormItem md={2} label="Alterar Senha?">
											<BtnRadioGroupSimNao name="flAlterarSenha" value={this.state.model.flAlterarSenha} required={true} onChange={this.handleChangeModel} />
										</FormItem>
										: <></>}
									{(!this.state.model.idUsuario || this.state.model.flAlterarSenha) ?
										<>
											<FormItem md={5} label="Senha">
												<InputPassword
													autoComplete="new-password" placeholder="Senha" value={this.state.model.dsSenha} name="dsSenha" onChange={this.handleChangeModel}
													validations={[
														{
															validation: this.state.model.dsConfirmacao === this.state.model.dsSenha,
															message: "Senha e Confirmação devem ser iguais"
														}
													]}
													required={true}
												/>
											</FormItem>
											<FormItem md={5} label="Confirmação">
												<InputPassword autoComplete="new-password" placeholder="Confirmação" col={6} value={this.state.model.dsConfirmacao} name="dsConfirmacao" onChange={this.handleChangeModel}
													validations={[
														{
															validation: this.state.model.dsConfirmacao === this.state.model.dsSenha,
															message: "Senha e Confirmação devem ser iguais"
														}
													]}
													required={true}
												/>
											</FormItem>
										</>
										: <></>
									}

									{/*
											<Row className={`row mx-1 mb-3 g-1`}>												
												<InputCheckSwitch label="Exigir senha para visualizar valor?" name="flExigirSenhaVerValor" checked={Boolean(this.state.model.flExigirSenhaVerValor)} onChange={this.handleChangeModel} />
												<InputCheckSwitch label="Permite ser marcado em comentários na ordem?" name="flMarcaComentario" checked={Boolean(this.state.model.flMarcaComentario)} onChange={this.handleChangeModel} />
											</Row>*/}
								</Row>

								<Row className="g-3 pb-2 px-2 pt-3">
									<Col md={12}>
										{parseInt(this.state.model?.arquivo?.idArquivo) > 0 && this.state.model.arquivo?.cdUid && this.state.model.arquivo?.arquivoBase?.cdHash ?
											<Div>
												<Div>
													<img style={{ maxHeight: "150px" }} src={ConfigApp.getUrlArquivo() + "public/inline/" + this.state.model.arquivo.cdUid + "/" + this.state.model.arquivo.arquivoBase.cdHash} className="img-fluid img-thumbnail" alt="..." />
												</Div>
												<Div>
													<BtnButton color={BtnButton.color.danger} sizing={Button.sizing.sm} onClick={this.handleDeleteArquivo}> <IconDelete /> Excluir Foto </BtnButton>
												</Div>
											</Div>
											: <BtnButton sizing={Button.sizing.default} multiple={false} onClick={this.handleArquivo} color={BtnButton.color.outlinePrimary}> <IconCamera /> Enviar Foto de Perfil </BtnButton>}
									</Col>
								</Row>
								<BtnSubmit hidden > Enviar </BtnSubmit>
							</Form>
						</Tab>
						<Tab show={Permissao.getInstance().getPermissoes([78])} title="Unidades" disabled={!this.state.model.idUsuario} opened={false} className="m-2">
							<ManyToManyCheckUsuarioUnidadeByUnidade typeCheck={ManyToManyCheckUsuarioUnidadeByUnidade.typeCheck.box} parent={this.state.model} search={true} />
						</Tab>
						<Tab show={Permissao.getInstance().getPermissoes([108])} title="Notificações" disabled={!this.state.model.idUsuario} opened={false} className="m-2">
							<ListUsuarioTipoNotificacao parent={this.state.model} localOpen={BaseList.localOpen.modal} />
						</Tab>
						<Tab show={Permissao.getInstance().getPermissoes([4])} title="Grupos" disabled={!this.state.model.idUsuario} opened={false} className="m-2">
							<ManyToManyCheckUsuarioGrupoByGrupo typeCheck={ManyToManyCheckUsuarioGrupoByGrupo.typeCheck.box} parent={this.state.model} search={true} />
						</Tab>
						<Tab show={Permissao.getInstance().getPermissoes([236])} title="Sessões" disabled={!this.state.model.idUsuario} opened={false} className="m-2">
							<ListUsuarioToken parent={this.state.model} localOpen={BaseList.localOpen.modal} />
						</Tab>
					</TabNavigator>
				</PanelBody>
				<PanelFooter>
					<FormButtons show={this.state.tabIndex === 0} onClose={this.handleClose} onSubmit={this.handleSubmitForm} />
				</PanelFooter>
			</Panel>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <FormUsuario {...props} navigate={navigate} params={params} location={location} />
}
export default With