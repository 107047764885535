//#NO-CHANGE-FILE#

import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import Permissao from "../../controllers/Permissao";
import { BaseList, BaseCrud } from "../../siht/base";
import { FormButtons, FormItem, Row, TabNavigator, Tab, Panel, PanelBody, PanelFooter, Container } from "../../siht/components";
import { InputText, Form, BtnSubmit } from "../../siht/elements";
import Config from "./Config";
import ListDadoValorByParent from "../DadoValor/ListDadoValorByParent";
import ListDadoValorByParentForm from "../DadoValor/ListDadoValorByParentForm";
import { PatchPlus as IconPatchPlus} from "react-bootstrap-icons";

class FormClassificacaoProduto extends BaseCrud {

	constructor(props) {
		super(props);
		this.init = this.init.bind(this);
		this.handleSuccess = this.handleSuccess.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleChangeModel = this.handleChangeModel.bind(this);
		this.handleSubmitForm = this.handleSubmitForm.bind(this);

		this.state.model = {
			idClassificacaoProduto : null,
			nmClassificacaoProduto : "",
			cdNcm : "",
			classificacaoProdutoDadoValor : []
		};
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		super.init(Config);
	}

	handleSuccess(e) {
		super.handleSuccess(e, Config);
	}

	handleClose(e) {
		super.handleClose(e, Config);
	}

	render() {
		return super.render(

					<Panel key={this.state.key} title="Cadastro de Classificação de Produto" onClose={this.handleClose} isModal={this.isModal()} container={{sizing: Container.sizing.xxl, className : ""}}>
						<PanelBody className="p-0 m-0">
							<TabNavigator key={this.state.key} onChangeTab={this.handleChangeTab} className="border-light">
								<Tab title="Classificação de Produto" className="m-2">
									<Form onSubmit={this.handleSuccess} refParam={f => (this.form = f)}>
										<Row className="g-3 pb-2 px-2">
											<FormItem md={6} label="Código">
												<InputText name="idClassificacaoProduto" value={this.state.model.idClassificacaoProduto} readOnly={true} className="readonly-field" required={false} onChange={this.handleChangeModel}/>
											</FormItem>
											<FormItem md={12} label="Nome da Classificação de Produto">
												<InputText name="nmClassificacaoProduto" value={this.state.model.nmClassificacaoProduto} autoFocus={true} maxLength={100} required={true} onChange={this.handleChangeModel}/>
											</FormItem>
											<FormItem md={6} label="CdNcm">
												<InputText name="cdNcm" value={this.state.model.cdNcm} maxLength={100} required={true} onChange={this.handleChangeModel}/>
											</FormItem>

											<ListDadoValorByParentForm cdDadoLocal="CLASSIFICACAO_PRODUTO" parent={this.state.model} onChange={this.handleChangeModel} name="classificacaoProdutoDadoValor"/>

											<BtnSubmit hidden > Enviar </BtnSubmit>
										</Row>
									</Form>
								</Tab>
								<Tab show={Permissao.getInstance().getPermissoes([156])} title={<><IconPatchPlus/> Complementar</>} disabled={!this.state.model.idClassificacaoProduto} opened={false} className="p-0 m-0">
									<ListDadoValorByParent cdDadoLocal="CLASSIFICACAO_PRODUTO" parent={this.state.model} localOpen={BaseList.localOpen.modal}/>
								</Tab>
							</TabNavigator>
						</PanelBody>
						<PanelFooter>
							<FormButtons show={this.state.tabIndex === 0} onClose={this.handleClose} onSubmit={this.handleSubmitForm}/>
						</PanelFooter>
					</Panel>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <FormClassificacaoProduto {...props} navigate={navigate} params={params} location={location}/>
}
export default With