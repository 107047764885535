
import FormContaTipoPagamentoDadoValor from "./FormContaTipoPagamentoDadoValor.js";
import ListContaTipoPagamentoDadoValor from "./ListContaTipoPagamentoDadoValor.js";
import { Permissao } from "../../controllers";
import { Util } from "../../utils";
import { DadoValorService, ContaTipoPagamentoDadoValorService } from "../../services";

export default class Config {

	static getUrlForm() {
		return "/CadContaTipoPagamentoDadoValor";
	}

	static getUrlList() {
		return "/LstContaTipoPagamentoDadoValor";
	}

	static getComponentForm() {
		return FormContaTipoPagamentoDadoValor;
	}

	static getPermissionForm() {
		return Permissao.getInstance().getPermissoes([163]);
	}

	static getPermissionList() {
		return Permissao.getInstance().getPermissoes([163]);
	}

	static getComponentList() {
		return ListContaTipoPagamentoDadoValor;
	}

	static getService() {
		return ContaTipoPagamentoDadoValorService;
	}

	static getKeyId() {
		return "idContaTipoPagamentoDadoValor";
	}

	static getKeyDescription() {
		return "nmContaTipoPagamentoDadoValor";
	}

	static setDefaultValues(state , fnCallBack = () => {}, className = undefined) {
		className = className === undefined ? "FormContaTipoPagamentoDadoValor" : className;
		let keyNameScreen = Util.toToUpperTextCamelCase(className, "_") + ".";
		var prefix = "CONFIGURACAO.";
		DadoValorService.getValues(`${prefix}${keyNameScreen}`, (response)=> {
			Util.setValueObjectCamelCase(state.model, response, keyNameScreen, prefix);
			fnCallBack();
		}, fnCallBack);
	}
}