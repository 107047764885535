import Http from "../controllers/Http";

export default class ContaReceberArquivoService {

	static urlBase = "/contaReceberArquivo/";

	static getForCombo(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(ContaReceberArquivoService.urlBase + 'forCombo', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static list(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(ContaReceberArquivoService.urlBase + 'list', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneById(id, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.get(ContaReceberArquivoService.urlBase + id)
			.then(fnSucess)
			.catch(fnError);
	}

	static create(data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(ContaReceberArquivoService.urlBase, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static update(id, data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.put(ContaReceberArquivoService.urlBase + id, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static delete(id, fnSucess = () => { }, fnError = () => { }) {
		if(Number.isInteger(id)){
			Http.getInstanceAppJson()
				.delete(ContaReceberArquivoService.urlBase + id)
				.then(fnSucess)
				.catch(fnError);
		}else{
			Http.getInstanceAppJson()
			.post(ContaReceberArquivoService.urlBase + 'delete', id)
			.then(fnSucess)
			.catch(fnError);
		}
	}

	static save(data, fnSucess = () => { }, fnError = () => { }) {
		if (data.idContaReceberArquivo) {
			this.update(data.idContaReceberArquivo, data, fnSucess, fnError);
		} else {
			this.create(data, fnSucess, fnError);
		}
	}

	static getAllByContaReceber(contaReceber, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(ContaReceberArquivoService.urlBase + 'contaReceber', contaReceber)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByContaReceber(contaReceber, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(ContaReceberArquivoService.urlBase + 'contaReceber/' + contaReceber.idContaReceber)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByArquivo(arquivo, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(ContaReceberArquivoService.urlBase + 'arquivo', arquivo)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByArquivo(arquivo, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(ContaReceberArquivoService.urlBase + 'arquivo/' + arquivo.idArquivo)
			.then(fnSucess)
			.catch(fnError);
	}
}