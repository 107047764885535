//#NO-CHANGE-FILE#

import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import SelectTipoVeiculo from "../../components/SelectDB/SelectTipoVeiculo";
//import ListVeiculoOdometro from "../VeiculoOdometro/ListVeiculoOdometro";
//import ListVeiculoLocalizacao from "../VeiculoLocalizacao/ListVeiculoLocalizacao";
import ListVeiculoTrajeto from "../VeiculoTrajeto/ListVeiculoTrajeto";
import ListVeiculoArquivo from "../VeiculoArquivo/ListVeiculoArquivo";
import ListVeiculoResumo from "../VeiculoResumo/ListVeiculoResumo";
import Permissao from "../../controllers/Permissao";
import { BaseList, BaseCrud } from "../../siht/base";
import { FormButtons, FormItem, Row, TabNavigator, Tab, Panel, PanelBody, PanelFooter, Container, InputMapOneMarker, ModalDialog } from "../../siht/components";
import { InputText, InputNumber, InputNumberFormat, Form, BtnSubmit, InputPlaca } from "../../siht/elements";
import Config from "./Config";
import ConfigApp from "../../ConfigApp";
import SelectPais from "../../components/SelectDB/SelectPais";
import BtnRadioGroupSimNao from "../../components/BtnRadioGroupSimNao";
import { FormItemEmpresaUnidade, FormItemMarcaModelo } from "../../components/FormItem";
import SelectTransmissao from "../../components/SelectDB/SelectTransmissao";
import ManyToManyCheckVeiculoCombustivel from "./ManyToManyCheckVeiculoCombustivelByCombustivel";
import Util from "../../utils/Util";
import { CarFrontFill as IconCarFrontFill, ClockHistory as IconClockHistory, Coin as IconCoin, Folder2 as IconFolder2, FuelPump as IconFuelPump, PatchPlus as IconPatchPlus, SignTurnSlightLeft as IconSignTurnSlightLeft } from "react-bootstrap-icons";
import ListDadoValorByParent from "../DadoValor/ListDadoValorByParent";
import ListDadoValorByParentForm from "../DadoValor/ListDadoValorByParentForm";
import ListContaPagarVeiculo from "../ContaPagar/ListContaPagarVeiculo";

class FormVeiculo extends BaseCrud {

	constructor(props) {
		super(props);
		this.init = this.init.bind(this);
		this.initModel = this.initModel.bind(this);
		this.handleSuccess = this.handleSuccess.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleChangeModel = this.handleChangeModel.bind(this);
		this.handleSubmitForm = this.handleSubmitForm.bind(this);
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		super.init(Config);
	}

	initModel(){
		return {
			idVeiculo : null,
			localizacao : null,
			tipoVeiculo : null,
			transmissao : null,
			modelo : null,
			veiculoPlaca : null,
			unidade : null,
			dsChassi : "",
			nrAnoFabricacao : null,
			nrAnoModelo : null,
			dsCor : "",
			nrPotencia : null,
			nrCilindrada : null,
			nrCapacidadePassageiro : null,
			nrCapacidadeTracao : null,
			nrEixo : null,
			vlQuilometragem : 0,
			vlVelocidadeMaxima : 110,
			flAtivo : true,
			flBaixado : false,
			veiculoOdometro : [],
			veiculoLocalizacao : [],
			veiculoDadoValor : []
		}
	}

	handleSuccess(e) {
		super.handleSuccess(e, Config);
	}

	handleClose(e) {
		super.handleClose(e, Config);
	}

	render() {
		return super.render(

					<Panel key={this.state.key} title="Cadastro de Veiculo" onClose={this.handleClose} isModal={this.isModal()} localOpen={this.props.localOpen} container={{sizing: Container.sizing.fluid, className : ""}} modal={{sizing: this.props.sizing ? this.props.sizing : ModalDialog.sizing.xl}}>
						<PanelBody className="p-0 m-0">
							<TabNavigator key={this.state.key} onChangeTab={this.handleChangeTab} className="border-light">
								<Tab title={<><IconCarFrontFill/> Veículo</>} className="m-2">
									<Form onSubmit={this.handleSuccess} refParam={f => (this.form = f)}>
										<Row className="g-3 pb-2 px-2">
											<FormItem md={3} label="Código">
												<InputText name="idVeiculo" value={this.state.model.idVeiculo} readOnly={true} className="readonly-field" required={false} onChange={this.handleChangeModel}/>
											</FormItem>

											<FormItem md={5} label={<>Última Localização <small className="fst-italic">{Util.date2Br(this.state.model.localizacao?.dhInsert, true)}</small></>}>
												<InputMapOneMarker apiKey={ConfigApp.getInstance().clientIdGoogleMaps}
													lng={this.state.model.localizacao?.vlLongitude}
													lat={this.state.model.localizacao?.vlLatitude}											
													required={false}
													readOnly={true}/>
											</FormItem>

											<FormItem md={2} label="Odômetro" help="Última leitura do Odômetro">
												<InputNumberFormat name="vlQuilometragem" value={this.state.model.vlQuilometragem} decimalScale={0} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} suffix=" KM" placeholder="99.999" required={false} readOnly={true}/>
											</FormItem>

											<FormItem md={2} label="Vel. Máx." help="Velocidade Máxima Permitida para esse veículo.">
												<InputNumberFormat name="vlVelocidadeMaxima" value={this.state.model.vlVelocidadeMaxima} decimalScale={0} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} suffix=" km/h" placeholder="99.999" required={true} readOnly={false}/>
											</FormItem>

											<FormItemEmpresaUnidade md={[6,6]} name="unidade" value={this.state.model.unidade} required={false} onChange={this.handleChangeModel}/>
										</Row>										

										<Row className="g-3 pb-2 px-2">
											<FormItem md={3} label="Emplacado?">
												<BtnRadioGroupSimNao name="flEmplacado" value={this.state.model.veiculoPlaca !== null} required={true} onChange={(e,v) => this.setModel({veiculoPlaca : v ? {} : null})}/>
											</FormItem>

											<FormItem md={6} label="Pais de Emplacamento">
												<SelectPais name="pais" disabled={this.state.model.veiculoPlaca === null} value={this.state.model.veiculoPlaca?.pais} required={true} onChange={(e,v,p) => this.handleChange(e, v, p, this.state.model.veiculoPlaca)} placeholder="Selecione Nome do Pais"/>
											</FormItem>

											<FormItem md={3} label="Placa">
												<InputPlaca pais={this.state.model.veiculoPlaca?.pais} name="dsPlaca" disabled={this.state.model.veiculoPlaca === null} value={this.state.model.veiculoPlaca?.dsPlaca} required={true} onChange={(e,v,p) => this.handleChange(e, v, p, this.state.model.veiculoPlaca)}/>
											</FormItem>
										</Row>

										<Row className="g-3 pb-2 px-2">
											<FormItemMarcaModelo md={[6,6]} name="modelo" value={this.state.model.modelo} required={true} onChange={this.handleChangeModel}/>

											<FormItem md={6} label="Tipo de Veículo">
												<SelectTipoVeiculo name="tipoVeiculo" value={this.state.model.tipoVeiculo} required={true} onChange={this.handleChangeModel} placeholder="Selecione o Tipo de Veiculo"/>
											</FormItem>

											<FormItem md={6} label="Transmissão">
												<SelectTransmissao name="transmissao" value={this.state.model.transmissao} required={true} onChange={this.handleChangeModel} placeholder="Selecione a Transmissão"/>
											</FormItem>

											<FormItem md={6} label="Número do Chassi">
												<InputText name="dsChassi" value={this.state.model.dsChassi} maxLength={100} required={false} onChange={this.handleChangeModel}/>
											</FormItem>
											<FormItem md={3} label="Ano Fabricação">
												<InputNumber name="nrAnoFabricacao" value={this.state.model.nrAnoFabricacao} required={false} onChange={this.handleChangeModel} min={1}/>
											</FormItem>
											<FormItem md={3} label="Ano Modelo">
												<InputNumber name="nrAnoModelo" value={this.state.model.nrAnoModelo} required={parseInt(this.state.model.nrAnoFabricacao) > 0} onChange={this.handleChangeModel} min={this.state.model.nrAnoFabricacao ? this.state.model.nrAnoFabricacao : null} max={this.state.model.nrAnoFabricacao ? this.state.model.nrAnoFabricacao + 1 : null}/>
											</FormItem>
											<FormItem md={6} label="Cor">
												<InputText name="dsCor" value={this.state.model.dsCor} maxLength={100} required={false} onChange={this.handleChangeModel}/>
											</FormItem>
											<FormItem md={3} label="Potência">
												<InputNumberFormat name="nrPotencia" value={this.state.model.nrPotencia} decimalScale={0} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} suffix=" CV" placeholder="99999" required={false} onChange={this.handleChangeModel}/>
											</FormItem>
											<FormItem md={3} label="Cilindrada">
												<InputNumber name="nrCilindrada" value={this.state.model.nrCilindrada} required={false} onChange={this.handleChangeModel} min={1}/>
											</FormItem>
											<FormItem md={3} label="Cap. Passageiros">
												<InputNumber name="nrCapacidadePassageiro" value={this.state.model.nrCapacidadePassageiro} required={false} onChange={this.handleChangeModel} min={1}/>
											</FormItem>
											<FormItem md={3} label="Cap. Tração">
												<InputNumber name="nrCapacidadeTracao" value={this.state.model.nrCapacidadeTracao} required={false} onChange={this.handleChangeModel} min={1}/>
											</FormItem>
											
											<FormItem md={2} label="Eixos">
												<InputNumber name="nrEixo" value={this.state.model.nrEixo} required={false} onChange={this.handleChangeModel}/>
											</FormItem>

											<FormItem md={2} label="Ativo?">
												<BtnRadioGroupSimNao name="flAtivo" value={this.state.model.flAtivo} required={true} onChange={this.handleChangeModel}/>
											</FormItem>

											<FormItem md={2} label="Baixado?">
												<BtnRadioGroupSimNao name="flBaixado" value={this.state.model.flBaixado} required={true} onChange={this.handleChangeModel}/>
											</FormItem>

											<ListDadoValorByParentForm cdDadoLocal="VEICULO" parent={this.state.model} onChange={this.handleChangeModel} name="veiculoDadoValor"/>

											<BtnSubmit hidden > Enviar </BtnSubmit>
										</Row>
									</Form>
								</Tab>
								{/*<Tab show={Permissao.getInstance().getPermissoes([176])} title="Odômetro" disabled={!this.state.model.idVeiculo} opened={false} className="m-2">
									<ListVeiculoOdometro parent={this.state.model} localOpen={BaseList.localOpen.modal}/>
								</Tab>
								<Tab show={Permissao.getInstance().getPermissoes([177])} title="Localização" disabled={!this.state.model.idVeiculo} opened={false} className="m-2">
									<ListVeiculoLocalizacao parent={this.state.model} localOpen={BaseList.localOpen.modal}/>
								</Tab>*/}
								<Tab show={Permissao.getInstance().getPermissoes([185])} title={<><IconSignTurnSlightLeft/> Trajeto</>} disabled={!this.state.model.idVeiculo} opened={false} className="m-2">
									<ListVeiculoTrajeto parent={this.state.model} localOpen={BaseList.localOpen.modal}/>
								</Tab>
								<Tab show={Permissao.getInstance().getPermissoes([187])} title={<><IconClockHistory/> Resumo</>} disabled={!this.state.model.idVeiculo} opened={false} className="m-0">
									<ListVeiculoResumo parent={this.state.model} localOpen={BaseList.localOpen.modal}/>
								</Tab>
								<Tab show={Permissao.getInstance().getPermissoes([181])} title={<><IconFuelPump/> Combustível</>} disabled={!this.state.model.idVeiculo} opened={false} className="m-2">
									<ManyToManyCheckVeiculoCombustivel typeCheck={ManyToManyCheckVeiculoCombustivel.typeCheck.box} parent={this.state.model} search={false}/>
								</Tab>
								<Tab show={Permissao.getInstance().getPermissoes([184])} title={<><IconFolder2/> Arquivos</>} disabled={!this.state.model.idVeiculo} opened={false} className="m-2">
									<ListVeiculoArquivo parent={this.state.model} localOpen={BaseList.localOpen.modal}/>
								</Tab>
								<Tab show={Permissao.getInstance().getPermissoes([183])} title={<><IconPatchPlus/> Complementar</>} disabled={!this.state.model.idVeiculo} opened={false} className="p-0 m-0">
									<ListDadoValorByParent cdDadoLocal="VEICULO" parent={this.state.model} localOpen={BaseList.localOpen.modal}/>
								</Tab>
								<Tab show={Permissao.getInstance().getPermissoes([190])} title={<><IconCoin/> Contas Pagar</>} disabled={!this.state.model.idVeiculo} opened={false} className="m-2">
									<ListContaPagarVeiculo parent={this.state.model} localOpen={BaseList.localOpen.modal}/>
								</Tab>								
							</TabNavigator>
						</PanelBody>
						<PanelFooter>
							<FormButtons show={this.state.tabIndex === 0} onClose={this.handleClose} onSubmit={this.handleSubmitForm}/>
						</PanelFooter>
					</Panel>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <FormVeiculo {...props} navigate={navigate} params={params} location={location}/>
}
export default With