
import FormPainel from "./FormPainel.js";
import ListPainel from "./ListPainel.js";
import { Permissao } from "../../controllers";
import { Util } from "../../utils";
import { DadoValorService, PainelService } from "../../services";

export default class Config {

	static getUrlForm() {
		return "/CadPainel";
	}

	static getUrlList() {
		return "/LstPainel";
	}

	static getComponentForm() {
		return FormPainel;
	}

	static getPermissionForm() {
		return Permissao.getInstance().getPermissoes([95]);
	}

	static getPermissionList() {
		return Permissao.getInstance().getPermissoes([95]);
	}

	static getComponentList() {
		return ListPainel;
	}

	static getService() {
		return PainelService;
	}

	static getKeyId() {
		return "idPainel";
	}

	static getKeyDescription() {
		return "nmPainel";
	}

	static setDefaultValues(state , fnCallBack = () => {}, className = undefined) {
		className = className === undefined ? "FormPainel" : className;
		let keyNameScreen = Util.toToUpperTextCamelCase(className, "_") + ".";
		var prefix = "CONFIGURACAO.";
		DadoValorService.getValues(`${prefix}${keyNameScreen}`, (response)=> {
			Util.setValueObjectCamelCase(state.model, response, keyNameScreen, prefix);
			fnCallBack();
		}, fnCallBack);
	}
}