import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { BaseCrud } from "../../siht/base";
import { FormButtons, FormItem, Row, Panel, PanelBody, PanelFooter, Container, ModalDialog, BtnRadioGroup } from "../../siht/components";
import { Form, BtnRadio, InputCheckSwitch, InputText, SelectMultipleDropDown, Option } from "../../siht/elements";
import Util from "../../utils/Util";
import ConfigApp from "../../ConfigApp";

class FormRelatorioAns001 extends BaseCrud {

    constructor(props) {
        super(props);

        this.init = this.init.bind(this);
		this.handleSuccess = this.handleSuccess.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleChangeModel = this.handleChangeModel.bind(this);
		this.handleSubmitForm = this.handleSubmitForm.bind(this);

        this.urlRelatorio = "relatorio/ans-001";

        this.state.model = {
            idAnsPerfis: props.idAnsPerfis,
            orientation: "P",
            destination: "I",
            flMarcaDagua: true,
            flProteger: true,
            dsSenhaUsuario : "",
            dsSenhaProprietario : "",
            cdProtecao : ["print","print-highres"].map(v => {return {value : v}})
        };
    }
   
	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
	}

	handleSuccess(e) {
        Util.openNewWindow(ConfigApp.getUrlApi() + this.urlRelatorio, this.state.model);
	}

	handleClose(e) {
		super.handleClose(e, {});
	}

    render() {
        return (
            <Form onSubmit={this.handleSuccess} refParam={f => (this.form = f)} key={this.state.key}>
				<Panel key={this.state.key} title="Impressão do Perfil" onClose={this.handleClose} isModal={this.isModal()} container={{sizing: Container.sizing.xxl, className : ""}} modal={{sizing: this.props.sizing ? this.props.sizing : ModalDialog.sizing.xl}}>
                    <PanelBody>
                        <Row className="row mb-3">
                            <FormItem md={6} label="Destino do Relatório">
                                <BtnRadioGroup name="destination" color={BtnRadioGroup.color.primary} opcional={true} value={this.state.model.destination} onClick={this.handleChangeModel}>
                                    <BtnRadio value="I" label="Tela" />
                                    <BtnRadio value="D" label="Download" />
                                </BtnRadioGroup>
                            </FormItem>
                            <FormItem md={6} label="Disposição da Página">
                                <BtnRadioGroup name="orientation" color={BtnRadioGroup.color.primary} opcional={true} value={this.state.model.orientation} onClick={this.handleChangeModel}>
                                    <BtnRadio value="P" label="Vertical" />
                                    <BtnRadio value="L" label="Horizontal" />
                                </BtnRadioGroup>
                            </FormItem>
                        </Row>
                        <Row className="row px-3 mb-1">
                            <InputCheckSwitch label="Proteger arquivo PDF" name="flProteger" checked={(this.state.model.flProteger)} onChange={this.handleChangeModel} />
                        </Row>
                        <Row className="row g-3 mb-3"  show={this.state.model.flProteger}>
                            <FormItem md={6} label="Senha do Proprietário" help="Senha para editar o arquivo">
                                <InputText name="dsSenhaProprietario" value={this.state.model.dsSenhaProprietario} autoFocus={true} maxLength={100} required={false} onChange={this.handleChangeModel}/>
                            </FormItem>

                            <FormItem md={6} label="Liberar Permissões para">
                                <SelectMultipleDropDown name="cdProtecao" required={true} value={this.state.model.cdProtecao} onChange={this.handleChangeModel} rowCols={2}>
                                    <Option value="copy" label="Copiar"/>                                    
                                    <Option value="extract" label="Extrair"/>
                                    <Option value="modify" label="Modificar"/>
                                    <Option value="print" label="Imprimir"/>
                                    <Option value="print-highres" label="Imprimir em Alta Resolução"/>
                                    <Option value="annot-forms" label="Anotações em Formulário"/>
                                    <Option value="fill-forms" label="Preencher Formulários"/>
                                    <Option value="assemble" label="Montar Documento"/>
                                </SelectMultipleDropDown>
                            </FormItem>

                            <FormItem md={6} label="Senha do Usuário" help="Senha para abrir o arquivo">
                                <InputText name="dsSenhaUsuario" value={this.state.model.dsSenhaUsuario} autoFocus={true} maxLength={100} required={false} onChange={this.handleChangeModel}/>
                            </FormItem>
                        </Row>
                    </PanelBody>
                    <PanelFooter>
                        <FormButtons onClose={this.handleClose} label={['Imprimir','Fechar']}/>
                    </PanelFooter>
                </Panel>
            </Form>
        );
    }
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <FormRelatorioAns001 {...props} navigate={navigate} params={params} location={location}/>
}
export default With

/**

The permission array is composed of values taken from the following ones (specify the ones you want to block):
    - print : Print the document;
    - modify : Modify the contents of the document by operations other than those controlled by 'fill-forms', 'extract' and 'assemble';
    - copy : Copy or otherwise extract text and graphics from the document;
    - annot-forms : Add or modify text annotations, fill in interactive form fields, and, if 'modify' is also set, create or modify interactive form fields (including signature fields);
    - fill-forms : Fill in existing interactive form fields (including signature fields), even if 'annot-forms' is not specified;
    - extract : Extract text and graphics (in support of accessibility to users with disabilities or for other purposes);
    - assemble : Assemble the document (insert, rotate, or delete pages and create bookmarks or thumbnail images), even if 'modify' is not set;
    - print-high : Print the document to a representation from which a faithful digital copy of the PDF content could be generated. When this is not set, printing is limited to a low-level representation of the appearance, possibly of degraded quality.
    - owner : (inverted logic - only for public-key) when set permits change of encryption and enables all other permissions.

*/