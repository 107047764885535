
import FormTipoFiltro from "./FormTipoFiltro.js";
import ListTipoFiltro from "./ListTipoFiltro.js";
import { Permissao } from "../../controllers";
import { Util } from "../../utils";
import { DadoValorService, TipoFiltroService } from "../../services";

export default class Config {

	static getUrlForm() {
		return "/CadTipoFiltro";
	}

	static getUrlList() {
		return "/LstTipoFiltro";
	}

	static getComponentForm() {
		return FormTipoFiltro;
	}

	static getPermissionForm() {
		return Permissao.getInstance().getPermissoes([102]);
	}

	static getPermissionList() {
		return Permissao.getInstance().getPermissoes([102]);
	}

	static getComponentList() {
		return ListTipoFiltro;
	}

	static getService() {
		return TipoFiltroService;
	}

	static getKeyId() {
		return "idTipoFiltro";
	}

	static getKeyDescription() {
		return "nmTipoFiltro";
	}

	static setDefaultValues(state , fnCallBack = () => {}, className = undefined) {
		className = className === undefined ? "FormTipoFiltro" : className;
		let keyNameScreen = Util.toToUpperTextCamelCase(className, "_") + ".";
		var prefix = "CONFIGURACAO.";
		DadoValorService.getValues(`${prefix}${keyNameScreen}`, (response)=> {
			Util.setValueObjectCamelCase(state.model, response, keyNameScreen, prefix);
			fnCallBack();
		}, fnCallBack);
	}
}