import React from "react";
import Field from "./Field";

export default class BtnRadio extends Field {

    static color = {
        primary : "primary",
        success : "success",
        danger : "danger",
        warning : "warning",
        dark : "dark",
        default : "primary"
    };
    
    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    handleChange(e) {
        super.handleChange(e, e?.target?.value, e?.target?.name);
    }

    render() {
        const props = Object.assign({}, this.props);
        props.type = "radio";
        props.onChange = this.handleChange;
        props.onClick = this.handleClick;
        props.ref = this.props.refParam ? this.props.refParam : this.ref;
        props.className = `btn-check ${props.className ? props.className : ""}`;

        var label = this.props.label;

        delete props.label;
        delete props.color;

        return (
            <React.Fragment>
                <input {...this.getProps(props)} />
                <label className={`btn btn-outline-${this.props.color ? this.props.color : BtnRadio.color.default}`} htmlFor={this.props.id}>
                   {label}
                </label>
            </React.Fragment>
        );
    }
}