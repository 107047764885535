import React from "react";
import { BaseComponent } from "../../siht/base";
import { BtnButton, Div } from "../../siht/elements";
import Bloco from "../Painel/Bloco";
import UsuarioBlocoFavoritoService from "../../services/UsuarioBlocoFavoritoService";
import { Col } from "../../siht/components";
import { Link } from "react-router-dom";

export default class Dasboard extends BaseComponent {

    constructor(props) {
        super(props);

        this.getFavoritos = this.getFavoritos.bind(this);

        this.state = {
            data : []
        };
    }

    componentDidMount() {
        super.componentDidMount();
        this.getFavoritos();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    getFavoritos(){
        UsuarioBlocoFavoritoService.list((response => {
            let blocos = response.map(ub => ub.bloco);
            this.setState({data : blocos});
        }));
    }

    render() {

        return (
            <Div className="px-3">
                <Div className="row g-3">
                    {this.state.data.map((bloco, index) => (
                        <Bloco idBloco={bloco.idBloco} filterable={false} play={true} key={bloco.idBloco} filter={{}} fnCallbackFavoritar={this.getFavoritos} />
                    ))}
                </Div>
                <Div className="pt-2 row">
                    <Col sizing="6">                        
                        <Link className="btn btn-outline-primary btn-sm" to="/painel">Ver todos os paineis</Link>
                    </Col>
                    <Col sizing="6" className="text-end">
                        <BtnButton sizing={BtnButton.sizing.sm} color={BtnButton.color.outlinePrimary} onClick={this.getFavoritos}> Atualizar </BtnButton>
                    </Col>
                </Div>
            </Div>
        );
    }
}