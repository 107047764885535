import React, { Component } from "react";
import DropDown from "./DropDown";
import DropDownLabel from "./DropDownLabel";
import DropDownItem from "./DropDownItem";
import { Util } from "../../utils";
import { BtnButton, Option } from "../elements";
import { Functions } from "../util";

export default class FormItemLabelDropDown extends Component {

    static sizing = {
        lg: "lg",
        sm : "sm",
        default: "",
    }

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        //this.setOptions = this.setOptions.bind(this);
        this.state = {};
        this.state.options = this.props.options || [];

        if(this.props.children){
            this.state.options = React.Children.toArray(this.props.children).filter(child => child.type === Option);
            this.state.options = this.state.options.map(o => o.props);

            if(this.props.value === undefined || this.props?.value === null){
                let selected = this.state.options.find(o => o.selected);
                if(selected){
                    this.handleChange(null, selected);
                }
            }
        }
    }

    getOptionValue(item) {
        if(Util.isObject(item)){
            return item?.value;
        }
        
        return item;
    }

    getOptionLabel(item) {

        if(this.props.getOptionLabel !== undefined && Functions.isFunction(this.props.getOptionLabel)){
            return this.props.getOptionLabel(item);
        }else{
            if(item?.label !== undefined){
                return item?.label;
            }else{
                return this.state.options.find(i => i.value?.toString() === item?.value?.toString())?.label;
            }
        }
    }

    handleChange(e, selected) {
        if(selected === undefined){
            selected = this.state.options.find(item => this.getOptionValue(item)?.toString() === e?.target?.value?.toString());
            selected = selected === undefined ? "" : selected;
        }

        if(this.props.onChange !== undefined && Functions.isFunction(this.props.onChange)){
            this.props.onChange(e, selected, this.props?.name);
        }
    }

    render() {
        if (this.props.show !== undefined && !Boolean(this.props.show)) {
            return (<></>);
        }

        const props = Object.assign({}, this.props);
        //props.className = `${this.getColSize()} ${props.className !== undefined ? props.className : ""}`;
        
        delete props.getOptionLabel;
        var render = <></>;

        var label = this.getOptionLabel(this.props.value);
        var placeholder = this.props.placeholder !== undefined ? this.props.placeholder : "Selecione";

        if(!label){
            label = placeholder;
        }

        render = (
            <DropDown className="p-0 m-0" style={{height : "24px"}}>
                <DropDownLabel {...props} toggle={true} color={BtnButton.color.link} className="p-0 m-0 btn-link-clear"> {label} </DropDownLabel>
                {this.state.options.map((option, index) => (
                    <DropDownItem active={this.getOptionValue(this.props?.value)?.toString() === this.getOptionValue(option)?.toString()} key={index} title={option.label} type="button" onClick={(e) => this.handleChange(e, option)}> 
                        {this.getOptionLabel(option)}
                    </DropDownItem>
                ))}
            </DropDown>
        );

        return render;
    }
}