import Field from "./Field";

export default class InputCheckBox extends Field {

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    handleChange(e) {
        super.handleChange(e, Boolean(e?.target?.checked), e?.target?.name);
    }

    handleClick(e) {
        super.handleClick(e, Boolean(e?.target?.checked), e?.target?.name);
    }

    render() {
        const props = Object.assign({}, this.props);
        props.type = "checkbox";
        props.onChange = this.handleChange;
        props.onClick = this.handleClick;
        props.ref = this.props.refParam ? this.props.refParam : this.ref;
        props.autoComplete = "off";
        props.className = `form-check-input ${props.indeterminate === true ? "form-checkbox-input-indeterminate" : ""} ${props.className ? props.className : ""}`;
        props.classNameLabel = `form-check-label ${props.classNameLabel ? props.classNameLabel : ""}`;
        props.checked = this.props.value === true;
        props.id = props.id !== undefined ? props.id : 'form-checkbox-input-' + Math.random();

        var propsLabel = {};
        propsLabel.className = props.classNameLabel;
        propsLabel.htmlFor = props.id;

        delete props.label;
        delete props.classNameLabel;
        delete props.indeterminate;

        return super.render(
            <div className="form-check">
                <input {...this.getProps(props)} />
                <label {...propsLabel}>
                    {this.props.label}
                </label>
            </div>
            , false);
    }
}