//#NO-CHANGE-FILE#

import Config from "./Config";
import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import SelectTipoComissaoData from "../../components/SelectDB/SelectTipoComissaoData";
import { BaseCrud } from "../../siht/base";
import { FormButtons, FormItem, Row, Panel, PanelBody, PanelFooter, Container, ModalDialog } from "../../siht/components";
import { InputDate, Form } from "../../siht/elements";

class FormComissaoData extends BaseCrud {

	constructor(props) {
		super(props);
		this.init = this.init.bind(this);
		this.initModel = this.initModel.bind(this);
		this.handleSuccess = this.handleSuccess.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleChangeModel = this.handleChangeModel.bind(this);
		this.handleSubmitForm = this.handleSubmitForm.bind(this);
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		super.init(Config);
	}

	initModel(){
		return {
			idComissaoData : null,
			comissao : this.props.parent !== undefined ? this.props.parent : {},
			tipoComissaoData : null,
			dtInicio : "",
			dtFim : ""
		}
	}

	handleSuccess(e) {
		super.handleSuccess(e, Config);
	}

	handleClose(e) {
		super.handleClose(e, Config);
	}

	render() {
		var tzoffset = (new Date()).getTimezoneOffset() * 60000;
		var dtHoje = new Date();
		dtHoje = new Date(dtHoje - tzoffset).toISOString().slice(0, 10);

		return super.render(
			<Form onSubmit={this.handleSuccess} refParam={f => (this.form = f)} key={this.state.key}>
				<Panel key={this.state.key} title="Cadastro de Comissão Data" localOpen={this.props.localOpen}  onClose={this.handleClose} isModal={this.isModal()} container={{sizing: Container.sizing.xxl, className : ""}} modal={{sizing: this.props.sizing ? this.props.sizing : ModalDialog.sizing.xl}}>
					<PanelBody>
						<Row className="g-3 pb-2 px-2">							
							<FormItem md={6} label="Tipo da Data">
								<SelectTipoComissaoData name="tipoComissaoData" value={this.state.model.tipoComissaoData} required={true} onChange={this.handleChangeModel} placeholder="Selecione Tipo da Data" buttons={{search : false, new : false, edit: false}}/>
							</FormItem>
							<FormItem md={3} label="DtInicio">
								<InputDate name="dtInicio" value={this.state.model.dtInicio} required={true} onChange={this.handleChangeModel} max={dtHoje}/>
							</FormItem>
							<FormItem md={3} label="DtFim">
								<InputDate name="dtFim" min={this.state.model.dtInicio} max={dtHoje} value={this.state.model.dtFim} required={true} onChange={this.handleChangeModel}/>
							</FormItem>
						</Row>
					</PanelBody>
					<PanelFooter>
						<FormButtons onClose={this.handleClose} />
					</PanelFooter>
				</Panel>
			</Form>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <FormComissaoData {...props} navigate={navigate} params={params} location={location}/>
}
export default With