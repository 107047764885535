import Config from "./Config";
import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import SelectFaturaCobrancaTransacao from "../../components/SelectDB/SelectFaturaCobrancaTransacao";
import SelectTipoBaixa from "../../components/SelectDB/SelectTipoBaixa";
import SelectUsuario from "../../components/SelectDB/SelectUsuario";
import SelectFaturaAcordo from "../../components/SelectDB/SelectFaturaAcordo";
import { BaseCrud } from "../../siht/base";
import { FormButtons, FormItem, Row, Panel, PanelBody, PanelFooter, Container, ModalDialog } from "../../siht/components";
import { InputText, InputNumberFormat, InputDate, TextArea, Form } from "../../siht/elements";
import { BtnRadioGroupSimNao } from "../../components/Custom";

class FormFaturaCobrancaBaixa extends BaseCrud {

	constructor(props) {
		super(props);
		this.init = this.init.bind(this);
		this.initModel = this.initModel.bind(this);
		this.handleSuccess = this.handleSuccess.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleChangeModel = this.handleChangeModel.bind(this);
		this.handleSubmitForm = this.handleSubmitForm.bind(this);
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		super.init(Config);
	}

	initModel(){
		return {
			idFaturaCobrancaBaixa : null,
			faturaCobrancaTransacao : null,
			faturaCobranca : this.props.parent !== undefined ? this.props.parent : {},
			tipoBaixa : null,
			usuario : null,
			faturaAcordo : null,
			vlBaixa : 0,
			vlMulta : 0,
			vlJuros : 0,
			vlTaxa : 0,
			dtBaixa : "",
			cdChaveBaixa : "",
			flEstorno : false,
			txBaixa : ""
		}
	}

	handleSuccess(e) {
		super.handleSuccess(e, Config);
	}

	handleClose(e) {
		super.handleClose(e, Config);
	}

	render() {
		return super.render(

					<Form onSubmit={this.handleSuccess} refParam={f => (this.form = f)} key={this.state.key}>
						<Panel key={this.state.key} title="Cadastro de FaturaCobrancaBaixa" localOpen={this.props.localOpen}  onClose={this.handleClose} isModal={this.isModal()} container={{sizing: Container.sizing.xxl, className : ""}} modal={{sizing: this.props.sizing ? this.props.sizing : ModalDialog.sizing.xl}}>
							<PanelBody>
								<Row className="g-3 pb-2 px-2">
									<FormItem md={6} label="Código">
										<InputText name="idFaturaCobrancaBaixa" value={this.state.model.idFaturaCobrancaBaixa} readOnly={true} className="readonly-field" required={false} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={12} label="Nome do FaturaCobrancaTransacao">
										<SelectFaturaCobrancaTransacao name="faturaCobrancaTransacao" value={this.state.model.faturaCobrancaTransacao} required={true} onChange={this.handleChangeModel} placeholder="Selecione Nome do FaturaCobrancaTransacao"/>
									</FormItem>
									<FormItem md={12} label="Nome do TipoBaixa">
										<SelectTipoBaixa name="tipoBaixa" value={this.state.model.tipoBaixa} required={true} onChange={this.handleChangeModel} placeholder="Selecione Nome do TipoBaixa"/>
									</FormItem>
									<FormItem md={12} label="Nome do Usuario">
										<SelectUsuario name="usuario" value={this.state.model.usuario} required={true} onChange={this.handleChangeModel} placeholder="Selecione Nome do Usuario"/>
									</FormItem>
									<FormItem md={12} label="Nome do FaturaAcordo">
										<SelectFaturaAcordo name="faturaAcordo" value={this.state.model.faturaAcordo} required={true} onChange={this.handleChangeModel} placeholder="Selecione Nome do FaturaAcordo"/>
									</FormItem>
									<FormItem md={4} label="VlBaixa">
										<InputNumberFormat name="vlBaixa" value={this.state.model.vlBaixa} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} prefix="R$ " required={true} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={4} label="VlMulta">
										<InputNumberFormat name="vlMulta" value={this.state.model.vlMulta} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} prefix="R$ " required={true} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={4} label="VlJuros">
										<InputNumberFormat name="vlJuros" value={this.state.model.vlJuros} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} prefix="R$ " required={true} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={4} label="VlTaxa">
										<InputNumberFormat name="vlTaxa" value={this.state.model.vlTaxa} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} prefix="R$ " required={true} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={4} label="DtBaixa">
										<InputDate name="dtBaixa" value={this.state.model.dtBaixa} required={true} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={6} label="CdChaveBaixa">
										<InputText name="cdChaveBaixa" value={this.state.model.cdChaveBaixa} maxLength={100} required={true} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={2} label="Estorno?">
										<BtnRadioGroupSimNao name="flEstorno" value={this.state.model.flEstorno} required={true} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={12} label="TxBaixa">
										<TextArea name="txBaixa" value={this.state.model.txBaixa} required={true} onChange={this.handleChangeModel}/>
									</FormItem>
								</Row>
							</PanelBody>
							<PanelFooter>
								<FormButtons onClose={this.handleClose} />
							</PanelFooter>
						</Panel>
					</Form>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <FormFaturaCobrancaBaixa {...props} navigate={navigate} params={params} location={location}/>
}
export default With