import Http from "../controllers/Http";

export default class PluginDadoValorService {

	static urlBase = "/pluginDadoValor/";

	static getForCombo(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(PluginDadoValorService.urlBase + 'forCombo', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static list(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(PluginDadoValorService.urlBase + 'list', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneById(id, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.get(PluginDadoValorService.urlBase + id)
			.then(fnSucess)
			.catch(fnError);
	}

	static create(data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(PluginDadoValorService.urlBase, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static update(id, data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.put(PluginDadoValorService.urlBase + id, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static delete(id, fnSucess = () => { }, fnError = () => { }) {
		if(Number.isInteger(id)){
			Http.getInstanceAppJson()
				.delete(PluginDadoValorService.urlBase + id)
				.then(fnSucess)
				.catch(fnError);
		}else{
			Http.getInstanceAppJson()
			.post(PluginDadoValorService.urlBase + 'delete', id)
			.then(fnSucess)
			.catch(fnError);
		}
	}

	static save(data, fnSucess = () => { }, fnError = () => { }) {
		if (data.idPluginDadoValor) {
			this.update(data.idPluginDadoValor, data, fnSucess, fnError);
		} else {
			this.create(data, fnSucess, fnError);
		}
	}

	static getAllByPlugin(plugin, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(PluginDadoValorService.urlBase + 'plugin', plugin)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByPlugin(plugin, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(PluginDadoValorService.urlBase + 'plugin/' + plugin.idPlugin)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByDadoValor(dadoValor, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(PluginDadoValorService.urlBase + 'dadoValor', dadoValor)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByDadoValor(dadoValor, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(PluginDadoValorService.urlBase + 'dadoValor/' + dadoValor.idDadoValor)
			.then(fnSucess)
			.catch(fnError);
	}
}