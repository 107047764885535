import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BaseComponent } from "../../siht/base";
import { FormItem } from "../../siht/components";
import InputPessoa from "../InputDB/InputPessoa";
import TipoPessoaService from "../../services/TipoPessoaService";

class FormItemPessoaByCdTipoPessoa extends BaseComponent {

	constructor(props) {
		super(props);
		this.init = this.init.bind(this);
		this.handleChangePessoa = this.handleChangePessoa.bind(this);
		this.state.tipoPessoa = {};
	}

	componentDidMount() {
		super.componentDidMount();
		this.init();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init() {
		TipoPessoaService.getOneByCdTipoPessoa(this.props.cdTipoPessoa, (response) => {
			this.setState({ tipoPessoa: response });
		});
	}

	handleChangePessoa(e, v, p) {
		var value = v ? v : { tipoPessoa: this.props.value?.tipoPessoa };
		this.props.onChange(e, value, this.props.name);
	}

	render() {

		var propsFormItem = ["col", "xs", "sm", "md", "lg", "xl", "xxl", "help"];
		var propsItem = ["required", "buttons", "label", "placeholder", "disabled", "opcional"];

		var labelPessoa = this.props.label !== undefined ? this.props.label : "Pessoa";

		return super.render(
			<React.Fragment>
				<FormItem label={labelPessoa} {...(this.getProps(propsFormItem, 1))}>
					<InputPessoa {...(this.getProps(propsItem, 1))} getOptionsWhen={f => this.state.tipoPessoa?.idTipoPessoa !== undefined} filter={{ tipoPessoa: this.state.tipoPessoa }} name="pessoa" value={this.props.value} onChange={this.handleChangePessoa} placeholder={`Selecione o ${labelPessoa}`} />
				</FormItem>
			</React.Fragment>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	return <FormItemPessoaByCdTipoPessoa {...props} navigate={navigate} params={params} />
}
export default With