import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { BaseCrud } from "../../siht/base";
import { FormButtons, FormItem, Row, Panel, PanelBody, PanelFooter, Container, ModalDialog, BtnRadioGroup } from "../../siht/components";
import { Form, BtnRadio, InputDate, InputCheckSwitch, InputText, SelectMultipleDropDown, Option } from "../../siht/elements";
import Util from "../../utils/Util";
import ConfigApp from "../../ConfigApp";
import Permissao from "../../controllers/Permissao";

class FormRelatorioAns002 extends BaseCrud {

    constructor(props) {
        super(props);

        this.init = this.init.bind(this);
		this.handleSuccess = this.handleSuccess.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleChangeModel = this.handleChangeModel.bind(this);
		this.handleSubmitForm = this.handleSubmitForm.bind(this);

        var tzoffset = (new Date()).getTimezoneOffset() * 60000;
        var dtHoje = new Date();
        dtHoje.setDate(dtHoje.getDate());
        dtHoje = new Date(dtHoje - tzoffset).toISOString().slice(0, 10);

        this.state.model = {
            idOrdens: props.idOrdens,
            dtEmissao : dtHoje,
            orientation: "P",
            destination: "I",            
            flAgrupado: true,
            flMarcaDagua: true,
            flIncluirPerfil: true,
            flIncluirOrdem: false,
            flMostrarValor: false,
            flIncluirFotos: true,
            flIncluirPlantas: true,
            flIncluirMemoriaCalculo: true,
            flIncluirImageSatelite: true,
            flIncluirImageSateliteIndividual: true,
            flIncluirMapa: false,

            flProteger: true,
            dsSenhaUsuario : "",
            dsSenhaProprietario : "",
            cdProtecao : ["print","print-highres"].map(v => {return {value : v}})
        };
    }
   
	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
	}

	handleSuccess(e) {
        let urlRelatorio = null;

        if(this.state.model.flAgrupado){
            urlRelatorio = "relatorio/ans-002";
        }else{
            urlRelatorio = "relatorio/ans-003";
        }

        Util.openNewWindow(ConfigApp.getUrlApi() + urlRelatorio, this.state.model);
	}

	handleClose(e) {
		super.handleClose(e, {});
	}

    render() {
        return (
            <Form onSubmit={this.handleSuccess} refParam={f => (this.form = f)} key={this.state.key}>
				<Panel key={this.state.key} title="Impressão do Perfil" onClose={this.handleClose} isModal={this.isModal()} container={{sizing: Container.sizing.xxl, className : ""}} modal={{sizing: this.props.sizing ? this.props.sizing : ModalDialog.sizing.xl}}>
                    <PanelBody>
                        <Row className="row mb-3">
                            <FormItem md={4} label="Destino do Relatório">
                                <BtnRadioGroup name="destination" color={BtnRadioGroup.color.primary} opcional={true} value={this.state.model.destination} onClick={this.handleChangeModel}>
                                    <BtnRadio value="I" label="Tela" />
                                    <BtnRadio value="D" label="Download" />
                                </BtnRadioGroup>
                            </FormItem>
                            <FormItem md={5} label="Disposição da Página">
                                <BtnRadioGroup name="orientation" color={BtnRadioGroup.color.primary} opcional={true} value={this.state.model.orientation} onClick={this.handleChangeModel} disabled={true}>
                                    <BtnRadio value="P" label="Vertical" />
                                    <BtnRadio value="L" label="Horizontal" />
                                </BtnRadioGroup>
                            </FormItem>
                            <FormItem md={3} label="Data de Emissão">
                                <InputDate name="dtEmissao" value={this.state.model.dtEmissao} required={true} onChange={this.handleChangeModel}/>
                            </FormItem>									
                        </Row>
                        <Row className="row px-3 mb-3">
                            <InputCheckSwitch label="Relatório Agrupado" name="flAgrupado" checked={(this.state.model.flAgrupado)} onChange={this.handleChangeModel} />

                            <InputCheckSwitch label="Incluir Perfil" name="flIncluirPerfil" checked={(this.state.model.flIncluirPerfil)} onChange={this.handleChangeModel} />
                            <InputCheckSwitch label="Incluir Fotos" name="flIncluirFotos" checked={(this.state.model.flIncluirFotos)} onChange={this.handleChangeModel} />
                            <InputCheckSwitch label="Incluir Plantas" name="flIncluirPlantas" checked={(this.state.model.flIncluirPlantas)} onChange={this.handleChangeModel} />
                            <InputCheckSwitch label="Incluir Memória de Cálculo" name="flIncluirMemoriaCalculo" checked={(this.state.model.flIncluirMemoriaCalculo)} onChange={this.handleChangeModel} />                            
                            
                            <InputCheckSwitch label="Incluir Imagem de Satélite do Perfis" name="flIncluirImageSatelite" checked={(this.state.model.flIncluirImageSatelite)} onChange={this.handleChangeModel} />
                            <InputCheckSwitch label="Incluir Imagem de Satélite de Cada Perfil" name="flIncluirImageSateliteIndividual" checked={(this.state.model.flIncluirImageSateliteIndividual)} onChange={this.handleChangeModel} />
                            
                            <InputCheckSwitch label="Incluir Ordem" name="flIncluirOrdem" checked={(this.state.model.flIncluirOrdem)} onChange={this.handleChangeModel} />
                            {Permissao.getInstance().getPermissao(76) ?
                                <InputCheckSwitch label="Mostrar valor da ordem?" name="flMostrarValor" show={(this.state.model.flIncluirOrdem)} checked={Boolean(this.state.model.flMostrarValor)} onChange={this.handleChangeModel} />
                                : <></>}

                            {Permissao.getInstance().getPermissao(219) ?
                                <InputCheckSwitch label="Incluir Mapa de Sondagens?" name="flIncluirMapa" checked={Boolean(this.state.model.flIncluirMapa)} onChange={this.handleChangeModel} />
                                : <></>}

                            <InputCheckSwitch label="Proteger arquivo PDF" name="flProteger" checked={(this.state.model.flProteger)} onChange={this.handleChangeModel} />

                            <Row className="row g-3 mb-3"  show={this.state.model.flProteger}>
                                <FormItem md={6} label="Senha do Proprietário" help="Senha para editar o arquivo">
                                    <InputText name="dsSenhaProprietario" value={this.state.model.dsSenhaProprietario} autoFocus={true} maxLength={100} required={false} onChange={this.handleChangeModel}/>
                                </FormItem>

                                <FormItem md={6} label="Liberar Permissões para">
                                    <SelectMultipleDropDown name="cdProtecao" required={true} value={this.state.model.cdProtecao} onChange={this.handleChangeModel} rowCols={2}>
                                        <Option value="copy" label="Copiar"/>                                    
                                        <Option value="extract" label="Extrair"/>
                                        <Option value="modify" label="Modificar"/>
                                        <Option value="print" label="Imprimir"/>
                                        <Option value="print-highres" label="Imprimir em Alta Resolução"/>
                                        <Option value="annot-forms" label="Anotações em Formulário"/>
                                        <Option value="fill-forms" label="Preencher Formulários"/>
                                        <Option value="assemble" label="Montar Documento"/>
                                    </SelectMultipleDropDown>
                                </FormItem>

                                <FormItem md={6} label="Senha do Usuário" help="Senha para abrir o arquivo">
                                    <InputText name="dsSenhaUsuario" value={this.state.model.dsSenhaUsuario} autoFocus={true} maxLength={100} required={false} onChange={this.handleChangeModel}/>
                                </FormItem>
                            </Row>
                            
                        </Row>
                    </PanelBody>
                    <PanelFooter>
                        <FormButtons onClose={this.handleClose} label={['Imprimir','Fechar']}/>
                    </PanelFooter>
                </Panel>
            </Form>
        );
    }
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <FormRelatorioAns002 {...props} navigate={navigate} params={params} location={location}/>
}
export default With