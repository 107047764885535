
import FormTipoRecorrencia from "./FormTipoRecorrencia.js";
import ListTipoRecorrencia from "./ListTipoRecorrencia.js";
import { Permissao } from "../../controllers";
import { Util } from "../../utils";
import { DadoValorService, TipoRecorrenciaService } from "../../services";

export default class Config {

	static getUrlForm() {
		return "/CadTipoRecorrencia";
	}

	static getUrlList() {
		return "/LstTipoRecorrencia";
	}

	static getComponentForm() {
		return FormTipoRecorrencia;
	}

	static getPermissionForm() {
		return Permissao.getInstance().getPermissoes([188]);
	}

	static getPermissionList() {
		return Permissao.getInstance().getPermissoes([188]);
	}

	static getComponentList() {
		return ListTipoRecorrencia;
	}

	static getService() {
		return TipoRecorrenciaService;
	}

	static getKeyId() {
		return "idTipoRecorrencia";
	}

	static getKeyDescription() {
		return "nmTipoRecorrencia";
	}

	static setDefaultValues(state , fnCallBack = () => {}, className = undefined) {
		className = className === undefined ? "FormTipoRecorrencia" : className;
		let keyNameScreen = Util.toToUpperTextCamelCase(className, "_") + ".";
		var prefix = "CONFIGURACAO.";
		DadoValorService.getValues(`${prefix}${keyNameScreen}`, (response)=> {
			Util.setValueObjectCamelCase(state.model, response, keyNameScreen, prefix);
			fnCallBack();
		}, fnCallBack);
	}
}