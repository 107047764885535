import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { BaseCrud } from "../../siht/base";
import { FormButtons, FormItem, Row, Panel, PanelBody, PanelFooter, Container, ModalDialog, BtnRadioGroup } from "../../siht/components";
import { Form, InputCheckSwitch, BtnRadio } from "../../siht/elements";
import Util from "../../utils/Util";
import ConfigApp from "../../ConfigApp";

class FormRelatorioFatura001 extends BaseCrud {

    constructor(props) {
        super(props);

        this.init = this.init.bind(this);
		this.handleSuccess = this.handleSuccess.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleChangeModel = this.handleChangeModel.bind(this);
		this.handleSubmitForm = this.handleSubmitForm.bind(this);

        this.urlRelatorio = "relatorio/fatura-001";

        this.state.model = {
            idFaturas: props.idFaturas,
            orientation: "P",
            destination: "I",            
            flMarcaDagua: true,            
            flOrdens : false
        };
    }
   
	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
	}

	handleSuccess(e) {
        Util.openNewWindow(ConfigApp.getUrlApi() + this.urlRelatorio, this.state.model);
	}

	handleClose(e) {
		super.handleClose(e, {});
	}

    render() {
        return (
            <Form onSubmit={this.handleSuccess} refParam={f => (this.form = f)} key={this.state.key}>
				<Panel key={this.state.key} title="Impressão da Fatura" onClose={this.handleClose} isModal={this.isModal()} container={{sizing: Container.sizing.xxl, className : ""}} modal={{sizing: this.props.sizing ? this.props.sizing : ModalDialog.sizing.xl}}>
                    <PanelBody>
                        <Row className="row mb-3">
                            <FormItem md={6} label="Disposição da Página">                                
                                <BtnRadioGroup name="orientation" color={BtnRadioGroup.color.primary} opcional={true} value={this.state.model.orientation} onClick={this.handleChangeModel}>
                                    <BtnRadio value="P" label="Vertical" />
                                    <BtnRadio value="L" label="Horizontal" />
                                </BtnRadioGroup>
                            </FormItem>
                            <FormItem md={6} label="Destino do Relatório">
                                <BtnRadioGroup name="destination" color={BtnRadioGroup.color.primary} opcional={true} value={this.state.model.destination} onClick={this.handleChangeModel}>
                                    <BtnRadio value="I" label="Tela" />
                                    <BtnRadio value="D" label="Download" />
                                </BtnRadioGroup>
                            </FormItem>
                        </Row>
                        <Row className="row px-3 mb-3">
                            <InputCheckSwitch label="Incluir Ordens?" name="flOrdens" checked={Boolean(this.state.model.flOrdens)} onChange={this.handleChangeModel} />
                            <InputCheckSwitch label="Mostrar marca d'água" name="flMarcaDagua" checked={Boolean(this.state.model.flMarcaDagua)} onChange={this.handleChangeModel} />                            
                        </Row>
                    </PanelBody>
                    <PanelFooter>
                        <FormButtons onClose={this.handleClose} label={['Imprimir','Fechar']}/>
                    </PanelFooter>
                </Panel>
            </Form>
        );
    }
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <FormRelatorioFatura001 {...props} navigate={navigate} params={params} location={location}/>
}
export default With