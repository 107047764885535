import { toast } from "react-toastify";
import Util from "../../utils/Util";
import TextArea from "./TextArea";
import Field from "./Field";
import { InputGroup, InputGroupButton } from "../components";
import { IconCopy } from "../../icons";
import BtnButton from "./BtnButton";

export default class InputCopy extends Field {

    constructor(props) {
        super(props);        
        this.handleClickCopy = this.handleClickCopy.bind(this);

        this.onClickCopy = this.props.onClickCopy !== undefined ? this.props.onClickCopy : null;
    }    

    handleClickCopy(e) {
        if (Util.copyClipboard(this.props.value)) {
            toast.success('Dados copiado para a área de transferência!');
        } else {
            toast.error('Erro ao copiar, por favor copie manualmente!');
        }
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    render() {

        const props = Object.assign({}, this.props);
        delete props.onClickCopy;

        return super.render(
            <InputGroup>
                <TextArea {...props}/>
                <InputGroupButton>
                    <BtnButton color={BtnButton.color.outlinePrimary} onClick={this.onClickCopy ? this.onClickCopy : this.handleClickCopy}>
                        <IconCopy/> Copiar
                    </BtnButton>
                </InputGroupButton>
            </InputGroup>
        );
    }
}