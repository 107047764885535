import Http from "../controllers/Http";

export default class GrupoProdutoTipoOrdemService {

	static urlBase = "/grupoProdutoTipoOrdem/";

	static getForCombo(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(GrupoProdutoTipoOrdemService.urlBase + 'forCombo', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static list(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(GrupoProdutoTipoOrdemService.urlBase + 'list', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneById(id, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.get(GrupoProdutoTipoOrdemService.urlBase + id)
			.then(fnSucess)
			.catch(fnError);
	}

	static create(data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(GrupoProdutoTipoOrdemService.urlBase, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static update(id, data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.put(GrupoProdutoTipoOrdemService.urlBase + id, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static delete(id, fnSucess = () => { }, fnError = () => { }) {
		if(Number.isInteger(id)){
			Http.getInstanceAppJson()
				.delete(GrupoProdutoTipoOrdemService.urlBase + id)
				.then(fnSucess)
				.catch(fnError);
		}else{
			Http.getInstanceAppJson()
			.post(GrupoProdutoTipoOrdemService.urlBase + 'delete', id)
			.then(fnSucess)
			.catch(fnError);
		}
	}

	static save(data, fnSucess = () => { }, fnError = () => { }) {
		if (data.idGrupoProdutoTipoOrdem) {
			this.update(data.idGrupoProdutoTipoOrdem, data, fnSucess, fnError);
		} else {
			this.create(data, fnSucess, fnError);
		}
	}

	static getAllByGrupoProduto(grupoProduto, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(GrupoProdutoTipoOrdemService.urlBase + 'grupoProduto', grupoProduto)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByGrupoProduto(grupoProduto, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(GrupoProdutoTipoOrdemService.urlBase + 'grupoProduto/' + grupoProduto.idGrupoProduto)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByTipoOrdem(tipoOrdem, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(GrupoProdutoTipoOrdemService.urlBase + 'tipoOrdem', tipoOrdem)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByTipoOrdem(tipoOrdem, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(GrupoProdutoTipoOrdemService.urlBase + 'tipoOrdem/' + tipoOrdem.idTipoOrdem)
			.then(fnSucess)
			.catch(fnError);
	}
}