//#NO-CHANGE-FILE#

import React from "react";
import { useNavigate, useLocation, useParams } from "react-router";
import Config from "./Config";
import { BadgeSimNao, BtnView, StatusCustom } from "../../components/Custom";
import { BaseList } from "../../siht/base";
import { DataGrid, DataGridButtons, DataGridColumn, Panel, Container, Row, InputGroup, InputGroupText, InputGroupButton, PanelFooter, Badge } from "../../siht/components";
import { Button, Div, Form, InputSearch, BtnSubmit, BtnButton, Span } from "../../siht/elements";
import Util from "../../utils/Util";
import { HandThumbsDown as IconHandThumbsDown, HandThumbsUp as IconHandThumbsUp } from "react-bootstrap-icons";
import { Modal } from "../../siht/controller";
import { IconClear, IconSearch } from "../../icons";

class ListFaturaAcordo extends BaseList {

	constructor(props) {
		super(props);
		this.getItens = this.getItens.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleNew = this.handleNew.bind(this);
		this.handleEdit = this.handleEdit.bind(this);
		this.handleCopy = this.handleCopy.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.handleMore = this.handleMore.bind(this);
		this.onSubmitFilter = this.onSubmitFilter.bind(this);
		this.handleClear = this.handleClear.bind(this);
		this.handleFormSuccess = this.handleFormSuccess.bind(this);
		this.handleFormClose = this.handleFormClose.bind(this);
		this.handleClickEfetivar = this.handleClickEfetivar.bind(this);
		this.handleClickEstornar = this.handleClickEstornar.bind(this);
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		super.init(Config);
	}

	getItens(more){
		super.getItens(Config.getService().list, false, more);
	}

	handleNew(e) {
		super.handleNew(e, Config);
	}

	handleEdit(e, item) {
		super.handleEdit(e, Config, item);
	}

	handleDelete(e, item) {
		super.handleDelete(e, Config, item);
	}

	handleCopy(e, item) {
		super.handleCopy(e, Config, item);
	}

	handleClickEfetivar(e, faturaAcordo) {
		this.props.navigate('/CadFaturar', { state : { ordens : [], pessoa : faturaAcordo.pessoa, unidade : faturaAcordo.unidade, faturaAcordo : faturaAcordo }});
	}

	handleClickEstornar(e, faturaAcordo) {
		Modal.confirm("Confirmação", "Deseja realmente estornar o acordo?", false, Modal.style.warning, ()=>{
			Config.getService().estornar(faturaAcordo, ()=>{
				this.getItens();
			});
		});
		
	}

	getFormEmbedded(){
		return super.getFormEmbedded(Config);
	}

	render() {
		return super.render(
			<Panel title="Lista de Acordo(s)" isModal={this.isModal()} container={{sizing: Container.sizing.fluid, className : ""}}>

				<Form onSubmit={this.onSubmitFilter}>
					<Row className="mt-1 mb-2 g-3">
						<InputGroup className="flex-nowrap" sizing={InputGroup.sizing.default}>
							<InputGroupText><IconSearch/></InputGroupText>
							<InputSearch autoComplete="off" autoFocus placeholder="Digite sua busca" name="search" value={this.state.filter.search} required={false} opcional={true} onChange={(e,v,n) => this.handleChange(e,v,n, this.state.filter)}/>
							<InputGroupButton>
								<BtnSubmit title="Filtrar"> Buscar </BtnSubmit>
								<BtnButton title="Limpar Pesquisa" color={BtnButton.color.outlineDanger} onClick={this.handleClear}> <IconClear/> </BtnButton>
							</InputGroupButton>
						</InputGroup>
					</Row>
				</Form>

				<DataGrid onDoubleClickRow={this.handleEdit} responsive={true} data={this.state.data} search={false} isDisabled={i => false} sizing={DataGrid.sizing.sm} select={true} selecteds={this.state.selecteds} onSelect={(selecteds)=>{this.setState({selecteds : selecteds})}} getItemValue={i=>i.idFaturaAcordo} onMore={this.handleMore}>
					<DataGridColumn label="Código" field={i => i.idFaturaAcordo} className="text-center"/>
					<DataGridColumn md={3} label="Cliente" field={i => <>
                        <Span className="fw-bold">{i.pessoa?.nmPessoa}</Span> <br/>
                        <StatusCustom color={i.pessoa?.tipoPessoa}> PESSOA {i.pessoa?.tipoPessoa?.nmTipoPessoa} </StatusCustom>
                        <Div className="fw-bold fs-7" show={i.pessoa?.tipoPessoa?.cdTipoPessoa === "F"}>{Util.formataCPF(i.pessoa?.nrCpf)}</Div>
                        <Div className="fw-bold fs-7" show={i.pessoa?.tipoPessoa?.cdTipoPessoa === "J"}>{Util.formataCnpj(i.pessoa?.nrCnpj)}</Div>
                        </>}
                    className="text-start"/>
					<DataGridColumn col={2} label="Unidade" field={i => <>
						<Span className="fw-bold">{i.unidade?.empresa?.nmEmpresa}</Span> <br/>
						{i.unidade?.nmUnidade}
					</>} className="text-start"/>
					<DataGridColumn col={2} label="Usuário" field={i => i.usuario?.nmUsuario} className="text-start"/>
					<DataGridColumn col={2} label="Status" field={i => <StatusCustom className="w-100" color={i.statusFaturaAcordo}> {i.statusFaturaAcordo?.nmStatusFaturaAcordo} </StatusCustom>} className="text-center"/>
					<DataGridColumn col={1} label="Validade" field={i => <>
						{Util.date2Br(i.dtValidade)}
						{i.flExpirado ? <Badge color={Badge.color.danger}>Expirado</Badge> : <></>}
					</>} className="text-center"/>
					<DataGridColumn col={1} label="Valor" field={i => Util.floatToReais(i.vlAcordo)} className="text-center"/>
					<DataGridColumn col={1} label="Efetivado?" field={i => <BadgeSimNao value={i.flEfetivado}/>} className="text-center"/>
						
					<DataGridButtons>
						<BtnView sizing={Button.sizing.default} onClick={this.handleEdit}/>
						<BtnButton disabled={i => i.flExpirado || !i.statusFaturaAcordo.flPodeEfetivar} color={BtnButton.color.outlineSuccess} sizing={Button.sizing.default} onClick={this.handleClickEfetivar}>
							<IconHandThumbsUp/>
						</BtnButton>
						<BtnButton disabled={i => i.flEfetivado || !i.statusFaturaAcordo.flPodeEstornar} color={BtnButton.color.outlineDanger} sizing={Button.sizing.default} onClick={this.handleClickEstornar}>
							<IconHandThumbsDown/>
						</BtnButton>
					</DataGridButtons>
				</DataGrid>

				<PanelFooter className="text-center py-2" show={this.state.data && this.state.data?.length > 0}>
					<BtnButton onClick={this.handleMore} disabled={!this.state.hasMore}> Buscar Mais Itens </BtnButton>
				</PanelFooter>
			</Panel>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <ListFaturaAcordo {...props} navigate={navigate} params={params} location={location}/>
}
export default With