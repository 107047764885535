import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BaseCrud } from "../../siht/base";
import { Col, Container, FormButtons, FormItem, GoogleMaps, GoogleMapsMarker, InputGroup, InputGroupButton, Panel, PanelBody, PanelFooter, Row } from "../../siht/components";
import { InputText, Form, BtnButton } from "../../siht/elements";
import { GeoFill as IconGeoFill, DashLg as IconDashLg, PlusLg as IconPlusLg } from "react-bootstrap-icons";

class FormMapOneMarker extends BaseCrud {

	constructor(props) {
		super(props);
		this.init = this.init.bind(this);
		this.handleSuccess = this.handleSuccess.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleChangeModel = this.handleChangeModel.bind(this);
		this.handleSubmitForm = this.handleSubmitForm.bind(this);
		this.handleClickLocation = this.handleClickLocation.bind(this);
		this.handleChangeMap = this.handleChangeMap.bind(this);
		this.handleChangeZoom = this.handleChangeZoom.bind(this);
		this.handleClickZoomIn = this.handleClickZoomIn.bind(this);
		this.handleClickZoomOut = this.handleClickZoomOut.bind(this);

		this.state.model = {
			lat: this.props.lat || "-15.802416514707035",
			lng: this.props.lng || "-47.90776318710937",
			zoom: this.props.zoom || 7
		};
	}

	handleClickLocation() {
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition((position) => {
				this.setState(state => {
					state.model.lng = position.coords.longitude
					state.model.lat = position.coords.latitude
					return state;
				});
			});
		} else {
			alert("Geolocation is not supported by this browser.");
		}
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init() {
		//super.init(Config);
	}

	handleSuccess(e) {
		if (this.props.handleSuccess) {
			this.props.handleSuccess(e, this.state.model);
		}
	}

	handleClose(e) {
		super.handleClose(e);
	}

	handleChangeMap(e, map, marker, position) {
		this.setState(state => {
			state.model.lng = position.lng
			state.model.lat = position.lat
			return state;
		});
	}

	handleChangeZoom(e, map, zoom) {
		this.setState(state => {
			state.model.zoom = zoom
			return state;
		});
	}

	handleClickZoomIn() {

		if(!(this.state.model.zoom < 22)){
			return;
		}

		this.setState(state => {
			state.model.zoom = state.model.zoom + 1
			return state;
		});
	}

	handleClickZoomOut() {
		if(!(this.state.model.zoom > 0)){
			return;
		}

		this.setState(state => {
			state.model.zoom = state.model.zoom - 1
			return state;
		});
	}

	render() {

		var readOnly = this.props.readOnly !== undefined ? this.props.readOnly : false;

		return (
			<Container sizing={Container.sizing.xxl} className="mt-3 p-2 my-2" key={this.state.key}>
				<Form onSubmit={this.handleSuccess} refParam={f => (this.form = f)}>
					<Panel title="Localização por Mapa" onClose={this.handleClose}>
						<PanelBody>
							<Row className="g-3 pb-2 px-2">
								<FormItem md={3} label="Latitude">
									<InputText name="lat" value={this.state.model.lat} required={true} onChange={this.handleChangeModel} pattern="^(-?\d+(\.\d+)?)$" readOnly={readOnly}/>
								</FormItem>
								<FormItem md={3} label="Longitude">
									<InputText name="lng" value={this.state.model.lng} required={true} onChange={this.handleChangeModel} pattern="^(-?\d+(\.\d+)?)$" readOnly={readOnly}/>
								</FormItem>
								<FormItem md={3} label="Zoom">
									<InputGroup opcional={true}>
										<InputText className="text-center" name="zoom" value={this.state.model.zoom} min={1} max={22} readOnly={true}  />
										<InputGroupButton>
											<BtnButton color={BtnButton.color.outlinePrimary} onClick={this.handleClickZoomIn} disabled={parseInt(this.state.model.zoom) === 22}> <IconPlusLg/> </BtnButton>
											<BtnButton color={BtnButton.color.outlinePrimary} onClick={this.handleClickZoomOut} disabled={parseInt(this.state.model.zoom) === 1}> <IconDashLg/> </BtnButton>
										</InputGroupButton>
									</InputGroup>
								</FormItem>
								<Col sizing={3} className="d-flex align-items-end">
									<BtnButton color={BtnButton.color.primary} onClick={this.handleClickLocation} className="w-100" disabled={readOnly}>
										<IconGeoFill /> Usar Minha Localização
									</BtnButton>
								</Col>
								<GoogleMaps height="400px" apiKey={this.props.apiKey} lng={this.state.model.lng} lat={this.state.model.lat} zoom={this.state.model.zoom} onZoomChange={this.handleChangeZoom}>
									<GoogleMapsMarker lng={this.state.model.lng} lat={this.state.model.lat} draggable={!readOnly} ondragEnd={this.handleChangeMap} />
								</GoogleMaps>
							</Row>
						</PanelBody>
						<PanelFooter>
							<FormButtons onClose={this.handleClose} disabled={[readOnly, false]}/>
						</PanelFooter>
					</Panel>
				</Form>
			</Container>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	return <FormMapOneMarker {...props} navigate={navigate} params={params} />
}
export default With