
import FormUsuarioGrupoUnidade from "./FormUsuarioGrupoUnidade.js";
import ListUsuarioGrupoUnidade from "./ListUsuarioGrupoUnidade.js";
import { Permissao } from "../../controllers";
import { Util } from "../../utils";
import { DadoValorService, UsuarioGrupoUnidadeService } from "../../services";

export default class Config {

	static getUrlForm() {
		return "/CadUsuarioGrupoUnidade";
	}

	static getUrlList() {
		return "/LstUsuarioGrupoUnidade";
	}

	static getComponentForm() {
		return FormUsuarioGrupoUnidade;
	}

	static getPermissionForm() {
		return Permissao.getInstance().getPermissoes([6]);
	}

	static getPermissionList() {
		return Permissao.getInstance().getPermissoes([6]);
	}

	static getComponentList() {
		return ListUsuarioGrupoUnidade;
	}

	static getService() {
		return UsuarioGrupoUnidadeService;
	}

	static getKeyId() {
		return "idUsuarioGrupoUnidade";
	}

	static getKeyDescription() {
		return "nmUsuarioGrupoUnidade";
	}

	static setDefaultValues(state , fnCallBack = () => {}, className = undefined) {
		className = className === undefined ? "FormUsuarioGrupoUnidade" : className;
		let keyNameScreen = Util.toToUpperTextCamelCase(className, "_") + ".";
		var prefix = "CONFIGURACAO.";
		DadoValorService.getValues(`${prefix}${keyNameScreen}`, (response)=> {
			Util.setValueObjectCamelCase(state.model, response, keyNameScreen, prefix);
			fnCallBack();
		}, fnCallBack);
	}
}