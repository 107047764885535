//#NO-CHANGE-FILE#

import Config from "./Config";
import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import SelectTipoEventoSalario from "../../components/SelectDB/SelectTipoEventoSalario";
import SelectTipoPagamentoSalario from "../../components/SelectDB/SelectTipoPagamentoSalario";
import { BaseCrud } from "../../siht/base";
import { FormButtons, FormItem, Row, Panel, PanelBody, PanelFooter, Container, ModalDialog } from "../../siht/components";
import { InputText, InputNumberFormat, InputDate, Form } from "../../siht/elements";
import { BtnRadioGroupSimNao } from "../../components/Custom";

class FormFuncionarioSalario extends BaseCrud {

	constructor(props) {
		super(props);
		this.init = this.init.bind(this);
		this.initModel = this.initModel.bind(this);
		this.handleSuccess = this.handleSuccess.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleChangeModel = this.handleChangeModel.bind(this);
		this.handleSubmitForm = this.handleSubmitForm.bind(this);
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init() {
		super.init(Config);
	}

	initModel() {
		return {
			idFuncionarioSalario: null,
			funcionario: this.props.parent !== undefined ? this.props.parent : {},
			usuario: null,
			tipoEventoSalario: null,
			tipoPagamentoSalario: null,
			vlSalario: 0,
			vlReajuste: 0,
			pcReajuste: "",
			dtSalario: "",
			flAtual: true
		}
	}

	handleSuccess(e) {
		super.handleSuccess(e, Config);
	}

	handleClose(e) {
		super.handleClose(e, Config);
	}

	render() {
		return super.render(

			<Form onSubmit={this.handleSuccess} refParam={f => (this.form = f)} key={this.state.key}>
				<Panel key={this.state.key} title="Cadastro de FuncionarioSalario" localOpen={this.props.localOpen} onClose={this.handleClose} isModal={this.isModal()} container={{ sizing: Container.sizing.xxl, className: "" }} modal={{ sizing: this.props.sizing ? this.props.sizing : ModalDialog.sizing.xl }}>
					<PanelBody>
						<Row className="g-3 pb-2 px-2">
							<FormItem md={6} label="Código">
								<InputText name="idFuncionarioSalario" value={this.state.model.idFuncionarioSalario} readOnly={true} className="readonly-field" required={false} onChange={this.handleChangeModel} />
							</FormItem>
							<FormItem md={12} label="Evento">
								<SelectTipoEventoSalario name="tipoEventoSalario" value={this.state.model.tipoEventoSalario} required={true} onChange={this.handleChangeModel} placeholder="Selecione TipoEventoSalario" />
							</FormItem>
							<FormItem md={12} label="Tipo Pagamento">
								<SelectTipoPagamentoSalario name="tipoPagamentoSalario" value={this.state.model.tipoPagamentoSalario} required={true} onChange={this.handleChangeModel} placeholder="Selecione Nome do TipoPagamentoSalario" />
							</FormItem>
							<FormItem md={4} label="VlSalario">
								<InputNumberFormat name="vlSalario" value={this.state.model.vlSalario} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} prefix="R$ " required={true} onChange={this.handleChangeModel} />
							</FormItem>
							<FormItem md={4} label="VlReajuste">
								<InputNumberFormat name="vlReajuste" value={this.state.model.vlReajuste} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} prefix="R$ " required={true} onChange={this.handleChangeModel} />
							</FormItem>
							<FormItem md={6} label="PcReajuste">
								<InputText name="pcReajuste" value={this.state.model.pcReajuste} maxLength={100} required={true} onChange={this.handleChangeModel} />
							</FormItem>
							<FormItem md={4} label="DtSalario">
								<InputDate name="dtSalario" value={this.state.model.dtSalario} required={true} onChange={this.handleChangeModel} />
							</FormItem>
							<FormItem md={2} label="FlAtual">
								<BtnRadioGroupSimNao name="flAtual" value={this.state.model.flAtual} required={true} onChange={this.handleChangeModel} />
							</FormItem>
						</Row>
					</PanelBody>
					<PanelFooter>
						<FormButtons onClose={this.handleClose} />
					</PanelFooter>
				</Panel>
			</Form>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <FormFuncionarioSalario {...props} navigate={navigate} params={params} location={location} />
}
export default With