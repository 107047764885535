import Version from "./Version";

export default class ConfigApp {

    static instance = null;
    static version = Version.version;

    static timeValidateLogged = 60; //segundos
    static timeRefreshToken = 60 * 15; //segundos
    static timeGetNotification = 60; //segundos
    static timeGetVersao = 60 * 15; //segundos
    static timeListUsuarioOnline = 60; //segundos
    static timeListContatosAtivos = 60; //segundos
    static timeGetMural = (60 * 5); //5 minutos

    clientIdGoogleAuth =  null; //608595942001-e95ffhoqr0364vp4celm6r9aoaj04fqd.apps.googleusercontent.com //https://console.cloud.google.com/apis/credentials?hl=pt-br
    clientIdGoogleMaps = null; //AIzaSyBuEVq0KOoIH2pvTNl4dgRu0cjjmDsc-AA //https://staticmapmaker.com/google/
    logoSistema = null;

    static default = {
        layoutFormControl: "grid", //floating || horizontal || grid
        placeHolderDefaultLabelValue: true,
        opcionalLabelDefaultNotRequired: true
    };

    static getInstance() {
        if (this.instance === null) {
            this.instance = new ConfigApp();
        }
        return this.instance;
    }

    static getAmbient() {
        var ambient = "";
        if (process.env.REACT_APP_ENV) {
            ambient = process.env.REACT_APP_ENV;
        } else {
            ambient = process.env.NODE_ENV;
        }
        return ambient.trim();
    }

    static getUrlApi() {
        switch (this.getAmbient()) {
            case "development":
                return "//localhost/Api/";
            case "homologation":
                return "//localhost/Api/";
            case "production":
                return "//"+ window.location.host + "/Api/";
            default:
                return null;
        }
    }

    static getUrlArquivo(ambient = null) {

        if(!ambient){
            ambient = this.getAmbient();
        }

        switch (ambient) {
            case "development":
                return "//localhost/Api/";
            case "homologation":
                return "//localhost/Api/";
            case "production":
                return "//"+ window.location.host + "/Api/";
            default:
                return null;
        }
    }
}