
import FormUnidadeMedida from "./FormUnidadeMedida.js";
import ListUnidadeMedida from "./ListUnidadeMedida.js";
import { Permissao } from "../../controllers";
import { Util } from "../../utils";
import { DadoValorService, UnidadeMedidaService } from "../../services";

export default class Config {

	static getUrlForm() {
		return "/CadUnidadeMedida";
	}

	static getUrlList() {
		return "/LstUnidadeMedida";
	}

	static getComponentForm() {
		return FormUnidadeMedida;
	}

	static getPermissionForm() {
		return Permissao.getInstance().getPermissoes([32]);
	}

	static getPermissionList() {
		return Permissao.getInstance().getPermissoes([32]);
	}

	static getComponentList() {
		return ListUnidadeMedida;
	}

	static getService() {
		return UnidadeMedidaService;
	}

	static getKeyId() {
		return "idUnidadeMedida";
	}

	static getKeyDescription() {
		return "nmUnidadeMedida";
	}

	static setDefaultValues(state , fnCallBack = () => {}, className = undefined) {
		className = className === undefined ? "FormUnidadeMedida" : className;
		let keyNameScreen = Util.toToUpperTextCamelCase(className, "_") + ".";
		var prefix = "CONFIGURACAO.";
		DadoValorService.getValues(`${prefix}${keyNameScreen}`, (response)=> {
			Util.setValueObjectCamelCase(state.model, response, keyNameScreen, prefix);
			fnCallBack();
		}, fnCallBack);
	}
}