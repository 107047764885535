import { BaseComponent } from "../siht/base";

export default class IconToogles extends BaseComponent {

    render() {

        if(this.props.show !== undefined && !Boolean(this.props.show)){
            return (<></>);
        }

        const props = Object.assign({}, this.props);
        props.xmlns = "http://www.w3.org/2000/svg";
        props.width = props.width === undefined ? 16 : props.width;
        props.height = props.height === undefined ? 16 : props.height;
        props.fill = "currentColor";
        props.className = `bi bi-toggles2 ${props.className !== undefined ? props.className : ""}`;
        props.viewBox=`0 0 16 16`

        return (
            <svg {...props}>
                <path d="M9.465 10H12a2 2 0 1 1 0 4H9.465c.34-.588.535-1.271.535-2 0-.729-.195-1.412-.535-2"/>
                <path d="M6 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6m0 1a4 4 0 1 1 0-8 4 4 0 0 1 0 8m.535-10a3.975 3.975 0 0 1-.409-1H4a1 1 0 0 1 0-2h2.126c.091-.355.23-.69.41-1H4a2 2 0 1 0 0 4z"/>
                <path d="M14 4a4 4 0 1 1-8 0 4 4 0 0 1 8 0"/>
            </svg>
        );
    }
}