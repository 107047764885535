
import FormPessoaLocalizacao from "./FormPessoaLocalizacao.js";
import ListPessoaLocalizacao from "./ListPessoaLocalizacao.js";
import { Permissao } from "../../controllers";
import { Util } from "../../utils";
import { DadoValorService, PessoaLocalizacaoService } from "../../services";

export default class Config {

	static getUrlForm() {
		return "/CadPessoaLocalizacao";
	}

	static getUrlList() {
		return "/LstPessoaLocalizacao";
	}

	static getComponentForm() {
		return FormPessoaLocalizacao;
	}

	static getPermissionForm() {
		return Permissao.getInstance().getPermissoes([182]);
	}

	static getPermissionList() {
		return Permissao.getInstance().getPermissoes([182]);
	}

	static getComponentList() {
		return ListPessoaLocalizacao;
	}

	static getService() {
		return PessoaLocalizacaoService;
	}

	static getKeyId() {
		return "idPessoaLocalizacao";
	}

	static getKeyDescription() {
		return "nmPessoaLocalizacao";
	}

	static setDefaultValues(state , fnCallBack = () => {}, className = undefined) {
		className = className === undefined ? "FormPessoaLocalizacao" : className;
		let keyNameScreen = Util.toToUpperTextCamelCase(className, "_") + ".";
		var prefix = "CONFIGURACAO.";
		DadoValorService.getValues(`${prefix}${keyNameScreen}`, (response)=> {
			Util.setValueObjectCamelCase(state.model, response, keyNameScreen, prefix);
			fnCallBack();
		}, fnCallBack);
	}
}