import ManyToManyCheckTipoOrdem from "../../components/ManyToManyCheck/ManyToManyCheckTipoOrdem";
import GrupoProdutoTipoOrdemService from "../../services/GrupoProdutoTipoOrdemService";

export default class ManyToManyCheckGrupoProdutoTipoOrdem extends ManyToManyCheckTipoOrdem {

    constructor(props) {
        super(props);
        
        this.onChangeAddItem = this.onChangeAddItem.bind(this);
		this.onChangeDelItem = this.onChangeDelItem.bind(this);
		this.onChangeAddAll = this.onChangeAddAll.bind(this);
		this.onChangeDelAll = this.onChangeDelAll.bind(this);
        this.getSelecteds = this.getSelecteds.bind(this);

        this.state.selectedsOriginal = [];
    }

    componentDidMount() {
        super.componentDidMount();
        this.getSelecteds();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    componentDidUpdate(nextProps) {
        if (nextProps.parent !== undefined && this.props.parent !== undefined) {
            if (!this.objEqual(nextProps.parent, this.props.parent)) {
                this.getSelecteds();
            }
        }
    }
	

	getColumns(){
		return 1;
	}

    getSelecteds(){
        if(this.props?.parent?.idGrupoProduto  || (Array.isArray(this.props?.parent) && this.props?.parent.length > 0)){
            GrupoProdutoTipoOrdemService.getAllByGrupoProduto(this.props.parent, (response) => {
                let selecteds = response.flatMap(i => i.tipoOrdem);
				let noSelecteds = [];

				if(Array.isArray(this.props?.parent)){
					selecteds = selecteds.filter((value, index, self) => self.findIndex((v) => parseInt(v.idTipoOrdem) === parseInt(value.idTipoOrdem)) === index);
					selecteds.forEach((selected, index) => {
						let grupoProdutos = response.filter(i=> parseInt(i.tipoOrdem.idTipoOrdem) === (selected.idTipoOrdem)).flatMap(i => i.grupoProduto);
						if((grupoProdutos.length !== this.props.parent.length)){
							noSelecteds.push(selected);
						}
					});

					selecteds = selecteds.filter(selected => !noSelecteds.find(ns => selected.idTipoOrdem === ns.idTipoOrdem));
				}

                this.setState({selecteds : selecteds, selectedsOriginal : response, noSelecteds : noSelecteds});
            });
        }
	}

    onChangeAddItem(e, item){     
		var parents = Array.isArray(this.props.parent) ? this.props.parent : [this.props.parent];

		parents.forEach(parent => {
			var selected = this.state.selectedsOriginal.find(i => parseInt(i.grupoProduto.idGrupoProduto) === parseInt(parent.idGrupoProduto) && parseInt(i.tipoOrdem.idTipoOrdem) === parseInt(item.idTipoOrdem));
			if(!selected){
				GrupoProdutoTipoOrdemService.save({
					tipoOrdem : item,
					grupoProduto : parent
				}, response =>{
					this.getSelecteds();
					super.onChangeAddItem(e, item);
				});
			}
		});
	};

	onChangeDelItem(e, item){
		var parents = Array.isArray(this.props.parent) ? this.props.parent : [this.props.parent];

		parents.forEach(parent => {			
			var selected = this.state.selectedsOriginal.find(i => parseInt(i.grupoProduto.idGrupoProduto) === parseInt(parent.idGrupoProduto) && parseInt(i.tipoOrdem.idTipoOrdem) === parseInt(item.idTipoOrdem));
			if(selected){
				GrupoProdutoTipoOrdemService.delete(selected.idGrupoProdutoTipoOrdem, response =>{
					this.getSelecteds();
					super.onChangeDelItem(e, item);
				});
			};
		});
	};

	onChangeAddAll(e, itens){
		var parents = Array.isArray(this.props.parent) ? this.props.parent : [this.props.parent];

		parents.forEach(parent => {
			var obj = itens.filter(item => 
				!this.state.selectedsOriginal.find(i => parseInt(i.grupoProduto.idGrupoProduto) === parseInt(parent.idGrupoProduto) && parseInt(i.tipoOrdem.idTipoOrdem) === parseInt(item.idTipoOrdem))
			).map(item => {
				return {
					tipoOrdem : item,
					grupoProduto : parent
				}
			});

			if(obj.length > 0){
				GrupoProdutoTipoOrdemService.save(obj, response =>{
					this.getSelecteds();
					super.onChangeAddAll(e, itens);
				});
			}
		});
	};

	onChangeDelAll(e, itens){
		var parents = Array.isArray(this.props.parent) ? this.props.parent : [this.props.parent];

		parents.forEach(parent => {
			var selecteds = this.state.selectedsOriginal.filter(i => parseInt(i.grupoProduto.idGrupoProduto) === parseInt(parent.idGrupoProduto)).filter(i => itens.find(is => parseInt(i.tipoOrdem.idTipoOrdem) === parseInt(is.idTipoOrdem)));
			GrupoProdutoTipoOrdemService.delete(selecteds , response =>{
				this.getSelecteds();
				super.onChangeDelAll(e, itens);
			});
		});
	};

    render() {
        return super.render();
    }
}