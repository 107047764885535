import Http from "../controllers/Http";

export default class OrdemEquipeService {

	static urlBase = "/ordemEquipe/";

	static getForCombo(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(OrdemEquipeService.urlBase + 'forCombo', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static list(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(OrdemEquipeService.urlBase + 'list', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneById(id, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.get(OrdemEquipeService.urlBase + id)
			.then(fnSucess)
			.catch(fnError);
	}

	static create(data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(OrdemEquipeService.urlBase, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static update(id, data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.put(OrdemEquipeService.urlBase + id, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static delete(id, fnSucess = () => { }, fnError = () => { }) {
		if(Number.isInteger(id)){
			Http.getInstanceAppJson()
				.delete(OrdemEquipeService.urlBase + id)
				.then(fnSucess)
				.catch(fnError);
		}else{
			Http.getInstanceAppJson()
			.post(OrdemEquipeService.urlBase + 'delete', id)
			.then(fnSucess)
			.catch(fnError);
		}
	}

	static save(data, fnSucess = () => { }, fnError = () => { }) {
		if (data.idOrdemEquipe) {
			this.update(data.idOrdemEquipe, data, fnSucess, fnError);
		} else {
			this.create(data, fnSucess, fnError);
		}
	}

	static getAllByOrdem(ordem, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(OrdemEquipeService.urlBase + 'ordem', ordem)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByOrdem(ordem, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(OrdemEquipeService.urlBase + 'ordem/' + ordem.idOrdem)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByEquipe(equipe, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(OrdemEquipeService.urlBase + 'equipe', equipe)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByEquipe(equipe, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(OrdemEquipeService.urlBase + 'equipe/' + equipe.idEquipe)
			.then(fnSucess)
			.catch(fnError);
	}
}