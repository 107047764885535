
import FormWebhook from "./FormWebhook.js";
import ListWebhook from "./ListWebhook.js";
import { Permissao } from "../../controllers";
import { Util } from "../../utils";
import { DadoValorService, WebhookService } from "../../services";

export default class Config {

	static getUrlForm() {
		return "/CadWebhook";
	}

	static getUrlList() {
		return "/LstWebhook";
	}

	static getComponentForm() {
		return FormWebhook;
	}

	static getPermissionForm() {
		return Permissao.getInstance().getPermissoes([162]);
	}

	static getPermissionList() {
		return Permissao.getInstance().getPermissoes([162]);
	}

	static getComponentList() {
		return ListWebhook;
	}

	static getService() {
		return WebhookService;
	}

	static getKeyId() {
		return "idWebhook";
	}

	static getKeyDescription() {
		return "nmWebhook";
	}

	static setDefaultValues(state , fnCallBack = () => {}, className = undefined) {
		className = className === undefined ? "FormWebhook" : className;
		let keyNameScreen = Util.toToUpperTextCamelCase(className, "_") + ".";
		var prefix = "CONFIGURACAO.";
		DadoValorService.getValues(`${prefix}${keyNameScreen}`, (response)=> {
			Util.setValueObjectCamelCase(state.model, response, keyNameScreen, prefix);
			fnCallBack();
		}, fnCallBack);
	}
}