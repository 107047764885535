//#NO-CHANGE-FILE#

import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import BtnRadioGroupSimNao from "../../components/BtnRadioGroupSimNao";
import { BaseCrud } from "../../siht/base";
import { Alert, FormItem, Row } from "../../siht/components";
import { InputNumber, InputNumberFormat, InputDate, Div } from "../../siht/elements";
import Config from "./Config";
import SelectTipoRecorrencia from "../../components/SelectDB/SelectTipoRecorrencia";
import Util from "../../utils/Util";

class FormRecorrencia extends BaseCrud {

	constructor(props) {
		super(props);
		this.init = this.init.bind(this);
		this.initModel = this.initModel.bind(this);
		this.handleSuccess = this.handleSuccess.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleChangeModel = this.handleChangeModel.bind(this);
		this.handleSubmitForm = this.handleSubmitForm.bind(this);
		this.handleChangeForm = this.handleChangeForm.bind(this);
		this.handleChangeTipoRecorrencia = this.handleChangeTipoRecorrencia.bind(this);
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		super.init(Config);
	}

	initModel(){

		if(this.props.value){
			return this.props.value;
		}

		var tzoffset = (new Date()).getTimezoneOffset() * 60000;
		var dtVencimento = new Date();
		dtVencimento = new Date(dtVencimento - tzoffset).toISOString().slice(0, 10);

		return {
			idRecorrencia : null,
			tipoRecorrencia : null,
			nrParcela : null,
			vlRecorrencia : 0,
			dtVencimento : dtVencimento,
			dtLimite : "",
			flDiasUteis : true
		};
	}

	handleChangeForm(e){
		if(this.props.onChange !== undefined){
			this.props.onChange(e, this.state.model, this.props.name);
		}
	}

	handleSuccess(e) {
		super.handleSuccess(e, Config);
	}

	handleClose(e) {
		super.handleClose(e, Config);
	}

	handleChangeTipoRecorrencia(e,v,p) {
		let nrParcela = null;

		if(this.props.value?.tipoRecorrencia?.cdTipoRecorrencia === "UNICA"){
			nrParcela=1;
		}else if(this.props.value?.tipoRecorrencia?.cdTipoRecorrencia === "PARCELADA"){
			nrParcela=2;
		}

		this.setModel({nrParcela : nrParcela}, () => this.handleChangeForm(e,v,p));
	}

	getDescricaoRecorrencia(){

		var arrSemana = ["domingo", "segunda-feira","terça-feira","quarta-feira","quinta-feira","sexta-feira","sábado"];
		
		var dtVencimento = new Date(this.props.value?.dtVencimento);
		let dia = dtVencimento.getUTCDate();
		let semana = dtVencimento.getUTCDay();

		switch(this.props.value?.tipoRecorrencia?.cdTipoRecorrencia){
			case "UNICA":
				return <>Parcela única de <strong>{Util.floatToReais(this.props.value?.vlRecorrencia)}</strong> com vencimento em <strong>{Util.date2Br(this.props.value?.dtVencimento)}</strong></>;
			case "PARCELADA":
				return <>Parcelada em <strong>{this.props.value?.nrParcela}x</strong> de <strong>{Util.floatToReais(this.props.value?.vlRecorrencia)}</strong> com vencimento em <strong>{Util.date2Br(this.props.value?.dtVencimento)}</strong></>;
			case "MENSAL":
				return <>Todo dia <strong>{dia}</strong> de cada mês iniciando em <strong>{Util.date2Br(this.props.value?.dtVencimento)}</strong> até <strong>{Util.date2Br(this.props.value?.dtLimite)}</strong></>;
			case "BIMESTRAL":
				return <>A cada 2 meses no dia <strong>{dia}</strong> iniciando em <strong>{Util.date2Br(this.props.value?.dtVencimento)}</strong> até <strong>{Util.date2Br(this.props.value?.dtLimite)}</strong></>;
			case "TRIMESTRAL":
				return <>A cada 3 meses no dia <strong>{dia}</strong> iniciando em <strong>{Util.date2Br(this.props.value?.dtVencimento)}</strong> até <strong>{Util.date2Br(this.props.value?.dtLimite)}</strong></>;
			case "SEMESTRAL":
				return <>A cada 6 meses no dia <strong>{dia}</strong> iniciando em <strong>{Util.date2Br(this.props.value?.dtVencimento)}</strong> até <strong>{Util.date2Br(this.props.value?.dtLimite)}</strong></>;
			case "ANUAL":
				return <>A cada 12 meses no dia <strong>{dia}</strong> iniciando em <strong>{Util.date2Br(this.props.value?.dtVencimento)}</strong> até <strong>{Util.date2Br(this.props.value?.dtLimite)}</strong></>;
			case "QUINZENAL":
				
				dtVencimento.setDate(dtVencimento.getDate() + 15);
				let dia2 = dtVencimento.getUTCDate();

				return <>A cada 15 dias no dia <strong>{dia}</strong> e <strong>{dia2}</strong> de cada mês iniciando em <strong>{Util.date2Br(this.props.value?.dtVencimento)}</strong> até <strong>{Util.date2Br(this.props.value?.dtLimite)}</strong></>;
			case "SEMANAL":
				return <>Ocorre todo(a) <strong>{arrSemana[semana]}</strong> de cada semana iniciando em <strong>{Util.date2Br(this.props.value?.dtVencimento)}</strong> até <strong>{Util.date2Br(this.props.value?.dtLimite)}</strong></>;
			default:
				return "";
		}
		
	}

	render() {

		let disabled = this.props.disabled !== undefined ? this.props.disabled : false;

		return super.render(
			<Row className="g-3 px-2 py-2">

				<FormItem md={3} label="Tipo de Recorrência">
					<SelectTipoRecorrencia disabled={disabled} name="tipoRecorrencia" value={this.props.value?.tipoRecorrencia} required={true} onChange={(e,v,p) => this.handleChangeModel(e,v,p, this.handleChangeTipoRecorrencia)} placeholder="Selecione a Recorrencia" buttons={{search : false, new : false, edit: false}}/>
				</FormItem>

				<FormItem md={2} label={this.props.value?.tipoRecorrencia?.cdTipoRecorrencia === "UNICA" ? "Data de Vencimento" : "Data de Inicio"} show={this.props.value?.tipoRecorrencia?.idTipoRecorrencia !== undefined}>
					<InputDate disabled={disabled} name="dtVencimento" value={this.props.value?.dtVencimento} required={true} onChange={(e,v,p) => this.handleChangeModel(e,v,p, this.handleChangeForm)} className="text-center"/>
				</FormItem>

				<FormItem md={2} label="Valor" show={this.props.value?.tipoRecorrencia?.idTipoRecorrencia !== undefined}>
					<InputNumberFormat disabled={disabled} name="vlRecorrencia" value={this.props.value?.vlRecorrencia} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} prefix="R$ " required={true} onChange={(e,v,p) => this.handleChangeModel(e,v,p, this.handleChangeForm)}/>
				</FormItem>

				<FormItem md={2} label={this.props.value?.tipoRecorrencia?.cdTipoRecorrencia === "UNICA" ? "Parcela" : "Parcela"} show={this.props.value?.tipoRecorrencia?.idTipoRecorrencia !== undefined && (this.props.value?.tipoRecorrencia?.cdTipoRecorrencia === "UNICA" || this.props.value?.tipoRecorrencia?.cdTipoRecorrencia === "PARCELADA")}>
					<InputNumber name="nrParcela" disabled={this.props.value?.tipoRecorrencia?.cdTipoRecorrencia === "UNICA" || disabled} value={this.props.value?.nrParcela} required={true} onChange={(e,v,p) => this.handleChangeModel(e,v,p, this.handleChangeForm)} className="text-center" min={2}/>
				</FormItem>

				<FormItem md={2} label="Limite" show={this.props.value?.tipoRecorrencia?.idTipoRecorrencia !== undefined && this.props.value?.tipoRecorrencia?.cdTipoRecorrencia !== "PARCELADA" && this.props.value?.tipoRecorrencia?.cdTipoRecorrencia !== "UNICA"}>
					<InputDate disabled={disabled} name="dtLimite" value={this.props.value?.dtLimite} required={true} onChange={(e,v,p) => this.handleChangeModel(e,v,p, this.handleChangeForm)}/>
				</FormItem>

				<FormItem md={3} label="Considerar dias úteis" show={this.props.value?.tipoRecorrencia?.idTipoRecorrencia !== undefined}>
					<BtnRadioGroupSimNao disabled={disabled} name="flDiasUteis" value={this.props.value?.flDiasUteis} required={true} onChange={(e,v,p) => this.handleChangeModel(e,v,p, this.handleChangeForm)}/>
				</FormItem>

				<Div className="py-2 d-flex justify-content-center" show={this.props.value?.tipoRecorrencia?.idTipoRecorrencia !== undefined}>
					<Alert color={Alert.color.warning} className="py-2">
						{this.getDescricaoRecorrencia()}
					</Alert>					
				</Div>
			</Row>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <FormRecorrencia {...props} navigate={navigate} params={params} location={location}/>
}
export default With