//#NO-CHANGE-FILE#

import Config from "./Config";
import React from "react";
import { useNavigate, useLocation, useParams } from "react-router";
import { BaseList } from "../../siht/base";
import { DataGrid, DataGridButtons, DataGridColumn } from "../../siht/components";
import { BtnLink, Button, Div, Span } from "../../siht/elements";
import { BtnDelete, BtnNew, StatusCustom } from "../../components/Custom";
import { Util } from "../../utils";
import { Modal } from "../../siht/controller";
import FormOrdem from "../Ordem/FormOrdem";

class ListComissaoOrdem extends BaseList {

	constructor(props) {
		super(props);
		this.getItens = this.getItens.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleNew = this.handleNew.bind(this);
		this.handleEdit = this.handleEdit.bind(this);
		this.handleCopy = this.handleCopy.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.handleMore = this.handleMore.bind(this);
		this.onSubmitFilter = this.onSubmitFilter.bind(this);
		this.handleClear = this.handleClear.bind(this);
		this.handleFormSuccess = this.handleFormSuccess.bind(this);
		this.handleFormClose = this.handleFormClose.bind(this);
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		super.init(Config);
	}

	getItens(more){
		if(this.props?.parent){
			super.getItens(Config.getService().getAllByComissao, this.props.parent, more);
		}
	}

	handleNew(e) {
		super.handleNew(e, Config);
	}

	handleEdit(e, item) {
		super.handleEdit(e, Config, item);
	}

	handleDelete(e, item) {
		super.handleDelete(e, Config, item);
	}

	handleCopy(e, item) {
		super.handleCopy(e, Config, item);
	}

	getFormEmbedded(){
		return super.getFormEmbedded(Config);
	}

	handleViewOrdem (e, ordem) {
		Modal.openWindow(FormOrdem, {sizing : Modal.sizing.xl}, { id : ordem.idOrdem });
	}

	render() {
		let editable = this.props.editable !== undefined ? this.props.editable : true;

		return super.render(
			<Div className="p-2 mt-2">
				<Div className="d-flex justify-content-end flex-nowrap gap-2">
					<BtnNew onClick={this.handleNew} disabled={!editable}/>
					<BtnDelete disabled={this.state.selecteds.length === 0 || !editable} sizing={Button.sizing.default} onClick={e => this.handleDelete(e, this.state.selecteds)}> Excluir </BtnDelete>
				</Div>

				<DataGrid responsive={true} data={this.state.data} search={false} isDisabled={i => false} sizing={DataGrid.sizing.sm} select={true} selecteds={this.state.selecteds} onSelect={(selecteds)=>{this.setState({selecteds : selecteds})}} getItemValue={i=>i.idComissaoOrdem} onMore={this.handleMore}>
					<DataGridColumn col={2} label="Ordem" field={i => <>
						<BtnLink onClick={(e) => this.handleViewOrdem(e, i.ordem)}> {i.ordem?.idOrdem} </BtnLink>
					</>} className="text-start"/>

					<DataGridColumn col={5} label="Cliente" field={i => <React.Fragment>
						<Span className="fw-bold">{i.ordem?.pessoa?.nmPessoa}</Span> <br/>
						<StatusCustom color={i.ordem?.pessoa?.tipoPessoa}> PESSOA {i.ordem?.pessoa?.tipoPessoa?.nmTipoPessoa} </StatusCustom>
						<Div className="fw-bold fs-7" show={i.ordem?.pessoa?.tipoPessoa?.cdTipoPessoa === "F"}>{Util.formataCPF(i.ordem?.pessoa?.nrCpf)}</Div>
						<Div className="fw-bold fs-7" show={i.ordem?.pessoa?.tipoPessoa?.cdTipoPessoa === "J"}>{Util.formataCnpj(i.ordem?.pessoa?.nrCnpj)}</Div>
						</React.Fragment>
						}
					className="text-start"/>

					<DataGridColumn col={5} label="Empresa / Unidade" field={i => <React.Fragment> 							
							<Div className="fw-bold">{i.ordem.unidade?.empresa?.nmEmpresa}</Div>
							<Div>{i.ordem.unidade?.nmUnidade} </Div>
					</React.Fragment>} className="text-start"/>

					<DataGridButtons>						
						<BtnDelete sizing={Button.sizing.default} onClick={this.handleDelete} disabled={!editable}/>
					</DataGridButtons>
				</DataGrid>
			</Div>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <ListComissaoOrdem {...props} navigate={navigate} params={params} location={location}/>
}
export default With