import React, { useCallback } from 'react';
import { useReactFlow } from '@xyflow/react';
import { Collection as IconCollection, Diamond as IconDiamond, JournalText as IconJournalText, Square as IconSquare, Play as IconPlay, Stop as IconStop } from 'react-bootstrap-icons';
import Config from '../Config';
 
export default function ContextMenu({
  top,
  left,
  right,
  bottom,
  nodeInit,
  ...props
}) {
    const reactFlow = useReactFlow();

    const onClickAddNode = useCallback((event, type) => {

        const idNode = `node.temp.${Math.random(100)}`;

        const newNode = {
            id : `node.temp.${Math.random(100)}`,
            position: reactFlow.screenToFlowPosition({
                x: left,
                y: top,
            }),
            data: { label: `Node ${idNode}` },
            origin: [0.5, 0.0],
            ...nodeInit,
            type : Config.getNodeType(type)
        };

        if(Config.getNodeType(type) === "group"){
            newNode.width = 400;
            newNode.height = 300;
        }

        if(Config.getNodeType(type) === "annotation"){
            newNode.data.arrowStyle = "left";
        }

        if(Config.getNodeType(type) === "start"){
            newNode.data.label = "Início";
        }

        if(Config.getNodeType(type) === "end"){
            newNode.data.label = "Fim";
        }

        //TODO: REORDENAR PARA COLOCAR O GROUP PRIMEIRO

        reactFlow.setNodes((nds) => nds.concat(newNode));
    }, []);
    
    return (
        <div style={{ top, left, right, bottom }} className="context-panel-menu" {...props}>
            {/*<p style={{ margin: '0.5em'}}> <small>Teste</small> </p>*/}
            <button onClick={e => onClickAddNode(e, "TAREFA")}> <IconSquare/> Adicionar Tarefa </button>
            <button onClick={e => onClickAddNode(e, "CONDICIONAL")}> <IconDiamond/> Adicionar Condicional </button>
            <button onClick={e => onClickAddNode(e, "GRUPO")}> <IconCollection/> Adicionar Grupo </button>
            <button onClick={e => onClickAddNode(e, "ANOTACAO")}> <IconJournalText/> Adicionar Anotação </button>
            <hr/>
            <button disabled={reactFlow.getNodes().filter(n => n.type === "start").length > 0} onClick={e => onClickAddNode(e, "INICIO")}> <IconPlay/> Adicionar Início </button>
            <button disabled={reactFlow.getNodes().filter(n => n.type === "end").length > 0} onClick={e => onClickAddNode(e, "FIM")}> <IconStop/> Adicionar Fim </button>
        </div>
    );
}