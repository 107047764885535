import Input from "./Input";

export default class InputNumber extends Input {

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }
    
    handleChange(e) {
        super.handleChange(e, parseInt(e?.target?.value), e?.target?.name);
    }

    handleBlur(e) {        
        super.handleBlur(e, parseInt(e?.target?.value), e?.target?.name);
    }

    render() {
        return super.render("number");
    }
}