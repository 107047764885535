
export default class Auth {

    static authed = false;

    static getToken() {
        if (localStorage.getItem("Authorization") !== null) {
            return localStorage.getItem("Authorization");
        }
        return false;
    }

    static getRefreshToken() {
        if (localStorage.getItem("RefreshToken") !== null) {
            return localStorage.getItem("RefreshToken");
        }
        return false;
    }

    static setToken(token, refreshToken) {
        if (token) {
            localStorage.setItem("Authorization", token);
        }

        if (refreshToken) {
            localStorage.setItem("RefreshToken", refreshToken);
        }
    }

    static removeAllToken() {
        localStorage.removeItem("Authorization");
        localStorage.removeItem("RefreshToken");
    }

    static removeRefreshToken() {
        localStorage.removeItem("RefreshToken");
    }

    static removeToken() {
        localStorage.removeItem("Authorization");
        localStorage.removeItem("RefreshToken");
    }

    static login(token, refreshToken) {
        this.authed = true;
        this.setToken(token, refreshToken);
    };

    static logout() {
        this.authed = false;
        this.removeAllToken();
    };

    static lock() {
        this.removeRefreshToken();
        var token = this.getToken();
        token = "a." + token.split('.')[1] + ".b";
        this.setToken(token);
    };

    static isAuthenticated() {
        return this.authed;
    };

    static getDataToken() {
        var token = this.getToken();
        try {
            var json = JSON.parse(atob(token.split('.')[1]));
            return json.data;
        } catch (e) {
            return null;
        }
    }

    static getUsuarioDataToken() {
        var dataToken = this.getDataToken();
        if (!dataToken) {
            return false;
        }

        if (!dataToken.usuario) {
            return false;
        }

        return dataToken.usuario;
    }

    static getIdUsuarioDataToken() {
        var dataToken = this.getDataToken();
        if (!dataToken) {
            return false;
        }

        if (!dataToken.idUsuario) {
            return false;
        }

        return dataToken.idUsuario;
    }


    static getModulosDataToken() {
        var dataToken = this.getDataToken();
        if (!dataToken) {
            return false;
        }

        if (!dataToken.permissoes) {
            return [];
        }

        return dataToken.permissoes;
    }

    static getDownloadDataToken() {
        var dataToken = this.getDataToken();
        if (!dataToken) {
            return false;
        }

        if (!dataToken.tokenDownload) {
            return false;
        }

        return dataToken.tokenDownload;
    }
}