//#NO-CHANGE-FILE#

import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import BtnRadioGroupSimNao from "../../components/BtnRadioGroupSimNao";
import Permissao from "../../controllers/Permissao";
import { BaseList, BaseCrud } from "../../siht/base";
import { FormButtons, FormItem, Row, TabNavigator, Tab, Panel, PanelBody, PanelFooter, Container } from "../../siht/components";
import { InputText, TextArea, Form, BtnSubmit } from "../../siht/elements";
import Config from "./Config";
import ListDadoValorByParent from "../DadoValor/ListDadoValorByParent";
import ManyToManyCheckPluginFuncionalidadePluginByFuncionalidadePlugin from "./ManyToManyCheckPluginFuncionalidadePluginByFuncionalidadePlugin";
import ListDadoValorByParentForm from "../DadoValor/ListDadoValorByParentForm";
import { PatchPlus as IconPatchPlus } from "react-bootstrap-icons";

class FormPlugin extends BaseCrud {

	constructor(props) {
		super(props);
		this.init = this.init.bind(this);
		this.handleSuccess = this.handleSuccess.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleChangeModel = this.handleChangeModel.bind(this);
		this.handleSubmitForm = this.handleSubmitForm.bind(this);

		this.state.model = {
			idPlugin : null,
			nmPlugin : "",
			txPlugin : "",
			cdPlugin : "",
			flAtivo : true,
			pluginDadoValor : []
		};
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		super.init(Config);
	}

	handleSuccess(e) {
		super.handleSuccess(e, Config);
	}

	handleClose(e) {
		super.handleClose(e, Config);
	}

	render() {
		return super.render(

					<Panel key={this.state.key} title="Cadastro de Plugin" onClose={this.handleClose} isModal={this.isModal()} container={{sizing: Container.sizing.xxl, className : ""}}>
						<PanelBody className="p-0 m-0">
							<TabNavigator key={this.state.key} onChangeTab={this.handleChangeTab} className="border-light">
								<Tab title="Plugin" className="m-2">
									<Form onSubmit={this.handleSuccess} refParam={f => (this.form = f)}>
										<Row className="g-3 pb-2 px-2">
											<FormItem md={6} label="Código">
												<InputText name="idPlugin" value={this.state.model.idPlugin} readOnly={true} className="readonly-field" required={false} onChange={this.handleChangeModel}/>
											</FormItem>
											<FormItem md={12} label="Nome da Plugin">
												<InputText name="nmPlugin" value={this.state.model.nmPlugin} autoFocus={true} maxLength={100} required={true} onChange={this.handleChangeModel}/>
											</FormItem>
											<FormItem md={12} label="TxPlugin">
												<TextArea name="txPlugin" value={this.state.model.txPlugin} required={false} onChange={this.handleChangeModel}/>
											</FormItem>
											<FormItem md={6} label="CdPlugin">
												<InputText name="cdPlugin" value={this.state.model.cdPlugin} maxLength={100} required={true} onChange={this.handleChangeModel}/>
											</FormItem>
											<FormItem md={2} label="Ativo?">
												<BtnRadioGroupSimNao name="flAtivo" value={this.state.model.flAtivo} required={true} onChange={this.handleChangeModel}/>
											</FormItem>
											
											<ListDadoValorByParentForm cdDadoLocal="PLUGIN" parent={this.state.model} onChange={this.handleChangeModel} name="pluginDadoValor"/>

											<BtnSubmit hidden > Enviar </BtnSubmit>
										</Row>
									</Form>
								</Tab>
								<Tab show={Permissao.getInstance().getPermissoes([157])} title="Funcionalidades" disabled={!this.state.model.idPlugin} opened={false} className="m-2">
									<ManyToManyCheckPluginFuncionalidadePluginByFuncionalidadePlugin typeCheck={ManyToManyCheckPluginFuncionalidadePluginByFuncionalidadePlugin.typeCheck.box} parent={this.state.model} search={true}/>
								</Tab>
								<Tab show={Permissao.getInstance().getPermissoes([153])} title={<><IconPatchPlus/> Complementar</>} disabled={!this.state.model.idPlugin} opened={false} className="p-0 m-0">
									<ListDadoValorByParent cdDadoLocal="PLUGIN" parent={this.state.model} localOpen={BaseList.localOpen.modal}/>
								</Tab>								
							</TabNavigator>
						</PanelBody>
						<PanelFooter>
							<FormButtons show={this.state.tabIndex === 0} onClose={this.handleClose} onSubmit={this.handleSubmitForm}/>
						</PanelFooter>
					</Panel>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <FormPlugin {...props} navigate={navigate} params={params} location={location}/>
}
export default With