import React, { Component } from "react";

export default class Field extends Component {

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.ref = props.refParam === undefined ? React.createRef() : props.refParam;

        this.state = {
            ref : this.ref,
            error: "",
        }
    }

    componentDidUpdate(nextProps) {
    }

    componentDidMount() {
        if (this.ref?.current?.validity) {
            this.setState({ error: this.hasError(this.ref.current) });
        }
    }

    componentWillUnmount() {
    }

    static getDerivedStateFromProps(props, state) { //1

        if (props.validations) {
            //state.validations = props.validations;
            for (const item of props.validations) {
                if (state.ref?.current) {                    
                    if (!item.validation) {
                        state.ref?.current.setCustomValidity(item.message);
                        state.error = item.message;
                    } else {
                        state.ref?.current.setCustomValidity("");
                        //state.error = "";
                    }
                }
            }
        }

        if (props.value && props.value !== state.value) {
            if (state.eventsNames?.onChange) {
                var element = { event: "getDerivedStateFromProps" };
                element[props.name] = props.value;
            }

            return {
                value: props.value,
            };
        }
        return null;
    }

    objEqual(object1, object2) {
        const keys1 = Object.keys(object1);
        const keys2 = Object.keys(object2);

        if (keys1.length !== keys2.length) {
            return false;
        }

        for (let key of keys1) {
            if (object1[key] !== object2[key]) {
                return false;
            }
        }

        return true;
    }

    getColSize() {
        var colValue = false;
        var colSize = false;

        if (this.props.col) {
            colValue = "";
            colSize = this.props.col;
        } else if (this.props.xs) {
            colValue = this.props.xs;
            colSize = "xs";
        } else if (this.props.sm) {
            colValue = this.props.sm;
            colSize = "sm";
        } else if (this.props.md) {
            colValue = this.props.md;
            colSize = "md";
        } else if (this.props.lg) {
            colValue = this.props.lg;
            colSize = "lg";
        } else if (this.props.xl) {
            colValue = this.props.xl;
            colSize = "xl";
        } else if (this.props.xxl) {
            colValue = this.props.xxl;
            colSize = "xxl";
        }

        if (colSize) {
            return "col-" + colSize + (colValue > 0 ? "-" + colValue : "");
        }

        return "";
    }

    hasError(field) {
        
        if (field) {

            // Don't validate submits, buttons, file and reset inputs, and disabled fields
            if (field.disabled || field.type === 'file' || field.type === 'reset' || field.type === 'submit' || field.type === 'button') return;

            // Get validity
            var validity = field.validity;

            // If valid, return null
            if (validity?.valid) return;

            if (validity?.patternMismatch && field.hasAttribute('title')) {
                if (field.hasAttribute('title')) return field.getAttribute('title');
            }

            if (field.validationMessage) return field.validationMessage;

            // If field is required and empty
            if (validity?.valueMissing) return 'Please fill out this field.';

            // If not the right type
            if (validity?.typeMismatch) {

                // Email
                if (field.type === 'email') return 'Please enter an email address.';

                // URL
                if (field.type === 'url') return 'Please enter a URL.';

            }

            // If too short
            if (validity?.tooShort) return 'Please lengthen this text to ' + field.getAttribute('minLength') + ' characters or more. You are currently using ' + field.value.length + ' characters.';

            // If too long
            if (validity?.tooLong) return 'Please shorten this text to no more than ' + field.getAttribute('maxLength') + ' characters. You are currently using ' + field.value.length + ' characters.';

            // If number input isn't a number
            if (validity?.badInput) return 'Please enter a number.';

            // If a number value doesn't match the step interval
            if (validity?.stepMismatch) return 'Please select a valid value.';

            // If a number field is over the max
            if (validity?.rangeOverflow) return 'Please select a value that is no more than ' + field.getAttribute('max') + '.';

            // If a number field is below the min
            if (validity?.rangeUnderflow) return 'Please select a value that is no less than ' + field.getAttribute('min') + '.';

            // If pattern doesn't match
            if (validity?.patternMismatch) {

                // If pattern info is included, return custom error
                if (field.hasAttribute('title')) return field.getAttribute('title');

                // Otherwise, generic error
                return 'Please match the requested format.';

            }
        }

        // If all else fails, return a generic catchall error
        return 'The value you entered for this field is invalid.';
    };

    handleClick(e, value, name) {
        if (this.props.onClick) {
            this.props.onClick(e, e?.target?.value, e?.target?.name);
        }
    }

    handleChange(e, value, name) {
        this.setState({ error: this.hasError(e.target) });

        if (this.props.onChange) {
            var callBack = this.props.onChangeCallBack !== undefined ?  () => this.props.onChangeCallBack(e, value, name) : false;
            this.props.onChange(e, value, name, callBack);
        }
    }

    handleBlur(e, value, name) {
        this.setState({ error: this.hasError(e.target) });

        if (this.props.onBlur) {            
            var callBack = this.props.onBlurCallBack !== undefined ?  () => this.props.onBlurCallBack(e, value, name) : false;
            this.props.onBlur(e, value, name, callBack);
        }
    }

    getProps(props, delProps = []) {
        const fProps = Object.assign({}, props);

        //fProps.id = fProps.id ? fProps.id : "formField" + (fProps.name ? fProps.name : Math.random());
        //fProps.placeholder = fProps.placeholder ? fProps.placeholder : fProps.label;

        delete fProps.refParam;
        delete fProps.feedback;
        delete fProps.col;
        delete fProps.xs;
        delete fProps.sm;
        delete fProps.md;
        delete fProps.lg;
        delete fProps.xl;
        delete fProps.xxl;
        delete fProps.show;
        delete fProps.sizing;
        delete fProps.layout;
        delete fProps.label;
        delete fProps.options;
        delete fProps.opcional;
        delete fProps.showBtnNewSearch;
        delete fProps.showBtnNewForm;
        delete fProps.showBtnNewSearch;
        delete fProps.showBtns;
        delete fProps.dispachChangeDidMount;
        delete fProps.getOptionsWhen;
        delete fProps.noTextBgColor;

        delete fProps.onChangeItem;
        delete fProps.onChangeCallBack;
        delete fProps.onClear;
        delete fProps.onSearch;
        delete fProps.onClickCopy;
        delete fProps.buttons;
        delete fProps.apiKey;
        delete fProps.showDDI;

        for (var i = 0; i < delProps.length; i++) {
            delete fProps[delProps[i]];
        }

        return fProps;
    }

    getFeedback(){
        return (
            <>
                <div className="invalid-feedback">{this.state?.error}</div>
                <div className="valid-feedback">OK!</div>
            </>
        );
    }

    render(element, feedback) {

        if(this.props.show !== undefined && !Boolean(this.props.show)){
            return (<></>);
        }

        if(feedback === undefined ){
            if (this.props.feedback !== undefined) {
                feedback = this.props.feedback;
            } else if (feedback === undefined) {
                feedback = true;
            }
        }

        return (<>
            {element}
            {feedback ? this.getFeedback() : <></>}
        </>);
    }
}