//#NO-CHANGE-FILE#

//import PessoaService from "../services/PessoaService";
import Config from "../../view/Pessoa/Config";
import {InputSearchDB} from "../../siht/elements";
import { Modal } from "../../siht/controller";
import Formatter from "../../utils/Formatter";
import { StatusCustom } from "../Custom";
//import Permissao from "../controllers/Permissao";

export default class InputPessoa extends InputSearchDB {

    constructor(props) {
        super(props);
        this.getOptionsWhen = this.getOptionsWhen.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
    }

    getForComboService(){
        return Config.getService().getForCombo;
    }

    getDefault() {
        return this.state?.options.find(item => item?.flPadrao);
    }

    getFormCrud(){
        return Config.getComponentForm();
    }

    getPermissionFormCrud(){
        return Config.getPermissionForm();
    }

    handleSearch(){
        super.handleSearch({ sizing : Modal.sizing.xl });
    }

    getConfigSearch(){
        return [
            {
                key : Config.getKeyId(),
                label : "Código",
                className: "text-center"
            },
            {
                key : Config.getKeyDescription(),
                label : "Nome",
                //fnTransform : (value) => value
            },
            {
                key : "nrCpf",
                label : "CPF / CNPJ",
                className : "text-center",                
                fnTransform : (value, item) => {
                    if(item.tipoPessoa.cdTipoPessoa === "F"){
                        return Formatter.cpf(item.nrCpf);
                    }else if(item.tipoPessoa.cdTipoPessoa === "J"){
                        return Formatter.cnpj(item.nrCnpj);
                    }
                }
            },
            {
                key : "tipoPessoa",
                label : "Tipo",
                className : "text-center",
                fnTransform : (tipoPessoa) => <StatusCustom color={tipoPessoa}> PESSOA {tipoPessoa?.nmTipoPessoa} </StatusCustom>
                
            },
        ];
    }

    getOptionsWhen(filter){
        return super.getOptionsWhen(filter);
    }

    getOptionValue(item) {
        if(item && item[Config.getKeyId()]){
            return item[Config.getKeyId()];
        }
    }

    getOptionLabel(item) {
        if(item && item[Config.getKeyDescription()]){
            return item[Config.getKeyDescription()];
        }
    }

    render() {
        return super.render();
    }
}