//#NO-CHANGE-FILE#

import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { BaseCrud } from "../../siht/base";
import { FormItem, Row, Panel, PanelBody, PanelFooter, Container, ModalDialog, TabNavigator, Tab } from "../../siht/components";
import { InputText, TextArea, Form, BtnButton, Div } from "../../siht/elements";
import Config from "./Config";

class FormLogRequest extends BaseCrud {

	constructor(props) {
		super(props);
		this.init = this.init.bind(this);
		this.initModel = this.initModel.bind(this);
		this.handleSuccess = this.handleSuccess.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleChangeModel = this.handleChangeModel.bind(this);
		this.handleSubmitForm = this.handleSubmitForm.bind(this);
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		super.init(Config);
	}

	initModel(){
		return {
			idLogRequest : null,
			logResponse : null,
			usuario : null,
			txUrl : "",
			cdMethod : "",
			txHeader : "",
			txBody : "",
			txServer : "",
		}
	}

	handleSuccess(e) {
		super.handleSuccess(e, Config);
	}

	handleClose(e) {
		super.handleClose(e, Config);
	}

	render() {
		return super.render(
			<Form onSubmit={this.handleSuccess} refParam={f => (this.form = f)} key={this.state.key}>
				<Panel key={this.state.key} title="Cadastro de LogRequest" localOpen={this.props.localOpen}  onClose={this.handleClose} isModal={this.isModal()} container={{sizing: Container.sizing.xxl, className : ""}} modal={{sizing: this.props.sizing ? this.props.sizing : ModalDialog.sizing.xl}}>
					<PanelBody className="p-0 m-0">
						<TabNavigator onChangeTab={this.handleChangeTab} className="border-light">
							<Tab title="Request" className="m-0 p-0">
								<TabNavigator className="border-light">
									<Tab title="Request" className="m-2">
										<Row className="g-3 pb-2 px-2">
											<FormItem md={3} label="Código">
												<InputText value={this.state.model.idLogRequest} readOnly={true} className="readonly-field"/>
											</FormItem>
											
											<FormItem md={3} label="Method">
												<InputText value={this.state.model.cdMethod} readOnly={true}/>
											</FormItem>
											
											<FormItem md={6} label="Usuário">
												<InputText value={this.state.model.usuario?.nmUsuario} readOnly={true}/>
											</FormItem>

											<FormItem md={12} label="URL">
												<InputText value={this.state.model.dsUrl} readOnly={true}/>
											</FormItem>

											<FormItem md={12} label="TxUrl">
												<TextArea value={this.state.model.txUrl} readOnly={true} className="text-bg-dark" style={{ height: "250px", fontFamily: "Courier New" }}/>
											</FormItem>
										</Row>
									</Tab>
									<Tab title="Header" className="m-2">
										<Div className="g-3 pb-2 px-2">
											<TextArea value={this.state.model.txHeader} readOnly={true} className="text-bg-dark" style={{ height: "400px", fontFamily: "Courier New" }}/>
										</Div>
									</Tab>
									<Tab title="Body" className="m-2">
										<Div className="g-3 pb-2 px-2">
											<TextArea value={this.state.model.txBody} readOnly={true} className="text-bg-dark" style={{ height: "400px", fontFamily: "Courier New" }}/>
										</Div>
									</Tab>
									<Tab title="Server" className="m-2">
										<Div className="g-3 pb-2 px-2">
											<TextArea value={this.state.model.txServer} readOnly={true} className="text-bg-dark" style={{ height: "400px", fontFamily: "Courier New" }}/>
										</Div>
									</Tab>
								</TabNavigator>
							</Tab>
							<Tab title="Response" className="m-0 p-0">
								<TabNavigator className="border-light">
									<Tab title="Response" className="m-2">
										<Row className="g-3 pb-2 px-2">
											<FormItem md={3} label="Código">
												<InputText name="idLogResponse" value={this.state.model.logResponse?.idLogResponse} readOnly={true} className="readonly-field"/>
											</FormItem>
											
											<FormItem md={3} label="Status">
												<InputText name="cdMethod" value={this.state.model.logResponse?.nrStatus} readOnly={true}/>
											</FormItem>
										</Row>
									</Tab>
									<Tab title="Header" className="m-2">
										<Div className="g-3 pb-2 px-2">												
											<TextArea name="txHeader" value={this.state.model.logResponse?.txHeader} readOnly={true} className="text-bg-dark" style={{ height: "400px", fontFamily: "Courier New" }}/>
										</Div>
									</Tab>
									<Tab title="Body" className="m-2">
										<Div className="g-3 pb-2 px-2">												
											<TextArea name="txBody" value={this.state.model.logResponse?.txBody} readOnly={true} className="text-bg-dark" style={{ height: "400px", fontFamily: "Courier New" }}/>
										</Div>
									</Tab>
								</TabNavigator>
							</Tab>
						</TabNavigator>
					</PanelBody>
					<PanelFooter>
						<BtnButton color={BtnButton.color.outlineSecondary} onClick={this.handleClose}> Fechar </BtnButton>
					</PanelFooter>
				</Panel>
			</Form>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <FormLogRequest {...props} navigate={navigate} params={params} location={location}/>
}
export default With