
import FormCombustivel from "./FormCombustivel.js";
import ListCombustivel from "./ListCombustivel.js";
import { Permissao } from "../../controllers";
import { Util } from "../../utils";
import { DadoValorService, CombustivelService } from "../../services";

export default class Config {

	static getUrlForm() {
		return "/CadCombustivel";
	}

	static getUrlList() {
		return "/LstCombustivel";
	}

	static getComponentForm() {
		return FormCombustivel;
	}

	static getPermissionForm() {
		return Permissao.getInstance().getPermissoes([179]);
	}

	static getPermissionList() {
		return Permissao.getInstance().getPermissoes([179]);
	}

	static getComponentList() {
		return ListCombustivel;
	}

	static getService() {
		return CombustivelService;
	}

	static getKeyId() {
		return "idCombustivel";
	}

	static getKeyDescription() {
		return "nmCombustivel";
	}

	static setDefaultValues(state , fnCallBack = () => {}, className = undefined) {
		className = className === undefined ? "FormCombustivel" : className;
		let keyNameScreen = Util.toToUpperTextCamelCase(className, "_") + ".";
		var prefix = "CONFIGURACAO.";
		DadoValorService.getValues(`${prefix}${keyNameScreen}`, (response)=> {
			Util.setValueObjectCamelCase(state.model, response, keyNameScreen, prefix);
			fnCallBack();
		}, fnCallBack);
	}
}