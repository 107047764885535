import Input from "./Input";

export default class InputEmail extends Input {

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    handleChange(e) {
        super.handleChange(e, e?.target?.value, e?.target?.name);        
    }

    handleBlur(e) {        
        super.handleBlur(e, e?.target?.value, e?.target?.name);
    }

    render() {
        return super.render("email");
    }
}