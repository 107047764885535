import { Component } from "react";

export default class TableResponsive extends Component {

    static sizing = {
        sm : "sm",
        md : "md",
        lg : "lg",
        xl : "xl",
        xxl : "xxl",
        default : "",
    };

    render() {
        if(this.props.show !== undefined && !Boolean(this.props.show)){
            return (<></>);
        }

        const props = Object.assign({}, this.props);
        delete props.show;
        
        if(props.responsive){
            props.className = `table-responsive${props.sizing !== undefined && props.sizing !== "" ? "-" + props.sizing : ""} ${props.className ? props.className : ""}`;
        }
        
        delete props.sizing;
        delete props.responsive;

        return <div {...props}>{this.props.children}</div>;
    }
}