import { BaseComponent } from "../../siht/base";
import { Badge } from "../../siht/components";
import { InputText } from "../../siht/elements";

export default class StatusCustom extends BaseComponent {

    render() {

        if (this.props.show !== undefined && !Boolean(this.props.show)) {
            return (<></>);
        }

        const props = Object.assign({}, this.props);

        props.className = `${props.className !== undefined ? props.className : ""}`;

        var type = props.type !== undefined ? props.type : "Badge";

        let style = {
            backgroundColor: this.props.color?.cdCorFundo,
            color: this.props.color?.cdCorTexto,
            border: "1px solid " + this.props.color?.cdCorBorda
        };

        delete props.show;
        delete props.color;
        delete props.type;
        delete props.value;
        delete props.children;
        delete props.opcional;

        if (type === "Badge") {
            return <Badge {...props} noTextBgColor={true} style={style} opcional={true}> {this.props.children} </Badge>;
        }

        //console.log(Children.only(this.props.children) );


        if (type === "InputText") {
            return <InputText {...props} value={this.props.children} readOnly={true} opcional={true} required={false} style={style} />;
        }

        return <></>;
    }
}