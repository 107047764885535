import { Component } from "react";
import { BtnButton, Div, H6 } from "../elements";

export default class CardHeader extends Component {

    render() {
        if(this.props.show !== undefined && !Boolean(this.props.show)){
            return (<></>);
        }

        const props = Object.assign({}, this.props);
        props.className = `card-header ${props.className !== undefined ? props.className : ""}`;

        delete props.show;

        return (
            <Div {...props}> 
                {this.props.children ? this.props.children : (
                    <Div className="d-flex">
                        <H6 className="p-1 w-100">{props.title}</H6>
                        {this.props.onClose !== undefined ? 
                            <Div className="p-1 flex-shrink-1">
                                <BtnButton className="btn-close" onClick={this.props.onClose}></BtnButton>
                            </Div>
                        : <></>}
                    </Div>
                )}                
            </Div>
        );
    }
}