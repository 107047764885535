import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { BaseCrud } from "../../siht/base";
import { Panel, PanelBody, PanelFooter, Container, ListGroup, ListGroupItem } from "../../siht/components";
import { Div, Form, InputRadio, BtnSubmit, BtnButton } from "../../siht/elements";

class FormLoginGrupo extends BaseCrud {

	constructor(props) {
		super(props);
		this.init = this.init.bind(this);
		this.handleSuccess = this.handleSuccess.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleChangeModel = this.handleChangeModel.bind(this);
		this.handleSubmitForm = this.handleSubmitForm.bind(this);
        this.handleClickRow = this.handleClickRow.bind(this);

		this.state.grupo = {};
        this.refRadio = React.createRef();
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
        if(this.props.grupos.length > 0){
		    this.setState({grupo : this.props.grupos[0]}, ()=>{
                if(this.refRadio.current){
                    this.refRadio.current.focus();
                }
            })
        }
        
	}

	handleSuccess(e) {
		if (this.props.handleSuccess !== undefined) {
            this.props.handleSuccess(e, this.state.grupo);
        }
	}

	handleClose(e) {
		super.handleClose(e);
	}

    handleClickRow(e, item, index){
        this.setState({grupo : item});
    }

	render() {
		return super.render(
				<Div key={this.state.key}>
					<Form onSubmit={this.handleSuccess} refParam={f => (this.form = f)}>
						<Panel title="Escolha um Grupo" onClose={this.handleClose} isModal={this.isModal()} container={{sizing: Container.sizing.xxl, className : ""}}>
							<PanelBody>
								<ListGroup>
                                    {this.props.grupos.map((item, index) => (
                                        <ListGroupItem className="pt-3 pb-3" key={index} action={true} onClick={e => this.handleClickRow(e, item, index)}>
                                            <InputRadio refParam={parseInt(this.state.grupo?.idGrupo) === parseInt(item.idGrupo) ? this.refRadio : null} name="grupo" checked={parseInt(this.state.grupo?.idGrupo) === parseInt(item.idGrupo)} value={parseInt(item.idGrupo)}/>
                                            &nbsp;&nbsp;{item.nmGrupo} 
                                        </ListGroupItem>
                                    ))}                                    
                                </ListGroup>
							</PanelBody>
							<PanelFooter>
                                <BtnSubmit sizing={BtnButton.sizing.default}  color={BtnButton.color.primary} > Selecionar </BtnSubmit>
                                <BtnButton sizing={BtnButton.sizing.default}  color={BtnButton.color.outlineSecondary} onClick={this.handleClose}> Cancelar </BtnButton>
							</PanelFooter>
						</Panel>
					</Form>
				</Div>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <FormLoginGrupo {...props} navigate={navigate} params={params} location={location}/>
}
export default With