import Http from "../controllers/Http";

export default class VwUsuarioTipoOrdemService {

	static urlBase = "/vwUsuarioTipoOrdem/";

	static getForCombo(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(VwUsuarioTipoOrdemService.urlBase + 'forCombo', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static list(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(VwUsuarioTipoOrdemService.urlBase + 'list', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneById(id, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.get(VwUsuarioTipoOrdemService.urlBase + id)
			.then(fnSucess)
			.catch(fnError);
	}


	static getAllByUsuario(usuario, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(VwUsuarioTipoOrdemService.urlBase + 'usuario', usuario)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByUsuario(usuario, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(VwUsuarioTipoOrdemService.urlBase + 'usuario/' + usuario.idUsuario)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByTipoOrdem(tipoOrdem, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(VwUsuarioTipoOrdemService.urlBase + 'tipoOrdem', tipoOrdem)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByTipoOrdem(tipoOrdem, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(VwUsuarioTipoOrdemService.urlBase + 'tipoOrdem/' + tipoOrdem.idTipoOrdem)
			.then(fnSucess)
			.catch(fnError);
	}
}