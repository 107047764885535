//#NO-CHANGE-FILE#

import Http from "../controllers/Http";

export default class ComissaoService {

	static urlBase = "/comissao/";

	static processar(comissao, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(ComissaoService.urlBase + 'processar', comissao)
			.then(fnSucess)
			.catch(fnError);
	}

	static gerarPagamento(comissao, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(ComissaoService.urlBase + 'pagamento', comissao)
			.then(fnSucess)
			.catch(fnError);
	}

	static estornar(comissao, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(ComissaoService.urlBase + 'estornar', comissao)
			.then(fnSucess)
			.catch(fnError);
	}

	static getForCombo(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(ComissaoService.urlBase + 'forCombo', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static list(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(ComissaoService.urlBase + 'list', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneById(id, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.get(ComissaoService.urlBase + id)
			.then(fnSucess)
			.catch(fnError);
	}

	static create(data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(ComissaoService.urlBase, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static update(id, data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.put(ComissaoService.urlBase + id, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static delete(id, fnSucess = () => { }, fnError = () => { }) {
		if(Number.isInteger(id)){
			Http.getInstanceAppJson()
				.delete(ComissaoService.urlBase + id)
				.then(fnSucess)
				.catch(fnError);
		}else{
			Http.getInstanceAppJson()
			.post(ComissaoService.urlBase + 'delete', id)
			.then(fnSucess)
			.catch(fnError);
		}
	}

	static save(data, fnSucess = () => { }, fnError = () => { }) {
		if (data.idComissao) {
			this.update(data.idComissao, data, fnSucess, fnError);
		} else {
			this.create(data, fnSucess, fnError);
		}
	}

	static getAllByTipoValor(tipoValor, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(ComissaoService.urlBase + 'tipoValor', tipoValor)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByTipoValor(tipoValor, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(ComissaoService.urlBase + 'tipoValor/' + tipoValor.idTipoValor)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByTipoComissao(tipoComissao, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(ComissaoService.urlBase + 'tipoComissao', tipoComissao)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByTipoComissao(tipoComissao, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(ComissaoService.urlBase + 'tipoComissao/' + tipoComissao.idTipoComissao)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByStatusComissao(statusComissao, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(ComissaoService.urlBase + 'statusComissao', statusComissao)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByStatusComissao(statusComissao, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(ComissaoService.urlBase + 'statusComissao/' + statusComissao.idStatusComissao)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByUsuario(usuario, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(ComissaoService.urlBase + 'usuario', usuario)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByUsuario(usuario, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(ComissaoService.urlBase + 'usuario/' + usuario.idUsuario)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByComissaoCargo(comissaoCargo, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(ComissaoService.urlBase + 'comissaoCargo', comissaoCargo)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByComissaoCargo(comissaoCargo, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(ComissaoService.urlBase + 'comissaoCargo/' + comissaoCargo.idComissaoCargo)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByComissaoPessoa(comissaoPessoa, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(ComissaoService.urlBase + 'comissaoPessoa', comissaoPessoa)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByComissaoPessoa(comissaoPessoa, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(ComissaoService.urlBase + 'comissaoPessoa/' + comissaoPessoa.idComissaoPessoa)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByComissaoHistorico(comissaoHistorico, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(ComissaoService.urlBase + 'comissaoHistorico', comissaoHistorico)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByComissaoHistorico(comissaoHistorico, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(ComissaoService.urlBase + 'comissaoHistorico/' + comissaoHistorico.idComissaoHistorico)
			.then(fnSucess)
			.catch(fnError);
	}
}