import React, { Component } from "react";

export default class GoogleMapsMarker extends Component {

  static SymbolPath = {
    "CIRCLE" : 0
  };

  render() {
    return (
      <></>
    );
  }
}
