import Config from "./Config";
import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import SelectTipoPagamento from "../../components/SelectDB/SelectTipoPagamento";
import SelectTipoTransacao from "../../components/SelectDB/SelectTipoTransacao";
import SelectPlugin from "../../components/SelectDB/SelectPlugin";
import SelectContaTipoPagamento from "../../components/SelectDB/SelectContaTipoPagamento";
import SelectConta from "../../components/SelectDB/SelectConta";
import ListFaturaCobrancaTipoPagamentoParcela from "../FaturaCobrancaTipoPagamentoParcela/ListFaturaCobrancaTipoPagamentoParcela";
import Permissao from "../../controllers/Permissao";
import { BaseList, BaseCrud } from "../../siht/base";
import { FormButtons, FormItem, Row, TabNavigator, Tab, Panel, PanelBody, PanelFooter, Container } from "../../siht/components";
import { InputText, InputNumber, Form, BtnSubmit } from "../../siht/elements";
import { BtnRadioGroupSimNao } from "../../components/Custom";

class FormFaturaCobrancaTipoPagamento extends BaseCrud {

	constructor(props) {
		super(props);
		this.init = this.init.bind(this);
		this.initModel = this.initModel.bind(this);
		this.handleSuccess = this.handleSuccess.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleChangeModel = this.handleChangeModel.bind(this);
		this.handleSubmitForm = this.handleSubmitForm.bind(this);
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		super.init(Config);
	}

	initModel(){
		return {
			idFaturaCobrancaTipoPagamento : null,
			faturaCobranca : this.props.parent !== undefined ? this.props.parent : {},
			tipoPagamento : null,
			tipoTransacao : null,
			plugin : null,
			contaTipoPagamento : null,
			nrParcelaMaxima : null,
			nrDiaAgenda : null,
			cdUid : "",
			flAtivo : true,
			conta : null,
			faturaCobrancaTipoPagamentoParcela : []
		}
	}

	handleSuccess(e) {
		super.handleSuccess(e, Config);
	}

	handleClose(e) {
		super.handleClose(e, Config);
	}

	render() {
		return super.render(

					<Panel key={this.state.key} title="Cadastro de FaturaCobrancaTipoPagamento" onClose={this.handleClose} isModal={this.isModal()} container={{sizing: Container.sizing.xxl, className : ""}}>
						<PanelBody className="p-0 m-0">
							<TabNavigator key={this.state.key} onChangeTab={this.handleChangeTab} className="border-light">
								<Tab title="FaturaCobrancaTipoPagamento" className="m-2">
									<Form onSubmit={this.handleSuccess} refParam={f => (this.form = f)}>
										<Row className="g-3 pb-2 px-2">
											<FormItem md={6} label="Código">
												<InputText name="idFaturaCobrancaTipoPagamento" value={this.state.model.idFaturaCobrancaTipoPagamento} readOnly={true} className="readonly-field" required={false} onChange={this.handleChangeModel}/>
											</FormItem>
											<FormItem md={12} label="Nome do TipoPagamento">
												<SelectTipoPagamento name="tipoPagamento" value={this.state.model.tipoPagamento} required={true} onChange={this.handleChangeModel} placeholder="Selecione Nome do TipoPagamento"/>
											</FormItem>
											<FormItem md={12} label="Nome do TipoTransacao">
												<SelectTipoTransacao name="tipoTransacao" value={this.state.model.tipoTransacao} required={true} onChange={this.handleChangeModel} placeholder="Selecione Nome do TipoTransacao"/>
											</FormItem>
											<FormItem md={12} label="Nome do Plugin">
												<SelectPlugin name="plugin" value={this.state.model.plugin} required={true} onChange={this.handleChangeModel} placeholder="Selecione Nome do Plugin"/>
											</FormItem>
											<FormItem md={12} label="Nome do ContaTipoPagamento">
												<SelectContaTipoPagamento name="contaTipoPagamento" value={this.state.model.contaTipoPagamento} required={true} onChange={this.handleChangeModel} placeholder="Selecione Nome do ContaTipoPagamento"/>
											</FormItem>
											<FormItem md={6} label="NrParcelaMaxima">
												<InputNumber name="nrParcelaMaxima" value={this.state.model.nrParcelaMaxima} required={false} onChange={this.handleChangeModel}/>
											</FormItem>
											<FormItem md={6} label="NrDiaAgenda">
												<InputNumber name="nrDiaAgenda" value={this.state.model.nrDiaAgenda} required={false} onChange={this.handleChangeModel}/>
											</FormItem>
											<FormItem md={6} label="CdUid">
												<InputText name="cdUid" value={this.state.model.cdUid} maxLength={100} required={true} onChange={this.handleChangeModel}/>
											</FormItem>
											<FormItem md={2} label="Ativo?">
												<BtnRadioGroupSimNao name="flAtivo" value={this.state.model.flAtivo} required={true} onChange={this.handleChangeModel}/>
											</FormItem>
											<FormItem md={12} label="Conta">
												<SelectConta name="conta" value={this.state.model.conta} required={true} onChange={this.handleChangeModel} placeholder="Selecione Conta"/>
											</FormItem>
											<BtnSubmit hidden > Enviar </BtnSubmit>
										</Row>
									</Form>
								</Tab>
								<Tab show={Permissao.getInstance().getPermissoes([69])} title="Parcelas" disabled={!this.state.model.idFaturaCobrancaTipoPagamento} opened={false} className="m-2">
									<ListFaturaCobrancaTipoPagamentoParcela parent={this.state.model} localOpen={BaseList.localOpen.modal}/>
								</Tab>
							</TabNavigator>
						</PanelBody>
						<PanelFooter>
							<FormButtons show={this.state.tabIndex === 0} onClose={this.handleClose} onSubmit={this.handleSubmitForm}/>
						</PanelFooter>
					</Panel>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <FormFaturaCobrancaTipoPagamento {...props} navigate={navigate} params={params} location={location}/>
}
export default With