import EventBus from "../../controllers/EventBus";

export default class Modal {

    static sizing = {
        sm : "sm",
        lg : "lg",
        xl : "xl",
        fullscreen : "fullscreen",
        default : ""
    };

    static style = {
        danger : "danger",
        primary : "primary",        
        warning : "warning",
    };

    static open(window, config, props, handleSuccess = () => { }, handleClose = () => { }) {
        EventBus.dispatch("modal-add",{
            window : window,            
            config : config,
            props : props,
            handleSuccess : handleSuccess,
            handleClose : handleClose
        });
    }

    static openWindow(window, config = {}, props = {}, handleSuccess = () => { }, handleClose = () => { }) {

        config.noCard = true;
        config.sizing = config.sizing || Modal.sizing.lg;

        EventBus.dispatch("modal-add", {
            window : window,            
            config : config,
            props : props,
            handleSuccess : handleSuccess,
            handleClose : handleClose
        });
    }

    static confirmLg(title, message, description, style, handleConfirm = () => { }, handleClose = () => { }) {
        EventBus.dispatch("modal-add",{
            config : {
                modalConfirm : true,
                sizing : Modal.sizing.lg,
                scrollable : true
            },
            props : {
                title : title,
                message : message,
                description : description,
                style : style ? style : "primary"
            },
            handleSuccess : handleConfirm,
            handleClose : handleClose
        });
    }

    static confirm(title, message, description, style, handleConfirm = () => { }, handleClose = () => { }) {
        EventBus.dispatch("modal-add",{
            config : {
                modalConfirm : true,
            },
            props : {
                title : title,
                message : message,
                description : description,
                style : style ? style : "primary"
            },
            handleSuccess : handleConfirm,
            handleClose : handleClose
        });
    }

    static info(title, message, description, style, handleConfirm = () => { }, handleClose = () => { }) {
        EventBus.dispatch("modal-add",{
            config : {
                modalInfo : true
            },
            props : {
                title : title,
                message : message,
                description : description,
                style : style ? style : "primary",                
            },
            handleSuccess : handleConfirm,
            handleClose : handleClose
        });
    }
}
