import Config from "./Config";
import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { BaseCrud } from "../../siht/base";
import { FormButtons, FormItem, Row, Panel, PanelBody, PanelFooter, Container, ModalDialog } from "../../siht/components";
import { InputText, InputDate, InputNumberFormat, Form } from "../../siht/elements";

class FormVeiculoResumo extends BaseCrud {

	constructor(props) {
		super(props);
		this.init = this.init.bind(this);
		this.initModel = this.initModel.bind(this);
		this.handleSuccess = this.handleSuccess.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleChangeModel = this.handleChangeModel.bind(this);
		this.handleSubmitForm = this.handleSubmitForm.bind(this);
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		super.init(Config);
	}

	initModel(){
		return {
			idVeiculoResumo : null,
			veiculo : this.props.parent !== undefined ? this.props.parent : {},
			dtResumo : "",
			vlVelocidadeMedia : 0,
			vlVelocidadeMaxima : 0,
			vlDistaciaPercorrida : 0,
			vlTempoDesligado : 0,
			vlTempoLigado : 0,
			vlTempoLigadoMovimento : 0,
			vlTempoLigadoParado : 0,
			vlTempoDesligadoParado : 0
		}
	}

	handleSuccess(e) {
		super.handleSuccess(e, Config);
	}

	handleClose(e) {
		super.handleClose(e, Config);
	}

	render() {
		return super.render(

					<Form onSubmit={this.handleSuccess} refParam={f => (this.form = f)} key={this.state.key}>
						<Panel key={this.state.key} title="Cadastro de VeiculoResumo" localOpen={this.props.localOpen}  onClose={this.handleClose} isModal={this.isModal()} container={{sizing: Container.sizing.xxl, className : ""}} modal={{sizing: this.props.sizing ? this.props.sizing : ModalDialog.sizing.xl}}>
							<PanelBody>
								<Row className="g-3 pb-2 px-2">
									<FormItem md={6} label="Código">
										<InputText name="idVeiculoResumo" value={this.state.model.idVeiculoResumo} readOnly={true} className="readonly-field" required={false} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={4} label="DtResumo">
										<InputDate name="dtResumo" value={this.state.model.dtResumo} required={true} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={4} label="VlVelocidadeMedia">
										<InputNumberFormat name="vlVelocidadeMedia" value={this.state.model.vlVelocidadeMedia} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} prefix="R$ " required={true} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={4} label="VlVelocidadeMaxima">
										<InputNumberFormat name="vlVelocidadeMaxima" value={this.state.model.vlVelocidadeMaxima} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} prefix="R$ " required={true} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={4} label="VlDistaciaPercorrida">
										<InputNumberFormat name="vlDistaciaPercorrida" value={this.state.model.vlDistaciaPercorrida} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} prefix="R$ " required={true} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={4} label="VlTempoDesligado">
										<InputNumberFormat name="vlTempoDesligado" value={this.state.model.vlTempoDesligado} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} prefix="R$ " required={true} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={4} label="VlTempoLigado">
										<InputNumberFormat name="vlTempoLigado" value={this.state.model.vlTempoLigado} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} prefix="R$ " required={true} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={4} label="VlTempoLigadoMovimento">
										<InputNumberFormat name="vlTempoLigadoMovimento" value={this.state.model.vlTempoLigadoMovimento} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} prefix="R$ " required={true} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={4} label="VlTempoLigadoParado">
										<InputNumberFormat name="vlTempoLigadoParado" value={this.state.model.vlTempoLigadoParado} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} prefix="R$ " required={true} onChange={this.handleChangeModel}/>
									</FormItem>
									<FormItem md={4} label="VlTempoDesligadoParado">
										<InputNumberFormat name="vlTempoDesligadoParado" value={this.state.model.vlTempoDesligadoParado} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} prefix="R$ " required={true} onChange={this.handleChangeModel}/>
									</FormItem>
								</Row>
							</PanelBody>
							<PanelFooter>
								<FormButtons onClose={this.handleClose} />
							</PanelFooter>
						</Panel>
					</Form>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <FormVeiculoResumo {...props} navigate={navigate} params={params} location={location}/>
}
export default With