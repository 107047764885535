import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { BtnSubmit, Div, Form, Input, InputCardCVV, InputCardNumber, InputCardValidate, InputText, Select } from "../../siht/elements";
import FaturaCobrancaTransacaoService from "../../services/FaturaCobrancaTransacaoService";
import {  FormItem, Row } from "../../siht/components";
import Config from "./Config";
import SelectFaturaCobrancaTipoPagamentoParcela from "../../components/SelectDB/SelectFaturaCobrancaTipoPagamentoParcela";
import FormPagar from "./FormPagar";

class FormPagarCartaoCredito extends FormPagar {

	constructor(props) {
		super(props);
		this.init = this.init.bind(this);
		this.initModel = this.initModel.bind(this);
		this.handleSuccess = this.handleSuccess.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleChangeModel = this.handleChangeModel.bind(this);
		this.handleSubmitForm = this.handleSubmitForm.bind(this);
        this.handleSuccess = this.handleSuccess.bind(this);

        this.handleChangeParcela = this.handleChangeParcela.bind(this);

        this.state.model.cartao = {
            nmImpresso : "",
            nrCartao : "",
            dtValidate : "",
            nrCvv : ""
        };
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
        super.init();

        this.setModel({
            cartao : {
                nmImpresso : "TESTE",
                nrCartao : "5555555555554444",
                dtValidade : "06/2030",
                nrCvv : "123"
            }
        });
	}

    handleChangeParcela(){
        if(this.state.model.faturaCobrancaTransacao.faturaCobrancaTipoPagamentoParcela){
            var payload = {
                faturaCobranca : this.state.model.faturaCobrancaTransacao.faturaCobranca,
                faturaCobrancaTipoPagamento : this.state.model.faturaCobrancaTransacao.faturaCobrancaTipoPagamentoParcela.faturaCobrancaTipoPagamento,
                faturaCobrancaTipoPagamentoParcela : this.state.model.faturaCobrancaTransacao.faturaCobrancaTipoPagamentoParcela,
            };

            FaturaCobrancaTransacaoService.getAtiva(payload, (faturaCobrancaTransacao) =>{
                this.props.navigate(Config.getUrlPagamento(faturaCobrancaTransacao), { replace: true });
                this.init();
            });
        }
    }

    handleSuccess(){
        FaturaCobrancaTransacaoService.pagarCartaoCredito(this.state.model, (response)=>{
            this.props.navigate('/pagamento/' + this.state.model?.faturaCobrancaTransacao?.faturaCobranca?.cdUid);
        })
    }

	render() {

		return super.render (
            <>
                <Form onSubmit={this.handleSuccess} refParam={f => (this.form = f)} key={this.state.key}>
                    <Row className="mb-2 g-3 mt-2">
                        <FormItem md={12} label={this.getLabelFormItem("Parcela(s)")}>
                            <SelectFaturaCobrancaTipoPagamentoParcela name="faturaCobrancaTipoPagamentoParcela" value={this.state.model?.faturaCobrancaTransacao?.faturaCobrancaTipoPagamentoParcela} filter={{faturaCobrancaTipoPagamento : this.state.model?.faturaCobrancaTransacao?.faturaCobrancaTipoPagamentoParcela?.faturaCobrancaTipoPagamento}} sizing={Select.sizing.lg} required={true} onChange={(e,v,p) => {if(!v){return}; this.handleChange(e,v,p,this.state.model?.faturaCobrancaTransacao, this.handleChangeParcela)}}/>
                        </FormItem>
                        <FormItem md={12} label={this.getLabelFormItem("Número do Cartão")}>
                            <InputCardNumber sizing={Input.sizing.lg} name="nrCartao" value={this.state.model?.cartao?.nrCartao} autoFocus={true} required={true} onChange={(e,v,p) => this.handleChange(e,v,p,this.state.model?.cartao)}/>
                        </FormItem>
                        <FormItem md={12} label={this.getLabelFormItem("Nome Impresso no Cartão")}>
                            <InputText sizing={Input.sizing.lg} name="nmImpresso" value={this.state.model?.cartao?.nmImpresso} maxLength={100} required={true} onChange={(e,v,p) => this.handleChange(e,v,p,this.state.model?.cartao)}/>
                        </FormItem>
                        <FormItem md={6} label={this.getLabelFormItem("Validade")}>
                            <InputCardValidate sizing={Input.sizing.lg} name="dtValidade" value={this.state.model?.cartao?.dtValidade} required={true} onChange={(e,v,p) => this.handleChange(e,v,p,this.state.model?.cartao)}/>
                        </FormItem>
                        <FormItem md={6} label={this.getLabelFormItem("CVV")}>
                            <InputCardCVV sizing={Input.sizing.lg} name="nrCvv" value={this.state.model?.cartao?.nrCvv} required={true} onChange={(e,v,p) => this.handleChange(e,v,p,this.state.model?.cartao)}/>
                        </FormItem>
                    </Row>
                    <Div className="hstack gap-2 mt-4 mb-4">
                        <BtnSubmit sizing={BtnSubmit.sizing.lg} color={BtnSubmit.color.primary}> Confirmar Pagamento </BtnSubmit>
                    </Div>
                </Form>
            </>
                    
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <FormPagarCartaoCredito {...props} navigate={navigate} params={params} location={location}/>
}
export default With