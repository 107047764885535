import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BaseComponent } from "../../siht/base";
import InputCidade from "../../components/InputDB/InputCidade";
import SelectPais from "../../components/SelectDB/SelectPais";
import SelectEstado from "../../components/SelectDB/SelectEstado";
import { FormItem } from "../../siht/components";

class FormItemPaisEstadoCidade extends BaseComponent {

	constructor(props) {
		super(props);
		this.init = this.init.bind(this);
		this.handleChangePais = this.handleChangePais.bind(this);
		this.handleChangeEstado = this.handleChangeEstado.bind(this);
		this.handleChangeCidade = this.handleChangeCidade.bind(this);
	}

	componentDidMount() {
		super.componentDidMount();
		this.init();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init() {

	}

	handleChangePais(e, v, p) {
		var value = { estado: { pais: v ? v : {} } };
		this.props.onChange(e, value, this.props.name);
	}

	handleChangeEstado(e, v, p) {
		var value = { estado: v ? v : { pais: this.props.value?.estado?.pais } }
		this.props.onChange(e, value, this.props.name);
	}

	handleChangeCidade(e, v, p) {
		var value = v ? v : { estado: this.props.value?.estado }
		this.props.onChange(e, value, this.props.name);
	}

	render() {

		var propsFormItem = ["col", "xs", "sm", "md", "lg", "xl", "xxl"];
		var propsItem = ["required", "buttons", "label", "placeholder", "disabled"];

		return super.render(
			<React.Fragment>
				<FormItem label={this.props.label !== undefined && this.props.label[0] ? this.props.label[0] : "Pais"} {...(this.getProps(propsFormItem, 0))}>
					<SelectPais {...(this.getProps(propsItem, 0))} name="pais" value={this.props.value?.estado?.pais} onChange={this.handleChangePais} placeholder="Selecione o Pais" />
				</FormItem>
				<FormItem label={this.props.label !== undefined && this.props.label[1] ? this.props.label[1] : "Estado"} {...(this.getProps(propsFormItem, 1))}>
					<SelectEstado {...(this.getProps(propsItem, 1))} getOptionsWhen={f => f?.pais?.idPais !== undefined} filter={{ pais: this.props.value?.estado?.pais }} name="estado" value={this.props.value?.estado} onChange={this.handleChangeEstado} placeholder="Selecione Nome do Estado" />
				</FormItem>
				<FormItem label={this.props.label !== undefined && this.props.label[2] ? this.props.label[2] : "Cidade"} {...(this.getProps(propsFormItem, 2))}>
					<InputCidade {...(this.getProps(propsItem, 2))} getOptionsWhen={f => f?.estado?.idEstado !== undefined} name="cidade" value={this.props.value} filter={{ estado: this.props.value?.estado }} onChange={this.handleChangeCidade} placeholder="Selecione Nome do Cidade" />
				</FormItem>
			</React.Fragment>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	return <FormItemPaisEstadoCidade {...props} navigate={navigate} params={params} />
}
export default With