import React, { Component } from "react";

export default class BtnGroup extends Component {

    static border = {
        primary : "primary",
        dark : "dark",
    }

    static color = {
        primary : "primary",
        success : "success",
        danger : "danger",
        warning : "warning",
        dark : "dark"
    };

    static direction = {
        vertical : "vertical",
        horizontal : "",
    };

    static sizing = {
        sm: "sm",
        default: false,
        lg: "lg",
    }

    render() {
        const props = Object.assign({}, this.props);
        
        if (this.props.show !== undefined && !Boolean(this.props.show)) {
            return (<></>);
        }

        var direction = null;

        if(props.direction !== undefined && props.direction === BtnGroup.direction.vertical){
            direction = BtnGroup.direction.vertical;
        }
        
        props.className = `btn-group${direction ? `-${direction}` : ""} w-100 ${props.className !== undefined ? props.className : ""} ${props.sizing !== undefined && props.sizing ? `btn-group-${props.sizing}` : ""} ${props.border ? `btn-group-custom-${props.border}` : ""}`;
        delete props.show;
        delete props.opcional;
        delete props.direction;
        delete props.sizing;
        
        return (
            <div {...props} role="group" aria-label="Basic radio toggle button group">
                {this.props.children}
            </div>
        );


    }
}