//#NO-CHANGE-FILE#

import Http from "../controllers/Http";

export default class DadoValorService {

	static urlBase = "/dadoValor/";

	static getValues(keys, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(DadoValorService.urlBase + 'values', keys)
			.then(fnSucess)
			.catch(fnError);
	}

	static getPublicValues(keys, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post('/public' + DadoValorService.urlBase + 'values', keys)
			.then(fnSucess)
			.catch(fnError);
	}

	static getForCombo(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(DadoValorService.urlBase + 'forCombo', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static list(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(DadoValorService.urlBase + 'list', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static listByParent(filter, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(DadoValorService.urlBase + 'listByParent', filter)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneById(id, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.get(DadoValorService.urlBase + id)
			.then(fnSucess)
			.catch(fnError);
	}

	static create(data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.post(DadoValorService.urlBase, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static update(id, data, fnSucess = () => { }, fnError = () => { }) {
		Http.getInstanceAppJson()
			.put(DadoValorService.urlBase + id, data)
			.then(fnSucess)
			.catch(fnError);
	}

	static delete(id, fnSucess = () => { }, fnError = () => { }) {
		if(Number.isInteger(id)){
			Http.getInstanceAppJson()
				.delete(DadoValorService.urlBase + id)
				.then(fnSucess)
				.catch(fnError);
		}else{
			Http.getInstanceAppJson()
			.post(DadoValorService.urlBase + 'delete', id)
			.then(fnSucess)
			.catch(fnError);
		}
	}

	static save(data, fnSucess = () => { }, fnError = () => { }) {
		if (data.idDadoValor) {
			this.update(data.idDadoValor, data, fnSucess, fnError);
		} else {
			this.create(data, fnSucess, fnError);
		}
	}

	static getAllByDado(dado, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(DadoValorService.urlBase + 'dado', dado)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByDado(dado, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(DadoValorService.urlBase + 'dado/' + dado.idDado)
			.then(fnSucess)
			.catch(fnError);
	}

	static getAllByArquivo(arquivo, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.post(DadoValorService.urlBase + 'arquivo', arquivo)
			.then(fnSucess)
			.catch(fnError);
	}

	static getOneByArquivo(arquivo, fnSucess = () => { }, fnError = () => { }) {;
		Http.getInstanceAppJson()
			.get(DadoValorService.urlBase + 'arquivo/' + arquivo.idArquivo)
			.then(fnSucess)
			.catch(fnError);
	}
}