import { Component } from "react";

export default class ModalTitle extends Component {

    render() {
        if(this.props.show !== undefined && !Boolean(this.props.show)){
            return (<></>);
        }

        const props = Object.assign({}, this.props);
        props.className = `modal-title ${props.className !== undefined ? props.className : ""}`;

        delete props.show;

        return (            
            <h5 {...props}> {this.props.children} </h5>
        );
    }
}