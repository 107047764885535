import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { BaseCrud } from "../../siht/base";
import { FormButtons, FormItem, Row, Panel, PanelBody, PanelFooter, Container, ModalDialog, Col } from "../../siht/components";
import { Div, Form } from "../../siht/elements";
import Config from "./Config";
import SelectAnsStatus from "../../components/SelectDB/SelectAnsStatus";
import { StatusCustom } from "../../components/Custom";

class FormAlterarStatus extends BaseCrud {

	constructor(props) {
		super(props);
		this.init = this.init.bind(this);
		this.handleSuccess = this.handleSuccess.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleChangeModel = this.handleChangeModel.bind(this);
		this.handleSubmitForm = this.handleSubmitForm.bind(this);

		this.ansStatus = Object.assign({}, this.props.ansPerfil.ansStatus);
		this.state.model = Object.assign({}, this.props.ansPerfil);
		this.state.model.txObservacao = "";		
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		super.init(Config);
	}

	handleSuccess(e) {
        Config.getService().alterarStatus(this.state.model, response => {			
			this.props.handleSuccess(e, response);
        });
	}

	handleClose(e) {
		super.handleClose(e, Config);
	}

	render() {
		return super.render(
				<Div key={this.state.key}>
					<Form onSubmit={this.handleSuccess} refParam={f => (this.form = f)}>
						<Panel title="Alteração do Status do Perfil" onClose={this.handleClose} isModal={this.isModal()} container={{sizing: Container.sizing.xxl, className : ""}} modal={{sizing: this.props.sizing ? this.props.sizing : ModalDialog.sizing.xl}}>
							<PanelBody>
								<Row className="g-3 pb-2 px-2 align-items-center">
									<FormItem md={5} label="Status Atual" show={this.state.model.idAnsPerfil}>
										<StatusCustom opcional={true} type="InputText" className="w-100 text-center" color={this.ansStatus}>{this.ansStatus?.nmAnsStatus}</StatusCustom>
									</FormItem>
									<Col md={2} className="text-center fw-bold align-middle h-100">
										PARA
									</Col>
                                    <FormItem md={5} label="Status Novo" show={this.state.model.idAnsPerfil}>
                                        <SelectAnsStatus isDisabled={(item) => parseInt(item.idAnsStatus) === parseInt(this.ansStatus.idAnsStatus)} autoFocus buttons={{search: false,new : false,edit : false,clear : false }} name="ansStatus" value={this.state.model.ansStatus} required={true} onChange={this.handleChangeModel} placeholder="Selecione o Status"/>
                                    </FormItem>									
								</Row>
							</PanelBody>
							<PanelFooter>
								<FormButtons label={["Alterar Status"]} onClose={this.handleClose} />
							</PanelFooter>
						</Panel>
					</Form>
				</Div>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <FormAlterarStatus {...props} navigate={navigate} params={params} location={location}/>
}
export default With