//#NO-CHANGE-FILE#
import React from "react";
import { useNavigate, useLocation, useParams, Link } from "react-router-dom";
import Config from "./Config";
import { BadgeSimNao, StatusCustom } from "../../components/Custom";
import {ThreeDotsVertical as IconThreeDotsVertical} from "react-bootstrap-icons"
import { BaseList } from "../../siht/base";
import { DataGrid, DataGridButtons, DataGridColumn, DropDown, DropDownLabel, DropDownItem, Badge } from "../../siht/components";
import { Button, Div, InputCheckSwitch, Span } from "../../siht/elements";
import Util from "../../utils/Util";
import { Modal } from "../../siht/controller";
import FormFaturaCobranca from "./FormFaturaCobranca";
import ManyToManyCheckFaturaCobrancaTipoPagamentoByTipoPagamento from "./ManyToManyCheckFaturaCobrancaTipoPagamentoByTipoPagamento";
import { ArrowLeftRight as IconArrowLeftRight, Calendar2Week as IconCalendar2Week, Check2Circle as IconCheck2Circle,  CurrencyExchange as IconCurrencyExchange, EyeFill as IconEyeFill } from "react-bootstrap-icons";
import ListFaturaCobrancaTransacao from "./ListFaturaCobrancaTransacao";
import ListFaturaCobrancaBaixa from "./ListFaturaCobrancaBaixa";
import FormFaturaCobrancaBaixaManual from "./FormFaturaCobrancaBaixaManual";
import FormFaturaCobrancaAlterarVencimento from "./FormFaturaCobrancaAlterarVencimento";

class ListFaturaCobranca extends BaseList {

	constructor(props) {
		super(props);
		this.getItens = this.getItens.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleNew = this.handleNew.bind(this);
		this.handleEdit = this.handleEdit.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.handleMore = this.handleMore.bind(this);
		this.onSubmitFilter = this.onSubmitFilter.bind(this);
		this.handleClear = this.handleClear.bind(this);
		this.handleFormSuccess = this.handleFormSuccess.bind(this);
		this.handleFormClose = this.handleFormClose.bind(this);
		this.handleVerCobranca = this.handleVerCobranca.bind(this);
		this.handleBaixaManual = this.handleBaixaManual.bind(this);
		this.handleHistoricoBaixas = this.handleHistoricoBaixas.bind(this);
		this.handleHistoricoTransacoes = this.handleHistoricoTransacoes.bind(this);
		this.handleAlterarVencimento = this.handleAlterarVencimento.bind(this);
		this.handleAlterarTipoPagamento = this.handleAlterarTipoPagamento.bind(this);

		this.state.flEstorno = false;
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init(){
		super.init(Config);
	}

	getItens(more){
		if(this.props?.parent){
			super.getItens(Config.getService().getAllByFatura, this.props.parent, more);
		}
	}

	handleNew(e) {
		super.handleNew(e, Config);
	}

	handleEdit(e, item) {
		super.handleEdit(e, Config, item);
	}

	handleDelete(e, item) {
		super.handleDelete(e, Config, item);
	}

	
	
	handleAlterarVencimento(e, item){
		Modal.openWindow(FormFaturaCobrancaAlterarVencimento, {sizing : Modal.sizing.xl}, { faturaCobranca : item }, (e, response) => { 
			this.init();
		});
	}

	handleHistoricoBaixas(e, item){
		Modal.openWindow(ListFaturaCobrancaBaixa, {sizing : Modal.sizing.fullscreen, scrollable : true}, { faturaCobranca : item }, (e, response) => { 
			this.init();
		}, () => {
			this.init();
		});
	}

	handleHistoricoTransacoes(e, item){
		Modal.openWindow(ListFaturaCobrancaTransacao, {sizing : Modal.sizing.fullscreen, scrollable : true}, { faturaCobranca : item }, (e, response) => { 
			this.init();
		}, () => {
			this.init();
		});
	}
	
	handleBaixaManual(e, item){
		Modal.openWindow(FormFaturaCobrancaBaixaManual, {sizing : Modal.sizing.xl}, { faturaCobranca : item }, (e, response) => { 
			this.init();
		});
	}
	
	handleAlterarTipoPagamento(e, item){
		Modal.open(ManyToManyCheckFaturaCobrancaTipoPagamentoByTipoPagamento, {sizing : Modal.sizing.lg}, { title : "Tipos de Pagamento", parent : item }, (e, response) => { 
			this.init();
		}, () => { 
			this.init();
		});
	}

	handleVerCobranca(e, item) {
		Modal.openWindow(FormFaturaCobranca, {sizing : Modal.sizing.xl}, { id : item.idFaturaCobranca }, (e, response) => { 
			this.init();
		}, () => { 
			this.init();
		});
	}

	render() {

		let data = this.state.data;

		if(this.props.parent.flEstorno){
			if(this.state.flEstorno){

			}else{
				data = data.filter(item => item.flEstorno === true && item.flEstornoFatura === true);
			}
		}else{
			if(this.state.flEstorno){
				//data = data.filter(item => item.flEstorno === false);
			}else{
				data = data.filter(item => item.flEstorno === false);	
			}
		}
		
		return super.render(
			<Div className="p-2 mt-2">

				<Div className={`d-flex flex-row-reverse`}>
					<InputCheckSwitch label="Mostrar cobranças estornadas?" name="flEstorno" checked={Boolean(this.state.flEstorno)} onChange={this.handleChange} />
				</Div>

				<DataGrid bordered={true} striped={true} data={data} search={false} isDisabled={i => false} sizing={DataGrid.sizing.sm} select={true} selecteds={this.state.selecteds} onSelect={(selecteds)=>{this.setState({selecteds : selecteds})}} getItemValue={i=>i.idFaturaCobranca} onMore={this.handleMore}>
					
					<DataGridColumn label="Cobrança" field={i => i.idFaturaCobranca} className="text-center"/>
					<DataGridColumn label="Parcela" field={i => i.nrParcela} className="text-center"/>
					<DataGridColumn col={1} label="Status" field={i => <StatusCustom className="w-100" color={i.statusFaturaCobranca}> {i.statusFaturaCobranca?.nmStatusFaturaCobranca} </StatusCustom>} className="text-center"/>
					
					<DataGridColumn col={3} label="Forma Pagamento" field={i => <React.Fragment>
						{i?.faturaCobrancaTipoPagamentos?.map((faturaCobrancaTipoPagamento, index) => (
							<StatusCustom key={index} color={faturaCobrancaTipoPagamento.tipoPagamento} className="fs-8 fw-normal">
								{faturaCobrancaTipoPagamento.tipoPagamento.nmTipoPagamento}
							</StatusCustom>
						))}
					</React.Fragment>} className="text-center"/>

					<DataGridColumn col={1} label="Encargos" field={i => (<React.Fragment>
						<Div className="fs-7">Juros: {i.flJuros ? <Span className="fw-bold"> {Util.floatToValor(i.vlJurosHoje, 2)} % </Span> : <BadgeSimNao value={i.flJuros}/>} </Div>
						<Div className="fs-7">Multa: {i.flMulta ? <Span className="fw-bold"> {Util.floatToValor(i.vlMultaHoje, 2)} % </Span> : <BadgeSimNao value={i.flMulta}/>} </Div>
					</React.Fragment>)} className="text-end"/>
					
					<DataGridColumn col={1} label="Cobrança" field={i => <React.Fragment>
						<Span className="fw-bold text-primary"> {Util.floatToReais(i.vlCobranca)}  </Span>
					</React.Fragment>} className="text-end"/>

					<DataGridColumn col={1} label="Aberto" field={i => <React.Fragment>
						<Div className={`fw-bold ${i.vlAberto > 0 ? "text-danger" : "text-secondary"}`}> {Util.floatToReais(i.vlAberto)}  </Div>
						
						{i.flVencido && (i.flJuros || i.flMulta) ?
							<React.Fragment>
								<Div className="fs-7">Juros: {i.flJuros ? <Span className={`fw-bold ${i.flJuros === 0 ? "text-secondary" : "text-danger"}`}> {Util.floatToReais(i.vlJurosHoje, 2)} </Span> : <></>} </Div>
								<Div className="fs-7">Multa: {i.flMulta ? <Span className={`fw-bold ${i.flMulta === 0 ? "text-secondary" : "text-danger"}`}> {Util.floatToReais(i.vlMultaHoje, 2)} </Span> : <></>} </Div>
							</React.Fragment> : <></>
						}
					</React.Fragment>} className="text-end"/>

					<DataGridColumn col={1} label="Cobrado" field={i => <React.Fragment>
						<Div className={`fw-bold ${i.vlCobrado > 0 ? "text-danger" : "text-secondary"}`}> {Util.floatToReais(i.vlCobrado)}  </Div>
					</React.Fragment>} className="text-end"/>

					<DataGridColumn col={2} label="Baixas" field={i => <React.Fragment>
						<Div className={`fw-bold ${i.vlBaixa === 0 ? "text-secondary" : "text-success"}`}> {Util.floatToReais(i.vlBaixa)}  </Div>
						<Div className={`fs-7`}> Pagamentos: <Span className={`fw-bold ${i.vlPago === 0 ? "text-secondary" : "text-success"}`}>{Util.floatToReais(i.vlPago)}</Span>  </Div>
						<Div className={`fs-7`}> Descontos: <Span className={`fw-bold ${i.vlDesconto === 0 ? "text-secondary" : "text-danger"}`}>{Util.floatToReais(i.vlDesconto)}</Span>  </Div>

						{i.vlBaixaEncargo > 0 ?
							<Div className={`fs-7`}> Encargos: <Span className={`fw-bold ${i.vlBaixaEncargo === 0 ? "text-secondary" : "text-danger"}`}>{Util.floatToReais(i.vlBaixaEncargo)}</Span>  </Div>
						: <></>}
					</React.Fragment>} className="text-end"/>

					<DataGridColumn col={1} label="Taxas" field={i => <React.Fragment>
						<Span className={`fw-bold ${i.vlBaixaTaxa === 0 ? "text-secondary" : "text-success"}`}> {Util.floatToReais(i.vlBaixaTaxa)}  </Span>
					</React.Fragment>} className="text-end"/>

					<DataGridColumn col={1} label="Total Baixa" field={i => <React.Fragment>
						<Span className={`fw-bold ${i.vlBaixaFinal === 0 ? "text-secondary" : "text-success"}`}> {Util.floatToReais(i.vlBaixaFinal)}  </Span>
					</React.Fragment>} className="text-end"/>

					<DataGridColumn col={1} label="Vencimento" field={i => <>
						<Div> {Util.date2Br(i.dtVencimento)} </Div>
						{i.flVencido && !i.statusFaturaCobranca.flPaga ? <Badge color={Badge.color.danger}>Vencido</Badge> : <></>}

					</>} className="text-center"/>

					<DataGridColumn col={1} label="Pagar" field={i => 
						(!i.statusFaturaCobranca.flEstornada && !i.statusFaturaCobranca.flPaga && i?.faturaCobrancaTipoPagamentos?.length > 0 ?
							<Link target="_blank" to={`/pagamento/${i.cdUid}`} className="btn btn-success"> Pagar </Link>
							: <></>
						)
					} className="text-center"/>

					<DataGridButtons>
						<DropDown alignment={DropDown.alignment.end}>
							<DropDownLabel sizing={Button.sizing.default} toggle={false}> <IconThreeDotsVertical/> </DropDownLabel>
							<DropDownItem title="Ver Cobrança" type="button" onClick={this.handleVerCobranca}> <IconEyeFill/> Ver Cobrança </DropDownItem>
							<DropDownItem title="Histórico de Baixas" type="button" onClick={this.handleHistoricoBaixas} disabled={this.props?.parent?.statusFatura?.flEstornada || !Config.getPermissaoCobranca().flPodeVerHistoricoBaixa}> <IconCheck2Circle/> Histórico de Baixas </DropDownItem>
							<DropDownItem title="Histórico de Transações" type="button" onClick={this.handleHistoricoTransacoes} disabled={this.props?.parent?.statusFatura?.flEstornada || !Config.getPermissaoCobranca().flPodeVerHistoricoTransacao}> <IconArrowLeftRight/> Histórico de Transações </DropDownItem>
							<DropDownItem title="Baixar Manualmente" type="button" onClick={this.handleBaixaManual} disabled={this.props?.parent?.statusFatura?.flEstornada || !Config.getPermissaoCobranca().flPodeBaixarManualmente}> <IconCurrencyExchange/> Baixar Manualmente </DropDownItem>
							<DropDownItem title="Alterar Vencimento" type="button" onClick={this.handleAlterarVencimento} disabled={this.props?.parent?.statusFatura?.flEstornada || !Config.getPermissaoCobranca().flPodeAlteraVencimento}> <IconCalendar2Week/> Alterar Vencimento </DropDownItem>
							{/*
							<DropDownItem title="Alterar Forma de Pagamento" type="button" onClick={this.handleAlterarTipoPagamento} disabled={this.props?.parent?.statusFatura?.flEstornada || !Config.getPermissaoCobranca().flPodeAlteraVencimento}> <IconCurrencyDollar/> Alterar Forma de Pagamento </DropDownItem>
							*/}
						</DropDown>
					</DataGridButtons>
				</DataGrid>
			</Div>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <ListFaturaCobranca {...props} navigate={navigate} params={params} location={location}/>
}
export default With