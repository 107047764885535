//#NO-CHANGE-FILE#
import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import SelectTipoOrdem from "../../components/SelectDB/SelectTipoOrdem";
import SelectTipoPreco from "../../components/SelectDB/SelectTipoPreco";
import ListOrdemItem from "../OrdemItem/ListOrdemItem";
import ListOrdemComentario from "../OrdemComentario/ListOrdemComentario";
import ListOrdemHistorico from "../OrdemHistorico/ListOrdemHistorico";
import ListAnsPerfilByOrdem from "../AnsPerfil/ListAnsPerfilByOrdem";
import Permissao from "../../controllers/Permissao";
import { BaseList, BaseCrud } from "../../siht/base";
import { FormButtons, FormItem, Row, TabNavigator, Tab, Panel, PanelBody, PanelFooter, Container, BtnRadioGroup, DropDown, DropDownItem, TabDropdown, Col, ModalDialog, InputGroup, InputGroupButton, InputMapOneMarker } from "../../siht/components";
import { InputText, InputNumberFormat, Div, Form, BtnSubmit, BtnRadio, Button, TextArea, BtnButton } from "../../siht/elements";
import Config from "./Config";
import Util from "../../utils/Util";
import { Modal } from "../../siht/controller";
import FormItemEmpresaUnidade from "../../components/FormItem/FormItemEmpresaUnidade";
import FormItemTipoPessoaPessoa from "../../components/FormItem/FormItemTipoPessoaPessoa";
import FormAlterarStatus from "./FormAlterarStatus";
import { IconClear, IconHandHoldingUsd, IconSearch } from "../../icons";
import { Coin as IconCoin, PrinterFill as IconPrinterFill, NodePlusFill as IconNodePlusFill, Recycle as IconRecycle, PersonFill as IconPersonFill, ChatSquareQuote as IconChatSquareQuote, ClockHistory as IconClockHistory, Crosshair2 as IconCrosshair2, Basket as IconBasket, CardChecklist as IconCardChecklist, MailboxFlag as IconMailboxFlag, PatchPlus as IconPatchPlus, ClipboardCheck as IconClipboardCheck, Paperclip as IconPaperclip, PeopleFill as IconPeopleFill } from "react-bootstrap-icons";
import FormPessoa from "../Pessoa/FormPessoa";
import FormRelatorioOrdem001 from "../Relatorio/FormRelatorioOrdem001";
import ListOrdemEndereco from "../OrdemEndereco/ListOrdemEndereco";
import ListOrdemArt from "../OrdemArt/ListOrdemArt";
import ListOrdemArquivo from "../OrdemArquivo/ListOrdemArquivo";
import ManyToManyCheckOrdemEquipeByEquipe from "./ManyToManyCheckOrdemEquipeByEquipe";
import ListOrdemPessoa from "../OrdemPessoa/ListOrdemPessoa";
import ListDadoValorByParent from "../DadoValor/ListDadoValorByParent";
import ListDadoValorByParentForm from "../DadoValor/ListDadoValorByParentForm";
import ConfigApp from "../../ConfigApp";
import { StatusCustom } from "../../components/Custom";
import { TipoOrdemRecursoOrdemService } from "../../services";
import { InputObra } from "../../components/InputDB";
import ListComissaoPessoaOrdemByOrdem from "../ComissaoPessoaOrdem/ListComissaoPessoaOrdemByOrdem";
import BtnRadioGroupSimNao from "../../components/BtnRadioGroupSimNao";
import { toast } from "react-toastify";


class FormOrdem extends BaseCrud {

	constructor(props) {
		super(props);
		this.init = this.init.bind(this);
		this.handleSuccess = this.handleSuccess.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleChangeModel = this.handleChangeModel.bind(this);
		this.handleSubmitForm = this.handleSubmitForm.bind(this);

		this.handleChangePercDesconto = this.handleChangePercDesconto.bind(this);
		this.handleChangeVlDesconto = this.handleChangeVlDesconto.bind(this);
		this.handleChangeTipoDesconto = this.handleChangeTipoDesconto.bind(this);

		this.handleAplicarDesconto = this.handleAplicarDesconto.bind(this);

		this.handleFaturar = this.handleFaturar.bind(this);
		this.handleImprimir = this.handleImprimir.bind(this);
		this.handleCancelar = this.handleCancelar.bind(this);
		this.handleReabrir = this.handleReabrir.bind(this);
		this.handleAlterarStatus = this.handleAlterarStatus.bind(this);
		this.handleVerFatura = this.handleVerFatura.bind(this);
		this.handleChangeTipoOrdem = this.handleChangeTipoOrdem.bind(this);
		this.getTipoOrdemRecurso = this.getTipoOrdemRecurso.bind(this);

		this.handleVerificaIdentificadorExterno = this.handleVerificaIdentificadorExterno.bind(this);
		this.handleVerificaPedidoExterno = this.handleVerificaPedidoExterno.bind(this);

		this.state.model = {
			idOrdem: null,
			unidade: null,
			pessoa: null,
			obra: null,
			localizacao: null,
			statusOrdem: {
				flPodeEditar: true
			},
			tipoOrdem: null,
			usuario: null,
			tipoPreco: null,
			txObservacao: "",
			pcDesconto: "",
			vlDesconto: "",
			flEstorno: true,
			ordemItem: [],
			ordemComentario: [],
			ansPerfil: [],
			cdTipoDesconto: "P",
			cdIdentificadorExterno: "",
			cdPedidoExterno: "",
			flPagarComissao: false,
			ordemDadoValor: []
		};

		this.state.tipoOrdemRecursoOrdem = [];
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	init() {
		super.init(Config, () => {
			this.setModel({ cdTipoDesconto: parseFloat(this.state.model.pcDesconto) > 0 || !this.state.model.idOrdem ? "P" : "V" });

			this.getTipoOrdemRecurso();

		});
	}

	handleSuccess(e) {
		super.handleSuccess(e, Config);
	}

	handleClose(e) {
		super.handleClose(e, Config);
	}

	handleChangeTipoOrdem() {
		this.getTipoOrdemRecurso();
	}

	getTipoOrdemRecurso() {
		if (parseInt(this.state.model?.tipoOrdem?.idTipoOrdem) > 0) {
			TipoOrdemRecursoOrdemService.getAllByTipoOrdem(this.state.model.tipoOrdem, (response) => {
				this.setState({ tipoOrdemRecursoOrdem: response });
			});
		}
	}

	handleChangePercDesconto(e, v, p) {
		let pcDesconto = parseFloat(this.state.model.pcDesconto) || 0;
		let vlDesconto = (parseFloat(this.state.model.vlFinalItem) * pcDesconto) / 100;
		let vlFinalOrdem = parseFloat(this.state.model.vlFinalItem) - vlDesconto;
		this.setModel({ vlDesconto: vlDesconto, vlFinalOrdem: vlFinalOrdem });
	}

	handleChangeVlDesconto(e, v, p) {
		let vlDesconto = this.state.model.vlDesconto;
		let vlFinalOrdem = parseFloat(this.state.model.vlFinalItem) - vlDesconto;
		this.setModel({ vlFinalOrdem: vlFinalOrdem });
	}

	handleChangeTipoDesconto() {
		if (this.state.model.cdTipoDesconto === "V") {
			this.setModel({ pcDesconto: 0 });
		}
	}

	handleAplicarDesconto(e, ordem) {
		Config.getService().aplicarDesconto(ordem, () => {
			this.init();
		})
	}

	//OPÇÕES DROPDOWN ORDEM

	handleFaturar(e, ordem) {
		this.props.navigate('/CadFaturar/' + ordem.idOrdem);
	}

	handleImprimir(e, ordem) {
		Modal.openWindow(FormRelatorioOrdem001, { sizing: Modal.sizing.default }, { idOrdens: [ordem.idOrdem] });
	}

	handleCancelar(e, ordem) {
		Modal.confirm("Confirmação", "Deseja realmente CANCELAR essa ordem?", null, Modal.style.danger, () => {
			Config.getService().cancelar(ordem, () => {
				this.init();
			});
		});
	}

	handleReabrir(e, ordem) {
		Modal.confirm("Confirmação", "Deseja realmente REABRIR essa ordem?", null, Modal.style.warning, () => {
			Config.getService().reabrir(ordem, () => {
				this.init();
			});
		});
	}

	handleAlterarStatus(e, ordem) {
		Modal.openWindow(FormAlterarStatus, { sizing: Modal.sizing.default }, { ordem: ordem }, (e, response) => {
			this.init();
		});
	}

	handleVerFatura(e, item = null) {
		this.props.navigate('/CadFatura/' + item?.fatura?.idFatura);
	}

	handleComissao(e) {
		this.props.navigate('/CadComissao', { state: { ordens: [this.state.model] } });
		return;
	}

	//OPÇÕES DROPDOWN ORDEM

	getRenderTotais() {

		if (!Config.getPermissaoOrdem(this.state.model).flPodeVerValorOrdem) {
			return (<></>);
		}

		return (
			<Row className="g-3 pb-3 px-2 justify-content-end" show={this.state.model.idOrdem}>
				<FormItem md={3} label="Valor Total Itens">
					<InputNumberFormat className="border border-primary text-primary fw-bold" value={this.state.model.vlTotalItem} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} prefix="R$ " required={false} readOnly={true} />
				</FormItem>
				<FormItem md={3} label="Valor Final Itens">
					<InputNumberFormat className="border border-primary text-primary fw-bold" value={this.state.model.vlFinalItem} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} prefix="R$ " required={false} readOnly={true} />
				</FormItem>
				<FormItem md={3} label="Valor Final Ordem">
					<InputNumberFormat className="border border-success text-success fw-bold" value={this.state.model.vlFinalOrdem} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} prefix="R$ " required={false} readOnly={true} />
				</FormItem>
			</Row>
		);
	}

	getRecursoOrdem(cdRecursoOrdem) {
		var tipoOrdemRecursoOrdem = this.state.tipoOrdemRecursoOrdem?.find(t => t?.recursoOrdem?.cdRecursoOrdem === cdRecursoOrdem)
		return tipoOrdemRecursoOrdem ? tipoOrdemRecursoOrdem : false;
	}

	handleVerificaIdentificadorExterno() {
		let cdIdentificadorExterno = this.state.model.cdIdentificadorExterno.trim();
		if (cdIdentificadorExterno.length === 0) {
			return;
		}

		Config.getService().getAllBy({ cdIdentificadorExterno: cdIdentificadorExterno, unidade: this.state.model.unidade }, (response) => {
			let ordens = response.filter(r => r.idOrdem !== this.state.model.idOrdem);

			if (ordens.length > 0) {
				Modal.info("Atenção", "O identificador externo já está em uso na(s) ordem(s): ", ordens.map(r => r.idOrdem), Modal.style.warning);
			} else {
				toast.success("O código '" + cdIdentificadorExterno + "' do identificador externo não está em uso em nenhuma ordem!", Modal.style.warning);
			}
		});
	}

	handleVerificaPedidoExterno() {
		let cdPedidoExterno = this.state.model.cdPedidoExterno.trim();
		if (cdPedidoExterno.length === 0) {
			return;
		}

		Config.getService().getAllBy({ cdPedidoExterno: cdPedidoExterno, unidade: this.state.model.unidade }, (response) => {
			let ordens = response.filter(r => r.idOrdem !== this.state.model.idOrdem);

			if (ordens.length > 0) {
				Modal.info("Atenção", "O pedido externo já está em uso na(s) ordem(s): ", ordens.map(r => r.idOrdem), Modal.style.warning);
			} else {
				toast.success("O código '" + cdPedidoExterno + "' do pedido externo não está em uso em nenhuma ordem!", Modal.style.warning);
			}
		});
	}

	render() {

		let flPodeEditar = this.state.model.statusOrdem?.flPodeEditar;
		let flEdicao = this.state.model?.idOrdem ? true : false;

		return super.render(
			<Div key={this.state.key}>
				<Panel title="Cadastro de Ordem" onClose={this.handleClose} isModal={this.isModal()} localOpen={this.props.localOpen} container={{ sizing: Container.sizing.fluid, className: "" }} modal={{ sizing: this.props.sizing ? this.props.sizing : ModalDialog.sizing.xl }}>
					<PanelBody className="p-0 m-0">
						<TabNavigator onChangeTab={this.handleChangeTab} className="border-light" index={this.state.tabIndex}>
							<Tab title={<><IconCardChecklist /> Ordem</>} className="m-2">
								<Form onSubmit={this.handleSuccess} refParam={f => (this.form = f)}>
									<Row className="g-3 pb-3 px-2">
										<FormItem md={2} label="Código">
											<InputText name="idOrdem" value={this.state.model.idOrdem} readOnly={true} required={false} onChange={this.handleChangeModel} />
										</FormItem>
										<FormItem md={2} label="Data Ordem" show={flEdicao}>
											<InputText value={Util.date2Br(this.state.model.dhInsert, true)} readOnly={true} required={false} />
										</FormItem>
										<FormItem md={4} label="Usuário" show={flEdicao}>
											<InputText value={this.state.model.usuario?.nmUsuario} readOnly={true} required={false} />
										</FormItem>
										<FormItem md={4} label="Status" show={flEdicao}>
											<InputGroup opcional={true} >
												<StatusCustom opcional={true} type="InputText" color={this.state.model.statusOrdem}>{this.state.model.statusOrdem?.nmStatusOrdem}</StatusCustom>
												<InputGroupButton>
													<BtnButton color={BtnButton.color.outlinePrimary} onClick={e => this.handleAlterarStatus(e, this.state.model)} disabled={!this.state.model.statusOrdem?.flPodeAlterarStatus} show={Config.getPermissaoOrdem(this.state.model).flPodeAlterarStatus}> <IconNodePlusFill /> </BtnButton>
												</InputGroupButton>
											</InputGroup>
										</FormItem>
									</Row>
									<Row className="g-3 pb-3 px-2">
										<FormItemEmpresaUnidade disabled={!flPodeEditar} md={[6, 6]} name="unidade" value={this.state.model.unidade} required={true} onChange={this.handleChangeModel} />

										<FormItemTipoPessoaPessoa type={["BtnRadioGroup"]} label={["Tipo Cliente", "Cliente"]} disabled={!flPodeEditar} md={[4, 8]} name="pessoa" value={this.state.model.pessoa} required={true} onChange={this.handleChangeModel} />

										<FormItem md={6} label="Tipo da Ordem">
											<SelectTipoOrdem readOnly={flEdicao} filter={{ hasAccess: true }} disabled={flEdicao} name="tipoOrdem" value={this.state.model.tipoOrdem} required={true} onChange={(e, v, p) => this.handleChangeModel(e, v, p, this.handleChangeTipoOrdem)} placeholder="Selecione Tipo da Ordem" />
										</FormItem>

										<FormItem md={6} label="Tipo Preço">
											<SelectTipoPreco readOnly={flEdicao} filter={{ hasAccess: true }} disabled={flEdicao} name="tipoPreco" value={this.state.model.tipoPreco} required={true} onChange={this.handleChangeModel} placeholder="Selecione o Tipo de Preço" />
										</FormItem>

										<FormItem label="Obra" md={12} show={this.getRecursoOrdem("VINCULAR_OBRA")}>
											<InputObra required={this.getRecursoOrdem("VINCULAR_OBRA")?.flObrigatorio} disabled={!flPodeEditar || !this.state.model.pessoa?.idPessoa} getOptionsWhen={f => this.state.model.pessoa?.idPessoa !== undefined} filter={{ pessoa: this.state.model.pessoa }} name="obra" value={this.state.model.obra} onChange={this.handleChangeModel} placeholder="Selecione a Obra" />
										</FormItem>

										<FormItem md={12} label="Observação">
											<TextArea rows={3} disabled={!flPodeEditar} placeholder="Digite a observação da ordem aqui" name="txObservacao" value={this.state.model.txObservacao} required={false} onChange={this.handleChangeModel} />
										</FormItem>

										<FormItem md={3} label="Identificador Externo">
											<InputGroup className="flex-nowrap" sizing={InputGroup.sizing.default}>
												<InputText name="cdIdentificadorExterno" value={this.state.model.cdIdentificadorExterno} maxLength={100} required={false} onChange={this.handleChangeModel} disabled={!flPodeEditar || !this.state.model.unidade?.idUnidade} />
												<InputGroupButton>
													<BtnButton disabled={!flPodeEditar || !this.state.model.unidade?.idUnidade || !this.state.model.cdIdentificadorExterno} onClick={this.handleVerificaIdentificadorExterno}> <IconSearch /> </BtnButton>
												</InputGroupButton>
											</InputGroup>
										</FormItem>

										<FormItem md={3} label="Pedido Externo">
											<InputGroup className="flex-nowrap" sizing={InputGroup.sizing.default}>
												<InputText name="cdPedidoExterno" value={this.state.model.cdPedidoExterno} maxLength={100} required={false} onChange={this.handleChangeModel} disabled={!flPodeEditar || !this.state.model.unidade?.idUnidade} />
												<InputGroupButton>
													<BtnButton disabled={!flPodeEditar || !this.state.model.unidade?.idUnidade || !this.state.model.cdPedidoExterno} onClick={this.handleVerificaPedidoExterno}> <IconSearch /> </BtnButton>
												</InputGroupButton>
											</InputGroup>
										</FormItem>

										<FormItem md={6} label="Localização">
											<InputMapOneMarker apiKey={ConfigApp.getInstance().clientIdGoogleMaps}
												lng={this.state.model.localizacao?.vlLongitude}
												lat={this.state.model.localizacao?.vlLatitude}
												required={false}
												readOnly={true} />
										</FormItem>

										<FormItem show={Config.getPermissaoOrdem(this.state.model).flPodeMarcarPagarComissao} md={3} label="Pagar Comissão?" help="Se marcado, ao realizar o processamento da comissão, essa ordem será considerada para pagamento!">
											<BtnRadioGroupSimNao disabled={!flPodeEditar} name="flPagarComissao" value={this.state.model.flPagarComissao} required={true} onChange={this.handleChangeModel} />
										</FormItem>

										<ListDadoValorByParentForm editable={flPodeEditar} cdDadoLocal="ORDEM" parent={this.state.model} onChange={this.handleChangeModel} name="ordemDadoValor" />

										<BtnSubmit hidden > Enviar </BtnSubmit>
									</Row>
									{this.getRenderTotais()}
								</Form>
							</Tab>
							<Tab title={<><IconPersonFill /> Pessoa</>} opened={false} disabled={!flEdicao}>
								<FormPessoa key={this.state.model?.pessoa?.idPessoa} id={this.state.model?.pessoa?.idPessoa} localOpen={BaseCrud.localOpen.embedded} handleSuccess={e => { }} handleClose={false} />
							</Tab>
							<Tab show={Permissao.getInstance().getPermissoes([45])} title={<><IconBasket /> Itens</>} disabled={!flEdicao} opened={false} className="m-2">
								<ListOrdemItem key={this.state.key} parent={this.state.model} localOpen={BaseList.localOpen.modal} onAdd={this.init} onEdit={this.init} onDelete={this.init} />

								<Div className="px-2">
									<Form onSubmit={e => this.handleAplicarDesconto(e, this.state.model)} show={Config.getPermissaoOrdem(this.state.model).flPodeVerValorOrdem}>
										<Row className="g-3 pb-0 justify-content-end" show={this.state.model.vlTotalItem > 0}>
											<FormItem md={3} label="Desconto">
												<BtnRadioGroup required={true} name="cdTipoDesconto" onChange={(e, v, p) => this.handleChangeModel(e, v, p, this.handleChangeTipoDesconto)} value={this.state.model.cdTipoDesconto} disabled={!flPodeEditar || !Config.getPermissaoOrdem(this.state.model).flPodeAplicarDesconto}>
													<BtnRadio value="P" label="Percentual" color={BtnRadio.color.primary} />
													<BtnRadio value="V" label="Valor" color={BtnRadio.color.primary} />
												</BtnRadioGroup>
											</FormItem>
											<FormItem md={3} label="Perc. Desconto">
												<InputNumberFormat disabled={this.state.model.cdTipoDesconto === "V" || !flPodeEditar || !Config.getPermissaoOrdem(this.state.model).flPodeAplicarDesconto} name="pcDesconto" value={parseFloat(this.state.model.pcDesconto)} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} suffix=" %" required={true} onChange={(e, v, p) => this.handleChangeModel(e, v, p, this.handleChangePercDesconto)} />
											</FormItem>
											<FormItem md={3} label="Valor  Desconto">
												<InputNumberFormat className="border border-danger text-danger fw-bold" disabled={this.state.model.cdTipoDesconto === "P" || !flPodeEditar || !Config.getPermissaoOrdem(this.state.model).flPodeAplicarDesconto} name="vlDesconto" value={parseFloat(this.state.model.vlDesconto)} decimalScale={2} thousandSeparator="." decimalSeparator="," inputMode="numeric" fixedDecimalScale={true} prefix="R$ " required={true} onChange={(e, v, p) => this.handleChangeModel(e, v, p, this.handleChangeVlDesconto)} />
											</FormItem>
										</Row>
										<Row className="justify-content-end px-2 pt-1" show={this.state.model.vlTotalItem > 0}>
											<Col md={3} className="text-end">
												<BtnSubmit sizing={Button.sizing.sm} disabled={!flPodeEditar || !Config.getPermissaoOrdem(this.state.model).flPodeAplicarDesconto}> Aplicar Desconto </BtnSubmit>
											</Col>
										</Row>
									</Form>

									{this.getRenderTotais()}
								</Div>
							</Tab>
							<Tab show={Permissao.getInstance().getPermissoes([136])} title={<><IconMailboxFlag /> Endereço</>} disabled={!flEdicao} opened={false} className="m-2">
								<ListOrdemEndereco key={this.state.key} parent={this.state.model} localOpen={BaseList.localOpen.modal} editable={flPodeEditar} />
							</Tab>
							<Tab show={Permissao.getInstance().getPermissoes([46])} title={<><IconChatSquareQuote /> Comentários</>} disabled={!flEdicao} opened={false} className="m-2">
								<ListOrdemComentario key={this.state.key} parent={this.state.model} localOpen={BaseList.localOpen.modal} />
							</Tab>
							<Tab show={Permissao.getInstance().getPermissoes([77])} title={<><IconClockHistory /> Histórico</>} disabled={!flEdicao} opened={false} className="m-2">
								<ListOrdemHistorico key={this.state.key} parent={this.state.model} localOpen={BaseList.localOpen.modal} />
							</Tab>
							<Tab show={Permissao.getInstance().getPermissoes([61]) && this.getRecursoOrdem("ANALISE_DE_SOLO")} title={<><IconCrosshair2 /> Perfis</>} disabled={!flEdicao} opened={false} className="m-2">
								<ListAnsPerfilByOrdem key={this.state.key} parent={this.state.model} localOpen={BaseList.localOpen.modal} />
							</Tab>
							<Tab show={Permissao.getInstance().getPermissoes([186])} title={<><IconClipboardCheck /> ART</>} disabled={!flEdicao} opened={false} className="m-2">
								<ListOrdemArt key={this.state.key} parent={this.state.model} localOpen={BaseList.localOpen.modal} editable={flPodeEditar} />
							</Tab>
							<Tab show={Permissao.getInstance().getPermissoes([195])} title={<><IconPaperclip /> Arquivos</>} disabled={!flEdicao} opened={false} className="m-2">
								<ListOrdemArquivo key={this.state.key} parent={this.state.model} localOpen={BaseList.localOpen.modal} editable={flPodeEditar} />
							</Tab>
							<Tab show={Permissao.getInstance().getPermissoes([205])} title={<><IconPeopleFill /> Equipe</>} disabled={!flEdicao} opened={false} className="m-2">
								<ManyToManyCheckOrdemEquipeByEquipe typeCheck={ManyToManyCheckOrdemEquipeByEquipe.typeCheck.box} parent={this.state.model} search={true} disabled={!flPodeEditar} />
							</Tab>
							<Tab show={Permissao.getInstance().getPermissoes([206])} title={<><IconPersonFill /> Pessoas</>} disabled={!flEdicao} opened={false} className="m-2">
								<ListOrdemPessoa key={this.state.key} parent={this.state.model} localOpen={BaseList.localOpen.modal} editable={flPodeEditar} />
							</Tab>
							<Tab show={Permissao.getInstance().getPermissoes([207])} title={<><IconHandHoldingUsd /> Comissão</>} disabled={!flEdicao} opened={false} className="m-2">
								<ListComissaoPessoaOrdemByOrdem parent={this.state.model} localOpen={BaseList.localOpen.modal} editable={flPodeEditar} />
							</Tab>
							<Tab show={Permissao.getInstance().getPermissoes([170])} title={<><IconPatchPlus /> Complementar</>} disabled={!this.state.model.idOrdem} opened={false} className="p-0 m-0">
								<ListDadoValorByParent cdDadoLocal="ORDEM" parent={this.state.model} localOpen={BaseList.localOpen.modal} />
							</Tab>
							<TabDropdown label="Opções" alignment={DropDown.alignment.end} show={flEdicao}>
								<DropDownItem title="Imprimir" type="button" onClick={e => this.handleImprimir(e, this.state.model)} show={Config.getPermissaoOrdem(this.state.model).flPodeImprimir}> <IconPrinterFill /> Imprimir Ordem </DropDownItem>
								<DropDownItem title="Faturar" type="button" onClick={e => this.handleFaturar(e, this.state.model)} disabled={!this.state.model.statusOrdem?.flPodeFaturar} show={Config.getPermissaoOrdem(this.state.model).flPodeFaturar}> <IconCoin /> Faturar Ordem </DropDownItem>
								<DropDownItem title="Cancelar" type="button" onClick={e => this.handleCancelar(e, this.state.model)} disabled={!this.state.model.statusOrdem?.flPodeCancelar} show={!this.state.model.statusOrdem?.flPodeReabrir && Config.getPermissaoOrdem(this.state.model).flPodeCancelar}> <IconClear /> Cancelar Ordem </DropDownItem>
								<DropDownItem title="Reabrir" type="button" onClick={e => this.handleReabrir(e, this.state.model)} disabled={!this.state.model.statusOrdem?.flPodeReabrir} show={this.state.model.statusOrdem?.flPodeReabrir && Config.getPermissaoOrdem(this.state.model).flPodeReabrir}> <IconRecycle /> Reabrir Ordem </DropDownItem>
								<DropDownItem title="Alterar Status" type="button" onClick={e => this.handleAlterarStatus(e, this.state.model)} disabled={!this.state.model.statusOrdem?.flPodeAlterarStatus} show={Config.getPermissaoOrdem(this.state.model).flPodeAlterarStatus}> <IconNodePlusFill /> Alterar Status </DropDownItem>
								<DropDownItem title="Ver Fatura" type="button" onClick={e => this.handleVerFatura(e, this.state.model)} show={Config.getPermissaoOrdem(this.state.model).flPodeVerFatura && this.state.model?.statusOrdem?.flFaturada}> <IconCoin /> Ver Fatura </DropDownItem>
								<DropDownItem show={Config.getPermissaoOrdem(this.state.model).flPodePagarComissao} sizing={Button.sizing.default} onClick={e => this.handleComissao(e)}> <IconHandHoldingUsd /> Gerar Comissão </DropDownItem>
							</TabDropdown>
						</TabNavigator>
					</PanelBody>
					<PanelFooter>
						<FormButtons show={this.state.tabIndex === 0} onClose={this.handleClose} onSubmit={this.handleSubmitForm} disabled={[!flPodeEditar]}></FormButtons>
					</PanelFooter>
				</Panel>
			</Div>
		);
	}
}

function With(props) {
	let navigate = useNavigate();
	let params = useParams();
	let location = useLocation();
	return <FormOrdem {...props} navigate={navigate} params={params} location={location} />
}
export default With