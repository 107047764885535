
import FormGenero from "./FormGenero.js";
import ListGenero from "./ListGenero.js";
import { Permissao } from "../../controllers";
import { Util } from "../../utils";
import { DadoValorService, GeneroService } from "../../services";

export default class Config {

	static getUrlForm() {
		return "/CadGenero";
	}

	static getUrlList() {
		return "/LstGenero";
	}

	static getComponentForm() {
		return FormGenero;
	}

	static getPermissionForm() {
		return Permissao.getInstance().getPermissoes([229]);
	}

	static getPermissionList() {
		return Permissao.getInstance().getPermissoes([229]);
	}

	static getComponentList() {
		return ListGenero;
	}

	static getService() {
		return GeneroService;
	}

	static getKeyId() {
		return "idGenero";
	}

	static getKeyDescription() {
		return "nmGenero";
	}

	static setDefaultValues(state , fnCallBack = () => {}, className = undefined) {
		className = className === undefined ? "FormGenero" : className;
		let keyNameScreen = Util.toToUpperTextCamelCase(className, "_") + ".";
		var prefix = "CONFIGURACAO.";
		DadoValorService.getValues(`${prefix}${keyNameScreen}`, (response)=> {
			Util.setValueObjectCamelCase(state.model, response, keyNameScreen, prefix);
			fnCallBack();
		}, fnCallBack);
	}
}