import React from "react";
import Field from "./Field";

export default class InputColor extends Field {

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
    }

    componentDidMount() {
        super.componentDidMount();

        if(!this.props.value){
            super.handleChange({}, "#000000", this.props.name);
        }
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    handleChange(e) {
        super.handleChange(e, e?.target?.value, e?.target?.name);
    }

    handleBlur(e) {
        super.handleBlur(e, e?.target?.value, e?.target?.name);
    }

    render(type) {
        const props = Object.assign({}, this.props);
        props.type = "color";
        props.onChange = this.handleChange;
        props.onBlur = this.handleBlur;
        props.ref = this.props.refParam ? this.props.refParam : this.ref;
        props.className = `form-control form-control-color ${props.className ? props.className : ""} ${props.sizing ? "form-control-" + props.sizing : ""}`;

        return super.render(<input {...this.getProps(props)} />);
    }
}