import { Component } from "react";
import EventBus from "../../controllers/EventBus";
import Modal from "../components/Modal";
import { BaseCrud } from "../base";

export default class ModalContainer extends Component {

    constructor(props) {
        super(props);

        this.addModal = this.addModal.bind(this);
        this.delModal = this.delModal.bind(this);

        this.state = {
            modals: []
        };
    }

    componentDidMount() {
        EventBus.on("modal-add", (info) => {
            this.addModal(info);
        });

        EventBus.on("modal-del", () => {
            this.delModal();
        });
    }

    addModal(modal) {
        this.setState((state) => {
            modal.zIndex = state.modals.length;
            modal.modalId = Math.random(10000);
            state.modals.push(modal);
            return state;
        });
    }

    delModal(modal, fnCallBack) {
        let index = this.state.modals.findIndex(m => m.modalId === modal.modalId);

        if (index >= 0) {
            this.setState((state) => {
                state.modals.splice(index, 1);
                return state;
            }, fnCallBack);
        }else{
            if(fnCallBack){
                fnCallBack();
            }
        }
    }

    render() {
        return (
            <>
                {this.state.modals.map((modal, index) => (
                    <Modal key={index}
                        {...modal.props}
                        window={modal.window}
                        config={modal.config}
                        zIndex={modal.zIndex}
                        handleSuccess={(...args) => {this.delModal(modal); modal.handleSuccess.apply(this, args)}}
                        handleClose={(...args) => {this.delModal(modal); modal.handleClose.apply(this, args)}}
                        localOpen={BaseCrud.localOpen.modal}
                    />
                ))}
            </>
        );
    }
}
