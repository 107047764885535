import { Component } from "react";

export default class Card extends Component {

    render() {
        if(this.props.show !== undefined && !Boolean(this.props.show)){
            return (<></>);
        }

        const props = Object.assign({}, this.props);
        props.className = `card ${props.className !== undefined ? props.className : ""}`;

        delete props.show;

        return (            
            <div {...props}> {this.props.children} </div>
        );
    }
}